import React from 'react';

const TabacoIcon = () => {
	return (
		<svg
			width="20"
			height="17"
			viewBox="0 0 20 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0 14H15V17H0V14ZM18.5 14H20V17H18.5V14ZM16 14H17.5V17H16V14ZM16.85 5.73C17.47 5.12 17.85 4.28 17.85 3.35C17.85 1.5 16.35 0 14.5 0V1.5C15.52 1.5 16.35 2.33 16.35 3.35C16.35 4.37 15.52 5.2 14.5 5.2V6.7C16.74 6.7 18.5 8.53 18.5 10.77V13H20V10.76C20 8.54 18.72 6.62 16.85 5.73ZM14.03 8.2H12.5C11.48 8.2 10.65 7.22 10.65 6.2C10.65 5.18 11.48 4.45 12.5 4.45V2.95C10.65 2.95 9.15 4.45 9.15 6.3C9.15 8.15 10.65 9.65 12.5 9.65H14.03C15.08 9.65 16 10.39 16 11.7V13H17.5V11.36C17.5 9.55 15.9 8.2 14.03 8.2Z"
				fill="#827717"
			/>
		</svg>
	);
};

export default TabacoIcon;
