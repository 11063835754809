import React, { FC } from 'react';

interface Props {
	height: string;
	viewBox: string;
}

const Biotech: FC<Props> = ({ height, viewBox }) => {
	return (
		<svg
			fill="rgba(255, 255, 255, 0.56)"
			xmlns="http://www.w3.org/2000/svg"
			width="32px"
			height={height}
			viewBox={viewBox}
			version="1.1"
		>
			<g id="surface1">
				<path d="M 6.667969 28 L 6.667969 25.765625 L 13.5 25.765625 L 13.5 22.566406 L 13.167969 22.566406 C 11.367188 22.566406 9.832031 21.9375 8.566406 20.683594 C 7.300781 19.425781 6.667969 17.890625 6.667969 16.066406 C 6.667969 14.710938 7.042969 13.484375 7.800781 12.382812 C 8.554688 11.285156 9.523438 10.511719 10.699219 10.066406 C 10.632812 10.445312 10.640625 10.828125 10.71875 11.21875 C 10.792969 11.605469 10.933594 11.964844 11.132812 12.300781 C 10.421875 12.609375 9.871094 13.109375 9.484375 13.800781 C 9.09375 14.488281 8.898438 15.246094 8.898438 16.066406 C 8.898438 17.246094 9.316406 18.253906 10.152344 19.101562 C 10.984375 19.945312 11.988281 20.367188 13.167969 20.367188 L 24.433594 20.367188 L 24.433594 22.566406 L 16.167969 22.566406 L 16.167969 25.765625 L 25.332031 25.765625 L 25.332031 28 Z M 19.101562 15.898438 L 18.667969 14.632812 L 17.300781 15.167969 L 16.5 13.066406 C 16.855469 12.734375 17.128906 12.355469 17.316406 11.933594 C 17.503906 11.511719 17.601562 11.054688 17.601562 10.566406 C 17.601562 9.632812 17.28125 8.832031 16.648438 8.167969 C 16.015625 7.5 15.234375 7.144531 14.300781 7.101562 L 13.632812 5.234375 L 14.964844 4.734375 L 14.5 3.5 L 16.800781 2.667969 L 17.234375 3.898438 L 18.566406 3.433594 L 22.300781 13.332031 L 20.898438 13.832031 L 21.367188 15.066406 Z M 14.132812 12.464844 C 13.601562 12.464844 13.148438 12.28125 12.78125 11.917969 C 12.417969 11.550781 12.234375 11.101562 12.234375 10.566406 C 12.234375 10.03125 12.417969 9.578125 12.78125 9.199219 C 13.148438 8.824219 13.601562 8.632812 14.132812 8.632812 C 14.667969 8.632812 15.117188 8.824219 15.484375 9.199219 C 15.851562 9.578125 16.035156 10.03125 16.035156 10.566406 C 16.035156 11.101562 15.851562 11.550781 15.484375 11.917969 C 15.117188 12.28125 14.667969 12.464844 14.132812 12.464844 Z M 14.132812 12.464844 " />
			</g>
		</svg>
	);
};

export default Biotech;
