import React, { useContext } from 'react';
import { Divider, withStyles } from '@material-ui/core';
import {
	CustomBadget,
	HistoryLabelText,
	PrescriptionContainer,
	HistorySideBarListItem,
	StyledList,
	StyledListItemText,
} from '../../../../shared/style';
import { HistoryContext, HistoryContextProps } from '../../../../context/history-context';
import { ClientContextProps, HistorySidebarOptions } from '../../../../context/types';
import { ClientsContext } from '../../../../context/client-context';
import { AllergyStatus } from 'models';

const SideBarListItemStyled = withStyles({
	selected: {
		backgroundColor: 'rgba(25, 118, 210, 0.08) !important',
	},
})(HistorySideBarListItem) as typeof HistorySideBarListItem;

const HistorySidebar: React.FC = () => {
	const { clientHistory: clientHistoryData } = useContext(ClientsContext) as ClientContextProps;
	const { currentSidebarTab, setCurrentSidebarTab } = useContext(
		HistoryContext
	) as HistoryContextProps;

	return (
		<StyledList component="nav" aria-label="secondary mailbox folders">
			<PrescriptionContainer style={{ height: 'calc(100dvh - 130px)' }}>
				<SideBarListItemStyled
					button
					selected={currentSidebarTab === HistorySidebarOptions.RESUMEN}
					onClick={() => setCurrentSidebarTab(HistorySidebarOptions.RESUMEN)}
					style={{ marginBottom: '6.5px', marginTop: '3px' }}
				>
					<StyledListItemText primary="Resumen de historial" />
					<CustomBadget overlap="rectangular" color="primary" />
				</SideBarListItemStyled>
				<Divider />
				<HistoryLabelText variant="subtitle2" component="h2">
					Antecedentes patológicos
				</HistoryLabelText>
				<SideBarListItemStyled
					button
					divider
					selected={currentSidebarTab === HistorySidebarOptions.ALERGIES}
					onClick={() => setCurrentSidebarTab(HistorySidebarOptions.ALERGIES)}
				>
					<StyledListItemText primary="Alergias" />
					<CustomBadget
						overlap="rectangular"
						badgeContent={
							clientHistoryData?.allergies?.filter(
								(d: any) => d.status === AllergyStatus.ACTIVE
							)?.length
						}
						color="error"
					/>
				</SideBarListItemStyled>
				<SideBarListItemStyled
					button
					divider
					selected={currentSidebarTab === HistorySidebarOptions.DISEASES}
					onClick={() => setCurrentSidebarTab(HistorySidebarOptions.DISEASES)}
				>
					<StyledListItemText primary="Enfermedades" />
					<CustomBadget
						overlap="rectangular"
						badgeContent={
							clientHistoryData?.diseases?.filter(
								(d: any) => d.status === AllergyStatus.ACTIVE
							)?.length
						}
						color="error"
					/>
				</SideBarListItemStyled>
				<SideBarListItemStyled
					button
					divider
					selected={currentSidebarTab === HistorySidebarOptions.PROCEDURES}
					onClick={() => setCurrentSidebarTab(HistorySidebarOptions.PROCEDURES)}
				>
					<StyledListItemText primary="Procedimientos" />
					<CustomBadget
						overlap="rectangular"
						badgeContent={clientHistoryData?.procedures?.length}
						color="primary"
					/>
				</SideBarListItemStyled>
				<SideBarListItemStyled
					button
					divider
					selected={currentSidebarTab === HistorySidebarOptions.PREVIOUS_HOSPITALIZATION}
					onClick={() =>
						setCurrentSidebarTab(HistorySidebarOptions.PREVIOUS_HOSPITALIZATION)
					}
				>
					<StyledListItemText primary="Hospitalización previa" />
					<CustomBadget
						overlap="rectangular"
						badgeContent={clientHistoryData?.hospitalizations?.length}
						color="primary"
					/>
				</SideBarListItemStyled>
				<HistoryLabelText variant="subtitle2" component="h2">
					Antecedentes familiares
				</HistoryLabelText>
				<SideBarListItemStyled
					button
					divider
					selected={currentSidebarTab === HistorySidebarOptions.FAMILY_HISTORY}
					onClick={() => setCurrentSidebarTab(HistorySidebarOptions.FAMILY_HISTORY)}
				>
					<StyledListItemText primary="Historia familiar" />
					<CustomBadget
						overlap="rectangular"
						badgeContent={
							clientHistoryData?.familyHistories?.filter(
								(f: any) =>
									f.arterialHypertension ||
									f.cancer ||
									f.epilepsy ||
									f.heartDisease ||
									f.mellitusDiabetes ||
									f.nephropathy ||
									f.neuropathies ||
									f.other ||
									f.psychiatricDiseases ||
									f.rheumatoidArthritis
							)?.length
						}
						color="primary"
					/>
				</SideBarListItemStyled>
				<HistoryLabelText variant="subtitle2" component="h2">
					Antecedentes no patológicos
				</HistoryLabelText>
				<SideBarListItemStyled
					button
					divider
					selected={currentSidebarTab === HistorySidebarOptions.LIFE_STYLE}
					onClick={() => setCurrentSidebarTab(HistorySidebarOptions.LIFE_STYLE)}
				>
					<StyledListItemText primary="Estilo de vida" />
					<CustomBadget
						overlap="rectangular"
						badgeContent={clientHistoryData?.lifeStyles?.length}
						color="primary"
					/>
				</SideBarListItemStyled>
			</PrescriptionContainer>
		</StyledList>
	);
};

export default HistorySidebar;
