import React from 'react';
import { get } from 'lodash';
import { IColumnType } from '../../shared/type';
import { StyledTableCell } from './style';
import { TableRow } from '@material-ui/core';
import useMobile from 'hooks/useMobile';

interface Props<T extends { isDisabled?: boolean }> {
	data: T[];
	columns: IColumnType<T>[];
}

function CTableRow<T extends { isDisabled?: boolean }>({ data, columns }: Props<T>): JSX.Element {
	const { isMobile } = useMobile();

	const mobileStyle = {
		borderLeft: 'none',
	};

	const columnsWithOutRightPadding = [
		'familyMember',
		'procedure_name',
		'status',
		'hospitalization_name',
	];
	return (
		<>
			{data.map((item, itemIndex) => {
				return (
					<TableRow
						key={`table-body-${itemIndex}`}
						style={{
							backgroundColor: item.isDisabled ? '#F5F5F5' : '#FFFFFF',
						}}
					>
						{columns.map((column, columnIndex) => {
							const value = get(item, column.key);
							return (
								<StyledTableCell
									key={`table-row-cell-${columnIndex}`}
									size={column.size || 'small'}
									component={'th'}
									style={{
										fontWeight: column.fontWeight,
										...(column.render && isMobile
											? { textAlign: 'right' }
											: {}),
										...(isMobile ? mobileStyle : {}),
										...(isMobile &&
										(column.key === 'status' || column.key === 'action')
											? { paddingLeft: 0 }
											: {}),
										...(isMobile &&
										columnsWithOutRightPadding.includes(column.key)
											? { paddingRight: 0 }
											: {}),
									}}
									scope={'row'}
									align={column.align || 'left'}
									width={column.width || 100}
								>
									{column.render ? column.render(column, item) : value}
								</StyledTableCell>
							);
						})}
					</TableRow>
				);
			})}
		</>
	);
}

export default CTableRow;
