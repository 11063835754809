import { Tooltip, withStyles } from '@material-ui/core';
import React, { FC } from 'react';

export const CustomTooltip = withStyles({
	tooltip: {
		fontSize: '16px',
	},
})(Tooltip);

interface props {
	description?: string;
}

const ExclamationMarkIcon: FC<props> = ({ description = 'No hay descripción' }) => {
	return (
		<CustomTooltip title={description} placement="top" arrow>
			<svg
				width="18"
				height="18"
				viewBox="0 0 18 18"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				style={{
					marginLeft: '10px',
				}}
			>
				<path
					d="M9.00008 0.666992C4.40008 0.666992 0.666748 4.40033 0.666748 9.00033C0.666748 13.6003 4.40008 17.3337 9.00008 17.3337C13.6001 17.3337 17.3334 13.6003 17.3334 9.00033C17.3334 4.40033 13.6001 0.666992 9.00008 0.666992ZM9.83342 13.167H8.16675V8.16699H9.83342V13.167ZM9.83342 6.50033H8.16675V4.83366H9.83342V6.50033Z"
					fill="black"
					fill-opacity="0.54"
				/>
			</svg>
		</CustomTooltip>
	);
};

export default ExclamationMarkIcon;
