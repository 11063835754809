import { Button, Divider, IconButton, ListItem, ListItemIcon, MenuItem } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Check from '@material-ui/icons/Check';

import React, { useContext } from 'react';
import { WaitingListItemStatus } from 'models';
import {
	WaitingListsContext,
	WaitingListContextProps,
} from '../../../context/waiting-list-context';
import {
	ActionSection,
	NoItemButton,
	PatientListItemText,
	WaitingListSelect,
} from '../../../shared/style';
import useMobile from 'hooks/useMobile';

interface WaitingListItemProps {
	waitingItemID: string;
	status: keyof typeof WaitingListItemStatus;
	includeLineSeparator?: boolean;
	clientID: string;
	positionNumber: number;
	clientName?: string;
	clientLastName?: string;
	clientHealthInsurrance?: string;
	onConsulta: (waitingListItemID: string) => void;
	onTerminada: (waitingListItemID: string) => void;
	onClick: (clientId: string) => void;
}

const WaitingListItem: React.FC<WaitingListItemProps> = (props) => {
	const { isMobile } = useMobile();
	const waitingListsContext = useContext(WaitingListsContext) as WaitingListContextProps;
	const totalItems = waitingListsContext.waitingListItems
		.filter((waitingListItem) => waitingListItem.status === 'ESPERA')
		.sort((a, b) => a.positionNumber - b.positionNumber);

	const handleWaitingItemAction = (
		status: keyof typeof WaitingListItemStatus,
		waitingListItemID: string
	) => {
		if (status === 'ESPERA') {
			props.onConsulta(waitingListItemID);
		} else if (status === 'CONSULTA') {
			props.onTerminada(waitingListItemID);
		}
	};

	const get0PrefixValue = (value: number): string => {
		return `${value > 9 ? '' : 0}${value}`;
	};

	const generateOptions = (): JSX.Element[] => {
		const result: JSX.Element[] = [];
		totalItems.forEach((item) => {
			result.push(
				<MenuItem
					className="outline-none"
					key={item.id}
					value={item.positionNumber}
					style={{
						color: '#1976D2',
						fontSize: '15px',
						fontWeight: '500',
						fontStyle: 'normal',
						lineHeight: '26px',
						letterSpacing: '0.46px',
						textTransform: 'uppercase',
					}}
				>
					{get0PrefixValue(item.positionNumber)}
				</MenuItem>
			);
		});

		if (props.status === 'CONSULTA') {
			return [
				<MenuItem
					className="outline-none"
					key={props.waitingItemID}
					value={props.positionNumber}
					style={{
						color: '#1976D2',
						fontSize: '15px',
						fontWeight: '500',
						fontStyle: 'normal',
						lineHeight: '26px',
						letterSpacing: '0.46px',
						textTransform: 'uppercase',
					}}
				>
					{props.positionNumber}
				</MenuItem>,
				<MenuItem
					className="outline-none"
					key={props.waitingItemID}
					value={WaitingListItemStatus.ESPERA}
					style={{
						color: '#1976D2',
						fontSize: '15px',
						fontWeight: '500',
						fontStyle: 'normal',
						lineHeight: '26px',
						letterSpacing: '0.46px',
						textTransform: 'uppercase',
					}}
				>
					Mover a espera
				</MenuItem>,
			];
		}

		return result;
	};

	const handlePositionChange = async (value: number) => {
		await waitingListsContext.updateWaitingItemPositionNumber(props.waitingItemID, value);
	};

	return (
		<>
			<ListItem button>
				<ListItemIcon>
					<WaitingListSelect
						value={props.positionNumber}
						onChange={(event) => handlePositionChange(event.target.value as number)}
					>
						{generateOptions()}
					</WaitingListSelect>
				</ListItemIcon>
				<PatientListItemText
					onClick={() => props.onClick(props.clientID)}
					primary={`${props.clientName} ${props.clientLastName}`}
					secondary={props.clientHealthInsurrance ? props.clientHealthInsurrance : null}
				/>
				<ActionSection>
					{props.status === 'ESPERA' ? (
						<Button
							variant="outlined"
							color="primary"
							size="small"
							onClick={() =>
								handleWaitingItemAction(props.status, props.waitingItemID)
							}
						>
							A Consulta
						</Button>
					) : (
						<NoItemButton
							variant="contained"
							color="primary"
							size="small"
							onClick={() =>
								handleWaitingItemAction(props.status, props.waitingItemID)
							}
						>
							{isMobile ? (
								<>
									<Check style={{ marginRight: 8 }} /> Terminar
								</>
							) : (
								'Terminar consulta'
							)}
						</NoItemButton>
					)}
					<IconButton
						edge="end"
						aria-label="close"
						onClick={() => props.onTerminada(props.waitingItemID)}
					>
						<CloseIcon />
					</IconButton>
				</ActionSection>
			</ListItem>
			{props.includeLineSeparator && <Divider variant="middle" component="li" />}
		</>
	);
};

export default WaitingListItem;
