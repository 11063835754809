import { createSubscription } from 'adapters/stripe';
import FullPulsearaLogo from 'components/svg/FullPulsearaLogo';
import { ClientsContext } from 'context/client-context';
import { RelationsContext } from 'context/relations-context';
import { DoctorType, RelationsContextProps, UserContextProps } from 'context/types';
import { UserContext } from 'context/user-context';
import useTolt from 'hooks/useTolt';
import { useContext, useEffect } from 'react';
import { StripePaymentStatus } from 'shared/constants';
import { getTrialEndDate } from 'shared/dateUtils';
import { SubscriptionData } from 'shared/type';
import { OnboardingStyled } from 'shared/style';

export const DoctorOnboardingScreen = () => {
	const { updateSubscription, setAlertMessage } = useContext(
		RelationsContext
	) as RelationsContextProps;
	const { refId, resetRef } = useTolt('ref', false);
	const { onboardingData, createClientWithExampleData } = useContext(ClientsContext);
	const { plans, checkIsLogged, handleDoctorLogin } = useContext(UserContext) as UserContextProps;
	const keyPlan = plans.find((p) => p.price === '80000')!;
	const saveWithOutPaymentHandler = async (doctor: DoctorType) => {
		try {
			const trialEndDate = getTrialEndDate();
			const subscriptionData: SubscriptionData = {
				customer: doctor && doctor.stripeCustomerID,
				items: [{ price: keyPlan.stripePlanKey }],
				trial_end: trialEndDate.unix(),
				billing_cycle_anchor: trialEndDate.unix(),
			};

			// @ts-ignore
			if (refId) {
				// @ts-ignore
				subscriptionData.metadata = {
					tolt_referral: refId,
				};
			}

			resetRef();
			const subscription = await createSubscription(subscriptionData);

			if (
				subscription.status !== StripePaymentStatus.Active &&
				subscription.status !== StripePaymentStatus.Trialing
			) {
				setAlertMessage({
					severity: 'error',
					message: 'Ocurrió un error al procesar el pago. Por favor, intenta de nuevo.',
				});

				return;
			}

			await updateSubscription(
				subscription.id,
				keyPlan.id,
				false,
				subscription.current_period_end,
				trialEndDate.toString(),
				doctor.id
			);
		} catch (e) {
			return;
		}
	};
	const executeDoctorOnboarding = async () => {
		if (onboardingData.hospitalDoctor) {
			try {
				const doctor = await handleDoctorLogin(
					onboardingData.user.attributes,
					onboardingData.use,
					true
				);
				if (doctor) {
					const [client] = await Promise.all([
						createClientWithExampleData?.(onboardingData.hospitalDoctor),
						saveWithOutPaymentHandler(doctor),
					]);
					const demoClientRoute = `/Clients/${client?.id}/summary`;
					await checkIsLogged(demoClientRoute);
				}
			} catch (error) {
				console.error('Error during onboarding:', error);
			}
		}
	};
	useEffect(() => {
		executeDoctorOnboarding();
	}, []);
	return (
		<OnboardingStyled>
			<div className="onboarding-container">
				<FullPulsearaLogo />
				<h1>Estamos configurando tu perfil. </h1>
				<span className="onboarding-loader"></span>
			</div>
		</OnboardingStyled>
	);
};
