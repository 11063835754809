import React from 'react';
import useMobile from 'hooks/useMobile';

type Props = {
	onClick: () => void;
};

const PrescriptionHeader = ({ onClick }: Props) => {
	const { isMobile } = useMobile();

	if (!isMobile) return null;

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				width: '100%',
				padding: '12px',
			}}
		>
			<h1
				style={{
					color: '#00000061',
					fontSize: '14px',
					fontStyle: 'normal',
					fontWeight: 500,
					lineHeight: '24px',
					letterSpacing: '0.4px',
					textTransform: 'uppercase',
				}}
			>
				Prescripciones
			</h1>
		</div>
	);
};

export default PrescriptionHeader;
