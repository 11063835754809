import { SubscriptionResponse } from 'context/types';

const generateURIBody = (data: any) => {
	/*
		here will be created a new array from the JSON received in data
		then will be created a new array using map to create the URI body as is requested,
		Ex:
		{
		customer: cus_123123,
		subscription: sub_12333
		}
		will be converted to
		customer=cus_123123&subscription=sub_12333
	*/
	const formBody = Object.entries(data).map(([key, value]) => {
		if (typeof value === 'object') {
			return generateURIProperty(key, value);
		}
		return `${key}=${value}`;
	});
	return formBody.join('&');
};
const generateURIProperty = (parentKey: string, object: any) => {
	const objectURI: any = Object.entries(object).map(([key, value]) => {
		if (typeof value === 'object') {
			return generateURIProperty(`${parentKey}[${key}]`, value);
		}
		return `${parentKey}[${key}]=${value}`;
	});
	return objectURI.join('&');
};

export const getCustomerById = async (customerID: string) => {
	const customer = await fetch(
		`${process.env.REACT_APP_STRIPE_BASE_URL}/customers/${customerID}`,
		{
			method: 'GET',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				Authorization: 'Bearer ' + process.env.REACT_APP_STRIPE_SECRET_KEY,
			},
		}
	);

	return getResponse(customer);
};

export const getSubByCustomer = async (customerID: string) => {
	const subscription = await fetch(
		`${process.env.REACT_APP_STRIPE_BASE_URL}/subscriptions?customer=${customerID}`,
		{
			method: 'GET',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				Authorization: 'Bearer ' + process.env.REACT_APP_STRIPE_SECRET_KEY,
			},
		}
	);

	return getResponse(subscription);
};
export const createCustomer = async (customerData: any) => {
	const customerCreated = await fetch(`${process.env.REACT_APP_STRIPE_BASE_URL}/customers`, {
		method: 'POST',
		body: generateURIBody(customerData),
		mode: 'cors',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			Authorization: 'Bearer ' + process.env.REACT_APP_STRIPE_SECRET_KEY,
		},
	});

	return getResponse(customerCreated);
};
export const createSubscription = async (subscriptionData: any) => {
	const subscriptionCreated = await fetch(
		`${process.env.REACT_APP_STRIPE_BASE_URL}/subscriptions`,
		{
			method: 'POST',
			body: generateURIBody(subscriptionData),
			mode: 'cors',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				Authorization: 'Bearer ' + process.env.REACT_APP_STRIPE_SECRET_KEY,
			},
		}
	);

	return getResponse(subscriptionCreated);
};
export const addCustomerToSubscription = async (customerID: string, subscriptionID: string) => {
	const customerUpdated = await fetch(
		`${process.env.REACT_APP_STRIPE_BASE_URL}/subscriptions/${subscriptionID}`,
		{
			method: 'POST',
			body: generateURIBody({ customer: customerID }),
			mode: 'cors',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				Authorization: 'Bearer ' + process.env.REACT_APP_STRIPE_SECRET_KEY,
			},
		}
	);

	return getResponse(customerUpdated);
};
export const attachNewPaymentMethodToCustomer = async (
	paymentMethodID: string,
	customerID: string
) => {
	const customerUpdated = await fetch(
		`${process.env.REACT_APP_STRIPE_BASE_URL}/payment_methods/${paymentMethodID}/attach`,
		{
			method: 'POST',
			body: generateURIBody({ customer: customerID }),
			mode: 'cors',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				Authorization: 'Bearer ' + process.env.REACT_APP_STRIPE_SECRET_KEY,
			},
		}
	);

	return getResponse(customerUpdated);
};
export const updateSubscriptionPaymentMethod = async (
	subscriptionID: string,
	paymentMethodID: string
) => {
	const subscriptionUpdated = await fetch(
		`${process.env.REACT_APP_STRIPE_BASE_URL}/subscriptions/${subscriptionID}`,
		{
			method: 'POST',
			body: generateURIBody({ default_payment_method: paymentMethodID }),
			mode: 'cors',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				Authorization: 'Bearer ' + process.env.REACT_APP_STRIPE_SECRET_KEY,
			},
		}
	);

	return getResponse(subscriptionUpdated);
};
export const cancelOrActivateSubscription = async (
	subscriptionID: string,
	cancelAtPeriodEnd: boolean
) => {
	const subscriptionUpdated = await fetch(
		`${process.env.REACT_APP_STRIPE_BASE_URL}/subscriptions/${subscriptionID}`,
		{
			method: 'POST',
			body: generateURIBody({ cancel_at_period_end: cancelAtPeriodEnd }),
			mode: 'cors',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				Authorization: 'Bearer ' + process.env.REACT_APP_STRIPE_SECRET_KEY,
			},
		}
	);

	return getResponse(subscriptionUpdated);
};
export const detachPaymentMethodToCustomer = async (paymentMethodID: string) => {
	const subscriptionUpdated = await fetch(
		`${process.env.REACT_APP_STRIPE_BASE_URL}/payment_methods/${paymentMethodID}/detach`,
		{
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				Authorization: 'Bearer ' + process.env.REACT_APP_STRIPE_SECRET_KEY,
			},
		}
	);

	return getResponse(subscriptionUpdated);
};

export const getCurrentSubscription = async (subsID: string) => {
	const currentSubscription = await fetch(
		`${process.env.REACT_APP_STRIPE_BASE_URL}/subscriptions/${subsID}`,
		{
			method: 'GET',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				Authorization: 'Bearer ' + process.env.REACT_APP_STRIPE_SECRET_KEY,
			},
		}
	);

	return getResponse(currentSubscription);
};

export const updateSubscriptionStripe = async (subsID: string, subscriptionData: any) => {
	const subscriptionCreated = await fetch(
		`${process.env.REACT_APP_STRIPE_BASE_URL}/subscriptions/${subsID}`,
		{
			method: 'POST',
			body: generateURIBody(subscriptionData),
			mode: 'cors',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				Authorization: 'Bearer ' + process.env.REACT_APP_STRIPE_SECRET_KEY,
			},
		}
	);

	return getResponse(subscriptionCreated);
};

export const createInvoiceByCustomer = async (customerID: string) => {
	const invoice = await fetch(`${process.env.REACT_APP_STRIPE_BASE_URL}/invoices`, {
		method: 'POST',
		body: generateURIBody({
			customer: customerID,
			collection_method: 'charge_automatically',
			auto_advance: true,
		}),
		mode: 'cors',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			Authorization: 'Bearer ' + process.env.REACT_APP_STRIPE_SECRET_KEY,
		},
	});

	return getResponse(invoice);
};

export const updateSubscriptionItem = async (subscriptionItemID: string, quantity: number) => {
	try {
		const subscriptionItemUpdated = await fetch(
			`${process.env.REACT_APP_STRIPE_BASE_URL}/subscription_items/${subscriptionItemID}`,
			{
				method: 'POST',
				body: generateURIBody({ quantity }),
				mode: 'cors',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					Authorization: 'Bearer ' + process.env.REACT_APP_STRIPE_SECRET_KEY,
				},
			}
		);

		return getResponse(subscriptionItemUpdated);
	} catch (error) {
		console.log(error);
	}
};

export const getCurrentSubscriptionPlan = async (planID: string) => {
	const currentPlan = await fetch(`${process.env.REACT_APP_STRIPE_BASE_URL}/plans/${planID}`, {
		method: 'GET',
		mode: 'cors',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			Authorization: 'Bearer ' + process.env.REACT_APP_STRIPE_SECRET_KEY,
		},
	});

	return getResponse(currentPlan);
};
export const getSubscriptionStatus = async (
	subscriptionID: string
): Promise<SubscriptionResponse> => {
	const subscriptionUpdated = await fetch(
		`${process.env.REACT_APP_STRIPE_BASE_URL}/subscriptions/${subscriptionID}`,
		{
			method: 'GET',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				Authorization: 'Bearer ' + process.env.REACT_APP_STRIPE_SECRET_KEY,
			},
		}
	);

	const resp = await subscriptionUpdated.json();
	return resp;
};

const getResponse = async (value: any) => {
	const resp = await value.json();

	if (Object.prototype.hasOwnProperty.call(resp, 'error')) {
		throw new Error(resp.error.code);
	}

	return resp;
};

export const getInvoiceBySubscription = async (subscriptionID: string) => {
	const invoice = await fetch(
		`${process.env.REACT_APP_STRIPE_BASE_URL}/invoices?subscription=${subscriptionID}`,
		{
			method: 'GET',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				Authorization: 'Bearer ' + process.env.REACT_APP_STRIPE_SECRET_KEY,
			},
		}
	);

	return getResponse(invoice);
};

export const retrieveIncomingInvoiceByCustomer = async (customerID: string) => {
	const invoice = await fetch(
		`${process.env.REACT_APP_STRIPE_BASE_URL}/invoices/upcoming?customer=${customerID}`,
		{
			method: 'GET',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				Authorization: 'Bearer ' + process.env.REACT_APP_STRIPE_SECRET_KEY,
			},
		}
	);

	return getResponse(invoice);
};

export const createPaymentIntent = async (
	amount: number,
	currency: string,
	customerID: string,
	paymentMethodID: string,
	off_session: boolean = false,
	confirm: boolean = true,
	description: string = ''
) => {
	const paymentIntent = await fetch(`${process.env.REACT_APP_STRIPE_BASE_URL}/payment_intents`, {
		method: 'POST',
		body: generateURIBody({
			amount,
			currency,
			customer: customerID,
			payment_method: paymentMethodID,
			off_session,
			confirm,
			description,
		}),
		mode: 'cors',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			Authorization: 'Bearer ' + process.env.REACT_APP_STRIPE_SECRET_KEY,
		},
	});

	return getResponse(paymentIntent);
};

export const createRefund = async (paymentIntentID: string, amount: number) => {
	const refund = await fetch(`${process.env.REACT_APP_STRIPE_BASE_URL}/refunds`, {
		method: 'POST',
		body: generateURIBody({ payment_intent: paymentIntentID, amount }),
		mode: 'cors',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			Authorization: 'Bearer ' + process.env.REACT_APP_STRIPE_SECRET_KEY,
		},
	});

	return getResponse(refund);
};

export const getAllPaymentIntentsByCustomer = async (customerID: string) => {
	const paymentIntents = await fetch(
		`${process.env.REACT_APP_STRIPE_BASE_URL}/payment_intents?customer=${customerID}`,
		{
			method: 'GET',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				Authorization: 'Bearer ' + process.env.REACT_APP_STRIPE_SECRET_KEY,
			},
		}
	);

	return getResponse(paymentIntents);
};

export const CancelCustomerSubscription = async (subscriptionID: string, description: string) => {
	const subscription = await fetch(
		`${process.env.REACT_APP_STRIPE_BASE_URL}/subscriptions/${subscriptionID}`,
		{
			method: 'POST',
			body: generateURIBody({
				cancel_at_period_end: true,
				metadata: { cancel_reason: description },
			}),
			mode: 'cors',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				Authorization: 'Bearer ' + process.env.REACT_APP_STRIPE_SECRET_KEY,
			},
		}
	);

	return getResponse(subscription);
};

export const DeleteSubscription = async (subscriptionID: string, description: string) => {
	const subscription = await fetch(
		`${process.env.REACT_APP_STRIPE_BASE_URL}/subscriptions/${subscriptionID}`,
		{
			method: 'DELETE',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				Authorization: 'Bearer ' + process.env.REACT_APP_STRIPE_SECRET_KEY,
			},
		}
	);

	return getResponse(subscription);
};

export const getCustomerCards = async (customerID: string) => {
	const cards = await fetch(
		`${process.env.REACT_APP_STRIPE_BASE_URL}/customers/${customerID}/payment_methods`,
		{
			method: 'GET',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				Authorization: 'Bearer ' + process.env.REACT_APP_STRIPE_SECRET_KEY,
			},
		}
	);

	return getResponse(cards);
};

export const setDefaultPaymentMethod = async (customerID: string, paymentMethodID: string) => {
	const paymentMethod = await fetch(
		`${process.env.REACT_APP_STRIPE_BASE_URL}/customers/${customerID}`,
		{
			method: 'POST',
			body: generateURIBody({
				invoice_settings: { default_payment_method: paymentMethodID },
			}),
			mode: 'cors',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				Authorization: 'Bearer ' + process.env.REACT_APP_STRIPE_SECRET_KEY,
			},
		}
	);

	return getResponse(paymentMethod);
};

export const getDefaultPaymentMethod = async (customerID: string) => {
	const paymentMethod = await fetch(
		`${process.env.REACT_APP_STRIPE_BASE_URL}/customers/${customerID}`,
		{
			method: 'GET',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				Authorization: 'Bearer ' + process.env.REACT_APP_STRIPE_SECRET_KEY,
			},
		}
	);

	return getResponse(paymentMethod);
};
