import { FC } from 'react';
import { Event } from 'react-big-calendar';

type EventCardProps = {
	event: Event;
};

export const EventCard: FC<EventCardProps> = ({ event }) => {
	return (
		<div>
			<b>{event.resource.client.name}:</b>
			<span>{event.title}</span>
		</div>
	);
};
