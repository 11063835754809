import React, { FC } from 'react';
import { HospitalLogoFrame, HospitalLogoImg } from 'shared/style';
import HospitalDefaultLogo from '../svg/HospitalDefaultLogo';
import useMobile from 'hooks/useMobile';
interface Props {
	image?: string | null;
}

const HospitalLogo: FC<Props> = ({ image }) => {
	const { isMobile } = useMobile();

	const imagePrinter = () => {
		if (!image) {
			return <HospitalDefaultLogo />;
		}

		return (
			<HospitalLogoFrame>
				<HospitalLogoImg src={image} alt="logo" className="logo" />
			</HospitalLogoFrame>
		);
	};

	return isMobile ? <div className="image-printer-mobile">{imagePrinter()}</div> : imagePrinter();
};

export default HospitalLogo;
