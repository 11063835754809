import React from 'react';
import { Select, SelectProps, styled as StyledMaterial } from '@material-ui/core';
import styled from 'styled-components';

interface HistoryDropdownProps<TOption> extends React.HTMLProps<SelectProps> {
	value: string;
	options: TOption[];
	optionRender: (option: TOption) => React.ReactNode;
	renderValue?: ((value: unknown) => React.ReactNode) | undefined;
}

const StyledButton = styled.button`
	background: #f5f5f5;
	height: 36px;
	border-radius: 0px 4px 4px 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
		0px 3px 1px -2px rgba(0, 0, 0, 0.2);
`;

const StyledContainer = styled.div`
	display: grid;
	grid-template-columns: 80% 20%;
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 0;
`;

const StyledSelect = StyledMaterial(Select)({
	height: 36,
	borderRadius: '4px 0px 0px 4px',
	padding: '0px 16px',
	boxShadow:
		'0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)',
	background: '#E0E0E0',
	fontWeight: 500,
	textTransform: 'uppercase',
	'&.root': {
		borderBottom: 'none !important',
	},
	'.underline': {
		'&:before': {
			content: '""',
			borderBottom: 'none !important',
		},
	},
});

const OpenIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
		<path
			d="M5.83301 7.91675L9.99967 12.0834L14.1663 7.91675H5.83301Z"
			fill="black"
			fill-opacity="0.87"
		/>
	</svg>
);

const HistoryDropdown: <TOption>(props: HistoryDropdownProps<TOption>) => JSX.Element = (props) => {
	const { value, onChange, options, optionRender, renderValue, defaultValue } = props;
	const [open, setOpen] = React.useState(false);

	const onClickSelect = () => {
		setOpen(!open);
	};

	return (
		<StyledContainer>
			<StyledSelect
				disableUnderline
				renderValue={renderValue}
				autoFocus={false}
				onClick={onClickSelect}
				defaultValue={defaultValue}
				open={open}
				MenuProps={{
					PaperProps: {
						style: {
							width: '100%',
						},
					},
				}}
				id="grouped-native-select"
				value={value}
				onChange={onChange}
				IconComponent={() => null}
			>
				{options.map(optionRender)}
			</StyledSelect>
			<StyledButton onClick={onClickSelect}>
				<OpenIcon />
			</StyledButton>
		</StyledContainer>
	);
};

export default HistoryDropdown;
