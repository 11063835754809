// @ts-nocheck
import moment from 'moment';
import React, { FC } from 'react';
import { PrescriptionTypes } from 'models';
import CustomCard from '../../card/CustomCard';
import { SimpleParagraph } from './styles';

interface medicineOrLab {
	id: string | number;
	name: string;
	selectedMeasure?: string;
	take?: string;
	indication?: string;
	duration?: string;
	description: string;
}

interface Prescription {
	id: number | string;
	type: string;
	title: string | React.ReactNode;
	creationDate: string;
	list: medicineOrLab[];
	headerAction: React.ReactNode;
}

const PrescriptionCard: FC<Prescription> = (props) => {
	return (
		<CustomCard
			title={props.title}
			divider={false}
			subheader={<p>{moment(props.creationDate).format('DD/MM/YYYY')}</p>}
			headerAction={props.headerAction}
			hasMarginBottom={true}
		>
			{props.list.map((option) => {
				const takeDescription =
					props.type === PrescriptionTypes.MEDICINE && option.take
						? `tomar ${option.take}, `
						: '';
				const indicationDescription =
					props.type === PrescriptionTypes.MEDICINE && option.indication
						? `${option.indication}, `
						: '';
				const durationDescription =
					props.type === PrescriptionTypes.MEDICINE && option.duration
						? `por ${option.duration.trim()}. `
						: '';

				return (
					<SimpleParagraph key={option.id}>
						<strong>
							{option.name || ''}
							{option.selectedMeasure && `, ${option.selectedMeasure}`}
							{(takeDescription || indicationDescription || durationDescription) &&
								`, ${takeDescription}${indicationDescription}${durationDescription}`}
						</strong>
						{option.name ? <>&nbsp;</> : ''}
						{option.description}
					</SimpleParagraph>
				);
			})}
		</CustomCard>
	);
};

export default PrescriptionCard;
