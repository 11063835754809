import { Button, Grid, IconButton, List } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import React, { FC, useContext } from 'react';
import { ButtonContainer, EmptyStateText, HistoryAlergyContainer } from '../../../../shared/style';
import CustomCard from '../../../card/CustomCard';
import { HistoryContext, HistoryContextProps } from '../../../../context/history-context';
import { HistorySidebarOptions } from '../../../../context/types';
import { AllergyStatus } from 'models';
import ResumenListItem from './ResumenListItem';
import AllergyCardSkeleton from './historySkeletons/AllergyCard.skeleton';
import useMobile from 'hooks/useMobile';

interface Props {
	handleOpenAllergyForm: () => void;
	allergies: any;
	loading: boolean;
}

const AllergyCard: FC<Props> = ({ handleOpenAllergyForm, allergies, loading }) => {
	const { setCurrentSidebarTab } = useContext(HistoryContext) as HistoryContextProps;
	const { isMobile } = useMobile();
	const hasAllergies = allergies && allergies.length > 0;

	const latsFiveAllergies = allergies && allergies.slice(0, 5);
	const sortedLastFiveAllergies = latsFiveAllergies
		? [
				...latsFiveAllergies.filter((d: any) => d.status === AllergyStatus.ACTIVE),
				...latsFiveAllergies.filter((d: any) => d.status !== AllergyStatus.ACTIVE),
		  ]
		: [];

	return (
		<HistoryAlergyContainer>
			<CustomCard
				title="Alergias"
				divider
				paddingBottom={isMobile ? '20px' : '0px'}
				paddingTop={hasAllergies ? '0px' : '16px'}
				headerAction={
					hasAllergies && (
						<IconButton
							aria-label="edit"
							onClick={() => setCurrentSidebarTab(HistorySidebarOptions.ALERGIES)}
						>
							<EditIcon />
						</IconButton>
					)
				}
			>
				<Grid container direction="column">
					{hasAllergies ? (
						<>
							<List
								dense
								disablePadding
								component="nav"
								aria-label="secondary mailbox folder"
							>
								{sortedLastFiveAllergies.map((allergy, index) => {
									return (
										<ResumenListItem
											key={allergy.id}
											name={allergy.name}
											status={allergy.status as AllergyStatus}
											showDivider={
												index !== sortedLastFiveAllergies.length - 1
											}
										/>
									);
								})}
							</List>
							{allergies && allergies.length > 5 && (
								<ButtonContainer>
									<Button
										variant="text"
										color="primary"
										size="small"
										onClick={() =>
											setCurrentSidebarTab(HistorySidebarOptions.ALERGIES)
										}
									>
										Ver todas (+{allergies?.length - 5})
									</Button>
								</ButtonContainer>
							)}
						</>
					) : loading ? (
						<AllergyCardSkeleton />
					) : (
						<>
							<EmptyStateText variant="body2">
								No hay alergias agregadas
							</EmptyStateText>
						</>
					)}
					{allergies?.length === 0 ? (
						<ButtonContainer>
							<Button
								startIcon={<AddIcon />}
								variant="text"
								color="primary"
								size="small"
								onClick={handleOpenAllergyForm}
							>
								Añadir Alergia
							</Button>
						</ButtonContainer>
					) : null}
				</Grid>
			</CustomCard>
		</HistoryAlergyContainer>
	);
};

export default AllergyCard;
