import { StyledAutocomplete } from './styles';
import React from 'react';
import { Option } from './type';
import { withStyles, TextField } from '@material-ui/core';

const StyledCustomAutocomplete = withStyles({
	root: {
		paddingTop: '1px',
	},
	option: {
		padding: '3px 16px',
		position: 'relative',
		width: '100%',
		display: 'inline-block',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
	listbox: {
		overflowX: 'hidden',
	},
})(StyledAutocomplete);
const StyledTextField = withStyles({
	root: {
		margin: 0,
		'& .MuiOutlinedInput-input::placeholder': {
			color: '#666',
			opacity: 1,
		},
	},
})(TextField);

interface Props {
	id: string;
	inputValue?: string;
	value?: any;
	error?: boolean;
	disabled?: boolean;
	onChange?: (event: any, newValue: any) => void;
	onInputChange?: (event: any, newInputValue: string, reason?: string) => void;
	options?: Option[] | undefined | any;
	renderOption?: (options: any, { selected }: any) => void;
	filterOptions?: (options: any, state: any) => void;
	disableCloseOnSelect?: boolean;
	label?: string;
	getOptionLabel?: (option: any) => string;
	renderTags?: () => void;
	margin?: 'dense' | 'none' | 'normal';
	variant?: 'filled' | 'outlined' | 'standard';
	size?: 'medium' | 'small';
	placeholder?: string;
	startAdornment?: React.ReactNode;
	textMask?: any;
	helperText?: React.ReactNode | string;
	InputLabelProps?: any;
	loading?: boolean;
	customStyles?: React.CSSProperties;
	textFieldStyles?: React.CSSProperties;
}

const SearchAutocomplete: React.FC<Props> = ({
	id,
	inputValue = '',
	filterOptions,
	value = '',
	onChange,
	onInputChange,
	disabled,
	options,
	renderOption,
	label = '',
	getOptionLabel,
	renderTags,
	error,
	margin = 'normal',
	variant = 'outlined',
	disableCloseOnSelect = false,
	placeholder,
	size = 'small',
	startAdornment,
	textMask,
	helperText,
	InputLabelProps,
	loading,
	customStyles,
	textFieldStyles,
}) => {
	return (
		<StyledCustomAutocomplete
			id={id}
			freeSolo={true}
			value={value}
			onChange={onChange}
			filterOptions={filterOptions}
			disableCloseOnSelect={disableCloseOnSelect}
			inputValue={inputValue}
			disabled={disabled}
			onInputChange={onInputChange}
			options={options}
			size={size}
			loading={loading}
			renderTags={renderTags}
			style={{ width: '100%', height: '36px', ...customStyles }}
			renderOption={renderOption}
			getOptionLabel={getOptionLabel}
			loadingText="Cargando..."
			renderInput={(params: any) => (
				<StyledTextField
					{...params}
					label={label}
					margin={margin}
					error={error}
					size={size}
					helperText={helperText}
					variant={variant}
					placeholder={placeholder}
					style={{ width: '100%', ...textFieldStyles }}
					InputLabelProps={InputLabelProps}
					InputProps={{
						...params.InputProps,
						startAdornment: startAdornment,
						inputComponent: textMask,
						endAdornment: null,
						className: null,
						style: {
							height: '36px',
						},
					}}
				/>
			)}
			data-fx-name="inputAutocompleteSearh"
		/>
	);
};

export default SearchAutocomplete;
