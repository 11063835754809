import React, { useState, useContext, useEffect } from 'react';
import { SummaryContainer, CardContainer } from './PatientSummary.style';
import SummaryAdultPatientInformation from './SummaryAdultPatientInformation';
import SummaryConsultation from './SummaryConsultation';
import SummaryNotes from './SummaryNotes';
import { useHistory } from 'react-router-dom';
import { ClientsContext } from '../../../context/client-context';
import {
	ClientContextProps,
	ConsultationType,
	RelationsContextProps,
} from '../../../context/types';
import PreviousConsultationModal from './PreviousConsultationModal';
// import SummaryClinicInformation from './SummaryClinicInformation';
import ModalPatient from '../../modalClient/ModalPatient';
import SummaryVitalSign from './SummaryVitalSign';
import { RelationsContext } from '../../../context/relations-context';
import SummaryUnderAgePatientInformation from './SummaryUnderAgePatientInformation';
import { SummaryNextAppointments } from './SummaryNextAppointments';
import useMobile from 'hooks/useMobile';

const PatientSummary: React.FC = () => {
	const history = useHistory();
	const { selectedClient, updateClientDoctorNote, getConsultationDataById } = useContext(
		ClientsContext
	) as ClientContextProps;
	const { actualSecretary } = useContext(RelationsContext) as RelationsContextProps;
	const path = history.location.pathname.replace('/summary', '');
	const [consultationCard, setConsultationCard] = useState<null | ConsultationType>(null);
	const [openConsultation, setOpenConsultation] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<EventTarget | null>(null);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [isUpdate, setIsUpdate] = useState(true);
	const [openEditPatient, setOpenEditPatient] = useState(false);
	const [clientDoctorNote, setClientDoctorNote] = useState<string>('');
	const { isMobile } = useMobile();
	useEffect(() => {
		setClientDoctorNote(selectedClient?.note ? selectedClient.note : '');
	}, [selectedClient]);

	const handleEditPatient = () => {
		setOpenEditPatient(true);
		setIsUpdate(true);
	};

	const handleSeeAllConsultation = () => {
		history.push(`${path}/consultation`);
	};

	const handleDetailEnterConsultation = async (
		e: React.MouseEvent<HTMLButtonElement>,
		consultation: ConsultationType
	) => {
		const consultationData = await getConsultationDataById(consultation.id);
		setConsultationCard(consultationData);
		setOpenConsultation(!!consultationData);

		if (e.target) {
			setAnchorEl(e.target);
		}
	};

	const handleDetailLeaveConsultation = () => {
		setConsultationCard(null);
		setOpenConsultation(false);
		setAnchorEl(null);
	};

	const handleNoteChange = (value: string) => {
		setClientDoctorNote(value);
	};

	const handleNoteOnBlur = async (
		e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>
	) => {
		if (e.target) {
			await updateClientDoctorNote(selectedClient?.id as string, clientDoctorNote);
		}
	};

	const previousId = openConsultation ? 'mouse-over-popover' : undefined;

	return (
		<>
			<SummaryContainer container justifyContent="space-between" className="patient-summary">
				{isMobile && <h1 className="mobile-patient-detail-title">Resumen</h1>}
				<CardContainer
					item
					xs={12}
					lg={3}
					style={{
						marginBottom: isMobile ? '20px' : '',
					}}
				>
					<SummaryNotes
						clientDoctorNote={clientDoctorNote}
						handleNoteChange={handleNoteChange}
						handleNoteOnBlur={handleNoteOnBlur}
					/>
					<SummaryVitalSign
						consultation={
							selectedClient &&
							selectedClient.consultations &&
							selectedClient.consultations.length > 0
								? selectedClient.consultations[0]
								: ({} as ConsultationType)
						}
					/>
				</CardContainer>
				<CardContainer
					item
					xs={12}
					lg={6}
					style={{
						marginBottom: isMobile ? '20px' : '',
					}}
				>
					{!actualSecretary && (
						<SummaryConsultation
							handleSeeAllConsultation={handleSeeAllConsultation}
							consultations={
								selectedClient &&
								selectedClient.consultations &&
								selectedClient.consultations.length > 0
									? selectedClient.consultations.slice(0, 10)
									: ([] as ConsultationType[])
							}
							handleDetailEnterConsultation={handleDetailEnterConsultation}
							previousId={previousId}
						/>
					)}
					{openConsultation && consultationCard && (
						<PreviousConsultationModal
							openConsultation={openConsultation}
							anchorEl={anchorEl}
							handleDetailLeaveConsultation={handleDetailLeaveConsultation}
							consultationCard={consultationCard}
							previousId={previousId}
						/>
					)}
					{selectedClient && selectedClient.isAdult ? (
						<SummaryAdultPatientInformation handleEditPatient={handleEditPatient} />
					) : (
						<SummaryUnderAgePatientInformation handleEditPatient={handleEditPatient} />
					)}
				</CardContainer>
				<CardContainer item xs={12} lg={3}>
					{/* <SummaryClinicInformation /> */}
					<SummaryNextAppointments />
				</CardContainer>
			</SummaryContainer>
			{openEditPatient && (
				<ModalPatient
					isUpdate={!!selectedClient}
					modalState={openEditPatient}
					setModalState={setOpenEditPatient}
				/>
			)}
		</>
	);
};

export default PatientSummary;
