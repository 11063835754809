import React, { FC, useRef } from 'react';
import {
	AIReportContainer,
	AIReportDescription,
	AIReportTitle,
	AISection,
	AiReportDescriptionList,
	PrintButton,
	PrintContainer,
} from './styles';
import PrintAIIcon from 'components/svg/PrintAIIcon';
import ReactToPrint from 'react-to-print';
import AIReportDescriptionListElements from './AIReportDescriptionListElements';

interface props {
	descripcion: string;
	study: string;
	treatment: string;
	medicines: string;
	diagnostics: string;
}

const AIReport: FC<props> = ({ descripcion, study, treatment, medicines, diagnostics }) => {
	const claimRef = useRef(null);

	return (
		<AIReportContainer ref={claimRef}>
			<AISection>
				<AIReportTitle className="flex flex-col justify-center">
					Plan Médico del paciente
				</AIReportTitle>
				<ReactToPrint
					trigger={() => {
						return (
							<PrintContainer>
								<PrintAIIcon />
								<PrintButton>imprimir</PrintButton>
							</PrintContainer>
						);
					}}
					content={() => claimRef.current}
				/>
			</AISection>
			<AIReportDescription className="mt-1">{descripcion}</AIReportDescription>
			<AIReportTitle>Diagnósticos presuntivos</AIReportTitle>
			<AIReportDescription>
				<AiReportDescriptionList>
					<AIReportDescriptionListElements data={diagnostics} />
				</AiReportDescriptionList>
			</AIReportDescription>
			<AIReportTitle>Estudios</AIReportTitle>
			<AIReportDescription>
				<AiReportDescriptionList>
					<AIReportDescriptionListElements data={study} />
				</AiReportDescriptionList>
			</AIReportDescription>
			<AIReportTitle>Posibles medicamentos</AIReportTitle>
			<AIReportDescription>
				<AiReportDescriptionList>
					<AIReportDescriptionListElements data={medicines} />
				</AiReportDescriptionList>
			</AIReportDescription>
			<AIReportTitle>Tratamiento médico</AIReportTitle>
			<AIReportDescription>
				<AiReportDescriptionList>
					<AIReportDescriptionListElements data={treatment} />
				</AiReportDescriptionList>
			</AIReportDescription>
		</AIReportContainer>
	);
};

export default AIReport;
