import React, { useState, useContext } from 'react';
import { HistorySidebarOptions, RelationsContextProps } from './types';
import { RelationsContext } from './relations-context';
import {
	ClientAllergy,
	ClientDisease,
	ClientFamilyHistory,
	ClientHospitalization,
	ClientLifeStyle,
	ClientProcedure,
	Status,
} from 'models';
import { AllergyStatus } from 'models';
import useAPI from 'hooks/API';
import {
	syncClientAllergies,
	syncClientDiseases,
	syncClientFamilyHistories,
	syncClientHospitalizations,
	syncClientLifeStyles,
	syncClientProcedures,
} from 'graphql/queries';
import {
	createClientAllergy,
	createClientDisease,
	createClientFamilyHistory,
	createClientHospitalization,
	createClientLifeStyle,
	createClientProcedure,
	updateClientAllergy,
	updateClientDisease,
	updateClientFamilyHistory,
	updateClientHospitalization,
	updateClientLifeStyle,
	updateClientProcedure,
} from 'graphql/mutations';

export interface HistoryContextProps {
	currentSidebarTab: HistorySidebarOptions;
	setCurrentSidebarTab: (value: HistorySidebarOptions) => void;
	createPatientDisease: (disease: ClientDisease) => Promise<ClientDisease | void>;
	updatePatientDisease: (disease: ClientDisease) => Promise<ClientDisease | void>;
	deletePatientDisease: (id: string) => Promise<void>;
	createPatientAllergy: (allergy: ClientAllergy) => Promise<ClientAllergy | void>;
	updatePatientAllergy: (allergy: ClientAllergy) => Promise<ClientAllergy | void>;
	deletePatientAllergy: (id: string) => Promise<void>;
	createPatientProcedure: (procedure: ClientProcedure) => Promise<ClientProcedure | void>;
	updatePatientProcedure: (procedure: ClientProcedure) => Promise<ClientProcedure | void>;
	deletePatientProcedure: (id: string) => Promise<void>;
	createPatientHospitalization: (
		hospitalization: ClientHospitalization
	) => Promise<ClientHospitalization | void>;
	updatePatientHospitalization: (
		hospitalization: ClientHospitalization
	) => Promise<ClientHospitalization | void>;
	deletePatientHospitalization: (id: string) => Promise<void>;
	createPatientFamilyHistory: (
		familyHistory: ClientFamilyHistory
	) => Promise<ClientFamilyHistory | void>;
	updatePatientFamilyHistory: (
		familyHistory: ClientFamilyHistory
	) => Promise<ClientFamilyHistory | void>;
	createPatientLifeStyle: (lifeStyle: ClientLifeStyle) => Promise<ClientLifeStyle | void>;
	updatePatientLifeStyle: (lifeStyle: ClientLifeStyle) => Promise<ClientLifeStyle | void>;
	fetchAlergyByClientId: (id: string) => Promise<ClientAllergy[]>;
	fetchDiseaseByClientId: (id: string) => Promise<ClientDisease[]>;
	fetchProcedureByClientId: (id: string) => Promise<ClientProcedure[]>;
	fetchHospitalizationByClientId: (id: string) => Promise<ClientHospitalization[]>;
	fetchFamilyHistoryByClientId: (id: string) => Promise<ClientFamilyHistory[]>;
	fetchLifeStyleByClientId: (id: string) => Promise<ClientLifeStyle[]>;
}

export const HistoryContext = React.createContext<Partial<HistoryContextProps>>({});

const HistoryProvider: React.FC = (props) => {
	const { handleError } = useContext(RelationsContext) as RelationsContextProps;
	const [currentSidebarTab, setCurrentSidebarTab] = useState<HistorySidebarOptions>(
		HistorySidebarOptions.RESUMEN
	);
	const { execute } = useAPI();

	// Get Client Allergies
	const fetchAlergyByClientId = async (id: string) => {
		try {
			const allergyResponse = await execute(syncClientAllergies, {
				filter: {
					clientID: {
						eq: id,
					},
					status: {
						ne: AllergyStatus.INACTIVE,
					},
				},
			});

			const allergies = allergyResponse.data.syncClientAllergies.items;

			return allergies;
		} catch (error) {
			handleError(error, false);
			return [];
		}
	};
	// Get Client Diseases
	const fetchDiseaseByClientId = async (id: string) => {
		try {
			const diseasesResponse = await execute(syncClientDiseases, {
				filter: {
					clientID: {
						eq: id,
					},
					status: {
						ne: AllergyStatus.INACTIVE,
					},
				},
			});

			const diseases = diseasesResponse.data.syncClientDiseases.items;

			return diseases;
		} catch (error) {
			handleError(error, false);

			return [];
		}
	};
	// Get Client Procedures
	const fetchProcedureByClientId = async (id: string) => {
		try {
			const proceduresResponse = await execute(syncClientProcedures, {
				filter: {
					clientID: {
						eq: id,
					},
					status: {
						ne: AllergyStatus.INACTIVE,
					},
				},
			});
			const procedures = proceduresResponse.data.syncClientProcedures.items;
			return procedures;
		} catch (error) {
			handleError(error, false);

			return [];
		}
	};
	// Get Client Hospitalizations
	const fetchHospitalizationByClientId = async (id: string) => {
		try {
			const hospitalizationsResponse = await execute(syncClientHospitalizations, {
				filter: {
					clientID: {
						eq: id,
					},
					status: {
						ne: Status.INACTIVE,
					},
				},
			});

			const hospitalizations = hospitalizationsResponse.data.syncClientHospitalizations.items;

			return hospitalizations;
		} catch (error) {}
	};
	// Get client Family History
	const fetchFamilyHistoryByClientId = async (id: string) => {
		try {
			const familyHistoryResponse = await execute(syncClientFamilyHistories, {
				filter: {
					clientID: {
						eq: id,
					},
				},
			});
			const familyHistory = familyHistoryResponse.data.syncClientFamilyHistories.items;

			return familyHistory;
		} catch (error) {
			handleError(error, false);
			return [];
		}
	};
	// Get client Life Style
	const fetchLifeStyleByClientId = async (id: string) => {
		try {
			const lifeStyleResponse = await execute(syncClientLifeStyles, {
				filter: {
					clientID: {
						eq: id,
					},
				},
			});
			const lifeStyle = lifeStyleResponse.data.syncClientLifeStyles.items;

			return lifeStyle;
		} catch (error) {
			handleError(error, true);
			return [];
		}
	};

	// Allergy
	const createPatientAllergy = async (allergy: ClientAllergy): Promise<ClientAllergy | void> => {
		try {
			const newAllergyResponse = await execute(createClientAllergy, {
				input: {
					clientID: allergy.clientID,
					name: allergy.name,
					status: allergy.status,
					note: allergy.note,
					diagnosedDate: allergy.diagnosedDate,
				},
			});
			const newAllergy = newAllergyResponse.data.createClientAllergy;
			return newAllergy;
		} catch (error) {
			handleError(error, false);
		}
	};

	const updatePatientAllergy = async (allergy: ClientAllergy): Promise<ClientAllergy | void> => {
		try {
			const currentClientAllergyResponse = await execute(syncClientAllergies, {
				filter: {
					id: {
						eq: allergy.id,
					},
				},
			});

			const currentClientAllergy =
				currentClientAllergyResponse.data.syncClientAllergies.items[0];

			if (!currentClientAllergy) {
				handleError('currentClientAllergy not found', false);
				return;
			}

			const updatedClientDiseaseResponse = await execute(updateClientAllergy, {
				input: {
					id: currentClientAllergy.id,
					clientID: currentClientAllergy.clientID,
					name: allergy.name,
					status: allergy.status,
					note: allergy.note,
					diagnosedDate: allergy.diagnosedDate,
					_version: currentClientAllergy._version,
				},
			});

			const updatedClientDisease = updatedClientDiseaseResponse.data.updateClientAllergy;
			return updatedClientDisease;
		} catch (error) {
			handleError(error, false);
		}
	};

	const deletePatientAllergy = async (id: string) => {
		try {
			const currentClientAllergyResponse = await execute(syncClientAllergies, {
				filter: {
					id: {
						eq: id,
					},
				},
			});

			const currentClientAllergy =
				currentClientAllergyResponse.data.syncClientAllergies.items[0];

			if (!currentClientAllergy) {
				handleError('currentClientAllergy not found', false);
				return;
			}

			await execute(updateClientAllergy, {
				input: {
					id: currentClientAllergy.id,
					status: AllergyStatus.INACTIVE,
					_version: currentClientAllergy._version,
				},
			});
		} catch (error) {
			handleError(error, false);
		}
	};

	// Disease
	const createPatientDisease = async (disease: ClientDisease): Promise<ClientDisease | void> => {
		try {
			const newDiseaseResponse = await execute(createClientDisease, {
				input: {
					clientID: disease.clientID,
					name: disease.name,
					status: disease.status,
					note: disease.note,
					diagnosedDate: disease.diagnosedDate,
				},
			});
			const newDisease = newDiseaseResponse.data.createClientDisease;

			return newDisease;
		} catch (error) {
			handleError(error, false);
		}
	};

	const updatePatientDisease = async (disease: ClientDisease): Promise<ClientDisease | void> => {
		try {
			const currentClientDiseaseResponse = await execute(syncClientDiseases, {
				filter: {
					id: {
						eq: disease.id,
					},
				},
			});
			const currentClientDisease =
				currentClientDiseaseResponse.data.syncClientDiseases.items[0];

			if (!currentClientDisease) {
				handleError('currentClientDisease not found', false);
				return;
			}

			const updatedClientDiseaseResponse = await execute(updateClientDisease, {
				input: {
					id: currentClientDisease.id,
					clientID: currentClientDisease.clientID,
					name: disease.name,
					status: disease.status,
					note: disease.note,
					diagnosedDate: disease.diagnosedDate,
					_version: currentClientDisease._version,
				},
			});

			const updatedClientDisease = updatedClientDiseaseResponse.data.updateClientDisease;

			return updatedClientDisease;
		} catch (error) {
			handleError(error, false);
		}
	};

	const deletePatientDisease = async (id: string) => {
		try {
			const currentClientDiseaseResponse = await execute(syncClientDiseases, {
				filter: {
					id: {
						eq: id,
					},
				},
			});
			const currentClientDisease =
				currentClientDiseaseResponse.data.syncClientDiseases.items[0];

			if (!currentClientDisease) {
				handleError('currentClientDisease not found', false);

				return;
			}

			await execute(updateClientDisease, {
				input: {
					id: currentClientDisease.id,
					status: AllergyStatus.INACTIVE,
					_version: currentClientDisease._version,
				},
			});
		} catch (error) {
			handleError(error, false);
		}
	};

	// Procedure
	const createPatientProcedure = async (
		procedure: ClientProcedure
	): Promise<ClientProcedure | void> => {
		try {
			const newProcedureResponse = await execute(createClientProcedure, {
				input: {
					clientID: procedure.clientID,
					name: procedure.name,
					status: procedure.status,
					note: procedure.note,
					realizationDate: procedure.realizationDate,
				},
			});

			const newProcedure = newProcedureResponse.data.createClientProcedure;

			return newProcedure;
		} catch (error) {
			handleError(error, false);
		}
	};

	const updatePatientProcedure = async (
		procedure: ClientProcedure
	): Promise<ClientProcedure | void> => {
		try {
			const currentClientProcedureResponse = await execute(syncClientProcedures, {
				filter: {
					id: {
						eq: procedure.id,
					},
				},
			});
			const currentClientProcedure =
				currentClientProcedureResponse.data.syncClientProcedures.items[0];

			if (!currentClientProcedure) {
				handleError('currentClientProcedure not found', false);
				return;
			}

			const updatedClientProcedureResponse = await execute(updateClientProcedure, {
				input: {
					id: currentClientProcedure.id,
					name: procedure.name,
					note: procedure.note,
					realizationDate: procedure.realizationDate,
					_version: currentClientProcedure._version,
				},
			});

			const updatedClientProcedure =
				updatedClientProcedureResponse.data.updateClientProcedure;

			return updatedClientProcedure;
		} catch (error) {
			handleError(error, false);
		}
	};

	const deletePatientProcedure = async (id: string) => {
		try {
			const currentClientProcedureResponse = await execute(syncClientProcedures, {
				filter: {
					id: {
						eq: id,
					},
				},
			});
			const currentClientProcedure =
				currentClientProcedureResponse.data.syncClientProcedures.items[0];

			if (!currentClientProcedure) {
				handleError('currentClientProcedure not found', false);
				return;
			}

			await execute(updateClientProcedure, {
				input: {
					id: currentClientProcedure.id,
					status: AllergyStatus.INACTIVE,
					_version: currentClientProcedure._version,
				},
			});
		} catch (error) {
			handleError(error, false);
		}
	};

	// Hospitalization
	const createPatientHospitalization = async (
		hospitalization: ClientHospitalization
	): Promise<ClientHospitalization | void> => {
		try {
			const newHospitalizationResponse = await execute(createClientHospitalization, {
				input: {
					clientID: hospitalization.clientID,
					name: hospitalization.name,
					note: hospitalization.note,
					date: hospitalization.date,
					status: Status.ACTIVE,
				},
			});

			const newHospitalization = newHospitalizationResponse.data.createClientHospitalization;

			return newHospitalization;
		} catch (error) {
			handleError(error, false);
		}
	};

	const updatePatientHospitalization = async (
		hospitalization: ClientHospitalization
	): Promise<ClientHospitalization | void> => {
		try {
			const currentClientHospitalizationResponse = await execute(syncClientHospitalizations, {
				filter: {
					id: {
						eq: hospitalization.id,
					},
				},
			});

			const currentClientHospitalization =
				currentClientHospitalizationResponse.data.syncClientHospitalizations.items[0];

			if (!currentClientHospitalization) {
				handleError('currentClientHospitalization not found', false);
				return;
			}

			const updatedClientHospitalizationResponse = await execute(
				updateClientHospitalization,
				{
					input: {
						id: currentClientHospitalization.id,
						name: hospitalization.name,
						note: hospitalization.note,
						date: hospitalization.date,
						_version: currentClientHospitalization._version,
					},
				}
			);

			const updatedClientHospitalization =
				updatedClientHospitalizationResponse.data.updateClientHospitalization;

			return updatedClientHospitalization;
		} catch (error) {
			handleError(error, false);
		}
	};

	const deletePatientHospitalization = async (id: string) => {
		try {
			const currentClientHospitalizationResponse = await execute(syncClientHospitalizations, {
				filter: {
					id: {
						eq: id,
					},
				},
			});
			const currentClientHospitalization =
				currentClientHospitalizationResponse.data.syncClientHospitalizations.items[0];
			if (!currentClientHospitalization) {
				handleError('currentClientHospitalization not found', false);
				return;
			}

			await execute(updateClientHospitalization, {
				input: {
					id: currentClientHospitalization.id,
					status: Status.INACTIVE,
					_version: currentClientHospitalization._version,
				},
			});
		} catch (error) {
			handleError(error, false);
		}
	};

	// FamilyHistory
	const createPatientFamilyHistory = async (
		familyHistory: ClientFamilyHistory
	): Promise<ClientFamilyHistory | void> => {
		try {
			const newFamilyHistoryResponse = await execute(createClientFamilyHistory, {
				input: {
					clientID: familyHistory.clientID,
					familyMember: familyHistory.familyMember,
					mellitusDiabetes: familyHistory.mellitusDiabetes,
					mellitusDiabetesNote: familyHistory.mellitusDiabetesNote,
					arterialHypertension: familyHistory.arterialHypertension,
					arterialHypertensionNote: familyHistory.arterialHypertensionNote,
					heartDisease: familyHistory.heartDisease,
					heartDiseaseNote: familyHistory.heartDiseaseNote,
					nephropathy: familyHistory.nephropathy,
					nephropathyNote: familyHistory.nephropathyNote,
					neuropathies: familyHistory.neuropathies,
					neuropathiesNote: familyHistory.neuropathiesNote,
					epilepsy: familyHistory.epilepsy,
					epilepsyNote: familyHistory.epilepsyNote,
					rheumatoidArthritis: familyHistory.rheumatoidArthritis,
					rheumatoidArthritisNote: familyHistory.rheumatoidArthritisNote,
					psychiatricDiseases: familyHistory.psychiatricDiseases,
					psychiatricDiseasesNote: familyHistory.psychiatricDiseasesNote,
					cancer: familyHistory.cancer,
					cancerNote: familyHistory.cancerNote,
					other: familyHistory.other,
					otherNote: familyHistory.otherNote,
				},
			});

			const newFamilyHistory = newFamilyHistoryResponse.data.createClientFamilyHistory;

			return newFamilyHistory;
		} catch (error) {
			handleError(error, false);
		}
	};

	const updatePatientFamilyHistory = async (
		familyHistory: ClientFamilyHistory
	): Promise<ClientFamilyHistory | void> => {
		try {
			const currentClientFamilyHistoryResponse = await execute(syncClientFamilyHistories, {
				filter: {
					id: {
						eq: familyHistory.id,
					},
				},
			});

			const currentClientFamilyHistory =
				currentClientFamilyHistoryResponse.data.syncClientFamilyHistories.items[0];

			if (!currentClientFamilyHistory) {
				handleError('currentClientFamilyHistory not found', false);
				return;
			}

			const updatedClientFamilyHistoryResponse = await execute(updateClientFamilyHistory, {
				input: {
					id: currentClientFamilyHistory.id,
					familyMember: familyHistory.familyMember,
					mellitusDiabetes: familyHistory.mellitusDiabetes,
					mellitusDiabetesNote: familyHistory.mellitusDiabetesNote,
					arterialHypertension: familyHistory.arterialHypertension,
					arterialHypertensionNote: familyHistory.arterialHypertensionNote,
					heartDisease: familyHistory.heartDisease,
					heartDiseaseNote: familyHistory.heartDiseaseNote,
					nephropathy: familyHistory.nephropathy,
					nephropathyNote: familyHistory.nephropathyNote,
					neuropathies: familyHistory.neuropathies,
					neuropathiesNote: familyHistory.neuropathiesNote,
					epilepsy: familyHistory.epilepsy,
					epilepsyNote: familyHistory.epilepsyNote,
					rheumatoidArthritis: familyHistory.rheumatoidArthritis,
					rheumatoidArthritisNote: familyHistory.rheumatoidArthritisNote,
					psychiatricDiseases: familyHistory.psychiatricDiseases,
					psychiatricDiseasesNote: familyHistory.psychiatricDiseasesNote,
					cancer: familyHistory.cancer,
					cancerNote: familyHistory.cancerNote,
					other: familyHistory.other,
					otherNote: familyHistory.otherNote,
					_version: currentClientFamilyHistory._version,
				},
			});

			const updatedClientFamilyHistory =
				updatedClientFamilyHistoryResponse.data.updateClientFamilyHistory;

			return updatedClientFamilyHistory;
		} catch (error) {
			handleError(error, false);
		}
	};

	// LifeStyle
	const createPatientLifeStyle = async (
		lifeStyle: ClientLifeStyle
	): Promise<ClientLifeStyle | void> => {
		try {
			const newLifeStyleResponse = await execute(createClientLifeStyle, {
				input: {
					clientID: lifeStyle.clientID,
					lifeStyle: lifeStyle.lifeStyle,
					name: lifeStyle.name,
					description: lifeStyle.description,
				},
			});

			const newLifeStyle = newLifeStyleResponse.data.createClientLifeStyle;

			return newLifeStyle;
		} catch (error) {
			handleError(error, false);
		}
	};

	const updatePatientLifeStyle = async (
		lifeStyle: ClientLifeStyle
	): Promise<ClientLifeStyle | void> => {
		try {
			const currentClientLifeStyleResponse = await execute(syncClientLifeStyles, {
				filter: {
					id: {
						eq: lifeStyle.id,
					},
				},
			});

			const currentClientLifeStyle =
				currentClientLifeStyleResponse.data.syncClientLifeStyles.items[0];

			if (!currentClientLifeStyle) {
				handleError('currentClientLifeStyle not found', false);
				return;
			}

			const updatedClientLifeStyleResponse = await execute(updateClientLifeStyle, {
				input: {
					id: currentClientLifeStyle.id,
					lifeStyle: lifeStyle.lifeStyle,
					name: lifeStyle.name,
					description: lifeStyle.description,
					_version: currentClientLifeStyle._version,
				},
			});

			const updatedClientLifeStyle =
				updatedClientLifeStyleResponse.data.updateClientLifeStyle;

			return updatedClientLifeStyle;
		} catch (error) {
			handleError(error, false);
		}
	};

	return (
		<HistoryContext.Provider
			value={{
				currentSidebarTab,
				setCurrentSidebarTab,
				createPatientDisease,
				updatePatientDisease,
				deletePatientDisease,
				createPatientAllergy,
				updatePatientAllergy,
				deletePatientAllergy,
				createPatientProcedure,
				updatePatientProcedure,
				deletePatientProcedure,
				createPatientHospitalization,
				updatePatientHospitalization,
				deletePatientHospitalization,
				createPatientFamilyHistory,
				updatePatientFamilyHistory,
				createPatientLifeStyle,
				updatePatientLifeStyle,
				fetchAlergyByClientId,
				fetchDiseaseByClientId,
				fetchProcedureByClientId,
				fetchHospitalizationByClientId,
				fetchFamilyHistoryByClientId,
				fetchLifeStyleByClientId,
			}}
		>
			{props.children}
		</HistoryContext.Provider>
	);
};

export default HistoryProvider;
