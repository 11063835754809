import React from 'react';
import SaveButton from '../saveButton/SaveButton';
import CancelButton from '../cancelButton/CancelButton';

interface SaveCancelButtonsProps {
	onSave: () => void;
	onCancel: () => void;
}

const SaveCancelButtons: React.FC<SaveCancelButtonsProps> = ({ onSave, onCancel }) => {
	return (
		<div style={{ position: 'absolute', right: '1.7%', marginTop: '-21px', zIndex: 9999 }}>
			<div className="flex flex-col">
				<SaveButton onClick={onSave} />
				<CancelButton onClick={onCancel} style={{ marginTop: '8px' }} />
			</div>
		</div>
	);
};

export default SaveCancelButtons;
