// @ts-nocheck
import React, { Dispatch, FC, SetStateAction, useEffect, useRef } from 'react';
import DescriptionForm from './DescriptionForm';
import DiagnosisForm from './DiagnosisForm';
import FormTreatment from './formTreatment/FormTreatment';
import PhysicalTestForm from './PhysicalTestForm';
import VitalSignsForm from './VitalSignsForm';
import { FormSection, DescriptionContainer, CardContainer } from './styles';
import DescriptionTreatmentForm from './DescriptionTreatmentForm';
import ProcedureForm from './ProcedureForm';
import {
	LabImages,
	Medicine,
	MedicineOption,
	MedicineConsultation,
	SelectedDiagnosis,
	SidebarOptions,
	Templates,
	FreePrescription,
} from '../../../../shared/type';
import { MedicineType } from '../../../../context/types';
import { LabImage } from 'models';
import {
	FieldValues,
	UseFormRegister,
	UseFormSetError,
	UseFormSetValue,
	UseFormWatch,
} from 'react-hook-form/dist/types';
import { VitalSigns } from 'pages/PatientConsultation/types';
import AISuggestionCard from './AISuggestionCard';
import useMobile from 'hooks/useMobile';
import NoPrescriptionAllowed from 'components/clients/prescriptions/NoPrescriptionAllowed';

interface Props {
	sidebar: { id: string; label: string }[];
	reason: string;
	setReason: (value: string) => void;
	isReasonEmpty: boolean;
	description: string;
	setDescription: (value: string) => void;
	physicalDescription: string;
	setPhysicalDescription: (value: string) => void;
	vitalsSigns: VitalSigns;
	registerVitalSigns: UseFormRegister<FieldValues>;
	watchVitalSigns: UseFormWatch<FieldValues>;
	vitalSignsErrors: FieldValues;
	setVitalSignValues: UseFormSetValue<FieldValues>;
	setVitalSignErrors: UseFormSetError<FieldValues>;
	selectedDiagnosis: SelectedDiagnosis[];
	selectedDiffDiagnosis: SelectedDiagnosis[];
	handleDeleteDiagnosis: (id: number | string) => void;
	handleDeleteDiffDiagnosis: (id: number | string) => void;
	diagnosisInputValue: string;
	setDiagnosisInputValue: (id: string) => void;
	diffDiagnosisInputValue: string;
	setDiffDiagnosisInputValue: (id: string) => void;
	selectedDiffDiagnosisValue: string | null;
	setSelectedDiffDiagnosisValue: (id: string | null) => void;
	handleAddDiagnosist: () => void;
	handleClickAddDiagnosist: (option: string | null) => void;
	handleClickAddDiffDiagnosist: (option: string | null) => void;
	treatmentDescription: string;
	setTreatmentDescription: (treat: string) => void;
	procedure: string;
	setProcedure: (proc: string) => void;
	procedureDescription: string;
	setProcedureDescription: (procD: string) => void;
	headerMenu: null | HTMLElement;
	setHeaderMenu: (value: null | HTMLElement) => void;
	medicines: Medicine[];
	setMedicines: Dispatch<SetStateAction<Medicine[]>>;
	labImages: LabImages[];
	setLabImages: Dispatch<SetStateAction<LabImages[]>>;
	freePrescription: FreePrescription[];
	setFreePrescription: Dispatch<SetStateAction<FreePrescription[]>>;
	isMedicine: boolean;
	setIsMedicine: (value: boolean) => void;
	sidebarMedicines: SidebarOptions[];
	setSideBarMedicines: (value: SidebarOptions[]) => void;
	searchValue: SidebarOptions;
	setSearchValue: (value: SidebarOptions) => void;
	searchMedicines: SidebarOptions[];
	setSearchMedicines: (value: SidebarOptions[]) => void;
	searchLabImages: SidebarOptions[];
	setSearchLabImages: (value: SidebarOptions[]) => void;
	selectedMedicine: MedicineType;
	setSelectedMedicine: (value: MedicineType) => void;
	medicine: MedicineOption;
	setMedicine: Dispatch<SetStateAction<MedicineOption>>;
	sidebarLabs: SidebarOptions[];
	setSideLabs: (value: SidebarOptions[]) => void;
	medicalTemplates: Templates[];
	setMedicalTemplates: (value: Templates[]) => void;
	openCreateTemplate: boolean;
	setOpenCreateTemplate: (value: boolean) => void;
	templateName: string;
	setTemplateName: (value: string) => void;
	labImagesTemplates: Templates[];
	setLabImagesTemplates: (value: Templates[]) => void;
	openMedicineModal?: boolean;
	medicineList: MedicineType[];
	handleAddNewMedicine: () => void;
	handleChangename: (value: string) => void;
	newMedicine: MedicineConsultation;
	isDisabledCreateMed: boolean;
	handleClickMedicine: (value: MedicineType) => void;
	handleCreateMedicine: (isFreePrescription: boolean) => void;
	deleteMedicineChip: (value: number | string) => void;
	handleMedicineChip: (value: number | string) => void;
	handleMedicineBlur: (e: React.FocusEvent<HTMLInputElement>, value: number | string) => void;
	handleMedicineChangeUnit: (
		e: React.ChangeEvent<HTMLInputElement>,
		value: number | string
	) => void;
	handleCloseNewMedicineModal: () => void;
	openLabImageModal: boolean;
	isDisabledCreateLab: boolean;
	labImagesList: LabImage[];
	handleCloseLabImageModal: () => void;
	newLabImage: LabImage;
	handleClickLabImage: (value: LabImage) => void;
	handleChangenameLabImage: (value: string) => void;
	handleCreateLabImage: (isFreePrescription: boolean) => void;
	handleAddNewLabImage: () => void;
	selectedDiagnosisValue: string | null;
	medicioneSuggestions: MedicineType[];
	freePrescriptionList: FreePrescription[];
	handleAddDiffDiagnosist: () => void;
}

const CreateUpdateForm: FC<Props> = ({
	sidebar,
	reason,
	setReason,
	isReasonEmpty,
	description,
	setDescription,
	physicalDescription,
	setPhysicalDescription,
	vitalsSigns,
	registerVitalSigns,
	watchVitalSigns,
	vitalSignsErrors,
	setVitalSignValues,
	setVitalSignErrors,
	selectedDiagnosis,
	selectedDiffDiagnosis,
	handleDeleteDiagnosis,
	handleDeleteDiffDiagnosis,
	diagnosisInputValue,
	setDiagnosisInputValue,
	diffDiagnosisInputValue,
	setDiffDiagnosisInputValue,
	selectedDiffDiagnosisValue,
	setSelectedDiffDiagnosisValue,
	headerMenu,
	setHeaderMenu,
	medicines,
	setMedicines,
	labImages,
	setLabImages,
	isMedicine,
	setIsMedicine,
	sidebarMedicines,
	setSideBarMedicines,
	searchValue,
	setSearchValue,
	searchMedicines,
	setSearchMedicines,
	searchLabImages,
	setSearchLabImages,
	selectedMedicine,
	setSelectedMedicine,
	medicine,
	setMedicine,
	sidebarLabs,
	setSideLabs,
	medicalTemplates,
	setMedicalTemplates,
	openCreateTemplate,
	setOpenCreateTemplate,
	templateName,
	setTemplateName,
	labImagesTemplates,
	setLabImagesTemplates,
	handleAddDiagnosist,
	handleClickAddDiagnosist,
	handleClickAddDiffDiagnosist,
	treatmentDescription,
	setTreatmentDescription,
	procedure,
	setProcedure,
	procedureDescription,
	setProcedureDescription,
	openMedicineModal,
	medicineList,
	handleAddNewMedicine,
	handleChangename,
	newMedicine,
	isDisabledCreateMed,
	handleClickMedicine,
	handleCreateMedicine,
	deleteMedicineChip,
	handleMedicineChip,
	handleMedicineBlur,
	handleMedicineChangeUnit,
	handleCloseNewMedicineModal,
	openLabImageModal,
	isDisabledCreateLab,
	labImagesList,
	handleCloseLabImageModal,
	newLabImage,
	handleClickLabImage,
	handleChangenameLabImage,
	handleCreateLabImage,
	handleAddNewLabImage,
	selectedDiagnosisValue,
	handleAddDiffDiagnosist,
	freePrescription,
	setFreePrescription,
	freePrescriptionList,
}) => {
	const refTop = useRef<null | HTMLElement>(null);
	const resToDiagnosis = useRef<null | HTMLElement>(null);
	const { isMobile } = useMobile();
	useEffect(() => {
		if (isReasonEmpty) {
			refTop.current?.scrollIntoView();
		}
	}, [isReasonEmpty]);

	const goToDiagnosis = () => {
		resToDiagnosis.current?.scrollIntoView();
	};

	return (
		<FormSection item xs={12} md={10} ref={refTop} className="!px-[16px] !md:px-0 ">
			<CardContainer container spacing={4} style={isMobile ? { marginTop: '0' } : {}}>
				<DescriptionContainer
					item
					xs={12}
					id={sidebar[0].id}
					className={isMobile && 'mobile-patient-detail-title'}
				>
					{isMobile ? 'notas del padecimiento' : sidebar[0].label}
				</DescriptionContainer>
				<DescriptionForm
					reason={reason}
					setReason={setReason}
					isReasonEmpty={isReasonEmpty}
					description={description}
					setDescription={setDescription}
				/>
			</CardContainer>
			<CardContainer container spacing={4}>
				<VitalSignsForm
					registerVitalSigns={registerVitalSigns}
					watchVitalSigns={watchVitalSigns}
					vitalSignsErrors={vitalSignsErrors}
					setVitalSignValues={setVitalSignValues}
					setVitalSignErrors={setVitalSignErrors}
					vitalsSigns={vitalsSigns}
				/>
				<DescriptionContainer
					item
					xs={12}
					id={sidebar[1].id}
					className={isMobile && 'mobile-patient-detail-title'}
				>
					{sidebar[1].label}
				</DescriptionContainer>
				<PhysicalTestForm
					physicalDescription={physicalDescription}
					setPhysicalDescription={setPhysicalDescription}
				/>
			</CardContainer>
			<CardContainer container spacing={4}>
				<AISuggestionCard
					goToDiagnosis={goToDiagnosis}
					physicTestNote={physicalDescription}
					reason={reason}
					reasonDescription={description}
					vitalsSigns={vitalsSigns}
					watchVitalSigns={watchVitalSigns}
				/>
			</CardContainer>
			<CardContainer container spacing={4}>
				<DescriptionContainer
					item
					xs={12}
					id={sidebar[2].id}
					className={isMobile && 'mobile-patient-detail-title'}
				>
					{sidebar[2].label}
				</DescriptionContainer>
				<DiagnosisForm
					ref={resToDiagnosis}
					selectedDiagnosisValue={selectedDiagnosisValue}
					handleDeleteDiagnosis={handleDeleteDiagnosis}
					handleDeleteDiffDiagnosis={handleDeleteDiffDiagnosis}
					diagnosisInputValue={diagnosisInputValue}
					selectedDiagnosis={selectedDiagnosis}
					selectedDiffDiagnosis={selectedDiffDiagnosis}
					handleClickAddDiagnosist={handleClickAddDiagnosist}
					handleClickAddDiffDiagnosist={handleClickAddDiffDiagnosist}
					setDiagnosisInputValue={setDiagnosisInputValue}
					diffDiagnosisInputValue={diffDiagnosisInputValue}
					setDiffDiagnosisInputValue={setDiffDiagnosisInputValue}
					selectedDiffDiagnosisValue={selectedDiffDiagnosisValue}
					setSelectedDiffDiagnosisValue={setSelectedDiffDiagnosisValue}
					handleAddDiagnosist={handleAddDiagnosist}
					handleAddDiffDiagnosist={handleAddDiffDiagnosist}
				/>
			</CardContainer>
			<CardContainer container spacing={4}>
				<DescriptionContainer
					item
					xs={12}
					id={sidebar[3].id}
					className={isMobile && 'mobile-patient-detail-title'}
				>
					{sidebar[3].label}
				</DescriptionContainer>
				{!isMobile ? (
					<FormTreatment
						height="calc(100dvh - 61vh)"
						headerMenu={headerMenu}
						setHeaderMenu={setHeaderMenu}
						medicines={medicines}
						setMedicines={setMedicines}
						labImages={labImages}
						setLabImages={setLabImages}
						isMedicine={isMedicine}
						setIsMedicine={setIsMedicine}
						sidebarMedicines={sidebarMedicines}
						setSideBarMedicines={setSideBarMedicines}
						searchValue={searchValue}
						setSearchValue={setSearchValue}
						searchMedicines={searchMedicines}
						setSearchMedicines={setSearchMedicines}
						searchLabImages={searchLabImages}
						setSearchLabImages={setSearchLabImages}
						selectedMedicine={selectedMedicine}
						setSelectedMedicine={setSelectedMedicine}
						medicine={medicine}
						setMedicine={setMedicine}
						sidebarLabs={sidebarLabs}
						setSideLabs={setSideLabs}
						medicalTemplates={medicalTemplates}
						setMedicalTemplates={setMedicalTemplates}
						openCreateTemplate={openCreateTemplate}
						setOpenCreateTemplate={setOpenCreateTemplate}
						templateName={templateName}
						setTemplateName={setTemplateName}
						labImagesTemplates={labImagesTemplates}
						setLabImagesTemplates={setLabImagesTemplates}
						openMedicineModal={openMedicineModal}
						medicineList={medicineList}
						freePrescriptionList={freePrescriptionList}
						handleAddNewMedicine={handleAddNewMedicine}
						handleChangename={handleChangename}
						newMedicine={newMedicine}
						isDisabledCreateMed={isDisabledCreateMed}
						handleClickMedicine={handleClickMedicine}
						handleCreateMedicine={handleCreateMedicine}
						deleteMedicineChip={deleteMedicineChip}
						handleMedicineChip={handleMedicineChip}
						handleMedicineBlur={handleMedicineBlur}
						handleMedicineChangeUnit={handleMedicineChangeUnit}
						handleCloseNewMedicineModal={handleCloseNewMedicineModal}
						openLabImageModal={openLabImageModal}
						isDisabledCreateLab={isDisabledCreateLab}
						labImagesList={labImagesList}
						handleCloseLabImageModal={handleCloseLabImageModal}
						newLabImage={newLabImage}
						handleClickLabImage={handleClickLabImage}
						handleChangenameLabImage={handleChangenameLabImage}
						handleCreateLabImage={handleCreateLabImage}
						handleAddNewLabImage={handleAddNewLabImage}
						freePrescription={freePrescription}
						setFreePrescription={setFreePrescription}
					/>
				) : (
					<NoPrescriptionAllowed />
				)}
				<DescriptionTreatmentForm
					treatmentDescription={treatmentDescription}
					setTreatmentDescription={setTreatmentDescription}
				/>
				<ProcedureForm
					procedure={procedure}
					setProcedure={setProcedure}
					procedureDescription={procedureDescription}
					setProcedureDescription={setProcedureDescription}
				/>
			</CardContainer>
		</FormSection>
	);
};

export default CreateUpdateForm;
