import React from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';
import { Grid } from '@material-ui/core';
import {
	GridEnd,
	StyledDialogContent,
	StyledDialogActions,
	StyledDialog,
} from '../../shared/style';
import useMobile from 'hooks/useMobile';
import styled from 'styled-components';

interface Props {
	open: boolean;
	handleClose: (
		event: React.MouseEvent<HTMLElement> | Record<string, unknown> | undefined,
		reason?: string
	) => void;
	maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false;
	fullWidth?: boolean;
	disabled?: boolean;
	disableBackdropClick?: boolean;
	title?: string | React.ReactNode;
	children?: React.ReactNode;
	disagreeText?: string;
	className?: string;
	agreeText?: string;
	handleAgree?: (event: React.MouseEvent<HTMLButtonElement>) => void;
	deleteText?: string;
	handleDelete?: () => void;
	cancelStyleColor?: 'secondary' | 'inherit' | 'default' | 'primary' | undefined;
	agreeTextColor?: string;
	loading?: boolean;
	agreeVariant?: 'text' | 'contained' | 'outlined';
	cancelVariant?: 'text' | 'contained' | 'outlined';
	fullScreen?: boolean;
	style?: React.CSSProperties;
	loadingText?: string;
	dataFxName?: string;
}

const CustomBtnStyled = styled(Button)<{ agreeTextColor?: string }>`
  color: ${({ agreeTextColor }) => agreeTextColor};
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "5px",
  textAlign: "center",
`;

const CustomDialog: React.FC<Props> = ({
	open,
	handleClose,
	maxWidth,
	fullWidth,
	disabled,
	disableBackdropClick = false,
	title,
	children,
	disagreeText,
	agreeText,
	handleAgree,
	deleteText,
	handleDelete,
	cancelStyleColor,
	agreeTextColor,
	loading,
	fullScreen,
	className,
	style,
	agreeVariant,
	cancelVariant,
	loadingText,
	dataFxName,
}) => {
	const { isMobile } = useMobile();
	return (
		<StyledDialog
			className={className}
			open={open}
			onClose={(event, reason) => {
				if (disableBackdropClick) {
					if (reason !== 'backdropClick') {
						handleClose(event);
					}
				} else {
					handleClose(event, reason);
				}
			}}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			maxHeight={fullWidth ? '100%' : ''}
			maxWidth={maxWidth}
			fullWidth={fullWidth}
			fullScreen={fullScreen}
			data-fx-name={dataFxName}
		>
			{title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
			{children && <StyledDialogContent>{children}</StyledDialogContent>}

			{(disagreeText || agreeText || deleteText) && (
				<StyledDialogActions>
					{deleteText && (
						<Grid item xs={6}>
							<Button
								variant="text"
								color="secondary"
								size="small"
								startIcon={<DeleteIcon />}
								onClick={handleDelete}
								data-fx-name="deleteButton"
							>
								{deleteText}
							</Button>
						</Grid>
					)}
					{(disagreeText || agreeText) && (
						<GridEnd
							item
							xs={isMobile ? 12 : !deleteText ? 12 : 6}
							style={{
								gap: isMobile ? '16px' : '',
							}}
						>
							{disagreeText && (
								<Button
									onClick={handleClose}
									color="primary"
									disabled={disabled}
									variant={
										isMobile
											? cancelVariant
												? cancelVariant
												: 'outlined'
											: 'text'
									}
									data-fx-name="disagreeButton"
								>
									{disagreeText}
								</Button>
							)}
							{agreeText && (
								<CustomBtnStyled
									variant={
										isMobile
											? agreeVariant
												? agreeVariant
												: 'contained'
											: 'text'
									}
									agreeTextColor={agreeTextColor}
									disabled={disabled || loading}
									onClick={handleAgree}
									color={cancelStyleColor ?? 'primary'}
									data-fx-name="agreeButton"
								>
									{loading ? loadingText : agreeText}
								</CustomBtnStyled>
							)}
						</GridEnd>
					)}
				</StyledDialogActions>
			)}
		</StyledDialog>
	);
};

export default CustomDialog;
