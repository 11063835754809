import React, { useState, useContext, useEffect, useRef, ChangeEvent } from 'react';
import { RouteProps, useHistory } from 'react-router-dom';
import { ClientsContext } from '../../../context/client-context';
import { ClientContextProps, ClientPrescriptionType } from '../../../context/types';

// New design
import { PrescriptionCardContainer, PrescriptionContainer, StyledPagination } from './styles';
import PrescriptionSidebarSection from './PrescriptionSidebarSection';
import PrescriptionCard from './PrescriptionCard';
import PrescriptionList from './PrescriptionList';
import CustomMenu from '../../menu/CustomMenu';
import { ClientPrescription, PrescriptionTypes } from 'models';
import { usePagination } from '../../../shared/paginationUtils';

import NoItem from '../../noItems/NoItem';
import { Box, Typography } from '@material-ui/core';
import PrescriptionsSkeleton from './Prescriptions.skeleton';
import useMobile from 'hooks/useMobile';
import ModalConfirmation from 'components/modalConfirmation/ModalConfirmation';
import { Alert } from '@material-ui/lab';
import PrescriptionHeader from './PrescriptionHeader';
import { useReactToPrint } from 'react-to-print';
import PrescriptionPrintLayout from 'printLayout/Prescription';
import moment from 'moment';
import { LabImages } from 'shared/type';
import PrescriptionPrint from './PrescriptionPrint';

type Props = RouteProps;

interface medicineOrLab {
	id: string | number;
	name: string;
	take?: string;
	indication?: string;
	duration?: string;
	description: string;
}

interface Prescription extends Omit<ClientPrescription, 'id'> {
	id: number | string;
	type: PrescriptionTypes;
	title: string | React.ReactElement<any, string | React.JSXElementConstructor<any>>;
	creationDate: string;
	list: medicineOrLab[];
}

const prescriptionFilters = [
	{ id: 1, label: 'Todos', value: 'all' },
	{ id: 2, label: 'Medicamentos', value: PrescriptionTypes.MEDICINE },
	{ id: 3, label: 'Lab/Imágenes', value: PrescriptionTypes.LABIMAGE },
	{ id: 4, label: 'Libres', value: PrescriptionTypes.FREE_PRESCRIPTION },
];

const Prescriptions: React.FC<Props> = () => {
	const printRef = useRef(null);
	const history = useHistory();
	const [printData, setPrintData] = useState<medicineOrLab[] | any>([]);

	const handlePrintHook = useReactToPrint({
		removeAfterPrint: true,
		content: () => printRef.current,
		onAfterPrint: () => setHeaderMenu(null),
	});
	// Context
	const {
		clientPrescriptions: prescriptions,
		deleteClientPrescription,
		getPrescriptionsData,
		selectedClient,
		loadingPrescriptions,
	} = useContext(ClientsContext) as ClientContextProps;
	const { isMobile } = useMobile();
	// Local states
	const [filterValue, setFilterValue] = useState(prescriptionFilters[0].value);
	const [headerMenu, setHeaderMenu] = useState<null | HTMLElement>(null);
	const [prescriptionCard, setPrescriptionCard] = useState<null | Prescription>(null);
	const [prescriptionList, setPrescriptionList] = useState<Prescription[]>([] as Prescription[]);
	const [openDeletePrescription, setOpenDeletePrescription] = useState(false);
	const LIST_PER_PAGE = 10;

	useEffect(() => {
		const getPrescriptions = async () => {
			await getPrescriptionsData();
		};
		if (!prescriptions || prescriptions.length === 0) {
			getPrescriptions();
		}
		if (prescriptions) {
			setPrescriptionList(
				prescriptions.map((p: any, i: any) =>
					getDataDependingPrescriptionType(p, i)
				) as Prescription[]
			);
		}
	}, [prescriptions]);

	const getDataDependingPrescriptionType = (p: ClientPrescriptionType, i: number) => {
		let title: React.ReactNode = <></>;
		if (p.type === PrescriptionTypes.MEDICINE) {
			title = (
				<Typography variant="h6">
					{i + 1} - Prescripción:&nbsp;
					<span style={{ color: 'rgba(0, 0, 0, 0.60)' }}>Medicamentos</span>
				</Typography>
			);
		}
		if (p.type === PrescriptionTypes.FREE_PRESCRIPTION) {
			title = (
				<Typography variant="h6">
					{i + 1} - Prescripción:&nbsp;
					<span style={{ color: 'rgba(0, 0, 0, 0.60)' }}>Libre</span>
				</Typography>
			);
		}
		if (p.type === PrescriptionTypes.LABIMAGE) {
			title = (
				<Typography variant="h6">
					{i + 1} - Prescripción:&nbsp;
					<span style={{ color: 'rgba(0, 0, 0, 0.60)' }}>Lab/Imágenes</span>
				</Typography>
			);
		}

		return {
			...p,
			creationDate: p.date as string,
			title,
		};
	};

	const prescr = prescriptions
		? (prescriptions.map((p: any, i) =>
				getDataDependingPrescriptionType(p, i)
		  ) as Prescription[])
		: [];

	const count = Math.ceil(prescriptionList.length / LIST_PER_PAGE);
	const _DATA = usePagination(prescriptionList, LIST_PER_PAGE);

	const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.value === prescriptionFilters[0].value) {
			setPrescriptionList(prescr);
		} else {
			const listFiltered = prescr.filter((pres) => pres.type === event.target.value);
			setPrescriptionList(
				listFiltered.length
					? (listFiltered.map((p, i) =>
							getDataDependingPrescriptionType(p as ClientPrescriptionType, i)
					  ) as Prescription[])
					: []
			);
		}
		setFilterValue(event.target.value);
	};

	const deletePrescription = () => {
		deleteClientPrescription(prescriptionCard as ClientPrescriptionType);
		if (
			(prescriptionList.length as number) > LIST_PER_PAGE
			// Redundant condition, as LIST_PER_PAGE is always equal to LIST_PER_PAGE
			// || LIST_PER_PAGE === LIST_PER_PAGE
		) {
			_DATA.jump(_DATA.currentPage - 1);
		}
		handleCloseMenu();
	};

	const handleClickMenu = (event: React.MouseEvent<HTMLElement>, prescription: Prescription) => {
		setHeaderMenu(event.currentTarget);
		setPrescriptionCard(prescription);
	};

	const handleCloseMenu = () => {
		setPrescriptionCard(null);
		setHeaderMenu(null);
	};

	const handleChangePage = (_e: ChangeEvent<unknown>, p: number) => {
		_DATA.jump(p);
	};

	const optionsForMenu = [
		{
			id: 1,
			label: 'Eliminar',
			onClick: () => setOpenDeletePrescription(true),
		},
	];

	if (loadingPrescriptions) {
		return <PrescriptionsSkeleton />;
	}

	const onCreatePrescription = () => {
		return history.push(`${history.location.pathname}/form`);
	};

	const formatPrintData = (data: Prescription) =>
		data &&
		data.list.map((p) => ({
			id: p.id,
			label: p.name,
			duration: p.duration,
			take: p.take,
			indication: p.indication,
			description: p.description,
			showDescription: true,
		}));
	const handlerSetData = (data: Prescription) => {
		const formattedData = formatPrintData(data);
		setPrintData(formattedData);
	};
	const handlePrintDataButton = (data: Prescription) => {
		handlerSetData(data);
		handlePrintHook();
	};
	return (
		<>
			{isMobile && (
				<Alert
					variant="filled"
					severity="info"
					style={{
						width: '100%',
					}}
				>
					Crea e imprime prescripciones en tu computadora. Pronto podrás hacerlo en tu
					celular
				</Alert>
			)}
			{openDeletePrescription && (
				<ModalConfirmation
					modalState={openDeletePrescription}
					approveFunction={() => {
						deletePrescription();
						setOpenDeletePrescription(false);
					}}
					name="¿Estás seguro de que deseas eliminar esta Prescripción?"
					closeFunction={() => setOpenDeletePrescription(false)}
				/>
			)}
			{prescriptions && prescriptions.length > 0 ? (
				<PrescriptionContainer xs={12} item>
					<PrescriptionSidebarSection
						radioList={prescriptionFilters}
						value={filterValue}
						handleChange={handleFilterChange}
					/>
					<PrescriptionList
						styles={{
							padding: '16px',
							marginBottom: '16px',
						}}
					>
						<PrescriptionHeader onClick={onCreatePrescription} />
						{_DATA.currentData().map((prescription) => {
							return (
								<PrescriptionCardContainer
									key={prescription.id}
									item
									xs={isMobile ? 12 : 10}
								>
									<PrescriptionCard
										{...prescription}
										headerAction={
											<Box style={{ display: 'flex', columnGap: '10px' }}>
												{!isMobile && (
													<PrescriptionPrint
														prescription={formatPrintData(prescription)}
														handler={handlePrintDataButton}
													/>
												)}
												<CustomMenu
													id={prescription.id}
													options={optionsForMenu}
													headerMenu={headerMenu}
													handleClick={(e) => {
														handlerSetData(prescription);
														handleClickMenu(
															e,
															prescription as Prescription
														);
													}}
													handleClose={handleCloseMenu}
												/>
											</Box>
										}
									/>
								</PrescriptionCardContainer>
							);
						})}

						{isMobile && (
							<div ref={printRef} className="printLayout">
								<PrescriptionPrintLayout
									client={selectedClient!}
									prescription={{
										creationDate: moment(new Date()).format('DD/MM/YYYY'),
										medicines: printData,
										analisis: [] as LabImages[],
									}}
								/>
							</div>
						)}
						{prescriptionList.length > LIST_PER_PAGE && (
							<StyledPagination
								page={_DATA.currentPage}
								onChange={handleChangePage}
								count={count}
								variant="outlined"
								color="primary"
								style={isMobile ? { paddingBottom: '20px' } : {}}
							/>
						)}
					</PrescriptionList>
				</PrescriptionContainer>
			) : (
				<div className="m-auto">
					<NoItem
						height="calc(100dvh - 250px)"
						title="No tienes prescripciones"
						name="prescripción"
						width="244px"
						isRow
						isFem={true}
						btnLabel={isMobile ? undefined : 'Crear Prescripción'}
						onClickHandler={onCreatePrescription}
					/>
				</div>
			)}
		</>
	);
};

export default Prescriptions;
