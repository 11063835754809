import React, { useContext, useState, useEffect } from 'react';
import { ClientsContext } from '../../../context/client-context';
import NoItem from '../../noItems/NoItem';
import DateSectionFile from './DateSectionFile';
import ModalConfirmation from '../../modalConfirmation/ModalConfirmation';
import { ClientContextProps, ClientImageType, FilesType } from '../../../context/types';
import { FormatDate, getDateToString } from 'shared/dateUtils';
import { SectionSkeleton } from './skeletons';
import MobileHeader from './MobileHeader';

interface Props {
	handleImageUpload: () => void;
}

const Files: React.FC<Props> = ({ handleImageUpload }) => {
	const {
		updateImage,
		deleteImageDateSection,
		deleteImage,
		updateImageDateSection,
		imageLoading,
		getClientFilesData,
		clientFiles,
	} = useContext(ClientsContext) as ClientContextProps;
	const [modalError, setModalError] = useState(false);
	const [modalConfirmationSection, setModalConfirmationSection] = useState(false);
	const [sectionSelected, setSectionSelected] = useState<null | FilesType>(null);
	const [modalConfirmationImg, setModalConfirmationImg] = useState(false);
	const [imgSelected, setImgSelected] = useState<null | ClientImageType>(null);

	useEffect(() => {
		const getFiles = async () => {
			await getClientFilesData();
		};
		getFiles();
	}, []);

	const deleteSectionClickHandler = (file: FilesType) => {
		setSectionSelected(file);
		setModalConfirmationSection(true);
	};
	const deleteImgClickHandler = (img: ClientImageType) => {
		setImgSelected(img);
		setModalConfirmationImg(true);
	};

	const deleteSectionHandler = async () => {
		await deleteImageDateSection(sectionSelected!.id);
		setSectionSelected(null);
		setModalConfirmationSection(false);
	};

	const deleteImageHandler = () => {
		if (!imgSelected) return;
		deleteImage(imgSelected);
		setSectionSelected(null);
		setModalConfirmationSection(false);
		setModalConfirmationImg(false);
	};

	const updateDescriptionSection = async (id: string, description: string) => {
		await updateImageDateSection(id, description);
	};

	const updateDescriptionImg = (id: string, description: string) => {
		updateImage(id, description);
	};

	const files = clientFiles?.map((file: any) => (
		<DateSectionFile
			key={file.id}
			file={file}
			deleteSection={() => deleteSectionClickHandler(file)}
			deleteImg={deleteImgClickHandler}
			updateDescriptionSection={updateDescriptionSection}
			updateDescriptionImg={updateDescriptionImg}
		/>
	));

	if (clientFiles?.length === 0 && imageLoading) {
		return (
			<div className="px-10 pt-1 pb-5">
				<SectionSkeleton />
			</div>
		);
	}
	if (
		imageLoading &&
		clientFiles?.length > 0 &&
		!clientFiles?.some(
			(file: any) =>
				file.date === getDateToString(new Date(), FormatDate['Monthname DD, YYYY'])
		)
	) {
		return (
			<div className="px-10 pt-1 pb-5">
				{files}
				<SectionSkeleton />
			</div>
		);
	}

	return (
		<div className="client-pages-container overflow-auto relative">
			{/* Modal Confirmation Section */}
			<ModalConfirmation
				approveFunction={deleteSectionHandler}
				modalState={modalConfirmationSection}
				closeFunction={() => setModalConfirmationSection(false)}
				name={`¿Estás seguro que quieres eliminar la sección de la fecha ${sectionSelected?.date}?`}
			/>
			{/* Modal Confirmation Image */}
			<ModalConfirmation
				approveFunction={deleteImageHandler}
				modalState={modalConfirmationImg}
				name={`¿Estás seguro de querer eliminar este archivo?`}
				closeFunction={() => setModalConfirmationImg(false)}
				confirmLabel="ACEPTAR"
			/>

			<ModalConfirmation
				modalState={modalError}
				hideConfirmBtn
				name={`Error al subir el archivo`}
				closeFunction={() => setModalError(false)}
			/>
			{clientFiles?.length ? (
				<div className="px-4 md:px-10 pt-4 pb-5">
					<MobileHeader />
					{files}
				</div>
			) : (
				<NoItem
					btnLabel="SUBIR ARCHIVO"
					title="No tienes archivos"
					width="244px"
					name="archivo"
					isRow
					isFem
					onClickHandler={handleImageUpload}
				/>
			)}
		</div>
	);
};

export default Files;
