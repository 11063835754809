import { Divider } from '@material-ui/core';
import { Box, Typography } from '@mui/material';
import CustomMenu from 'components/menu/CustomMenu';
import { Appointment } from 'models';
import moment from 'moment';
import { FC, useMemo, useState } from 'react';
import { IAppointment } from 'shared/type';

type AppointmentForCardProps = {
	appointment: Appointment;
	shouldShowDivider: boolean;
	handleDelete: (appointment: IAppointment) => void;
	handleEditClick: (appointment: IAppointment) => void;
};

export const AppointmentForCard: FC<AppointmentForCardProps> = ({
	appointment,
	shouldShowDivider,
	handleDelete,
	handleEditClick,
}) => {
	const date = useMemo(() => {
		const currentDate = moment(new Date());
		const appointStartDate = moment(appointment.start);

		if (currentDate.isSame(appointStartDate, 'day')) {
			return appointStartDate.format('[Hoy a las] hh:mm A');
		}

		return appointStartDate.format('dddd DD [de] MMMM, YYYY');
	}, [appointment.start]);

	const [headerMenu, setHeaderMenu] = useState<null | HTMLElement>(null);

	const optionsForMenu = [
		{
			id: 1,
			label: 'Editar',
			onClick: () => {
				handleCloseMenu();
				handleEditClick(appointment as unknown as IAppointment);
			},
		},
		{
			id: 2,
			label: 'Eliminar',
			onClick: () => {
				handleCloseMenu();
				handleDelete(appointment as unknown as IAppointment);
			},
		},
	];

	const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
		setHeaderMenu(event.currentTarget);
	};

	const handleCloseMenu = () => {
		setHeaderMenu(null);
	};

	return (
		<>
			<Box
				paddingY={'8px'}
				display={'flex'}
				gap={'4px'}
				justifyContent={'space-between'}
				alignItems={'center'}
			>
				<div>
					<Typography sx={{ fontSize: '16px', textTransform: 'capitalize' }}>
						{date}
					</Typography>
					<Typography
						variant="caption"
						sx={{
							color: 'rgba(0, 0, 0, 0.60)',
							fontSize: '14px',
						}}
					>
						{appointment.description || 'No hay descripción'}
					</Typography>
				</div>
				<Box height={'20px'} width={'20px'}>
					<CustomMenu
						options={optionsForMenu}
						headerMenu={headerMenu}
						handleClick={handleClickMenu}
						handleClose={handleCloseMenu}
					/>
				</Box>
			</Box>
			{shouldShowDivider && <Divider />}
		</>
	);
};
