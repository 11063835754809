import React from 'react';
import { TailSpin } from 'react-loader-spinner';

interface Props {
	transparencyOff?: boolean;
}

const Loader: React.FC<Props> = ({ transparencyOff = false }) => {
	return (
		<div
			className={`h-screen w-screen flex justify-center items-center absolute zIndezLoader ${
				transparencyOff ? 'bg-gallery-default' : 'loader'
			}`}
		>
			<TailSpin width="100" height="100" color="#1976D2" ariaLabel="loading" />
		</div>
	);
};

export default Loader;
