// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import { RelationsContext } from '../context/relations-context';
import LabImageRow from '../components/labImage/LabImageRow';
import { RelationsContextProps, SuggestionType } from '../context/types';
import LabImageDialog from '../components/labImageDialog/LabImageDialog';
import ListContainer from '../components/list/ListContainer';
import { CardTitle, PageContainer } from '../shared/style';
import ModalConfirmation from '../components/modalConfirmation/ModalConfirmation';
import { LabImage as LabImageType } from 'models';
import { usePagination } from '../shared/paginationUtils';
import { Pagination } from '@material-ui/lab';
import LoadingSpinner from '../components/loading/LoadingSpinner';
import { clearSuggestions, filterSuggestions } from 'shared/medicinesAndLabImagesUtils';
import { LabImage } from 'API';
import useMobile from 'hooks/useMobile';
import { NoItemButton } from '../shared/style';
import Add from '@material-ui/icons/Add';
import NoItem from 'components/noItems';

const LabImageC = () => {
	const {
		actualDoctor,
		fetchLabImages,
		fetchDoctorLabImages,
		createLabImage,
		createDoctorLabImage,
		editLabImage,
		deleteLabImage,
		labImages,
		setAlertMessage,
	} = useContext(RelationsContext) as RelationsContextProps;
	const [isUpdateLabImage, setIsUpdateLabImage] = useState(false);
	const [openLabImageModal, setOpenLabImageModal] = useState(false);
	const [labImage, setLabImage] = useState<LabImageType>({} as LabImageType);
	const [filterText, setFilterText] = useState<string>('');
	const [selectedLabImage, setSelectedLabImage] = useState<LabImageType>({} as LabImageType);
	const [labImagesFiltered, setLabImagesFiltered] = useState<LabImageType[]>(
		[] as LabImageType[]
	);
	const [isDisabledCreate, setIsDisabledCreate] = useState<boolean>(false);
	const [deleteLabImageModal, setDeleteLabImageModal] = useState<boolean>(false);
	const LIST_PER_PAGE = 15;
	const data = filterText === '' ? actualDoctor?.labImages : labImagesFiltered;
	const dataLength =
		filterText === ''
			? actualDoctor?.labImages && actualDoctor?.labImages.length
			: labImagesFiltered.length;
	const count = Math.ceil((dataLength as number) / LIST_PER_PAGE);
	const _DATA = usePagination(data as LabImageType[], LIST_PER_PAGE);
	const [isLoading, setIsLoading] = useState(false);
	const [labImagesSuggestions, setLabImagesSuggestions] = useState<LabImageType[]>([]);
	const { isMobile } = useMobile();
	const handleRequests = async () => {
		setIsLoading(true);
		await fetchLabImages();
		await fetchDoctorLabImages();
		setIsLoading(false);
	};

	useEffect(() => {
		handleRequests();
	}, []);

	useEffect(() => {
		if (filterText !== '') {
			let timeOut: any;
			if (actualDoctor!.labImages) {
				timeOut = setTimeout(() => {
					const labImagesFiltered = actualDoctor?.labImages!.filter((el) =>
						el.name?.toLowerCase().trim().includes(filterText.toLowerCase().trim())
					);
					setLabImagesFiltered(labImagesFiltered!);
				}, 500);
			}

			return () => {
				if (timeOut) clearTimeout(timeOut);
			};
		}
	}, [filterText]);

	const clearForm = () => {
		fetchLabImages();
		setOpenLabImageModal(false);
		setIsDisabledCreate(false);
		setLabImage({} as LabImageType);
		setSelectedLabImage({} as LabImageType);
		setIsUpdateLabImage(false);
	};

	const handleCreateLabImage = async () => {
		const labImageExists = actualDoctor?.labImages?.some((el) => el.name === labImage.name);
		if (labImageExists) {
			clearForm();
			setAlertMessage({
				severity: 'info',
				message: 'Ya existe un lab/Imagen con ese nombre',
			});

			return;
		}
		const createdLabImage = await createLabImage(labImage.name as string);
		await createDoctorLabImage(createdLabImage!.id);
		clearForm();
	};

	const handleUpdateLabImage = async () => {
		await editLabImage(labImage);
		clearForm();
	};

	const handleAddNewLabImage = () => {
		setIsDisabledCreate(true);
		setOpenLabImageModal(true);
		setLabImage({ name: '' } as LabImageType);
	};

	const handleChangename = (value: string) => {
		if (value !== undefined) {
			setLabImage({ ...labImage, name: value });
			if (value) {
				setIsDisabledCreate(false);
			} else {
				setIsDisabledCreate(true);
			}
		}
	};

	useEffect(() => {
		const { name } = labImage;
		const timerId = filterSuggestions(
			SuggestionType.LAB_IMAGES,
			labImages as LabImage[],
			name,
			setLabImagesSuggestions
		);

		return () => {
			clearSuggestions(timerId, setLabImagesSuggestions);
		};
	}, [labImage.name]);

	const handleClickLabImage = async (value: LabImageType) => {
		setLabImage(value);
	};

	const handleCloseLabImageModal = () => {
		setOpenLabImageModal(false);
		setIsUpdateLabImage(false);
		setLabImage({} as LabImageType);
	};

	const editLabImageHandler = (el: LabImageType) => {
		setIsUpdateLabImage(true);
		setOpenLabImageModal(true);
		setSelectedLabImage(el);
		setLabImage(el);
	};

	const deleteLabImageHandler = async (el: LabImageType) => {
		setSelectedLabImage(el);
		setDeleteLabImageModal(true);
	};

	const handleDelete = async () => {
		setDeleteLabImageModal(false);
		await deleteLabImage(selectedLabImage);
		setSelectedLabImage({} as LabImageType);
		if ((dataLength as number) > LIST_PER_PAGE || dataLength === LIST_PER_PAGE) {
			_DATA.jump(_DATA.currentPage - 1);
		}
	};

	const isLabsAvailable = _DATA.currentData() && _DATA.currentData().length > 0;
	const labImagesList = !isLabsAvailable ? (
		<NoItem
			title="No tienes Lab/imágenes en tu lista"
			isRow={isMobile}
			onClickHandler={handleAddNewLabImage}
			btnLabel={'Agregar Lab/Imagen'}
			containerStyle={{
				alignItems: 'center',
			}}
		/>
	) : (
		_DATA
			.currentData()
			.map((el) => (
				<LabImageRow
					key={el.id}
					labImage={el}
					deleteLabImageHandler={() => deleteLabImageHandler(el)}
					editLabImageHandler={() => editLabImageHandler(el)}
				/>
			))
	);

	const populatedLabImagesStyles = {
		justifyContent: 'flex-start',
	};

	return (
		<PageContainer id="medicines-labimage">
			{openLabImageModal && (
				<LabImageDialog
					isDisabled={isDisabledCreate}
					isUpdate={isUpdateLabImage}
					open={openLabImageModal}
					searchLabImages={labImagesSuggestions}
					handleClose={handleCloseLabImageModal}
					labImage={labImage}
					handleClickLabImage={handleClickLabImage}
					handleChangename={handleChangename}
					handleAgree={isUpdateLabImage ? handleUpdateLabImage : handleCreateLabImage}
				/>
			)}

			{isLoading ? (
				<LoadingSpinner />
			) : (
				<ListContainer
					className="medicines-labimage-list"
					placeholder={isMobile ? 'Buscar Lab/Imágenes' : undefined}
					title={<CardTitle>{!isMobile && 'Mis '}Lab/Imágenes</CardTitle>}
					handleAdd={isMobile ? undefined : handleAddNewLabImage}
					addTitle={isMobile ? undefined : 'Crear Lab/Imagen'}
					filterText={filterText}
					setFilterText={setFilterText}
					isFooter={(dataLength as number) > LIST_PER_PAGE}
					footer={
						<Pagination
							page={_DATA.currentPage}
							onChange={(_e: any, number: number) => {
								_DATA.jump(number);
							}}
							count={count}
							variant="outlined"
							color="primary"
						/>
					}
					contentStyle={{
						display: 'flex',
						flexDirection: 'column',
						maxHeight: '89%',
						...(isLabsAvailable ? populatedLabImagesStyles : {}),
					}}
				>
					{isMobile && _DATA.currentData().length > 0 && (
						<div className="flex py-[20px] justify-center">
							<NoItemButton
								variant="contained"
								color="primary"
								size="small"
								onClick={handleAddNewLabImage}
							>
								<Add style={{ marginRight: 8 }} /> agregar lab/imágenes
							</NoItemButton>
						</div>
					)}
					{labImagesList}
				</ListContainer>
			)}

			<ModalConfirmation
				className="modal-deletion"
				modalState={deleteLabImageModal}
				approveFunction={handleDelete}
				name={`¿Estás seguro de borrar el lab/Imagen ${selectedLabImage.name}?`}
				closeFunction={() => setDeleteLabImageModal(false)}
			/>
		</PageContainer>
	);
};

export default LabImageC;
