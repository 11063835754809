import React, { useEffect, useState } from 'react';
import { RelationsContext } from './relations-context';
import { RelationsContextProps } from './types';
import { Hospital } from 'models';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { getUniqueValues } from '../shared/utilFunctions';
import useAPI from 'hooks/API';
import { createHospital as createHospitalMutation } from 'graphql/mutations';
import { listHospitals } from 'graphql/queries';

export interface HospitalContextProps {
	hospitals: Hospital[];
	createHospital: (hospital: Hospital) => Promise<Hospital | null>;
	fetchHospitals: () => Promise<void>;
	hospitalExists: (hospital: Hospital | null) => Promise<boolean>;
}

export const HospitalsContext = React.createContext<Partial<HospitalContextProps>>({});

const HospitalsProvider: React.FC = (props) => {
	const history = useHistory();
	const [isError, setIsError] = useState(false);
	const [hospitals, setHospitals] = useState<Hospital[]>([]);
	const { setActualHospital } = useContext(RelationsContext) as RelationsContextProps;
	const { execute } = useAPI();

	useEffect(() => {
		if (isError) {
			return history.push('/Not-Found');
		}
	}, [isError]);

	const createHospital = async (hospital: Hospital): Promise<Hospital | null> => {
		try {
			const response = await execute(createHospitalMutation, {
				input: hospital,
			});
			const newHospital = response.data.createHospital;
			setActualHospital(newHospital);
			setHospitals([...hospitals, newHospital]);
			return newHospital;
		} catch (error) {
			setIsError(true);
			return null;
		}
	};

	const fetchHospitals = async (): Promise<void> => {
		// fetch all the Hospitals
		try {
			const response = await execute(listHospitals);
			const hospitals = response.data.listHospitals.items;
			setHospitals(getUniqueValues(hospitals, 'name'));
		} catch (error) {
			setIsError(true);
		}
	};

	const hospitalExists = async (hospital: Hospital | null): Promise<boolean> => {
		try {
			if (!hospital) return false;
			const response = await execute(listHospitals, {
				filter: { name: { eq: hospital.name } },
			});
			const hospitals = response.data.listHospitals.items;
			return hospitals.length > 0;
		} catch (error) {
			setIsError(true);
			return false;
		}
	};

	return (
		<HospitalsContext.Provider
			value={{
				hospitals,
				createHospital,
				fetchHospitals,
				hospitalExists,
			}}
		>
			{props.children}
		</HospitalsContext.Provider>
	);
};

export default HospitalsProvider;
