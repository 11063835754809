import { Input, MenuItem, Select } from '@material-ui/core';
import { LoaderContext } from 'context/loader-context';
import { LoaderContextProps, UserContextProps, UserRoles } from 'context/types';
import { UserContext } from 'context/user-context';
import { WaitingListContextProps, WaitingListsContext } from 'context/waiting-list-context';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

const StyledSelect = styled(Select)`
	.MuiSelect-select.MuiSelect-select {
		color: #1976d2;
	}

	.MuiSelect-icon {
		color: #1976d2;
	}
	width: 150px;
`;

interface IMemberDropdown {
	selectCustomStyles?: React.CSSProperties;
}

const MemberDropDown = ({ selectCustomStyles }: IMemberDropdown) => {
	const { doctorMembers, userRole, doctorMemberSelectedHandler } = useContext(
		UserContext
	) as UserContextProps;
	const waitingListsContext = useContext(WaitingListsContext) as WaitingListContextProps;
	const { actualWaitingList } = waitingListsContext;
	const [selectedDoctor, setSelectedDoctor] = useState<string | null>(doctorMembers[0]?.doctorID);
	const { showSpinner } = useContext(LoaderContext) as LoaderContextProps;
	const loadWaitingList = async (doctorID: string) => {
		try {
			if (doctorID === selectedDoctor) return;

			const doctor = doctorMembers.find((item) => item.doctorID === doctorID);
			if (!doctor) return;
			showSpinner(true);
			await waitingListsContext.upsertMemberWaitingList(doctor);
		} catch (error) {
			console.error('Error loading waiting list', error);
		} finally {
			showSpinner(false);
		}
	};

	const generateOptions = useCallback(
		() =>
			doctorMembers.map((item) => {
				return (
					<MenuItem
						className="outline-none"
						key={item.doctorID}
						value={item.doctorID}
						style={{
							color: '#1976D2',
							fontSize: '15px',
							fontWeight: '500',
							fontStyle: 'normal',
							lineHeight: '26px',
							letterSpacing: '0.46px',
							textTransform: 'uppercase',
						}}
					>
						{item.doctorName.toUpperCase()}
					</MenuItem>
				);
			}),
		[doctorMembers]
	);

	const getSelectedDoctor = async () => {
		if (actualWaitingList) {
			const doctor = doctorMembers.find(
				(item) => item.lastWaitingListID === actualWaitingList?.id
			);
			doctorMemberSelectedHandler(doctor);
			if (doctor?.doctorID === selectedDoctor) {
				return;
			}
			if (doctor) {
				setSelectedDoctor(doctor.doctorID);
			} else {
				doctorMemberSelectedHandler(doctorMembers[0]);
				setSelectedDoctor(doctorMembers[0].doctorID);
			}
		} else {
			doctorMemberSelectedHandler(doctorMembers[0]);
			setSelectedDoctor(doctorMembers[0].doctorID);
		}
	};

	useEffect(() => {
		getSelectedDoctor();
	}, [actualWaitingList]);
	if (
		doctorMembers.length === 0 ||
		doctorMembers.length === 1 ||
		userRole !== UserRoles.Secretary
	)
		return null;

	return (
		<div className="flex flex-col items-center mr-2">
			<div className="flex items-center">
				<StyledSelect
					input={<Input disableUnderline />}
					className="text-[#1976D2] text-lg font-bold bg-transparent border-none outline-none"
					value={selectedDoctor}
					onChange={(e) => loadWaitingList(e.target.value as string)}
					style={selectCustomStyles}
				>
					{generateOptions()}
				</StyledSelect>
			</div>
		</div>
	);
};

export default MemberDropDown;
