import DoctorMemberBox from './DoctorMemberBox';
import { AddIconBlue } from '../../svg/AddIcon';
import TextField from '@material-ui/core/TextField';
import { Container, Tooltip, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { styled } from '@material-ui/core';
import InputAdornment from '@mui/material/InputAdornment';
import DoctorMemberDialog from './DoctorMemberdialog';
import { useContext, useEffect, useState } from 'react';
import { RelationsContext } from 'context/relations-context';
import { RelationsContextProps, UserContextProps } from 'context/types';
import { Teams } from 'models';
import DoctorMembersSkeleton from './DoctorMembersSkeleton';
import { TeamStatus } from 'models';
import { InfoIcon } from 'components/svg/InfoIcon';
import { UserContext } from 'context/user-context';
import ModalConfirmation from 'components/modalConfirmation/ModalConfirmation';
import { StyledInfoOutlinedIcon } from 'components/clients/clientSummary/PatientSummary.style';
import useMobile from 'hooks/useMobile';

const InputSearch = styled(TextField)({
	width: '228px',
	'& .MuiOutlinedInput-root': {
		height: '36px',
	},
});

const DoctorsMembers = () => {
	const [showAddModal, setShowAddModal] = useState(false);
	const relationsContext = useContext(RelationsContext) as RelationsContextProps;
	const [doctorMemberEmail, setDoctorMemberEmail] = useState('');
	const [searchDoctor, setSearchDoctor] = useState('');
	const { resendConfirmationCode } = useContext(UserContext) as UserContextProps;
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [selectedMemberId, setSelectedMemberId] = useState('');
	const [selectedMemberName, setSelectedMemberName] = useState('');
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [openTooltip, setOpenTooltip] = useState(false);
	const { isMobile } = useMobile();
	const members = relationsContext.teamMembers?.filter((sec: any) =>
		sec?.Member?.doctor?.email.includes(searchDoctor)
	);

	const sendConfirmationCode = async (email?: string) => {
		try {
			if (!email) return;
			await resendConfirmationCode(email);
			relationsContext.setAlertMessage({
				severity: 'success',
				message: 'Código de confirmación enviado',
			});
		} catch (error) {
			relationsContext.setAlertMessage({
				severity: 'error',
				message: 'No se pudo enviar el código de confirmación',
			});
		}
	};

	const onDeleteMember = async () => {
		try {
			if (!selectedMemberId) {
				relationsContext.setAlertMessage({
					severity: 'error',
					message: 'No se pudo eliminar el miembro',
				});
				return;
			}
			setDeleteLoading(true);
			await relationsContext.disableTeamMember(selectedMemberId);
			setShowDeleteModal(false);
			setDeleteLoading(false);
			relationsContext.setAlertMessage({
				severity: 'success',
				message: 'Miembro eliminado',
			});
		} catch (error) {
			relationsContext.setAlertMessage({
				severity: 'error',
				message: 'No se pudo eliminar el miembro',
			});
			setDeleteLoading(false);
		}
	};

	const showDeleteModalConfirmation = (memberId: string, name: string | undefined) => {
		setSelectedMemberId(memberId);
		if (name) {
			setSelectedMemberName(name);
		}
		setShowDeleteModal(true);
	};

	const closeDeleteModal = () => {
		setShowDeleteModal(false);
		setSelectedMemberId('');
	};

	const doctorsMembers = members?.map((sec: any) => {
		let name = sec?.Member?.doctor?.email + ' (Esperando confirmación)';

		if (sec?.status === TeamStatus.ACTIVE) {
			name = sec?.Member?.doctor?.name + ` (${sec?.Member?.doctor?.email})`;
		}

		return (
			<DoctorMemberBox
				key={sec?.id}
				name={name}
				status={sec?.status as TeamStatus}
				sendConfirmationCode={() => sendConfirmationCode(sec?.Member?.doctor?.email)}
				deleteFunction={() =>
					showDeleteModalConfirmation(
						sec?.MemberID,
						sec?.Member?.doctor?.name ?? sec?.Member?.doctor?.email
					)
				}
			/>
		);
	});

	useEffect(() => {
		relationsContext.getTeamMembers();
	}, []);

	const containerStyles = {
		minHeight: 360,
		boxShadow:
			'0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)',
		borderRadius: '4px',
		backgroundColor: 'rgb(255, 255, 255)',
		padding: '0px 0px 30px 0px',
	};

	const titleStyles = {
		fontFamily: 'Roboto',
		fontStyle: 'normal',
		fontWeight: '500',
		fontSize: '20px',
		lineHeight: '160%',
		letterSpacing: '0.15px',
		color: '#000000DE',
	};

	return (
		<>
			<DoctorMemberDialog
				open={showAddModal}
				handleClose={() => setShowAddModal(false)}
				doctorId={'1'}
				handlerAddDoctor={async () =>
					await relationsContext.addDoctorMember(doctorMemberEmail)
				}
				setEmail={(email: string) => setDoctorMemberEmail(email)}
			/>
			<ModalConfirmation
				approveFunction={onDeleteMember}
				modalState={showDeleteModal}
				closeFunction={closeDeleteModal}
				name={`¿Estás seguro que quieres eliminar a ${selectedMemberName}?`}
				loading={deleteLoading}
			/>
			<Container
				id="doctor-members-container"
				style={{
					width: isMobile ? '100%' : 716,
					...containerStyles,
				}}
			>
				<div
					className="border-b-2 border-green-content py-4"
					style={{
						padding: '13px 16px',
					}}
				>
					<div className="flex justify-between">
						<div className="flex gap-2 justify-center items-center">
							<h1 style={titleStyles}>Doctores</h1>
							<Tooltip
								open={openTooltip}
								onClose={() => setOpenTooltip(false)}
								onOpen={() => setOpenTooltip(true)}
								title={
									<Typography variant="body2">
										Los doctores son los profesionales de la salud que pueden
										atender a los pacientes.
									</Typography>
								}
								placement="top"
								arrow
							>
								<StyledInfoOutlinedIcon onClick={() => setOpenTooltip(true)} />
							</Tooltip>
						</div>

						<InputSearch
							id="search-doctor"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
							}}
							placeholder="Buscar"
							variant="outlined"
							onChange={(e) => setSearchDoctor(e.target.value)}
						/>
					</div>
				</div>
				<div
					style={{
						padding: '0px 20px',
						minHeight: '75%',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
					}}
				>
					<div
						style={{
							overflowY: 'auto',
							padding: '8px 0px',
							height: '100%',
							maxHeight: '230px',
							display: 'flex',
							flexDirection: 'column',
							gap: '10px',
						}}
					>
						{relationsContext.loadingMembers ? (
							<DoctorMembersSkeleton />
						) : (
							doctorsMembers
						)}
					</div>
					<div
						style={{
							marginTop: '20px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}
					>
						<div className="flex justify-end w-full">
							<button
								className="OpenSansRegular text-[#1976D2] font-bold cursor-pointer uppercase flex gap-2 items-center"
								onClick={() => setShowAddModal(true)}
							>
								<AddIconBlue />
								agregar doctor
							</button>
						</div>
					</div>
				</div>
			</Container>
		</>
	);
};

export default DoctorsMembers;
