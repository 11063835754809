import React from 'react';
import { OffLineSVG } from '../svg';
import {
	NoInternetContainer,
	DescriptionOffline,
	InformationContainer,
	TitleOffline,
} from '../../shared/style';

type Props = {
	message: string;
	style?: React.CSSProperties;
};

const NoInternet: React.FC<Props> = ({ message, style }) => {
	return (
		<NoInternetContainer style={style}>
			<InformationContainer>
				<OffLineSVG />
				<TitleOffline>Sin internet</TitleOffline>
				<DescriptionOffline>{message}</DescriptionOffline>
			</InformationContainer>
		</NoInternetContainer>
	);
};

export default NoInternet;
