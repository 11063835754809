import { Box, Button, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { CancelSubscriptionContainer } from '../../shared/style';
import styled from 'styled-components';

export const PaymentContainer = styled(Box)`
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-self: baseline;
`;

export const CardsContainer = styled(Box)`
	width: 642px;
	margin-top: 60px;
	border-radius: 4px;
	background: #ffffff;
	margin-bottom: 20px;
	flex-basis: 50%;
	padding-bottom: 30px;
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
		0px 2px 1px -1px rgba(0, 0, 0, 0.2);
`;

export const CardsHeaderContainer = styled.div`
	padding: 13px 16px;
	height: 58px;
`;

export const CardsButtonContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	padding-right: 20px;
	gap: 20px;
`;

export const StyledSpan = styled.span`
	color: #00000099;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.15px;
`;

export const CardsButton = styled(Button)`
	color: #9c27b0;
	border-color: #9c27b0;
	font-size: 15px;
	font-weight: 500;
	line-height: 26px;
	font-style: normal;
	letter-spacing: 0.46px;
	text-transform: uppercase;
`;

export const MembershipContainer = styled.div`
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
		0px 2px 1px -1px rgba(0, 0, 0, 0.2);
	background: #fff;
	border-radius: 4px;
	display: flex;
	justify-content: space-between;
	margin: auto;
	flex-basis: 20%;
	width: 100%;
	height: 100%;
	max-width: 642px;
	max-height: 82px;
	padding: 25px 20px;
`;

export const MembershipTitle = styled(Typography)`
	color: rgba(0, 0, 0, 0.87);
	font-size: 20px;
	font-weight: 500;
	line-height: 32px;
	font-style: normal;
	letter-spacing: 0.15px;
`;

export const MembershipButton = styled(Button)`
	color: #1976d2;
	font-size: 15px;
	font-weight: 500;
	line-height: 26px;
	font-style: normal;
	letter-spacing: 0.46px;
	text-transform: uppercase;
`;

export const PaymentSkeleton = () => {
	return (
		<div
			id="myCards"
			className="bg-white-section rounded-xl m-auto"
			style={{
				width: 716,
				marginTop: '60px',
				borderRadius: '4px',
				background: '#FFFFFF',
				boxShadow:
					'0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)',
			}}
		>
			<div
				className="border-b-2 border-green-content"
				style={{
					padding: ' 13px 16px',
					height: '58px',
				}}
			>
				<h1
					className="font-bold raleway-font"
					style={{
						fontFamily: 'Roboto',
						fontSize: '20px',
					}}
				>
					<Skeleton variant="rect" width="100%" height={30} />
				</h1>
			</div>

			<div
				style={{
					padding: '20px',
				}}
			>
				<div>
					<h2
						className="text-blueLucki-default font-bold raleway-font mb-1"
						style={{
							fontSize: '16px',
						}}
					>
						<Skeleton variant="rect" width="100%" height={50} />
					</h2>

					<span
						className="text-base font-normal raleway-font"
						style={{
							color: '#00000099',
							fontFamily: 'Roboto',
							fontStyle: 'normal',
							fontWeight: 400,
							fontSize: '16px',
							lineHeight: '24px',
							letterSpacing: '0.15px',
						}}
					>
						<Skeleton variant="rect" width="100%" height={50} />
					</span>
				</div>

				<Skeleton variant="rect" width="100%" height={20} />

				<CancelSubscriptionContainer>
					<Skeleton
						variant="rect"
						width={150}
						height={50}
						style={{
							marginTop: '20px',
						}}
					/>
				</CancelSubscriptionContainer>
			</div>
		</div>
	);
};
