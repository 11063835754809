import React, { FC } from 'react';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { StyledRadioGroup, StyledFormLabel } from '../../shared/style';

interface RadioInputs {
	id: string | number;
	value: string;
	label: string;
}

interface Props {
	label: string;
	name: string;
	value: string;
	handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	radioList?: RadioInputs[];
	disabled?: boolean;
}

const RadioFilter: FC<Props> = ({ label, name, value, handleChange, radioList, disabled }) => {
	return (
		<FormControl>
			<StyledFormLabel>{label}</StyledFormLabel>
			<StyledRadioGroup aria-label={label} name={name} value={value} onChange={handleChange}>
				{radioList?.map((radio) => (
					<FormControlLabel
						key={radio.id}
						value={radio.value}
						control={<Radio color="primary" />}
						label={radio.label}
						disabled={disabled}
					/>
				))}
			</StyledRadioGroup>
		</FormControl>
	);
};

export default RadioFilter;
