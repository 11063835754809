import React from 'react';

const ActivityIcon = () => {
	return (
		<svg
			width="17"
			height="22"
			viewBox="0 0 17 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.4906 4.47656C12.5906 4.47656 13.4906 3.57656 13.4906 2.47656C13.4906 1.37656 12.5906 0.476562 11.4906 0.476562C10.3906 0.476562 9.49062 1.37656 9.49062 2.47656C9.49062 3.57656 10.3906 4.47656 11.4906 4.47656ZM7.89062 18.3766L8.89062 13.9766L10.9906 15.9766V21.9766H12.9906V14.4766L10.8906 12.4766L11.4906 9.47656C12.7906 10.9766 14.7906 11.9766 16.9906 11.9766V9.97656C15.0906 9.97656 13.4906 8.97656 12.6906 7.57656L11.6906 5.97656C11.2906 5.37656 10.6906 4.97656 9.99062 4.97656C9.69062 4.97656 9.49062 5.07656 9.19062 5.07656L3.99062 7.27656V11.9766H5.99062V8.57656L7.79062 7.87656L6.19062 15.9766L1.29062 14.9766L0.890625 16.9766L7.89062 18.3766Z"
				fill="#9C27B0"
			/>
		</svg>
	);
};

export default ActivityIcon;
