/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from '../API';
type GeneratedQuery<InputType, OutputType> = string & {
	__generatedQueryInput: InputType;
	__generatedQueryOutput: OutputType;
};

export const getSecretary = /* GraphQL */ `query GetSecretary($id: ID!) {
  getSecretary(id: $id) {
    id
    name
    email
    firstTime
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetSecretaryQueryVariables, APITypes.GetSecretaryQuery>;
export const listSecretarys = /* GraphQL */ `query ListSecretarys(
  $filter: ModelSecretaryFilterInput
  $limit: Int
  $nextToken: String
) {
  listSecretarys(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      email
      firstTime
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListSecretarysQueryVariables, APITypes.ListSecretarysQuery>;
export const syncSecretaries = /* GraphQL */ `query SyncSecretaries(
  $filter: ModelSecretaryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncSecretaries(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      email
      firstTime
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncSecretariesQueryVariables, APITypes.SyncSecretariesQuery>;
export const getEmailDistributionLists = /* GraphQL */ `query GetEmailDistributionLists($id: ID!) {
  getEmailDistributionLists(id: $id) {
    id
    email
    specialty
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.GetEmailDistributionListsQueryVariables,
	APITypes.GetEmailDistributionListsQuery
>;
export const listEmailDistributionListss = /* GraphQL */ `query ListEmailDistributionListss(
  $filter: ModelEmailDistributionListsFilterInput
  $limit: Int
  $nextToken: String
) {
  listEmailDistributionListss(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      email
      specialty
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.ListEmailDistributionListssQueryVariables,
	APITypes.ListEmailDistributionListssQuery
>;
export const syncEmailDistributionLists = /* GraphQL */ `query SyncEmailDistributionLists(
  $filter: ModelEmailDistributionListsFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncEmailDistributionLists(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      email
      specialty
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.SyncEmailDistributionListsQueryVariables,
	APITypes.SyncEmailDistributionListsQuery
>;
export const getSecretaryHospitalDoctor =
	/* GraphQL */ `query GetSecretaryHospitalDoctor($id: ID!) {
  getSecretaryHospitalDoctor(id: $id) {
    id
    hospitalDoctorID
    secretaryID
    hasHistoryPermission
    hasSurgeryPermission
    hasPrescriptionsPermission
    hasFilesPermission
    hasNotesPermission
    hasClaimsPermission
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    secretary {
      id
      name
      email
      firstTime
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    hospitalDoctor {
      id
      doctorID
      hospitalID
      lastWaitingListID
      hospitalLogo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospital {
        id
        name
        type
        province
        town
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
		APITypes.GetSecretaryHospitalDoctorQueryVariables,
		APITypes.GetSecretaryHospitalDoctorQuery
	>;
export const listSecretaryHospitalDoctors = /* GraphQL */ `query ListSecretaryHospitalDoctors(
  $filter: ModelSecretaryHospitalDoctorFilterInput
  $limit: Int
  $nextToken: String
) {
  listSecretaryHospitalDoctors(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      hospitalDoctorID
      secretaryID
      hasHistoryPermission
      hasSurgeryPermission
      hasPrescriptionsPermission
      hasFilesPermission
      hasNotesPermission
      hasClaimsPermission
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      secretary {
        id
        name
        email
        firstTime
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospitalDoctor {
        id
        doctorID
        hospitalID
        lastWaitingListID
        hospitalLogo
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        doctor {
          id
          name
          specialty
          exequator
          email
          firstTime
          studyPlace
          isBeenReview
          isFirstAddSecretary
          hasTrialOnCreation
          title
          phone
          code
          password
          isAdmin
          daysOfTrial
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        hospital {
          id
          name
          type
          province
          town
          sector
          phone
          address
          pssCode
          rnc
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.ListSecretaryHospitalDoctorsQueryVariables,
	APITypes.ListSecretaryHospitalDoctorsQuery
>;
export const syncSecretaryHospitalDoctors = /* GraphQL */ `query SyncSecretaryHospitalDoctors(
  $filter: ModelSecretaryHospitalDoctorFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncSecretaryHospitalDoctors(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      hospitalDoctorID
      secretaryID
      hasHistoryPermission
      hasSurgeryPermission
      hasPrescriptionsPermission
      hasFilesPermission
      hasNotesPermission
      hasClaimsPermission
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      secretary {
        id
        name
        email
        firstTime
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospitalDoctor {
        id
        doctorID
        hospitalID
        lastWaitingListID
        hospitalLogo
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        doctor {
          id
          name
          specialty
          exequator
          email
          firstTime
          studyPlace
          isBeenReview
          isFirstAddSecretary
          hasTrialOnCreation
          title
          phone
          code
          password
          isAdmin
          daysOfTrial
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        hospital {
          id
          name
          type
          province
          town
          sector
          phone
          address
          pssCode
          rnc
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.SyncSecretaryHospitalDoctorsQueryVariables,
	APITypes.SyncSecretaryHospitalDoctorsQuery
>;
export const getSecretaryHospitalDoctorClient =
	/* GraphQL */ `query GetSecretaryHospitalDoctorClient($id: ID!) {
  getSecretaryHospitalDoctorClient(id: $id) {
    id
    hospitalDoctorID
    secretaryID
    clientID
    note
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
		APITypes.GetSecretaryHospitalDoctorClientQueryVariables,
		APITypes.GetSecretaryHospitalDoctorClientQuery
	>;
export const listSecretaryHospitalDoctorClients =
	/* GraphQL */ `query ListSecretaryHospitalDoctorClients(
  $filter: ModelSecretaryHospitalDoctorClientFilterInput
  $limit: Int
  $nextToken: String
) {
  listSecretaryHospitalDoctorClients(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      hospitalDoctorID
      secretaryID
      clientID
      note
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
		APITypes.ListSecretaryHospitalDoctorClientsQueryVariables,
		APITypes.ListSecretaryHospitalDoctorClientsQuery
	>;
export const syncSecretaryHospitalDoctorClients =
	/* GraphQL */ `query SyncSecretaryHospitalDoctorClients(
  $filter: ModelSecretaryHospitalDoctorClientFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncSecretaryHospitalDoctorClients(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      hospitalDoctorID
      secretaryID
      clientID
      note
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
		APITypes.SyncSecretaryHospitalDoctorClientsQueryVariables,
		APITypes.SyncSecretaryHospitalDoctorClientsQuery
	>;
export const getMemberHospitalDoctorClient =
	/* GraphQL */ `query GetMemberHospitalDoctorClient($id: ID!) {
  getMemberHospitalDoctorClient(id: $id) {
    id
    hospitalDoctorID
    memberID
    ownerID
    clientID
    note
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    owner {
      id
      doctorID
      hospitalID
      lastWaitingListID
      hospitalLogo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospital {
        id
        name
        type
        province
        town
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
		APITypes.GetMemberHospitalDoctorClientQueryVariables,
		APITypes.GetMemberHospitalDoctorClientQuery
	>;
export const listMemberHospitalDoctorClients = /* GraphQL */ `query ListMemberHospitalDoctorClients(
  $filter: ModelMemberHospitalDoctorClientFilterInput
  $limit: Int
  $nextToken: String
) {
  listMemberHospitalDoctorClients(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      hospitalDoctorID
      memberID
      ownerID
      clientID
      note
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner {
        id
        doctorID
        hospitalID
        lastWaitingListID
        hospitalLogo
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        doctor {
          id
          name
          specialty
          exequator
          email
          firstTime
          studyPlace
          isBeenReview
          isFirstAddSecretary
          hasTrialOnCreation
          title
          phone
          code
          password
          isAdmin
          daysOfTrial
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        hospital {
          id
          name
          type
          province
          town
          sector
          phone
          address
          pssCode
          rnc
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.ListMemberHospitalDoctorClientsQueryVariables,
	APITypes.ListMemberHospitalDoctorClientsQuery
>;
export const syncMemberHospitalDoctorClients = /* GraphQL */ `query SyncMemberHospitalDoctorClients(
  $filter: ModelMemberHospitalDoctorClientFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMemberHospitalDoctorClients(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      hospitalDoctorID
      memberID
      ownerID
      clientID
      note
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner {
        id
        doctorID
        hospitalID
        lastWaitingListID
        hospitalLogo
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        doctor {
          id
          name
          specialty
          exequator
          email
          firstTime
          studyPlace
          isBeenReview
          isFirstAddSecretary
          hasTrialOnCreation
          title
          phone
          code
          password
          isAdmin
          daysOfTrial
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        hospital {
          id
          name
          type
          province
          town
          sector
          phone
          address
          pssCode
          rnc
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.SyncMemberHospitalDoctorClientsQueryVariables,
	APITypes.SyncMemberHospitalDoctorClientsQuery
>;
export const getClient = /* GraphQL */ `query GetClient($id: ID!) {
  getClient(id: $id) {
    id
    identificationName
    identificationData
    actualInsurance
    name
    lastName
    cellphoneNumber
    email
    bornDate
    parentOrTutorCedula
    parentOrTutorID
    gender
    phoneNumber
    addressStreet
    city
    isAdult
    sector
    bloodType
    company
    vitalSignsHeight
    vitalSignsWeight
    bodyMass
    vitalSignsBloodPressure
    heartRate
    respiratoryRate
    bodyTemperature
    oxygenSaturation
    occupation
    profileImage
    heartBeat
    weight
    height
    bloodPressure
    updateDateTime
    createdAt
    updatedAt
    code
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetClientQueryVariables, APITypes.GetClientQuery>;
export const listClients = /* GraphQL */ `query ListClients(
  $filter: ModelClientFilterInput
  $limit: Int
  $nextToken: String
) {
  listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      identificationName
      identificationData
      actualInsurance
      name
      lastName
      cellphoneNumber
      email
      bornDate
      parentOrTutorCedula
      parentOrTutorID
      gender
      phoneNumber
      addressStreet
      city
      isAdult
      sector
      bloodType
      company
      vitalSignsHeight
      vitalSignsWeight
      bodyMass
      vitalSignsBloodPressure
      heartRate
      respiratoryRate
      bodyTemperature
      oxygenSaturation
      occupation
      profileImage
      heartBeat
      weight
      height
      bloodPressure
      updateDateTime
      createdAt
      updatedAt
      code
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListClientsQueryVariables, APITypes.ListClientsQuery>;
export const syncClients = /* GraphQL */ `query SyncClients(
  $filter: ModelClientFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncClients(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      identificationName
      identificationData
      actualInsurance
      name
      lastName
      cellphoneNumber
      email
      bornDate
      parentOrTutorCedula
      parentOrTutorID
      gender
      phoneNumber
      addressStreet
      city
      isAdult
      sector
      bloodType
      company
      vitalSignsHeight
      vitalSignsWeight
      bodyMass
      vitalSignsBloodPressure
      heartRate
      respiratoryRate
      bodyTemperature
      oxygenSaturation
      occupation
      profileImage
      heartBeat
      weight
      height
      bloodPressure
      updateDateTime
      createdAt
      updatedAt
      code
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncClientsQueryVariables, APITypes.SyncClientsQuery>;
export const getClientImageSection = /* GraphQL */ `query GetClientImageSection($id: ID!) {
  getClientImageSection(id: $id) {
    id
    clientHospitalDoctorID
    date
    description
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.GetClientImageSectionQueryVariables,
	APITypes.GetClientImageSectionQuery
>;
export const listClientImageSections = /* GraphQL */ `query ListClientImageSections(
  $filter: ModelClientImageSectionFilterInput
  $limit: Int
  $nextToken: String
) {
  listClientImageSections(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      clientHospitalDoctorID
      date
      description
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.ListClientImageSectionsQueryVariables,
	APITypes.ListClientImageSectionsQuery
>;
export const syncClientImageSections = /* GraphQL */ `query SyncClientImageSections(
  $filter: ModelClientImageSectionFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncClientImageSections(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      clientHospitalDoctorID
      date
      description
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.SyncClientImageSectionsQueryVariables,
	APITypes.SyncClientImageSectionsQuery
>;
export const getPosibleClient = /* GraphQL */ `query GetPosibleClient($id: ID!) {
  getPosibleClient(id: $id) {
    id
    phoneNumber
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetPosibleClientQueryVariables, APITypes.GetPosibleClientQuery>;
export const listPosibleClients = /* GraphQL */ `query ListPosibleClients(
  $filter: ModelPosibleClientFilterInput
  $limit: Int
  $nextToken: String
) {
  listPosibleClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      phoneNumber
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListPosibleClientsQueryVariables, APITypes.ListPosibleClientsQuery>;
export const syncPosibleClients = /* GraphQL */ `query SyncPosibleClients(
  $filter: ModelPosibleClientFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPosibleClients(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      phoneNumber
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncPosibleClientsQueryVariables, APITypes.SyncPosibleClientsQuery>;
export const getClientImage = /* GraphQL */ `query GetClientImage($id: ID!) {
  getClientImage(id: $id) {
    id
    pathFile
    description
    ClientImageDateID
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetClientImageQueryVariables, APITypes.GetClientImageQuery>;
export const listClientImages = /* GraphQL */ `query ListClientImages(
  $filter: ModelClientImageFilterInput
  $limit: Int
  $nextToken: String
) {
  listClientImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      pathFile
      description
      ClientImageDateID
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListClientImagesQueryVariables, APITypes.ListClientImagesQuery>;
export const syncClientImages = /* GraphQL */ `query SyncClientImages(
  $filter: ModelClientImageFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncClientImages(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      pathFile
      description
      ClientImageDateID
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncClientImagesQueryVariables, APITypes.SyncClientImagesQuery>;
export const getConsultationDiagnostic = /* GraphQL */ `query GetConsultationDiagnostic($id: ID!) {
  getConsultationDiagnostic(id: $id) {
    id
    clientConsultationID
    name
    code
    type
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.GetConsultationDiagnosticQueryVariables,
	APITypes.GetConsultationDiagnosticQuery
>;
export const listConsultationDiagnostics = /* GraphQL */ `query ListConsultationDiagnostics(
  $filter: ModelConsultationDiagnosticFilterInput
  $limit: Int
  $nextToken: String
) {
  listConsultationDiagnostics(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      clientConsultationID
      name
      code
      type
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.ListConsultationDiagnosticsQueryVariables,
	APITypes.ListConsultationDiagnosticsQuery
>;
export const syncConsultationDiagnostics = /* GraphQL */ `query SyncConsultationDiagnostics(
  $filter: ModelConsultationDiagnosticFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncConsultationDiagnostics(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      clientConsultationID
      name
      code
      type
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.SyncConsultationDiagnosticsQueryVariables,
	APITypes.SyncConsultationDiagnosticsQuery
>;
export const getClientConsultation = /* GraphQL */ `query GetClientConsultation($id: ID!) {
  getClientConsultation(id: $id) {
    id
    clientHospitalDoctorID
    doctorID
    visitReason
    visitDescription
    physicTestNote
    height
    weight
    bodyMass
    bloodPressure
    heartRate
    respiratoryRate
    bodyTemperature
    oxygenSaturation
    clientMedicinePrescriptionID
    clientAnalisisPrescriptionID
    clientFreePrescriptionID
    treatment
    procedureName
    procedureDescription
    createdAt
    updatedAt
    status
    _version
    _deleted
    _lastChangedAt
    doctor {
      id
      name
      specialty
      exequator
      email
      firstTime
      studyPlace
      isBeenReview
      isFirstAddSecretary
      hasTrialOnCreation
      title
      phone
      code
      password
      isAdmin
      daysOfTrial
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
	APITypes.GetClientConsultationQueryVariables,
	APITypes.GetClientConsultationQuery
>;
export const listClientConsultations = /* GraphQL */ `query ListClientConsultations(
  $filter: ModelClientConsultationFilterInput
  $limit: Int
  $nextToken: String
) {
  listClientConsultations(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      clientHospitalDoctorID
      doctorID
      visitReason
      visitDescription
      physicTestNote
      height
      weight
      bodyMass
      bloodPressure
      heartRate
      respiratoryRate
      bodyTemperature
      oxygenSaturation
      clientMedicinePrescriptionID
      clientAnalisisPrescriptionID
      clientFreePrescriptionID
      treatment
      procedureName
      procedureDescription
      createdAt
      updatedAt
      status
      _version
      _deleted
      _lastChangedAt
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.ListClientConsultationsQueryVariables,
	APITypes.ListClientConsultationsQuery
>;
export const syncClientConsultations = /* GraphQL */ `query SyncClientConsultations(
  $filter: ModelClientConsultationFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncClientConsultations(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      clientHospitalDoctorID
      doctorID
      visitReason
      visitDescription
      physicTestNote
      height
      weight
      bodyMass
      bloodPressure
      heartRate
      respiratoryRate
      bodyTemperature
      oxygenSaturation
      clientMedicinePrescriptionID
      clientAnalisisPrescriptionID
      clientFreePrescriptionID
      treatment
      procedureName
      procedureDescription
      createdAt
      updatedAt
      status
      _version
      _deleted
      _lastChangedAt
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.SyncClientConsultationsQueryVariables,
	APITypes.SyncClientConsultationsQuery
>;
export const getMedicine = /* GraphQL */ `query GetMedicine($id: ID!) {
  getMedicine(id: $id) {
    id
    name
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetMedicineQueryVariables, APITypes.GetMedicineQuery>;
export const listMedicines = /* GraphQL */ `query ListMedicines(
  $filter: ModelMedicineFilterInput
  $limit: Int
  $nextToken: String
) {
  listMedicines(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListMedicinesQueryVariables, APITypes.ListMedicinesQuery>;
export const syncMedicines = /* GraphQL */ `query SyncMedicines(
  $filter: ModelMedicineFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMedicines(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncMedicinesQueryVariables, APITypes.SyncMedicinesQuery>;
export const getClientAllergy = /* GraphQL */ `query GetClientAllergy($id: ID!) {
  getClientAllergy(id: $id) {
    id
    clientID
    name
    status
    note
    diagnosedDate
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetClientAllergyQueryVariables, APITypes.GetClientAllergyQuery>;
export const listClientAllergys = /* GraphQL */ `query ListClientAllergys(
  $filter: ModelClientAllergyFilterInput
  $limit: Int
  $nextToken: String
) {
  listClientAllergys(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      clientID
      name
      status
      note
      diagnosedDate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListClientAllergysQueryVariables, APITypes.ListClientAllergysQuery>;
export const syncClientAllergies = /* GraphQL */ `query SyncClientAllergies(
  $filter: ModelClientAllergyFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncClientAllergies(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      clientID
      name
      status
      note
      diagnosedDate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncClientAllergiesQueryVariables, APITypes.SyncClientAllergiesQuery>;
export const getClientDisease = /* GraphQL */ `query GetClientDisease($id: ID!) {
  getClientDisease(id: $id) {
    id
    clientID
    name
    status
    note
    diagnosedDate
    updatedAt
    createdAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetClientDiseaseQueryVariables, APITypes.GetClientDiseaseQuery>;
export const listClientDiseases = /* GraphQL */ `query ListClientDiseases(
  $filter: ModelClientDiseaseFilterInput
  $limit: Int
  $nextToken: String
) {
  listClientDiseases(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      clientID
      name
      status
      note
      diagnosedDate
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListClientDiseasesQueryVariables, APITypes.ListClientDiseasesQuery>;
export const syncClientDiseases = /* GraphQL */ `query SyncClientDiseases(
  $filter: ModelClientDiseaseFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncClientDiseases(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      clientID
      name
      status
      note
      diagnosedDate
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncClientDiseasesQueryVariables, APITypes.SyncClientDiseasesQuery>;
export const getClientProcedure = /* GraphQL */ `query GetClientProcedure($id: ID!) {
  getClientProcedure(id: $id) {
    id
    clientID
    name
    note
    realizationDate
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetClientProcedureQueryVariables, APITypes.GetClientProcedureQuery>;
export const listClientProcedures = /* GraphQL */ `query ListClientProcedures(
  $filter: ModelClientProcedureFilterInput
  $limit: Int
  $nextToken: String
) {
  listClientProcedures(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      clientID
      name
      note
      realizationDate
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.ListClientProceduresQueryVariables,
	APITypes.ListClientProceduresQuery
>;
export const syncClientProcedures = /* GraphQL */ `query SyncClientProcedures(
  $filter: ModelClientProcedureFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncClientProcedures(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      clientID
      name
      note
      realizationDate
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.SyncClientProceduresQueryVariables,
	APITypes.SyncClientProceduresQuery
>;
export const getClientHospitalization = /* GraphQL */ `query GetClientHospitalization($id: ID!) {
  getClientHospitalization(id: $id) {
    id
    clientID
    name
    note
    date
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.GetClientHospitalizationQueryVariables,
	APITypes.GetClientHospitalizationQuery
>;
export const listClientHospitalizations = /* GraphQL */ `query ListClientHospitalizations(
  $filter: ModelClientHospitalizationFilterInput
  $limit: Int
  $nextToken: String
) {
  listClientHospitalizations(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      clientID
      name
      note
      date
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.ListClientHospitalizationsQueryVariables,
	APITypes.ListClientHospitalizationsQuery
>;
export const syncClientHospitalizations = /* GraphQL */ `query SyncClientHospitalizations(
  $filter: ModelClientHospitalizationFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncClientHospitalizations(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      clientID
      name
      note
      date
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.SyncClientHospitalizationsQueryVariables,
	APITypes.SyncClientHospitalizationsQuery
>;
export const getClientFamilyHistory = /* GraphQL */ `query GetClientFamilyHistory($id: ID!) {
  getClientFamilyHistory(id: $id) {
    id
    clientID
    familyMember
    mellitusDiabetes
    mellitusDiabetesNote
    arterialHypertension
    arterialHypertensionNote
    heartDisease
    heartDiseaseNote
    nephropathy
    nephropathyNote
    neuropathies
    neuropathiesNote
    epilepsy
    epilepsyNote
    rheumatoidArthritis
    rheumatoidArthritisNote
    psychiatricDiseases
    psychiatricDiseasesNote
    cancer
    cancerNote
    other
    otherNote
    updatedAt
    createdAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.GetClientFamilyHistoryQueryVariables,
	APITypes.GetClientFamilyHistoryQuery
>;
export const listClientFamilyHistorys = /* GraphQL */ `query ListClientFamilyHistorys(
  $filter: ModelClientFamilyHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listClientFamilyHistorys(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      clientID
      familyMember
      mellitusDiabetes
      mellitusDiabetesNote
      arterialHypertension
      arterialHypertensionNote
      heartDisease
      heartDiseaseNote
      nephropathy
      nephropathyNote
      neuropathies
      neuropathiesNote
      epilepsy
      epilepsyNote
      rheumatoidArthritis
      rheumatoidArthritisNote
      psychiatricDiseases
      psychiatricDiseasesNote
      cancer
      cancerNote
      other
      otherNote
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.ListClientFamilyHistorysQueryVariables,
	APITypes.ListClientFamilyHistorysQuery
>;
export const syncClientFamilyHistories = /* GraphQL */ `query SyncClientFamilyHistories(
  $filter: ModelClientFamilyHistoryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncClientFamilyHistories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      clientID
      familyMember
      mellitusDiabetes
      mellitusDiabetesNote
      arterialHypertension
      arterialHypertensionNote
      heartDisease
      heartDiseaseNote
      nephropathy
      nephropathyNote
      neuropathies
      neuropathiesNote
      epilepsy
      epilepsyNote
      rheumatoidArthritis
      rheumatoidArthritisNote
      psychiatricDiseases
      psychiatricDiseasesNote
      cancer
      cancerNote
      other
      otherNote
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.SyncClientFamilyHistoriesQueryVariables,
	APITypes.SyncClientFamilyHistoriesQuery
>;
export const getClientLifeStyle = /* GraphQL */ `query GetClientLifeStyle($id: ID!) {
  getClientLifeStyle(id: $id) {
    id
    clientID
    lifeStyle
    name
    description
    updatedAt
    createdAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetClientLifeStyleQueryVariables, APITypes.GetClientLifeStyleQuery>;
export const listClientLifeStyles = /* GraphQL */ `query ListClientLifeStyles(
  $filter: ModelClientLifeStyleFilterInput
  $limit: Int
  $nextToken: String
) {
  listClientLifeStyles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      clientID
      lifeStyle
      name
      description
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.ListClientLifeStylesQueryVariables,
	APITypes.ListClientLifeStylesQuery
>;
export const syncClientLifeStyles = /* GraphQL */ `query SyncClientLifeStyles(
  $filter: ModelClientLifeStyleFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncClientLifeStyles(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      clientID
      lifeStyle
      name
      description
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.SyncClientLifeStylesQueryVariables,
	APITypes.SyncClientLifeStylesQuery
>;
export const getClientPrescription = /* GraphQL */ `query GetClientPrescription($id: ID!) {
  getClientPrescription(id: $id) {
    id
    clientHospitalDoctorID
    creationDate
    type
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.GetClientPrescriptionQueryVariables,
	APITypes.GetClientPrescriptionQuery
>;
export const listClientPrescriptions = /* GraphQL */ `query ListClientPrescriptions(
  $filter: ModelClientPrescriptionFilterInput
  $limit: Int
  $nextToken: String
) {
  listClientPrescriptions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      clientHospitalDoctorID
      creationDate
      type
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.ListClientPrescriptionsQueryVariables,
	APITypes.ListClientPrescriptionsQuery
>;
export const syncClientPrescriptions = /* GraphQL */ `query SyncClientPrescriptions(
  $filter: ModelClientPrescriptionFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncClientPrescriptions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      clientHospitalDoctorID
      creationDate
      type
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.SyncClientPrescriptionsQueryVariables,
	APITypes.SyncClientPrescriptionsQuery
>;
export const getClientPrescriptionMedicine =
	/* GraphQL */ `query GetClientPrescriptionMedicine($id: ID!) {
  getClientPrescriptionMedicine(id: $id) {
    id
    clientPrescriptionID
    medicineID
    name
    take
    indication
    duration
    description
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
		APITypes.GetClientPrescriptionMedicineQueryVariables,
		APITypes.GetClientPrescriptionMedicineQuery
	>;
export const listClientPrescriptionMedicines = /* GraphQL */ `query ListClientPrescriptionMedicines(
  $filter: ModelClientPrescriptionMedicineFilterInput
  $limit: Int
  $nextToken: String
) {
  listClientPrescriptionMedicines(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      clientPrescriptionID
      medicineID
      name
      take
      indication
      duration
      description
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.ListClientPrescriptionMedicinesQueryVariables,
	APITypes.ListClientPrescriptionMedicinesQuery
>;
export const syncClientPrescriptionMedicines = /* GraphQL */ `query SyncClientPrescriptionMedicines(
  $filter: ModelClientPrescriptionMedicineFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncClientPrescriptionMedicines(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      clientPrescriptionID
      medicineID
      name
      take
      indication
      duration
      description
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.SyncClientPrescriptionMedicinesQueryVariables,
	APITypes.SyncClientPrescriptionMedicinesQuery
>;
export const getClientFreePrescription = /* GraphQL */ `query GetClientFreePrescription($id: ID!) {
  getClientFreePrescription(id: $id) {
    id
    clientPrescriptionID
    description
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.GetClientFreePrescriptionQueryVariables,
	APITypes.GetClientFreePrescriptionQuery
>;
export const listClientFreePrescriptions = /* GraphQL */ `query ListClientFreePrescriptions(
  $filter: ModelClientFreePrescriptionFilterInput
  $limit: Int
  $nextToken: String
) {
  listClientFreePrescriptions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      clientPrescriptionID
      description
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.ListClientFreePrescriptionsQueryVariables,
	APITypes.ListClientFreePrescriptionsQuery
>;
export const syncClientFreePrescriptions = /* GraphQL */ `query SyncClientFreePrescriptions(
  $filter: ModelClientFreePrescriptionFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncClientFreePrescriptions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      clientPrescriptionID
      description
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.SyncClientFreePrescriptionsQueryVariables,
	APITypes.SyncClientFreePrescriptionsQuery
>;
export const getClientPrescriptionAnalisis =
	/* GraphQL */ `query GetClientPrescriptionAnalisis($id: ID!) {
  getClientPrescriptionAnalisis(id: $id) {
    id
    clientPrescriptionID
    name
    description
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
		APITypes.GetClientPrescriptionAnalisisQueryVariables,
		APITypes.GetClientPrescriptionAnalisisQuery
	>;
export const listClientPrescriptionAnalisiss = /* GraphQL */ `query ListClientPrescriptionAnalisiss(
  $filter: ModelClientPrescriptionAnalisisFilterInput
  $limit: Int
  $nextToken: String
) {
  listClientPrescriptionAnalisiss(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      clientPrescriptionID
      name
      description
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.ListClientPrescriptionAnalisissQueryVariables,
	APITypes.ListClientPrescriptionAnalisissQuery
>;
export const syncClientPrescriptionAnalises = /* GraphQL */ `query SyncClientPrescriptionAnalises(
  $filter: ModelClientPrescriptionAnalisisFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncClientPrescriptionAnalises(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      clientPrescriptionID
      name
      description
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.SyncClientPrescriptionAnalisesQueryVariables,
	APITypes.SyncClientPrescriptionAnalisesQuery
>;
export const getDoctorLabImage = /* GraphQL */ `query GetDoctorLabImage($id: ID!) {
  getDoctorLabImage(id: $id) {
    id
    doctorID
    labImageID
    updatedAt
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    labImage {
      id
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetDoctorLabImageQueryVariables, APITypes.GetDoctorLabImageQuery>;
export const listDoctorLabImages = /* GraphQL */ `query ListDoctorLabImages(
  $filter: ModelDoctorLabImageFilterInput
  $limit: Int
  $nextToken: String
) {
  listDoctorLabImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      doctorID
      labImageID
      updatedAt
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      labImage {
        id
        name
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListDoctorLabImagesQueryVariables, APITypes.ListDoctorLabImagesQuery>;
export const syncDoctorLabImages = /* GraphQL */ `query SyncDoctorLabImages(
  $filter: ModelDoctorLabImageFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncDoctorLabImages(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      doctorID
      labImageID
      updatedAt
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      labImage {
        id
        name
        description
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncDoctorLabImagesQueryVariables, APITypes.SyncDoctorLabImagesQuery>;
export const getLabImage = /* GraphQL */ `query GetLabImage($id: ID!) {
  getLabImage(id: $id) {
    id
    name
    description
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetLabImageQueryVariables, APITypes.GetLabImageQuery>;
export const listLabImages = /* GraphQL */ `query ListLabImages(
  $filter: ModelLabImageFilterInput
  $limit: Int
  $nextToken: String
) {
  listLabImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListLabImagesQueryVariables, APITypes.ListLabImagesQuery>;
export const syncLabImages = /* GraphQL */ `query SyncLabImages(
  $filter: ModelLabImageFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncLabImages(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncLabImagesQueryVariables, APITypes.SyncLabImagesQuery>;
export const getPharmacyMedicine = /* GraphQL */ `query GetPharmacyMedicine($id: ID!) {
  getPharmacyMedicine(id: $id) {
    id
    name
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetPharmacyMedicineQueryVariables, APITypes.GetPharmacyMedicineQuery>;
export const listPharmacyMedicines = /* GraphQL */ `query ListPharmacyMedicines(
  $filter: ModelPharmacyMedicineFilterInput
  $limit: Int
  $nextToken: String
) {
  listPharmacyMedicines(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.ListPharmacyMedicinesQueryVariables,
	APITypes.ListPharmacyMedicinesQuery
>;
export const syncPharmacyMedicines = /* GraphQL */ `query SyncPharmacyMedicines(
  $filter: ModelPharmacyMedicineFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPharmacyMedicines(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.SyncPharmacyMedicinesQueryVariables,
	APITypes.SyncPharmacyMedicinesQuery
>;
export const getPharmacyMedicineMeasure =
	/* GraphQL */ `query GetPharmacyMedicineMeasure($id: ID!) {
  getPharmacyMedicineMeasure(id: $id) {
    id
    pharmacyMedicineID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
		APITypes.GetPharmacyMedicineMeasureQueryVariables,
		APITypes.GetPharmacyMedicineMeasureQuery
	>;
export const listPharmacyMedicineMeasures = /* GraphQL */ `query ListPharmacyMedicineMeasures(
  $filter: ModelPharmacyMedicineMeasureFilterInput
  $limit: Int
  $nextToken: String
) {
  listPharmacyMedicineMeasures(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      pharmacyMedicineID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.ListPharmacyMedicineMeasuresQueryVariables,
	APITypes.ListPharmacyMedicineMeasuresQuery
>;
export const syncPharmacyMedicineMeasures = /* GraphQL */ `query SyncPharmacyMedicineMeasures(
  $filter: ModelPharmacyMedicineMeasureFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPharmacyMedicineMeasures(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      pharmacyMedicineID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.SyncPharmacyMedicineMeasuresQueryVariables,
	APITypes.SyncPharmacyMedicineMeasuresQuery
>;
export const getTemplate = /* GraphQL */ `query GetTemplate($id: ID!) {
  getTemplate(id: $id) {
    id
    doctorID
    templateName
    templateType
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTemplateQueryVariables, APITypes.GetTemplateQuery>;
export const listTemplates = /* GraphQL */ `query ListTemplates(
  $filter: ModelTemplateFilterInput
  $limit: Int
  $nextToken: String
) {
  listTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      doctorID
      templateName
      templateType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListTemplatesQueryVariables, APITypes.ListTemplatesQuery>;
export const syncTemplates = /* GraphQL */ `query SyncTemplates(
  $filter: ModelTemplateFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTemplates(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      doctorID
      templateName
      templateType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncTemplatesQueryVariables, APITypes.SyncTemplatesQuery>;
export const getTemplateMedicine = /* GraphQL */ `query GetTemplateMedicine($id: ID!) {
  getTemplateMedicine(id: $id) {
    id
    templateID
    medicineID
    take
    indication
    duration
    description
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTemplateMedicineQueryVariables, APITypes.GetTemplateMedicineQuery>;
export const listTemplateMedicines = /* GraphQL */ `query ListTemplateMedicines(
  $filter: ModelTemplateMedicineFilterInput
  $limit: Int
  $nextToken: String
) {
  listTemplateMedicines(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      templateID
      medicineID
      take
      indication
      duration
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.ListTemplateMedicinesQueryVariables,
	APITypes.ListTemplateMedicinesQuery
>;
export const syncTemplateMedicines = /* GraphQL */ `query SyncTemplateMedicines(
  $filter: ModelTemplateMedicineFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTemplateMedicines(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      templateID
      medicineID
      take
      indication
      duration
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.SyncTemplateMedicinesQueryVariables,
	APITypes.SyncTemplateMedicinesQuery
>;
export const getDoctorRecentDiagnosis = /* GraphQL */ `query GetDoctorRecentDiagnosis($id: ID!) {
  getDoctorRecentDiagnosis(id: $id) {
    id
    doctorID
    name
    code
    type
    updateDateTime
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.GetDoctorRecentDiagnosisQueryVariables,
	APITypes.GetDoctorRecentDiagnosisQuery
>;
export const listDoctorRecentDiagnosiss = /* GraphQL */ `query ListDoctorRecentDiagnosiss(
  $filter: ModelDoctorRecentDiagnosisFilterInput
  $limit: Int
  $nextToken: String
) {
  listDoctorRecentDiagnosiss(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      doctorID
      name
      code
      type
      updateDateTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.ListDoctorRecentDiagnosissQueryVariables,
	APITypes.ListDoctorRecentDiagnosissQuery
>;
export const syncDoctorRecentDiagnoses = /* GraphQL */ `query SyncDoctorRecentDiagnoses(
  $filter: ModelDoctorRecentDiagnosisFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncDoctorRecentDiagnoses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      doctorID
      name
      code
      type
      updateDateTime
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.SyncDoctorRecentDiagnosesQueryVariables,
	APITypes.SyncDoctorRecentDiagnosesQuery
>;
export const getTemplateLabImage = /* GraphQL */ `query GetTemplateLabImage($id: ID!) {
  getTemplateLabImage(id: $id) {
    id
    templateID
    name
    description
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTemplateLabImageQueryVariables, APITypes.GetTemplateLabImageQuery>;
export const listTemplateLabImages = /* GraphQL */ `query ListTemplateLabImages(
  $filter: ModelTemplateLabImageFilterInput
  $limit: Int
  $nextToken: String
) {
  listTemplateLabImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      templateID
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.ListTemplateLabImagesQueryVariables,
	APITypes.ListTemplateLabImagesQuery
>;
export const syncTemplateLabImages = /* GraphQL */ `query SyncTemplateLabImages(
  $filter: ModelTemplateLabImageFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTemplateLabImages(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      templateID
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.SyncTemplateLabImagesQueryVariables,
	APITypes.SyncTemplateLabImagesQuery
>;
export const getDoctorMedicine = /* GraphQL */ `query GetDoctorMedicine($id: ID!) {
  getDoctorMedicine(id: $id) {
    id
    doctorID
    medicineID
    isDeleted
    updatedAt
    _version
    _deleted
    _lastChangedAt
    createdAt
    medicine {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetDoctorMedicineQueryVariables, APITypes.GetDoctorMedicineQuery>;
export const listDoctorMedicines = /* GraphQL */ `query ListDoctorMedicines(
  $filter: ModelDoctorMedicineFilterInput
  $limit: Int
  $nextToken: String
) {
  listDoctorMedicines(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      doctorID
      medicineID
      isDeleted
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      medicine {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListDoctorMedicinesQueryVariables, APITypes.ListDoctorMedicinesQuery>;
export const syncDoctorMedicines = /* GraphQL */ `query SyncDoctorMedicines(
  $filter: ModelDoctorMedicineFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncDoctorMedicines(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      doctorID
      medicineID
      isDeleted
      updatedAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      medicine {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncDoctorMedicinesQueryVariables, APITypes.SyncDoctorMedicinesQuery>;
export const getRecentDoctorMedicinePrescription =
	/* GraphQL */ `query GetRecentDoctorMedicinePrescription($id: ID!) {
  getRecentDoctorMedicinePrescription(id: $id) {
    id
    doctorID
    medicineID
    take
    indication
    duration
    description
    updateDateTime
    createdAt
    updatedAt
    status
    _version
    _deleted
    _lastChangedAt
    medicine {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
		APITypes.GetRecentDoctorMedicinePrescriptionQueryVariables,
		APITypes.GetRecentDoctorMedicinePrescriptionQuery
	>;
export const listRecentDoctorMedicinePrescriptions =
	/* GraphQL */ `query ListRecentDoctorMedicinePrescriptions(
  $filter: ModelRecentDoctorMedicinePrescriptionFilterInput
  $limit: Int
  $nextToken: String
) {
  listRecentDoctorMedicinePrescriptions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      doctorID
      medicineID
      take
      indication
      duration
      description
      updateDateTime
      createdAt
      updatedAt
      status
      _version
      _deleted
      _lastChangedAt
      medicine {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
		APITypes.ListRecentDoctorMedicinePrescriptionsQueryVariables,
		APITypes.ListRecentDoctorMedicinePrescriptionsQuery
	>;
export const syncRecentDoctorMedicinePrescriptions =
	/* GraphQL */ `query SyncRecentDoctorMedicinePrescriptions(
  $filter: ModelRecentDoctorMedicinePrescriptionFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncRecentDoctorMedicinePrescriptions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      doctorID
      medicineID
      take
      indication
      duration
      description
      updateDateTime
      createdAt
      updatedAt
      status
      _version
      _deleted
      _lastChangedAt
      medicine {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
		APITypes.SyncRecentDoctorMedicinePrescriptionsQueryVariables,
		APITypes.SyncRecentDoctorMedicinePrescriptionsQuery
	>;
export const getRecentDoctorLabImagePrescription =
	/* GraphQL */ `query GetRecentDoctorLabImagePrescription($id: ID!) {
  getRecentDoctorLabImagePrescription(id: $id) {
    id
    doctorID
    labImageID
    name
    description
    updateDateTime
    createdAt
    updatedAt
    status
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
		APITypes.GetRecentDoctorLabImagePrescriptionQueryVariables,
		APITypes.GetRecentDoctorLabImagePrescriptionQuery
	>;
export const listRecentDoctorLabImagePrescriptions =
	/* GraphQL */ `query ListRecentDoctorLabImagePrescriptions(
  $filter: ModelRecentDoctorLabImagePrescriptionFilterInput
  $limit: Int
  $nextToken: String
) {
  listRecentDoctorLabImagePrescriptions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      doctorID
      labImageID
      name
      description
      updateDateTime
      createdAt
      updatedAt
      status
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
		APITypes.ListRecentDoctorLabImagePrescriptionsQueryVariables,
		APITypes.ListRecentDoctorLabImagePrescriptionsQuery
	>;
export const syncRecentDoctorLabImagePrescriptions =
	/* GraphQL */ `query SyncRecentDoctorLabImagePrescriptions(
  $filter: ModelRecentDoctorLabImagePrescriptionFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncRecentDoctorLabImagePrescriptions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      doctorID
      labImageID
      name
      description
      updateDateTime
      createdAt
      updatedAt
      status
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
		APITypes.SyncRecentDoctorLabImagePrescriptionsQueryVariables,
		APITypes.SyncRecentDoctorLabImagePrescriptionsQuery
	>;
export const getTemplateAnalisis = /* GraphQL */ `query GetTemplateAnalisis($id: ID!) {
  getTemplateAnalisis(id: $id) {
    id
    templateID
    analisisID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTemplateAnalisisQueryVariables, APITypes.GetTemplateAnalisisQuery>;
export const listTemplateAnalisiss = /* GraphQL */ `query ListTemplateAnalisiss(
  $filter: ModelTemplateAnalisisFilterInput
  $limit: Int
  $nextToken: String
) {
  listTemplateAnalisiss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      templateID
      analisisID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.ListTemplateAnalisissQueryVariables,
	APITypes.ListTemplateAnalisissQuery
>;
export const syncTemplateAnalises = /* GraphQL */ `query SyncTemplateAnalises(
  $filter: ModelTemplateAnalisisFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTemplateAnalises(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      templateID
      analisisID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.SyncTemplateAnalisesQueryVariables,
	APITypes.SyncTemplateAnalisesQuery
>;
export const getAnalisis = /* GraphQL */ `query GetAnalisis($id: ID!) {
  getAnalisis(id: $id) {
    id
    analisisName
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetAnalisisQueryVariables, APITypes.GetAnalisisQuery>;
export const listAnalisiss = /* GraphQL */ `query ListAnalisiss(
  $filter: ModelAnalisisFilterInput
  $limit: Int
  $nextToken: String
) {
  listAnalisiss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      analisisName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListAnalisissQueryVariables, APITypes.ListAnalisissQuery>;
export const syncAnalises = /* GraphQL */ `query SyncAnalises(
  $filter: ModelAnalisisFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncAnalises(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      analisisName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncAnalisesQueryVariables, APITypes.SyncAnalisesQuery>;
export const getClientActiveDisease = /* GraphQL */ `query GetClientActiveDisease($id: ID!) {
  getClientActiveDisease(id: $id) {
    id
    clientID
    name
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.GetClientActiveDiseaseQueryVariables,
	APITypes.GetClientActiveDiseaseQuery
>;
export const listClientActiveDiseases = /* GraphQL */ `query ListClientActiveDiseases(
  $filter: ModelClientActiveDiseaseFilterInput
  $limit: Int
  $nextToken: String
) {
  listClientActiveDiseases(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      clientID
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.ListClientActiveDiseasesQueryVariables,
	APITypes.ListClientActiveDiseasesQuery
>;
export const syncClientActiveDiseases = /* GraphQL */ `query SyncClientActiveDiseases(
  $filter: ModelClientActiveDiseaseFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncClientActiveDiseases(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      clientID
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.SyncClientActiveDiseasesQueryVariables,
	APITypes.SyncClientActiveDiseasesQuery
>;
export const getClientHistory = /* GraphQL */ `query GetClientHistory($id: ID!) {
  getClientHistory(id: $id) {
    id
    clientHospitalDoctorID
    title
    treatment
    endModifyDate
    authorizationNumber
    description
    authorizationDetail
    personalPathologicalHistory
    familyPathologicalHistory
    physicalTest
    createdDate
    insuranceID
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetClientHistoryQueryVariables, APITypes.GetClientHistoryQuery>;
export const listClientHistorys = /* GraphQL */ `query ListClientHistorys(
  $filter: ModelClientHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listClientHistorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      clientHospitalDoctorID
      title
      treatment
      endModifyDate
      authorizationNumber
      description
      authorizationDetail
      personalPathologicalHistory
      familyPathologicalHistory
      physicalTest
      createdDate
      insuranceID
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListClientHistorysQueryVariables, APITypes.ListClientHistorysQuery>;
export const syncClientHistories = /* GraphQL */ `query SyncClientHistories(
  $filter: ModelClientHistoryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncClientHistories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      clientHospitalDoctorID
      title
      treatment
      endModifyDate
      authorizationNumber
      description
      authorizationDetail
      personalPathologicalHistory
      familyPathologicalHistory
      physicalTest
      createdDate
      insuranceID
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncClientHistoriesQueryVariables, APITypes.SyncClientHistoriesQuery>;
export const getClientNotes = /* GraphQL */ `query GetClientNotes($id: ID!) {
  getClientNotes(id: $id) {
    id
    clientHospitalDoctorID
    title
    noteBody
    endModifyDate
    createdDate
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetClientNotesQueryVariables, APITypes.GetClientNotesQuery>;
export const listClientNotess = /* GraphQL */ `query ListClientNotess(
  $filter: ModelClientNotesFilterInput
  $limit: Int
  $nextToken: String
) {
  listClientNotess(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      clientHospitalDoctorID
      title
      noteBody
      endModifyDate
      createdDate
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListClientNotessQueryVariables, APITypes.ListClientNotessQuery>;
export const syncClientNotes = /* GraphQL */ `query SyncClientNotes(
  $filter: ModelClientNotesFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncClientNotes(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      clientHospitalDoctorID
      title
      noteBody
      endModifyDate
      createdDate
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncClientNotesQueryVariables, APITypes.SyncClientNotesQuery>;
export const getClientProcedureSurgery = /* GraphQL */ `query GetClientProcedureSurgery($id: ID!) {
  getClientProcedureSurgery(id: $id) {
    id
    clientHospitalDoctorID
    title
    description
    endModifyDate
    createdDate
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.GetClientProcedureSurgeryQueryVariables,
	APITypes.GetClientProcedureSurgeryQuery
>;
export const listClientProcedureSurgerys = /* GraphQL */ `query ListClientProcedureSurgerys(
  $filter: ModelClientProcedureSurgeryFilterInput
  $limit: Int
  $nextToken: String
) {
  listClientProcedureSurgerys(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      clientHospitalDoctorID
      title
      description
      endModifyDate
      createdDate
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.ListClientProcedureSurgerysQueryVariables,
	APITypes.ListClientProcedureSurgerysQuery
>;
export const syncClientProcedureSurgeries = /* GraphQL */ `query SyncClientProcedureSurgeries(
  $filter: ModelClientProcedureSurgeryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncClientProcedureSurgeries(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      clientHospitalDoctorID
      title
      description
      endModifyDate
      createdDate
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.SyncClientProcedureSurgeriesQueryVariables,
	APITypes.SyncClientProcedureSurgeriesQuery
>;
export const getDoctor = /* GraphQL */ `query GetDoctor($id: ID!) {
  getDoctor(id: $id) {
    id
    name
    specialty
    exequator
    email
    firstTime
    studyPlace
    isBeenReview
    isFirstAddSecretary
    hasTrialOnCreation
    title
    phone
    code
    password
    isAdmin
    daysOfTrial
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetDoctorQueryVariables, APITypes.GetDoctorQuery>;
export const listDoctors = /* GraphQL */ `query ListDoctors(
  $filter: ModelDoctorFilterInput
  $limit: Int
  $nextToken: String
) {
  listDoctors(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      specialty
      exequator
      email
      firstTime
      studyPlace
      isBeenReview
      isFirstAddSecretary
      hasTrialOnCreation
      title
      phone
      code
      password
      isAdmin
      daysOfTrial
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListDoctorsQueryVariables, APITypes.ListDoctorsQuery>;
export const syncDoctors = /* GraphQL */ `query SyncDoctors(
  $filter: ModelDoctorFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncDoctors(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      specialty
      exequator
      email
      firstTime
      studyPlace
      isBeenReview
      isFirstAddSecretary
      hasTrialOnCreation
      title
      phone
      code
      password
      isAdmin
      daysOfTrial
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncDoctorsQueryVariables, APITypes.SyncDoctorsQuery>;
export const getPlan = /* GraphQL */ `query GetPlan($id: ID!) {
  getPlan(id: $id) {
    id
    refID
    title
    subTitle
    stripePlanKey
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetPlanQueryVariables, APITypes.GetPlanQuery>;
export const listPlans = /* GraphQL */ `query ListPlans(
  $filter: ModelPlanFilterInput
  $limit: Int
  $nextToken: String
) {
  listPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      refID
      title
      subTitle
      stripePlanKey
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListPlansQueryVariables, APITypes.ListPlansQuery>;
export const syncPlans = /* GraphQL */ `query SyncPlans(
  $filter: ModelPlanFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPlans(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      refID
      title
      subTitle
      stripePlanKey
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncPlansQueryVariables, APITypes.SyncPlansQuery>;
export const getDoctorStripeCustomerSubscription =
	/* GraphQL */ `query GetDoctorStripeCustomerSubscription($id: ID!) {
  getDoctorStripeCustomerSubscription(id: $id) {
    id
    doctorID
    currentPlanID
    stripeCustomerID
    stripeSubscriptionID
    defaultCard
    nextPaymentDate
    cancelAtPeriodEnd
    trialsEndsAt
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
		APITypes.GetDoctorStripeCustomerSubscriptionQueryVariables,
		APITypes.GetDoctorStripeCustomerSubscriptionQuery
	>;
export const listDoctorStripeCustomerSubscriptions =
	/* GraphQL */ `query ListDoctorStripeCustomerSubscriptions(
  $filter: ModelDoctorStripeCustomerSubscriptionFilterInput
  $limit: Int
  $nextToken: String
) {
  listDoctorStripeCustomerSubscriptions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      doctorID
      currentPlanID
      stripeCustomerID
      stripeSubscriptionID
      defaultCard
      nextPaymentDate
      cancelAtPeriodEnd
      trialsEndsAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
		APITypes.ListDoctorStripeCustomerSubscriptionsQueryVariables,
		APITypes.ListDoctorStripeCustomerSubscriptionsQuery
	>;
export const syncDoctorStripeCustomerSubscriptions =
	/* GraphQL */ `query SyncDoctorStripeCustomerSubscriptions(
  $filter: ModelDoctorStripeCustomerSubscriptionFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncDoctorStripeCustomerSubscriptions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      doctorID
      currentPlanID
      stripeCustomerID
      stripeSubscriptionID
      defaultCard
      nextPaymentDate
      cancelAtPeriodEnd
      trialsEndsAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
		APITypes.SyncDoctorStripeCustomerSubscriptionsQueryVariables,
		APITypes.SyncDoctorStripeCustomerSubscriptionsQuery
	>;
export const getDoctorStripeCards = /* GraphQL */ `query GetDoctorStripeCards($id: ID!) {
  getDoctorStripeCards(id: $id) {
    id
    DoctorID
    cardLastDigits
    stripePaymentMethodID
    defaultCard
    cardBrand
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.GetDoctorStripeCardsQueryVariables,
	APITypes.GetDoctorStripeCardsQuery
>;
export const listDoctorStripeCardss = /* GraphQL */ `query ListDoctorStripeCardss(
  $filter: ModelDoctorStripeCardsFilterInput
  $limit: Int
  $nextToken: String
) {
  listDoctorStripeCardss(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      DoctorID
      cardLastDigits
      stripePaymentMethodID
      defaultCard
      cardBrand
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.ListDoctorStripeCardssQueryVariables,
	APITypes.ListDoctorStripeCardssQuery
>;
export const syncDoctorStripeCards = /* GraphQL */ `query SyncDoctorStripeCards(
  $filter: ModelDoctorStripeCardsFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncDoctorStripeCards(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      DoctorID
      cardLastDigits
      stripePaymentMethodID
      defaultCard
      cardBrand
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.SyncDoctorStripeCardsQueryVariables,
	APITypes.SyncDoctorStripeCardsQuery
>;
export const getHospital = /* GraphQL */ `query GetHospital($id: ID!) {
  getHospital(id: $id) {
    id
    name
    type
    province
    town
    sector
    phone
    address
    pssCode
    rnc
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetHospitalQueryVariables, APITypes.GetHospitalQuery>;
export const listHospitals = /* GraphQL */ `query ListHospitals(
  $filter: ModelHospitalFilterInput
  $limit: Int
  $nextToken: String
) {
  listHospitals(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      type
      province
      town
      sector
      phone
      address
      pssCode
      rnc
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListHospitalsQueryVariables, APITypes.ListHospitalsQuery>;
export const syncHospitals = /* GraphQL */ `query SyncHospitals(
  $filter: ModelHospitalFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncHospitals(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      type
      province
      town
      sector
      phone
      address
      pssCode
      rnc
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncHospitalsQueryVariables, APITypes.SyncHospitalsQuery>;
export const getHospitalDoctor = /* GraphQL */ `query GetHospitalDoctor($id: ID!) {
  getHospitalDoctor(id: $id) {
    id
    doctorID
    hospitalID
    lastWaitingListID
    hospitalLogo
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    doctor {
      id
      name
      specialty
      exequator
      email
      firstTime
      studyPlace
      isBeenReview
      isFirstAddSecretary
      hasTrialOnCreation
      title
      phone
      code
      password
      isAdmin
      daysOfTrial
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    hospital {
      id
      name
      type
      province
      town
      sector
      phone
      address
      pssCode
      rnc
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetHospitalDoctorQueryVariables, APITypes.GetHospitalDoctorQuery>;
export const listHospitalDoctors = /* GraphQL */ `query ListHospitalDoctors(
  $filter: ModelHospitalDoctorFilterInput
  $limit: Int
  $nextToken: String
) {
  listHospitalDoctors(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      doctorID
      hospitalID
      lastWaitingListID
      hospitalLogo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospital {
        id
        name
        type
        province
        town
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListHospitalDoctorsQueryVariables, APITypes.ListHospitalDoctorsQuery>;
export const syncHospitalDoctors = /* GraphQL */ `query SyncHospitalDoctors(
  $filter: ModelHospitalDoctorFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncHospitalDoctors(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      doctorID
      hospitalID
      lastWaitingListID
      hospitalLogo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospital {
        id
        name
        type
        province
        town
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncHospitalDoctorsQueryVariables, APITypes.SyncHospitalDoctorsQuery>;
export const getTeams = /* GraphQL */ `query GetTeams($id: ID!) {
  getTeams(id: $id) {
    id
    OwnerID
    MemberID
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    Owner {
      id
      doctorID
      hospitalID
      lastWaitingListID
      hospitalLogo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospital {
        id
        name
        type
        province
        town
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    Member {
      id
      doctorID
      hospitalID
      lastWaitingListID
      hospitalLogo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospital {
        id
        name
        type
        province
        town
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTeamsQueryVariables, APITypes.GetTeamsQuery>;
export const listTeamss = /* GraphQL */ `query ListTeamss(
  $filter: ModelTeamsFilterInput
  $limit: Int
  $nextToken: String
) {
  listTeamss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      OwnerID
      MemberID
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Owner {
        id
        doctorID
        hospitalID
        lastWaitingListID
        hospitalLogo
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        doctor {
          id
          name
          specialty
          exequator
          email
          firstTime
          studyPlace
          isBeenReview
          isFirstAddSecretary
          hasTrialOnCreation
          title
          phone
          code
          password
          isAdmin
          daysOfTrial
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        hospital {
          id
          name
          type
          province
          town
          sector
          phone
          address
          pssCode
          rnc
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      Member {
        id
        doctorID
        hospitalID
        lastWaitingListID
        hospitalLogo
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        doctor {
          id
          name
          specialty
          exequator
          email
          firstTime
          studyPlace
          isBeenReview
          isFirstAddSecretary
          hasTrialOnCreation
          title
          phone
          code
          password
          isAdmin
          daysOfTrial
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        hospital {
          id
          name
          type
          province
          town
          sector
          phone
          address
          pssCode
          rnc
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListTeamssQueryVariables, APITypes.ListTeamssQuery>;
export const syncTeams = /* GraphQL */ `query SyncTeams(
  $filter: ModelTeamsFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTeams(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      OwnerID
      MemberID
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      Owner {
        id
        doctorID
        hospitalID
        lastWaitingListID
        hospitalLogo
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        doctor {
          id
          name
          specialty
          exequator
          email
          firstTime
          studyPlace
          isBeenReview
          isFirstAddSecretary
          hasTrialOnCreation
          title
          phone
          code
          password
          isAdmin
          daysOfTrial
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        hospital {
          id
          name
          type
          province
          town
          sector
          phone
          address
          pssCode
          rnc
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      Member {
        id
        doctorID
        hospitalID
        lastWaitingListID
        hospitalLogo
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        doctor {
          id
          name
          specialty
          exequator
          email
          firstTime
          studyPlace
          isBeenReview
          isFirstAddSecretary
          hasTrialOnCreation
          title
          phone
          code
          password
          isAdmin
          daysOfTrial
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        hospital {
          id
          name
          type
          province
          town
          sector
          phone
          address
          pssCode
          rnc
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncTeamsQueryVariables, APITypes.SyncTeamsQuery>;
export const getHospitalDoctorCliente = /* GraphQL */ `query GetHospitalDoctorCliente($id: ID!) {
  getHospitalDoctorCliente(id: $id) {
    id
    clientID
    hospitalDoctorID
    lastHealthInsurranceID
    clientStatus
    note
    recordNo
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    client {
      id
      identificationName
      identificationData
      actualInsurance
      name
      lastName
      cellphoneNumber
      email
      bornDate
      parentOrTutorCedula
      parentOrTutorID
      gender
      phoneNumber
      addressStreet
      city
      isAdult
      sector
      bloodType
      company
      vitalSignsHeight
      vitalSignsWeight
      bodyMass
      vitalSignsBloodPressure
      heartRate
      respiratoryRate
      bodyTemperature
      oxygenSaturation
      occupation
      profileImage
      heartBeat
      weight
      height
      bloodPressure
      updateDateTime
      createdAt
      updatedAt
      code
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    hospitalDoctor {
      id
      doctorID
      hospitalID
      lastWaitingListID
      hospitalLogo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospital {
        id
        name
        type
        province
        town
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    clientInsurance {
      id
      insuranceID
      clientID
      contractNumber
      affiliateNumber
      affiliateType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      insurance {
        id
        name
        allowClaim
        code
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
	APITypes.GetHospitalDoctorClienteQueryVariables,
	APITypes.GetHospitalDoctorClienteQuery
>;
export const listHospitalDoctorClientes = /* GraphQL */ `query ListHospitalDoctorClientes(
  $filter: ModelHospitalDoctorClienteFilterInput
  $limit: Int
  $nextToken: String
) {
  listHospitalDoctorClientes(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      clientID
      hospitalDoctorID
      lastHealthInsurranceID
      clientStatus
      note
      recordNo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      client {
        id
        identificationName
        identificationData
        actualInsurance
        name
        lastName
        cellphoneNumber
        email
        bornDate
        parentOrTutorCedula
        parentOrTutorID
        gender
        phoneNumber
        addressStreet
        city
        isAdult
        sector
        bloodType
        company
        vitalSignsHeight
        vitalSignsWeight
        bodyMass
        vitalSignsBloodPressure
        heartRate
        respiratoryRate
        bodyTemperature
        oxygenSaturation
        occupation
        profileImage
        heartBeat
        weight
        height
        bloodPressure
        updateDateTime
        createdAt
        updatedAt
        code
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      hospitalDoctor {
        id
        doctorID
        hospitalID
        lastWaitingListID
        hospitalLogo
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        doctor {
          id
          name
          specialty
          exequator
          email
          firstTime
          studyPlace
          isBeenReview
          isFirstAddSecretary
          hasTrialOnCreation
          title
          phone
          code
          password
          isAdmin
          daysOfTrial
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        hospital {
          id
          name
          type
          province
          town
          sector
          phone
          address
          pssCode
          rnc
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      clientInsurance {
        id
        insuranceID
        clientID
        contractNumber
        affiliateNumber
        affiliateType
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        insurance {
          id
          name
          allowClaim
          code
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.ListHospitalDoctorClientesQueryVariables,
	APITypes.ListHospitalDoctorClientesQuery
>;
export const syncHospitalDoctorClientes = /* GraphQL */ `query SyncHospitalDoctorClientes(
  $filter: ModelHospitalDoctorClienteFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncHospitalDoctorClientes(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      clientID
      hospitalDoctorID
      lastHealthInsurranceID
      clientStatus
      note
      recordNo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      client {
        id
        identificationName
        identificationData
        actualInsurance
        name
        lastName
        cellphoneNumber
        email
        bornDate
        parentOrTutorCedula
        parentOrTutorID
        gender
        phoneNumber
        addressStreet
        city
        isAdult
        sector
        bloodType
        company
        vitalSignsHeight
        vitalSignsWeight
        bodyMass
        vitalSignsBloodPressure
        heartRate
        respiratoryRate
        bodyTemperature
        oxygenSaturation
        occupation
        profileImage
        heartBeat
        weight
        height
        bloodPressure
        updateDateTime
        createdAt
        updatedAt
        code
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      hospitalDoctor {
        id
        doctorID
        hospitalID
        lastWaitingListID
        hospitalLogo
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        doctor {
          id
          name
          specialty
          exequator
          email
          firstTime
          studyPlace
          isBeenReview
          isFirstAddSecretary
          hasTrialOnCreation
          title
          phone
          code
          password
          isAdmin
          daysOfTrial
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        hospital {
          id
          name
          type
          province
          town
          sector
          phone
          address
          pssCode
          rnc
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      clientInsurance {
        id
        insuranceID
        clientID
        contractNumber
        affiliateNumber
        affiliateType
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        insurance {
          id
          name
          allowClaim
          code
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.SyncHospitalDoctorClientesQueryVariables,
	APITypes.SyncHospitalDoctorClientesQuery
>;
export const getWaitList = /* GraphQL */ `query GetWaitList($id: ID!) {
  getWaitList(id: $id) {
    id
    hospitalDoctorID
    createdAt
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetWaitListQueryVariables, APITypes.GetWaitListQuery>;
export const listWaitLists = /* GraphQL */ `query ListWaitLists(
  $filter: ModelWaitListFilterInput
  $limit: Int
  $nextToken: String
) {
  listWaitLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      hospitalDoctorID
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListWaitListsQueryVariables, APITypes.ListWaitListsQuery>;
export const syncWaitLists = /* GraphQL */ `query SyncWaitLists(
  $filter: ModelWaitListFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncWaitLists(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      hospitalDoctorID
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncWaitListsQueryVariables, APITypes.SyncWaitListsQuery>;
export const getWaitingListItem = /* GraphQL */ `query GetWaitingListItem($id: ID!) {
  getWaitingListItem(id: $id) {
    id
    waitingListID
    clientID
    status
    clientHealthInsurrance
    positionNumber
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetWaitingListItemQueryVariables, APITypes.GetWaitingListItemQuery>;
export const listWaitingListItems = /* GraphQL */ `query ListWaitingListItems(
  $filter: ModelWaitingListItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listWaitingListItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      waitingListID
      clientID
      status
      clientHealthInsurrance
      positionNumber
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.ListWaitingListItemsQueryVariables,
	APITypes.ListWaitingListItemsQuery
>;
export const syncWaitingListItems = /* GraphQL */ `query SyncWaitingListItems(
  $filter: ModelWaitingListItemFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncWaitingListItems(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      waitingListID
      clientID
      status
      clientHealthInsurrance
      positionNumber
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.SyncWaitingListItemsQueryVariables,
	APITypes.SyncWaitingListItemsQuery
>;
export const getClientInsurance = /* GraphQL */ `query GetClientInsurance($id: ID!) {
  getClientInsurance(id: $id) {
    id
    insuranceID
    clientID
    contractNumber
    affiliateNumber
    affiliateType
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    insurance {
      id
      name
      allowClaim
      code
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetClientInsuranceQueryVariables, APITypes.GetClientInsuranceQuery>;
export const listClientInsurances = /* GraphQL */ `query ListClientInsurances(
  $filter: ModelClientInsuranceFilterInput
  $limit: Int
  $nextToken: String
) {
  listClientInsurances(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      insuranceID
      clientID
      contractNumber
      affiliateNumber
      affiliateType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      insurance {
        id
        name
        allowClaim
        code
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.ListClientInsurancesQueryVariables,
	APITypes.ListClientInsurancesQuery
>;
export const syncClientInsurances = /* GraphQL */ `query SyncClientInsurances(
  $filter: ModelClientInsuranceFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncClientInsurances(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      insuranceID
      clientID
      contractNumber
      affiliateNumber
      affiliateType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      insurance {
        id
        name
        allowClaim
        code
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
	APITypes.SyncClientInsurancesQueryVariables,
	APITypes.SyncClientInsurancesQuery
>;
export const getInsurance = /* GraphQL */ `query GetInsurance($id: ID!) {
  getInsurance(id: $id) {
    id
    name
    allowClaim
    code
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetInsuranceQueryVariables, APITypes.GetInsuranceQuery>;
export const listInsurances = /* GraphQL */ `query ListInsurances(
  $filter: ModelInsuranceFilterInput
  $limit: Int
  $nextToken: String
) {
  listInsurances(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      allowClaim
      code
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListInsurancesQueryVariables, APITypes.ListInsurancesQuery>;
export const syncInsurances = /* GraphQL */ `query SyncInsurances(
  $filter: ModelInsuranceFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncInsurances(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      allowClaim
      code
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncInsurancesQueryVariables, APITypes.SyncInsurancesQuery>;
export const getClientClaim = /* GraphQL */ `query GetClientClaim($id: ID!) {
  getClientClaim(id: $id) {
    id
    clientHospitalDoctorID
    healthInsuranceID
    diagnostic
    authorization
    date
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    healthInsurance {
      id
      name
      allowClaim
      code
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetClientClaimQueryVariables, APITypes.GetClientClaimQuery>;
export const listClientClaims = /* GraphQL */ `query ListClientClaims(
  $filter: ModelClientClaimFilterInput
  $limit: Int
  $nextToken: String
) {
  listClientClaims(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      clientHospitalDoctorID
      healthInsuranceID
      diagnostic
      authorization
      date
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      healthInsurance {
        id
        name
        allowClaim
        code
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListClientClaimsQueryVariables, APITypes.ListClientClaimsQuery>;
export const syncClientClaims = /* GraphQL */ `query SyncClientClaims(
  $filter: ModelClientClaimFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncClientClaims(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      clientHospitalDoctorID
      healthInsuranceID
      diagnostic
      authorization
      date
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      healthInsurance {
        id
        name
        allowClaim
        code
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncClientClaimsQueryVariables, APITypes.SyncClientClaimsQuery>;
export const getClaimProcess = /* GraphQL */ `query GetClaimProcess($id: ID!) {
  getClaimProcess(id: $id) {
    id
    claimID
    name
    code
    claimed
    difference
    authorized
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetClaimProcessQueryVariables, APITypes.GetClaimProcessQuery>;
export const listClaimProcesss = /* GraphQL */ `query ListClaimProcesss(
  $filter: ModelClaimProcessFilterInput
  $limit: Int
  $nextToken: String
) {
  listClaimProcesss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      claimID
      name
      code
      claimed
      difference
      authorized
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListClaimProcesssQueryVariables, APITypes.ListClaimProcesssQuery>;
export const syncClaimProcesses = /* GraphQL */ `query SyncClaimProcesses(
  $filter: ModelClaimProcessFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncClaimProcesses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      claimID
      name
      code
      claimed
      difference
      authorized
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncClaimProcessesQueryVariables, APITypes.SyncClaimProcessesQuery>;
export const getForms = /* GraphQL */ `query GetForms($id: ID!) {
  getForms(id: $id) {
    id
    DoctorID
    public
    name
    formImage
    description
    elements
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetFormsQueryVariables, APITypes.GetFormsQuery>;
export const listFormss = /* GraphQL */ `query ListFormss(
  $filter: ModelFormsFilterInput
  $limit: Int
  $nextToken: String
) {
  listFormss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      DoctorID
      public
      name
      formImage
      description
      elements
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListFormssQueryVariables, APITypes.ListFormssQuery>;
export const syncForms = /* GraphQL */ `query SyncForms(
  $filter: ModelFormsFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncForms(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      DoctorID
      public
      name
      formImage
      description
      elements
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncFormsQueryVariables, APITypes.SyncFormsQuery>;
export const getAppointment = /* GraphQL */ `query GetAppointment($id: ID!) {
  getAppointment(id: $id) {
    id
    clientID
    doctorID
    hospitalID
    description
    date
    start
    end
    status
    shouldSendReminder
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    client {
      id
      identificationName
      identificationData
      actualInsurance
      name
      lastName
      cellphoneNumber
      email
      bornDate
      parentOrTutorCedula
      parentOrTutorID
      gender
      phoneNumber
      addressStreet
      city
      isAdult
      sector
      bloodType
      company
      vitalSignsHeight
      vitalSignsWeight
      bodyMass
      vitalSignsBloodPressure
      heartRate
      respiratoryRate
      bodyTemperature
      oxygenSaturation
      occupation
      profileImage
      heartBeat
      weight
      height
      bloodPressure
      updateDateTime
      createdAt
      updatedAt
      code
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    doctor {
      id
      name
      specialty
      exequator
      email
      firstTime
      studyPlace
      isBeenReview
      isFirstAddSecretary
      hasTrialOnCreation
      title
      phone
      code
      password
      isAdmin
      daysOfTrial
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    hospital {
      id
      name
      type
      province
      town
      sector
      phone
      address
      pssCode
      rnc
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetAppointmentQueryVariables, APITypes.GetAppointmentQuery>;
export const listAppointments = /* GraphQL */ `query ListAppointments(
  $filter: ModelAppointmentFilterInput
  $limit: Int
  $nextToken: String
) {
  listAppointments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      clientID
      doctorID
      hospitalID
      description
      date
      start
      end
      status
      shouldSendReminder
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      client {
        id
        identificationName
        identificationData
        actualInsurance
        name
        lastName
        cellphoneNumber
        email
        bornDate
        parentOrTutorCedula
        parentOrTutorID
        gender
        phoneNumber
        addressStreet
        city
        isAdult
        sector
        bloodType
        company
        vitalSignsHeight
        vitalSignsWeight
        bodyMass
        vitalSignsBloodPressure
        heartRate
        respiratoryRate
        bodyTemperature
        oxygenSaturation
        occupation
        profileImage
        heartBeat
        weight
        height
        bloodPressure
        updateDateTime
        createdAt
        updatedAt
        code
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospital {
        id
        name
        type
        province
        town
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListAppointmentsQueryVariables, APITypes.ListAppointmentsQuery>;
export const syncAppointments = /* GraphQL */ `query SyncAppointments(
  $filter: ModelAppointmentFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncAppointments(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      clientID
      doctorID
      hospitalID
      description
      date
      start
      end
      status
      shouldSendReminder
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      client {
        id
        identificationName
        identificationData
        actualInsurance
        name
        lastName
        cellphoneNumber
        email
        bornDate
        parentOrTutorCedula
        parentOrTutorID
        gender
        phoneNumber
        addressStreet
        city
        isAdult
        sector
        bloodType
        company
        vitalSignsHeight
        vitalSignsWeight
        bodyMass
        vitalSignsBloodPressure
        heartRate
        respiratoryRate
        bodyTemperature
        oxygenSaturation
        occupation
        profileImage
        heartBeat
        weight
        height
        bloodPressure
        updateDateTime
        createdAt
        updatedAt
        code
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospital {
        id
        name
        type
        province
        town
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncAppointmentsQueryVariables, APITypes.SyncAppointmentsQuery>;
