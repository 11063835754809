import React from 'react';

const ExcelIcon = () => (
	<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M17.8181 25.8511V0.507812H15.7719C10.5162 1.45735 5.2747 2.33568 0 3.24724V27.2184C5.18924 28.1347 10.445 29.051 15.6674 30.0006H17.8039V25.8511H17.8181ZM10.0889 20.5906C9.43425 19.1908 8.86042 17.7547 8.37021 16.2892C7.89544 17.6803 7.24025 18.9906 6.70376 20.3532C5.95363 20.3532 5.20349 20.3105 4.45335 20.282C5.33168 18.5586 6.18151 16.8209 7.08833 15.1118C6.3192 13.3456 5.46936 11.6174 4.67649 9.86554L6.9459 9.73736C7.4539 11.0762 8.01413 12.4008 8.43668 13.7729C8.91145 12.3154 9.56188 10.9243 10.1411 9.53795C10.9166 9.48415 11.6936 9.43667 12.4722 9.39552C11.5607 11.2693 10.6412 13.1478 9.71381 15.0311C10.6634 16.9301 11.6129 18.853 12.5624 20.771C11.7363 20.7046 10.915 20.6523 10.0889 20.5906Z"
			fill="#07B284"
		/>
		<path
			d="M18.6836 3.79297V5.61134H22.3156V8.2938H18.6836V9.76558H22.3156V12.448H18.6836V13.9198H22.3156V16.5975H18.6836V18.0693H22.3156V20.728H18.6836V22.2853H22.3156V24.8823H18.6836V27.0002H31.227V3.79297H18.6836ZM28.4591 24.9013H23.1797V22.3043H28.4591V24.9013ZM28.4591 20.747H23.1797V18.0646H28.4591V20.747ZM28.4591 16.5928H23.1797V13.9151H28.4591V16.5928ZM28.4591 12.4385H23.1797V9.76084H28.4591V12.4385ZM28.4591 8.2843H23.1797V5.61134H28.4591V8.2843Z"
			fill="#07B284"
		/>
	</svg>
);

export default ExcelIcon;
