import React, { FC } from 'react';

const CloseIcon: FC<{
	width?: string;
	height?: string;
}> = ({ width, height }) => (
	<svg
		width={width || '15'}
		height={height || '15'}
		viewBox="0 0 25 25"
		fill="currentColor"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M6.78613 8.17569L11.055 12.4446L6.78613 16.7135C6.2924 17.2072 6.2848 18.0199 6.78613 18.5213C7.27986 19.015 8.09262 19.0074 8.58635 18.5137L12.8552 14.2448L17.1241 18.5137C17.6102 18.9998 18.423 19.0074 18.9167 18.5137C19.4105 18.0199 19.4029 17.2072 18.9167 16.721L14.6479 12.4522L18.9167 8.18329C19.4029 7.69715 19.4105 6.88439 18.9167 6.39066C18.4154 5.88933 17.6027 5.89693 17.1165 6.38307L12.8476 10.6519L8.57876 6.38307C8.09262 5.89693 7.27986 5.88933 6.78613 6.38307C6.2924 6.8768 6.29999 7.68956 6.78613 8.17569Z"
			fill="currentColor"
		/>
	</svg>
);

export default CloseIcon;
