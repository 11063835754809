import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { drawerWidth } from '../../shared/constants';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
		},
		menuButton: {
			marginRight: '10px',
		},
		hide: {
			display: 'none',
		},
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
			whiteSpace: 'nowrap',
		},
		drawerOpen: {
			width: drawerWidth,
			backgroundColor: '#1565c0',
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		subDrawerOpen: {
			width: drawerWidth,
			backgroundColor: '#FAFAFA',
			transition: theme.transitions.create(['left', 'width'], {
				easing: theme.transitions.easing.easeInOut,
				duration: theme.transitions.duration.enteringScreen + 200,
			}),
			left: '0px',
			top: '0px',
			marginTop: '14px',
		},
		subDrawerOpenHideSideBar: {
			width: drawerWidth,
			backgroundColor: '#FAFAFA',
			transition: theme.transitions.create(['left', 'width'], {
				easing: theme.transitions.easing.easeInOut,
				duration: theme.transitions.duration.enteringScreen + 200,
			}),
			left: '49px',
			top: '42px',
			position: 'absolute',
			height: 'calc(100dvh - 54px)',
		},
		subDrawerClose: {
			backgroundColor: '#FAFAFA',
			overflowX: 'hidden',
			width: 0,
		},
		drawerClose: {
			backgroundColor: '#1565c0',
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			overflowX: 'hidden',
			width: theme.spacing(7) + 1,
			[theme.breakpoints.up('sm')]: {
				width: theme.spacing(6) + 1,
			},
		},
		listItem: {
			padding: theme.spacing(1, 1.5),
		},
		subDrawerListItem: {
			color: '#000000DE',
			height: '38px',
			fontSize: '14px',
			padding: theme.spacing(1, 1.5),
			'&:hover': {
				backgroundColor: '#1976D214',
			},
			'&:focus': {
				backgroundColor: '#1976D214',
			},
			'&.Mui-selected': {
				backgroundColor: '#1976D214',
				fontFamily: 'Roboto',
				fontWeight: 500,
				fontSize: '14px',
				lineHeight: '157%',
				letterSpacing: '0.1px',
			},
		},
		subDrawerListText: {
			fontFamily: 'Roboto',
			color: '#000000DE',
			padding: theme.spacing(1, 1.5),
		},
		container: {
			display: 'flex',
			alignItems: 'center',
			width: '550px',
		},
		searchSection: {
			marginLeft: '16px',
		},
		searchSectionMobile: {
			width: 'auto',
			marginRight: '16px',
			padding: '16px 0',
		},
		actionSection: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			gap: '10px',
		},
		header: {
			display: 'flex',
			justifyContent: 'space-between',
			[theme.breakpoints.up('xs')]: {
				minHeight: '53px',
			},
		},
		toolbar: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			height: '53px',
			// necessary for content to be below app bar
			...theme.mixins.toolbar,
			[theme.breakpoints.up('xs')]: {
				minHeight: '53px',
			},
		},
		list: {
			padding: 0,
		},
		listContainer: {
			marginBottom: 'auto',
		},
		listIcon: {
			padding: 0,
			color: 'rgba(255, 255, 255, 0.56) !important',
		},
		searchIcon: {
			color: 'gray',
		},
		listText: {
			color: '#fff',
		},
		listTextMobile: {
			color: 'rgba(0, 0, 0, 0.87)',
		},
		listTextMobileSelected: {
			color: 'rgba(25, 118, 210, 1)',
			fontSize: '13px',
		},
		content: {
			flexGrow: 1,
			padding: theme.spacing(3),
		},
		menuContainer: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			height: '100%',
		},
		menuContainerMobile: {
			backgroundColor: 'white',
		},
	})
);
