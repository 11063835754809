// @ts-nocheck
import React, { useState, useContext, useEffect, useCallback } from 'react';
import SecretaryBox from './SecretaryBox';
import { RelationsContext } from '../../../context/relations-context';
import { UserContext } from '../../../context/user-context';
import { RelationsContextProps, SecretaryType, UserContextProps } from '../../../context/types';
import ModalConfirmation from '../../modalConfirmation/ModalConfirmation';
import { AddIconBlue } from '../../svg/AddIcon';
import AddIcon from '../../svg/AddIcon';
import ModalSecretary from '../../modalSecretary/ModalSecretary';
import SecretaryConfirmation from '../../modalSecretary/SecretaryConfirmation';
import { Container } from '@material-ui/core';
import useMobile from 'hooks/useMobile';
import { Tooltip, Typography } from '@material-ui/core';
import { StyledInfoOutlinedIcon } from 'components/clients/clientSummary/PatientSummary.style';
const Secretarys = () => {
	const relationsContext = useContext(RelationsContext) as RelationsContextProps;
	const userContext = useContext(UserContext) as UserContextProps;
	const [secretaries, setSecretaries] = useState<SecretaryType[]>([]);
	const [secretaryModal, setSecretaryModal] = useState(false);
	const [selectedSecretary, setSelectedSecretary] = useState<SecretaryType | null>(null);
	const [modalDeleteConfirmation, setModalDeleteConfirmation] = useState(false);
	const [openConfirmation, setOpenConfirmation] = useState(false);
	const { isMobile } = useMobile();
	const [openTooltip, setOpenTooltip] = useState(false);

	const getHospitalsAndSecretaries = useCallback(async () => {
		const secretaries = await relationsContext.fetchDoctorHospitalSecretarys();
		setSecretaries(secretaries);
	}, []);

	useEffect(() => {
		getHospitalsAndSecretaries();
	}, []);

	const upsertSecretaryHandler = async (secretary: SecretaryType) => {
		if (selectedSecretary === null) {
			const newSecretary = await userContext.upsertSecretary(secretary);

			if (newSecretary === null) {
				relationsContext.setAlertMessage({
					message: 'Error al crear secretaria',
					severity: 'error',
				});
				return;
			}

			setSecretaries((se) => [...se, newSecretary]);
			setOpenConfirmation(true);
		} else {
			const editedSecretary = await userContext.editSecretary({
				...selectedSecretary,
				...secretary,
			});
			setSecretaries((se) =>
				se.map((s) => (s.id === selectedSecretary.id ? editedSecretary : s))
			);
			setSelectedSecretary(null);
			relationsContext.setAlertMessage({
				message: 'Secretaria editada',
				severity: 'success',
			});
		}

		setSecretaryModal(false);
	};

	const deleteClickHandler = async (secretary: SecretaryType) => {
		setSelectedSecretary(secretary);
		setModalDeleteConfirmation(true);
	};

	const editClickHandler = async (secretary: SecretaryType) => {
		setSelectedSecretary(secretary);
		setSecretaryModal(true);
	};

	const deleteConfirmationHandler = async () => {
		await relationsContext.deleteSecretaryFromDoctor(selectedSecretary as SecretaryType);
		setSecretaries((se) => se.filter((s) => s.id !== selectedSecretary?.id));
		setSelectedSecretary(null);
		setModalDeleteConfirmation(false);
		relationsContext.setAlertMessage({
			message: 'Secretaria eliminada',
			severity: 'success',
		});

		setSecretaryModal(false);
	};

	const handleSecretaryModal = () => {
		setSecretaryModal(false);
		setSelectedSecretary(null);
	};

	const handleSecretaryConfirmation = () => {
		setOpenConfirmation(false);
	};

	const secretariesToRender = secretaries.map((sec) => {
		const name = sec.name
			? sec.name + `(${sec.email})`
			: sec.email + ' (pendiente de confirmación)';
		return (
			<SecretaryBox
				key={sec.id}
				name={name}
				editFunction={() => editClickHandler(sec)}
				deleteFunction={() => deleteClickHandler(sec)}
			/>
		);
	});

	return (
		<>
			<ModalConfirmation
				className={isMobile ? 'cancel-card-modal' : ''}
				approveFunction={deleteConfirmationHandler}
				modalState={modalDeleteConfirmation}
				closeFunction={() => setModalDeleteConfirmation(false)}
				name={'¿Estás seguro que quieres eliminar esta secretaria?'}
				validationLabel={selectedSecretary?.name ? selectedSecretary?.name : ''}
			/>
			{openConfirmation && (
				<SecretaryConfirmation
					open={openConfirmation}
					handleClose={handleSecretaryConfirmation}
				/>
			)}
			{secretaryModal && (
				<ModalSecretary
					open={secretaryModal}
					handleClose={handleSecretaryModal}
					secretary={selectedSecretary}
					onDelete={deleteClickHandler}
					onSubmit={upsertSecretaryHandler}
				/>
			)}
			<Container
				id="secretarys"
				style={{
					width: isMobile ? '100%' : 716,
					minHeight: 360,
					boxShadow:
						'0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)',
					borderRadius: '4px',
					backgroundColor: 'rgb(255, 255, 255)',
					padding: '0px 0px 30px 0px',
					...(isMobile
						? {
								marginBottom: '100px',
						  }
						: {}),
				}}
			>
				<div
					className="border-b-2 border-green-content py-4"
					style={{
						padding: '13px 16px',
					}}
				>
					<div
						className="border-b-2 border-green-content py-4"
						style={{
							padding: isMobile ? '16px 0' : '13px 16px',
						}}
					>
						<h1
							style={{
								fontFamily: 'Roboto',
								fontStyle: 'normal',
								display: 'flex',
								alignItems: 'center',
								fontWeight: '500',
								fontSize: '20px',
								lineHeight: '160%',
								letterSpacing: '0.15px',
								color: '#000000DE',
							}}
						>
							Secretarias
							<Tooltip
								open={openTooltip}
								onClose={() => setOpenTooltip(false)}
								onOpen={() => setOpenTooltip(true)}
								title={
									<Typography variant="body2">
										La secretaria no podrán ver el historial del paciente, crear
										recetas ni subir imagines.
									</Typography>
								}
								placement="top"
								arrow
							>
								<StyledInfoOutlinedIcon onClick={() => setOpenTooltip(true)} />
							</Tooltip>
						</h1>
					</div>
					<div
						style={
							isMobile
								? { padding: '0 0 16px' }
								: {
										padding: '20px 16px',
								  }
						}
					>
						{!isMobile && (
							<>
								<h2 className="raleway-font font-bold text-blueLucki-default text-lg mb-[8px] lg:mt-0 lg:mt-[8px]">
									Administradores
								</h2>
								<p
									className="OpenSansRegular"
									style={
										isMobile
											? { marginBottom: '20px' }
											: {
													marginTop: '0.5rem',
											  }
									}
								>
									Añade administradores de tus pacientes. Configura que pueden ver
									tus secretarias.
								</p>
							</>
						)}

						<div
							style={{
								overflowY: 'auto',
								padding: '8px 0px',
								height: isMobile ? '180px' : '145px',
								display: 'flex',
								flexDirection: 'column',
								gap: '10px',
								overflowY: 'auto',
							}}
						>
							{secretariesToRender}
						</div>

						<div
							style={{
								marginTop: '20px',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							<div className="flex justify-end w-full">
								<button
									className="OpenSansRegular text-[#1976D2] font-bold cursor-pointer uppercase flex gap-2 items-center"
									onClick={() => setSecretaryModal(true)}
								>
									<AddIconBlue />
									Añadir secretaria
								</button>
							</div>
						</div>
					</div>
				</div>
			</Container>
		</>
	);
};

export default Secretarys;
