import {
	Chip,
	FormControl,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import React, { FC } from 'react';
import { MultiSelectListItemText, MultiSelectCheckbox } from '../../shared/style';

const useStyles = makeStyles(() =>
	createStyles({
		formControl: {
			width: '100%',
		},
		chips: {
			display: 'flex',
			flexWrap: 'wrap',
		},
		chip: {
			margin: 1,
		},
	})
);

const ITEM_HEIGHT = 80;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

function getStyles(name: string, options: any[], theme: Theme) {
	return {
		fontWeight:
			options.indexOf(name) === -1
				? theme.typography.fontWeightRegular
				: theme.typography.fontWeightMedium,
	};
}

interface Props {
	id: string;
	label: string;
	selectedValues: string[];
	handleChange: (event: React.ChangeEvent<any>) => void;
	options: any[];
}

const CheckboxMultiSelect: FC<Props> = ({ id, label, selectedValues, handleChange, options }) => {
	const classes = useStyles();
	const theme = useTheme();

	return (
		<FormControl variant="outlined" size="small" className={classes.formControl}>
			<InputLabel htmlFor={id}>{label}</InputLabel>
			<Select
				labelId={id}
				multiple
				value={selectedValues}
				onChange={handleChange}
				input={<OutlinedInput id={id} label={label} />}
				renderValue={(selectedValues) => (
					<div className={classes.chips}>
						{(selectedValues as string[]).map((value) => (
							<Chip size="small" key={value} label={value} className={classes.chip} />
						))}
					</div>
				)}
				MenuProps={MenuProps}
			>
				{options.map((option) => {
					return (
						<MenuItem
							dense
							key={option}
							value={option}
							style={getStyles(option, selectedValues, theme)}
						>
							<MultiSelectCheckbox
								color="primary"
								checked={selectedValues.indexOf(option) > -1}
							/>
							<MultiSelectListItemText
								primary={
									<Typography component="span" variant="body1">
										{option}
									</Typography>
								}
							/>
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
};

export default CheckboxMultiSelect;
