import React from 'react';
import CustomDialog from '../dialog/CustomDialog';
import CircularProgress from '@material-ui/core/CircularProgress';

interface Props {
	modalState: boolean;
	closeFunction?: () => void;
	approveFunction?: () => void;
	name: string;
	validationLabel?: string;
	hideConfirmBtn?: boolean;
	confirmLabel?: string;
	cancelLabel?: string;
	cancelStyleColor?: 'secondary' | 'inherit' | 'default' | 'primary' | undefined;
	cancelVariant?: 'text' | 'contained' | 'outlined';
	agreeVariant?: 'text' | 'contained' | 'outlined';
	agreeTextColor?: string;
	loading?: boolean;
	className?: string;
}
const ModalConfirmation: React.FC<Props> = ({
	modalState,
	closeFunction,
	approveFunction,
	name,
	validationLabel = '',
	hideConfirmBtn,
	cancelLabel,
	confirmLabel,
	cancelStyleColor,
	agreeVariant,
	cancelVariant,
	agreeTextColor,
	loading,
	className,
}) => {
	return (
		<CustomDialog
			className={className}
			title={name}
			open={modalState}
			handleClose={() => closeFunction?.()}
			handleAgree={() => approveFunction?.()}
			maxWidth={'xs'}
			agreeText={!hideConfirmBtn ? confirmLabel || 'Confirmar' : ''}
			disagreeText={cancelLabel || 'Cancelar'}
			cancelStyleColor={cancelStyleColor}
			agreeTextColor={agreeTextColor}
			disabled={loading}
			loading={loading}
			agreeVariant={agreeVariant}
			cancelVariant={cancelVariant}
		>
			{validationLabel && (
				<div>
					<p className="text-lg">{validationLabel || ''}</p>
				</div>
			)}
		</CustomDialog>
	);
};

export default ModalConfirmation;
