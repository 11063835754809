import { TextField } from '@material-ui/core';
import React from 'react';
import CustomCard from '../../../card/CustomCard';
import { InputContainer, SecondContainer } from '../../../../shared/style';
import useMobile from 'hooks/useMobile';

interface Props {
	procedure: string;
	setProcedure: (data: string) => void;
	procedureDescription: string;
	setProcedureDescription: (data: string) => void;
}

const ProcedureForm: React.FC<Props> = ({
	procedure,
	setProcedure,
	procedureDescription,
	setProcedureDescription,
}) => {
	const { isMobile } = useMobile();
	return (
		<SecondContainer
			item
			xs={12}
			style={isMobile ? { paddingTop: 0, marginBottom: '96px' } : {}}
		>
			<CustomCard divider={true} title="Procedimiento realizado o cirugía">
				<InputContainer item xs={12} md={10} style={{ marginBottom: '16px' }}>
					<TextField
						id="procedure"
						label="Nombre de procedimiento"
						variant="outlined"
						size="small"
						fullWidth
						value={procedure}
						onChange={(e) => setProcedure(e.target.value)}
					/>
				</InputContainer>
				<InputContainer item xs={12} md={10}>
					<TextField
						id="description"
						label="Descripción"
						variant="outlined"
						fullWidth
						multiline
						minRows={4}
						value={procedureDescription}
						onChange={(e) => setProcedureDescription(e.target.value)}
						data-fx-name="procedureDescription"
					/>
				</InputContainer>
			</CustomCard>
		</SecondContainer>
	);
};

export default ProcedureForm;
