import { SexType } from 'API';
import { AllergyStatus } from 'models';
import { HumanoLogo, SenasaLogo } from '../components/svg';
import { HistorySidebarOptions } from 'context/types';
import { LifeStyle } from 'models';

export const sinSeguro = 'Sin seguro';

export const NoSpecified = 'No especificado';

export const MONTHLY_PLAN_ID = 2;
export const ANNUALLY_PLAN_ID = 1;
export const LISTBOX_PADDING = 4; // px
export const MAX_FILE_NAME_LENGTH = 12;
export const MAX_FILE_SIZE = 2000000; // 2MB

export const drawerWidth = 300;

export const pathNameList = {
	home: '/',
	clients: '/Clients',
	calendar: '/Calendar',
	medicines: '/Medicine',
	labImages: '/Lab-Image',
	configuration: '/Configuration/Profile',
	clientDetail: '/Clients/:clientID',
	clientSummary: '/Clients/:clientID/summary',
	profile: '/Configuration/Profile',
	institution: '/Configuration/Institution',
	subscription: '/Configuration/Subscription',
};

export const insurancesOptions = [
	{ id: 1, label: sinSeguro, image: '' },
	{ id: 2, label: 'ARS SENASA', image: SenasaLogo },
	{ id: 3, label: 'ARS Humano', image: HumanoLogo },
	{ id: 4, label: 'ARS Primera de Humano', image: HumanoLogo },
	{ id: 5, label: 'ARS Universal', image: HumanoLogo },
	{ id: 6, label: 'ARS Simag', image: HumanoLogo },
	{ id: 7, label: 'ARS Semunased', image: HumanoLogo },
	{ id: 8, label: 'ARS Semma', image: HumanoLogo },
	{ id: 9, label: 'ARS Salud Segura', image: HumanoLogo },
	{ id: 10, label: 'ARS Plan Salud Banco Central', image: HumanoLogo },
	{ id: 11, label: 'ARS Plamedin', image: HumanoLogo },
	{ id: 12, label: 'ARS MAFRE', image: HumanoLogo },
	{ id: 13, label: 'ARS Meta Salud', image: HumanoLogo },
	{ id: 14, label: 'ARS GMA (Grupo Médico Asociado)', image: HumanoLogo },
	{ id: 15, label: 'ARS Futuro', image: HumanoLogo },
	{ id: 16, label: 'ARS FFAA (Fuerzas Armadas)', image: HumanoLogo },
	{ id: 17, label: 'ARS Constitución', image: HumanoLogo },
	{ id: 18, label: 'ARS Colonial', image: HumanoLogo },
	{ id: 19, label: 'ARS CMD (Colegio Médico Dominicano)', image: HumanoLogo },
	{ id: 20, label: 'ARS Yunén', image: HumanoLogo },
	{ id: 21, label: 'ARS ASEMAP', image: HumanoLogo },
	{ id: 22, label: 'ARS APS ', image: HumanoLogo },
	{ id: 23, label: 'ARS Monumental', image: HumanoLogo },
	{
		id: 24,
		label: 'Seguro Reservas',
		image: HumanoLogo,
	},
];

export const bloodTypeOptions = [
	{ id: 1, label: 'A+' },
	{ id: 2, label: 'O+' },
	{ id: 3, label: 'B+' },
	{ id: 4, label: 'AB+' },
	{ id: 5, label: 'A-' },
	{ id: 6, label: 'O-' },
	{ id: 7, label: 'B-' },
	{ id: 8, label: 'AB-' },
];

export const statusOptions = [
	{ id: 1, label: AllergyStatus.ACTIVE },
	{ id: 2, label: AllergyStatus.INACTIVE },
	{ id: 3, label: AllergyStatus.CURED },
];

export const genderOptions = [
	{ id: 1, name: SexType.FEMENINO, label: 'F' },
	{ id: 2, name: SexType.MASCULINO, label: 'M' },
];

export const identitiesOptions = [
	{ id: 1, name: 'CEDULA', label: 'Cédula' },
	{ id: 2, name: 'PASAPORTE', label: 'Pasaporte' },
];

export const insuranceType = {
	SINSEGURO: insurancesOptions[0].label,
	ARSSENASA: insurancesOptions[1].label,
	ARSHUMANO: insurancesOptions[2].label,
};

export const identificationType = {
	CEDULA: identitiesOptions[0].name,
	PASAPORTE: identitiesOptions[1].name,
};

export const alcoholFrequency = [
	{ id: 1, value: 'Ex-bebedor' },
	{ id: 2, value: 'Nunca ha consumido alcohol' },
	{ id: 3, value: 'Bebedor de acohol social' },
	{ id: 4, value: 'Bebedor de acohol excesivo' },
	{ id: 5, value: 'Alcohólico' },
];

export const smokeType = [
	{ id: 1, value: 'Ex-fumador' },
	{ id: 2, value: 'Nunca ha fumado' },
	{ id: 3, value: 'Fumador de cigarro' },
	{ id: 4, value: 'Fumador leve (1-9 cigs/día)' },
	{
		id: 5,
		value: 'Fumador moderado (10-19 cigs/día)',
	},
	{ id: 6, value: 'Fumador severo (20-39 cigs/día)' },
	{ id: 7, value: 'Fumador de vaporizador (vape)' },
];

export const acivityFrequency = [
	{ id: 1, value: 'Sedentario' },
	{ id: 2, value: 'Actividad ligera: (3d/semana)' },
	{ id: 3, value: 'Actividad moderada: (5d/semana)' },
	{ id: 4, value: 'Actividad intensa: (6d/semana)' },
];

export const drugs = [
	{ id: 1, value: 'No consume drogas' },
	{ id: 2, value: 'Marihuana' },
	{ id: 3, value: 'Cocaína' },
	{ id: 4, value: 'Metanfetamina' },
	{ id: 5, value: 'Otras' },
];
export const sidebar = [
	{ id: 'description', label: 'Descripción' },
	{ id: 'physicalTest', label: 'Examen Físico' },
	{ id: 'diagnosis', label: 'Diagnóstico' },
	{ id: 'treatment', label: 'Tratamiento' },
];

export const pillOptions = [
	{ id: 1, label: '1/2 tableta' },
	{ id: 2, label: '1 tableta' },
	{ id: 3, label: '2 tableta' },
	{ id: 4, label: '1 ampolleta' },
	{ id: 5, label: '1 cápsula' },
	{ id: 6, label: '2 cápsula' },
	{ id: 7, label: '1 pastilla' },
	{ id: 8, label: '2 pastilla' },
	{ id: 9, label: '1 cucharada' },
	{ id: 10, label: '1 gota' },
	{ id: 11, label: '2 gota' },
	{ id: 12, label: '2.5 ML' },
	{ id: 13, label: '5 ML' },
	{ id: 14, label: '10 ML' },
];

export const indicationOptions = [
	{ id: 1, label: 'Cada 4 horas' },
	{ id: 2, label: 'Cada 6 horas' },
	{ id: 3, label: 'Cada 8 horas' },
	{ id: 4, label: 'Cada 12 horas' },
	{ id: 5, label: 'Cada 24 horas' },
	{ id: 6, label: 'Al medio día' },
	{ id: 7, label: 'En ayuna' },
	{ id: 8, label: 'En la noche' },
	{ id: 9, label: 'Semanal' },
	{ id: 10, label: 'Mensual' },
];

export const durationOptions = [
	{ id: 1, label: '5 días' },
	{ id: 2, label: '7 días ' },
	{ id: 3, label: '10 días' },
	{ id: 4, label: '15 días' },
	{ id: 5, label: '20 días' },
	{ id: 6, label: '1 mes' },
	{ id: 7, label: '2 mes' },
	{ id: 8, label: 'Uso continuo' },
	{ id: 9, label: 'Permanente' },
];

export const SPECIALTIES = [
	'Estudiante',
	'Médico cirujano y partero',
	'Medicina del Dolor',
	'Psiquiatría Infantil',
	'Laringología',
	'Enfermería',
	'Psiquiatría Infantil y del Adolescente',
	'Hemoterapia e Inmunohematología',
	'Ninguna',
	'Ultrasonografia',
	'Medicina Interna',
	'Hepatología',
	'Pediatra y Medicina del Adolescente',
	'Especialista en Trastornos de la Conducta Alimentaria',
	'Matrona',
	'Cirujano Cardiovascular',
	'Neuro-otología',
	'Traumatologia y Ortopedia',
	'Acupunturista',
	'Fonocirugía',
	'Adicciones',
	'Alergólogo',
	'Algólogo',
	'Anestesiólogo',
	'Angiólogo',
	'Audiólogo y Foniatra',
	'Bariatra',
	'Biotecnología',
	'Cardiólogo',
	'Cardiólogo Hemodinamista',
	'Cardiólogo Pediatra',
	'Cirugía Maxilofacial',
	'Cirujano Cardiotorácico',
	'Cirujano Endocrinólogo',
	'Cirujano Gastroenterólogo',
	'Cirujano General',
	'Cirujano Oncólogo',
	'Cirujano Pediatra',
	'Cirujano Plástico',
	'Cirujano Vascular',
	'Coloproctólogo',
	'Dentista',
	'Dermatólogo',
	'Dermatólogo Pediatra',
	'Cardiólogo Electrofisiólogo',
	'Dermocosmiatra',
	'Endocrinólogo',
	'Endocrinólogo Pediatra',
	'Endodoncista',
	'Endoperiodontólogo',
	'Estomatología',
	'Fisioterapeuta',
	'Gastroenterólogo',
	'Gastroenterólogo Pediatra',
	'Genetista',
	'Geriatra',
	'Ginecología',
	'Ginecología Oncológica',
	'Ginecólogo y Obstetra',
	'Hematólogo',
	'Oncólogo Pediatra',
	'Hematólogo Pediatra',
	'Homeópata',
	'Infectólogo',
	'Infectólogo Pediatra',
	'Inmunólogo',
	'Internista',
	'Maxilofacial',
	'Medicina del Trabajo',
	'Médico Alternativo',
	'Médico Deportivo',
	'Médico de Rehabilitación',
	'Médico en Biología de la Reproducción',
	'Médico Estético',
	'Médico Cirujano',
	'Cirujano Torácico',
	'Médico Familiar',
	'Médico General',
	'Médico Intensivista',
	'Nefrólogo',
	'Nefrólogo (Hemodiálisis)',
	'Nefrólogo Pediatra',
	'Neonatólogo',
	'Neumólogo',
	'Neumólogo Pediatra',
	'Neurocirujano',
	'Neurólogo',
	'Neurólogo Pediatra',
	'Neuroradiólogo',
	'Nutriólogo',
	'Nutriólogo Clínico',
	'Odontología',
	'Odontopediatra',
	'Oftalmólogo',
	'Oftalmólogo Pediatra',
	'Oncólogo',
	'Optometrista',
	'Ortodoncista',
	'Ortopedista Pediatra',
	'Ortopedista y Traumatólogo',
	'Otorrinolaringólogo',
	'Urólogo',
	'Otorrinolaringólogo Pediatra',
	'Patólogo Bucal',
	'Pediatra',
	'Periodoncista',
	'Podiatra',
	'Podólogo',
	'Prostodoncista',
	'Psicólogo',
	'Psiquiatra',
	'Quiropráctico',
	'Radiólogo',
	'Rehabilitación en Lenguaje y Audición',
	'Reumatólogo',
	'Urgencias Médico Quirúrgicas',
	'Urólogo Pediatra',
	'Andrología',
	'Otorrinolaringología',
	'Oncólogo Médico',
	'Medicina del Enfermo en Estado Crítico',
	'Tecnólogo Médico',
	'Medicina Física y Rehabilitación',
	'Neuropsicología',
];

export const DOCTOR_IDENTIFIER = 'custom:doctorID';
export const SECRETARY_IDENTIFIER = 'custom:secretaryID';
export const MEMBER_IDENTIFIER = 'custom:role';
export const SECRETARY_CUSTOM_PASSWORD_IDENTIFIER = 'custom:tempPassword';
export const CUSTOM_PASSWORD_IDENTIFIER = 'custom:tempPassword';
export const IS_TRIAL_IDENTIFIER = 'custom:isTrial';
export const ERRORS = {
	PASSWORD: {
		REQUIRED: 'La contraseña es requerida',
	},
};

export const HAS_TRIAL_USER_MESSAGE = 'A pagar ahora: RD$0 Pesos';

export enum StripePaymentStatus {
	Incomplete = 'incomplete',
	IncompleteExpired = 'incomplete_expired',
	Trialing = 'trialing',
	Active = 'active',
	PastDue = 'past_due',
	Canceled = 'canceled',
	Unpaid = 'unpaid',
	Succeeded = 'succeeded',
}

export const NOT_AUTHENTICATED = 'The user is not authenticated';

export const DROPDOWN_MOBILE_MENU = {
	[HistorySidebarOptions.DISEASES]: 'Enfermedades',
	[HistorySidebarOptions.ALERGIES]: 'Alergias',
	[HistorySidebarOptions.PROCEDURES]: 'Procedimientos',
	[HistorySidebarOptions.LIFE_STYLE]: 'Estilo de vida',
	[HistorySidebarOptions.FAMILY_HISTORY]: 'Historia familiar',
	[HistorySidebarOptions.NUTRITION]: 'Nutrición',
	[HistorySidebarOptions.PREVIOUS_HOSPITALIZATION]: 'Hospitalizaciones',
	[HistorySidebarOptions.RESUMEN]: 'Resumen del paciente',
	[HistorySidebarOptions.SMOKING_DRUG]: 'Tabaco y drogas recreativas',
	[HistorySidebarOptions.VACCINES_IMMUNIZATION]: 'Vacunas e inmunización',
};
export const EXAMPLE_PATIENT_DATA = {
	identificationName: 'CEDULA',
	identificationData: '40289675673',
	actualInsurance: 'ARS Humano',
	name: 'Elias',
	lastName: 'Fernando (Demo)',
	cellphoneNumber: '(565) 248-8555',
	email: 'efne@test.com',
	bornDate: '1997-05-08',
	parentOrTutorCedula: '',
	parentOrTutorID: '',
	gender: 'MASCULINO',
	phoneNumber: '(829) 565-6544',
	addressStreet: 'Fursen #65',
	city: 'Santo Domingo',
	isAdult: true,
	sector: 'Invimo',
	bloodType: 'A+',
	company: null,
	vitalSignsHeight: '8',
	vitalSignsWeight: '50',
	bodyMass: '0.78',
	vitalSignsBloodPressure: '654/8',
	heartRate: '55',
	respiratoryRate: '23',
	bodyTemperature: '28',
	oxygenSaturation: '75',
	occupation: 'Chofer',
	heartBeat: null,
	weight: null,
	height: null,
	bloodPressure: null,
};

export const EXAMPLE_CLIENT_CONSULTATION_DIAGNOSTICS = {
	diagnostics: [
		{
			id: 1,
			name: 'Necrosis arterial',
			code: 'I775',
			type: 'PRESUMPTIVE',
		},
	],
	diffDiagnostics: [
		{
			id: 1,
			name: 'Trisomía 13, mosaico (por falta de disyunción mitótica)',
			code: 'Q915',
			type: 'DIFFERENTIAL',
		},
	],
};
export const EXAMPLE_CLIENT_CONSULTATION_PRESCRIPTIONS_MEDICINE = [
	{
		id: 'a54a274b-e0cf-4fcd-8a17-c750a97e3946',
		medicineID: '7aab2dd0-0098-4764-a5cb-59cb4c625aca',
		name: 'BISOPROLOL 5 mg',
		take: '1 tableta',
		indication: 'Cada 6 horas',
		duration: '7 días ',
		description: '',
		status: 'ACTIVE',
	},
	{
		id: '4cf711bd-fcda-4674-a005-6874e06c0fca',
		medicineID: '47ef2bc5-3f06-4745-a07a-eb9e1652dc60',
		name: 'Atorvastatina 20 mg',
		take: '1 tableta',
		indication: 'Cada 6 horas',
		duration: '7 días ',
		description: '',
		status: 'ACTIVE',
	},
];

export const EXAMPLE_CLIENT_CONSULTATION_PRESCRIPTIONS_LAB_IMAGE = [];
export const EXAMPLE_CLIENT_CONSULTATION_PRESCRIPTIONS = {
	analisis: EXAMPLE_CLIENT_CONSULTATION_PRESCRIPTIONS_LAB_IMAGE,
	creationDate: '20240-08-14T22:18:59.220Z',
	medicines: EXAMPLE_CLIENT_CONSULTATION_PRESCRIPTIONS_MEDICINE,
	customPrescription: [
		{
			description: 'Referido a Gastro',
		},
	],
};
export const EXAMPLE_CLIENT_ALLERGY = {
	name: 'Aspirina',
	status: 'ACTIVE',
	note: 'Alergia a la aspirina',
	diagnosedDate: '2024-08-14T22:18:59.220Z',
};
export const EXAMPLE_CLIENT_DISEASE = {
	name: 'Diabetes',
	status: 'ACTIVE',
	note: 'Diabetes',
	diagnosedDate: '2024-08-14T22:18:59.220Z',
};
export const EXAMPLE_CLIENT_LIFESTYLE = [
	{
		name: 'Bebedor de acohol social',
		description: 'El paciente, tuvo problemas de alcoholismo.',
		lifeStyle: LifeStyle.ALCOHOL,
	},
	{
		name: 'Ex-fumador',
		description: 'Ha fumado desde los 18 años.',
		lifeStyle: LifeStyle.TABACO,
	},
	{
		name: 'Actividad ligera: (3d/semana)',
		description: 'Inició actividad ligera en el 2022',
		lifeStyle: LifeStyle.ACTIVITY,
	},
	{
		name: 'No consume drogas',
		description: 'No consume drogas - Consumía Mariguana anteriormente',
		lifeStyle: LifeStyle.DRUG,
	},
];
export const EXAMPLE_CLIENT_PROCEDURE = {
	name: 'Cateterismo cardíaco',
	note: 'Procedimiento realizado para evaluar la anatomía de las arterias coronarias mediante la inserción de un catéter en el sistema arterial.',
	realizationDate: '2023-11-24',
};
export const EXAMPLE_CLIENT_HOSPITALIZATION = {
	name: 'Hospitalización por infarto agudo de miocardio',
	note: 'Ingresado debido a un episodio de infarto de miocardio, tratado con angioplastia coronaria y stent.',
	date: '2023-11-24',
};
export const EXAMPLE_CLIENT_FAMILY_HISTORY = {
	familyMember: 'FATHER',
	mellitusDiabetes: true,
	mellitusDiabetesNote: '',
	arterialHypertension: false,
	arterialHypertensionNote: '',
	heartDisease: true,
	heartDiseaseNote: '',
	nephropathy: false,
	nephropathyNote: '',
	neuropathies: false,
	neuropathiesNote: '',
	epilepsy: true,
	epilepsyNote: 'Lleva sufriendo de Epilepsia desde los 12 años',
	rheumatoidArthritis: false,
	rheumatoidArthritisNote: '',
	psychiatricDiseases: false,
	psychiatricDiseasesNote: '',
	cancer: false,
	cancerNote: '',
	other: false,
	otherNote: '',
};

export const EXAMPLE_CLIENT_CONSULTATION = {
	visitReason: 'Dolor en el pecho y dificultad para respirar',
	visitDescription:
		'El paciente refiere que el dolor en el pecho ha estado presente durante las últimas dos semanas, especialmente al caminar distancias cortas o subir escaleras. ',
	physicTestNote:
		'- Aspecto general: El paciente está ligeramente sudoroso y muestra signos de malestar.\n- Auscultación cardíaca: Ruidos cardíacos rítmicos, sin soplos audibles.\n- Auscultación pulmonar: Murmullo vesicular presente bilateralmente, sin ruidos adicionales.\n- Extremidades: No se evidencian edemas periféricos. Pulso periférico presente y simétrico.',
	height: '8',
	weight: '50',
	bodyMass: '0.78',
	bloodPressure: '654/8',
	heartRate: '55',
	respiratoryRate: '23',
	bodyTemperature: '28',
	oxygenSaturation: '75',
	clientMedicinePrescriptionID: '592285cd-e754-4fdf-9ef4-130615725ee7',
	clientAnalisisPrescriptionID: '3dac3c10-ac35-4562-b435-b7ca15910f7e',
	clientFreePrescriptionID: 'c19a9153-de3f-445c-a648-1d33b1b48b76',
	treatment:
		'- Iniciar tratamiento farmacológico para controlar los factores de riesgo cardiovascular, incluyendo antiagregantes plaquetarios, estatinas y beta bloqueantes.\n- Realizar estudios complementarios para evaluar la presencia y la gravedad de la enfermedad coronaria.\n- Implementar cambios en el estilo de vida, como dieta saludable, ejercicio regular y cese del tabaquismo.',
	procedureName: 'Angiografía Coronaria',
	procedureDescription:
		'Este procedimiento es esencialmente un tipo de radiografía especializada que utiliza un medio de contraste para visualizar las arterias coronarias y sus ramas.',
	createdAt: '2023-11-24T12:49:03.235Z',
	updatedAt: '2024-03-31T15:42:03.381Z',
	prescription: {
		medicines: EXAMPLE_CLIENT_CONSULTATION_PRESCRIPTIONS_MEDICINE,
		analisis: EXAMPLE_CLIENT_CONSULTATION_PRESCRIPTIONS_LAB_IMAGE,
		creationDate: '2024-08-14T22:18:59.220Z',
		customPrescription: [
			{
				description: 'Referido a Gastro',
			},
		],
	},
};
export const EXAMPLE_CLIENT_INFORMATION = {
	personalInformation: EXAMPLE_PATIENT_DATA,
	consultation: EXAMPLE_CLIENT_CONSULTATION,
	diagnostics: EXAMPLE_CLIENT_CONSULTATION_DIAGNOSTICS,
	prescriptions: EXAMPLE_CLIENT_CONSULTATION_PRESCRIPTIONS,
	allergies: EXAMPLE_CLIENT_ALLERGY,
	diseases: EXAMPLE_CLIENT_DISEASE,
	lifestyle: EXAMPLE_CLIENT_LIFESTYLE,
	procedures: EXAMPLE_CLIENT_PROCEDURE,
	hospitalizations: EXAMPLE_CLIENT_HOSPITALIZATION,
	familyHistory: EXAMPLE_CLIENT_FAMILY_HISTORY,
};
