import { MenuItem, MenuItemProps } from '@material-ui/core';

interface Props extends MenuItemProps {
	label: string;
	count: number;
	highlight?: boolean;
	highlightColor?: string;
}

const CustomMenuItem = (props: Props) => {
	const { label, count, button, highlight, highlightColor, ...rest } = props;
	const highlightStyle = `bg-[${!!highlight ? highlightColor : '#1976D2'}]`;
	return (
		<MenuItem
			key={label}
			style={{
				width: '100%',
				display: 'flex',
				justifyContent: 'space-between',
				overflow: 'hidden',
				boxShadow: '0px -1px 0px 0px rgba(0, 0, 0, 0.12) inset',
			}}
			{...rest}
		>
			{label}
			{count > 0 ? (
				<span
					className={`${highlightStyle} text-[#fff] h-5 w-5 rounded-full flex justify-center items-center`}
				>
					{count}
				</span>
			) : null}
		</MenuItem>
	);
};

export default CustomMenuItem;
