import { Skeleton } from '@material-ui/lab';
import { FC } from 'react';

const FamilySkeleton: FC<{
	style?: any;
}> = ({ style }) => {
	return (
		<div
			style={{
				...style,
				marginBottom: '16px',
			}}
		>
			<Skeleton variant="rect" width="100%" height={30} />
		</div>
	);
};

export default FamilySkeleton;
