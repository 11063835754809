// @ts-nocheck
import React, { useState, useEffect, useContext } from 'react';
import AI from '../shared/ai/AiSuggestions';
import { AIContextProps, LoaderContextProps, RelationsContextProps } from './types';
import {
	AIDiagnosisSuggestion,
	AIMedicalPlanSuggestion,
	AIPatienDiagnostic,
	AISection,
	AISuggestion,
	AIType,
	PatientData,
} from 'shared/ai/types';
import { LoaderContext } from './loader-context';
import { RelationsContext } from './relations-context';
import { consultationSuggestion } from 'shared/ai/promts/promtConsultation';
import { promtDiagnosis } from 'shared/ai/promts/promtDiagnosis';
import { promtMedicalPlan } from 'shared/ai/promts/promtMedicalPlan';

export const AiContext = React.createContext<Partial<AIContextProps>>({});

const AiProvider: React.FC = ({ children }) => {
	const { setAlertMessage } = useContext(RelationsContext) as RelationsContextProps;
	const { showSpinner } = useContext(LoaderContext) as LoaderContextProps;
	const [aiPromptInput, setAIPromptInput] = useState<
		PatientData | AIPatienDiagnostic | AIDiagnosisSuggestion | null
	>(null);
	const [aiSuggestions, setAiSuggestions] = useState<
		AISuggestion | AIMedicalPlanSuggestion | AIDiagnosisSuggestion | null
	>(null);
	const [aiSection, setAiSection] = useState<AISection | null>('consultation');
	const [callback, setCallback] = useState<Function | null>(null);

	useEffect(() => {
		if (aiPromptInput) {
			try {
				showSpinner(true);
				const generatePromptOutput = async () => {
					const callback =
						aiSection === 'consultation'
							? consultationSuggestion
							: aiSection === 'diagnosis'
							? promtDiagnosis
							: promtMedicalPlan;

					const suggestions = await AI(aiPromptInput, callback);
					let input: AISuggestion | AIMedicalPlanSuggestion | AIDiagnosisSuggestion;
					if (aiSection === 'medical_plan') {
						input = {
							diagnosticos: suggestions.diagnosticos.map((diagnostico: any) => ({
								nombre: diagnostico.nombre,
							})),
							estudios: suggestions.estudios.map((estudio: any) => ({
								nombre: estudio.nombre,
							})),
							medicamentos: suggestions.medicamentos.map((medicamento: any) => ({
								nombre: medicamento.nombre,
							})),
							planMedico: suggestions.planMedico[0].descripcion,
							tratamientos: suggestions.tratamientos.map((tratamiento: any) => ({
								nombre: tratamiento.nombre,
							})),
						} as AIMedicalPlanSuggestion;
					} else if (aiSection === 'diagnosis') {
						input = {
							diagnosticos: suggestions.diagnosticos,
						} as AIDiagnosisSuggestion;
					} else {
						input = {
							...suggestions,
							diagnosticosDiferenciales: suggestions.diagnosticos,
						} as AISuggestion;
					}

					setAiSuggestions(input);
				};

				generatePromptOutput()
					.then(() => {
						showSpinner(false);
						setAlertMessage({
							severity: 'success',
							message: 'Sugerencias generadas correctamente',
						});
						callback && callback();
					})
					.catch((error) => {
						setAlertMessage({
							severity: 'error',
							message: error.message,
						});
						showSpinner(false);
					});
			} catch (error: any) {
				setAlertMessage({
					severity: 'error',
					message: 'Error al generar sugerencias',
				});
				showSpinner(false);
			}
		}
	}, [aiPromptInput]);

	useEffect(() => {
		if (!aiSection) {
			setAiSuggestions(null);
			setAIPromptInput(null);
		}
	}, [aiSection]);

	const clearSuggestions = () => {
		setAiSuggestions(null);
		setCallback(null);
	};

	const suggestionClickHandler = (type: AIType, name: string, callback: Function) => {
		const suggestion = aiSuggestions?.[type];
		const filteredSuggestion = suggestion?.filter((suggestion) => suggestion.nombre !== name);

		setAiSuggestions({
			...aiSuggestions,
			[type]: filteredSuggestion,
		} as AISuggestion);
		callback();
	};

	const onHandlerAiPromptInput = (aiPromptInput: PatientData, callback?: Function) => {
		setAIPromptInput(aiPromptInput);

		if (callback) {
			setCallback(callback);
		}
	};

	return (
		<AiContext.Provider
			value={{
				aiSuggestions,
				setIAPrompt: onHandlerAiPromptInput,
				clearIASuggestions: clearSuggestions,
				suggestionClickHandler,
				setAiSection,
				aiSection,
			}}
		>
			{children}
		</AiContext.Provider>
	);
};

export default AiProvider;
