import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import WaitingListItemC from './waitingListItem/WaitingListItem';
import { WaitingListsContext, WaitingListContextProps } from '../../context/waiting-list-context';
import { useHistory } from 'react-router-dom';
import {
	OnCreateWaitingListItemSubscription,
	OnUpdateWaitingListItemSubscription,
	WaitingListItemStatus,
} from 'API';
import { ClientContextProps, WaitingListItemType } from '../../context/types';
import ListContainer from '../list/ListContainer';
import { StyledPatientList } from '../../shared/style';
import LoadingSpinner from '../loading/LoadingSpinner';
import { API, graphqlOperation, GraphQLSubscription } from '@aws-amplify/api';
import NoItemsInWaitingListIcon from '../svg/NoItemsInWaitingListIcon';
import NoItem from '../noItems';
import { onCreateWaitingListItem, onUpdateWaitingListItem } from 'graphql/subscriptions';
import { ClientsContext } from 'context/client-context';
import useMobile from 'hooks/useMobile';

const WaitingListC: FC = () => {
	const history = useHistory();
	const {
		waitingListItems,
		updateWaitingListItemStatus,
		getWaitingListItems,
		actualWaitingList,
		setWaitingListItems,
	} = useContext(WaitingListsContext) as WaitingListContextProps;
	const [filterText, setFilterText] = useState('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { isMobile } = useMobile();
	const [localWaitingList, setLocalWaitingList] = useState<WaitingListItemType[]>(
		[] as WaitingListItemType[]
	);
	const { clientsLoading } = useContext(ClientsContext) as ClientContextProps;

	const inProcessWaitingItems = localWaitingList
		.filter((waitingListItem) => waitingListItem.status === WaitingListItemStatus.CONSULTA)
		.sort((a, b) => a.positionNumber - b.positionNumber);
	const pendingWaitingItems = localWaitingList
		.filter((waitingListItem) => waitingListItem.status === WaitingListItemStatus.ESPERA)
		.sort((a, b) => a.positionNumber - b.positionNumber);

	const fetchWaitingList = async () => {
		setIsLoading(true);
		await getWaitingListItems();
		setIsLoading(false);
	};

	useEffect(() => {
		if (waitingListItems.length === 0) {
			fetchWaitingList();
		}
	}, []);

	useEffect(() => {
		let syncTimeOut: NodeJS.Timeout;
		let updateTimeOut: NodeJS.Timeout;
		const createSub = API.graphql<GraphQLSubscription<OnCreateWaitingListItemSubscription>>(
			graphqlOperation(onCreateWaitingListItem, {
				waitingListID: actualWaitingList?.id,
			})
		).subscribe({
			next: async (data: any) => {
				if (syncTimeOut) clearTimeout(syncTimeOut);

				syncTimeOut = setTimeout(async () => {
					const currentWaitingListItems = [...waitingListItems];
					const newWaitingListItem = data.value.data.onCreateWaitingListItem;

					if (
						currentWaitingListItems.findIndex((x) => x.id === newWaitingListItem.id) !==
						-1
					)
						return;
					await getWaitingListItems();
				}, 5000);
			},
		});

		const updateSub = API.graphql<GraphQLSubscription<OnUpdateWaitingListItemSubscription>>(
			graphqlOperation(onUpdateWaitingListItem, {
				waitingListID: actualWaitingList?.id,
			})
		).subscribe({
			next: async (data: any) => {
				if (updateTimeOut) clearTimeout(updateTimeOut);

				updateTimeOut = setTimeout(async () => {
					const currentWaitingListItems = [...waitingListItems];
					const updatedWaitingListItem = data.value.data.onUpdateWaitingListItem;

					const waitingListItemIndex = currentWaitingListItems.findIndex(
						(x) => x.id === updatedWaitingListItem.id
					);

					if (waitingListItemIndex === -1) {
						return;
					}

					const patientToUpdate = currentWaitingListItems[waitingListItemIndex];

					if (updatedWaitingListItem.positionNumber !== patientToUpdate.positionNumber) {
						await getWaitingListItems();
						return;
					}

					if (updatedWaitingListItem.status === patientToUpdate.status) {
						return;
					}

					if (updatedWaitingListItem.status === WaitingListItemStatus.TERMINADA) {
						await getWaitingListItems();
						return;
					}

					patientToUpdate.status = updatedWaitingListItem.status;
					patientToUpdate.positionNumber = updatedWaitingListItem.positionNumber;
					setWaitingListItems(currentWaitingListItems);
				}, 5000);
			},
		});

		return () => {
			if (syncTimeOut) {
				clearTimeout(syncTimeOut);
			}
			if (updateTimeOut) {
				clearTimeout(updateTimeOut);
			}
			updateSub.unsubscribe();
			createSub.unsubscribe();
		};
	}, [waitingListItems]);

	useEffect(() => {
		if (filterText !== '') {
			const timeOut = setTimeout(() => {
				setLocalWaitingList(
					waitingListItems.filter((x: WaitingListItemType) =>
						`${x.clientName} ${x.clientLastName}`
							.toLowerCase()
							.includes(filterText.toLowerCase())
					)
				);
			}, 500);

			return () => {
				clearTimeout(timeOut);
			};
		} else {
			setLocalWaitingList(waitingListItems);
		}
	}, [filterText, waitingListItems]);

	const handleOnConsultaWaitingItem = async (waitingListItemID: string) => {
		await updateWaitingListItemStatus(waitingListItemID, WaitingListItemStatus.CONSULTA);
	};

	const handleOnTerminadaWaitingItem = async (waitingListItemID: string) => {
		await updateWaitingListItemStatus(waitingListItemID, WaitingListItemStatus.TERMINADA);
	};

	const handleClickClient = async (clientId: string) => {
		history.push(`/Clients/${clientId}`);
	};

	const inProcessItems: JSX.Element[] = inProcessWaitingItems.map((waitingListItem, index) => {
		return (
			<StyledPatientList key={waitingListItem.id} dense>
				<WaitingListItemC
					key={waitingListItem.id}
					waitingItemID={waitingListItem.id}
					clientID={waitingListItem.clientID}
					status={waitingListItem.status}
					positionNumber={waitingListItem.positionNumber}
					clientName={waitingListItem.clientName}
					clientLastName={waitingListItem.clientLastName}
					clientHealthInsurrance={waitingListItem.clientHealthInsurrance}
					includeLineSeparator={
						isMobile ? true : index + 1 !== inProcessWaitingItems.length
					}
					onConsulta={handleOnConsultaWaitingItem}
					onTerminada={handleOnTerminadaWaitingItem}
					onClick={handleClickClient}
				/>
			</StyledPatientList>
		);
	});
	const pendingItems: JSX.Element[] = pendingWaitingItems.map((waitingListItem, index) => {
		return (
			<StyledPatientList key={waitingListItem.id} dense>
				<WaitingListItemC
					waitingItemID={waitingListItem.id}
					clientID={waitingListItem.clientID}
					status={waitingListItem.status}
					positionNumber={waitingListItem.positionNumber}
					clientName={waitingListItem.clientName}
					clientLastName={waitingListItem.clientLastName}
					clientHealthInsurrance={waitingListItem.clientHealthInsurrance}
					includeLineSeparator={
						isMobile ? true : index + 1 !== pendingWaitingItems.length
					}
					onConsulta={handleOnConsultaWaitingItem}
					onTerminada={handleOnTerminadaWaitingItem}
					onClick={handleClickClient}
				/>
			</StyledPatientList>
		);
	});

	const renderWaitingList = useCallback(() => {
		if (inProcessItems.length > 0 || pendingItems.length > 0) {
			return (
				<>
					{isMobile && inProcessItems.length === 0 ? (
						<></>
					) : (
						<>
							<span
								className="section-cell en-consulta-header"
								style={{
									fontSize: '14px',
									fontWeight: 500,
									color: 'rgba(0, 0, 0, 0.6)',
									paddingTop: isMobile ? '8px' : '32.27px',
									paddingBottom: isMobile ? '8px' : '0px',
									marginRight: isMobile ? 'auto' : 0,
									paddingLeft: isMobile ? '16px' : '36px',
								}}
							>
								En consulta
							</span>
							{inProcessItems}
						</>
					)}

					<span
						className="section-cell mr-auto  md:mr-0"
						style={{
							fontSize: '14px',
							fontWeight: 500,
							color: 'rgba(0, 0, 0, 0.6)',
							paddingBottom: isMobile ? '20px' : '0px',
							marginRight: isMobile ? 'auto' : 0,
							paddingLeft: isMobile ? '16px' : '36px',
						}}
					>
						En espera
					</span>
					{pendingItems}
				</>
			);
		}

		return (
			<NoItem
				icon={<NoItemsInWaitingListIcon />}
				onClickHandler={() => null}
				title="Presiona el icono de la lista en los pacientes para agregarlos a la lista de espera"
				width="401px"
				isRow
				containerStyle={{
					alignItems: 'center',
					textAlign: 'center',
				}}
			/>
		);
	}, [inProcessItems, pendingItems]);

	return (
		<ListContainer
			id="wait-list"
			title="Lista de espera"
			placeholder={isMobile ? 'Buscar en lista de espera' : undefined}
			filterText={filterText}
			setFilterText={setFilterText}
			isDisplayContent
			contentStyle={{
				flexDirection: `${
					inProcessItems.length > 0 || pendingItems.length > 0 ? 'column' : 'row'
				}`,
				justifyContent: `${
					inProcessItems.length > 0 || pendingItems.length > 0 ? 'flex-start' : 'center'
				}`,
				backgroundColor: `${
					inProcessWaitingItems.length === 0 && pendingWaitingItems.length === 0
						? 'transparent'
						: '#ffffff'
				}`,
			}}
			customStyles={{
				marginBottom: '20px',
				...(isMobile && { height: '100%' }),
			}}
			data-fx-name="listaEspera"
			className={`list ${isMobile && inProcessItems.length > 0 ? 'empty-process' : ''}`}
		>
			{isLoading || clientsLoading ? <LoadingSpinner /> : renderWaitingList()}
		</ListContainer>
	);
};

export default WaitingListC;
