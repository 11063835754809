import { ClientFamilyHistory, HistoryFamilyMember } from 'models';

export const calculateBodyMass = (height: number, weight: number) => {
	const height2 = Math.pow(height, 2);
	const bodyMass = weight / height2;
	return bodyMass.toFixed(2);
};

export const addSlash = (input: string) => {
	let output = input;
	const slashIndex = output.indexOf('/');

	if (slashIndex === 3 || (slashIndex === 3 && output.length === 4 && output[3] === '/')) {
		return output;
	}

	if (output.length === 4) {
		output = output.slice(0, 3) + '/' + output.slice(3);
	}

	return output;
};

const formatFamilyHistoryItem = (item: string) => {
	switch (item) {
		case HistoryFamilyMember.FATHER:
			return 'Padre';
		case HistoryFamilyMember.MOTHER:
			return 'Madre';
		case HistoryFamilyMember.BROTHERS:
			return 'Hermanos';
		case HistoryFamilyMember.GRANDPARENTS:
			return 'Abuelos';
		case HistoryFamilyMember.GREATGRANDPARENTS:
			return 'Bisabuelos';
		case HistoryFamilyMember.SONS:
			return 'Hijos';
	}
};

export const formatFamilyHistory = (data: ClientFamilyHistory[] | undefined) => {
	let text = '\n';
	if (!data) return text;

	data.forEach((item, index) => {
		text += `${index}-${formatFamilyHistoryItem(item.familyMember)}`;
		if (item.arterialHypertension) {
			text += '\n';
			text += `Hipertensión arterial: ${item.arterialHypertensionNote}`;
		}
		if (item.cancer) {
			text += '\n';
			text += `Cáncer: ${item.cancerNote}`;
		}
		if (item.epilepsy) {
			text += '\n';
			text += `Epilepsia: ${item.epilepsyNote}`;
		}
		if (item.heartDisease) {
			text += '\n';
			text += `Enfermedad cardíaca: ${item.heartDiseaseNote}`;
		}
		if (item.mellitusDiabetes) {
			text += '\n';
			text += `Diabetes mellitus: ${item.mellitusDiabetesNote}`;
		}
		if (item.nephropathy) {
			text += '\n';
			text += `Nefropatía: ${item.nephropathyNote}`;
		}
		if (item.psychiatricDiseases) {
			text += '\n';
			text += `Enfermedades psiquiátricas: ${item.psychiatricDiseasesNote}`;
		}
		if (item.rheumatoidArthritis) {
			text += '\n';
			text += `Artritis reumatoide: ${item.rheumatoidArthritisNote}`;
		}
		if (item.other) {
			text += '\n';
			text += `Otro: ${item.otherNote}`;
		}

		text += '\n\n';
	});

	return text;
};
