import React from 'react';
import CustomCard from '../../card/CustomCard';
import { NoteContainer, TextFieldNote } from './PatientSummary.style';

interface Props {
	clientDoctorNote: string;
	handleNoteChange: (value: string) => void;
	handleNoteOnBlur: (
		e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>
	) => void;
}

const SummaryNotes: React.FC<Props> = ({
	clientDoctorNote,
	handleNoteChange,
	handleNoteOnBlur,
}) => {
	return (
		<CustomCard
			title="Nota rápida"
			divider={true}
			paddingTop={'0'}
			paddingBottom={'0'}
			hasMarginBottom
		>
			<NoteContainer item xs={12}>
				<TextFieldNote
					id="outlined-rapid-note"
					placeholder="Escribe una nota rápida"
					value={clientDoctorNote}
					onChange={(e) => handleNoteChange(e.target.value)}
					onBlur={(e) => handleNoteOnBlur(e)}
					multiline
					fullWidth
					minRows={5}
					variant="outlined"
				/>
			</NoteContainer>
		</CustomCard>
	);
};

export default SummaryNotes;
