import { Grid } from '@material-ui/core';
import { FC, useContext } from 'react';
import AddIcon from '@material-ui/icons/Add';
import {
	EditIconButton,
	HeaderHistoryContainer,
	HistoryListTitle,
	NoItemButton,
} from '../../../../shared/style';
import EditIcon from '@material-ui/icons/Edit';
import ListEmptyState from './ListEmptyState';
import CTable from '../../../table/CTable';
import ModalConfirmation from '../../../modalConfirmation/ModalConfirmation';
import { IColumnType } from '../../../../shared/type';
import { ClientType, RelationsContextProps } from '../../../../context/types';
import moment from 'moment';
import { HistoryContext, HistoryContextProps } from '../../../../context/history-context';
import { RelationsContext } from '../../../../context/relations-context';
import useMobile from 'hooks/useMobile';

interface IData {
	hospitalization_name: string;
	date: string;
	hospitalization_note: string;
	action: string;
	align?: 'center' | 'inherit' | 'justify' | 'left' | 'right';
	isDisabled?: boolean;
}

interface Props {
	hospitalizationId: string;
	clearHospitalizationForm: () => void;
	handleEditHospitalization: (id: string) => void;
	handleOpenHospitalizationForm: () => void;
	isHospitalizationDeleteOpen: boolean;
	hospitalizationName: string;
	hospitalizations: any;
	setClientHistory: (client: any) => void;
	isAnyModalOpen: boolean;
}

const HospitalizationList: FC<Props> = ({
	hospitalizationId,
	clearHospitalizationForm,
	handleEditHospitalization,
	handleOpenHospitalizationForm,
	isHospitalizationDeleteOpen,
	hospitalizationName,
	hospitalizations,
	setClientHistory,
	isAnyModalOpen = false,
}) => {
	const { deletePatientHospitalization } = useContext(HistoryContext) as HistoryContextProps;
	const { setAlertMessage } = useContext(RelationsContext) as RelationsContextProps;
	const { isMobile } = useMobile();

	const approveDelete = async () => {
		await deletePatientHospitalization(hospitalizationId);
		setClientHistory((prevState: ClientType) => ({
			...prevState,
			hospitalizations: prevState?.hospitalizations?.filter(
				(a) => a.id !== hospitalizationId
			),
		}));
		clearHospitalizationForm();
		setAlertMessage({
			message: 'Hospitalización eliminado',
			severity: 'success',
		});
	};

	const columns: IColumnType<IData>[] = [
		{
			key: 'hospitalization_name',
			title: 'Razón de hospitalización',
		},
		{
			key: 'hospitalization_note',
			title: 'Notas',
			width: 320,
		},
		{
			key: 'date',
			title: 'En fecha',
			width: 170,
		},
		{
			key: 'action',
			title: '',
			width: 35,
			render: (_, { action }) => (
				<EditIconButton
					custommarginleft="0px"
					aria-label="edit"
					onClick={() => handleEditHospitalization(action)}
				>
					<EditIcon />
				</EditIconButton>
			),
		},
	];

	const columnsMobile: IColumnType<IData>[] = [
		{
			key: 'hospitalization_name',
			title: 'Razón de hospitalización',
		},
		{
			key: 'action',
			title: '',
			width: 35,
			render: (_, { action }) => (
				<EditIconButton
					custommarginleft="0px"
					aria-label="edit"
					onClick={() => handleEditHospitalization(action)}
				>
					<EditIcon />
				</EditIconButton>
			),
		},
	];

	let bodyContainer = (
		<ListEmptyState
			btnLabel="Agregar Hospitalización"
			onClickHandler={handleOpenHospitalizationForm}
		/>
	);

	const columnsToRender = isMobile ? columnsMobile : columns;

	if (hospitalizations && hospitalizations.length > 0) {
		const data: IData[] = hospitalizations.map((d: any) => {
			if (isMobile) {
				return {
					hospitalization_name: (
						<div>
							<b>{d.name}</b>
							<br />
							<span
								style={{
									color: 'var(--Light-Text-Secondary, rgba(0, 0, 0, 0.60))',
									fontFeatureSettings: "'clig' off, 'liga' off",
									fontFamily: 'Roboto',
									fontSize: '14px',
									fontStyle: 'normal',
									fontWeight: 400,
									lineHeight: '143%',
									letterSpacing: '0.17px',
								}}
							>
								{moment(d.date).format('DD/MM/YYYY')}
							</span>
							<p className="mt-2">{d.note ? d.note : 'Sin descripción'}</p>
						</div>
					),
					date: moment(d.date).format('DD/MM/YYYY') as string,
					action: d.id,
					isDisabled: false,
				};
			}

			return {
				hospitalization_name: d.name,
				date: moment(d.date).format('DD/MM/YYYY') as string,
				hospitalization_note: d.note ? d.note : 'Sin descripción',
				action: d.id,
				isDisabled: false,
			};
		});
		bodyContainer = (
			<Grid container>
				{!isMobile ? (
					<HeaderHistoryContainer item>
						<HistoryListTitle variant="h6">Hospitalización</HistoryListTitle>
						<NoItemButton
							variant="contained"
							color="primary"
							size="small"
							onClick={handleOpenHospitalizationForm}
							startIcon={<AddIcon fontSize="large" />}
						>
							{isMobile ? '' : 'Agregar Hospitalización'}
						</NoItemButton>
					</HeaderHistoryContainer>
				) : null}
				<CTable data={data} columns={columnsToRender} />
			</Grid>
		);
	}

	return (
		<div
			style={{
				paddingBottom: isMobile && !isAnyModalOpen ? '70px' : '',
			}}
		>
			{isHospitalizationDeleteOpen && (
				<ModalConfirmation
					modalState={isHospitalizationDeleteOpen}
					name="¿Estás seguro que quieres eliminar la siguiente hospitalización?"
					validationLabel={hospitalizationName}
					agreeTextColor="#D32F2F"
					agreeVariant="text"
					cancelVariant="text"
					closeFunction={clearHospitalizationForm}
					approveFunction={approveDelete}
				></ModalConfirmation>
			)}
			{bodyContainer}
		</div>
	);
};

export default HospitalizationList;
