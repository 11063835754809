/* eslint-disable no-lone-blocks */
// @ts-nocheck
import React, {
	useCallback,
	useEffect,
	useContext,
	Dispatch,
	SetStateAction,
	useState,
} from 'react';
import { Grid, InputAdornment, TextField } from '@material-ui/core';
import PrescriptionCard from '../../../../card/PrescriptionCard';
import SearchAutocomplete from '../../../../searchAutocomplete/SearchAutocomplete';
import { StyledTextField } from 'components/searchAutocomplete/styles';
import { StyledAutocomplete } from 'components/searchAutocomplete/styles';
import SearchIcon from '@material-ui/icons/Search';
import SidebarTreatment from './SidebarTreatment';
import {
	Medicine,
	LabImages,
	SidebarOptions,
	Templates,
	MedicineOption,
	MedicineConsultation,
	FreePrescription,
} from '../../../../../shared/type';
import MedicineList from './MedicineList';
import LabList from './LabList';
import CustomDialog from '../../../../dialog/CustomDialog';
import {
	ActionHeader,
	FormContainer,
	ListContainer,
	SearchListItemText,
	StyledCardActionBtn,
} from '../../../../../shared/style';
import MedicineDialog from '../../../../../components/medicineDialog/MedicineDialog';
import {
	AIContextProps,
	CreateTemplateLabImage,
	CreateTemplateMedicine,
	MedicineType,
	RelationsContextProps,
	TemplateMedicineType,
} from '../../../../../context/types';
import CustomMenu from '../../../../menu/CustomMenu';
import { RelationsContext } from '../../../../../context/relations-context';
import { LabImage, PrescriptionTypes, EagerLabImage } from 'models';
import AddIcon from '@material-ui/icons/Add';
import LabImageDialog from '../../../../labImageDialog/LabImageDialog';
import AIAlertSuggestion from '../AIAlertSuggestion';
import { AiContext } from 'context/ai-context';
import { AIType } from 'shared/ai/types';
import NoItem from 'components/noItems';
import { ConsultationContainer } from '../styles';
import FreePrescriptionList from './FreePrescription';
import useMobile from 'hooks/useMobile';

interface Props {
	headerMenu: null | HTMLElement;
	setHeaderMenu: (value: null | HTMLElement) => void;
	medicines: Medicine[];
	setMedicines: Dispatch<SetStateAction<Medicine[]>>;
	freePrescription: FreePrescription[];
	setFreePrescription: Dispatch<SetStateAction<FreePrescription[]>>;
	labImages: LabImages[];
	setLabImages: Dispatch<SetStateAction<LabImages[]>>;
	isMedicine: boolean;
	setIsMedicine: (value: boolean) => void;
	sidebarMedicines: SidebarOptions[];
	setSideBarMedicines: (value: SidebarOptions[]) => void;
	searchValue: SidebarOptions;
	setSearchValue: (value: SidebarOptions) => void;
	searchMedicines: SidebarOptions[];
	setSearchMedicines: (value: SidebarOptions[]) => void;
	searchLabImages: SidebarOptions[];
	setSearchLabImages: (value: SidebarOptions[]) => void;
	selectedMedicine: MedicineType;
	setSelectedMedicine: (value: MedicineType) => void;
	medicine: MedicineOption;
	setMedicine: Dispatch<SetStateAction<MedicineOption>>;
	sidebarLabs: SidebarOptions[];
	setSideLabs: (value: SidebarOptions[]) => void;
	medicalTemplates: Templates[];
	setMedicalTemplates: (value: Templates[]) => void;
	openCreateTemplate: boolean;
	setOpenCreateTemplate: (value: boolean) => void;
	templateName: string;
	setTemplateName: (value: string) => void;
	labImagesTemplates: Templates[];
	setLabImagesTemplates: (value: Templates[]) => void;
	height?: string;
	openMedicineModal?: boolean;
	medicineList: MedicineType[];
	handleAddNewMedicine: () => void;
	handleChangename: (value: string) => void;
	newMedicine: MedicineConsultation;
	isDisabledCreateMed: boolean;
	handleClickMedicine: (value: MedicineType) => void;
	handleCreateMedicine: (isFreePrescription: boolean) => void;
	deleteMedicineChip: (value: number | string) => void;
	handleMedicineChip: (value: number | string) => void;
	handleMedicineBlur: (e: React.FocusEvent<HTMLInputElement>, value: number | string) => void;
	handleMedicineChangeUnit: (
		e: React.ChangeEvent<HTMLInputElement>,
		value: number | string
	) => void;
	handleCloseNewMedicineModal: () => void;
	openLabImageModal: boolean;
	isDisabledCreateLab: boolean;
	labImagesList: LabImage[];
	handleCloseLabImageModal: () => void;
	newLabImage: LabImage;
	handleClickLabImage: (value: LabImage) => void;
	handleChangenameLabImage: (value: string) => void;
	handleCreateLabImage: (isFreePrescription: boolean) => void;
	handleAddNewLabImage: () => void;
}

const FormTreatment: React.FC<Props> = ({
	headerMenu,
	setHeaderMenu,
	medicines,
	setMedicines,
	labImages,
	setLabImages,
	isMedicine,
	setIsMedicine,
	sidebarMedicines,
	setSideBarMedicines,
	searchValue,
	setSearchValue,
	searchMedicines,
	setSearchMedicines,
	searchLabImages,
	setSearchLabImages,
	sidebarLabs,
	setSideLabs,
	medicalTemplates,
	setMedicalTemplates,
	openCreateTemplate,
	setOpenCreateTemplate,
	templateName,
	setTemplateName,
	labImagesTemplates,
	setLabImagesTemplates,
	height,
	openMedicineModal,
	medicineList,
	handleAddNewMedicine,
	handleChangename,
	newMedicine,
	isDisabledCreateMed,
	handleClickMedicine,
	handleCreateMedicine,
	handleCloseNewMedicineModal,
	openLabImageModal,
	isDisabledCreateLab,
	labImagesList,
	handleCloseLabImageModal,
	newLabImage,
	handleClickLabImage,
	handleChangenameLabImage,
	handleCreateLabImage,
	handleAddNewLabImage,
	freePrescription,
	setFreePrescription,
	freePrescriptionList,
}) => {
	const {
		fetchDoctorTemplates,
		createDoctorTemplate,
		createLabImage,
		fetchDoctorPrescriptionMedicines,
		fetchDoctorPrescriptionLabImages,
		fetchDoctorMedicines,
		fetchDoctorLabImages,
		fetchMedicines,
		fetchLabImages,
		actualDoctor,
	} = useContext(RelationsContext) as RelationsContextProps;
	const { aiSuggestions, suggestionClickHandler } = useContext(AiContext) as AIContextProps;

	const headerHandleAdd = isMedicine ? handleAddNewMedicine : handleAddNewLabImage;
	const searchValueHandler = isMedicine ? newMedicine.medicineName : newLabImage.name;
	const [showAIMedicinesSuggestions, setShowAIMedicinesSuggestions] = useState<boolean>(true);
	const [showAILabsSuggestions, setShowAILabsSuggestions] = useState<boolean>(true);
	const [isFreePrescription, setIsFreePrescription] = useState<boolean>(false);
	const { isMobile } = useMobile();
	const [mobileMedicineSelect, setMobileMedicineSelect] = useState<
		{ id: string; label: string }[]
	>([]);
	const searchHandler = isFreePrescription
		? (value: string) => {
				handleChangename(value);
				handleChangenameLabImage(value);
		  }
		: isMedicine
		? handleChangename
		: handleChangenameLabImage;
	const searchPlaceholder = isFreePrescription
		? 'Buscar'
		: isMedicine
		? 'Buscar medicamento'
		: 'Buscar Laboratorio';

	const searchOptions = isFreePrescription
		? [...searchMedicines, ...searchLabImages]
		: isMedicine
		? searchMedicines
		: searchLabImages;

	const headerBtnTxt = isMedicine ? 'Crear Medicamento' : 'Crear Lab/Imágen';
	const [prescriptionActionsRef, setPrescriptionActionsRef] = useState<null | HTMLElement>(null);

	let freePrescriptionText = '';
	if (freePrescription && freePrescription.length) {
		const item = freePrescription[0];

		if (item?.description !== '') {
			freePrescriptionText = item.description;
		} else if (item?.label) {
			freePrescriptionText = item.label;
		}
	}
	const options = [
		{
			id: 1,
			label: 'Medicamento',
			onClick: () => {
				setIsMedicine(true);
				setPrescriptionActionsRef(null);
				handleAddNewMedicine();
			},
		},
		{
			id: 2,
			label: 'Lab/imagen',
			onClick: () => {
				setIsMedicine(false);
				setPrescriptionActionsRef(null);
				handleAddNewLabImage();
			},
		},
	];

	useEffect(() => {
		const recentLabImagesList = actualDoctor?.recentLabImages?.map((recentLab) => {
			return { ...recentLab, label: recentLab.name };
		});
		const recentMedicinesList = actualDoctor?.recentMedicines?.map((recentMedi) => {
			return {
				...recentMedi,
				name: recentMedi.name,
				label: recentMedi.name,
				medicineID: recentMedi.medicineID,
				description: recentMedi.description ? recentMedi.description : '',
				showDescription: Boolean(recentMedi.description),
			};
		});

		const medTemplate =
			actualDoctor?.templates &&
			actualDoctor?.templates
				.filter((t) => t.templateType === PrescriptionTypes.MEDICINE)
				.map((t) => {
					const tempList = t.list
						? (t.list as TemplateMedicineType[])
						: ([] as TemplateMedicineType[]);
					return {
						...t,
						name: t.templateName,
						label: t.templateName,
						templateList: tempList.map((l) => {
							return {
								...l,
								label: l.name,
								description: l.description ? l.description : '',
								showDescription: Boolean(l.description),
							};
						}),
					};
				});
		const labImgTemplate =
			actualDoctor?.templates &&
			actualDoctor?.templates
				.filter((t) => t.templateType === PrescriptionTypes.LABIMAGE)
				.map((t) => {
					const tempList = t.list && (t.list as TemplateMedicineType[]);
					return {
						...t,
						name: t.templateName,
						label: t.templateName,
						templateList: tempList.map((l) => {
							return {
								...l,
								label: l.name,
							};
						}),
					};
				});
		setLabImagesTemplates(
			labImgTemplate?.length ? (labImgTemplate as Templates[]) : ([] as Templates[])
		);
		setMedicalTemplates(
			medTemplate?.length ? (medTemplate as Templates[]) : ([] as Templates[])
		);
		setSideLabs(
			recentLabImagesList?.length
				? (recentLabImagesList as SidebarOptions[])
				: ([] as SidebarOptions[])
		);
		setSideBarMedicines(
			recentMedicinesList?.length
				? (recentMedicinesList as SidebarOptions[])
				: ([] as SidebarOptions[])
		);
	}, [actualDoctor]);

	useEffect(() => {
		if (sidebarMedicines.length) {
			setMobileMedicineSelect(() => {
				return sidebarMedicines.map((item) => ({
					id: item.medicineID,
					label: item.name,
				}));
			});
		}
	}, [sidebarMedicines]);
	useEffect(() => {
		fetchMedicines();
		fetchLabImages();
		fetchDoctorTemplates();
		fetchDoctorMedicines(0, 30);
		fetchDoctorLabImages(0, 30);
		fetchDoctorPrescriptionLabImages(0, 30);
		fetchDoctorPrescriptionMedicines(0, 30);
	}, []);

	// SideBar funtionality
	const handleChangeSearch = (
		_e: React.MouseEvent<HTMLDivElement>,
		newInputValue: SidebarOptions
	) => {
		setSearchValue(newInputValue);
	};

	const handleClickSearch = (newInputValue: SidebarOptions, isFromSearchbar: boolean = false) => {
		if (isMedicine || isFreePrescription) {
			handleAddOption(
				{
					...newInputValue,
				},
				isFromSearchbar
			);
		} else {
			handleAddOption(newInputValue, isFromSearchbar);
		}
	};

	const handleAddOption = (option: SidebarOptions, isFromSearchbar: boolean = false) => {
		if (isFreePrescription) {
			const newOption = {
				id: option.id,
				description: option.label,
				label: option.label,
			};

			const hasFreePrescription = freePrescription && freePrescription?.length > 0;
			if (!isFromSearchbar && !hasFreePrescription) {
				setFreePrescription([newOption]);
				return;
			}
			setFreePrescription([
				{
					id: newOption.id
						? newOption.id
						: hasFreePrescription
						? freePrescription[0].id
						: '',
					description:
						hasFreePrescription && freePrescription[0].description !== ''
							? freePrescription[0].description + ', ' + newOption.description
							: newOption.description,
					label:
						hasFreePrescription && freePrescription[0].label !== ''
							? freePrescription[0].label + ', ' + newOption.label
							: newOption.label,
				},
			]);

			return;
		}

		if (isMedicine) {
			setMedicines((prev) => [
				...prev,
				{
					...option,
					openUnit: null,
					openSideUnit: null,
					take: option.take ? option.take : '',
					indication: option.indication ? option.indication : '',
					duration: option.duration ? option.duration : '',
					description: option.description ? option.description : '',
					showDescription: Boolean(option.description),
				},
			]);
		} else {
			setLabImages(
				(prev) =>
					[
						...prev,
						{
							...option,
						},
					] as LabImages[]
			);
		}
	};

	const handleTemplate = useCallback(
		(data: LabImages[] | Medicine[]) => {
			if (isMedicine) {
				setMedicines(
					(prev) =>
						[
							...prev,
							...(data.map((x) => ({
								...x,
								id: prev.length + 1,
							})) as Medicine[]),
						] as Medicine[]
				);
			} else {
				setLabImages(
					(prev) =>
						[
							...prev,
							...(data.map((x) => ({
								...x,
								id: prev.length + 1,
							})) as LabImages[]),
						] as LabImages[]
				);
			}
		},
		[isMedicine]
	);

	// Medicine or Lab-image funtionality
	const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
		setHeaderMenu(event.currentTarget);
	};

	const handleCloseMenu = () => {
		setHeaderMenu(null);
	};

	const handleMouseLeaveMedicine = () => {
		setMedicines(
			medicines.map((medicine: Medicine) => {
				return { ...medicine, showDescription: false };
			})
		);
	};

	const removeAll = () => {
		if (isMedicine) {
			setMedicines([]);
			setHeaderMenu(null);
		} else {
			setLabImages([]);
			setHeaderMenu(null);
		}
	};

	const openDialogCreateTemplate = () => {
		setOpenCreateTemplate(true);
		setHeaderMenu(null);
	};

	const optionsForMenu = [
		{
			id: 1,
			label: 'Crear Plantilla',
			onClick: openDialogCreateTemplate,
		},
		{
			id: 2,
			label: 'Eliminar todo',
			onClick: removeAll,
		},
	];

	const handleMouseOverMedicine = (id: number | string) => {
		setMedicines(
			medicines.map((medicine: Medicine) => {
				{
					return medicine.id === id ? { ...medicine, showDescription: true } : medicine;
				}
			})
		);
	};

	const handleDeleteMedicine = (id: number | string) => {
		setMedicines(
			medicines
				.filter((medicine: Medicine) => medicine.id !== id)
				.map((x, i) => {
					return { ...x, id: x.id ? x.id : i + 1 };
				})
		);
	};

	const handleFocusMedicine = (e: any, id: number | string) => {
		if (e.currentTarget === e.target) {
			setMedicines(
				medicines.map((medicine: Medicine) => {
					{
						return medicine.id === id
							? { ...medicine, showDescription: true }
							: medicine;
					}
				})
			);
		}
	};

	const handleUnFocuseMedicine = (e: any, id: number | string) => {
		if (e.currentTarget === e.target && e.target.value === '') {
			setMedicines(
				medicines.map((medicine: Medicine) => {
					{
						return medicine.id === id
							? { ...medicine, showDescription: false }
							: medicine;
					}
				})
			);
		}
	};

	const handleChangeMedicine = (id: number | string, label: string, value: any) => {
		setMedicines((medicines: Medicine[]) =>
			medicines.map((medicine: Medicine) =>
				medicine.id === id ? ({ ...medicine, [label]: value } as Medicine) : medicine
			)
		);
	};

	const handleDeleteLabImage = (id: number | string) => {
		setLabImages(
			labImages
				.filter((l: LabImages) => l.id !== id)
				.map((x, i) => {
					return { ...x, id: x.id ? x.id : i + 1 };
				})
		);
	};

	const handleChangeLabImage = (id: number | string, value: string) => {
		setLabImages((l: LabImages[]) => {
			return l.map((x: LabImages) =>
				x.id === id ? { ...x, description: value } : x
			) as LabImages[];
		});
	};

	const closeModalForTemplate = () => {
		setOpenCreateTemplate(false);
		setTemplateName('');
	};

	const handleAddTemplate = () => {
		if (isMedicine) {
			if (templateName !== '') {
				createDoctorTemplate(
					templateName,
					PrescriptionTypes.MEDICINE,
					medicines.map((me) => {
						return {
							...me,
							indication: me.indication || '',
							duration: me.duration || '',
							take: me.take || '',
							name: me.label,
							description: me.description ? me.description : '',
						};
					}) as CreateTemplateMedicine[]
				);
				closeModalForTemplate();
			}
		} else {
			if (templateName !== '') {
				createDoctorTemplate(
					templateName,
					PrescriptionTypes.LABIMAGE,
					labImages.map((li) => {
						return { ...li, name: li.label };
					}) as CreateTemplateLabImage[]
				);
				closeModalForTemplate();
			}
		}
	};

	const AISuggestionClickHandler = async (suggestion: string, description: string) => {
		const newLab = await createLabImage(suggestion, description);
		const newOption: SidebarOptions = {
			id: newLab?.id as string,
			label: newLab?.name as string,
			description: '',
			labImageID: newLab?.id as string,
		};

		handleAddOption(newOption);
	};

	const AIPrescriptionSuggestionType: AIType = isMedicine ? 'medicamentos' : 'estudios';

	useEffect(() => {
		setSearchValue({} as SidebarOptions);
	}, [isMedicine]);

	useEffect(() => {
		setSearchMedicines(
			medicineList?.map((medicine) => ({
				...medicine,
				medicineID: medicine.id as string,
				label: medicine.name as string,
				take: '',
				indication: '',
				duration: '',
				description: '',
			}))
		);
	}, [medicineList]);

	useEffect(() => {
		setSearchLabImages(
			labImagesList.map((lab) => ({
				...lab,
				label: lab.name || '',
				description: lab.description || '',
			}))
		);
	}, [labImagesList]);

	useEffect(() => {
		if (isMedicine) {
			setShowAIMedicinesSuggestions(true);
		} else {
			setShowAILabsSuggestions(true);
		}
	}, [aiSuggestions?.medicamentos, aiSuggestions?.estudios]);

	const onHideAiSuggestionHandler = isMedicine
		? setShowAIMedicinesSuggestions
		: setShowAILabsSuggestions;

	const handleFreePrescription = (value: string) => {
		if (value === '') {
			setFreePrescription([]);
			return;
		}

		const obj = {
			description: value,
		};

		setFreePrescription((prev) => (prev && prev.length > 0 ? [{ ...prev[0], ...obj }] : [obj]));
	};

	const handleCreateDialog = (props: MedicineOption | EagerLabImage) => {
		const value: string[] = Object.values(props);

		if (isFreePrescription) {
			setFreePrescription([
				{
					description:
						freePrescription?.length === 0
							? value[0]
							: `${
									freePrescription[0].description !== ''
										? freePrescription[0].description
										: freePrescription[0].label
							  }, ${value[0]}`,
				},
			]);
		}

		if (isMedicine) {
			handleCreateMedicine(isFreePrescription);
		} else {
			handleCreateLabImage(isFreePrescription);
		}
	};

	const handleClickDialog = (value: LabImage | MedicineValue) => {
		if (isFreePrescription) {
			setFreePrescription([
				{
					description:
						freePrescription?.length === 0
							? value.name
							: `${
									freePrescription[0].description !== ''
										? freePrescription[0].description
										: freePrescription[0].label
							  }, ${value.name}`,
				},
			]);
			if (isMedicine) {
				handleCloseNewMedicineModal();
			} else {
				handleCloseLabImageModal();
			}
			return;
		}
		if (isMedicine) {
			handleClickMedicine(value as MedicineValue);
		} else {
			handleClickLabImage(value as LabImage);
		}
	};

	const onShowAiSuggestionHandler = isMedicine
		? showAIMedicinesSuggestions
		: showAILabsSuggestions;
	const noItemsTitle = isMedicine
		? 'No has recetado medicamentos'
		: 'No has indicado ningún estudio';
	const noItemsSubtitle = isMedicine
		? 'Busca, agrega medicamentos de la lista izquierda o usa las sugerencias.'
		: 'Busca, agrega estudios de la lista izquierda o usa las sugerencias.';
	const showTemplate = () => {
		if (isFreePrescription) {
			return (
				<FormContainer item xs={9}>
					<FreePrescriptionList
						headerMenu={headerMenu}
						prescription={freePrescriptionText}
						handleAddPrescription={handleFreePrescription}
					/>
				</FormContainer>
			);
		}
		if (isMedicine) {
			const customMedicineHeight =
				aiSuggestions &&
				aiSuggestions[AIPrescriptionSuggestionType]?.length > 0 &&
				showAIMedicinesSuggestions
					? '4vh'
					: height;
			if (medicines?.length > 0) {
				return (
					<>
						<FormContainer item xs={12}>
							<MedicineList
								height={customMedicineHeight}
								headerMenu={headerMenu}
								handleClick={handleClickMenu}
								handleClose={handleCloseMenu}
								handleMouseLeaveMedicine={handleMouseLeaveMedicine}
								optionsForMenu={optionsForMenu}
								medicines={medicines}
								handleMouseOverMedicine={handleMouseOverMedicine}
								handleDeleteMedicine={handleDeleteMedicine}
								handleFocusMedicine={handleFocusMedicine}
								handleUnFocuseMedicine={handleUnFocuseMedicine}
								handleChangeMedicine={handleChangeMedicine}
							/>
						</FormContainer>
					</>
				);
			}
		}
		if (!isMedicine && labImages.length > 0) {
			const customLabHeight =
				aiSuggestions &&
				aiSuggestions[AIPrescriptionSuggestionType].length > 0 &&
				showAILabsSuggestions
					? '14vh'
					: height;
			return (
				<FormContainer item xs={12}>
					<LabList
						height={customLabHeight}
						headerMenu={headerMenu}
						handleClick={handleClickMenu}
						handleClose={handleCloseMenu}
						optionsForMenu={optionsForMenu}
						labImages={labImages}
						handleDeleteLabImage={handleDeleteLabImage}
						handleChangeLabImage={handleChangeLabImage}
					/>
				</FormContainer>
			);
		}
		return (
			<NoItem
				onClickHandler={() => null}
				isRow
				title={noItemsTitle}
				subTitle={noItemsSubtitle}
				width="401px"
				height="401px"
			/>
		);
	};

	return (
		<ConsultationContainer
			style={{
				width: 'inherit',
			}}
			data-fx-name="prescriptionForm"
		>
			{openMedicineModal && (
				<MedicineDialog
					isDisabled={isDisabledCreateMed}
					isUpdate={false}
					open={openMedicineModal}
					searchMedicines={medicineList}
					handleClose={handleCloseNewMedicineModal}
					medicine={newMedicine as MedicineOption}
					handleClickMedicine={handleClickDialog}
					handleChangename={handleChangename}
					handleAgree={handleCreateDialog}
				/>
			)}
			{openLabImageModal && (
				<LabImageDialog
					isDisabled={isDisabledCreateLab}
					isUpdate={false}
					open={openLabImageModal}
					searchLabImages={labImagesList || []}
					handleClose={handleCloseLabImageModal}
					labImage={newLabImage}
					handleClickLabImage={handleClickDialog}
					handleChangename={handleChangenameLabImage}
					handleAgree={handleCreateDialog}
				/>
			)}
			{!isMobile && (
				<Grid item xs={12}>
					<PrescriptionCard
						title="Prescripciones"
						headerAction={
							<ActionHeader>
								{isFreePrescription ? (
									<CustomMenu
										options={options}
										handleClose={() => setPrescriptionActionsRef(null)}
										handleClick={(e) =>
											setPrescriptionActionsRef(e.currentTarget)
										}
										headerMenu={prescriptionActionsRef}
										customButtonContent={
											<>
												<AddIcon /> Agregar
											</>
										}
										isCustomButton
									/>
								) : (
									<StyledCardActionBtn
										size={'small'}
										onClick={headerHandleAdd}
										color="primary"
										startIcon={<AddIcon />}
									>
										{headerBtnTxt}
									</StyledCardActionBtn>
								)}
								<div style={{ width: '250px', marginLeft: '20px' }}>
									<SearchAutocomplete
										id="autocomplete-search"
										inputValue={searchValueHandler}
										options={searchOptions}
										placeholder={searchPlaceholder}
										onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
											e !== null && e.target.value
												? searchHandler(e.target.value)
												: searchHandler('');
										}}
										onChange={handleChangeSearch}
										getOptionLabel={(option) =>
											option.label ? option.label : ''
										}
										renderOption={(option) => {
											return (
												<>
													<SearchListItemText
														onClick={() => {
															handleClickSearch?.(option, true);
														}}
														primary={option.label ? option.label : ''}
													/>
												</>
											);
										}}
										startAdornment={
											<InputAdornment position="start">
												<SearchIcon />
											</InputAdornment>
										}
									/>
								</div>
							</ActionHeader>
						}
						height={!!aiSuggestions ? '700px' : '592px'}
					>
						<Grid container>
							{isMobile ? (
								<>
									<Grid xs={12}>
										<StyledAutocomplete
											id="medicament-select"
											options={mobileMedicineSelect}
											getOptionLabel={(option: any) => option.label}
											onChange={(e: any, val: any) => {
												if (val) {
													handleAddOption(e, val);
												}
											}}
											renderOption={(option: any) => (
												<div>{option.label}</div>
											)}
											renderInput={(params: any) => (
												<StyledTextField
													{...params}
													label="Medicamento"
													margin="normal"
													variant="outlined"
													style={{ width: '100%' }}
													InputProps={{
														...params.InputProps,
													}}
												/>
											)}
										/>
									</Grid>
									{showTemplate()}
								</>
							) : (
								<>
									<ListContainer item xs={2}>
										<SidebarTreatment
											height={'calc(100dvh - 58vh + 215px)'}
											hasSearch={false}
											handleAddOption={handleAddOption}
											isMedicine={isMedicine}
											setIsMedicine={(val) => {
												setIsFreePrescription(false);
												setIsMedicine(val);
											}}
											setIsFreePrescription={setIsFreePrescription}
											isFreePrescription={isFreePrescription}
											quantMedicines={medicines?.length}
											quantLabImages={labImages?.length}
											sidebarMedicines={sidebarMedicines}
											sidebarLabs={sidebarLabs}
											medicalTemplates={medicalTemplates}
											labImagesTemplates={labImagesTemplates}
											handleTemplate={handleTemplate}
											handleChangename={handleChangename}
											medicinesSuggestions={medicineList}
											labImagesSuggestions={labImagesList}
											freePrescriptionSuggestions={freePrescriptionList}
											hasFreePrescription={
												freePrescription?.length > 0 ? true : false
											}
										/>
									</ListContainer>
									<Grid
										item
										xs={10}
										style={{
											padding: '16px 0 16px 16px',
											display: 'flex',
											flexDirection: 'column',
											flexGrow: 0,
											flexBasis: '75%',
										}}
									>
										{showTemplate()}
										{aiSuggestions &&
											aiSuggestions[AIPrescriptionSuggestionType].length >
												0 &&
											onShowAiSuggestionHandler && (
												<AIAlertSuggestion
													clickAble={!isMedicine}
													suggestions={aiSuggestions}
													add={suggestionClickHandler}
													cb={AISuggestionClickHandler}
													type={AIPrescriptionSuggestionType}
													onHide={() => onHideAiSuggestionHandler(false)}
													style={{
														flexDirection: `${
															isMedicine ? 'column' : 'row'
														}`,
														alignItems: `${
															isMedicine ? 'baseline' : ''
														}`,
														flexGrow: 1,
													}}
												/>
											)}
									</Grid>
									<Grid
										item
										xs={10}
										style={{
											padding: '16px 0 16px 16px',
											display: 'flex',
											flexDirection: 'column',
											flexGrow: 0,
											flexBasis: '75%',
										}}
									>
										{showTemplate()}
										{aiSuggestions &&
											aiSuggestions[AIPrescriptionSuggestionType].length >
												0 &&
											onShowAiSuggestionHandler && (
												<AIAlertSuggestion
													clickAble={!isMedicine}
													suggestions={aiSuggestions}
													add={suggestionClickHandler}
													cb={AISuggestionClickHandler}
													type={AIPrescriptionSuggestionType}
													onHide={() => onHideAiSuggestionHandler(false)}
													style={{
														flexDirection: `${
															isMedicine ? 'column' : 'row'
														}`,
														alignItems: `${
															isMedicine ? 'baseline' : ''
														}`,
														flexGrow: 1,
													}}
												/>
											)}
									</Grid>
								</>
							)}
						</Grid>
					</PrescriptionCard>
				</Grid>
			)}

			{openCreateTemplate && (
				<CustomDialog
					open={openCreateTemplate}
					handleClose={closeModalForTemplate}
					title="Agrega un nombre a la nueva plantilla"
					disagreeText="Cancelar"
					agreeText="Guardar"
					handleAgree={handleAddTemplate}
				>
					<TextField
						label="Nombre de plantilla"
						placeholder="Nombre de plantilla"
						fullWidth
						variant="outlined"
						value={templateName}
						onChange={(e) => setTemplateName(e.target.value)}
					/>
				</CustomDialog>
			)}
		</ConsultationContainer>
	);
};

export default FormTreatment;
