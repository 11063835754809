import React, { FC } from 'react';
import {
	StyledFormControlLabel,
	CardDescription,
	SubscriptionCard,
	HelperText,
	StyledRadio,
	PriceContainer,
} from '../../shared/style';
import useMobile from 'hooks/useMobile';

interface Props {
	selectedPlan: number;
	handleSelectPlan: (value: number) => void;
	value: number;
	title: string;
	subtitle: string;
	price: string;
	showTrial?: boolean;
}

const PlanCard: FC<Props> = ({
	selectedPlan,
	handleSelectPlan,
	value,
	title,
	subtitle,
	price,
	showTrial = true,
}) => {
	const { isMobile } = useMobile();
	const labelFormatted = () => {
		return (
			<p>
				{title}
				{showTrial ? (
					<span
						style={{
							fontWeight: 'normal',
						}}
					>
						- 14 días gratis
					</span>
				) : (
					''
				)}
			</p>
		);
	};

	const SelectedPlanType = price.includes('7') ? 'Anual' : 'Mes por mes';

	const planLabel = () => {
		if (isMobile) {
			return (
				<CardDescription>
					<StyledFormControlLabel
						control={
							<StyledRadio
								name="plan_price"
								checked={selectedPlan === value}
								value={value}
								color="primary"
								onChange={(e) => handleSelectPlan(parseInt(e.target.value))}
							/>
						}
						label={
							<p className="flex gap-[9px] font-semibold">
								{SelectedPlanType}{' '}
								<PriceContainer className="font-medium text-[14px] no-italic tracking-[0.1px] decoration-[rgba(21, 101, 192, 1)]">
									RD$ {price}
								</PriceContainer>
							</p>
						}
						className="text-[14px] md:text-[16px]"
					/>

					<HelperText>{subtitle}</HelperText>
				</CardDescription>
			);
		} else {
			return (
				<>
					{' '}
					<CardDescription>
						<StyledFormControlLabel
							control={
								<StyledRadio
									name="plan_price"
									checked={selectedPlan === value}
									value={value}
									color="primary"
									onChange={(e) => handleSelectPlan(parseInt(e.target.value))}
								/>
							}
							label={labelFormatted()}
							className="text-[14px] md:text-[16px]"
						/>
						<HelperText>{subtitle}</HelperText>
					</CardDescription>
					<PriceContainer className="font-medium text-[14px] no-italic tracking-[0.1px] decoration-[rgba(21, 101, 192, 1)]">
						RD$ {price}
					</PriceContainer>
				</>
			);
		}
	};
	return (
		<SubscriptionCard
			onClick={() => handleSelectPlan(value)}
			style={{ border: selectedPlan === value ? 'solid 3px #007dff' : 'solid 3px #949494' }}
		>
			{planLabel()}
		</SubscriptionCard>
	);
};

export default PlanCard;
