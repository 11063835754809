import { useEffect, useState } from 'react';

const useMobile = () => {
	const [isMobile, setIsMobile] = useState(false);
	const [hasResize, setHasResize] = useState(false);
	const [isMobileWidth, setIsMobileWidth] = useState(0);

	useEffect(() => {
		const handleResize = () => {
			const currentSize = window.innerWidth;
			const newIsMobile = currentSize < 1024;

			if (newIsMobile) {
				setIsMobileWidth(currentSize);
			}

			setIsMobile(newIsMobile);
			if (!hasResize) {
				setHasResize(true);
			}
		};

		handleResize();

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [hasResize]);

	return { isMobile, hasResize, isMobileWidth };
};

export default useMobile;
