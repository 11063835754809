import { Chip, Grid } from '@material-ui/core';
import React, { FC, useContext } from 'react';
import AddIcon from '@material-ui/icons/Add';
import {
	EditIconButton,
	HeaderHistoryContainer,
	HistoryListTitle,
	NoItemButton,
} from '../../../../shared/style';
import EditIcon from '@material-ui/icons/Edit';
import ListEmptyState from './ListEmptyState';
import CTable from '../../../table/CTable';
import ModalConfirmation from '../../../modalConfirmation/ModalConfirmation';
import { IColumnType } from '../../../../shared/type';
import { AllergyStatus } from 'models';
import { ClientType, RelationsContextProps } from '../../../../context/types';
import moment from 'moment';
import { HistoryContext, HistoryContextProps } from '../../../../context/history-context';
import { RelationsContext } from '../../../../context/relations-context';
import useMobile from 'hooks/useMobile';

interface IData {
	allergy_name: string | JSX.Element;
	diagnosed_date: string;
	status: AllergyStatus;
	action: string;
	align?: 'center' | 'inherit' | 'justify' | 'left' | 'right';
	isDisabled?: boolean;
}

interface Props {
	allergyId: string;
	clearAllergyForm: () => void;
	handleEditAllergy: (id: string) => void;
	handleOpenAllergyForm: () => void;
	isAllergyDeleteOpen: boolean;
	allergyName: string;
	allergies: any;
	setClientHistory: (client: any) => void;

	isAnyModalOpen: boolean;
}

const orderedAllergies = (a: IData, b: IData) => {
	if (a.status === 'ACTIVE' && b.status !== 'ACTIVE') {
		return -1;
	}
	if (a.status !== 'ACTIVE' && b.status === 'ACTIVE') {
		return 1;
	}
	if (a.status === 'CURED' && b.status !== 'CURED') {
		return -1;
	}
	if (a.status !== 'CURED' && b.status === 'CURED') {
		return 1;
	}

	if (a.status === 'INACTIVE' && b.status !== 'INACTIVE') {
		return -1;
	}
	if (a.status !== 'INACTIVE' && b.status === 'INACTIVE') {
		return 1;
	}

	return 0;
};

const AllergyList: FC<Props> = ({
	allergyId,
	clearAllergyForm,
	handleEditAllergy,
	handleOpenAllergyForm,
	isAllergyDeleteOpen,
	allergyName,
	allergies,
	setClientHistory,
	isAnyModalOpen = false,
}) => {
	const { deletePatientAllergy } = useContext(HistoryContext) as HistoryContextProps;
	const { setAlertMessage } = useContext(RelationsContext) as RelationsContextProps;
	const { isMobile } = useMobile();
	const approveDelete = async () => {
		await deletePatientAllergy(allergyId);
		setClientHistory((prevState: ClientType) => ({
			...prevState,
			allergies: prevState?.allergies?.filter((a) => a.id !== allergyId),
		}));
		clearAllergyForm();
		setAlertMessage({
			message: 'Alergia eliminada',
			severity: 'success',
		});
	};

	const columns: IColumnType<IData>[] = [
		{
			key: 'allergy_name',
			title: 'Nombre de alergias',
		},
		{
			key: 'diagnosed_date',
			title: 'Fecha de diagnóstico',
			width: 170,
		},
		{
			key: 'status',
			title: 'Estado',
			width: 90,
			align: 'left',
			render: (_, { status }) => {
				let statusLabel = 'Activa';
				let color = '#FFFFFF';
				let bgColor = '#1976D2';
				if (AllergyStatus.CURED === status) {
					statusLabel = 'Curada';
					color = 'rgba(0, 0, 0, 0.87)';
					bgColor = '#CFD8DC';
				}
				if (AllergyStatus.INACTIVE === status) {
					statusLabel = 'Inactiva';
					color = 'rgba(0, 0, 0, 0.87)';
					bgColor = '#E0E0E0';
				}
				return (
					<Chip
						size="small"
						style={{ backgroundColor: bgColor, color: color, fontWeight: 500 }}
						label={statusLabel}
					/>
				);
			},
		},
		{
			key: 'action',
			title: '',
			width: 35,
			render: (_, { action }) => (
				<EditIconButton
					custommarginleft="0px"
					aria-label="edit"
					onClick={() => handleEditAllergy(action)}
				>
					<EditIcon />
				</EditIconButton>
			),
		},
	];

	const mobileColumns: IColumnType<IData>[] = [
		{
			key: 'allergy_name',
			title: 'Nombre de alergias',
		},
		{
			key: 'status',
			title: 'Estado',
			width: 90,
			align: 'left',
			render: (_, { status }) => {
				let statusLabel = 'Activa';
				let color = '#FFFFFF';
				let bgColor = '#1976D2';
				if (AllergyStatus.CURED === status) {
					statusLabel = 'Curada';
					color = 'rgba(0, 0, 0, 0.87)';
					bgColor = '#CFD8DC';
				}
				if (AllergyStatus.INACTIVE === status) {
					statusLabel = 'Inactiva';
					color = 'rgba(0, 0, 0, 0.87)';
					bgColor = '#E0E0E0';
				}
				return (
					<Chip
						size="small"
						style={{ backgroundColor: bgColor, color: color, fontWeight: 500 }}
						label={statusLabel}
					/>
				);
			},
		},
		{
			key: 'action',
			title: '',
			width: 35,
			render: (_, { action }) => (
				<EditIconButton
					custommarginleft="0px"
					aria-label="edit"
					onClick={() => handleEditAllergy(action)}
				>
					<EditIcon />
				</EditIconButton>
			),
		},
	];

	const columnsToUse = isMobile ? mobileColumns : columns;

	let bodyContainer = (
		<ListEmptyState btnLabel="Agregar Alergia" onClickHandler={handleOpenAllergyForm} />
	);

	if (allergies && allergies.length > 0) {
		const data: IData[] = [
			...allergies
				.map((d: any) => {
					if (isMobile) {
						return {
							allergy_name: (
								<div>
									<b>{d.name}</b>
									<br />
									<span
										style={{
											color: 'var(--Light-Text-Secondary, rgba(0, 0, 0, 0.60))',
											fontFeatureSettings: "'clig' off, 'liga' off",
											fontFamily: 'Roboto',
											fontSize: '14px',
											fontStyle: 'normal',
											fontWeight: 400,
											lineHeight: '143%',
											letterSpacing: '0.17px',
										}}
									>
										{moment(d.diagnosedDate).format('DD/MM/YYYY')}
									</span>
								</div>
							),
							status: d.status as AllergyStatus,
							action: d.id,
							isDisabled: d.status !== AllergyStatus.ACTIVE ? true : false,
						};
					}

					return {
						allergy_name: d.name,
						diagnosed_date: moment(d.diagnosedDate).format('DD/MM/YYYY') as string,
						status: d.status as AllergyStatus,
						action: d.id,
						isDisabled: d.status !== AllergyStatus.ACTIVE ? true : false,
					};
				})
				.sort(orderedAllergies),
		];
		bodyContainer = (
			<Grid container>
				<HeaderHistoryContainer item>
					{!isMobile ? (
						<>
							<HistoryListTitle variant="h6">Alergias</HistoryListTitle>
							<NoItemButton
								variant="contained"
								color="primary"
								size="small"
								onClick={handleOpenAllergyForm}
								startIcon={<AddIcon fontSize="large" />}
							>
								Agregar Alergia
							</NoItemButton>
						</>
					) : null}
				</HeaderHistoryContainer>
				<CTable data={data} columns={columnsToUse} />
			</Grid>
		);
	}

	return (
		<div
			style={{
				paddingBottom: isMobile && !isAnyModalOpen ? '70px' : '',
			}}
		>
			{isAllergyDeleteOpen && (
				<ModalConfirmation
					modalState={isAllergyDeleteOpen}
					name="¿Estás seguro que quieres eliminar la siguiente alergia? "
					validationLabel={allergyName}
					agreeTextColor="#D32F2F"
					closeFunction={clearAllergyForm}
					approveFunction={approveDelete}
					agreeVariant="text"
					cancelVariant="text"
				></ModalConfirmation>
			)}
			{bodyContainer}
		</div>
	);
};

export default AllergyList;
