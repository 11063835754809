import { Checkbox, FormControl, FormGroup, TextField } from '@material-ui/core';
import React, { FC, useContext } from 'react';
import { HistoryFamilyMember } from 'models';
import { ClientsContext } from '../../../../context/client-context';
import { ClientContextProps } from '../../../../context/types';
import { CheckboxFormLabel, CheckboxFormControlLabel } from '../../../../shared/style';
import { replaceCommanForBullet } from '../../../../shared/utilFunctions';
import CustomDialog from '../../../dialog/CustomDialog';
import { getFamilyLabel } from '../util';
import HistoryTitle from './FamilyHistoryFormTitle';

interface Props {
	familyMember: HistoryFamilyMember;
	isOpen: boolean;
	handleClose: () => void;
	onSubmit: () => void;
	isEdit: boolean;
	mellitusDiabetes: boolean;
	handleMellitusDiabetes: (event: React.ChangeEvent<HTMLInputElement>) => void;
	mellitusDiabetesNote: string;
	handleMellitusDiabetesNote: (event: React.ChangeEvent<HTMLInputElement>) => void;
	arterialHypertension: boolean;
	handleArterialHypertension: (event: React.ChangeEvent<HTMLInputElement>) => void;
	arterialHypertensionNote: string;
	handleArterialHypertensionNote: (event: React.ChangeEvent<HTMLInputElement>) => void;
	heartDisease: boolean;
	handleHeartDisease: (event: React.ChangeEvent<HTMLInputElement>) => void;
	heartDiseaseNote: string;
	handleHeartDiseaseNote: (event: React.ChangeEvent<HTMLInputElement>) => void;
	nephropathy: boolean;
	handleNephropathy: (event: React.ChangeEvent<HTMLInputElement>) => void;
	nephropathyNote: string;
	handleNephropathyNote: (event: React.ChangeEvent<HTMLInputElement>) => void;
	neuropathies: boolean;
	handleNeuropathies: (event: React.ChangeEvent<HTMLInputElement>) => void;
	neuropathiesNote: string;
	handleNeuropathiesNote: (event: React.ChangeEvent<HTMLInputElement>) => void;
	epilepsy: boolean;
	handleEpilepsy: (event: React.ChangeEvent<HTMLInputElement>) => void;
	epilepsyNote: string;
	handleEpilepsyNote: (event: React.ChangeEvent<HTMLInputElement>) => void;
	rheumatoidArthritis: boolean;
	handleRheumatoidArthritis: (event: React.ChangeEvent<HTMLInputElement>) => void;
	rheumatoidArthritisNote: string;
	handleRheumatoidArthritisNote: (event: React.ChangeEvent<HTMLInputElement>) => void;
	psychiatricDiseases: boolean;
	handlePsychiatricDiseases: (event: React.ChangeEvent<HTMLInputElement>) => void;
	psychiatricDiseasesNote: string;
	handlePsychiatricDiseasesNote: (event: React.ChangeEvent<HTMLInputElement>) => void;
	cancer: boolean;
	handleCancer: (event: React.ChangeEvent<HTMLInputElement>) => void;
	cancerNote: string;
	handleCancerNote: (event: React.ChangeEvent<HTMLInputElement>) => void;
	other: boolean;
	handleOther: (event: React.ChangeEvent<HTMLInputElement>) => void;
	otherNote: string;
	handleOtherNote: (event: React.ChangeEvent<HTMLInputElement>) => void;
	isMobile?: boolean;
}

const FamilyHistoryFormDialog: FC<Props> = (props) => {
	const { selectedClient } = useContext(ClientsContext) as ClientContextProps;

	const familySelectedClient = selectedClient?.familyHistories?.find(
		(cfm) => cfm.familyMember === props.familyMember
	);

	const disabledButton =
		familySelectedClient?.mellitusDiabetes === props.mellitusDiabetes &&
		familySelectedClient?.arterialHypertension === props.arterialHypertension &&
		familySelectedClient?.heartDisease === props.heartDisease &&
		familySelectedClient?.nephropathy === props.nephropathy &&
		familySelectedClient?.epilepsy === props.epilepsy &&
		familySelectedClient?.rheumatoidArthritis === props.rheumatoidArthritis &&
		familySelectedClient?.psychiatricDiseases === props.psychiatricDiseases &&
		familySelectedClient?.cancer === props.cancer &&
		familySelectedClient?.other === props.other &&
		replaceCommanForBullet(familySelectedClient?.mellitusDiabetesNote as string) ===
			props.mellitusDiabetesNote &&
		replaceCommanForBullet(familySelectedClient?.arterialHypertensionNote as string) ===
			props.arterialHypertensionNote &&
		replaceCommanForBullet(familySelectedClient?.heartDiseaseNote as string) ===
			props.heartDiseaseNote &&
		replaceCommanForBullet(familySelectedClient?.nephropathyNote as string) ===
			props.nephropathyNote &&
		replaceCommanForBullet(familySelectedClient?.epilepsyNote as string) ===
			props.epilepsyNote &&
		replaceCommanForBullet(familySelectedClient?.rheumatoidArthritisNote as string) ===
			props.rheumatoidArthritisNote &&
		replaceCommanForBullet(familySelectedClient?.psychiatricDiseasesNote as string) ===
			props.psychiatricDiseasesNote &&
		replaceCommanForBullet(familySelectedClient?.cancerNote as string) === props.cancerNote &&
		replaceCommanForBullet(familySelectedClient?.otherNote as string) === props.otherNote;

	const title = props.isEdit
		? `Actualizar datos del ${getFamilyLabel(props.familyMember)}`
		: `Agregar datos del ${getFamilyLabel(props.familyMember)}`;

	return (
		<CustomDialog
			open={props.isOpen}
			disabled={disabledButton}
			disableBackdropClick
			className={props.isMobile ? 'appointment-modal' : ''}
			fullScreen={props.isMobile}
			fullWidth
			maxWidth="xs"
			handleClose={props.handleClose}
			title={
				props.isMobile ? <HistoryTitle title={title} goBack={props.handleClose} /> : title
			}
			disagreeText="Cancelar"
			agreeText="Guardar"
			handleAgree={props.onSubmit}
		>
			<FormControl
				component="fieldset"
				fullWidth
				style={{
					marginTop: '0.5rem',
				}}
			>
				<CheckboxFormLabel>Enfermedades heredofamiliares</CheckboxFormLabel>
				<FormGroup
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '0px',
					}}
				>
					<CheckboxFormControlLabel
						control={
							<Checkbox
								checked={props.mellitusDiabetes}
								onChange={props.handleMellitusDiabetes}
								color="primary"
								name="mellitusDiabetes"
							/>
						}
						label="Diabetes mellitus"
					/>
					{props.mellitusDiabetes && (
						<TextField
							id="outlined-mellitus-diabetes"
							label="Datos sobre Diabetes mellitus"
							multiline
							fullWidth
							value={props.mellitusDiabetesNote}
							onChange={props.handleMellitusDiabetesNote}
							minRows={1}
							variant="outlined"
						/>
					)}
					<CheckboxFormControlLabel
						control={
							<Checkbox
								checked={props.arterialHypertension}
								onChange={props.handleArterialHypertension}
								color="primary"
								name="arterialHypertension"
							/>
						}
						label="Hipertensión arterial"
					/>
					{props.arterialHypertension && (
						<TextField
							id="outlined-arterial-hypertension"
							label="Datos sobre Hipertensión arterial"
							multiline
							fullWidth
							value={props.arterialHypertensionNote}
							onChange={props.handleArterialHypertensionNote}
							minRows={1}
							variant="outlined"
						/>
					)}
					<CheckboxFormControlLabel
						control={
							<Checkbox
								checked={props.heartDisease}
								onChange={props.handleHeartDisease}
								color="primary"
								name="heartDisease"
							/>
						}
						label="Cardiopatías"
					/>
					{props.heartDisease && (
						<TextField
							id="outlined-heart-disease"
							label="Datos sobre la cardiopatías"
							multiline
							fullWidth
							value={props.heartDiseaseNote}
							onChange={props.handleHeartDiseaseNote}
							minRows={1}
							variant="outlined"
						/>
					)}
					<CheckboxFormControlLabel
						control={
							<Checkbox
								checked={props.nephropathy}
								onChange={props.handleNephropathy}
								color="primary"
								name="nephropathy"
							/>
						}
						label="Nefropatías"
					/>
					{props.nephropathy && (
						<TextField
							id="outlined-nephropathy"
							label="Datos sobre la nefropatías"
							multiline
							fullWidth
							value={props.nephropathyNote}
							onChange={props.handleNephropathyNote}
							minRows={1}
							variant="outlined"
						/>
					)}
					<CheckboxFormControlLabel
						control={
							<Checkbox
								checked={props.neuropathies}
								onChange={props.handleNeuropathies}
								color="primary"
								name="neuropathies"
							/>
						}
						label="Neuropatías"
					/>
					{props.neuropathies && (
						<TextField
							id="outlined-neuropathies"
							label="Datos sobre la neuropatías"
							multiline
							fullWidth
							value={props.neuropathiesNote}
							onChange={props.handleNeuropathiesNote}
							minRows={1}
							variant="outlined"
						/>
					)}
					<CheckboxFormControlLabel
						control={
							<Checkbox
								checked={props.epilepsy}
								onChange={props.handleEpilepsy}
								color="primary"
								name="epilepsy"
							/>
						}
						label="Epilepsia"
					/>
					{props.epilepsy && (
						<TextField
							id="outlined-epilepsy"
							label="Datos sobre la epilepsia"
							multiline
							fullWidth
							value={props.epilepsyNote}
							onChange={props.handleEpilepsyNote}
							minRows={1}
							variant="outlined"
						/>
					)}
					<CheckboxFormControlLabel
						control={
							<Checkbox
								checked={props.rheumatoidArthritis}
								onChange={props.handleRheumatoidArthritis}
								color="primary"
								name="rheumatoidArthritis"
							/>
						}
						label="Artritis reumatoide"
					/>
					{props.rheumatoidArthritis && (
						<TextField
							id="outlined-rheumatoid-arthritis"
							label="Datos sobre Artritis reumatoide"
							multiline
							fullWidth
							value={props.rheumatoidArthritisNote}
							onChange={props.handleRheumatoidArthritisNote}
							minRows={1}
							variant="outlined"
						/>
					)}
					<CheckboxFormControlLabel
						control={
							<Checkbox
								checked={props.psychiatricDiseases}
								onChange={props.handlePsychiatricDiseases}
								color="primary"
								name="psychiatricDiseases"
							/>
						}
						label="Enfermedades psiquiátricas"
					/>
					{props.psychiatricDiseases && (
						<TextField
							id="outlined-psychiatric-diseases"
							label="Datos sobre Enfermedades psiquiátricas"
							multiline
							fullWidth
							value={props.psychiatricDiseasesNote}
							onChange={props.handlePsychiatricDiseasesNote}
							minRows={1}
							variant="outlined"
						/>
					)}
					<CheckboxFormControlLabel
						control={
							<Checkbox
								checked={props.cancer}
								onChange={props.handleCancer}
								color="primary"
								name="cancer"
							/>
						}
						label="Cáncer"
					/>
					{props.cancer && (
						<TextField
							id="outlined-cancer"
							label="Datos sobre Cáncer"
							multiline
							fullWidth
							value={props.cancerNote}
							onChange={props.handleCancerNote}
							minRows={1}
							variant="outlined"
						/>
					)}

					<CheckboxFormControlLabel
						control={
							<Checkbox
								checked={props.other}
								onChange={props.handleOther}
								color="primary"
								name="other"
							/>
						}
						label="Otros"
					/>
					{props.other && (
						<TextField
							id="outlined-other"
							label="Datos sobre Otros"
							multiline
							fullWidth
							value={props.otherNote}
							onChange={props.handleOtherNote}
							minRows={1}
							variant="outlined"
						/>
					)}
				</FormGroup>
			</FormControl>
		</CustomDialog>
	);
};

export default FamilyHistoryFormDialog;
