import React, { FC } from 'react';
import { IADiagnostic } from 'shared/ai/types';
import { AIReportDescription, AIReportTitle } from './styles';

interface Props {
	diagnostic: IADiagnostic;
	index: number;
}

export const DiagnosticSection: FC<Props> = ({ diagnostic, index }) => {
	return (
		<div
			key={`${diagnostic.nombre}-${index}`}
			style={{
				marginTop: '10px',
			}}
		>
			<AIReportTitle>
				{index + 1}- {diagnostic.nombre}
			</AIReportTitle>
			<AIReportDescription
				style={{
					marginTop: '0px',
				}}
			>
				{diagnostic.descripcion}
			</AIReportDescription>
		</div>
	);
};

export default DiagnosticSection;
