import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ClientImageType } from '../../../context/types';
import { FileDescription } from '../../../shared/style';
import PreviewIMG from './PreviewIMG';
import pdfImg from 'assets/images/pdf_img.svg';
import wordImg from 'assets/images/word_img.svg';
import ImageOptions from './ImageOptions';

interface Props {
	files: ClientImageType[];
	img: ClientImageType;
	deleteImg: () => void;
	updateDescriptionImg: (id: string, description: string) => void;
	imgIndex: number;
}

const ImageFile: React.FC<Props> = ({ files, img, deleteImg, updateDescriptionImg, imgIndex }) => {
	const { url, description: imgDescription } = img;
	const [description, setDescription] = useState('');
	const [modalPreview, setModalPreview] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [hover, setHover] = useState(false);

	const handleMouseEnter = () => {
		setHover(true);
	};

	const handleMouseLeave = () => {
		setHover(false);
	};

	useEffect(() => {
		setDescription(imgDescription as string);
	}, [imgDescription]);

	const handlePDF = (urlBlob: Blob, filename: string) => {
		const url = URL.createObjectURL(urlBlob);
		const a = document.createElement('a');
		a.href = url;
		a.download = filename || 'download';
		const clickHandler = () => {
			setTimeout(() => {
				URL.revokeObjectURL(url);
				a.removeEventListener('click', clickHandler);
			}, 150);
		};
		a.addEventListener('click', clickHandler, false);
		a.click();
		return a;
	};

	const onPreview =
		description.includes('pdf') || description.includes('doc') || description.includes('docx')
			? () => handlePDF(url, description)
			: () => setModalPreview(true);

	return (
		<div
			className="flex flex-col mt-3 ml-4 items-start relative"
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<ImageOptions
				onDelete={deleteImg}
				onPreview={onPreview}
				onDownload={() => handlePDF(url, description)}
			/>
			<PreviewIMG
				images={files.filter(
					(x) =>
						x.description?.toLowerCase()?.includes('jpg') ||
						x.description?.toLowerCase()?.includes('png') ||
						x.description?.toLowerCase()?.includes('jpeg')
				)}
				previewState={modalPreview}
				setPreviewState={setModalPreview}
				imgIndex={imgIndex}
			/>
			{url ? (
				<>
					{description.includes('pdf') ||
					description.includes('doc') ||
					description.includes('docx') ? (
						<button
							className="file-container object-cover rounded cursor-pointer"
							onClick={() => handlePDF(url, description)}
						>
							<img
								src={
									description.includes('doc') || description.includes('docx')
										? wordImg
										: pdfImg
								}
								alt=""
							/>
						</button>
					) : (
						<button onClick={() => setModalPreview(true)}>
							<img
								className="file-container object-cover cursor-pointer"
								src={URL.createObjectURL(url)}
								alt=""
							/>
						</button>
					)}
					<FileDescription variant="body2" title={description}>
						{description}
					</FileDescription>
				</>
			) : (
				<div className="border border-inherit w-48 h-36 object-cover cursor-pointer flex items-center justify-center OpenSansRegular">
					<CircularProgress />
				</div>
			)}
		</div>
	);
};

export default ImageFile;
