import { Button } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { FilesType, ClientImageType, ClientContextProps } from '../../../context/types';
import { FileInputDescription } from '../../../shared/style';
import ImageFile from './ImageFile';
import { ClientsContext } from 'context/client-context';
import { FormatDate, getDateToString } from 'shared/dateUtils';
import { ImageSkeleton } from './skeletons';

interface Props {
	file: FilesType;
	deleteSection: () => void;
	deleteImg: (id: ClientImageType) => void;
	updateDescriptionSection: (id: string, description: string) => void;
	updateDescriptionImg: (id: string, description: string) => void;
}

const DateSectionFile: React.FC<Props> = ({
	file,
	deleteSection,
	deleteImg,
	updateDescriptionSection,
	updateDescriptionImg,
}) => {
	const { description: fileDescription } = file;
	const [description, setDescription] = useState('');
	const { imageLoading } = useContext(ClientsContext) as ClientContextProps;

	useEffect(() => {
		setDescription(fileDescription as string);
	}, [fileDescription]);
	const images = () => {
		const imageToRender = file.images;

		const formattedImages = imageToRender.map((img, index) => (
			<div key={img.id}>
				<ImageFile
					files={imageToRender}
					img={img}
					deleteImg={() => deleteImg(img)}
					updateDescriptionImg={updateDescriptionImg}
					imgIndex={index}
				/>
			</div>
		));
		const date = getDateToString(new Date(), FormatDate['Monthname DD, YYYY']);
		if (imageLoading && file.date === date) {
			formattedImages.push(<ImageSkeleton />);
		}

		return formattedImages;
	};

	return (
		<div className="flex flex-col mt-8">
			<FileInputDescription
				title={description}
				className="raleway-font text-2xl font-semibold input-status bg-green-content overflow-hidden whitespace-nowrap overflow-ellipsis"
				value={description}
				onChange={(e) => setDescription(e.target.value)}
				onBlur={() => updateDescriptionSection(file.id, description!)}
				placeholder="Escribe un título"
			/>
			<div className="flex mb-4">
				<div className="flex items-center mr-2">
					<h2 className="OpenSansRegular font-normal">{file.date}</h2>
				</div>
				<Button onClick={deleteSection} color="primary">
					Eliminar Sección
				</Button>
			</div>
			<div className="flex flex-wrap -ml-4">{images()}</div>
		</div>
	);
};

export default DateSectionFile;
