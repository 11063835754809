import React, { FC } from 'react';
import RadioFilter from '../../radioFilter/RadioFilter';
import { SideBarContainer, SideBarSection } from './styles';
import useMobile from 'hooks/useMobile';

interface RadioInputs {
	id: string | number;
	value: string;
	label: string;
}

interface Props {
	radioList?: RadioInputs[];
	value: string;
	handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	disabled?: boolean;
}

const PrescriptionSidebarSection: FC<Props> = ({ radioList, value, handleChange, disabled }) => {
	const { isMobile } = useMobile();
	if (isMobile) return null;
	return (
		<SideBarSection item xs={4} md={2}>
			<SideBarContainer>
				<RadioFilter
					radioList={radioList}
					label="Filtro de prescripciones"
					name="prescriptions"
					value={value}
					handleChange={handleChange}
					disabled={disabled}
				/>
			</SideBarContainer>
		</SideBarSection>
	);
};

export default PrescriptionSidebarSection;
