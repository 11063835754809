// @ts-nocheck
import { Divider, Grid, ListItemText, Tooltip, Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import {
	EstaturaIcon,
	FrecuenciaIcon,
	FrecuenciaREIcon,
	MasaIcon,
	PesoIcon,
	SaturacionIcon,
	TemperatureIcon,
	TensionIcon,
} from 'components/svg';
import { ClientsContext } from 'context/client-context';
import {
	ConsultationType,
	ClientContextProps,
	ClientType,
	LoaderContextProps,
} from 'context/types';
import { FormatDate, getDateToString } from 'shared/dateUtils';
import CustomCard from 'components/card/CustomCard';
import {
	StyledInfoOutlinedIcon,
	StyledListItem,
	TitleContainer,
	VitalSignTitle,
} from './PatientSummary.style';
import { LoaderContext } from 'context/loader-context';

interface Props {
	consultation: ConsultationType | null;
}

const SummaryVitalSign: React.FC<Props> = ({ consultation }) => {
	const { selectedClient } = useContext(ClientsContext) as ClientContextProps;

	return (
		<CustomCard
			title={
				<TitleContainer>
					<VitalSignTitle variant="h5">Últimos Signos Vitales</VitalSignTitle>
					<Tooltip
						title={
							<Typography variant="body2">
								Los signos vitales son actualizados en base a la última consulta del
								paciente.
							</Typography>
						}
						placement="top"
						arrow
						interactive
						enterTouchDelay={50}
					>
						<StyledInfoOutlinedIcon />
					</Tooltip>
				</TitleContainer>
			}
			subheader={
				consultation && consultation?.updatedAt ? (
					<p>
						{`Última edición: ${getDateToString(
							new Date(consultation?.updatedAt.replace(/-/g, '/').replace(/T.+/, '')),
							FormatDate['DD/MM/YYYY']
						)}`}
					</p>
				) : (
					<p>Última edición: Sin datos</p>
				)
			}
			divider={true}
		>
			<Grid item xs={12}>
				<StyledListItem>
					<EstaturaIcon />
					<ListItemText
						primary="ESTATURA"
						secondary={
							selectedClient && selectedClient.vitalSignsHeight
								? `${selectedClient.vitalSignsHeight} M`
								: 'n/d'
						}
					/>
				</StyledListItem>
			</Grid>
			<Divider />
			<Grid item xs={12}>
				<StyledListItem>
					<PesoIcon />
					<ListItemText
						primary="PESO"
						secondary={
							selectedClient && selectedClient.vitalSignsWeight
								? `${selectedClient.vitalSignsWeight} KG`
								: 'n/d'
						}
					/>
				</StyledListItem>
			</Grid>
			<Divider />
			<Grid item xs={12}>
				<StyledListItem>
					<MasaIcon />
					<ListItemText
						primary="MASA CORPORAL"
						secondary={
							selectedClient && selectedClient.bodyMass
								? `${selectedClient.bodyMass} kg/m²`
								: 'n/d'
						}
					/>
				</StyledListItem>
			</Grid>
			<Divider />
			<Grid item xs={12}>
				<StyledListItem>
					<TensionIcon />
					<ListItemText
						primary="TENSIÓN ARTERIAL"
						secondary={
							selectedClient && selectedClient.vitalSignsBloodPressure
								? `${selectedClient.vitalSignsBloodPressure} mmHg`
								: 'n/d'
						}
					/>
				</StyledListItem>
			</Grid>
			<Divider />
			<Grid item xs={12}>
				<StyledListItem>
					<FrecuenciaIcon />
					<ListItemText
						primary="FRECUENCIA CARDÍACA"
						secondary={
							selectedClient && selectedClient.heartRate
								? `${selectedClient.heartRate} bpm`
								: 'n/d'
						}
					/>
				</StyledListItem>
			</Grid>
			<Divider />
			<Grid item xs={12}>
				<StyledListItem>
					<FrecuenciaREIcon />
					<ListItemText
						primary="FRECUENCIA RESPIRATORIA"
						secondary={
							selectedClient && selectedClient.respiratoryRate
								? `${selectedClient.respiratoryRate} r/m`
								: 'n/d'
						}
					/>
				</StyledListItem>
			</Grid>
			<Divider />
			<Grid item xs={12}>
				<StyledListItem>
					<TemperatureIcon />
					<ListItemText
						primary="TEMPERATURA CORPORAL"
						secondary={
							selectedClient && selectedClient.bodyTemperature
								? `${selectedClient.bodyTemperature} °C`
								: 'n/d'
						}
					/>
				</StyledListItem>
			</Grid>
			<Divider />
			<Grid item xs={12}>
				<StyledListItem>
					<SaturacionIcon />
					<ListItemText
						primary="SATURACIÓN DE OXÍGENO"
						secondary={
							selectedClient && selectedClient.oxygenSaturation
								? `${selectedClient.oxygenSaturation} %`
								: 'n/d'
						}
					/>
				</StyledListItem>
			</Grid>
		</CustomCard>
	);
};

export default SummaryVitalSign;
