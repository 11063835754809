import { Box, Typography } from '@material-ui/core';

const CreateAccountBanner = () => {
	return (
		<Box
			style={{
				marginTop: '35px',
			}}
		>
			<Typography
				style={{
					fontSize: '15px',
					fontWeight: 500,
					color: '#000000',
					textTransform: 'uppercase',
					letterSpacing: '0.46px',
					lineHeight: '26px',
					fontStyle: 'normal',
				}}
			>
				¿No tiene una cuenta?{' '}
				<a
					href="https://www.pulseara.com/register"
					target="_blank"
					rel="noreferrer"
					style={{
						fontSize: '15px',
						fontWeight: 500,
						color: '#1976D2',
						textTransform: 'uppercase',
						letterSpacing: '0.46px',
						lineHeight: '26px',
						fontStyle: 'normal',
						textDecorationLine: 'underline',
					}}
				>
					{' '}
					Cree una
				</a>
			</Typography>
		</Box>
	);
};

export default CreateAccountBanner;
