import { ClientType } from 'context/types';
import moment from 'moment';
import { CSSProperties, FC } from 'react';
import { displayAge } from 'shared/dateUtils';

const flexRow: CSSProperties = {
	display: 'flex',
};

const flexCol: CSSProperties = {
	...flexRow,
	flexDirection: 'column',
};

const footerContainer: CSSProperties = {
	borderTop: '2px solid #F3EFEF',
	...flexCol,
	paddingLeft: 16,
};

const arialFont: CSSProperties = {
	fontFamily: 'Arial, Helvetica, sans-serif',
	fontWeight: 700,
	wordSpacing: '0.075em',
	fontStyle: 'normal',
};

const bodyStyle: CSSProperties = {
	...arialFont,
	fontWeight: 400,
	fontSize: '12px',
};

const InfoHeaderStyle: CSSProperties = {
	...arialFont,
	fontWeight: 700,
	fontSize: '12px',
};

const FooterPrescription: FC<{
	client: ClientType;
	doctorName?: string | null;
}> = ({ client, doctorName }) => {
	return (
		<div className="flex flex-col pl-4" style={footerContainer}>
			<div className="flex flex-col mt-10" style={{ ...flexCol, marginTop: '25px' }}>
				<span style={InfoHeaderStyle}>Paciente</span>
				<span
					className="mt-2"
					style={{ ...bodyStyle, marginTop: 2 }}
				>{`${client?.name} ${client?.lastName}`}</span>
			</div>
			<div className="mt-6 flex" style={{ ...flexRow, marginTop: 24 }}>
				<div className="flex flex-col mr-8" style={{ ...flexCol, marginRight: 32 }}>
					<span style={InfoHeaderStyle}>Edad</span>
					<span style={bodyStyle}>{displayAge(moment(client.bornDate))}</span>
				</div>
				<div className="flex flex-col mr-8" style={{ ...flexCol, marginRight: 32 }}>
					<span style={InfoHeaderStyle}>Fecha</span>
					<span style={bodyStyle}>{moment(new Date()).format('DD/MM/YYYY')}</span>
				</div>
				<div className="flex flex-col mr-8" style={{ ...flexCol, marginRight: 32 }}>
					<span style={InfoHeaderStyle}>Seguro</span>
					<span style={bodyStyle}>
						{client?.healthInsuranceName === 'Sin seguro'
							? 'No especificado'
							: client?.healthInsuranceName}
					</span>
				</div>
				<div className="flex flex-col">
					<span
						style={{
							...InfoHeaderStyle,
							position: 'absolute',
							bottom: '14.33px',
							right: '18px',
						}}
					>
						{doctorName}
					</span>
				</div>
			</div>
		</div>
	);
};

export default FooterPrescription;
