import React, { useState } from 'react';
import { Button, Dialog, TextField } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import CircularProgress from '@mui/material/CircularProgress';

const CustomDialog = styled(Dialog)({
	'& .MuiDialog-paper': {
		width: '500px',
		height: '330px',
		padding: '41px',
		borderRadius: '4px',
	},
});

const CustomHeader = styled('h1')({
	fontSize: '24px',
	fontWeight: 400,
	lineHeight: '32px',
	fontFeatureSettings: 'clig off, liga off',
	color: 'rgb(0, 0, 0)',
});

const CustomSubHeader = styled('h2')({
	fontSize: '14px',
	fontStyle: 'normal',
	fontWeight: 400,
	lineHeight: '20px',
	letterSpacing: '0.17px',
	fontFeatureSettings: 'clig off, liga off',
	color: '#00000099',
});

const InputSearch = styled(TextField)({
	width: '100%',
	'& .MuiOutlinedInput-root': {
		height: '36px',
	},
});

const CustomAddButton = styled(Button)({
	width: '100%',
	height: '36px',
	backgroundColor: '#1976D2',
	color: 'white',
	textTransform: 'none',
	'&:hover': {
		backgroundColor: '#1976D2',
	},
});

const CustomCancelButton = styled(Button)({
	width: '100%',
	height: '36px',
	color: '#1976D2',
});

type DoctorMemberDialogProps = {
	open: boolean;
	handleClose: () => void;
	doctorId: string;
	handlerAddDoctor: () => Promise<void>;
	setEmail: (email: string) => void;
};

const DoctorMemberDialog = (props: DoctorMemberDialogProps) => {
	const { open, handleClose, doctorId, handlerAddDoctor, setEmail } = props;
	const [loading, setLoading] = useState(false);
	const onAddDoctor = async () => {
		setLoading(true);
		await handlerAddDoctor();
		handleClose();
		setLoading(false);
	};
	return (
		<CustomDialog open={open} onClose={handleClose} maxWidth={'xs'}>
			<div className="flex flex-col">
				<CustomHeader>Agregar un doctor</CustomHeader>
				<CustomSubHeader>
					Pagarás ahora 800 pesos o el monto prorrateado por este.
				</CustomSubHeader>
			</div>
			<div className="mt-[32px]">
				<InputSearch
					placeholder="Correo*"
					variant="outlined"
					onChange={(e) => setEmail(e.target.value)}
				/>
			</div>
			<div className="mt-[16px] flex flex-col gap-4">
				<CustomAddButton variant="contained" onClick={onAddDoctor} disabled={loading}>
					{loading ? 'Agregando...' : 'Agregar y Pagar ahora'}
				</CustomAddButton>
				<CustomCancelButton variant="text" onClick={handleClose} disabled={loading}>
					Cancelar
				</CustomCancelButton>
			</div>
		</CustomDialog>
	);
};

export default DoctorMemberDialog;
