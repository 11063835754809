export const CalendarIconAdd = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
		<g clip-path="url(#clip0_2225_17507)">
			<path
				d="M12.5 2.25V0.75H14V2.25H14.75C15.575 2.25 16.25 2.925 16.25 3.75V9.75H14.75V6H4.25V14.25H11V15.75H4.25C3.4175 15.75 2.75 15.075 2.75 14.25L2.7575 3.75C2.7575 2.925 3.4175 2.25 4.25 2.25H5V0.75H6.5V2.25H12.5Z"
				fill="currentColor"
			/>
			<path
				d="M16.25 17.25H14.75V15H12.5V13.5H14.75V11.25H16.25V13.5H18.5V15H16.25V17.25Z"
				fill="currentColor"
			/>
		</g>
		<defs>
			<clipPath id="clip0_2225_17507">
				<rect width="18" height="18" fill="white" transform="translate(0.5)" />
			</clipPath>
		</defs>
	</svg>
);
