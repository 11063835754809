import React from 'react';

const TemperatureIcon = () => (
	<svg width="20" height="20" viewBox="0 0 10 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M8 11V3C8 1.34 6.66 0 5 0C3.34 0 2 1.34 2 3V11C0.79 11.91 0 13.37 0 15C0 17.76 2.24 20 5 20C7.76 20 10 17.76 10 15C10 13.37 9.21 11.91 8 11ZM4 3C4 2.45 4.45 2 5 2C5.55 2 6 2.45 6 3H5V4H6V6H5V7H6V9H4V3Z"
			fill="black"
			fill-opacity="0.54"
		/>
	</svg>
);

export default TemperatureIcon;
