import { MenuItem } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment, { Moment } from 'moment';
import React, { FC } from 'react';
import { AllergyStatus } from 'models';
import { statusOptions } from '../../../../shared/constants';
import MomentUtils from '@date-io/moment';
import {
	CustomKeyboardDatePicker,
	CustomTextField,
	StyledFormControl,
	StyledInputLabel,
	StyledSelect,
	StyledTextField,
} from '../../../../shared/style';
import CustomDialog from '../../../dialog/CustomDialog';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import HistoryTitle from './FamilyHistoryFormTitle';
import useMobile from 'hooks/useMobile';

interface Props {
	isOpen: boolean;
	handleClose: () => void;
	isEdit: boolean;
	diseaseName: string;
	diseaseStatus: AllergyStatus;
	handleChangeDiseaseName: (e: React.ChangeEvent<HTMLInputElement>) => void;
	handleChangeDiseaseStatus: (
		e: React.ChangeEvent<{
			name?: string | undefined;
			value: unknown;
		}>
	) => void;
	diseaseDate: string | null | undefined | Moment;
	handleDiseaseDate: (date: MaterialUiPickersDate) => void;
	diseaseNote: string;
	handleDiseaseNote: (e: React.ChangeEvent<HTMLInputElement>) => void;
	handleSubmitDisease: () => void;
	handleDeleteDisease: () => void;
	isEmptyDiseaseName: boolean;
	fullWidth: boolean;
	isMobile: boolean;
}

const DiseaseFormDialog: FC<Props> = (props) => {
	const { isMobile } = useMobile();
	const getDiseaseStatusLabel = (option: AllergyStatus) => {
		let label = 'Activa';
		if (option === AllergyStatus.INACTIVE) {
			label = 'Inactiva';
		}
		if (option === AllergyStatus.CURED) {
			label = 'Curada';
		}

		return label;
	};

	const title = props.isEdit ? 'Editar enfermedad' : 'Agregar enfermedad';
	const deleteText = props.isEdit ? (isMobile ? 'Eliminar' : 'Eliminar enfermedad') : '';

	return (
		<CustomDialog
			open={props.isOpen}
			disableBackdropClick
			fullWidth={props.fullWidth}
			className={props.isMobile ? 'appointment-modal' : ''}
			fullScreen={props.isMobile}
			maxWidth="xs"
			handleClose={props.handleClose}
			title={
				props.isMobile ? <HistoryTitle title={title} goBack={props.handleClose} /> : title
			}
			disagreeText="Cancelar"
			agreeText="Guardar"
			deleteText={deleteText}
			handleAgree={props.handleSubmitDisease}
			handleDelete={props.handleDeleteDisease}
		>
			<form className="flex flex-col gap-4 mt-4">
				<CustomTextField
					label="Nombre de la enfermedad*"
					placeholder="Nombre de la enfermedad*"
					fullWidth
					size="small"
					error={props.isEmptyDiseaseName}
					variant="outlined"
					value={props.diseaseName}
					onChange={props.handleChangeDiseaseName}
					helperText={
						props.isEmptyDiseaseName ? 'Debes escribir el nombre de la enfermedad' : ''
					}
				/>
				<StyledFormControl variant="outlined" fullWidth>
					<StyledInputLabel htmlFor="outlined-status-native-simple">
						Estado
					</StyledInputLabel>
					<StyledSelect
						label="Estado"
						value={props.diseaseStatus}
						inputProps={{
							name: 'status',
							id: 'outlined-status-native-simple',
						}}
						onChange={props.handleChangeDiseaseStatus}
					>
						{statusOptions.map((s) => {
							return (
								<MenuItem key={s.id} value={s.label}>
									{getDiseaseStatusLabel(s.label)}
								</MenuItem>
							);
						})}
					</StyledSelect>
				</StyledFormControl>
				<MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={'es'}>
					<CustomKeyboardDatePicker
						disableFuture
						invalidDateMessage="Formato fecha invalida"
						openTo="year"
						views={['year', 'month', 'date']}
						fullWidth
						variant="inline"
						format="DD/MM/YYYY"
						inputVariant="outlined"
						id="disease-date-picker-inline"
						label="Fecha de diagnóstico"
						value={props.diseaseDate}
						onChange={props.handleDiseaseDate}
						KeyboardButtonProps={{
							'aria-label': 'change date',
						}}
					/>
				</MuiPickersUtilsProvider>
				<StyledTextField
					id="outlined-disease-note"
					label="Notas"
					placeholder="Notas"
					value={props.diseaseNote}
					onChange={props.handleDiseaseNote}
					multiline
					fullWidth
					minRows={5}
					variant="outlined"
				/>
			</form>
		</CustomDialog>
	);
};

export default DiseaseFormDialog;
