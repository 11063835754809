import moment from 'moment';

export const getFormatNextPaymentDate = (nextPaymentDate: number) => {
	return new Date(nextPaymentDate! * 1000).toLocaleDateString('es-ES', {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
	});
};

export const formatPrice = (price: string) => {
	const currencyPrice = parseInt(price)
		.toFixed(2)
		.replace(/\d(?=(\d{3})+\.)/g, '$&,');

	return currencyPrice;
};

const convertToValidFormatDate = (dateString: string) => {
	const datePattern = /^\d{2}\/\d{2}\/\d{4}$/;
	if (!datePattern.test(dateString)) {
		return dateString;
	}

	return moment(dateString, 'DD/MM/YYYY').toDate();
};

export const getTimeLeft = (nextPaymentDate: string): number => {
	const actualDate = moment();
	const nextPayment = moment(convertToValidFormatDate(nextPaymentDate));
	if (nextPayment.isBefore(actualDate)) {
		return 0;
	}
	const timeLeft = nextPayment.diff(actualDate, 'seconds');
	return timeLeft;
};
