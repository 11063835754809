import CircularProgress from '@material-ui/core/CircularProgress';
import Skeleton from '@material-ui/lab/Skeleton';
import useMobile from 'hooks/useMobile';

export const ImageSkeleton = () => {
	return (
		<div
			key="loading"
			style={{
				width: '141.8px',
				height: '125.8px',
				border: 'solid 1px rgb(148, 163, 184)',
				borderRadius: '10px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				marginLeft: '1rem',
				marginTop: '0.75rem',
			}}
		>
			<CircularProgress />
		</div>
	);
};

export const SectionSkeleton = () => {
	const { isMobile } = useMobile();
	const skeletonStyle = {
		width: '30%',
		height: 35,
	};

	if (isMobile) {
		skeletonStyle.width = '100%';
	}

	return (
		<div
			className="flex flex-col mt-8"
			style={{
				rowGap: '1rem',
			}}
		>
			<Skeleton variant="rect" style={skeletonStyle} />
			<Skeleton variant="rect" style={skeletonStyle} />
			<ImageSkeleton />
		</div>
	);
};
