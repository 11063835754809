import { DiagnosticTypes } from 'models';
import moment from 'moment';
import React, { FC, useContext, useEffect } from 'react';
import { ClientPrescriptionAnalisis, ClientPrescriptionMedicine } from 'API';
import CustomCard from '../../../components/card/CustomCard';
import { ConsultationDiagnosticType, RelationsContextProps } from '../../../context/types';
import { FreePrescription } from '../../../shared/type';
import { Paragraph, SimpleParagraph, LastParagraph, List } from './style';
import { RelationsContext } from 'context/relations-context';

type CustomClientPrescriptionMedicine = {
	selectedMeasure: string;
} & ClientPrescriptionMedicine;

interface Consultation {
	headerAction?: React.ReactNode;
	title: string;
	date: string;
	description: string;
	note_physic_test: string;
	medicines_prescripted: CustomClientPrescriptionMedicine[];
	labs_images: ClientPrescriptionAnalisis[];
	diagnostics: ConsultationDiagnosticType[];
	diffDiagnostics: ConsultationDiagnosticType[];
	treatment: string;
	procedures_cirgury: string;
	proceures_cirugy_description: string;
	freePrescription: FreePrescription[];
	doctorID: string;
}

const ConsultationCard: FC<Consultation> = (props) => {
	const [diagnosticList, setDiagnosticList] = React.useState<ConsultationDiagnosticType[]>([]);
	const { actualDoctor } = useContext(RelationsContext) as RelationsContextProps;
	const [diffDiagnosticList, setDiffDiagnosticList] = React.useState<
		ConsultationDiagnosticType[]
	>([]);
	const noDataStyle = { margin: 0 };
	const dataStyle = { marginBottom: '14px', marginTop: '14px' };
	const getStyle = (hasData: boolean) => (hasData ? dataStyle : noDataStyle);

	useEffect(() => {
		setDiagnosticList(props.diagnostics.filter((d) => d.type === DiagnosticTypes.PRESUMPTIVE));
		if (props.diffDiagnostics) {
			setDiffDiagnosticList(props.diffDiagnostics);
		} else {
			setDiffDiagnosticList(
				props.diagnostics.filter((d) => d.type === DiagnosticTypes.DIFFERENTIAL)
			);
		}
	}, [props.diagnostics, props.diffDiagnostics]);
	const showHeaderAction = () => {
		if (!props.doctorID) {
			return !!props.headerAction;
		}
		return actualDoctor?.id === props.doctorID;
	};
	return (
		<CustomCard
			title={props.title}
			divider={false}
			headerAction={showHeaderAction() && props.headerAction}
			subheader={<p>{moment(props.date).format('DD/MM/YYYY')}</p>}
			dataFxName="consultationCardModal"
		>
			<Paragraph>{props.description ? props.description : 'Sin Descripción'}</Paragraph>
			<SimpleParagraph style={getStyle(!!props.note_physic_test)}>
				<strong>Notas de examen físico: </strong>
				{props.note_physic_test ? (
					<>
						<br /> {props.note_physic_test}
					</>
				) : (
					'Sin Datos'
				)}
			</SimpleParagraph>
			<SimpleParagraph
				style={getStyle(
					props.medicines_prescripted && props.medicines_prescripted.length > 0
				)}
			>
				<strong>Medicamentos recetados: </strong>
				{(!props.medicines_prescripted || props.medicines_prescripted.length === 0) &&
					'Sin Datos'}
			</SimpleParagraph>
			{props.medicines_prescripted && props.medicines_prescripted.length > 0 && (
				<List>
					{props.medicines_prescripted.map((med) => (
						<li key={med.id}>
							{med.name}
							{med.selectedMeasure && `, ${med.selectedMeasure}`}
							{med.take && `, ${med.take}`}
							{med.indication && `, ${med.indication}`}
							{med.duration && `, ${med.duration}`}
							{med.description && `. ${med.description}`}
						</li>
					))}
				</List>
			)}
			<SimpleParagraph style={getStyle(props.labs_images && props.labs_images.length > 0)}>
				<strong>Laboratorio e imagenes: </strong>
				{(!props.labs_images || props.labs_images.length === 0) && 'Sin Datos'}
			</SimpleParagraph>
			{props.labs_images && props.labs_images.length > 0 && (
				<List>
					{props.labs_images.map((lab) => (
						<li key={lab.id}>
							{lab.name}. {lab.description}
						</li>
					))}
				</List>
			)}
			{props.freePrescription && props.freePrescription.length > 0 && (
				<SimpleParagraph
					style={getStyle(props.freePrescription && props.freePrescription.length > 0)}
				>
					<strong>Prescripción libre: </strong>
					{props.freePrescription[0].description}
				</SimpleParagraph>
			)}

			<SimpleParagraph style={getStyle(!!diagnosticList)}>
				<strong>Diagnóstico presuntivo: </strong>
				{diagnosticList && diagnosticList.length === 0 && 'Sin Datos'}
			</SimpleParagraph>
			{diagnosticList && diagnosticList.length > 0 && (
				<List>
					{diagnosticList.map((diagnostic) => (
						<li key={diagnostic.id}>{diagnostic.name}</li>
					))}
				</List>
			)}
			<SimpleParagraph style={getStyle(!!diffDiagnosticList)}>
				<strong>Diagnóstico diferencial: </strong>
				{diffDiagnosticList && diffDiagnosticList.length === 0 && 'Sin Datos'}
			</SimpleParagraph>
			{diffDiagnosticList && diffDiagnosticList.length > 0 && (
				<List>
					{diffDiagnosticList
						.filter((diagnostic) => diagnostic.type === DiagnosticTypes.DIFFERENTIAL)
						.map((diagnostic) => (
							<li key={diagnostic.id}>{diagnostic.name}</li>
						))}
				</List>
			)}
			<LastParagraph style={getStyle(!!props.treatment)}>
				<strong>Tratamiento: </strong>
				{props.treatment ? (
					<>
						<br /> {props.treatment}
					</>
				) : (
					'Sin Datos'
				)}
			</LastParagraph>
			<LastParagraph style={getStyle(!!props.procedures_cirgury)}>
				<strong>Procedimiento y cirugías: </strong>
				{props.procedures_cirgury ? (
					<>
						<br /> {props.procedures_cirgury}: {props.proceures_cirugy_description}
					</>
				) : (
					'Sin Datos'
				)}
			</LastParagraph>
		</CustomCard>
	);
};

export default ConsultationCard;
