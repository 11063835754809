import React, { ChangeEventHandler } from 'react';

interface CheckBox {
	id: string;
	label?: string;
	onChangeHandler: ChangeEventHandler;
}

const ExportExcelCircleCheckBox: React.FC<CheckBox> = ({ id, label = '', onChangeHandler }) => {
	return (
		<div className="checkbox-round flex items-center">
			<input onChange={onChangeHandler} type="checkbox" name={id} id={id} />
			<label className="cursor-pointer ml-4 text-base font-normal OpenSansLight" htmlFor={id}>
				{label || ''}
			</label>
		</div>
	);
};

export default ExportExcelCircleCheckBox;
