// @ts-nocheck
import { withStyles } from '@material-ui/styles';
import React, { FC } from 'react';
import { sidebar } from '../../../shared/constants';
import { ListItemLink, SidebarQueryList, SideBarSection, StyledListItemText } from './styles';

const ListItemLinkStyled = withStyles({
	selected: {
		backgroundColor: 'rgba(25, 118, 210, 0.08) !important',
	},
})(ListItemLink);

interface Props {
	actualSidebarItem: string;
	setActualSidebarItem: (id: string) => void;
}

const SidebarSection: FC<Props> = ({ actualSidebarItem, setActualSidebarItem }) => {
	return (
		<SideBarSection item xs={2}>
			<SidebarQueryList>
				{sidebar.map((s) => (
					<ListItemLinkStyled
						button
						component="a"
						selected={s.id === actualSidebarItem}
						key={s.id}
						href={`#${s.id}`}
						onClick={() => setActualSidebarItem(s.id)}
					>
						<StyledListItemText primary={s.label} />
					</ListItemLinkStyled>
				))}
			</SidebarQueryList>
		</SideBarSection>
	);
};

export default SidebarSection;
