import { AIPatienDiagnostic } from '../types';

export const promtMedicalPlan = (data: AIPatienDiagnostic) => {
	return `
Eres un medico consultor experto,
Analiza los siguientes datos del paciente:

Datos del paciente:

${data.bornDate ? 'nacimiento: ' + data.bornDate : '\r'}
${data.gender ? 'genero: ' + data.gender : '\r'}
${data.clinicPresentation ? 'Razon de la visita: ' + data.clinicPresentation : '\r'}
${data.alergias ? 'Alergias: ' + data.alergias : '\r'}
${data.antecFamiliares ? 'Antecedentes familiares: ' + data.antecFamiliares : '\r'}
${data.antecPatologico ? 'Antecedentes no Patológicos: ' + data.antecPatologico : '\r'}
${data.enfermActiva ? 'Enfermedades activas: ' + data.enfermActiva : '\r'}
${data.enfermInactivas ? 'Enfermedades inactivas: ' + data.enfermInactivas : '\r'}
${data.hospitalPrevias ? 'Hospitalizaciones previas: ' + data.hospitalPrevias : '\r'}
${data.estiloDeVida ? 'Estilo de vida: ' + data.estiloDeVida : '\r'}

Tomando en cuenta el analisis de los datos anteriores, provee la siguiente información:

- 3 posibles diagnósticos médicos
- 3 sugerencias de estudios médicos usando el catalogo CPT
- 4 sugerencias de medicamentos
- 5 sugerencias de tratamientos
- Descripción de no más de 50 palabras de lo que podría padecer el paciente

Toda esta informacion retornala en formato JSON sin ningun texto adicional fuera de este. MUY IMPORTANTE: No puede haber ningun texto asignado al json, como por ejemplo; Respuesta: ect.. Solo quiero el JSON dentro de un objecto.

Toma el siguiente objeto como un ejemplo de lo que quiero que me devuelvas:

{
    "planMedico": [{
      "descripcion": "El paciente podría estar sufriendo de una migraña con síntomas como dolor de cabeza persistente en la región frontal y temporal derecha, náuseas y mareo. También puede haber una infección del tracto urinario debido a la disminución del apetito y el aumento de la frecuencia urinaria. Se necesita una evaluación médica para determinar el diagnóstico y el tratamiento adecuado."
    }],
    "diagnosticos": [
      { "nombre": "Migraña"}
    ],
    "estudios": [
      {"nombre": "Mamografía"}
    ],
    "medicamentos": [
      {"nombre": "Ibuprofeno"}
    ],
    "tratamientos": [
      {"nombre": "Radioterapia"}
    ]
  }

  Dame el JSON dentro de un objeto sin asignarlo a ninguna variable. Asegúrate de colocar las comas y comillas dobles en los lugares correctos para evitar errores de sintaxis en el JSON que retornaràs 
  `;
};
