import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import { IColumnType } from '../../shared/type';
import CTableHeader from './CTableHeader';
import CTableRow from './CTableRow';
import useMobile from 'hooks/useMobile';

const useStyles = makeStyles({
	table: {
		minWidth: 400,
	},
});

interface Props<T extends { isDisabled?: boolean }> {
	data: T[];
	columns: IColumnType<T>[];
}

function CTable<T extends { isDisabled?: boolean }>({ data, columns }: Props<T>): JSX.Element {
	const classes = useStyles();
	const isMobile = useMobile();
	const styles = isMobile.isMobile ? '' : classes.table;
	return (
		<TableContainer component={Paper}>
			<Table className={styles} aria-label="customized table">
				{isMobile.isMobile ? null : (
					<TableHead>
						<CTableHeader columns={columns} />
					</TableHead>
				)}
				<TableBody
					style={{
						minHeight: 300,
					}}
				>
					<CTableRow data={data} columns={columns} />
				</TableBody>
			</Table>
		</TableContainer>
	);
}

export default CTable;
