import { LabImage } from 'API';
import { MedicineType, SuggestionType } from 'context/types';

const fetchMedicinesSuggestions = (data: MedicineType[], search: string) => {
	const currentMedicines = [...data];
	const filteredMedicines = currentMedicines.filter((m) => {
		const { name } = m;

		if (!name) {
			return false;
		}

		if (search === '') {
			return true;
		}

		return name.toLowerCase().includes(search.toLowerCase());
	});

	return filteredMedicines;
};

const fetchLabImagesSuggestions = (data: LabImage[], search: string) => {
	const currentLabImages = [...data];
	const filteredLabImages = currentLabImages
		.filter((li) => {
			const { name } = li;

			if (!name) {
				return false;
			}

			if (search === '') {
				return true;
			}

			return name.toLowerCase().includes(search.toLowerCase());
		})
		.map((li) => ({
			...li,
			labImageID: li.id,
		}));

	return filteredLabImages;
};

export const filterSuggestions = (
	type: SuggestionType,
	data: MedicineType[] | LabImage[],
	search: string | null,
	callBack: Function
): NodeJS.Timeout | null => {
	let timerId: NodeJS.Timeout | null = null;
	if (search && search?.length > 2) {
		timerId = setTimeout(() => {
			if (type === SuggestionType.MEDICINES) {
				const medicines = fetchMedicinesSuggestions(data as MedicineType[], search);
				callBack(medicines);
			} else {
				const labImages = fetchLabImagesSuggestions(data as LabImage[], search);
				callBack(labImages);
			}
		}, 500);
	}
	return timerId;
};

export const clearSuggestions = (timerId: NodeJS.Timeout | null, callBack: Function) => {
	if (timerId) {
		clearTimeout(timerId);
	}
	callBack([]);
};
