const EstaturaIcon = () => (
	<svg width="20" height="20" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M12 20L12 2C12 0.9 11.1 -3.93402e-08 10 -8.74228e-08L2 -4.37114e-07C0.9 -4.85196e-07 -3.93402e-08 0.899999 -8.74228e-08 2L-8.74228e-07 20C-9.2231e-07 21.1 0.899999 22 2 22L10 22C11.1 22 12 21.1 12 20ZM2 20L2 2L10 2L10 4L6 4L6 6L10 6L10 8L6 8L6 10L10 10L10 12L6 12L6 14L10 14L10 16L6 16L6 18L10 18L10 20L2 20Z"
			fill="black"
			fill-opacity="0.54"
		/>
	</svg>
);

export default EstaturaIcon;
