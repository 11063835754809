import { Grid, MenuItem, TextField } from '@material-ui/core';
import moment from 'moment';
import React, { FC } from 'react';
import { LifeStyle } from 'models';
import { acivityFrequency, alcoholFrequency, smokeType, drugs } from '../../../../shared/constants';
import {
	HeaderHistoryContainer,
	HistoryListTitle,
	LifeStyleContainer,
	TitleSection,
	EditionDate,
	SelectLifeStyleContainer,
	InputLifeStyleContainer,
} from '../../../../shared/style';
import CheckboxMultiSelect from '../../../multiSelect/CheckboxMultiSelect';
import { AlcoholIcon, TabacoIcon, ActivityIcon, DrugIcon } from '../../../svg';
import LifeStyleCard from './LifeStyleCard';
import useMobile from 'hooks/useMobile';

interface Props {
	getLifeStyleTitle: (lifeStyle: LifeStyle) => { title: string };
	cancelAlcohol: () => void;
	handleSave: (
		id: string,
		name: string | string[],
		description: string,
		lifeStyle: LifeStyle
	) => void;
	alcoholId: string;
	alcoholUpdatedAt: string;
	isAlcoholVisible: boolean;
	lifeStyleAlcohol: string;
	handleLifeStyleAlcohol: (e: React.ChangeEvent<HTMLInputElement>) => void;
	lifeStyleAlcoholNote: string;
	handleLifeStyleAlcoholNote: (e: React.ChangeEvent<HTMLInputElement>) => void;
	cancelTabaco: () => void;
	tabacoId: string;
	tabacoUpdatedAt: string;
	isTabacoVisible: boolean;
	lifeStyleTabaco: string;
	handleLifeStyleTabaco: (e: React.ChangeEvent<HTMLInputElement>) => void;
	lifeStyleTabacoNote: string;
	handleLifeStyleTabacoNote: (e: React.ChangeEvent<HTMLInputElement>) => void;
	cancelActivity: () => void;
	activityId: string;
	activityUpdatedAt: string;
	isActivityVisible: boolean;
	lifeStyleActivity: string;
	handleLifeStyleActivity: (e: React.ChangeEvent<HTMLInputElement>) => void;
	lifeStyleActivityNote: string;
	handleLifeStyleActivityNote: (e: React.ChangeEvent<HTMLInputElement>) => void;
	cancelDrug: () => void;
	drugId: string;
	drugUpdatedAt: string;
	isDrugVisible: boolean;
	lifeStyleDrug: string[];
	handleLifeStyleDrug: (
		event: React.ChangeEvent<{
			name?: string | undefined;
			value: string[];
		}>
	) => void;
	lifeStyleDrugNote: string;
	handleLifeStyleDrugNote: (e: React.ChangeEvent<HTMLInputElement>) => void;
	isAnyModalOpen: boolean;
}

const LifeStyleList: FC<Props> = ({
	getLifeStyleTitle,
	cancelAlcohol,
	handleSave,
	alcoholId,
	alcoholUpdatedAt,
	isAlcoholVisible,
	lifeStyleAlcohol,
	handleLifeStyleAlcohol,
	lifeStyleAlcoholNote,
	handleLifeStyleAlcoholNote,
	cancelTabaco,
	tabacoId,
	tabacoUpdatedAt,
	isTabacoVisible,
	lifeStyleTabaco,
	handleLifeStyleTabaco,
	lifeStyleTabacoNote,
	handleLifeStyleTabacoNote,
	cancelActivity,
	activityId,
	activityUpdatedAt,
	isActivityVisible,
	lifeStyleActivity,
	handleLifeStyleActivity,
	lifeStyleActivityNote,
	handleLifeStyleActivityNote,
	cancelDrug,
	drugId,
	drugUpdatedAt,
	isDrugVisible,
	lifeStyleDrug,
	handleLifeStyleDrug,
	lifeStyleDrugNote,
	handleLifeStyleDrugNote,
	isAnyModalOpen = false,
}) => {
	const { title: alcoholTitle } = getLifeStyleTitle(LifeStyle.ALCOHOL);
	const { title: tabacoTitle } = getLifeStyleTitle(LifeStyle.TABACO);
	const { title: activityTitle } = getLifeStyleTitle(LifeStyle.ACTIVITY);
	const { title: drugTitle } = getLifeStyleTitle(LifeStyle.DRUG);
	const { isMobile } = useMobile();
	const mobileStyle = isMobile ? { width: '100%' } : {};

	return (
		<div
			style={{
				paddingBottom: isMobile && !isAnyModalOpen ? '70px' : '',
			}}
		>
			{!isMobile && (
				<HeaderHistoryContainer item>
					<HistoryListTitle variant="h6">Estilo de vida</HistoryListTitle>
				</HeaderHistoryContainer>
			)}
			<LifeStyleContainer item>
				<LifeStyleCard
					avatar={<AlcoholIcon />}
					title={
						<TitleSection>
							<p>{alcoholTitle}</p>
							{alcoholUpdatedAt && (
								<EditionDate>
									{`Ultima edición ${moment(alcoholUpdatedAt).format(
										'DD/MM/YYYY'
									)}`}
								</EditionDate>
							)}
						</TitleSection>
					}
					isActionVisible={isAlcoholVisible}
					disabled={false}
					handleCancel={cancelAlcohol}
					handleSave={() =>
						handleSave(
							alcoholId,
							lifeStyleAlcohol,
							lifeStyleAlcoholNote,
							LifeStyle.ALCOHOL
						)
					}
				>
					<Grid container spacing={3} style={mobileStyle}>
						<SelectLifeStyleContainer item style={mobileStyle}>
							<TextField
								id="outlined-alcohol-frequency"
								select
								fullWidth
								size="small"
								label="Selecciona frecuencia"
								value={lifeStyleAlcohol}
								style={mobileStyle}
								onChange={handleLifeStyleAlcohol}
								variant="outlined"
							>
								{alcoholFrequency.map((option) => (
									<MenuItem key={option.id} value={option.value}>
										{option.value}
									</MenuItem>
								))}
							</TextField>
						</SelectLifeStyleContainer>
						{(isAlcoholVisible ||
							lifeStyleAlcoholNote !== '' ||
							lifeStyleAlcohol !== '') && (
							<InputLifeStyleContainer item style={mobileStyle}>
								<TextField
									id="outlined-alchol-note"
									multiline
									fullWidth
									value={lifeStyleAlcoholNote}
									onChange={handleLifeStyleAlcoholNote}
									label="Agrega una nota"
									size="small"
									style={mobileStyle}
									placeholder="Agrega una nota"
									variant="outlined"
								/>
							</InputLifeStyleContainer>
						)}
					</Grid>
				</LifeStyleCard>
			</LifeStyleContainer>
			<LifeStyleContainer item>
				<LifeStyleCard
					avatar={<TabacoIcon />}
					title={
						<TitleSection>
							<p>{tabacoTitle}</p>
							{tabacoUpdatedAt && (
								<EditionDate>
									{`Ultima edición ${moment(tabacoUpdatedAt).format(
										'DD/MM/YYYY'
									)}`}
								</EditionDate>
							)}
						</TitleSection>
					}
					isActionVisible={isTabacoVisible}
					disabled={false}
					handleCancel={cancelTabaco}
					handleSave={() =>
						handleSave(tabacoId, lifeStyleTabaco, lifeStyleTabacoNote, LifeStyle.TABACO)
					}
				>
					<Grid container spacing={3}>
						<SelectLifeStyleContainer item style={mobileStyle}>
							<TextField
								id="outlined-smoke-type"
								select
								fullWidth
								size="small"
								style={mobileStyle}
								label="Selecciona tipo de fumador"
								value={lifeStyleTabaco}
								onChange={handleLifeStyleTabaco}
								variant="outlined"
							>
								{smokeType.map((option) => (
									<MenuItem key={option.id} value={option.value}>
										{option.value}
									</MenuItem>
								))}
							</TextField>
						</SelectLifeStyleContainer>
						{(isTabacoVisible ||
							lifeStyleTabacoNote !== '' ||
							lifeStyleTabaco !== '') && (
							<InputLifeStyleContainer item style={mobileStyle}>
								<TextField
									id="outlined-smoke-note"
									multiline
									fullWidth
									style={mobileStyle}
									value={lifeStyleTabacoNote}
									onChange={handleLifeStyleTabacoNote}
									label="Agrega una nota"
									size="small"
									placeholder="Agrega una nota"
									variant="outlined"
								/>
							</InputLifeStyleContainer>
						)}
					</Grid>
				</LifeStyleCard>
			</LifeStyleContainer>
			<LifeStyleContainer item>
				<LifeStyleCard
					avatar={<ActivityIcon />}
					title={
						<TitleSection>
							<p>{activityTitle}</p>
							{activityUpdatedAt && (
								<EditionDate>{`Ultima edición ${moment(activityUpdatedAt).format(
									'DD/MM/YYYY'
								)}`}</EditionDate>
							)}
						</TitleSection>
					}
					isActionVisible={isActivityVisible}
					disabled={false}
					handleCancel={cancelActivity}
					handleSave={() =>
						handleSave(
							activityId,
							lifeStyleActivity,
							lifeStyleActivityNote,
							LifeStyle.ACTIVITY
						)
					}
				>
					<Grid container spacing={3}>
						<SelectLifeStyleContainer item style={mobileStyle}>
							<TextField
								id="outlined-activity"
								select
								fullWidth
								size="small"
								style={mobileStyle}
								label="Selecciona frequencia"
								value={lifeStyleActivity}
								onChange={handleLifeStyleActivity}
								variant="outlined"
							>
								{acivityFrequency.map((option) => (
									<MenuItem key={option.id} value={option.value}>
										{option.value}
									</MenuItem>
								))}
							</TextField>
						</SelectLifeStyleContainer>
						{(isActivityVisible ||
							lifeStyleActivityNote !== '' ||
							lifeStyleActivity !== '') && (
							<InputLifeStyleContainer item style={mobileStyle}>
								<TextField
									id="outlined-smoke-note"
									multiline
									fullWidth
									style={mobileStyle}
									onChange={handleLifeStyleActivityNote}
									value={lifeStyleActivityNote}
									label="Agrega una nota"
									size="small"
									placeholder="Agrega una nota"
									variant="outlined"
								/>
							</InputLifeStyleContainer>
						)}
					</Grid>
				</LifeStyleCard>
			</LifeStyleContainer>
			<LifeStyleContainer item>
				<LifeStyleCard
					avatar={<DrugIcon />}
					title={
						<TitleSection>
							<p>{drugTitle}</p>
							{drugUpdatedAt && (
								<EditionDate>{`Ultima edición ${moment(drugUpdatedAt).format(
									'DD/MM/YYYY'
								)}`}</EditionDate>
							)}
						</TitleSection>
					}
					isActionVisible={isDrugVisible}
					disabled={false}
					handleCancel={cancelDrug}
					handleSave={() =>
						handleSave(drugId, lifeStyleDrug, lifeStyleDrugNote, LifeStyle.DRUG)
					}
				>
					<Grid container spacing={3}>
						<SelectLifeStyleContainer item style={mobileStyle}>
							<CheckboxMultiSelect
								id="outlined-drugs"
								label="Selecciona otras drogas"
								selectedValues={lifeStyleDrug}
								handleChange={handleLifeStyleDrug}
								options={drugs.map((x) => x.value)}
							/>
						</SelectLifeStyleContainer>
						{(isDrugVisible ||
							lifeStyleDrugNote !== '' ||
							lifeStyleDrug.length !== 0) && (
							<InputLifeStyleContainer item style={mobileStyle}>
								<TextField
									id="outlined-drugs-note"
									multiline
									fullWidth
									style={mobileStyle}
									onChange={handleLifeStyleDrugNote}
									value={lifeStyleDrugNote}
									label="Agrega una nota"
									size="small"
									placeholder="Agrega una nota"
									variant="outlined"
								/>
							</InputLifeStyleContainer>
						)}
					</Grid>
				</LifeStyleCard>
			</LifeStyleContainer>
		</div>
	);
};

export default LifeStyleList;
