const MasaIcon = () => (
	<svg width="20" height="20" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M6 0H2C0.9 0 0 0.9 0 2V8H2V15H6V8H8V2C8 0.9 7.1 0 6 0Z"
			fill="black"
			fill-opacity="0.54"
		/>
	</svg>
);

export default MasaIcon;
