import React from 'react';

const BackgroundImg = () => {
	return (
		<svg
			width="305"
			height="300"
			viewBox="0 0 305 300"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_4355_92598)">
				<path
					d="M304.782 164.759C303.822 165.597 303.925 166.847 303.6 167.923C299.966 179.673 292.373 187.41 280.432 190.757C272.315 188.518 266.396 183.47 262.088 176.421C260.314 173.576 259.007 170.467 258.216 167.211C257.97 166.159 257.597 165.115 258.374 164.118C259.961 163.999 260.215 165.423 260.794 166.333C264.515 172.125 270.379 173.533 276.583 171.587C282.415 169.767 285.716 164.07 285.073 157.646C284.486 151.688 280.067 147.115 273.727 146.173C268.126 145.343 264.063 147.969 260.818 152.258C260.136 153.16 259.953 154.528 258.565 154.813C257.367 153.706 257.986 152.44 258.295 151.237C260.874 141.355 266.777 134.139 275.885 129.487C277.25 128.941 278.576 128.301 279.852 127.573C293.134 131.37 301.275 139.955 304.21 153.46C304.306 153.927 304.21 154.457 304.782 154.695V164.759Z"
					fill="#DADFE5"
				/>
				<path
					d="M37.6203 0.182373C37.6203 0.37226 37.6203 0.562151 37.6203 0.744126C36.3502 1.45816 34.9168 1.83332 33.4587 1.83332C32.0007 1.83332 30.5673 1.45816 29.2972 0.744126C29.2972 0.554239 29.2972 0.37226 29.2972 0.182373H37.6203Z"
					fill="#F1F5FB"
				/>
				<path
					d="M134.981 0.744126C134.981 0.562151 134.981 0.37226 134.981 0.182373H143.288C143.288 0.37226 143.288 0.554239 143.288 0.744126C142.019 1.45308 140.589 1.82538 139.135 1.82538C137.68 1.82538 136.25 1.45308 134.981 0.744126V0.744126Z"
					fill="#F1F5FB"
				/>
				<path
					d="M240.586 168.92C223.13 171.713 211.15 183.494 208.119 200.956C207.998 201.934 207.961 202.922 208.008 203.907C208.008 213.264 208.008 222.626 208.008 231.994C208.047 247.32 203.866 261.268 194.432 273.5C183.451 287.686 169.043 296.223 151.175 298.549C128.959 301.429 110.052 294.475 94.6515 278.58C84.5829 268.191 78.8386 255.54 77.339 241.006C76.4424 232.342 77.0534 223.695 76.8788 215.047C77.9341 214.089 79.2591 214.192 80.5206 214.176C84.0672 214.137 87.6217 214.105 91.1683 214.176C93.4137 214.224 94.4214 213.385 94.342 211.075C94.223 209.012 94.223 206.943 94.342 204.88C94.4214 203.796 94.1516 202.419 95.7543 202.04C95.7543 213.116 95.5639 224.13 95.8257 235.167C96.2542 253.048 104.91 266.023 120.239 274.932C126.773 278.682 134.179 280.655 141.717 280.653C153.485 280.845 164.881 276.54 173.565 268.618C182.245 260.92 187.109 251.149 188.878 239.772C188.98 238.892 189.015 238.006 188.982 237.121C188.982 227.057 188.942 217.001 188.982 206.945C189.085 190.615 194.813 176.516 206.699 165.257C215.173 157.016 226.164 151.832 237.928 150.525C238.797 150.377 239.658 150.181 240.506 149.939C241.3 150.667 241.062 151.577 240.903 152.424C240.022 157.051 240.022 161.801 240.903 166.428C241.125 167.275 241.316 168.184 240.586 168.92Z"
					fill="#F2F7FC"
				/>
				<path
					d="M95.7623 202.048C94.5643 205.371 95.2863 208.82 95.2863 212.191C95.2863 214.564 94.4929 215.197 92.2237 215.126C87.1061 214.976 81.9885 215.055 76.8709 215.047C76.8709 211.494 76.8709 207.926 76.8233 204.397C76.8233 199.017 74.8795 196.129 69.6191 194.65C60.0584 192.098 51.1232 187.62 43.3647 181.492C31.1111 171.787 22.6234 158.135 19.3557 142.874C17.1817 133.214 15.2855 123.474 13.4447 113.742C12.7862 110.253 14.8253 107.539 16.8644 104.952C20.0381 102.175 22.9658 101.787 25.6952 103.758C29.4877 105.292 31.4395 108.212 32.3202 112.113C33.3993 116.86 34.7005 121.607 35.8668 126.314C37.1125 127.794 37.4934 129.622 37.9456 131.433C39.0723 135.896 40.0799 140.39 41.5478 144.765C47.062 160.977 58.2572 171.428 74.7366 175.582C92.3506 180.021 107.814 175.234 120.525 162.322C125.46 157.298 129.388 151.467 130.84 144.354C132.633 139.789 133.482 134.97 134.759 130.263C135.092 129.052 135.275 127.77 136.132 126.758C137.965 121.931 139.036 116.883 140.162 111.875C141.067 107.872 143.09 105.142 146.93 103.734C149.31 101.693 152.611 102.151 155.753 105.016C160.133 109.763 158.8 115.135 157.602 120.586C155.436 130.437 154.388 140.556 151 150.106C142.884 172.955 126.976 187.664 103.609 194.325C99.5549 195.496 96.4844 197.538 95.7623 202.048Z"
					fill="#ECF3FA"
				/>
				<path
					d="M240.586 168.92C238.99 162.691 238.99 156.161 240.586 149.932C245.735 135.405 256.105 127.683 271.418 126.528C274.281 126.349 277.152 126.703 279.884 127.572C280.59 129.194 278.956 128.783 278.424 129.02C268.054 133.665 261.342 141.323 258.771 152.448C258.642 153.217 258.565 153.994 258.541 154.774C259.094 157.82 259.115 160.938 258.604 163.991C258.834 169.49 261.373 174.095 264.341 178.494C267.784 183.589 272.672 186.928 278.218 189.412C278.992 189.816 279.742 190.266 280.463 190.757C268.133 195.117 251.979 188.795 244.283 176.626C242.839 174.164 241.602 171.586 240.586 168.92V168.92Z"
					fill="#DCE2E9"
				/>
				<path
					d="M155.785 105.016C152.921 104.042 150.175 102.302 146.962 103.734C150.39 86.6546 153.828 69.5701 157.276 52.4803C158.863 44.4655 160.585 36.4745 162.188 28.4597C163.838 20.2234 159.696 15.0094 151.08 14.3764C150.437 11.4716 150.453 8.46026 151.127 5.56253C164.512 5.31726 173.756 15.8955 171.169 29.2983C166.242 54.6165 160.918 79.7844 155.785 105.016Z"
					fill="#DADFE5"
				/>
				<path
					d="M151.08 5.56251C151.866 8.45045 151.849 11.4971 151.032 14.3764C146.375 20.9275 139.559 19.4717 133.244 18.7042C129.046 18.1979 126.428 13.6485 126.674 9.11497C126.896 5.159 130.443 1.61445 134.981 0.744141H143.304C146.787 0.926115 148.827 3.41046 151.08 5.56251Z"
					fill="#F2F7FC"
				/>
				<path
					d="M25.6951 103.757C22.5215 102.444 19.7127 103.86 16.8643 104.952C12.1038 81.8335 7.34327 58.7148 2.58273 35.5962C1.67823 31.1734 0.202461 26.7902 0.995884 22.1933C2.65414 12.0186 11.0248 5.26971 21.5059 5.57827C22.1458 8.46951 22.1593 11.4636 21.5455 14.3605C12.4846 15.0726 8.61275 20.2549 10.4376 29.1717C15.5155 53.7356 20.5881 78.3048 25.6555 102.879C25.692 103.17 25.7053 103.464 25.6951 103.757Z"
					fill="#DADFE5"
				/>
				<path
					d="M21.5455 14.3606C20.6322 11.5061 20.6183 8.44094 21.5059 5.57833C23.5291 3.0386 25.8538 0.989411 29.3052 0.744141H37.6282C42.7696 1.74896 46.2924 5.94228 45.9195 10.6024C45.5148 15.6423 40.3179 19.8752 36.0255 19.179C34.6547 18.9812 33.2626 18.9812 31.8918 19.179C27.401 19.7407 24.0607 17.9605 21.5455 14.3606Z"
					fill="#F2F7FC"
				/>
				<path
					d="M41.27 145.105C38.5803 140.018 38.2233 134.242 36.5095 128.87C36.2397 128.039 36.089 127.169 35.8748 126.314C37.9615 127.699 39.2785 129.835 40.9527 131.623C53.0206 144.496 67.8735 151.498 85.5272 151.625C105.014 151.759 121.136 144.116 133.624 129.076C134.354 128.197 134.815 126.971 136.171 126.758C135.053 132.779 133.942 138.807 131.411 144.449C130.917 145.529 130.117 146.442 129.11 147.075C104.426 166.38 67.1435 166.183 42.6823 146.616C42.1145 146.213 41.6341 145.699 41.27 145.105V145.105Z"
					fill="#DADFE5"
				/>
				<path
					d="M258.573 163.991C257.782 160.972 257.76 157.804 258.509 154.774C260.255 151.134 262.611 148.072 266.443 146.419C268.916 145.345 271.646 145.004 274.308 145.437C276.969 145.869 279.45 147.056 281.453 148.857C283.456 150.657 284.896 152.994 285.603 155.59C286.309 158.185 286.252 160.928 285.437 163.491C284.622 166.054 283.084 168.33 281.007 170.045C278.93 171.76 276.401 172.842 273.724 173.163C271.047 173.483 268.333 173.028 265.908 171.852C263.483 170.676 261.449 168.829 260.048 166.531C259.509 165.7 259.057 164.838 258.573 163.991Z"
					fill="#FBFDFE"
				/>
			</g>
			<defs>
				<clipPath id="clip0_4355_92598">
					<rect
						width="304"
						height="299"
						fill="white"
						transform="translate(0.781616 0.182373)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default BackgroundImg;
