import React, { FC, useContext } from 'react';
import CustomCard from 'components/card/CustomCard';
import {
	AIContainerActionGenerateSuggestionsBtn,
	AIContainerActionHowItWorksBtn,
	AISuggestionCardText,
	FormSection,
} from './styles';
import AISuggestionIcon from 'components/svg/AISuggestionIcon';
import { ClientsContext } from 'context/client-context';
import { AiContext } from 'context/ai-context';
import { AIContextProps, ClientContextProps } from 'context/types';
import { PatientData } from 'shared/ai/types';
import { VitalSigns } from 'pages/PatientConsultation/types';
import { FieldValues, UseFormWatch } from 'react-hook-form';
import { AllergyStatus } from 'models';
import { formatFamilyHistory } from './utils';
import ModalContainer from 'components/modalContainer/ModalContainer';
import { CustomTooltip } from 'components/svg/ExclamationMarkIcon';
import useMobile from 'hooks/useMobile';

interface props {
	reason: string;
	reasonDescription: string;
	physicTestNote: string;
	vitalsSigns: VitalSigns;
	watchVitalSigns: UseFormWatch<FieldValues>;
	goToDiagnosis: () => void;
}

const AISuggestionCard: FC<props> = ({
	reason,
	reasonDescription,
	physicTestNote,
	vitalsSigns,
	watchVitalSigns,
	goToDiagnosis,
}) => {
	const { selectedClient: client } = useContext(ClientsContext) as ClientContextProps;
	const { setIAPrompt } = useContext(AiContext) as AIContextProps;
	const [showModal, setShowModal] = React.useState(false);
	const { isMobile } = useMobile();
	const promptInput: PatientData = {
		alergias: client?.allergies?.map((el) => el.name).join(', ') ?? '',
		bloodPressure: watchVitalSigns('bloodPressure') ?? vitalsSigns?.bloodPressure,
		heartRate: watchVitalSigns('heartRate') ?? vitalsSigns?.heartRate,
		height: watchVitalSigns('height') ?? vitalsSigns?.height,
		weight: watchVitalSigns('weight') ?? vitalsSigns?.weight,
		bodyMass: watchVitalSigns('bodyMass') ?? vitalsSigns?.bodyMass,
		bodyTemperature: watchVitalSigns('bodyTemperature') ?? vitalsSigns?.bodyTemperature,
		respiratoryRate: watchVitalSigns('respiratoryRate') ?? vitalsSigns?.respiratoryRate,
		oxygenSaturation: watchVitalSigns('oxygenSaturation') ?? vitalsSigns?.oxygenSaturation,
		bornDate: client?.bornDate ?? '',
		visitReason: reason ?? '',
		visitReasonDescription: reasonDescription ?? '',
		gender: client?.gender ?? '',
		estiloDeVida: client?.lifeStyles?.map((el) => el.name).join(', ') ?? '',
		hospitalPrevias: client?.hospitalizations?.join(', ') ?? '',
		antecFamiliares: formatFamilyHistory(client?.familyHistories),
		enfermActiva:
			client?.diseases
				?.filter((d) => d.status === AllergyStatus.ACTIVE)
				.map((d) => d.name)
				.join(', ') ?? '',
		antecPatologico: '',
		enfermInactivas:
			client?.diseases
				?.filter((d) => d.status === AllergyStatus.INACTIVE)
				.map((d) => d.name)
				.join(', ') ?? '',
		physicTestNote: physicTestNote ?? '',
	};
	const isEnable = reason.length > 0 || reasonDescription.length > 0 || physicTestNote.length > 0;

	const TooltipText = () => {
		let text = '';

		if (isEnable) {
			return text;
		}

		if (reason.length === 0) {
			text = 'Debe ingresar un motivo de consulta';
		}
		if (physicTestNote.length === 0) {
			if (text.length > 0) {
				text += ' o ';
			}
			text += 'Debe ingresar una nota de examen físico';
		}
		if (reasonDescription.length === 0) {
			if (text.length > 0) {
				text += ' o ';
			}
			text += 'Debe ingresar una descripción del motivo de consulta';
		}

		return text;
	};

	const actions = () => {
		return (
			<>
				<CustomTooltip title={`${TooltipText()}`} placement="top" arrow>
					<span>
						<AIContainerActionGenerateSuggestionsBtn
							variant="contained"
							color="primary"
							startIcon={<AISuggestionIcon />}
							onClick={() => setIAPrompt(promptInput, goToDiagnosis)}
							disabled={!isEnable}
							data-fx-name="iaGenerationButton"
						>
							Generar Sugerencia
						</AIContainerActionGenerateSuggestionsBtn>
					</span>
				</CustomTooltip>
				<AIContainerActionHowItWorksBtn
					variant="text"
					onClick={() => {
						setShowModal(true);
					}}
				>
					¿Cómo funciona?
				</AIContainerActionHowItWorksBtn>
			</>
		);
	};
	return (
		<FormSection
			item
			style={
				isMobile
					? {
							paddingTop: 0,
					  }
					: {}
			}
		>
			<CustomCard
				title="Sugerencias inteligentes"
				subheader="Analizamos el historial, notas de examen físico y síntomas del paciente para sugerir diagnósticos, estudios y tratamiento médico."
			>
				{isMobile ? (
					<div className="flex flex-col gap-[8px] items-center">{actions()} </div>
				) : (
					actions()
				)}
			</CustomCard>
			<ModalContainer
				modalState={showModal}
				setModalState={setShowModal}
				hideConfirmBtn={true}
				title="¿Cómo funciona?"
			>
				<AISuggestionCardText>
					El sistema de sugerencias inteligentes analiza el historial, notas de examen
					físico y síntomas del paciente para sugerir diagnósticos, estudios y tratamiento
					médico.
				</AISuggestionCardText>
			</ModalContainer>
		</FormSection>
	);
};

export default AISuggestionCard;
