import {
	cancelOrActivateSubscription,
	createInvoiceByCustomer,
	detachPaymentMethodToCustomer,
	getCustomerCards,
	getDefaultPaymentMethod,
	getInvoiceBySubscription,
	getSubByCustomer,
	getSubscriptionStatus,
	retrieveIncomingInvoiceByCustomer,
	setDefaultPaymentMethod,
	updateSubscriptionItem,
} from 'adapters/stripe';

import { LoaderContext } from 'context/loader-context';
import { RelationsContext } from 'context/relations-context';
import { LoaderContextProps, RelationsContextProps, UserContextProps } from 'context/types';
import { UserContext } from 'context/user-context';
import { useContext, useEffect, useState } from 'react';
import { StripePaymentStatus } from 'shared/constants';
import { StripeError } from 'shared/type';

function convertTimestamp(timestamp: number): string {
	var date = new Date(timestamp * 1000);
	var day = ('0' + date.getUTCDate()).slice(-2);
	var month = ('0' + (date.getUTCMonth() + 1)).slice(-2);
	var year = date.getUTCFullYear();
	return day + '/' + month + '/' + year;
}

const useStripeConfiguration = () => {
	const { plans, fetchPlans } = useContext(UserContext) as UserContextProps;
	const [loadingPrice, setLoadingPrice] = useState<boolean>(false);
	const [price, setPrice] = useState<number>(0);
	const [nextPaymentDate, setNextPaymentDate] = useState<string>('');
	const [showCancel, setShowCancel] = useState<boolean>(false);
	const [paymentCards, setPaymentCards] = useState<any>([]);
	const { showSpinner } = useContext(LoaderContext) as LoaderContextProps;
	const [defaultPaymentMethodId, setDefaultPaymentMethodId] = useState<string>('');
	const [errorMessage, setErrorMessage] = useState('');
	const [errorModal, setErrorModal] = useState(false);
	const [changeCardModal, setChangeCardModal] = useState(false);
	const [openAddCardModal, setOpenAddCardModal] = useState(false);
	const [deleteCardModal, setDeleteCardModal] = useState(false);
	const [selectedCard, setSelectedCard] = useState<any>();
	const [actualSubs, setActualSubs] = useState<any>();
	const [allowUserToUpdatePlan, setAllowUserToUpdatePlan] = useState<boolean>(false);
	const {
		actualDoctor,
		updateSubscription,
		setAlertMessage,
		getActualSubProfile,
		updateDoctorStripeSubscription,
	} = useContext(RelationsContext) as RelationsContextProps;
	const getCustomerDefaultPayment = async () => {
		const customerData = await getDefaultPaymentMethod(actualDoctor?.stripeCustomerID!);

		if (customerData) {
			setDefaultPaymentMethodId(customerData.invoice_settings.default_payment_method);
		}

		return customerData.invoice_settings.default_payment_method;
	};

	const fetchCreditCards = async () => {
		const cards = await getCustomerCards(actualDoctor?.stripeCustomerID!);

		if (cards) {
			setPaymentCards(cards);
			getCustomerDefaultPayment();
		}
	};

	const fetchNextPaymentAmount = async () => {
		const upcomingInvoice = await retrieveIncomingInvoiceByCustomer(
			actualDoctor?.stripeCustomerID!
		);
		if (upcomingInvoice) {
			setPrice(upcomingInvoice.amount_due / 100);
		}
	};

	const fetchSubscription = async () => {
		try {
			setLoadingPrice(true);
			if (!plans || plans.length === 0) {
				await fetchPlans();
			}
			let sub: any = await getSubscriptionStatus(
				actualDoctor?.stripeSubscriptionID! as string
			);
			if (sub.error) {
				if (!!actualSubs && actualDoctor) {
					sub = actualSubs;
					await updateDoctorStripeSubscription(sub.id);
				} else {
					const subsData = await getSubByCustomer(actualDoctor?.stripeCustomerID!);
					if (subsData && subsData.data.length > 0) {
						sub = subsData.data[0];
						if (actualDoctor) {
							await updateDoctorStripeSubscription(sub.id);
						}
					}
				}
			}
			setActualSubs(sub);
			if (sub && sub.discount) {
				if (!sub?.plan) return;
				const nextPaymentDate = convertTimestamp(sub.current_period_end);
				setNextPaymentDate(nextPaymentDate);
			} else {
				const nextPaymentDate = convertTimestamp(sub.current_period_end);
				setNextPaymentDate(nextPaymentDate);
			}
			await fetchNextPaymentAmount();
			await fetchCreditCards();
			await getCustomerDefaultPayment();
		} catch (error) {
			console.error(error);
		} finally {
			setLoadingPrice(false);
		}
	};

	const cancelOrActivateSubscriptionHandler = async () => {
		showSpinner(true);
		if (paymentCards.data.length === 0 && actualDoctor?.cancelAtPeriodEnd) {
			setAlertMessage({
				message: 'Para activar tu membresía, debes agregar una tarjeta de crédito.',
				severity: 'error',
			});
			setErrorModal(true);
			showSpinner(false);
			return;
		}

		const actualDoctorSub = await getActualSubProfile();
		const subscription = await cancelOrActivateSubscription(
			actualSubs?.id ?? (actualDoctorSub!.stripeSubscriptionID as string),
			!actualDoctor?.cancelAtPeriodEnd
		);

		if (!subscription) return;

		await updateSubscription(
			actualSubs?.id ?? (actualDoctorSub!.stripeSubscriptionID as string),
			actualDoctor?.currentPlanID as number,
			subscription.cancel_at_period_end,
			subscription.current_period_end
		);

		if (subscription.cancellation_details.reason) {
			setAlertMessage({
				message: 'Tu membresía fue cancelada. Ya no te haremos ningún cargo',
				severity: 'warning',
			});
		}

		if (!subscription.cancellation_details.reason) {
			setAlertMessage({
				message: 'Tu membresía fue activada correctamente',
				severity: 'success',
			});
		}
		setShowCancel(false);
		showSpinner(false);
	};

	const openModal = () => {
		setShowCancel(true);
	};

	const closeModal = () => {
		setShowCancel(false);
	};

	// cards methods
	const updateSelectedCard = async (selectedCard: any) => {
		showSpinner(true);
		try {
			await setDefaultPaymentMethod(actualDoctor?.stripeCustomerID!, selectedCard.id);
			setDefaultPaymentMethodId(selectedCard.id);
		} catch (error: any) {
			setErrorMessage(
				StripeError[error.message as keyof typeof StripeError] ??
					'Ha ocurrido un error con los datos ingresados, por favor inténtelo nuevamente. Si el error persiste, contáctenos.'
			);
			setErrorModal(true);
		}

		showSpinner(false);
		setChangeCardModal(false);
	};

	const deleteCard = async (card: any) => {
		showSpinner(true);
		try {
			const subsDetail = await getSubscriptionStatus(actualDoctor?.stripeSubscriptionID!);
			if (
				subsDetail.status === StripePaymentStatus.Active &&
				(card.id === defaultPaymentMethodId || paymentCards.length === 1)
			) {
				setErrorMessage(
					'Para borrar esta tarjeta, debes seleccionar otra O Cancelar tu suscripción.'
				);
				setErrorModal(true);
				showSpinner(false);
				return;
			}
			await detachPaymentMethodToCustomer(card.id!);
			const newPaymentsCards = paymentCards.data.filter((c: any) => c.id !== card.id);
			setPaymentCards({ data: newPaymentsCards });
			if (paymentCards.data.length === 0) {
				await setDefaultPaymentMethod(actualDoctor?.stripeCustomerID!, '');
			}
		} catch (error: any) {
			setErrorModal(true);
			setErrorMessage(
				StripeError[error.message as keyof typeof StripeError] ??
					'Ha ocurrido un error con los datos ingresados, por favor inténtelo nuevamente. Si el error persiste, contáctenos.'
			);
		}
		showSpinner(false);
		setChangeCardModal(false);
		setDeleteCardModal(false);
	};

	const deleteCardHandler = async (card: any) => {
		const subsDetail = await getSubscriptionStatus(actualDoctor?.stripeSubscriptionID!);
		if (card.id === defaultPaymentMethodId) {
			if (!subsDetail.cancel_at_period_end) {
				setErrorModal(true);
				setErrorMessage(
					'Para borrar esta tarjeta, debes seleccionar otra tarjeta como predeterminada o cancelar tu suscripción.'
				);
				return;
			}
		}
		setDeleteCardModal(true);
		setSelectedCard(card);
	};

	const selectCardHandler = (card: any) => {
		if (card.id === defaultPaymentMethodId) return;
		setSelectedCard(card);
		setChangeCardModal(true);
	};

	const closeErrorModal = () => {
		setErrorModal(false);
	};

	const closeChangeCardModal = () => {
		setChangeCardModal(false);
	};

	const closeDeleteCardModal = () => {
		setDeleteCardModal(false);
	};

	const openCardModal = () => {
		setOpenAddCardModal(true);
	};

	const closeCardModal = () => {
		setOpenAddCardModal(false);
	};

	const updateSubsQuantity = async (add: boolean, currentActualDoctor: any) => {
		if (!currentActualDoctor && !actualSubs) return;
		const action = add ? 1 : -1;
		let sub: any = await getSubscriptionStatus(
			currentActualDoctor?.stripeSubscriptionID! as string
		);
		const newQuantity = parseInt(sub.items.data[0].quantity) + action;
		if (newQuantity < 1) return;
		await updateSubscriptionItem(sub.items.data[0].id, newQuantity);
		if (action === 1) {
			await createInvoiceByCustomer(currentActualDoctor?.stripeCustomerID!);
		}
	};

	const showChangePlanModal = async () => {
		if (actualSubs && actualSubs.status === StripePaymentStatus.Active) {
			setAllowUserToUpdatePlan(true);
		} else {
			setAllowUserToUpdatePlan(false);
		}
	};

	useEffect(() => {
		if (actualDoctor) {
			getInvoiceBySubscription(actualDoctor?.stripeSubscriptionID!);
			fetchSubscription();
		}
	}, [plans, actualDoctor]);

	useEffect(() => {
		showChangePlanModal();
	}, [actualSubs]);

	return {
		plans,
		nextPaymentDate,
		price,
		loadingPrice,
		cancelOrActivateSubscriptionHandler,
		openModal,
		closeModal,
		showModal: showCancel,
		paymentCards,
		defaultPaymentMethod: defaultPaymentMethodId,
		updateSelectedCard,
		deleteCard,
		deleteCardHandler,
		selectCardHandler,
		errorMessage,
		errorModal,
		closeErrorModal,
		changeCardModal,
		closeChangeCardModal,
		deleteCardModal,
		closeDeleteCardModal,
		selectedCard,
		openCardModal,
		closeCardModal,
		openAddCardModal,
		fetchCreditCards,
		actualSubs,
		getCustomerDefaultPayment,
		updateSubsQuantity,
		allowUserToUpdatePlan,
		fetchSubscription,
	};
};

export default useStripeConfiguration;
