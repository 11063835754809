import { TextField } from '@material-ui/core';
import React from 'react';
import CustomCard from '../../../card/CustomCard';
import { InputContainer } from '../../../../shared/style';
import { ConsultationContainer } from './styles';

interface Props {
	reason: string;
	setReason: (data: string) => void;
	isReasonEmpty: boolean;
	description: string;
	setDescription: (data: string) => void;
}

const DescriptionForm: React.FC<Props> = ({
	reason,
	setReason,
	isReasonEmpty,
	description,
	setDescription,
}) => {
	return (
		<ConsultationContainer item xs={12}>
			<CustomCard divider={true} title="Motivo de consulta">
				<InputContainer className="!mb-[16px] !md:mb-0" item xs={12} md={10}>
					<TextField
						id="reason"
						label="Motivo de consulta"
						variant="outlined"
						size="small"
						error={isReasonEmpty}
						helperText={isReasonEmpty && 'Este campo es obligatorio'}
						fullWidth
						value={reason}
						onChange={(e) => setReason(e.target.value)}
					/>
				</InputContainer>
				<InputContainer item xs={12} md={10}>
					<TextField
						id="reason-description"
						label="Notas de la consulta"
						variant="outlined"
						fullWidth
						multiline
						minRows={4}
						value={description}
						onChange={(e) => setDescription(e.target.value)}
					/>
				</InputContainer>
			</CustomCard>
		</ConsultationContainer>
	);
};

export default DescriptionForm;
