import React, { FC, useContext, useEffect, useLayoutEffect } from 'react';
import { formatPrice } from '../../shared/paymentUtils';
import {
	ActionContainer,
	BackButton,
	NewDialogContent,
	TitlePaymentModalTypography,
	ButtonPaymentModal,
	PaymentStyledDialog,
	PaymentRenewHeader,
} from '../../shared/style';
import PlanCard from './PaymentPlanCard';
import { UserContext } from '../../context/user-context';
import { UserContextProps } from '../../context/types';

interface Props {
	open: boolean;
	disableBackdropClick: boolean;
	handleClose: (
		event: React.MouseEvent<HTMLElement> | Record<string, unknown> | undefined
	) => void;
	selectedPlan: number;
	handleSelectPlan: (value: number) => void;
	handleNextStep: () => void;
}

const ModalRenewSubscription: FC<Props> = ({
	open,
	disableBackdropClick,
	handleClose,
	selectedPlan,
	handleSelectPlan,
	handleNextStep,
}) => {
	const { plans, fetchPlans } = useContext(UserContext) as UserContextProps;

	useLayoutEffect(() => {
		fetchPlans();
	}, []);

	useEffect(() => {
		if (plans.length > 0) {
			handleSelectPlan(plans[0].id);
		}
	}, [plans]);

	return (
		<PaymentStyledDialog
			open={open}
			aria-labelledby="renew-dialog-title"
			aria-describedby="renew-dialog-description"
		>
			<NewDialogContent>
				<PaymentRenewHeader>
					<TitlePaymentModalTypography variant="h5">
						Confirma tu plan de preferencia
					</TitlePaymentModalTypography>
					<div>
						{plans.map((plan) => {
							return (
								<PlanCard
									key={plan.id}
									selectedPlan={selectedPlan}
									handleSelectPlan={handleSelectPlan}
									value={plan.id}
									title={plan.title}
									subtitle={plan.subtitle}
									price={formatPrice(plan.price.slice(0, -2) as string)}
								/>
							);
						})}
					</div>
				</PaymentRenewHeader>
				<ActionContainer>
					<ButtonPaymentModal
						onClick={handleNextStep}
						variant="contained"
						color="primary"
					>
						Siguiente 1/2
					</ButtonPaymentModal>
					<BackButton color="primary" onClick={handleClose}>
						terminar luego y desloguearme
					</BackButton>
				</ActionContainer>
			</NewDialogContent>
		</PaymentStyledDialog>
	);
};

export default ModalRenewSubscription;
