import React, { FC } from 'react';
import {
	Divider,
	IconButton,
	ListItemSecondaryAction,
	ListItemText,
	Typography,
} from '@material-ui/core';
import { VisibilityIconStyled, StyledListItem, StyledList } from './PatientSummary.style';
import { ConsultationType } from '../../../context/types';
import { FormatDate, getDateToString } from '../../../shared/dateUtils';

interface Props {
	consultation: ConsultationType;
	handleDetailEnterConsultation: (
		e: React.MouseEvent<HTMLButtonElement>,
		consultation: ConsultationType
	) => Promise<void>;
	previousId: string | undefined;
}

const SummaryConsultationCard: FC<Props> = ({
	consultation,
	handleDetailEnterConsultation,
	previousId,
}) => {
	return (
		<div data-fx-name="consultationCard">
			<StyledList dense>
				<StyledListItem>
					<ListItemText
						primary={
							<React.Fragment>
								<Typography component="span" variant="body1" color="textPrimary">
									{consultation.visitReason || 'N/A'}
								</Typography>
							</React.Fragment>
						}
						secondary={
							consultation.updatedAt &&
							getDateToString(
								new Date(
									consultation.updatedAt.replace(/-/g, '/').replace(/T.+/, '')
								),
								FormatDate['DD/MM/YYYY']
							)
						}
						data-fx-name="consultationCardDetails"
					/>
					<ListItemSecondaryAction>
						<IconButton
							edge="end"
							id={previousId}
							onClick={async (e: React.MouseEvent<HTMLButtonElement>) => {
								await handleDetailEnterConsultation(e, consultation);
							}}
							aria-label="show"
							size="small"
						>
							<VisibilityIconStyled fontSize="small" />
						</IconButton>
					</ListItemSecondaryAction>
				</StyledListItem>
			</StyledList>
			<Divider />
		</div>
	);
};

export default SummaryConsultationCard;
