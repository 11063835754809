// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import { RelationsContext } from '../../../context/relations-context';
import {
	ClientContextProps,
	CreateTemplateLabImage,
	CreateTemplateMedicine,
	MedicineType,
	PrescriptionType,
	RelationsContextProps,
	SuggestionType,
	TemplateMedicineType,
} from '../../../context/types';
import {
	Medicine,
	LabImages,
	SidebarOptions,
	Templates,
	FreePrescription,
} from '../../../shared/type';
import { Grid, TextField } from '@material-ui/core';
import LabList from '../../forms/client/consultation/formTreatment/LabList';
import MedicineList from '../../forms/client/consultation/formTreatment/MedicineList';
import FreePrescriptionList from '../../forms/client/consultation/formTreatment/FreePrescription';
import SidebarTreatment from '../../forms/client/consultation/formTreatment/SidebarTreatment';
import { FormBodyContainer, ListContainer } from '../../../shared/style';
import { ClientsContext } from '../../../context/client-context';
import { ClientPrescriptionMedicine, LabImage, PrescriptionTypes } from 'models';
import CustomDialog from '../../dialog/CustomDialog';
import { getDateAndTime } from '../../../shared/dateUtils';
import { clearSuggestions, filterSuggestions } from 'shared/medicinesAndLabImagesUtils';

const CreateUpdatePrescription = () => {
	const {
		setPrescriptionToAdd,
		selectedClient,
		updateClientWithOutChanges,
		getPrescriptionsData,
		clientPrescriptions: prescriptions,
	} = useContext(ClientsContext) as ClientContextProps;
	const {
		fetchMedicines,
		fetchLabImages,
		fetchDoctorTemplates,
		createDoctorTemplate,
		fetchDoctorPrescriptionMedicines,
		fetchDoctorPrescriptionLabImages,
		fetchDoctorMedicines,
		fetchDoctorLabImages,
		actualDoctor,
		medicines: medicineList,
		labImages: labImagesList,
	} = useContext(RelationsContext) as RelationsContextProps;
	const [headerMenu, setHeaderMenu] = useState<null | HTMLElement>(null);
	const [medicines, setMedicines] = useState<Medicine[]>([] as Medicine[]);
	const [labImages, setLabImages] = useState<LabImages[]>([] as LabImages[]);
	const [freePrescription, setFreePrescription] = useState<FreePrescription[]>(
		[] as FreePrescription[]
	);
	const [isMedicine, setIsMedicine] = useState<boolean>(true);
	const [isFreePrescription, setIsFreePrescription] = useState<boolean>(false);
	const [sidebarMedicines, setSideBarMedicines] = useState<SidebarOptions[]>(
		[] as SidebarOptions[]
	);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [searchValue, setSearchValue] = useState<SidebarOptions>({} as SidebarOptions);
	const [sidebarLabs, setSideLabs] = useState<SidebarOptions[]>([] as SidebarOptions[]);
	const [medicalTemplates, setMedicalTemplates] = useState<Templates[]>([] as Templates[]);
	const [openCreateTemplate, setOpenCreateTemplate] = useState<boolean>(false);
	const [templateName, setTemplateName] = useState<string>('');
	const [labImagesTemplates, setLabImagesTemplates] = useState<Templates[]>([] as Templates[]);
	const [medicinesSuggestions, setMedicinesSuggestions] = useState<MedicineType[]>(
		[] as MedicineType[]
	);
	const [labImagesSuggestions, setLabImagesSuggestions] = useState<LabImage[]>([] as LabImage[]);
	const [freePrescriptionSuggestions, setFreePrescriptionSuggestions] = useState<
		FreePrescription[]
	>([] as FreePrescription[]);
	const [inputSearch, setInputSearch] = useState<string>('');

	useEffect(() => {
		const prescription = [];
		if (medicines && medicines.length > 0) {
			prescription.push({
				creationDate: getDateAndTime(),
				type: PrescriptionTypes.MEDICINE,
				medicines: medicines.map((m) => {
					return {
						id: m.medicineID as string,
						medicineID: m.medicineID as string,
						name: m.label,
						take: m.take,
						indication: m.indication,
						duration: m.duration,
						description: m.description,
					};
				}) as ClientPrescriptionMedicine[],
			});
		}
		if (labImages && labImages.length > 0) {
			prescription.push({
				creationDate: getDateAndTime(),
				type: PrescriptionTypes.LABIMAGE,
				analisis: labImages.map((li) => {
					return {
						...li,
						name: li.label,
						description: li.description,
					};
				}),
			});
		}

		if (freePrescription && freePrescription.length > 0) {
			prescription.push({
				creationDate: getDateAndTime(),
				type: PrescriptionTypes.FREE_PRESCRIPTION,
				customPrescription: freePrescription.map((li) => {
					return {
						...li,
						description: li.description,
					};
				}),
			});
		}

		setPrescriptionToAdd(prescription as PrescriptionType[]);
	}, [medicines, labImages, freePrescription, setPrescriptionToAdd]);

	useEffect(() => {
		const recentLabImagesList = actualDoctor?.recentLabImages?.map((recentLab) => {
			return { ...recentLab, label: recentLab.name };
		});
		const recentMedicinesList = actualDoctor?.recentMedicines?.map((recentMedi) => {
			return {
				...recentMedi,
				label: recentMedi.name,
			};
		});

		const medTemplate = actualDoctor?.templates
			? actualDoctor?.templates
					.filter((t) => t.templateType === PrescriptionTypes.MEDICINE)
					.map((t) => {
						const tempList = t.list && (t.list as TemplateMedicineType[]);
						return {
							...t,
							name: t.templateName,
							label: t.templateName,
							templateList: tempList.map((l) => {
								return {
									...l,
									label: l.name,
									name: l.name,
									description: l.description ? l.description : '',
									showDescription: Boolean(l.description),
								};
							}),
						};
					})
			: [];
		const labImgTemplate = actualDoctor?.templates
			? actualDoctor?.templates
					.filter((t) => t.templateType === PrescriptionTypes.LABIMAGE)
					.map((t) => {
						const tempList = t.list && (t.list as TemplateMedicineType[]);
						return {
							...t,
							name: t.templateName,
							label: t.templateName,
							templateList: tempList.map((l) => {
								return {
									...l,
									label: l.name,
								};
							}),
						};
					})
			: [];
		setLabImagesTemplates(
			labImgTemplate?.length ? (labImgTemplate as Templates[]) : ([] as Templates[])
		);
		setMedicalTemplates(
			medTemplate?.length ? (medTemplate as Templates[]) : ([] as Templates[])
		);
		setSideLabs(
			recentLabImagesList?.length
				? (recentLabImagesList as SidebarOptions[])
				: ([] as SidebarOptions[])
		);
		setSideBarMedicines(
			recentMedicinesList?.length
				? (recentMedicinesList as SidebarOptions[])
				: ([] as SidebarOptions[])
		);
	}, [actualDoctor]);

	useEffect(() => {
		const getPrescriptions = async () => {
			await getPrescriptionsData();
		};
		if (!prescriptions || prescriptions.length === 0) {
			getPrescriptions();
		}
		if (prescriptions) {
			const freePres = prescriptions
				.filter((p: any, i: any) => p.type === PrescriptionTypes.FREE_PRESCRIPTION)
				.map((p: any) => ({
					...p.list[0],
					label: p.list[0].description || '',
					description: p.list[0].description || '',
					freePrescriptionID: p.list[0].id,
				}))
				.slice(0, 5);

			setFreePrescriptionSuggestions(freePres);
		}
	}, [prescriptions]);

	useEffect(() => {
		fetchMedicines();
		fetchLabImages();
		fetchDoctorTemplates();
		fetchDoctorMedicines(0, 30);
		fetchDoctorLabImages(0, 30);
		fetchDoctorPrescriptionLabImages(0, 30);
		fetchDoctorPrescriptionMedicines(0, 30);
	}, []);

	useEffect(() => {
		const suggestionType = isMedicine ? SuggestionType.MEDICINES : SuggestionType.LAB_IMAGES;
		const callback = isMedicine ? setMedicinesSuggestions : setLabImagesSuggestions;
		const data = isFreePrescription
			? [...medicineList, ...labImagesList]
			: isMedicine
			? medicineList
			: labImagesList;
		const timerId = filterSuggestions(suggestionType, data, inputSearch, callback);

		return () => {
			clearSuggestions(timerId, callback);
		};
	}, [inputSearch, isFreePrescription, isMedicine, labImagesList, medicineList]);

	// SideBar funtionality
	const handleChangeSearch = (
		_e: React.MouseEvent<HTMLDivElement>,
		newInputValue: SidebarOptions
	) => {
		setSearchValue(newInputValue);
	};

	const handleClickSearch = (newInputValue: SidebarOptions) => {
		if (isMedicine) {
			handleAddOption({
				...newInputValue,
			});
		} else {
			handleAddOption(newInputValue);
		}
	};

	const handleAddOption = (option: SidebarOptions) => {
		if (isFreePrescription) {
			const newOption = {
				id: option.id,
				description: option.label,
				label: option.label,
			};
			setFreePrescription((prev) => {
				return [
					{
						...prev[0],
						description: `${prev[0]?.description ? `${prev[0]?.description}, ` : ''} ${
							newOption.description
						}`,
					},
				];
			});

			return;
		}
		if (isMedicine) {
			setMedicines((prev) => {
				return (prev as Medicine[]).concat({
					...option,
					openUnit: null,
					openSideUnit: null,
					take: option.take ? option.take : '',
					duration: option.duration ? option.duration : '',
					showDescription: Boolean(option.description),
				});
			});
		} else {
			setLabImages((prev) => {
				return (prev as LabImages[]).concat({
					...option,
				});
			});
			const sideBarLabIndex = sidebarLabs.findIndex((x) => x.id === option.id);
			if (sideBarLabIndex !== -1) {
				setSideLabs((prev) => [
					option,
					...prev.slice(0, sideBarLabIndex),
					...prev.slice(sideBarLabIndex + 1),
				]);
			}
		}
	};

	const handleTemplate = (data: LabImages[] | Medicine[]) => {
		if (isMedicine) {
			setMedicines((prev: Medicine[]) =>
				prev.concat(data.map((x, i) => ({ ...x, name: x.label, id: prev.length + i + 1 })))
			);
		} else {
			setLabImages((prev: LabImages[]) =>
				prev.concat(data.map((x, i) => ({ ...x, name: x.label, id: prev.length + i + 1 })))
			);
		}
	};

	// Medicine or Lab-image funtionality
	const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
		setHeaderMenu(event.currentTarget);
	};

	const handleCloseMenu = () => {
		setHeaderMenu(null);
	};

	const handleMouseLeaveMedicine = () => {
		setMedicines(
			medicines.map((m: Medicine) => {
				return { ...m, showDescription: false };
			})
		);
	};

	const removeAll = () => {
		if (isMedicine) {
			setMedicines([]);
			setHeaderMenu(null);
		} else {
			setLabImages([]);
			setHeaderMenu(null);
		}
	};

	const openDialogCreateTemplate = () => {
		setOpenCreateTemplate(true);
		setHeaderMenu(null);
	};

	const optionsForMenu = [
		{
			id: 1,
			label: 'Crear Plantilla',
			onClick: openDialogCreateTemplate,
		},
		{
			id: 2,
			label: 'Eliminar todo',
			onClick: removeAll,
		},
	];

	const handleMouseOverMedicine = (id: number | string) => {
		setMedicines(
			medicines.map((m: Medicine) => {
				// eslint-disable-next-line no-lone-blocks
				{
					return m.id === id ? { ...m, showDescription: true } : m;
				}
			})
		);
	};

	const handleDeleteMedicine = (id: number | string) => {
		setMedicines(
			medicines
				.filter((m: Medicine) => m.id !== id)
				.map((x, index) => {
					return { ...x, id: index + 1 };
				})
		);
	};

	const handleFocusMedicine = (e: any, id: number | string) => {
		if (e.currentTarget === e.target) {
			setMedicines(
				medicines.map((m: Medicine) => {
					// eslint-disable-next-line no-lone-blocks
					{
						return m.id === id ? { ...m, showDescription: true } : m;
					}
				})
			);
		}
	};

	const handleUnFocuseMedicine = (e: any, id: number | string) => {
		if (e.currentTarget === e.target && e.target.value === '') {
			setMedicines(
				medicines.map((m: Medicine) => {
					// eslint-disable-next-line no-lone-blocks
					{
						return m.id === id ? { ...m, showDescription: false } : m;
					}
				})
			);
		}
	};

	const handleChangeMedicine = (id: number | string, label: string, value: any) => {
		setMedicines((m: Medicine[]) => m.map((x) => (x.id === id ? { ...x, [label]: value } : x)));
	};

	const handleDeleteLabImage = (id: number | string) => {
		setLabImages(
			labImages
				.filter((l: LabImages) => l.id !== id)
				.map((x, index) => {
					return { ...x, id: index + 1 };
				})
		);
	};

	const handleChangeLabImage = (id: number | string, value: string) => {
		setLabImages((l: LabImages[]) => {
			return l.map((x: LabImages) => (x.id === id ? { ...x, description: value } : x));
		});
	};

	const handleFreePrescription = (value: string) => {
		if (value === '') {
			setFreePrescription([]);
			return;
		}

		const obj = {
			description: value,
		};

		setFreePrescription([obj]);
	};

	const closeModalForTemplate = () => {
		setOpenCreateTemplate(false);
		setTemplateName('');
	};

	const handleAddTemplate = async () => {
		if (isMedicine) {
			if (templateName !== '') {
				await createDoctorTemplate(
					templateName,
					PrescriptionTypes.MEDICINE,
					medicines.map((me) => {
						return {
							...me,
							name: me.label,
						};
					}) as CreateTemplateMedicine[]
				);

				closeModalForTemplate();
			}
		} else {
			if (templateName !== '') {
				createDoctorTemplate(
					templateName,
					PrescriptionTypes.LABIMAGE,
					labImages.map((li) => {
						return { ...li, name: li.label };
					}) as CreateTemplateLabImage[]
				);
				closeModalForTemplate();
			}
		}
		await updateClientWithOutChanges(selectedClient?.id as string);
	};

	const handleChangename = (value: string) => {
		setInputSearch(value);
	};

	return (
		<Grid container>
			<ListContainer item xs={2}>
				<SidebarTreatment
					hasSearch={true}
					searchValue={inputSearch}
					setSearchValue={setSearchValue}
					handleClickSearch={handleClickSearch}
					handleChangeSearch={handleChangeSearch}
					handleAddOption={handleAddOption}
					isMedicine={isMedicine}
					setIsMedicine={setIsMedicine}
					quantMedicines={medicines.length}
					quantLabImages={labImages.length}
					sidebarMedicines={sidebarMedicines}
					sidebarLabs={sidebarLabs}
					medicalTemplates={medicalTemplates}
					labImagesTemplates={labImagesTemplates}
					handleTemplate={handleTemplate}
					isFreePrescription={isFreePrescription}
					setIsFreePrescription={setIsFreePrescription}
					handleChangename={handleChangename}
					freePrescriptionSuggestions={freePrescriptionSuggestions as SidebarOptions[]}
					medicinesSuggestions={medicinesSuggestions}
					labImagesSuggestions={labImagesSuggestions}
					hasFreePrescription={freePrescription.length > 0 ? true : false}
				/>
			</ListContainer>
			{isFreePrescription && (
				<FormBodyContainer item xs={9}>
					<FreePrescriptionList
						headerMenu={headerMenu}
						prescription={
							freePrescription.length ? freePrescription[0].description : ''
						}
						handleAddPrescription={handleFreePrescription}
						handleClick={handleClickMenu}
						handleClose={handleCloseMenu}
						handleMouseLeaveMedicine={handleMouseLeaveMedicine}
						optionsForMenu={optionsForMenu}
						medicines={medicines}
						handleMouseOverMedicine={handleMouseOverMedicine}
						handleDeleteMedicine={handleDeleteMedicine}
						handleFocusMedicine={handleFocusMedicine}
						handleUnFocuseMedicine={handleUnFocuseMedicine}
						handleChangeMedicine={handleChangeMedicine}
					/>
				</FormBodyContainer>
			)}
			{isMedicine && medicines && medicines.length > 0 && (
				<FormBodyContainer item xs={9}>
					<MedicineList
						headerMenu={headerMenu}
						handleClick={handleClickMenu}
						handleClose={handleCloseMenu}
						handleMouseLeaveMedicine={handleMouseLeaveMedicine}
						optionsForMenu={optionsForMenu}
						medicines={medicines}
						handleMouseOverMedicine={handleMouseOverMedicine}
						handleDeleteMedicine={handleDeleteMedicine}
						handleFocusMedicine={handleFocusMedicine}
						handleUnFocuseMedicine={handleUnFocuseMedicine}
						handleChangeMedicine={handleChangeMedicine}
					/>
				</FormBodyContainer>
			)}
			{!isMedicine && labImages && labImages.length > 0 && (
				<FormBodyContainer item xs={9}>
					<LabList
						headerMenu={headerMenu}
						handleClick={handleClickMenu}
						handleClose={handleCloseMenu}
						optionsForMenu={optionsForMenu}
						labImages={labImages}
						handleDeleteLabImage={handleDeleteLabImage}
						handleChangeLabImage={handleChangeLabImage}
					/>
				</FormBodyContainer>
			)}

			{openCreateTemplate && (
				<CustomDialog
					open={openCreateTemplate}
					handleClose={closeModalForTemplate}
					title="Agrega un nombre a la nueva plantilla"
					disagreeText="Cancelar"
					agreeText="Guardar"
					handleAgree={handleAddTemplate}
				>
					<TextField
						label="Nombre de plantilla"
						placeholder="Nombre de plantilla"
						fullWidth
						variant="outlined"
						value={templateName}
						onChange={(e) => setTemplateName(e.target.value)}
					/>
				</CustomDialog>
			)}
		</Grid>
	);
};

export default CreateUpdatePrescription;
