import { ClientsContext } from 'context/client-context';
import { RelationsContext } from 'context/relations-context';
import moment from 'moment';
import { SyntheticEvent, useContext, useState } from 'react';
import { Event as BigCalendarEvent, SlotInfo } from 'react-big-calendar';
import { IAppointment } from 'shared/type';

interface UseAppointmentsProps {
	selectedDate: moment.Moment;
	isCalenderSideView?: boolean;
}

export const useAppointments = ({ selectedDate, isCalenderSideView }: UseAppointmentsProps) => {
	const { deleteClientAppointment } = useContext(ClientsContext);
	const { fetchDoctorAppointments } = useContext(RelationsContext);

	const [appointments, setAppointments] = useState<BigCalendarEvent[]>([]);
	const [appointment, setAppointment] = useState<IAppointment>({
		client: null,
		description: '',
		date: selectedDate,
		start: null,
		end: null,
	});
	const [isUpdating, setIsUpdating] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

	const deleteSelectedAppointment = async () => {
		await deleteClientAppointment!({
			id: appointment.id!,
			_version: appointment._version!,
		});
	};

	const fetchAppointments = async (docId: any = null) => {
		const date = moment(selectedDate).format('YYYY-MM-DD');
		let filter: string | { start: string; end: string } = date;

		if (!isCalenderSideView) {
			const currentDate = moment(date);
			const startCurrentWeek = moment(
				currentDate.subtract(currentDate.weekday(), 'days')
			).format('YYYY-MM-DD');
			const endCurrentWeek = moment(
				currentDate.add(currentDate.weekday() + Math.abs(currentDate.weekday() - 6), 'days')
			).format('YYYY-MM-DD');
			filter = { start: startCurrentWeek, end: endCurrentWeek };
		}

		fetchDoctorAppointments!(filter, docId).then((appointments) => {
			const appointmentsMapped: BigCalendarEvent[] = appointments.map((appointment) => ({
				title: appointment.description,
				start: moment(appointment.start).toDate(),
				end: moment(appointment.end).toDate(),
				resource: appointment,
			}));
			setAppointments(appointmentsMapped);
		});
	};

	const handleClickEvent = (
		event: BigCalendarEvent,
		htmlEvent: SyntheticEvent<HTMLElement, Event>
	) => {
		let currentElement = htmlEvent.target as HTMLElement;

		// Traverse up the DOM until we find a button or reach the top
		while (
			currentElement &&
			!Array.from(currentElement.classList).includes('rbc-event-content')
		) {
			currentElement = currentElement.parentElement!;
		}

		setAppointment({
			id: event.resource.id,
			_version: event.resource._version,
			client: event.resource.client,
			description: event.resource.description,
			date: moment(event.start),
			start: moment(event.start).format('hh:mm A'),
			end: moment(event.end).format('hh:mm A'),
			shouldSendReminder: event.resource.shouldSendReminder,
		});
		setAnchorEl(currentElement);
	};

	const handleCreateEventOnSelect = (slotInfo: SlotInfo) => {
		const { start, end } = slotInfo;

		setAppointment({
			description: '',
			client: null,
			date: moment(start),
			start: moment(start).format('hh:mm A'),
			end: moment(end).format('hh:mm A'),
		});

		setIsModalOpen(true);
	};

	const onClickNewAppointment = () => {
		setIsModalOpen(true);
		setAppointment({
			description: '',
			client: null,
			date: selectedDate,
			start: null,
			end: null,
		});
	};

	const handleClickEditAppointment = () => {
		setIsModalOpen(true);
		setAnchorEl(null);
		setIsUpdating(true);
	};

	const handleClosePopOver = () => {
		setAnchorEl(null);
		setIsUpdating(false);
	};

	const closeModal = () => {
		setIsModalOpen(false);
		setIsUpdating(false);
	};

	const handleDeleteAppointment = async () => {
		await deleteSelectedAppointment();
		await fetchAppointments();
		handleClosePopOver();
		setOpenConfirmationModal(false);
	};

	return {
		appointment,
		appointments,
		anchorEl,
		isModalOpen,
		isUpdating,
		openConfirmationModal,
		setOpenConfirmationModal,
		setIsModalOpen,
		setAnchorEl,
		closeModal,
		handleClickEditAppointment,
		handleCreateEventOnSelect,
		setAppointment,
		fetchDoctorAppointments: fetchAppointments,
		deleteSelectedAppointment,
		handleClickEvent,
		onClickNewAppointment,
		handleClosePopOver,
		handleDeleteAppointment,
	};
};
