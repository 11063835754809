import React from 'react';
import { ClientType } from '../../../context/types';
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import FormatListNumberedRtlIcon from '@material-ui/icons/FormatListNumberedRtl';
import {
	Avatar,
	Divider,
	IconButton,
	ListItem,
	ListItemAvatar,
	ListItemSecondaryAction,
	ListItemText,
} from '@material-ui/core';

interface ClientProps {
	image?: string;
	client: ClientType;
	onEdit: (client: ClientType) => void;
	onSend: (client: ClientType) => void;
	fullWidth?: boolean;
	isDisableSentBtn: boolean;
}

const PatientFC: React.FC<ClientProps> = (props) => {
	const history = useHistory();

	const subStringInsurance = (insurance: string) => {
		if (insurance.length < 35) {
			return insurance;
		}
		return insurance.substring(0, 28) + '...';
	};

	const showInsurance = props.client.actualInsurance
		? subStringInsurance(props.client.actualInsurance)
		: null;
	return (
		<>
			<ListItem button onClick={() => history.push(`/Clients/${props.client.id}`)}>
				<ListItemAvatar>
					<Avatar alt="patient-img" src={props.client.profileImage} />
				</ListItemAvatar>
				<ListItemText
					primary={`${props.client.name} ${props.client.lastName}`}
					secondary={showInsurance}
					data-fx-name="patientName"
				/>
				<ListItemSecondaryAction>
					<IconButton
						aria-label="edit"
						onClick={() => props.onEdit.apply(null, [props.client])}
					>
						<EditIcon />
					</IconButton>
					<IconButton
						edge="end"
						aria-label="share"
						disabled={props.isDisableSentBtn}
						onClick={() => props.onSend.apply(null, [props.client])}
					>
						<FormatListNumberedRtlIcon />
					</IconButton>
				</ListItemSecondaryAction>
			</ListItem>
			<Divider variant="middle" component="li" />
		</>
	);
};

export default PatientFC;
