import React from 'react';
import { NoItemsIcon } from '../svg';
import AddIcon from '@material-ui/icons/Add';
import { NoItemButton, SubTitle, Title } from './styles';

interface NoItemProps {
	title: string;
	titleStyle?: React.CSSProperties;
	containerStyle?: React.CSSProperties;
	subTitle?: string;
	name?: string;
	btnLabel?: string;
	isFem?: boolean;
	onClickHandler: () => void;
	height?: string;
	width?: string;
	isRow?: boolean;
	icon?: React.ReactNode;
	iconStyle?: React.CSSProperties;
}

const NoItem: React.FC<NoItemProps> = ({
	title,
	titleStyle,
	containerStyle,
	subTitle,
	name,
	isFem = false,
	onClickHandler,
	btnLabel,
	height,
	width,
	isRow = false,
	icon = <NoItemsIcon />,
	iconStyle = {},
}) => {
	const customIcon = () => {
		return (
			<div
				className="flex justify-center items-center"
				style={{
					...iconStyle,
				}}
			>
				{icon}
			</div>
		);
	};
	return (
		<div
			className={`flex justify-center items-center px-5  md-px-0 h-full ${
				isRow ? 'flex-col' : 'flex-row'
			} rg-1 gap-y-5 gap-x-5`}
			style={{
				height: height,
				columnGap: '53px',
			}}
		>
			{customIcon()}
			<div
				className="flex flex-col text-center items-center md:text-left"
				style={{
					maxWidth: width,
					...containerStyle,
				}}
			>
				<Title
					style={{
						...titleStyle,
					}}
				>
					{title}
				</Title>
				{name && (
					<SubTitle>
						{`Parece que aún no has creado ${isFem ? 'ninguna' : 'ningun'} ${name}`}.
					</SubTitle>
				)}
				{subTitle && <SubTitle>{subTitle}</SubTitle>}
				{btnLabel && (
					<NoItemButton
						color="primary"
						size="small"
						onClick={() => onClickHandler()}
						startIcon={<AddIcon fontSize="large" />}
						style={{
							width: 'fit-content',
						}}
					>
						{btnLabel || ''}
					</NoItemButton>
				)}
			</div>
		</div>
	);
};

export default NoItem;
