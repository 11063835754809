/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://ggqxzybrjnb7tpbpschuum7ugy.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-oz5bdze3hndytnannucg4p7n2y",
    "aws_cloud_logic_custom": [
        {
            "name": "apifb7c2ea8",
            "endpoint": "https://b29043p612.execute-api.us-east-1.amazonaws.com/produccion",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:f8bd7eb3-7e26-46ea-96dc-46200700a22a",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_MCxjK4Gec",
    "aws_user_pools_web_client_id": "5v22q24uo1hud4gota8qqjjkv6",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "devstorage32927-produccion",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
