import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const ITEM_HEIGHT = 48;

type Props = {
	onDelete: () => void;
	onPreview: () => void;
	onDownload: () => void;
};

export default function ImageOptions({ onDelete, onDownload, onPreview }: Props) {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const options = [
		{
			name: 'Abrir',
			action: () => {
				onPreview();
				handleClose();
			},
			icon: null,
		},
		{
			name: 'Descargar',
			action: () => {
				onDownload();
				handleClose();
			},
			icon: null,
		},
		{
			name: 'Eliminar',
			action: () => {
				onDelete();
				handleClose();
			},
			icon: null,
		},
	];

	return (
		<div
			style={{
				position: 'absolute',
				background: '#FFFFFF',
				width: '20px',
				height: '20px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				gap: '10px',
				top: '5%',
				right: '5%',
			}}
		>
			<IconButton
				aria-label="more"
				aria-controls="long-menu"
				aria-haspopup="true"
				onClick={handleClick}
				style={{
					width: '20px',
					height: '20px',
				}}
			>
				<MoreVertIcon
					style={{
						width: '20px',
						height: '20px',
					}}
				/>
			</IconButton>
			<Menu
				id="long-menu"
				anchorEl={anchorEl}
				keepMounted
				open={open}
				onClose={handleClose}
				PaperProps={{
					style: {
						maxHeight: ITEM_HEIGHT * 4.5,
						width: '20ch',
					},
				}}
			>
				{options.map((option) => (
					<MenuItem
						key={option.name}
						selected={option.name === 'Pyxis'}
						onClick={option.action}
					>
						{option.name}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
}
