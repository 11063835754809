// @ts-nocheck
import { Grid } from '@material-ui/core';
import { DiagnosticTypes } from 'models';
import React, { FC } from 'react';
import AISuggestionCloseIcon from 'components/svg/AISuggestionCloseIcon';
import AISuggestionIcon from 'components/svg/AISuggestionIcon';
import ExclamationMarkIcon from 'components/svg/ExclamationMarkIcon';
import { AISuggestion, AIType } from 'shared/ai/types';
import { StyledChip } from 'shared/style';

interface props {
	add?: (type: AIType, name: string, cb: Function) => void;
	cb?: Function;
	style?: React.CSSProperties;
	suggestions: AISuggestion;
	type: AIType;
	clickAble?: boolean;
	onHide?: () => void;
}

const AIAlertSuggestion: FC<props> = ({
	add = () => {},
	cb,
	style,
	suggestions,
	type,
	clickAble = true,
	onHide = () => {},
}) => {
	const generateSuggestionsHandler = () => {
		const suggestionsArray = suggestions[type];
		if (type === 'diagnosticosDiferenciales' || type === 'diagnosticos') {
			const diagnosticType =
				type === 'diagnosticos'
					? DiagnosticTypes.PRESUMPTIVE
					: DiagnosticTypes.DIFFERENTIAL;

			return suggestionsArray.map((suggestion, index) => {
				return (
					<StyledChip
						key={`${suggestion.nombre}-${index}`}
						label={suggestion.nombre}
						clickable
						color="primary"
						variant="outlined"
						icon={<ExclamationMarkIcon description={suggestion.descripcion} />}
						style={{
							fontFamily: 'Roboto',
							fontStyle: 'normal',
							fontWeight: 500,
							fontSize: '14px',
							lineHeight: '24px',
							letterSpacing: '0.17px',
							color: 'rgba(0, 0, 0, 0.87)',
							border: '1px solid rgba(0, 0, 0, 0.26)',
							borderRadius: '16px',
							marginRight: '13px',
							marginBottom: '13px',
							overflowX: 'clip',
						}}
						customHoverEffect={true}
						disabled={!clickAble}
						onClick={() =>
							add(
								type,
								suggestion.nombre,
								() => cb && cb(suggestion.nombre, diagnosticType)
							)
						}
						data-fx-name="diagnosticSuggestion"
					/>
				);
			});
		}

		if (type === 'medicamentos') {
			return suggestionsArray.map((suggestion, index) => {
				const customLabel = `${suggestion.nombre}, ${suggestion.medida}, ${suggestion.posologia} ${suggestion.tomar}`;
				return (
					<StyledChip
						key={`${suggestion.nombre}-${index}`}
						label={customLabel}
						clickable
						color="primary"
						variant="outlined"
						icon={<ExclamationMarkIcon description={suggestion.descripcion} />}
						style={{
							fontFamily: 'Roboto',
							fontStyle: 'normal',
							fontWeight: 500,
							fontSize: '14px',
							lineHeight: '24px',
							letterSpacing: '0.17px',
							color: 'rgba(0, 0, 0, 0.87)',
							border: 'none',
							cursor: 'unset',
							marginRight: '13px',
							marginBottom: '13px',
							overflowX: 'clip',
						}}
						onClick={() => {}}
						removeDisabledStyle
						data-fx-name="medicineSuggestion"
					/>
				);
			});
		}

		if (type === 'estudios') {
			return suggestionsArray.map((suggestion, index) => {
				return (
					<StyledChip
						key={`${suggestion.nombre}-${index}`}
						label={suggestion.nombre}
						clickable
						color="primary"
						variant="outlined"
						icon={<ExclamationMarkIcon description={suggestion.descripcion} />}
						style={{
							fontFamily: 'Roboto',
							fontStyle: 'normal',
							fontWeight: 500,
							fontSize: '14px',
							lineHeight: '24px',
							letterSpacing: '0.17px',
							color: 'rgba(0, 0, 0, 0.87)',
							border: '1px solid rgba(0, 0, 0, 0.26)',
							borderRadius: '16px',
							marginRight: '13px',
							marginBottom: '13px',
							overflowX: 'clip',
						}}
						disabled={!clickAble}
						customHoverEffect={true}
						onClick={() =>
							add(
								type,
								suggestion.nombre,
								() => cb && cb(suggestion.nombre, suggestion.descripcion)
							)
						}
						data-fx-name="labsSuggestion"
					/>
				);
			});
		}

		return suggestionsArray.map((suggestion, index) => {
			return (
				<StyledChip
					key={`${suggestion.nombre}-${index}`}
					label={suggestion.nombre}
					clickable
					color="primary"
					variant="outlined"
					icon={<ExclamationMarkIcon description={suggestion.descripcion} />}
					style={{
						fontFamily: 'Roboto',
						fontStyle: 'normal',
						fontWeight: 500,
						fontSize: '14px',
						lineHeight: '24px',
						letterSpacing: '0.17px',
						color: 'rgba(0, 0, 0, 0.87)',
						border: '1px solid rgba(0, 0, 0, 0.26)',
						borderRadius: '16px',
						marginRight: '13px',
						marginBottom: '13px',
						overflowX: 'clip',
					}}
					customHoverEffect={true}
					onClick={() => add(type, suggestion.nombre, () => cb && cb(suggestion.nombre))}
					data-fx-name="recomendationSuggestion"
				/>
			);
		});
	};

	return (
		<Grid
			container
			style={{
				backgroundColor:
					'linear-gradient(0deg, #FFFDE7, #FFFDE7),linear-gradient(0deg, #D1CD99, #D1CD99)',
				background: '#FFFDE7',
				border: '1px solid #D1CD99',
				borderRadius: '4px',
				padding: '20px 20px 10px 20px',
				position: 'relative',
				rowGap: '10px',
				marginTop: '10px',
			}}
			data-fx-name="suggestionsContainer"
		>
			<AISuggestionCloseIcon onClick={onHide} />
			<Grid
				container
				xs={12}
				style={{
					display: 'flex',
					flexDirection: 'row',
					columnGap: '8px',
				}}
			>
				<AISuggestionIcon color="#9C27B0" />
				<h3>Sugerencias inteligente. {clickAble ? 'Agrégala dandole click.' : ''}</h3>
			</Grid>
			<Grid
				container
				xs={12}
				style={{
					display: 'flex',
					columnGap: '6px',
					flexWrap: 'wrap',
					overflowX: 'clip',
					...style,
				}}
			>
				{generateSuggestionsHandler()}
			</Grid>
		</Grid>
	);
};

export default AIAlertSuggestion;
