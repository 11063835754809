import { Box } from '@material-ui/core';
import AddRounded from '@material-ui/icons/AddRounded';
import { Typography } from '@mui/material';
import { AppointmentForCard } from 'components/appointment/AppointmentForCard';
import { ModalAppointment } from 'components/appointment/components/ModalAppointment';
import CustomCard from 'components/card/CustomCard';
import CustomDialog from 'components/dialog/CustomDialog';
import { ClientsContext } from 'context/client-context';
import moment from 'moment';
import { useContext, useMemo, useState } from 'react';
import { IAppointment } from 'shared/type';

export const SummaryNextAppointments = () => {
	const { selectedClient, deleteClientAppointment } = useContext(ClientsContext);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isUpdating, setIsUpdating] = useState(false);
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

	const [appointment, setAppointment] = useState<IAppointment>({
		client: null,
		description: '',
		date: moment(),
		start: null,
		end: null,
	});

	const closeModal = () => {
		setIsModalOpen(false);
		setIsUpdating(false);
	};

	const handleSelectAppointment = (appointment: IAppointment) => {
		setAppointment({
			id: appointment.id,
			date: moment(appointment.date),
			start: moment(appointment.start).format('hh:mm A'),
			end: moment(appointment.end).format('hh:mm A'),
			shouldSendReminder: appointment.shouldSendReminder,
			description: appointment.description,
			client: appointment.client!,
			_version: appointment._version!,
		});
	};

	const handleDelete = async () => {
		await deleteClientAppointment!({
			id: appointment.id!,
			_version: appointment._version!,
		});
		setOpenConfirmationModal(false);
	};

	const onClickNewAppointment = () => {
		setIsModalOpen(true);
		setAppointment({
			description: '',
			client: {
				recordNo: selectedClient!.recordNo as string,
				name: selectedClient!.name + ' ' + selectedClient!.lastName,
				id: selectedClient!.id,
				email: selectedClient!.email!,
				_version: (selectedClient as any)._version!,
			},
			date: moment(),
			start: null,
			end: null,
		});
	};

	const handleEditClick = (appointment: IAppointment) => {
		handleSelectAppointment(appointment);
		setIsModalOpen(true);
		setIsUpdating(true);
	};

	const handleDeleteClick = (appointment: IAppointment) => {
		handleSelectAppointment(appointment);
		setOpenConfirmationModal(true);
	};

	const appointments = useMemo(() => {
		if (!selectedClient) return null;
		return selectedClient!.appointments?.map((a, i) => (
			<AppointmentForCard
				handleDelete={handleDeleteClick}
				handleEditClick={handleEditClick}
				appointment={a}
				shouldShowDivider={i !== selectedClient!.appointments.length - 1}
			/>
		));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedClient]);

	return (
		<>
			<CustomCard
				title="Próximas citas"
				divider={true}
				headerAction={
					<button style={{ cursor: 'pointer' }} onClick={onClickNewAppointment}>
						<AddRounded color="primary" />
					</button>
				}
				paddingTop={'0'}
				paddingBottom={'0'}
			>
				<Box display={'flex'} flexDirection={'column'}>
					{appointments?.length ? (
						appointments
					) : (
						<Typography paddingY={'8px'}>El paciente no tiene citas</Typography>
					)}
				</Box>
			</CustomCard>

			<ModalAppointment
				modalState={isModalOpen}
				disabledUserInput
				closeModal={closeModal}
				isUpdating={isUpdating}
				initialValues={appointment}
			/>

			<CustomDialog
				maxWidth={'xs'}
				open={openConfirmationModal}
				handleClose={() => setOpenConfirmationModal(false)}
				title={'¿Estás seguro que quieres eliminar la siguiente cita?'}
				agreeText="Confirmar"
				disagreeText="Cancelar"
				handleAgree={handleDelete}
			>
				{appointment.description}, {appointment.date?.format('dddd, D [de] MMMM')} ⋅{' '}
				{appointment.start} - {appointment.end}
			</CustomDialog>
		</>
	);
};
