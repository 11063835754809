import React, { useState, useEffect } from 'react';
import { Badge, InputAdornment, withStyles } from '@material-ui/core';
import {
	StyledList,
	SideBarListItem,
	LabelText,
	StyledListItemText,
	StyledIconButton,
	SearchListItemText,
	PrescriptionContainer,
} from '../../../../../shared/style';
import AddIcon from '@material-ui/icons/Add';
import { SidebarOptions, Medicine, LabImages, Templates } from '../../../../../shared/type';
import SearchAutocomplete from '../../../../searchAutocomplete/SearchAutocomplete';
import SearchIcon from '@material-ui/icons/Search';
import SidebarTooltip from './SidebarTooltip';
import { MedicineType } from 'context/types';
import { LabImage } from 'models';

const SideBarListItemStyled = withStyles({
	selected: {
		backgroundColor: 'rgba(25, 118, 210, 0.08) !important',
	},
})(SideBarListItem) as typeof SideBarListItem;

interface Props {
	handleAddOption: (value: SidebarOptions) => void;
	isFreePrescription: boolean;
	isMedicine: boolean;
	hasSearch?: boolean;
	searchValue?: any;
	setSearchValue?: (value: any) => void;
	handleChangeSearch?: (
		e: React.MouseEvent<HTMLDivElement>,
		newInputValue: SidebarOptions
	) => void;
	setIsMedicine: (data: boolean) => void;
	setIsFreePrescription: (data: boolean) => void;
	quantMedicines: number;
	quantLabImages: number;
	hasFreePrescription?: boolean;
	sidebarMedicines: SidebarOptions[];
	sidebarLabs: SidebarOptions[];
	medicalTemplates: Templates[];
	labImagesTemplates: Templates[];
	handleClickSearch?: (option: SidebarOptions) => void;
	handleTemplate: (data: Medicine[] | LabImages[]) => void;
	height?: string;
	medicinesSuggestions: MedicineType[];
	labImagesSuggestions: LabImage[];
	freePrescriptionSuggestions: SidebarOptions[];
	handleChangename: (value: string) => void;
}

const SidebarTreatment: React.FC<Props> = ({
	handleAddOption,
	isMedicine,
	setIsMedicine,
	quantMedicines,
	hasSearch,
	searchValue,
	setSearchValue,
	handleChangeSearch,
	quantLabImages,
	hasFreePrescription,
	sidebarMedicines,
	sidebarLabs,
	medicalTemplates,
	labImagesTemplates,
	handleTemplate,
	handleClickSearch,
	height,
	medicinesSuggestions,
	labImagesSuggestions,
	freePrescriptionSuggestions,
	handleChangename,
	isFreePrescription,
	setIsFreePrescription,
}) => {
	const [searchMedicines, setSearchMedicines] = useState<SidebarOptions[]>(
		[] as SidebarOptions[]
	);
	const [searchLabImages, setSearchLabImages] = useState<SidebarOptions[]>(
		[] as SidebarOptions[]
	);
	const options = isFreePrescription
		? freePrescriptionSuggestions
		: isMedicine
		? sidebarMedicines
		: sidebarLabs;
	const templates = isMedicine ? medicalTemplates : labImagesTemplates;
	const searchOptions = isMedicine ? searchMedicines : searchLabImages;
	const unique = new Set();
	const uniqueOptions = options.filter((option) => {
		if (unique.has(option.label)) {
			return false;
		}
		unique.add(option.label);
		return true;
	});
	const uniqueTemplates = new Set();
	const uniqueTemplatesOptions = templates.filter((option) => {
		if (uniqueTemplates.has(option.name)) {
			return false;
		}
		uniqueTemplates.add(option.name);
		return true;
	});

	useEffect(() => {
		setSearchMedicines(
			medicinesSuggestions?.map((m) => ({
				...m,
				medicineID: m.id as string,
				label: m.name as string,
				take: '',
				indication: '',
				duration: '',
				description: '',
			}))
		);
	}, [medicinesSuggestions]);

	useEffect(() => {
		setSearchLabImages(
			labImagesSuggestions?.map((lab) => ({
				...lab,
				labImageID: lab.id,
				label: lab.name || '',
				description: lab.description || '',
			}))
		);
	}, [labImagesSuggestions]);

	return (
		<StyledList component="nav" aria-label="secondary mailbox folders">
			<SideBarListItemStyled
				button
				selected={isMedicine}
				onClick={() => {
					setIsMedicine(true);
					setIsFreePrescription(false);
					setSearchValue?.({} as SidebarOptions);
				}}
			>
				<StyledListItemText primary="Medicamentos" />
				<Badge overlap="rectangular" badgeContent={quantMedicines} color="primary" />
			</SideBarListItemStyled>
			<SideBarListItemStyled
				button
				selected={isMedicine === false && !isFreePrescription}
				onClick={() => {
					setIsMedicine(false);
					setIsFreePrescription(false);
					setSearchValue?.({} as SidebarOptions);
				}}
			>
				<StyledListItemText primary="Lab/imágenes" data-fx-name="labImagentab" />
				<Badge overlap="rectangular" badgeContent={quantLabImages} color="primary" />
			</SideBarListItemStyled>

			<SideBarListItemStyled
				button
				divider
				selected={isFreePrescription}
				onClick={() => {
					setIsMedicine(false);
					setIsFreePrescription(true);
				}}
			>
				<StyledListItemText primary="Prescripción libre" data-fx-name="freePrescription" />
				<Badge
					overlap="rectangular"
					badgeContent={hasFreePrescription ? 1 : 0}
					color="primary"
				/>
			</SideBarListItemStyled>

			<PrescriptionContainer style={{ height: height ? height : 'calc(100dvh - 243px)' }}>
				{hasSearch && (
					<div
						style={{
							width: '88%',
							margin: 'auto',
							paddingTop: '20px',
							paddingBottom: '10px',
						}}
					>
						<SearchAutocomplete
							id="autocomplete-search"
							placeholder={
								isFreePrescription
									? 'Buscar'
									: isMedicine
									? 'Buscar medicamento'
									: 'Buscar Laboratorio'
							}
							options={searchOptions ? searchOptions : []}
							onChange={handleChangeSearch}
							onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								e !== null && e.target.value
									? handleChangename(e.target.value)
									: handleChangename('');
							}}
							inputValue={searchValue}
							getOptionLabel={(option) => (option.label ? option.label : '')}
							renderOption={(option) => (
								<SearchListItemText
									onClick={() => {
										handleClickSearch?.(option);
									}}
									primary={option.label ? option.label : ''}
								/>
							)}
							startAdornment={
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							}
						/>
					</div>
				)}
				{templates && templates.length > 0 && !isFreePrescription && (
					<>
						<LabelText variant="subtitle2" component="h2">
							Plantillas
						</LabelText>
						{uniqueTemplatesOptions.map((t) => (
							<SideBarListItemStyled
								key={t.id}
								onClick={() => handleTemplate(t.templateList)}
								button
								divider
							>
								<StyledListItemText primary={t.name} />
								<StyledIconButton aria-label="add">
									<AddIcon fontSize="small" />
								</StyledIconButton>
							</SideBarListItemStyled>
						))}
					</>
				)}
				{uniqueOptions && uniqueOptions.length > 0 && (
					<>
						<LabelText variant="subtitle2" component="h2">
							{isFreePrescription
								? 'Recientes'
								: isMedicine
								? 'Medicamentos Recientes'
								: 'Lab/imágenes recientes'}
						</LabelText>
						{uniqueOptions.map((option) => (
							<SidebarTooltip
								key={option.id}
								option={option}
								handleAddOption={handleAddOption}
							/>
						))}
					</>
				)}
			</PrescriptionContainer>
		</StyledList>
	);
};

export default SidebarTreatment;
