import React from 'react';

const Correct = () => {
	return (
		<svg
			width="97"
			height="97"
			viewBox="0 0 97 97"
			style={{
				marginTop: '40px',
			}}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_4863_113561)">
				<rect
					width="96"
					height="96"
					transform="translate(0.631836 0.731445)"
					fill="white"
				/>
				<path
					d="M71.1324 31.6486L66.3149 26.8311L44.6532 48.4927L49.4707 53.3102L71.1324 31.6486ZM85.619 26.8311L49.4707 62.9794L35.189 48.7319L30.3715 53.5494L49.4707 72.6486L90.4707 31.6486L85.619 26.8311ZM11.0332 53.5494L30.1324 72.6486L34.9499 67.8311L15.8849 48.7319L11.0332 53.5494Z"
					fill="#9C27B0"
				/>
			</g>
			<defs>
				<clipPath id="clip0_4863_113561">
					<rect
						width="96"
						height="96"
						fill="white"
						transform="translate(0.631836 0.731445)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default Correct;
