import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment, { Moment } from 'moment';
import React, { FC } from 'react';
import MomentUtils from '@date-io/moment';
import {
	CustomKeyboardDatePicker,
	CustomTextField,
	StyledTextField,
} from '../../../../shared/style';
import CustomDialog from '../../../dialog/CustomDialog';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import HistoryTitle from './FamilyHistoryFormTitle';

interface Props {
	isOpen: boolean;
	handleClose: () => void;
	isEdit: boolean;
	hospitalizationName: string;
	handleChangeHospitalizationName: (e: React.ChangeEvent<HTMLInputElement>) => void;
	hospitalizationDate: string | null | undefined | Moment;
	handleHospitalizationDate: (date: MaterialUiPickersDate) => void;
	hospitalizationNote: string;
	handleHospitalizationNote: (e: React.ChangeEvent<HTMLInputElement>) => void;
	handleSubmitHospitalization: () => void;
	handleDeleteHospitalization: () => void;
	isEmptyHospitalizationName: boolean;
	isMobile: boolean;
}

const HospitalizationFormDialog: FC<Props> = (props) => {
	const title = props.isEdit ? 'Editar hospitalización' : 'Agregar hospitalización';
	return (
		<CustomDialog
			open={props.isOpen}
			disableBackdropClick
			fullWidth
			maxWidth="xs"
			className={props.isMobile ? 'appointment-modal' : ''}
			fullScreen={props.isMobile}
			handleClose={props.handleClose}
			title={
				props.isMobile ? <HistoryTitle title={title} goBack={props.handleClose} /> : title
			}
			disagreeText="Cancelar"
			agreeText="Guardar"
			deleteText={
				props.isEdit ? (props.isMobile ? 'Eliminar' : 'Eliminar hospitalización') : ''
			}
			handleAgree={props.handleSubmitHospitalization}
			handleDelete={props.handleDeleteHospitalization}
		>
			<form className="flex flex-col gap-4 mt-4">
				<CustomTextField
					label="Razón de hospitalización*"
					placeholder="Razón de hospitalización*"
					fullWidth
					size="small"
					error={props.isEmptyHospitalizationName}
					variant="outlined"
					value={props.hospitalizationName}
					onChange={props.handleChangeHospitalizationName}
					helperText={
						props.isEmptyHospitalizationName
							? 'Debes escribir la razón de hospitalización'
							: ''
					}
				/>
				<MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={'es'}>
					<CustomKeyboardDatePicker
						disableFuture
						invalidDateMessage="Formato fecha invalida"
						openTo="year"
						views={['year', 'month', 'date']}
						fullWidth
						variant="inline"
						format="DD/MM/YYYY"
						inputVariant="outlined"
						id="hospitalization-date-picker-inline"
						label="En fecha"
						value={props.hospitalizationDate}
						onChange={props.handleHospitalizationDate}
						KeyboardButtonProps={{
							'aria-label': 'change date',
						}}
					/>
				</MuiPickersUtilsProvider>
				<StyledTextField
					id="outlined-hospitalization-note"
					label="Notas"
					placeholder="Notas"
					value={props.hospitalizationNote}
					onChange={props.handleHospitalizationNote}
					multiline
					fullWidth
					minRows={5}
					variant="outlined"
				/>
			</form>
		</CustomDialog>
	);
};

export default HospitalizationFormDialog;
