import { ClientsContext } from 'context/client-context';
import { ClientContextProps, IClientFilter } from 'context/types';
import { useContext, useEffect, useState } from 'react';

const compareByNameAndRecordNo = (a: IClientFilter, b: IClientFilter) => {
	if (a.recordNo === null && b.recordNo !== null) return 1;
	if (a.recordNo !== null && b.recordNo === null) return -1;

	if (a.recordNo === null && b.recordNo === null) {
		if (a.name < b.name) return -1;
		if (a.name > b.name) return 1;
		return 0;
	} else {
		const aRecord = a.recordNo?.substring(1, 3) || '';
		const bRecord = b.recordNo?.substring(1, 3) || '';

		if (aRecord < bRecord) return -1;
		if (aRecord > bRecord) return 1;
		return 0;
	}
};
export const useClientFilter = () => {
	const [filterText, setFilterText] = useState<string>('');
	const [options, setOptions] = useState<readonly IClientFilter[]>([]);
	const [loadingClientOptions, setLoadingClientOptions] = useState(false);
	const { fetchPaginatedClientsByName } = useContext(ClientsContext) as ClientContextProps;

	useEffect(() => {
		if (filterText.length >= 2) {
			const timeOut = setTimeout(async () => {
				setOptions([]);
				setLoadingClientOptions(true);
				const clients = await fetchPaginatedClientsByName(filterText);
				let clientOptions = clients.sort(compareByNameAndRecordNo);
				setOptions(clientOptions);
				setLoadingClientOptions(false);
			}, 1000);

			return () => {
				clearTimeout(timeOut);
			};
		} else {
			setOptions([]);
		}
	}, [filterText]);

	return {
		loadingClientOptions,
		clientOptions: options,
		filterText,
		setFilterText,
	};
};
