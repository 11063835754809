// @ts-nocheck
import {
	Button,
	IconButton,
	CardHeader,
	createTheme,
	withStyles,
	InputAdornment,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { Box, Divider } from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CustomDialog from 'components/dialog/CustomDialog';
import { RelationsContext } from 'context/relations-context';
import { useAppointments } from 'hooks/useAppointmentsViewLogic';
import { StyledTextField } from 'components/searchAutocomplete/styles';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { AppointmentDetailPopOver } from './components/AppointmentDetailPopOver';
import { EventCard } from './components/EventCard';
import { ModalAppointment } from './components/ModalAppointment';
import useMobile from 'hooks/useMobile';
import MobileCalendar from './ScrollableMonthCalendar';
import { useClientFilter } from 'hooks/useClientFilter';
import { ActionHeader, StyledSearchIcon } from '../../shared/style';
import MobileActionsButton from 'components/mobileActionsButton/MobileActionsButton';
import { Autocomplete } from '@material-ui/lab';
import { UserContext } from 'context/user-context';
import { UserContextProps } from 'context/types';

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const theme = createTheme();
const StyledCardHeader = withStyles({
	root: {
		padding: theme.spacing(1.5, 2),
	},
	title: {
		fontSize: theme.spacing(2.5),
		fontWeight: 500,
	},
	action: {
		marginTop: 0,
		marginRight: 0,
	},
})(CardHeader) as typeof CardHeader;

export const AppointmentSideView = () => {
	const { setIsCalendarSideViewOpen } = useContext(RelationsContext);
	const [selectedDate, setSelectedDate] = useState<moment.Moment>(moment());
	const [showSearchMobile, toggleSearchMobile] = useState(false);
	const { doctorMemberSelected } = useContext(UserContext) as UserContextProps;
	const {
		appointment,
		appointments,
		anchorEl,
		isModalOpen,
		isUpdating,
		openConfirmationModal,
		setOpenConfirmationModal,
		closeModal,
		handleCreateEventOnSelect,
		fetchDoctorAppointments,
		handleDeleteAppointment,
		handleClickEvent,
		handleClickEditAppointment,
		onClickNewAppointment,
		handleClosePopOver,
	} = useAppointments({ selectedDate, isCalenderSideView: true });
	const { isMobile } = useMobile();

	const { filterText, setFilterText } = useClientFilter();

	const actions = [
		{
			name: 'Nueva cita',
			onClick: () => onClickNewAppointment(),
		},
	];

	useEffect(() => {
		const handleScroll = () => {
			const targetElement = document.querySelector<HTMLDivElement>(
				'.rbc-current-time-indicator'
			);

			if (window.innerWidth < 1024) {
				if (targetElement) {
					const calendarTableView =
						document.querySelector<HTMLDivElement>('.calendarTableView');
					if (calendarTableView) {
						// Calculate the scroll position to center the target element
						const scrollPosition =
							targetElement.offsetTop -
							calendarTableView.clientHeight / 2 +
							targetElement.clientHeight / 2;
						calendarTableView.scroll({
							behavior: 'smooth',
							top: scrollPosition,
						});
					}
				} else {
					// Scroll the target element into view with smooth behavior
					targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
				}
			}
		};

		const timeoutId = setTimeout(handleScroll, 400);

		return () => clearTimeout(timeoutId);
	}, []);

	useEffect(() => {
		if (!isModalOpen) {
			fetchDoctorAppointments(doctorMemberSelected?.doctorID);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedDate, isModalOpen, doctorMemberSelected]);

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	return (
		<>
			<Box
				display={'flex'}
				flexDirection={'column'}
				sx={{
					backgroundColor: 'white',
					paddingTop: isMobile ? 0 : '40px',
					paddingBottom: isMobile ? '64px' : 0,
					maxHeight: '100%',
					boxShadow:
						'0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20);',
				}}
			>
				{!showSearchMobile && isMobile && (
					<StyledCardHeader
						title="Calendario"
						className="card-header"
						action={
							<ActionHeader>
								{isMobile && (
									<>
										{/* Disabled temporary */}
										{/* <button
                                            onClick={() => toggleSearchMobile(true)}
                                            style={{ minWidth: '36px' }}
                                        >
                                            <StyledSearchIcon />
                                        </button> */}
										<MobileActionsButton actions={actions} />
									</>
								)}
							</ActionHeader>
						}
					/>
				)}

				{showSearchMobile && (
					<StyledCardHeader
						className="header-search-mobile"
						title={
							<IconButton onClick={() => toggleSearchMobile(false)}>
								<ArrowBackIcon />
							</IconButton>
						}
						action={
							<ActionHeader>
								<Autocomplete
									freeSolo
									id="list-search"
									options={[]}
									size="small"
									inputValue={filterText}
									disableListWrap
									onInputChange={(e: React.ChangeEvent<any>) => {
										e !== null && e.target.value !== undefined
											? setFilterText(e.target.value)
											: setFilterText('');
									}}
									renderInput={(params: any) => (
										<StyledTextField
											{...params}
											label=""
											placeholder="Buscar"
											margin="normal"
											variant="outlined"
											style={{ width: '100%' }}
											InputProps={{
												...params.InputProps,
												startAdornment: (
													<InputAdornment position="start">
														<StyledSearchIcon />
													</InputAdornment>
												),
											}}
										/>
									)}
								/>
							</ActionHeader>
						}
					/>
				)}

				<Box>
					{!isMobile && (
						<Box paddingX={'14px'} display={'flex'} justifyContent={'space-between'}>
							<Button
								color="primary"
								startIcon={<AddIcon />}
								onClick={onClickNewAppointment}
							>
								Nueva Cita
							</Button>
							<IconButton
								onClick={() => setIsCalendarSideViewOpen!(false)}
								aria-label="close"
							>
								<CloseIcon />
							</IconButton>
						</Box>
					)}

					{isMobile ? (
						<MobileCalendar
							onChange={(newValue) => setSelectedDate(newValue as moment.Moment)}
						/>
					) : (
						<DateCalendar
							showDaysOutsideCurrentMonth
							className="calendar"
							views={['month', 'year', 'day']}
							value={selectedDate}
							onChange={(newValue) => setSelectedDate(newValue)}
						/>
					)}
				</Box>
				<Divider />
				<Box
					padding={'0px 20px'}
					overflow={'auto'}
					flex={'1'}
					className="calendarTableView"
				>
					<DnDCalendar
						localizer={localizer}
						events={appointments}
						className="calendar-side-view"
						defaultView="day"
						date={selectedDate.toDate()}
						onNavigate={(date) => setSelectedDate(moment(date))}
						onSelectEvent={handleClickEvent}
						draggableAccessor={() => false}
						components={{
							toolbar: () => null,
							event: ({ event }) => <EventCard event={event} />,
						}}
						startAccessor="start"
						endAccessor="end"
						style={{ height: '100%' }}
						selectable
						onSelectSlot={handleCreateEventOnSelect}
						longPressThreshold={50}
					/>
				</Box>
			</Box>
			<ModalAppointment
				modalState={isModalOpen}
				closeModal={closeModal}
				isUpdating={isUpdating}
				initialValues={appointment}
			/>

			<AppointmentDetailPopOver
				id={id}
				open={open}
				anchorEl={anchorEl}
				handleClose={handleClosePopOver}
				appointment={appointment}
				setOpenConfirmationModal={setOpenConfirmationModal}
				handleClickEdit={handleClickEditAppointment}
			/>

			<CustomDialog
				maxWidth={'xs'}
				open={openConfirmationModal}
				handleClose={() => setOpenConfirmationModal(false)}
				title={'¿Estás seguro que quieres eliminar la siguiente cita?'}
				agreeText="Confirmar"
				disagreeText="Cancelar"
				handleAgree={handleDeleteAppointment}
			>
				{appointment.description}, {appointment.date?.format('dddd, D [de] MMMM')} ⋅{' '}
				{appointment.start} - {appointment.end}
			</CustomDialog>
		</>
	);
};
