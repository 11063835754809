import React, { memo, useCallback, useContext, useState } from 'react';
import {
	IconButton,
	InputAdornment,
	ListItemSecondaryAction,
	ListItemText,
} from '@material-ui/core';
import SearchAutocomplete from 'components/searchAutocomplete/SearchAutocomplete';
import { useClientFilter } from 'hooks/useClientFilter';
import ModalPatient from 'components/modalClient/ModalPatient';
import { StyledSearchIcon } from 'shared/style';
import FormatListNumberedRtlIcon from '@material-ui/icons/FormatListNumberedRtl';
import { useStyles } from './styles';
import { ClientContextProps, ClientType, LoaderContextProps } from 'context/types';
import { ClientsContext } from 'context/client-context';
import EditIcon from '@material-ui/icons/Edit';
import { WaitingListContextProps, WaitingListsContext } from 'context/waiting-list-context';
import { LoaderContext } from 'context/loader-context';
import { useHistory } from 'react-router-dom';

type Props = {
	callback?: () => void;
	customStyles?: React.CSSProperties;
};
const GlobalSearchPatientBar = ({ callback, customStyles }: Props) => {
	const [isDisableSentBtn, setIsDisableSentBtn] = useState(false);

	const history = useHistory<{ prevPath: '' }>();
	const { clientOptions, filterText, loadingClientOptions, setFilterText } = useClientFilter();

	const {
		getClientDataToEdit,
		setSelectedClient,
		loadClientEditModal,
		setLoadClientEditModal,
		fetchPaginatedClients,
		rowsPerPage,
		page,
		setClientsLoading: setIsLoading,
	} = useContext(ClientsContext) as ClientContextProps;
	const { showSpinner } = useContext(LoaderContext) as LoaderContextProps;
	const waitingListsContext = useContext(WaitingListsContext) as WaitingListContextProps;

	const classes = useStyles();

	const fetchClientList = useCallback(async () => {
		setIsLoading(true);
		await fetchPaginatedClients(page, rowsPerPage);
		setIsLoading(false);
	}, [rowsPerPage]);

	const handleEditClient = async (clientId: string) => {
		showSpinner(true);
		const clientInfo = await getClientDataToEdit(clientId);
		setSelectedClient(clientInfo);
		setLoadClientEditModal(true);
		showSpinner(false);
	};

	const handleSendClient = async (client: ClientType) => {
		setIsDisableSentBtn(true);
		showSpinner(true);
		await waitingListsContext.addClientToCurrentWaitingList(client);
		setIsDisableSentBtn(false);
		showSpinner(false);
	};
	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e !== null && e.target.value ? setFilterText(e.target.value) : setFilterText('');
	};
	return (
		<>
			<ModalPatient
				modalState={loadClientEditModal}
				setModalState={setLoadClientEditModal}
				isUpdate={loadClientEditModal}
				setIsUpdate={setLoadClientEditModal}
				updatePaginatedClients={fetchClientList}
			/>
			<SearchAutocomplete
				id="clientSearch"
				options={clientOptions}
				loading={loadingClientOptions}
				placeholder="Buscar por nombre, expediente y cédula"
				getOptionLabel={(option) => option.name || ''}
				filterOptions={(options, state) => options}
				inputValue={filterText}
				onInputChange={handleInputChange}
				customStyles={customStyles}
				renderOption={(option) => (
					<>
						<ListItemText
							primary={`${option.name}`}
							secondary={option.recordNo ? `Expediente: ${option.recordNo}` : null}
							data-fx-name="patientName"
							onClick={() => {
								if (callback) callback();
								history.push(`/Clients/${option!.id}`);
								setFilterText('');
							}}
						/>
						<ListItemSecondaryAction>
							<IconButton
								aria-label="edit"
								onClick={() => handleEditClient(option.id)}
							>
								<EditIcon />
							</IconButton>
							<IconButton
								edge="end"
								aria-label="share"
								disabled={isDisableSentBtn}
								onClick={() => handleSendClient(option)}
							>
								<FormatListNumberedRtlIcon />
							</IconButton>
						</ListItemSecondaryAction>
					</>
				)}
				startAdornment={
					<InputAdornment
						position="start"
						style={{
							marginRight: '8px',
						}}
					>
						<StyledSearchIcon className={classes.searchIcon} />
					</InputAdornment>
				}
			/>
		</>
	);
};

export default memo(GlobalSearchPatientBar);
