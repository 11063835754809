import React, { FC } from 'react';

interface props {
	disabled?: boolean;
	color?: string;
}

const AISuggestionIcon: FC<props> = ({ disabled = false, color = '#FFFFFF' }) => {
	if (disabled) {
		color = 'rgba(0, 0, 0, 0.26)';
	}
	return (
		<svg
			width="19"
			height="18"
			viewBox="0 0 19 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M6.25005 3.16699L8.33338 4.33366L7.16672 2.25033L8.33338 0.166992L6.25005 1.33366L4.16672 0.166992L5.33338 2.25033L4.16672 4.33366L6.25005 3.16699ZM16.25 11.3337L14.1667 10.167L15.3334 12.2503L14.1667 14.3337L16.25 13.167L18.3334 14.3337L17.1667 12.2503L18.3334 10.167L16.25 11.3337ZM18.3334 0.166992L16.25 1.33366L14.1667 0.166992L15.3334 2.25033L14.1667 4.33366L16.25 3.16699L18.3334 4.33366L17.1667 2.25033L18.3334 0.166992ZM11.975 4.57533C11.65 4.25033 11.125 4.25033 10.8 4.57533L1.07505 14.3003C0.750049 14.6253 0.750049 15.1503 1.07505 15.4753L3.02505 17.4253C3.35005 17.7503 3.87505 17.7503 4.20005 17.4253L13.9167 7.70866C14.2417 7.38366 14.2417 6.85866 13.9167 6.53366L11.975 4.57533ZM11.1167 9.15033L9.35005 7.38366L11.3834 5.35033L13.15 7.11699L11.1167 9.15033Z"
				fill={color}
			/>
		</svg>
	);
};

export default AISuggestionIcon;
