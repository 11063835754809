import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { UserContextProps } from 'context/types';
import { UserContext } from 'context/user-context';
import { decryptMessage } from 'shared/utilFunctions';

const VerifyLoginPage: FC = () => {
	const { message } = useParams<{ message: string }>();
	const { logIn } = useContext(UserContext) as UserContextProps;
	const history = useHistory();

	const [isLoggingIn, setIsLoggingIn] = useState(false);

	const startLogging = useCallback(
		async ({ user, password }: { user: string; password: string }) => {
			if (isLoggingIn) return; // Prevent multiple login attempts
			setIsLoggingIn(true);
			try {
				await logIn(user, password, (loginError) => {
					if (loginError) {
						console.error(loginError);
						history.push('/login');
					}
				});
			} catch (error) {
				console.error('Login failed:', error);
				history.push('/login');
			} finally {
				setIsLoggingIn(false);
			}
		},
		[logIn, history, isLoggingIn]
	);

	useEffect(() => {
		if (message) {
			const data = decryptMessage(message);
			if (data?.user && data?.password) {
				startLogging({ user: data.user, password: data.password });
			} else {
				history.push('/login');
			}
		}
	}, [message, history, startLogging]);

	return (
		<div>
			<h1>Verificando...</h1>
		</div>
	);
};

export default VerifyLoginPage;
