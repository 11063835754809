import { ModelHospitalDoctorClienteFilterInput, ModelIDInput, ModelStringInput } from 'API';

export const syncHospitalDoctorClientesForFetchPaginatedClientsByName = /* GraphQL */ `
	query SyncHospitalDoctorClientes(
		$filter: ModelHospitalDoctorClienteFilterInput
		$limit: Int
		$nextToken: String
		$lastSync: AWSTimestamp
	) {
		syncHospitalDoctorClientes(
			filter: $filter
			limit: $limit
			nextToken: $nextToken
			lastSync: $lastSync
		) {
			items {
				hospitalDoctorID
				clientStatus
				client {
					id
					name
					lastName
					email
					identificationData
					_version
				}
				clientInsurance {
					id
					insurance {
						id
						name
					}
					__typename
				}
				recordNo
			}
			nextToken
			startedAt
		}
	}
`;

export type ModelSecretaryHospitalDoctorClientFilterInput = {
	id?: ModelIDInput | null;
	clientID?: ModelIDInput | null;
	hospitalDoctorID?: ModelIDInput | null;
	note?: ModelStringInput | null;
	and?: Array<ModelHospitalDoctorClienteFilterInput | null> | null;
	or?: Array<ModelHospitalDoctorClienteFilterInput | null> | null;
	not?: ModelHospitalDoctorClienteFilterInput | null;
};

export type CreateSecretaryHospitalDoctorClientInput = {
	id?: string | null;
	clientID: string;
	hospitalDoctorID: string;
	note?: string | null;
	secretaryID: string;
	_version?: number | null;
	_deleted?: boolean | null;
	_lastChangedAt?: number | null;
};

export type ModelSecretaryHospitalDoctorClientConditionInput = {
	clientID?: ModelIDInput | null;
	hospitalDoctorID?: ModelIDInput | null;
	note?: ModelStringInput | null;
	secretaryID?: ModelIDInput | null;
	and?: Array<ModelSecretaryHospitalDoctorClientConditionInput | null> | null;
	or?: Array<ModelSecretaryHospitalDoctorClientConditionInput | null> | null;
	not?: ModelSecretaryHospitalDoctorClientConditionInput | null;
};

export type UpdateSecretaryHospitalDoctorClientInput = {
	id: string;
	clientID?: string | null;
	hospitalDoctorID?: string | null;
	note?: string | null;
	secretaryID?: string | null;
	_version?: number | null;
	_deleted?: boolean | null;
	_lastChangedAt?: number | null;
};

export const syncSecretaryHospitalDoctorClients = /* GraphQL */ `
	query SyncSecretaryHospitalDoctorClients(
		$filter: ModelSecretaryHospitalDoctorClientFilterInput
		$limit: Int
		$nextToken: String
		$lastSync: AWSTimestamp
	) {
		syncSecretaryHospitalDoctorClients(
			filter: $filter
			limit: $limit
			nextToken: $nextToken
			lastSync: $lastSync
		) {
			items {
				_deleted
				_lastChangedAt
				_version
				clientID
				createdAt
				id
				note
				secretaryID
				updatedAt
			}
			nextToken
			startedAt
		}
	}
`;

export const createSecretaryHospitalDoctorClient = /* GraphQL */ `
	mutation CreateSecretaryHospitalDoctorClient(
		$input: CreateSecretaryHospitalDoctorClientInput!
		$condition: ModelSecretaryHospitalDoctorClientConditionInput
	) {
		createSecretaryHospitalDoctorClient(input: $input, condition: $condition) {
			_deleted
			_lastChangedAt
			_version
			clientID
			createdAt
			id
			note
			secretaryID
			updatedAt
		}
	}
`;

export const updateSecretaryHospitalDoctorClient = /* GraphQL */ `
	mutation UpdateSecretaryHospitalDoctorClient(
		$input: UpdateSecretaryHospitalDoctorClientInput!
		$condition: ModelSecretaryHospitalDoctorClientConditionInput
	) {
		updateSecretaryHospitalDoctorClient(input: $input, condition: $condition) {
			_deleted
			_lastChangedAt
			_version
			clientID
			createdAt
			id
			note
			secretaryID
			updatedAt
		}
	}
`;

export const syncClientConsultationsByMainPage = /* GraphQL */ `
	query SyncClientConsultations(
		$filter: ModelClientConsultationFilterInput
		$limit: Int
		$nextToken: String
		$lastSync: AWSTimestamp
	) {
		syncClientConsultations(
			filter: $filter
			limit: $limit
			nextToken: $nextToken
			lastSync: $lastSync
		) {
			items {
				id
				visitReason
				createdAt
				updatedAt
			}
			nextToken
			startedAt
			__typename
		}
	}
`;

export const syncClientConsultations = /* GraphQL */ `
	query SyncClientConsultations(
		$filter: ModelClientConsultationFilterInput
		$limit: Int
		$nextToken: String
		$lastSync: AWSTimestamp
	) {
		syncClientConsultations(
			filter: $filter
			limit: $limit
			nextToken: $nextToken
			lastSync: $lastSync
		) {
			items {
				id
				clientHospitalDoctorID
				visitReason
				visitDescription
				physicTestNote
				height
				weight
				bodyMass
				bloodPressure
				heartRate
				respiratoryRate
				bodyTemperature
				oxygenSaturation
				clientMedicinePrescriptionID
				clientAnalisisPrescriptionID
				treatment
				procedureName
				procedureDescription
				createdAt
				updatedAt
				status
				_version
				_deleted
				_lastChangedAt
				__typename
			}
			nextToken
			startedAt
			__typename
		}
	}
`;
export const syncHospitalDoctorClientesPaginated = /* GraphQL */ `
	query SyncHospitalDoctorClientes(
		$filter: ModelHospitalDoctorClienteFilterInput
		$limit: Int
		$nextToken: String
		$lastSync: AWSTimestamp
	) {
		syncHospitalDoctorClientes(
			filter: $filter
			limit: $limit
			nextToken: $nextToken
			lastSync: $lastSync
		) {
			items {
				id
				client {
					id
					actualInsurance
					name
					lastName
					profileImage
					updateDateTime
					_lastChangedAt
					__typename
				}
				clientInsurance {
					id
					insurance {
						id
						name
					}
					__typename
				}
				recordNo
				createdAt
				__typename
			}
			nextToken
			startedAt
			__typename
		}
	}
`;
export const syncHospitalDoctorClientesForEditClient = /* GraphQL */ `
	query SyncHospitalDoctorClientes(
		$filter: ModelHospitalDoctorClienteFilterInput
		$limit: Int
		$nextToken: String
		$lastSync: AWSTimestamp
	) {
		syncHospitalDoctorClientes(
			filter: $filter
			limit: $limit
			nextToken: $nextToken
			lastSync: $lastSync
		) {
			items {
				id
				lastHealthInsurranceID
				clientStatus
				client {
					id
					identificationName
					identificationData
					actualInsurance
					name
					lastName
					cellphoneNumber
					email
					bornDate
					parentOrTutorCedula
					parentOrTutorID
					gender
					phoneNumber
					addressStreet
					city
					isAdult
					sector
					bloodType
					company
					occupation
					profileImage
					code
					__typename
				}
				clientInsurance {
					id
					insuranceID
					contractNumber
					affiliateNumber
					affiliateType
					insurance {
						id
						allowClaim
						name
						code
						__typename
					}
					__typename
				}
				__typename
			}
			nextToken
			startedAt
			__typename
		}
	}
`;
export const syncHospitalDoctorClientesByIdIdentification = /* GraphQL */ `
	query SyncHospitalDoctorClientes(
		$filter: ModelHospitalDoctorClienteFilterInput
		$limit: Int
		$nextToken: String
		$lastSync: AWSTimestamp
	) {
		syncHospitalDoctorClientes(
			filter: $filter
			limit: $limit
			nextToken: $nextToken
			lastSync: $lastSync
		) {
			items {
				id
				client {
					id
					identificationData
					__typename
				}
				__typename
			}
			nextToken
		}
	}
`;
