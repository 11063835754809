import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const CustomDialogHeader = ({
	goBack,
	title,
}: {
	goBack?: (props?: any) => void;
	title: string;
}) => {
	return (
		<div className="fullscreen-modal-header">
			<IconButton
				style={{
					padding: 0,
					height: 32,
					width: 32,
					marginRight: 8,
				}}
				onClick={() => {
					if (goBack) goBack();
				}}
			>
				<ArrowBackIcon />
			</IconButton>
			{title}
		</div>
	);
};

export default CustomDialogHeader;
