import { Box, Button } from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers';
import CustomDialog from 'components/dialog/CustomDialog';
import { useAppointments } from 'hooks/useAppointmentsViewLogic';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { AppointmentDetailPopOver } from './components/AppointmentDetailPopOver';
import { EventCard } from './components/EventCard';
import { ModalAppointment } from './components/ModalAppointment';
import { UserContext } from 'context/user-context';
import { UserContextProps, UserRoles } from 'context/types';

const localizer = momentLocalizer(moment);

export const AppointmentsCalendar = () => {
	const [selectedDate, setSelectedDate] = useState(moment());
	const { doctorMemberSelected } = useContext(UserContext) as UserContextProps;
	const {
		appointment,
		appointments,
		anchorEl,
		openConfirmationModal,
		setOpenConfirmationModal,
		isModalOpen,
		isUpdating,
		closeModal,
		fetchDoctorAppointments,
		handleClickEvent,
		handleClickEditAppointment,
		onClickNewAppointment,
		handleClosePopOver,
		handleDeleteAppointment,
		handleCreateEventOnSelect,
	} = useAppointments({ selectedDate });

	useEffect(() => {
		setTimeout(() => {
			const targetElement = document.querySelector<HTMLDivElement>(
				'.rbc-current-time-indicator'
			);
			if (targetElement) {
				targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
			}
		}, 400);
	}, []);

	useEffect(() => {
		if (!isModalOpen) {
			fetchDoctorAppointments(doctorMemberSelected?.doctorID);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedDate, isModalOpen, doctorMemberSelected]);

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	return (
		<Box display={'flex'} width={'100%'}>
			<Box paddingTop={'50px'}>
				<Button
					color="primary"
					variant="contained"
					sx={{ marginLeft: '20px' }}
					onClick={onClickNewAppointment}
				>
					Nueva Cita
				</Button>
				<DateCalendar
					showDaysOutsideCurrentMonth
					className="calendar"
					views={['month', 'year', 'day']}
					value={selectedDate}
					onChange={(newValue) => setSelectedDate(newValue)}
				/>
			</Box>
			<Box
				padding={'28px 0px 0px'}
				overflow={'auto'}
				flex={'1'}
				width={'100%'}
				sx={{
					background: '#fff',
					borderLeft: '1px solid #D0D0D0',
				}}
			>
				<Calendar
					localizer={localizer}
					events={appointments}
					className="calendar-week-view"
					defaultView="week"
					date={selectedDate.toDate()}
					onNavigate={(date) => setSelectedDate(moment(date))}
					onSelectEvent={handleClickEvent}
					components={{
						toolbar: () => null,
						event: ({ event }) => <EventCard event={event} />,
					}}
					startAccessor="start"
					endAccessor="end"
					style={{ height: '100%' }}
					selectable
					onSelectSlot={handleCreateEventOnSelect}
				/>
			</Box>

			<AppointmentDetailPopOver
				id={id}
				open={open}
				anchorEl={anchorEl}
				handleClose={handleClosePopOver}
				appointment={appointment}
				setOpenConfirmationModal={setOpenConfirmationModal}
				handleClickEdit={handleClickEditAppointment}
			/>

			<ModalAppointment
				modalState={isModalOpen}
				closeModal={closeModal}
				isUpdating={isUpdating}
				initialValues={appointment}
			/>

			<CustomDialog
				maxWidth={'xs'}
				open={openConfirmationModal}
				handleClose={() => setOpenConfirmationModal(false)}
				title={'¿Estás seguro que quieres eliminar la siguiente cita?'}
				agreeText="Confirmar"
				disagreeText="Cancelar"
				handleAgree={handleDeleteAppointment}
			>
				{appointment.description}, {appointment.date?.format('dddd, D [de] MMMM')} ⋅{' '}
				{appointment.start} - {appointment.end}
			</CustomDialog>
		</Box>
	);
};
