import { Button } from '@material-ui/core';
import { ClientsContext } from 'context/client-context';
import moment from 'moment';
import PrescriptionPrintLayout from 'printLayout/Prescription';
import { memo, useContext, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { LabImages } from 'shared/type';
import PrintIcon from '@material-ui/icons/Print';

const PrescriptionPrint = ({ prescription, handler }: { prescription: any; handler: any }) => {
	const { selectedClient } = useContext(ClientsContext);
	const prescriptionPrintRef = useRef(null);
	return (
		<>
			<ReactToPrint
				removeAfterPrint
				trigger={() => (
					<Button
						type="button"
						color="primary"
						startIcon={<PrintIcon />}
						onClick={() => handler(prescription)}
					>
						Imprimir
					</Button>
				)}
				content={() => prescriptionPrintRef.current}
			></ReactToPrint>
			<div ref={prescriptionPrintRef} className="printLayout">
				<PrescriptionPrintLayout
					client={selectedClient!}
					prescription={{
						creationDate: moment(new Date()).format('DD/MM/YYYY'),
						medicines: prescription,
						analisis: [] as LabImages[],
					}}
				/>
			</div>
		</>
	);
};
export default memo(PrescriptionPrint);
