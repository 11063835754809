import { Divider } from '@material-ui/core';
import React, { FC } from 'react';
import {
	ParagraphTypography,
	BackButton,
	PaymentRenewHeader,
	TitlePaymentModalTypography,
	ButtonPaymentModal,
	PaymentActionContainer,
	PaymentStyledDialog,
	NewDialogContent,
} from '../../shared/style';
import Cards from '../payment/Cards';
import LoadingSpinner from '../loading/LoadingSpinner';

interface Props {
	price: string;
	open: boolean;
	selectedPlan: number;
	handleClose: (
		event: React.MouseEvent<HTMLElement> | Record<string, unknown> | undefined
	) => void;
	handlePay: () => void;
	updateSubscriptionStatus: boolean;
	members?: number;
	disabled: boolean;
}

const TotalStyle = {
	fontWeight: '700',
	fontSize: '1rem',
	lineHeight: '24px',
	letterSpacing: '0.15px',
	width: '100%',
	marginBottom: '0',
};

const ModalPaymentSubscription: FC<Props> = ({
	price,
	open,
	selectedPlan,
	handleClose,
	handlePay,
	updateSubscriptionStatus,
	members,
	disabled,
}) => {
	return (
		<PaymentStyledDialog
			open={open}
			aria-labelledby="renew-dialog-title"
			aria-describedby="renew-dialog-description"
		>
			<NewDialogContent>
				<PaymentRenewHeader>
					<div>
						<TitlePaymentModalTypography variant="h5">
							Agrega método de pago
						</TitlePaymentModalTypography>
					</div>
					<Cards planId={selectedPlan} />
				</PaymentRenewHeader>
				<Divider />
				<div
					style={{
						marginTop: '1rem',
						display: 'grid',
						gridTemplateColumns: '1fr 2fr',
						justifyContent: 'space-between',
					}}
				>
					<ParagraphTypography style={TotalStyle}>Total:</ParagraphTypography>
					<ParagraphTypography style={{ ...TotalStyle, textAlign: 'right' }}>
						RD$ {price} pesos. {members ? `(${members} usuarios)` : ''}
					</ParagraphTypography>
				</div>
				<PaymentActionContainer>
					<ButtonPaymentModal
						onClick={handlePay}
						variant="contained"
						color="primary"
						disabled={updateSubscriptionStatus || disabled}
					>
						{updateSubscriptionStatus ? <LoadingSpinner /> : 'Pagar ahora 2/2'}
					</ButtonPaymentModal>
					<BackButton color="primary" onClick={handleClose}>
						terminar luego y desloguearme
					</BackButton>
				</PaymentActionContainer>
			</NewDialogContent>
		</PaymentStyledDialog>
	);
};
export default ModalPaymentSubscription;
