import styled from 'styled-components';
import { Button, createTheme, Grid, Typography } from '@material-ui/core';
import { BREAKPOINTS } from 'shared/style';

const theme = createTheme();

export const FormSection = styled(Grid)`
	padding: ${theme.spacing(2, 0)};
	width: 100%;
` as typeof Grid;
export const DescriptionContainer = styled(Grid)`
	margin-bottom: 8px !important;
	@media (min-width: ${BREAKPOINTS.md}px) {
		margin-top: 36px !important;
	}
	font-size: 16px;
	padding: 0 0 0 16px !important;
` as typeof Grid;
export const CardContainer = styled(Grid)`
	margin-bottom: ${theme.spacing(3)}px;
` as typeof Grid;

export const AIContainerTitle = styled(Typography)`
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 160%;
	/* identical to box height, or 32px */

	letter-spacing: 0.15px;

	/* Light/Text/Primary */

	color: rgba(0, 0, 0, 0.87);
`;
export const AIContainerActionHowItWorksBtn = styled(Button)`
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	/* identical to box height, or 171% */

	text-align: right;
	letter-spacing: 0.4px;
	text-transform: uppercase;
	color: #1976d2 !important;
`;

export const ConsultationContainer = styled(Grid)`
	padding: 0 16px 16px 16px !important;
`;

export const AIContainerActionGenerateSuggestionsBtn = styled(Button)`
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	/* identical to box height, or 171% */

	text-align: right;
	letter-spacing: 0.4px;
	text-transform: uppercase;

	/* Light/Primary/Contrast */

	color: #ffffff;
	background: #1976d2 !important;
	/* Elevation/2 */

	box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14),
		0px 1px 5px rgba(0, 0, 0, 0.12);
	border-radius: 4px;

	&:disabled {
		background: rgba(0, 0, 0, 0.12) !important;
		border-radius: 4px;
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		/* identical to box height, or 171% */

		text-align: right;
		letter-spacing: 0.4px;
		text-transform: uppercase;

		/* Light/Action/Disabled (26p) */

		color: rgba(0, 0, 0, 0.26) !important;
	}
`;
export const AISuggestionCardText = styled(Typography)`
	text-wrap: wrap;
	width: 250px;
	@media (min-width: ${BREAKPOINTS.md}px) {
		width: 444px;
	}
`;
