import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core';
import { Card, CardHeader, CardActions, Divider } from '@material-ui/core';

interface Props {
	title?: string;
	headerAction?: React.ReactNode;
	headerBody?: React.ReactNode;
	children: React.ReactNode;
	action?: React.ReactNode;
	height?: string;
}

const theme = createTheme();
const StyledCardHeader = withStyles({
	root: {
		padding: theme.spacing(2, 2),
	},
	title: {
		fontSize: theme.spacing(2.5),
		fontWeight: 500,
	},
	action: {
		marginTop: 0,
		marginRight: 0,
	},
})(CardHeader) as typeof CardHeader;

const StyledCardContent = withStyles({
	root: {
		padding: theme.spacing(0, 2, 0, 0),
	},
})(Card) as typeof Card;

const PrescriptionCard: React.FC<Props> = ({
	title,
	headerAction,
	headerBody,
	children,
	action,
	height,
}) => {
	return (
		<Card>
			{title && (
				<StyledCardHeader title={title} action={headerAction}>
					{headerBody}
				</StyledCardHeader>
			)}
			<Divider light />
			<StyledCardContent style={{ height: height }}>{children}</StyledCardContent>
			{action && <CardActions>{action}</CardActions>}
		</Card>
	);
};

export default PrescriptionCard;
