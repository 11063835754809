import { Grid } from '@material-ui/core';
import React, { FC } from 'react';
import CustomDialog from '../dialog/CustomDialog';
import { InputContainer } from './style';
import { MedicineType } from '../../context/types';
import SearchAutocomplete from '../searchAutocomplete/SearchAutocomplete';
import { SearchListItemText } from '../../shared/style';
import { MedicineOption } from '../../shared/type';
import useMobile from 'hooks/useMobile';
import MobileDialogHeader from '../dialog/MobileDialogHeader';

interface Props {
	open: boolean;
	isUpdate: boolean;
	title?: string;
	isDisabled?: boolean;
	handleClose: (event: any) => void;
	disabledInput?: boolean;
	searchMedicines?: MedicineType[];
	handleAgree?: (medicine: MedicineOption) => void;
	medicine: MedicineOption;
	handleChangename?: (value: string) => void;
	handleClickMedicine?: (value: MedicineType) => void;
}

const MedicineDialog: FC<Props> = ({
	isUpdate = false,
	title,
	isDisabled,
	open,
	handleClose,
	searchMedicines,
	disabledInput,
	handleAgree,
	medicine,
	handleChangename,
	handleClickMedicine,
}) => {
	const { isMobile } = useMobile();
	const dialogTitle = title ? title : isUpdate ? 'Editar Medicamento' : 'Nuevo Medicamento';

	return (
		<CustomDialog
			title={
				isMobile ? (
					<MobileDialogHeader title={dialogTitle} goBack={handleClose} />
				) : (
					dialogTitle
				)
			}
			disableBackdropClick={true}
			open={open}
			maxWidth={'sm'}
			disagreeText={'Cancelar'}
			agreeText={'Guardar'}
			fullWidth
			disabled={isDisabled}
			fullScreen={isMobile}
			handleClose={handleClose}
			className="fullscreen-modal"
			handleAgree={() => handleAgree?.(medicine)}
		>
			<Grid container style={isMobile ? { marginTop: '32px' } : {}}>
				<InputContainer item xs={12}>
					<SearchAutocomplete
						id="autocomplete-search"
						inputValue={medicine.medicineName}
						options={searchMedicines || []}
						label="Nombre del medicamento"
						disabled={disabledInput}
						placeholder="Celeco 400mg"
						onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							e != null && handleChangename?.(e.target.value);
						}}
						getOptionLabel={(option) => option.name || ''}
						renderOption={(option) => (
							<SearchListItemText
								onClick={() => handleClickMedicine?.(option)}
								primary={option.name || ''}
							/>
						)}
						InputLabelProps={{ shrink: true }}
					/>
				</InputContainer>
			</Grid>
		</CustomDialog>
	);
};

export default MedicineDialog;
