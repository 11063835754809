import { PatientData } from '../types';

export const consultationSuggestion = (data: PatientData) => {
	return `
Eres un medico consultor experto,
Analiza los siguientes datos del paciente:

Datos del paciente:

${data.bornDate ? 'nacimiento: ' + data.bornDate : '\r'}
${data.gender ? 'genero: ' + data.gender : '\r'}
${data.visitReason ? 'Razon de la visita: ' + data.visitReason : '\r'}
${data.visitReasonDescription ? 'descripcion de la visita: ' + data.visitReasonDescription : '\r'}
${data.physicTestNote ? 'Resultado de examen fisico: ' + data.physicTestNote : '\r'}
${data.bloodPressure ? 'tension arterial: ' + data.bloodPressure : '\r'}
${data.heartRate ? 'frecuencia cardiaca: ' + data.heartRate : '\r'}
${data.respiratoryRate ? 'frecuencia respiratoria: ' + data.respiratoryRate : '\r'}
${data.bodyTemperature ? 'temperatura corporal: ' + data.bodyTemperature : '\r'}
${data.oxygenSaturation ? 'saturacion de oxigeno: ' + data.oxygenSaturation : '\r'}
${data.weight ? 'peso: ' + data.weight : '\r'}
${data.height ? 'altura: ' + data.height : '\r'}
${data.bodyMass ? 'indice de masa corporal: ' + data.bodyMass : '\r'}
${data.alergias ? 'Alergias: ' + data.alergias : '\r'}
${data.antecFamiliares ? 'Antecedentes familiares: ' + data.antecFamiliares : '\r'}
${data.antecPatologico ? 'Antecedentes no Patológicos: ' + data.antecPatologico : '\r'}
${data.enfermActiva ? 'Enfermedades activas: ' + data.enfermActiva : '\r'}
${data.enfermInactivas ? 'Enfermedades inactivas: ' + data.enfermInactivas : '\r'}
${data.hospitalPrevias ? 'Hospitalizaciones previas: ' + data.hospitalPrevias : '\r'}
${data.estiloDeVida ? 'Estilo de vida: ' + data.estiloDeVida : '\r'}

Tomando en cuenta el analisis de los datos anteriores, provee la siguiente información:

- 5 posibles diagnósticos médicos y descripcion de porque este diagnostico es sugerido y estas descripciones no deben ser repetitivas
- 5 sugerencias de estudios médicos usando el catalogo CPT para el paciente y descripcion de para que este estudio es sugerido
- 4 sugerencias de medicamentos y descripcion de porque este medicamento es sugerido
- 5 sugerencias de tratamientos y Descripción corta de porque y como ayudará al paciente

Toda esta informacion retornala en formato JSON sin ningun texto adicional fuera de este.

Toma el siguiente objeto como un ejemplo de lo que quiero que me devuelvas.

{
  "diagnosticos": [{
    "nombre": "Migraña",
    "descripcion": "Debido a los síntomas de dolor de cabeza persistentes en la región frontal, se puede determinar que el paciente sufre de migraña."
  }],
"estudios": [{
    "nombre": "",
    "descripcion": ""}],
"medicamentos": [{
"nombre": "Ibuprofeno",
    "medida": "400mg",
    "tomar": "cada 6-8 horas",
    "descripcion": "",
    "duracion": "No más de 10 días",
    "posologia": "Tomar con comida y un vaso de agua" }],
"tratamientos": [{
    "nombre": "",
    "descripcion": ""}]
}

Dame el JSON dentro de un objeto sin asignarlo a ninguna variable. Asegúrate de colocar las comas y comillas dobles en los lugares correctos para evitar errores de sintaxis en el JSON que retornaràs 
`;
};
