/* eslint-disable no-useless-escape */
const diagnossis = [
	{
		id: 1,
		code: 'A00',
		label: 'Cólera',
	},
	{
		id: 2,
		code: 'A000',
		label: 'Cólera debido a Vibrio cholerae 01, biotipo cholerae',
	},
	{
		id: 3,
		code: 'A001',
		label: 'Cólera debido a Vibrio cholerae 01, biotipo el Tor',
	},
	{
		id: 4,
		code: 'A009',
		label: 'Cólera, no especificado',
	},
	{
		id: 5,
		code: 'A01',
		label: 'Fiebres tifoidea y paratifoidea',
	},
	{
		id: 6,
		code: 'A010',
		label: 'Fiebre tifoidea',
	},
	{
		id: 7,
		code: 'A011',
		label: 'Fiebre paratifoidea A',
	},
	{
		id: 8,
		code: 'A012',
		label: 'Fiebre paratifoidea B',
	},
	{
		id: 9,
		code: 'A013',
		label: 'Fiebre paratifoidea C',
	},
	{
		id: 10,
		code: 'A014',
		label: 'Fiebre paratifoidea, no especificada',
	},
	{
		id: 11,
		code: 'A02',
		label: 'Otras infecciones debidas a Salmonella',
	},
	{
		id: 12,
		code: 'A020',
		label: 'Enteritis debida a Salmonella',
	},
	{
		id: 13,
		code: 'A021',
		label: 'Sepsis debida a Salmonella',
	},
	{
		id: 14,
		code: 'A022',
		label: 'Infecciones localizadas debidas a Salmonella',
	},
	{
		id: 15,
		code: 'A028',
		label: 'Otras infecciones especificadas como debidas a Salmonella',
	},
	{
		id: 16,
		code: 'A029',
		label: 'Infección debida a Salmonella, no especificada',
	},
	{
		id: 17,
		code: 'A03',
		label: 'Shigelosis',
	},
	{
		id: 18,
		code: 'A030',
		label: 'Shigelosis debida a Shigella dysenteriae',
	},
	{
		id: 19,
		code: 'A031',
		label: 'Shigelosis debida a Shigella flexneri',
	},
	{
		id: 20,
		code: 'A032',
		label: 'Shigelosis debida a Shigella boydii',
	},
	{
		id: 21,
		code: 'A033',
		label: 'Shigelosis debida a Shigella sonnei',
	},
	{
		id: 22,
		code: 'A038',
		label: 'Otras shigelosis',
	},
	{
		id: 23,
		code: 'A039',
		label: 'Shigelosis de tipo no especificado',
	},
	{
		id: 24,
		code: 'A04',
		label: 'Otras infecciones intestinales bacterianas',
	},
	{
		id: 25,
		code: 'A040',
		label: 'Infección debida a Escherichia coli enteropatógena',
	},
	{
		id: 26,
		code: 'A041',
		label: 'Infección debida a Escherichia coli enterotoxígena',
	},
	{
		id: 27,
		code: 'A042',
		label: 'Infección debida a Escherichia coli enteroinvasiva',
	},
	{
		id: 28,
		code: 'A043',
		label: 'Infección debida a Escherichia coli enterohemorrágica',
	},
	{
		id: 29,
		code: 'A044',
		label: 'Otras infecciones intestinales debidas a Escherichia coli',
	},
	{
		id: 30,
		code: 'A045',
		label: 'Enteritis debida a Campylobacter',
	},
	{
		id: 31,
		code: 'A046',
		label: 'Enteritis debida a Yersinia enterocolitica',
	},
	{
		id: 32,
		code: 'A047',
		label: 'Enterocolitis debida a Clostridium difficile',
	},
	{
		id: 33,
		code: 'A048',
		label: 'Otras infecciones intestinales bacterianas especificadas',
	},
	{
		id: 34,
		code: 'A049',
		label: 'Infección intestinal bacteriana, no especificada',
	},
	{
		id: 35,
		code: 'A05',
		label: 'Otras intoxicaciones alimentarias bacterianas, no clasificadas en otra parte',
	},
	{
		id: 36,
		code: 'A050',
		label: 'Intoxicación alimentaria estafilocócica',
	},
	{
		id: 37,
		code: 'A051',
		label: 'Botulismo',
	},
	{
		id: 38,
		code: 'A052',
		label: 'Intoxicación alimentaria debida a Clostridium perfringens [Clostridium welchii]',
	},
	{
		id: 39,
		code: 'A053',
		label: 'Intoxicación alimentaria debida a Vibrio parahaemolyticus',
	},
	{
		id: 40,
		code: 'A054',
		label: 'Intoxicación alimentaria debida a Bacillus cereus',
	},
	{
		id: 41,
		code: 'A058',
		label: 'Otras intoxicaciones alimentarias debidas a bacterias especificadas',
	},
	{
		id: 42,
		code: 'A059',
		label: 'Intoxicación alimentaria bacteriana, no especificada',
	},
	{
		id: 43,
		code: 'A06',
		label: 'Amebiasis',
	},
	{
		id: 44,
		code: 'A060',
		label: 'Disentería amebiana aguda',
	},
	{
		id: 45,
		code: 'A061',
		label: 'Amebiasis intestinal crónica',
	},
	{
		id: 46,
		code: 'A062',
		label: 'Colitis amebiana no disentérica',
	},
	{
		id: 47,
		code: 'A063',
		label: 'Ameboma intestinal',
	},
	{
		id: 48,
		code: 'A064',
		label: 'Absceso amebiano del hígado',
	},
	{
		id: 49,
		code: 'A065',
		label: 'Absceso amebiano del pulmón',
	},
	{
		id: 50,
		code: 'A066',
		label: 'Absceso amebiano del cerebro',
	},
	{
		id: 51,
		code: 'A067',
		label: 'Amebiasis cutánea',
	},
	{
		id: 52,
		code: 'A068',
		label: 'Infección amebiana de otras localizaciones',
	},
	{
		id: 53,
		code: 'A069',
		label: 'Amebiasis, no especificada',
	},
	{
		id: 54,
		code: 'A07',
		label: 'Otras enfermedades intestinales debidas a protozoarios',
	},
	{
		id: 55,
		code: 'A070',
		label: 'Balantidiasis',
	},
	{
		id: 56,
		code: 'A071',
		label: 'Giardiasis [lambliasis]',
	},
	{
		id: 57,
		code: 'A072',
		label: 'Criptosporidiosis',
	},
	{
		id: 58,
		code: 'A073',
		label: 'Isosporiasis',
	},
	{
		id: 59,
		code: 'A078',
		label: 'Otras enfermedades intestinales especificadas debidas a protozoarios',
	},
	{
		id: 60,
		code: 'A079',
		label: 'Enfermedad intestinal debida a protozoarios, no especificada',
	},
	{
		id: 61,
		code: 'A08',
		label: 'Infecciones intestinales debidas a virus y otros organismos especificados',
	},
	{
		id: 62,
		code: 'A080',
		label: 'Enteritis debida a rotavirus',
	},
	{
		id: 63,
		code: 'A081',
		label: 'Gastroenteropatía aguda debida al agente de Norwalk',
	},
	{
		id: 64,
		code: 'A082',
		label: 'Enteritis debida a adenovirus',
	},
	{
		id: 65,
		code: 'A083',
		label: 'Otras enteritis virales',
	},
	{
		id: 66,
		code: 'A084',
		label: 'Infección intestinal viral, sin otra especificación',
	},
	{
		id: 67,
		code: 'A085',
		label: 'Otras infecciones intestinales especificadas',
	},
	{
		id: 68,
		code: 'A09',
		label: 'Otras gastroenteritis y colitis de origen infeccioso y no especificado',
	},
	{
		id: 69,
		code: 'A090',
		label: 'Otras gastroenteritis y colitis de origen infeccioso ',
	},
	{
		id: 70,
		code: 'A099',
		label: 'Gastroenteritis y colitis de origen no especificado ',
	},
	{
		id: 71,
		code: 'A09X',
		label: 'Diarrea y gastroenteritis de presunto origen infeccioso',
	},
	{
		id: 72,
		code: 'A15',
		label: 'Tuberculosis respiratoria, confirmada bacteriológica e histológicamente',
	},
	{
		id: 73,
		code: 'A150',
		label: 'Tuberculosis del pulmón, confirmada por hallazgo microscópico del bacilo tuberculoso en esputo, con o sin cultivo',
	},
	{
		id: 74,
		code: 'A151',
		label: 'Tuberculosis del pulmón, confirmada únicamente por cultivo',
	},
	{
		id: 75,
		code: 'A152',
		label: 'Tuberculosis del pulmón, confirmada histológicamente',
	},
	{
		id: 76,
		code: 'A153',
		label: 'Tuberculosis del pulmón, confirmada por medios no especificados',
	},
	{
		id: 77,
		code: 'A154',
		label: 'Tuberculosis de ganglios linfáticos intratorácicos, confirmada bacteriológica e histológicamente',
	},
	{
		id: 78,
		code: 'A155',
		label: 'Tuberculosis de laringe, traquea y bronquios, confirmada bacteriológica e histológicamente',
	},
	{
		id: 79,
		code: 'A156',
		label: 'Pleuresía tuberculosa, confirmada bacteriológica e histológicamente',
	},
	{
		id: 80,
		code: 'A157',
		label: 'Tuberculosis respiratoria primaria, confirmada bacteriológica e histológicamente',
	},
	{
		id: 81,
		code: 'A158',
		label: 'Otras tuberculosis respiratorias, confirmadas bacteriológica e histológicamente',
	},
	{
		id: 82,
		code: 'A159',
		label: 'Tuberculosis respiratoria no especificada, confirmada bacteriológica e histológicamente',
	},
	{
		id: 83,
		code: 'A16',
		label: 'Tuberculosis respiratoria, no confirmada bacteriológica o histológicamente',
	},
	{
		id: 84,
		code: 'A160',
		label: 'Tuberculosis del pulmón, con examen bacteriológico e histológico negativos',
	},
	{
		id: 85,
		code: 'A161',
		label: 'Tuberculosis de pulmón, sin examen bacteriológico e histológico',
	},
	{
		id: 86,
		code: 'A162',
		label: 'Tuberculosis de pulmón, sin mención de confirmación bacteriológica o histológica',
	},
	{
		id: 87,
		code: 'A163',
		label: 'Tuberculosis de ganglios linfáticos intratorácicos, sin mención de confirmación bacteriológica o histológica',
	},
	{
		id: 88,
		code: 'A164',
		label: 'Tuberculosis de laringe, traquea y bronquios, sin mención de confirmación bacteriológica o histológica',
	},
	{
		id: 89,
		code: 'A165',
		label: 'Pleuresía tuberculosa, sin mención de confirmación bacteriológica o histológica',
	},
	{
		id: 90,
		code: 'A167',
		label: 'Tuberculosis respiratoria primaria, sin mención de confirmación bacteriológica o histológica',
	},
	{
		id: 91,
		code: 'A168',
		label: 'Otras tuberculosis respiratorias, sin mención de confirmación',
	},
	{
		id: 92,
		code: 'A169',
		label: 'Tuberculosis respiratoria no especificada, sin mención de confirmación bacteriológica o histológica',
	},
	{
		id: 93,
		code: 'A17',
		label: 'Tuberculosis del sistema nervioso',
	},
	{
		id: 94,
		code: 'A170',
		label: 'Meningitis tuberculosa',
	},
	{
		id: 95,
		code: 'A171',
		label: 'Tuberculoma meníngeo',
	},
	{
		id: 96,
		code: 'A178',
		label: 'Otras tuberculosis del sistema nervioso',
	},
	{
		id: 97,
		code: 'A179',
		label: 'Tuberculosis del sistema nervioso, no especificada',
	},
	{
		id: 98,
		code: 'A18',
		label: 'Tuberculosis de otros órganos',
	},
	{
		id: 99,
		code: 'A180',
		label: 'Tuberculosis de huesos y articulaciones',
	},
	{
		id: 100,
		code: 'A181',
		label: 'Tuberculosis del aparato genitourinario',
	},
	{
		id: 101,
		code: 'A182',
		label: 'Linfadenopatía periférica tuberculosa',
	},
	{
		id: 102,
		code: 'A183',
		label: 'Tuberculosis de los intestinos, el peritoneo y los ganglios mesentéricos',
	},
	{
		id: 103,
		code: 'A184',
		label: 'Tuberculosis de la piel y el tejido subcutáneo',
	},
	{
		id: 104,
		code: 'A185',
		label: 'Tuberculosis del ojo',
	},
	{
		id: 105,
		code: 'A186',
		label: 'Tuberculosis del oído',
	},
	{
		id: 106,
		code: 'A187',
		label: 'Tuberculosis de glándulas suprarrenales',
	},
	{
		id: 107,
		code: 'A188',
		label: 'Tuberculosis de otros órganos especificados',
	},
	{
		id: 108,
		code: 'A19',
		label: 'Tuberculosis miliar',
	},
	{
		id: 109,
		code: 'A190',
		label: 'Tuberculosis miliar aguda de un solo sitio especificado',
	},
	{
		id: 110,
		code: 'A191',
		label: 'Tuberculosis miliar aguda de sitios múltiples',
	},
	{
		id: 111,
		code: 'A192',
		label: 'Tuberculosis miliar aguda, no especificada',
	},
	{
		id: 112,
		code: 'A198',
		label: 'Otras tuberculosis miliares',
	},
	{
		id: 113,
		code: 'A199',
		label: 'Tuberculosis miliar, sin otra especificación',
	},
	{
		id: 114,
		code: 'A20',
		label: 'Peste',
	},
	{
		id: 115,
		code: 'A200',
		label: 'Peste bubónica',
	},
	{
		id: 116,
		code: 'A201',
		label: 'Peste celulocutánea',
	},
	{
		id: 117,
		code: 'A202',
		label: 'Peste neumónica',
	},
	{
		id: 118,
		code: 'A203',
		label: 'Meningitis por peste',
	},
	{
		id: 119,
		code: 'A207',
		label: 'Peste septicémica',
	},
	{
		id: 120,
		code: 'A208',
		label: 'Otras formas de peste',
	},
	{
		id: 121,
		code: 'A209',
		label: 'Peste, no especificada',
	},
	{
		id: 122,
		code: 'A21',
		label: 'Tularemia',
	},
	{
		id: 123,
		code: 'A210',
		label: 'Tularemia ulceroglandular',
	},
	{
		id: 124,
		code: 'A211',
		label: 'Tularemia oculoglandular',
	},
	{
		id: 125,
		code: 'A212',
		label: 'Tularemia pulmonar',
	},
	{
		id: 126,
		code: 'A213',
		label: 'Tularemia gastrointestinal',
	},
	{
		id: 127,
		code: 'A217',
		label: 'Tularemia generalizada',
	},
	{
		id: 128,
		code: 'A218',
		label: 'Otras formas de tularemia',
	},
	{
		id: 129,
		code: 'A219',
		label: 'Tularemia, no especificada',
	},
	{
		id: 130,
		code: 'A22',
		label: 'Carbunco [ántrax]',
	},
	{
		id: 131,
		code: 'A220',
		label: 'Carbunco cutáneo',
	},
	{
		id: 132,
		code: 'A221',
		label: 'Carbunco pulmonar',
	},
	{
		id: 133,
		code: 'A222',
		label: 'Carbunco gastrointestinal',
	},
	{
		id: 134,
		code: 'A227',
		label: 'Carbunco séptico',
	},
	{
		id: 135,
		code: 'A228',
		label: 'Otras formas de carbunco',
	},
	{
		id: 136,
		code: 'A229',
		label: 'Carbunco, no especificado',
	},
	{
		id: 137,
		code: 'A23',
		label: 'Brucelosis',
	},
	{
		id: 138,
		code: 'A230',
		label: 'Brucelosis debida a Brucella melitensis',
	},
	{
		id: 139,
		code: 'A231',
		label: 'Brucelosis debida a Brucella abortus',
	},
	{
		id: 140,
		code: 'A232',
		label: 'Brucelosis debida a Brucella suis',
	},
	{
		id: 141,
		code: 'A233',
		label: 'Brucelosis debida a Brucella canis',
	},
	{
		id: 142,
		code: 'A238',
		label: 'Otras brucelosis',
	},
	{
		id: 143,
		code: 'A239',
		label: 'Brucelosis, no especificada',
	},
	{
		id: 144,
		code: 'A24',
		label: 'Muermo y melioidosis',
	},
	{
		id: 145,
		code: 'A240',
		label: 'Muermo',
	},
	{
		id: 146,
		code: 'A241',
		label: 'Melioidosis aguda y fulminante',
	},
	{
		id: 147,
		code: 'A242',
		label: 'Melioidosis subaguda y crónica',
	},
	{
		id: 148,
		code: 'A243',
		label: 'Otras melioidosis',
	},
	{
		id: 149,
		code: 'A244',
		label: 'Melioidosis, no especificada',
	},
	{
		id: 150,
		code: 'A25',
		label: 'Fiebre por mordedura de rata',
	},
	{
		id: 151,
		code: 'A250',
		label: 'Espirilosis',
	},
	{
		id: 152,
		code: 'A251',
		label: 'Estreptobacilosis',
	},
	{
		id: 153,
		code: 'A259',
		label: 'Fiebre por mordedura de rata, no especificada',
	},
	{
		id: 154,
		code: 'A26',
		label: 'Erisipeloide',
	},
	{
		id: 155,
		code: 'A260',
		label: 'Erisipeloide cutáneo',
	},
	{
		id: 156,
		code: 'A267',
		label: 'Sepsis por Erysipelothrix',
	},
	{
		id: 157,
		code: 'A268',
		label: 'Otras formas de erisipeloide',
	},
	{
		id: 158,
		code: 'A269',
		label: 'Erisipeloide, no especificada',
	},
	{
		id: 159,
		code: 'A27',
		label: 'Leptospirosis',
	},
	{
		id: 160,
		code: 'A270',
		label: 'Leptospirosis icterohemorrágica',
	},
	{
		id: 161,
		code: 'A278',
		label: 'Otras formas de leptospirosis',
	},
	{
		id: 162,
		code: 'A279',
		label: 'Leptospirosis, no especificada',
	},
	{
		id: 163,
		code: 'A28',
		label: 'Otras enfermedades zoonóticas bacterianas, no clasificadas en otra parte',
	},
	{
		id: 164,
		code: 'A280',
		label: 'Pasteurelosis',
	},
	{
		id: 165,
		code: 'A281',
		label: 'Enfermedad por rasguño de gato',
	},
	{
		id: 166,
		code: 'A282',
		label: 'Yersiniosis extraintestinal',
	},
	{
		id: 167,
		code: 'A288',
		label: 'Otras enfermedades zoonóticas bacterianas especificadas, no clasificadas en otra parte',
	},
	{
		id: 168,
		code: 'A289',
		label: 'Enfermedad zoonótica bacteriana, sin otra especificación',
	},
	{
		id: 169,
		code: 'A30',
		label: 'Lepra [enfermedad de Hansen]',
	},
	{
		id: 170,
		code: 'A300',
		label: 'Lepra indeterminada',
	},
	{
		id: 171,
		code: 'A301',
		label: 'Lepra tuberculoide',
	},
	{
		id: 172,
		code: 'A302',
		label: 'Lepra tuberculoide limítrofe',
	},
	{
		id: 173,
		code: 'A303',
		label: 'Lepra limítrofe',
	},
	{
		id: 174,
		code: 'A304',
		label: 'Lepra lepromatosa limítrofe',
	},
	{
		id: 175,
		code: 'A305',
		label: 'Lepra lepromatosa',
	},
	{
		id: 176,
		code: 'A308',
		label: 'Otras formas de lepra',
	},
	{
		id: 177,
		code: 'A309',
		label: 'Lepra, no especificada',
	},
	{
		id: 178,
		code: 'A31',
		label: 'Infecciones debidas a otras micobacterias',
	},
	{
		id: 179,
		code: 'A310',
		label: 'Infecciones por micobacterias pulmonares',
	},
	{
		id: 180,
		code: 'A311',
		label: 'Infección cutánea por micobacterias',
	},
	{
		id: 181,
		code: 'A318',
		label: 'Otras infecciones por micobacterias',
	},
	{
		id: 182,
		code: 'A319',
		label: 'Infección por micobacteria, no especificada',
	},
	{
		id: 183,
		code: 'A32',
		label: 'Listeriosis',
	},
	{
		id: 184,
		code: 'A320',
		label: 'Listeriosis cutánea',
	},
	{
		id: 185,
		code: 'A321',
		label: 'Meningitis y meningoencefalitis listeriana',
	},
	{
		id: 186,
		code: 'A327',
		label: 'Sepsis listeriana',
	},
	{
		id: 187,
		code: 'A328',
		label: 'Otras formas de listeriosis',
	},
	{
		id: 188,
		code: 'A329',
		label: 'Listeriosis, no especificada',
	},
	{
		id: 189,
		code: 'A33X',
		label: 'Tétanos neonatal',
	},
	{
		id: 190,
		code: 'A34X',
		label: 'Tétanos obstétrico',
	},
	{
		id: 191,
		code: 'A35X',
		label: 'Otros tétanos',
	},
	{
		id: 192,
		code: 'A36',
		label: 'Difteria',
	},
	{
		id: 193,
		code: 'A360',
		label: 'Difteria faríngea',
	},
	{
		id: 194,
		code: 'A361',
		label: 'Difteria nasofaríngea',
	},
	{
		id: 195,
		code: 'A362',
		label: 'Difteria laríngea',
	},
	{
		id: 196,
		code: 'A363',
		label: 'Difteria cutánea',
	},
	{
		id: 197,
		code: 'A368',
		label: 'Otras difterias',
	},
	{
		id: 198,
		code: 'A369',
		label: 'Difteria, no especificada',
	},
	{
		id: 199,
		code: 'A37',
		label: 'Tos ferina [tos convulsiva]',
	},
	{
		id: 200,
		code: 'A370',
		label: 'Tos ferina debida a Bordetella pertussis',
	},
	{
		id: 201,
		code: 'A371',
		label: 'Tos ferina debida a Bordetella parapertussis',
	},
	{
		id: 202,
		code: 'A378',
		label: 'Tos ferina debida a otras especies de Bordetella',
	},
	{
		id: 203,
		code: 'A379',
		label: 'Tos ferina, no especificada',
	},
	{
		id: 204,
		code: 'A38X',
		label: 'Escarlatina',
	},
	{
		id: 205,
		code: 'A39',
		label: 'Enfermedad meningocócica',
	},
	{
		id: 206,
		code: 'A390',
		label: 'Meningitis meningocócica',
	},
	{
		id: 207,
		code: 'A391',
		label: 'Síndrome de Waterhouse-Friderichsen',
	},
	{
		id: 208,
		code: 'A392',
		label: 'Meningococemia aguda',
	},
	{
		id: 209,
		code: 'A393',
		label: 'Meningococemia crónica',
	},
	{
		id: 210,
		code: 'A394',
		label: 'Meningococemia, no especificada',
	},
	{
		id: 211,
		code: 'A395',
		label: 'Enfermedad cardíaca debida a meningococo',
	},
	{
		id: 212,
		code: 'A398',
		label: 'Otras formas de enfermedad meningocócica',
	},
	{
		id: 213,
		code: 'A399',
		label: 'Enfermedad meningocócica, no especificada',
	},
	{
		id: 214,
		code: 'A40',
		label: 'Sepsis estreptocócica',
	},
	{
		id: 215,
		code: 'A400',
		label: 'Sepsis debida a estreptococo, grupo A',
	},
	{
		id: 216,
		code: 'A401',
		label: 'Sepsis debida a estreptococo, grupo B',
	},
	{
		id: 217,
		code: 'A402',
		label: 'Sepsis debida a estreptococo, grupo D',
	},
	{
		id: 218,
		code: 'A403',
		label: 'Sepsis debida a Streptococcus pneumoniae',
	},
	{
		id: 219,
		code: 'A408',
		label: 'Otras Sepsis estreptocócicas',
	},
	{
		id: 220,
		code: 'A409',
		label: 'Sepsis estreptocócica, no especificada',
	},
	{
		id: 221,
		code: 'A41',
		label: 'Otras Sepsis',
	},
	{
		id: 222,
		code: 'A410',
		label: 'Sepsis debida a Staphylococcus aureus',
	},
	{
		id: 223,
		code: 'A411',
		label: 'Sepsis debida a otro estafilococo especificado',
	},
	{
		id: 224,
		code: 'A412',
		label: 'Sepsis debida a estafilococo no especificado',
	},
	{
		id: 225,
		code: 'A413',
		label: 'Sepsis debida a Haemophilus influenzae',
	},
	{
		id: 226,
		code: 'A414',
		label: 'Sepsis debida a anaerobios',
	},
	{
		id: 227,
		code: 'A415',
		label: 'Sepsis debida a otros organismos gramnegativos',
	},
	{
		id: 228,
		code: 'A418',
		label: 'Otras Sepsis especificadas',
	},
	{
		id: 229,
		code: 'A419',
		label: 'Sepsis, no especificada',
	},
	{
		id: 230,
		code: 'A42',
		label: 'Actinomicosis',
	},
	{
		id: 231,
		code: 'A420',
		label: 'Actinomicosis pulmonar',
	},
	{
		id: 232,
		code: 'A421',
		label: 'Actinomicosis abdominal',
	},
	{
		id: 233,
		code: 'A422',
		label: 'Actinomicosis cervicofacial',
	},
	{
		id: 234,
		code: 'A427',
		label: 'Sepsis actinomicótica',
	},
	{
		id: 235,
		code: 'A428',
		label: 'Otras formas de actinomicosis',
	},
	{
		id: 236,
		code: 'A429',
		label: 'Actinomicosis, sin otra especificación',
	},
	{
		id: 237,
		code: 'A43',
		label: 'Nocardiosis',
	},
	{
		id: 238,
		code: 'A430',
		label: 'Nocardiosis pulmonar',
	},
	{
		id: 239,
		code: 'A431',
		label: 'Nocardiosis cutánea',
	},
	{
		id: 240,
		code: 'A438',
		label: 'Otras formas de nocardiosis',
	},
	{
		id: 241,
		code: 'A439',
		label: 'Nocardiosis, no especificada',
	},
	{
		id: 242,
		code: 'A44',
		label: 'Bartonelosis',
	},
	{
		id: 243,
		code: 'A440',
		label: 'Bartonelosis sistémica',
	},
	{
		id: 244,
		code: 'A441',
		label: 'Bartonelosis cutánea y mucocutánea',
	},
	{
		id: 245,
		code: 'A448',
		label: 'Otras formas de bartonelosis',
	},
	{
		id: 246,
		code: 'A449',
		label: 'Bartonelosis, no especificada',
	},
	{
		id: 247,
		code: 'A46X',
		label: 'Erisipela',
	},
	{
		id: 248,
		code: 'A48',
		label: 'Otras enfermedades bacterianas, no clasificadas en otra parte',
	},
	{
		id: 249,
		code: 'A480',
		label: 'Gangrena gaseosa',
	},
	{
		id: 250,
		code: 'A481',
		label: 'Enfermedad de los legionarios',
	},
	{
		id: 251,
		code: 'A482',
		label: 'Enfermedad de los legionarios no neumónica (fiebre de Pontiac)',
	},
	{
		id: 252,
		code: 'A483',
		label: 'Síndrome del choque tóxico',
	},
	{
		id: 253,
		code: 'A484',
		label: 'Fiebre purpúrica brasileña',
	},
	{
		id: 254,
		code: 'A488',
		label: 'Otras enfermedades bacterianas especificadas',
	},
	{
		id: 255,
		code: 'A49',
		label: 'Infección bacteriana de sitio no especificado',
	},
	{
		id: 256,
		code: 'A490',
		label: 'Infección estafilocócica, sin otra especificación',
	},
	{
		id: 257,
		code: 'A491',
		label: 'Infección estreptocócica, sin otra especificación',
	},
	{
		id: 258,
		code: 'A492',
		label: 'Infección por Haemophilus influenzae, sin otra especificación',
	},
	{
		id: 259,
		code: 'A493',
		label: 'Infección por micoplasma, sin otra especificación',
	},
	{
		id: 260,
		code: 'A498',
		label: 'Otras infecciones bacterianas de sitio no especificado',
	},
	{
		id: 261,
		code: 'A499',
		label: 'Infección bacteriana, no especificada',
	},
	{
		id: 262,
		code: 'A50',
		label: 'Sífilis congénita',
	},
	{
		id: 263,
		code: 'A500',
		label: 'Sífilis congénita precoz, sintomática',
	},
	{
		id: 264,
		code: 'A501',
		label: 'Sífilis congénita precoz, latente',
	},
	{
		id: 265,
		code: 'A502',
		label: 'Sífilis congénita precoz, sin otra especificación',
	},
	{
		id: 266,
		code: 'A503',
		label: 'Oculopatía sifilítica congénita tardía',
	},
	{
		id: 267,
		code: 'A504',
		label: 'Neurosífilis congénita tardía [neurosífilis juvenil]',
	},
	{
		id: 268,
		code: 'A505',
		label: 'Otras formas de sífilis congénita tardía, sintomática',
	},
	{
		id: 269,
		code: 'A506',
		label: 'Sífilis congénita tardía, latente',
	},
	{
		id: 270,
		code: 'A507',
		label: 'Sífilis congénita tardía, sin otra especificación',
	},
	{
		id: 271,
		code: 'A509',
		label: 'Sífilis congénita, sin otra especificación',
	},
	{
		id: 272,
		code: 'A51',
		label: 'Sífilis precoz',
	},
	{
		id: 273,
		code: 'A510',
		label: 'Sífilis genital primaria',
	},
	{
		id: 274,
		code: 'A511',
		label: 'Sífilis primaria anal',
	},
	{
		id: 275,
		code: 'A512',
		label: 'Sífilis primaria en otros sitios',
	},
	{
		id: 276,
		code: 'A513',
		label: 'Sífilis secundaria de piel y membranas mucosas',
	},
	{
		id: 277,
		code: 'A514',
		label: 'Otras sífilis secundarias',
	},
	{
		id: 278,
		code: 'A515',
		label: 'Sífilis precoz, latente',
	},
	{
		id: 279,
		code: 'A519',
		label: 'Sífilis precoz, sin otra especificación',
	},
	{
		id: 280,
		code: 'A52',
		label: 'Sífilis tardía',
	},
	{
		id: 281,
		code: 'A520',
		label: 'Sífilis cardiovascular',
	},
	{
		id: 282,
		code: 'A521',
		label: 'Neurosífilis sintomática',
	},
	{
		id: 283,
		code: 'A522',
		label: 'Neurosífilis asintomática',
	},
	{
		id: 284,
		code: 'A523',
		label: 'Neurosífilis no especificada',
	},
	{
		id: 285,
		code: 'A527',
		label: 'Otras sífilis tardías sintomáticas',
	},
	{
		id: 286,
		code: 'A528',
		label: 'Sífilis tardía, latente',
	},
	{
		id: 287,
		code: 'A529',
		label: 'Sífilis tardía, no especificada',
	},
	{
		id: 288,
		code: 'A53',
		label: 'Otras sífilis y las no especificadas',
	},
	{
		id: 289,
		code: 'A530',
		label: 'Sífilis latente, no especificada como precoz o tardía',
	},
	{
		id: 290,
		code: 'A539',
		label: 'Sífilis, no especificada',
	},
	{
		id: 291,
		code: 'A54',
		label: 'Infección gonocócica',
	},
	{
		id: 292,
		code: 'A540',
		label: 'Infección gonocócica del tracto genitourinario inferior sin absceso periuretral y de glándula accesoria',
	},
	{
		id: 293,
		code: 'A541',
		label: 'Infección gonocócica del tracto genitourinario inferior con absceso periuretral y de glándulas accesorias',
	},
	{
		id: 294,
		code: 'A542',
		label: 'Pelviperitonitis gonocócica y otras infecciones gonocócicas genitourinarias',
	},
	{
		id: 295,
		code: 'A543',
		label: 'Infección gonocócica del ojo',
	},
	{
		id: 296,
		code: 'A544',
		label: 'Infección gonocócica del sistema osteomuscular',
	},
	{
		id: 297,
		code: 'A545',
		label: 'Faringitis gonocócica',
	},
	{
		id: 298,
		code: 'A546',
		label: 'Infección gonocócica del ano y del recto',
	},
	{
		id: 299,
		code: 'A548',
		label: 'Otras infecciones gonocócicas',
	},
	{
		id: 300,
		code: 'A549',
		label: 'Infección gonocócica, no especificada',
	},
	{
		id: 301,
		code: 'A55X',
		label: 'Linfogranuloma (venéreo) por clamidias',
	},
	{
		id: 302,
		code: 'A56',
		label: 'Otras enfermedades de transmisión sexual debidas a clamidias',
	},
	{
		id: 303,
		code: 'A560',
		label: 'Infección del tracto genitourinario inferior debida a clamidias',
	},
	{
		id: 304,
		code: 'A561',
		label: 'Infección del pelviperitoneo y otros órganos genitourinarios debida a clamidias',
	},
	{
		id: 305,
		code: 'A562',
		label: 'Infecciones del tracto genitourinario debidas a clamidias, sin otra especificación',
	},
	{
		id: 306,
		code: 'A563',
		label: 'Infección del ano y del recto debida a clamidias',
	},
	{
		id: 307,
		code: 'A564',
		label: 'Infección de faringe debida a clamidias',
	},
	{
		id: 308,
		code: 'A568',
		label: 'Infección de transmisión sexual de otros sitios debida a clamidias',
	},
	{
		id: 309,
		code: 'A57X',
		label: 'Chancro blando',
	},
	{
		id: 310,
		code: 'A58X',
		label: 'Granuloma inguinal',
	},
	{
		id: 311,
		code: 'A59',
		label: 'Tricomoniasis',
	},
	{
		id: 312,
		code: 'A590',
		label: 'Tricomoniasis urogenital',
	},
	{
		id: 313,
		code: 'A598',
		label: 'Tricomoniasis de otros sitos',
	},
	{
		id: 314,
		code: 'A599',
		label: 'Tricomoniasis, no especificada',
	},
	{
		id: 315,
		code: 'A60',
		label: 'Infección anogenital debida a virus del herpes [herpes simple]',
	},
	{
		id: 316,
		code: 'A600',
		label: 'Infección de genitales y trayecto urogenital debida a virus del herpes [herpes simple]',
	},
	{
		id: 317,
		code: 'A601',
		label: 'Infección de la piel perianal y recto por virus del herpes simple',
	},
	{
		id: 318,
		code: 'A609',
		label: 'Infección anogenital por virus del herpes simple, sin otra especificación',
	},
	{
		id: 319,
		code: 'A63',
		label: 'Otras enfermedades de transmisión predominantemente sexual, no clasificadas en otra parte',
	},
	{
		id: 320,
		code: 'A630',
		label: 'Verrugas (venéreas) anogenitales',
	},
	{
		id: 321,
		code: 'A638',
		label: 'Otras enfermedades de transmisión predominantemente sexual, especificadas',
	},
	{
		id: 322,
		code: 'A64X',
		label: 'Enfermedad de transmisión sexual no especificada',
	},
	{
		id: 323,
		code: 'A65X',
		label: 'Sífilis no venérea',
	},
	{
		id: 324,
		code: 'A66',
		label: 'Frambesia',
	},
	{
		id: 325,
		code: 'A660',
		label: 'Lesiones iniciales de frambesia',
	},
	{
		id: 326,
		code: 'A661',
		label: 'Lesiones papilomatosas múltiples y frambesia con paso de cangrejo',
	},
	{
		id: 327,
		code: 'A662',
		label: 'Otras lesiones precoces de la piel en la frambesia',
	},
	{
		id: 328,
		code: 'A663',
		label: 'Hiperqueratosis de frambesia',
	},
	{
		id: 329,
		code: 'A664',
		label: 'Goma y úlceras de frambesia',
	},
	{
		id: 330,
		code: 'A665',
		label: 'Gangosa',
	},
	{
		id: 331,
		code: 'A666',
		label: 'Lesiones frambésicas de los huesos y de las articulaciones',
	},
	{
		id: 332,
		code: 'A667',
		label: 'Otras manifestaciones de frambesia',
	},
	{
		id: 333,
		code: 'A668',
		label: 'Frambesia latente',
	},
	{
		id: 334,
		code: 'A669',
		label: 'Frambesia, no especificada',
	},
	{
		id: 335,
		code: 'A67',
		label: 'Pinta [carate]',
	},
	{
		id: 336,
		code: 'A670',
		label: 'Lesiones primarias de la pinta',
	},
	{
		id: 337,
		code: 'A671',
		label: 'Lesiones intermedias de la pinta',
	},
	{
		id: 338,
		code: 'A672',
		label: 'Lesiones tardías de la pinta',
	},
	{
		id: 339,
		code: 'A673',
		label: 'Lesiones mixtas de la pinta',
	},
	{
		id: 340,
		code: 'A679',
		label: 'Pinta, no especificada',
	},
	{
		id: 341,
		code: 'A68',
		label: 'Fiebres recurrentes',
	},
	{
		id: 342,
		code: 'A680',
		label: 'Fiebre recurrente transmitida por piojos',
	},
	{
		id: 343,
		code: 'A681',
		label: 'Fiebre recurrente transmitida por garrapatas',
	},
	{
		id: 344,
		code: 'A689',
		label: 'Fiebre recurrente, no especificada',
	},
	{
		id: 345,
		code: 'A69',
		label: 'Otras infecciones causadas por espiroquetas',
	},
	{
		id: 346,
		code: 'A690',
		label: 'Estomatitis ulcerativa necrotizante',
	},
	{
		id: 347,
		code: 'A691',
		label: 'Otras infecciones de Vincent',
	},
	{
		id: 348,
		code: 'A692',
		label: 'Enfermedad de Lyme',
	},
	{
		id: 349,
		code: 'A698',
		label: 'Otras infecciones especificadas por espiroquetas',
	},
	{
		id: 350,
		code: 'A699',
		label: 'Infección por espiroqueta, no especificada',
	},
	{
		id: 351,
		code: 'A70X',
		label: 'Infeccion debida a Chlamydia psittaci',
	},
	{
		id: 352,
		code: 'A71',
		label: 'Tracoma',
	},
	{
		id: 353,
		code: 'A710',
		label: 'Estado inicial de tracoma',
	},
	{
		id: 354,
		code: 'A711',
		label: 'Estado activo de tracoma',
	},
	{
		id: 355,
		code: 'A719',
		label: 'Tracoma, no especificado',
	},
	{
		id: 356,
		code: 'A74',
		label: 'Otras enfermedades causadas por clamidias',
	},
	{
		id: 357,
		code: 'A740',
		label: 'Conjuntivitis por clamidias',
	},
	{
		id: 358,
		code: 'A748',
		label: 'Otras enfermedades por clamidias',
	},
	{
		id: 359,
		code: 'A749',
		label: 'Infección por clamidias, no especificada',
	},
	{
		id: 360,
		code: 'A75',
		label: 'Tifus',
	},
	{
		id: 361,
		code: 'A750',
		label: 'Tifus epidémico debido a Rickettsia prowazekii transmitido por piojos',
	},
	{
		id: 362,
		code: 'A751',
		label: 'Tifus recrudescente [enfermedad de Brill]',
	},
	{
		id: 363,
		code: 'A752',
		label: 'Tifus debido a Rickettsia typhi',
	},
	{
		id: 364,
		code: 'A753',
		label: 'Tifus debido a Rickettsia tsutsugamushi',
	},
	{
		id: 365,
		code: 'A759',
		label: 'Tifus, no especificado',
	},
	{
		id: 366,
		code: 'A77',
		label: 'Fiebre maculosa [rickettsiosis transmitida por garrapatas]',
	},
	{
		id: 367,
		code: 'A770',
		label: 'Fiebre maculosa debida a Rickettsia rickettsii',
	},
	{
		id: 368,
		code: 'A771',
		label: 'Fiebre maculosa debida a Rickettsia conorii',
	},
	{
		id: 369,
		code: 'A772',
		label: 'Fiebre maculosa debida a Rickettsia siberica',
	},
	{
		id: 370,
		code: 'A773',
		label: 'Fiebre maculosa debida a Rickettsia australis',
	},
	{
		id: 371,
		code: 'A778',
		label: 'Otras fiebres maculosas',
	},
	{
		id: 372,
		code: 'A779',
		label: 'Fiebre maculosa, no especificada',
	},
	{
		id: 373,
		code: 'A78X',
		label: 'Fiebre Q',
	},
	{
		id: 374,
		code: 'A79',
		label: 'Otras rickettsiosis',
	},
	{
		id: 375,
		code: 'A790',
		label: 'Fiebre de las trincheras',
	},
	{
		id: 376,
		code: 'A791',
		label: 'Rickettsiosis pustulosa debida a Rickettsia akari',
	},
	{
		id: 377,
		code: 'A798',
		label: 'Otras rickettsiosis especificadas',
	},
	{
		id: 378,
		code: 'A799',
		label: 'Rickettsiosis, no especificada',
	},
	{
		id: 379,
		code: 'A80',
		label: 'Poliomielitis aguda',
	},
	{
		id: 380,
		code: 'A800',
		label: 'Poliomielitis aguda paralítica, asociada a vacuna',
	},
	{
		id: 381,
		code: 'A801',
		label: 'Poliomielitis aguda paralítica debida a virus salvaje importado',
	},
	{
		id: 382,
		code: 'A802',
		label: 'Poliomielitis aguda paralítica debida a virus salvaje autóctono',
	},
	{
		id: 383,
		code: 'A803',
		label: 'Otras poliomielitis agudas paralíticas y las no especificadas',
	},
	{
		id: 384,
		code: 'A804',
		label: 'Poliomielitis aguda no paralítica',
	},
	{
		id: 385,
		code: 'A809',
		label: 'Poliomielitis aguda, sin otra especificación',
	},
	{
		id: 386,
		code: 'A81',
		label: 'Infecciones del sistema nervioso central por virus atípico',
	},
	{
		id: 387,
		code: 'A810',
		label: 'Enfermedad de Creutzfeldt-Jakob',
	},
	{
		id: 388,
		code: 'A811',
		label: 'Panencefalitis esclerosante subaguda',
	},
	{
		id: 389,
		code: 'A812',
		label: 'Leucoencefalopatía multifocal progresiva',
	},
	{
		id: 390,
		code: 'A818',
		label: 'Otras infecciones del sistema nervioso central por virus atípico',
	},
	{
		id: 391,
		code: 'A819',
		label: 'Infecciones del sistema nervioso central por virus atípico, sin otra especificación',
	},
	{
		id: 392,
		code: 'A82',
		label: 'Rabia',
	},
	{
		id: 393,
		code: 'A820',
		label: 'Rabia selvática',
	},
	{
		id: 394,
		code: 'A821',
		label: 'Rabia urbana',
	},
	{
		id: 395,
		code: 'A829',
		label: 'Rabia, sin otra especificación',
	},
	{
		id: 396,
		code: 'A83',
		label: 'Encefalitis viral transmitida por mosquitos',
	},
	{
		id: 397,
		code: 'A830',
		label: 'Encefalitis japonesa',
	},
	{
		id: 398,
		code: 'A831',
		label: 'Encefalitis equina del oeste',
	},
	{
		id: 399,
		code: 'A832',
		label: 'Encefalitis equina del este',
	},
	{
		id: 400,
		code: 'A833',
		label: 'Encefalitis de San Luis',
	},
	{
		id: 401,
		code: 'A834',
		label: 'Encefalitis australiana',
	},
	{
		id: 402,
		code: 'A835',
		label: 'Encefalitis de California',
	},
	{
		id: 403,
		code: 'A836',
		label: 'Enfermedad por virus Rocío',
	},
	{
		id: 404,
		code: 'A838',
		label: 'Otras encefalitis virales transmitidas por mosquitos',
	},
	{
		id: 405,
		code: 'A839',
		label: 'Encefalitis viral transmitida por mosquitos, sin otra especificación',
	},
	{
		id: 406,
		code: 'A84',
		label: 'Encefalitis viral transmitida por garrapatas',
	},
	{
		id: 407,
		code: 'A840',
		label: 'Encefalitis del Lejano Oriente transmitida por garrapatas [encefalitis primaveroestival rusa]',
	},
	{
		id: 408,
		code: 'A841',
		label: 'Encefalitis centroeuropea transmitida por garrapatas',
	},
	{
		id: 409,
		code: 'A848',
		label: 'Otras encefalitis virales transmitida por garrapatas',
	},
	{
		id: 410,
		code: 'A849',
		label: 'Encefalitis viral transmitida por garrapatas, sin otra especificación',
	},
	{
		id: 411,
		code: 'A85',
		label: 'Otras encefalitis virales, no clasificadas en otra parte',
	},
	{
		id: 412,
		code: 'A850',
		label: 'Encefalitis enteroviral',
	},
	{
		id: 413,
		code: 'A851',
		label: 'Encefalitis por adenovirus',
	},
	{
		id: 414,
		code: 'A852',
		label: 'Encefalitis viral transmitida por artrópodos, sin otra especifcación',
	},
	{
		id: 415,
		code: 'A858',
		label: 'Otras encefalitis virales especifcadas',
	},
	{
		id: 416,
		code: 'A86X',
		label: 'Encefalitis viral, no especificada',
	},
	{
		id: 417,
		code: 'A87',
		label: 'Meningitis viral',
	},
	{
		id: 418,
		code: 'A870',
		label: 'Meningitis enteroviral',
	},
	{
		id: 419,
		code: 'A871',
		label: 'Meningitis debida a adenovirus',
	},
	{
		id: 420,
		code: 'A872',
		label: 'Coriomeningitis linfocítica',
	},
	{
		id: 421,
		code: 'A878',
		label: 'Otras meningitis virales',
	},
	{
		id: 422,
		code: 'A879',
		label: 'Meningitis viral, sin otra especificación',
	},
	{
		id: 423,
		code: 'A88',
		label: 'Otras infecciones virales del sistema nervioso central, no clasificadas en otra parte',
	},
	{
		id: 424,
		code: 'A880',
		label: 'Fiebre exantemática enteroviral [exantema de Boston]',
	},
	{
		id: 425,
		code: 'A881',
		label: 'Vértigo epidémico',
	},
	{
		id: 426,
		code: 'A888',
		label: 'Otras infecciones virales especificadas del sistema nervioso central',
	},
	{
		id: 427,
		code: 'A89X',
		label: 'Infección viral del sistema nervioso central, no especificada',
	},
	{
		id: 428,
		code: 'A90X',
		label: 'Fiebre del dengue [dengue clásico]',
	},
	{
		id: 429,
		code: 'A91X',
		label: 'Fiebre del dengue hemorrágico',
	},
	{
		id: 430,
		code: 'A92',
		label: 'Otras fiebres virales transmitidas por mosquitos',
	},
	{
		id: 431,
		code: 'A920',
		label: 'Enfermedad por virus Chikungunya',
	},
	{
		id: 432,
		code: 'A921',
		label: "Fiebre de O'nyong-nyong",
	},
	{
		id: 433,
		code: 'A922',
		label: 'Fiebre equina venezolana',
	},
	{
		id: 434,
		code: 'A923',
		label: 'Infección por virus del oeste del Nilo',
	},
	{
		id: 435,
		code: 'A924',
		label: 'Fiebre del valle del Rift',
	},
	{
		id: 436,
		code: 'A928',
		label: 'Otras fiebres virales especificadas transmitidas por mosquitos',
	},
	{
		id: 437,
		code: 'A929',
		label: 'Fiebre viral transmitida por mosquito, sin otra especificación',
	},
	{
		id: 438,
		code: 'A93',
		label: 'Otras fiebres virales transmitidas por artrópodos, no clasificadas en otra parte',
	},
	{
		id: 439,
		code: 'A930',
		label: 'Enfermedad por virus de  Oropouche',
	},
	{
		id: 440,
		code: 'A931',
		label: 'Fiebre transmitida por flebótomos',
	},
	{
		id: 441,
		code: 'A932',
		label: 'Fiebre de Colorado transmitida por garrapatas',
	},
	{
		id: 442,
		code: 'A938',
		label: 'Otras fiebres virales especificadas transmitidas por artrópodos',
	},
	{
		id: 443,
		code: 'A94X',
		label: 'Fiebre viral transmitida por artrópodos, no especificada',
	},
	{
		id: 444,
		code: 'A95',
		label: 'Fiebre amarilla',
	},
	{
		id: 445,
		code: 'A950',
		label: 'Fiebre amarilla selvática',
	},
	{
		id: 446,
		code: 'A951',
		label: 'Fiebre amarilla urbana',
	},
	{
		id: 447,
		code: 'A959',
		label: 'Fiebre amarilla, no especificada',
	},
	{
		id: 448,
		code: 'A96',
		label: 'Fiebre hemorrágica por arenavirus',
	},
	{
		id: 449,
		code: 'A960',
		label: 'Fiebre hemorrágica de Junín',
	},
	{
		id: 450,
		code: 'A961',
		label: 'Fiebre hemorrágica de Machupo',
	},
	{
		id: 451,
		code: 'A962',
		label: 'Fiebre de Lassa',
	},
	{
		id: 452,
		code: 'A968',
		label: 'Otras fiebres hemorrágicas por arenavirus',
	},
	{
		id: 453,
		code: 'A969',
		label: 'Fiebre hemorrágica por arenavirus, sin otra especificación',
	},
	{
		id: 454,
		code: 'A98',
		label: 'Otras fiebres virales hemorrágicas, no clasificadas en otra parte',
	},
	{
		id: 455,
		code: 'A980',
		label: 'Fiebre hemorrágica de Crimea-Congo',
	},
	{
		id: 456,
		code: 'A981',
		label: 'Fiebre hemorrágica de Omsk',
	},
	{
		id: 457,
		code: 'A982',
		label: 'Enfermedad de la selva Kyasanur',
	},
	{
		id: 458,
		code: 'A983',
		label: 'Enfermedad por el virus de Marburg',
	},
	{
		id: 459,
		code: 'A984',
		label: 'Enfermedad por el virus de Ebola',
	},
	{
		id: 460,
		code: 'A985',
		label: 'Fiebres hemorrágicas con síndrome renal',
	},
	{
		id: 461,
		code: 'A988',
		label: 'Otras fiebres hemorrágicas virales especificadas',
	},
	{
		id: 462,
		code: 'A99X',
		label: 'Fiebre viral hemorrágica, no especificada',
	},
	{
		id: 463,
		code: 'B00',
		label: 'Infecciones herpética [herpes simple]',
	},
	{
		id: 464,
		code: 'B000',
		label: 'Eczema herpético',
	},
	{
		id: 465,
		code: 'B001',
		label: 'Dermatitis vesicular herpética',
	},
	{
		id: 466,
		code: 'B002',
		label: 'Gingivoestomatitis y faringoamigdalitis herpética',
	},
	{
		id: 467,
		code: 'B003',
		label: 'Meningitis herpética',
	},
	{
		id: 468,
		code: 'B004',
		label: 'Encefalitis herpética',
	},
	{
		id: 469,
		code: 'B005',
		label: 'Oculopatía herpética',
	},
	{
		id: 470,
		code: 'B007',
		label: 'Enfermedad herpética diseminada',
	},
	{
		id: 471,
		code: 'B008',
		label: 'Otras formas de infecciones herpéticas',
	},
	{
		id: 472,
		code: 'B009',
		label: 'Infección debida al virus del herpes, no especificada',
	},
	{
		id: 473,
		code: 'B01',
		label: 'Varicela',
	},
	{
		id: 474,
		code: 'B010',
		label: 'Meningitis debida a varicela',
	},
	{
		id: 475,
		code: 'B011',
		label: 'Encefalitis debida a varicela',
	},
	{
		id: 476,
		code: 'B012',
		label: 'Neumonía debida a varicela',
	},
	{
		id: 477,
		code: 'B018',
		label: 'Varicela con otras complicaciones',
	},
	{
		id: 478,
		code: 'B019',
		label: 'Varicela sin complicaciones',
	},
	{
		id: 479,
		code: 'B02',
		label: 'Herpes zoster',
	},
	{
		id: 480,
		code: 'B020',
		label: 'Encefalitis debida a herpes zoster',
	},
	{
		id: 481,
		code: 'B021',
		label: 'Meningitis debida a herpes zoster',
	},
	{
		id: 482,
		code: 'B022',
		label: 'Herpes zoster con otros compromisos del sistema nervioso',
	},
	{
		id: 483,
		code: 'B023',
		label: 'Herpes zoster ocular',
	},
	{
		id: 484,
		code: 'B027',
		label: 'Herpes zoster diseminado',
	},
	{
		id: 485,
		code: 'B028',
		label: 'Herpes zoster con otras complicaciones',
	},
	{
		id: 486,
		code: 'B029',
		label: 'Herpes zoster sin complicaciones',
	},
	{
		id: 487,
		code: 'B03X',
		label: 'Viruela',
	},
	{
		id: 488,
		code: 'B04X',
		label: 'Viruela de los monos',
	},
	{
		id: 489,
		code: 'B05',
		label: 'Sarampión',
	},
	{
		id: 490,
		code: 'B050',
		label: 'Sarampión complicado con encefalitis',
	},
	{
		id: 491,
		code: 'B051',
		label: 'Sarampión complicado con meningitis',
	},
	{
		id: 492,
		code: 'B052',
		label: 'Sarampión complicado con neumonía',
	},
	{
		id: 493,
		code: 'B053',
		label: 'Sarampión complicado con otitis media',
	},
	{
		id: 494,
		code: 'B054',
		label: 'Sarampión con complicaciones intestinales',
	},
	{
		id: 495,
		code: 'B058',
		label: 'Sarampión con otras complicaciones',
	},
	{
		id: 496,
		code: 'B059',
		label: 'Sarampión sin complicaciones',
	},
	{
		id: 497,
		code: 'B06',
		label: 'Rubéola [sarampión alemán]',
	},
	{
		id: 498,
		code: 'B060',
		label: 'Rubéola con complicaciones neurológicas',
	},
	{
		id: 499,
		code: 'B068',
		label: 'Rubéola con otras complicaciones',
	},
	{
		id: 500,
		code: 'B069',
		label: 'Rubéola sin complicaciones',
	},
	{
		id: 501,
		code: 'B07X',
		label: 'Verrugas víricas',
	},
	{
		id: 502,
		code: 'B08',
		label: 'Otras infeciones víricas caracterizadas por lesiones de la piel y de las membranas mucosas, no clasificadas en otra parte',
	},
	{
		id: 503,
		code: 'B080',
		label: 'Otras infecciones debidas a ortopoxvirus',
	},
	{
		id: 504,
		code: 'B081',
		label: 'Molusco contagioso',
	},
	{
		id: 505,
		code: 'B082',
		label: 'Exantema súbito [sexta enfermedad]',
	},
	{
		id: 506,
		code: 'B083',
		label: 'Eritema infeccioso [quinta enfermedad]',
	},
	{
		id: 507,
		code: 'B084',
		label: 'Estomatitis vesicular enteroviral con exantema',
	},
	{
		id: 508,
		code: 'B085',
		label: 'Faringitis vesicular enterovírica',
	},
	{
		id: 509,
		code: 'B088',
		label: 'Otras infecciones virales especificadas, caracterizadas por lesiones de la piel y de las membranas mucosas',
	},
	{
		id: 510,
		code: 'B09X',
		label: 'Infección viral no especificada, caracterizada por lesiones de la piel y de las membranas mucosas',
	},
	{
		id: 511,
		code: 'B15',
		label: 'Hepatitis aguda tipo A',
	},
	{
		id: 512,
		code: 'B150',
		label: 'Hepatitis aguda tipo A, con coma hepático',
	},
	{
		id: 513,
		code: 'B159',
		label: 'Hepatitis aguda tipo A, sin coma hepático',
	},
	{
		id: 514,
		code: 'B16',
		label: 'Hepatitis aguda tipo B',
	},
	{
		id: 515,
		code: 'B160',
		label: 'Hepatitis aguda tipo B, con agente delta (coinfección), con coma hepático',
	},
	{
		id: 516,
		code: 'B161',
		label: 'Hepatitis aguda tipo B, con agente delta (coinfección), sin coma hepático',
	},
	{
		id: 517,
		code: 'B162',
		label: 'Hepatitis aguda tipo B, sin agente delta, con coma hepático',
	},
	{
		id: 518,
		code: 'B169',
		label: 'Hepatitis aguda tipo B, sin agente delta y sin coma hepático',
	},
	{
		id: 519,
		code: 'B17',
		label: 'Otras hepatitis virales agudas',
	},
	{
		id: 520,
		code: 'B170',
		label: 'Infección (superinfección) aguda por agente delta en el portador de hepatitis B',
	},
	{
		id: 521,
		code: 'B171',
		label: 'Hepatitis aguda tipo C',
	},
	{
		id: 522,
		code: 'B172',
		label: 'Hepatitis aguda tipo E',
	},
	{
		id: 523,
		code: 'B178',
		label: 'Otras hepatitis virales agudas especificadas',
	},
	{
		id: 524,
		code: 'B179',
		label: 'Hepatitis viral aguda no especificada ',
	},
	{
		id: 525,
		code: 'B18',
		label: 'Hepatitis viral crónica',
	},
	{
		id: 526,
		code: 'B180',
		label: 'Hepatitis viral tipo B crónica, con agente delta',
	},
	{
		id: 527,
		code: 'B181',
		label: 'Hepatitis viral tipo B crónica, sin agente delta',
	},
	{
		id: 528,
		code: 'B182',
		label: 'Hepatitis viral tipo C crónica',
	},
	{
		id: 529,
		code: 'B188',
		label: 'Otras hepatitis virales crónicas',
	},
	{
		id: 530,
		code: 'B189',
		label: 'Hepatitis viral crónica, sin otra especificación',
	},
	{
		id: 531,
		code: 'B19',
		label: 'Hepatitis viral, sin otra especificación',
	},
	{
		id: 532,
		code: 'B190',
		label: 'Hepatitis viral no especificada con coma hepático',
	},
	{
		id: 533,
		code: 'B199',
		label: 'Hepatitis viral no especificada sin coma hepático',
	},
	{
		id: 534,
		code: 'B20',
		label: 'Enfermedad por virus de la inmunodeficiencia humana [VIH], resultante en enfermedades infecciosas y parasitarias',
	},
	{
		id: 535,
		code: 'B200',
		label: 'Enfermedad por VIH, resultante en infección por micobacterias',
	},
	{
		id: 536,
		code: 'B201',
		label: 'Enfermedad por VIH, resultante en otras infecciones bacterianas',
	},
	{
		id: 537,
		code: 'B202',
		label: 'Enfermedad por VIH, resultante en enfermedad por citomegalovirus',
	},
	{
		id: 538,
		code: 'B203',
		label: 'Enfermedad por VIH, resultante en otras infecciones virales',
	},
	{
		id: 539,
		code: 'B204',
		label: 'Enfermedad por VIH, resultante en candidiasis',
	},
	{
		id: 540,
		code: 'B205',
		label: 'Enfermedad por VIH, resultante en otras micosis',
	},
	{
		id: 541,
		code: 'B206',
		label: 'Enfermedad por VIH, resultante en neumonía por Pneumocystis carinii',
	},
	{
		id: 542,
		code: 'B207',
		label: 'Enfermedad por VIH, resultante en infecciones múltiples',
	},
	{
		id: 543,
		code: 'B208',
		label: 'Enfermedad por VIH, resultante en otras enfermedades infecciosas o parasitarias',
	},
	{
		id: 544,
		code: 'B209',
		label: 'Enfermedad por VIH, resultante en enfermedad infecciosa o parasitaria no especificada',
	},
	{
		id: 545,
		code: 'B21',
		label: 'Enfermedad por virus de la inmunodeficiencia humana [VIH], resultante  en tumores malignos',
	},
	{
		id: 546,
		code: 'B210',
		label: 'Enfermedad por VIH, resultante en sarcoma de Kaposi',
	},
	{
		id: 547,
		code: 'B211',
		label: 'Enfermedad por VIH, resultante en linfoma de Burkitt',
	},
	{
		id: 548,
		code: 'B212',
		label: 'Enfermedad por VIH, resultante en otros tipos de linfoma no Hodgkin',
	},
	{
		id: 549,
		code: 'B213',
		label: 'Enfermedad por VIH, resultante en otros tumores malignos del tejido linfoide, hematopoyético y tejidos relacionados',
	},
	{
		id: 550,
		code: 'B217',
		label: 'Enfermedad por VIH, resultante en tumores malignos múltiples',
	},
	{
		id: 551,
		code: 'B218',
		label: 'Enfermedad por VIH, resultante en otros tumores malignos',
	},
	{
		id: 552,
		code: 'B219',
		label: 'Enfermedad por VIH, resultante en tumores malignos no especificados',
	},
	{
		id: 553,
		code: 'B22',
		label: 'Enfermedad por virus de la inmunodeficiencia humana [VIH], resultante en otras enfermedades especificadas',
	},
	{
		id: 554,
		code: 'B220',
		label: 'Enfermedad por VIH, resultante en encefalopatía',
	},
	{
		id: 555,
		code: 'B221',
		label: 'Enfermedad por VIH, resultante en neumonitis linfoide intersticial',
	},
	{
		id: 556,
		code: 'B222',
		label: 'Enfermedad por VIH, resultante en síndrome caquéctico',
	},
	{
		id: 557,
		code: 'B227',
		label: 'Enfermedad por VIH, resultante en enfermedades múltiples clasificadas en otra parte',
	},
	{
		id: 558,
		code: 'B23',
		label: 'Enfermedad por virus de la inmunodeficiencia humana [VIH], resultante en otras afecciones',
	},
	{
		id: 559,
		code: 'B230',
		label: 'Síndrome de infección aguda debida a VIH',
	},
	{
		id: 560,
		code: 'B231',
		label: 'Enfermedad por VIH, resultante en linfadenopatía generalizada (persistente)',
	},
	{
		id: 561,
		code: 'B232',
		label: 'Enfermedad por VIH, resultante en anormalidades inmunológicas y hematológicas, no clasificadas en otra parte',
	},
	{
		id: 562,
		code: 'B238',
		label: 'Enfermedad por VIH, resultante en otras afecciones especificadas',
	},
	{
		id: 563,
		code: 'B24X',
		label: 'Enfermedad por virus de la inmunodeficiencia humana [VIH], sin otra especificación',
	},
	{
		id: 564,
		code: 'B25',
		label: 'Enfermedad debida a virus citomegálico',
	},
	{
		id: 565,
		code: 'B250',
		label: 'Neumonitis debida a virus citomegálico',
	},
	{
		id: 566,
		code: 'B251',
		label: 'Hepatitis debida a virus citomegálico',
	},
	{
		id: 567,
		code: 'B252',
		label: 'Pancreatitis debida a virus citomegálico',
	},
	{
		id: 568,
		code: 'B258',
		label: 'Otras enfermedades debidas a virus citomegálico',
	},
	{
		id: 569,
		code: 'B259',
		label: 'Enfermedad por virus citomegálico, no especificada',
	},
	{
		id: 570,
		code: 'B26',
		label: 'Parotiditis infecciosa',
	},
	{
		id: 571,
		code: 'B260',
		label: 'Orquitis por parotiditis',
	},
	{
		id: 572,
		code: 'B261',
		label: 'Meningitis por parotiditis',
	},
	{
		id: 573,
		code: 'B262',
		label: 'Encefalitis por parotiditis',
	},
	{
		id: 574,
		code: 'B263',
		label: 'Pancreatitis por parotiditis',
	},
	{
		id: 575,
		code: 'B268',
		label: 'Parotiditis infecciosa con otras complicaciones',
	},
	{
		id: 576,
		code: 'B269',
		label: 'Parotiditis, sin complicaciones',
	},
	{
		id: 577,
		code: 'B27',
		label: 'Mononucleosis infecciosa',
	},
	{
		id: 578,
		code: 'B270',
		label: 'Mononucleosis debida a herpes virus gamma',
	},
	{
		id: 579,
		code: 'B271',
		label: 'Mononucleosis por citomegalovirus',
	},
	{
		id: 580,
		code: 'B278',
		label: 'Otras mononucleosis infecciosas',
	},
	{
		id: 581,
		code: 'B279',
		label: 'Mononucleosis infecciosa, no especificada',
	},
	{
		id: 582,
		code: 'B30',
		label: 'Conjuntivitis viral',
	},
	{
		id: 583,
		code: 'B300',
		label: 'Queratoconjuntivitis debida a adenovirus',
	},
	{
		id: 584,
		code: 'B301',
		label: 'Conjuntivitis debida a adenovirus',
	},
	{
		id: 585,
		code: 'B302',
		label: 'Faringoconjuntivitis viral',
	},
	{
		id: 586,
		code: 'B303',
		label: 'Conjuntivitis epidémica aguda hemorrágica (enterovírica)',
	},
	{
		id: 587,
		code: 'B308',
		label: 'Otras conjuntivitis virales',
	},
	{
		id: 588,
		code: 'B309',
		label: 'Conjuntivitis viral, sin otra especificación',
	},
	{
		id: 589,
		code: 'B33',
		label: 'Otras enfermedades virales, no clasificadas en otra parte',
	},
	{
		id: 590,
		code: 'B330',
		label: 'Mialgia epidémica',
	},
	{
		id: 591,
		code: 'B331',
		label: 'Enfermedad del río Ross',
	},
	{
		id: 592,
		code: 'B332',
		label: 'Carditis viral',
	},
	{
		id: 593,
		code: 'B333',
		label: 'Infecciones debidas a retrovirus, no clasificadas en otra parte',
	},
	{
		id: 594,
		code: 'B334',
		label: 'Síndrome (cardio) pulmonar por hantavirus [SPH] [SCPH] ',
	},
	{
		id: 595,
		code: 'B338',
		label: 'Otras enfermedades virales especificadas',
	},
	{
		id: 596,
		code: 'B34',
		label: 'Infección viral de sitio no especificado',
	},
	{
		id: 597,
		code: 'B340',
		label: 'Infección debida a adenovirus, sin otra especificación',
	},
	{
		id: 598,
		code: 'B341',
		label: 'Infección debida a enterovirus, sin otra especificación',
	},
	{
		id: 599,
		code: 'B342',
		label: 'Infección debida a Coronavirus, sin otra especificación',
	},
	{
		id: 600,
		code: 'B343',
		label: 'Infección debida a Parvovirus, sin otra especificación',
	},
	{
		id: 601,
		code: 'B344',
		label: 'Infección debida a Papovavirus, sin otra especificación',
	},
	{
		id: 602,
		code: 'B348',
		label: 'Otras infecciones virales de sitio no especificado',
	},
	{
		id: 603,
		code: 'B349',
		label: 'Infección viral, no especificada',
	},
	{
		id: 604,
		code: 'B35',
		label: 'Dermatofitosis',
	},
	{
		id: 605,
		code: 'B350',
		label: 'Tiña de la barba y del cuero cabelludo',
	},
	{
		id: 606,
		code: 'B351',
		label: 'Tiña de las uñas',
	},
	{
		id: 607,
		code: 'B352',
		label: 'Tiña de la mano',
	},
	{
		id: 608,
		code: 'B353',
		label: 'Tiña del pie [Tinea pedis]',
	},
	{
		id: 609,
		code: 'B354',
		label: 'Tiña del cuerpo [Tinea corporis]',
	},
	{
		id: 610,
		code: 'B355',
		label: 'Tiña imbricada [Tinea imbricata]',
	},
	{
		id: 611,
		code: 'B356',
		label: 'Tiña inguinal [Tinea cruris]',
	},
	{
		id: 612,
		code: 'B358',
		label: 'Otras dermatofitosis',
	},
	{
		id: 613,
		code: 'B359',
		label: 'Dermatofitosis, no especificada',
	},
	{
		id: 614,
		code: 'B36',
		label: 'Otras micosis superficiales',
	},
	{
		id: 615,
		code: 'B360',
		label: 'Pitiriasis versicolor',
	},
	{
		id: 616,
		code: 'B361',
		label: 'Tiña negra',
	},
	{
		id: 617,
		code: 'B362',
		label: 'Piedra blanca',
	},
	{
		id: 618,
		code: 'B363',
		label: 'Piedra negra',
	},
	{
		id: 619,
		code: 'B368',
		label: 'Otras micosis superficiales especificadas',
	},
	{
		id: 620,
		code: 'B369',
		label: 'Micosis superficial, sin otra especificación',
	},
	{
		id: 621,
		code: 'B37',
		label: 'Candidiasis',
	},
	{
		id: 622,
		code: 'B370',
		label: 'Estomatitis candidiásica',
	},
	{
		id: 623,
		code: 'B371',
		label: 'Candidiasis pulmonar',
	},
	{
		id: 624,
		code: 'B372',
		label: 'Candidiasis de la piel y las uñas',
	},
	{
		id: 625,
		code: 'B373',
		label: 'Candidiasis de la vulva y de la vagina',
	},
	{
		id: 626,
		code: 'B374',
		label: 'Candidiasis de otras localizaciones urogenitales',
	},
	{
		id: 627,
		code: 'B375',
		label: 'Meningitis debida a candida',
	},
	{
		id: 628,
		code: 'B376',
		label: 'Endocarditis debida a candida',
	},
	{
		id: 629,
		code: 'B377',
		label: 'Sepsis debida a candida',
	},
	{
		id: 630,
		code: 'B378',
		label: 'Candidiasis de otros sitios',
	},
	{
		id: 631,
		code: 'B379',
		label: 'Candidiasis, no especificada',
	},
	{
		id: 632,
		code: 'B38',
		label: 'Coccidioidomicosis',
	},
	{
		id: 633,
		code: 'B380',
		label: 'Coccidioidomicosis pulmonar aguda',
	},
	{
		id: 634,
		code: 'B381',
		label: 'Coccidioidomicosis pulmonar crónica',
	},
	{
		id: 635,
		code: 'B382',
		label: 'Coccidioidomicosis pulmonar, sin otra especificación',
	},
	{
		id: 636,
		code: 'B383',
		label: 'Coccidioidomicosis cutánea',
	},
	{
		id: 637,
		code: 'B384',
		label: 'Meningitis debida a coccidioidomicosis',
	},
	{
		id: 638,
		code: 'B387',
		label: 'Coccidioidomicosis diseminada',
	},
	{
		id: 639,
		code: 'B388',
		label: 'Otras formas de coccidioidomicosis',
	},
	{
		id: 640,
		code: 'B389',
		label: 'Coccidioidomicosis, no especificada',
	},
	{
		id: 641,
		code: 'B39',
		label: 'Histoplasmosis',
	},
	{
		id: 642,
		code: 'B390',
		label: 'Infección pulmonar aguda debida a Histoplasma capsulatum',
	},
	{
		id: 643,
		code: 'B391',
		label: 'Infección pulmonar crónica debida a Histoplasma capsulatum',
	},
	{
		id: 644,
		code: 'B392',
		label: 'Infección pulmonar debida a Histoplasma capsulatum, sin otra especificación',
	},
	{
		id: 645,
		code: 'B393',
		label: 'Infección diseminada debida a Histoplasma capsulatum',
	},
	{
		id: 646,
		code: 'B394',
		label: 'Histoplasmosis debida a Histoplasma capsulatum, sin otra especificación',
	},
	{
		id: 647,
		code: 'B395',
		label: 'Infección debida a Histoplasma duboisii',
	},
	{
		id: 648,
		code: 'B399',
		label: 'Histoplasmosis, no especificada',
	},
	{
		id: 649,
		code: 'B40',
		label: 'Blastomicosis',
	},
	{
		id: 650,
		code: 'B400',
		label: 'Blastomicosis pulmonar aguda',
	},
	{
		id: 651,
		code: 'B401',
		label: 'Blastomicosis pulmonar crónica',
	},
	{
		id: 652,
		code: 'B402',
		label: 'Blastomicosis pulmonar, sin otra especificación',
	},
	{
		id: 653,
		code: 'B403',
		label: 'Blastomicosis cutánea',
	},
	{
		id: 654,
		code: 'B407',
		label: 'Blastomicosis diseminada',
	},
	{
		id: 655,
		code: 'B408',
		label: 'Otras formas de blastomicosis',
	},
	{
		id: 656,
		code: 'B409',
		label: 'Blastomicosis, no especificada',
	},
	{
		id: 657,
		code: 'B41',
		label: 'Paracoccidioidomicosis',
	},
	{
		id: 658,
		code: 'B410',
		label: 'Paracoccidioidomicosis pulmonar',
	},
	{
		id: 659,
		code: 'B417',
		label: 'Paracoccidioidomicosis diseminada',
	},
	{
		id: 660,
		code: 'B418',
		label: 'Otras formas de paracoccidioidomicosis',
	},
	{
		id: 661,
		code: 'B419',
		label: 'Paracoccidioidomicosis, no especificada',
	},
	{
		id: 662,
		code: 'B42',
		label: 'Esporotricosis',
	},
	{
		id: 663,
		code: 'B420',
		label: 'Esporotricosis pulmonar',
	},
	{
		id: 664,
		code: 'B421',
		label: 'Esporotricosis linfocutánea',
	},
	{
		id: 665,
		code: 'B427',
		label: 'Esporotricosis diseminada',
	},
	{
		id: 666,
		code: 'B428',
		label: 'Otras formas de esporotricosis',
	},
	{
		id: 667,
		code: 'B429',
		label: 'Esporotricosis, no especificada',
	},
	{
		id: 668,
		code: 'B43',
		label: 'Cromomicosis y absceso feomicótico',
	},
	{
		id: 669,
		code: 'B430',
		label: 'Cromomicosis cutánea',
	},
	{
		id: 670,
		code: 'B431',
		label: 'Absceso cerebral feomicótico',
	},
	{
		id: 671,
		code: 'B432',
		label: 'Absceso y quiste subcutáneo feomicótico',
	},
	{
		id: 672,
		code: 'B438',
		label: 'Otras formas de cromomicosis',
	},
	{
		id: 673,
		code: 'B439',
		label: 'Cromomicosis,no especificada',
	},
	{
		id: 674,
		code: 'B44',
		label: 'Aspergilosis',
	},
	{
		id: 675,
		code: 'B440',
		label: 'Aspergilosis pulmonar invasiva',
	},
	{
		id: 676,
		code: 'B441',
		label: 'Otras aspergilosis pulmonares',
	},
	{
		id: 677,
		code: 'B442',
		label: 'Aspergilosis amigdalina',
	},
	{
		id: 678,
		code: 'B447',
		label: 'Aspergilosis diseminada',
	},
	{
		id: 679,
		code: 'B448',
		label: 'Otras formas de aspergilosis',
	},
	{
		id: 680,
		code: 'B449',
		label: 'Aspergilosis, no especificada',
	},
	{
		id: 681,
		code: 'B45',
		label: 'Criptococosis',
	},
	{
		id: 682,
		code: 'B450',
		label: 'Criptococosis pulmonar',
	},
	{
		id: 683,
		code: 'B451',
		label: 'Criptococosis cerebral',
	},
	{
		id: 684,
		code: 'B452',
		label: 'Criptococosis cutánea',
	},
	{
		id: 685,
		code: 'B453',
		label: 'Criptococosis ósea',
	},
	{
		id: 686,
		code: 'B457',
		label: 'Criptococosis diseminada',
	},
	{
		id: 687,
		code: 'B458',
		label: 'Otras formas de criptococosis',
	},
	{
		id: 688,
		code: 'B459',
		label: 'Criptococosis, no especificada',
	},
	{
		id: 689,
		code: 'B46',
		label: 'Cigomicosis',
	},
	{
		id: 690,
		code: 'B460',
		label: 'Mucormicosis pulmonar',
	},
	{
		id: 691,
		code: 'B461',
		label: 'Mucormicosis rinocerebral',
	},
	{
		id: 692,
		code: 'B462',
		label: 'Mucormicosis gastrointestinal',
	},
	{
		id: 693,
		code: 'B463',
		label: 'Mucormicosis cutánea',
	},
	{
		id: 694,
		code: 'B464',
		label: 'Mucormicosis diseminada',
	},
	{
		id: 695,
		code: 'B465',
		label: 'Mucormicosis, sin otra especificación',
	},
	{
		id: 696,
		code: 'B468',
		label: 'Otras cigomicosis',
	},
	{
		id: 697,
		code: 'B469',
		label: 'Cigomicosis, no especificada',
	},
	{
		id: 698,
		code: 'B47',
		label: 'Micetoma',
	},
	{
		id: 699,
		code: 'B470',
		label: 'Eumicetoma',
	},
	{
		id: 700,
		code: 'B471',
		label: 'Actinomicetoma',
	},
	{
		id: 701,
		code: 'B479',
		label: 'Micetoma, no especificado',
	},
	{
		id: 702,
		code: 'B48',
		label: 'Otras micosis, no clasificadas en otra parte',
	},
	{
		id: 703,
		code: 'B480',
		label: 'Lobomicosis',
	},
	{
		id: 704,
		code: 'B481',
		label: 'Rinosporidiosis',
	},
	{
		id: 705,
		code: 'B482',
		label: 'Alesqueriasis',
	},
	{
		id: 706,
		code: 'B483',
		label: 'Geotricosis',
	},
	{
		id: 707,
		code: 'B484',
		label: 'Penicilosis',
	},
	{
		id: 708,
		code: 'B487',
		label: 'Micosis oportunistas',
	},
	{
		id: 709,
		code: 'B488',
		label: 'Otras micosis especificadas',
	},
	{
		id: 710,
		code: 'B49X',
		label: 'Micosis, no especificada',
	},
	{
		id: 711,
		code: 'B50',
		label: 'Paludismo [malaria] debido a Plasmodium falciparum',
	},
	{
		id: 712,
		code: 'B500',
		label: 'Paludismo debido a Plasmodium falciparum con complicaciones cerebrales',
	},
	{
		id: 713,
		code: 'B508',
		label: 'Otro paludismo grave y complicado debido a Plasmodium falciparum',
	},
	{
		id: 714,
		code: 'B509',
		label: 'Paludismo debido a Plasmodium falciparum, sin otra especificación',
	},
	{
		id: 715,
		code: 'B51',
		label: 'Paludismo [malaria] debido a Plasmodium vivax',
	},
	{
		id: 716,
		code: 'B510',
		label: 'Paludismo debido a Plasmodium vivax con ruptura esplénica',
	},
	{
		id: 717,
		code: 'B518',
		label: 'Paludismo debido a Plasmodium vivax con otras complicaciones',
	},
	{
		id: 718,
		code: 'B519',
		label: 'Paludismo debido a Plasmodium vivax, sin complicaciones',
	},
	{
		id: 719,
		code: 'B52',
		label: 'Paludismo [malaria] debido a Plasmodium malariae',
	},
	{
		id: 720,
		code: 'B520',
		label: 'Paludismo debido a Plasmodium malariae con nefropatía',
	},
	{
		id: 721,
		code: 'B528',
		label: 'Paludismo debido a Plasmodium malariae con otras complicaciones',
	},
	{
		id: 722,
		code: 'B529',
		label: 'Paludismo debido a Plasmodium malariae, sin complicaciones',
	},
	{
		id: 723,
		code: 'B53',
		label: 'Otro paludismo [malaria] confirmado parasitológicamente',
	},
	{
		id: 724,
		code: 'B530',
		label: 'Paludismo debido a Plasmodium ovale',
	},
	{
		id: 725,
		code: 'B531',
		label: 'Paludismo debido a plasmodios de los simios',
	},
	{
		id: 726,
		code: 'B538',
		label: 'Otro paludismo confirmado parasitológicamente, no clasificado en otra parte',
	},
	{
		id: 727,
		code: 'B54X',
		label: 'Paludismo [malaria] no especificado',
	},
	{
		id: 728,
		code: 'B55',
		label: 'Leishmaniasis',
	},
	{
		id: 729,
		code: 'B550',
		label: 'Leishmaniasis visceral',
	},
	{
		id: 730,
		code: 'B551',
		label: 'Leishmaniasis cutánea',
	},
	{
		id: 731,
		code: 'B552',
		label: 'Leishmaniasis mucocutánea',
	},
	{
		id: 732,
		code: 'B559',
		label: 'Leishmaniasis, no especificada',
	},
	{
		id: 733,
		code: 'B56',
		label: 'Tripanosomiasis africana',
	},
	{
		id: 734,
		code: 'B560',
		label: 'Tripanosomiasis gambiense',
	},
	{
		id: 735,
		code: 'B561',
		label: 'Tripanosomiasis rhodesiense',
	},
	{
		id: 736,
		code: 'B569',
		label: 'Tripanosomiasis africana, sin otra especificación',
	},
	{
		id: 737,
		code: 'B57',
		label: 'Enfermedad de Chagas',
	},
	{
		id: 738,
		code: 'B570',
		label: 'Enfermedad de Chagas aguda que afecta al corazón',
	},
	{
		id: 739,
		code: 'B571',
		label: 'Enfermedad de Chagas aguda que no afecta al corazón',
	},
	{
		id: 740,
		code: 'B572',
		label: 'Enfermedad de Chagas (crónica) que afecta al corazón',
	},
	{
		id: 741,
		code: 'B573',
		label: 'Enfermedad de Chagas (crónica) que afecta al sistema digestivo',
	},
	{
		id: 742,
		code: 'B574',
		label: 'Enfermedad de Chagas (crónica) que afecta al sistema nervioso',
	},
	{
		id: 743,
		code: 'B575',
		label: 'Enfermedad de Chagas (crónica) que afecta otros órganos',
	},
	{
		id: 744,
		code: 'B58',
		label: 'Toxoplasmosis',
	},
	{
		id: 745,
		code: 'B580',
		label: 'Oculopatía debida a toxoplasma',
	},
	{
		id: 746,
		code: 'B581',
		label: 'Hepatitis debida a toxoplasma',
	},
	{
		id: 747,
		code: 'B582',
		label: 'Meningoencefalitis debida a toxoplasma',
	},
	{
		id: 748,
		code: 'B583',
		label: 'Toxoplasmosis pulmonar',
	},
	{
		id: 749,
		code: 'B588',
		label: 'Toxoplasmosis con otro órgano afectado',
	},
	{
		id: 750,
		code: 'B589',
		label: 'Toxoplasmosis, no especificada',
	},
	{
		id: 751,
		code: 'B59X',
		label: 'Neumocistosis',
	},
	{
		id: 752,
		code: 'B60',
		label: 'Otras enfermedades debidas a protozoarios, no clasificadas en otra parte',
	},
	{
		id: 753,
		code: 'B600',
		label: 'Babesiosis',
	},
	{
		id: 754,
		code: 'B601',
		label: 'Acantamebiasis',
	},
	{
		id: 755,
		code: 'B602',
		label: 'Naegleriasis',
	},
	{
		id: 756,
		code: 'B608',
		label: 'Otras enfermedades especificadas debidas a protozoarios',
	},
	{
		id: 757,
		code: 'B64X',
		label: 'Enfermedad debida a protozoarios, no especificada',
	},
	{
		id: 758,
		code: 'B65',
		label: 'Esquistosomiasis [bilharziasis]',
	},
	{
		id: 759,
		code: 'B650',
		label: 'Esquistosomiasis debida a Schistosoma haematobium (esquistosomiasis urinaria)',
	},
	{
		id: 760,
		code: 'B651',
		label: 'Esquistosomiasis debida a Schistosoma mansoni [esquistosomiasis intestinal]',
	},
	{
		id: 761,
		code: 'B652',
		label: 'Esquistosomiasis debida a Schistosoma japonicum',
	},
	{
		id: 762,
		code: 'B653',
		label: 'Dermatitis por cercarias',
	},
	{
		id: 763,
		code: 'B658',
		label: 'Otras esquistosomiasis',
	},
	{
		id: 764,
		code: 'B659',
		label: 'Esquistosomiasis, no especificada',
	},
	{
		id: 765,
		code: 'B66',
		label: 'Otras infecciones debidas a trematodos',
	},
	{
		id: 766,
		code: 'B660',
		label: 'Opistorquiasis',
	},
	{
		id: 767,
		code: 'B661',
		label: 'Clonorquiasis',
	},
	{
		id: 768,
		code: 'B662',
		label: 'Dicrocoeliasis',
	},
	{
		id: 769,
		code: 'B663',
		label: 'Fascioliasis',
	},
	{
		id: 770,
		code: 'B664',
		label: 'Paragonimiasis',
	},
	{
		id: 771,
		code: 'B665',
		label: 'Fasciolopsiasis',
	},
	{
		id: 772,
		code: 'B668',
		label: 'Otras infecciones especificadas debidas a trematodos',
	},
	{
		id: 773,
		code: 'B669',
		label: 'Infección debida a trematodos, no especificada',
	},
	{
		id: 774,
		code: 'B67',
		label: 'Equinococosis',
	},
	{
		id: 775,
		code: 'B670',
		label: 'Infección del hígado debida a Echinococcus granulosus',
	},
	{
		id: 776,
		code: 'B671',
		label: 'Infección del pulmón debida a Echinococcus granulosus',
	},
	{
		id: 777,
		code: 'B672',
		label: 'Infección de hueso debida a Echinococcus granulosus',
	},
	{
		id: 778,
		code: 'B673',
		label: 'Infección de otro órgano y de sitios múltiples debida a Echinococcus granulosus',
	},
	{
		id: 779,
		code: 'B674',
		label: 'Infección debida a Echinococcus granulosus, sin otra especificación',
	},
	{
		id: 780,
		code: 'B675',
		label: 'Infección del hígado debida a Echinococcus multilocularis',
	},
	{
		id: 781,
		code: 'B676',
		label: 'Infección de otro órgano y de sitios múltiples debida a Echinococcus multilocularis',
	},
	{
		id: 782,
		code: 'B677',
		label: 'Infección debida a Echinococcus multilocularis, sin otra especificación',
	},
	{
		id: 783,
		code: 'B678',
		label: 'Equinococosis del hígado, no especificada',
	},
	{
		id: 784,
		code: 'B679',
		label: 'Equinococosis, otra y la no especificada',
	},
	{
		id: 785,
		code: 'B68',
		label: 'Teniasis',
	},
	{
		id: 786,
		code: 'B680',
		label: 'Teniasis debida a Taenia solium',
	},
	{
		id: 787,
		code: 'B681',
		label: 'Infección debida a Taenia saginata',
	},
	{
		id: 788,
		code: 'B689',
		label: 'Teniasis, no especificada',
	},
	{
		id: 789,
		code: 'B69',
		label: 'Cisticercosis',
	},
	{
		id: 790,
		code: 'B690',
		label: 'Cisticercosis del sistema nervioso central',
	},
	{
		id: 791,
		code: 'B691',
		label: 'Cisticercosis del ojo',
	},
	{
		id: 792,
		code: 'B698',
		label: 'Cisticercosis de otros sitios',
	},
	{
		id: 793,
		code: 'B699',
		label: 'Cisticercosis, no especificada',
	},
	{
		id: 794,
		code: 'B70',
		label: 'Difilobotriasis y esparganosis',
	},
	{
		id: 795,
		code: 'B700',
		label: 'Difilobotriasis intestinal',
	},
	{
		id: 796,
		code: 'B701',
		label: 'Esparganosis',
	},
	{
		id: 797,
		code: 'B71',
		label: 'Otras infecciones debidas a cestodos',
	},
	{
		id: 798,
		code: 'B710',
		label: 'Himenolepiasis',
	},
	{
		id: 799,
		code: 'B711',
		label: 'Dipilidiasis',
	},
	{
		id: 800,
		code: 'B718',
		label: 'Otras infecciones debidas a cestodos especificadas',
	},
	{
		id: 801,
		code: 'B719',
		label: 'Infecciones debidas a cestodos, no especificada',
	},
	{
		id: 802,
		code: 'B72X',
		label: 'Dracontiasis',
	},
	{
		id: 803,
		code: 'B73X',
		label: 'Oncocercosis',
	},
	{
		id: 804,
		code: 'B74',
		label: 'Filariasis',
	},
	{
		id: 805,
		code: 'B740',
		label: 'Filariasis debida a Wuchereria bancrofti',
	},
	{
		id: 806,
		code: 'B741',
		label: 'Filariasis debida a Brugia malayi',
	},
	{
		id: 807,
		code: 'B742',
		label: 'Filariasis debida a Brugia timori',
	},
	{
		id: 808,
		code: 'B743',
		label: 'Loaiasis',
	},
	{
		id: 809,
		code: 'B744',
		label: 'Mansoneliasis',
	},
	{
		id: 810,
		code: 'B748',
		label: 'Otras filariasis',
	},
	{
		id: 811,
		code: 'B749',
		label: 'Filariasis, no especificada',
	},
	{
		id: 812,
		code: 'B75X',
		label: 'Triquinosis',
	},
	{
		id: 813,
		code: 'B76',
		label: 'Anquilostomiasis y necatoriasis',
	},
	{
		id: 814,
		code: 'B760',
		label: 'Anquilostomiasis',
	},
	{
		id: 815,
		code: 'B761',
		label: 'Necatoriasis',
	},
	{
		id: 816,
		code: 'B768',
		label: 'Otras enfermedades debidas a anquilostomas',
	},
	{
		id: 817,
		code: 'B769',
		label: 'Enfermedad debida a anquilostomas, no especificada',
	},
	{
		id: 818,
		code: 'B77',
		label: 'Ascariasis',
	},
	{
		id: 819,
		code: 'B770',
		label: 'Ascariasis con complicaciones intestinales',
	},
	{
		id: 820,
		code: 'B778',
		label: 'Ascariasis con otras complicaciones',
	},
	{
		id: 821,
		code: 'B779',
		label: 'Ascariasis, no especificada',
	},
	{
		id: 822,
		code: 'B78',
		label: 'Estrongiloidiasis',
	},
	{
		id: 823,
		code: 'B780',
		label: 'Estrongiloidiasis intestinal',
	},
	{
		id: 824,
		code: 'B781',
		label: 'Estrongiloidiasis cutánea',
	},
	{
		id: 825,
		code: 'B787',
		label: 'Estrongiloidiasis diseminada',
	},
	{
		id: 826,
		code: 'B789',
		label: 'Estrongiloidiasis, no especificada',
	},
	{
		id: 827,
		code: 'B79X',
		label: 'Tricuriasis',
	},
	{
		id: 828,
		code: 'B80X',
		label: 'Enterobiasis',
	},
	{
		id: 829,
		code: 'B81',
		label: 'Otras helmintiasis intestinales, no clasificadas en otra parte',
	},
	{
		id: 830,
		code: 'B810',
		label: 'Anisaquiasis',
	},
	{
		id: 831,
		code: 'B811',
		label: 'Capilariasis intestinal',
	},
	{
		id: 832,
		code: 'B812',
		label: 'Tricoestrongiliasis',
	},
	{
		id: 833,
		code: 'B813',
		label: 'Angioestrongiliasis intestinal',
	},
	{
		id: 834,
		code: 'B814',
		label: 'Helmintiasis intestinal mixta',
	},
	{
		id: 835,
		code: 'B818',
		label: 'Otras helmintiasis intestinales especificadas',
	},
	{
		id: 836,
		code: 'B82',
		label: 'Parasitosis intestinal, sin otra especificación',
	},
	{
		id: 837,
		code: 'B820',
		label: 'Helmintiasis intestinal, sin otra especificación',
	},
	{
		id: 838,
		code: 'B829',
		label: 'Parasitosis intestinal, sin otra especificación',
	},
	{
		id: 839,
		code: 'B83',
		label: 'Otros helmintiasis',
	},
	{
		id: 840,
		code: 'B830',
		label: 'Larva migrans visceral',
	},
	{
		id: 841,
		code: 'B831',
		label: 'Gnatostomiasis',
	},
	{
		id: 842,
		code: 'B832',
		label: 'Angioestrongiliasis debida a Parastrongylus cantonensis',
	},
	{
		id: 843,
		code: 'B833',
		label: 'Singamiasis',
	},
	{
		id: 844,
		code: 'B834',
		label: 'Hirudiniasis interna',
	},
	{
		id: 845,
		code: 'B838',
		label: 'Otras helmintiasis especificadas',
	},
	{
		id: 846,
		code: 'B839',
		label: 'Helmintiasis, no especificada',
	},
	{
		id: 847,
		code: 'B85',
		label: 'Pediculosis y phthiriasis',
	},
	{
		id: 848,
		code: 'B850',
		label: 'Pediculosis debida a Pediculus humanus capitis',
	},
	{
		id: 849,
		code: 'B851',
		label: 'Pediculosis debida a Pediculus humanus corporis',
	},
	{
		id: 850,
		code: 'B852',
		label: 'Pediculosis, sin otra especificación',
	},
	{
		id: 851,
		code: 'B853',
		label: 'Phthiriasis',
	},
	{
		id: 852,
		code: 'B854',
		label: 'Pediculosis y phthiriasis mixtas',
	},
	{
		id: 853,
		code: 'B86X',
		label: 'Escabiosis',
	},
	{
		id: 854,
		code: 'B87',
		label: 'Miasis',
	},
	{
		id: 855,
		code: 'B870',
		label: 'Miasis cutánea',
	},
	{
		id: 856,
		code: 'B871',
		label: 'Miasis en heridas',
	},
	{
		id: 857,
		code: 'B872',
		label: 'Miasis ocular',
	},
	{
		id: 858,
		code: 'B873',
		label: 'Miasis nasofaríngea',
	},
	{
		id: 859,
		code: 'B874',
		label: 'Miasis aural',
	},
	{
		id: 860,
		code: 'B878',
		label: 'Miasis de otros sitios',
	},
	{
		id: 861,
		code: 'B879',
		label: 'Miasis, no especificada',
	},
	{
		id: 862,
		code: 'B88',
		label: 'Otras infestaciones',
	},
	{
		id: 863,
		code: 'B880',
		label: 'Otras acariasis',
	},
	{
		id: 864,
		code: 'B881',
		label: 'Tungiasis [infección debida a pulga de arena]',
	},
	{
		id: 865,
		code: 'B882',
		label: 'Otras infestaciones debidas a artrópodos',
	},
	{
		id: 866,
		code: 'B883',
		label: 'Hirudiniasis externa',
	},
	{
		id: 867,
		code: 'B888',
		label: 'Otras infestaciones especificadas',
	},
	{
		id: 868,
		code: 'B889',
		label: 'Infestación, no especificada',
	},
	{
		id: 869,
		code: 'B89X',
		label: 'Enfermedad parasitaria, no especificada',
	},
	{
		id: 870,
		code: 'B90',
		label: 'Secuelas de tuberculosis',
	},
	{
		id: 871,
		code: 'B900',
		label: 'Secuelas de tuberculosis del sistema nervioso central',
	},
	{
		id: 872,
		code: 'B901',
		label: 'Secuelas de tuberculosis genitourinaria',
	},
	{
		id: 873,
		code: 'B902',
		label: 'Secuelas de tuberculosis de huesos y articulaciones',
	},
	{
		id: 874,
		code: 'B908',
		label: 'Secuelas de tuberculosis de otros órganos especificados',
	},
	{
		id: 875,
		code: 'B909',
		label: 'Secuelas de tuberculosis respiratoria y de tuberculosis no especificada',
	},
	{
		id: 876,
		code: 'B91X',
		label: 'Secuelas de poliomielitis',
	},
	{
		id: 877,
		code: 'B92X',
		label: 'Secuelas de lepra',
	},
	{
		id: 878,
		code: 'B94',
		label: 'Secuelas de otras enfermedades infecciosas y parasitarias de los no especificadas',
	},
	{
		id: 879,
		code: 'B940',
		label: 'Secuelas de tracoma',
	},
	{
		id: 880,
		code: 'B941',
		label: 'Secuelas de encefalitis viral',
	},
	{
		id: 881,
		code: 'B942',
		label: 'Secuelas de hepatitis viral',
	},
	{
		id: 882,
		code: 'B948',
		label: 'Secuelas de otras enfermedades infecciosas y parasitarias especificadas',
	},
	{
		id: 883,
		code: 'B949',
		label: 'Secuelas de enfermedades infecciosas y parasitarias no especificadas',
	},
	{
		id: 884,
		code: 'B95',
		label: 'Estreptococos y estafilococos como causa de enfermedades clasificadas en otros capítulos',
	},
	{
		id: 885,
		code: 'B950',
		label: 'Estreptococo, grupo A, como causa de enfermedades clasificadas en otros capítulos',
	},
	{
		id: 886,
		code: 'B951',
		label: 'Estreptococo, grupo B, como causa de enfermedades clasificadas en otros capítulos',
	},
	{
		id: 887,
		code: 'B952',
		label: 'Estreptococo, grupo D, como causa de enfermedades clasificadas en otros capítulos',
	},
	{
		id: 888,
		code: 'B953',
		label: 'Streptococcus pneumoniae como causa de enfermedades clasificadas en otros capítulos',
	},
	{
		id: 889,
		code: 'B954',
		label: 'Otros estreptococos como causa de enfermedades clasificadas en otros capítulos',
	},
	{
		id: 890,
		code: 'B955',
		label: 'Estreptococo no especificado como causa de enfermedades clasificadas en otros capítulos',
	},
	{
		id: 891,
		code: 'B956',
		label: 'Staphylococcus aureus como causa de enfermedades clasificadas en otros capítulos',
	},
	{
		id: 892,
		code: 'B957',
		label: 'Otros estafilococos como causa de enfermedades clasificadas en otros capítulos',
	},
	{
		id: 893,
		code: 'B958',
		label: 'Estafilococo no especificado, como causa de enfermedades clasificadas en otros capítulos',
	},
	{
		id: 894,
		code: 'B96',
		label: 'Otros agentes bacterianos especificados como causa de enfermedades clasificadas en otros capítulos',
	},
	{
		id: 895,
		code: 'B960',
		label: 'Mycoplasma pneumoniae [M. pneumoniae] como causa de enfermedades clasificadas en otros capítulos',
	},
	{
		id: 896,
		code: 'B961',
		label: 'Klebsiella pneumoniae [K. pneumoniae] como causa de enfermedades clasificadas en otros capítulos',
	},
	{
		id: 897,
		code: 'B962',
		label: 'Escherichia coli [E. coli] como causa de enfermedades clasificadas en otros capítulos',
	},
	{
		id: 898,
		code: 'B963',
		label: 'Haemophilus influenzae [H. influenzae] como causa de enfermedades clasificadas en otros capítulos',
	},
	{
		id: 899,
		code: 'B964',
		label: 'Proteus (mirabilis) (morganii) como causa de enfermedades clasificadas en otros capítulos',
	},
	{
		id: 900,
		code: 'B965',
		label: 'Pseudomonas (aeruginosa) como causa de enfermedades clasificadas en otros capítulos',
	},
	{
		id: 901,
		code: 'B966',
		label: 'Bacillus fragilis  [B. fragilis] como causa de enfermedades clasificadas en otros capítulos',
	},
	{
		id: 902,
		code: 'B967',
		label: 'Clostridium perfringens [C. perfringens] como causa de enfermedades clasificadas en otros capítulos',
	},
	{
		id: 903,
		code: 'B968',
		label: 'Otros agentes bacterianos especificados como causa de enfermedades clasificadas en otros capítulos',
	},
	{
		id: 904,
		code: 'B97',
		label: 'Agentes virales como causa de enfermedades clasificadas en otros capítulos',
	},
	{
		id: 905,
		code: 'B970',
		label: 'Adenovirus como causa de enfermedades clasificadas en otros capítulos',
	},
	{
		id: 906,
		code: 'B971',
		label: 'Enterovirus como causa de enfermedades clasificadas en otros capítulos',
	},
	{
		id: 907,
		code: 'B972',
		label: 'Coronavirus como causa de enfermedades clasificadas en otros capítulos',
	},
	{
		id: 908,
		code: 'B973',
		label: 'Retrovirus como causa de enfermedades clasificadas en otros capítulos',
	},
	{
		id: 909,
		code: 'B974',
		label: 'Virus sincicial respiratorio como causa de enfermedades clasificadas en otros capítulos',
	},
	{
		id: 910,
		code: 'B975',
		label: 'Reovirus como causa de enfermedades clasificadas en otros capítulos',
	},
	{
		id: 911,
		code: 'B976',
		label: 'Parvovirus como causa de enfermedades clasificadas en otros capítulos',
	},
	{
		id: 912,
		code: 'B977',
		label: 'Papilomavirus como causa de enfermedades clasificadas en otros capítulos',
	},
	{
		id: 913,
		code: 'B978',
		label: 'Otros agentes virales como causa de enfermedades clasificadas en otros capítulos',
	},
	{
		id: 914,
		code: 'B98',
		label: 'Otros agentes infecciosos especificados como causa de enfermedades clasificadas en otros capítulos',
	},
	{
		id: 915,
		code: 'B980',
		label: 'Helicobacter pylori [H.pylori] como la causa de enfermedades clasificadas en otros capítulos ',
	},
	{
		id: 916,
		code: 'B981',
		label: 'Vibrio vulnificus como la causa de enfermedades clasificadas en otros capítulos ',
	},
	{
		id: 917,
		code: 'B99X',
		label: 'Otras enfermedades infecciosas y las no especificadas',
	},
	{
		id: 918,
		code: 'C00',
		label: 'Tumor maligno del labio',
	},
	{
		id: 919,
		code: 'C000',
		label: 'Tumor maligno del labio superior, cara externa',
	},
	{
		id: 920,
		code: 'C001',
		label: 'Tumor maligno del labio inferior, cara externa',
	},
	{
		id: 921,
		code: 'C002',
		label: 'Tumor maligno del labio, cara externa, sin otra especificación',
	},
	{
		id: 922,
		code: 'C003',
		label: 'Tumor maligno del labio superior, cara interna',
	},
	{
		id: 923,
		code: 'C004',
		label: 'Tumor maligno del labio inferior, cara interna',
	},
	{
		id: 924,
		code: 'C005',
		label: 'Tumor maligno del labio, cara interna, sin otra especificación',
	},
	{
		id: 925,
		code: 'C006',
		label: 'Tumor maligno de la comisura labial',
	},
	{
		id: 926,
		code: 'C008',
		label: 'Lesión de sitios contiguos del labio',
	},
	{
		id: 927,
		code: 'C009',
		label: 'Tumor maligno del labio, parte no especificada',
	},
	{
		id: 928,
		code: 'C01X',
		label: 'Tumor maligno de la base de la lengua',
	},
	{
		id: 929,
		code: 'C02',
		label: 'Tumor maligno de otras partes y de las no especificadas de la lengua',
	},
	{
		id: 930,
		code: 'C020',
		label: 'Tumor maligno de la cara dorsal de la lengua',
	},
	{
		id: 931,
		code: 'C021',
		label: 'Tumor maligno del borde de la lengua',
	},
	{
		id: 932,
		code: 'C022',
		label: 'Tumor maligno de la cara ventral de la lengua',
	},
	{
		id: 933,
		code: 'C023',
		label: 'Tumor maligno de los dos tercios anteriores de la lengua, parte no especificada',
	},
	{
		id: 934,
		code: 'C024',
		label: 'Tumor maligno de la amígdala lingual',
	},
	{
		id: 935,
		code: 'C028',
		label: 'Lesión de sitios contiguos de la lengua',
	},
	{
		id: 936,
		code: 'C029',
		label: 'Tumor maligno de la lengua, parte no especificada',
	},
	{
		id: 937,
		code: 'C03',
		label: 'Tumor maligno de la encía',
	},
	{
		id: 938,
		code: 'C030',
		label: 'Tumor maligno de la encía superior',
	},
	{
		id: 939,
		code: 'C031',
		label: 'Tumor maligno de la encía inferior',
	},
	{
		id: 940,
		code: 'C039',
		label: 'Tumor maligno de la encía, parte no especificada',
	},
	{
		id: 941,
		code: 'C04',
		label: 'Tumor maligno del piso de la boca',
	},
	{
		id: 942,
		code: 'C040',
		label: 'Tumor maligno de la parte anterior del piso de la boca',
	},
	{
		id: 943,
		code: 'C041',
		label: 'Tumor maligno de la parte lateral del piso de la boca',
	},
	{
		id: 944,
		code: 'C048',
		label: 'Lesión de sitios contiguos del piso de la boca',
	},
	{
		id: 945,
		code: 'C049',
		label: 'Tumor maligno del piso de la boca, parte no especificada',
	},
	{
		id: 946,
		code: 'C05',
		label: 'Tumor maligno del paladar',
	},
	{
		id: 947,
		code: 'C050',
		label: 'Tumor maligno del paladar duro',
	},
	{
		id: 948,
		code: 'C051',
		label: 'Tumor maligno del paladar blando',
	},
	{
		id: 949,
		code: 'C052',
		label: 'Tumor maligno de la úvula',
	},
	{
		id: 950,
		code: 'C058',
		label: 'Lesión de sitios contiguos del paladar',
	},
	{
		id: 951,
		code: 'C059',
		label: 'Tumor maligno del paladar, parte no especificada',
	},
	{
		id: 952,
		code: 'C06',
		label: 'Tumor maligno de otras partes y de las no especificadas de la boca',
	},
	{
		id: 953,
		code: 'C060',
		label: 'Tumor maligno de la mucosa de la mejilla',
	},
	{
		id: 954,
		code: 'C061',
		label: 'Tumor maligno del vestíbulo de la boca',
	},
	{
		id: 955,
		code: 'C062',
		label: 'Tumor maligno del área retromolar',
	},
	{
		id: 956,
		code: 'C068',
		label: 'Lesión de sitios contiguos de otras partes y de las no especificadas de la boca',
	},
	{
		id: 957,
		code: 'C069',
		label: 'Tumor maligno de la boca, parte no especificada',
	},
	{
		id: 958,
		code: 'C07X',
		label: 'Tumor maligno de la glándula parótida',
	},
	{
		id: 959,
		code: 'C08',
		label: 'Tumor maligno de otras glándulas salivales mayores y de las no especificadas',
	},
	{
		id: 960,
		code: 'C080',
		label: 'Tumor maligno de la glándula submaxilar',
	},
	{
		id: 961,
		code: 'C081',
		label: 'Tumor maligno de la glándula sublingual',
	},
	{
		id: 962,
		code: 'C088',
		label: 'Lesión de sitios contiguos de las glándulas salivales mayores',
	},
	{
		id: 963,
		code: 'C089',
		label: 'Tumor maligno de glándula salival mayor, no especificada',
	},
	{
		id: 964,
		code: 'C09',
		label: 'Tumor maligno de la amígdala',
	},
	{
		id: 965,
		code: 'C090',
		label: 'Tumor maligno de la fosa amigdalina',
	},
	{
		id: 966,
		code: 'C091',
		label: 'Tumor maligno del pilar amigdalino (anterior) (posterior)',
	},
	{
		id: 967,
		code: 'C098',
		label: 'Lesión de sitios contiguos de la amígdala',
	},
	{
		id: 968,
		code: 'C099',
		label: 'Tumor maligno de la amígdala, parte no especificada',
	},
	{
		id: 969,
		code: 'C10',
		label: 'Tumor maligno de la orofaringe',
	},
	{
		id: 970,
		code: 'C100',
		label: 'Tumor maligno de la valécula',
	},
	{
		id: 971,
		code: 'C101',
		label: 'Tumor maligno de la cara anterior de la epiglotis',
	},
	{
		id: 972,
		code: 'C102',
		label: 'Tumor maligno de la pared lateral de la orofaringe',
	},
	{
		id: 973,
		code: 'C103',
		label: 'Tumor maligno de la pared posterior de la orofaringe',
	},
	{
		id: 974,
		code: 'C104',
		label: 'Tumor maligno de la hendidura branquial',
	},
	{
		id: 975,
		code: 'C108',
		label: 'Lesión de sitios contiguos de la orofaringe',
	},
	{
		id: 976,
		code: 'C109',
		label: 'Tumor maligno de la orofaringe, parte no especificada',
	},
	{
		id: 977,
		code: 'C11',
		label: 'Tumor maligno de la nasofaringe',
	},
	{
		id: 978,
		code: 'C110',
		label: 'Tumor maligno de la pared superior de la nasofaringe',
	},
	{
		id: 979,
		code: 'C111',
		label: 'Tumor maligno de la pared posterior de la nasofaringe',
	},
	{
		id: 980,
		code: 'C112',
		label: 'Tumor maligno de la pared lateral de la nasofaringe',
	},
	{
		id: 981,
		code: 'C113',
		label: 'Tumor maligno de la pared anterior de la nasofaringe',
	},
	{
		id: 982,
		code: 'C118',
		label: 'Lesión de sitios contiguos de la nasofaringe',
	},
	{
		id: 983,
		code: 'C119',
		label: 'Tumor maligno de la nasofaringe, parte no especificada',
	},
	{
		id: 984,
		code: 'C12X',
		label: 'Tumor maligno del seno piriforme',
	},
	{
		id: 985,
		code: 'C13',
		label: 'Tumor maligno de la hipofaringe',
	},
	{
		id: 986,
		code: 'C130',
		label: 'Tumor maligno de la región postcricoidea',
	},
	{
		id: 987,
		code: 'C131',
		label: 'Tumor maligno del pliegue aritenoepiglótico, cara hipofaríngea',
	},
	{
		id: 988,
		code: 'C132',
		label: 'Tumor maligno de la pared posterior de la hipofaringe',
	},
	{
		id: 989,
		code: 'C138',
		label: 'Lesión de sitios contiguos de la hipofaringe',
	},
	{
		id: 990,
		code: 'C139',
		label: 'Tumor maligno de la hipofaringe, parte no especificada',
	},
	{
		id: 991,
		code: 'C14',
		label: 'Tumor maligno de otros sitios y de los mal definidos del labio, de la cavidad bucal y de la faringe',
	},
	{
		id: 992,
		code: 'C140',
		label: 'Tumor maligno de la faringe, parte no especificada',
	},
	{
		id: 993,
		code: 'C142',
		label: 'Tumor maligno del anillo de Waldeyer',
	},
	{
		id: 994,
		code: 'C148',
		label: 'Lesión de sitios contiguos del labio, de la cavidad bucal y de la faringe',
	},
	{
		id: 995,
		code: 'C15',
		label: 'Tumor maligno del esófago',
	},
	{
		id: 996,
		code: 'C150',
		label: 'Tumor maligno del esófago, porción cervical',
	},
	{
		id: 997,
		code: 'C151',
		label: 'Tumor maligno del esófago, porción torácica',
	},
	{
		id: 998,
		code: 'C152',
		label: 'Tumor maligno del esófago, porción abdominal',
	},
	{
		id: 999,
		code: 'C153',
		label: 'Tumor maligno del tercio superior del esófago',
	},
	{
		id: 1000,
		code: 'C154',
		label: 'Tumor maligno del tercio medio del esófago',
	},
	{
		id: 1001,
		code: 'C155',
		label: 'Tumor maligno del tercio inferior del esófago',
	},
	{
		id: 1002,
		code: 'C158',
		label: 'Lesión de sitios contiguos del esófago',
	},
	{
		id: 1003,
		code: 'C159',
		label: 'Tumor maligno del esófago, parte no especificada',
	},
	{
		id: 1004,
		code: 'C16',
		label: 'Tumor maligno del estómago',
	},
	{
		id: 1005,
		code: 'C160',
		label: 'Tumor maligno del cardias',
	},
	{
		id: 1006,
		code: 'C161',
		label: 'Tumor maligno del fundus gástrico',
	},
	{
		id: 1007,
		code: 'C162',
		label: 'Tumor maligno del cuerpo del estómago',
	},
	{
		id: 1008,
		code: 'C163',
		label: 'Tumor maligno del antro pilórico',
	},
	{
		id: 1009,
		code: 'C164',
		label: 'Tumor maligno del píloro',
	},
	{
		id: 1010,
		code: 'C165',
		label: 'Tumor maligno de la curvatura menor del estómago, sin otra especificación',
	},
	{
		id: 1011,
		code: 'C166',
		label: 'Tumor maligno de la curvatura mayor del estómago, sin otra especificación',
	},
	{
		id: 1012,
		code: 'C168',
		label: 'Lesión de sitios contiguos del estómago',
	},
	{
		id: 1013,
		code: 'C169',
		label: 'Tumor maligno del estómago, parte no especificada',
	},
	{
		id: 1014,
		code: 'C17',
		label: 'Tumor maligno del intestino delgado',
	},
	{
		id: 1015,
		code: 'C170',
		label: 'Tumor maligno del duodeno',
	},
	{
		id: 1016,
		code: 'C171',
		label: 'Tumor maligno del yeyuno',
	},
	{
		id: 1017,
		code: 'C172',
		label: 'Tumor maligno del íleon',
	},
	{
		id: 1018,
		code: 'C173',
		label: 'Tumor maligno del divertículo de Meckel',
	},
	{
		id: 1019,
		code: 'C178',
		label: 'Lesión de sitios contiguos del intestino delgado',
	},
	{
		id: 1020,
		code: 'C179',
		label: 'Tumor maligno del intestino delgado, parte no especificada',
	},
	{
		id: 1021,
		code: 'C18',
		label: 'Tumor maligno del colon',
	},
	{
		id: 1022,
		code: 'C180',
		label: 'Tumor maligno del ciego',
	},
	{
		id: 1023,
		code: 'C181',
		label: 'Tumor maligno del apéndice',
	},
	{
		id: 1024,
		code: 'C182',
		label: 'Tumor maligno del colon ascendente',
	},
	{
		id: 1025,
		code: 'C183',
		label: 'Tumor maligno del ángulo hepático',
	},
	{
		id: 1026,
		code: 'C184',
		label: 'Tumor maligno del colon transverso',
	},
	{
		id: 1027,
		code: 'C185',
		label: 'Tumor maligno del ángulo esplénico',
	},
	{
		id: 1028,
		code: 'C186',
		label: 'Tumor maligno del colon descendente',
	},
	{
		id: 1029,
		code: 'C187',
		label: 'Tumor maligno del colon sigmoide',
	},
	{
		id: 1030,
		code: 'C188',
		label: 'Lesión de sitios contiguos del colon',
	},
	{
		id: 1031,
		code: 'C189',
		label: 'Tumor maligno del colon, parte no especificada',
	},
	{
		id: 1032,
		code: 'C19X',
		label: 'Tumor maligno de la unión rectosigmoidea',
	},
	{
		id: 1033,
		code: 'C20X',
		label: 'Tumor maligno del recto',
	},
	{
		id: 1034,
		code: 'C21',
		label: 'Tumor maligno del ano y del conducto anal',
	},
	{
		id: 1035,
		code: 'C210',
		label: 'Tumor maligno del ano, parte no especificada',
	},
	{
		id: 1036,
		code: 'C211',
		label: 'Tumor maligno del conducto anal',
	},
	{
		id: 1037,
		code: 'C212',
		label: 'Tumor maligno de la zona cloacogénica',
	},
	{
		id: 1038,
		code: 'C218',
		label: 'Lesión de sitios contiguos del ano, del conducto anal y del recto',
	},
	{
		id: 1039,
		code: 'C22',
		label: 'Tumor maligno del hígado y de las vías biliares intrahepáticas',
	},
	{
		id: 1040,
		code: 'C220',
		label: 'Carcinoma de células hepáticas',
	},
	{
		id: 1041,
		code: 'C221',
		label: 'Carcinoma de vías biliares intrahepáticas',
	},
	{
		id: 1042,
		code: 'C222',
		label: 'Hepatoblastoma',
	},
	{
		id: 1043,
		code: 'C223',
		label: 'Angiosarcoma del hígado',
	},
	{
		id: 1044,
		code: 'C224',
		label: 'Otros sarcomas del hígado',
	},
	{
		id: 1045,
		code: 'C227',
		label: 'Otros carcinomas especificados del hígado',
	},
	{
		id: 1046,
		code: 'C229',
		label: 'Tumor maligno del hígado, no especificado',
	},
	{
		id: 1047,
		code: 'C23X',
		label: 'Tumor maligno de la vesícula biliar',
	},
	{
		id: 1048,
		code: 'C24',
		label: 'Tumor maligno de otras partes y de las no especificadas de las vías biliares',
	},
	{
		id: 1049,
		code: 'C240',
		label: 'Tumor maligno de las vías biliares extrahepáticas',
	},
	{
		id: 1050,
		code: 'C241',
		label: 'Tumor maligno de la ampolla de Vater',
	},
	{
		id: 1051,
		code: 'C248',
		label: 'Lesión de sitios contiguos de las vías biliares',
	},
	{
		id: 1052,
		code: 'C249',
		label: 'Tumor maligno de las vías biliares, parte no especificada',
	},
	{
		id: 1053,
		code: 'C25',
		label: 'Tumor maligno del páncreas',
	},
	{
		id: 1054,
		code: 'C250',
		label: 'Tumor maligno de la cabeza del páncreas',
	},
	{
		id: 1055,
		code: 'C251',
		label: 'Tumor maligno del cuerpo del páncreas',
	},
	{
		id: 1056,
		code: 'C252',
		label: 'Tumor maligno de la cola del páncreas',
	},
	{
		id: 1057,
		code: 'C253',
		label: 'Tumor maligno del conducto pancreático',
	},
	{
		id: 1058,
		code: 'C254',
		label: 'Tumor maligno del páncreas endocrino',
	},
	{
		id: 1059,
		code: 'C257',
		label: 'Tumor maligno de otras partes especificadas del páncreas',
	},
	{
		id: 1060,
		code: 'C258',
		label: 'Lesión de sitios contiguos del páncreas',
	},
	{
		id: 1061,
		code: 'C259',
		label: 'Tumor maligno del páncreas, parte no especificada',
	},
	{
		id: 1062,
		code: 'C26',
		label: 'Tumor maligno de otros sitios y de los mal definidos de los órganos digestivos',
	},
	{
		id: 1063,
		code: 'C260',
		label: 'Tumor maligno del intestino, parte no especificada',
	},
	{
		id: 1064,
		code: 'C261',
		label: 'Tumor maligno del bazo',
	},
	{
		id: 1065,
		code: 'C268',
		label: 'Lesión de sitios contiguos de los órganos digestivos',
	},
	{
		id: 1066,
		code: 'C269',
		label: 'Tumor maligno de sitios mal definidos de los órganos digestivos',
	},
	{
		id: 1067,
		code: 'C30',
		label: 'Tumor maligno de las fosas nasales y del oído medio',
	},
	{
		id: 1068,
		code: 'C300',
		label: 'Tumor maligno de la fosa nasal',
	},
	{
		id: 1069,
		code: 'C301',
		label: 'Tumor maligno del oído medio',
	},
	{
		id: 1070,
		code: 'C31',
		label: 'Tumor maligno de los senos paranasales',
	},
	{
		id: 1071,
		code: 'C310',
		label: 'Tumor maligno del seno maxilar',
	},
	{
		id: 1072,
		code: 'C311',
		label: 'Tumor maligno del seno etmoidal',
	},
	{
		id: 1073,
		code: 'C312',
		label: 'Tumor maligno del seno frontal',
	},
	{
		id: 1074,
		code: 'C313',
		label: 'Tumor maligno del seno esfenoidal',
	},
	{
		id: 1075,
		code: 'C318',
		label: 'Lesión de sitios contiguos de los senos paranasales',
	},
	{
		id: 1076,
		code: 'C319',
		label: 'Tumor maligno de seno paranasal no especificado',
	},
	{
		id: 1077,
		code: 'C32',
		label: 'Tumor maligno de la laringe',
	},
	{
		id: 1078,
		code: 'C320',
		label: 'Tumor maligno de la glotis',
	},
	{
		id: 1079,
		code: 'C321',
		label: 'Tumor maligno de la región supraglótica',
	},
	{
		id: 1080,
		code: 'C322',
		label: 'Tumor maligno de la región subglótica',
	},
	{
		id: 1081,
		code: 'C323',
		label: 'Tumor maligno del cartílago laríngeo',
	},
	{
		id: 1082,
		code: 'C328',
		label: 'Lesión de sitios contiguos de la laringe',
	},
	{
		id: 1083,
		code: 'C329',
		label: 'Tumor maligno de la laringe, parte no especificada',
	},
	{
		id: 1084,
		code: 'C33X',
		label: 'Tumor maligno de la tráquea',
	},
	{
		id: 1085,
		code: 'C34',
		label: 'Tumor maligno de los bronquios y del pulmón',
	},
	{
		id: 1086,
		code: 'C340',
		label: 'Tumor maligno del bronquio principal',
	},
	{
		id: 1087,
		code: 'C341',
		label: 'Tumor maligno del lóbulo superior, bronquio o pulmón',
	},
	{
		id: 1088,
		code: 'C342',
		label: 'Tumor maligno del lóbulo medio, bronquio o pulmón',
	},
	{
		id: 1089,
		code: 'C343',
		label: 'Tumor maligno del lóbulo inferior, bronquio o pulmón',
	},
	{
		id: 1090,
		code: 'C348',
		label: 'Lesión de sitios contiguos de los bronquios y del pulmón',
	},
	{
		id: 1091,
		code: 'C349',
		label: 'Tumor maligno de los bronquios o del pulmón, parte no especificada',
	},
	{
		id: 1092,
		code: 'C37X',
		label: 'Tumor maligno del timo',
	},
	{
		id: 1093,
		code: 'C38',
		label: 'Tumor maligno del corazón, del mediastino y de la pleura',
	},
	{
		id: 1094,
		code: 'C380',
		label: 'Tumor maligno del corazón',
	},
	{
		id: 1095,
		code: 'C381',
		label: 'Tumor maligno del mediastino anterior',
	},
	{
		id: 1096,
		code: 'C382',
		label: 'Tumor maligno del mediastino posterior',
	},
	{
		id: 1097,
		code: 'C383',
		label: 'Tumor maligno del mediastino, parte no especificada',
	},
	{
		id: 1098,
		code: 'C384',
		label: 'Tumor maligno de la pleura',
	},
	{
		id: 1099,
		code: 'C388',
		label: 'Lesión de sitos contiguos del corazón, del mediastino y de la pleura',
	},
	{
		id: 1100,
		code: 'C39',
		label: 'Tumor maligno de otros sitios y de los mal definidos del sistema respiratorio y de los órganos intratorácicos',
	},
	{
		id: 1101,
		code: 'C390',
		label: 'Tumor maligno de la vías respiratoria superiores, parte no especificada',
	},
	{
		id: 1102,
		code: 'C398',
		label: 'Lesión de sitios contiguos de los órganos respiratorios e intratorácicos',
	},
	{
		id: 1103,
		code: 'C399',
		label: 'Tumor maligno de sitios mal definidos del sistema respiratorio',
	},
	{
		id: 1104,
		code: 'C40',
		label: 'Tumor maligno de los huesos y de los cartílagos articulares de los miembros',
	},
	{
		id: 1105,
		code: 'C400',
		label: 'Tumor maligno del omóplato y de los huesos largos del miembro superior',
	},
	{
		id: 1106,
		code: 'C401',
		label: 'Tumor maligno de los huesos cortos del miembro superior',
	},
	{
		id: 1107,
		code: 'C402',
		label: 'Tumor maligno de los huesos largos del miembro inferior',
	},
	{
		id: 1108,
		code: 'C403',
		label: 'Tumor maligno de los huesos cortos del miembro inferior',
	},
	{
		id: 1109,
		code: 'C408',
		label: 'Lesión de sitios contiguos de los huesos y de los cartílagos articulares de los miembros',
	},
	{
		id: 1110,
		code: 'C409',
		label: 'Tumor maligno de los huesos y de los cartílagos articulares de los miembros, sin otra especificación',
	},
	{
		id: 1111,
		code: 'C41',
		label: 'Tumor maligno de los huesos y de los cartílagos articulares, de otros sitios y de sitios no especificados',
	},
	{
		id: 1112,
		code: 'C410',
		label: 'Tumor maligno de los huesos del cráneo y de la cara',
	},
	{
		id: 1113,
		code: 'C411',
		label: 'Tumor maligno del hueso del maxilar inferior',
	},
	{
		id: 1114,
		code: 'C412',
		label: 'Tumor maligno de la columna vertebral',
	},
	{
		id: 1115,
		code: 'C413',
		label: 'Tumor maligno de la costilla, esternón y clavícula',
	},
	{
		id: 1116,
		code: 'C414',
		label: 'Tumor maligno de los huesos de la pelvis, sacro y cóccix',
	},
	{
		id: 1117,
		code: 'C418',
		label: 'Lesión de sitios contiguos del hueso y del cartílago articular',
	},
	{
		id: 1118,
		code: 'C419',
		label: 'Tumor maligno del hueso y del cartílago articular, no especificado',
	},
	{
		id: 1119,
		code: 'C43',
		label: 'Melanoma maligno de la piel',
	},
	{
		id: 1120,
		code: 'C430',
		label: 'Melanoma maligno del labio',
	},
	{
		id: 1121,
		code: 'C431',
		label: 'Melanoma maligno del párpado, incluida la comisura palpebral',
	},
	{
		id: 1122,
		code: 'C432',
		label: 'Melanoma maligno de la oreja y del conducto auditivo externo',
	},
	{
		id: 1123,
		code: 'C433',
		label: 'Melanoma maligno de las otras partes y las no especificadas de la cara',
	},
	{
		id: 1124,
		code: 'C434',
		label: 'Melanoma maligno del cuero cabelludo y del cuello',
	},
	{
		id: 1125,
		code: 'C435',
		label: 'Melanoma maligno del tronco',
	},
	{
		id: 1126,
		code: 'C436',
		label: 'Melanoma maligno del miembro superior, incluido el hombro',
	},
	{
		id: 1127,
		code: 'C437',
		label: 'Melanoma maligno del miembro inferior, incluida la cadera',
	},
	{
		id: 1128,
		code: 'C438',
		label: 'Melanoma maligno de sitios contiguos de la piel',
	},
	{
		id: 1129,
		code: 'C439',
		label: 'Melanoma maligno de piel, sitio no especificado',
	},
	{
		id: 1130,
		code: 'C44',
		label: 'Otros tumores malignos de la piel',
	},
	{
		id: 1131,
		code: 'C440',
		label: 'Tumor maligno de la piel del labio',
	},
	{
		id: 1132,
		code: 'C441',
		label: 'Tumor maligno de la piel del párpado, incluida la comisura palpebral',
	},
	{
		id: 1133,
		code: 'C442',
		label: 'Tumor maligno de la piel de la oreja y del conducto auditivo externo',
	},
	{
		id: 1134,
		code: 'C443',
		label: 'Tumor maligno de la piel de otras partes y de las no especificadas de la cara',
	},
	{
		id: 1135,
		code: 'C444',
		label: 'Tumor maligno de la piel del cuero cabelludo y del cuello',
	},
	{
		id: 1136,
		code: 'C445',
		label: 'Tumor maligno de la piel del tronco',
	},
	{
		id: 1137,
		code: 'C446',
		label: 'Tumor maligno de la piel del miembro superior, incluido el hombro',
	},
	{
		id: 1138,
		code: 'C447',
		label: 'Tumor maligno de la piel miembro inferior, incluida la cadera',
	},
	{
		id: 1139,
		code: 'C448',
		label: 'Lesión de sitios contiguos de la piel',
	},
	{
		id: 1140,
		code: 'C449',
		label: 'Tumor maligno de la piel, sitio no especificado',
	},
	{
		id: 1141,
		code: 'C45',
		label: 'Mesotelioma',
	},
	{
		id: 1142,
		code: 'C450',
		label: 'Mesotelioma de la pleura',
	},
	{
		id: 1143,
		code: 'C451',
		label: 'Mesotelioma del peritoneo',
	},
	{
		id: 1144,
		code: 'C452',
		label: 'Mesotelioma del pericardio',
	},
	{
		id: 1145,
		code: 'C457',
		label: 'Mesotelioma de otros sitios especificados',
	},
	{
		id: 1146,
		code: 'C459',
		label: 'Mesotelioma, de sitio no especificado',
	},
	{
		id: 1147,
		code: 'C46',
		label: 'Sarcoma de Kaposi',
	},
	{
		id: 1148,
		code: 'C460',
		label: 'Sarcoma de Kaposi de la piel',
	},
	{
		id: 1149,
		code: 'C461',
		label: 'Sarcoma de Kaposi del tejido blando',
	},
	{
		id: 1150,
		code: 'C462',
		label: 'Sarcoma de Kaposi del paladar',
	},
	{
		id: 1151,
		code: 'C463',
		label: 'Sarcoma de Kaposi de los ganglios linfáticos',
	},
	{
		id: 1152,
		code: 'C467',
		label: 'Sarcoma de Kaposi de otros sitios especificados',
	},
	{
		id: 1153,
		code: 'C468',
		label: 'Sarcoma de Kaposi de múltiples órganos',
	},
	{
		id: 1154,
		code: 'C469',
		label: 'Sarcoma de Kaposi de sitio no especificado',
	},
	{
		id: 1155,
		code: 'C47',
		label: 'Tumor maligno de los nervios periféricos y del sistema nervioso autónomo',
	},
	{
		id: 1156,
		code: 'C470',
		label: 'Tumor maligno de los nervios periféricos de la cabeza, cara y cuello',
	},
	{
		id: 1157,
		code: 'C471',
		label: 'Tumor maligno de los nervios periféricos del miembro superior, incluido el hombro',
	},
	{
		id: 1158,
		code: 'C472',
		label: 'Tumor maligno de los nervios periféricos del miembro inferior, incluida la cadera',
	},
	{
		id: 1159,
		code: 'C473',
		label: 'Tumor maligno de los nervios periféricos del tórax',
	},
	{
		id: 1160,
		code: 'C474',
		label: 'Tumor maligno de los nervios periféricos del abdomen',
	},
	{
		id: 1161,
		code: 'C475',
		label: 'Tumor maligno de los nervios periféricos de la pelvis',
	},
	{
		id: 1162,
		code: 'C476',
		label: 'Tumor maligno de los nervios periféricos del tronco, sin otra especificación',
	},
	{
		id: 1163,
		code: 'C478',
		label: 'Lesión de sitios contiguos de los nervios periféricos y del sistema nervioso autónomo',
	},
	{
		id: 1164,
		code: 'C479',
		label: 'Tumor maligno de los nervios periféricos y del sistema nervioso autónomo, parte no especificada',
	},
	{
		id: 1165,
		code: 'C48',
		label: 'Tumor maligno del peritoneo y del retroperitoneo',
	},
	{
		id: 1166,
		code: 'C480',
		label: 'Tumor maligno del retroperitoneo',
	},
	{
		id: 1167,
		code: 'C481',
		label: 'Tumor maligno de parte especificada del peritoneo',
	},
	{
		id: 1168,
		code: 'C482',
		label: 'Tumor maligno del peritoneo, sin otra especificación',
	},
	{
		id: 1169,
		code: 'C488',
		label: 'Lesión de sitios contiguos del peritoneo y del retroperitoneo',
	},
	{
		id: 1170,
		code: 'C49',
		label: 'Tumor maligno de otros tejidos conjuntivos y de tejidos blandos',
	},
	{
		id: 1171,
		code: 'C490',
		label: 'Tumor maligno del tejido conjuntivo y tejido blando de la cabeza, cara y cuello',
	},
	{
		id: 1172,
		code: 'C491',
		label: 'Tumor maligno del tejido conjuntivo y tejido blando del miembro superior, incluido el hombro',
	},
	{
		id: 1173,
		code: 'C492',
		label: 'Tumor maligno del tejido conjuntivo y tejido blando del miembro inferior, incluida la cadera',
	},
	{
		id: 1174,
		code: 'C493',
		label: 'Tumor maligno del tejido conjuntivo y tejido blando del tórax',
	},
	{
		id: 1175,
		code: 'C494',
		label: 'Tumor maligno del tejido conjuntivo y tejido blando del abdomen',
	},
	{
		id: 1176,
		code: 'C495',
		label: 'Tumor maligno del tejido conjuntivo y tejido blando de la pelvis',
	},
	{
		id: 1177,
		code: 'C496',
		label: 'Tumor maligno del tejido conjuntivo y tejido blando del tronco, sin otra especificación',
	},
	{
		id: 1178,
		code: 'C498',
		label: 'Lesión de sitios contiguos  del tejido conjuntivo y del tejido blando',
	},
	{
		id: 1179,
		code: 'C499',
		label: 'Tumor maligno del tejido conjuntivo y tejido blando, de sitio no especificado',
	},
	{
		id: 1180,
		code: 'C50',
		label: 'Tumor maligno de la mama',
	},
	{
		id: 1181,
		code: 'C500',
		label: 'Tumor maligno del pezón y aréola mamaria',
	},
	{
		id: 1182,
		code: 'C501',
		label: 'Tumor maligno de la porción central de la mama',
	},
	{
		id: 1183,
		code: 'C502',
		label: 'Tumor maligno del cuadrante superior interno de la mama',
	},
	{
		id: 1184,
		code: 'C503',
		label: 'Tumor maligno del cuadrante inferior interno de la mama',
	},
	{
		id: 1185,
		code: 'C504',
		label: 'Tumor maligno del cuadrante superior externo de la mama',
	},
	{
		id: 1186,
		code: 'C505',
		label: 'Tumor maligno del cuadrante inferior externo de la mama',
	},
	{
		id: 1187,
		code: 'C506',
		label: 'Tumor maligno de la prolongación axilar de la mama',
	},
	{
		id: 1188,
		code: 'C508',
		label: 'Lesión de sitios contiguos de la mama',
	},
	{
		id: 1189,
		code: 'C509',
		label: 'Tumor maligno de la mama, parte no especificada',
	},
	{
		id: 1190,
		code: 'C51',
		label: 'Tumor maligno de la vulva',
	},
	{
		id: 1191,
		code: 'C510',
		label: 'Tumor maligno del labio mayor',
	},
	{
		id: 1192,
		code: 'C511',
		label: 'Tumor maligno del labio menor',
	},
	{
		id: 1193,
		code: 'C512',
		label: 'Tumor maligno del clítoris',
	},
	{
		id: 1194,
		code: 'C518',
		label: 'Lesión de sitios contiguos de la vulva',
	},
	{
		id: 1195,
		code: 'C519',
		label: 'Tumor maligno de la vulva, parte no especificada',
	},
	{
		id: 1196,
		code: 'C52X',
		label: 'Tumor maligno de la vagina',
	},
	{
		id: 1197,
		code: 'C53',
		label: 'Tumor maligno del cuello del útero',
	},
	{
		id: 1198,
		code: 'C530',
		label: 'Tumor maligno del endocérvix',
	},
	{
		id: 1199,
		code: 'C531',
		label: 'Tumor maligno del exocérvix',
	},
	{
		id: 1200,
		code: 'C538',
		label: 'Lesión de sitios contiguos del cuello del útero',
	},
	{
		id: 1201,
		code: 'C539',
		label: 'Tumor maligno del cuello del útero, sin otra especificación',
	},
	{
		id: 1202,
		code: 'C54',
		label: 'Tumor maligno del cuerpo del útero',
	},
	{
		id: 1203,
		code: 'C540',
		label: 'Tumor maligno del istmo uterino',
	},
	{
		id: 1204,
		code: 'C541',
		label: 'Tumor maligno del endometrio',
	},
	{
		id: 1205,
		code: 'C542',
		label: 'Tumor maligno del miometrio',
	},
	{
		id: 1206,
		code: 'C543',
		label: 'Tumor maligno del fondo del útero',
	},
	{
		id: 1207,
		code: 'C548',
		label: 'Lesión de sitios contiguos del cuerpo del útero',
	},
	{
		id: 1208,
		code: 'C549',
		label: 'Tumor maligno del cuerpo del útero, parte no especificada',
	},
	{
		id: 1209,
		code: 'C55X',
		label: 'Tumor maligno del útero, parte no especificada',
	},
	{
		id: 1210,
		code: 'C56X',
		label: 'Tumor maligno del ovario',
	},
	{
		id: 1211,
		code: 'C57',
		label: 'Tumor maligno de otros órganos genitales femeninos y de los no especificados',
	},
	{
		id: 1212,
		code: 'C570',
		label: 'Tumor maligno de la trompa de Falopio',
	},
	{
		id: 1213,
		code: 'C571',
		label: 'Tumor maligno del ligamento ancho',
	},
	{
		id: 1214,
		code: 'C572',
		label: 'Tumor maligno del ligamento redondo',
	},
	{
		id: 1215,
		code: 'C573',
		label: 'Tumor maligno del parametrio',
	},
	{
		id: 1216,
		code: 'C574',
		label: 'Tumor maligno de los anexos uterinos, sin otra especificación',
	},
	{
		id: 1217,
		code: 'C577',
		label: 'Tumor maligno de otras partes especificadas de los órganos genitales femeninos',
	},
	{
		id: 1218,
		code: 'C578',
		label: 'Lesión de sitios contiguos de los órganos genitales femeninos',
	},
	{
		id: 1219,
		code: 'C579',
		label: 'Tumor maligno de órgano genital femenino, parte no especificada',
	},
	{
		id: 1220,
		code: 'C58X',
		label: 'Tumor maligno de la placenta',
	},
	{
		id: 1221,
		code: 'C60',
		label: 'Tumor maligno del pene',
	},
	{
		id: 1222,
		code: 'C600',
		label: 'Tumor maligno del prepucio',
	},
	{
		id: 1223,
		code: 'C601',
		label: 'Tumor maligno del glande',
	},
	{
		id: 1224,
		code: 'C602',
		label: 'Tumor maligno del cuerpo del pene',
	},
	{
		id: 1225,
		code: 'C608',
		label: 'Lesión de sitios contiguos del pene',
	},
	{
		id: 1226,
		code: 'C609',
		label: 'Tumor maligno del pene, parte no especificada',
	},
	{
		id: 1227,
		code: 'C61X',
		label: 'Tumor maligno de la próstata',
	},
	{
		id: 1228,
		code: 'C62',
		label: 'Tumor maligno del testículo',
	},
	{
		id: 1229,
		code: 'C620',
		label: 'Tumor maligno del testículo no descendido',
	},
	{
		id: 1230,
		code: 'C621',
		label: 'Tumor maligno del testículo descendido',
	},
	{
		id: 1231,
		code: 'C629',
		label: 'Tumor maligno del testículo, no especificado',
	},
	{
		id: 1232,
		code: 'C63',
		label: 'Tumor maligno de otros órganos genitales maculinos y de los no especificados',
	},
	{
		id: 1233,
		code: 'C630',
		label: 'Tumor maligno del epidídimo',
	},
	{
		id: 1234,
		code: 'C631',
		label: 'Tumor maligno del cordón espermático',
	},
	{
		id: 1235,
		code: 'C632',
		label: 'Tumor maligno del escroto',
	},
	{
		id: 1236,
		code: 'C637',
		label: 'Tumor maligno de otras partes especificadas de los órganos genitales masculinos',
	},
	{
		id: 1237,
		code: 'C638',
		label: 'Lesión de sitios contiguos de los órganos genitales masculinos',
	},
	{
		id: 1238,
		code: 'C639',
		label: 'Tumor maligno de órgano genital masculino, parte no especificada',
	},
	{
		id: 1239,
		code: 'C64X',
		label: 'Tumor maligno del riñón, excepto de la pelvis renal',
	},
	{
		id: 1240,
		code: 'C65X',
		label: 'Tumor maligno de la pelvis renal',
	},
	{
		id: 1241,
		code: 'C66X',
		label: 'Tumor maligno del uréter',
	},
	{
		id: 1242,
		code: 'C67',
		label: 'Tumor maligno de la vejiga urinaria',
	},
	{
		id: 1243,
		code: 'C670',
		label: 'Tumor maligno del trígono vesical',
	},
	{
		id: 1244,
		code: 'C671',
		label: 'Tumor maligno de la cúpula vesical',
	},
	{
		id: 1245,
		code: 'C672',
		label: 'Tumor maligno de la pared lateral de la vejiga',
	},
	{
		id: 1246,
		code: 'C673',
		label: 'Tumor maligno de la pared anterior de la vejiga',
	},
	{
		id: 1247,
		code: 'C674',
		label: 'Tumor maligno de la pared posterior de la vejiga',
	},
	{
		id: 1248,
		code: 'C675',
		label: 'Tumor maligno del cuello de la vejiga',
	},
	{
		id: 1249,
		code: 'C676',
		label: 'Tumor maligno del orificio ureteral',
	},
	{
		id: 1250,
		code: 'C677',
		label: 'Tumor maligno del uraco',
	},
	{
		id: 1251,
		code: 'C678',
		label: 'Lesión de sitios contiguos de la vejiga',
	},
	{
		id: 1252,
		code: 'C679',
		label: 'Tumor maligno de la vejiga urinaria, parte no especificada',
	},
	{
		id: 1253,
		code: 'C68',
		label: 'Tumor maligno de otros óganos urinarios y de los no especificados',
	},
	{
		id: 1254,
		code: 'C680',
		label: 'Tumor maligno de la uretra',
	},
	{
		id: 1255,
		code: 'C681',
		label: 'Tumor maligno de las glándulas parauretrales',
	},
	{
		id: 1256,
		code: 'C688',
		label: 'Lesión de sitios contiguos de los órganos urinarios',
	},
	{
		id: 1257,
		code: 'C689',
		label: 'Tumor maligno de órgano urinario no especificado',
	},
	{
		id: 1258,
		code: 'C69',
		label: 'Tumor maligno del ojo y sus anexos',
	},
	{
		id: 1259,
		code: 'C690',
		label: 'Tumor maligno de la conjuntiva',
	},
	{
		id: 1260,
		code: 'C691',
		label: 'Tumor maligno de la córnea',
	},
	{
		id: 1261,
		code: 'C692',
		label: 'Tumor maligno de la retina',
	},
	{
		id: 1262,
		code: 'C693',
		label: 'Tumor maligno de la coroides',
	},
	{
		id: 1263,
		code: 'C694',
		label: 'Tumor maligno del cuerpo ciliar',
	},
	{
		id: 1264,
		code: 'C695',
		label: 'Tumor maligno de la glándula y conducto lagrimales',
	},
	{
		id: 1265,
		code: 'C696',
		label: 'Tumor maligno de la órbita',
	},
	{
		id: 1266,
		code: 'C698',
		label: 'Lesión de sitios contiguos del ojo y sus anexos',
	},
	{
		id: 1267,
		code: 'C699',
		label: 'Tumor maligno del ojo, parte no especificada',
	},
	{
		id: 1268,
		code: 'C70',
		label: 'Tumor maligno de las meninges',
	},
	{
		id: 1269,
		code: 'C700',
		label: 'Tumor maligno de las meninges cerebrales',
	},
	{
		id: 1270,
		code: 'C701',
		label: 'Tumor maligno de las meninges raquídeas',
	},
	{
		id: 1271,
		code: 'C709',
		label: 'Tumor maligno de las meninges, parte no especificada',
	},
	{
		id: 1272,
		code: 'C71',
		label: 'Tumor maligno del encéfalo',
	},
	{
		id: 1273,
		code: 'C710',
		label: 'Tumor maligno del cerebro, excepto lóbulos y ventrículos',
	},
	{
		id: 1274,
		code: 'C711',
		label: 'Tumor maligno del lóbulo frontal',
	},
	{
		id: 1275,
		code: 'C712',
		label: 'Tumor maligno del lóbulo temporal',
	},
	{
		id: 1276,
		code: 'C713',
		label: 'Tumor maligno del lóbulo parietal',
	},
	{
		id: 1277,
		code: 'C714',
		label: 'Tumor maligno del lóbulo occipital',
	},
	{
		id: 1278,
		code: 'C715',
		label: 'Tumor maligno del ventrículo cerebral',
	},
	{
		id: 1279,
		code: 'C716',
		label: 'Tumor maligno del cerebelo',
	},
	{
		id: 1280,
		code: 'C717',
		label: 'Tumor maligno del pedúnculo cerebral',
	},
	{
		id: 1281,
		code: 'C718',
		label: 'Lesión de sitios contiguos del encéfalo',
	},
	{
		id: 1282,
		code: 'C719',
		label: 'Tumor maligno del encéfalo, parte no especificada',
	},
	{
		id: 1283,
		code: 'C72',
		label: 'Tumor maligno de la médula espinal, de los nervios craneales y de otras partes del sistema nervioso central',
	},
	{
		id: 1284,
		code: 'C720',
		label: 'Tumor maligno de la médula espinal',
	},
	{
		id: 1285,
		code: 'C721',
		label: 'Tumor maligno de la cola de caballo',
	},
	{
		id: 1286,
		code: 'C722',
		label: 'Tumor maligno del nervio olfatorio',
	},
	{
		id: 1287,
		code: 'C723',
		label: 'Tumor maligno del nervio óptico',
	},
	{
		id: 1288,
		code: 'C724',
		label: 'Tumor maligno del nervio acústico',
	},
	{
		id: 1289,
		code: 'C725',
		label: 'Tumor maligno de otros nervios craneales y los no especificados',
	},
	{
		id: 1290,
		code: 'C728',
		label: 'Lesión de sitios contiguos del encéfalo y otras partes del sistema nervioso central',
	},
	{
		id: 1291,
		code: 'C729',
		label: 'Tumor maligno del sistema nervioso central, sin otra especificación',
	},
	{
		id: 1292,
		code: 'C73X',
		label: 'Tumor maligno de la glándula tiroides',
	},
	{
		id: 1293,
		code: 'C74',
		label: 'Tumor maligno de la glándula suprarrenal',
	},
	{
		id: 1294,
		code: 'C740',
		label: 'Tumor maligno de la corteza de la glándula suprarrenal',
	},
	{
		id: 1295,
		code: 'C741',
		label: 'Tumor maligno de la médula de la glándula suprarrenal',
	},
	{
		id: 1296,
		code: 'C749',
		label: 'Tumor maligno de la glándula suprarrenal, parte no especificada',
	},
	{
		id: 1297,
		code: 'C75',
		label: 'Tumor maligno de otras glándulas endocrinas y de estructuras afines',
	},
	{
		id: 1298,
		code: 'C750',
		label: 'Tumor maligno de la glándula paratiroides',
	},
	{
		id: 1299,
		code: 'C751',
		label: 'Tumor maligno de la hipófisis',
	},
	{
		id: 1300,
		code: 'C752',
		label: 'Tumor maligno del conducto craneofaríngeo',
	},
	{
		id: 1301,
		code: 'C753',
		label: 'Tumor maligno de la glándula pineal',
	},
	{
		id: 1302,
		code: 'C754',
		label: 'Tumor maligno del cuerpo carotídeo',
	},
	{
		id: 1303,
		code: 'C755',
		label: 'Tumor maligno del cuerpo aórtico y otros cuerpos cromafines',
	},
	{
		id: 1304,
		code: 'C758',
		label: 'Tumor maligno pluriglandular, no especificado',
	},
	{
		id: 1305,
		code: 'C759',
		label: 'Tumor maligno de glándula endocrina no especificada',
	},
	{
		id: 1306,
		code: 'C76',
		label: 'Tumor maligno de otros sitios y de los sitios mal definidos',
	},
	{
		id: 1307,
		code: 'C760',
		label: 'Tumor maligno de la cabeza, cara y cuello',
	},
	{
		id: 1308,
		code: 'C761',
		label: 'Tumor maligno del tórax',
	},
	{
		id: 1309,
		code: 'C762',
		label: 'Tumor maligno del abdomen',
	},
	{
		id: 1310,
		code: 'C763',
		label: 'Tumor maligno de la pelvis',
	},
	{
		id: 1311,
		code: 'C764',
		label: 'Tumor maligno del miembro superior',
	},
	{
		id: 1312,
		code: 'C765',
		label: 'Tumor maligno del miembro inferior',
	},
	{
		id: 1313,
		code: 'C767',
		label: 'Tumor maligno de otros sitios mal definidos',
	},
	{
		id: 1314,
		code: 'C768',
		label: 'Lesión de sitios contiguos mal definidos',
	},
	{
		id: 1315,
		code: 'C77',
		label: 'Tumor maligno secundario y el no especificado de los ganglios linfáticos',
	},
	{
		id: 1316,
		code: 'C770',
		label: 'Tumor maligno de los ganglios linfáticos de la cabeza, cara y cuello',
	},
	{
		id: 1317,
		code: 'C771',
		label: 'Tumor maligno de los ganglios linfáticos intratorácicos',
	},
	{
		id: 1318,
		code: 'C772',
		label: 'Tumor maligno de los ganglios linfáticos intraabdominales',
	},
	{
		id: 1319,
		code: 'C773',
		label: 'Tumor maligno de los ganglios linfáticos de la axila y del miembro superior',
	},
	{
		id: 1320,
		code: 'C774',
		label: 'Tumor maligno de los ganglios linfáticos de la región inguinal y del miembro inferior',
	},
	{
		id: 1321,
		code: 'C775',
		label: 'Tumor maligno de los ganglios linfáticos de la pelvis',
	},
	{
		id: 1322,
		code: 'C778',
		label: 'Tumor maligno de los ganglios linfáticos de regiones múltiples',
	},
	{
		id: 1323,
		code: 'C779',
		label: 'Tumor maligno del ganglio linfático, sitio no especificado',
	},
	{
		id: 1324,
		code: 'C78',
		label: 'Tumor maligno secundario de los órganos respiratorios y digestivos',
	},
	{
		id: 1325,
		code: 'C780',
		label: 'Tumor maligno secundario del pulmón',
	},
	{
		id: 1326,
		code: 'C781',
		label: 'Tumor maligno secundario del mediastino',
	},
	{
		id: 1327,
		code: 'C782',
		label: 'Tumor maligno secundario de la pleura',
	},
	{
		id: 1328,
		code: 'C783',
		label: 'Tumor maligno secundario de otros órganos respiratorios y de los no especificados',
	},
	{
		id: 1329,
		code: 'C784',
		label: 'Tumor maligno secundario del intestino delgado',
	},
	{
		id: 1330,
		code: 'C785',
		label: 'Tumor maligno secundario del intestino grueso y del recto',
	},
	{
		id: 1331,
		code: 'C786',
		label: 'Tumor maligno secundario del peritoneo y del retroperitoneo',
	},
	{
		id: 1332,
		code: 'C787',
		label: 'Tumor maligno secundario del hígado y de los conductos biliares intrahepáticos',
	},
	{
		id: 1333,
		code: 'C788',
		label: 'Tumor maligno secundario de otros órganos digestivos y de los no especificados',
	},
	{
		id: 1334,
		code: 'C79',
		label: 'Tumor maligno secundario de otros sitios y de sitios no especificados',
	},
	{
		id: 1335,
		code: 'C790',
		label: 'Tumor maligno secundario del riñón y de la pelvis renal',
	},
	{
		id: 1336,
		code: 'C791',
		label: 'Tumor maligno secundario de la vejiga, y de otros órganos y los no especificados de las vías urinarias',
	},
	{
		id: 1337,
		code: 'C792',
		label: 'Tumor maligno secundario de la piel',
	},
	{
		id: 1338,
		code: 'C793',
		label: 'Tumor maligno secundario del encéfalo y de las meninges cerebrales',
	},
	{
		id: 1339,
		code: 'C794',
		label: 'Tumor maligno secundario de otras partes del sistema nervioso y de las no especificadas',
	},
	{
		id: 1340,
		code: 'C795',
		label: 'Tumor maligno secundario de los huesos y de la médula ósea',
	},
	{
		id: 1341,
		code: 'C796',
		label: 'Tumor maligno secundario del ovario',
	},
	{
		id: 1342,
		code: 'C797',
		label: 'Tumor maligno secundario de la glándula suprarrenal',
	},
	{
		id: 1343,
		code: 'C798',
		label: 'Tumor maligno secundario de otros sitios especificados',
	},
	{
		id: 1344,
		code: 'C799',
		label: 'Tumor maligno secundario, sitio no especificado',
	},
	{
		id: 1345,
		code: 'C80',
		label: 'Tumor maligno de sitios no especificados',
	},
	{
		id: 1346,
		code: 'C800',
		label: 'Tumor maligno, de sitio primario desconocido, así descrito ',
	},
	{
		id: 1347,
		code: 'C809',
		label: 'Tumor maligno, sitio primario no especificado',
	},
	{
		id: 1348,
		code: 'C80X',
		label: 'Tumor maligno de sitios no especificados',
	},
	{
		id: 1349,
		code: 'C81',
		label: 'Linfoma de Hodgkin',
	},
	{
		id: 1350,
		code: 'C810',
		label: 'Linfoma de Hodgkin con predominio linfocítico nodular',
	},
	{
		id: 1351,
		code: 'C811',
		label: 'Linfoma de Hodgkin (clásico) con esclerosis nodular',
	},
	{
		id: 1352,
		code: 'C812',
		label: 'Linfoma de Hodgkin (clásico) con celularidad mixta',
	},
	{
		id: 1353,
		code: 'C813',
		label: 'Linfoma de Hodgkin (clásico) con depleción linfocítica',
	},
	{
		id: 1354,
		code: 'C814',
		label: 'Linfoma de Hodgkin clásico rico en linfocitos ',
	},
	{
		id: 1355,
		code: 'C817',
		label: 'Otros tipos de linfomas de Hodgkin (clásicos)',
	},
	{
		id: 1356,
		code: 'C819',
		label: 'Linfoma de Hodgkin, no especificado',
	},
	{
		id: 1357,
		code: 'C82',
		label: 'Linfoma folicular',
	},
	{
		id: 1358,
		code: 'C820',
		label: 'Linfoma folicular grado I',
	},
	{
		id: 1359,
		code: 'C821',
		label: 'Linfoma folicular grado II',
	},
	{
		id: 1360,
		code: 'C822',
		label: 'Linfoma folicular grado III no especificado',
	},
	{
		id: 1361,
		code: 'C823',
		label: 'Linfoma folicular grado IIIa ',
	},
	{
		id: 1362,
		code: 'C824',
		label: 'Linfoma folicular grado IIIb ',
	},
	{
		id: 1363,
		code: 'C825',
		label: 'Linfoma centro folicular difuso ',
	},
	{
		id: 1364,
		code: 'C826',
		label: 'Linfoma centro folicular cutáneo ',
	},
	{
		id: 1365,
		code: 'C827',
		label: 'Otros tipos especificados de linfoma folicular',
	},
	{
		id: 1366,
		code: 'C829',
		label: 'Linfoma folicular, sin otra especificación',
	},
	{
		id: 1367,
		code: 'C83',
		label: 'Linfoma no folicular',
	},
	{
		id: 1368,
		code: 'C830',
		label: 'Linfoma de células B pequeñas',
	},
	{
		id: 1369,
		code: 'C831',
		label: 'Linfoma de células del manto',
	},
	{
		id: 1370,
		code: 'C832',
		label: 'Linfoma no Hodgkin mixto, de células pequeñas y grandes (difuso)',
	},
	{
		id: 1371,
		code: 'C833',
		label: 'Linfoma de células grandes  B difuso',
	},
	{
		id: 1372,
		code: 'C834',
		label: 'Linfoma no Hodgkin inmunoblástico (difuso)',
	},
	{
		id: 1373,
		code: 'C835',
		label: 'Linfoma linfoblástico (difuso)',
	},
	{
		id: 1374,
		code: 'C836',
		label: 'Linfoma no Hodgkin indiferenciado (difuso)',
	},
	{
		id: 1375,
		code: 'C837',
		label: 'Linfoma de Burkitt',
	},
	{
		id: 1376,
		code: 'C838',
		label: 'Otros tipos especificados de linfoma no folicular',
	},
	{
		id: 1377,
		code: 'C839',
		label: 'Linfoma no folicular (difuso), sin otra especificación',
	},
	{
		id: 1378,
		code: 'C84',
		label: 'Linfomas de células T/NK maduras ',
	},
	{
		id: 1379,
		code: 'C840',
		label: 'Micosis fungoide',
	},
	{
		id: 1380,
		code: 'C841',
		label: 'Enfermedad de Sézary',
	},
	{
		id: 1381,
		code: 'C842',
		label: 'Linfoma de zona T',
	},
	{
		id: 1382,
		code: 'C843',
		label: 'Linfoma linfoepitelioide',
	},
	{
		id: 1383,
		code: 'C844',
		label: 'Linfoma periferico de células T, no clasificados en otra parte',
	},
	{
		id: 1384,
		code: 'C845',
		label: 'Otros linfomas de células T/NK maduras',
	},
	{
		id: 1385,
		code: 'C846',
		label: 'Linfoma anaplásico de células grandes ALK-positivo ',
	},
	{
		id: 1386,
		code: 'C847',
		label: 'Linfoma anaplásico de células grandes ALK-negativo ',
	},
	{
		id: 1387,
		code: 'C848',
		label: 'Linfoma cutáneo de células T, no especificado',
	},
	{
		id: 1388,
		code: 'C849',
		label: 'Linfoma de células T/NK maduras, no especificado ',
	},
	{
		id: 1389,
		code: 'C85',
		label: 'Linfoma no Hodgkin de otro tipo y el no especificado',
	},
	{
		id: 1390,
		code: 'C850',
		label: 'Linfosarcoma',
	},
	{
		id: 1391,
		code: 'C851',
		label: 'Linfoma de células B, sin otra especificación',
	},
	{
		id: 1392,
		code: 'C852',
		label: 'Linfoma mediastinal de células B grandes (del timo',
	},
	{
		id: 1393,
		code: 'C857',
		label: 'Otros tipos especificados de linfoma no Hodgkin',
	},
	{
		id: 1394,
		code: 'C859',
		label: 'Linfoma no Hodgkin, no especificado',
	},
	{
		id: 1395,
		code: 'C86',
		label: 'Otros tipos especificados de linfoma de células T/NK  ',
	},
	{
		id: 1396,
		code: 'C860',
		label: 'Linfoma extranodal de células T/NK, tipo nasal ',
	},
	{
		id: 1397,
		code: 'C861',
		label: 'Linfoma hepatoesplénico de células T  ',
	},
	{
		id: 1398,
		code: 'C862',
		label: 'Linfoma de células T tipo enteropatía (intestinal)  ',
	},
	{
		id: 1399,
		code: 'C863',
		label: 'Linfoma de células T tipo paniculitis subcutánea  ',
	},
	{
		id: 1400,
		code: 'C864',
		label: 'Linfoma blástico de células NK ',
	},
	{
		id: 1401,
		code: 'C865',
		label: 'Linfoma angioinmunoblástico de células T  ',
	},
	{
		id: 1402,
		code: 'C866',
		label: 'Trastornos linfoproliferativos primario cutáneos de células T CD30-positivo',
	},
	{
		id: 1403,
		code: 'C88',
		label: 'Otros linfomas de células B [enfermedades inmunoproliferativas malignas]',
	},
	{
		id: 1404,
		code: 'C880',
		label: 'Macroglobulinemia de Waldenström',
	},
	{
		id: 1405,
		code: 'C881',
		label: 'Enfermedad de cadena pesada alfa',
	},
	{
		id: 1406,
		code: 'C882',
		label: 'Otras enfermedades de cadena pesada',
	},
	{
		id: 1407,
		code: 'C883',
		label: 'Enfermedad inmunoproliferativa del intestino delgado',
	},
	{
		id: 1408,
		code: 'C884',
		label: 'Linfoma de células B extranodal de zona marginal de tejido linfoide asociado a mucosas [Linfoma TLAM] ',
	},
	{
		id: 1409,
		code: 'C887',
		label: 'Otras enfermedades inmunoproliferativas malignas',
	},
	{
		id: 1410,
		code: 'C889',
		label: 'Enfermedad inmunoproliferativa maligna, sin otra especificación',
	},
	{
		id: 1411,
		code: 'C90',
		label: 'Mieloma múltiple y tumores malignos de células plasmáticas',
	},
	{
		id: 1412,
		code: 'C900',
		label: 'Mieloma múltiple',
	},
	{
		id: 1413,
		code: 'C901',
		label: 'Leucemia de células plasmáticas',
	},
	{
		id: 1414,
		code: 'C902',
		label: 'Plasmocitoma extramedular',
	},
	{
		id: 1415,
		code: 'C903',
		label: 'Plasmocitoma solitario ',
	},
	{
		id: 1416,
		code: 'C91',
		label: 'Leucemia linfoide',
	},
	{
		id: 1417,
		code: 'C910',
		label: 'Leucemia linfoblástica aguda [LLA]',
	},
	{
		id: 1418,
		code: 'C911',
		label: 'Leucemia linfocítica crónica de célula tipo B',
	},
	{
		id: 1419,
		code: 'C912',
		label: 'Leucemia linfocítica subaguda',
	},
	{
		id: 1420,
		code: 'C913',
		label: 'Leucemia prolinfocítica de célula tipo B',
	},
	{
		id: 1421,
		code: 'C914',
		label: 'Leucemia de células vellosas',
	},
	{
		id: 1422,
		code: 'C915',
		label: 'Leucemia/linfoma de células T adultas [HTLV-1-asociado]',
	},
	{
		id: 1423,
		code: 'C916',
		label: 'Leucemia prolinfocítica de células tipo T ',
	},
	{
		id: 1424,
		code: 'C917',
		label: 'Otras leucemias linfoides',
	},
	{
		id: 1425,
		code: 'C918',
		label: 'Leucemia tipo Burkitt de células B maduras ',
	},
	{
		id: 1426,
		code: 'C919',
		label: 'Leucemia linfoide, sin otra especificación',
	},
	{
		id: 1427,
		code: 'C92',
		label: 'Leucemia mieloide',
	},
	{
		id: 1428,
		code: 'C920',
		label: 'Leucemia mieloblástica aguda [LMA]',
	},
	{
		id: 1429,
		code: 'C921',
		label: 'Leucemia mieloide crónica [LMC], BCR/ABL-positivo',
	},
	{
		id: 1430,
		code: 'C922',
		label: 'Leucemia mieloide crónica atípica, BCR/ABL-negativo',
	},
	{
		id: 1431,
		code: 'C923',
		label: 'Sarcoma mieloide',
	},
	{
		id: 1432,
		code: 'C924',
		label: 'Leucemia promielocítica aguda [LPA]',
	},
	{
		id: 1433,
		code: 'C925',
		label: 'Leucemia mielomonocítica aguda',
	},
	{
		id: 1434,
		code: 'C926',
		label: 'Leucemia mieloide aguda con anormalidad 11q23',
	},
	{
		id: 1435,
		code: 'C927',
		label: 'Otras leucemias mieloides',
	},
	{
		id: 1436,
		code: 'C928',
		label: 'Leucemia mieloide aguda con displasia multilinaje',
	},
	{
		id: 1437,
		code: 'C929',
		label: 'Leucemia mieloide, sin otra especificación',
	},
	{
		id: 1438,
		code: 'C93',
		label: 'Leucemia monocítica',
	},
	{
		id: 1439,
		code: 'C930',
		label: 'Leucemia monocítica/monoblástica aguda',
	},
	{
		id: 1440,
		code: 'C931',
		label: 'Leucemia mielomonocítica crónica',
	},
	{
		id: 1441,
		code: 'C932',
		label: 'Leucemia monocítica subaguda',
	},
	{
		id: 1442,
		code: 'C933',
		label: 'Leucemia mielomonocítica juvenil ',
	},
	{
		id: 1443,
		code: 'C937',
		label: 'Otras leucemias monocíticas',
	},
	{
		id: 1444,
		code: 'C939',
		label: 'Leucemia monocítica, sin otra especificación',
	},
	{
		id: 1445,
		code: 'C94',
		label: 'Otras leucemias de tipo celular especificado',
	},
	{
		id: 1446,
		code: 'C940',
		label: 'Leucemia  eritroide aguda',
	},
	{
		id: 1447,
		code: 'C941',
		label: 'Eritremia crónica',
	},
	{
		id: 1448,
		code: 'C942',
		label: 'Leucemia megacarioblástica aguda',
	},
	{
		id: 1449,
		code: 'C943',
		label: 'Leucemia de mastocitos',
	},
	{
		id: 1450,
		code: 'C944',
		label: 'Panmielosis aguda con mielofibrosis',
	},
	{
		id: 1451,
		code: 'C945',
		label: 'Mielofibrosis aguda',
	},
	{
		id: 1452,
		code: 'C946',
		label: 'Enfermedad mielodisplásica y mieloproliferativa, no clasificada en otra parte ',
	},
	{
		id: 1453,
		code: 'C947',
		label: 'Otras leucemias especificadas',
	},
	{
		id: 1454,
		code: 'C95',
		label: 'Leucemia de células de tipo no especificado',
	},
	{
		id: 1455,
		code: 'C950',
		label: 'Leucemia aguda, células de tipo no especificado',
	},
	{
		id: 1456,
		code: 'C951',
		label: 'Leucemia crónica, células de tipo no especificado',
	},
	{
		id: 1457,
		code: 'C952',
		label: 'Leucemia subaguda, células de tipo no especificado',
	},
	{
		id: 1458,
		code: 'C957',
		label: 'Otras leucemias de células de tipo no especificado',
	},
	{
		id: 1459,
		code: 'C959',
		label: 'Leucemia, no especificada',
	},
	{
		id: 1460,
		code: 'C96',
		label: 'Otros tumores malignos y los no especificados del tejido linfático, de los órganos hematopoyéticos y de tejidos afines',
	},
	{
		id: 1461,
		code: 'C960',
		label: ' Histiocitosis de células de Langerhans multifocal y multisistémica (diseminada [Enfermedad de Letterer-Siwe]',
	},
	{
		id: 1462,
		code: 'C961',
		label: 'Histiocitosis maligna',
	},
	{
		id: 1463,
		code: 'C962',
		label: 'Tumor maligno de mastocitos',
	},
	{
		id: 1464,
		code: 'C963',
		label: 'Linfoma histiocítico verdadero',
	},
	{
		id: 1465,
		code: 'C964',
		label: 'Sarcoma de células dendríticas (células accesorias',
	},
	{
		id: 1466,
		code: 'C965',
		label: 'Histiocitosis de células de Langerhans multifocal y unisistémica',
	},
	{
		id: 1467,
		code: 'C966',
		label: 'Histiocitosis de células de Langerhans unifocal ',
	},
	{
		id: 1468,
		code: 'C967',
		label: 'Otros tumores malignos especificados del tejido linfático, hematopoyético y tejidos afines',
	},
	{
		id: 1469,
		code: 'C968',
		label: 'Sarcoma histiocítico',
	},
	{
		id: 1470,
		code: 'C969',
		label: 'Tumor maligno del tejido linfático, hematopoyético y tejidos afines, sin otra especificación',
	},
	{
		id: 1471,
		code: 'C97X',
		label: 'Tumores malignos (primarios) de sitios múltiples independientes',
	},
	{
		id: 1472,
		code: 'D00',
		label: 'Carcinoma in situ de la cavidad bucal, del esófago y del estómago',
	},
	{
		id: 1473,
		code: 'D000',
		label: 'Carcinoma in situ del labio, de la cavidad bucal y de la faringe',
	},
	{
		id: 1474,
		code: 'D001',
		label: 'Carcinoma in situ del esófago',
	},
	{
		id: 1475,
		code: 'D002',
		label: 'Carcinoma in situ del estómago',
	},
	{
		id: 1476,
		code: 'D01',
		label: 'Carcinoma in situ de otros órganos digestivos y de los no especificados',
	},
	{
		id: 1477,
		code: 'D010',
		label: 'Carcinoma in situ del colon',
	},
	{
		id: 1478,
		code: 'D011',
		label: 'Carcinoma in situ de la unión rectosigmoidea',
	},
	{
		id: 1479,
		code: 'D012',
		label: 'Carcinoma in situ del recto',
	},
	{
		id: 1480,
		code: 'D013',
		label: 'Carcinoma in situ del ano y del conducto anal',
	},
	{
		id: 1481,
		code: 'D014',
		label: 'Carcinoma in situ de otras partes y de las no especificadas del intestino',
	},
	{
		id: 1482,
		code: 'D015',
		label: 'Carcinoma in situ del hígado, de la vesícula biliar y del conducto biliar',
	},
	{
		id: 1483,
		code: 'D017',
		label: 'Carcinoma in situ de otras partes especificadas de órganos digestivos',
	},
	{
		id: 1484,
		code: 'D019',
		label: 'Carcinoma in situ de órganos digestivos no especificados',
	},
	{
		id: 1485,
		code: 'D02',
		label: 'Carcinoma in situ del sistema respiratorio y del oído medio',
	},
	{
		id: 1486,
		code: 'D020',
		label: 'Carcinoma in situ de la laringe',
	},
	{
		id: 1487,
		code: 'D021',
		label: 'Carcinoma in situ de la tráquea',
	},
	{
		id: 1488,
		code: 'D022',
		label: 'Carcinoma in situ del bronquio y del pulmón',
	},
	{
		id: 1489,
		code: 'D023',
		label: 'Carcinoma in situ de otras partes del sistema respiratorio',
	},
	{
		id: 1490,
		code: 'D024',
		label: 'Carcinoma in situ de órganos respiratorios no especificados',
	},
	{
		id: 1491,
		code: 'D03',
		label: 'Melanoma in situ',
	},
	{
		id: 1492,
		code: 'D030',
		label: 'Melanoma in situ del labio',
	},
	{
		id: 1493,
		code: 'D031',
		label: 'Melanoma in situ del párpado y de la comisura palpebral',
	},
	{
		id: 1494,
		code: 'D032',
		label: 'Melanoma in situ de la oreja y del conducto auditivo externo',
	},
	{
		id: 1495,
		code: 'D033',
		label: 'Melanoma in situ de otras partes y de las no especificadas de la cara',
	},
	{
		id: 1496,
		code: 'D034',
		label: 'Melanoma in situ del cuero cabelludo y del cuello',
	},
	{
		id: 1497,
		code: 'D035',
		label: 'Melanoma in situ del tronco',
	},
	{
		id: 1498,
		code: 'D036',
		label: 'Melanoma in situ del miembro superior, incluido el hombro',
	},
	{
		id: 1499,
		code: 'D037',
		label: 'Melanoma in situ del miembro inferior, incluida la cadera',
	},
	{
		id: 1500,
		code: 'D038',
		label: 'Melanoma in situ de otros sitios',
	},
	{
		id: 1501,
		code: 'D039',
		label: 'Melanoma in situ, sitio no especificado',
	},
	{
		id: 1502,
		code: 'D04',
		label: 'Carcinoma in situ de la piel',
	},
	{
		id: 1503,
		code: 'D040',
		label: 'Carcinoma in situ de la piel del labio',
	},
	{
		id: 1504,
		code: 'D041',
		label: 'Carcinoma in situ de la piel del párpado y de la comisura palpebral',
	},
	{
		id: 1505,
		code: 'D042',
		label: 'Carcinoma in situ de la piel de la oreja y del conducto auditivo externo',
	},
	{
		id: 1506,
		code: 'D043',
		label: 'Carcinoma in situ de la piel de otras partes y de las no especificadas de la cara',
	},
	{
		id: 1507,
		code: 'D044',
		label: 'Carcinoma in situ de la piel del cuero cabelludo y cuello',
	},
	{
		id: 1508,
		code: 'D045',
		label: 'Carcinoma in situ de la piel del tronco',
	},
	{
		id: 1509,
		code: 'D046',
		label: 'Carcinoma in situ de la piel del miembro superior, incluido el hombro',
	},
	{
		id: 1510,
		code: 'D047',
		label: 'Carcinoma in situ de la piel del miembro inferior, incluida la cadera',
	},
	{
		id: 1511,
		code: 'D048',
		label: 'Carcinoma in situ de la piel de otros sitios especificados',
	},
	{
		id: 1512,
		code: 'D049',
		label: 'Carcinoma in situ de la piel, sitio no especificado',
	},
	{
		id: 1513,
		code: 'D05',
		label: 'Carcinoma in situ de la mama',
	},
	{
		id: 1514,
		code: 'D050',
		label: 'Carcinoma in situ lobular',
	},
	{
		id: 1515,
		code: 'D051',
		label: 'Carcinoma in situ intracanalicular',
	},
	{
		id: 1516,
		code: 'D057',
		label: 'Otros carcinomas in situ de la mama',
	},
	{
		id: 1517,
		code: 'D059',
		label: 'Carcinoma in situ de la mama, parte no especificada',
	},
	{
		id: 1518,
		code: 'D06',
		label: 'Carcinoma in situ del cuello del útero',
	},
	{
		id: 1519,
		code: 'D060',
		label: 'Carcinoma in situ del endocérvix',
	},
	{
		id: 1520,
		code: 'D061',
		label: 'Carcinoma in situ del exocérvix',
	},
	{
		id: 1521,
		code: 'D067',
		label: 'Carcinoma in situ de otras partes especificadas del cuello del útero',
	},
	{
		id: 1522,
		code: 'D069',
		label: 'Carcinoma in situ del cuello del útero, parte no especificada',
	},
	{
		id: 1523,
		code: 'D07',
		label: 'Carcinoma in situ de otros órganos genitales y de los no especificados',
	},
	{
		id: 1524,
		code: 'D070',
		label: 'Carcinoma in situ del endometrio',
	},
	{
		id: 1525,
		code: 'D071',
		label: 'Carcinoma in situ de la vulva',
	},
	{
		id: 1526,
		code: 'D072',
		label: 'Carcinoma in situ de la vagina',
	},
	{
		id: 1527,
		code: 'D073',
		label: 'Carcinoma in situ de otros sitios de órganos genitales femeninos y de los no especificados',
	},
	{
		id: 1528,
		code: 'D074',
		label: 'Carcinoma in situ del pene',
	},
	{
		id: 1529,
		code: 'D075',
		label: 'Carcinoma in situ de la próstata',
	},
	{
		id: 1530,
		code: 'D076',
		label: 'Carcinoma in situ de otros órganos genitales masculinos y de los no especificados',
	},
	{
		id: 1531,
		code: 'D09',
		label: 'Carcinoma in situ de otros sitios y de los no especificados',
	},
	{
		id: 1532,
		code: 'D090',
		label: 'Carcinoma in situ de la vejiga',
	},
	{
		id: 1533,
		code: 'D091',
		label: 'Carcinoma in situ de otros órganos urinarios y de los no especificados',
	},
	{
		id: 1534,
		code: 'D092',
		label: 'Carcinoma in situ del ojo',
	},
	{
		id: 1535,
		code: 'D093',
		label: 'Carcinoma in situ de la glándula tiroides y de otras glándulas endocrinas',
	},
	{
		id: 1536,
		code: 'D097',
		label: 'Carcinoma in situ de otros sitios especificados',
	},
	{
		id: 1537,
		code: 'D099',
		label: 'Carcinoma in situ, sitio no especificado',
	},
	{
		id: 1538,
		code: 'D10',
		label: 'Tumor benigno de la boca y de la faringe',
	},
	{
		id: 1539,
		code: 'D100',
		label: 'Tumor benigno del labio',
	},
	{
		id: 1540,
		code: 'D101',
		label: 'Tumor benigno de la lengua',
	},
	{
		id: 1541,
		code: 'D102',
		label: 'Tumor benigno del piso de la boca',
	},
	{
		id: 1542,
		code: 'D103',
		label: 'Tumor benigno de otras partes y de las no especificadas de la boca',
	},
	{
		id: 1543,
		code: 'D104',
		label: 'Tumor benigno de la amígdala',
	},
	{
		id: 1544,
		code: 'D105',
		label: 'Tumor benigno de otras partes de la orofaringe',
	},
	{
		id: 1545,
		code: 'D106',
		label: 'Tumor benigno de la nasofaringe',
	},
	{
		id: 1546,
		code: 'D107',
		label: 'Tumor benigno de la hipofaringe',
	},
	{
		id: 1547,
		code: 'D109',
		label: 'Tumor benigno de la faringe, parte no especificada',
	},
	{
		id: 1548,
		code: 'D11',
		label: 'Tumor benigno de las glándulas salivales mayores',
	},
	{
		id: 1549,
		code: 'D110',
		label: 'Tumor benigno de la glándula parótida',
	},
	{
		id: 1550,
		code: 'D117',
		label: 'Tumor benigno de otras glándulas salivales mayores especificadas',
	},
	{
		id: 1551,
		code: 'D119',
		label: 'Tumor benigno de la glándula salival mayor, sin otra especificación',
	},
	{
		id: 1552,
		code: 'D12',
		label: 'Tumor benigno del colon, del recto, del conducto anal y del ano',
	},
	{
		id: 1553,
		code: 'D120',
		label: 'Tumor benigno del ciego',
	},
	{
		id: 1554,
		code: 'D121',
		label: 'Tumor benigno del apéndice',
	},
	{
		id: 1555,
		code: 'D122',
		label: 'Tumor benigno del colon ascendente',
	},
	{
		id: 1556,
		code: 'D123',
		label: 'Tumor benigno del colon transverso',
	},
	{
		id: 1557,
		code: 'D124',
		label: 'Tumor benigno del colon descendente',
	},
	{
		id: 1558,
		code: 'D125',
		label: 'Tumor benigno del colon sigmoide',
	},
	{
		id: 1559,
		code: 'D126',
		label: 'Tumor benigno del colon, parte no especificada',
	},
	{
		id: 1560,
		code: 'D127',
		label: 'Tumor benigno de la unión rectosigmoidea',
	},
	{
		id: 1561,
		code: 'D128',
		label: 'Tumor benigno del recto',
	},
	{
		id: 1562,
		code: 'D129',
		label: 'Tumor benigno del conducto anal y del ano',
	},
	{
		id: 1563,
		code: 'D13',
		label: 'Tumor benigno de otras partes y de las mal definidas del sistema digestivo',
	},
	{
		id: 1564,
		code: 'D130',
		label: 'Tumor benigno del esófago',
	},
	{
		id: 1565,
		code: 'D131',
		label: 'Tumor benigno del estómago',
	},
	{
		id: 1566,
		code: 'D132',
		label: 'Tumor benigno del duodeno',
	},
	{
		id: 1567,
		code: 'D133',
		label: 'Tumor benigno de otras partes y de las no especificadas del intestino delgado',
	},
	{
		id: 1568,
		code: 'D134',
		label: 'Tumor benigno del hígado',
	},
	{
		id: 1569,
		code: 'D135',
		label: 'Tumor benigno de las vías biliares extrahepáticas',
	},
	{
		id: 1570,
		code: 'D136',
		label: 'Tumor benigno del páncreas',
	},
	{
		id: 1571,
		code: 'D137',
		label: 'Tumor benigno del páncreas endocrino',
	},
	{
		id: 1572,
		code: 'D139',
		label: 'Tumor benigno de sitios mal definidos del sistema digestivo',
	},
	{
		id: 1573,
		code: 'D14',
		label: 'Tumor benigno del oído medio y del sistema respiratorio',
	},
	{
		id: 1574,
		code: 'D140',
		label: 'Tumor benigno del oído medio, de la cavidad nasal y de los senos paranasales',
	},
	{
		id: 1575,
		code: 'D141',
		label: 'Tumor benigno de la laringe',
	},
	{
		id: 1576,
		code: 'D142',
		label: 'Tumor benigno de la tráquea',
	},
	{
		id: 1577,
		code: 'D143',
		label: 'Tumor benigno de los bronquios y del pulmón',
	},
	{
		id: 1578,
		code: 'D144',
		label: 'Tumor benigno del sistema respiratorio, sitio no especificado',
	},
	{
		id: 1579,
		code: 'D15',
		label: 'Tumor benigno de otros órganos intratorácicos y de los no especificados',
	},
	{
		id: 1580,
		code: 'D150',
		label: 'Tumor benigno del timo',
	},
	{
		id: 1581,
		code: 'D151',
		label: 'Tumor benigno del corazón',
	},
	{
		id: 1582,
		code: 'D152',
		label: 'Tumor benigno del mediastino',
	},
	{
		id: 1583,
		code: 'D157',
		label: 'Tumor benigno de otros órganos intratorácicos especificados',
	},
	{
		id: 1584,
		code: 'D159',
		label: 'Tumor benigno de órgano intratorácico no especificado',
	},
	{
		id: 1585,
		code: 'D16',
		label: 'Tumor benigno del hueso y del cartílago articular',
	},
	{
		id: 1586,
		code: 'D160',
		label: 'Tumor benigno del omóplato y huesos largos del miembro superior',
	},
	{
		id: 1587,
		code: 'D161',
		label: 'Tumor benigno de los huesos cortos del miembro superior',
	},
	{
		id: 1588,
		code: 'D162',
		label: 'Tumor benigno de los huesos largos del miembro inferior',
	},
	{
		id: 1589,
		code: 'D163',
		label: 'Tumor benigno de los huesos cortos del miembro inferior',
	},
	{
		id: 1590,
		code: 'D164',
		label: 'Tumor benigno de los huesos del cráneo y de la cara',
	},
	{
		id: 1591,
		code: 'D165',
		label: 'Tumor benigno del maxilar inferior',
	},
	{
		id: 1592,
		code: 'D166',
		label: 'Tumor benigno de la columna vertebral',
	},
	{
		id: 1593,
		code: 'D167',
		label: 'Tumor benigno de las costillas, esternón y clavícula',
	},
	{
		id: 1594,
		code: 'D168',
		label: 'Tumor benigno de los huesos pélvicos, sacro y cóccix',
	},
	{
		id: 1595,
		code: 'D169',
		label: 'Tumor benigno del hueso y del cartílago articular, sitio no especificado',
	},
	{
		id: 1596,
		code: 'D17',
		label: 'Tumores benignos lipomatosos',
	},
	{
		id: 1597,
		code: 'D170',
		label: 'Tumor benigno lipomatoso de piel y de tejido subcutáneo de cabeza, cara y cuello',
	},
	{
		id: 1598,
		code: 'D171',
		label: 'Tumor benigno lipomatoso de piel y de tejido subcutáneo del tronco',
	},
	{
		id: 1599,
		code: 'D172',
		label: 'Tumor benigno lipomatoso de piel y de tejido subcutáneo de miembros',
	},
	{
		id: 1600,
		code: 'D173',
		label: 'Tumor benigno lipomatoso de piel y de tejido subcutáneo de otros sitios y de los no especificados',
	},
	{
		id: 1601,
		code: 'D174',
		label: 'Tumor benigno lipomatoso de los órganos intratorácicos',
	},
	{
		id: 1602,
		code: 'D175',
		label: 'Tumor benigno lipomatoso de los órganos intraabdominales',
	},
	{
		id: 1603,
		code: 'D176',
		label: 'Tumor benigno lipomatoso del cordón espermático',
	},
	{
		id: 1604,
		code: 'D177',
		label: 'Tumor benigno lipomatoso de otros sitios especificados',
	},
	{
		id: 1605,
		code: 'D179',
		label: 'Tumor benigno lipomatoso, de sitio no especificado',
	},
	{
		id: 1606,
		code: 'D18',
		label: 'Hemangioma y linfangioma de cualquier sitio',
	},
	{
		id: 1607,
		code: 'D180',
		label: 'Hemangioma, de cualquier sitio',
	},
	{
		id: 1608,
		code: 'D181',
		label: 'Linfangioma, de cualquier sitio',
	},
	{
		id: 1609,
		code: 'D19',
		label: 'Tumores benignos del tejido mesotelial',
	},
	{
		id: 1610,
		code: 'D190',
		label: 'Tumor benigno del tejido mesotelial de la pleura',
	},
	{
		id: 1611,
		code: 'D191',
		label: 'Tumor benigno del tejido mesotelial del peritoneo',
	},
	{
		id: 1612,
		code: 'D197',
		label: 'Tumor benigno del tejido mesotelial de otros sitios especificados',
	},
	{
		id: 1613,
		code: 'D199',
		label: 'Tumor benigno del tejido mesotelial, de sitio no especificado',
	},
	{
		id: 1614,
		code: 'D20',
		label: 'Tumor benigno del tejido blando del peritoneo y del retroperitoneo',
	},
	{
		id: 1615,
		code: 'D200',
		label: 'Tumor benigno del retroperitoneo',
	},
	{
		id: 1616,
		code: 'D201',
		label: 'Tumor benigno del peritoneo',
	},
	{
		id: 1617,
		code: 'D21',
		label: 'Otros tumores benignos del tejido conjuntivo y de los tejidos blandos',
	},
	{
		id: 1618,
		code: 'D210',
		label: 'Tumor benigno del tejido conjuntivo y de otros tejidos blandos de cabeza, cara y cuello',
	},
	{
		id: 1619,
		code: 'D211',
		label: 'Tumor benigno del tejido conjuntivo y de otros tejidos blandos del miembro superior, incluido el hombro',
	},
	{
		id: 1620,
		code: 'D212',
		label: 'Tumor benigno del tejido conjuntivo y de otros tejidos blandos del miembro inferior, incluida la cadera',
	},
	{
		id: 1621,
		code: 'D213',
		label: 'Tumor benigno del tejido conjuntivo y de otros tejidos blandos del tórax',
	},
	{
		id: 1622,
		code: 'D214',
		label: 'Tumor benigno del tejido conjuntivo y otros tejidos blandos del abdomen',
	},
	{
		id: 1623,
		code: 'D215',
		label: 'Tumor benigno del tejido conjuntivo y otros tejidos blandos de la pelvis',
	},
	{
		id: 1624,
		code: 'D216',
		label: 'Tumor benigno del tejido conjuntivo y otros tejidos blandos del tronco, sin otra especificación',
	},
	{
		id: 1625,
		code: 'D219',
		label: 'Tumor benigno del tejido conjuntivo y otros tejidos blandos, de sitio no especificado',
	},
	{
		id: 1626,
		code: 'D22',
		label: 'Nevo melanocítico',
	},
	{
		id: 1627,
		code: 'D220',
		label: 'Nevo melanocítico del labio',
	},
	{
		id: 1628,
		code: 'D221',
		label: 'Nevo melanocítico del párpado, incluida la comisura palpebral',
	},
	{
		id: 1629,
		code: 'D222',
		label: 'Nevo melanocítico de la oreja y del conducto auditivo externo',
	},
	{
		id: 1630,
		code: 'D223',
		label: 'Nevo melanocítico de otras partes y de las no especificadas de la cara',
	},
	{
		id: 1631,
		code: 'D224',
		label: 'Nevo melanocítico del cuero cabelludo y del cuello',
	},
	{
		id: 1632,
		code: 'D225',
		label: 'Nevo melanocítico del tronco',
	},
	{
		id: 1633,
		code: 'D226',
		label: 'Nevo melanocítico del miembro superior, incluido el hombro',
	},
	{
		id: 1634,
		code: 'D227',
		label: 'Nevo melanocítico del miembro inferior, incluida la cadera',
	},
	{
		id: 1635,
		code: 'D229',
		label: 'Nevo melanocítico, sitio no especificado',
	},
	{
		id: 1636,
		code: 'D23',
		label: 'Otros tumores benignos de la piel',
	},
	{
		id: 1637,
		code: 'D230',
		label: 'Tumor benigno de la piel del labio',
	},
	{
		id: 1638,
		code: 'D231',
		label: 'Tumor benigno de la piel del párpado, incluida la comisura palpebral',
	},
	{
		id: 1639,
		code: 'D232',
		label: 'Tumor benigno de la piel de la oreja y del conducto auditivo externo',
	},
	{
		id: 1640,
		code: 'D233',
		label: 'Tumor benigno de la piel de otras partes y de las no especificadas de la cara',
	},
	{
		id: 1641,
		code: 'D234',
		label: 'Tumor benigno de la piel del cuero cabelludo y del cuello',
	},
	{
		id: 1642,
		code: 'D235',
		label: 'Tumor benigno de la piel del tronco',
	},
	{
		id: 1643,
		code: 'D236',
		label: 'Tumor benigno de la piel del miembro superior, incluido el hombro',
	},
	{
		id: 1644,
		code: 'D237',
		label: 'Tumor benigno de la piel del miembro inferior, incluida la cadera',
	},
	{
		id: 1645,
		code: 'D239',
		label: 'Tumor benigno de la piel, sitio no especificado',
	},
	{
		id: 1646,
		code: 'D24X',
		label: 'Tumor benigno de la mama',
	},
	{
		id: 1647,
		code: 'D25',
		label: 'Leiomioma del útero',
	},
	{
		id: 1648,
		code: 'D250',
		label: 'Leiomioma submucoso del útero',
	},
	{
		id: 1649,
		code: 'D251',
		label: 'Leiomioma intramural del útero',
	},
	{
		id: 1650,
		code: 'D252',
		label: 'Leiomioma subseroso del útero',
	},
	{
		id: 1651,
		code: 'D259',
		label: 'Leiomioma del útero, sin otra especificación',
	},
	{
		id: 1652,
		code: 'D26',
		label: 'Otros tumores benignos del útero',
	},
	{
		id: 1653,
		code: 'D260',
		label: 'Tumor benigno del cuello del útero',
	},
	{
		id: 1654,
		code: 'D261',
		label: 'Tumor benigno del cuerpo del útero',
	},
	{
		id: 1655,
		code: 'D267',
		label: 'Tumor benigno de otras partes especificadas del útero',
	},
	{
		id: 1656,
		code: 'D269',
		label: 'Tumor benigno del útero, parte no especificada',
	},
	{
		id: 1657,
		code: 'D27X',
		label: 'Tumor benigno del ovario',
	},
	{
		id: 1658,
		code: 'D28',
		label: 'Tumor benigno de otros órganos genitales femeninos y de los no especificados',
	},
	{
		id: 1659,
		code: 'D280',
		label: 'Tumor benigno de la vulva',
	},
	{
		id: 1660,
		code: 'D281',
		label: 'Tumor benigno de la vagina',
	},
	{
		id: 1661,
		code: 'D282',
		label: 'Tumor benigno de la trompa de Falopio y de los ligamentos uterinos',
	},
	{
		id: 1662,
		code: 'D287',
		label: 'Tumor benigno de otros sitios especificados de los órganos genitales femeninos',
	},
	{
		id: 1663,
		code: 'D289',
		label: 'Tumor benigno de órgano genital femenino, sitio no especificado',
	},
	{
		id: 1664,
		code: 'D29',
		label: 'Tumor benigno de los órganos genitales masculinos',
	},
	{
		id: 1665,
		code: 'D290',
		label: 'Tumor benigno del pene',
	},
	{
		id: 1666,
		code: 'D291',
		label: 'Tumor benigno de la próstata',
	},
	{
		id: 1667,
		code: 'D292',
		label: 'Tumor benigno de los testículos',
	},
	{
		id: 1668,
		code: 'D293',
		label: 'Tumor benigno del epidídimo',
	},
	{
		id: 1669,
		code: 'D294',
		label: 'Tumor benigno del escroto',
	},
	{
		id: 1670,
		code: 'D297',
		label: 'Tumor benigno de otros órganos genitales masculinos',
	},
	{
		id: 1671,
		code: 'D299',
		label: 'Tumor benigno de órgano genital masculino, sitio no especificado',
	},
	{
		id: 1672,
		code: 'D30',
		label: 'Tumor benigno de los órganos urinarios',
	},
	{
		id: 1673,
		code: 'D300',
		label: 'Tumor benigno del riñón',
	},
	{
		id: 1674,
		code: 'D301',
		label: 'Tumor benigno de la pelvis renal',
	},
	{
		id: 1675,
		code: 'D302',
		label: 'Tumor benigno del uréter',
	},
	{
		id: 1676,
		code: 'D303',
		label: 'Tumor benigno de la vejiga',
	},
	{
		id: 1677,
		code: 'D304',
		label: 'Tumor benigno de la uretra',
	},
	{
		id: 1678,
		code: 'D307',
		label: 'Tumor benigno de otros órganos urinarios',
	},
	{
		id: 1679,
		code: 'D309',
		label: 'Tumor benigno de órgano urinario no especificado',
	},
	{
		id: 1680,
		code: 'D31',
		label: 'Tumor benigno del ojo y sus anexos',
	},
	{
		id: 1681,
		code: 'D310',
		label: 'Tumor benigno de la conjuntiva',
	},
	{
		id: 1682,
		code: 'D311',
		label: 'Tumor benigno de la córnea',
	},
	{
		id: 1683,
		code: 'D312',
		label: 'Tumor benigno de la retina',
	},
	{
		id: 1684,
		code: 'D313',
		label: 'Tumor benigno de la coroides',
	},
	{
		id: 1685,
		code: 'D314',
		label: 'Tumor benigno del cuerpo ciliar',
	},
	{
		id: 1686,
		code: 'D315',
		label: 'Tumor benigno de las glándulas y de los conductos lagrimales',
	},
	{
		id: 1687,
		code: 'D316',
		label: 'Tumor benigno de la órbita, parte no especificada',
	},
	{
		id: 1688,
		code: 'D319',
		label: 'Tumor benigno del ojo, parte no especificada',
	},
	{
		id: 1689,
		code: 'D32',
		label: 'Tumores benignos de las meninges',
	},
	{
		id: 1690,
		code: 'D320',
		label: 'Tumor benigno de las meninges cerebrales',
	},
	{
		id: 1691,
		code: 'D321',
		label: 'Tumor benigno de las meninges raquídeas',
	},
	{
		id: 1692,
		code: 'D329',
		label: 'Tumor benigno de las meninges, parte no especificada',
	},
	{
		id: 1693,
		code: 'D33',
		label: 'Tumor benigno del encéfalo y de otras partes del sistema nervioso central',
	},
	{
		id: 1694,
		code: 'D330',
		label: 'Tumor benigno del encéfalo, supratentorial',
	},
	{
		id: 1695,
		code: 'D331',
		label: 'Tumor benigno del encéfalo, infratentorial',
	},
	{
		id: 1696,
		code: 'D332',
		label: 'Tumor benigno del encéfalo, parte no especificada',
	},
	{
		id: 1697,
		code: 'D333',
		label: 'Tumor benigno de los nervios craneales',
	},
	{
		id: 1698,
		code: 'D334',
		label: 'Tumor benigno de la médula espinal',
	},
	{
		id: 1699,
		code: 'D337',
		label: 'Tumor benigno de otras partes especificadas del sistema nervioso central',
	},
	{
		id: 1700,
		code: 'D339',
		label: 'Tumor benigno del sistema nervioso central, sitio no especificado',
	},
	{
		id: 1701,
		code: 'D34X',
		label: 'Tumor benigno de la glándula tiroides',
	},
	{
		id: 1702,
		code: 'D35',
		label: 'Tumor benigno de otras glándulas endocrinas y de las no especificadas',
	},
	{
		id: 1703,
		code: 'D350',
		label: 'Tumor benigno de la glándula suprarrenal',
	},
	{
		id: 1704,
		code: 'D351',
		label: 'Tumor benigno de la glándula paratiroides',
	},
	{
		id: 1705,
		code: 'D352',
		label: 'Tumor benigno de la hipófisis',
	},
	{
		id: 1706,
		code: 'D353',
		label: 'Tumor benigno del conducto craneofaringeo',
	},
	{
		id: 1707,
		code: 'D354',
		label: 'Tumor benigno de la glándula pineal',
	},
	{
		id: 1708,
		code: 'D355',
		label: 'Tumor benigno del cuerpo carotídeo',
	},
	{
		id: 1709,
		code: 'D356',
		label: 'Tumor benigno del cuerpo aórtico y de otros cuerpos cromafines',
	},
	{
		id: 1710,
		code: 'D357',
		label: 'Tumor benigno de otras glándulas endocrinas especificadas',
	},
	{
		id: 1711,
		code: 'D358',
		label: 'Tumor benigno pluriglandular',
	},
	{
		id: 1712,
		code: 'D359',
		label: 'Tumor benigno de glándula endocrina no especificada',
	},
	{
		id: 1713,
		code: 'D36',
		label: 'Tumor benigno de otros sitios y de los no especificados',
	},
	{
		id: 1714,
		code: 'D360',
		label: 'Tumor benigno de los ganglios linfáticos',
	},
	{
		id: 1715,
		code: 'D361',
		label: 'Tumor benigno de los nervios periféricos y del sistema nervioso autónomo',
	},
	{
		id: 1716,
		code: 'D367',
		label: 'Tumor benigno de otros sitios especificados',
	},
	{
		id: 1717,
		code: 'D369',
		label: 'Tumor benigno de sitio no especificado',
	},
	{
		id: 1718,
		code: 'D37',
		label: 'Tumor de comportamiento incierto o desconocido de la cavidad bucal y de los órganos digestivos',
	},
	{
		id: 1719,
		code: 'D370',
		label: 'Tumor de comportamiento incierto o desconocido del labio, de la cavidad bucal y de la faringe',
	},
	{
		id: 1720,
		code: 'D371',
		label: 'Tumor de comportamiento incierto o desconocido del estómago',
	},
	{
		id: 1721,
		code: 'D372',
		label: 'Tumor de comportamiento incierto o desconocido del intestino delgado',
	},
	{
		id: 1722,
		code: 'D373',
		label: 'Tumor de comportamiento incierto o desconocido del apéndice',
	},
	{
		id: 1723,
		code: 'D374',
		label: 'Tumor de comportamiento incierto o desconocido del colon',
	},
	{
		id: 1724,
		code: 'D375',
		label: 'Tumor de comportamiento incierto o desconocido del recto',
	},
	{
		id: 1725,
		code: 'D376',
		label: 'Tumor de comportamiento incierto o desconocido del hígado, de la vesícula biliar y del conducto biliar',
	},
	{
		id: 1726,
		code: 'D377',
		label: 'Tumor de comportamiento incierto o desconocido de otros órganos digestivos especificados',
	},
	{
		id: 1727,
		code: 'D379',
		label: 'Tumor de comportamiento incierto o desconocido de órganos digestivos, sitio no especificado',
	},
	{
		id: 1728,
		code: 'D38',
		label: 'Tumor de comportamiento incierto o desconocido del oído medio y de los órganos respiratorios e intratorácicos',
	},
	{
		id: 1729,
		code: 'D380',
		label: 'Tumor de comportamiento incierto o desconocido de laringe',
	},
	{
		id: 1730,
		code: 'D381',
		label: 'Tumor de comportamiento incierto o desconocido de la tráquea, de los bronquios y del pulmón',
	},
	{
		id: 1731,
		code: 'D382',
		label: 'Tumor de comportamiento incierto o desconocido de la pleura',
	},
	{
		id: 1732,
		code: 'D383',
		label: 'Tumor de comportamiento incierto o desconocido del mediastino',
	},
	{
		id: 1733,
		code: 'D384',
		label: 'Tumor de comportamiento incierto o desconocido del timo',
	},
	{
		id: 1734,
		code: 'D385',
		label: 'Tumor de comportamiento incierto o desconocido de otros órganos respiratorios y del oído medio',
	},
	{
		id: 1735,
		code: 'D386',
		label: 'Tumor de comportamiento incierto o desconocido de órganos respiratorios, sitio no especificado',
	},
	{
		id: 1736,
		code: 'D39',
		label: 'Tumor de comportamiento incierto o desconocido de los órganos genitales femeninos',
	},
	{
		id: 1737,
		code: 'D390',
		label: 'Tumor de comportamiento incierto o desconocido del útero',
	},
	{
		id: 1738,
		code: 'D391',
		label: 'Tumor de comportamiento incierto o desconocido del ovario',
	},
	{
		id: 1739,
		code: 'D392',
		label: 'Tumor de comportamiento incierto o desconocido de la placenta',
	},
	{
		id: 1740,
		code: 'D397',
		label: 'Tumor de comportamiento incierto o desconocido de otros órganos genitales femeninos',
	},
	{
		id: 1741,
		code: 'D399',
		label: 'Tumor de comportamiento incierto o desconocido de órgano genital femenino no especificado',
	},
	{
		id: 1742,
		code: 'D40',
		label: 'Tumor de comportamiento incierto o desconocido de los órganos genitales masculinos',
	},
	{
		id: 1743,
		code: 'D400',
		label: 'Tumor de comportamiento incierto o desconocido de la próstata',
	},
	{
		id: 1744,
		code: 'D401',
		label: 'Tumor de comportamiento incierto o desconocido del testículo',
	},
	{
		id: 1745,
		code: 'D407',
		label: 'Tumor de comportamiento incierto o desconocido de otros órganos genitales masculinos',
	},
	{
		id: 1746,
		code: 'D409',
		label: 'Tumor de comportamiento incierto o desconocido de órgano genital masculino no especificado',
	},
	{
		id: 1747,
		code: 'D41',
		label: 'Tumor de comportamiento incierto o desconocido de los órganos urinarios',
	},
	{
		id: 1748,
		code: 'D410',
		label: 'Tumor de comportamiento incierto o desconocido del riñón',
	},
	{
		id: 1749,
		code: 'D411',
		label: 'Tumor de comportamiento incierto o desconocido de la pelvis renal',
	},
	{
		id: 1750,
		code: 'D412',
		label: 'Tumor de comportamiento incierto o desconocido del uréter',
	},
	{
		id: 1751,
		code: 'D413',
		label: 'Tumor de comportamiento incierto o desconocido de la uretra',
	},
	{
		id: 1752,
		code: 'D414',
		label: 'Tumor de comportamiento incierto o desconocido de la vejiga',
	},
	{
		id: 1753,
		code: 'D417',
		label: 'Tumor de comportamiento incierto o desconocido de otros órganos urinarios',
	},
	{
		id: 1754,
		code: 'D419',
		label: 'Tumor de comportamiento incierto o desconocido de órgano urinario no especificado',
	},
	{
		id: 1755,
		code: 'D42',
		label: 'Tumor de comportamiento incierto o desconocido de las meninges',
	},
	{
		id: 1756,
		code: 'D420',
		label: 'Tumor de comportamiento incierto o desconocido de las meninges cerebrales',
	},
	{
		id: 1757,
		code: 'D421',
		label: 'Tumor de comportamiento incierto o desconocido de las meninges raquídeas',
	},
	{
		id: 1758,
		code: 'D429',
		label: 'Tumor de comportamiento incierto o desconocido de las meninges, parte no especificada',
	},
	{
		id: 1759,
		code: 'D43',
		label: 'Tumor de comportamiento incierto o desconocido del encéfalo y del sistema nervioso central',
	},
	{
		id: 1760,
		code: 'D430',
		label: 'Tumor de comportamiento incierto o desconocido del encéfalo, supratentorial',
	},
	{
		id: 1761,
		code: 'D431',
		label: 'Tumor de comportamiento incierto o desconocido del encéfalo, infratentorial',
	},
	{
		id: 1762,
		code: 'D432',
		label: 'Tumor de comportamiento incierto o desconocido del encéfalo, parte no especificada',
	},
	{
		id: 1763,
		code: 'D433',
		label: 'Tumor de comportamiento incierto o desconocido de los nervios craneales',
	},
	{
		id: 1764,
		code: 'D434',
		label: 'Tumor de comportamiento incierto o desconocido de la médula espinal',
	},
	{
		id: 1765,
		code: 'D437',
		label: 'Tumor de comportamiento incierto o desconocido de otras partes especificadas del sistema nervioso central',
	},
	{
		id: 1766,
		code: 'D439',
		label: 'Tumor de comportamiento incierto o desconocido del sistema nervioso central, sitio no especificado',
	},
	{
		id: 1767,
		code: 'D44',
		label: 'Tumor de comportamiento incierto o desconocido de las glándulas endocrinas',
	},
	{
		id: 1768,
		code: 'D440',
		label: 'Tumor de comportamiento incierto o desconocido de la glándula tiroides',
	},
	{
		id: 1769,
		code: 'D441',
		label: 'Tumor de comportamiento incierto o desconocido de la glándula suprarrenal',
	},
	{
		id: 1770,
		code: 'D442',
		label: 'Tumor de comportamiento incierto o desconocido de la glándula paratiroides',
	},
	{
		id: 1771,
		code: 'D443',
		label: 'Tumor de comportamiento incierto o desconocido de la glándula hipófisis',
	},
	{
		id: 1772,
		code: 'D444',
		label: 'Tumor de comportamiento incierto o desconocido del conducto craneofaríngeo',
	},
	{
		id: 1773,
		code: 'D445',
		label: 'Tumor de comportamiento incierto o desconocido de la glándula pineal',
	},
	{
		id: 1774,
		code: 'D446',
		label: 'Tumor de comportamiento incierto o desconocido del cuerpo carotídeo',
	},
	{
		id: 1775,
		code: 'D447',
		label: 'Tumor de comportamiento incierto o desconocido del cuerpo aórtico y otros cuerpos cromafines',
	},
	{
		id: 1776,
		code: 'D448',
		label: 'Tumor de comportamiento incierto o desconocido con afectación pluriglandular',
	},
	{
		id: 1777,
		code: 'D449',
		label: 'Tumor de comportamiento incierto o desconocido de glándula endocrina no especificada',
	},
	{
		id: 1778,
		code: 'D45X',
		label: 'Policitemia vera',
	},
	{
		id: 1779,
		code: 'D46',
		label: 'Síndromes mielodisplásicos',
	},
	{
		id: 1780,
		code: 'D460',
		label: 'Anemia refractaria sin sideroblastos, así descrita',
	},
	{
		id: 1781,
		code: 'D461',
		label: 'Anemia refractaria con sideroblastos en forma de anillo',
	},
	{
		id: 1782,
		code: 'D462',
		label: 'Anemia refractaria con exceso de blastos [AREB]',
	},
	{
		id: 1783,
		code: 'D463',
		label: 'Anemia refractaria con exceso de blastos con transformación',
	},
	{
		id: 1784,
		code: 'D464',
		label: 'Anemia refractaria, sin otra especificación',
	},
	{
		id: 1785,
		code: 'D465',
		label: 'Anemia refractaria con displasia multilinaje ',
	},
	{
		id: 1786,
		code: 'D466',
		label: 'Síndrome mielodisplásico con anormalidad cromosómica aislada del (5q)',
	},
	{
		id: 1787,
		code: 'D467',
		label: 'Otros síndromes mielodisplásicos',
	},
	{
		id: 1788,
		code: 'D469',
		label: 'Síndrome mielodisplásico, sin otra especificación',
	},
	{
		id: 1789,
		code: 'D47',
		label: 'Otros tumores de comportamiento incierto o desconocido del tejido linfático, de los órganos hematopoyéticos y de tejidos afines',
	},
	{
		id: 1790,
		code: 'D470',
		label: 'Tumor de comportamiento incierto o desconocido de los mastocitos e histiocitos',
	},
	{
		id: 1791,
		code: 'D471',
		label: 'Enfermedad mieloproliferativa crónica',
	},
	{
		id: 1792,
		code: 'D472',
		label: 'Gammopatía monoclonal',
	},
	{
		id: 1793,
		code: 'D473',
		label: 'Trombocitopenia (hemorrágica) esencial',
	},
	{
		id: 1794,
		code: 'D474',
		label: 'Osteomielofibrosis ',
	},
	{
		id: 1795,
		code: 'D475',
		label: 'Leucemia eosinofílica crónica [síndrome hipereosinofílico] ',
	},
	{
		id: 1796,
		code: 'D477',
		label: 'Otros tumores especificados de comportamiento incierto o desconocido del tejido linfático, de los órganos hematopoyéticos y de tejidos afines',
	},
	{
		id: 1797,
		code: 'D479',
		label: 'Tumores de comportamiento incierto o desconocido del tejido linfático, de los órganos hematopoyéticos y de tejidos afines, no especificados',
	},
	{
		id: 1798,
		code: 'D48',
		label: 'Tumor de comportamiento incierto o desconocido de otros sitios y de los no especificados',
	},
	{
		id: 1799,
		code: 'D480',
		label: 'Tumor de comportamiento incierto o desconocido del hueso y cartílago articular',
	},
	{
		id: 1800,
		code: 'D481',
		label: 'Tumor de comportamiento incierto o desconocido del tejido conjuntivo y otro tejido blando',
	},
	{
		id: 1801,
		code: 'D482',
		label: 'Tumor de comportamiento incierto o desconocido de los nervios periféricos y del sistema nervioso autónomo',
	},
	{
		id: 1802,
		code: 'D483',
		label: 'Tumor de comportamiento incierto o desconocido del retroperitoneo',
	},
	{
		id: 1803,
		code: 'D484',
		label: 'Tumor de comportamiento incierto o desconocido del peritoneo',
	},
	{
		id: 1804,
		code: 'D485',
		label: 'Tumor de comportamiento incierto o desconocido de la piel',
	},
	{
		id: 1805,
		code: 'D486',
		label: 'Tumor de comportamiento incierto o desconocido de la mama',
	},
	{
		id: 1806,
		code: 'D487',
		label: 'Tumor de comportamiento incierto o desconocido de otros sitios especificados',
	},
	{
		id: 1807,
		code: 'D489',
		label: 'Tumor de comportamiento incierto o desconocido, de sitio no especificado',
	},
	{
		id: 1808,
		code: 'D50',
		label: 'Anemias por deficiencia de hierro',
	},
	{
		id: 1809,
		code: 'D500',
		label: 'Anemia por deficiencia de hierro secundaria a pérdida de sangre (crónica)',
	},
	{
		id: 1810,
		code: 'D501',
		label: 'Disfagia sideropénica',
	},
	{
		id: 1811,
		code: 'D508',
		label: 'Otras anemias por deficiencia de hierro',
	},
	{
		id: 1812,
		code: 'D509',
		label: 'Anemia por deficiencia de hierro sin otra especificación',
	},
	{
		id: 1813,
		code: 'D51',
		label: 'Anemia por deficiencia de vitamina B12',
	},
	{
		id: 1814,
		code: 'D510',
		label: 'Anemia por deficiencia de vitamina B12 debida a deficiencia del factor intrínseco',
	},
	{
		id: 1815,
		code: 'D511',
		label: 'Anemia por deficiencia de vitamina B12 debida a mala absorción selectiva de vitamina B12 con proteinuria',
	},
	{
		id: 1816,
		code: 'D512',
		label: 'Deficiencia de trascobalamina II',
	},
	{
		id: 1817,
		code: 'D513',
		label: 'Otras anemias por deficiencia dietética de vitamina B12',
	},
	{
		id: 1818,
		code: 'D518',
		label: 'Otras anemias por deficiencia de vitamina B12',
	},
	{
		id: 1819,
		code: 'D519',
		label: 'Anemia por deficiencia de vitamina B12, sin otra especificación',
	},
	{
		id: 1820,
		code: 'D52',
		label: 'Anemia por deficiencia de folatos',
	},
	{
		id: 1821,
		code: 'D520',
		label: 'Anemia por deficiencia dietética de folatos',
	},
	{
		id: 1822,
		code: 'D521',
		label: 'Anemia por deficiencia de folatos inducida por drogas',
	},
	{
		id: 1823,
		code: 'D528',
		label: 'Otras anemias por deficiencia de folatos',
	},
	{
		id: 1824,
		code: 'D529',
		label: 'Anemia por deficiencia de folatos, sin otra especificación',
	},
	{
		id: 1825,
		code: 'D53',
		label: 'Otras anemias nutricionales',
	},
	{
		id: 1826,
		code: 'D530',
		label: 'Anemia por deficiencia de proteínas',
	},
	{
		id: 1827,
		code: 'D531',
		label: 'Otras anemias megaloblásticas, no clasificadas en otra parte',
	},
	{
		id: 1828,
		code: 'D532',
		label: 'Anemia escorbútica',
	},
	{
		id: 1829,
		code: 'D538',
		label: 'Otras anemias nutricionales especificadas',
	},
	{
		id: 1830,
		code: 'D539',
		label: 'Anemia nutricional, no especificada',
	},
	{
		id: 1831,
		code: 'D55',
		label: 'Anemia debida a trastornos enzimáticos',
	},
	{
		id: 1832,
		code: 'D550',
		label: 'Anemia debida a deficiencia de glucosa-6-fosfato deshidrogenasa [G6FD]',
	},
	{
		id: 1833,
		code: 'D551',
		label: 'Anemia debida a otros trastornos del metabolismo del glutatión',
	},
	{
		id: 1834,
		code: 'D552',
		label: 'Anemia debida a trastornos de las enzimas glucolíticas',
	},
	{
		id: 1835,
		code: 'D553',
		label: 'Anemia debida a trastornos del metabolismo de los nucleótidos',
	},
	{
		id: 1836,
		code: 'D558',
		label: 'Otras anemias debidas a trastornos enzimáticos',
	},
	{
		id: 1837,
		code: 'D559',
		label: 'Anemia debida a trastornos enzimáticos, sin otra especificación',
	},
	{
		id: 1838,
		code: 'D56',
		label: 'Talasemia',
	},
	{
		id: 1839,
		code: 'D560',
		label: 'Alfa talasemia',
	},
	{
		id: 1840,
		code: 'D561',
		label: 'Beta talasemia',
	},
	{
		id: 1841,
		code: 'D562',
		label: 'Delta-beta talasemia',
	},
	{
		id: 1842,
		code: 'D563',
		label: 'Rasgo talasémico',
	},
	{
		id: 1843,
		code: 'D564',
		label: 'Persistencia hereditaria de la hemoglobina fetal [PHHF]',
	},
	{
		id: 1844,
		code: 'D568',
		label: 'Otras talasemias',
	},
	{
		id: 1845,
		code: 'D569',
		label: 'Talasemia, no especificada',
	},
	{
		id: 1846,
		code: 'D57',
		label: 'Trastornos falciformes',
	},
	{
		id: 1847,
		code: 'D570',
		label: 'Anemia falciforme con crisis',
	},
	{
		id: 1848,
		code: 'D571',
		label: 'Anemia falciforme  sin crisis',
	},
	{
		id: 1849,
		code: 'D572',
		label: 'Trastornos falciformes heterocigotos dobles',
	},
	{
		id: 1850,
		code: 'D573',
		label: 'Rasgo drepanocítico',
	},
	{
		id: 1851,
		code: 'D578',
		label: 'Otros trastornos falciformes',
	},
	{
		id: 1852,
		code: 'D58',
		label: 'Otras anemias hemolíticas hereditarias',
	},
	{
		id: 1853,
		code: 'D580',
		label: 'Esferocitosis hereditaria',
	},
	{
		id: 1854,
		code: 'D581',
		label: 'Eliptocitosis hereditaria',
	},
	{
		id: 1855,
		code: 'D582',
		label: 'Otras hemoglobinopatías',
	},
	{
		id: 1856,
		code: 'D588',
		label: 'Otras anemias hemolíticas hereditarias especificadas',
	},
	{
		id: 1857,
		code: 'D589',
		label: 'Anemia hemolítica hereditaria, sin otra especificación',
	},
	{
		id: 1858,
		code: 'D59',
		label: 'Anemia hemolítica adquirida',
	},
	{
		id: 1859,
		code: 'D590',
		label: 'Anemia hemolítica autoinmune inducida por drogas',
	},
	{
		id: 1860,
		code: 'D591',
		label: 'Otras anemias hemolíticas autoinmunes',
	},
	{
		id: 1861,
		code: 'D592',
		label: 'Anemia hemolítica no autoinmune inducida por drogas',
	},
	{
		id: 1862,
		code: 'D593',
		label: 'Síndrome hemolítico-urémico',
	},
	{
		id: 1863,
		code: 'D594',
		label: 'Otras anemias hemolíticas no autoinmunes',
	},
	{
		id: 1864,
		code: 'D595',
		label: 'Hemoglobinuria paroxística nocturna [Marchiafava- Micheli]',
	},
	{
		id: 1865,
		code: 'D596',
		label: 'Hemoglobinuria debida a hemólisis por otras causas externas',
	},
	{
		id: 1866,
		code: 'D598',
		label: 'Otras anemias hemolíticas adquiridas',
	},
	{
		id: 1867,
		code: 'D599',
		label: 'Anemia hemolítica adquirida, sin otra especificación',
	},
	{
		id: 1868,
		code: 'D60',
		label: 'Aplasia adquirida, exclusiva de la serie roja [eritroblastopenia]',
	},
	{
		id: 1869,
		code: 'D600',
		label: 'Aplasia crónica adquirida, exclusiva de la serie roja',
	},
	{
		id: 1870,
		code: 'D601',
		label: 'Aplasia transitoria adquirida, exclusiva de la serie roja',
	},
	{
		id: 1871,
		code: 'D608',
		label: 'Otras aplasias adquiridas, exclusivas de la serie roja',
	},
	{
		id: 1872,
		code: 'D609',
		label: 'Aplasia adquirida, exclusiva de la serie roja, no especificada',
	},
	{
		id: 1873,
		code: 'D61',
		label: 'Otras anemias aplásticas',
	},
	{
		id: 1874,
		code: 'D610',
		label: 'Anemia aplástica constitucional',
	},
	{
		id: 1875,
		code: 'D611',
		label: 'Anemia aplástica inducida por drogas',
	},
	{
		id: 1876,
		code: 'D612',
		label: 'Anemia aplástica debida a otros agentes externos',
	},
	{
		id: 1877,
		code: 'D613',
		label: 'Anemia aplástica idiopática',
	},
	{
		id: 1878,
		code: 'D618',
		label: 'Otras anemias aplásticas especificadas',
	},
	{
		id: 1879,
		code: 'D619',
		label: 'Anemia aplástica, sin otra especificación',
	},
	{
		id: 1880,
		code: 'D62X',
		label: 'Anemia posthemorrágica aguda',
	},
	{
		id: 1881,
		code: 'D63',
		label: 'Anemia en enfermedades crónicas clasificadas en otra parte',
	},
	{
		id: 1882,
		code: 'D630',
		label: 'Anemia en enfermedad neoplásica',
	},
	{
		id: 1883,
		code: 'D638',
		label: 'Anemia en otras enfermedades crónicas clasificadas en otra parte',
	},
	{
		id: 1884,
		code: 'D64',
		label: 'Otras anemias',
	},
	{
		id: 1885,
		code: 'D640',
		label: 'Anemia sideroblástica hereditaria',
	},
	{
		id: 1886,
		code: 'D641',
		label: 'Anemia sideroblástica secundaria a otra enfermedad',
	},
	{
		id: 1887,
		code: 'D642',
		label: 'Anemia sideroblástica secundaria, debida a drogas y toxinas',
	},
	{
		id: 1888,
		code: 'D643',
		label: 'Otras anemias sideroblásticas',
	},
	{
		id: 1889,
		code: 'D644',
		label: 'Anemia diseritropoyética congénita',
	},
	{
		id: 1890,
		code: 'D648',
		label: 'Otras anemias especificadas',
	},
	{
		id: 1891,
		code: 'D649',
		label: 'Anemia de tipo no especificado',
	},
	{
		id: 1892,
		code: 'D65X',
		label: 'Coagulación intravascular diseminada [síndrome de desfibrinación]',
	},
	{
		id: 1893,
		code: 'D66X',
		label: 'Deficiencia hereditaria del factor VIII',
	},
	{
		id: 1894,
		code: 'D67X',
		label: 'Deficiencia hereditaria del factor IX',
	},
	{
		id: 1895,
		code: 'D68',
		label: 'Otros defectos de la coagulación',
	},
	{
		id: 1896,
		code: 'D680',
		label: 'Enfermedad de von Willebrand',
	},
	{
		id: 1897,
		code: 'D681',
		label: 'Deficiencia hereditaria del factor XI',
	},
	{
		id: 1898,
		code: 'D682',
		label: 'Deficiencia hereditaria de otros factores de la coagulación',
	},
	{
		id: 1899,
		code: 'D683',
		label: 'Trastorno hemorrágico debido a anticoagulantes circulantes',
	},
	{
		id: 1900,
		code: 'D684',
		label: 'Deficiencia adquirida de factores de la coagulación',
	},
	{
		id: 1901,
		code: 'D685',
		label: 'Trombofilia primaria',
	},
	{
		id: 1902,
		code: 'D686',
		label: 'Otra trombofilia ',
	},
	{
		id: 1903,
		code: 'D688',
		label: 'Otros defectos especificados de la coagulación',
	},
	{
		id: 1904,
		code: 'D689',
		label: 'Defecto de la coagulación, no especificado',
	},
	{
		id: 1905,
		code: 'D69',
		label: 'Púrpura y otras afecciones hemorrágicas',
	},
	{
		id: 1906,
		code: 'D690',
		label: 'Púrpura alérgica',
	},
	{
		id: 1907,
		code: 'D691',
		label: 'Defecto cualitativos de las plaquetas',
	},
	{
		id: 1908,
		code: 'D692',
		label: 'Otras púrpuras no trombocitopénicas',
	},
	{
		id: 1909,
		code: 'D693',
		label: 'Púrpura trombocitopénica idiopática',
	},
	{
		id: 1910,
		code: 'D694',
		label: 'Otras trombocitopenias primarias',
	},
	{
		id: 1911,
		code: 'D695',
		label: 'Trombocitopenia secundaria',
	},
	{
		id: 1912,
		code: 'D696',
		label: 'Trombocitopenia no especificada',
	},
	{
		id: 1913,
		code: 'D698',
		label: 'Otras afecciones hemorrágicas especificadas',
	},
	{
		id: 1914,
		code: 'D699',
		label: 'Afección hemorrágica, no especificada',
	},
	{
		id: 1915,
		code: 'D70X',
		label: 'Agranulocitosis',
	},
	{
		id: 1916,
		code: 'D71X',
		label: 'Trastornos funcionales de los polimorfonucleares neutrófilos',
	},
	{
		id: 1917,
		code: 'D72',
		label: 'Otros trastornos de los leucocitos',
	},
	{
		id: 1918,
		code: 'D720',
		label: 'Anomalías genéticas de los leucocitos',
	},
	{
		id: 1919,
		code: 'D721',
		label: 'Eosinofilia',
	},
	{
		id: 1920,
		code: 'D728',
		label: 'Otros trastornos especificados de los leucocitos',
	},
	{
		id: 1921,
		code: 'D729',
		label: 'Trastorno de los leucocitos, no especificado',
	},
	{
		id: 1922,
		code: 'D73',
		label: 'Enfermedades del bazo',
	},
	{
		id: 1923,
		code: 'D730',
		label: 'Hipoesplenismo',
	},
	{
		id: 1924,
		code: 'D731',
		label: 'Hiperesplenismo',
	},
	{
		id: 1925,
		code: 'D732',
		label: 'Esplenomegalia congestiva crónica',
	},
	{
		id: 1926,
		code: 'D733',
		label: 'Absceso del bazo',
	},
	{
		id: 1927,
		code: 'D734',
		label: 'Quiste del bazo',
	},
	{
		id: 1928,
		code: 'D735',
		label: 'Infarto del bazo',
	},
	{
		id: 1929,
		code: 'D738',
		label: 'Otras enfermedades del bazo',
	},
	{
		id: 1930,
		code: 'D739',
		label: 'Enfermedad del bazo, no especificada',
	},
	{
		id: 1931,
		code: 'D74',
		label: 'Metahemoglobinemia',
	},
	{
		id: 1932,
		code: 'D740',
		label: 'Metahemoglobinemia congénita',
	},
	{
		id: 1933,
		code: 'D748',
		label: 'Otras metahemoglobinemias',
	},
	{
		id: 1934,
		code: 'D749',
		label: 'Metahemoglobinemia, no especificada',
	},
	{
		id: 1935,
		code: 'D75',
		label: 'Otras enfermedades de la sangre y de los órganos hematopoyéticos',
	},
	{
		id: 1936,
		code: 'D750',
		label: 'Eritrocitosis familiar',
	},
	{
		id: 1937,
		code: 'D751',
		label: 'Policitemia secundaria',
	},
	{
		id: 1938,
		code: 'D752',
		label: 'Trombocitosis esencial',
	},
	{
		id: 1939,
		code: 'D758',
		label: 'Otras enfermedades especificadas de la sangre y de los órganos hematopoyéticos',
	},
	{
		id: 1940,
		code: 'D759',
		label: 'Enfermedad de la sangre y de los órganos hematopoyéticos, no especificada',
	},
	{
		id: 1941,
		code: 'D76',
		label: 'Otras enfermedades especificadas con participación deL tejido linforreticular y del tejido reticulohistiocítico',
	},
	{
		id: 1942,
		code: 'D760',
		label: 'Histiocitosis de las células de Langerhans, no clasificada en otra parte',
	},
	{
		id: 1943,
		code: 'D761',
		label: 'Linfohistiocitosis hemofagocítica',
	},
	{
		id: 1944,
		code: 'D762',
		label: 'Síndrome hemofagocítico asociado a infección',
	},
	{
		id: 1945,
		code: 'D763',
		label: 'Otros síndromes histiocíticos',
	},
	{
		id: 1946,
		code: 'D77X',
		label: 'Otros trastornos de la sangre y de los órganos hematopoyéticos en enfermedades clasificadas en otra parte',
	},
	{
		id: 1947,
		code: 'D80',
		label: 'Inmunodeficiencia con predominio de defectos de los anticuerpos',
	},
	{
		id: 1948,
		code: 'D800',
		label: 'Hipogammaglobulinemia hereditaria',
	},
	{
		id: 1949,
		code: 'D801',
		label: 'Hipogammaglobulinemia no familiar',
	},
	{
		id: 1950,
		code: 'D802',
		label: 'Deficiencia selectiva de inmunoglobulina A [IgA]',
	},
	{
		id: 1951,
		code: 'D803',
		label: 'Deficiencia selectiva de subclases de la inmunoglobulina G [IgG]',
	},
	{
		id: 1952,
		code: 'D804',
		label: 'Deficiencia selectiva de inmunoglobulina M [IgM]',
	},
	{
		id: 1953,
		code: 'D805',
		label: 'Inmunodeficiencia con incremento de inmunoglobulina M [IgM]',
	},
	{
		id: 1954,
		code: 'D806',
		label: 'Deficiencia de anticuerpos con inmunoglobulinas casi normales o con hiperinmunoglobulinemia',
	},
	{
		id: 1955,
		code: 'D807',
		label: 'Hipogammaglobulinemia transitoria de la infancia',
	},
	{
		id: 1956,
		code: 'D808',
		label: 'Otras inmunodeficiencias con predominio de defectos de los anticuerpos',
	},
	{
		id: 1957,
		code: 'D809',
		label: 'Inmunodeficiencia con predominio de defectos de los anticuerpos, no especificada',
	},
	{
		id: 1958,
		code: 'D81',
		label: 'Inmunodeficiencias combinadas',
	},
	{
		id: 1959,
		code: 'D810',
		label: 'Inmunodeficiencia combinada severa [IDCS] con disgenesia reticular',
	},
	{
		id: 1960,
		code: 'D811',
		label: 'Inmunodeficiencia combinada severa [IDCS] con linfocitopenia T y B',
	},
	{
		id: 1961,
		code: 'D812',
		label: 'Inmunodeficiencia combinada severa [IDCS] con cifra baja o normal de linfocitos B',
	},
	{
		id: 1962,
		code: 'D813',
		label: 'Deficiencia de la adenosina deaminasa [ADA]',
	},
	{
		id: 1963,
		code: 'D814',
		label: 'Síndrome de Nezelof',
	},
	{
		id: 1964,
		code: 'D815',
		label: 'Deficiencia de la fosforilasa purinonucleósida [FPN]',
	},
	{
		id: 1965,
		code: 'D816',
		label: 'Deficiencia de la clase I del complejo de histocompatibilidad mayor',
	},
	{
		id: 1966,
		code: 'D817',
		label: 'Deficiencia de la clase II del complejo de histocompatibilidad mayor',
	},
	{
		id: 1967,
		code: 'D818',
		label: 'Otras inmunodeficiencias combinadas',
	},
	{
		id: 1968,
		code: 'D819',
		label: 'Inmunodeficiencia combinada, no especificada',
	},
	{
		id: 1969,
		code: 'D82',
		label: 'Inmunodeficiencia asociada con otros defectos mayores',
	},
	{
		id: 1970,
		code: 'D820',
		label: 'Síndrome de Wiskott-Aldrich',
	},
	{
		id: 1971,
		code: 'D821',
		label: 'Síndrome de Di George',
	},
	{
		id: 1972,
		code: 'D822',
		label: 'Inmunodeficiencia con enanismo micromélico [miembros cortos]',
	},
	{
		id: 1973,
		code: 'D823',
		label: 'Inmunodeficiencia consecutiva a respuesta defectuosa hereditaria contra el virus de Epstein-Barr',
	},
	{
		id: 1974,
		code: 'D824',
		label: 'Síndrome de hiperinmunoglobulina E [IgE]',
	},
	{
		id: 1975,
		code: 'D828',
		label: 'Inmunodeficiencia asociada con otros defectos mayores especificados',
	},
	{
		id: 1976,
		code: 'D829',
		label: 'Inmunodeficiencia asociada con defectos mayores no especificados',
	},
	{
		id: 1977,
		code: 'D83',
		label: 'Inmunodeficiencia variable común',
	},
	{
		id: 1978,
		code: 'D830',
		label: 'Inmunodeficiencia variable común con predominio de anormalidades en el número y la función de los linfocitos B',
	},
	{
		id: 1979,
		code: 'D831',
		label: 'Inmunodeficiencia variable común con predominio de trastornos inmunorreguladores de los linfocitos T',
	},
	{
		id: 1980,
		code: 'D832',
		label: 'Inmunodeficiencia variable común con autoanticuerpos anti-B o anti-T',
	},
	{
		id: 1981,
		code: 'D838',
		label: 'Otras inmunodeficiencias variables comunes',
	},
	{
		id: 1982,
		code: 'D839',
		label: 'Inmunodeficiencia variable común, no especificada',
	},
	{
		id: 1983,
		code: 'D84',
		label: 'Otras inmunodeficiencias',
	},
	{
		id: 1984,
		code: 'D840',
		label: 'Defecto de la función del antígeno-1 del linfocito [LFA-1]',
	},
	{
		id: 1985,
		code: 'D841',
		label: 'Defecto del sistema del complemento',
	},
	{
		id: 1986,
		code: 'D848',
		label: 'Otras inmunodeficiencias especificadas',
	},
	{
		id: 1987,
		code: 'D849',
		label: 'Inmunodeficiencia, no especificada',
	},
	{
		id: 1988,
		code: 'D86',
		label: 'Sarcoidosis',
	},
	{
		id: 1989,
		code: 'D860',
		label: 'Sarcoidosis del pulmón',
	},
	{
		id: 1990,
		code: 'D861',
		label: 'Sarcoidosis de los ganglios linfáticos',
	},
	{
		id: 1991,
		code: 'D862',
		label: 'Sarcoidosis del pulmón y de los ganglios linfáticos',
	},
	{
		id: 1992,
		code: 'D863',
		label: 'Sarcoidosis de la piel',
	},
	{
		id: 1993,
		code: 'D868',
		label: 'Sarcoidosis de otros sitios especificados o de sitios combinados',
	},
	{
		id: 1994,
		code: 'D869',
		label: 'Sarcoidosis de sitio no especificado',
	},
	{
		id: 1995,
		code: 'D89',
		label: 'Otros trastornos que afectan el mecanismo de la inmunidad, no clasificados en otra parte',
	},
	{
		id: 1996,
		code: 'D890',
		label: 'Hipergammaglobulinemia policlonal',
	},
	{
		id: 1997,
		code: 'D891',
		label: 'Crioglobulinemia',
	},
	{
		id: 1998,
		code: 'D892',
		label: 'Hipergammaglobulinemia, no especificada',
	},
	{
		id: 1999,
		code: 'D893',
		label: 'Síndrome de reconstitución inmune ',
	},
	{
		id: 2000,
		code: 'D898',
		label: 'Otros trastornos especificados que afectan el mecanismo de la inmunidad, no clasificados en otra parte',
	},
	{
		id: 2001,
		code: 'D899',
		label: 'Trastorno que afecta al mecanismo de la inmunidad, no especificado',
	},
	{
		id: 2002,
		code: 'E00',
		label: 'Síndrome congénito de deficiencia de yodo',
	},
	{
		id: 2003,
		code: 'E000',
		label: 'Síndrome congénito de deficiencia de yodo, tipo neurológico',
	},
	{
		id: 2004,
		code: 'E001',
		label: 'Síndrome de deficiencia congénita de yodo, tipo mixedematoso',
	},
	{
		id: 2005,
		code: 'E002',
		label: 'Síndrome congénito de deficiencia de yodo, tipo mixto',
	},
	{
		id: 2006,
		code: 'E009',
		label: 'Síndrome congénito de deficiencia de yodo, no especificado',
	},
	{
		id: 2007,
		code: 'E01',
		label: 'Trastornos tiroideos vinculados a deficiencia de yodo y afecciones relacionadas',
	},
	{
		id: 2008,
		code: 'E010',
		label: 'Bocio difuso (endémico) relacionado con deficiencia de yodo',
	},
	{
		id: 2009,
		code: 'E011',
		label: 'Bocio multinodular (endémico) relacionado con deficiencia de yodo',
	},
	{
		id: 2010,
		code: 'E012',
		label: 'Bocio (endémico) relacionado con deficiencia de yodo, no especificado',
	},
	{
		id: 2011,
		code: 'E018',
		label: 'Otros trastornos de la tiroides relacionados con deficiencia de yodo y afecciones similares',
	},
	{
		id: 2012,
		code: 'E02X',
		label: 'Hipotiroidismo subclínico por deficiencia de yodo',
	},
	{
		id: 2013,
		code: 'E03',
		label: 'Otros hipotiroidismos',
	},
	{
		id: 2014,
		code: 'E030',
		label: 'Hipotiroidismo congénito con bocio difuso',
	},
	{
		id: 2015,
		code: 'E031',
		label: 'Hipotiroidismo congénito sin bocio',
	},
	{
		id: 2016,
		code: 'E032',
		label: 'Hipotiroidismo debido a medicamentos y a otras sustancias exógenas',
	},
	{
		id: 2017,
		code: 'E033',
		label: 'Hipotiroidismo postinfeccioso',
	},
	{
		id: 2018,
		code: 'E034',
		label: 'Atrofia de tiroides (adquirida)',
	},
	{
		id: 2019,
		code: 'E035',
		label: 'Coma mixedematoso',
	},
	{
		id: 2020,
		code: 'E038',
		label: 'Otros hipotiroidismos especificados',
	},
	{
		id: 2021,
		code: 'E039',
		label: 'Hipotiroidismo, no especificado',
	},
	{
		id: 2022,
		code: 'E04',
		label: 'Otros bocios no tóxicos',
	},
	{
		id: 2023,
		code: 'E040',
		label: 'Bocio difuso no tóxico',
	},
	{
		id: 2024,
		code: 'E041',
		label: 'Nódulo tiroideo solitario no tóxico',
	},
	{
		id: 2025,
		code: 'E042',
		label: 'Bocio multinodular no tóxico',
	},
	{
		id: 2026,
		code: 'E048',
		label: 'Otros bocios no tóxicos especificados',
	},
	{
		id: 2027,
		code: 'E049',
		label: 'Bocio no tóxico, no especificado',
	},
	{
		id: 2028,
		code: 'E05',
		label: 'Tirotoxicosis [hipertiroidismo]',
	},
	{
		id: 2029,
		code: 'E050',
		label: 'Tirotoxicosis con bocio difuso',
	},
	{
		id: 2030,
		code: 'E051',
		label: 'Tirotoxicosis con nódulo solitario tiroideo tóxico',
	},
	{
		id: 2031,
		code: 'E052',
		label: 'Tirotoxicosis con bocio multinodular tóxico',
	},
	{
		id: 2032,
		code: 'E053',
		label: 'Tirotoxicosis por tejido tiroideo ectópico',
	},
	{
		id: 2033,
		code: 'E054',
		label: 'Tirotoxicosis facticia',
	},
	{
		id: 2034,
		code: 'E055',
		label: 'Crisis o tormenta tirotóxica',
	},
	{
		id: 2035,
		code: 'E058',
		label: 'Otras tirotoxicosis',
	},
	{
		id: 2036,
		code: 'E059',
		label: 'Tirotoxicosis, no especificada',
	},
	{
		id: 2037,
		code: 'E06',
		label: 'Tiroiditis',
	},
	{
		id: 2038,
		code: 'E060',
		label: 'Tiroiditis aguda',
	},
	{
		id: 2039,
		code: 'E061',
		label: 'Tiroiditis subaguda',
	},
	{
		id: 2040,
		code: 'E062',
		label: 'Tiroiditis crónica con tirotoxicosis transitoria',
	},
	{
		id: 2041,
		code: 'E063',
		label: 'Tiroiditis autoinmune',
	},
	{
		id: 2042,
		code: 'E064',
		label: 'Tiroiditis inducida por drogas',
	},
	{
		id: 2043,
		code: 'E065',
		label: 'Otras tiroiditis crónicas',
	},
	{
		id: 2044,
		code: 'E069',
		label: 'Tiroiditis, no especificada',
	},
	{
		id: 2045,
		code: 'E07',
		label: 'Otros trastornos tiroideos',
	},
	{
		id: 2046,
		code: 'E070',
		label: 'Hipersecreción de calcitonina',
	},
	{
		id: 2047,
		code: 'E071',
		label: 'Bocio dishormogenético',
	},
	{
		id: 2048,
		code: 'E078',
		label: 'Otros trastornos especificados de la glándula tiroides',
	},
	{
		id: 2049,
		code: 'E079',
		label: 'Trastorno de la glándula tiroides, no especificado',
	},
	{
		id: 2050,
		code: 'E10',
		label: 'Diabetes mellitus insulinodependiente',
	},
	{
		id: 2051,
		code: 'E100',
		label: 'Diabetes mellitus insulinodependiente, con coma',
	},
	{
		id: 2052,
		code: 'E101',
		label: 'Diabetes mellitus insulinodependiente, con cetoacidosis',
	},
	{
		id: 2053,
		code: 'E102',
		label: 'Diabetes mellitus insulinodependiente, con complicaciones renales',
	},
	{
		id: 2054,
		code: 'E103',
		label: 'Diabetes mellitus insulinodependiente, con complicaciones oftálmicas',
	},
	{
		id: 2055,
		code: 'E104',
		label: 'Diabetes mellitus insulinodependiente, con complicaciones neurológicas',
	},
	{
		id: 2056,
		code: 'E105',
		label: 'Diabetes mellitus insulinodependiente, con complicaciones circulatorias periféricas',
	},
	{
		id: 2057,
		code: 'E106',
		label: 'Diabetes mellitus insulinodependiente, con otras complicaciones especificadas',
	},
	{
		id: 2058,
		code: 'E107',
		label: 'Diabetes mellitus insulinodependiente, con complicaciones múltiples',
	},
	{
		id: 2059,
		code: 'E108',
		label: 'Diabetes mellitus insulinodependiente, con complicaciones no especificadas',
	},
	{
		id: 2060,
		code: 'E109',
		label: 'Diabetes mellitus insulinodependiente, sin mención de complicación',
	},
	{
		id: 2061,
		code: 'E11',
		label: 'Diabetes mellitus no insulinodependiente',
	},
	{
		id: 2062,
		code: 'E110',
		label: 'Diabetes mellitus no insulinodependiente, con coma',
	},
	{
		id: 2063,
		code: 'E111',
		label: 'Diabetes mellitus no insulinodependiente, con cetoacidosis',
	},
	{
		id: 2064,
		code: 'E112',
		label: 'Diabetes mellitus no insulinodependiente, con complicaciones renales',
	},
	{
		id: 2065,
		code: 'E113',
		label: 'Diabetes mellitus no insulinodependiente, con complicaciones oftálmicas',
	},
	{
		id: 2066,
		code: 'E114',
		label: 'Diabetes mellitus no insulinodependiente, con complicaciones neurológicas',
	},
	{
		id: 2067,
		code: 'E115',
		label: 'Diabetes mellitus no insulinodependiente, con complicaciones circulatorias periféricas',
	},
	{
		id: 2068,
		code: 'E116',
		label: 'Diabetes mellitus no insulinodependiente, con otras complicaciones especificadas',
	},
	{
		id: 2069,
		code: 'E117',
		label: 'Diabetes mellitus no insulinodependiente, con complicaciones múltiples',
	},
	{
		id: 2070,
		code: 'E118',
		label: 'Diabetes mellitus no insulinodependiente, con complicaciones no especificadas',
	},
	{
		id: 2071,
		code: 'E119',
		label: 'Diabetes mellitus no insulinodependiente, sin mención de complicación',
	},
	{
		id: 2072,
		code: 'E12',
		label: 'Diabetes mellitus asociada con desnutrición',
	},
	{
		id: 2073,
		code: 'E120',
		label: 'Diabetes mellitus asociada con desnutrición, con coma',
	},
	{
		id: 2074,
		code: 'E121',
		label: 'Diabetes mellitus asociada con desnutrición, con cetoacidosis',
	},
	{
		id: 2075,
		code: 'E122',
		label: 'Diabetes mellitus asociada con desnutrición, con complicaciones renales',
	},
	{
		id: 2076,
		code: 'E123',
		label: 'Diabetes mellitus asociada con desnutrición, con complicaciones oftálmicas',
	},
	{
		id: 2077,
		code: 'E124',
		label: 'Diabetes mellitus asociada con desnutrición, con complicaciones neurológicas',
	},
	{
		id: 2078,
		code: 'E125',
		label: 'Diabetes mellitus asociada con desnutrición, con complicaciones circulatorias periféricas',
	},
	{
		id: 2079,
		code: 'E126',
		label: 'Diabetes mellitus asociada con desnutrición, con otras complicaciones especificadas',
	},
	{
		id: 2080,
		code: 'E127',
		label: 'Diabetes mellitus asociada con desnutrición, con complicaciones múltiples',
	},
	{
		id: 2081,
		code: 'E128',
		label: 'Diabetes mellitus asociada con desnutrición, con complicaciones no especificadas',
	},
	{
		id: 2082,
		code: 'E129',
		label: 'Diabetes mellitus asociada con desnutrición, sin mención de complicación',
	},
	{
		id: 2083,
		code: 'E13',
		label: 'Otras diabetes mellitus especificadas',
	},
	{
		id: 2084,
		code: 'E130',
		label: 'Otras diabetes mellitus especificadas, con coma',
	},
	{
		id: 2085,
		code: 'E131',
		label: 'Otras Diabetes mellitus especificadas, con cetoacidosis',
	},
	{
		id: 2086,
		code: 'E132',
		label: 'Otras diabetes mellitus especificadas, con complicaciones renales',
	},
	{
		id: 2087,
		code: 'E133',
		label: 'Otras diabetes mellitus especificadas, con complicaciones oftálmicas',
	},
	{
		id: 2088,
		code: 'E134',
		label: 'Otras diabetes mellitus especificadas, con complicaciones neurológicas',
	},
	{
		id: 2089,
		code: 'E135',
		label: 'Otras diabetes mellitus especificadas, con complicaciones circulatorias periféricas',
	},
	{
		id: 2090,
		code: 'E136',
		label: 'Otras diabetes mellitus especificadas, con otras complicaciones especificadas',
	},
	{
		id: 2091,
		code: 'E137',
		label: 'Otras diabetes mellitus especificadas, con complicaciones múltiples',
	},
	{
		id: 2092,
		code: 'E138',
		label: 'Otras diabetes mellitus especificadas, con complicaciones no especificadas',
	},
	{
		id: 2093,
		code: 'E139',
		label: 'Otras diabetes mellitus especificadas, sin mención de complicación',
	},
	{
		id: 2094,
		code: 'E14',
		label: 'Diabetes mellitus, no especificada',
	},
	{
		id: 2095,
		code: 'E140',
		label: 'Diabetes mellitus no especificada, con coma',
	},
	{
		id: 2096,
		code: 'E141',
		label: 'Diabetes mellitus no especificada, con cetoacidosis',
	},
	{
		id: 2097,
		code: 'E142',
		label: 'Diabetes mellitus no especificada, con complicaciones renales',
	},
	{
		id: 2098,
		code: 'E143',
		label: 'Diabetes mellitus no especificada, con complicaciones oftálmicas',
	},
	{
		id: 2099,
		code: 'E144',
		label: 'Diabetes mellitus no especificada, con complicaciones neurológicas',
	},
	{
		id: 2100,
		code: 'E145',
		label: 'Diabetes mellitus no especificada, con complicaciones circulatorias periféricas',
	},
	{
		id: 2101,
		code: 'E146',
		label: 'Diabetes mellitus no especificada, con otras complicaciones especificadas',
	},
	{
		id: 2102,
		code: 'E147',
		label: 'Diabetes mellitus no especificada, con complicaciones múltiples',
	},
	{
		id: 2103,
		code: 'E148',
		label: 'Diabetes mellitus no especificada, con complicaciones no especificadas',
	},
	{
		id: 2104,
		code: 'E149',
		label: 'Diabetes mellitus no especificada, sin mención de complicación',
	},
	{
		id: 2105,
		code: 'E15X',
		label: 'Coma hipoglicémico no diabético',
	},
	{
		id: 2106,
		code: 'E16',
		label: 'Otros trastornos de la secreción interna del páncreas',
	},
	{
		id: 2107,
		code: 'E160',
		label: 'Hipoglicemia sin coma, inducida por drogas',
	},
	{
		id: 2108,
		code: 'E161',
		label: 'Otras hipoglicemias',
	},
	{
		id: 2109,
		code: 'E162',
		label: 'Hipoglicemia, no especificada',
	},
	{
		id: 2110,
		code: 'E163',
		label: 'Secreción exagerada del glucagón',
	},
	{
		id: 2111,
		code: 'E164',
		label: 'Secreción anormal de gastrina',
	},
	{
		id: 2112,
		code: 'E168',
		label: 'Otros trastornos especificados de la secreción interna del páncreas',
	},
	{
		id: 2113,
		code: 'E169',
		label: 'Trastornos de la secreción interna del páncreas, sin otra especificación',
	},
	{
		id: 2114,
		code: 'E20',
		label: 'Hipoparatiroidismo',
	},
	{
		id: 2115,
		code: 'E200',
		label: 'Hipoparatiroidismo idiopático',
	},
	{
		id: 2116,
		code: 'E201',
		label: 'Pseudohipoparatiroidismo',
	},
	{
		id: 2117,
		code: 'E208',
		label: 'Otros tipos de hipoparatiroidismo',
	},
	{
		id: 2118,
		code: 'E209',
		label: 'Hipoparatiroidismo, no especificado',
	},
	{
		id: 2119,
		code: 'E21',
		label: 'Hiperparatiroidismo y otros trastornos de la glándula paratiroides',
	},
	{
		id: 2120,
		code: 'E210',
		label: 'Hiperparatiroidismo primario',
	},
	{
		id: 2121,
		code: 'E211',
		label: 'Hiperparatiroidismo secundario no clasificado en otra parte',
	},
	{
		id: 2122,
		code: 'E212',
		label: 'Otros tipos de hiperparatiroidismo',
	},
	{
		id: 2123,
		code: 'E213',
		label: 'Hiperparatiroidismo, sin otra especificación',
	},
	{
		id: 2124,
		code: 'E214',
		label: 'Otros trastornos especificados de la glándula paratiroides',
	},
	{
		id: 2125,
		code: 'E215',
		label: 'Trastorno de la glándula paratiroides, no especificado',
	},
	{
		id: 2126,
		code: 'E22',
		label: 'Hiperfunción de la glándula hipófisis',
	},
	{
		id: 2127,
		code: 'E220',
		label: 'Acromegalia y gigantismo hipofisario',
	},
	{
		id: 2128,
		code: 'E221',
		label: 'Hiperprolactinemia',
	},
	{
		id: 2129,
		code: 'E222',
		label: 'Síndrome de secreción inapropiada de hormona anitidiurética',
	},
	{
		id: 2130,
		code: 'E228',
		label: 'Otras hiperfunciones de la glándula hipófisis',
	},
	{
		id: 2131,
		code: 'E229',
		label: 'Hiperfunción de la glándula hipófisis, no especificada',
	},
	{
		id: 2132,
		code: 'E23',
		label: 'Hipofunción y otros trastornos de la glándula hipófisis',
	},
	{
		id: 2133,
		code: 'E230',
		label: 'Hipopituitarismo',
	},
	{
		id: 2134,
		code: 'E231',
		label: 'Hipopituitarismo inducido por drogas',
	},
	{
		id: 2135,
		code: 'E232',
		label: 'Diabetes insípida',
	},
	{
		id: 2136,
		code: 'E233',
		label: 'Disfunción hipotalámica, no clasificada en otra parte',
	},
	{
		id: 2137,
		code: 'E236',
		label: 'Otros trastornos de la glándula hipófisis',
	},
	{
		id: 2138,
		code: 'E237',
		label: 'Trastorno de la glándula hipófisis, no especificado',
	},
	{
		id: 2139,
		code: 'E24',
		label: 'Síndrome de Cushing',
	},
	{
		id: 2140,
		code: 'E240',
		label: 'Enfermedad de Cushing dependiente de la hipófisis',
	},
	{
		id: 2141,
		code: 'E241',
		label: 'Síndrome de Nelson',
	},
	{
		id: 2142,
		code: 'E242',
		label: 'Síndrome de Cushing inducido por drogas',
	},
	{
		id: 2143,
		code: 'E243',
		label: 'Síndrome de ACTH ectópico',
	},
	{
		id: 2144,
		code: 'E244',
		label: 'Síndrome de seudo-Cushing inducido por alcohol',
	},
	{
		id: 2145,
		code: 'E248',
		label: 'Otros tipos de síndrome de Cushing',
	},
	{
		id: 2146,
		code: 'E249',
		label: 'Síndrome de Cushing, no especificado',
	},
	{
		id: 2147,
		code: 'E25',
		label: 'Trastornos adrenogenitales',
	},
	{
		id: 2148,
		code: 'E250',
		label: 'Trastornos adrenogenitales congénitos con deficiencia enzimática',
	},
	{
		id: 2149,
		code: 'E258',
		label: 'Otros trastornos adrenogenitales',
	},
	{
		id: 2150,
		code: 'E259',
		label: 'Trastorno adrenogenital, no especificado',
	},
	{
		id: 2151,
		code: 'E26',
		label: 'Hiperaldosteronismo',
	},
	{
		id: 2152,
		code: 'E260',
		label: 'Hiperaldosteronismo primario',
	},
	{
		id: 2153,
		code: 'E261',
		label: 'Hiperaldosteronismo secundario',
	},
	{
		id: 2154,
		code: 'E268',
		label: 'Otros tipos de hiperaldosteronismo',
	},
	{
		id: 2155,
		code: 'E269',
		label: 'Hiperaldosteronismo, no especificado',
	},
	{
		id: 2156,
		code: 'E27',
		label: 'Otros trastornos  de la glándula suprarrenal',
	},
	{
		id: 2157,
		code: 'E270',
		label: 'Otra hiperactividad corticosuprarrenal',
	},
	{
		id: 2158,
		code: 'E271',
		label: 'Insuficiencia corticosuprarrenal primaria',
	},
	{
		id: 2159,
		code: 'E272',
		label: 'Crisis addisoniana',
	},
	{
		id: 2160,
		code: 'E273',
		label: 'Insuficiencia corticosuprarrenal inducida por drogas',
	},
	{
		id: 2161,
		code: 'E274',
		label: 'Otras insuficiencias corticosuprarrenales y las no especificadas',
	},
	{
		id: 2162,
		code: 'E275',
		label: 'Hiperfunción de la médula suprarrenal',
	},
	{
		id: 2163,
		code: 'E278',
		label: 'Otros trastornos especificados de la glándula suprarrenal',
	},
	{
		id: 2164,
		code: 'E279',
		label: 'Trastorno de la glándula suprarrenal, no especificado',
	},
	{
		id: 2165,
		code: 'E28',
		label: 'Disfunción ovárica',
	},
	{
		id: 2166,
		code: 'E280',
		label: 'Exceso de estrógenos',
	},
	{
		id: 2167,
		code: 'E281',
		label: 'Exceso de andrógenos',
	},
	{
		id: 2168,
		code: 'E282',
		label: 'Síndrome de ovario poliquístico',
	},
	{
		id: 2169,
		code: 'E283',
		label: 'Insuficiencia ovárica primaria',
	},
	{
		id: 2170,
		code: 'E288',
		label: 'Otras disfunciones ováricas',
	},
	{
		id: 2171,
		code: 'E289',
		label: 'Disfunción ovárica, no especificada',
	},
	{
		id: 2172,
		code: 'E29',
		label: 'Disfunción testicular',
	},
	{
		id: 2173,
		code: 'E290',
		label: 'Hiperfunción testicular',
	},
	{
		id: 2174,
		code: 'E291',
		label: 'Hipofunción testicular',
	},
	{
		id: 2175,
		code: 'E298',
		label: 'Otras disfunciones testiculares',
	},
	{
		id: 2176,
		code: 'E299',
		label: 'Disfunción testicular, no especificada',
	},
	{
		id: 2177,
		code: 'E30',
		label: 'Trastornos de la pubertad, no clasificados en otra parte',
	},
	{
		id: 2178,
		code: 'E300',
		label: 'Pubertad retardada',
	},
	{
		id: 2179,
		code: 'E301',
		label: 'Pubertad precoz',
	},
	{
		id: 2180,
		code: 'E308',
		label: 'Otros trastornos de la pubertad',
	},
	{
		id: 2181,
		code: 'E309',
		label: 'Trastorno de la pubertad, no especificado',
	},
	{
		id: 2182,
		code: 'E31',
		label: 'Disfunción poliglandular',
	},
	{
		id: 2183,
		code: 'E310',
		label: 'Insuficiencia poliglandular autoinmune',
	},
	{
		id: 2184,
		code: 'E311',
		label: 'Hiperfunción poliglandular',
	},
	{
		id: 2185,
		code: 'E318',
		label: 'Otras disfunciones poliglandulares',
	},
	{
		id: 2186,
		code: 'E319',
		label: 'Disfunción poliglandular, no especificada',
	},
	{
		id: 2187,
		code: 'E32',
		label: 'Enfermedades del timo',
	},
	{
		id: 2188,
		code: 'E320',
		label: 'Hiperplasia persistente del timo',
	},
	{
		id: 2189,
		code: 'E321',
		label: 'Absceso del timo',
	},
	{
		id: 2190,
		code: 'E328',
		label: 'Otras enfermedades del timo',
	},
	{
		id: 2191,
		code: 'E329',
		label: 'Enfermedad del timo, no especificada',
	},
	{
		id: 2192,
		code: 'E34',
		label: 'Otros trastornos endocrinos',
	},
	{
		id: 2193,
		code: 'E340',
		label: 'Síndrome carcinoide',
	},
	{
		id: 2194,
		code: 'E341',
		label: 'Otras hipersecreciones de hormonas intestinales',
	},
	{
		id: 2195,
		code: 'E342',
		label: 'Secreción hormonal ectópica, no clasificada en otra parte',
	},
	{
		id: 2196,
		code: 'E343',
		label: 'Enanismo, no clasificado en otra parte',
	},
	{
		id: 2197,
		code: 'E344',
		label: 'Estatura alta constitucional',
	},
	{
		id: 2198,
		code: 'E345',
		label: 'Síndrome de resistencia androgénica',
	},
	{
		id: 2199,
		code: 'E348',
		label: 'Otros trastornos endocrinos especificados',
	},
	{
		id: 2200,
		code: 'E349',
		label: 'Trastorno endocrino, no especificado',
	},
	{
		id: 2201,
		code: 'E35',
		label: 'Trastornos endocrinos en enfermedades clasificadas en otra parte',
	},
	{
		id: 2202,
		code: 'E350',
		label: 'Trastornos de la glándula tiroides en enfermedades clasificadas en otra parte',
	},
	{
		id: 2203,
		code: 'E351',
		label: 'Trastornos de las glándulas suprarrenales en enfermedades clasificadas en otra parte',
	},
	{
		id: 2204,
		code: 'E358',
		label: 'Trastornos de otras glándulas endocrinas en enfermedades clasificadas en otra parte',
	},
	{
		id: 2205,
		code: 'E40X',
		label: 'Kwashiorkor',
	},
	{
		id: 2206,
		code: 'E41X',
		label: 'Marasmo nutricional',
	},
	{
		id: 2207,
		code: 'E42X',
		label: 'Kwashiorkor marasmático',
	},
	{
		id: 2208,
		code: 'E43X',
		label: 'Desnutrición proteicocalórica severa, no especificada',
	},
	{
		id: 2209,
		code: 'E44',
		label: 'Desnutrición proteicocalórica de grado moderado y leve',
	},
	{
		id: 2210,
		code: 'E440',
		label: 'Desnutrición proteicocalórica moderada',
	},
	{
		id: 2211,
		code: 'E441',
		label: 'Desnutrición proteicocalórica leve',
	},
	{
		id: 2212,
		code: 'E45X',
		label: 'Retardo del desarrollo debido a desnutrición proteicocalórica',
	},
	{
		id: 2213,
		code: 'E46X',
		label: 'Desnutrición proteicocalórica, no especificada',
	},
	{
		id: 2214,
		code: 'E50',
		label: 'Deficiencia de vitamina A',
	},
	{
		id: 2215,
		code: 'E500',
		label: 'Deficiencia de vitamina A con xerosis conjuntival',
	},
	{
		id: 2216,
		code: 'E501',
		label: 'Deficiencia de vitamina A con mancha de Bitot y xerosis conjuntival',
	},
	{
		id: 2217,
		code: 'E502',
		label: 'Deficiencia de vitamina A con xerosis corneal',
	},
	{
		id: 2218,
		code: 'E503',
		label: 'Deficiencia de vitamina A con ulceración corneal y xerosis',
	},
	{
		id: 2219,
		code: 'E504',
		label: 'Deficiencia de vitamina A con queratomalacia',
	},
	{
		id: 2220,
		code: 'E505',
		label: 'Deficiencia de vitamina A con ceguera nocturna',
	},
	{
		id: 2221,
		code: 'E506',
		label: 'Deficiencia de vitamina A con cicatrices xeroftálmicas de la córnea',
	},
	{
		id: 2222,
		code: 'E507',
		label: 'Otras manifestaciones oculares de deficiencia de vitamina A',
	},
	{
		id: 2223,
		code: 'E508',
		label: 'Otras manifestaciones de deficiencia de vitamina A',
	},
	{
		id: 2224,
		code: 'E509',
		label: 'Deficiencia de vitamina A, no especificada',
	},
	{
		id: 2225,
		code: 'E51',
		label: 'Deficiencia de tiamina',
	},
	{
		id: 2226,
		code: 'E511',
		label: 'Beriberi',
	},
	{
		id: 2227,
		code: 'E512',
		label: 'Encefalopatía de Wernicke',
	},
	{
		id: 2228,
		code: 'E518',
		label: 'Otras manifestaciones de la deficiencia de tiamina',
	},
	{
		id: 2229,
		code: 'E519',
		label: 'Deficiencia de tiamina, no especificada',
	},
	{
		id: 2230,
		code: 'E52X',
		label: 'Deficiencia de niacina [pelagra]',
	},
	{
		id: 2231,
		code: 'E53',
		label: 'Deficiencias de otras vitaminas del grupo B',
	},
	{
		id: 2232,
		code: 'E530',
		label: 'Deficiencia de riboflavina',
	},
	{
		id: 2233,
		code: 'E531',
		label: 'Deficiencia de piridoxina',
	},
	{
		id: 2234,
		code: 'E538',
		label: 'Deficiencia de otras vitaminas del grupo B',
	},
	{
		id: 2235,
		code: 'E539',
		label: 'Deficiencia de vitamina B, no especificada',
	},
	{
		id: 2236,
		code: 'E54X',
		label: 'Deficiencia de ácido ascórbico',
	},
	{
		id: 2237,
		code: 'E55',
		label: 'Deficiencia de vitamina D',
	},
	{
		id: 2238,
		code: 'E550',
		label: 'Raquitismo activo',
	},
	{
		id: 2239,
		code: 'E559',
		label: 'Deficiencia de vitamina D, no especificada',
	},
	{
		id: 2240,
		code: 'E56',
		label: 'Otras deficiencias de vitaminas',
	},
	{
		id: 2241,
		code: 'E560',
		label: 'Deficiencia de vitamina E',
	},
	{
		id: 2242,
		code: 'E561',
		label: 'Deficiencia de vitamina K',
	},
	{
		id: 2243,
		code: 'E568',
		label: 'Deficiencia de otras vitaminas',
	},
	{
		id: 2244,
		code: 'E569',
		label: 'Deficiencia de vitamina, no especificada',
	},
	{
		id: 2245,
		code: 'E58X',
		label: 'Deficiencia dietética de calcio',
	},
	{
		id: 2246,
		code: 'E59X',
		label: 'Deficiencia dietética de selenio',
	},
	{
		id: 2247,
		code: 'E60X',
		label: 'Deficiencia dietética de zinc',
	},
	{
		id: 2248,
		code: 'E61',
		label: 'Deficiencias de otros elementos nutricionales',
	},
	{
		id: 2249,
		code: 'E610',
		label: 'Deficiencia de cobre',
	},
	{
		id: 2250,
		code: 'E611',
		label: 'Deficiencia de hierro',
	},
	{
		id: 2251,
		code: 'E612',
		label: 'Deficiencia de magnesio',
	},
	{
		id: 2252,
		code: 'E613',
		label: 'Deficiencia de manganeso',
	},
	{
		id: 2253,
		code: 'E614',
		label: 'Deficiencia de cromo',
	},
	{
		id: 2254,
		code: 'E615',
		label: 'Deficiencia de molibdeno',
	},
	{
		id: 2255,
		code: 'E616',
		label: 'Deficiencia de vanadio',
	},
	{
		id: 2256,
		code: 'E617',
		label: 'Deficiencia de múltiples elementos nutricionales',
	},
	{
		id: 2257,
		code: 'E618',
		label: 'Deficiencia de otros elementos nutricionales especificados',
	},
	{
		id: 2258,
		code: 'E619',
		label: 'Deficiencia de otro elemento nutricional, no especificado',
	},
	{
		id: 2259,
		code: 'E63',
		label: 'Otras deficiencias nutricionales',
	},
	{
		id: 2260,
		code: 'E630',
		label: 'Deficiencia de ácidos grasos esenciales [AGE]',
	},
	{
		id: 2261,
		code: 'E631',
		label: 'Desequilibrio de los constituyentes en la dieta',
	},
	{
		id: 2262,
		code: 'E638',
		label: 'Otras deficiencias nutricionales especificadas',
	},
	{
		id: 2263,
		code: 'E639',
		label: 'Deficiencia nutricional, no especificada',
	},
	{
		id: 2264,
		code: 'E64',
		label: 'Secuelas de la desnutrición y de otras deficiencias nutricionales',
	},
	{
		id: 2265,
		code: 'E640',
		label: 'Secuelas de la desnutrición proteicocalórica',
	},
	{
		id: 2266,
		code: 'E641',
		label: 'Secuelas de la deficiencia de vitamina A',
	},
	{
		id: 2267,
		code: 'E642',
		label: 'Secuelas de la deficiencia de vitamina C',
	},
	{
		id: 2268,
		code: 'E643',
		label: 'Secuelas del raquitismo',
	},
	{
		id: 2269,
		code: 'E648',
		label: 'Secuelas de otras deficiencias nutricionales',
	},
	{
		id: 2270,
		code: 'E649',
		label: 'Secuelas de la deficiencia nutricional no especificada',
	},
	{
		id: 2271,
		code: 'E65X',
		label: 'Adiposidad localizada',
	},
	{
		id: 2272,
		code: 'E66',
		label: 'Obesidad',
	},
	{
		id: 2273,
		code: 'E660',
		label: 'Obesidad debida a exceso de calorías',
	},
	{
		id: 2274,
		code: 'E661',
		label: 'Obesidad inducida por drogas',
	},
	{
		id: 2275,
		code: 'E662',
		label: 'Obesidad extrema con hipoventilación alveolar',
	},
	{
		id: 2276,
		code: 'E668',
		label: 'Otros tipos de obesidad',
	},
	{
		id: 2277,
		code: 'E669',
		label: 'Obesidad, no especificada',
	},
	{
		id: 2278,
		code: 'E67',
		label: 'Otros tipos de hiperalimentación',
	},
	{
		id: 2279,
		code: 'E670',
		label: 'Hipervitaminosis A',
	},
	{
		id: 2280,
		code: 'E671',
		label: 'Hipercarotinemia',
	},
	{
		id: 2281,
		code: 'E672',
		label: 'Síndrome de megavitamina B6',
	},
	{
		id: 2282,
		code: 'E673',
		label: 'Hipervitaminosis D',
	},
	{
		id: 2283,
		code: 'E678',
		label: 'Otros tipos de hiperalimentación especificados',
	},
	{
		id: 2284,
		code: 'E68X',
		label: 'Secuelas de hiperalimentación',
	},
	{
		id: 2285,
		code: 'E70',
		label: 'Trastornos del metabolismo de los aminoácidos aromáticos',
	},
	{
		id: 2286,
		code: 'E700',
		label: 'Fenilcetonuria clásica',
	},
	{
		id: 2287,
		code: 'E701',
		label: 'Otras hiperfenilalaninemias',
	},
	{
		id: 2288,
		code: 'E702',
		label: 'Trastornos del metabolismo de la tirosina',
	},
	{
		id: 2289,
		code: 'E703',
		label: 'Albinismo',
	},
	{
		id: 2290,
		code: 'E708',
		label: 'Otros trastornos del metabolismo de los aminoácidos aromáticos',
	},
	{
		id: 2291,
		code: 'E709',
		label: 'Trastorno del metabolismo de los aminoácidos aromáticos, no especificado',
	},
	{
		id: 2292,
		code: 'E71',
		label: 'Trastornos del metabolismo de los aminoácidos de cadena ramificada y de los ácidos grasos',
	},
	{
		id: 2293,
		code: 'E710',
		label: 'Enfermedad de la orina en jarabe de arce',
	},
	{
		id: 2294,
		code: 'E711',
		label: 'Otros trastornos del metabolismo de los aminoácidos de cadena ramificada',
	},
	{
		id: 2295,
		code: 'E712',
		label: 'Otros trastornos del metabolismo de los aminoácidos de cadena ramificada, no especificados',
	},
	{
		id: 2296,
		code: 'E713',
		label: 'Trastornos del metabolismo de los ácidos grasos',
	},
	{
		id: 2297,
		code: 'E72',
		label: 'Otros trastornos del metabolismo de los aminoácidos',
	},
	{
		id: 2298,
		code: 'E720',
		label: 'Trastornos del transporte de los aminoácidos',
	},
	{
		id: 2299,
		code: 'E721',
		label: 'Trastornos del metabolismo de los aminoácidos azufrados',
	},
	{
		id: 2300,
		code: 'E722',
		label: 'Trastornos del metabolismo del ciclo de la urea',
	},
	{
		id: 2301,
		code: 'E723',
		label: 'Trastornos del metabolismo de la lisina y la hidroxilisina',
	},
	{
		id: 2302,
		code: 'E724',
		label: 'Trastornos del metabolismo de la ornitina',
	},
	{
		id: 2303,
		code: 'E725',
		label: 'Trastornos del metabolismo de la glicina',
	},
	{
		id: 2304,
		code: 'E728',
		label: 'Otros trastornos especificados del metabolismo de los aminoácidos',
	},
	{
		id: 2305,
		code: 'E729',
		label: 'Trastorno del metabolismo de los aminoácidos, no especificado',
	},
	{
		id: 2306,
		code: 'E73',
		label: 'Intolerancia a la lactosa',
	},
	{
		id: 2307,
		code: 'E730',
		label: 'Deficiencia congénita de lactasa',
	},
	{
		id: 2308,
		code: 'E731',
		label: 'Deficiencia secundaria de lactasa',
	},
	{
		id: 2309,
		code: 'E738',
		label: 'Otros tipos de intolerancia a la lactosa',
	},
	{
		id: 2310,
		code: 'E739',
		label: 'Intolerancia a la lactosa, no especificada',
	},
	{
		id: 2311,
		code: 'E74',
		label: 'Otros trastornos del metabolismo de los carbohidratos',
	},
	{
		id: 2312,
		code: 'E740',
		label: 'Enfermedad del almacenamiento de glucógeno',
	},
	{
		id: 2313,
		code: 'E741',
		label: 'Trastornos del metabolismo de la fructosa',
	},
	{
		id: 2314,
		code: 'E742',
		label: 'Trastorno del metabolismo de la galactosa',
	},
	{
		id: 2315,
		code: 'E743',
		label: 'Otros trastornos de la absorción intestinal de carbohidratos',
	},
	{
		id: 2316,
		code: 'E744',
		label: 'Trastornos del metabolismo del piruvato y de la gluconeogénesis',
	},
	{
		id: 2317,
		code: 'E748',
		label: 'Otros trastornos especificados del metabolismo de los carbohidratos',
	},
	{
		id: 2318,
		code: 'E749',
		label: 'Trastorno del metabolismo de los carbohidratos, no especificado',
	},
	{
		id: 2319,
		code: 'E75',
		label: 'Trastornos del metabolismo de los esfingolípidos y otros trastornos por almacenamiento de lípidos',
	},
	{
		id: 2320,
		code: 'E750',
		label: 'Gangliosidosis GM2',
	},
	{
		id: 2321,
		code: 'E751',
		label: 'Otras gangliosidosis',
	},
	{
		id: 2322,
		code: 'E752',
		label: 'Otras esfingolipidosis',
	},
	{
		id: 2323,
		code: 'E753',
		label: 'Esfingolipidosis, no especificada',
	},
	{
		id: 2324,
		code: 'E754',
		label: 'Lipofuscinosis ceroide neuronal',
	},
	{
		id: 2325,
		code: 'E755',
		label: 'Otros trastornos del almacenamiento de lípidos',
	},
	{
		id: 2326,
		code: 'E756',
		label: 'Trastorno del almacenamiento de lípidos, no especificado',
	},
	{
		id: 2327,
		code: 'E76',
		label: 'Trastornos del metabolismo de los glucosaminoglicanos',
	},
	{
		id: 2328,
		code: 'E760',
		label: 'Mucopolisacaridosis tipo I',
	},
	{
		id: 2329,
		code: 'E761',
		label: 'Mucopolisacaridosis tipo II',
	},
	{
		id: 2330,
		code: 'E762',
		label: 'Otras mucopolisacaridosis',
	},
	{
		id: 2331,
		code: 'E763',
		label: 'Mucopolisacaridosis no especificada',
	},
	{
		id: 2332,
		code: 'E768',
		label: 'Otros trastornos del metabolismo de los glucosaminoglicanos',
	},
	{
		id: 2333,
		code: 'E769',
		label: 'Trastorno del metabolismo de los glucosaminoglicanos, no especificado',
	},
	{
		id: 2334,
		code: 'E77',
		label: 'Trastornos del metabolismo de las glucoproteínas',
	},
	{
		id: 2335,
		code: 'E770',
		label: 'Defectos en la modificación postraslacional de enzimas lisosomales',
	},
	{
		id: 2336,
		code: 'E771',
		label: 'Defectos de la degradación de glucoproteínas',
	},
	{
		id: 2337,
		code: 'E778',
		label: 'Otros trastornos del metabolismo de las glucoproteínas',
	},
	{
		id: 2338,
		code: 'E779',
		label: 'Trastornos del metabolismo de las glucoproteínas, no especificado',
	},
	{
		id: 2339,
		code: 'E78',
		label: 'Trastornos del metabolismo de las lipoproteínas y otras lipidemias',
	},
	{
		id: 2340,
		code: 'E780',
		label: 'Hipercolesterolemia pura',
	},
	{
		id: 2341,
		code: 'E781',
		label: 'Hipergliceridemia pura',
	},
	{
		id: 2342,
		code: 'E782',
		label: 'Hiperlipidemia mixta',
	},
	{
		id: 2343,
		code: 'E783',
		label: 'Hiperquilomicronemia',
	},
	{
		id: 2344,
		code: 'E784',
		label: 'Otra hiperlipidemia',
	},
	{
		id: 2345,
		code: 'E785',
		label: 'Hiperlipidemia no especificada',
	},
	{
		id: 2346,
		code: 'E786',
		label: 'Deficiencia de lipoproteínas',
	},
	{
		id: 2347,
		code: 'E788',
		label: 'Otros trastornos del metabolismo de las lipoproteínas',
	},
	{
		id: 2348,
		code: 'E789',
		label: 'Trastorno del metabolismo de las lipoproteínas, no especificado',
	},
	{
		id: 2349,
		code: 'E79',
		label: 'Trastornos del metabolismo de las purinas y de las pirimidinas',
	},
	{
		id: 2350,
		code: 'E790',
		label: 'Hiperuricemia sin signos de artritis inflamatoria y enfermedad tofácea',
	},
	{
		id: 2351,
		code: 'E791',
		label: 'Síndrome de Lesch-Nyhan',
	},
	{
		id: 2352,
		code: 'E798',
		label: 'Otros trastornos del metabolismo de las purinas y de las pirimidinas',
	},
	{
		id: 2353,
		code: 'E799',
		label: 'Trastorno del metabolismo de las purinas y de las pirimidinas, no especificado',
	},
	{
		id: 2354,
		code: 'E80',
		label: 'Trastornos del metabolismo de las porfirinas y de la bilirrubina',
	},
	{
		id: 2355,
		code: 'E800',
		label: 'Porfiria eritropoyética hereditaria',
	},
	{
		id: 2356,
		code: 'E801',
		label: 'Porfiria cutánea tardía',
	},
	{
		id: 2357,
		code: 'E802',
		label: 'Otras porfirias',
	},
	{
		id: 2358,
		code: 'E803',
		label: 'Defectos de catalasa y peroxidasa',
	},
	{
		id: 2359,
		code: 'E804',
		label: 'Síndrome de Gilbert',
	},
	{
		id: 2360,
		code: 'E805',
		label: 'Síndrome de Crigler-Najjar',
	},
	{
		id: 2361,
		code: 'E806',
		label: 'Otros trastornos del metabolismo de la bilirrubina',
	},
	{
		id: 2362,
		code: 'E807',
		label: 'Trastorno del metabolismo de la bilirrubina, no especificado',
	},
	{
		id: 2363,
		code: 'E83',
		label: 'Trastornos del metabolismo de los minerales',
	},
	{
		id: 2364,
		code: 'E830',
		label: 'Trastornos del metabolismo del cobre',
	},
	{
		id: 2365,
		code: 'E831',
		label: 'Trastornos del metabolismo del hierro',
	},
	{
		id: 2366,
		code: 'E832',
		label: 'Trastornos del metabolismo del zinc',
	},
	{
		id: 2367,
		code: 'E833',
		label: 'Trastornos del metabolismo del fósforo y fosfatasa',
	},
	{
		id: 2368,
		code: 'E834',
		label: 'Trastornos del metabolismo del magnesio',
	},
	{
		id: 2369,
		code: 'E835',
		label: 'Trastornos del metabolismo del calcio',
	},
	{
		id: 2370,
		code: 'E838',
		label: 'Otros trastornos del metabolismo de los minerales',
	},
	{
		id: 2371,
		code: 'E839',
		label: 'Trastorno del metabolismo de los minerales, no especificado',
	},
	{
		id: 2372,
		code: 'E84',
		label: 'Fibrosis quística',
	},
	{
		id: 2373,
		code: 'E840',
		label: 'Fibrosis quística con manifestaciones pulmonares',
	},
	{
		id: 2374,
		code: 'E841',
		label: 'Fibrosis quística con manifestaciones intestinales',
	},
	{
		id: 2375,
		code: 'E848',
		label: 'Fibrosis quística con otras manifestaciones',
	},
	{
		id: 2376,
		code: 'E849',
		label: 'Fibrosis quística, sin otra especificación',
	},
	{
		id: 2377,
		code: 'E85',
		label: 'Amiloidosis',
	},
	{
		id: 2378,
		code: 'E850',
		label: 'Amiloidosis heredofamiliar no neuropática',
	},
	{
		id: 2379,
		code: 'E851',
		label: 'Amiloidosis heredofamiliar neuropática',
	},
	{
		id: 2380,
		code: 'E852',
		label: 'Amiloidosis heredofamiliar no especificada',
	},
	{
		id: 2381,
		code: 'E853',
		label: 'Amiloidosis sistémica secundaria',
	},
	{
		id: 2382,
		code: 'E854',
		label: 'Amiloidosis limitada a un órgano',
	},
	{
		id: 2383,
		code: 'E858',
		label: 'Otras amiloidosis',
	},
	{
		id: 2384,
		code: 'E859',
		label: 'Amiloidosis, no especificada',
	},
	{
		id: 2385,
		code: 'E86X',
		label: 'Depleción del volumen',
	},
	{
		id: 2386,
		code: 'E87',
		label: 'Otros trastornos de los líquidos, de los electrólitos y del equilibrio ácido-básico',
	},
	{
		id: 2387,
		code: 'E870',
		label: 'Hiperosmolaridad e hipernatremia',
	},
	{
		id: 2388,
		code: 'E871',
		label: 'Hiposmolaridad e hiponatremia',
	},
	{
		id: 2389,
		code: 'E872',
		label: 'Acidosis',
	},
	{
		id: 2390,
		code: 'E873',
		label: 'Alcalosis',
	},
	{
		id: 2391,
		code: 'E874',
		label: 'Trastornos mixtos del balance ácido-básico',
	},
	{
		id: 2392,
		code: 'E875',
		label: 'Hiperpotasemia',
	},
	{
		id: 2393,
		code: 'E876',
		label: 'Hipopotasmia',
	},
	{
		id: 2394,
		code: 'E877',
		label: 'Sobrecarga de líquidos',
	},
	{
		id: 2395,
		code: 'E878',
		label: 'Otros trastornos del equilibrio de los electrólitos y de los líquidos, no clasificados en otra parte',
	},
	{
		id: 2396,
		code: 'E88',
		label: 'Otros trastornos metabólicos',
	},
	{
		id: 2397,
		code: 'E880',
		label: 'Trastornos del metabolismo de las proteínas plasmáticas, no clasificados en otra parte',
	},
	{
		id: 2398,
		code: 'E881',
		label: 'Lipodistrofia, no clasificada en otra parte',
	},
	{
		id: 2399,
		code: 'E882',
		label: 'Lipomatosis, no clasificada en otra parte',
	},
	{
		id: 2400,
		code: 'E883',
		label: 'Síndrome de lisis tumoral ',
	},
	{
		id: 2401,
		code: 'E888',
		label: 'Otros trastornos especificados del metabolismo',
	},
	{
		id: 2402,
		code: 'E889',
		label: 'Trastorno metabólico, no especificado',
	},
	{
		id: 2403,
		code: 'E89',
		label: 'Trastornos endocrinos y metabólicos consecutivos a procedimientos, no clasificados en otra parte',
	},
	{
		id: 2404,
		code: 'E890',
		label: 'Hipotiroidismo consecutivo a procedimientos',
	},
	{
		id: 2405,
		code: 'E891',
		label: 'Hipoinsulinemia consecutiva a procedimientos',
	},
	{
		id: 2406,
		code: 'E892',
		label: 'Hipoparatiroidismo consecutivo a procedimientos',
	},
	{
		id: 2407,
		code: 'E893',
		label: 'Hipopituitarismo consecutivo a procedimientos',
	},
	{
		id: 2408,
		code: 'E894',
		label: 'Insuficiencia ovárica consecutiva a procedimientos',
	},
	{
		id: 2409,
		code: 'E895',
		label: 'Hipofunción testicular consecutiva a procedimientos',
	},
	{
		id: 2410,
		code: 'E896',
		label: 'Hipofunción adrenocortical [médula suprarrenal] consecutiva a procedimientos',
	},
	{
		id: 2411,
		code: 'E898',
		label: 'Otros trastornos metabólicos y endocrinos consecutivos a procedimientos',
	},
	{
		id: 2412,
		code: 'E899',
		label: 'Trastorno endocrino y metabólico consecutivo a procedimientos, no especificado',
	},
	{
		id: 2413,
		code: 'E90X',
		label: 'Trastornos nutricionales y metabólicos en enfermedades clasificadas en otra parte',
	},
	{
		id: 2414,
		code: 'F00',
		label: 'Demencia en la enfermedad de Alzheimer',
	},
	{
		id: 2415,
		code: 'F000',
		label: 'Demencia  en la enfermedad de Alzheimer, de comienzo temprano',
	},
	{
		id: 2416,
		code: 'F001',
		label: 'Demencia  en la enfermedad de Alzheimer, de comienzo tardío',
	},
	{
		id: 2417,
		code: 'F002',
		label: 'Demencia  en la enfermedad de Alzheimer, atípica o de tipo mixto',
	},
	{
		id: 2418,
		code: 'F009',
		label: 'Demencia  en la enfermedad de Alzheimer, no especificada',
	},
	{
		id: 2419,
		code: 'F01',
		label: 'Demencia vascular',
	},
	{
		id: 2420,
		code: 'F010',
		label: 'Demencia  vascular de comienzo agudo',
	},
	{
		id: 2421,
		code: 'F011',
		label: 'Demencia  vascular por infartos múltiples',
	},
	{
		id: 2422,
		code: 'F012',
		label: 'Demencia  vascular subcortical',
	},
	{
		id: 2423,
		code: 'F013',
		label: 'Demencia  vascular mixta, cortical y subcortical',
	},
	{
		id: 2424,
		code: 'F018',
		label: 'Otras demencia vasculares',
	},
	{
		id: 2425,
		code: 'F019',
		label: 'Demencia  vascular, no especificada',
	},
	{
		id: 2426,
		code: 'F02',
		label: 'Demencia en otras enfermedades clasificadas en otra parte',
	},
	{
		id: 2427,
		code: 'F020',
		label: 'Demencia  en la enfermedad de Pick',
	},
	{
		id: 2428,
		code: 'F021',
		label: 'Demencia  en la enfermedad de Creutzfeldt-Jakob',
	},
	{
		id: 2429,
		code: 'F022',
		label: 'Demencia  en la enfermedad de Huntington',
	},
	{
		id: 2430,
		code: 'F023',
		label: 'Demencia  en la enfermedad de Parkinson',
	},
	{
		id: 2431,
		code: 'F024',
		label: 'Demencia  en la enfermedad por virus de la inmunodeficiencia humana [VIH]',
	},
	{
		id: 2432,
		code: 'F028',
		label: 'Demencia  en otras enfermedades especificadas clasificadas en otra parte',
	},
	{
		id: 2433,
		code: 'F03X',
		label: 'Demencia , no especificada',
	},
	{
		id: 2434,
		code: 'F04X',
		label: 'Síndrome amnésico orgánico, no inducido por alcohol o por otras sustancias psicoactivas',
	},
	{
		id: 2435,
		code: 'F05',
		label: 'Delirio, no inducido por alcohol o por otras sustancias psicoactivas',
	},
	{
		id: 2436,
		code: 'F050',
		label: 'Delirio no superpuesto a un cuadro de demencia, así descrito',
	},
	{
		id: 2437,
		code: 'F051',
		label: 'Delirio superpuesto a un cuadro de demencia',
	},
	{
		id: 2438,
		code: 'F058',
		label: 'Otros delirios',
	},
	{
		id: 2439,
		code: 'F059',
		label: 'Delirio, no especificado',
	},
	{
		id: 2440,
		code: 'F06',
		label: 'Otros trastornos mentales debidos a lesión y disfunción cerebral, y a enfermedad física',
	},
	{
		id: 2441,
		code: 'F060',
		label: 'Alucinosis orgánica',
	},
	{
		id: 2442,
		code: 'F061',
		label: 'Trastorno catatónico, orgánico',
	},
	{
		id: 2443,
		code: 'F062',
		label: 'Trastorno delirante [esquizofreniforme], orgánico',
	},
	{
		id: 2444,
		code: 'F063',
		label: 'Trastornos del humor [afectivos], orgánicos',
	},
	{
		id: 2445,
		code: 'F064',
		label: 'Trastorno de ansiedad, orgánico',
	},
	{
		id: 2446,
		code: 'F065',
		label: 'Trastorno disociativo, orgánico',
	},
	{
		id: 2447,
		code: 'F066',
		label: 'Trastorno de labilidad emocional [asténico], orgánico',
	},
	{
		id: 2448,
		code: 'F067',
		label: 'Trastorno cognoscitivo leve',
	},
	{
		id: 2449,
		code: 'F068',
		label: 'Otros trastornos mentales especificados debidos a lesión y disfunción cerebral y a enfermedad física',
	},
	{
		id: 2450,
		code: 'F069',
		label: 'Trastorno mental no especificado debido a lesión y disfunción cerebral y a enfermedad física',
	},
	{
		id: 2451,
		code: 'F07',
		label: 'Trastronos de la personalidad y del comportamiento debidos a enfermedad, lesión o disfunción cerebral',
	},
	{
		id: 2452,
		code: 'F070',
		label: 'Trastorno de la personalidad, orgánico',
	},
	{
		id: 2453,
		code: 'F071',
		label: 'Síndrome postencefalítico',
	},
	{
		id: 2454,
		code: 'F072',
		label: 'Síndrome postconcusional',
	},
	{
		id: 2455,
		code: 'F078',
		label: 'Otros trastornos orgánicos de la personalidad y del comportamiento debidos a enfermedad, lesión y  disfunción cerebrales',
	},
	{
		id: 2456,
		code: 'F079',
		label: 'Trastorno orgánico de la personalidad y del comportamiento, no especificado, debido a enfermedad, lesión y disfunción cerebral',
	},
	{
		id: 2457,
		code: 'F09X',
		label: 'Trastorno mental orgánico o sintomático, no especificado',
	},
	{
		id: 2458,
		code: 'F10',
		label: 'Trastornos mentales y del comportamiento debidos al uso de alcohol',
	},
	{
		id: 2459,
		code: 'F100',
		label: 'Trastornos mentales y del comportamiento debidos al uso de alcohol, intoxicación aguda',
	},
	{
		id: 2460,
		code: 'F101',
		label: 'Trastornos mentales y del comportamiento debidos al uso de alcohol, uso nocivo',
	},
	{
		id: 2461,
		code: 'F102',
		label: 'Trastornos mentales y del comportamiento debidos al uso de alcohol, síndrome de dependencia',
	},
	{
		id: 2462,
		code: 'F103',
		label: 'Trastornos mentales y del comportamiento debidos al uso de alcohol, estado de abstinencia',
	},
	{
		id: 2463,
		code: 'F104',
		label: 'Trastornos mentales y del comportamiento debidos al uso de alcohol, estado de abstinencia con delirio',
	},
	{
		id: 2464,
		code: 'F105',
		label: 'Trastornos mentales y del comportamiento debidos al uso de alcohol, trastorno psicótico',
	},
	{
		id: 2465,
		code: 'F106',
		label: 'Trastornos mentales y del comportamiento debidos al uso de alcohol, síndrome amnésico',
	},
	{
		id: 2466,
		code: 'F107',
		label: 'Trastornos mentales y del comportamiento debidos al uso de alcohol, trastorno psicótico residual y de comienzo tardío',
	},
	{
		id: 2467,
		code: 'F108',
		label: 'Trastornos mentales y del comportamiento debidos al uso de alcohol, otros trastornos mentales y del comportamiento',
	},
	{
		id: 2468,
		code: 'F109',
		label: 'Trastornos mentales y del comportamiento debidos al uso de alcohol, trastorno mental y del comportamiento, no especificado',
	},
	{
		id: 2469,
		code: 'F11',
		label: 'Trastornos mentales y del comportamiento debidos al uso de opiáceos',
	},
	{
		id: 2470,
		code: 'F110',
		label: 'Trastornos mentales y del comportamiento debidos al uso de opiáceos, intoxicación aguda',
	},
	{
		id: 2471,
		code: 'F111',
		label: 'Trastornos mentales y del comportamiento debidos al uso de opiáceos, uso nocivo',
	},
	{
		id: 2472,
		code: 'F112',
		label: 'Trastornos mentales y del comportamiento debidos al uso de opiáceos, síndrome de dependencia',
	},
	{
		id: 2473,
		code: 'F113',
		label: 'Trastornos mentales y del comportamiento debidos al uso de opiáceos, estado de abstinencia',
	},
	{
		id: 2474,
		code: 'F114',
		label: 'Trastornos mentales y del comportamiento debidos al uso de opiáceos, estado de abstinencia con delirio',
	},
	{
		id: 2475,
		code: 'F115',
		label: 'Trastornos mentales y del comportamiento debidos al uso de opiáceos, trastorno psicótico',
	},
	{
		id: 2476,
		code: 'F116',
		label: 'Trastornos mentales y del comportamiento debidos al uso de opiáceos, síndrome amnésico',
	},
	{
		id: 2477,
		code: 'F117',
		label: 'Trastornos mentales y del comportamiento debidos al uso de opiáceos, trastorno psicótico residual y de comienzo tardío',
	},
	{
		id: 2478,
		code: 'F118',
		label: 'Trastornos mentales y del comportamiento debidos al uso de opiáceos, otros trastornos mentales y del comportamiento',
	},
	{
		id: 2479,
		code: 'F119',
		label: 'Trastornos mentales y del comportamiento debidos al uso de opiáceos, trastorno mental y del comportamiento, no especificado',
	},
	{
		id: 2480,
		code: 'F12',
		label: 'Trastornos mentales y del comportamiento debidos al uso de cannabinoides',
	},
	{
		id: 2481,
		code: 'F120',
		label: 'Trastornos mentales y del comportamiento debidos al uso de cannabinoides, intoxicación aguda',
	},
	{
		id: 2482,
		code: 'F121',
		label: 'Trastornos mentales y del comportamiento debidos al uso de cannabinoides, uso nocivo',
	},
	{
		id: 2483,
		code: 'F122',
		label: 'Trastornos mentales y del comportamiento debidos al uso de cannabinoides, síndrome de dependencia',
	},
	{
		id: 2484,
		code: 'F123',
		label: 'Trastornos mentales y del comportamiento debidos al uso de cannabinoides, estado de abstinencia',
	},
	{
		id: 2485,
		code: 'F124',
		label: 'Trastornos mentales y del comportamiento debidos al uso de cannabinoides, estado de abstinencia con delirio',
	},
	{
		id: 2486,
		code: 'F125',
		label: 'Trastornos mentales y del comportamiento debidos al uso de cannabinoides, trastorno psicótico',
	},
	{
		id: 2487,
		code: 'F126',
		label: 'Trastornos mentales y del comportamiento debidos al uso de cannabinoides, síndrome amnésico',
	},
	{
		id: 2488,
		code: 'F127',
		label: 'Trastornos mentales y del comportamiento debidos al uso de cannabinoides, trastorno psicótico residual y de comienzo tardío',
	},
	{
		id: 2489,
		code: 'F128',
		label: 'Trastornos mentales y del comportamiento debidos al uso de cannabinoides, otros trastornos mentales y del comportamiento',
	},
	{
		id: 2490,
		code: 'F129',
		label: 'Trastornos mentales y del comportamiento debidos al uso de cannabinoides, trastorno mental y del comportamiento, no especificado',
	},
	{
		id: 2491,
		code: 'F13',
		label: 'Trastornos mentales y del comportamiento debidos al uso de sedantes  o hipnóticos',
	},
	{
		id: 2492,
		code: 'F130',
		label: 'Trastornos mentales y del comportamiento debidos al uso de sedantes o hipnóticos, intoxicación aguda',
	},
	{
		id: 2493,
		code: 'F131',
		label: 'Trastornos mentales y del comportamiento debidos al uso de sedantes o hipnóticos, uso nocivo',
	},
	{
		id: 2494,
		code: 'F132',
		label: 'Trastornos mentales y del comportamiento debidos al uso de sedantes o hipnóticos, síndrome de dependencia',
	},
	{
		id: 2495,
		code: 'F133',
		label: 'Trastornos mentales y del comportamiento debidos al uso de sedantes o hipnóticos, estado de abstinencia',
	},
	{
		id: 2496,
		code: 'F134',
		label: 'Trastornos mentales y del comportamiento debidos al uso de sedantes o hipnóticos, estado de abstinencia con delirio',
	},
	{
		id: 2497,
		code: 'F135',
		label: 'Trastornos mentales y del comportamiento debidos al uso de sedantes o hipnóticos, trastorno psicótico',
	},
	{
		id: 2498,
		code: 'F136',
		label: 'Trastornos mentales y del comportamiento debidos al uso de sedantes o hipnóticos, síndrome amnésico',
	},
	{
		id: 2499,
		code: 'F137',
		label: 'Trastornos mentales y del comportamiento debidos al uso de sedantes o hipnóticos, trastorno psicótico residual y de comienzo  tardío',
	},
	{
		id: 2500,
		code: 'F138',
		label: 'Trastornos mentales y del comportamiento debidos al uso de sedantes o hipnóticos, otros trastornos mentales y del comportamiento',
	},
	{
		id: 2501,
		code: 'F139',
		label: 'Trastornos mentales y del comportamiento debidos al uso de sedantes o hipnóticos, trastornos mental y del comportamiento, no especificados',
	},
	{
		id: 2502,
		code: 'F14',
		label: 'Trastornos mentales y del comportamiento debidos al uso de cocaína',
	},
	{
		id: 2503,
		code: 'F140',
		label: 'Trastornos mentales y del comportamiento debidos al uso de cocaína, intoxicación aguda',
	},
	{
		id: 2504,
		code: 'F141',
		label: 'Trastornos mentales y del comportamiento debidos al uso de cocaína, uso nocivo',
	},
	{
		id: 2505,
		code: 'F142',
		label: 'Trastornos mentales y del comportamiento debidos al uso de cocaína, síndrome de dependencia',
	},
	{
		id: 2506,
		code: 'F143',
		label: 'Trastornos mentales y del comportamiento debidos al uso de cocaína, estado de abstinencia',
	},
	{
		id: 2507,
		code: 'F144',
		label: 'Trastornos mentales y del comportamiento debidos al uso de cocaína, estado de abstinencia con delirio',
	},
	{
		id: 2508,
		code: 'F145',
		label: 'Trastornos mentales y del comportamiento debidos al uso de cocaína, trastorno psicótico',
	},
	{
		id: 2509,
		code: 'F146',
		label: 'Trastornos mentales y del comportamiento debidos al uso de cocaína, síndrome amnésico',
	},
	{
		id: 2510,
		code: 'F147',
		label: 'Trastornos mentales y del comportamiento debidos al uso de cocaína, trastorno psicótico residual y de comienzo tardío',
	},
	{
		id: 2511,
		code: 'F148',
		label: 'Trastornos mentales y del comportamiento debidos al uso de cocaína, otros trastornos mentales y del comportamiento',
	},
	{
		id: 2512,
		code: 'F149',
		label: 'Trastornos mentales y del comportamiento debidos al uso de cocaína, trastorno mental y del comportamiento, no especificado',
	},
	{
		id: 2513,
		code: 'F15',
		label: 'Trastornos mentales y del comportamiento debidos al uso de otros estimilantes, incluida la cafeína',
	},
	{
		id: 2514,
		code: 'F150',
		label: 'Trastornos mentales y del comportamiento debidos al uso de otros estimulantes, incluida la cafeína, intoxicación  aguda',
	},
	{
		id: 2515,
		code: 'F151',
		label: 'Trastornos mentales y del comportamiento debidos al uso de otros estimulantes, incluida la cafeína, uso nocivo',
	},
	{
		id: 2516,
		code: 'F152',
		label: 'Trastornos mentales y del comportamiento  debidos al uso de otros estimulantes, incluida la cafeína, síndrome de dependencia',
	},
	{
		id: 2517,
		code: 'F153',
		label: 'Trastornos mentales y del comportamiento  debidos al uso de otros estimulantes, incluida la cafeína, estado de abstinencia',
	},
	{
		id: 2518,
		code: 'F154',
		label: 'Trastornos mentales y del comportamiento  debidos al uso de otros estimulantes, incluida la cafeína, estado de abstinencia con delirio',
	},
	{
		id: 2519,
		code: 'F155',
		label: 'Trastornos mentales y del comportamiento debidos al uso de otros estimulantes, incluida la cafeína, trastorno psicótico',
	},
	{
		id: 2520,
		code: 'F156',
		label: 'Trastornos mentales y del comportamiento debidos al uso de otros estimulantes, incluida la cafeína, síndrome amnésico',
	},
	{
		id: 2521,
		code: 'F157',
		label: 'Trastornos mentales y del comportamiento debidos al uso de otros estimulantes, incluida la cafeína, trastorno psicótico residual y de comienzo tardío',
	},
	{
		id: 2522,
		code: 'F158',
		label: 'Trastornos mentales  y del comportamiento debidos al uso de otros estimulantes, incluida la cafeína, otros trastornos mentales y del comportamiento',
	},
	{
		id: 2523,
		code: 'F159',
		label: 'Trastornos mentales  y del comportamiento debidos al uso de otros estimulantes, incluida la cafeína, trastorno mental y del comportamiento, no especificado',
	},
	{
		id: 2524,
		code: 'F16',
		label: 'Trastornos mentales y del comportamiento debidos al uso de alucinógenos',
	},
	{
		id: 2525,
		code: 'F160',
		label: 'Trastornos mentales y del comportamiento debidos al uso de alucinógenos, intoxicación aguda',
	},
	{
		id: 2526,
		code: 'F161',
		label: 'Trastornos mentales y del comportamiento debidos al uso de alucinógenos, uso nocivo',
	},
	{
		id: 2527,
		code: 'F162',
		label: 'Trastornos mentales y del comportamiento debidos al uso de alucinógenos, síndrome de dependencia',
	},
	{
		id: 2528,
		code: 'F163',
		label: 'Trastornos mentales y del comportamiento debidos al uso de alucinógenos, estado de abstinencia',
	},
	{
		id: 2529,
		code: 'F164',
		label: 'Trastornos mentales y del comportamiento debidos al uso de alucinógenos, estado de abstinencia con delirio',
	},
	{
		id: 2530,
		code: 'F165',
		label: 'Trastornos mentales y del comportamiento debidos al uso de alucinógenos, trastorno psicótico',
	},
	{
		id: 2531,
		code: 'F166',
		label: 'Trastornos mentales y del comportamiento debidos al uso de alucinógenos, síndrome amnésico',
	},
	{
		id: 2532,
		code: 'F167',
		label: 'Trastornos mentales y del comportamiento debidos al uso de alucinógenos, trastorno psicótico residual y de comienzo tardío',
	},
	{
		id: 2533,
		code: 'F168',
		label: 'Trastornos mentales y del comportamiento debidos al uso de alucinógenos, otros trastorno mentales y del comportamiento',
	},
	{
		id: 2534,
		code: 'F169',
		label: 'Trastornos mentales y del comportamiento debidos al uso de alucinógenos, trastorno mental y del comportamiento, no especificado',
	},
	{
		id: 2535,
		code: 'F17',
		label: 'Trastornos mentales y del comportamiento debidos al uso de tabaco',
	},
	{
		id: 2536,
		code: 'F170',
		label: 'Trastornos mentales y del comportamiento debidos al uso de tabaco, intoxicación aguda',
	},
	{
		id: 2537,
		code: 'F171',
		label: 'Trastornos mentales y del comportamiento debidos al uso de tabaco, uso nocivo',
	},
	{
		id: 2538,
		code: 'F172',
		label: 'Trastornos mentales y del comportamiento debidos al uso de tabaco, síndrome de dependencia',
	},
	{
		id: 2539,
		code: 'F173',
		label: 'Trastornos mentales y del comportamiento debidos al uso de tabaco, estado de abstinencia',
	},
	{
		id: 2540,
		code: 'F174',
		label: 'Trastornos mentales y del comportamiento debidos al uso de tabaco, estado de abstinencia con delirio',
	},
	{
		id: 2541,
		code: 'F175',
		label: 'Trastornos mentales y del comportamiento debidos al uso de tabaco, trastorno psicótico',
	},
	{
		id: 2542,
		code: 'F176',
		label: 'Trastornos mentales y del comportamiento debidos al uso de tabaco, síndrome amnésico',
	},
	{
		id: 2543,
		code: 'F177',
		label: 'Trastornos mentales y del comportamiento debidos al uso de tabaco, trastorno psicótico residual y de comienzo tardio',
	},
	{
		id: 2544,
		code: 'F178',
		label: 'Trastornos mentales y del comportamiento debidos al uso de tabaco, otros trastornos mentales y del comportamiento',
	},
	{
		id: 2545,
		code: 'F179',
		label: 'Trastornos mentales y del comportamiento debidos al uso de tabaco, trastorno mental y del comportamiento, no especificado',
	},
	{
		id: 2546,
		code: 'F18',
		label: 'Trastornos mentales y del comportamiento debidos al uso de disolventes volátiles',
	},
	{
		id: 2547,
		code: 'F180',
		label: 'Trastornos mentales y del comportamiento debidos al uso de disolventes volátiles, intoxicación aguda',
	},
	{
		id: 2548,
		code: 'F181',
		label: 'Trastornos mentales y del comportamiento debidos al uso de disolventes volátiles, uso nocivo',
	},
	{
		id: 2549,
		code: 'F182',
		label: 'Trastornos mentales y del comportamiento debidos al uso de disolventes volátiles, síndrome de dependencia',
	},
	{
		id: 2550,
		code: 'F183',
		label: 'Trastornos mentales y del comportamiento debidos al uso de disolventes volátiles, estado de abstinencia',
	},
	{
		id: 2551,
		code: 'F184',
		label: 'Trastornos mentales y del comportamiento debidos al uso de disolventes volátiles, estado de abstinencia con delirio',
	},
	{
		id: 2552,
		code: 'F185',
		label: 'Trastornos mentales y del comportamiento debidos al uso de disolventes volátiles, trastorno psicótico',
	},
	{
		id: 2553,
		code: 'F186',
		label: 'Trastornos mentales y del comportamiento debidos al uso de disolventes volátiles,  síndrome amnésico',
	},
	{
		id: 2554,
		code: 'F187',
		label: 'Trastornos mentales y del comportamiento debidos al uso de disolventes volátiles, trastorno psicótico residual y de comienzo tardío',
	},
	{
		id: 2555,
		code: 'F188',
		label: 'Trastornos mentales y del comportamiento debidos al uso de disolventes volátiles, otros trastornos mentales y del comportamiento',
	},
	{
		id: 2556,
		code: 'F189',
		label: 'Trastornos mentales y del comportamiento debidos al uso de disolventes volátiles, trastorno mental y del comportamiento, no especificado',
	},
	{
		id: 2557,
		code: 'F19',
		label: 'Trastornos mentales y del comportamiento debidos al uso de múltiples drogas y al uso de otras sustancias psicoactivas',
	},
	{
		id: 2558,
		code: 'F190',
		label: 'Trastornos mentales y del comportamiento debidos al uso de múltiples drogas y al uso de otras sustancias psicoactivas, intoxicación aguda',
	},
	{
		id: 2559,
		code: 'F191',
		label: 'Trastornos mentales y del comportamiento debidos al uso de múltiples drogas y al uso de otras sustancias  psicoactivas, uso nocivo',
	},
	{
		id: 2560,
		code: 'F192',
		label: 'Trastornos mentales y del comportamiento debidos al uso de múltiples drogas y al uso de otras sustancias psicoactivas, sindrome de dependendencia',
	},
	{
		id: 2561,
		code: 'F193',
		label: 'Trastornos mentales y del comportamiento debidos al uso de múltiples drogas y al uso de otras sustancias psicoactivas, estado de abstinencia',
	},
	{
		id: 2562,
		code: 'F194',
		label: 'Trastornos mentales y del comportamiento debidos al uso de múltiples drogas y al uso de otras sustancias psicoactivas, estado de abstinencia con delirio',
	},
	{
		id: 2563,
		code: 'F195',
		label: 'Trastornos mentales y del comportamiento debidos al uso de múltiples drogas y al uso de otras sustancias psicoactivas, trastorno psicótico',
	},
	{
		id: 2564,
		code: 'F196',
		label: 'Trastornos mentales y del comportamiento debidos al uso de múltiples drogas y al uso de otras sustancias psicoactivas, síndrome amnésico',
	},
	{
		id: 2565,
		code: 'F197',
		label: 'Trastornos mentales y del comportamiento debidos al uso de multiples drogas y al uso de otras sustancias psicoactivas, trastorno psicótico residual y de comienzo tardío',
	},
	{
		id: 2566,
		code: 'F198',
		label: 'Trastornos mentales y del comportamiento debidos al uso de múltiples drogas  y al uso de otras sustancias psicoactivas, otros trastornos mentales y del comportamiento',
	},
	{
		id: 2567,
		code: 'F199',
		label: 'Trastornos mentales y del comportamiento debidos al uso de múltiples drogas y al uso de otras sustancias psicoactivas, trastorno mental y del comportamiento, no especificado',
	},
	{
		id: 2568,
		code: 'F20',
		label: 'Esquizofrenia',
	},
	{
		id: 2569,
		code: 'F200',
		label: 'Esquizofrenia paranoide',
	},
	{
		id: 2570,
		code: 'F201',
		label: 'Esquizofrenia hebefrénica',
	},
	{
		id: 2571,
		code: 'F202',
		label: 'Esquizofrenia catatónica',
	},
	{
		id: 2572,
		code: 'F203',
		label: 'Esquizofrenia indiferenciada',
	},
	{
		id: 2573,
		code: 'F204',
		label: 'Depresión postesquizofrénica',
	},
	{
		id: 2574,
		code: 'F205',
		label: 'Esquizofrenia residual',
	},
	{
		id: 2575,
		code: 'F206',
		label: 'Esquizofrenia simple',
	},
	{
		id: 2576,
		code: 'F208',
		label: 'Otras esquizofrenias',
	},
	{
		id: 2577,
		code: 'F209',
		label: 'Esquizofrenia, no especificada',
	},
	{
		id: 2578,
		code: 'F21X',
		label: 'Trastorno esquizotípico',
	},
	{
		id: 2579,
		code: 'F22',
		label: 'Trastornos delirantes persistentes',
	},
	{
		id: 2580,
		code: 'F220',
		label: 'Trastorno delirante',
	},
	{
		id: 2581,
		code: 'F228',
		label: 'Otros trastornos delirantes persistentes',
	},
	{
		id: 2582,
		code: 'F229',
		label: 'Trastornos delirante persistente, no especificado',
	},
	{
		id: 2583,
		code: 'F23',
		label: 'Trastornos psicóticos agudos y transitorios',
	},
	{
		id: 2584,
		code: 'F230',
		label: 'Trastorno psicótico agudo polimorfo, sin síntomas de esquizofrenia',
	},
	{
		id: 2585,
		code: 'F231',
		label: 'Trastorno psicótico agudo polimorfo, con síntomas de esquizofrenia',
	},
	{
		id: 2586,
		code: 'F232',
		label: 'Trastorno psicótico agudo de tipo esquizofrénico',
	},
	{
		id: 2587,
		code: 'F233',
		label: 'Otro trastorno psicótico agudo, con predominio de ideas delirantes',
	},
	{
		id: 2588,
		code: 'F238',
		label: 'Otros trastornos psicóticos agudos y transitorios',
	},
	{
		id: 2589,
		code: 'F239',
		label: 'Trastorno psicótico agudo y transitorio, no especificado',
	},
	{
		id: 2590,
		code: 'F24X',
		label: 'Trastorno delirante inducido',
	},
	{
		id: 2591,
		code: 'F25',
		label: 'Trastornos esquizoafectivos',
	},
	{
		id: 2592,
		code: 'F250',
		label: 'Trastorno esquizoafectivo de tipo maníaco',
	},
	{
		id: 2593,
		code: 'F251',
		label: 'Trastorno esquizoafectivo de tipo depresivo',
	},
	{
		id: 2594,
		code: 'F252',
		label: 'Trastorno esquizoafectivo de tipo mixto',
	},
	{
		id: 2595,
		code: 'F258',
		label: 'Otros trastornos esquizoafectivos',
	},
	{
		id: 2596,
		code: 'F259',
		label: 'Trastorno esquizoafectivo, no especificado',
	},
	{
		id: 2597,
		code: 'F28X',
		label: 'Otros trastornos psicóticos  de origen no orgánico',
	},
	{
		id: 2598,
		code: 'F29X',
		label: 'Psicosis de origen no orgánico, no especificada',
	},
	{
		id: 2599,
		code: 'F30',
		label: 'Episodio maníaco',
	},
	{
		id: 2600,
		code: 'F300',
		label: 'Hipomanía',
	},
	{
		id: 2601,
		code: 'F301',
		label: 'Manía sin síntomas psicóticos',
	},
	{
		id: 2602,
		code: 'F302',
		label: 'Manía con síntomas psicóticos',
	},
	{
		id: 2603,
		code: 'F308',
		label: 'Otros episodios maníacos',
	},
	{
		id: 2604,
		code: 'F309',
		label: 'Episodio maníaco, no especificado',
	},
	{
		id: 2605,
		code: 'F31',
		label: 'Trastorno afectivo bipolar',
	},
	{
		id: 2606,
		code: 'F310',
		label: 'Trastorno afectivo bipolar, episodio hipomaníaco presente',
	},
	{
		id: 2607,
		code: 'F311',
		label: 'Trastorno afectivo bipolar, episodio maníaco presente sin síntomas psicóticos',
	},
	{
		id: 2608,
		code: 'F312',
		label: 'Trastorno afectivo bipolar, episodio maníaco presente con síntomas psicóticos',
	},
	{
		id: 2609,
		code: 'F313',
		label: 'Trastorno afectivo bipolar, episodio depresivo presente leve o moderado',
	},
	{
		id: 2610,
		code: 'F314',
		label: 'Trastorno afectivo bipolar, episodio depresivo grave presente sin síntomas psicóticos',
	},
	{
		id: 2611,
		code: 'F315',
		label: 'Trastorno afectivo bipolar, episodio depresivo grave presente con síntomas psicóticos',
	},
	{
		id: 2612,
		code: 'F316',
		label: 'Trastorno afectivo bipolar, episodio mixto presente',
	},
	{
		id: 2613,
		code: 'F317',
		label: 'Trastorno afectivo bipolar, actualmente en remisión',
	},
	{
		id: 2614,
		code: 'F318',
		label: 'Otros trastornos afectivos bipolares',
	},
	{
		id: 2615,
		code: 'F319',
		label: 'Trastorno afectivo bipolar, no especificado',
	},
	{
		id: 2616,
		code: 'F32',
		label: 'Episodio depresivo',
	},
	{
		id: 2617,
		code: 'F320',
		label: 'Episodio depresivo leve',
	},
	{
		id: 2618,
		code: 'F321',
		label: 'Episodio depresivo moderado',
	},
	{
		id: 2619,
		code: 'F322',
		label: 'Episodio depresivo grave sin síntomas psicóticos',
	},
	{
		id: 2620,
		code: 'F323',
		label: 'Episodio depresivo grave con síntomas psicóticos',
	},
	{
		id: 2621,
		code: 'F328',
		label: 'Otros episodios depresivos',
	},
	{
		id: 2622,
		code: 'F329',
		label: 'Episodio depresivo, no especificado',
	},
	{
		id: 2623,
		code: 'F33',
		label: 'Trastorno depresivo recurrente',
	},
	{
		id: 2624,
		code: 'F330',
		label: 'Trastorno depresivo recurrente, episodio leve presente',
	},
	{
		id: 2625,
		code: 'F331',
		label: 'Trastorno depresivo recurrente, episodio moderado presente',
	},
	{
		id: 2626,
		code: 'F332',
		label: 'Trastorno depresivo recurrente, episodio depresivo grave presente sin síntomas psicóticos',
	},
	{
		id: 2627,
		code: 'F333',
		label: 'Trastorno depresivo recurrente, episodio depresivo grave presente, con síntomas psicóticos',
	},
	{
		id: 2628,
		code: 'F334',
		label: 'Trastorno depresivo recurrente actualmente en remisión',
	},
	{
		id: 2629,
		code: 'F338',
		label: 'Otros trastornos depresivos recurrentes',
	},
	{
		id: 2630,
		code: 'F339',
		label: 'Trastorno depresivo recurrente, no especificado',
	},
	{
		id: 2631,
		code: 'F34',
		label: 'Trastorno del humor [afectivos] persistentes',
	},
	{
		id: 2632,
		code: 'F340',
		label: 'Ciclotimia',
	},
	{
		id: 2633,
		code: 'F341',
		label: 'Distimia',
	},
	{
		id: 2634,
		code: 'F348',
		label: 'Otros trastornos del humor [afectivos] persistentes',
	},
	{
		id: 2635,
		code: 'F349',
		label: 'Trastorno persistente del humor [afectivo], no especificado',
	},
	{
		id: 2636,
		code: 'F38',
		label: 'Otros trastornos del humor [afectivos]',
	},
	{
		id: 2637,
		code: 'F380',
		label: 'Otros trastornos del humor [afectivos], aislados',
	},
	{
		id: 2638,
		code: 'F381',
		label: 'Otros trastornos del humor [afectivos], recurrentes',
	},
	{
		id: 2639,
		code: 'F388',
		label: 'Otros trastornos del humor [afectivos], especificados',
	},
	{
		id: 2640,
		code: 'F39X',
		label: 'Trastorno del humor [afectivo], no especificado',
	},
	{
		id: 2641,
		code: 'F40',
		label: 'Trastornos fóbicos de ansiedad',
	},
	{
		id: 2642,
		code: 'F400',
		label: 'Agorafobia',
	},
	{
		id: 2643,
		code: 'F401',
		label: 'Fobias sociales',
	},
	{
		id: 2644,
		code: 'F402',
		label: 'Fobias específicas (aisladas)',
	},
	{
		id: 2645,
		code: 'F408',
		label: 'Otros trastornos fóbicos de ansiedad',
	},
	{
		id: 2646,
		code: 'F409',
		label: 'Trastorno fóbico de ansiedad, no especificado',
	},
	{
		id: 2647,
		code: 'F41',
		label: 'Otros trastornos de ansiedad',
	},
	{
		id: 2648,
		code: 'F410',
		label: 'Trastorno de pánico [ansiedad paroxística episódica]',
	},
	{
		id: 2649,
		code: 'F411',
		label: 'Trastorno de ansiedad generalizada',
	},
	{
		id: 2650,
		code: 'F412',
		label: 'Trastorno mixto de ansiedad y depresión',
	},
	{
		id: 2651,
		code: 'F413',
		label: 'Otros trastornos de ansiedad mixtos',
	},
	{
		id: 2652,
		code: 'F418',
		label: 'Otros trastornos de ansiedad especificados',
	},
	{
		id: 2653,
		code: 'F419',
		label: 'Trastorno de ansiedad, no especificado',
	},
	{
		id: 2654,
		code: 'F42',
		label: 'Trastorno obsesivo-compulsivo',
	},
	{
		id: 2655,
		code: 'F420',
		label: 'Predominio de pensamientos o rumiaciones obsesivas',
	},
	{
		id: 2656,
		code: 'F421',
		label: 'Predominio de actos compulsivos [rituales obsesivos]',
	},
	{
		id: 2657,
		code: 'F422',
		label: 'Actos e ideas obsesivas mixtos',
	},
	{
		id: 2658,
		code: 'F428',
		label: 'Otros trastornos obsesivo-compulsivos',
	},
	{
		id: 2659,
		code: 'F429',
		label: 'Trastorno obsesivo-compulsivo, no especificado',
	},
	{
		id: 2660,
		code: 'F43',
		label: 'Reacción al estrés grave y trastornos de adaptación',
	},
	{
		id: 2661,
		code: 'F430',
		label: 'Reacción al estrés agudo',
	},
	{
		id: 2662,
		code: 'F431',
		label: 'Trastorno de estrés postraumático',
	},
	{
		id: 2663,
		code: 'F432',
		label: 'Trastornos de adaptación',
	},
	{
		id: 2664,
		code: 'F438',
		label: 'Otras reacciones al estrés grave',
	},
	{
		id: 2665,
		code: 'F439',
		label: 'Reacción al estrés grave, no especificada',
	},
	{
		id: 2666,
		code: 'F44',
		label: 'Trastornos disociativos [de conversión]',
	},
	{
		id: 2667,
		code: 'F440',
		label: 'Amnesia disociativa',
	},
	{
		id: 2668,
		code: 'F441',
		label: 'Fuga disociativa',
	},
	{
		id: 2669,
		code: 'F442',
		label: 'Estupor disociativo',
	},
	{
		id: 2670,
		code: 'F443',
		label: 'Trastornos de trance y de posesión',
	},
	{
		id: 2671,
		code: 'F444',
		label: 'Trastornos disociativos del movimiento',
	},
	{
		id: 2672,
		code: 'F445',
		label: 'Convulsiones disociativas',
	},
	{
		id: 2673,
		code: 'F446',
		label: 'Anestesia disociativa y pérdida sensorial',
	},
	{
		id: 2674,
		code: 'F447',
		label: 'Trastornos disociativos mixtos [y de conversión]',
	},
	{
		id: 2675,
		code: 'F448',
		label: 'Otros trastornos disociativos [de conversión]',
	},
	{
		id: 2676,
		code: 'F449',
		label: 'Trastorno disociativo [de conversión], no especificado',
	},
	{
		id: 2677,
		code: 'F45',
		label: 'Trastornos somatomorfos',
	},
	{
		id: 2678,
		code: 'F450',
		label: 'Trastorno de somatización',
	},
	{
		id: 2679,
		code: 'F451',
		label: 'Trastorno somatomorfo indiferenciado',
	},
	{
		id: 2680,
		code: 'F452',
		label: 'Trastorno hipocondríaco',
	},
	{
		id: 2681,
		code: 'F453',
		label: 'Disfunción autonómica somatomorfa',
	},
	{
		id: 2682,
		code: 'F454',
		label: 'Trastorno de dolor persistente somatomorfo',
	},
	{
		id: 2683,
		code: 'F458',
		label: 'Otros trastornos somatomorfos',
	},
	{
		id: 2684,
		code: 'F459',
		label: 'Trastorno somatomorfo, no especificado',
	},
	{
		id: 2685,
		code: 'F48',
		label: 'Otros trastornos neuróticos',
	},
	{
		id: 2686,
		code: 'F480',
		label: 'Neurastenia',
	},
	{
		id: 2687,
		code: 'F481',
		label: 'Síndrome de despersonalización y desvinculación de la realidad',
	},
	{
		id: 2688,
		code: 'F488',
		label: 'Otros trastornos neuróticos especificados',
	},
	{
		id: 2689,
		code: 'F489',
		label: 'Trastorno neurótico, no especificado',
	},
	{
		id: 2690,
		code: 'F50',
		label: 'Trastornos de la ingestión de alimentos',
	},
	{
		id: 2691,
		code: 'F500',
		label: 'Anorexia nerviosa',
	},
	{
		id: 2692,
		code: 'F501',
		label: 'Anorexia nerviosa atípica',
	},
	{
		id: 2693,
		code: 'F502',
		label: 'Bulimia nerviosa',
	},
	{
		id: 2694,
		code: 'F503',
		label: 'Bulimia nerviosa atípica',
	},
	{
		id: 2695,
		code: 'F504',
		label: 'Hiperfagia asociada con otras alteraciones psicológicas',
	},
	{
		id: 2696,
		code: 'F505',
		label: 'Vómitos asociados con otras alteraciones psicológicas',
	},
	{
		id: 2697,
		code: 'F508',
		label: 'Otros trastornos de la ingestión de alimentos',
	},
	{
		id: 2698,
		code: 'F509',
		label: 'Trastorno de la ingestión de alimentos, no especificado',
	},
	{
		id: 2699,
		code: 'F51',
		label: 'Trastornos no orgánicos del sueño',
	},
	{
		id: 2700,
		code: 'F510',
		label: 'Insomnio no orgánico',
	},
	{
		id: 2701,
		code: 'F511',
		label: 'hipersomnio no orgánico',
	},
	{
		id: 2702,
		code: 'F512',
		label: 'Trastorno no orgánico del ciclo sueño-vigilia',
	},
	{
		id: 2703,
		code: 'F513',
		label: 'Sonambulismo',
	},
	{
		id: 2704,
		code: 'F514',
		label: 'Terrores del sueño [terrores nocturnos]',
	},
	{
		id: 2705,
		code: 'F515',
		label: 'Pesadillas',
	},
	{
		id: 2706,
		code: 'F518',
		label: 'Otros trastornos no orgánicos del sueño',
	},
	{
		id: 2707,
		code: 'F519',
		label: 'Trastorno no orgánico del sueño, no especificado',
	},
	{
		id: 2708,
		code: 'F52',
		label: 'Disfunción sexual no ocasionada por trastorno ni enfermedad orgánicos',
	},
	{
		id: 2709,
		code: 'F520',
		label: 'Falta o pérdida del deseo sexual',
	},
	{
		id: 2710,
		code: 'F521',
		label: 'Aversión al sexo y falta de goce sexual',
	},
	{
		id: 2711,
		code: 'F522',
		label: 'Falla de la respuesta genital',
	},
	{
		id: 2712,
		code: 'F523',
		label: 'Disfunción orgásmica',
	},
	{
		id: 2713,
		code: 'F524',
		label: 'Eyaculación precoz',
	},
	{
		id: 2714,
		code: 'F525',
		label: 'Vaginismo no orgánico',
	},
	{
		id: 2715,
		code: 'F526',
		label: 'Dispareunia no orgánica',
	},
	{
		id: 2716,
		code: 'F527',
		label: 'Impulso sexual excesivo',
	},
	{
		id: 2717,
		code: 'F528',
		label: 'Otras disfunciones sexuales, no ocasionadas por trastorno ni por enfermedad orgánicos',
	},
	{
		id: 2718,
		code: 'F529',
		label: 'Disfunción sexual no ocasionada por trastorno ni por enfermedad orgánicos, no especificada',
	},
	{
		id: 2719,
		code: 'F53',
		label: 'Trastornos mentales y del comportamiento asociados con el puerperio, no clasificados en otra parte',
	},
	{
		id: 2720,
		code: 'F530',
		label: 'Trastornos mentales y del comportamiento leves, asociados con el puerperio, no clasificados en otra parte',
	},
	{
		id: 2721,
		code: 'F531',
		label: 'Trastornos mentales y del comportamiento graves, asociados con el puerperio, no clasificados en otra parte',
	},
	{
		id: 2722,
		code: 'F538',
		label: 'Otros trastornos mentales y del comportamiento asociados con el puerperio, no clasificados en otra parte',
	},
	{
		id: 2723,
		code: 'F539',
		label: 'Trastorno mental puerperal, no especificado',
	},
	{
		id: 2724,
		code: 'F54X',
		label: 'Factores psicológicos y del comportamiento asociados con trastornos o enfermedades clasificados en otra parte',
	},
	{
		id: 2725,
		code: 'F55X',
		label: 'Abuso de sustancias que no producen dependencia',
	},
	{
		id: 2726,
		code: 'F59X',
		label: 'Síndromes del comportamiento asociados con alteraciones fisiológicas y factores físicos, no especificados',
	},
	{
		id: 2727,
		code: 'F60',
		label: 'Trastornos específicos de la personalidad',
	},
	{
		id: 2728,
		code: 'F600',
		label: 'Trastorno paranoide de la personalidad',
	},
	{
		id: 2729,
		code: 'F601',
		label: 'Trastorno esquizoide de la personalidad',
	},
	{
		id: 2730,
		code: 'F602',
		label: 'Trastorno asocial de la personalidad',
	},
	{
		id: 2731,
		code: 'F603',
		label: 'Trastorno de la personalidad emocionalmente inestable',
	},
	{
		id: 2732,
		code: 'F604',
		label: 'Trastorno histriónico de la personalidad',
	},
	{
		id: 2733,
		code: 'F605',
		label: 'Trastorno anancástico de la personalidad',
	},
	{
		id: 2734,
		code: 'F606',
		label: 'Trastorno de la personalidad ansiosa (evasiva, elusiva)',
	},
	{
		id: 2735,
		code: 'F607',
		label: 'Trastorno de la personalidad dependiente',
	},
	{
		id: 2736,
		code: 'F608',
		label: 'Otros trastornos específicos de la personalidad',
	},
	{
		id: 2737,
		code: 'F609',
		label: 'Trastorno de la personalidad, no especificado',
	},
	{
		id: 2738,
		code: 'F61X',
		label: 'Trastornos mixtos y otros trastornos de la personalidad',
	},
	{
		id: 2739,
		code: 'F62',
		label: 'Cambios perdurables de la personalidad, no atribuibles a lesión o a enfermedad cerebral',
	},
	{
		id: 2740,
		code: 'F620',
		label: 'Cambio perdurable de la personalidad después de una experiencia catastrófica',
	},
	{
		id: 2741,
		code: 'F621',
		label: 'Cambio perdurable de la personalidad consecutivo a una enfermedad psiquiátrica',
	},
	{
		id: 2742,
		code: 'F628',
		label: 'Otros cambios perdurables de la personalidad',
	},
	{
		id: 2743,
		code: 'F629',
		label: 'Cambio perdurable de la personalidad, no especificado',
	},
	{
		id: 2744,
		code: 'F63',
		label: 'Trastornos de los hábitos y de los impulsos',
	},
	{
		id: 2745,
		code: 'F630',
		label: 'Juego patológico',
	},
	{
		id: 2746,
		code: 'F631',
		label: 'Piromanía',
	},
	{
		id: 2747,
		code: 'F632',
		label: 'Hurto patológico [cleptomanía]',
	},
	{
		id: 2748,
		code: 'F633',
		label: 'Tricotilomanía',
	},
	{
		id: 2749,
		code: 'F638',
		label: 'Otros trastornos de los hábitos y de los impulsos',
	},
	{
		id: 2750,
		code: 'F639',
		label: 'Trastorno de los hábitos y de los impulsos, no especificado',
	},
	{
		id: 2751,
		code: 'F64',
		label: 'Trastornos de la identidad de género',
	},
	{
		id: 2752,
		code: 'F640',
		label: 'Transexualismo',
	},
	{
		id: 2753,
		code: 'F641',
		label: 'Transvestismo de rol dual',
	},
	{
		id: 2754,
		code: 'F642',
		label: 'Trastorno de la identidad de género en la niñez',
	},
	{
		id: 2755,
		code: 'F648',
		label: 'Otros trastornos de la identidad de género',
	},
	{
		id: 2756,
		code: 'F649',
		label: 'Trastorno de la identidad de género, no especificado',
	},
	{
		id: 2757,
		code: 'F65',
		label: 'Trastornos de la preferencia sexual',
	},
	{
		id: 2758,
		code: 'F650',
		label: 'Fetichismo',
	},
	{
		id: 2759,
		code: 'F651',
		label: 'Transvestismo fetichista',
	},
	{
		id: 2760,
		code: 'F652',
		label: 'Exhibicionismo',
	},
	{
		id: 2761,
		code: 'F653',
		label: 'Voyeurismo',
	},
	{
		id: 2762,
		code: 'F654',
		label: 'Pedofilia',
	},
	{
		id: 2763,
		code: 'F655',
		label: 'Sadomasoquismo',
	},
	{
		id: 2764,
		code: 'F656',
		label: 'Trastornos múltiples de la preferencia sexual',
	},
	{
		id: 2765,
		code: 'F658',
		label: 'Otros trastornos de la preferencia sexual',
	},
	{
		id: 2766,
		code: 'F659',
		label: 'Trastorno de la preferencia sexual, no especificado',
	},
	{
		id: 2767,
		code: 'F66',
		label: 'Trastornos psicológicos y del comportamiento asociados con el desarrollo y con la orientación sexuales',
	},
	{
		id: 2768,
		code: 'F660',
		label: 'Trastorno de la maduración sexual',
	},
	{
		id: 2769,
		code: 'F661',
		label: 'Orientación sexual egodistónica',
	},
	{
		id: 2770,
		code: 'F662',
		label: 'Trastorno de la relación sexual',
	},
	{
		id: 2771,
		code: 'F668',
		label: 'Otros trastornos del desarrollo psicosexual',
	},
	{
		id: 2772,
		code: 'F669',
		label: 'Trastorno del desarrollo psicosexual, no especificado',
	},
	{
		id: 2773,
		code: 'F68',
		label: 'Otros trastornos de la personalidad y del comportamiento en adultos',
	},
	{
		id: 2774,
		code: 'F680',
		label: 'Elaboración de síntomas físicos por causas psicológicas',
	},
	{
		id: 2775,
		code: 'F681',
		label: 'Producción intencional o simulación de síntomas o de incapacidades, tanto físicas como psicológicas [trastorno facticio]',
	},
	{
		id: 2776,
		code: 'F688',
		label: 'Otros trastornos especificados de la personalidad y del comportamiento en adultos',
	},
	{
		id: 2777,
		code: 'F69X',
		label: 'Trastorno de la personalidad y del comportamiento en adultos, no especificado',
	},
	{
		id: 2778,
		code: 'F70',
		label: 'Retraso mental leve',
	},
	{
		id: 2779,
		code: 'F700',
		label: 'Retraso mental leve, deterioro del comportamiento nulo o mínimo',
	},
	{
		id: 2780,
		code: 'F701',
		label: 'Retraso mental leve, deterioro del comportamiento significativo, que requiere atención o tratamiento',
	},
	{
		id: 2781,
		code: 'F708',
		label: 'Retraso mental leve, otros deterioros del comportamiento',
	},
	{
		id: 2782,
		code: 'F709',
		label: 'Retraso mental leve, deterioro del comportamiento de grado no especificado',
	},
	{
		id: 2783,
		code: 'F71',
		label: 'Retraso mental moderado',
	},
	{
		id: 2784,
		code: 'F710',
		label: 'Retraso mental moderado, deterioro del comportamiento nulo o mínimo',
	},
	{
		id: 2785,
		code: 'F711',
		label: 'Retraso mental moderado, deterioro del comportamiento significativo, que requiere atención o tratamiento',
	},
	{
		id: 2786,
		code: 'F718',
		label: 'Retraso mental moderado, otros deterioros del comportamiento',
	},
	{
		id: 2787,
		code: 'F719',
		label: 'Retraso mental moderado, deterioro del comportamiento de grado no especificado',
	},
	{
		id: 2788,
		code: 'F72',
		label: 'Retraso mental grave',
	},
	{
		id: 2789,
		code: 'F720',
		label: 'Retraso mental grave, deterioro del comportamiento nulo o mínimo',
	},
	{
		id: 2790,
		code: 'F721',
		label: 'Retraso mental grave, deterioro del comportamiento significativo, que requiere atención o tratamiento',
	},
	{
		id: 2791,
		code: 'F728',
		label: 'Retraso mental grave, otros deterioros del comportamiento',
	},
	{
		id: 2792,
		code: 'F729',
		label: 'Retraso mental grave, deterioro del comportamiento de grado no especificado',
	},
	{
		id: 2793,
		code: 'F73',
		label: 'Retraso mental profundo',
	},
	{
		id: 2794,
		code: 'F730',
		label: 'Retraso mental profundo, deterioro del comportamiento nulo o mínimo',
	},
	{
		id: 2795,
		code: 'F731',
		label: 'Retraso mental profundo, deterioro del comportamiento significativo, que requiere atención o tratamiento',
	},
	{
		id: 2796,
		code: 'F738',
		label: 'Retraso mental profundo, otros deterioros del comportamiento',
	},
	{
		id: 2797,
		code: 'F739',
		label: 'Retraso mental profundo, deterioro del comportamiento de grado no especificado',
	},
	{
		id: 2798,
		code: 'F78',
		label: 'Otros tipos de retraso mental',
	},
	{
		id: 2799,
		code: 'F780',
		label: 'Otros tipos de retraso mental, deterioro del comportamiento nulo o mínimo',
	},
	{
		id: 2800,
		code: 'F781',
		label: 'Otros tipos de retraso mental, deterioro del comportamiento significativo, que requiere atención o tratamiento',
	},
	{
		id: 2801,
		code: 'F788',
		label: 'Otros tipos de retraso mental, otros deterioros del comportamiento',
	},
	{
		id: 2802,
		code: 'F789',
		label: 'Otros tipos de retraso mental,  deterioro del comportamiento de grado no especificado',
	},
	{
		id: 2803,
		code: 'F79',
		label: 'Retraso mental, no especificado',
	},
	{
		id: 2804,
		code: 'F790',
		label: 'Retraso mental, no especificado, deterioro del comportamiento nulo o mínimo',
	},
	{
		id: 2805,
		code: 'F791',
		label: 'Retraso mental, no especificado, deterioro del comportamiento significativo, que requiere atención o tratamiento',
	},
	{
		id: 2806,
		code: 'F798',
		label: 'Retraso mental, no especificado, otros deterioros del comportamiento',
	},
	{
		id: 2807,
		code: 'F799',
		label: 'Retraso mental, no especificado, deterioro del comportamiento de grado no especificado',
	},
	{
		id: 2808,
		code: 'F80',
		label: 'Trastornos específicos del desarrollo del habla y del lenguaje',
	},
	{
		id: 2809,
		code: 'F800',
		label: 'Trastorno específico de la pronunciación',
	},
	{
		id: 2810,
		code: 'F801',
		label: 'Trastorno del lenguaje expresivo',
	},
	{
		id: 2811,
		code: 'F802',
		label: 'Trastorno de la recepción del lenguaje',
	},
	{
		id: 2812,
		code: 'F803',
		label: 'Afasia adquirida con epilepsia [Landau-Kleffner]',
	},
	{
		id: 2813,
		code: 'F808',
		label: 'Otros trastornos del desarrollo del habla y del lenguaje',
	},
	{
		id: 2814,
		code: 'F809',
		label: 'Trastorno del desarrollo del habla y del lenguaje no especificado',
	},
	{
		id: 2815,
		code: 'F81',
		label: 'Trastornos específicos del  desarrollo de las habilidades escolares',
	},
	{
		id: 2816,
		code: 'F810',
		label: 'Trastorno específico de la lectura',
	},
	{
		id: 2817,
		code: 'F811',
		label: 'Trastorno específico del deletreo [ortografía]',
	},
	{
		id: 2818,
		code: 'F812',
		label: 'Trastorno específico de las habilidades aritméticas',
	},
	{
		id: 2819,
		code: 'F813',
		label: 'Trastorno mixto de las habilidades escolares',
	},
	{
		id: 2820,
		code: 'F818',
		label: 'Otros trastornos del desarrollo de las habilidades escolares',
	},
	{
		id: 2821,
		code: 'F819',
		label: 'Trastorno del desarrollo de las habilidades escolares, no especificado',
	},
	{
		id: 2822,
		code: 'F82X',
		label: 'Trastorno especÍfico del desarrollo de la función motriz',
	},
	{
		id: 2823,
		code: 'F83X',
		label: 'Trastornos específicos mixtos del desarrollo',
	},
	{
		id: 2824,
		code: 'F84',
		label: 'Trastornos generalizados del desarrollo',
	},
	{
		id: 2825,
		code: 'F840',
		label: 'Autismo en la niñez',
	},
	{
		id: 2826,
		code: 'F841',
		label: 'Autismo atípico',
	},
	{
		id: 2827,
		code: 'F842',
		label: 'Síndrome de Rett',
	},
	{
		id: 2828,
		code: 'F843',
		label: 'Otro trastorno desintegrativo de la niñez',
	},
	{
		id: 2829,
		code: 'F844',
		label: 'Trastorno hiperactivo asociado con retraso mental y movimientos estereotipados',
	},
	{
		id: 2830,
		code: 'F845',
		label: 'Síndrome de Asperger',
	},
	{
		id: 2831,
		code: 'F848',
		label: 'Otros trastornos generalizados del desarrollo',
	},
	{
		id: 2832,
		code: 'F849',
		label: 'Trastorno generalizado del desarrollo no especificado',
	},
	{
		id: 2833,
		code: 'F88X',
		label: 'Otros trastornos del desarrollo psicológico',
	},
	{
		id: 2834,
		code: 'F89X',
		label: 'Trastorno del desarrollo psicológico, no especificado',
	},
	{
		id: 2835,
		code: 'F90',
		label: 'Trastornos hipercinéticos',
	},
	{
		id: 2836,
		code: 'F900',
		label: 'Perturbación de la actividad y de la atención',
	},
	{
		id: 2837,
		code: 'F901',
		label: 'Trastorno hipercinético de la conducta',
	},
	{
		id: 2838,
		code: 'F908',
		label: 'Otros trastornos hipercinéticos',
	},
	{
		id: 2839,
		code: 'F909',
		label: 'Trastorno hipercinético, no especificado',
	},
	{
		id: 2840,
		code: 'F91',
		label: 'Trastornos de la conducta',
	},
	{
		id: 2841,
		code: 'F910',
		label: 'Trastorno de la conducta limitado al contexto familiar',
	},
	{
		id: 2842,
		code: 'F911',
		label: 'Trastorno de la conducta insociable',
	},
	{
		id: 2843,
		code: 'F912',
		label: 'Trastorno de la conducta sociable',
	},
	{
		id: 2844,
		code: 'F913',
		label: 'Trastorno opositor desafiante',
	},
	{
		id: 2845,
		code: 'F918',
		label: 'Otros trastornos de la conducta',
	},
	{
		id: 2846,
		code: 'F919',
		label: 'Trastorno de la conducta, no especificado',
	},
	{
		id: 2847,
		code: 'F92',
		label: 'Trastornos mixtos de la conducta y de las emociones',
	},
	{
		id: 2848,
		code: 'F920',
		label: 'Trastorno depresivo de la conducta',
	},
	{
		id: 2849,
		code: 'F928',
		label: 'Otros trastornos mixtos de la conducta y de las emociones',
	},
	{
		id: 2850,
		code: 'F929',
		label: 'Trastorno mixto de la conducta y de las emociones, no especificado',
	},
	{
		id: 2851,
		code: 'F93',
		label: 'Trastornos emocionales de comienzo específico en la niñez',
	},
	{
		id: 2852,
		code: 'F930',
		label: 'Trastorno de ansiedad de separación en la niñez',
	},
	{
		id: 2853,
		code: 'F931',
		label: 'Trastorno de ansiedad fóbica en la niñez',
	},
	{
		id: 2854,
		code: 'F932',
		label: 'Trastorno de ansiedad social en la niñez',
	},
	{
		id: 2855,
		code: 'F933',
		label: 'Trastorno de rivalidad entre hermanos',
	},
	{
		id: 2856,
		code: 'F938',
		label: 'Otros trastornos emocionales en la niñez',
	},
	{
		id: 2857,
		code: 'F939',
		label: 'Trastorno emocional en la niñez, no especificado',
	},
	{
		id: 2858,
		code: 'F94',
		label: 'Trastornos del comportamiento social de comienzo específico en la niñez y en la adolescencia',
	},
	{
		id: 2859,
		code: 'F940',
		label: 'Mutismo electivo',
	},
	{
		id: 2860,
		code: 'F941',
		label: 'Trastorno de vinculación reactiva en la niñez',
	},
	{
		id: 2861,
		code: 'F942',
		label: 'Trastorno de vinculación desinhibida en la niñez',
	},
	{
		id: 2862,
		code: 'F948',
		label: 'Otros trastornos del comportamiento social en la niñez',
	},
	{
		id: 2863,
		code: 'F949',
		label: 'Trastorno del comportamiento social en la niñez, no especificado',
	},
	{
		id: 2864,
		code: 'F95',
		label: 'Trastornos por tics',
	},
	{
		id: 2865,
		code: 'F950',
		label: 'Trastorno por tic transitorio',
	},
	{
		id: 2866,
		code: 'F951',
		label: 'Trastorno por tic motor o vocal crónico',
	},
	{
		id: 2867,
		code: 'F952',
		label: 'Trastorno por tics motores y vocales múltiples combinados [de la Tourette]',
	},
	{
		id: 2868,
		code: 'F958',
		label: 'Otros trastornos por tics',
	},
	{
		id: 2869,
		code: 'F959',
		label: 'Trastorno por tic, no especificado',
	},
	{
		id: 2870,
		code: 'F98',
		label: 'Otros trastornos emocionales y del comportamiento que aparecen habitualmente en la niñez y en la adolescencia',
	},
	{
		id: 2871,
		code: 'F980',
		label: 'Enuresis no orgánica',
	},
	{
		id: 2872,
		code: 'F981',
		label: 'Encopresis no orgánica',
	},
	{
		id: 2873,
		code: 'F982',
		label: 'Trastorno de la ingestión alimentaria en la infancia y la niñez',
	},
	{
		id: 2874,
		code: 'F983',
		label: 'Pica en la infancia y la niñez',
	},
	{
		id: 2875,
		code: 'F984',
		label: 'Trastornos de los movimientos estereotipados',
	},
	{
		id: 2876,
		code: 'F985',
		label: 'Tartamudez [espasmofemia]',
	},
	{
		id: 2877,
		code: 'F986',
		label: 'Farfulleo',
	},
	{
		id: 2878,
		code: 'F988',
		label: 'Otros trastornos emocionales y del comportamiento que aparecen habitualmente en la niñez y en la adolescencia',
	},
	{
		id: 2879,
		code: 'F989',
		label: 'Trastornos no especificados, emocionales y del comportamiento, que aparecen habitualmente en la niñez y en la adolescencia',
	},
	{
		id: 2880,
		code: 'F99X',
		label: 'Trastorno mental, no especificado',
	},
	{
		id: 2881,
		code: 'G00',
		label: 'Meningitis bacteriana, no clasificada en otra parte',
	},
	{
		id: 2882,
		code: 'G000',
		label: 'Meningitis por hemófilos',
	},
	{
		id: 2883,
		code: 'G001',
		label: 'Meningitis  neumocócica',
	},
	{
		id: 2884,
		code: 'G002',
		label: 'Meningitis estreptocócica',
	},
	{
		id: 2885,
		code: 'G003',
		label: 'Meningitis estafilocócica',
	},
	{
		id: 2886,
		code: 'G008',
		label: 'Otras meningitis bacterianas',
	},
	{
		id: 2887,
		code: 'G009',
		label: 'Meningitis bacteriana, no especificada',
	},
	{
		id: 2888,
		code: 'G01X',
		label: 'Meningitis en enfermedades bacterianas clasificadas en otra parte',
	},
	{
		id: 2889,
		code: 'G02',
		label: 'Meningitis en otras enfermedades infecciosas y parasitarias clasificada en otra parte',
	},
	{
		id: 2890,
		code: 'G020',
		label: 'Meningitis en enfermedades virales clasificadas en otra parte',
	},
	{
		id: 2891,
		code: 'G021',
		label: 'Meningitis en micosis',
	},
	{
		id: 2892,
		code: 'G028',
		label: 'Meningitis en otras enfermedades infecciosas y parasitarias especificadas clasificadas en otra parte',
	},
	{
		id: 2893,
		code: 'G03',
		label: 'Meningitis debida a otras causas y a las no especificadas',
	},
	{
		id: 2894,
		code: 'G030',
		label: 'Meningitis apiógena',
	},
	{
		id: 2895,
		code: 'G031',
		label: 'Meningitis crónica',
	},
	{
		id: 2896,
		code: 'G032',
		label: 'Meningitis recurrente benigna [Mollaret]',
	},
	{
		id: 2897,
		code: 'G038',
		label: 'Meningitis debidas a otras causas especificadas',
	},
	{
		id: 2898,
		code: 'G039',
		label: 'Meningitis, no especificada',
	},
	{
		id: 2899,
		code: 'G04',
		label: 'Encefalitis, mielitis y encefalomielitis',
	},
	{
		id: 2900,
		code: 'G040',
		label: 'Encefalitis aguda diseminada',
	},
	{
		id: 2901,
		code: 'G041',
		label: 'Paraplejía espástica tropical',
	},
	{
		id: 2902,
		code: 'G042',
		label: 'Meningoencefalitis y meningomielitis bacterianas, no clasificadas en otra parte',
	},
	{
		id: 2903,
		code: 'G048',
		label: 'Otras encefalitis, mielitis y encefalomielitis',
	},
	{
		id: 2904,
		code: 'G049',
		label: 'Encefalitis, mielitis y encefalomielitis, no especificadas',
	},
	{
		id: 2905,
		code: 'G05',
		label: 'Encefalitis, mielitis y encefalomielitis en enfermedades clasificadas en otra parte',
	},
	{
		id: 2906,
		code: 'G050',
		label: 'Encefalitis, mielitis y encefalomielitis en enfermedades bacterianas clasificadas en otra parte',
	},
	{
		id: 2907,
		code: 'G051',
		label: 'Encefalitis, mielitis y encefalomielitis en enfermedades virales clasificadas en otra parte',
	},
	{
		id: 2908,
		code: 'G052',
		label: 'Encefalitis, mielitis y encefalomielitis en otras enfermedades infecciosas y parasitarias clasificadas en otra parte',
	},
	{
		id: 2909,
		code: 'G058',
		label: 'Encefalitis, mielitis y encefalomielitis en enfermedades clasificadas en otra parte',
	},
	{
		id: 2910,
		code: 'G06',
		label: 'Absceso y granuloma intracraneal e intrarraquídeo',
	},
	{
		id: 2911,
		code: 'G060',
		label: 'Absceso y granuloma intracraneal',
	},
	{
		id: 2912,
		code: 'G061',
		label: 'Absceso y granuloma intrarraquídeo',
	},
	{
		id: 2913,
		code: 'G062',
		label: 'Absceso extradural y subdural, no especificado',
	},
	{
		id: 2914,
		code: 'G07X',
		label: 'Absceso y granuloma intracraneal e intrarraquídeo en enfermedades clasificadas en otra parte',
	},
	{
		id: 2915,
		code: 'G08X',
		label: 'Flebitis y tromboflebitis intracraneal e intrarraquídea',
	},
	{
		id: 2916,
		code: 'G09X',
		label: 'Secuelas de enfermedades inflamatorias del sistema nervioso central',
	},
	{
		id: 2917,
		code: 'G10X',
		label: 'Enfermedad de Huntington',
	},
	{
		id: 2918,
		code: 'G11',
		label: 'Ataxia hereditaria',
	},
	{
		id: 2919,
		code: 'G110',
		label: 'Ataxia congénita no progresiva',
	},
	{
		id: 2920,
		code: 'G111',
		label: 'Ataxia cerebelosa de iniciación temprana',
	},
	{
		id: 2921,
		code: 'G112',
		label: 'Ataxia cerebelosa de iniciación tardía',
	},
	{
		id: 2922,
		code: 'G113',
		label: 'Ataxia cerebelosa con reparación defectuosa del ADN',
	},
	{
		id: 2923,
		code: 'G114',
		label: 'Paraplejía espástica hereditaria',
	},
	{
		id: 2924,
		code: 'G118',
		label: 'Otras ataxias hereditarias',
	},
	{
		id: 2925,
		code: 'G119',
		label: 'Ataxia hereditaria, no especificada',
	},
	{
		id: 2926,
		code: 'G12',
		label: 'Atrofia muscular espinal y síndromes afines',
	},
	{
		id: 2927,
		code: 'G120',
		label: 'Atrofia muscular espinal infantil, tipo I (Werdnig-Hoffman)',
	},
	{
		id: 2928,
		code: 'G121',
		label: 'Otras atrofias musculares espinales hereditarias',
	},
	{
		id: 2929,
		code: 'G122',
		label: 'Enfermedades de las neuronas motoras',
	},
	{
		id: 2930,
		code: 'G128',
		label: 'Otras atrofias musculares espinales y síndromes afines',
	},
	{
		id: 2931,
		code: 'G129',
		label: 'Atrofia muscular espinal, sin otra especificación',
	},
	{
		id: 2932,
		code: 'G13',
		label: 'Atrofias sistémicas que afectan primariamente el sistema nervioso central en enfermedades clasificadas en otra parte',
	},
	{
		id: 2933,
		code: 'G130',
		label: 'Neuromiopatía y neuropatía paraneoplásica',
	},
	{
		id: 2934,
		code: 'G131',
		label: 'Otras atrofias sistémicas que afectan el sistema nervioso central en enfermedad neoplásica',
	},
	{
		id: 2935,
		code: 'G132',
		label: 'Atrofia sistémica que afecta primariamente el sistema nervioso central en el mixedema',
	},
	{
		id: 2936,
		code: 'G138',
		label: 'Atrofia sistémica que afecta primariamente el sistema nervioso central en otras enfermedades clasificadas en otra parte',
	},
	{
		id: 2937,
		code: 'G14X',
		label: 'Síndrome postpolio',
	},
	{
		id: 2938,
		code: 'G20X',
		label: 'Enfermedad de Parkinson',
	},
	{
		id: 2939,
		code: 'G21',
		label: 'Parkinsonismo secundario',
	},
	{
		id: 2940,
		code: 'G210',
		label: 'Síndrome neuroléptico maligno',
	},
	{
		id: 2941,
		code: 'G211',
		label: 'Otro parkinsonismo secundario inducido por drogas',
	},
	{
		id: 2942,
		code: 'G212',
		label: 'Parkinsonismo secundario debido a otros agentes externos',
	},
	{
		id: 2943,
		code: 'G213',
		label: 'Parkinsonismo postencefalítico',
	},
	{
		id: 2944,
		code: 'G214',
		label: 'Parkinsonismo vascular ',
	},
	{
		id: 2945,
		code: 'G218',
		label: 'Otros tipos de parkinsonismo secundario',
	},
	{
		id: 2946,
		code: 'G219',
		label: 'Parkinsonismo secundario, no especificado',
	},
	{
		id: 2947,
		code: 'G22X',
		label: 'Parkinsonismo en enfermedades clasificadas en otra parte',
	},
	{
		id: 2948,
		code: 'G23',
		label: 'Otras enfermedades degenerativas de los núcleos de la base',
	},
	{
		id: 2949,
		code: 'G230',
		label: 'Enfermedad de Hallervorden-Spatz',
	},
	{
		id: 2950,
		code: 'G231',
		label: 'Oftalmoplejía supranuclear progresiva [Steele-Richardson-Olszewski]',
	},
	{
		id: 2951,
		code: 'G232',
		label: 'Degeneración nigroestriada',
	},
	{
		id: 2952,
		code: 'G238',
		label: 'Otras enfermedades degenerativas especificas de los núcleos de la base',
	},
	{
		id: 2953,
		code: 'G239',
		label: 'Enfermedad degenerativa de los núcleos de la base, no especificada',
	},
	{
		id: 2954,
		code: 'G24',
		label: 'Distonía',
	},
	{
		id: 2955,
		code: 'G240',
		label: 'Distonía inducida por drogas',
	},
	{
		id: 2956,
		code: 'G241',
		label: 'Distonía idiopática familiar',
	},
	{
		id: 2957,
		code: 'G242',
		label: 'Distonía idiopática no familiar',
	},
	{
		id: 2958,
		code: 'G243',
		label: 'Tortícolis espasmódica',
	},
	{
		id: 2959,
		code: 'G244',
		label: 'Disponía bucofacial idiopática',
	},
	{
		id: 2960,
		code: 'G245',
		label: 'Blefarospasmo',
	},
	{
		id: 2961,
		code: 'G248',
		label: 'Otras distonías',
	},
	{
		id: 2962,
		code: 'G249',
		label: 'Distonía, no especificada',
	},
	{
		id: 2963,
		code: 'G25',
		label: 'Otros trastornos extrapiramidales y del movimiento',
	},
	{
		id: 2964,
		code: 'G250',
		label: 'Temblor esencial',
	},
	{
		id: 2965,
		code: 'G251',
		label: 'Temblor inducido por drogas',
	},
	{
		id: 2966,
		code: 'G252',
		label: 'Otras formas especificadas de temblor',
	},
	{
		id: 2967,
		code: 'G253',
		label: 'Mioclonía',
	},
	{
		id: 2968,
		code: 'G254',
		label: 'Corea inducida por drogas',
	},
	{
		id: 2969,
		code: 'G255',
		label: 'Otras coreas',
	},
	{
		id: 2970,
		code: 'G256',
		label: 'Tics inducidos por drogas y otros tics de origen orgánico',
	},
	{
		id: 2971,
		code: 'G258',
		label: 'Otros trastornos extrapiramidales y del movimiento',
	},
	{
		id: 2972,
		code: 'G259',
		label: 'Trastorno extrapiramidal y del movimiento, no especificado',
	},
	{
		id: 2973,
		code: 'G26X',
		label: 'Trastornos extrapiramidales y del movimiento en enfermedades clasificadas en otra parte',
	},
	{
		id: 2974,
		code: 'G30',
		label: 'Enfermedad de Alzheimer',
	},
	{
		id: 2975,
		code: 'G300',
		label: 'Enfermedad de Alzheimer de comienzo temprano',
	},
	{
		id: 2976,
		code: 'G301',
		label: 'Enfermedad de Alzheimer de comienzo tardío',
	},
	{
		id: 2977,
		code: 'G308',
		label: 'Otros tipos de enfermedad de Alzheimer',
	},
	{
		id: 2978,
		code: 'G309',
		label: 'Enfermedad de Alzheimer, no especificada',
	},
	{
		id: 2979,
		code: 'G31',
		label: 'Otras enfermedades degenerativas del sistema nervioso, no clasificadas en otra parte',
	},
	{
		id: 2980,
		code: 'G310',
		label: 'Atrofia cerebral circunscrita',
	},
	{
		id: 2981,
		code: 'G311',
		label: 'Degeneración cerebral senil no clasificada en otra parte',
	},
	{
		id: 2982,
		code: 'G312',
		label: 'Degeneración del sistema nervioso debida al alcohol',
	},
	{
		id: 2983,
		code: 'G318',
		label: 'Otras enfermedades degenerativas especificadas del sistema nervioso',
	},
	{
		id: 2984,
		code: 'G319',
		label: 'Degeneración del sistema nervioso, no especificada',
	},
	{
		id: 2985,
		code: 'G32',
		label: 'Otros trastornos degenerativos del sistema nervioso en enfermedades  clasificadas en otra parte',
	},
	{
		id: 2986,
		code: 'G320',
		label: 'Degeneración combinada subaguda de la médula espinal en enfermedades clasificadas en otra parte',
	},
	{
		id: 2987,
		code: 'G328',
		label: 'Otros trastornos degenerativos especificados del sistema nervioso en enfermedades  clasificadas en otra parte',
	},
	{
		id: 2988,
		code: 'G35X',
		label: 'Esclerosis múltiple',
	},
	{
		id: 2989,
		code: 'G36',
		label: 'Otras desmielinizaciones diseminadas agudas',
	},
	{
		id: 2990,
		code: 'G360',
		label: 'Neuromielitis óptica [Devic]',
	},
	{
		id: 2991,
		code: 'G361',
		label: 'Leucoencefalitis hemorrágica aguda y subaguda [Hurst]',
	},
	{
		id: 2992,
		code: 'G368',
		label: 'Otras desmielinizaciones agudas diseminadas especificadas',
	},
	{
		id: 2993,
		code: 'G369',
		label: 'Desmielinización diseminada aguda, sin otra especificación',
	},
	{
		id: 2994,
		code: 'G37',
		label: 'Otras enfermedades desmielinizantes del sistema nervioso central',
	},
	{
		id: 2995,
		code: 'G370',
		label: 'Esclerosis difusa',
	},
	{
		id: 2996,
		code: 'G371',
		label: 'Desmielinización central del cuerpo calloso',
	},
	{
		id: 2997,
		code: 'G372',
		label: 'Mielinólisis central pontina',
	},
	{
		id: 2998,
		code: 'G373',
		label: 'Mielitis transversa aguda en enfermedad desmielinizante del sistema nervioso central',
	},
	{
		id: 2999,
		code: 'G374',
		label: 'Mielitis necrotizante subaguda',
	},
	{
		id: 3000,
		code: 'G375',
		label: 'Esclerosis concéntrica (Baló)',
	},
	{
		id: 3001,
		code: 'G378',
		label: 'Otras enfermedades desmielinizantes del sistema nervioso central, especificadas',
	},
	{
		id: 3002,
		code: 'G379',
		label: 'Enfermedad desmielinizante del sistema nervioso central, no especificada',
	},
	{
		id: 3003,
		code: 'G40',
		label: 'Epilepsia',
	},
	{
		id: 3004,
		code: 'G400',
		label: 'Epilepsia y síndromes epilépticos idiopáticos relacionados con localizaciones (focales)(parciales) y con ataques de inicio localizado',
	},
	{
		id: 3005,
		code: 'G401',
		label: 'Epilepsia y síndromes epilépticos sintomáticos relacionados con localizaciones (focales)(parciales) y con ataques parciales simples',
	},
	{
		id: 3006,
		code: 'G402',
		label: 'Epilepsia y síndromes epilépticos sintomáticos relacionados con localizaciones (focales)(parciales) y con ataques parciales complejos',
	},
	{
		id: 3007,
		code: 'G403',
		label: 'Epilepsia y síndromes epilépticos idiopáticos generalizados',
	},
	{
		id: 3008,
		code: 'G404',
		label: 'Otras epilepsias y síndromes epilépticos generalizados',
	},
	{
		id: 3009,
		code: 'G405',
		label: 'Síndromes epilépticos especiales',
	},
	{
		id: 3010,
		code: 'G406',
		label: 'Ataques de gran mal, no especificados (con o sin pequeño mal)',
	},
	{
		id: 3011,
		code: 'G407',
		label: 'Pequeño mal, no especificado (sin ataque de gran mal)',
	},
	{
		id: 3012,
		code: 'G408',
		label: 'Otras epilepsias',
	},
	{
		id: 3013,
		code: 'G409',
		label: 'Epilepsia, tipo no especificado',
	},
	{
		id: 3014,
		code: 'G41',
		label: 'Estado de mal epiléptico',
	},
	{
		id: 3015,
		code: 'G410',
		label: 'Estado de gran mal epiléptico',
	},
	{
		id: 3016,
		code: 'G411',
		label: 'Estado de pequeño mal epiléptico',
	},
	{
		id: 3017,
		code: 'G412',
		label: 'Estado de mal epiléptico parcial complejo',
	},
	{
		id: 3018,
		code: 'G418',
		label: 'Otros estados epilépticos',
	},
	{
		id: 3019,
		code: 'G419',
		label: 'Estado de mal epiléptico de tipo no especificado',
	},
	{
		id: 3020,
		code: 'G43',
		label: 'Migraña',
	},
	{
		id: 3021,
		code: 'G430',
		label: 'Migraña sin aura [migraña común]',
	},
	{
		id: 3022,
		code: 'G431',
		label: 'Migraña con aura [migraña clásica]',
	},
	{
		id: 3023,
		code: 'G432',
		label: 'Estado migrañoso',
	},
	{
		id: 3024,
		code: 'G433',
		label: 'Migraña complicada',
	},
	{
		id: 3025,
		code: 'G438',
		label: 'Otras migrañas',
	},
	{
		id: 3026,
		code: 'G439',
		label: 'Migraña, no especificada',
	},
	{
		id: 3027,
		code: 'G44',
		label: 'Otros síndromes de cefalea',
	},
	{
		id: 3028,
		code: 'G440',
		label: 'Síndrome de cefalea en racimos',
	},
	{
		id: 3029,
		code: 'G441',
		label: 'Cefalea vascular, NCOP',
	},
	{
		id: 3030,
		code: 'G442',
		label: 'Cefalea debida a tensión',
	},
	{
		id: 3031,
		code: 'G443',
		label: 'Cefalea postraumática crónica',
	},
	{
		id: 3032,
		code: 'G444',
		label: 'Cefalea inducida por drogas, no clasificada en otra parte',
	},
	{
		id: 3033,
		code: 'G448',
		label: 'Otros síndromes de cefalea especificados',
	},
	{
		id: 3034,
		code: 'G45',
		label: 'Ataques de isquemia cerebral transitoria y síndromes afines',
	},
	{
		id: 3035,
		code: 'G450',
		label: 'Síndrome arterial vértebro-basilar',
	},
	{
		id: 3036,
		code: 'G451',
		label: 'Síndrome de la arteria carótida (hemisférico)',
	},
	{
		id: 3037,
		code: 'G452',
		label: 'Síndromes arteriales precerebrales bilaterales y múltiples',
	},
	{
		id: 3038,
		code: 'G453',
		label: 'Amaurosis fugaz',
	},
	{
		id: 3039,
		code: 'G454',
		label: 'Amnesia global transitoria',
	},
	{
		id: 3040,
		code: 'G458',
		label: 'Otras isquemias cerebrales transitorias y síndromes afines',
	},
	{
		id: 3041,
		code: 'G459',
		label: 'Isquemia cerebral transitoria, sin otra especificación',
	},
	{
		id: 3042,
		code: 'G46',
		label: 'Síndromes vasculares encefálicos en enfermedades cerebrovasculares',
	},
	{
		id: 3043,
		code: 'G460',
		label: 'Síndrome de la arteria cerebral media',
	},
	{
		id: 3044,
		code: 'G461',
		label: 'Síndrome de la arteria cerebral anterior',
	},
	{
		id: 3045,
		code: 'G462',
		label: 'Síndrome de la arteria cerebral posterior',
	},
	{
		id: 3046,
		code: 'G463',
		label: 'Síndromes apopléticos del tallo encefálico',
	},
	{
		id: 3047,
		code: 'G464',
		label: 'Síndrome de infarto cerebeloso',
	},
	{
		id: 3048,
		code: 'G465',
		label: 'Síndrome lacunar motor puro',
	},
	{
		id: 3049,
		code: 'G466',
		label: 'Síndrome lacunar sensorial puro',
	},
	{
		id: 3050,
		code: 'G467',
		label: 'Otros síndromes lacunares',
	},
	{
		id: 3051,
		code: 'G468',
		label: 'Otros síndromes vasculares encefálicos en enfermedades cerebrovasculares',
	},
	{
		id: 3052,
		code: 'G47',
		label: 'Trastornos del sueño',
	},
	{
		id: 3053,
		code: 'G470',
		label: 'Trastornos del inicio y del mantenimiento del sueño [insomnios]',
	},
	{
		id: 3054,
		code: 'G471',
		label: 'Trastornos de somnolencia excesiva (hipersomnios)',
	},
	{
		id: 3055,
		code: 'G472',
		label: 'Trastornos del ritmo nictameral',
	},
	{
		id: 3056,
		code: 'G473',
		label: 'Apnea del sueño',
	},
	{
		id: 3057,
		code: 'G474',
		label: 'Narcolepsia y cataplexia',
	},
	{
		id: 3058,
		code: 'G478',
		label: 'Otros trastornos del sueño',
	},
	{
		id: 3059,
		code: 'G479',
		label: 'Trastorno del sueño, no especificado',
	},
	{
		id: 3060,
		code: 'G50',
		label: 'Trastornos del nervio trigémino',
	},
	{
		id: 3061,
		code: 'G500',
		label: 'Neuralgia del trigémino',
	},
	{
		id: 3062,
		code: 'G501',
		label: 'Dolor facial atípico',
	},
	{
		id: 3063,
		code: 'G508',
		label: 'Otros trastornos del trigémino',
	},
	{
		id: 3064,
		code: 'G509',
		label: 'Trastorno del trigémino, no especificado',
	},
	{
		id: 3065,
		code: 'G51',
		label: 'Trastornos del nervio facial',
	},
	{
		id: 3066,
		code: 'G510',
		label: 'Parálisis de Bell',
	},
	{
		id: 3067,
		code: 'G511',
		label: 'Ganglionitis geniculada',
	},
	{
		id: 3068,
		code: 'G512',
		label: 'Síndrome de Melkersson',
	},
	{
		id: 3069,
		code: 'G513',
		label: 'Espasmo hemifacial clónico',
	},
	{
		id: 3070,
		code: 'G514',
		label: 'Mioquimia facial',
	},
	{
		id: 3071,
		code: 'G518',
		label: 'Otros trastornos del nervio facial',
	},
	{
		id: 3072,
		code: 'G519',
		label: 'Trastorno del nervio facial, no especificado',
	},
	{
		id: 3073,
		code: 'G52',
		label: 'Trastornos de otros nervios craneales',
	},
	{
		id: 3074,
		code: 'G520',
		label: 'Trastornos del nervio olfatorio',
	},
	{
		id: 3075,
		code: 'G521',
		label: 'Trastornos del nervio glosofaríngeo',
	},
	{
		id: 3076,
		code: 'G522',
		label: 'Trastornos del nervio vago',
	},
	{
		id: 3077,
		code: 'G523',
		label: 'Trastornos del nervio hipogloso',
	},
	{
		id: 3078,
		code: 'G527',
		label: 'Trastornos de múltiples nervios craneales',
	},
	{
		id: 3079,
		code: 'G528',
		label: 'Trastornos de otros nervios craneales especificados',
	},
	{
		id: 3080,
		code: 'G529',
		label: 'Trastorno de nervio craneal, no especificado',
	},
	{
		id: 3081,
		code: 'G53',
		label: 'Trastornos de los nervios craneales en enfermedades clasificadas en otra parte',
	},
	{
		id: 3082,
		code: 'G530',
		label: 'Neuralgia postherpes zoster',
	},
	{
		id: 3083,
		code: 'G531',
		label: 'Parálisis múltiple de los nervios craneales en enfermedades infecciosas y parasitarias clasificadas en otra parte',
	},
	{
		id: 3084,
		code: 'G532',
		label: 'Parálisis múltiple de los nervios craneales, en la sarcoidosis',
	},
	{
		id: 3085,
		code: 'G533',
		label: 'Parálisis múltiple de los nervios craneales, en enfermedades neoplásicas',
	},
	{
		id: 3086,
		code: 'G538',
		label: 'Otros trastornos de los nervios craneales en otras enfermedades clasificadas en otra parte',
	},
	{
		id: 3087,
		code: 'G54',
		label: 'Trastornos de las raíces y de los plexos nerviosos',
	},
	{
		id: 3088,
		code: 'G540',
		label: 'Trastornos del plexo braquial',
	},
	{
		id: 3089,
		code: 'G541',
		label: 'Trastornos del plexo lumbosacro',
	},
	{
		id: 3090,
		code: 'G542',
		label: 'Trastornos de la raíz cervical, no clasificados en otra parte',
	},
	{
		id: 3091,
		code: 'G543',
		label: 'Trastornos de la raíz torácica, no clasificados en otra parte',
	},
	{
		id: 3092,
		code: 'G544',
		label: 'Trastornos de la raíz lumbosacra, no clasificados en otra parte',
	},
	{
		id: 3093,
		code: 'G545',
		label: 'Amiotrofia neurálgica',
	},
	{
		id: 3094,
		code: 'G546',
		label: 'Síndrome del miembro fantasma con dolor',
	},
	{
		id: 3095,
		code: 'G547',
		label: 'Síndrome del miembro fantasma sin dolor',
	},
	{
		id: 3096,
		code: 'G548',
		label: 'Otros trastornos de las raíces y plexos nerviosos',
	},
	{
		id: 3097,
		code: 'G549',
		label: 'Trastorno de la raíz y plexos nerviosos, no especificado',
	},
	{
		id: 3098,
		code: 'G55',
		label: 'Compresiones de las raíces y de los plexos nerviosos en enfermedades clasificadas en otra parte',
	},
	{
		id: 3099,
		code: 'G550',
		label: 'Compresiones de las raíces y  plexos nerviosos en enfermedades neoplásicas',
	},
	{
		id: 3100,
		code: 'G551',
		label: 'Compresiones de las raíces y  plexos nerviosos en trastornos de los discos intervertebrales',
	},
	{
		id: 3101,
		code: 'G552',
		label: 'Compresiones de las raíces y  plexos nerviosos en la espondilosis',
	},
	{
		id: 3102,
		code: 'G553',
		label: 'Compresiones de las raíces y  plexos nerviosos en otras dorsopatías',
	},
	{
		id: 3103,
		code: 'G558',
		label: 'Compresiones de las raíces y plexos nerviosos en otras enfermedades clasificadas en orta parte',
	},
	{
		id: 3104,
		code: 'G56',
		label: 'Mononeuropatías del miembro superior',
	},
	{
		id: 3105,
		code: 'G560',
		label: 'Síndrome del túnel carpiano',
	},
	{
		id: 3106,
		code: 'G561',
		label: 'Otras lesiones del nervio mediano',
	},
	{
		id: 3107,
		code: 'G562',
		label: 'Lesión del nervio cubital',
	},
	{
		id: 3108,
		code: 'G563',
		label: 'Lesión del nervio radial',
	},
	{
		id: 3109,
		code: 'G564',
		label: 'Causalgia',
	},
	{
		id: 3110,
		code: 'G568',
		label: 'Otras mononeuropatías del miembro superior',
	},
	{
		id: 3111,
		code: 'G569',
		label: 'Mononeuropatía del miembro superior, sin otra especificación',
	},
	{
		id: 3112,
		code: 'G57',
		label: 'Mononeuropatías del miembro inferior',
	},
	{
		id: 3113,
		code: 'G570',
		label: 'Lesión del nervio ciático',
	},
	{
		id: 3114,
		code: 'G571',
		label: 'Meralgia parestésica',
	},
	{
		id: 3115,
		code: 'G572',
		label: 'Lesión del nervio crural',
	},
	{
		id: 3116,
		code: 'G573',
		label: 'Lesión del nervio ciático poplíteo externo',
	},
	{
		id: 3117,
		code: 'G574',
		label: 'Lesión del nervio ciático poplíteo interno',
	},
	{
		id: 3118,
		code: 'G575',
		label: 'Síndrome del túnel calcáneo',
	},
	{
		id: 3119,
		code: 'G576',
		label: 'Lesión del nervio plantar',
	},
	{
		id: 3120,
		code: 'G578',
		label: 'Otras mononeuropatías del miembro inferior',
	},
	{
		id: 3121,
		code: 'G579',
		label: 'Mononeuropatía del miembro inferior, sin otra especificación',
	},
	{
		id: 3122,
		code: 'G58',
		label: 'Otras mononeuropatías',
	},
	{
		id: 3123,
		code: 'G580',
		label: 'Neuropatía intercostal',
	},
	{
		id: 3124,
		code: 'G587',
		label: 'Mononeuritis múltiple',
	},
	{
		id: 3125,
		code: 'G588',
		label: 'Otras mononeuropatías especificadas',
	},
	{
		id: 3126,
		code: 'G589',
		label: 'Mononeuropatía, no especificada',
	},
	{
		id: 3127,
		code: 'G59',
		label: 'Mononeuropatía en enfermedades clasificadas en otra parte',
	},
	{
		id: 3128,
		code: 'G590',
		label: 'Mononeuropatía diabética',
	},
	{
		id: 3129,
		code: 'G598',
		label: 'Otras mononeuropatías en enfermedades clasificadas en otra parte',
	},
	{
		id: 3130,
		code: 'G60',
		label: 'Neuropatía hereditaria e idiopática',
	},
	{
		id: 3131,
		code: 'G600',
		label: 'Neuropatía hereditaria motora y sensorial',
	},
	{
		id: 3132,
		code: 'G601',
		label: 'Enfermedad de Refsum',
	},
	{
		id: 3133,
		code: 'G602',
		label: 'Neuropatía asociada con ataxia hereditaria',
	},
	{
		id: 3134,
		code: 'G603',
		label: 'Neuropatía progresiva idiopática',
	},
	{
		id: 3135,
		code: 'G608',
		label: 'Otras neuropatías hereditarias e idiopáticas',
	},
	{
		id: 3136,
		code: 'G609',
		label: 'Neuropatía hereditaria e idiopática, sin otra especificación',
	},
	{
		id: 3137,
		code: 'G61',
		label: 'Polineuopatía inflamatoria',
	},
	{
		id: 3138,
		code: 'G610',
		label: 'Síndrome de Guillain-Barré',
	},
	{
		id: 3139,
		code: 'G611',
		label: 'Neuropatía al suero',
	},
	{
		id: 3140,
		code: 'G618',
		label: 'Otras polineuropatías inflamatorias',
	},
	{
		id: 3141,
		code: 'G619',
		label: 'Polineuropatía inflamatoria, no especificada',
	},
	{
		id: 3142,
		code: 'G62',
		label: 'Otras polineuropatías',
	},
	{
		id: 3143,
		code: 'G620',
		label: 'Polineuropatía inducida por drogas',
	},
	{
		id: 3144,
		code: 'G621',
		label: 'Polineuropatía alcohólica',
	},
	{
		id: 3145,
		code: 'G622',
		label: 'Polineuropatía debida a otro agente tóxico',
	},
	{
		id: 3146,
		code: 'G628',
		label: 'Otras polineuropatías especificadas',
	},
	{
		id: 3147,
		code: 'G629',
		label: 'Polineuropatía, no especificada',
	},
	{
		id: 3148,
		code: 'G63',
		label: 'Polineuropatías en enfermedades clasificadas en otra parte',
	},
	{
		id: 3149,
		code: 'G630',
		label: 'Polineuropatía en enfermedades infecciosas y parasitarias clasificadas en otra parte',
	},
	{
		id: 3150,
		code: 'G631',
		label: 'Polineuropatía en enfermedad neoplásica',
	},
	{
		id: 3151,
		code: 'G632',
		label: 'Polineuropatía diabética',
	},
	{
		id: 3152,
		code: 'G633',
		label: 'Polineuropatía en otras enfermedades endocrinas y metabólicas',
	},
	{
		id: 3153,
		code: 'G634',
		label: 'Polineuropatía en deficiencia nutricional',
	},
	{
		id: 3154,
		code: 'G635',
		label: 'Polineuropatía en trastornos del tejido conectivo sistémico',
	},
	{
		id: 3155,
		code: 'G636',
		label: 'Polineuropatía en otros trastornos osteomusculares',
	},
	{
		id: 3156,
		code: 'G638',
		label: 'Polineuropatía en otras enfermedades clasificadas en otra parte',
	},
	{
		id: 3157,
		code: 'G64X',
		label: 'Otros trastornos del sistema nervioso periférico',
	},
	{
		id: 3158,
		code: 'G70',
		label: 'Miastenia gravis y otros trastornos neuromusculares',
	},
	{
		id: 3159,
		code: 'G700',
		label: 'Miastenia gravis',
	},
	{
		id: 3160,
		code: 'G701',
		label: 'Trastornos tóxicos neuromusculares',
	},
	{
		id: 3161,
		code: 'G702',
		label: 'Miastenia congénita o del desarrollo',
	},
	{
		id: 3162,
		code: 'G708',
		label: 'Otros trastornos neuromusculares especificados',
	},
	{
		id: 3163,
		code: 'G709',
		label: 'Trastorno neuromuscular, no especificado',
	},
	{
		id: 3164,
		code: 'G71',
		label: 'Trastornos musculares primarios',
	},
	{
		id: 3165,
		code: 'G710',
		label: 'Distrofia muscular',
	},
	{
		id: 3166,
		code: 'G711',
		label: 'Trastornos miotónicos',
	},
	{
		id: 3167,
		code: 'G712',
		label: 'Miopatías congénitas',
	},
	{
		id: 3168,
		code: 'G713',
		label: 'Miopatía mitocóndrica, no clasificada en otra parte',
	},
	{
		id: 3169,
		code: 'G718',
		label: 'Otros trastornos primarios de los músculos',
	},
	{
		id: 3170,
		code: 'G719',
		label: 'Trastorno primario del músculo, tipo no especificado',
	},
	{
		id: 3171,
		code: 'G72',
		label: 'Otras miopatías',
	},
	{
		id: 3172,
		code: 'G720',
		label: 'Miopatía inducida por drogas',
	},
	{
		id: 3173,
		code: 'G721',
		label: 'Miopatía alcohólica',
	},
	{
		id: 3174,
		code: 'G722',
		label: 'Miopatía debida a otros agentes tóxicos',
	},
	{
		id: 3175,
		code: 'G723',
		label: 'Parálisis periódica',
	},
	{
		id: 3176,
		code: 'G724',
		label: 'Miopatía inflamatoria, no clasificada en otra parte',
	},
	{
		id: 3177,
		code: 'G728',
		label: 'Otras miopatías especificadas',
	},
	{
		id: 3178,
		code: 'G729',
		label: 'Miopatía, no especificada',
	},
	{
		id: 3179,
		code: 'G73',
		label: 'Trastornos del músculo y de la unión neuromuscular en enfermedades clasificadas en otra parte',
	},
	{
		id: 3180,
		code: 'G730',
		label: 'Síndromes miasténicos en enfermedades endocrinas',
	},
	{
		id: 3181,
		code: 'G731',
		label: 'Síndrome de Lambert-Eaton (C00-D48†)',
	},
	{
		id: 3182,
		code: 'G732',
		label: 'Otros síndromes miasténicos en enfermedad neoplásica',
	},
	{
		id: 3183,
		code: 'G733',
		label: 'Síndromes miasténicos en otras enfermedades clasificadas en otra parte',
	},
	{
		id: 3184,
		code: 'G734',
		label: 'Miopatía en enfermedades infecciosas y parasitarias clasificadas en otra parte',
	},
	{
		id: 3185,
		code: 'G735',
		label: 'Miopatía en enfermedades endocrinas',
	},
	{
		id: 3186,
		code: 'G736',
		label: 'Miopatía en enfermedades metabólicas',
	},
	{
		id: 3187,
		code: 'G737',
		label: 'Miopatía en otras enfermedades clasificadas en otra parte',
	},
	{
		id: 3188,
		code: 'G80',
		label: 'Parálisis cerebral ',
	},
	{
		id: 3189,
		code: 'G800',
		label: 'Parálisis cerebral espástica cuadripléjica',
	},
	{
		id: 3190,
		code: 'G801',
		label: 'Parálisis cerebral espástica dipléjica',
	},
	{
		id: 3191,
		code: 'G802',
		label: 'Parálisis cerebral espástica hemipléjica',
	},
	{
		id: 3192,
		code: 'G803',
		label: 'Parálisis cerebral discinética',
	},
	{
		id: 3193,
		code: 'G804',
		label: 'Parálisis cerebral atáxica',
	},
	{
		id: 3194,
		code: 'G808',
		label: 'Otros tipos de parálisis cerebral ',
	},
	{
		id: 3195,
		code: 'G809',
		label: 'Parálisis cerebral, sin otra especificación',
	},
	{
		id: 3196,
		code: 'G81',
		label: 'Hemiplejía',
	},
	{
		id: 3197,
		code: 'G810',
		label: 'Hemiplejía flácida',
	},
	{
		id: 3198,
		code: 'G811',
		label: 'Hemiplejía espástica',
	},
	{
		id: 3199,
		code: 'G819',
		label: 'Hemiplejía, no especificada',
	},
	{
		id: 3200,
		code: 'G82',
		label: 'Paraplejía y cuadriplejía',
	},
	{
		id: 3201,
		code: 'G820',
		label: 'Paraplejía flácida',
	},
	{
		id: 3202,
		code: 'G821',
		label: 'Paraplejía espástica',
	},
	{
		id: 3203,
		code: 'G822',
		label: 'Paraplejía, no especificada',
	},
	{
		id: 3204,
		code: 'G823',
		label: 'Cuadriplejía flácida',
	},
	{
		id: 3205,
		code: 'G824',
		label: 'Cuadriplejía espástica',
	},
	{
		id: 3206,
		code: 'G825',
		label: 'Cuadriplejía, no especificada',
	},
	{
		id: 3207,
		code: 'G83',
		label: 'Otros síndromes paralíticos',
	},
	{
		id: 3208,
		code: 'G830',
		label: 'Diplejía de los miembros superiores',
	},
	{
		id: 3209,
		code: 'G831',
		label: 'Monoplejía de miembro inferior',
	},
	{
		id: 3210,
		code: 'G832',
		label: 'Monoplejía de miembro superior',
	},
	{
		id: 3211,
		code: 'G833',
		label: 'Monoplejía, no especificada',
	},
	{
		id: 3212,
		code: 'G834',
		label: 'Síndrome de la cola de caballo',
	},
	{
		id: 3213,
		code: 'G838',
		label: 'Otros síndromes paralíticos especificados',
	},
	{
		id: 3214,
		code: 'G839',
		label: 'Síndrome paralítico, no especificado',
	},
	{
		id: 3215,
		code: 'G90',
		label: 'Trastornos del sistema nervioso autónomo',
	},
	{
		id: 3216,
		code: 'G900',
		label: 'Neuropatía autónoma periférica idiopática',
	},
	{
		id: 3217,
		code: 'G901',
		label: 'Disautonomía familiar [Síndrome de Riley-Day]',
	},
	{
		id: 3218,
		code: 'G902',
		label: 'Síndrome de Horner',
	},
	{
		id: 3219,
		code: 'G903',
		label: 'Degeneración de sistemas múltiples',
	},
	{
		id: 3220,
		code: 'G904',
		label: 'Disreflexia autonómica',
	},
	{
		id: 3221,
		code: 'G908',
		label: 'Otros Trastornos del sistema nervioso autónomo',
	},
	{
		id: 3222,
		code: 'G909',
		label: 'Trastorno del sistema nervioso autónomo, no especificado',
	},
	{
		id: 3223,
		code: 'G91',
		label: 'Hidrocéfalo',
	},
	{
		id: 3224,
		code: 'G910',
		label: 'Hidrocéfalo comunicante',
	},
	{
		id: 3225,
		code: 'G911',
		label: 'Hidrocéfalo obstructivo',
	},
	{
		id: 3226,
		code: 'G912',
		label: 'Hidrocéfalo de presión normal',
	},
	{
		id: 3227,
		code: 'G913',
		label: 'Hidrocéfalo postraumático, sin otra especificación',
	},
	{
		id: 3228,
		code: 'G918',
		label: 'Otros tipos de hidrocéfalo',
	},
	{
		id: 3229,
		code: 'G919',
		label: 'Hidrocéfalo, no especificado',
	},
	{
		id: 3230,
		code: 'G92X',
		label: 'Encefalopatía tóxica',
	},
	{
		id: 3231,
		code: 'G93',
		label: 'Otros trastornos del encéfalo',
	},
	{
		id: 3232,
		code: 'G930',
		label: 'Quiste cerebral',
	},
	{
		id: 3233,
		code: 'G931',
		label: 'Lesión cerebral anóxica, no clasificada en otra parte',
	},
	{
		id: 3234,
		code: 'G932',
		label: 'Hipertensión intracraneal benigna',
	},
	{
		id: 3235,
		code: 'G933',
		label: 'Síndrome de fatiga postviral',
	},
	{
		id: 3236,
		code: 'G934',
		label: 'Encefalopatía no especificada',
	},
	{
		id: 3237,
		code: 'G935',
		label: 'Compresión del encéfalo',
	},
	{
		id: 3238,
		code: 'G936',
		label: 'Edema cerebral',
	},
	{
		id: 3239,
		code: 'G937',
		label: 'Síndrome de Reye',
	},
	{
		id: 3240,
		code: 'G938',
		label: 'Otros Trastornos especificados del encéfalo',
	},
	{
		id: 3241,
		code: 'G939',
		label: 'Trastorno del encéfalo, no especificado',
	},
	{
		id: 3242,
		code: 'G94',
		label: 'Otros trastornos del encéfalo en enfermedades clasificadas en otra parte',
	},
	{
		id: 3243,
		code: 'G940',
		label: 'Hidrocéfalo en enfermedades infecciosas y parasitarias clasificadas en otra parte',
	},
	{
		id: 3244,
		code: 'G941',
		label: 'Hidrocéfalo en enfermedad neoplásica',
	},
	{
		id: 3245,
		code: 'G942',
		label: 'Hidrocéfalo en otras enfermedades clasificadas en otra parte',
	},
	{
		id: 3246,
		code: 'G948',
		label: 'Otros trastornos encefálicos especificados en enfermedades clasificadas en otra parte',
	},
	{
		id: 3247,
		code: 'G95',
		label: 'Otras enfermedades de la médula espinal',
	},
	{
		id: 3248,
		code: 'G950',
		label: 'Siringomielia y siringobulbia',
	},
	{
		id: 3249,
		code: 'G951',
		label: 'Mielopatías vasculares',
	},
	{
		id: 3250,
		code: 'G952',
		label: 'Compresión medular, no especificada',
	},
	{
		id: 3251,
		code: 'G958',
		label: 'Otras enfermedades especificadas de la médula espinal',
	},
	{
		id: 3252,
		code: 'G959',
		label: 'Enfermedad de la médula espinal, no especificada',
	},
	{
		id: 3253,
		code: 'G96',
		label: 'Otros trastornos del sistema nervioso central',
	},
	{
		id: 3254,
		code: 'G960',
		label: 'Pérdida de líquido cefalorraquídeo',
	},
	{
		id: 3255,
		code: 'G961',
		label: 'Trastornos de las meninges, no clasificados en otra parte',
	},
	{
		id: 3256,
		code: 'G968',
		label: 'Otros trastornos especificados del sistema nervioso central',
	},
	{
		id: 3257,
		code: 'G969',
		label: 'Trastorno del sistema nervioso central, no especificado',
	},
	{
		id: 3258,
		code: 'G97',
		label: 'Trastornos del sistema nervioso consecutivos a procedimientos, no clasificados en otra parte',
	},
	{
		id: 3259,
		code: 'G970',
		label: 'Pérdida de líquido cefalorraquídeo por punción espinal',
	},
	{
		id: 3260,
		code: 'G971',
		label: 'Otra reacción a la punción espinal y lumbar',
	},
	{
		id: 3261,
		code: 'G972',
		label: 'Hipotensión intracraneal posterior a anastomosis ventricular',
	},
	{
		id: 3262,
		code: 'G978',
		label: 'Otros trastornos del sistema nervioso consecutivos a procedimientos',
	},
	{
		id: 3263,
		code: 'G979',
		label: 'Trastornos no especificados del sistema nervioso, consecutivos a procedimientos',
	},
	{
		id: 3264,
		code: 'G98X',
		label: 'Otros trastornos del sistema nervioso, no clasificados en otra parte',
	},
	{
		id: 3265,
		code: 'G99',
		label: 'Otros trastornos del sistema nervioso en enfermedades clasificadas en otra parte',
	},
	{
		id: 3266,
		code: 'G990',
		label: 'Neuropatía autonómica en enfermedades metabólica y endócrinas',
	},
	{
		id: 3267,
		code: 'G991',
		label: 'Otros trastornos del sistema nervioso autónomo en otras enfermedades clasificadas en otra parte',
	},
	{
		id: 3268,
		code: 'G992',
		label: 'Mielopatía en enfermedades clasificadas en otra parte',
	},
	{
		id: 3269,
		code: 'G998',
		label: 'Otros trastornos especificados del sistema nervioso en enfermedades clasificadas en otra parte',
	},
	{
		id: 3270,
		code: 'H00',
		label: 'Orzuelo y calacio',
	},
	{
		id: 3271,
		code: 'H000',
		label: 'Orzuelo y otras inflamaciones profundas del párpado',
	},
	{
		id: 3272,
		code: 'H001',
		label: 'Calacio [chalazión]',
	},
	{
		id: 3273,
		code: 'H01',
		label: 'Otras inflamaciones del párpado',
	},
	{
		id: 3274,
		code: 'H010',
		label: 'Blefaritis',
	},
	{
		id: 3275,
		code: 'H011',
		label: 'Dermatosis no infecciosa del párpado',
	},
	{
		id: 3276,
		code: 'H018',
		label: 'Otras inflamaciones especificadas del párpado',
	},
	{
		id: 3277,
		code: 'H019',
		label: 'Inflamación del párpado, no especificada',
	},
	{
		id: 3278,
		code: 'H02',
		label: 'Otros trastornos de los párpados',
	},
	{
		id: 3279,
		code: 'H020',
		label: 'Entropión y triquiasis palpebral',
	},
	{
		id: 3280,
		code: 'H021',
		label: 'Ectropión del párpado',
	},
	{
		id: 3281,
		code: 'H022',
		label: 'Lagoftalmos',
	},
	{
		id: 3282,
		code: 'H023',
		label: 'Blefarocalasia',
	},
	{
		id: 3283,
		code: 'H024',
		label: 'Blefaroptosis',
	},
	{
		id: 3284,
		code: 'H025',
		label: 'Otros trastornos funcionales del párpado',
	},
	{
		id: 3285,
		code: 'H026',
		label: 'Xantelasma del párpado',
	},
	{
		id: 3286,
		code: 'H027',
		label: 'Otros trastornos degenerativos del párpado y del área periocular',
	},
	{
		id: 3287,
		code: 'H028',
		label: 'Otros trastornos especificados del párpado',
	},
	{
		id: 3288,
		code: 'H029',
		label: 'Trastorno del párpado, no especificado',
	},
	{
		id: 3289,
		code: 'H03',
		label: 'Trastornos del párpado en enfermedades clasificadas en otra parte',
	},
	{
		id: 3290,
		code: 'H030',
		label: 'Infección o infestación parasitaria del párpado en enfermedades clasificadas en otra parte',
	},
	{
		id: 3291,
		code: 'H031',
		label: 'Compromiso del párpado en enfermedades infecciosas clasificadas en otra parte',
	},
	{
		id: 3292,
		code: 'H038',
		label: 'Compromiso del párpado en enfermedades clasificadas en otra parte',
	},
	{
		id: 3293,
		code: 'H04',
		label: 'Trastornos del aparato lagrimal',
	},
	{
		id: 3294,
		code: 'H040',
		label: 'Dacrioadenitis',
	},
	{
		id: 3295,
		code: 'H041',
		label: 'Otros trastornos de la glándula lagrimal',
	},
	{
		id: 3296,
		code: 'H042',
		label: 'Epífora',
	},
	{
		id: 3297,
		code: 'H043',
		label: 'Inflamación aguda y la no especificada de las vías lagrimales',
	},
	{
		id: 3298,
		code: 'H044',
		label: 'Inflamación crónica de las vías lagrimales',
	},
	{
		id: 3299,
		code: 'H045',
		label: 'Estenosis e insuficiencia de las vías lagrimales',
	},
	{
		id: 3300,
		code: 'H046',
		label: 'Otros cambios de las vías lagrimales',
	},
	{
		id: 3301,
		code: 'H048',
		label: 'Otros trastornos especificados del aparato lagrimal',
	},
	{
		id: 3302,
		code: 'H049',
		label: 'Trastorno del aparato lagrimal, no especificado',
	},
	{
		id: 3303,
		code: 'H05',
		label: 'Trastornos de la órbita',
	},
	{
		id: 3304,
		code: 'H050',
		label: 'Inflamación aguda de la órbita',
	},
	{
		id: 3305,
		code: 'H051',
		label: 'Trastornos inflamatorios crónicos de la órbita',
	},
	{
		id: 3306,
		code: 'H052',
		label: 'Afecciones exoftálmicas',
	},
	{
		id: 3307,
		code: 'H053',
		label: 'Deformidad de la órbita',
	},
	{
		id: 3308,
		code: 'H054',
		label: 'Enoftalmia',
	},
	{
		id: 3309,
		code: 'H055',
		label: 'Retención de cuerpo extraño (antiguo), consecutiva a herida penetrante de la órbita',
	},
	{
		id: 3310,
		code: 'H058',
		label: 'Otros trastornos de la órbita',
	},
	{
		id: 3311,
		code: 'H059',
		label: 'Trastorno de la órbita, no especificado',
	},
	{
		id: 3312,
		code: 'H06',
		label: 'Trastornos del aparato lagrimal y de la órbita en enfermedades clasificadas en otra parte',
	},
	{
		id: 3313,
		code: 'H060',
		label: 'Trastornos del aparato lagrimal en enfermedades clasificadas en otra parte',
	},
	{
		id: 3314,
		code: 'H061',
		label: 'Infección e infestación parasitarias de la órbita en enfermedades clasificadas en otra parte',
	},
	{
		id: 3315,
		code: 'H062',
		label: 'Exoftalmia hipertiroidea',
	},
	{
		id: 3316,
		code: 'H063',
		label: 'Otros trastornos de la órbita en enfermedades clasificadas en otra parte',
	},
	{
		id: 3317,
		code: 'H10',
		label: 'Conjuntivitis',
	},
	{
		id: 3318,
		code: 'H100',
		label: 'Conjuntivitis mucopurulenta',
	},
	{
		id: 3319,
		code: 'H101',
		label: 'Conjuntivitis atópica aguda',
	},
	{
		id: 3320,
		code: 'H102',
		label: 'Otras conjuntivitis agudas',
	},
	{
		id: 3321,
		code: 'H103',
		label: 'Conjuntivitis aguda, no especificada',
	},
	{
		id: 3322,
		code: 'H104',
		label: 'Conjuntivitis crónica',
	},
	{
		id: 3323,
		code: 'H105',
		label: 'Blefaroconjuntivitis',
	},
	{
		id: 3324,
		code: 'H108',
		label: 'Otras conjuntivitis',
	},
	{
		id: 3325,
		code: 'H109',
		label: 'Conjuntivitis, no especificada',
	},
	{
		id: 3326,
		code: 'H11',
		label: 'Otros trastornos de la conjuntiva',
	},
	{
		id: 3327,
		code: 'H110',
		label: 'Pterigión',
	},
	{
		id: 3328,
		code: 'H111',
		label: 'Degeneraciones y depósitos conjuntivales',
	},
	{
		id: 3329,
		code: 'H112',
		label: 'Cicatrices conjuntivales',
	},
	{
		id: 3330,
		code: 'H113',
		label: 'Hemorragia conjuntival',
	},
	{
		id: 3331,
		code: 'H114',
		label: 'Otros trastornos vasculares y quistes conjuntivales',
	},
	{
		id: 3332,
		code: 'H118',
		label: 'Otros trastornos especificados de la conjuntiva',
	},
	{
		id: 3333,
		code: 'H119',
		label: 'Trastorno de la conjuntiva, no especificado',
	},
	{
		id: 3334,
		code: 'H13',
		label: 'Trastornos de la conjuntiva en enfermedades clasificadas en otra parte',
	},
	{
		id: 3335,
		code: 'H130',
		label: 'Infección filárica de la conjuntiva',
	},
	{
		id: 3336,
		code: 'H131',
		label: 'Conjuntivitis en enfermedades infecciosas y parasitarias clasificadas en otra parte',
	},
	{
		id: 3337,
		code: 'H132',
		label: 'Conjuntivitis en otras enfermedades clasificadas en otra parte',
	},
	{
		id: 3338,
		code: 'H133',
		label: 'Penfigoide ocular',
	},
	{
		id: 3339,
		code: 'H138',
		label: 'Otros trastornos de la conjuntiva en enfermedades clasificadas en otra parte',
	},
	{
		id: 3340,
		code: 'H15',
		label: 'Trastornos de la esclerótica',
	},
	{
		id: 3341,
		code: 'H150',
		label: 'Escleritis',
	},
	{
		id: 3342,
		code: 'H151',
		label: 'Episcleritis',
	},
	{
		id: 3343,
		code: 'H158',
		label: 'Otros trastornos de la esclerótica',
	},
	{
		id: 3344,
		code: 'H159',
		label: 'Trastorno de la esclerótica, no especificado',
	},
	{
		id: 3345,
		code: 'H16',
		label: 'Queratitis',
	},
	{
		id: 3346,
		code: 'H160',
		label: 'Úlcera de la córnea',
	},
	{
		id: 3347,
		code: 'H161',
		label: 'Otras queratitis superficiales sin conjuntivitis',
	},
	{
		id: 3348,
		code: 'H162',
		label: 'Queratoconjuntivitis',
	},
	{
		id: 3349,
		code: 'H163',
		label: 'Queratitis intersticial y profunda',
	},
	{
		id: 3350,
		code: 'H164',
		label: 'Neovascularización  de la córnea',
	},
	{
		id: 3351,
		code: 'H168',
		label: 'Otras queratitis',
	},
	{
		id: 3352,
		code: 'H169',
		label: 'Queratitis,no especificada',
	},
	{
		id: 3353,
		code: 'H17',
		label: 'Opacidades y cicatrices corneales',
	},
	{
		id: 3354,
		code: 'H170',
		label: 'Leucoma adherente',
	},
	{
		id: 3355,
		code: 'H171',
		label: 'Otras opacidades centrales de la córnea',
	},
	{
		id: 3356,
		code: 'H178',
		label: 'Otras opacidades o cicatrices de la córnea',
	},
	{
		id: 3357,
		code: 'H179',
		label: 'Cicatriz u opacidad de la córnea,  no especificada',
	},
	{
		id: 3358,
		code: 'H18',
		label: 'Otros trastornos de la córnea',
	},
	{
		id: 3359,
		code: 'H180',
		label: 'Pigmentaciones y depósitos en la córnea',
	},
	{
		id: 3360,
		code: 'H181',
		label: 'Queratopatía vesicular',
	},
	{
		id: 3361,
		code: 'H182',
		label: 'Otros edemas de la córnea',
	},
	{
		id: 3362,
		code: 'H183',
		label: 'Cambios en las membranas de la córnea',
	},
	{
		id: 3363,
		code: 'H184',
		label: 'Degeneración de la córnea',
	},
	{
		id: 3364,
		code: 'H185',
		label: 'Distrofia hereditaria de la córnea',
	},
	{
		id: 3365,
		code: 'H186',
		label: 'Queratocono',
	},
	{
		id: 3366,
		code: 'H187',
		label: 'Otras deformidades de la córnea',
	},
	{
		id: 3367,
		code: 'H188',
		label: 'Otros trastornos especificados de la córnea',
	},
	{
		id: 3368,
		code: 'H189',
		label: 'Trastorno de la córnea, no especificado',
	},
	{
		id: 3369,
		code: 'H19',
		label: 'Trastornos de la esclerótica y de la córnea en enfermedades clasificadas en otra parte',
	},
	{
		id: 3370,
		code: 'H190',
		label: 'Escleritis y episcleritis en enfermedades clasificadas en otra parte',
	},
	{
		id: 3371,
		code: 'H191',
		label: 'Queratitis y queratoconjuntivitis por herpes simple',
	},
	{
		id: 3372,
		code: 'H192',
		label: 'Queratitis y queratoconjuntivitis en enfermedades infecciosas y parasitarias, clasificadas en otra parte',
	},
	{
		id: 3373,
		code: 'H193',
		label: 'Queratitis y queratoconjuntivitis en otras enfermedades clasificadas en otra parte',
	},
	{
		id: 3374,
		code: 'H198',
		label: 'Otros trastornos de la esclerótica y de la córnea en enfermedades clasificadas en otra parte',
	},
	{
		id: 3375,
		code: 'H20',
		label: 'Iridociclitis',
	},
	{
		id: 3376,
		code: 'H200',
		label: 'Iridociclitis aguda y subaguda',
	},
	{
		id: 3377,
		code: 'H201',
		label: 'Iridociclitis crónica',
	},
	{
		id: 3378,
		code: 'H202',
		label: 'Iridociclitis inducida por trastorno del cristalino',
	},
	{
		id: 3379,
		code: 'H208',
		label: 'Otras iridociclitis especificadas',
	},
	{
		id: 3380,
		code: 'H209',
		label: 'Iridociclitis, no especificada',
	},
	{
		id: 3381,
		code: 'H21',
		label: 'Otros trastornos del iris y del cuerpo ciliar',
	},
	{
		id: 3382,
		code: 'H210',
		label: 'Hifema',
	},
	{
		id: 3383,
		code: 'H211',
		label: 'Otros trastornos vasculares del iris y del cuerpo ciliar',
	},
	{
		id: 3384,
		code: 'H212',
		label: 'Degeneración del iris y del cuerpo ciliar',
	},
	{
		id: 3385,
		code: 'H213',
		label: 'Quiste del iris, del cuerpo ciliar y de la cámara anterior',
	},
	{
		id: 3386,
		code: 'H214',
		label: 'Membranas pupilares',
	},
	{
		id: 3387,
		code: 'H215',
		label: 'Otras adherencias y desgarros del iris y del cuerpo ciliar',
	},
	{
		id: 3388,
		code: 'H218',
		label: 'Otros trastornos especificados del iris y del cuerpo ciliar',
	},
	{
		id: 3389,
		code: 'H219',
		label: 'Trastorno del iris y del cuerpo ciliar, no especificado',
	},
	{
		id: 3390,
		code: 'H22',
		label: 'Trastornos del iris y del cuerpo ciliar en enfermedades clasificadas en otra parte',
	},
	{
		id: 3391,
		code: 'H220',
		label: 'Iridociclitis en enfermedades infecciosas y parasitarias  clasificadas en otra parte',
	},
	{
		id: 3392,
		code: 'H221',
		label: 'Iridociclitis en otras enfermedades clasificadas en otra parte',
	},
	{
		id: 3393,
		code: 'H228',
		label: 'Otros trastornos del iris y del cuerpo ciliar en enfermedades clasificadas en otra parte',
	},
	{
		id: 3394,
		code: 'H25',
		label: 'Catarata senil',
	},
	{
		id: 3395,
		code: 'H250',
		label: 'Catarata senil incipiente',
	},
	{
		id: 3396,
		code: 'H251',
		label: 'Catarata senil nuclear',
	},
	{
		id: 3397,
		code: 'H252',
		label: 'Catarata senil, tipo morgagnian',
	},
	{
		id: 3398,
		code: 'H258',
		label: 'Otras cataratas seniles',
	},
	{
		id: 3399,
		code: 'H259',
		label: 'Catarata senil, no especificada',
	},
	{
		id: 3400,
		code: 'H26',
		label: 'Otras cataratas',
	},
	{
		id: 3401,
		code: 'H260',
		label: 'Catarata infantil, juvenil y presenil',
	},
	{
		id: 3402,
		code: 'H261',
		label: 'Catarata traumática',
	},
	{
		id: 3403,
		code: 'H262',
		label: 'Catarata complicada',
	},
	{
		id: 3404,
		code: 'H263',
		label: 'Catarata inducida por drogas',
	},
	{
		id: 3405,
		code: 'H264',
		label: 'Catarata residual',
	},
	{
		id: 3406,
		code: 'H268',
		label: 'Otras formas especificadas de catarata',
	},
	{
		id: 3407,
		code: 'H269',
		label: 'Catarata, no especificada',
	},
	{
		id: 3408,
		code: 'H27',
		label: 'Otros trastornos del cristalino',
	},
	{
		id: 3409,
		code: 'H270',
		label: 'Afaquia',
	},
	{
		id: 3410,
		code: 'H271',
		label: 'Luxación del cristalino',
	},
	{
		id: 3411,
		code: 'H278',
		label: 'Otros trastornos especificados del cristalino',
	},
	{
		id: 3412,
		code: 'H279',
		label: 'Trastorno del cristalino, no especificado',
	},
	{
		id: 3413,
		code: 'H28',
		label: 'Catarata y otros trastornos del cristalino en enfermedades clasificadas en otra parte',
	},
	{
		id: 3414,
		code: 'H280',
		label: 'Catarata diabética',
	},
	{
		id: 3415,
		code: 'H281',
		label: 'Catarata en otras enfermedades endocrinas, nutricionales y metabólicas clasificadas en otra parte',
	},
	{
		id: 3416,
		code: 'H282',
		label: 'Catarata en otras enfermedades clasificadas en otra parte',
	},
	{
		id: 3417,
		code: 'H288',
		label: 'Otros trastornos del cristalino en enfermedades clasificadas en otra parte',
	},
	{
		id: 3418,
		code: 'H30',
		label: 'Inflamación coriorretiniana',
	},
	{
		id: 3419,
		code: 'H300',
		label: 'Coriorretinitis focal',
	},
	{
		id: 3420,
		code: 'H301',
		label: 'Coriorretinitis diseminada',
	},
	{
		id: 3421,
		code: 'H302',
		label: 'Ciclitis posterior',
	},
	{
		id: 3422,
		code: 'H308',
		label: 'Otras coriorretinitis',
	},
	{
		id: 3423,
		code: 'H309',
		label: 'Coriorretinitis, no especificada',
	},
	{
		id: 3424,
		code: 'H31',
		label: 'Otros trastornos de la coroides',
	},
	{
		id: 3425,
		code: 'H310',
		label: 'Cicatrices coriorretinianas',
	},
	{
		id: 3426,
		code: 'H311',
		label: 'Degeneración coroidea',
	},
	{
		id: 3427,
		code: 'H312',
		label: 'Distrofia coroidea hereditaria',
	},
	{
		id: 3428,
		code: 'H313',
		label: 'Hemorragia y ruptura de la coroides',
	},
	{
		id: 3429,
		code: 'H314',
		label: 'Desprendimiento de la coroides',
	},
	{
		id: 3430,
		code: 'H318',
		label: 'Otros trastornos especificados de la coroides',
	},
	{
		id: 3431,
		code: 'H319',
		label: 'Trastorno de la coroides, no especificado',
	},
	{
		id: 3432,
		code: 'H32',
		label: 'Trastornos coriorretinianos en enfermedades clasificadas en otra parte',
	},
	{
		id: 3433,
		code: 'H320',
		label: 'Inflamación coriorretiniana en enfermedades infecciosas y parasitarias clasificadas en otra parte',
	},
	{
		id: 3434,
		code: 'H328',
		label: 'Otros trastornos coriorretinianos en enfermedades clasificadas en otra parte',
	},
	{
		id: 3435,
		code: 'H33',
		label: 'Desprendimiento y desgarro de la retina',
	},
	{
		id: 3436,
		code: 'H330',
		label: 'Desprendimiento de la retina con ruptura',
	},
	{
		id: 3437,
		code: 'H331',
		label: 'Retinosquisis y quistes de la retina',
	},
	{
		id: 3438,
		code: 'H332',
		label: 'Desprendimiento seroso de la retina',
	},
	{
		id: 3439,
		code: 'H333',
		label: 'Desgarro de la retina sin desprendimiento',
	},
	{
		id: 3440,
		code: 'H334',
		label: 'Desprendimiento de la retina por tracción',
	},
	{
		id: 3441,
		code: 'H335',
		label: 'Otros desprendimientos de la retina',
	},
	{
		id: 3442,
		code: 'H34',
		label: 'Oclusión vascular de la retina',
	},
	{
		id: 3443,
		code: 'H340',
		label: 'Oclusión arterial transitoria de la retina',
	},
	{
		id: 3444,
		code: 'H341',
		label: 'Oclusión de la arteria central de la retina',
	},
	{
		id: 3445,
		code: 'H342',
		label: 'Otras formas de oclusión de la arteria de la retina',
	},
	{
		id: 3446,
		code: 'H348',
		label: 'Otras oclusiones vasculares retinianas',
	},
	{
		id: 3447,
		code: 'H349',
		label: 'Oclusión vascular retiniana, sin otra especificación',
	},
	{
		id: 3448,
		code: 'H35',
		label: 'Otros trastornos de la retina',
	},
	{
		id: 3449,
		code: 'H350',
		label: 'Retinopatías del fondo y cambios vasculares retinianos',
	},
	{
		id: 3450,
		code: 'H351',
		label: 'Retinopatía de la prematuridad',
	},
	{
		id: 3451,
		code: 'H352',
		label: 'Otras retinopatías proliferativas',
	},
	{
		id: 3452,
		code: 'H353',
		label: 'Degeneración de la mácula y del polo posterior del ojo',
	},
	{
		id: 3453,
		code: 'H354',
		label: 'Degeneración periférica de la retina',
	},
	{
		id: 3454,
		code: 'H355',
		label: 'Distrofia hereditaria de la retina',
	},
	{
		id: 3455,
		code: 'H356',
		label: 'Hemorragia retiniana',
	},
	{
		id: 3456,
		code: 'H357',
		label: 'Separación de las capas de la retina',
	},
	{
		id: 3457,
		code: 'H358',
		label: 'Otros trastornos especificados de la retina',
	},
	{
		id: 3458,
		code: 'H359',
		label: 'Trastorno de la retina, no especificado',
	},
	{
		id: 3459,
		code: 'H36',
		label: 'Trastornos de la retina en enfermedades clasificadas en otra parte',
	},
	{
		id: 3460,
		code: 'H360',
		label: 'Retinopatía diabética',
	},
	{
		id: 3461,
		code: 'H368',
		label: 'Otros trastornos de la retina en enfermedades clasificadas en otra parte',
	},
	{
		id: 3462,
		code: 'H40',
		label: 'Glaucoma',
	},
	{
		id: 3463,
		code: 'H400',
		label: 'Sospecha de glaucoma',
	},
	{
		id: 3464,
		code: 'H401',
		label: 'Glaucoma primario de ángulo abierto',
	},
	{
		id: 3465,
		code: 'H402',
		label: 'Glaucoma primario de ángulo cerrado',
	},
	{
		id: 3466,
		code: 'H403',
		label: 'Glaucoma secundario a traumatismo ocular',
	},
	{
		id: 3467,
		code: 'H404',
		label: 'Glaucoma secundario a inflamación ocular',
	},
	{
		id: 3468,
		code: 'H405',
		label: 'Glaucoma secundario a otros trastornos del ojo',
	},
	{
		id: 3469,
		code: 'H406',
		label: 'Glaucoma secundario a drogas',
	},
	{
		id: 3470,
		code: 'H408',
		label: 'Otros glaucomas',
	},
	{
		id: 3471,
		code: 'H409',
		label: 'Glaucoma, no especificado',
	},
	{
		id: 3472,
		code: 'H42',
		label: 'Glaucoma en enfermedades clasificadas en otra parte',
	},
	{
		id: 3473,
		code: 'H420',
		label: 'Glaucoma en enfermedades endocrinas, nutricionales y metabólicas clasificadas en otra parte',
	},
	{
		id: 3474,
		code: 'H428',
		label: 'Glaucoma en otras enfermedades clasificadas en otra parte',
	},
	{
		id: 3475,
		code: 'H43',
		label: 'Trastornos del cuerpo vítreo',
	},
	{
		id: 3476,
		code: 'H430',
		label: 'Prolapso del vítreo',
	},
	{
		id: 3477,
		code: 'H431',
		label: 'Hemorragia del vítreo',
	},
	{
		id: 3478,
		code: 'H432',
		label: 'Depósitos cristalinos en el cuerpo vítreo',
	},
	{
		id: 3479,
		code: 'H433',
		label: 'Otras opacidades vítreas',
	},
	{
		id: 3480,
		code: 'H438',
		label: 'Otros trastornos del cuerpo vítreo',
	},
	{
		id: 3481,
		code: 'H439',
		label: 'Trastorno del cuerpo vítreo, no especificado',
	},
	{
		id: 3482,
		code: 'H44',
		label: 'Trastornos del globo ocular',
	},
	{
		id: 3483,
		code: 'H440',
		label: 'Endoftalmitis purulenta',
	},
	{
		id: 3484,
		code: 'H441',
		label: 'Otras endoftalmitis',
	},
	{
		id: 3485,
		code: 'H442',
		label: 'Miopía degenerativa',
	},
	{
		id: 3486,
		code: 'H443',
		label: 'Otros trastornos degenerativos del globo ocular',
	},
	{
		id: 3487,
		code: 'H444',
		label: 'Hipotonía ocular',
	},
	{
		id: 3488,
		code: 'H445',
		label: 'Afecciones degenerativas del globo ocular',
	},
	{
		id: 3489,
		code: 'H446',
		label: 'Retención intraocular de cuerpo extraño magnético (antiguo)',
	},
	{
		id: 3490,
		code: 'H447',
		label: 'Retención intraocular de cuerpo extraño no magnético (antiguo)',
	},
	{
		id: 3491,
		code: 'H448',
		label: 'Otros trastornos del globo ocular',
	},
	{
		id: 3492,
		code: 'H449',
		label: 'Trastorno del globo ocular, no especificado',
	},
	{
		id: 3493,
		code: 'H45',
		label: 'Trastornos del cuerpo vítreo y del globo ocular en enfermedades clasificadas en otra parte',
	},
	{
		id: 3494,
		code: 'H450',
		label: 'Hemorragia del vítreo en enfermedades clasificadas en otra parte',
	},
	{
		id: 3495,
		code: 'H451',
		label: 'Endoftalmitis en enfermedades clasificadas en otra parte',
	},
	{
		id: 3496,
		code: 'H458',
		label: 'Otros trastornos del cuerpo vítreo y del globo ocular en enfermedades clasificadas en otra parte',
	},
	{
		id: 3497,
		code: 'H46X',
		label: 'Neuritis óptica',
	},
	{
		id: 3498,
		code: 'H47',
		label: 'Otros trastornos del nervio óptico [II par] y de las vías ópticas',
	},
	{
		id: 3499,
		code: 'H470',
		label: 'Trastornos del nervio óptico, no clasificados en otra parte',
	},
	{
		id: 3500,
		code: 'H471',
		label: 'Papiledema, no especificado',
	},
	{
		id: 3501,
		code: 'H472',
		label: 'Atrofia óptica',
	},
	{
		id: 3502,
		code: 'H473',
		label: 'Otros trastornos del disco óptico',
	},
	{
		id: 3503,
		code: 'H474',
		label: 'Trastornos del quiasma óptico',
	},
	{
		id: 3504,
		code: 'H475',
		label: 'Trastornos de otras vías ópticas',
	},
	{
		id: 3505,
		code: 'H476',
		label: 'Trastornos de la corteza visual',
	},
	{
		id: 3506,
		code: 'H477',
		label: 'Trastorno de las vías ópticas, no especificado',
	},
	{
		id: 3507,
		code: 'H48',
		label: 'Trastornos del nervio óptico [II par] y de las vías ópticas en enfermedades clasificadas en otra parte',
	},
	{
		id: 3508,
		code: 'H480',
		label: 'Atrofia óptica en enfermedades clasificadas en otra parte',
	},
	{
		id: 3509,
		code: 'H481',
		label: 'Neuritis retrobulbar en enfermedades clasificadas en otra parte',
	},
	{
		id: 3510,
		code: 'H488',
		label: 'Otros trastornos del nervio óptico y de las vías ópticas en enfermedades clasificadas en otra parte',
	},
	{
		id: 3511,
		code: 'H49',
		label: 'Estrabismo paralítico',
	},
	{
		id: 3512,
		code: 'H490',
		label: 'Parálisis del nervio motor ocular común [III par]',
	},
	{
		id: 3513,
		code: 'H491',
		label: 'Parálisis del nervio patético [IV par]',
	},
	{
		id: 3514,
		code: 'H492',
		label: 'Parálisis del nervio motor ocular externo [VI par]',
	},
	{
		id: 3515,
		code: 'H493',
		label: 'Oftalmoplejía total (externa)',
	},
	{
		id: 3516,
		code: 'H494',
		label: 'Oftalmoplejía externa progresiva',
	},
	{
		id: 3517,
		code: 'H498',
		label: 'Otros estrabismos paralíticos',
	},
	{
		id: 3518,
		code: 'H499',
		label: 'Estrabismo paralítico, no especificado',
	},
	{
		id: 3519,
		code: 'H50',
		label: 'Otros estrabismos',
	},
	{
		id: 3520,
		code: 'H500',
		label: 'Estrabismo concomitante convergente',
	},
	{
		id: 3521,
		code: 'H501',
		label: 'Estrabismo concomitante divergente',
	},
	{
		id: 3522,
		code: 'H502',
		label: 'Estrabismo vertical',
	},
	{
		id: 3523,
		code: 'H503',
		label: 'Heterotropía intermitente',
	},
	{
		id: 3524,
		code: 'H504',
		label: 'Otras heterotropías o las no especificadas',
	},
	{
		id: 3525,
		code: 'H505',
		label: 'Heteroforia',
	},
	{
		id: 3526,
		code: 'H506',
		label: 'Estrabismo mecánico',
	},
	{
		id: 3527,
		code: 'H508',
		label: 'Otros estrabismos especificados',
	},
	{
		id: 3528,
		code: 'H509',
		label: 'Estrabismo, no especificado',
	},
	{
		id: 3529,
		code: 'H51',
		label: 'Otros trastornos de los movimientos binoculares',
	},
	{
		id: 3530,
		code: 'H510',
		label: 'Parálisis de la conjugación de la mirada',
	},
	{
		id: 3531,
		code: 'H511',
		label: 'Exceso e insuficiencia de la convergencia ocular',
	},
	{
		id: 3532,
		code: 'H512',
		label: 'Oftalmoplejía internuclear',
	},
	{
		id: 3533,
		code: 'H518',
		label: 'Otros trastornos especificados de los movimientos binoculares',
	},
	{
		id: 3534,
		code: 'H519',
		label: 'Trastorno del movimiento binocular, no especificado',
	},
	{
		id: 3535,
		code: 'H52',
		label: 'Trastornos de la acomodación y de la refracción',
	},
	{
		id: 3536,
		code: 'H520',
		label: 'Hipermetropía',
	},
	{
		id: 3537,
		code: 'H521',
		label: 'Miopía',
	},
	{
		id: 3538,
		code: 'H522',
		label: 'Astigmatismo',
	},
	{
		id: 3539,
		code: 'H523',
		label: 'Anisometropía y aniseiconía',
	},
	{
		id: 3540,
		code: 'H524',
		label: 'Presbicia',
	},
	{
		id: 3541,
		code: 'H525',
		label: 'Trastornos de la acomodación',
	},
	{
		id: 3542,
		code: 'H526',
		label: 'Otros trastornos de la refracción',
	},
	{
		id: 3543,
		code: 'H527',
		label: 'Trastorno de la refracción, no especificado',
	},
	{
		id: 3544,
		code: 'H53',
		label: 'Alteraciones de la visión',
	},
	{
		id: 3545,
		code: 'H530',
		label: 'Ambliopía ex anopsia',
	},
	{
		id: 3546,
		code: 'H531',
		label: 'Alteraciones visuales subjetivas',
	},
	{
		id: 3547,
		code: 'H532',
		label: 'Diplopía',
	},
	{
		id: 3548,
		code: 'H533',
		label: 'Otros trastornos de la visión binocular',
	},
	{
		id: 3549,
		code: 'H534',
		label: 'Defectos del campo visual',
	},
	{
		id: 3550,
		code: 'H535',
		label: 'Deficiencia de la visión cromática',
	},
	{
		id: 3551,
		code: 'H536',
		label: 'Ceguera nocturna',
	},
	{
		id: 3552,
		code: 'H538',
		label: 'Otras alteraciones visuales',
	},
	{
		id: 3553,
		code: 'H539',
		label: 'Alteración visual, no especificada',
	},
	{
		id: 3554,
		code: 'H54',
		label: 'Ceguera y deficiencia visual (binicular o monocular)',
	},
	{
		id: 3555,
		code: 'H540',
		label: 'Ceguera binocular',
	},
	{
		id: 3556,
		code: 'H541',
		label: 'Deficiencia visual severa, binocular',
	},
	{
		id: 3557,
		code: 'H542',
		label: 'Deficiencia visual moderada, binocular',
	},
	{
		id: 3558,
		code: 'H543',
		label: 'Deficiencia visual leve o ausente, binocular',
	},
	{
		id: 3559,
		code: 'H544',
		label: 'Ceguera monocular',
	},
	{
		id: 3560,
		code: 'H545',
		label: 'Deficiencia visual severa, monocular',
	},
	{
		id: 3561,
		code: 'H546',
		label: 'Deficiencia visual moderada, monocular',
	},
	{
		id: 3562,
		code: 'H547',
		label: 'Disminución de la agudeza visual, sin especificación',
	},
	{
		id: 3563,
		code: 'H549',
		label: 'Deficiencia visual no especificada (binocular) ',
	},
	{
		id: 3564,
		code: 'H55X',
		label: 'Nistagmo y otros movimientos oculares irregulares',
	},
	{
		id: 3565,
		code: 'H57',
		label: 'Otros trastornos del ojo y sus anexos',
	},
	{
		id: 3566,
		code: 'H570',
		label: 'Anomalías de la función pupilar',
	},
	{
		id: 3567,
		code: 'H571',
		label: 'Dolor ocular',
	},
	{
		id: 3568,
		code: 'H578',
		label: 'Otros trastornos especificados del ojo y sus anexos',
	},
	{
		id: 3569,
		code: 'H579',
		label: 'Trastorno del ojo y sus anexos, no especificado',
	},
	{
		id: 3570,
		code: 'H58',
		label: 'Otros trastornos del ojo y sus anexos en enfermedades clasificadas en otra parte',
	},
	{
		id: 3571,
		code: 'H580',
		label: 'Anomalías de la función pupilar en enfermedades clasificadas en otra parte',
	},
	{
		id: 3572,
		code: 'H581',
		label: 'Alteraciones de la visión en enfermedades clasificadas en otra parte',
	},
	{
		id: 3573,
		code: 'H588',
		label: 'Otros trastornos especificados del ojo en enfermedades clasificadas en otra parte',
	},
	{
		id: 3574,
		code: 'H59',
		label: 'Trastornos del ojo y sus anexos consecutivos a procedimientos, no clasificados en otra parte',
	},
	{
		id: 3575,
		code: 'H590',
		label: 'Queratopatía (bullosa afáquica) consecutiva a cirugía de catarata',
	},
	{
		id: 3576,
		code: 'H598',
		label: 'Otros trastornos del ojo y sus anexos, consecutivos a procedimientos',
	},
	{
		id: 3577,
		code: 'H599',
		label: 'Trastorno no especificado del ojo y sus anexos, consecutivo a procedimientos',
	},
	{
		id: 3578,
		code: 'H60',
		label: 'Otitis externa',
	},
	{
		id: 3579,
		code: 'H600',
		label: 'Absceso del oído externo',
	},
	{
		id: 3580,
		code: 'H601',
		label: 'Celulitis del oído externo',
	},
	{
		id: 3581,
		code: 'H602',
		label: 'Otitis externa maligna',
	},
	{
		id: 3582,
		code: 'H603',
		label: 'Otras otitis externas infecciosas',
	},
	{
		id: 3583,
		code: 'H604',
		label: 'Colesteatoma del oído externo',
	},
	{
		id: 3584,
		code: 'H605',
		label: 'Otitis externa aguda, no infecciosa',
	},
	{
		id: 3585,
		code: 'H608',
		label: 'Otras otitis externas',
	},
	{
		id: 3586,
		code: 'H609',
		label: 'Otitis externa, sin otra especificación',
	},
	{
		id: 3587,
		code: 'H61',
		label: 'Otros trastornos del oído externo',
	},
	{
		id: 3588,
		code: 'H610',
		label: 'Pericondritis del oído externo',
	},
	{
		id: 3589,
		code: 'H611',
		label: 'Afecciones no infecciosas del pabellón auditivo',
	},
	{
		id: 3590,
		code: 'H612',
		label: 'Cerumen impactado',
	},
	{
		id: 3591,
		code: 'H613',
		label: 'Estenosis adquirida del conducto auditivo externo',
	},
	{
		id: 3592,
		code: 'H618',
		label: 'Otros trastornos especificados del oído externo',
	},
	{
		id: 3593,
		code: 'H619',
		label: 'Trastorno del oído externo, no especificado',
	},
	{
		id: 3594,
		code: 'H62',
		label: 'Trastornos del oído externo en enfermedades clasificadas en otra parte',
	},
	{
		id: 3595,
		code: 'H620',
		label: 'Otitis externa en enfermedades bacterianas clasificadas en otra parte',
	},
	{
		id: 3596,
		code: 'H621',
		label: 'Otitits externa en enfermedades vírales clasificadas en otra parte',
	},
	{
		id: 3597,
		code: 'H622',
		label: 'Otitits externa en micosis',
	},
	{
		id: 3598,
		code: 'H623',
		label: 'Otitits externa en otras enfermedades infecciosas y parasitarias clasificadas en otra parte',
	},
	{
		id: 3599,
		code: 'H624',
		label: 'Otitits externa en otras enfermedades clasificadas en otra parte',
	},
	{
		id: 3600,
		code: 'H628',
		label: 'Otros trastornos del oído externo en enfermedades clasificadas en otra parte',
	},
	{
		id: 3601,
		code: 'H65',
		label: 'Otitis media no supurativa',
	},
	{
		id: 3602,
		code: 'H650',
		label: 'Otitis media aguda serosa',
	},
	{
		id: 3603,
		code: 'H651',
		label: 'Otra otitis media aguda, no supurativa',
	},
	{
		id: 3604,
		code: 'H652',
		label: 'Otitis media crónica serosa',
	},
	{
		id: 3605,
		code: 'H653',
		label: 'Otitis media crónica mucoide',
	},
	{
		id: 3606,
		code: 'H654',
		label: 'Otras otitis medias crónicas no supurativas',
	},
	{
		id: 3607,
		code: 'H659',
		label: 'Otitis media no supurativa, sin otra especificación',
	},
	{
		id: 3608,
		code: 'H66',
		label: 'Otitis media supurativa y la no especificada',
	},
	{
		id: 3609,
		code: 'H660',
		label: 'Otitis media supurativa aguda',
	},
	{
		id: 3610,
		code: 'H661',
		label: 'Otitis media tubotimpánica supurativa crónica',
	},
	{
		id: 3611,
		code: 'H662',
		label: 'Otitis media supurativa crónica aticoantral',
	},
	{
		id: 3612,
		code: 'H663',
		label: 'Otras otitis medias crónicas supurativas',
	},
	{
		id: 3613,
		code: 'H664',
		label: 'Otitis media supurativa, sin otra especificación',
	},
	{
		id: 3614,
		code: 'H669',
		label: 'Otitis media, no especificada',
	},
	{
		id: 3615,
		code: 'H67',
		label: 'Otitis media en enfermedades clasificadas en otra parte',
	},
	{
		id: 3616,
		code: 'H670',
		label: 'Otitis media en enfermedades bacterianas clasificadas en otra parte',
	},
	{
		id: 3617,
		code: 'H671',
		label: 'Otitis media en enfermedades virales clasificadas en otra parte',
	},
	{
		id: 3618,
		code: 'H678',
		label: 'Otitis media en otras enfermedades clasificadas en otra parte',
	},
	{
		id: 3619,
		code: 'H68',
		label: 'Inflamación y obstrucción de la trompa de Eustaquio',
	},
	{
		id: 3620,
		code: 'H680',
		label: 'Salpingitis eustaquiana',
	},
	{
		id: 3621,
		code: 'H681',
		label: 'Obstrucción de la trompa de Eustaquio',
	},
	{
		id: 3622,
		code: 'H69',
		label: 'Otros trastornos de la trompa de Eustaquio',
	},
	{
		id: 3623,
		code: 'H690',
		label: 'Distensión de la trompa de Eustaquio',
	},
	{
		id: 3624,
		code: 'H698',
		label: 'Otros trastornos especificados de la trompa de Eustaquio',
	},
	{
		id: 3625,
		code: 'H699',
		label: 'Trastorno de la trompa de Eustaquio, no especificado',
	},
	{
		id: 3626,
		code: 'H70',
		label: 'Mastoiditis y afecciones relacionadas',
	},
	{
		id: 3627,
		code: 'H700',
		label: 'Mastoiditis aguda',
	},
	{
		id: 3628,
		code: 'H701',
		label: 'Mastoiditis crónica',
	},
	{
		id: 3629,
		code: 'H702',
		label: 'Petrositis',
	},
	{
		id: 3630,
		code: 'H708',
		label: 'Otras mastoiditis y afecciones relacionadas',
	},
	{
		id: 3631,
		code: 'H709',
		label: 'Mastoiditis, no especificada',
	},
	{
		id: 3632,
		code: 'H71X',
		label: 'Colesteatoma del oído medio',
	},
	{
		id: 3633,
		code: 'H72',
		label: 'Perforación de la membrana timpánica',
	},
	{
		id: 3634,
		code: 'H720',
		label: 'Perforación central de la membrana timpánica',
	},
	{
		id: 3635,
		code: 'H721',
		label: 'Perforación ática de la membrana timpánica',
	},
	{
		id: 3636,
		code: 'H722',
		label: 'Otras perforaciones marginales de la membrana timpánica',
	},
	{
		id: 3637,
		code: 'H728',
		label: 'Otras perforaciones de la membrana timpánica',
	},
	{
		id: 3638,
		code: 'H729',
		label: 'Perforación de la membrana timpánica, sin otra especificación',
	},
	{
		id: 3639,
		code: 'H73',
		label: 'Otros trastornos de la membrana timpánica',
	},
	{
		id: 3640,
		code: 'H730',
		label: 'Miringitis aguda',
	},
	{
		id: 3641,
		code: 'H731',
		label: 'Miringitis crónica',
	},
	{
		id: 3642,
		code: 'H738',
		label: 'Otros trastornos especificados de la membrana timpánica',
	},
	{
		id: 3643,
		code: 'H739',
		label: 'Trastorno de la membrana timpánica, no especificado',
	},
	{
		id: 3644,
		code: 'H74',
		label: 'Otros trastornos del oído medio y de la apófisis mastoides',
	},
	{
		id: 3645,
		code: 'H740',
		label: 'Timpanosclerosis',
	},
	{
		id: 3646,
		code: 'H741',
		label: 'Enfermedad adhesiva del oído medio',
	},
	{
		id: 3647,
		code: 'H742',
		label: 'Discontinuidad y dislocación de los huesecillos del oído',
	},
	{
		id: 3648,
		code: 'H743',
		label: 'Otras anormalidades adquiridas de los huesecillos del oído',
	},
	{
		id: 3649,
		code: 'H744',
		label: 'Pólipo del oído medio',
	},
	{
		id: 3650,
		code: 'H748',
		label: 'Otros trastornos especificados del oído medio y de la apófisis mastoides',
	},
	{
		id: 3651,
		code: 'H749',
		label: 'Trastorno del oído medio y de la apófisis mastoides, no especificado',
	},
	{
		id: 3652,
		code: 'H75',
		label: 'Otros trastornos del oído medio y de la apófisis mastoides en enfermedades clasificadas en otra parte',
	},
	{
		id: 3653,
		code: 'H750',
		label: 'Mastoiditis en enfermedades infecciosas y parasitarias clasificadas en otra parte',
	},
	{
		id: 3654,
		code: 'H758',
		label: 'Otros trastornos especificados del oído medio y de la apofisis mastoides en enfermedades clasificadas en otra parte',
	},
	{
		id: 3655,
		code: 'H80',
		label: 'Otosclerosis',
	},
	{
		id: 3656,
		code: 'H800',
		label: 'Otosclerosis que afecta la ventana oval, no obliterante',
	},
	{
		id: 3657,
		code: 'H801',
		label: 'Otosclerosis que afecta la ventana oval, obliterante',
	},
	{
		id: 3658,
		code: 'H802',
		label: 'Otosclerosis coclear',
	},
	{
		id: 3659,
		code: 'H808',
		label: 'Otras otosclerosis',
	},
	{
		id: 3660,
		code: 'H809',
		label: 'Otosclerosis, no especificada',
	},
	{
		id: 3661,
		code: 'H81',
		label: 'Trastornos de la función vestibular',
	},
	{
		id: 3662,
		code: 'H810',
		label: 'Enfermedad de meniere',
	},
	{
		id: 3663,
		code: 'H811',
		label: 'Vértigo paroxístico benigno',
	},
	{
		id: 3664,
		code: 'H812',
		label: 'Neuronitis vestibular',
	},
	{
		id: 3665,
		code: 'H813',
		label: 'Otros vértigos periféricos',
	},
	{
		id: 3666,
		code: 'H814',
		label: 'Vértigo de origen central',
	},
	{
		id: 3667,
		code: 'H818',
		label: 'Otros trastornos de la función vestibular',
	},
	{
		id: 3668,
		code: 'H819',
		label: 'Trastorno de la función vestibular, no especificado',
	},
	{
		id: 3669,
		code: 'H82X',
		label: 'Síndromes vertiginosos en enfermedades clasificadas en otra parte',
	},
	{
		id: 3670,
		code: 'H83',
		label: 'Otros trastornos del oído interno',
	},
	{
		id: 3671,
		code: 'H830',
		label: 'Laberintitis',
	},
	{
		id: 3672,
		code: 'H831',
		label: 'Fístula del laberinto',
	},
	{
		id: 3673,
		code: 'H832',
		label: 'Disfunción del laberinto',
	},
	{
		id: 3674,
		code: 'H833',
		label: 'Efectos del ruido sobre el oído interno',
	},
	{
		id: 3675,
		code: 'H838',
		label: 'Otros trastornos especificados del oído interno',
	},
	{
		id: 3676,
		code: 'H839',
		label: 'Trastorno del oído interno, no especificado',
	},
	{
		id: 3677,
		code: 'H90',
		label: 'Hipocausia conductiva y neurosensorial',
	},
	{
		id: 3678,
		code: 'H900',
		label: 'Hipoacusia conductiva bilateral',
	},
	{
		id: 3679,
		code: 'H901',
		label: 'Hipoacusia conductiva, unilateral con audición irrestricta contralateral',
	},
	{
		id: 3680,
		code: 'H902',
		label: 'Hipoacusia conductiva, sin otra especificación',
	},
	{
		id: 3681,
		code: 'H903',
		label: 'Hipoacusia neurosensorial, bilateral',
	},
	{
		id: 3682,
		code: 'H904',
		label: 'Hipoacusia neurosensorial, unilateral con audición irrestricta contralateral',
	},
	{
		id: 3683,
		code: 'H905',
		label: 'Hipoacusia neurosensorial, sin otra especificación',
	},
	{
		id: 3684,
		code: 'H906',
		label: 'Hipoacusia mixta conductiva y neurosensorial, bilateral',
	},
	{
		id: 3685,
		code: 'H907',
		label: 'Hipoacusia mixta conductiva y neurosensorial, unilateral con audición irrestricta contralateral',
	},
	{
		id: 3686,
		code: 'H908',
		label: 'Hipoacusia mixta conductiva y neurosensorial, no especificada',
	},
	{
		id: 3687,
		code: 'H91',
		label: 'Otras hipoacusias',
	},
	{
		id: 3688,
		code: 'H910',
		label: 'Hipoacusia ototóxica',
	},
	{
		id: 3689,
		code: 'H911',
		label: 'Presbiacusia',
	},
	{
		id: 3690,
		code: 'H912',
		label: 'Hipoacusia súbita idiopática',
	},
	{
		id: 3691,
		code: 'H913',
		label: 'Sordomudez, no clasificada en otra parte',
	},
	{
		id: 3692,
		code: 'H918',
		label: 'Otras hipoacusias especificadas',
	},
	{
		id: 3693,
		code: 'H919',
		label: 'Hipoacusia, no especificada',
	},
	{
		id: 3694,
		code: 'H92',
		label: 'Otalgia y secreción del oído',
	},
	{
		id: 3695,
		code: 'H920',
		label: 'Otalgia',
	},
	{
		id: 3696,
		code: 'H921',
		label: 'Otorrea',
	},
	{
		id: 3697,
		code: 'H922',
		label: 'Otorragia',
	},
	{
		id: 3698,
		code: 'H93',
		label: 'Otros trastornos del oído, no clasificados en otra parte',
	},
	{
		id: 3699,
		code: 'H930',
		label: 'Trastornos degenerativos y vasculares del oído',
	},
	{
		id: 3700,
		code: 'H931',
		label: 'Tinnitus',
	},
	{
		id: 3701,
		code: 'H932',
		label: 'Otras percepciones auditivas anormales',
	},
	{
		id: 3702,
		code: 'H933',
		label: 'Trastornos del nervio auditivo',
	},
	{
		id: 3703,
		code: 'H938',
		label: 'Otros trastornos especificados del oído',
	},
	{
		id: 3704,
		code: 'H939',
		label: 'Trastorno del oído, no especificado',
	},
	{
		id: 3705,
		code: 'H94',
		label: 'Otros trastornos del oído en enfermedades clasificadas en otra parte',
	},
	{
		id: 3706,
		code: 'H940',
		label: 'Neuritis del nervio auditivo en enfermedades infecciosas y parasitarias  clasificadas en otra parte',
	},
	{
		id: 3707,
		code: 'H948',
		label: 'Otros trastornos especificados del oído en enfermedades clasificadas en otra parte',
	},
	{
		id: 3708,
		code: 'H95',
		label: 'Trastornos del oído y de la apófisis mastoides consecutivos a procedimientos, no clasificados en otra parte',
	},
	{
		id: 3709,
		code: 'H950',
		label: 'Colesteatoma recurrente de la cavidad resultante de la mastoidectomía',
	},
	{
		id: 3710,
		code: 'H951',
		label: 'Otros trastornos posteriores  a la mastoidectomía',
	},
	{
		id: 3711,
		code: 'H958',
		label: 'Otros trastornos del oído y de la apófisis mastoides, consecutivos a procedimientos',
	},
	{
		id: 3712,
		code: 'H959',
		label: 'Trastornos no especificados del oído y de la apófisis mastoides, consecutivos a procedimientos',
	},
	{
		id: 3713,
		code: 'I00X',
		label: 'Fiebre reumática sin mención de complicación cardíaca',
	},
	{
		id: 3714,
		code: 'I01',
		label: 'Fiebre reumática con complicación cardíaca',
	},
	{
		id: 3715,
		code: 'I010',
		label: 'Pericarditis reumática aguda',
	},
	{
		id: 3716,
		code: 'I011',
		label: 'Endocarditis reumática aguda',
	},
	{
		id: 3717,
		code: 'I012',
		label: 'Miocarditis reumática aguda',
	},
	{
		id: 3718,
		code: 'I018',
		label: 'Otras enfermedades reumáticas agudas del corazón',
	},
	{
		id: 3719,
		code: 'I019',
		label: 'Enfermedad reumática aguda del corazón, no especificada',
	},
	{
		id: 3720,
		code: 'I02',
		label: 'Corea reumática',
	},
	{
		id: 3721,
		code: 'I020',
		label: 'Corea reumática con complicación cardíaca',
	},
	{
		id: 3722,
		code: 'I029',
		label: 'Corea reumática sin mención de complicación cardíaca',
	},
	{
		id: 3723,
		code: 'I05',
		label: 'Enfermedades reumáticas de la válvula mitral',
	},
	{
		id: 3724,
		code: 'I050',
		label: 'Estenosis mitral',
	},
	{
		id: 3725,
		code: 'I051',
		label: 'Insuficiencia mitral reumática',
	},
	{
		id: 3726,
		code: 'I052',
		label: 'Estenosis mitral con insuficiencia',
	},
	{
		id: 3727,
		code: 'I058',
		label: 'Otras enfermedades de la válvula mitral',
	},
	{
		id: 3728,
		code: 'I059',
		label: 'Enfermedad valvular mitral, no especificada',
	},
	{
		id: 3729,
		code: 'I06',
		label: 'Enfermedades reumáticas de la válvula aórtica',
	},
	{
		id: 3730,
		code: 'I060',
		label: 'Estenosis aórtica reumática',
	},
	{
		id: 3731,
		code: 'I061',
		label: 'Insuficiencia aórtica reumática',
	},
	{
		id: 3732,
		code: 'I062',
		label: 'Estenosis aórtica reumática con insuficiencia',
	},
	{
		id: 3733,
		code: 'I068',
		label: 'Otras enfermedades reumáticas de la válvula aórtica',
	},
	{
		id: 3734,
		code: 'I069',
		label: 'Enfermedad valvular aórtica reumática, no especificada',
	},
	{
		id: 3735,
		code: 'I07',
		label: 'Enfermedades reumáticas de la válvula tricúspide',
	},
	{
		id: 3736,
		code: 'I070',
		label: 'Estenosis tricúspide',
	},
	{
		id: 3737,
		code: 'I071',
		label: 'Insuficiencia tricúspide',
	},
	{
		id: 3738,
		code: 'I072',
		label: 'Estenosis e insuficiencia tricúspide',
	},
	{
		id: 3739,
		code: 'I078',
		label: 'Otras enfermedades de la válvula tricúspide',
	},
	{
		id: 3740,
		code: 'I079',
		label: 'Enfermedad de la válvula tricúspide, no especificada',
	},
	{
		id: 3741,
		code: 'I08',
		label: 'Enfermedades valvulares múltiples',
	},
	{
		id: 3742,
		code: 'I080',
		label: 'Trastornos de las válvulas mitral y aórtica',
	},
	{
		id: 3743,
		code: 'I081',
		label: 'Trastornos de las válvulas mitral y tricúspide',
	},
	{
		id: 3744,
		code: 'I082',
		label: 'Trastornos de las válvulas aórtica y tricúspide',
	},
	{
		id: 3745,
		code: 'I083',
		label: 'Trastornos combinados de las válvulas mitral, tricúspide y aórtica',
	},
	{
		id: 3746,
		code: 'I088',
		label: 'Otras enfermedades de múltiples válvulas',
	},
	{
		id: 3747,
		code: 'I089',
		label: 'Enfermedad de múltiples válvulas, no especificada',
	},
	{
		id: 3748,
		code: 'I09',
		label: 'Otras enfermedades reumáticas del corazón',
	},
	{
		id: 3749,
		code: 'I090',
		label: 'Miocarditis reumática',
	},
	{
		id: 3750,
		code: 'I091',
		label: 'Enfermedades reumáticas del endocardio, válvula no especificada',
	},
	{
		id: 3751,
		code: 'I092',
		label: 'Pericarditis reumática crónica',
	},
	{
		id: 3752,
		code: 'I098',
		label: 'Otras enfermedades reumáticas especificadas del corazón',
	},
	{
		id: 3753,
		code: 'I099',
		label: 'Enfermedad reumática del corazón, no especificada',
	},
	{
		id: 3754,
		code: 'I10X',
		label: 'Hipertensión esencial (primaria)',
	},
	{
		id: 3755,
		code: 'I11',
		label: 'Enfermedad cardíaca hipertensiva',
	},
	{
		id: 3756,
		code: 'I110',
		label: 'Enfermedad cardíaca hipertensiva con insuficiencia cardíaca (congestiva)',
	},
	{
		id: 3757,
		code: 'I119',
		label: 'Enfermedad cardíaca hipertensiva sin insuficiencia cardíaca (congestiva)',
	},
	{
		id: 3758,
		code: 'I12',
		label: 'Enfermedad renal hipertensiva',
	},
	{
		id: 3759,
		code: 'I120',
		label: 'Enfermedad renal hipertensiva con insuficiencia renal',
	},
	{
		id: 3760,
		code: 'I129',
		label: 'Enfermedad renal hipertensiva sin insuficiencia renal',
	},
	{
		id: 3761,
		code: 'I13',
		label: 'Enfermedad cardiorrenal hipertensiva',
	},
	{
		id: 3762,
		code: 'I130',
		label: 'Enfermedad cardiorrenal hipertensiva con insuficiencia cardíaca (congestiva)',
	},
	{
		id: 3763,
		code: 'I131',
		label: 'Enfermedad cardiorrenal hipertensiva con insuficiencia renal',
	},
	{
		id: 3764,
		code: 'I132',
		label: 'Enfermedad cardiorrenal hipertensiva con insuficiencia cardíaca (congestiva) e insuficiencia renal ',
	},
	{
		id: 3765,
		code: 'I139',
		label: 'Enfermedad cardiorrenal hipertensiva, no especificada',
	},
	{
		id: 3766,
		code: 'I15',
		label: 'Hipertensión secundaria',
	},
	{
		id: 3767,
		code: 'I150',
		label: 'Hipertensión renovascular',
	},
	{
		id: 3768,
		code: 'I151',
		label: 'Hipertensión secundaria a otros trastornos renales',
	},
	{
		id: 3769,
		code: 'I152',
		label: 'Hipertensión secundaria  a trastornos endocrinos',
	},
	{
		id: 3770,
		code: 'I158',
		label: 'otros tipos de hipertensión secundaria',
	},
	{
		id: 3771,
		code: 'I159',
		label: 'Hipertensión secundaria, no especificada',
	},
	{
		id: 3772,
		code: 'I20',
		label: 'Angina de pecho',
	},
	{
		id: 3773,
		code: 'I200',
		label: 'Angina inestable',
	},
	{
		id: 3774,
		code: 'I201',
		label: 'Angina de pecho con espasmo documentado',
	},
	{
		id: 3775,
		code: 'I208',
		label: 'Otras formas especificadas de angina de pecho',
	},
	{
		id: 3776,
		code: 'I209',
		label: 'Angina de pecho, no especificada',
	},
	{
		id: 3777,
		code: 'I21',
		label: 'Infarto agudo del miocardio',
	},
	{
		id: 3778,
		code: 'I210',
		label: 'Infarto transmural agudo del miocardio de la pared anterior',
	},
	{
		id: 3779,
		code: 'I211',
		label: 'Infarto transmural agudo del miocardio de la pared inferior',
	},
	{
		id: 3780,
		code: 'I212',
		label: 'Infarto agudo transmural del miocardio de otros sitios',
	},
	{
		id: 3781,
		code: 'I213',
		label: 'Infarto transmural agudo del miocardio, de sitio no especificado',
	},
	{
		id: 3782,
		code: 'I214',
		label: 'Infarto subendocárdico agudo del miocardio',
	},
	{
		id: 3783,
		code: 'I219',
		label: 'Infarto agudo del miocardio, sin otra especificación',
	},
	{
		id: 3784,
		code: 'I22',
		label: 'Infarto subsecuente del miocardio',
	},
	{
		id: 3785,
		code: 'I220',
		label: 'Infarto subsecuente del miocardio de la pared anterior',
	},
	{
		id: 3786,
		code: 'I221',
		label: 'Infarto subsecuente del miocardio de la pared inferior',
	},
	{
		id: 3787,
		code: 'I228',
		label: 'Infarto subsecuente del miocardio de otros sitios',
	},
	{
		id: 3788,
		code: 'I229',
		label: 'Infarto subsecuente del miocardio, de parte no especificada',
	},
	{
		id: 3789,
		code: 'I23',
		label: 'Ciertas complicaciones presentes posteriores al infarto agudo del miocardio',
	},
	{
		id: 3790,
		code: 'I230',
		label: 'Hemopericardio como complicación presente posterior al infarto agudo del miocardio',
	},
	{
		id: 3791,
		code: 'I231',
		label: 'Defecto del tabique auricular como complicación presente posterior al infarto del miocardio',
	},
	{
		id: 3792,
		code: 'I232',
		label: 'Defecto del tabique ventricular como complicación presente posterior al infarto del miocardio',
	},
	{
		id: 3793,
		code: 'I233',
		label: 'Ruptura de la pared cardíaca sin hemopericardio como complicación presente posterior al infarto agudo del miocardio',
	},
	{
		id: 3794,
		code: 'I234',
		label: 'Ruptura de las cuerdas tendinosas como complicación presente posterior al infarto agudo del miocardio',
	},
	{
		id: 3795,
		code: 'I235',
		label: 'Ruptura de músculo papilar como complicación presente posterior al infarto agudo del miocardio',
	},
	{
		id: 3796,
		code: 'I236',
		label: 'Trombosis de la aurícula, apéndice  auricular y ventrículo como complicación presente posterior al infarto agudo del miocardio',
	},
	{
		id: 3797,
		code: 'I238',
		label: 'Otras complicaciones presentes posteriores al infarto agudo del miocardio',
	},
	{
		id: 3798,
		code: 'I24',
		label: 'Otras enfermedades isquémicas agudas del corazón',
	},
	{
		id: 3799,
		code: 'I240',
		label: 'Trombosis coronaria que no resulta en infarto del miocardio',
	},
	{
		id: 3800,
		code: 'I241',
		label: 'Síndrome de Dressler',
	},
	{
		id: 3801,
		code: 'I248',
		label: 'Otras formas de enfermedad isquémica aguda del corazón',
	},
	{
		id: 3802,
		code: 'I249',
		label: 'Enfermedad isquémica aguda del corazón, no especificada',
	},
	{
		id: 3803,
		code: 'I25',
		label: 'Enfermedad isquémica crónica del corazón',
	},
	{
		id: 3804,
		code: 'I250',
		label: 'Enfermedad cardiovascular aterosclerótica, así descrita',
	},
	{
		id: 3805,
		code: 'I251',
		label: 'Enfermedad aterosclerótica del corazón',
	},
	{
		id: 3806,
		code: 'I252',
		label: 'Infarto antiguo del miocardio',
	},
	{
		id: 3807,
		code: 'I253',
		label: 'Aneurisma cardíaco',
	},
	{
		id: 3808,
		code: 'I254',
		label: 'Aneurisma de arteria coronaria',
	},
	{
		id: 3809,
		code: 'I255',
		label: 'Cardiomiopatía isquémica',
	},
	{
		id: 3810,
		code: 'I256',
		label: 'Isquemia silente del miocardio',
	},
	{
		id: 3811,
		code: 'I258',
		label: 'Otras formas de enfermedad isquémica crónica del corazón',
	},
	{
		id: 3812,
		code: 'I259',
		label: 'Enfermedad isquémica crónica del corazón, no especificada',
	},
	{
		id: 3813,
		code: 'I26',
		label: 'Embolia pulmonar',
	},
	{
		id: 3814,
		code: 'I260',
		label: 'Embolia pulmonar con mención de corazón pulmonar agudo',
	},
	{
		id: 3815,
		code: 'I269',
		label: 'Embolia pulmonar sin mención de corazón pulmonar agudo',
	},
	{
		id: 3816,
		code: 'I27',
		label: 'Otras enfermedades cardiopulmonares',
	},
	{
		id: 3817,
		code: 'I270',
		label: 'Hipertensión pulmonar primaria',
	},
	{
		id: 3818,
		code: 'I271',
		label: 'Enfermedad cifoscoliótica del corazón',
	},
	{
		id: 3819,
		code: 'I272',
		label: 'Otras hipertensiones pulmonares secundarias',
	},
	{
		id: 3820,
		code: 'I278',
		label: 'Otras enfermedades cardiopulmonares especificadas',
	},
	{
		id: 3821,
		code: 'I279',
		label: 'Enfermedad pulmonar del corazón, no especificada',
	},
	{
		id: 3822,
		code: 'I28',
		label: 'Otras enfermedades de los vasos pulmonares',
	},
	{
		id: 3823,
		code: 'I280',
		label: 'Fístula arteriovenosa de los vasos pulmonares',
	},
	{
		id: 3824,
		code: 'I281',
		label: 'Aneurisma de la arteria pulmonar',
	},
	{
		id: 3825,
		code: 'I288',
		label: 'Otras enfermedades especificadas de los vasos pulmonares',
	},
	{
		id: 3826,
		code: 'I289',
		label: 'Enfermedad de los vasos pulmonares, no especificada',
	},
	{
		id: 3827,
		code: 'I30',
		label: 'Pericarditis aguda',
	},
	{
		id: 3828,
		code: 'I300',
		label: 'Pericarditis idiopática aguda inespecífica',
	},
	{
		id: 3829,
		code: 'I301',
		label: 'Pericarditis infecciosa',
	},
	{
		id: 3830,
		code: 'I308',
		label: 'Otras formas de pericarditis aguda',
	},
	{
		id: 3831,
		code: 'I309',
		label: 'Pericarditis aguda, no especificada',
	},
	{
		id: 3832,
		code: 'I31',
		label: 'Otras enfermedades del pericardio',
	},
	{
		id: 3833,
		code: 'I310',
		label: 'Pericarditis crónica adhesiva',
	},
	{
		id: 3834,
		code: 'I311',
		label: 'Pericarditis constrictiva crónica',
	},
	{
		id: 3835,
		code: 'I312',
		label: 'Hemopericardio, no clasificado en otra parte',
	},
	{
		id: 3836,
		code: 'I313',
		label: 'Derrame pericárdico (no inflamatorio)',
	},
	{
		id: 3837,
		code: 'I318',
		label: 'Otras enfermedades especificadas del pericardio',
	},
	{
		id: 3838,
		code: 'I319',
		label: 'Enfermedad del pericardio, no especificada',
	},
	{
		id: 3839,
		code: 'I32',
		label: 'Pericarditis en enfermedades clasificadas en otra parte',
	},
	{
		id: 3840,
		code: 'I320',
		label: 'Pericarditis en enfermedades bacterianas clasificadas en otra parte',
	},
	{
		id: 3841,
		code: 'I321',
		label: 'Pericarditis en otras enfermedades infecciosas y parasitarias clasificadas en otra parte',
	},
	{
		id: 3842,
		code: 'I328',
		label: 'Pericarditis en otras enfermedades clasificadas en otras parte',
	},
	{
		id: 3843,
		code: 'I33',
		label: 'Endocarditis aguda y subaguda',
	},
	{
		id: 3844,
		code: 'I330',
		label: 'Endocarditis infecciosa aguda y subaguda',
	},
	{
		id: 3845,
		code: 'I339',
		label: 'Endocarditis aguda, no especificada',
	},
	{
		id: 3846,
		code: 'I34',
		label: 'Trastornos no reumáticos de la válvula mitral',
	},
	{
		id: 3847,
		code: 'I340',
		label: 'Insuficiencia (de la válvula) mitral',
	},
	{
		id: 3848,
		code: 'I341',
		label: 'Prolapso (de la válvula) mitral',
	},
	{
		id: 3849,
		code: 'I342',
		label: 'Estenosis (de la válvula) mitral, no reumática',
	},
	{
		id: 3850,
		code: 'I348',
		label: 'Otros trastornos no reumáticos de la válvula mitral',
	},
	{
		id: 3851,
		code: 'I349',
		label: 'Trastorno mitral no reumático, no especificado',
	},
	{
		id: 3852,
		code: 'I35',
		label: 'Trastornos no reumáticos de la válvula aórtica',
	},
	{
		id: 3853,
		code: 'I350',
		label: 'Estenosis (de la válvula) aórtica',
	},
	{
		id: 3854,
		code: 'I351',
		label: 'Insuficiencia (de la válvula) aórtica',
	},
	{
		id: 3855,
		code: 'I352',
		label: 'Estenosis (de la válvula) aórtica con insuficiencia',
	},
	{
		id: 3856,
		code: 'I358',
		label: 'Otros trastornos de la válvula aórtica',
	},
	{
		id: 3857,
		code: 'I359',
		label: 'Trastorno de la válvula aórtica, no especificado',
	},
	{
		id: 3858,
		code: 'I36',
		label: 'Trastornos no reumáticos de la válvula tricúspide',
	},
	{
		id: 3859,
		code: 'I360',
		label: 'Estenosis no reumática (de la válvula) tricúspide',
	},
	{
		id: 3860,
		code: 'I361',
		label: 'Insuficiencia no reumática (de la válvula) tricúspide',
	},
	{
		id: 3861,
		code: 'I362',
		label: 'Estenosis con insuficiencia no reumática (de la válvula) tricúspide',
	},
	{
		id: 3862,
		code: 'I368',
		label: 'Otros trastornos no reumáticos de la válvula tricúspide',
	},
	{
		id: 3863,
		code: 'I369',
		label: 'Trastorno no reumático de la válvula tricúspide, no especificado',
	},
	{
		id: 3864,
		code: 'I37',
		label: 'Trastornos de la válvula pulmonar',
	},
	{
		id: 3865,
		code: 'I370',
		label: 'Estenosis de la válvula pulmonar',
	},
	{
		id: 3866,
		code: 'I371',
		label: 'Insuficiencia de la válvula pulmonar',
	},
	{
		id: 3867,
		code: 'I372',
		label: 'Estenosis de la válvula pulmonar con insuficiencia',
	},
	{
		id: 3868,
		code: 'I378',
		label: 'Otros trastornos de la válvula pulmonar',
	},
	{
		id: 3869,
		code: 'I379',
		label: 'Trastorno de la válvula pulmonar, no especificado',
	},
	{
		id: 3870,
		code: 'I38X',
		label: 'Endocarditis, válvula no especificada',
	},
	{
		id: 3871,
		code: 'I39',
		label: 'Endocarditis y trastornos valvulares en enfermedades clasificadas en otra parte',
	},
	{
		id: 3872,
		code: 'I390',
		label: 'Trastornos de la válvula mitral en enfermedades clasificadas en otra parte',
	},
	{
		id: 3873,
		code: 'I391',
		label: 'Trastornos de la válvula aórtica en enfermedades clasificadas en otra parte',
	},
	{
		id: 3874,
		code: 'I392',
		label: 'Trastornos de la válvula tricúspide en enfermedades clasificadas en otra parte',
	},
	{
		id: 3875,
		code: 'I393',
		label: 'Trastornos de la válvula pulmonar en enfermedades clasificadas en otra parte',
	},
	{
		id: 3876,
		code: 'I394',
		label: 'Trastornos valvulares múltiples en enfermedades clasificadas en otras parte',
	},
	{
		id: 3877,
		code: 'I398',
		label: 'Endocarditis, válvula no especificada, en enfermedades clasificadas en otra parte',
	},
	{
		id: 3878,
		code: 'I40',
		label: 'Miocarditis aguda',
	},
	{
		id: 3879,
		code: 'I400',
		label: 'Miocarditis infecciosa',
	},
	{
		id: 3880,
		code: 'I401',
		label: 'Miocarditis aislada',
	},
	{
		id: 3881,
		code: 'I408',
		label: 'Otras miocarditis agudas',
	},
	{
		id: 3882,
		code: 'I409',
		label: 'Miocarditis aguda, no especificada',
	},
	{
		id: 3883,
		code: 'I41',
		label: 'Miocarditis en enfermedades clasificadas en otra parte',
	},
	{
		id: 3884,
		code: 'I410',
		label: 'Miocarditis en enfermedades bacterianas clasificadas en otra parte',
	},
	{
		id: 3885,
		code: 'I411',
		label: 'Miocarditis en enfermedades virales clasificadas en otra parte',
	},
	{
		id: 3886,
		code: 'I412',
		label: 'Miocarditis en otras enfermedades infecciosas y parasitarias clasificadas en otra parte',
	},
	{
		id: 3887,
		code: 'I418',
		label: 'Miocarditis en otras enfermedades clasificadas en otra parte',
	},
	{
		id: 3888,
		code: 'I42',
		label: 'Cardiomiopatía',
	},
	{
		id: 3889,
		code: 'I420',
		label: 'Cardiomiopatía dilatada',
	},
	{
		id: 3890,
		code: 'I421',
		label: 'Cardiomiopatía hipertrófica obstructiva',
	},
	{
		id: 3891,
		code: 'I422',
		label: 'Otras cardiomiopatías hipertróficas',
	},
	{
		id: 3892,
		code: 'I423',
		label: 'Enfermedad endomiocárdica (eosinofílica)',
	},
	{
		id: 3893,
		code: 'I424',
		label: 'Fibroelastosis endocárdica',
	},
	{
		id: 3894,
		code: 'I425',
		label: 'Otras cardiomiopatías restrictivas',
	},
	{
		id: 3895,
		code: 'I426',
		label: 'Cardiomiopatía alcohólica',
	},
	{
		id: 3896,
		code: 'I427',
		label: 'Cardiomiopatía debida a drogas y otros agentes externos',
	},
	{
		id: 3897,
		code: 'I428',
		label: 'Otras cardiomiopatías',
	},
	{
		id: 3898,
		code: 'I429',
		label: 'Cardiomiopatía, no especificada',
	},
	{
		id: 3899,
		code: 'I43',
		label: 'Cardiomiopatía en enfermedades clasificadas en otra parte',
	},
	{
		id: 3900,
		code: 'I430',
		label: 'Cardiomiopatía en enfermedades infecciosas y parasitarias clasificadas en otra parte',
	},
	{
		id: 3901,
		code: 'I431',
		label: 'Cardiomiopatía en enfermedades metabólicas',
	},
	{
		id: 3902,
		code: 'I432',
		label: 'Cardiomiopatía en enfermedades nutricionales',
	},
	{
		id: 3903,
		code: 'I438',
		label: 'Cardiomiopatía en otras enfermedades clasificadas en otra parte',
	},
	{
		id: 3904,
		code: 'I44',
		label: 'Bloqueo auriculoventricular y de rama izquierda del haz',
	},
	{
		id: 3905,
		code: 'I440',
		label: 'Bloqueo auriculoventricular de primer grado',
	},
	{
		id: 3906,
		code: 'I441',
		label: 'Bloqueo auriculoventricular de segundo grado',
	},
	{
		id: 3907,
		code: 'I442',
		label: 'Bloqueo auriculoventricular completo',
	},
	{
		id: 3908,
		code: 'I443',
		label: 'Otros tipos de bloqueo auriculoventricular y los no especificados',
	},
	{
		id: 3909,
		code: 'I444',
		label: 'Bloqueo fascicular anterior izquierdo',
	},
	{
		id: 3910,
		code: 'I445',
		label: 'Bloqueo fascicular posterior izquierdo',
	},
	{
		id: 3911,
		code: 'I446',
		label: 'Otros tipos de bloqueo fascicular y los no especificados',
	},
	{
		id: 3912,
		code: 'I447',
		label: 'Bloqueo de rama izquierda del haz, sin otra especificación',
	},
	{
		id: 3913,
		code: 'I45',
		label: 'Otros trastornos de la conducción',
	},
	{
		id: 3914,
		code: 'I450',
		label: 'Bloqueo fascicular derecho',
	},
	{
		id: 3915,
		code: 'I451',
		label: 'Otros tipos de bloqueo de rama derecha del haz y los no especificados',
	},
	{
		id: 3916,
		code: 'I452',
		label: 'Bloqueo bifascicular',
	},
	{
		id: 3917,
		code: 'I453',
		label: 'Bloqueo trifascicular',
	},
	{
		id: 3918,
		code: 'I454',
		label: 'Bloqueo intraventricular no especificado',
	},
	{
		id: 3919,
		code: 'I455',
		label: 'Otros tipos especificados de bloqueo del corazón',
	},
	{
		id: 3920,
		code: 'I456',
		label: 'Síndrome de preexcitación',
	},
	{
		id: 3921,
		code: 'I458',
		label: 'Otros trastornos especificados de la conducción',
	},
	{
		id: 3922,
		code: 'I459',
		label: 'Trastorno de la conducción, no especificado',
	},
	{
		id: 3923,
		code: 'I46',
		label: 'Paro cardíaco',
	},
	{
		id: 3924,
		code: 'I460',
		label: 'Paro cardíaco con resucitación exitosa',
	},
	{
		id: 3925,
		code: 'I461',
		label: 'Muerte cardíaca súbita, así descrita',
	},
	{
		id: 3926,
		code: 'I469',
		label: 'Paro cardíaco, no especificado',
	},
	{
		id: 3927,
		code: 'I47',
		label: 'Taquicardia paroxística',
	},
	{
		id: 3928,
		code: 'I470',
		label: 'Arritmia por reentrada ventricular',
	},
	{
		id: 3929,
		code: 'I471',
		label: 'Taquicardia supraventricular',
	},
	{
		id: 3930,
		code: 'I472',
		label: 'Taquicardia ventricular',
	},
	{
		id: 3931,
		code: 'I479',
		label: 'Taquicardia paroxística, no especificada',
	},
	{
		id: 3932,
		code: 'I48',
		label: 'Fibrilación y aleteo auricular',
	},
	{
		id: 3933,
		code: 'I480',
		label: 'Fibrilación auricular paroxística',
	},
	{
		id: 3934,
		code: 'I481',
		label: 'Fibrilación auricular persistente ',
	},
	{
		id: 3935,
		code: 'I482',
		label: 'Fibrilación auricular crónica ',
	},
	{
		id: 3936,
		code: 'I483',
		label: 'Aleteo auricular típico',
	},
	{
		id: 3937,
		code: 'I484',
		label: 'Aleteo auricular atípico ',
	},
	{
		id: 3938,
		code: 'I489',
		label: 'Fibrilación y aleteo auricular, no especificado ',
	},
	{
		id: 3939,
		code: 'I48X',
		label: 'Fibrilación y aleteo auricular',
	},
	{
		id: 3940,
		code: 'I49',
		label: 'Otras arritmias cardiacas',
	},
	{
		id: 3941,
		code: 'I490',
		label: 'Fibrilación y aleteo ventricular',
	},
	{
		id: 3942,
		code: 'I491',
		label: 'Despolarización auricular prematura',
	},
	{
		id: 3943,
		code: 'I492',
		label: 'Despolarización prematura nodal',
	},
	{
		id: 3944,
		code: 'I493',
		label: 'Despolarización ventricular prematura',
	},
	{
		id: 3945,
		code: 'I494',
		label: 'Otros tipos de despolarización prematura y los no especificados',
	},
	{
		id: 3946,
		code: 'I495',
		label: 'Síndrome del seno enfermo',
	},
	{
		id: 3947,
		code: 'I498',
		label: 'Otras arritmias cardiacas especificadas',
	},
	{
		id: 3948,
		code: 'I499',
		label: 'Arritmia cardíaca, no especificada',
	},
	{
		id: 3949,
		code: 'I50',
		label: 'Insuficiencia cardíaca',
	},
	{
		id: 3950,
		code: 'I500',
		label: 'Insuficiencia cardíaca congestiva',
	},
	{
		id: 3951,
		code: 'I501',
		label: 'Insuficiencia ventricular izquierda',
	},
	{
		id: 3952,
		code: 'I509',
		label: 'Insuficiencia cardíaca, no especificada',
	},
	{
		id: 3953,
		code: 'I51',
		label: 'Complicaciones y descripciones mal definidas de enfermedad cardíaca',
	},
	{
		id: 3954,
		code: 'I510',
		label: 'Defecto del tabique cardíaco, adquirido',
	},
	{
		id: 3955,
		code: 'I511',
		label: 'Ruptura de cuerda tendinosa, no clasificada en otra parte',
	},
	{
		id: 3956,
		code: 'I512',
		label: 'Ruptura de músculo papilar, no clasificada en otra parte',
	},
	{
		id: 3957,
		code: 'I513',
		label: 'Trombosis intracardíaca, no clasificada en otra parte',
	},
	{
		id: 3958,
		code: 'I514',
		label: 'Miocarditis, no especificada',
	},
	{
		id: 3959,
		code: 'I515',
		label: 'Degeneración miocárdica',
	},
	{
		id: 3960,
		code: 'I516',
		label: 'Enfermedad cardiovascular, no especificada',
	},
	{
		id: 3961,
		code: 'I517',
		label: 'Cardiomegalia',
	},
	{
		id: 3962,
		code: 'I518',
		label: 'Otras enfermedades cardiacas mal definidas',
	},
	{
		id: 3963,
		code: 'I519',
		label: 'Enfermedad cardíaca, no especificada',
	},
	{
		id: 3964,
		code: 'I52',
		label: 'Otros trastornos cardíacos en enfermedades clasificadas en otra parte',
	},
	{
		id: 3965,
		code: 'I520',
		label: 'Otros trastornos cardíacos en enfermedades bacterianas clasificadas en otra parte',
	},
	{
		id: 3966,
		code: 'I521',
		label: 'Otros trastornos cardíacos en otras enfermedades infecciosas y parasitarias clasificadas en otra parte',
	},
	{
		id: 3967,
		code: 'I528',
		label: 'Otros trastornos cardíacos en otras enfermedades clasificadas en otra parte',
	},
	{
		id: 3968,
		code: 'I60',
		label: 'Hemorragia subaracnoidea',
	},
	{
		id: 3969,
		code: 'I600',
		label: 'Hemorragia subaracnoidea de sifón y bifurcación carotídea',
	},
	{
		id: 3970,
		code: 'I601',
		label: 'Hemorragia subaracnoidea de arteria cerebral media',
	},
	{
		id: 3971,
		code: 'I602',
		label: 'Hemorragia subaracnoidea de arteria comunicante anterior',
	},
	{
		id: 3972,
		code: 'I603',
		label: 'Hemorragia subaracnoidea de arteria comunicante posterior',
	},
	{
		id: 3973,
		code: 'I604',
		label: 'Hemorragia subaracnoidea de arteria basilar',
	},
	{
		id: 3974,
		code: 'I605',
		label: 'Hemorragia subaracnoidea de arteria vertebral',
	},
	{
		id: 3975,
		code: 'I606',
		label: 'Hemorragia subaracnoidea de otras arterias intracraneales',
	},
	{
		id: 3976,
		code: 'I607',
		label: 'Hemorragia subaracnoidea de arteria intracraneal no especificada',
	},
	{
		id: 3977,
		code: 'I608',
		label: 'Otras hemorragias subaracnoideas',
	},
	{
		id: 3978,
		code: 'I609',
		label: 'Hemorragia subaracnoidea, no especificada',
	},
	{
		id: 3979,
		code: 'I61',
		label: 'Hemorragia intraencefálica',
	},
	{
		id: 3980,
		code: 'I610',
		label: 'Hemorragia intracerebral en hemisferio, subcortical',
	},
	{
		id: 3981,
		code: 'I611',
		label: 'Hemorragia intracerebral en hemisferio, cortical',
	},
	{
		id: 3982,
		code: 'I612',
		label: 'Hemorragia intracerebral en hemisferio, no especificada',
	},
	{
		id: 3983,
		code: 'I613',
		label: 'Hemorragia intraencefálica en tallo cerebral',
	},
	{
		id: 3984,
		code: 'I614',
		label: 'Hemorragia intraencefálica en cerebelo',
	},
	{
		id: 3985,
		code: 'I615',
		label: 'Hemorragia intraencefálica, intraventricular',
	},
	{
		id: 3986,
		code: 'I616',
		label: 'Hemorragia intraencefálica de localizaciones múltiples',
	},
	{
		id: 3987,
		code: 'I618',
		label: 'Otras hemorragias intraencefálicas',
	},
	{
		id: 3988,
		code: 'I619',
		label: 'Hemorragia intraencefálica, no especificada',
	},
	{
		id: 3989,
		code: 'I62',
		label: 'Otras hemorragias intracraneales no traumáticas',
	},
	{
		id: 3990,
		code: 'I620',
		label: 'Hemorragia subdural (aguda) (no traumática)',
	},
	{
		id: 3991,
		code: 'I621',
		label: 'Hemorragia extradural no traumática',
	},
	{
		id: 3992,
		code: 'I629',
		label: 'Hemorragia intracraneal (no traumática), no especificada',
	},
	{
		id: 3993,
		code: 'I63',
		label: 'Infarto cerebral',
	},
	{
		id: 3994,
		code: 'I630',
		label: 'Infarto cerebral debido a trombosis de arterias precerebrales',
	},
	{
		id: 3995,
		code: 'I631',
		label: 'Infarto cerebral debido a embolia de arterias precerebrales',
	},
	{
		id: 3996,
		code: 'I632',
		label: 'Infarto cerebral debido a oclusión o estenosis no especificada de arterias precerebrales',
	},
	{
		id: 3997,
		code: 'I633',
		label: 'Infarto cerebral debido a trombosis de arterias cerebrales',
	},
	{
		id: 3998,
		code: 'I634',
		label: 'Infarto cerebral debido a embolia de arterias cerebrales',
	},
	{
		id: 3999,
		code: 'I635',
		label: 'Infarto cerebral debido a oclusión o estenosis no especificada de arterias cerebrales',
	},
	{
		id: 4000,
		code: 'I636',
		label: 'Infarto cerebral debido a trombosis de venas cerebrales, no piógeno',
	},
	{
		id: 4001,
		code: 'I638',
		label: 'Otros Infartos cerebrales',
	},
	{
		id: 4002,
		code: 'I639',
		label: 'Infarto cerebral, no especificado',
	},
	{
		id: 4003,
		code: 'I64X',
		label: 'Accidente vascular encefálico agudo, no especificado como hemorrágico o isquémico',
	},
	{
		id: 4004,
		code: 'I65',
		label: 'Oclusión y estenosis de las arterias precerebrales sin  ocasionar infarto cerebral',
	},
	{
		id: 4005,
		code: 'I650',
		label: 'Oclusión y estenosis de arteria vertebral',
	},
	{
		id: 4006,
		code: 'I651',
		label: 'Oclusión y estenosis de arteria basilar',
	},
	{
		id: 4007,
		code: 'I652',
		label: 'Oclusión y estenosis de arteria carótida',
	},
	{
		id: 4008,
		code: 'I653',
		label: 'Oclusión y estenosis múltiple bilateral de arterias precerebrales',
	},
	{
		id: 4009,
		code: 'I658',
		label: 'Oclusión y estenosis de Otras arterias precerebrales',
	},
	{
		id: 4010,
		code: 'I659',
		label: 'Oclusión y estenosis de arteria precerebral no especificada',
	},
	{
		id: 4011,
		code: 'I66',
		label: 'Oclusión y estenosis de las arterias cerebrales sin  ocasionar infarto cerebral',
	},
	{
		id: 4012,
		code: 'I660',
		label: 'Oclusión y estenosis de la arteria cerebral media',
	},
	{
		id: 4013,
		code: 'I661',
		label: 'Oclusión y estenosis de la arteria cerebral anterior',
	},
	{
		id: 4014,
		code: 'I662',
		label: 'Oclusión y estenosis de la arteria cerebral posterior',
	},
	{
		id: 4015,
		code: 'I663',
		label: 'Oclusión y estenosis de arterias cerebelosas',
	},
	{
		id: 4016,
		code: 'I664',
		label: 'Oclusión y estenosis múltiple bilateral de arterias cerebrales',
	},
	{
		id: 4017,
		code: 'I668',
		label: 'Oclusión y estenosis de otras arterias cerebrales',
	},
	{
		id: 4018,
		code: 'I669',
		label: 'Oclusión y estenosis de arteria cerebral no especificada',
	},
	{
		id: 4019,
		code: 'I67',
		label: 'Otras enfermedades cerebrovasculares',
	},
	{
		id: 4020,
		code: 'I670',
		label: 'Disección de arterias cerebrales, sin ruptura',
	},
	{
		id: 4021,
		code: 'I671',
		label: 'Aneurisma cerebral, sin ruptura',
	},
	{
		id: 4022,
		code: 'I672',
		label: 'Aterosclerosis cerebral',
	},
	{
		id: 4023,
		code: 'I673',
		label: 'Leucoencefalopatía vascular progresiva',
	},
	{
		id: 4024,
		code: 'I674',
		label: 'Encefalopatía hipertensiva',
	},
	{
		id: 4025,
		code: 'I675',
		label: 'Enfermedad de Moyamoya',
	},
	{
		id: 4026,
		code: 'I676',
		label: 'Trombosis apiógena del sistema venoso intracraneal',
	},
	{
		id: 4027,
		code: 'I677',
		label: 'Arteritis cerebral, no clasificada en otra parte',
	},
	{
		id: 4028,
		code: 'I678',
		label: 'Otras enfermedades cerebrovasculares especificadas',
	},
	{
		id: 4029,
		code: 'I679',
		label: 'Enfermedad cerebrovascular, no especificada',
	},
	{
		id: 4030,
		code: 'I68',
		label: 'Trastornos cerebrovasculares en enfermedades clasificadas en otra parte',
	},
	{
		id: 4031,
		code: 'I680',
		label: 'Angiopatía cerebral amiloide',
	},
	{
		id: 4032,
		code: 'I681',
		label: 'Arteritis cerebral en enfermedades infecciosas y parasitarias clasificadas en otra parte',
	},
	{
		id: 4033,
		code: 'I682',
		label: 'Arteritis cerebral en otras enfermedades clasificadas en otra parte',
	},
	{
		id: 4034,
		code: 'I688',
		label: 'Otros trastornos cerebrovasculares en enfermedades clasificadas en otra parte',
	},
	{
		id: 4035,
		code: 'I69',
		label: 'Secuelas de enfermedad cerebrovascular',
	},
	{
		id: 4036,
		code: 'I690',
		label: 'Secuelas de hemorragia subaracnoidea',
	},
	{
		id: 4037,
		code: 'I691',
		label: 'Secuelas de hemorragia intraencefálica',
	},
	{
		id: 4038,
		code: 'I692',
		label: 'Secuelas de otras hemorragias intracraneales no traumáticas',
	},
	{
		id: 4039,
		code: 'I693',
		label: 'Secuelas de infarto cerebral',
	},
	{
		id: 4040,
		code: 'I694',
		label: 'Secuelas de accidente vascular encéfalico, no especificado como hemorrágico o isquémico',
	},
	{
		id: 4041,
		code: 'I698',
		label: 'Secuelas de otras enfermedades cerebrovasculares y de las no especificadas ',
	},
	{
		id: 4042,
		code: 'I70',
		label: 'Aterosclerosis',
	},
	{
		id: 4043,
		code: 'I700',
		label: 'Aterosclerosis de la aorta',
	},
	{
		id: 4044,
		code: 'I701',
		label: 'Aterosclerosis de la arteria renal',
	},
	{
		id: 4045,
		code: 'I702',
		label: 'Aterosclerosis de las arterias de los miembros',
	},
	{
		id: 4046,
		code: 'I708',
		label: 'Aterosclerosis de otras arterias',
	},
	{
		id: 4047,
		code: 'I709',
		label: 'Aterosclerosis generalizada y la no especificada',
	},
	{
		id: 4048,
		code: 'I71',
		label: 'Aneurisma y disección aórticos',
	},
	{
		id: 4049,
		code: 'I710',
		label: 'Disección de aorta (cualquier parte)',
	},
	{
		id: 4050,
		code: 'I711',
		label: 'Ruptura de aneurisma de la aorta torácica',
	},
	{
		id: 4051,
		code: 'I712',
		label: 'Aneurisma de la aorta torácica, sin mención de ruptura',
	},
	{
		id: 4052,
		code: 'I713',
		label: 'Ruptura de aneurisma de la aorta abdominal',
	},
	{
		id: 4053,
		code: 'I714',
		label: 'Aneurisma de la aorta abdominal, sin mención de ruptura',
	},
	{
		id: 4054,
		code: 'I715',
		label: 'Ruptura de aneurisma de la aorta toracoabdominal',
	},
	{
		id: 4055,
		code: 'I716',
		label: 'Aneurisma de la aorta toracoabdominal, sin mención de ruptura',
	},
	{
		id: 4056,
		code: 'I718',
		label: 'Ruptura de aneurisma aórtico, sitio no especificado',
	},
	{
		id: 4057,
		code: 'I719',
		label: 'Aneurisma de la aorta, sitio no especificado, sin mención de ruptura',
	},
	{
		id: 4058,
		code: 'I72',
		label: 'Otros aneurismas y aneurismas disecantes',
	},
	{
		id: 4059,
		code: 'I720',
		label: 'Aneurisma y disección de la arteria carótida',
	},
	{
		id: 4060,
		code: 'I721',
		label: 'Aneurisma y disección de arteria del miembro superior',
	},
	{
		id: 4061,
		code: 'I722',
		label: 'Aneurisma y disección de arteria renal',
	},
	{
		id: 4062,
		code: 'I723',
		label: 'Aneurisma y disección de arteria ilíaca',
	},
	{
		id: 4063,
		code: 'I724',
		label: 'Aneurisma y disección de arteria del miembro inferior',
	},
	{
		id: 4064,
		code: 'I725',
		label: 'Aneurisma y disección de otras arterias precerebrales',
	},
	{
		id: 4065,
		code: 'I726',
		label: 'Aneurisma y disección de arteria vertebral',
	},
	{
		id: 4066,
		code: 'I728',
		label: 'Aneurisma y disección de otras arterias especificadas',
	},
	{
		id: 4067,
		code: 'I729',
		label: 'Aneurisma y disección de sitio no especificado',
	},
	{
		id: 4068,
		code: 'I73',
		label: 'Otras enfermedades vasculares periféricas',
	},
	{
		id: 4069,
		code: 'I730',
		label: 'Síndrome de Raynaud',
	},
	{
		id: 4070,
		code: 'I731',
		label: 'Tromboangeítis obliterante [Buerger]',
	},
	{
		id: 4071,
		code: 'I738',
		label: 'Otras enfermedades vasculares periféricas especificadas',
	},
	{
		id: 4072,
		code: 'I739',
		label: 'Enfermedad vascular periférica, no especificada',
	},
	{
		id: 4073,
		code: 'I74',
		label: 'Embolia y trombosis arteriales',
	},
	{
		id: 4074,
		code: 'I740',
		label: 'Embolia y trombosis de la aorta abdominal',
	},
	{
		id: 4075,
		code: 'I741',
		label: 'Embolia y trombosis de otras porciones y las no especificadas de la aorta',
	},
	{
		id: 4076,
		code: 'I742',
		label: 'Embolia y trombosis de arterias de los miembros superiores',
	},
	{
		id: 4077,
		code: 'I743',
		label: 'Embolia y trombosis de arterias de los miembros inferiores',
	},
	{
		id: 4078,
		code: 'I744',
		label: 'Embolia y trombosis de arterias de los miembros, no especificadas',
	},
	{
		id: 4079,
		code: 'I745',
		label: 'Embolia y trombosis de arteria ilíaca',
	},
	{
		id: 4080,
		code: 'I748',
		label: 'Embolia y trombosis de otras arterias',
	},
	{
		id: 4081,
		code: 'I749',
		label: 'Embolia y trombosis de arteria no especificada',
	},
	{
		id: 4082,
		code: 'I77',
		label: 'Otros trastornos arteriales o arteriolares',
	},
	{
		id: 4083,
		code: 'I770',
		label: 'Fístula arteriovenosa, adquirida',
	},
	{
		id: 4084,
		code: 'I771',
		label: 'Estrechez arterial',
	},
	{
		id: 4085,
		code: 'I772',
		label: 'Ruptura arterial',
	},
	{
		id: 4086,
		code: 'I773',
		label: 'Displasia fibromuscular arterial',
	},
	{
		id: 4087,
		code: 'I774',
		label: 'Síndrome de compresión del tronco celíaco',
	},
	{
		id: 4088,
		code: 'I775',
		label: 'Necrosis arterial',
	},
	{
		id: 4089,
		code: 'I776',
		label: 'Arteritis, no especificada',
	},
	{
		id: 4090,
		code: 'I778',
		label: 'Otros trastornos especificados de arterias y arteriolas',
	},
	{
		id: 4091,
		code: 'I779',
		label: 'Trastorno de arterias y arteriolas, no especificado',
	},
	{
		id: 4092,
		code: 'I78',
		label: 'Enfermedades de los vasos capilares',
	},
	{
		id: 4093,
		code: 'I780',
		label: 'Telangiectasia hemorrágica hereditaria',
	},
	{
		id: 4094,
		code: 'I781',
		label: 'Nevo, no neoplásico',
	},
	{
		id: 4095,
		code: 'I788',
		label: 'Otras enfermedades de los capilares',
	},
	{
		id: 4096,
		code: 'I789',
		label: 'Enfermedad de los vasos capilares, no especificada',
	},
	{
		id: 4097,
		code: 'I79',
		label: 'Trastornos de las arterias, de las arteriolas y de los vasos capilares en enfermedades clasificadas en otra parte',
	},
	{
		id: 4098,
		code: 'I790',
		label: 'Aneurisma de la aorta en enfermedades clasificadas en otra parte',
	},
	{
		id: 4099,
		code: 'I791',
		label: 'Aortitis en enfermedades clasificadas en otra parte',
	},
	{
		id: 4100,
		code: 'I792',
		label: 'Angiopatía periférica en enfermedades clasificadas en otra parte',
	},
	{
		id: 4101,
		code: 'I798',
		label: 'Otros trastornos de arterias, arteriolas y vasos capilares  en enfermedades clasificadas en otra parte',
	},
	{
		id: 4102,
		code: 'I80',
		label: 'Flebitis y tromboflebitis',
	},
	{
		id: 4103,
		code: 'I800',
		label: 'Flebitis y tromboflebitis de vasos superficiales de los miembros inferiores',
	},
	{
		id: 4104,
		code: 'I801',
		label: 'Flebitis y tromboflebitis de la vena femoral',
	},
	{
		id: 4105,
		code: 'I802',
		label: 'Flebitis y tromboflebitis de otros vasos profundos de los miembros inferiores',
	},
	{
		id: 4106,
		code: 'I803',
		label: 'Flebitis y tromboflebitis de los miembros inferiores, no especificada',
	},
	{
		id: 4107,
		code: 'I808',
		label: 'Flebitis y tromboflebitis de otros sitios',
	},
	{
		id: 4108,
		code: 'I809',
		label: 'Flebitis y tromboflebitis de sitio no especificado',
	},
	{
		id: 4109,
		code: 'I81X',
		label: 'Trombosis de la vena porta',
	},
	{
		id: 4110,
		code: 'I82',
		label: 'Otras embolias y trombosis venosas',
	},
	{
		id: 4111,
		code: 'I820',
		label: 'Síndrome de Budd-Chiari',
	},
	{
		id: 4112,
		code: 'I821',
		label: 'Tromboflebitis migratoria',
	},
	{
		id: 4113,
		code: 'I822',
		label: 'Embolia y trombosis de vena cava',
	},
	{
		id: 4114,
		code: 'I823',
		label: 'Embolia y trombosis de vena renal',
	},
	{
		id: 4115,
		code: 'I828',
		label: 'Embolia y trombosis de otras venas especificadas',
	},
	{
		id: 4116,
		code: 'I829',
		label: 'Embolia y trombosis de vena no especificada',
	},
	{
		id: 4117,
		code: 'I83',
		label: 'Venas varicosas de los miembros inferiores',
	},
	{
		id: 4118,
		code: 'I830',
		label: 'Venas varicosas de los miembros inferiores con úlcera',
	},
	{
		id: 4119,
		code: 'I831',
		label: 'Venas varicosas de los miembros inferiores con inflamación',
	},
	{
		id: 4120,
		code: 'I832',
		label: 'Venas varicosas de los miembros inferiores con úlcera e inflamación',
	},
	{
		id: 4121,
		code: 'I839',
		label: 'Venas varicosas de los miembros inferiores sin úlcera ni inflamación',
	},
	{
		id: 4122,
		code: 'I84',
		label: 'Hemorroides',
	},
	{
		id: 4123,
		code: 'I840',
		label: 'Hemorroides internas trombosadas',
	},
	{
		id: 4124,
		code: 'I841',
		label: 'Hemorroides internas con otras complicaciones',
	},
	{
		id: 4125,
		code: 'I842',
		label: 'Hemorroides internas sin complicación',
	},
	{
		id: 4126,
		code: 'I843',
		label: 'Hemorroides externas trombosadas',
	},
	{
		id: 4127,
		code: 'I844',
		label: 'Hemorroides externas con otras complicaciones',
	},
	{
		id: 4128,
		code: 'I845',
		label: 'Hemorroides externas sin complicación',
	},
	{
		id: 4129,
		code: 'I846',
		label: 'Prominencias cutáneas, residuo de hemorroides',
	},
	{
		id: 4130,
		code: 'I847',
		label: 'Hemorroides trombosadas no especificadas',
	},
	{
		id: 4131,
		code: 'I848',
		label: 'Hemorroides no especificadas, con otras complicaciones',
	},
	{
		id: 4132,
		code: 'I849',
		label: 'Hemorroides no especificadas, sin complicación',
	},
	{
		id: 4133,
		code: 'I85',
		label: 'Várices esofágicas',
	},
	{
		id: 4134,
		code: 'I850',
		label: 'Várices esofágicas con hemorragia',
	},
	{
		id: 4135,
		code: 'I859',
		label: 'Várices esofágicas sin hemorragia',
	},
	{
		id: 4136,
		code: 'I86',
		label: 'Várices de otros sitios',
	},
	{
		id: 4137,
		code: 'I860',
		label: 'Várices sublinguales',
	},
	{
		id: 4138,
		code: 'I861',
		label: 'Várices escrotales',
	},
	{
		id: 4139,
		code: 'I862',
		label: 'Várices pélvicas',
	},
	{
		id: 4140,
		code: 'I863',
		label: 'Várices de la vulva',
	},
	{
		id: 4141,
		code: 'I864',
		label: 'Várices gástricas',
	},
	{
		id: 4142,
		code: 'I868',
		label: 'Várices en otros sitios especificados',
	},
	{
		id: 4143,
		code: 'I87',
		label: 'Otros trastornos de las venas',
	},
	{
		id: 4144,
		code: 'I870',
		label: 'Síndrome postrombótico',
	},
	{
		id: 4145,
		code: 'I871',
		label: 'Compresión de vena',
	},
	{
		id: 4146,
		code: 'I872',
		label: 'Insuficiencia venosa (crónica) (periférica)',
	},
	{
		id: 4147,
		code: 'I878',
		label: 'Otros trastornos venosos especificados',
	},
	{
		id: 4148,
		code: 'I879',
		label: 'Trastorno venoso, no especificado',
	},
	{
		id: 4149,
		code: 'I88',
		label: 'Linfadenitis inespecífica',
	},
	{
		id: 4150,
		code: 'I880',
		label: 'Linfadenitis mesentérica inespecífica',
	},
	{
		id: 4151,
		code: 'I881',
		label: 'Linfadenitis crónica, excepto la mesentérica',
	},
	{
		id: 4152,
		code: 'I888',
		label: 'Otras linfadenitis inespecíficas',
	},
	{
		id: 4153,
		code: 'I889',
		label: 'Linfadenitis inespecífica no especificada',
	},
	{
		id: 4154,
		code: 'I89',
		label: 'Otros trastornos no infecciosos de los vasos y ganglios linfáticos',
	},
	{
		id: 4155,
		code: 'I890',
		label: 'Linfedema, no clasificado en otra parte',
	},
	{
		id: 4156,
		code: 'I891',
		label: 'Linfangitis',
	},
	{
		id: 4157,
		code: 'I898',
		label: 'Otros trastornos especificados no infecciosos de los vasos y ganglios linfáticos',
	},
	{
		id: 4158,
		code: 'I899',
		label: 'Trastorno no infeccioso de vasos y ganglios linfáticos, no especificado',
	},
	{
		id: 4159,
		code: 'I95',
		label: 'Hipotensión',
	},
	{
		id: 4160,
		code: 'I950',
		label: 'Hipotensión idiopática',
	},
	{
		id: 4161,
		code: 'I951',
		label: 'Hipotensión ortostática',
	},
	{
		id: 4162,
		code: 'I952',
		label: 'Hipotensión debida a drogas',
	},
	{
		id: 4163,
		code: 'I958',
		label: 'Otros tipos de hipotensión',
	},
	{
		id: 4164,
		code: 'I959',
		label: 'Hipotensión, no especificada',
	},
	{
		id: 4165,
		code: 'I97',
		label: 'Trastornos del sistema circulatorio consecutivos a procedimientos, no clasificados en otra parte',
	},
	{
		id: 4166,
		code: 'I970',
		label: 'Síndrome de postcardiotomía',
	},
	{
		id: 4167,
		code: 'I971',
		label: 'Otras alteraciones funcionales consecutivas a cirugía cardíaca',
	},
	{
		id: 4168,
		code: 'I972',
		label: 'Síndrome de linfedema postmastectomía',
	},
	{
		id: 4169,
		code: 'I978',
		label: 'Otros trastornos del sistema circulatorio consecutivos a procedimientos, no clasificados en otra parte',
	},
	{
		id: 4170,
		code: 'I979',
		label: 'Trastorno no especificado del sistema circulatorio consecutivo a procedimientos',
	},
	{
		id: 4171,
		code: 'I98',
		label: 'Otros trastornos del sistema circulatorio en enfermedades clasificadas en otra parte',
	},
	{
		id: 4172,
		code: 'I980',
		label: 'Sífilis cardiovascular',
	},
	{
		id: 4173,
		code: 'I981',
		label: 'Trastornos cardiovasculares en otras enfermedades infecciosas y parasitarias clasificadas en otra parte',
	},
	{
		id: 4174,
		code: 'I982',
		label: 'Várices esofágicas sin hemorragia en enfermedades clasificadas en otra parte',
	},
	{
		id: 4175,
		code: 'I983',
		label: 'Várices esofágicas con hemorragia en enfermedades clasificadas en otra parte ',
	},
	{
		id: 4176,
		code: 'I988',
		label: 'Otros trastornos especificados del aparato circulatorio en enfermedades clasificadas en otra parte',
	},
	{
		id: 4177,
		code: 'I99X',
		label: 'Otros trastornos y los no especificados del sistema circulatorio',
	},
	{
		id: 4178,
		code: 'J00X',
		label: 'Rinofaringitis aguda [resfriado común]',
	},
	{
		id: 4179,
		code: 'J01',
		label: 'Sinusitis aguda',
	},
	{
		id: 4180,
		code: 'J010',
		label: 'Sinusitis maxilar aguda',
	},
	{
		id: 4181,
		code: 'J011',
		label: 'Sinusitis frontal aguda',
	},
	{
		id: 4182,
		code: 'J012',
		label: 'Sinusitis etmoidal aguda',
	},
	{
		id: 4183,
		code: 'J013',
		label: 'Sinusitis esfenoidal aguda',
	},
	{
		id: 4184,
		code: 'J014',
		label: 'Pansinusitis aguda',
	},
	{
		id: 4185,
		code: 'J018',
		label: 'Otras sinusitis agudas',
	},
	{
		id: 4186,
		code: 'J019',
		label: 'Sinusitis aguda, no especificada',
	},
	{
		id: 4187,
		code: 'J02',
		label: 'Faringitis aguda',
	},
	{
		id: 4188,
		code: 'J020',
		label: 'Faringitis estreptocócica',
	},
	{
		id: 4189,
		code: 'J028',
		label: 'Faringitis aguda debida a otros microorganismos especificados',
	},
	{
		id: 4190,
		code: 'J029',
		label: 'Faringitis aguda, no especificada',
	},
	{
		id: 4191,
		code: 'J03',
		label: 'Amigdalitis aguda',
	},
	{
		id: 4192,
		code: 'J030',
		label: 'Amigdalitis estreptocócica',
	},
	{
		id: 4193,
		code: 'J038',
		label: 'Amigdalitis aguda debida a otros microorganismos especificados',
	},
	{
		id: 4194,
		code: 'J039',
		label: 'Amigdalitis aguda, no especificada',
	},
	{
		id: 4195,
		code: 'J04',
		label: 'Laringitis y traqueítis agudas',
	},
	{
		id: 4196,
		code: 'J040',
		label: 'Laringitis aguda',
	},
	{
		id: 4197,
		code: 'J041',
		label: 'Traqueítis aguda',
	},
	{
		id: 4198,
		code: 'J042',
		label: 'Laringotraqueítis aguda',
	},
	{
		id: 4199,
		code: 'J05',
		label: 'Laringitis obstructiva aguda [crup] y epiglotitis',
	},
	{
		id: 4200,
		code: 'J050',
		label: 'Laringitis obstructiva, aguda [crup]',
	},
	{
		id: 4201,
		code: 'J051',
		label: 'Epiglotitis aguda',
	},
	{
		id: 4202,
		code: 'J06',
		label: 'Infecciones agudas de las vías respiratorias superiores, de sitios múltiples o no especificados',
	},
	{
		id: 4203,
		code: 'J060',
		label: 'Laringofaringitis aguda',
	},
	{
		id: 4204,
		code: 'J068',
		label: 'Otras infecciones agudas de sitios múltiples de las vías respiratorias superiores',
	},
	{
		id: 4205,
		code: 'J069',
		label: 'Infección aguda de las vías respiratorias superiores, no especificada',
	},
	{
		id: 4206,
		code: 'J09X',
		label: 'Influenza debida a ciertos virus de la influenza identificados',
	},
	{
		id: 4207,
		code: 'J10',
		label: 'Influenza debida a otro virus de la influenza identificado',
	},
	{
		id: 4208,
		code: 'J100',
		label: 'Influenza con neumonía, debida a otro virus de la influenza identificado',
	},
	{
		id: 4209,
		code: 'J101',
		label: 'Influenza con otras manifestaciones respiratorias, debida a otro virus de la influenza identificado',
	},
	{
		id: 4210,
		code: 'J108',
		label: 'Influenza, con otras manifestaciones, debida a otro virus de la influenza identificado',
	},
	{
		id: 4211,
		code: 'J11',
		label: 'Influenza debida a virus no identificado',
	},
	{
		id: 4212,
		code: 'J110',
		label: 'Influenza con neumonía, virus no identificado',
	},
	{
		id: 4213,
		code: 'J111',
		label: 'Influenza con otras manifestaciones respiratorias, virus no identificado',
	},
	{
		id: 4214,
		code: 'J118',
		label: 'Influenza con otras manifestaciones, virus no identificado',
	},
	{
		id: 4215,
		code: 'J12',
		label: 'Neumonía viral, no clasificada en otra parte',
	},
	{
		id: 4216,
		code: 'J120',
		label: 'Neumonía debida a adenovirus',
	},
	{
		id: 4217,
		code: 'J121',
		label: 'Neumonía debida a virus sincitial respiratorio',
	},
	{
		id: 4218,
		code: 'J122',
		label: 'Neumonía debida a virus parainfluenza',
	},
	{
		id: 4219,
		code: 'J123',
		label: 'Neumonía debida a metaneumovirus humano ',
	},
	{
		id: 4220,
		code: 'J128',
		label: 'Neumonía debida a otros virus',
	},
	{
		id: 4221,
		code: 'J129',
		label: 'Neumonía viral, no especificada',
	},
	{
		id: 4222,
		code: 'J13X',
		label: 'Neumonía debida a Streptococcus pneumoniae',
	},
	{
		id: 4223,
		code: 'J14X',
		label: 'Neumonía debida a Haemophilus influenzae',
	},
	{
		id: 4224,
		code: 'J15',
		label: 'Neumonía bacteriana, no clasificada en otra parte',
	},
	{
		id: 4225,
		code: 'J150',
		label: 'Neumonía debida a Klebsiella pneumoniae',
	},
	{
		id: 4226,
		code: 'J151',
		label: 'Neumonía debida a pseudomonas',
	},
	{
		id: 4227,
		code: 'J152',
		label: 'Neumonía debida a estafilococos',
	},
	{
		id: 4228,
		code: 'J153',
		label: 'Neumonía debida a estreptococos del grupo B',
	},
	{
		id: 4229,
		code: 'J154',
		label: 'Neumonía debida a otros estreptococos',
	},
	{
		id: 4230,
		code: 'J155',
		label: 'Neumonía debida a Escherichia coli',
	},
	{
		id: 4231,
		code: 'J156',
		label: 'Neumonía debida a otras bacterias aeróbicas gramnegativas',
	},
	{
		id: 4232,
		code: 'J157',
		label: 'Neumonía debida a Mycoplasma pneumoniae',
	},
	{
		id: 4233,
		code: 'J158',
		label: 'Otras neumonías bacterianas',
	},
	{
		id: 4234,
		code: 'J159',
		label: 'Neumonía bacteriana, no especificada',
	},
	{
		id: 4235,
		code: 'J16',
		label: 'Neumonía debida a otros microorganismos infecciosos, no clasificados en otra parte',
	},
	{
		id: 4236,
		code: 'J160',
		label: 'Neumonía debida a clamidias',
	},
	{
		id: 4237,
		code: 'J168',
		label: 'Neumonía debida a otros microorganismos infecciosos especificados',
	},
	{
		id: 4238,
		code: 'J17',
		label: 'Neumonía en enfermedades clasificadas en otra parte',
	},
	{
		id: 4239,
		code: 'J170',
		label: 'Neumonía en enfermedades bacterianas clasificadas en otra parte',
	},
	{
		id: 4240,
		code: 'J171',
		label: 'Neumonía en enfermedades virales clasificadas en otra parte',
	},
	{
		id: 4241,
		code: 'J172',
		label: 'Neumonía en micosis',
	},
	{
		id: 4242,
		code: 'J173',
		label: 'Neumonía en enfermedades parasitarias',
	},
	{
		id: 4243,
		code: 'J178',
		label: 'Neumonía en otras enfermedades clasificadas en otra parte',
	},
	{
		id: 4244,
		code: 'J18',
		label: 'Neumonía, organismo no especificado',
	},
	{
		id: 4245,
		code: 'J180',
		label: 'Bronconeumonía, no especificada',
	},
	{
		id: 4246,
		code: 'J181',
		label: 'Neumonía lobar, no especificada',
	},
	{
		id: 4247,
		code: 'J182',
		label: 'Neumonía hipostática, no especificada',
	},
	{
		id: 4248,
		code: 'J188',
		label: 'Otras neumonías, de microorganismo no especificado',
	},
	{
		id: 4249,
		code: 'J189',
		label: 'Neumonía, no especificada',
	},
	{
		id: 4250,
		code: 'J20',
		label: 'Bronquitis aguda',
	},
	{
		id: 4251,
		code: 'J200',
		label: 'Bronquitis aguda debida a Mycoplasma pneumoniae',
	},
	{
		id: 4252,
		code: 'J201',
		label: 'Bronquitis aguda debida a Haemophilus influenzae',
	},
	{
		id: 4253,
		code: 'J202',
		label: 'Bronquitis aguda debida a estreptococos',
	},
	{
		id: 4254,
		code: 'J203',
		label: 'Bronquitis aguda debida a virus Coxsackie',
	},
	{
		id: 4255,
		code: 'J204',
		label: 'Bronquitis aguda debida a virus parainfluenza',
	},
	{
		id: 4256,
		code: 'J205',
		label: 'Bronquitis aguda debida a virus sincitial respiratorio',
	},
	{
		id: 4257,
		code: 'J206',
		label: 'Bronquitis aguda debida a rinovirus',
	},
	{
		id: 4258,
		code: 'J207',
		label: 'Bronquitis aguda debida a virus ECHO',
	},
	{
		id: 4259,
		code: 'J208',
		label: 'Bronquitis aguda debida a otros microorganismos especificados',
	},
	{
		id: 4260,
		code: 'J209',
		label: 'Bronquitis aguda, no especificada',
	},
	{
		id: 4261,
		code: 'J21',
		label: 'Bronquiolitis aguda',
	},
	{
		id: 4262,
		code: 'J210',
		label: 'Bronquiolitis aguda debida a virus sincitial respiratorio',
	},
	{
		id: 4263,
		code: 'J211',
		label: 'Bronquiolitis aguda debida a metaneumovirus humano',
	},
	{
		id: 4264,
		code: 'J218',
		label: 'Bronquiolitis aguda debida a otros microorganismos especificados',
	},
	{
		id: 4265,
		code: 'J219',
		label: 'Bronquiolitis aguda, no especificada',
	},
	{
		id: 4266,
		code: 'J22X',
		label: 'Infección aguda no especificada de las vías respiratorias inferiores',
	},
	{
		id: 4267,
		code: 'J30',
		label: 'Rinitis alérgica y vasomotora',
	},
	{
		id: 4268,
		code: 'J300',
		label: 'Rinitis vasomotora',
	},
	{
		id: 4269,
		code: 'J301',
		label: 'Rinitis alérgica debida al polen',
	},
	{
		id: 4270,
		code: 'J302',
		label: 'Otra rinitis alérgica estacional',
	},
	{
		id: 4271,
		code: 'J303',
		label: 'Otras rinitis alérgicas',
	},
	{
		id: 4272,
		code: 'J304',
		label: 'Rinitis alérgica, no especificada',
	},
	{
		id: 4273,
		code: 'J31',
		label: 'Rinitis, rinofaringitis y faringitis crónicas',
	},
	{
		id: 4274,
		code: 'J310',
		label: 'Rinitis crónica',
	},
	{
		id: 4275,
		code: 'J311',
		label: 'Rinofaringitis crónica',
	},
	{
		id: 4276,
		code: 'J312',
		label: 'Faringitis crónica',
	},
	{
		id: 4277,
		code: 'J32',
		label: 'Sinusitis crónica',
	},
	{
		id: 4278,
		code: 'J320',
		label: 'Sinusitis maxilar crónica',
	},
	{
		id: 4279,
		code: 'J321',
		label: 'Sinusitis frontal crónica',
	},
	{
		id: 4280,
		code: 'J322',
		label: 'Sinusitis etmoidal crónica',
	},
	{
		id: 4281,
		code: 'J323',
		label: 'Sinusitis esfenoidal crónica',
	},
	{
		id: 4282,
		code: 'J324',
		label: 'Pansinusitis crónica',
	},
	{
		id: 4283,
		code: 'J328',
		label: 'Otras sinusitis crónicas',
	},
	{
		id: 4284,
		code: 'J329',
		label: 'Sinusitis crónica, no especificada',
	},
	{
		id: 4285,
		code: 'J33',
		label: 'Pólipo nasal',
	},
	{
		id: 4286,
		code: 'J330',
		label: 'Pólipo de la cavidad nasal',
	},
	{
		id: 4287,
		code: 'J331',
		label: 'Degeneración polipoide de seno paranasal',
	},
	{
		id: 4288,
		code: 'J338',
		label: 'Otros pólipos de los senos paranasales',
	},
	{
		id: 4289,
		code: 'J339',
		label: 'Pólipo nasal, no especificado',
	},
	{
		id: 4290,
		code: 'J34',
		label: 'Otros trastornos de la nariz y de los senos paranasales',
	},
	{
		id: 4291,
		code: 'J340',
		label: 'Absceso, furúnculo y carbunco de la nariz',
	},
	{
		id: 4292,
		code: 'J341',
		label: 'Quiste y mucocele de la nariz y del seno paranasal',
	},
	{
		id: 4293,
		code: 'J342',
		label: 'Desviación del tabique nasal',
	},
	{
		id: 4294,
		code: 'J343',
		label: 'Hipertrofia de los cornetes nasales',
	},
	{
		id: 4295,
		code: 'J348',
		label: 'Otros trastornos especificados de la nariz y de los senos paranasales',
	},
	{
		id: 4296,
		code: 'J35',
		label: 'Enfermedades crónicas de las amígdalas y de las adenoides',
	},
	{
		id: 4297,
		code: 'J350',
		label: 'Amigdalitis crónica',
	},
	{
		id: 4298,
		code: 'J351',
		label: 'Hipertrofia de las amígdalas',
	},
	{
		id: 4299,
		code: 'J352',
		label: 'Hipertrofia de las adenoides',
	},
	{
		id: 4300,
		code: 'J353',
		label: 'Hipertrofia de las amígdalas con hipertrofia de las adenoides',
	},
	{
		id: 4301,
		code: 'J358',
		label: 'Otras enfermedades crónicas de las amígdalas y de las adenoides',
	},
	{
		id: 4302,
		code: 'J359',
		label: 'Enfermedad crónica de las amígdalas y de las adenoides, no especificada',
	},
	{
		id: 4303,
		code: 'J36X',
		label: 'Absceso periamigdalino',
	},
	{
		id: 4304,
		code: 'J37',
		label: 'Laringitis y laringotraqueítis crónicas',
	},
	{
		id: 4305,
		code: 'J370',
		label: 'Laringitis crónica',
	},
	{
		id: 4306,
		code: 'J371',
		label: 'Laringotraqueítis crónica',
	},
	{
		id: 4307,
		code: 'J38',
		label: 'Enfermedades de las cuerdas vocales y de la laringe, no clasificadas en otra parte',
	},
	{
		id: 4308,
		code: 'J380',
		label: 'Parálisis de las cuerdas vocales y de la laringe',
	},
	{
		id: 4309,
		code: 'J381',
		label: 'Pólipo de las cuerdas vocales y de la laringe',
	},
	{
		id: 4310,
		code: 'J382',
		label: 'Nódulos de las cuerdas vocales',
	},
	{
		id: 4311,
		code: 'J383',
		label: 'Otras enfermedades de las cuerdas vocales',
	},
	{
		id: 4312,
		code: 'J384',
		label: 'Edema de laringe',
	},
	{
		id: 4313,
		code: 'J385',
		label: 'Espasmo laríngeo',
	},
	{
		id: 4314,
		code: 'J386',
		label: 'Estenosis laríngea',
	},
	{
		id: 4315,
		code: 'J387',
		label: 'Otras enfermedades de la laringe',
	},
	{
		id: 4316,
		code: 'J39',
		label: 'Otras enfermedades de las vías respiratorias superiores',
	},
	{
		id: 4317,
		code: 'J390',
		label: 'Absceso retrofaríngeo y parafaríngeo',
	},
	{
		id: 4318,
		code: 'J391',
		label: 'Otros abscesos de la faringe',
	},
	{
		id: 4319,
		code: 'J392',
		label: 'Otras enfermedades de la faringe',
	},
	{
		id: 4320,
		code: 'J393',
		label: 'Reacción de hipersensibilidad de las vías respiratorias superiores, sitio no especificado',
	},
	{
		id: 4321,
		code: 'J398',
		label: 'Otras enfermedades especificadas de las vías respiratorias superiores',
	},
	{
		id: 4322,
		code: 'J399',
		label: 'Enfermedad de las vías respiratorias superiores, no especificada',
	},
	{
		id: 4323,
		code: 'J40X',
		label: 'Bronquitis, no especificada como aguda o crónica',
	},
	{
		id: 4324,
		code: 'J41',
		label: 'Bronquitis crónica simple y mucopurulenta',
	},
	{
		id: 4325,
		code: 'J410',
		label: 'Bronquitis crónica simple',
	},
	{
		id: 4326,
		code: 'J411',
		label: 'Bronquitis crónica mucopurulenta',
	},
	{
		id: 4327,
		code: 'J418',
		label: 'Bronquitis crónica mixta simple y mucopurulenta',
	},
	{
		id: 4328,
		code: 'J42X',
		label: 'Bronquitis crónica no especificada',
	},
	{
		id: 4329,
		code: 'J43',
		label: 'Enfisema',
	},
	{
		id: 4330,
		code: 'J430',
		label: 'Síndrome de MacLeod',
	},
	{
		id: 4331,
		code: 'J431',
		label: 'Enfisema panlobular',
	},
	{
		id: 4332,
		code: 'J432',
		label: 'Enfisema centrolobular',
	},
	{
		id: 4333,
		code: 'J438',
		label: 'Otros tipos de enfisema',
	},
	{
		id: 4334,
		code: 'J439',
		label: 'Enfisema, no especificado',
	},
	{
		id: 4335,
		code: 'J44',
		label: 'Otras enfermedades pulmonares obstructivas crónicas',
	},
	{
		id: 4336,
		code: 'J440',
		label: 'Enfermedad pulmonar obstructiva crónica con infección aguda de las vías respiratorias inferiores',
	},
	{
		id: 4337,
		code: 'J441',
		label: 'Enfermedad pulmonar obstructiva crónica con exacerbación aguda, no especificada',
	},
	{
		id: 4338,
		code: 'J448',
		label: 'Otras enfermedades pulmonares obstructivas crónicas especificadas',
	},
	{
		id: 4339,
		code: 'J449',
		label: 'Enfermedad pulmonar obstructiva crónica, no especificada',
	},
	{
		id: 4340,
		code: 'J45',
		label: 'Asma',
	},
	{
		id: 4341,
		code: 'J450',
		label: 'Asma predominantemente alérgica',
	},
	{
		id: 4342,
		code: 'J451',
		label: 'Asma no alérgica',
	},
	{
		id: 4343,
		code: 'J458',
		label: 'Asma mixta',
	},
	{
		id: 4344,
		code: 'J459',
		label: 'Asma, no especificado',
	},
	{
		id: 4345,
		code: 'J46X',
		label: 'Estado asmático',
	},
	{
		id: 4346,
		code: 'J47X',
		label: 'Bronquiectasia',
	},
	{
		id: 4347,
		code: 'J60X',
		label: 'Neumoconiosis de los mineros del carbón',
	},
	{
		id: 4348,
		code: 'J61X',
		label: 'Neumoconiosis debida al asbesto y a otras fibras minerales',
	},
	{
		id: 4349,
		code: 'J62',
		label: 'Neumoconiosis debida a polvo de sílice',
	},
	{
		id: 4350,
		code: 'J620',
		label: 'Neumoconiosis debida a polvo de talco',
	},
	{
		id: 4351,
		code: 'J628',
		label: 'Neumoconiosis debida a otros polvos que contienen sílice',
	},
	{
		id: 4352,
		code: 'J63',
		label: 'Neumoconiosis debida a otros polvos inorgánicos',
	},
	{
		id: 4353,
		code: 'J630',
		label: 'Aluminosis (del pulmón)',
	},
	{
		id: 4354,
		code: 'J631',
		label: 'Fibrosis (del pulmón) debida a bauxita',
	},
	{
		id: 4355,
		code: 'J632',
		label: 'Beriliosis',
	},
	{
		id: 4356,
		code: 'J633',
		label: 'Fibrosis (del pulmón) debida a grafito',
	},
	{
		id: 4357,
		code: 'J634',
		label: 'Siderosis',
	},
	{
		id: 4358,
		code: 'J635',
		label: 'Estañosis',
	},
	{
		id: 4359,
		code: 'J638',
		label: 'Neumoconiosis debida a otros polvos inorgánicos especificados',
	},
	{
		id: 4360,
		code: 'J64X',
		label: 'Neumoconiosis, no especificada',
	},
	{
		id: 4361,
		code: 'J65X',
		label: 'Neumoconiosis asociada con tuberculosis',
	},
	{
		id: 4362,
		code: 'J66',
		label: 'Enfermedades de las vías aéreas debidas a polvos orgánicos específicos',
	},
	{
		id: 4363,
		code: 'J660',
		label: 'Bisinosis',
	},
	{
		id: 4364,
		code: 'J661',
		label: 'Enfermedad de los trabajadores del lino',
	},
	{
		id: 4365,
		code: 'J662',
		label: 'Canabinosis',
	},
	{
		id: 4366,
		code: 'J668',
		label: 'Enfermedad de las vías aéreas debida a otros polvos orgánicos específicos',
	},
	{
		id: 4367,
		code: 'J67',
		label: 'Neumonitis debida a hipersensibilidad al polvo orgánico',
	},
	{
		id: 4368,
		code: 'J670',
		label: 'Pulmón del granjero',
	},
	{
		id: 4369,
		code: 'J671',
		label: 'Bagazosis',
	},
	{
		id: 4370,
		code: 'J672',
		label: 'Pulmón del ornitófilo',
	},
	{
		id: 4371,
		code: 'J673',
		label: 'Suberosis',
	},
	{
		id: 4372,
		code: 'J674',
		label: 'Pulmón del manipulador de malta',
	},
	{
		id: 4373,
		code: 'J675',
		label: 'Pulmón del manipulador de hongos',
	},
	{
		id: 4374,
		code: 'J676',
		label: 'Pulmón del descortezador del arce',
	},
	{
		id: 4375,
		code: 'J677',
		label: 'Neumonitis de la ventilación debida al acondicionador y humidificador del aire',
	},
	{
		id: 4376,
		code: 'J678',
		label: 'Neumonitis debidas a hipersensibilidad a otros polvos orgánicos',
	},
	{
		id: 4377,
		code: 'J679',
		label: 'Neumonitis debida a hipersensibilidad a polvo orgánico no especificado',
	},
	{
		id: 4378,
		code: 'J68',
		label: 'Afecciones respiratorias debidas a inhalación de gases, humos, vapores y sustancias químicas',
	},
	{
		id: 4379,
		code: 'J680',
		label: 'Bronquitis y neumonitis debidas a inhalación de gases, humos, vapores y sustancias químicas',
	},
	{
		id: 4380,
		code: 'J681',
		label: 'Edema pulmonar agudo debido a inhalación de gases, humos, vapores y sustancias químicas',
	},
	{
		id: 4381,
		code: 'J682',
		label: 'Inflamación respiratoria superior debida a inhalación de gases, humos, vapores y sustancias químicas, no clasificadas en otra parte',
	},
	{
		id: 4382,
		code: 'J683',
		label: 'Otras afecciones respiratorias agudas y subagudas debidas a inhalación de gases, humos, vapores y sustancias químicas',
	},
	{
		id: 4383,
		code: 'J684',
		label: 'Afecciones respiratorias crónicas debidas a inhalación de gases, humos, vapores y sustancias químicas',
	},
	{
		id: 4384,
		code: 'J688',
		label: 'Otras afecciones respiratorias debidas a inhalación de gases, humos, vapores y sustancias químicas',
	},
	{
		id: 4385,
		code: 'J689',
		label: 'Afección respiratoria no especificada, debida a inhalación de gases, humos, vapores y sustancias químicas',
	},
	{
		id: 4386,
		code: 'J69',
		label: 'Neumonitis debida a sólidos y líquidos',
	},
	{
		id: 4387,
		code: 'J690',
		label: 'Neumonitis debida a aspiración de alimento o vómito',
	},
	{
		id: 4388,
		code: 'J691',
		label: 'Neumonitis debida a aspiración de aceites y esencias',
	},
	{
		id: 4389,
		code: 'J698',
		label: 'Neumonitis debida a aspiración de otros sólidos y líquidos',
	},
	{
		id: 4390,
		code: 'J70',
		label: 'Afecciones respiratorias debidas a otros agentes externos',
	},
	{
		id: 4391,
		code: 'J700',
		label: 'Manifestaciones pulmonares agudas debidas a radiación',
	},
	{
		id: 4392,
		code: 'J701',
		label: 'Manifestaciones pulmonares crónicas y otras manifestaciones debidas a radiación',
	},
	{
		id: 4393,
		code: 'J702',
		label: 'Trastornos pulmonares intersticiales agudos inducidos por drogas',
	},
	{
		id: 4394,
		code: 'J703',
		label: 'Trastornos pulmonares intersticiales crónicos inducidos por drogas',
	},
	{
		id: 4395,
		code: 'J704',
		label: 'Trastornos pulmonares intersticiales no especificados inducidos por drogas',
	},
	{
		id: 4396,
		code: 'J708',
		label: 'Afecciones respiratorias debidas a otros agentes externos especificados',
	},
	{
		id: 4397,
		code: 'J709',
		label: 'Afecciones respiratorias debidas a agentes externos no especificados',
	},
	{
		id: 4398,
		code: 'J80X',
		label: 'Síndrome de dificultad respiratoria del adulto',
	},
	{
		id: 4399,
		code: 'J81X',
		label: 'Edema pulmonar',
	},
	{
		id: 4400,
		code: 'J82X',
		label: 'Eosinofilia pulmonar, no clasificada en otra parte',
	},
	{
		id: 4401,
		code: 'J84',
		label: 'Otras enfermedades pulmonares intersticiales',
	},
	{
		id: 4402,
		code: 'J840',
		label: 'Afecciones alveolares y alveoloparietales',
	},
	{
		id: 4403,
		code: 'J841',
		label: 'Otras enfermedades pulmonares intersticiales con fibrosis',
	},
	{
		id: 4404,
		code: 'J848',
		label: 'Otras enfermedades pulmonares intersticiales especificadas',
	},
	{
		id: 4405,
		code: 'J849',
		label: 'Enfermedad pulmonar intersticial, no especificada',
	},
	{
		id: 4406,
		code: 'J85',
		label: 'Absceso del pulmón y del mediastino',
	},
	{
		id: 4407,
		code: 'J850',
		label: 'Gangrena y necrosis del pulmón',
	},
	{
		id: 4408,
		code: 'J851',
		label: 'Absceso del pulmón con neumonía',
	},
	{
		id: 4409,
		code: 'J852',
		label: 'Absceso del pulmón sin neumonía',
	},
	{
		id: 4410,
		code: 'J853',
		label: 'Absceso del mediastino',
	},
	{
		id: 4411,
		code: 'J86',
		label: 'Piotórax',
	},
	{
		id: 4412,
		code: 'J860',
		label: 'Piotórax con fístula',
	},
	{
		id: 4413,
		code: 'J869',
		label: 'Piotórax sin fístula',
	},
	{
		id: 4414,
		code: 'J90X',
		label: 'Derrame pleural no clasificado en otra parte',
	},
	{
		id: 4415,
		code: 'J91X',
		label: 'Derrame pleural en afecciones clasificadas en otra parte',
	},
	{
		id: 4416,
		code: 'J92',
		label: 'Paquipleuritis',
	},
	{
		id: 4417,
		code: 'J920',
		label: 'Paquipleuritis con asbestosis',
	},
	{
		id: 4418,
		code: 'J929',
		label: 'Paquipleuritis sin asbestosis',
	},
	{
		id: 4419,
		code: 'J93',
		label: 'Neumotórax',
	},
	{
		id: 4420,
		code: 'J930',
		label: 'Neumótorax espontáneo a presión',
	},
	{
		id: 4421,
		code: 'J931',
		label: 'Otros tipos de neumotórax espontáneo',
	},
	{
		id: 4422,
		code: 'J938',
		label: 'Otros neumotórax',
	},
	{
		id: 4423,
		code: 'J939',
		label: 'Neumotórax, no especificado',
	},
	{
		id: 4424,
		code: 'J94',
		label: 'Otras afecciones de la pleura',
	},
	{
		id: 4425,
		code: 'J940',
		label: 'Quilotórax',
	},
	{
		id: 4426,
		code: 'J941',
		label: 'Fibrotórax',
	},
	{
		id: 4427,
		code: 'J942',
		label: 'Hemotórax',
	},
	{
		id: 4428,
		code: 'J948',
		label: 'Otras afecciones especificadas de la pleura',
	},
	{
		id: 4429,
		code: 'J949',
		label: 'Afección pleural, no especificada',
	},
	{
		id: 4430,
		code: 'J95',
		label: 'Trastornos del sistema respiratorio consecutivos a procedimientos, no clasificados en otra parte',
	},
	{
		id: 4431,
		code: 'J950',
		label: 'Funcionamiento defectuoso de la traqueostomía',
	},
	{
		id: 4432,
		code: 'J951',
		label: 'Insuficiencia pulmonar aguda consecutiva a cirugía torácica',
	},
	{
		id: 4433,
		code: 'J952',
		label: 'Insuficiencia pulmonar aguda consecutiva debida a cirugía extratorácica',
	},
	{
		id: 4434,
		code: 'J953',
		label: 'Insuficiencia pulmonar crónica consecutiva a cirugía',
	},
	{
		id: 4435,
		code: 'J954',
		label: 'Síndrome de Mendelson',
	},
	{
		id: 4436,
		code: 'J955',
		label: 'Estenosis subglótica consecutiva a procedimientos',
	},
	{
		id: 4437,
		code: 'J958',
		label: 'Otros trastornos respiratorios consecutivos a procedimientos',
	},
	{
		id: 4438,
		code: 'J959',
		label: 'Trastorno no especificado del sistema respiratorio, consecutivo a procedimientos',
	},
	{
		id: 4439,
		code: 'J96',
		label: 'Insuficiencia respiratoria, no clasificada en otra parte',
	},
	{
		id: 4440,
		code: 'J960',
		label: 'Insuficiencia respiratoria aguda',
	},
	{
		id: 4441,
		code: 'J961',
		label: 'Insuficiencia respiratoria crónica',
	},
	{
		id: 4442,
		code: 'J969',
		label: 'Insuficiencia respiratoria, no especificada',
	},
	{
		id: 4443,
		code: 'J98',
		label: 'Otros trastornos respiratorios',
	},
	{
		id: 4444,
		code: 'J980',
		label: 'Enfermedades de la tráquea y de los bronquios, no clasificadas en otra parte',
	},
	{
		id: 4445,
		code: 'J981',
		label: 'Colapso pulmonar',
	},
	{
		id: 4446,
		code: 'J982',
		label: 'Enfisema intersticial',
	},
	{
		id: 4447,
		code: 'J983',
		label: 'Enfisema compensatorio',
	},
	{
		id: 4448,
		code: 'J984',
		label: 'Otros trastornos del pulmón',
	},
	{
		id: 4449,
		code: 'J985',
		label: 'Enfermedades del mediastino, no clasificadas en otra parte',
	},
	{
		id: 4450,
		code: 'J986',
		label: 'Trastornos del diafragma',
	},
	{
		id: 4451,
		code: 'J988',
		label: 'Otros trastornos respiratorios especificados',
	},
	{
		id: 4452,
		code: 'J989',
		label: 'Trastorno respiratorio, no especificado',
	},
	{
		id: 4453,
		code: 'J99',
		label: 'Trastornos respiratorios en enfermedades clasificadas en otra parte',
	},
	{
		id: 4454,
		code: 'J990',
		label: 'Enfermedad pulmonar reumatoide',
	},
	{
		id: 4455,
		code: 'J991',
		label: 'Trastornos respiratorios en otros trastornos difusos del tejido conjuntivo',
	},
	{
		id: 4456,
		code: 'J998',
		label: 'Trastornos respiratorios en otras enfermedades clasificadas en otra parte',
	},
	{
		id: 4457,
		code: 'K00',
		label: 'Trastornos del desarrollo y de la erupción de los dientes',
	},
	{
		id: 4458,
		code: 'K000',
		label: 'Anodoncia',
	},
	{
		id: 4459,
		code: 'K001',
		label: 'Dientes supernumerarios',
	},
	{
		id: 4460,
		code: 'K002',
		label: 'Anomalías del tamaño y de la forma del diente',
	},
	{
		id: 4461,
		code: 'K003',
		label: 'Dientes moteados',
	},
	{
		id: 4462,
		code: 'K004',
		label: 'Alteraciones en la formación dentaria',
	},
	{
		id: 4463,
		code: 'K005',
		label: 'Alteraciones hereditarias de la estructura dentaria, no clasificadas en otra parte',
	},
	{
		id: 4464,
		code: 'K006',
		label: 'Alteraciones en la erupción dentaria',
	},
	{
		id: 4465,
		code: 'K007',
		label: 'Síndrome de la erupción dentaria',
	},
	{
		id: 4466,
		code: 'K008',
		label: 'Otros trastornos del desarrollo de los dientes',
	},
	{
		id: 4467,
		code: 'K009',
		label: 'Trastorno del desarrollo de los dientes, no especificado',
	},
	{
		id: 4468,
		code: 'K01',
		label: 'Dientes incluidos e impactados',
	},
	{
		id: 4469,
		code: 'K010',
		label: 'Dientes incluidos',
	},
	{
		id: 4470,
		code: 'K011',
		label: 'Dientes impactados',
	},
	{
		id: 4471,
		code: 'K02',
		label: 'Caries dental',
	},
	{
		id: 4472,
		code: 'K020',
		label: 'Caries limitada al esmalte',
	},
	{
		id: 4473,
		code: 'K021',
		label: 'Caries de la dentina',
	},
	{
		id: 4474,
		code: 'K022',
		label: 'Caries del cemento',
	},
	{
		id: 4475,
		code: 'K023',
		label: 'Caries dentaria detenida',
	},
	{
		id: 4476,
		code: 'K024',
		label: 'Odontoclasia',
	},
	{
		id: 4477,
		code: 'K025',
		label: 'Caries con exposición pulpar',
	},
	{
		id: 4478,
		code: 'K028',
		label: 'Otras caries dentales',
	},
	{
		id: 4479,
		code: 'K029',
		label: 'Caries dental, no especificada',
	},
	{
		id: 4480,
		code: 'K03',
		label: 'Otras enfermedades de los tejidos duros de los dientes',
	},
	{
		id: 4481,
		code: 'K030',
		label: 'Atrición excesiva de los dientes',
	},
	{
		id: 4482,
		code: 'K031',
		label: 'Abrasión de los dientes',
	},
	{
		id: 4483,
		code: 'K032',
		label: 'Erosión de los dientes',
	},
	{
		id: 4484,
		code: 'K033',
		label: 'Reabsorción patológica de los dientes',
	},
	{
		id: 4485,
		code: 'K034',
		label: 'Hipercementosis',
	},
	{
		id: 4486,
		code: 'K035',
		label: 'Anquilosis dental',
	},
	{
		id: 4487,
		code: 'K036',
		label: 'Depósitos [acreciones] en los dientes',
	},
	{
		id: 4488,
		code: 'K037',
		label: 'Cambios posteruptivos del color de los tejidos dentales duros',
	},
	{
		id: 4489,
		code: 'K038',
		label: 'Otras enfermedades especificadas de los tejidos duros de los dientes',
	},
	{
		id: 4490,
		code: 'K039',
		label: 'Enfermedad no especificada de los tejidos dentales duros',
	},
	{
		id: 4491,
		code: 'K04',
		label: 'Enfermedades de la pulpa y de los tejidos periapicales',
	},
	{
		id: 4492,
		code: 'K040',
		label: 'Pulpitis',
	},
	{
		id: 4493,
		code: 'K041',
		label: 'Necrosis de la pulpa',
	},
	{
		id: 4494,
		code: 'K042',
		label: 'Degeneración de la pulpa',
	},
	{
		id: 4495,
		code: 'K043',
		label: 'Formación anormal de tejido duro en la pulpa',
	},
	{
		id: 4496,
		code: 'K044',
		label: 'Periodontitis apical aguda originada en la pulpa',
	},
	{
		id: 4497,
		code: 'K045',
		label: 'Periodontitis apical crónica',
	},
	{
		id: 4498,
		code: 'K046',
		label: 'Absceso periapical con fístula',
	},
	{
		id: 4499,
		code: 'K047',
		label: 'Absceso periapical sin fístula',
	},
	{
		id: 4500,
		code: 'K048',
		label: 'Quiste radicular',
	},
	{
		id: 4501,
		code: 'K049',
		label: 'Otras enfermedades y las no especificadas de la pulpa y del tejido periapical',
	},
	{
		id: 4502,
		code: 'K05',
		label: 'Gingivitis y enfermedades periodontales',
	},
	{
		id: 4503,
		code: 'K050',
		label: 'Gingivitis aguda',
	},
	{
		id: 4504,
		code: 'K051',
		label: 'Gingivitis crónica',
	},
	{
		id: 4505,
		code: 'K052',
		label: 'Periodontitis aguda',
	},
	{
		id: 4506,
		code: 'K053',
		label: 'Periodontitis crónica',
	},
	{
		id: 4507,
		code: 'K054',
		label: 'Periodontosis',
	},
	{
		id: 4508,
		code: 'K055',
		label: 'Otras enfermedades periodontales',
	},
	{
		id: 4509,
		code: 'K056',
		label: 'Enfermedad del periodonto, no especificada',
	},
	{
		id: 4510,
		code: 'K06',
		label: 'Otros trastornos de la encía y de la zona edéntula',
	},
	{
		id: 4511,
		code: 'K060',
		label: 'Retracción gingival',
	},
	{
		id: 4512,
		code: 'K061',
		label: 'Hiperplasia gingival',
	},
	{
		id: 4513,
		code: 'K062',
		label: 'Lesiones de la encía y de la zona edéntula asociadas con traumatismo',
	},
	{
		id: 4514,
		code: 'K068',
		label: 'Otros trastornos especificados de la encía y de la zona edéntula',
	},
	{
		id: 4515,
		code: 'K069',
		label: 'Trastorno no especificado de la encía y de la zona edéntula',
	},
	{
		id: 4516,
		code: 'K07',
		label: 'Anomalías dentofaciales (incluso la maloclusión)',
	},
	{
		id: 4517,
		code: 'K070',
		label: 'Anomalías evidentes del tamaño de los maxilares',
	},
	{
		id: 4518,
		code: 'K071',
		label: 'Anomalías de la relación maxilobasilar',
	},
	{
		id: 4519,
		code: 'K072',
		label: 'Anomalías de la relación entre los arcos dentarios',
	},
	{
		id: 4520,
		code: 'K073',
		label: 'Anomalías de la posición del diente',
	},
	{
		id: 4521,
		code: 'K074',
		label: 'Maloclusión de tipo no especificado',
	},
	{
		id: 4522,
		code: 'K075',
		label: 'Anomalías dentofaciales funcionales',
	},
	{
		id: 4523,
		code: 'K076',
		label: 'Trastornos de la articulación temporomaxilar',
	},
	{
		id: 4524,
		code: 'K078',
		label: 'Otras anomalías dentofaciales',
	},
	{
		id: 4525,
		code: 'K079',
		label: 'Anomalía dentofacial, no especificada',
	},
	{
		id: 4526,
		code: 'K08',
		label: 'Otros trastornos de los dientes y de sus estructuras de sostén',
	},
	{
		id: 4527,
		code: 'K080',
		label: 'Exfoliación de los dientes debida a causas sistémicas',
	},
	{
		id: 4528,
		code: 'K081',
		label: 'Perdida de dientes debida a accidente, extracción o enfermedad periodontal local',
	},
	{
		id: 4529,
		code: 'K082',
		label: 'Atrofia del reborde alveolar desdentado',
	},
	{
		id: 4530,
		code: 'K083',
		label: 'Raíz dental retenida',
	},
	{
		id: 4531,
		code: 'K088',
		label: 'Otras afecciones especificadas de los dientes y de sus estructuras de sostén',
	},
	{
		id: 4532,
		code: 'K089',
		label: 'Trastorno de los dientes y de sus estruturas de sostén, no especificado',
	},
	{
		id: 4533,
		code: 'K09',
		label: 'Quites de la región bucal, no clasificados en otra parte',
	},
	{
		id: 4534,
		code: 'K090',
		label: 'Quistes originados por el desarrollo de los dientes',
	},
	{
		id: 4535,
		code: 'K091',
		label: 'Quistes de las fisuras de la región oral (no odontogénicos)',
	},
	{
		id: 4536,
		code: 'K092',
		label: 'Otros quistes de los maxilares',
	},
	{
		id: 4537,
		code: 'K098',
		label: 'Otros quistes de la región bucal, no clasificados en otra parte',
	},
	{
		id: 4538,
		code: 'K099',
		label: 'Quiste de la región bucal, sin otra especificación',
	},
	{
		id: 4539,
		code: 'K10',
		label: 'Otras enfermedades de los maxilares',
	},
	{
		id: 4540,
		code: 'K100',
		label: 'Trastornos del desarrollo de los maxilares',
	},
	{
		id: 4541,
		code: 'K101',
		label: 'Granuloma central de células gigantes',
	},
	{
		id: 4542,
		code: 'K102',
		label: 'Afecciones inflamatorias de los maxilares',
	},
	{
		id: 4543,
		code: 'K103',
		label: 'Alveolitis del maxilar',
	},
	{
		id: 4544,
		code: 'K108',
		label: 'Otras enfermedades especificadas de los maxilares',
	},
	{
		id: 4545,
		code: 'K109',
		label: 'Enfermedad de los maxilares, no especificada',
	},
	{
		id: 4546,
		code: 'K11',
		label: 'Enfermedades de las glándulas salivales',
	},
	{
		id: 4547,
		code: 'K110',
		label: 'Atrofia de glándula salival',
	},
	{
		id: 4548,
		code: 'K111',
		label: 'Hipertrofia de glándula salival',
	},
	{
		id: 4549,
		code: 'K112',
		label: 'Sialadenitis',
	},
	{
		id: 4550,
		code: 'K113',
		label: 'Absceso de glándula salival',
	},
	{
		id: 4551,
		code: 'K114',
		label: 'Fístula de glándula salival',
	},
	{
		id: 4552,
		code: 'K115',
		label: 'Sialolitiasis',
	},
	{
		id: 4553,
		code: 'K116',
		label: 'Mucocele de glándula salival',
	},
	{
		id: 4554,
		code: 'K117',
		label: 'Alteraciones de la secreción salival',
	},
	{
		id: 4555,
		code: 'K118',
		label: 'Otras enfermedades de las glándulas salivales',
	},
	{
		id: 4556,
		code: 'K119',
		label: 'Enfermedad de glándula salival, no especificada',
	},
	{
		id: 4557,
		code: 'K12',
		label: 'Estomatitis y lesiones afines',
	},
	{
		id: 4558,
		code: 'K120',
		label: 'Estomatitis aftosa recurrente',
	},
	{
		id: 4559,
		code: 'K121',
		label: 'Otras formas de estomatitis',
	},
	{
		id: 4560,
		code: 'K122',
		label: 'Celulitis y absceso de boca',
	},
	{
		id: 4561,
		code: 'K123',
		label: 'Mucositis oral (ulcerativa) ',
	},
	{
		id: 4562,
		code: 'K13',
		label: 'Otras enfermedades de los labios y de la mucosa bucal',
	},
	{
		id: 4563,
		code: 'K130',
		label: 'Enfermedades de los labios',
	},
	{
		id: 4564,
		code: 'K131',
		label: 'Mordedura del labio y de la mejilla',
	},
	{
		id: 4565,
		code: 'K132',
		label: 'Leucoplasia y otras alteraciones del epitelio bucal, incluyendo la lengua',
	},
	{
		id: 4566,
		code: 'K133',
		label: 'Leucoplasia pilosa',
	},
	{
		id: 4567,
		code: 'K134',
		label: 'Granuloma y lesiones semejantes de la mucosa bucal',
	},
	{
		id: 4568,
		code: 'K135',
		label: 'Fibrosis de la submucosa bucal',
	},
	{
		id: 4569,
		code: 'K136',
		label: 'Hiperplasia irritativa de la mucosa bucal',
	},
	{
		id: 4570,
		code: 'K137',
		label: 'Otras lesiones y las no especificadas de la mucosa bucal',
	},
	{
		id: 4571,
		code: 'K14',
		label: 'Enfermedades de la lengua',
	},
	{
		id: 4572,
		code: 'K140',
		label: 'Glositis',
	},
	{
		id: 4573,
		code: 'K141',
		label: 'Lengua geográfica',
	},
	{
		id: 4574,
		code: 'K142',
		label: 'Glositis romboidea mediana',
	},
	{
		id: 4575,
		code: 'K143',
		label: 'Hipertrofia de las papilas linguales',
	},
	{
		id: 4576,
		code: 'K144',
		label: 'Atrofia de las papilas linguales',
	},
	{
		id: 4577,
		code: 'K145',
		label: 'Lengua plegada',
	},
	{
		id: 4578,
		code: 'K146',
		label: 'Glosodinia',
	},
	{
		id: 4579,
		code: 'K148',
		label: 'Otras enfermedades de la lengua',
	},
	{
		id: 4580,
		code: 'K149',
		label: 'Enfermedad de la lengua, no especificada',
	},
	{
		id: 4581,
		code: 'K20X',
		label: 'Esofagitis',
	},
	{
		id: 4582,
		code: 'K21',
		label: 'Enfermedad del reflujo gastroesofágico',
	},
	{
		id: 4583,
		code: 'K210',
		label: 'Enfermedad del reflujo gastroesofágico con esofagitis',
	},
	{
		id: 4584,
		code: 'K219',
		label: 'Enfermedad del reflujo gastroesofágico sin esofagitis',
	},
	{
		id: 4585,
		code: 'K22',
		label: 'Otras enfermedades del esófago',
	},
	{
		id: 4586,
		code: 'K220',
		label: 'Acalasia del cardias',
	},
	{
		id: 4587,
		code: 'K221',
		label: 'Úlcera del esófago',
	},
	{
		id: 4588,
		code: 'K222',
		label: 'Obstrucción del esófago',
	},
	{
		id: 4589,
		code: 'K223',
		label: 'Perforación del esófago',
	},
	{
		id: 4590,
		code: 'K224',
		label: 'Disquinesia del esófago',
	},
	{
		id: 4591,
		code: 'K225',
		label: 'Divertículo del esófago, adquirido',
	},
	{
		id: 4592,
		code: 'K226',
		label: 'Síndrome de laceración y hemorragia gastroesofágicas',
	},
	{
		id: 4593,
		code: 'K227',
		label: 'Esófago de Barrett        ',
	},
	{
		id: 4594,
		code: 'K228',
		label: 'Otras enfermedades especificadas del esófago',
	},
	{
		id: 4595,
		code: 'K229',
		label: 'Enfermedad del esófago, no especificada',
	},
	{
		id: 4596,
		code: 'K23',
		label: 'Trastornos del esófago en enfermedades clasificadas en otra parte',
	},
	{
		id: 4597,
		code: 'K230',
		label: 'Esofagitis tuberculosa',
	},
	{
		id: 4598,
		code: 'K231',
		label: 'Megaesófago en la enfermedad de Chagas',
	},
	{
		id: 4599,
		code: 'K238',
		label: 'Trastornos del esófago en otras enfermedades clasificadas en otra parte',
	},
	{
		id: 4600,
		code: 'K25',
		label: 'Úlcera gástrica',
	},
	{
		id: 4601,
		code: 'K250',
		label: 'Úlcera gástrica, aguda con hemorragia',
	},
	{
		id: 4602,
		code: 'K251',
		label: 'Úlcera gastrica, aguda con perforación',
	},
	{
		id: 4603,
		code: 'K252',
		label: 'Úlcera gástrica, aguda con hemorragia y perforación',
	},
	{
		id: 4604,
		code: 'K253',
		label: 'Úlcera gástrica, aguda sin hemorragia ni perforación',
	},
	{
		id: 4605,
		code: 'K254',
		label: 'Úlcera gástrica, crónica o no especificada, con hemorragia',
	},
	{
		id: 4606,
		code: 'K255',
		label: 'Úlcera gástrica, crónica o no especificada, con perforación',
	},
	{
		id: 4607,
		code: 'K256',
		label: 'Úlcera gástrica, crónica o no especificada, con hemorragia y perforación',
	},
	{
		id: 4608,
		code: 'K257',
		label: 'Úlcera gástrica, crónica sin hemorragia ni perforación',
	},
	{
		id: 4609,
		code: 'K259',
		label: 'Úlcera gástrica, no especificada como aguda ni crónica, sin hemorragia ni perforación',
	},
	{
		id: 4610,
		code: 'K26',
		label: 'Úlcera duodenal',
	},
	{
		id: 4611,
		code: 'K260',
		label: 'Úlcera duodenal, aguda con hemorragia',
	},
	{
		id: 4612,
		code: 'K261',
		label: 'Úlcera duodenal, aguda con perforación',
	},
	{
		id: 4613,
		code: 'K262',
		label: 'Úlcera duodenal, aguda con hemorragia y perforación',
	},
	{
		id: 4614,
		code: 'K263',
		label: 'Úlcera duodenal, aguda sin hemorragia ni perforación',
	},
	{
		id: 4615,
		code: 'K264',
		label: 'Úlcera duodenal, crónica o no especificada, con hemorragia',
	},
	{
		id: 4616,
		code: 'K265',
		label: 'Úlcera duodenal, crónica o no especificada, con perforación',
	},
	{
		id: 4617,
		code: 'K266',
		label: 'Úlcera duodenal, crónica o no especificada, con hemorragia y perforación',
	},
	{
		id: 4618,
		code: 'K267',
		label: 'Úlcera duodenal, crónica sin hemorragia ni perforación',
	},
	{
		id: 4619,
		code: 'K269',
		label: 'Úlcera duodenal, no especificada como aguda ni crónica, sin hemorragia ni perforación',
	},
	{
		id: 4620,
		code: 'K27',
		label: 'Úlcera péptica, de sitio no especificado',
	},
	{
		id: 4621,
		code: 'K270',
		label: 'Úlcera péptica, de sitio no especificado, aguda con hemorragia',
	},
	{
		id: 4622,
		code: 'K271',
		label: 'Úlcera péptica, de sitio no especificado, aguda con perforación',
	},
	{
		id: 4623,
		code: 'K272',
		label: 'Úlcera péptica, de sitio no especificado, aguda con hemorragia y perforación',
	},
	{
		id: 4624,
		code: 'K273',
		label: 'Úlcera péptica, de sitio no especificado, aguda sin hemorragia ni perforación',
	},
	{
		id: 4625,
		code: 'K274',
		label: 'Úlcera péptica, de sitio no especificado, crónica o no especificada, con hemorragia',
	},
	{
		id: 4626,
		code: 'K275',
		label: 'Úlcera péptica, de sitio no especificado, crónica o no especificada, con perforación',
	},
	{
		id: 4627,
		code: 'K276',
		label: 'Úlcera péptica, de sitio no especificado, crónica o no especificada, con hemorragia y perforación',
	},
	{
		id: 4628,
		code: 'K277',
		label: 'Úlcera péptica, de sitio no especificado, crónica sin  hemorragia ni perforación',
	},
	{
		id: 4629,
		code: 'K279',
		label: 'Úlcera péptica, de sitio no especificado, no especificada como aguda ni crónica, sin  hemorragia ni perforación',
	},
	{
		id: 4630,
		code: 'K28',
		label: 'Úlcera gastroyeyunal',
	},
	{
		id: 4631,
		code: 'K280',
		label: 'Úlcera gastroyeyunal, aguda con hemorragia',
	},
	{
		id: 4632,
		code: 'K281',
		label: 'Úlcera gastroyeyunal, aguda con perforación',
	},
	{
		id: 4633,
		code: 'K282',
		label: 'Úlcera gastroyeyunal, aguda con hemorragia y perforación',
	},
	{
		id: 4634,
		code: 'K283',
		label: 'Úlcera gastroyeyunal, aguda sin hemorragia ni perforación',
	},
	{
		id: 4635,
		code: 'K284',
		label: 'Úlcera gastroyeyunal, crónica o no especificada, con hemorragia',
	},
	{
		id: 4636,
		code: 'K285',
		label: 'Úlcera gastroyeyunal, crónica o no especificada, con perforación',
	},
	{
		id: 4637,
		code: 'K286',
		label: 'Úlcera gastroyeyunal, crónica o no especificada, con hemorragia y perforación',
	},
	{
		id: 4638,
		code: 'K287',
		label: 'Úlcera gastroyeyunal, crónica sin hemorragia ni perforación',
	},
	{
		id: 4639,
		code: 'K289',
		label: 'Úlcera gastroyeyunal, no especificada como aguda ni crónica, sin hemorragia ni perforación',
	},
	{
		id: 4640,
		code: 'K29',
		label: 'Gastritis y duodenitis',
	},
	{
		id: 4641,
		code: 'K290',
		label: 'Gastritis aguda hemorrágica',
	},
	{
		id: 4642,
		code: 'K291',
		label: 'Otras gastritis agudas',
	},
	{
		id: 4643,
		code: 'K292',
		label: 'Gastritis alcohólica',
	},
	{
		id: 4644,
		code: 'K293',
		label: 'Gastritis crónica superficial',
	},
	{
		id: 4645,
		code: 'K294',
		label: 'Gastritis crónica atrófica',
	},
	{
		id: 4646,
		code: 'K295',
		label: 'Gastritis crónica, no especificada',
	},
	{
		id: 4647,
		code: 'K296',
		label: 'Otras gastritis',
	},
	{
		id: 4648,
		code: 'K297',
		label: 'Gastritis, no especificada',
	},
	{
		id: 4649,
		code: 'K298',
		label: 'Duodenitis',
	},
	{
		id: 4650,
		code: 'K299',
		label: 'Gastroduodenitis, no especificada',
	},
	{
		id: 4651,
		code: 'K30X',
		label: 'Dispepsia funcional',
	},
	{
		id: 4652,
		code: 'K31',
		label: 'Otras enfermedades del estómago y del duodeno',
	},
	{
		id: 4653,
		code: 'K310',
		label: 'Dilatación aguda del estómago',
	},
	{
		id: 4654,
		code: 'K311',
		label: 'Estenosis pilórica hipertrófica del adulto',
	},
	{
		id: 4655,
		code: 'K312',
		label: 'Estrechez o estenosis del estómago en reloj de arena',
	},
	{
		id: 4656,
		code: 'K313',
		label: 'Espasmo del píloro, no clasificado en otra parte',
	},
	{
		id: 4657,
		code: 'K314',
		label: 'Divertículo gástrico',
	},
	{
		id: 4658,
		code: 'K315',
		label: 'Obstrucción del duodeno',
	},
	{
		id: 4659,
		code: 'K316',
		label: 'Fístula del estómago y del duodeno',
	},
	{
		id: 4660,
		code: 'K317',
		label: 'Pólipo del estómago y del duodeno',
	},
	{
		id: 4661,
		code: 'K318',
		label: 'Otras enfermedades especificadas del estómago y del duodeno',
	},
	{
		id: 4662,
		code: 'K319',
		label: 'Enfermedad del estómago y del duodeno, no especificada',
	},
	{
		id: 4663,
		code: 'K35',
		label: 'Apendicitis aguda',
	},
	{
		id: 4664,
		code: 'K350',
		label: 'Apendicitis aguda con peritonitis generalizada',
	},
	{
		id: 4665,
		code: 'K351',
		label: 'Apendicitis aguda con absceso peritoneal',
	},
	{
		id: 4666,
		code: 'K352',
		label: 'Apendicitis aguda con peritonitis generalizada',
	},
	{
		id: 4667,
		code: 'K353',
		label: 'Apendicitis aguda con peritonitis localizada ',
	},
	{
		id: 4668,
		code: 'K358',
		label: 'Otras apendicitis agudas, y las no especificadas ',
	},
	{
		id: 4669,
		code: 'K359',
		label: 'Apendicitis aguda, no especificada',
	},
	{
		id: 4670,
		code: 'K36X',
		label: 'Otros tipos de apendicitis',
	},
	{
		id: 4671,
		code: 'K37X',
		label: 'Apendicitis, no especificada',
	},
	{
		id: 4672,
		code: 'K38',
		label: 'Otras enfermedades del apéndice',
	},
	{
		id: 4673,
		code: 'K380',
		label: 'Hiperplasia del apéndice',
	},
	{
		id: 4674,
		code: 'K381',
		label: 'Concreciones apendiculares',
	},
	{
		id: 4675,
		code: 'K382',
		label: 'Divertículo del apéndice',
	},
	{
		id: 4676,
		code: 'K383',
		label: 'Fístula del apéndice',
	},
	{
		id: 4677,
		code: 'K388',
		label: 'Otras enfermedades especificadas del apéndice',
	},
	{
		id: 4678,
		code: 'K389',
		label: 'Enfermedad del apéndice, no especificada',
	},
	{
		id: 4679,
		code: 'K40',
		label: 'Hernia inguinal',
	},
	{
		id: 4680,
		code: 'K400',
		label: 'Hernia inguinal bilateral con obstrucción, sin gangrena',
	},
	{
		id: 4681,
		code: 'K401',
		label: 'Hernia inguinal bilateral, con gangrena',
	},
	{
		id: 4682,
		code: 'K402',
		label: 'Hernia inguinal bilateral, sin obstrucción ni gangrena',
	},
	{
		id: 4683,
		code: 'K403',
		label: 'Hernia inguinal unilateral o no especificada, con obstrucción, sin gangrena',
	},
	{
		id: 4684,
		code: 'K404',
		label: 'Hernia inguinal unilateral o no especificada, con gangrena',
	},
	{
		id: 4685,
		code: 'K409',
		label: 'Hernia inguinal unilateral o no especificada, sin obstrucción ni gangrena',
	},
	{
		id: 4686,
		code: 'K41',
		label: 'Hernia femoral',
	},
	{
		id: 4687,
		code: 'K410',
		label: 'Hernia femoral bilateral, con obstrucción, sin gangrena',
	},
	{
		id: 4688,
		code: 'K411',
		label: 'Hernia femoral bilateral, con gangrena',
	},
	{
		id: 4689,
		code: 'K412',
		label: 'Hernia femoral bilateral, sin obstrucción ni gangrena',
	},
	{
		id: 4690,
		code: 'K413',
		label: 'Hernia femoral unilateral o no especificada, con obstrucción, sin gangrena',
	},
	{
		id: 4691,
		code: 'K414',
		label: 'Hernia femoral unilateral o no especificada, con gangrena',
	},
	{
		id: 4692,
		code: 'K419',
		label: 'Hernia femoral unilateral o no especificada, sin obstrucción ni gangrena',
	},
	{
		id: 4693,
		code: 'K42',
		label: 'Hernia umbilical',
	},
	{
		id: 4694,
		code: 'K420',
		label: 'Hernia umbilical con obstrucción, sin gangrena',
	},
	{
		id: 4695,
		code: 'K421',
		label: 'Hernia umbilical con gangrena',
	},
	{
		id: 4696,
		code: 'K429',
		label: 'Hernia umbilical sin obstrucción ni gangrena',
	},
	{
		id: 4697,
		code: 'K43',
		label: 'Hernia ventral',
	},
	{
		id: 4698,
		code: 'K430',
		label: 'Hernia insicional con obstrucción, sin gangrena',
	},
	{
		id: 4699,
		code: 'K431',
		label: 'Hernia incisional con gangrena',
	},
	{
		id: 4700,
		code: 'K432',
		label: 'Hernia incisional sin obstrucción o gangrena ',
	},
	{
		id: 4701,
		code: 'K433',
		label: 'Hernia paraestomal con obstrucción, sin gangrena ',
	},
	{
		id: 4702,
		code: 'K434',
		label: 'Hernia paraestomal con gangrena ',
	},
	{
		id: 4703,
		code: 'K435',
		label: 'Hernia paraestomal sin obstrucción o gangrena',
	},
	{
		id: 4704,
		code: 'K436',
		label: 'Otras hernias ventrales y las no especificadas con obstrucción, sin gangrena ',
	},
	{
		id: 4705,
		code: 'K437',
		label: 'Otras hernias ventrales y las no especificadas con gangrena ',
	},
	{
		id: 4706,
		code: 'K439',
		label: 'Otras hernias ventrales y las no especificadas sin obstrucción o gangrena',
	},
	{
		id: 4707,
		code: 'K44',
		label: 'Hernia diafragmática',
	},
	{
		id: 4708,
		code: 'K440',
		label: 'Hernia diafragmática con obstrucción, sin gangrena',
	},
	{
		id: 4709,
		code: 'K441',
		label: 'Hernia diafragmática con gangrena',
	},
	{
		id: 4710,
		code: 'K449',
		label: 'Hernia diafragmática sin obstrucción ni gangrena',
	},
	{
		id: 4711,
		code: 'K45',
		label: 'Otras hernias de la cavidad abdominal',
	},
	{
		id: 4712,
		code: 'K450',
		label: 'Otras hernias de la cavidad abdominal especificadas, con obstrucción, sin gangrena',
	},
	{
		id: 4713,
		code: 'K451',
		label: 'Otras hernias de la cavidad abdominal especificadas, con gangrena',
	},
	{
		id: 4714,
		code: 'K458',
		label: 'Otras hernias de la cavidad abdominal especificadas, sin obstrucción ni gangrena',
	},
	{
		id: 4715,
		code: 'K46',
		label: 'Hernia no especificada de la cavidad abdominal',
	},
	{
		id: 4716,
		code: 'K460',
		label: 'Hernia abdominal no especificada, con obstrucción, sin gangrena',
	},
	{
		id: 4717,
		code: 'K461',
		label: 'Hernia abdominal no especificada, con gangrena',
	},
	{
		id: 4718,
		code: 'K469',
		label: 'Hernia abdominal no especificada, sin obstrucción ni gangrena',
	},
	{
		id: 4719,
		code: 'K50',
		label: 'Enfermedad de Crohn [enteritis regional]',
	},
	{
		id: 4720,
		code: 'K500',
		label: 'Enfermedad de Crohn del intestino delgado',
	},
	{
		id: 4721,
		code: 'K501',
		label: 'Enfermedad de Crohn del intestino grueso',
	},
	{
		id: 4722,
		code: 'K508',
		label: 'Otros tipos de enfermedad de Crohn',
	},
	{
		id: 4723,
		code: 'K509',
		label: 'Enfermedad de Crohn, no especificada',
	},
	{
		id: 4724,
		code: 'K51',
		label: 'Colitis Ulcerativa',
	},
	{
		id: 4725,
		code: 'K510',
		label: 'Pancolitis ulcerativa (crónica) ',
	},
	{
		id: 4726,
		code: 'K511',
		label: 'Ileocolitis (crónica) ulcerativa',
	},
	{
		id: 4727,
		code: 'K512',
		label: 'Proctitis (crónica) ulcerativa',
	},
	{
		id: 4728,
		code: 'K513',
		label: 'Rectosigmoiditis (crónica) ulcerativa',
	},
	{
		id: 4729,
		code: 'K514',
		label: 'Pólipos inflamatorios',
	},
	{
		id: 4730,
		code: 'K515',
		label: 'Colitis del lado izquierdo',
	},
	{
		id: 4731,
		code: 'K518',
		label: 'Otras colitis ulcerativas',
	},
	{
		id: 4732,
		code: 'K519',
		label: 'Colitis Ulcerativa, sin otra especificación',
	},
	{
		id: 4733,
		code: 'K52',
		label: 'Otras colitis y gastroenteritis no infecciosas',
	},
	{
		id: 4734,
		code: 'K520',
		label: 'Colitis y gastroenteritis debidas a radiación',
	},
	{
		id: 4735,
		code: 'K521',
		label: 'Colitis y gastroenteritis tóxicas',
	},
	{
		id: 4736,
		code: 'K522',
		label: 'Colitis y gastroenteritis alérgicas y dietéticas',
	},
	{
		id: 4737,
		code: 'K523',
		label: 'Colitis de etiología indeterminada',
	},
	{
		id: 4738,
		code: 'K528',
		label: 'Otras colitis y gastroenteritis no infecciosas especificadas',
	},
	{
		id: 4739,
		code: 'K529',
		label: 'Colitis y gastroenteritis no infecciosas, no especificadas',
	},
	{
		id: 4740,
		code: 'K55',
		label: 'Trastornos vasculares de los intestinos',
	},
	{
		id: 4741,
		code: 'K550',
		label: 'Trastorno vascular agudo de los intestinos',
	},
	{
		id: 4742,
		code: 'K551',
		label: 'Trastorno vascular crónico del intestino',
	},
	{
		id: 4743,
		code: 'K552',
		label: 'Angiodisplasia del colon',
	},
	{
		id: 4744,
		code: 'K558',
		label: 'Otros trastornos vasculares del intestino',
	},
	{
		id: 4745,
		code: 'K559',
		label: 'Trastorno vascular del intestino, no especificado',
	},
	{
		id: 4746,
		code: 'K56',
		label: 'Ileo paralítico y obstrucción intestinal sin hernia',
	},
	{
		id: 4747,
		code: 'K560',
		label: 'Íleo paralítico',
	},
	{
		id: 4748,
		code: 'K561',
		label: 'Invaginación',
	},
	{
		id: 4749,
		code: 'K562',
		label: 'Vólvulo',
	},
	{
		id: 4750,
		code: 'K563',
		label: 'Íleo por cálculo biliar',
	},
	{
		id: 4751,
		code: 'K564',
		label: 'Otras obstrucciones del intestino',
	},
	{
		id: 4752,
		code: 'K565',
		label: 'Adherencias [bridas] intestinales con obstrucción',
	},
	{
		id: 4753,
		code: 'K566',
		label: 'Otras obstrucciones intestinales y las no especificadas',
	},
	{
		id: 4754,
		code: 'K567',
		label: 'Íleo, no especificado',
	},
	{
		id: 4755,
		code: 'K57',
		label: 'Enfermedad diverticular del intestino',
	},
	{
		id: 4756,
		code: 'K570',
		label: 'Enfermedad diverticular del intestino delgado con perforación y absceso',
	},
	{
		id: 4757,
		code: 'K571',
		label: 'Enfermedad diverticular del intestino delgado sin perforación ni absceso',
	},
	{
		id: 4758,
		code: 'K572',
		label: 'Enfermedad diverticular del intestino grueso con perforación y absceso',
	},
	{
		id: 4759,
		code: 'K573',
		label: 'Enfermedad diverticular del intestino grueso sin perforación ni absceso',
	},
	{
		id: 4760,
		code: 'K574',
		label: 'Enfermedad diverticular de ambos intestinos con perforación y absceso',
	},
	{
		id: 4761,
		code: 'K575',
		label: 'Enfermedad diverticular de ambos intestinos, sin perforación ni absceso',
	},
	{
		id: 4762,
		code: 'K578',
		label: 'Enfermedad diverticular del intestino, parte no especificada, con perforación y absceso',
	},
	{
		id: 4763,
		code: 'K579',
		label: 'Enfermedad diverticular del intestino, parte no especificada, sin perforación ni absceso',
	},
	{
		id: 4764,
		code: 'K58',
		label: 'Síndrome del colon irritable',
	},
	{
		id: 4765,
		code: 'K580',
		label: 'Síndrome del colon irritable con diarrea',
	},
	{
		id: 4766,
		code: 'K589',
		label: 'Síndrome del colon irritable sin diarrea',
	},
	{
		id: 4767,
		code: 'K59',
		label: 'Otros trastornos funcionles del intestino',
	},
	{
		id: 4768,
		code: 'K590',
		label: 'Constipación',
	},
	{
		id: 4769,
		code: 'K591',
		label: 'Diarrea funcional',
	},
	{
		id: 4770,
		code: 'K592',
		label: 'Intestino neurogénico, no clasificado en otra parte',
	},
	{
		id: 4771,
		code: 'K593',
		label: 'Megacolon, no clasificado en otra parte',
	},
	{
		id: 4772,
		code: 'K594',
		label: 'Espasmo anal',
	},
	{
		id: 4773,
		code: 'K598',
		label: 'Otros trastornos funcionales especificados del intestino',
	},
	{
		id: 4774,
		code: 'K599',
		label: 'Trastorno funcional intestinal, no especificado',
	},
	{
		id: 4775,
		code: 'K60',
		label: 'Fisura y fístula de las regiones anal y rectal',
	},
	{
		id: 4776,
		code: 'K600',
		label: 'Fisura anal aguda',
	},
	{
		id: 4777,
		code: 'K601',
		label: 'Fisura anal crónica',
	},
	{
		id: 4778,
		code: 'K602',
		label: 'Fisura anal, no especificada',
	},
	{
		id: 4779,
		code: 'K603',
		label: 'Fístula anal',
	},
	{
		id: 4780,
		code: 'K604',
		label: 'Fístula rectal',
	},
	{
		id: 4781,
		code: 'K605',
		label: 'Fístula anorrectal',
	},
	{
		id: 4782,
		code: 'K61',
		label: 'Absceso de las regiones anal y rectal',
	},
	{
		id: 4783,
		code: 'K610',
		label: 'Absceso anal',
	},
	{
		id: 4784,
		code: 'K611',
		label: 'Absceso rectal',
	},
	{
		id: 4785,
		code: 'K612',
		label: 'Absceso anorrectal',
	},
	{
		id: 4786,
		code: 'K613',
		label: 'Absceso isquiorrectal',
	},
	{
		id: 4787,
		code: 'K614',
		label: 'Absceso intraesfinteriano',
	},
	{
		id: 4788,
		code: 'K62',
		label: 'Otras enfermedades del ano y del recto',
	},
	{
		id: 4789,
		code: 'K620',
		label: 'Pólipo anal',
	},
	{
		id: 4790,
		code: 'K621',
		label: 'Pólipo rectal',
	},
	{
		id: 4791,
		code: 'K622',
		label: 'Prolapso anal',
	},
	{
		id: 4792,
		code: 'K623',
		label: 'Prolapso rectal',
	},
	{
		id: 4793,
		code: 'K624',
		label: 'Estenosis del ano y del recto',
	},
	{
		id: 4794,
		code: 'K625',
		label: 'Hemorragia del ano y del recto',
	},
	{
		id: 4795,
		code: 'K626',
		label: 'Úlcera del ano y del recto',
	},
	{
		id: 4796,
		code: 'K627',
		label: 'Proctitis por radiación',
	},
	{
		id: 4797,
		code: 'K628',
		label: 'Otras enfermedades especificadas del ano y del recto',
	},
	{
		id: 4798,
		code: 'K629',
		label: 'Enfermedad del ano y del recto, no especificada',
	},
	{
		id: 4799,
		code: 'K63',
		label: 'Otras enfermedades de los intestinos',
	},
	{
		id: 4800,
		code: 'K630',
		label: 'Absceso del intestino',
	},
	{
		id: 4801,
		code: 'K631',
		label: 'Perforación del intestino (no traumática)',
	},
	{
		id: 4802,
		code: 'K632',
		label: 'Fístula del intestino',
	},
	{
		id: 4803,
		code: 'K633',
		label: 'Úlcera del intestino',
	},
	{
		id: 4804,
		code: 'K634',
		label: 'Enteroptosis',
	},
	{
		id: 4805,
		code: 'K635',
		label: 'Pólipo del colon',
	},
	{
		id: 4806,
		code: 'K638',
		label: 'Otras enfermedades especificadas del intestino',
	},
	{
		id: 4807,
		code: 'K639',
		label: 'Enfermedad del intestino, no especificada',
	},
	{
		id: 4808,
		code: 'K64',
		label: 'Hemorroides y trombosis venosa perianal ',
	},
	{
		id: 4809,
		code: 'K640',
		label: 'Hemorroides de primer grado ',
	},
	{
		id: 4810,
		code: 'K641',
		label: 'Hemorroides de segundo grado ',
	},
	{
		id: 4811,
		code: 'K642',
		label: 'Hemorroides de tercer grado ',
	},
	{
		id: 4812,
		code: 'K643',
		label: 'Hemorroides de cuarto grado ',
	},
	{
		id: 4813,
		code: 'K644',
		label: 'Marcas residuales en la piel, de hemorroides ',
	},
	{
		id: 4814,
		code: 'K645',
		label: 'Trombosis venosa perianal ',
	},
	{
		id: 4815,
		code: 'K648',
		label: 'Otras hemorroides especificadas ',
	},
	{
		id: 4816,
		code: 'K649',
		label: 'Hemorroides, sin otra especificación ',
	},
	{
		id: 4817,
		code: 'K65',
		label: 'Peritonitis',
	},
	{
		id: 4818,
		code: 'K650',
		label: 'Peritonitis aguda',
	},
	{
		id: 4819,
		code: 'K658',
		label: 'Otras peritonitis',
	},
	{
		id: 4820,
		code: 'K659',
		label: 'Peritonitis, no especificada',
	},
	{
		id: 4821,
		code: 'K66',
		label: 'Otros trastornos del peritoneo',
	},
	{
		id: 4822,
		code: 'K660',
		label: 'Adherencias peritoneales',
	},
	{
		id: 4823,
		code: 'K661',
		label: 'Hemoperitoneo',
	},
	{
		id: 4824,
		code: 'K668',
		label: 'Otros trastornos especificados del peritoneo',
	},
	{
		id: 4825,
		code: 'K669',
		label: 'Trastorno del peritoneo, no especificado',
	},
	{
		id: 4826,
		code: 'K67',
		label: 'Trastornos del peritoneo en enfermedades infecciosas clasificadas en otra parte',
	},
	{
		id: 4827,
		code: 'K670',
		label: 'Peritonitis por clamidias',
	},
	{
		id: 4828,
		code: 'K671',
		label: 'Peritonitis gonocócica',
	},
	{
		id: 4829,
		code: 'K672',
		label: 'Peritonitis sifilítica',
	},
	{
		id: 4830,
		code: 'K673',
		label: 'Peritonitis tuberculosa',
	},
	{
		id: 4831,
		code: 'K678',
		label: 'Otros trastornos del peritoneo en enfermedades infecciosas clasificadas en otra parte',
	},
	{
		id: 4832,
		code: 'K70',
		label: 'Enfermedad alcohólica del hígado',
	},
	{
		id: 4833,
		code: 'K700',
		label: 'Hígado alcohólico adiposo',
	},
	{
		id: 4834,
		code: 'K701',
		label: 'Hepatitis alcohólica',
	},
	{
		id: 4835,
		code: 'K702',
		label: 'Fibrosis y esclerosis del hígado, alcohólica',
	},
	{
		id: 4836,
		code: 'K703',
		label: 'Cirrosis hepática alcohólica',
	},
	{
		id: 4837,
		code: 'K704',
		label: 'Insuficiencia hepática alcohólica',
	},
	{
		id: 4838,
		code: 'K709',
		label: 'Enfermedad hepática alcohólica, no especificada',
	},
	{
		id: 4839,
		code: 'K71',
		label: 'Enfermedad tóxica del hígado',
	},
	{
		id: 4840,
		code: 'K710',
		label: 'Enfermedad tóxica del hígado, con colestasis',
	},
	{
		id: 4841,
		code: 'K711',
		label: 'Enfermedad tóxica del hígado con necrosis hepática',
	},
	{
		id: 4842,
		code: 'K712',
		label: 'Enfermedad tóxica del hígado con hepatitis aguda',
	},
	{
		id: 4843,
		code: 'K713',
		label: 'Enfermedad tóxica del hígado con hepatitis crónica persistente',
	},
	{
		id: 4844,
		code: 'K714',
		label: 'Enfermedad tóxica del hígado con hepatitis crónica lobular',
	},
	{
		id: 4845,
		code: 'K715',
		label: 'Enfermedad tóxica del hígado con hepatitis crónica activa',
	},
	{
		id: 4846,
		code: 'K716',
		label: 'Enfermedad tóxica del hígado con hepatitis no clasificada en otra parte',
	},
	{
		id: 4847,
		code: 'K717',
		label: 'Enfermedad tóxica del hígado con cirrosis y fibrosis del hígado',
	},
	{
		id: 4848,
		code: 'K718',
		label: 'Enfermedad tóxica del hígado con otros trastornos hepáticos',
	},
	{
		id: 4849,
		code: 'K719',
		label: 'Enfermedad tóxica del hígado, no especificada',
	},
	{
		id: 4850,
		code: 'K72',
		label: 'Insuficiencia hepática, no clasificada en otra parte',
	},
	{
		id: 4851,
		code: 'K720',
		label: 'Insuficiencia hepática aguda o subaguda',
	},
	{
		id: 4852,
		code: 'K721',
		label: 'Insuficiencia hepática crónica',
	},
	{
		id: 4853,
		code: 'K729',
		label: 'Insuficiencia hepática, no especificada',
	},
	{
		id: 4854,
		code: 'K73',
		label: 'Hepatitis crónica, no clasificada en otra parte',
	},
	{
		id: 4855,
		code: 'K730',
		label: 'Hepatitis crónica persistente, no clasificada en otra parte',
	},
	{
		id: 4856,
		code: 'K731',
		label: 'Hepatitis crónica lobular, no clasificada en otra parte',
	},
	{
		id: 4857,
		code: 'K732',
		label: 'Hepatitis crónica activa, no clasificada en otra parte',
	},
	{
		id: 4858,
		code: 'K738',
		label: 'Otras hepatitis crónicas, no clasificadas en otra parte',
	},
	{
		id: 4859,
		code: 'K739',
		label: 'Hepatitis crónica, no especificada',
	},
	{
		id: 4860,
		code: 'K74',
		label: 'Fibrosis y cirrosis del hígado',
	},
	{
		id: 4861,
		code: 'K740',
		label: 'Fibrosis hepática',
	},
	{
		id: 4862,
		code: 'K741',
		label: 'Esclerosis hepática',
	},
	{
		id: 4863,
		code: 'K742',
		label: 'Fibrosis hepática con esclerosis hepática',
	},
	{
		id: 4864,
		code: 'K743',
		label: 'Cirrosis biliar primaria',
	},
	{
		id: 4865,
		code: 'K744',
		label: 'Cirrosis biliar secundaria',
	},
	{
		id: 4866,
		code: 'K745',
		label: 'Cirrosis biliar, no especificada',
	},
	{
		id: 4867,
		code: 'K746',
		label: 'Otras cirrosis del hígado y las no especificadas',
	},
	{
		id: 4868,
		code: 'K75',
		label: 'Otras enfermedades inflamatorias del hígado',
	},
	{
		id: 4869,
		code: 'K750',
		label: 'Absceso del hígado',
	},
	{
		id: 4870,
		code: 'K751',
		label: 'Flebitis de la vena porta',
	},
	{
		id: 4871,
		code: 'K752',
		label: 'Hepatitis reactiva no especificada',
	},
	{
		id: 4872,
		code: 'K753',
		label: 'Hepatitis granulomatosa, no clasificada en otra parte',
	},
	{
		id: 4873,
		code: 'K754',
		label: 'Hepatitis autoinmune',
	},
	{
		id: 4874,
		code: 'K758',
		label: 'Otras enfermedades inflamatorias del hígado, especificadas',
	},
	{
		id: 4875,
		code: 'K759',
		label: 'Enfermedad inflamatoria del hígado, no especificada',
	},
	{
		id: 4876,
		code: 'K76',
		label: 'Otras enfermedades del hígado',
	},
	{
		id: 4877,
		code: 'K760',
		label: 'Degeneración grasa del hígado, no clasificada en otra parte',
	},
	{
		id: 4878,
		code: 'K761',
		label: 'Congestión pasiva crónica del hígado',
	},
	{
		id: 4879,
		code: 'K762',
		label: 'Necrosis hemorrágica central del hígado',
	},
	{
		id: 4880,
		code: 'K763',
		label: 'Infarto del hígado',
	},
	{
		id: 4881,
		code: 'K764',
		label: 'Peliosis hepática',
	},
	{
		id: 4882,
		code: 'K765',
		label: 'Enfermedad veno-oclusiva del hígado',
	},
	{
		id: 4883,
		code: 'K766',
		label: 'Hipertensión portal',
	},
	{
		id: 4884,
		code: 'K767',
		label: 'Síndrome hepatorrenal',
	},
	{
		id: 4885,
		code: 'K768',
		label: 'Otras enfermedades especificadas del hígado',
	},
	{
		id: 4886,
		code: 'K769',
		label: 'Enfermedad del hígado, no especificada',
	},
	{
		id: 4887,
		code: 'K77',
		label: 'Trastornos del hígado en enfermedades clasificadas en otra parte',
	},
	{
		id: 4888,
		code: 'K770',
		label: 'Trastornos del hígado en enfermedades infecciosas y parasitarias clasificadas en otra parte',
	},
	{
		id: 4889,
		code: 'K778',
		label: 'Trastornos del hígado en otras enfermedades clasificadas en otra parte',
	},
	{
		id: 4890,
		code: 'K80',
		label: 'Colelitiasis',
	},
	{
		id: 4891,
		code: 'K800',
		label: 'Cálculo de la vesícula biliar con colecistitis aguda',
	},
	{
		id: 4892,
		code: 'K801',
		label: 'Cálculo de la vesícula biliar con otra colecistitis',
	},
	{
		id: 4893,
		code: 'K802',
		label: 'Cálculo de la vesícula biliar sin colecistitis',
	},
	{
		id: 4894,
		code: 'K803',
		label: 'Cálculo de conducto biliar con colangitis',
	},
	{
		id: 4895,
		code: 'K804',
		label: 'Cálculo de conducto biliar con colecistitis',
	},
	{
		id: 4896,
		code: 'K805',
		label: 'Cálculo de conducto biliar sin colangitis ni colecistitis',
	},
	{
		id: 4897,
		code: 'K808',
		label: 'Otras colelitiasis',
	},
	{
		id: 4898,
		code: 'K81',
		label: 'Colecistitis',
	},
	{
		id: 4899,
		code: 'K810',
		label: 'Colecistitis aguda',
	},
	{
		id: 4900,
		code: 'K811',
		label: 'Colecistitis crónica',
	},
	{
		id: 4901,
		code: 'K818',
		label: 'Otras colecistitis',
	},
	{
		id: 4902,
		code: 'K819',
		label: 'Colecistitis, no especificada',
	},
	{
		id: 4903,
		code: 'K82',
		label: 'Otras enfermedades de la vesícula biliar',
	},
	{
		id: 4904,
		code: 'K820',
		label: 'Obstrucción de la vesícula biliar',
	},
	{
		id: 4905,
		code: 'K821',
		label: 'Hidropesía de la vesícula biliar',
	},
	{
		id: 4906,
		code: 'K822',
		label: 'Perforación de la vesícula biliar',
	},
	{
		id: 4907,
		code: 'K823',
		label: 'Fístula de la vesícula biliar',
	},
	{
		id: 4908,
		code: 'K824',
		label: 'Colesterolosis de la vesícula biliar',
	},
	{
		id: 4909,
		code: 'K828',
		label: 'Otras enfermedades especificadas de la vesícula biliar',
	},
	{
		id: 4910,
		code: 'K829',
		label: 'Enfermedad de la vesícula biliar, no especificada',
	},
	{
		id: 4911,
		code: 'K83',
		label: 'Otras enfermedades de las vías biliares',
	},
	{
		id: 4912,
		code: 'K830',
		label: 'Colangitis',
	},
	{
		id: 4913,
		code: 'K831',
		label: 'Obstrucción del conducto biliar',
	},
	{
		id: 4914,
		code: 'K832',
		label: 'Perforación del conducto biliar',
	},
	{
		id: 4915,
		code: 'K833',
		label: 'Fístula del conducto biliar',
	},
	{
		id: 4916,
		code: 'K834',
		label: 'Espasmo del esfínter de Oddi',
	},
	{
		id: 4917,
		code: 'K835',
		label: 'Quiste biliar',
	},
	{
		id: 4918,
		code: 'K838',
		label: 'Otras enfermedades especificadas de las vías biliares',
	},
	{
		id: 4919,
		code: 'K839',
		label: 'Enfermedad de las vías biliares, no especificada',
	},
	{
		id: 4920,
		code: 'K85X',
		label: 'Pancreatitis aguda',
	},
	{
		id: 4921,
		code: 'K85',
		label: 'Pancreatitis aguda',
	},
	{
		id: 4922,
		code: 'K850',
		label: 'Pancreatitis idiopática aguda',
	},
	{
		id: 4923,
		code: 'K851',
		label: 'Pancreatitis biliar aguda',
	},
	{
		id: 4924,
		code: 'K852',
		label: 'Pancreatitis aguda inducida por alcohol',
	},
	{
		id: 4925,
		code: 'K853',
		label: 'Pancreatitits aguda inducida por drogas',
	},
	{
		id: 4926,
		code: 'K858',
		label: 'Otras pancreatitis agudas',
	},
	{
		id: 4927,
		code: 'K859',
		label: 'Pancreatitis aguda, no especificada',
	},
	{
		id: 4928,
		code: 'K86',
		label: 'Otras enfermedades del páncreas',
	},
	{
		id: 4929,
		code: 'K860',
		label: 'Pancreatitis crónica inducida por el alcohol',
	},
	{
		id: 4930,
		code: 'K861',
		label: 'Otras pancreatitis crónicas',
	},
	{
		id: 4931,
		code: 'K862',
		label: 'Quiste del páncreas',
	},
	{
		id: 4932,
		code: 'K863',
		label: 'Seudoquiste del páncreas',
	},
	{
		id: 4933,
		code: 'K868',
		label: 'Otras enfermedades especificadas del páncreas',
	},
	{
		id: 4934,
		code: 'K869',
		label: 'Enfermedad del páncreas, no especificada',
	},
	{
		id: 4935,
		code: 'K87',
		label: 'Trastornos de la vesícula biliar, de las vías biliares y del páncreas en enfermedades clasificadas en otra parte',
	},
	{
		id: 4936,
		code: 'K870',
		label: 'Trastornos de la vesícula biliar y de las vías biliares en enfermedades clasificadas en otra parte',
	},
	{
		id: 4937,
		code: 'K871',
		label: 'Trastornos del páncreas en enfermedades clasificadas en otra parte',
	},
	{
		id: 4938,
		code: 'K90',
		label: 'Malabsorción intestinal',
	},
	{
		id: 4939,
		code: 'K900',
		label: 'Enfermedad celíaca',
	},
	{
		id: 4940,
		code: 'K901',
		label: 'Esprue tropical',
	},
	{
		id: 4941,
		code: 'K902',
		label: 'Síndrome del asa ciega, no clasificado en otra parte',
	},
	{
		id: 4942,
		code: 'K903',
		label: 'Esteatorrea pancreática',
	},
	{
		id: 4943,
		code: 'K904',
		label: 'Malabsorción debida a intolerancia, no clasificada en otra parte',
	},
	{
		id: 4944,
		code: 'K908',
		label: 'Otros tipos de malabsorción intestinal',
	},
	{
		id: 4945,
		code: 'K909',
		label: 'Malabsorción intestinal, no especificada',
	},
	{
		id: 4946,
		code: 'K91',
		label: 'Trastornos del sistema digestivo consecutivos a procedimientos, no clasificados en otra parte',
	},
	{
		id: 4947,
		code: 'K910',
		label: 'Vómito postcirugía gastrointestinal',
	},
	{
		id: 4948,
		code: 'K911',
		label: 'Síndromes consecutivos a la cirugía gástrica',
	},
	{
		id: 4949,
		code: 'K912',
		label: 'Malabsorción postquirúrgica, no clasificada en otra parte',
	},
	{
		id: 4950,
		code: 'K913',
		label: 'Obstrucción intestinal postoperatoria',
	},
	{
		id: 4951,
		code: 'K914',
		label: 'Disfunción de colostomía o enterostomía',
	},
	{
		id: 4952,
		code: 'K915',
		label: 'Síndrome de postcolecistectomía',
	},
	{
		id: 4953,
		code: 'K918',
		label: 'Otros trastornos del sistema digestivo consecutivos a procedimientos, no clasificados en otra parte',
	},
	{
		id: 4954,
		code: 'K919',
		label: 'Trastorno no especificado del sistema digestivo consecutivo a procedimientos',
	},
	{
		id: 4955,
		code: 'K92',
		label: 'Otras enfermedades del sistema digestivo',
	},
	{
		id: 4956,
		code: 'K920',
		label: 'Hematemesis',
	},
	{
		id: 4957,
		code: 'K921',
		label: 'Melena',
	},
	{
		id: 4958,
		code: 'K922',
		label: 'Hemorragia gastrointestinal, no especificada',
	},
	{
		id: 4959,
		code: 'K928',
		label: 'Otras enfermedades especificadas del sistema digestivo',
	},
	{
		id: 4960,
		code: 'K929',
		label: 'Enfermedad del sistema digestivo, no especificada',
	},
	{
		id: 4961,
		code: 'K93',
		label: 'Trastornos de otros órganos digestivos en enfermedades clasificadas en otra parte',
	},
	{
		id: 4962,
		code: 'K930',
		label: 'Trastornos tuberculosos del intestino, peritoneo y ganglios mesentéricos',
	},
	{
		id: 4963,
		code: 'K931',
		label: 'Megacolon en la enfermedad de Chagas',
	},
	{
		id: 4964,
		code: 'K938',
		label: 'Trastornos de otros órganos digestivos especificados en enfermedades clasificadas en otra parte',
	},
	{
		id: 4965,
		code: 'L00X',
		label: 'Síndrome estafilocócico de la piel escaldada',
	},
	{
		id: 4966,
		code: 'L01',
		label: 'Impétigo',
	},
	{
		id: 4967,
		code: 'L010',
		label: 'Impétigo [cualquier sitio anatómico] [cualquier organismo]',
	},
	{
		id: 4968,
		code: 'L011',
		label: 'Impetiginización de otras dermatosis',
	},
	{
		id: 4969,
		code: 'L02',
		label: 'Absceso cutáneo, furúnculo y ántrax',
	},
	{
		id: 4970,
		code: 'L020',
		label: 'Absceso cutáneo, furúnculo y ántrax de la cara',
	},
	{
		id: 4971,
		code: 'L021',
		label: 'Absceso cutáneo, furúnculo y ántrax del cuello',
	},
	{
		id: 4972,
		code: 'L022',
		label: 'Absceso cutáneo, furúnculo y ántrax del tronco',
	},
	{
		id: 4973,
		code: 'L023',
		label: 'Absceso cutáneo, furúnculo y ántrax de glúteos',
	},
	{
		id: 4974,
		code: 'L024',
		label: 'Absceso cutáneo, furúnculo y ántrax de miembro',
	},
	{
		id: 4975,
		code: 'L028',
		label: 'Absceso cutáneo, furúnculo y ántrax de otros sitios',
	},
	{
		id: 4976,
		code: 'L029',
		label: 'Absceso cutáneo, furúnculo y ántrax de sitio no especificado',
	},
	{
		id: 4977,
		code: 'L03',
		label: 'Celulitis',
	},
	{
		id: 4978,
		code: 'L030',
		label: 'Celulitis de los dedos de la mano y del pie',
	},
	{
		id: 4979,
		code: 'L031',
		label: 'Celulitis de otras partes de los miembros',
	},
	{
		id: 4980,
		code: 'L032',
		label: 'Celulitis de la cara',
	},
	{
		id: 4981,
		code: 'L033',
		label: 'Celulitis del tronco',
	},
	{
		id: 4982,
		code: 'L038',
		label: 'Celulitis de otros sitios',
	},
	{
		id: 4983,
		code: 'L039',
		label: 'Celulitis de sitio no especificado',
	},
	{
		id: 4984,
		code: 'L04',
		label: 'Linfadenitis aguda',
	},
	{
		id: 4985,
		code: 'L040',
		label: 'Linfadenitis aguda de cara, cabeza y cuello',
	},
	{
		id: 4986,
		code: 'L041',
		label: 'Linfadenitis aguda del tronco',
	},
	{
		id: 4987,
		code: 'L042',
		label: 'Linfadenitis aguda del miembro superior',
	},
	{
		id: 4988,
		code: 'L043',
		label: 'Linfadenitis aguda del miembro inferior',
	},
	{
		id: 4989,
		code: 'L048',
		label: 'Linfadenitis aguda de otros sitios',
	},
	{
		id: 4990,
		code: 'L049',
		label: 'Linfadenitis aguda de sitio no especificado',
	},
	{
		id: 4991,
		code: 'L05',
		label: 'Quiste pilonidal',
	},
	{
		id: 4992,
		code: 'L050',
		label: 'Quiste pilonidal con absceso',
	},
	{
		id: 4993,
		code: 'L059',
		label: 'Quiste pilonidal sin absceso',
	},
	{
		id: 4994,
		code: 'L08',
		label: 'Otras infecciones locales de la piel y del tejido subcutáneo',
	},
	{
		id: 4995,
		code: 'L080',
		label: 'Pioderma',
	},
	{
		id: 4996,
		code: 'L081',
		label: 'Eritrasma',
	},
	{
		id: 4997,
		code: 'L088',
		label: 'Otras infecciones locales especificadas de la piel y del tejido subcutáneo',
	},
	{
		id: 4998,
		code: 'L089',
		label: 'Infección local de la piel y del tejido subcutáneo, no especificada',
	},
	{
		id: 4999,
		code: 'L10',
		label: 'Pénfigo',
	},
	{
		id: 5000,
		code: 'L100',
		label: 'Pénfigo vulgar',
	},
	{
		id: 5001,
		code: 'L101',
		label: 'Pénfigo vegetante',
	},
	{
		id: 5002,
		code: 'L102',
		label: 'Pénfigo foliáceo',
	},
	{
		id: 5003,
		code: 'L103',
		label: 'Pénfigo brasileño [fogo selvagem]',
	},
	{
		id: 5004,
		code: 'L104',
		label: 'Pénfigo eritematoso',
	},
	{
		id: 5005,
		code: 'L105',
		label: 'Pénfigo inducido por drogas',
	},
	{
		id: 5006,
		code: 'L108',
		label: 'Otros pénfigos',
	},
	{
		id: 5007,
		code: 'L109',
		label: 'Pénfigo, no especificado',
	},
	{
		id: 5008,
		code: 'L11',
		label: 'Otros trastornos acantolíticos',
	},
	{
		id: 5009,
		code: 'L110',
		label: 'Queratosis folicular adquirida',
	},
	{
		id: 5010,
		code: 'L111',
		label: 'Dermatosis acantolítica transitoria [Grover]',
	},
	{
		id: 5011,
		code: 'L118',
		label: 'Otros trastornos acantolíticos especificados',
	},
	{
		id: 5012,
		code: 'L119',
		label: 'Trastorno acantolítico, no especificado',
	},
	{
		id: 5013,
		code: 'L12',
		label: 'Penfigoide',
	},
	{
		id: 5014,
		code: 'L120',
		label: 'Penfigoide flictenular',
	},
	{
		id: 5015,
		code: 'L121',
		label: 'Penfigoide cicatricial',
	},
	{
		id: 5016,
		code: 'L122',
		label: 'Enfermedad flictenular crónica de la infancia',
	},
	{
		id: 5017,
		code: 'L123',
		label: 'Epidermólisis bullosa adquirida',
	},
	{
		id: 5018,
		code: 'L128',
		label: 'Otros penfigoides',
	},
	{
		id: 5019,
		code: 'L129',
		label: 'Penfigoide, no especificado',
	},
	{
		id: 5020,
		code: 'L13',
		label: 'Otros trastornos flictenulares',
	},
	{
		id: 5021,
		code: 'L130',
		label: 'Dermatitis herpetiforme',
	},
	{
		id: 5022,
		code: 'L131',
		label: 'Dermatitis pustulosa subcorneal',
	},
	{
		id: 5023,
		code: 'L138',
		label: 'Otros trastornos flictenulares especificados',
	},
	{
		id: 5024,
		code: 'L139',
		label: 'Trastorno flictenular, no especificado',
	},
	{
		id: 5025,
		code: 'L14X',
		label: 'Trastornos flictenulares en enfermedades clasificadas en otra parte',
	},
	{
		id: 5026,
		code: 'L20',
		label: 'Dermatitis atópica',
	},
	{
		id: 5027,
		code: 'L200',
		label: 'Prurigo de Besnier',
	},
	{
		id: 5028,
		code: 'L208',
		label: 'Otras dermatitis atópicas',
	},
	{
		id: 5029,
		code: 'L209',
		label: 'Dermatitis atópica, no especificada',
	},
	{
		id: 5030,
		code: 'L21',
		label: 'Dermatitis seborreica',
	},
	{
		id: 5031,
		code: 'L210',
		label: 'Seborrea capitis',
	},
	{
		id: 5032,
		code: 'L211',
		label: 'Dermatitis seborreica infantil',
	},
	{
		id: 5033,
		code: 'L218',
		label: 'Otras dermatitis seborreicas',
	},
	{
		id: 5034,
		code: 'L219',
		label: 'Dermatitis seborreica, no especificada',
	},
	{
		id: 5035,
		code: 'L22X',
		label: 'Dermatitis del pañal',
	},
	{
		id: 5036,
		code: 'L23',
		label: 'Dermatitis alérgica de contacto',
	},
	{
		id: 5037,
		code: 'L230',
		label: 'Dermatitis alérgica de contacto debida a metales',
	},
	{
		id: 5038,
		code: 'L231',
		label: 'Dermatitis alérgica de contacto debida a adhesivos',
	},
	{
		id: 5039,
		code: 'L232',
		label: 'Dermatitis alérgica de contacto debida a cosméticos',
	},
	{
		id: 5040,
		code: 'L233',
		label: 'Dermatitis alérgica de contacto debida a drogas en contacto con la piel',
	},
	{
		id: 5041,
		code: 'L234',
		label: 'Dermatitis alérgica de contacto debida a colorantes',
	},
	{
		id: 5042,
		code: 'L235',
		label: 'Dermatitis alérgica de contacto debida a otros productos químicos',
	},
	{
		id: 5043,
		code: 'L236',
		label: 'Dermatitis alérgica de contacto debida a alimentos en contacto con la piel',
	},
	{
		id: 5044,
		code: 'L237',
		label: 'Dermatitis alérgica de contacto debida a plantas, excepto las alimenticias',
	},
	{
		id: 5045,
		code: 'L238',
		label: 'Dermatitis alérgica de contacto debida a otros agentes',
	},
	{
		id: 5046,
		code: 'L239',
		label: 'Dermatitis alérgica de contacto, de causa no especificada',
	},
	{
		id: 5047,
		code: 'L24',
		label: 'Dermatitis de contacto por irritantes',
	},
	{
		id: 5048,
		code: 'L240',
		label: 'Dermatitis de contacto por irritantes, debida a detergentes',
	},
	{
		id: 5049,
		code: 'L241',
		label: 'Dermatitis de contacto por irritantes, debida a aceites y grasas',
	},
	{
		id: 5050,
		code: 'L242',
		label: 'Dermatitis de contacto por irritantes, debida a disolventes',
	},
	{
		id: 5051,
		code: 'L243',
		label: 'Dermatitis de contacto por irritantes, debida a cosméticos',
	},
	{
		id: 5052,
		code: 'L244',
		label: 'Dermatitis de contacto por irritantes, debida a drogas en contacto con la piel',
	},
	{
		id: 5053,
		code: 'L245',
		label: 'Dermatitis de contacto por irritantes, debida a otros productos químicos',
	},
	{
		id: 5054,
		code: 'L246',
		label: 'Dermatitis de contacto por irritantes, debida a alimentos en contacto con la piel',
	},
	{
		id: 5055,
		code: 'L247',
		label: 'Dermatitis de contacto por irritantes, debida a plantas, excepto las alimenticias',
	},
	{
		id: 5056,
		code: 'L248',
		label: 'Dermatitis de contacto por irritantes, debida a otros agentes',
	},
	{
		id: 5057,
		code: 'L249',
		label: 'Dermatitis de contacto por irritantes, de causa no especificada',
	},
	{
		id: 5058,
		code: 'L25',
		label: 'Dermatitis de contacto, forma no especificada',
	},
	{
		id: 5059,
		code: 'L250',
		label: 'Dermatitis de contacto, forma no especificada, debida a cosméticos',
	},
	{
		id: 5060,
		code: 'L251',
		label: 'Dermatitis de contacto, forma no especificada, debida a drogas en contacto con la piel',
	},
	{
		id: 5061,
		code: 'L252',
		label: 'Dermatitis de contacto, forma no especificada, debida a colorantes',
	},
	{
		id: 5062,
		code: 'L253',
		label: 'Dermatitis de contacto, forma no especificada, debida a otros productos químicos',
	},
	{
		id: 5063,
		code: 'L254',
		label: 'Dermatitis de contacto, forma no especificada, debida a alimentos en contacto con la piel',
	},
	{
		id: 5064,
		code: 'L255',
		label: 'Dermatitis de contacto, forma no especificada, debida a plantas, excepto las alimenticias',
	},
	{
		id: 5065,
		code: 'L258',
		label: 'Dermatitis de contacto, forma no especificada, debida a otros agentes',
	},
	{
		id: 5066,
		code: 'L259',
		label: 'Dermatitis de contacto, forma y causa no especificadas',
	},
	{
		id: 5067,
		code: 'L26X',
		label: 'Dermatitis exfoliativa',
	},
	{
		id: 5068,
		code: 'L27',
		label: 'Dermatitis debida a sustancias ingeridas',
	},
	{
		id: 5069,
		code: 'L270',
		label: 'Erupción cutánea generalizada debida a drogas y medicamentos',
	},
	{
		id: 5070,
		code: 'L271',
		label: 'Erupción cutánea localizada debida a drogas y medicamentos',
	},
	{
		id: 5071,
		code: 'L272',
		label: 'Dermatitis debida a ingestión de alimentos',
	},
	{
		id: 5072,
		code: 'L278',
		label: 'Dermatitis debida a otras sustancias ingeridas',
	},
	{
		id: 5073,
		code: 'L279',
		label: 'Dermatitis debida a sustancias ingeridas no especificadas',
	},
	{
		id: 5074,
		code: 'L28',
		label: 'Liquen simple crónico y prurigo',
	},
	{
		id: 5075,
		code: 'L280',
		label: 'Liquen simple crónico',
	},
	{
		id: 5076,
		code: 'L281',
		label: 'Prurigo nodular',
	},
	{
		id: 5077,
		code: 'L282',
		label: 'Otros prurigos',
	},
	{
		id: 5078,
		code: 'L29',
		label: 'Prurito',
	},
	{
		id: 5079,
		code: 'L290',
		label: 'Prurito anal',
	},
	{
		id: 5080,
		code: 'L291',
		label: 'Prurito escrotal',
	},
	{
		id: 5081,
		code: 'L292',
		label: 'Prurito vulvar',
	},
	{
		id: 5082,
		code: 'L293',
		label: 'Prurito anogenital, no especificado',
	},
	{
		id: 5083,
		code: 'L298',
		label: 'Otros pruritos',
	},
	{
		id: 5084,
		code: 'L299',
		label: 'Prurito, no especificado',
	},
	{
		id: 5085,
		code: 'L30',
		label: 'Otras dermatitis',
	},
	{
		id: 5086,
		code: 'L300',
		label: 'Dermatitis numular',
	},
	{
		id: 5087,
		code: 'L301',
		label: 'Dishidrosis [ponfólix]',
	},
	{
		id: 5088,
		code: 'L302',
		label: 'Autosensibilización cutánea',
	},
	{
		id: 5089,
		code: 'L303',
		label: 'Dermatitis infecciosa',
	},
	{
		id: 5090,
		code: 'L304',
		label: 'Eritema intertrigo',
	},
	{
		id: 5091,
		code: 'L305',
		label: 'Pitiriasis alba',
	},
	{
		id: 5092,
		code: 'L308',
		label: 'Otras dermatitis especificadas',
	},
	{
		id: 5093,
		code: 'L309',
		label: 'Dermatitis, no especificada',
	},
	{
		id: 5094,
		code: 'L40',
		label: 'Psoriasis',
	},
	{
		id: 5095,
		code: 'L400',
		label: 'Psoriasis vulgar',
	},
	{
		id: 5096,
		code: 'L401',
		label: 'Psoriasis pustulosa generalizada',
	},
	{
		id: 5097,
		code: 'L402',
		label: 'Acrodermatitis continua',
	},
	{
		id: 5098,
		code: 'L403',
		label: 'Pustulosis palmar y plantar',
	},
	{
		id: 5099,
		code: 'L404',
		label: 'Psoriasis guttata',
	},
	{
		id: 5100,
		code: 'L405',
		label: 'Artropatía psoriásica',
	},
	{
		id: 5101,
		code: 'L408',
		label: 'Otras psoriasis',
	},
	{
		id: 5102,
		code: 'L409',
		label: 'Psoriasis, no especificada',
	},
	{
		id: 5103,
		code: 'L41',
		label: 'Parapsoriasis',
	},
	{
		id: 5104,
		code: 'L410',
		label: 'Pitiriasis liquenoide y varioliforme aguda',
	},
	{
		id: 5105,
		code: 'L411',
		label: 'Pitiriasis liquenoide crónica',
	},
	{
		id: 5106,
		code: 'L412',
		label: 'Papulosis linfomatoide',
	},
	{
		id: 5107,
		code: 'L413',
		label: 'Parapsoriasis en placas pequeñas',
	},
	{
		id: 5108,
		code: 'L414',
		label: 'Parapsoriasis en placas grandes',
	},
	{
		id: 5109,
		code: 'L415',
		label: 'Parapsoriasis retiforme',
	},
	{
		id: 5110,
		code: 'L418',
		label: 'Otras parapsoriasis',
	},
	{
		id: 5111,
		code: 'L419',
		label: 'Parapsoriasis, no especificada',
	},
	{
		id: 5112,
		code: 'L42X',
		label: 'Pitiriasis rosada',
	},
	{
		id: 5113,
		code: 'L43',
		label: 'Liquen plano',
	},
	{
		id: 5114,
		code: 'L430',
		label: 'Liquen plano hipertrófico',
	},
	{
		id: 5115,
		code: 'L431',
		label: 'Liquen plano flictenular',
	},
	{
		id: 5116,
		code: 'L432',
		label: 'Reacción liquenoide debida a drogas',
	},
	{
		id: 5117,
		code: 'L433',
		label: 'Liquen plano subagudo (activo)',
	},
	{
		id: 5118,
		code: 'L438',
		label: 'Otros líquenes planos',
	},
	{
		id: 5119,
		code: 'L439',
		label: 'Liquen plano, no especificado',
	},
	{
		id: 5120,
		code: 'L44',
		label: 'Otros trastornos papuloescamosos',
	},
	{
		id: 5121,
		code: 'L440',
		label: 'Pitiriasis rubra pilaris',
	},
	{
		id: 5122,
		code: 'L441',
		label: 'Liquen nítido',
	},
	{
		id: 5123,
		code: 'L442',
		label: 'Liquen estriado',
	},
	{
		id: 5124,
		code: 'L443',
		label: 'Liquen rojo moniliforme',
	},
	{
		id: 5125,
		code: 'L444',
		label: 'Acrodermatitis papular infantil [Giannotti-Crosti]',
	},
	{
		id: 5126,
		code: 'L448',
		label: 'Otros trastornos papuloescamosos especificados',
	},
	{
		id: 5127,
		code: 'L449',
		label: 'Trastorno papuloescamoso, no especificado',
	},
	{
		id: 5128,
		code: 'L45X',
		label: 'Trastornos papuloescamosos en enfermedades clasificadas en otra parte',
	},
	{
		id: 5129,
		code: 'L50',
		label: 'Urticaria',
	},
	{
		id: 5130,
		code: 'L500',
		label: 'Urticaria alérgica',
	},
	{
		id: 5131,
		code: 'L501',
		label: 'Urticaria idiopática',
	},
	{
		id: 5132,
		code: 'L502',
		label: 'Urticaria debida al calor y al frío',
	},
	{
		id: 5133,
		code: 'L503',
		label: 'Urticaria dermatográfica',
	},
	{
		id: 5134,
		code: 'L504',
		label: 'Urticaria vibratoria',
	},
	{
		id: 5135,
		code: 'L505',
		label: 'Urticaria colinérgica',
	},
	{
		id: 5136,
		code: 'L506',
		label: 'Urticaria por contacto',
	},
	{
		id: 5137,
		code: 'L508',
		label: 'Otras urticarias',
	},
	{
		id: 5138,
		code: 'L509',
		label: 'Urticaria, no especificada',
	},
	{
		id: 5139,
		code: 'L51',
		label: 'Eritema multiforme',
	},
	{
		id: 5140,
		code: 'L510',
		label: 'Eritema multiforme no flictenular',
	},
	{
		id: 5141,
		code: 'L511',
		label: 'Eritema multiforme flictenular',
	},
	{
		id: 5142,
		code: 'L512',
		label: 'Necrólisis epidérmica tóxica [Lyell]',
	},
	{
		id: 5143,
		code: 'L518',
		label: 'Otros eritemas multiformes',
	},
	{
		id: 5144,
		code: 'L519',
		label: 'Eritema multiforme, no especificado',
	},
	{
		id: 5145,
		code: 'L52X',
		label: 'Eritema nudoso',
	},
	{
		id: 5146,
		code: 'L53',
		label: 'Otras afecciones eritematosas',
	},
	{
		id: 5147,
		code: 'L530',
		label: 'Eritema tóxico',
	},
	{
		id: 5148,
		code: 'L531',
		label: 'Eritema anular centrífugo',
	},
	{
		id: 5149,
		code: 'L532',
		label: 'Eritema marginado',
	},
	{
		id: 5150,
		code: 'L533',
		label: 'Otros eritemas figurados crónicos',
	},
	{
		id: 5151,
		code: 'L538',
		label: 'Otras afecciones eritematosas especificadas',
	},
	{
		id: 5152,
		code: 'L539',
		label: 'Afección eritematosa, no especificada',
	},
	{
		id: 5153,
		code: 'L54',
		label: 'Eritema en enfermedades clasificadas en otra parte',
	},
	{
		id: 5154,
		code: 'L540',
		label: 'Eritema marginado en la fiebre reumática aguda',
	},
	{
		id: 5155,
		code: 'L548',
		label: 'Eritema en otras enfermedades clasificadas en otra parte',
	},
	{
		id: 5156,
		code: 'L55',
		label: 'Quemadura solar',
	},
	{
		id: 5157,
		code: 'L550',
		label: 'Quemadura solar de primer grado',
	},
	{
		id: 5158,
		code: 'L551',
		label: 'Quemadura solar de segundo grado',
	},
	{
		id: 5159,
		code: 'L552',
		label: 'Quemadura solar de tercer grado',
	},
	{
		id: 5160,
		code: 'L558',
		label: 'Otras quemaduras solares',
	},
	{
		id: 5161,
		code: 'L559',
		label: 'Quemadura solar, no especificada',
	},
	{
		id: 5162,
		code: 'L56',
		label: 'Otros cambios agudos de la piel debidos a radiación ultravioleta',
	},
	{
		id: 5163,
		code: 'L560',
		label: 'Respuesta fototóxica a drogas',
	},
	{
		id: 5164,
		code: 'L561',
		label: 'Respuesta fotoalérgica a drogas',
	},
	{
		id: 5165,
		code: 'L562',
		label: 'Dermatitis por fotocontacto [dermatitis de berloque]',
	},
	{
		id: 5166,
		code: 'L563',
		label: 'Urticaria solar',
	},
	{
		id: 5167,
		code: 'L564',
		label: 'Erupción polimorfa a la luz',
	},
	{
		id: 5168,
		code: 'L568',
		label: 'Otros cambios agudos especificados de la piel debidos a radiación ultravioleta',
	},
	{
		id: 5169,
		code: 'L569',
		label: 'Cambio agudo de la piel debido a radiación ultravioleta, sin otra especificación',
	},
	{
		id: 5170,
		code: 'L57',
		label: 'Cambios de la piel debidos a exposición crónica a radiación no ionizante',
	},
	{
		id: 5171,
		code: 'L570',
		label: 'Queratosis actínica',
	},
	{
		id: 5172,
		code: 'L571',
		label: 'Reticuloide actínico',
	},
	{
		id: 5173,
		code: 'L572',
		label: 'Piel romboidal de la nuca',
	},
	{
		id: 5174,
		code: 'L573',
		label: 'Poiquilodermia de Civatte',
	},
	{
		id: 5175,
		code: 'L574',
		label: 'Piel laxa senil',
	},
	{
		id: 5176,
		code: 'L575',
		label: 'Granuloma actínico',
	},
	{
		id: 5177,
		code: 'L578',
		label: 'Otros cambios de la piel debidos a exposición crónica a radiación no ionizante',
	},
	{
		id: 5178,
		code: 'L579',
		label: 'Cambios de la piel debidos a exposición crónica a radiación no ionizante, sin otra especificación',
	},
	{
		id: 5179,
		code: 'L58',
		label: 'Radiodermatitis',
	},
	{
		id: 5180,
		code: 'L580',
		label: 'Radiodermatitis aguda',
	},
	{
		id: 5181,
		code: 'L581',
		label: 'Radiodermatitis crónica',
	},
	{
		id: 5182,
		code: 'L589',
		label: 'Radiodermatitis, no especificada',
	},
	{
		id: 5183,
		code: 'L59',
		label: 'Otros trastornos de la piel y del tejido subcutáneo relacionados con radiación',
	},
	{
		id: 5184,
		code: 'L590',
		label: 'Eritema ab igne [dermatitis ab igne]',
	},
	{
		id: 5185,
		code: 'L598',
		label: 'Otros trastornos especificados de la piel y del tejido subcutáneo relacionados con radiación',
	},
	{
		id: 5186,
		code: 'L599',
		label: 'Trastornos no especificados de la piel y del tejido subcutáneo relacionados con radiación',
	},
	{
		id: 5187,
		code: 'L60',
		label: 'Trastornos de las uñas',
	},
	{
		id: 5188,
		code: 'L600',
		label: 'Uña encarnada',
	},
	{
		id: 5189,
		code: 'L601',
		label: 'Onicólisis',
	},
	{
		id: 5190,
		code: 'L602',
		label: 'Onicogriposis',
	},
	{
		id: 5191,
		code: 'L603',
		label: 'Distrofia ungueal',
	},
	{
		id: 5192,
		code: 'L604',
		label: 'Líneas de Beau',
	},
	{
		id: 5193,
		code: 'L605',
		label: 'Síndrome de la uña amarilla',
	},
	{
		id: 5194,
		code: 'L608',
		label: 'Otros trastornos de las uñas',
	},
	{
		id: 5195,
		code: 'L609',
		label: 'Trastorno de la uña, no especificado',
	},
	{
		id: 5196,
		code: 'L62',
		label: 'Trastornos de las uñas en enfermedades clasificadas en otra parte',
	},
	{
		id: 5197,
		code: 'L620',
		label: 'Uña deforme de la paquidermoperiostosis',
	},
	{
		id: 5198,
		code: 'L628',
		label: 'Trastornos de las uñas en otras enfermedades clasificadas en otra parte',
	},
	{
		id: 5199,
		code: 'L63',
		label: 'Alopecia areata',
	},
	{
		id: 5200,
		code: 'L630',
		label: 'Alopecia (capitis) total',
	},
	{
		id: 5201,
		code: 'L631',
		label: 'Alopecia universal',
	},
	{
		id: 5202,
		code: 'L632',
		label: 'Ofiasis',
	},
	{
		id: 5203,
		code: 'L638',
		label: 'Otras alopecias areatas',
	},
	{
		id: 5204,
		code: 'L639',
		label: 'Alopecia areata, no especificada',
	},
	{
		id: 5205,
		code: 'L64',
		label: 'Alopecia andrógena',
	},
	{
		id: 5206,
		code: 'L640',
		label: 'Alopecia andrógena, inducida por drogas',
	},
	{
		id: 5207,
		code: 'L648',
		label: 'Otras alopecias andrógenas',
	},
	{
		id: 5208,
		code: 'L649',
		label: 'Alopecia andrógena, no especificada',
	},
	{
		id: 5209,
		code: 'L65',
		label: 'Otra pérdida no cicatricial del pelo',
	},
	{
		id: 5210,
		code: 'L650',
		label: 'Pérdida capilar telógena',
	},
	{
		id: 5211,
		code: 'L651',
		label: 'Pérdida capilar anágena',
	},
	{
		id: 5212,
		code: 'L652',
		label: 'Alopecia mucinosa',
	},
	{
		id: 5213,
		code: 'L658',
		label: 'Otras pérdidas especificadas no cicatriciales del pelo',
	},
	{
		id: 5214,
		code: 'L659',
		label: 'Perdida no cicatricial del pelo, sin otra especificación',
	},
	{
		id: 5215,
		code: 'L66',
		label: 'Alopecia cicatricial [pérdida cicatricial del pelo]',
	},
	{
		id: 5216,
		code: 'L660',
		label: 'Seudopelada',
	},
	{
		id: 5217,
		code: 'L661',
		label: 'Liquen plano pilaris',
	},
	{
		id: 5218,
		code: 'L662',
		label: 'Foliculitis decalvante',
	},
	{
		id: 5219,
		code: 'L663',
		label: 'Perifoliculitis capitis abscedens',
	},
	{
		id: 5220,
		code: 'L664',
		label: 'Foliculitis uleritematosa reticulada',
	},
	{
		id: 5221,
		code: 'L668',
		label: 'Otras alopecias cicatriciales',
	},
	{
		id: 5222,
		code: 'L669',
		label: 'Alopecia cicatricial, no especificada',
	},
	{
		id: 5223,
		code: 'L67',
		label: 'Anormalidades del tallo y del color del pelo',
	},
	{
		id: 5224,
		code: 'L670',
		label: 'Tricorrexis nudosa',
	},
	{
		id: 5225,
		code: 'L671',
		label: 'Variación del color del pelo',
	},
	{
		id: 5226,
		code: 'L678',
		label: 'Otras anormalidades del tallo y del color del pelo',
	},
	{
		id: 5227,
		code: 'L679',
		label: 'Anormalidad no especificada del tallo y del color del pelo',
	},
	{
		id: 5228,
		code: 'L68',
		label: 'Hipertricosis',
	},
	{
		id: 5229,
		code: 'L680',
		label: 'Hirsutismo',
	},
	{
		id: 5230,
		code: 'L681',
		label: 'Hipertricosis lanuginosa adquirida',
	},
	{
		id: 5231,
		code: 'L682',
		label: 'Hipertricosis localizada',
	},
	{
		id: 5232,
		code: 'L683',
		label: 'Politriquia',
	},
	{
		id: 5233,
		code: 'L688',
		label: 'Otras hipertricosis',
	},
	{
		id: 5234,
		code: 'L689',
		label: 'Hipertricosis, no especificada',
	},
	{
		id: 5235,
		code: 'L70',
		label: 'Acné',
	},
	{
		id: 5236,
		code: 'L700',
		label: 'Acné vulgar',
	},
	{
		id: 5237,
		code: 'L701',
		label: 'Acné conglobado',
	},
	{
		id: 5238,
		code: 'L702',
		label: 'Acné varioliforme',
	},
	{
		id: 5239,
		code: 'L703',
		label: 'Acné tropical',
	},
	{
		id: 5240,
		code: 'L704',
		label: 'Acné infantil',
	},
	{
		id: 5241,
		code: 'L705',
		label: 'Acné excoriado de la mujer joven',
	},
	{
		id: 5242,
		code: 'L708',
		label: 'Otros acnés',
	},
	{
		id: 5243,
		code: 'L709',
		label: 'Acné, no especificado',
	},
	{
		id: 5244,
		code: 'L71',
		label: 'Rosásea',
	},
	{
		id: 5245,
		code: 'L710',
		label: 'Dermatitis peribucal',
	},
	{
		id: 5246,
		code: 'L711',
		label: 'Rinofima',
	},
	{
		id: 5247,
		code: 'L718',
		label: 'Otras rosáceas',
	},
	{
		id: 5248,
		code: 'L719',
		label: 'Rosácea, no especificada',
	},
	{
		id: 5249,
		code: 'L72',
		label: 'Quiste folicular de la piel y del tejido subcutáneo',
	},
	{
		id: 5250,
		code: 'L720',
		label: 'Quiste epidérmico',
	},
	{
		id: 5251,
		code: 'L721',
		label: 'Quiste tricodérmico',
	},
	{
		id: 5252,
		code: 'L722',
		label: 'Esteatocistoma múltiple',
	},
	{
		id: 5253,
		code: 'L728',
		label: 'Otros quistes foliculares de la piel y del tejido subcutáneo',
	},
	{
		id: 5254,
		code: 'L729',
		label: 'Quiste folicular de la piel y del tejido subcutáneo, sin otra especificación',
	},
	{
		id: 5255,
		code: 'L73',
		label: 'Otros trastornos foliculares',
	},
	{
		id: 5256,
		code: 'L730',
		label: 'Acné queloide',
	},
	{
		id: 5257,
		code: 'L731',
		label: 'Seudofoliculitis de la barba',
	},
	{
		id: 5258,
		code: 'L732',
		label: 'Hidradenitis supurativa',
	},
	{
		id: 5259,
		code: 'L738',
		label: 'Otros trastornos foliculares especificados',
	},
	{
		id: 5260,
		code: 'L739',
		label: 'Trastorno folicular, no especificado',
	},
	{
		id: 5261,
		code: 'L74',
		label: 'Trastornos sudoríparos ecrinos',
	},
	{
		id: 5262,
		code: 'L740',
		label: 'Miliaria rubra',
	},
	{
		id: 5263,
		code: 'L741',
		label: 'Miliaria cristalina',
	},
	{
		id: 5264,
		code: 'L742',
		label: 'Miliaria profunda',
	},
	{
		id: 5265,
		code: 'L743',
		label: 'Miliaria, no especificada',
	},
	{
		id: 5266,
		code: 'L744',
		label: 'Anhidrosis',
	},
	{
		id: 5267,
		code: 'L748',
		label: 'Otros trastornos sudoríparos ecrinos',
	},
	{
		id: 5268,
		code: 'L749',
		label: 'Trastorno sudoríparo ecrino, no especificado',
	},
	{
		id: 5269,
		code: 'L75',
		label: 'Trastornos sudoríparos apocrinos',
	},
	{
		id: 5270,
		code: 'L750',
		label: 'Bromhidrosis',
	},
	{
		id: 5271,
		code: 'L751',
		label: 'Cromhidrosis',
	},
	{
		id: 5272,
		code: 'L752',
		label: 'Miliaria apocrina',
	},
	{
		id: 5273,
		code: 'L758',
		label: 'Otros trastornos sudoríparos apocrinos',
	},
	{
		id: 5274,
		code: 'L759',
		label: 'Trastorno sudoríparo apocrino, no especificado',
	},
	{
		id: 5275,
		code: 'L80X',
		label: 'Vitíligo',
	},
	{
		id: 5276,
		code: 'L81',
		label: 'Otros trastornos de la pigmentación',
	},
	{
		id: 5277,
		code: 'L810',
		label: 'Hiperpigmentación postinflamatoria',
	},
	{
		id: 5278,
		code: 'L811',
		label: 'Cloasma',
	},
	{
		id: 5279,
		code: 'L812',
		label: 'Efélide',
	},
	{
		id: 5280,
		code: 'L813',
		label: 'Manchas café con leche',
	},
	{
		id: 5281,
		code: 'L814',
		label: 'Otros tipos de hiperpigmentación melanodérmica',
	},
	{
		id: 5282,
		code: 'L815',
		label: 'Leucodermía, no clasificada en otra parte',
	},
	{
		id: 5283,
		code: 'L816',
		label: 'Otros trastornos de disminución de la formación de la melanina',
	},
	{
		id: 5284,
		code: 'L817',
		label: 'Dermatosis purpúrica pigmentada',
	},
	{
		id: 5285,
		code: 'L818',
		label: 'Otros trastornos especificados de la pigmentación',
	},
	{
		id: 5286,
		code: 'L819',
		label: 'Trastorno de la pigmentación, no especificado',
	},
	{
		id: 5287,
		code: 'L82X',
		label: 'Queratosis seborreica',
	},
	{
		id: 5288,
		code: 'L83X',
		label: 'Acantosis nigricans',
	},
	{
		id: 5289,
		code: 'L84X',
		label: 'Callos y callosidades',
	},
	{
		id: 5290,
		code: 'L85',
		label: 'Otros tipos de engrosamiento epidérmico',
	},
	{
		id: 5291,
		code: 'L850',
		label: 'Ictiosis adquirida',
	},
	{
		id: 5292,
		code: 'L851',
		label: 'Queratosis [queratodermia] palmar y plantar adquirida',
	},
	{
		id: 5293,
		code: 'L852',
		label: 'Queratosis punctata (palmar y plantar)',
	},
	{
		id: 5294,
		code: 'L853',
		label: 'Xerosis del cutis',
	},
	{
		id: 5295,
		code: 'L858',
		label: 'Otros engrosamientos epidérmicos especificados',
	},
	{
		id: 5296,
		code: 'L859',
		label: 'Engrosamiento epidérmico, no especificado',
	},
	{
		id: 5297,
		code: 'L86X',
		label: 'Queratoderma en enfermedades clasificadas en otra parte',
	},
	{
		id: 5298,
		code: 'L87',
		label: 'Trastornos de la eliminación transepidérmica',
	},
	{
		id: 5299,
		code: 'L870',
		label: 'Queratosis folicular y parafolicular penetrante del cutis [Kyrle]',
	},
	{
		id: 5300,
		code: 'L871',
		label: 'Colagenosis perforante reactiva',
	},
	{
		id: 5301,
		code: 'L872',
		label: 'Elastosis serpiginosa perforante',
	},
	{
		id: 5302,
		code: 'L878',
		label: 'Otros trastornos de la eliminación transepidérmica',
	},
	{
		id: 5303,
		code: 'L879',
		label: 'Trastorno de la eliminación transepidérmica, no especificado',
	},
	{
		id: 5304,
		code: 'L88X',
		label: 'Pioderma gangrenoso',
	},
	{
		id: 5305,
		code: 'L89',
		label: 'Úlcera de decúbito y por área de presión',
	},
	{
		id: 5306,
		code: 'L890',
		label: 'Úlcera de decúbito y por área de presión, etapa I ',
	},
	{
		id: 5307,
		code: 'L891',
		label: 'Úlcera de decúbito, etapa II  ',
	},
	{
		id: 5308,
		code: 'L892',
		label: 'Úlcera de decúbito, etapa III ',
	},
	{
		id: 5309,
		code: 'L893',
		label: 'Úlcera de decúbito, etapa IV  ',
	},
	{
		id: 5310,
		code: 'L899',
		label: 'Úlcera de decúbito y por área de presión, no especificada',
	},
	{
		id: 5311,
		code: 'L89X',
		label: 'Úlcera de decúbito',
	},
	{
		id: 5312,
		code: 'L90',
		label: 'Trastornos atróficos de la piel',
	},
	{
		id: 5313,
		code: 'L900',
		label: 'Liquen escleroso y atrófico',
	},
	{
		id: 5314,
		code: 'L901',
		label: 'Anetodermia de Schweninger-Buzzi',
	},
	{
		id: 5315,
		code: 'L902',
		label: 'Anetodermia de Jadassohn-Pellizzari',
	},
	{
		id: 5316,
		code: 'L903',
		label: 'Atrofoderma de Pasini y Prerini',
	},
	{
		id: 5317,
		code: 'L904',
		label: 'Acrodermatitis crónica atrófica',
	},
	{
		id: 5318,
		code: 'L905',
		label: 'Fibrosis y afecciones cicatriciales de la piel',
	},
	{
		id: 5319,
		code: 'L906',
		label: 'Estrías atróficas',
	},
	{
		id: 5320,
		code: 'L908',
		label: 'Otros trastornos atróficos de la piel',
	},
	{
		id: 5321,
		code: 'L909',
		label: 'Trastorno atrófico de la piel, no especificado',
	},
	{
		id: 5322,
		code: 'L91',
		label: 'Trastornos hipertróficos de la piel',
	},
	{
		id: 5323,
		code: 'L910',
		label: 'Cicatriz hipertrófica',
	},
	{
		id: 5324,
		code: 'L918',
		label: 'Otros trastornos hipertróficos de la piel',
	},
	{
		id: 5325,
		code: 'L919',
		label: 'Trastorno hipertrófico de la piel, no especificado',
	},
	{
		id: 5326,
		code: 'L92',
		label: 'Trastornos granulomatosos de la piel y del tejido subcutáneo',
	},
	{
		id: 5327,
		code: 'L920',
		label: 'Granuloma anular',
	},
	{
		id: 5328,
		code: 'L921',
		label: 'Necrobiosis lipídica, no clasificada en otra parte',
	},
	{
		id: 5329,
		code: 'L922',
		label: 'Granuloma facial (granuloma eosinófilo de la piel)',
	},
	{
		id: 5330,
		code: 'L923',
		label: 'Granuloma por cuerpo extraño en la piel y en el tejido subcutáneo',
	},
	{
		id: 5331,
		code: 'L928',
		label: 'Otros trastornos granulomatosos de la piel y del tejido subcutáneo',
	},
	{
		id: 5332,
		code: 'L929',
		label: 'Trastorno granulomatoso de la piel y del tejido subcutáneo, no especificado',
	},
	{
		id: 5333,
		code: 'L93',
		label: 'Lupus eritematoso',
	},
	{
		id: 5334,
		code: 'L930',
		label: 'Lupus eritematoso discoide',
	},
	{
		id: 5335,
		code: 'L931',
		label: 'Lupus eritematoso cutáneo subagudo',
	},
	{
		id: 5336,
		code: 'L932',
		label: 'Otros lupus eritematosos localizados',
	},
	{
		id: 5337,
		code: 'L94',
		label: 'Otros trastornos localizados del tejido conjuntivo',
	},
	{
		id: 5338,
		code: 'L940',
		label: 'Escleroderma localizado [morfea]',
	},
	{
		id: 5339,
		code: 'L941',
		label: 'Escleroderma lineal',
	},
	{
		id: 5340,
		code: 'L942',
		label: 'Calcinosis de la piel',
	},
	{
		id: 5341,
		code: 'L943',
		label: 'Esclerodactilia',
	},
	{
		id: 5342,
		code: 'L944',
		label: 'Pápulas de Gottron',
	},
	{
		id: 5343,
		code: 'L945',
		label: 'Poiquilodermia vascular atrófica',
	},
	{
		id: 5344,
		code: 'L946',
		label: 'Ainhum',
	},
	{
		id: 5345,
		code: 'L948',
		label: 'Otros trastornos localizados especificados del tejido conjuntivo',
	},
	{
		id: 5346,
		code: 'L949',
		label: 'Trastorno localizado del tejido conjuntivo, no especificado',
	},
	{
		id: 5347,
		code: 'L95',
		label: 'Vasculitis limitada a la piel, no clasificada en otra parte',
	},
	{
		id: 5348,
		code: 'L950',
		label: 'Vasculitis livedoide',
	},
	{
		id: 5349,
		code: 'L951',
		label: 'Eritema elevatum diutinum',
	},
	{
		id: 5350,
		code: 'L958',
		label: 'Otras vasculitis limitadas a la piel',
	},
	{
		id: 5351,
		code: 'L959',
		label: 'Vasculitis limitada a la piel, sin otra especificación',
	},
	{
		id: 5352,
		code: 'L97X',
		label: 'Úlcera de miembro inferior, no clasificada en otra parte',
	},
	{
		id: 5353,
		code: 'L98',
		label: 'Otros trastornos de la piel y del tejido subcutáneo no clasificados en otra parte',
	},
	{
		id: 5354,
		code: 'L980',
		label: 'Granuloma piógeno',
	},
	{
		id: 5355,
		code: 'L981',
		label: 'Dermatitis facticia',
	},
	{
		id: 5356,
		code: 'L982',
		label: 'Dermatosis neutrófila febril [Sweet]',
	},
	{
		id: 5357,
		code: 'L983',
		label: 'Celulitis eosinófilia [Wells]',
	},
	{
		id: 5358,
		code: 'L984',
		label: 'Úlcera crónica de la piel, no clasificada en otra parte',
	},
	{
		id: 5359,
		code: 'L985',
		label: 'Mucinosis de la piel',
	},
	{
		id: 5360,
		code: 'L986',
		label: 'Otros trastornos infiltrativos de la piel y del tejido subcutáneo',
	},
	{
		id: 5361,
		code: 'L988',
		label: 'Otros trastornos especificados de la piel y del tejido subcutáneo',
	},
	{
		id: 5362,
		code: 'L989',
		label: 'Trastorno de la piel y del tejido subcutáneo, no especificado',
	},
	{
		id: 5363,
		code: 'L99',
		label: 'Otros trastornos de la piel y del tejido subcutáneo en enfermedades clasificadas en otra parte',
	},
	{
		id: 5364,
		code: 'L990',
		label: 'Amiloidosis de la piel',
	},
	{
		id: 5365,
		code: 'L998',
		label: 'Otros trastornos de la piel y del tejido subcutáneo en enfermedades clasificadas en otra parte',
	},
	{
		id: 5366,
		code: 'M00',
		label: 'Artritis piógena',
	},
	{
		id: 5367,
		code: 'M000',
		label: 'Artritis y poliartritis estafilocócica',
	},
	{
		id: 5368,
		code: 'M001',
		label: 'Artritis y poliartritis neumocócica',
	},
	{
		id: 5369,
		code: 'M002',
		label: 'Otras artritis y poliartritis estreptocócicas',
	},
	{
		id: 5370,
		code: 'M008',
		label: 'Artritis y poliartritis debidas a otros agentes bacterianos especificados',
	},
	{
		id: 5371,
		code: 'M009',
		label: 'Artritis piógena, no especificada',
	},
	{
		id: 5372,
		code: 'M01',
		label: 'Infecciones directas de la articulación en enfermedades infecciosas y parasitarias clasificadas en otra parte',
	},
	{
		id: 5373,
		code: 'M010',
		label: 'Artritis meningocócica',
	},
	{
		id: 5374,
		code: 'M011',
		label: 'Artritis tuberculosa',
	},
	{
		id: 5375,
		code: 'M012',
		label: 'Artritis en la enfermedad de Lyme',
	},
	{
		id: 5376,
		code: 'M013',
		label: 'Artritis en otras enfermedades bacterianas clasificadas en otra parte',
	},
	{
		id: 5377,
		code: 'M014',
		label: 'Artritis en rubéola',
	},
	{
		id: 5378,
		code: 'M015',
		label: 'Artritis en otras enfermedades virales clasificadas en otra parte',
	},
	{
		id: 5379,
		code: 'M016',
		label: 'Artritis en micosis',
	},
	{
		id: 5380,
		code: 'M018',
		label: 'Artritis en otras enfermedades infecciosas y parasitarias clasificadas en otra parte',
	},
	{
		id: 5381,
		code: 'M02',
		label: 'Artropatías reactivas',
	},
	{
		id: 5382,
		code: 'M020',
		label: 'Artropatía consecutiva a derivación intestinal',
	},
	{
		id: 5383,
		code: 'M021',
		label: 'Artropatía postdisentérica',
	},
	{
		id: 5384,
		code: 'M022',
		label: 'Artropatía postinmunización',
	},
	{
		id: 5385,
		code: 'M023',
		label: 'Enfermedad de Reiter',
	},
	{
		id: 5386,
		code: 'M028',
		label: 'Otras artropatías reactivas',
	},
	{
		id: 5387,
		code: 'M029',
		label: 'Artropatía reactiva, no especificada',
	},
	{
		id: 5388,
		code: 'M03',
		label: 'Artropatías postinfecciosas y reactivas en enfermedades clasificadas en otra parte',
	},
	{
		id: 5389,
		code: 'M030',
		label: 'Artritis postmeningocócica',
	},
	{
		id: 5390,
		code: 'M031',
		label: 'Artropatía postinfecciosa en sífilis',
	},
	{
		id: 5391,
		code: 'M032',
		label: 'Otras artropatías postinfecciosas en enfermedades clasificadas en otra parte',
	},
	{
		id: 5392,
		code: 'M036',
		label: 'Artropatía reactiva en otras enfermedades clasificadas en otra parte',
	},
	{
		id: 5393,
		code: 'M05',
		label: 'Artritis reumatoide seropositiva',
	},
	{
		id: 5394,
		code: 'M050',
		label: 'Síndrome de Felty',
	},
	{
		id: 5395,
		code: 'M051',
		label: 'Enfermedad reumatoide del pulmón',
	},
	{
		id: 5396,
		code: 'M052',
		label: 'Vasculitis reumatoide',
	},
	{
		id: 5397,
		code: 'M053',
		label: 'Artritis reumatoide con compromiso de otros órganos o sistemas',
	},
	{
		id: 5398,
		code: 'M058',
		label: 'Otras artritis reumatoideas seropositivas',
	},
	{
		id: 5399,
		code: 'M059',
		label: 'Artritis reumatoidea seropositiva, sin otra especificación',
	},
	{
		id: 5400,
		code: 'M06',
		label: 'Otras artritis reumatoides',
	},
	{
		id: 5401,
		code: 'M060',
		label: 'Artritis reumatoide seronegativa',
	},
	{
		id: 5402,
		code: 'M061',
		label: 'Enfermedad de Still de comienzo en el adulto',
	},
	{
		id: 5403,
		code: 'M062',
		label: 'Bursitis reumatoide',
	},
	{
		id: 5404,
		code: 'M063',
		label: 'Nódulo reumatoide',
	},
	{
		id: 5405,
		code: 'M064',
		label: 'Poliartropatía inflamatoria',
	},
	{
		id: 5406,
		code: 'M068',
		label: 'Otras artritis reumatoides especificadas',
	},
	{
		id: 5407,
		code: 'M069',
		label: 'Artritis reumatoide, no especificada',
	},
	{
		id: 5408,
		code: 'M07',
		label: 'Artropatías psoriásicas y enteropáticas',
	},
	{
		id: 5409,
		code: 'M070',
		label: 'Artropatía psoriásica interfalángica distal',
	},
	{
		id: 5410,
		code: 'M071',
		label: 'Artritis mutilante',
	},
	{
		id: 5411,
		code: 'M072',
		label: 'Espondilitis psoriásica',
	},
	{
		id: 5412,
		code: 'M073',
		label: 'Otras artropatías psoriásicas',
	},
	{
		id: 5413,
		code: 'M074',
		label: 'Artropatía en la enfermedad de Crohn [enteritis regional]',
	},
	{
		id: 5414,
		code: 'M075',
		label: 'Artropatía en la colitis ulcerativa',
	},
	{
		id: 5415,
		code: 'M076',
		label: 'Otras artropatías enteropáticas',
	},
	{
		id: 5416,
		code: 'M08',
		label: 'Artritis juvenil',
	},
	{
		id: 5417,
		code: 'M080',
		label: 'Artritis reumatoide juvenil',
	},
	{
		id: 5418,
		code: 'M081',
		label: 'Espondilitis anquilosante juvenil',
	},
	{
		id: 5419,
		code: 'M082',
		label: 'Artritis juvenil de comienzo generalizado',
	},
	{
		id: 5420,
		code: 'M083',
		label: 'Poliartritis juvenil (seronegativa)',
	},
	{
		id: 5421,
		code: 'M084',
		label: 'Artritis juvenil pauciarticular',
	},
	{
		id: 5422,
		code: 'M088',
		label: 'Otras artritis juveniles',
	},
	{
		id: 5423,
		code: 'M089',
		label: 'Artritis juvenil, no especificada',
	},
	{
		id: 5424,
		code: 'M09',
		label: 'Artritis juvenil en enfermedades clasificadas en otra parte',
	},
	{
		id: 5425,
		code: 'M090',
		label: 'Artritis juvenil en la psoriasis',
	},
	{
		id: 5426,
		code: 'M091',
		label: 'Artritis juvenil en la enfermedad de Crohn [enteritis regional]',
	},
	{
		id: 5427,
		code: 'M092',
		label: 'Artritis juvenil en la colitis ulcerativa',
	},
	{
		id: 5428,
		code: 'M098',
		label: 'Artritis juvenil en otras enfermedades clasificadas en otra parte',
	},
	{
		id: 5429,
		code: 'M10',
		label: 'Gota',
	},
	{
		id: 5430,
		code: 'M100',
		label: 'Gota idiopática',
	},
	{
		id: 5431,
		code: 'M101',
		label: 'Gota saturnina',
	},
	{
		id: 5432,
		code: 'M102',
		label: 'Gota inducida por drogas',
	},
	{
		id: 5433,
		code: 'M103',
		label: 'Gota debida a alteración de la función renal',
	},
	{
		id: 5434,
		code: 'M104',
		label: 'Otras gotas secundarias',
	},
	{
		id: 5435,
		code: 'M109',
		label: 'Gota, no especificada',
	},
	{
		id: 5436,
		code: 'M11',
		label: 'Otras artropatías por cristales',
	},
	{
		id: 5437,
		code: 'M110',
		label: 'Enfermedad por depósito de hidroxiapatita',
	},
	{
		id: 5438,
		code: 'M111',
		label: 'Condrocalcinosis familiar',
	},
	{
		id: 5439,
		code: 'M112',
		label: 'Otras condrocalcinosis',
	},
	{
		id: 5440,
		code: 'M118',
		label: 'Otras artropatías por cristales, especificadas',
	},
	{
		id: 5441,
		code: 'M119',
		label: 'Artropatía por cristales, no especificada',
	},
	{
		id: 5442,
		code: 'M12',
		label: 'Otras artropatías específicas',
	},
	{
		id: 5443,
		code: 'M120',
		label: 'Artropatía postraumática crónica [de Jaccoud]',
	},
	{
		id: 5444,
		code: 'M121',
		label: 'Enfermedad de Kaschin-Beck',
	},
	{
		id: 5445,
		code: 'M122',
		label: 'Sinovitis vellonodular (pigmentada)',
	},
	{
		id: 5446,
		code: 'M123',
		label: 'Reumatismo palindrómico',
	},
	{
		id: 5447,
		code: 'M124',
		label: 'Hidrartrosis intermitente',
	},
	{
		id: 5448,
		code: 'M125',
		label: 'Artropatía traumática',
	},
	{
		id: 5449,
		code: 'M128',
		label: 'Otras artropatías específicas, no clasificadas en otra parte',
	},
	{
		id: 5450,
		code: 'M13',
		label: 'Otras artritis',
	},
	{
		id: 5451,
		code: 'M130',
		label: 'Poliartritis, no especificada',
	},
	{
		id: 5452,
		code: 'M131',
		label: 'Monoartritis, no clasificada en otra parte',
	},
	{
		id: 5453,
		code: 'M138',
		label: 'Otras artritis especificadas',
	},
	{
		id: 5454,
		code: 'M139',
		label: 'Artritis, no especificada',
	},
	{
		id: 5455,
		code: 'M14',
		label: 'Artropatía en otras enfermedades clasificadas en otra parte',
	},
	{
		id: 5456,
		code: 'M140',
		label: 'Artropatía gotosa debida a defectos enzimáticos y a otros trastornos hereditarios, clasificados en otra parte',
	},
	{
		id: 5457,
		code: 'M141',
		label: 'Artropatía por cristales en otros trastornos metabólicos',
	},
	{
		id: 5458,
		code: 'M142',
		label: 'Artropatía diabética',
	},
	{
		id: 5459,
		code: 'M143',
		label: 'Dermatoartritis lipoide',
	},
	{
		id: 5460,
		code: 'M144',
		label: 'Artropatía en la amiloidosis',
	},
	{
		id: 5461,
		code: 'M145',
		label: 'Artropatía en otros trastornos endocrinos, metabólicos y nutricionales',
	},
	{
		id: 5462,
		code: 'M146',
		label: 'Artropatía neuropática',
	},
	{
		id: 5463,
		code: 'M148',
		label: 'Artropatía en otras enfermedades especificadas, clasificadas en otra parte',
	},
	{
		id: 5464,
		code: 'M15',
		label: 'Poliartrosis',
	},
	{
		id: 5465,
		code: 'M150',
		label: '(Osteo)artrosis primaria generalizada',
	},
	{
		id: 5466,
		code: 'M151',
		label: 'Nódulos de Heberden (con artropatía)',
	},
	{
		id: 5467,
		code: 'M152',
		label: 'Nódulos de Bouchard (con artropatía)',
	},
	{
		id: 5468,
		code: 'M153',
		label: 'Artrosis secundaria múltiple',
	},
	{
		id: 5469,
		code: 'M154',
		label: '(Osteo)artrosis erosiva',
	},
	{
		id: 5470,
		code: 'M158',
		label: 'Otras poliartrosis',
	},
	{
		id: 5471,
		code: 'M159',
		label: 'Poliartrosis, no especificada',
	},
	{
		id: 5472,
		code: 'M16',
		label: 'Coxartrosis [artrosis de la cadera]',
	},
	{
		id: 5473,
		code: 'M160',
		label: 'Coxartrosis primaria, bilateral',
	},
	{
		id: 5474,
		code: 'M161',
		label: 'Otras coxartrosis primarias',
	},
	{
		id: 5475,
		code: 'M162',
		label: 'Coxartrosis a consecuencia de displasia, bilateral',
	},
	{
		id: 5476,
		code: 'M163',
		label: 'Otras coxartrosis displásicas',
	},
	{
		id: 5477,
		code: 'M164',
		label: 'Coxartrosis postraumática, bilateral',
	},
	{
		id: 5478,
		code: 'M165',
		label: 'Otra coxartrosis postraumática',
	},
	{
		id: 5479,
		code: 'M166',
		label: 'Otra coxartrosis secundaria, bilateral',
	},
	{
		id: 5480,
		code: 'M167',
		label: 'Otras coxartrosis secundarias',
	},
	{
		id: 5481,
		code: 'M169',
		label: 'Coxartrosis, no especificada',
	},
	{
		id: 5482,
		code: 'M17',
		label: 'Gonartrosis [artrosis de la rodilla]',
	},
	{
		id: 5483,
		code: 'M170',
		label: 'Gonartrosis primaria, bilateral',
	},
	{
		id: 5484,
		code: 'M171',
		label: 'Otras gonartrosis primarias',
	},
	{
		id: 5485,
		code: 'M172',
		label: 'Gonartrosis postraumática, bilateral',
	},
	{
		id: 5486,
		code: 'M173',
		label: 'Otras gonartrosis postraumáticas',
	},
	{
		id: 5487,
		code: 'M174',
		label: 'Otras gonartrosis secundarias, bilaterales',
	},
	{
		id: 5488,
		code: 'M175',
		label: 'Otras gonartrosis secundarias',
	},
	{
		id: 5489,
		code: 'M179',
		label: 'Gonartrosis, no especificada',
	},
	{
		id: 5490,
		code: 'M18',
		label: 'Artrosis de la primera articulación carpometacarpiana',
	},
	{
		id: 5491,
		code: 'M180',
		label: 'Artrosis primaria de la primera articulación carpometacarpiana, bilateral',
	},
	{
		id: 5492,
		code: 'M181',
		label: 'Otras artrosis primarias de la primera articulación carpometacarpiana',
	},
	{
		id: 5493,
		code: 'M182',
		label: 'Artrosis postraumática de la primera articulación carpometacarpiana, bilateral',
	},
	{
		id: 5494,
		code: 'M183',
		label: 'Otras artrosis postraumáticas de la primera articulación carpometacarpiana',
	},
	{
		id: 5495,
		code: 'M184',
		label: 'Otras artrosis secundarias de la primera articulación carpometacarpiana, bilaterales',
	},
	{
		id: 5496,
		code: 'M185',
		label: 'Otras artrosis secundarias de la primera articulación carpometacarpiana',
	},
	{
		id: 5497,
		code: 'M189',
		label: 'Artrosis de la primera articulación carpometacarpiana, sin otra especificación',
	},
	{
		id: 5498,
		code: 'M19',
		label: 'Otras artrosis',
	},
	{
		id: 5499,
		code: 'M190',
		label: 'Artrosis primaria de otras articulaciones',
	},
	{
		id: 5500,
		code: 'M191',
		label: 'Artrosis postraumática de otras articulaciones',
	},
	{
		id: 5501,
		code: 'M192',
		label: 'Otras artrosis secundarias',
	},
	{
		id: 5502,
		code: 'M198',
		label: 'Otras artrosis especificadas',
	},
	{
		id: 5503,
		code: 'M199',
		label: 'Artrosis, no especificada',
	},
	{
		id: 5504,
		code: 'M20',
		label: 'Deformidades adquiridas de los dedos de la mano y del pie',
	},
	{
		id: 5505,
		code: 'M200',
		label: 'Deformidad de dedo(s) de la mano',
	},
	{
		id: 5506,
		code: 'M201',
		label: 'Hallux valgus (adquirido)',
	},
	{
		id: 5507,
		code: 'M202',
		label: 'Hallux rigidus',
	},
	{
		id: 5508,
		code: 'M203',
		label: 'Otras deformidades del hallux (adquiridas)',
	},
	{
		id: 5509,
		code: 'M204',
		label: 'Otro(s) dedo(s) del pie en martillo (adquiridos)',
	},
	{
		id: 5510,
		code: 'M205',
		label: 'Otras deformidades (adquiridas) del (de los) dedo(s) del pie',
	},
	{
		id: 5511,
		code: 'M206',
		label: 'Deformidades adquiridas de los dedos del pie, no especificadas',
	},
	{
		id: 5512,
		code: 'M21',
		label: 'Otras deformidades adquiridas de los miembros',
	},
	{
		id: 5513,
		code: 'M210',
		label: 'Deformidad en valgo, no clasificada en otra parte',
	},
	{
		id: 5514,
		code: 'M211',
		label: 'Deformidad en varo, no clasificada en otra parte',
	},
	{
		id: 5515,
		code: 'M212',
		label: 'Deformidad en flexión',
	},
	{
		id: 5516,
		code: 'M213',
		label: 'Muñeca o pie en péndulo (adquirido)',
	},
	{
		id: 5517,
		code: 'M214',
		label: 'Pie plano [pes planus] (adquirido)',
	},
	{
		id: 5518,
		code: 'M215',
		label: 'Mano o pie en garra o en talipes, pie equinovaro o zambo adquiridos',
	},
	{
		id: 5519,
		code: 'M216',
		label: 'Otras deformidades adquiridas del tobillo y del pie',
	},
	{
		id: 5520,
		code: 'M217',
		label: 'Longitud desigual de los miembros (adquirida)',
	},
	{
		id: 5521,
		code: 'M218',
		label: 'Otras deformidades adquiridas de los miembros, especificadas',
	},
	{
		id: 5522,
		code: 'M219',
		label: 'Deformidad adquirida del miembro, no especificada',
	},
	{
		id: 5523,
		code: 'M22',
		label: 'Trastornos de la rótula',
	},
	{
		id: 5524,
		code: 'M220',
		label: 'Luxación recidivante de la rótula',
	},
	{
		id: 5525,
		code: 'M221',
		label: 'Subluxación recidivante de la rótula',
	},
	{
		id: 5526,
		code: 'M222',
		label: 'Trastornos rotulofemorales',
	},
	{
		id: 5527,
		code: 'M223',
		label: 'Otros desarreglos de la rótula',
	},
	{
		id: 5528,
		code: 'M224',
		label: 'Condromalacia de la rótula',
	},
	{
		id: 5529,
		code: 'M228',
		label: 'Otros trastornos de la rótula',
	},
	{
		id: 5530,
		code: 'M229',
		label: 'Trastorno de la rótula, no especificado',
	},
	{
		id: 5531,
		code: 'M23',
		label: 'Trastorno interno de la rodilla',
	},
	{
		id: 5532,
		code: 'M230',
		label: 'Menisco quístico',
	},
	{
		id: 5533,
		code: 'M231',
		label: 'Menisco discoide (congénito)',
	},
	{
		id: 5534,
		code: 'M232',
		label: 'Trastorno de menisco debido a desgarro o lesión antigua',
	},
	{
		id: 5535,
		code: 'M233',
		label: 'Otros trastornos de los meniscos',
	},
	{
		id: 5536,
		code: 'M234',
		label: 'Cuerpo flotante en la rodilla',
	},
	{
		id: 5537,
		code: 'M235',
		label: 'Inestabilidad crónica de la rodilla',
	},
	{
		id: 5538,
		code: 'M236',
		label: 'Otra ruptura espontánea del (de los) ligamento(s) de la rodilla',
	},
	{
		id: 5539,
		code: 'M238',
		label: 'Otros trastornos internos de la rodilla',
	},
	{
		id: 5540,
		code: 'M239',
		label: 'Trastorno interno de la rodilla, no especificado',
	},
	{
		id: 5541,
		code: 'M24',
		label: 'Otros trastornos articulares específicos',
	},
	{
		id: 5542,
		code: 'M240',
		label: 'Cuerpo flotante articular',
	},
	{
		id: 5543,
		code: 'M241',
		label: 'Otros trastornos del cartílago articular',
	},
	{
		id: 5544,
		code: 'M242',
		label: 'Trastorno del ligamento',
	},
	{
		id: 5545,
		code: 'M243',
		label: 'Luxación y subluxación patológica de la articulación, no clasificada en otra parte',
	},
	{
		id: 5546,
		code: 'M244',
		label: 'Luxación y subluxación recidivante de la articulación',
	},
	{
		id: 5547,
		code: 'M245',
		label: 'Contractura articular',
	},
	{
		id: 5548,
		code: 'M246',
		label: 'Anquilosis articular',
	},
	{
		id: 5549,
		code: 'M247',
		label: 'Protrusión de acetábulo',
	},
	{
		id: 5550,
		code: 'M248',
		label: 'Otras lesiones articulares específicas, no clasificadas en otra parte',
	},
	{
		id: 5551,
		code: 'M249',
		label: 'Desarreglo articular, no especificado',
	},
	{
		id: 5552,
		code: 'M25',
		label: 'Otros trastornos articulares, no clasificados en otra parte',
	},
	{
		id: 5553,
		code: 'M250',
		label: 'Hemartrosis',
	},
	{
		id: 5554,
		code: 'M251',
		label: 'Fístula articular',
	},
	{
		id: 5555,
		code: 'M252',
		label: 'Articulación inestable',
	},
	{
		id: 5556,
		code: 'M253',
		label: 'Otras inestabilidades articulares',
	},
	{
		id: 5557,
		code: 'M254',
		label: 'Derrame articular',
	},
	{
		id: 5558,
		code: 'M255',
		label: 'Dolor en articulación',
	},
	{
		id: 5559,
		code: 'M256',
		label: 'Rigidez articular, no clasificada en otra parte',
	},
	{
		id: 5560,
		code: 'M257',
		label: 'Osteofito',
	},
	{
		id: 5561,
		code: 'M258',
		label: 'Otros trastornos articulares especificados',
	},
	{
		id: 5562,
		code: 'M259',
		label: 'Trastorno articular, no especificado',
	},
	{
		id: 5563,
		code: 'M30',
		label: 'Poliarteritis nudosa y afecciones relacionadas',
	},
	{
		id: 5564,
		code: 'M300',
		label: 'Poliarteritis nudosa',
	},
	{
		id: 5565,
		code: 'M301',
		label: 'Poliarteritis con compromiso pulmonar [Churg-Strauss]',
	},
	{
		id: 5566,
		code: 'M302',
		label: 'Poliarteritis juvenil',
	},
	{
		id: 5567,
		code: 'M303',
		label: 'Síndrome mucocutáneo linfonodular [Kawasaki]',
	},
	{
		id: 5568,
		code: 'M308',
		label: 'Otras afecciones relacionadas con la poliarteritis nudosa',
	},
	{
		id: 5569,
		code: 'M31',
		label: 'Otras vasculopatías necrotizantes',
	},
	{
		id: 5570,
		code: 'M310',
		label: 'Angiítis debida a hipersensibilidad',
	},
	{
		id: 5571,
		code: 'M311',
		label: 'Microangiopatía trombótica',
	},
	{
		id: 5572,
		code: 'M312',
		label: 'Granuloma letal de la línea media',
	},
	{
		id: 5573,
		code: 'M313',
		label: 'Granulomatosis de Wegener',
	},
	{
		id: 5574,
		code: 'M314',
		label: 'Síndrome del cayado de la aorta [Takayasu]',
	},
	{
		id: 5575,
		code: 'M315',
		label: 'Arteritis de células gigantes con polimialgia reumática',
	},
	{
		id: 5576,
		code: 'M316',
		label: 'Otras arteritis de células gigantes',
	},
	{
		id: 5577,
		code: 'M317',
		label: 'Poliangiítis microscópica',
	},
	{
		id: 5578,
		code: 'M318',
		label: 'Otras vasculopatías necrotizantes especificadas',
	},
	{
		id: 5579,
		code: 'M319',
		label: 'Vasculopatía necrotizante, no especificada',
	},
	{
		id: 5580,
		code: 'M32',
		label: 'Lupus eritematoso sistémico',
	},
	{
		id: 5581,
		code: 'M320',
		label: 'Lupus eritematoso sistémico, inducido por drogas',
	},
	{
		id: 5582,
		code: 'M321',
		label: 'Lupus eritematoso sistémico con compromiso de órganos o sistemas',
	},
	{
		id: 5583,
		code: 'M328',
		label: 'Otras formas de lupus eritematoso sistémico',
	},
	{
		id: 5584,
		code: 'M329',
		label: 'Lupus eritematoso sistémico, sin otra especificación',
	},
	{
		id: 5585,
		code: 'M33',
		label: 'Dermatopolimiositis',
	},
	{
		id: 5586,
		code: 'M330',
		label: 'Dermatomiositis juvenil',
	},
	{
		id: 5587,
		code: 'M331',
		label: 'Otras dermatomiositis',
	},
	{
		id: 5588,
		code: 'M332',
		label: 'Polimiositis',
	},
	{
		id: 5589,
		code: 'M339',
		label: 'Dermatopolimiositis, no especificada',
	},
	{
		id: 5590,
		code: 'M34',
		label: 'Esclerosis sistémica',
	},
	{
		id: 5591,
		code: 'M340',
		label: 'Esclerosis sistémica progresiva',
	},
	{
		id: 5592,
		code: 'M341',
		label: 'Síndrome CR(E)ST',
	},
	{
		id: 5593,
		code: 'M342',
		label: 'Esclerosis sistémica inducida por drogas o productos químicos',
	},
	{
		id: 5594,
		code: 'M348',
		label: 'Otras formas de esclerosis sistémica',
	},
	{
		id: 5595,
		code: 'M349',
		label: 'Esclerosis sistémica, no especificada',
	},
	{
		id: 5596,
		code: 'M35',
		label: 'Otro compromiso sistémico del tejido conjuntivo',
	},
	{
		id: 5597,
		code: 'M350',
		label: 'Síndrome seco [Sjögren]',
	},
	{
		id: 5598,
		code: 'M351',
		label: 'Otros síndromes superpuestos',
	},
	{
		id: 5599,
		code: 'M352',
		label: 'Enfermedad de Behcet',
	},
	{
		id: 5600,
		code: 'M353',
		label: 'Polimialgia reumática',
	},
	{
		id: 5601,
		code: 'M354',
		label: 'Fascitis difusa (eosinofílica)',
	},
	{
		id: 5602,
		code: 'M355',
		label: 'Fibrosclerosis multifocal',
	},
	{
		id: 5603,
		code: 'M356',
		label: 'Paniculitis recidivante [Weber-Christian]',
	},
	{
		id: 5604,
		code: 'M357',
		label: 'Síndrome de hipermovilidad',
	},
	{
		id: 5605,
		code: 'M358',
		label: 'Otras enfermedades especificadas con compromiso sistémico del tejido conjuntivo',
	},
	{
		id: 5606,
		code: 'M359',
		label: 'Compromiso sistémico del tejido conjuntivo, no especificado',
	},
	{
		id: 5607,
		code: 'M36',
		label: 'Trastornos sistémicos del tejido conjuntivo en enfermedades clasificadas en otra parte',
	},
	{
		id: 5608,
		code: 'M360',
		label: 'Dermato(poli)miositis en enfermedad neoplásica',
	},
	{
		id: 5609,
		code: 'M361',
		label: 'Artropatía en enfermedad neoplásica',
	},
	{
		id: 5610,
		code: 'M362',
		label: 'Artropatía hemofílica',
	},
	{
		id: 5611,
		code: 'M363',
		label: 'Artropatía en otros trastornos de la sangre',
	},
	{
		id: 5612,
		code: 'M364',
		label: 'Artropatía en reacciones de hipersensibilidad clasificadas en otra parte',
	},
	{
		id: 5613,
		code: 'M368',
		label: 'Trastornos sistémicos del tejido conjuntivo en otras enfermedades clasificadas en otra parte',
	},
	{
		id: 5614,
		code: 'M40',
		label: 'Cifosis y lordosis',
	},
	{
		id: 5615,
		code: 'M400',
		label: 'Cifosis postural',
	},
	{
		id: 5616,
		code: 'M401',
		label: 'Otras cifosis secundarias',
	},
	{
		id: 5617,
		code: 'M402',
		label: 'Otras cifosis y las no especificadas',
	},
	{
		id: 5618,
		code: 'M403',
		label: 'Síndrome de espalda plana',
	},
	{
		id: 5619,
		code: 'M404',
		label: 'Otras lordosis',
	},
	{
		id: 5620,
		code: 'M405',
		label: 'Lordosis, no especificada',
	},
	{
		id: 5621,
		code: 'M41',
		label: 'Escoliosis',
	},
	{
		id: 5622,
		code: 'M410',
		label: 'Escoliosis idiopática infantil',
	},
	{
		id: 5623,
		code: 'M411',
		label: 'Escoliosis idiopática juvenil',
	},
	{
		id: 5624,
		code: 'M412',
		label: 'Otras escoliosis idiopáticas',
	},
	{
		id: 5625,
		code: 'M413',
		label: 'Escoliosis toracogénica',
	},
	{
		id: 5626,
		code: 'M414',
		label: 'Escoliosis neuromuscular',
	},
	{
		id: 5627,
		code: 'M415',
		label: 'Otras escoliosis secundarias',
	},
	{
		id: 5628,
		code: 'M418',
		label: 'Otras formas de escoliosis',
	},
	{
		id: 5629,
		code: 'M419',
		label: 'Escoliosis, no especificada',
	},
	{
		id: 5630,
		code: 'M42',
		label: 'Osteocondrosis de la columna vertebral',
	},
	{
		id: 5631,
		code: 'M420',
		label: 'Osteocondrosis juvenil de la columna vertebral',
	},
	{
		id: 5632,
		code: 'M421',
		label: 'Osteocondrosis de la columna vertebral del adulto',
	},
	{
		id: 5633,
		code: 'M429',
		label: 'Osteocondrosis vertebral, no especificada',
	},
	{
		id: 5634,
		code: 'M43',
		label: 'Otras dorsopatías deformantes',
	},
	{
		id: 5635,
		code: 'M430',
		label: 'Espondilólisis',
	},
	{
		id: 5636,
		code: 'M431',
		label: 'Espondilolistesis',
	},
	{
		id: 5637,
		code: 'M432',
		label: 'Otras fusiones de la columna vertebral',
	},
	{
		id: 5638,
		code: 'M433',
		label: 'Subluxación atlanto-axoidea recurrente, con mielopatía',
	},
	{
		id: 5639,
		code: 'M434',
		label: 'Otras subluxaciones atlanto-axoideas recurrentes',
	},
	{
		id: 5640,
		code: 'M435',
		label: 'Otras subluxaciones vertebrales recurrentes',
	},
	{
		id: 5641,
		code: 'M436',
		label: 'Tortícolis',
	},
	{
		id: 5642,
		code: 'M438',
		label: 'Otras dorsopatías deformantes de la columna vertebral especificadas',
	},
	{
		id: 5643,
		code: 'M439',
		label: 'Dorsopatía deformante, no especificada',
	},
	{
		id: 5644,
		code: 'M45X',
		label: 'Espondilitis anquilosante',
	},
	{
		id: 5645,
		code: 'M46',
		label: 'Otras espondilopatías inflamatorias',
	},
	{
		id: 5646,
		code: 'M460',
		label: 'Entesopatía vertebral',
	},
	{
		id: 5647,
		code: 'M461',
		label: 'Sacroiliítis, no clasificada en otra parte',
	},
	{
		id: 5648,
		code: 'M462',
		label: 'Osteomielitis de vértebra',
	},
	{
		id: 5649,
		code: 'M463',
		label: 'Infección de disco intervertebral (piógena)',
	},
	{
		id: 5650,
		code: 'M464',
		label: 'Discitis, no especificada',
	},
	{
		id: 5651,
		code: 'M465',
		label: 'Otras espondilopatías infecciosas',
	},
	{
		id: 5652,
		code: 'M468',
		label: 'Otras espondilopatías inflamatorias especificadas',
	},
	{
		id: 5653,
		code: 'M469',
		label: 'Espondilopatía inflamatoria, no especificada',
	},
	{
		id: 5654,
		code: 'M47',
		label: 'Espondilosis',
	},
	{
		id: 5655,
		code: 'M470',
		label: 'Síndromes de compresión de la arteria espinal o vertebral anterior',
	},
	{
		id: 5656,
		code: 'M471',
		label: 'Otras espondilosis con mielopatía',
	},
	{
		id: 5657,
		code: 'M472',
		label: 'Otras espondilosis con radiculopatía',
	},
	{
		id: 5658,
		code: 'M478',
		label: 'Otras espondilosis',
	},
	{
		id: 5659,
		code: 'M479',
		label: 'Espondilosis, no especificada',
	},
	{
		id: 5660,
		code: 'M48',
		label: 'Otras espondilopatías',
	},
	{
		id: 5661,
		code: 'M480',
		label: 'Estenosis espinal',
	},
	{
		id: 5662,
		code: 'M481',
		label: 'Hiperostosis anquilosante [Forestier]',
	},
	{
		id: 5663,
		code: 'M482',
		label: 'Espondilopatía interespinosa (vértebras "en beso")',
	},
	{
		id: 5664,
		code: 'M483',
		label: 'Espondilopatía traumática',
	},
	{
		id: 5665,
		code: 'M484',
		label: 'Fractura de vértebra por fatiga',
	},
	{
		id: 5666,
		code: 'M485',
		label: 'Vértebra colapsada, no clasificada en otra parte',
	},
	{
		id: 5667,
		code: 'M488',
		label: 'Otras espondilopatías especificadas',
	},
	{
		id: 5668,
		code: 'M489',
		label: 'Espondilopatía, no especificada',
	},
	{
		id: 5669,
		code: 'M49',
		label: 'Espondilopatías en enfermedades clasificadas en otra parte',
	},
	{
		id: 5670,
		code: 'M490',
		label: 'Tuberculosis de la columna vertebral',
	},
	{
		id: 5671,
		code: 'M491',
		label: 'Espondilitis por brucelosis',
	},
	{
		id: 5672,
		code: 'M492',
		label: 'Espondilitis por enterobacterias',
	},
	{
		id: 5673,
		code: 'M493',
		label: 'Espondilopatía en otras enfermedades infecciosas y parasitarias clasificadas en otra parte',
	},
	{
		id: 5674,
		code: 'M494',
		label: 'Espondilopatía neuropática',
	},
	{
		id: 5675,
		code: 'M495',
		label: 'Vértebra colapsada en enfermedades clasificadas en otra parte',
	},
	{
		id: 5676,
		code: 'M498',
		label: 'Espondilopatía en otras enfermedades clasificadas en otra parte',
	},
	{
		id: 5677,
		code: 'M50',
		label: 'Trastornos de disco cervical',
	},
	{
		id: 5678,
		code: 'M500',
		label: 'Trastorno de disco cervical con mielopatía',
	},
	{
		id: 5679,
		code: 'M501',
		label: 'Trastorno de disco cervical con radiculopatía',
	},
	{
		id: 5680,
		code: 'M502',
		label: 'Otros desplazamientos de disco cervical',
	},
	{
		id: 5681,
		code: 'M503',
		label: 'Otras degeneraciones de disco cervical',
	},
	{
		id: 5682,
		code: 'M508',
		label: 'Otros trastornos de disco cervical',
	},
	{
		id: 5683,
		code: 'M509',
		label: 'Trastorno de disco cervical, no especificado',
	},
	{
		id: 5684,
		code: 'M51',
		label: 'Otros trastornos de los discos intervertebrales',
	},
	{
		id: 5685,
		code: 'M510',
		label: 'Trastornos de discos intervertebrales lumbares y otros, con mielopatía',
	},
	{
		id: 5686,
		code: 'M511',
		label: 'Trastornos de disco lumbar y otros, con radiculopatía',
	},
	{
		id: 5687,
		code: 'M512',
		label: 'Otros desplazamientos especificados de disco intervertebral ',
	},
	{
		id: 5688,
		code: 'M513',
		label: 'Otras degeneraciones especificadas de disco intervertebral',
	},
	{
		id: 5689,
		code: 'M514',
		label: 'Nódulos de Schmorl',
	},
	{
		id: 5690,
		code: 'M518',
		label: 'Otros trastornos especificados de los discos intervertebrales',
	},
	{
		id: 5691,
		code: 'M519',
		label: 'Trastorno de los discos intervertebrales, no especificado',
	},
	{
		id: 5692,
		code: 'M53',
		label: 'Otras dorsopatías, no clasificadas en otra parte',
	},
	{
		id: 5693,
		code: 'M530',
		label: 'Síndrome cervicocraneal',
	},
	{
		id: 5694,
		code: 'M531',
		label: 'Síndrome cervicobraquial',
	},
	{
		id: 5695,
		code: 'M532',
		label: 'Inestabilidad de la columna vertebral',
	},
	{
		id: 5696,
		code: 'M533',
		label: 'Trastornos sacrococcígeos, no clasificados en otra parte',
	},
	{
		id: 5697,
		code: 'M538',
		label: 'Otras dorsopatías especificadas',
	},
	{
		id: 5698,
		code: 'M539',
		label: 'Dorsopatía, no especificada',
	},
	{
		id: 5699,
		code: 'M54',
		label: 'Dorsalgia',
	},
	{
		id: 5700,
		code: 'M540',
		label: 'Paniculitis que afecta regiones del cuello y de la espalda',
	},
	{
		id: 5701,
		code: 'M541',
		label: 'Radiculopatía',
	},
	{
		id: 5702,
		code: 'M542',
		label: 'Cervicalgia',
	},
	{
		id: 5703,
		code: 'M543',
		label: 'Ciática',
	},
	{
		id: 5704,
		code: 'M544',
		label: 'Lumbago con ciática',
	},
	{
		id: 5705,
		code: 'M545',
		label: 'Lumbago no especificado',
	},
	{
		id: 5706,
		code: 'M546',
		label: 'Dolor en la columna dorsal',
	},
	{
		id: 5707,
		code: 'M548',
		label: 'Otras dorsalgias',
	},
	{
		id: 5708,
		code: 'M549',
		label: 'Dorsalgia, no especificada',
	},
	{
		id: 5709,
		code: 'M60',
		label: 'Miositis',
	},
	{
		id: 5710,
		code: 'M600',
		label: 'Miositis infecciosa',
	},
	{
		id: 5711,
		code: 'M601',
		label: 'Miositis intersticial',
	},
	{
		id: 5712,
		code: 'M602',
		label: 'Granuloma por cuerpo extraño en tejido blando, no clasificado en otra parte',
	},
	{
		id: 5713,
		code: 'M608',
		label: 'Otras miositis',
	},
	{
		id: 5714,
		code: 'M609',
		label: 'Miositis, no especificada',
	},
	{
		id: 5715,
		code: 'M61',
		label: 'Calcificación y osificación del músculo',
	},
	{
		id: 5716,
		code: 'M610',
		label: 'Miositis osificante traumática',
	},
	{
		id: 5717,
		code: 'M611',
		label: 'Miositis osificante progresiva',
	},
	{
		id: 5718,
		code: 'M612',
		label: 'Calcificación y osificación paralítica del músculo',
	},
	{
		id: 5719,
		code: 'M613',
		label: 'Calcificación y osificación de los músculos asociadas con quemaduras',
	},
	{
		id: 5720,
		code: 'M614',
		label: 'Otras calcificaciones del músculo',
	},
	{
		id: 5721,
		code: 'M615',
		label: 'Otras osificaciones del músculo',
	},
	{
		id: 5722,
		code: 'M619',
		label: 'Calcificación y osificación del músculo, no especificada',
	},
	{
		id: 5723,
		code: 'M62',
		label: 'Otros trastornos de los músculos',
	},
	{
		id: 5724,
		code: 'M620',
		label: 'Diástasis del músculo',
	},
	{
		id: 5725,
		code: 'M621',
		label: 'Otros desgarros (no traumáticos) del músculo',
	},
	{
		id: 5726,
		code: 'M622',
		label: 'Infarto isquémico del músculo',
	},
	{
		id: 5727,
		code: 'M623',
		label: 'Síndrome de inmovilidad (parapléjico)',
	},
	{
		id: 5728,
		code: 'M624',
		label: 'Contractura muscular',
	},
	{
		id: 5729,
		code: 'M625',
		label: 'Atrofia y desgaste musculares, no clasificados en otra parte',
	},
	{
		id: 5730,
		code: 'M626',
		label: 'Distensión muscular',
	},
	{
		id: 5731,
		code: 'M628',
		label: 'Otros trastornos especificados de los músculos',
	},
	{
		id: 5732,
		code: 'M629',
		label: 'Trastorno muscular, no especificado',
	},
	{
		id: 5733,
		code: 'M63',
		label: 'Trastornos de los músculos en enfermedades clasificadas en otra parte',
	},
	{
		id: 5734,
		code: 'M630',
		label: 'Miositis en enfermedades bacterianas clasificadas en otra parte',
	},
	{
		id: 5735,
		code: 'M631',
		label: 'Miositis en infecciones por protozoarios y parásitos clasificados en otra parte',
	},
	{
		id: 5736,
		code: 'M632',
		label: 'Miositis en otras enfermedades infecciosas clasificadas en otra parte',
	},
	{
		id: 5737,
		code: 'M633',
		label: 'Miositis en sarcoidosis',
	},
	{
		id: 5738,
		code: 'M638',
		label: 'Otros trastornos de los músculos en enfermedades clasificadas en otra parte',
	},
	{
		id: 5739,
		code: 'M65',
		label: 'Sinovitis y tenosinovitis',
	},
	{
		id: 5740,
		code: 'M650',
		label: 'Absceso de vaina tendinosa',
	},
	{
		id: 5741,
		code: 'M651',
		label: 'Otras (teno)sinovitis infecciosas',
	},
	{
		id: 5742,
		code: 'M652',
		label: 'Tendinitis calcificada',
	},
	{
		id: 5743,
		code: 'M653',
		label: 'Dedo en gatillo',
	},
	{
		id: 5744,
		code: 'M654',
		label: 'Tenosinovitis de estiloides radial [de Quervain]',
	},
	{
		id: 5745,
		code: 'M658',
		label: 'Otras sinovitis y tenosinovitis',
	},
	{
		id: 5746,
		code: 'M659',
		label: 'Sinovitis y tenosinovitis, no especificada',
	},
	{
		id: 5747,
		code: 'M66',
		label: 'Ruptura espontánea de la sinovia y del tendón',
	},
	{
		id: 5748,
		code: 'M660',
		label: 'Ruptura de quiste sinovial poplíteo',
	},
	{
		id: 5749,
		code: 'M661',
		label: 'Ruptura de la sinovia',
	},
	{
		id: 5750,
		code: 'M662',
		label: 'Ruptura espontánea de tendones extensores',
	},
	{
		id: 5751,
		code: 'M663',
		label: 'Ruptura espontánea de tendones flexores',
	},
	{
		id: 5752,
		code: 'M664',
		label: 'Ruptura espontánea de otros tendones',
	},
	{
		id: 5753,
		code: 'M665',
		label: 'Ruptura espontánea de tendón no especificado',
	},
	{
		id: 5754,
		code: 'M67',
		label: 'Otros trastornos de la sinovia y del tendón',
	},
	{
		id: 5755,
		code: 'M670',
		label: 'Acortamiento del tendón de Aquiles (adquirido)',
	},
	{
		id: 5756,
		code: 'M671',
		label: 'Otras contracturas de tendón (vaina)',
	},
	{
		id: 5757,
		code: 'M672',
		label: 'Hipertrofia sinovial, no clasificada en otra parte',
	},
	{
		id: 5758,
		code: 'M673',
		label: 'Sinovitis transitoria',
	},
	{
		id: 5759,
		code: 'M674',
		label: 'Ganglión',
	},
	{
		id: 5760,
		code: 'M678',
		label: 'Otros trastornos especificados de la sinovia y del tendón',
	},
	{
		id: 5761,
		code: 'M679',
		label: 'Trastorno sinovial y tendinoso, no especificado',
	},
	{
		id: 5762,
		code: 'M68',
		label: 'Trastornos de los tendones y de la sinovia en enfermedades clasificadas en otra parte',
	},
	{
		id: 5763,
		code: 'M680',
		label: 'Sinovitis y tenosinovitis en enfermedades bacterianas clasificadas en otra parte',
	},
	{
		id: 5764,
		code: 'M688',
		label: 'Otros trastornos sinoviales y tendinosos en enfermedades clasificadas en otra parte',
	},
	{
		id: 5765,
		code: 'M70',
		label: 'Trastornos de los tejidos blandos relacionados con el uso, el uso excesivo y la presión',
	},
	{
		id: 5766,
		code: 'M700',
		label: 'Sinovitis crepitante crónica de la mano y de la muñeca',
	},
	{
		id: 5767,
		code: 'M701',
		label: 'Bursitis de la mano',
	},
	{
		id: 5768,
		code: 'M702',
		label: 'Bursitis del olécranon',
	},
	{
		id: 5769,
		code: 'M703',
		label: 'Otras bursitis del codo',
	},
	{
		id: 5770,
		code: 'M704',
		label: 'Otras bursitis prerrotulianas',
	},
	{
		id: 5771,
		code: 'M705',
		label: 'Otras bursitis de la rodilla',
	},
	{
		id: 5772,
		code: 'M706',
		label: 'Bursitis del trocánter',
	},
	{
		id: 5773,
		code: 'M707',
		label: 'Otras bursitis de la cadera',
	},
	{
		id: 5774,
		code: 'M708',
		label: 'Otros trastornos de los tejidos blandos relacionados con el uso, el uso excesivo y la presión',
	},
	{
		id: 5775,
		code: 'M709',
		label: 'Trastorno no especificado de los tejidos blandos relacionado con el uso, el uso excesivo y la presión',
	},
	{
		id: 5776,
		code: 'M71',
		label: 'Otras bursopatías',
	},
	{
		id: 5777,
		code: 'M710',
		label: 'Absceso de la bolsa sinovial',
	},
	{
		id: 5778,
		code: 'M711',
		label: 'Otras bursitis infecciosas',
	},
	{
		id: 5779,
		code: 'M712',
		label: 'Quiste sinovial del hueco poplíteo [de Baker]',
	},
	{
		id: 5780,
		code: 'M713',
		label: 'Otros quistes de la bolsa serosa',
	},
	{
		id: 5781,
		code: 'M714',
		label: 'Depósito de calcio  en la bolsa serosa',
	},
	{
		id: 5782,
		code: 'M715',
		label: 'Otras bursitis, no clasificadas en otra parte',
	},
	{
		id: 5783,
		code: 'M718',
		label: 'Otros trastornos especificados de la bolsa serosa',
	},
	{
		id: 5784,
		code: 'M719',
		label: 'Bursopatía, no especificada',
	},
	{
		id: 5785,
		code: 'M72',
		label: 'Trastornos fibroblásticos',
	},
	{
		id: 5786,
		code: 'M720',
		label: 'Fibromatosis de la aponeurosis palmar [Dupuytren]',
	},
	{
		id: 5787,
		code: 'M721',
		label: 'Nódulos interfalángicos',
	},
	{
		id: 5788,
		code: 'M722',
		label: 'Fibromatosis de la aponeurosis plantar',
	},
	{
		id: 5789,
		code: 'M723',
		label: 'Fascitis nodular',
	},
	{
		id: 5790,
		code: 'M724',
		label: 'Fibromatosis seudosarcomatosa',
	},
	{
		id: 5791,
		code: 'M725',
		label: 'Fascitis, No clasificada en otra parte',
	},
	{
		id: 5792,
		code: 'M726',
		label: 'Fascitis necrotizante',
	},
	{
		id: 5793,
		code: 'M728',
		label: 'Otros trastornos fibroblásticos',
	},
	{
		id: 5794,
		code: 'M729',
		label: 'Trastorno fibroblástico, no especificado',
	},
	{
		id: 5795,
		code: 'M73',
		label: 'Trastornos de los tejidos blandos en enfermedades clasificadas en otra parte',
	},
	{
		id: 5796,
		code: 'M730',
		label: 'Bursitis gonocócica',
	},
	{
		id: 5797,
		code: 'M731',
		label: 'Bursitis sifilítica',
	},
	{
		id: 5798,
		code: 'M738',
		label: 'Otros trastornos de los tejidos blandos en enfermedades clasificadas en otra parte',
	},
	{
		id: 5799,
		code: 'M75',
		label: 'Lesiones del hombro',
	},
	{
		id: 5800,
		code: 'M750',
		label: 'Capsulitis adhesiva del hombro',
	},
	{
		id: 5801,
		code: 'M751',
		label: 'Síndrome del manguito rotatorio',
	},
	{
		id: 5802,
		code: 'M752',
		label: 'Tendinitis del bíceps',
	},
	{
		id: 5803,
		code: 'M753',
		label: 'Tendinitis calcificante del hombro',
	},
	{
		id: 5804,
		code: 'M754',
		label: 'Síndrome de abducción dolorosa del hombro',
	},
	{
		id: 5805,
		code: 'M755',
		label: 'Bursitis del hombro',
	},
	{
		id: 5806,
		code: 'M758',
		label: 'Otras lesiones del hombro',
	},
	{
		id: 5807,
		code: 'M759',
		label: 'Lesión del hombro, no especificada',
	},
	{
		id: 5808,
		code: 'M76',
		label: 'Entesopatías del miembro inferior, excluido el pie',
	},
	{
		id: 5809,
		code: 'M760',
		label: 'Tendinitis del glúteo',
	},
	{
		id: 5810,
		code: 'M761',
		label: 'Tendinitis del psoas',
	},
	{
		id: 5811,
		code: 'M762',
		label: 'Espolón de la cresta iliaca',
	},
	{
		id: 5812,
		code: 'M763',
		label: 'Síndrome del tendón del tensor de la fascia lata',
	},
	{
		id: 5813,
		code: 'M764',
		label: 'Bursitis tibial colateral [Pellegrini-Stieda]',
	},
	{
		id: 5814,
		code: 'M765',
		label: 'Tendinitis rotuliana',
	},
	{
		id: 5815,
		code: 'M766',
		label: 'Tendinitis aquiliana',
	},
	{
		id: 5816,
		code: 'M767',
		label: 'Tendinitis peroneal',
	},
	{
		id: 5817,
		code: 'M768',
		label: 'Otras entesopatías del miembro inferior, excluido el pie',
	},
	{
		id: 5818,
		code: 'M769',
		label: 'Entesopatía del miembro inferior, no especificada',
	},
	{
		id: 5819,
		code: 'M77',
		label: 'Otras entesopatías',
	},
	{
		id: 5820,
		code: 'M770',
		label: 'Epicondilitis media',
	},
	{
		id: 5821,
		code: 'M771',
		label: 'Epicondilitis lateral',
	},
	{
		id: 5822,
		code: 'M772',
		label: 'Periartritis de la muñeca',
	},
	{
		id: 5823,
		code: 'M773',
		label: 'Espolón calcáneo',
	},
	{
		id: 5824,
		code: 'M774',
		label: 'Metatarsalgia',
	},
	{
		id: 5825,
		code: 'M775',
		label: 'Otras entesopatías del pie',
	},
	{
		id: 5826,
		code: 'M778',
		label: 'Otras entesopatías, no clasificadas en otra parte',
	},
	{
		id: 5827,
		code: 'M779',
		label: 'Entesopatía, no especificada',
	},
	{
		id: 5828,
		code: 'M79',
		label: 'Otros trastornos de los tejidos blandos, no clasificados en otra parte',
	},
	{
		id: 5829,
		code: 'M790',
		label: 'Reumatismo, no especificado',
	},
	{
		id: 5830,
		code: 'M791',
		label: 'Mialgia',
	},
	{
		id: 5831,
		code: 'M792',
		label: 'Neuralgia y neuritis, no especificadas',
	},
	{
		id: 5832,
		code: 'M793',
		label: 'Paniculitis, no especificada',
	},
	{
		id: 5833,
		code: 'M794',
		label: 'Hipertrofia de paquete adiposo (infrarrotuliano)',
	},
	{
		id: 5834,
		code: 'M795',
		label: 'Cuerpo extraño residual en tejido blando',
	},
	{
		id: 5835,
		code: 'M796',
		label: 'Dolor en miembro',
	},
	{
		id: 5836,
		code: 'M797',
		label: 'Fibromialgia',
	},
	{
		id: 5837,
		code: 'M798',
		label: 'Otros trastornos especificados de los tejidos blandos',
	},
	{
		id: 5838,
		code: 'M799',
		label: 'Trastorno de los tejidos blandos, no especificado',
	},
	{
		id: 5839,
		code: 'M80',
		label: 'Osteoporosis con fractura patológica',
	},
	{
		id: 5840,
		code: 'M800',
		label: 'Osteoporosis postmenopáusica, con fractura patológica',
	},
	{
		id: 5841,
		code: 'M801',
		label: 'Osteoporosis postooforectomía, con fractura patológica',
	},
	{
		id: 5842,
		code: 'M802',
		label: 'Osteoporosis por desuso, con fractura patológica',
	},
	{
		id: 5843,
		code: 'M803',
		label: 'Osteoporosis por malabsorción postquirúrgica, con fractura patológica',
	},
	{
		id: 5844,
		code: 'M804',
		label: 'Osteoporosis inducida por drogas, con fractura patológica',
	},
	{
		id: 5845,
		code: 'M805',
		label: 'Osteoporosis idiopática, con fractura patológica',
	},
	{
		id: 5846,
		code: 'M808',
		label: 'Otras osteoporosis, con fractura patológica',
	},
	{
		id: 5847,
		code: 'M809',
		label: 'Osteoporosis no especificada, con fractura patológica',
	},
	{
		id: 5848,
		code: 'M81',
		label: 'Osteoporosis sin fractura patológica',
	},
	{
		id: 5849,
		code: 'M810',
		label: 'Osteoporosis postmenopáusica, sin fractura patológica',
	},
	{
		id: 5850,
		code: 'M811',
		label: 'Osteoporosis postooforectomía, sin fractura patológica',
	},
	{
		id: 5851,
		code: 'M812',
		label: 'Osteoporosis por desuso, sin fractura patológica',
	},
	{
		id: 5852,
		code: 'M813',
		label: 'Osteoporosis por malabsorción postquirúrgica, sin fractura patológica',
	},
	{
		id: 5853,
		code: 'M814',
		label: 'Osteoporosis inducida por drogas, sin fractura patológica',
	},
	{
		id: 5854,
		code: 'M815',
		label: 'Osteoporosis idiopática, sin fractura patológica',
	},
	{
		id: 5855,
		code: 'M816',
		label: 'Osteoporosis localizada [Lequesne], sin fractura patológica',
	},
	{
		id: 5856,
		code: 'M818',
		label: 'Otras osteoporosis, sin fractura patológica',
	},
	{
		id: 5857,
		code: 'M819',
		label: 'Osteoporosis no especificada, sin fractura patológica',
	},
	{
		id: 5858,
		code: 'M82',
		label: 'Osteoporosis en enfermedades clasificadas en otra parte',
	},
	{
		id: 5859,
		code: 'M820',
		label: 'Osteoporosis en mielomatosis múltiple',
	},
	{
		id: 5860,
		code: 'M821',
		label: 'Osteoporosis en trastornos endocrinos',
	},
	{
		id: 5861,
		code: 'M828',
		label: 'Osteoporosis en otras enfermedades clasificadas en otra parte',
	},
	{
		id: 5862,
		code: 'M83',
		label: 'Osteomalacia del adulto',
	},
	{
		id: 5863,
		code: 'M830',
		label: 'Osteomalacia puerperal',
	},
	{
		id: 5864,
		code: 'M831',
		label: 'Osteomalacia senil',
	},
	{
		id: 5865,
		code: 'M832',
		label: 'Osteomalacia del adulto debida a malabsorción',
	},
	{
		id: 5866,
		code: 'M833',
		label: 'Osteomalacia del adulto debida a desnutrición',
	},
	{
		id: 5867,
		code: 'M834',
		label: 'Enfermedad de los huesos por aluminio',
	},
	{
		id: 5868,
		code: 'M835',
		label: 'Otras osteomalacias del adulto inducidas por drogas',
	},
	{
		id: 5869,
		code: 'M838',
		label: 'Otras osteomalacias del adulto',
	},
	{
		id: 5870,
		code: 'M839',
		label: 'Osteomalacia del adulto, no especificada',
	},
	{
		id: 5871,
		code: 'M84',
		label: 'Trastornos de la continuidad del hueso',
	},
	{
		id: 5872,
		code: 'M840',
		label: 'Consolidación defectuosa de fractura',
	},
	{
		id: 5873,
		code: 'M841',
		label: 'Falta de consolidación de fractura [seudoartrosis]',
	},
	{
		id: 5874,
		code: 'M842',
		label: 'Consolidación retardada de fractura',
	},
	{
		id: 5875,
		code: 'M843',
		label: 'Fractura por tensión, no clasificada en otra parte',
	},
	{
		id: 5876,
		code: 'M844',
		label: 'Fractura patológica, no clasificada en otra parte',
	},
	{
		id: 5877,
		code: 'M848',
		label: 'Otros trastornos de la continuidad del hueso',
	},
	{
		id: 5878,
		code: 'M849',
		label: 'Trastorno de la continuidad del hueso, no especificado',
	},
	{
		id: 5879,
		code: 'M85',
		label: 'Otros trastornos de la densidad y de la estructura óseas',
	},
	{
		id: 5880,
		code: 'M850',
		label: 'Displasia fibrosa (monostótica)',
	},
	{
		id: 5881,
		code: 'M851',
		label: 'Fluorosis del esqueleto',
	},
	{
		id: 5882,
		code: 'M852',
		label: 'Hiperostosis del cráneo',
	},
	{
		id: 5883,
		code: 'M853',
		label: 'Osteítis condensante',
	},
	{
		id: 5884,
		code: 'M854',
		label: 'Quiste óseo solitario',
	},
	{
		id: 5885,
		code: 'M855',
		label: 'Quiste óseo aneurismático',
	},
	{
		id: 5886,
		code: 'M856',
		label: 'Otros quistes óseos',
	},
	{
		id: 5887,
		code: 'M858',
		label: 'Otros trastornos especificados de la densidad y de la estructura óseas',
	},
	{
		id: 5888,
		code: 'M859',
		label: 'Trastorno de la densidad y de la estructura óseas, no especificado',
	},
	{
		id: 5889,
		code: 'M86',
		label: 'Osteomielitis',
	},
	{
		id: 5890,
		code: 'M860',
		label: 'Osteomielitis hematógena aguda',
	},
	{
		id: 5891,
		code: 'M861',
		label: 'Otras osteomielitis agudas',
	},
	{
		id: 5892,
		code: 'M862',
		label: 'Osteomielitis subaguda',
	},
	{
		id: 5893,
		code: 'M863',
		label: 'Osteomielitis multifocal crónica',
	},
	{
		id: 5894,
		code: 'M864',
		label: 'Osteomielitis crónica con drenaje del seno',
	},
	{
		id: 5895,
		code: 'M865',
		label: 'Otras osteomielitis hematógenas crónicas',
	},
	{
		id: 5896,
		code: 'M866',
		label: 'Otras osteomielitis crónicas',
	},
	{
		id: 5897,
		code: 'M868',
		label: 'Otras osteomielitis',
	},
	{
		id: 5898,
		code: 'M869',
		label: 'Osteomielitis, no especificada',
	},
	{
		id: 5899,
		code: 'M87',
		label: 'Osteonecrosis',
	},
	{
		id: 5900,
		code: 'M870',
		label: 'Necrosis aséptica idiopática ósea',
	},
	{
		id: 5901,
		code: 'M871',
		label: 'Osteonecrosis debida a drogas',
	},
	{
		id: 5902,
		code: 'M872',
		label: 'Osteonecrosis debida a traumatismo previo',
	},
	{
		id: 5903,
		code: 'M873',
		label: 'Otras osteonecrosis secundarias',
	},
	{
		id: 5904,
		code: 'M878',
		label: 'Otras osteonecrosis',
	},
	{
		id: 5905,
		code: 'M879',
		label: 'Osteonecrosis, no especificada',
	},
	{
		id: 5906,
		code: 'M88',
		label: 'Enfermedad de Paget de los huesos [osteítis deformante]',
	},
	{
		id: 5907,
		code: 'M880',
		label: 'Enfermedad de Paget del cráneo',
	},
	{
		id: 5908,
		code: 'M888',
		label: 'Enfermedad de Paget de otros huesos',
	},
	{
		id: 5909,
		code: 'M889',
		label: 'Enfermedad ósea de Paget, huesos no especificados',
	},
	{
		id: 5910,
		code: 'M89',
		label: 'Otros trastornos del hueso',
	},
	{
		id: 5911,
		code: 'M890',
		label: 'Algoneurodistrofia',
	},
	{
		id: 5912,
		code: 'M891',
		label: 'Detención del crecimiento epifisario',
	},
	{
		id: 5913,
		code: 'M892',
		label: 'Otros trastornos del desarrollo y crecimiento óseo',
	},
	{
		id: 5914,
		code: 'M893',
		label: 'Hipertrofia del hueso',
	},
	{
		id: 5915,
		code: 'M894',
		label: 'Otras osteoartropatias hipertróficas',
	},
	{
		id: 5916,
		code: 'M895',
		label: 'Osteólisis',
	},
	{
		id: 5917,
		code: 'M896',
		label: 'Osteopatía a consecuencia de poliomielitis',
	},
	{
		id: 5918,
		code: 'M898',
		label: 'Otros trastornos especificados del hueso',
	},
	{
		id: 5919,
		code: 'M899',
		label: 'Trastorno del hueso, no especificado',
	},
	{
		id: 5920,
		code: 'M90',
		label: 'Osteopatías en enfermedades clasificadas en otra parte',
	},
	{
		id: 5921,
		code: 'M900',
		label: 'Tuberculosis ósea',
	},
	{
		id: 5922,
		code: 'M901',
		label: 'Periostitis en otras enfermedades infecciosas clasificadas en otra parte',
	},
	{
		id: 5923,
		code: 'M902',
		label: 'Osteopatía en otras enfermedades infecciosas clasificadas en otra parte',
	},
	{
		id: 5924,
		code: 'M903',
		label: 'Osteonecrosis en la enfermedad causada por descompresión',
	},
	{
		id: 5925,
		code: 'M904',
		label: 'Osteonecrosis debida a hemoglobinopatía',
	},
	{
		id: 5926,
		code: 'M905',
		label: 'Osteonecrosis en otras enfermedades clasificadas en otra parte',
	},
	{
		id: 5927,
		code: 'M906',
		label: 'Osteítis deformante en enfermedad neoplásica',
	},
	{
		id: 5928,
		code: 'M907',
		label: 'Fractura ósea en enfermedad neoplásica',
	},
	{
		id: 5929,
		code: 'M908',
		label: 'Osteopatía en otras enfermedades clasificadas en otra parte',
	},
	{
		id: 5930,
		code: 'M91',
		label: 'Osteocondrosis juvenil de la cadera y de la pelvis',
	},
	{
		id: 5931,
		code: 'M910',
		label: 'Osteocondrosis juvenil de la pelvis',
	},
	{
		id: 5932,
		code: 'M911',
		label: 'Osteocondrosis juvenil de la cabeza del fémur [Legg-Calvé-Perthes]',
	},
	{
		id: 5933,
		code: 'M912',
		label: 'Coxa plana',
	},
	{
		id: 5934,
		code: 'M913',
		label: 'Pseudocoxalgia',
	},
	{
		id: 5935,
		code: 'M918',
		label: 'Otras osteocondrosis juveniles de la cadera y de la pelvis',
	},
	{
		id: 5936,
		code: 'M919',
		label: 'Osteocondrosis juvenil de la cadera y de la pelvis, sin otra especificación',
	},
	{
		id: 5937,
		code: 'M92',
		label: 'Otras osteocondrosis juveniles',
	},
	{
		id: 5938,
		code: 'M920',
		label: 'Osteocondrosis juvenil del humero',
	},
	{
		id: 5939,
		code: 'M921',
		label: 'Osteocondrosis juvenil del cúbito y del radio',
	},
	{
		id: 5940,
		code: 'M922',
		label: 'Osteocondrosis juvenil de la mano',
	},
	{
		id: 5941,
		code: 'M923',
		label: 'Otras osteocondrosis juveniles del miembro superior',
	},
	{
		id: 5942,
		code: 'M924',
		label: 'Osteocondrosis juvenil de la rótula',
	},
	{
		id: 5943,
		code: 'M925',
		label: 'Osteocondrosis juvenil de la tibia y del peroné',
	},
	{
		id: 5944,
		code: 'M926',
		label: 'Osteocondrosis juvenil del tarso',
	},
	{
		id: 5945,
		code: 'M927',
		label: 'Osteocondrosis juvenil del metatarso',
	},
	{
		id: 5946,
		code: 'M928',
		label: 'Otras osteocondrosis juveniles especificadas',
	},
	{
		id: 5947,
		code: 'M929',
		label: 'Osteocondrosis juvenil, no especificada',
	},
	{
		id: 5948,
		code: 'M93',
		label: 'Otras osteocondropatías',
	},
	{
		id: 5949,
		code: 'M930',
		label: 'Deslizamiento de la epífisis femoral superior (no traumático)',
	},
	{
		id: 5950,
		code: 'M931',
		label: 'Enfermedad de Kienböck del adulto',
	},
	{
		id: 5951,
		code: 'M932',
		label: 'Osteocondritis disecante',
	},
	{
		id: 5952,
		code: 'M938',
		label: 'Otras osteocondropatías especificadas',
	},
	{
		id: 5953,
		code: 'M939',
		label: 'Osteocondropatía, no especificada',
	},
	{
		id: 5954,
		code: 'M94',
		label: 'Otros trastornos del cartílago',
	},
	{
		id: 5955,
		code: 'M940',
		label: 'Síndrome de la articulación condrocostal [Tietze]',
	},
	{
		id: 5956,
		code: 'M941',
		label: 'Policondritis recidivante',
	},
	{
		id: 5957,
		code: 'M942',
		label: 'Condromalacia',
	},
	{
		id: 5958,
		code: 'M943',
		label: 'Condrólisis',
	},
	{
		id: 5959,
		code: 'M948',
		label: 'Otros trastornos especificados del cartílago',
	},
	{
		id: 5960,
		code: 'M949',
		label: 'Trastorno del cartílago, no especificado',
	},
	{
		id: 5961,
		code: 'M95',
		label: 'Otras deformidades adquiridas del sistema osteomuscular y del tejido conjuntivo',
	},
	{
		id: 5962,
		code: 'M950',
		label: 'Deformidad adquirida de la nariz',
	},
	{
		id: 5963,
		code: 'M951',
		label: 'Oreja en coliflor',
	},
	{
		id: 5964,
		code: 'M952',
		label: 'Otras deformidades adquiridas de la cabeza',
	},
	{
		id: 5965,
		code: 'M953',
		label: 'Deformidad adquirida del cuello',
	},
	{
		id: 5966,
		code: 'M954',
		label: 'Deformidad adquirida de costillas y tórax',
	},
	{
		id: 5967,
		code: 'M955',
		label: 'Deformidad adquirida de la pelvis',
	},
	{
		id: 5968,
		code: 'M958',
		label: 'Otras deformidades adquiridas especificadas del sistema osteomuscular',
	},
	{
		id: 5969,
		code: 'M959',
		label: 'Deformidad adquirida del sistema osteomuscular, no especificada',
	},
	{
		id: 5970,
		code: 'M96',
		label: 'Trastornos osteomusculares consecutivos a procedimientos, no clasificados en otra parte',
	},
	{
		id: 5971,
		code: 'M960',
		label: 'Seudoartrosis consecutiva a fusión o artrodesis',
	},
	{
		id: 5972,
		code: 'M961',
		label: 'Síndrome postlaminectomía, no clasificado en otra parte',
	},
	{
		id: 5973,
		code: 'M962',
		label: 'Cifosis postradiación',
	},
	{
		id: 5974,
		code: 'M963',
		label: 'Cifosis postlaminectomía',
	},
	{
		id: 5975,
		code: 'M964',
		label: 'Lordosis postquirúrgica',
	},
	{
		id: 5976,
		code: 'M965',
		label: 'Escoliosis postrradiación',
	},
	{
		id: 5977,
		code: 'M966',
		label: 'Fractura de hueso posterior a inserción o implante ortopédico, prótesis articular o placa ósea',
	},
	{
		id: 5978,
		code: 'M968',
		label: 'Otros trastornos osteomusculares consecutivos a procedimientos',
	},
	{
		id: 5979,
		code: 'M969',
		label: 'Trastornos osteomusculares no especificados consecutivos a procedimientos',
	},
	{
		id: 5980,
		code: 'M99',
		label: 'Lesiones biomecánicas, no clasificadas en otra parte',
	},
	{
		id: 5981,
		code: 'M990',
		label: 'Disfunción segmental o somática',
	},
	{
		id: 5982,
		code: 'M991',
		label: 'Complejo de subluxación (vertebral)',
	},
	{
		id: 5983,
		code: 'M992',
		label: 'Subluxación con estenosis del canal neural',
	},
	{
		id: 5984,
		code: 'M993',
		label: 'Estenosis ósea del canal neural',
	},
	{
		id: 5985,
		code: 'M994',
		label: 'Estenosis del canal neural por tejido conjuntivo',
	},
	{
		id: 5986,
		code: 'M995',
		label: 'Estenosis del canal neural por disco intervertebral',
	},
	{
		id: 5987,
		code: 'M996',
		label: 'Estenosis ósea y subluxación de los agujeros intervertebrales',
	},
	{
		id: 5988,
		code: 'M997',
		label: 'Estenosis de los agujeros intervertebrales por tejido conjuntivo o por disco intervertebral',
	},
	{
		id: 5989,
		code: 'M998',
		label: 'Otras lesiones biomecánicas',
	},
	{
		id: 5990,
		code: 'M999',
		label: 'Lesión biomecánica, no especificada',
	},
	{
		id: 5991,
		code: 'N00',
		label: 'Síndrome nefrítico agudo',
	},
	{
		id: 5992,
		code: 'N000',
		label: 'Síndrome nefrítico agudo, anomalía glomerular mínima',
	},
	{
		id: 5993,
		code: 'N001',
		label: 'Síndrome nefrítico agudo, lesiones glomerulares focales y segmentarias',
	},
	{
		id: 5994,
		code: 'N002',
		label: 'Síndrome nefrítico agudo, glomerulonefritis membranosa difusa',
	},
	{
		id: 5995,
		code: 'N003',
		label: 'Síndrome nefrítico agudo, glomerulonefritis proliferativa mesangial difusa',
	},
	{
		id: 5996,
		code: 'N004',
		label: 'Síndrome nefrítico agudo, glomerulonefritis proliferativa endocapilar difusa',
	},
	{
		id: 5997,
		code: 'N005',
		label: 'Síndrome nefrítico agudo, glomerulonefritis mesangiocapilar difusa',
	},
	{
		id: 5998,
		code: 'N006',
		label: 'Síndrome nefrítico agudo, enfermedad por depósitos densos',
	},
	{
		id: 5999,
		code: 'N007',
		label: 'Síndrome nefrítico agudo, glomerulonefritis difusa en media luna',
	},
	{
		id: 6000,
		code: 'N008',
		label: 'Síndrome nefrítico agudo, otras',
	},
	{
		id: 6001,
		code: 'N009',
		label: 'Síndrome nefrítico agudo, no especificada',
	},
	{
		id: 6002,
		code: 'N01',
		label: 'Síndrome nefrítico rápidamente progresivo',
	},
	{
		id: 6003,
		code: 'N010',
		label: 'Síndrome nefrítico rápidamente progresivo, anomalía glomerular mínima',
	},
	{
		id: 6004,
		code: 'N011',
		label: 'Síndrome nefrítico rápidamente progresivo, lesiones glomerulares focales y segmentarias',
	},
	{
		id: 6005,
		code: 'N012',
		label: 'Síndrome nefrítico rápidamente progresivo, glomerulonefritis membranosa difusa',
	},
	{
		id: 6006,
		code: 'N013',
		label: 'Síndrome nefritico rápidamente progresivo, Glomerulonefritis proliferativa mesangial difusa',
	},
	{
		id: 6007,
		code: 'N014',
		label: 'Síndrome nefrítico rápidamente progresivo, glomerulonefritis proliferativa endocapilar difusa',
	},
	{
		id: 6008,
		code: 'N015',
		label: 'Síndrome nefrítico rápidamente progresivo, glomerulonefritis mesangiocapilar difusa',
	},
	{
		id: 6009,
		code: 'N016',
		label: 'Síndrome nefrítico rápidamente progresivo,  enfermedad por depósitos densos',
	},
	{
		id: 6010,
		code: 'N017',
		label: 'Síndrome nefrítico rápidamente progresivo, glomerulonefritis difusa en media luna',
	},
	{
		id: 6011,
		code: 'N018',
		label: 'Síndrome nefrítico rápidamente progresivo, otras',
	},
	{
		id: 6012,
		code: 'N019',
		label: 'Síndrome nefrítico rápidamente progresivo, no especificada',
	},
	{
		id: 6013,
		code: 'N02',
		label: 'Hematuria recurrente y persistente',
	},
	{
		id: 6014,
		code: 'N020',
		label: 'Hematuria recurrente y persistente, anomalía glomerular mínima',
	},
	{
		id: 6015,
		code: 'N021',
		label: 'Hematuria recurrente y persistente, lesiones glomerulares focales y segmentarias',
	},
	{
		id: 6016,
		code: 'N022',
		label: 'Hematuria recurrente y persistente,  glomerulonefritis membranosa difusa',
	},
	{
		id: 6017,
		code: 'N023',
		label: 'Hematuria recurrente y persistente,  glomerulonefritis proliferativa mesangial difusa',
	},
	{
		id: 6018,
		code: 'N024',
		label: 'Hematuria recurrente y persistente,  glomerulonefritis proliferativa endocapilar difusa',
	},
	{
		id: 6019,
		code: 'N025',
		label: 'Hematuria recurrente y persistente,  glomerulonefritis mesangiocapilar difusa',
	},
	{
		id: 6020,
		code: 'N026',
		label: 'Hematuria recurrente y persistente,  enfermedad por depósitos densos',
	},
	{
		id: 6021,
		code: 'N027',
		label: 'Hematuria recurrente y persistente,  glomerulonefritis difusa en media luna',
	},
	{
		id: 6022,
		code: 'N028',
		label: 'Hematuria recurrente y persistente,  otras',
	},
	{
		id: 6023,
		code: 'N029',
		label: 'Hematuria recurrente y persistente, no especificada',
	},
	{
		id: 6024,
		code: 'N03',
		label: 'Síndrome nefrítico crónico',
	},
	{
		id: 6025,
		code: 'N030',
		label: 'Síndrome nefrítico crónico, anomalía glomerular mínima',
	},
	{
		id: 6026,
		code: 'N031',
		label: 'Síndrome nefrítico crónico, lesiones glomerulares focales y segmentarias',
	},
	{
		id: 6027,
		code: 'N032',
		label: 'Síndrome nefrítico crónico, glomerulonefritis membranosa difusa',
	},
	{
		id: 6028,
		code: 'N033',
		label: 'Síndrome nefrítico crónico, glomerulonefritis proliferativa mesangial difusa',
	},
	{
		id: 6029,
		code: 'N034',
		label: 'Síndrome nefrítico crónico, glomerulonefritis proliferativa endocapilar difusa',
	},
	{
		id: 6030,
		code: 'N035',
		label: 'Síndrome nefrítico crónico, glomerulonefritis mesangiocapilar difusa',
	},
	{
		id: 6031,
		code: 'N036',
		label: 'Síndrome nefrítico crónico, enfermedad por depósitos densos',
	},
	{
		id: 6032,
		code: 'N037',
		label: 'Síndrome nefrítico crónico, glomerulonefritis difusa en media luna',
	},
	{
		id: 6033,
		code: 'N038',
		label: 'Síndrome nefrítico crónico, otras',
	},
	{
		id: 6034,
		code: 'N039',
		label: 'Síndrome nefrítico crónico, no especificada',
	},
	{
		id: 6035,
		code: 'N04',
		label: 'Síndrome nefrótico',
	},
	{
		id: 6036,
		code: 'N040',
		label: 'Síndrome nefrótico, anomalía glomerular mínima',
	},
	{
		id: 6037,
		code: 'N041',
		label: 'Síndrome nefrótico, lesiones glomerulares focales y segmentarias',
	},
	{
		id: 6038,
		code: 'N042',
		label: 'Síndrome nefrótico, glomerulonefritis membranosa difusa',
	},
	{
		id: 6039,
		code: 'N043',
		label: 'Síndrome nefrótico, glomerulonefritis proliferativa mesangial difusa',
	},
	{
		id: 6040,
		code: 'N044',
		label: 'Síndrome nefrótico, glomerulonefritis proliferativa endocapilar difusa',
	},
	{
		id: 6041,
		code: 'N045',
		label: 'Síndrome nefrótico, glomerulonefritis mesangiocapilar difusa',
	},
	{
		id: 6042,
		code: 'N046',
		label: 'Síndrome nefrótico, enfermedad por depósitos densos',
	},
	{
		id: 6043,
		code: 'N047',
		label: 'Síndrome nefrótico, glomerulonefritis difusa en media luna',
	},
	{
		id: 6044,
		code: 'N048',
		label: 'Síndrome nefrótico, otras',
	},
	{
		id: 6045,
		code: 'N049',
		label: 'Síndrome nefrótico, no especificada',
	},
	{
		id: 6046,
		code: 'N05',
		label: 'Síndrome nefrítico no especificado',
	},
	{
		id: 6047,
		code: 'N050',
		label: 'Síndrome nefrítico no especificado, anomalía glomerular mínima',
	},
	{
		id: 6048,
		code: 'N051',
		label: 'Síndrome nefrítico no especificado, lesiones glomerulares focales y segmentarias',
	},
	{
		id: 6049,
		code: 'N052',
		label: 'Síndrome nefrítico no especificado, glomerulonefritis membranosa difusa',
	},
	{
		id: 6050,
		code: 'N053',
		label: 'Síndrome nefrítico no especificado, glomerulonefritis proliferativa mesangial difusa',
	},
	{
		id: 6051,
		code: 'N054',
		label: 'Síndrome nefrítico no especificado, glomerulonefritis proliferativa endocapilar difusa',
	},
	{
		id: 6052,
		code: 'N055',
		label: 'Síndrome nefrítico no especificado, glomerulonefritis mesangiocapilar difusa',
	},
	{
		id: 6053,
		code: 'N056',
		label: 'Síndrome nefrítico no especificado, enfermedad por depósitos densos',
	},
	{
		id: 6054,
		code: 'N057',
		label: 'Síndrome nefrítico no especificado, glomerulonefritis difusa en media luna',
	},
	{
		id: 6055,
		code: 'N058',
		label: 'Síndrome nefrítico no especificado, otras',
	},
	{
		id: 6056,
		code: 'N059',
		label: 'Síndrome nefrítico no especificada',
	},
	{
		id: 6057,
		code: 'N06',
		label: 'Proteinuria aislada con lesión morfológica especificada',
	},
	{
		id: 6058,
		code: 'N060',
		label: 'Proteinuria aislada con lesión morfológica especificada, anomalía glomerular mínima',
	},
	{
		id: 6059,
		code: 'N061',
		label: 'Proteinuria aislada con lesión morfológica especificada, lesiones glomerulares focales y segmentarias',
	},
	{
		id: 6060,
		code: 'N062',
		label: 'Proteinuria aislada con lesión morfológica especificada, glomerulonefritis membranosa difusa',
	},
	{
		id: 6061,
		code: 'N063',
		label: 'Proteinuria aislada con lesión morfológica especificada,  glomerulonefritis proliferativa mesangial difusa',
	},
	{
		id: 6062,
		code: 'N064',
		label: 'Proteinuria aislada con lesión morfológica especificada,  glomerulonefritis proliferativa endocapilar difusa',
	},
	{
		id: 6063,
		code: 'N065',
		label: 'Proteinuria aislada con lesión morfológica especificada, glomerulonefritis mesangiocapilar difusa',
	},
	{
		id: 6064,
		code: 'N066',
		label: 'Proteinuria aislada con lesión morfológica especificada,  enfermedad por depósitos densos',
	},
	{
		id: 6065,
		code: 'N067',
		label: 'Proteinuria aislada con lesión morfológica especificada, glomerulonefritis difusa en media luna',
	},
	{
		id: 6066,
		code: 'N068',
		label: 'Proteinuria aislada con lesión morfológica especificada, otras',
	},
	{
		id: 6067,
		code: 'N069',
		label: 'Proteinuria aislada con lesión morfológica especificada, no especificada',
	},
	{
		id: 6068,
		code: 'N07',
		label: 'Nefropatía hereditaria no clasificada en otra parte',
	},
	{
		id: 6069,
		code: 'N070',
		label: 'Nefropatía hereditaria, NCOP, anomalía glomerular mínima',
	},
	{
		id: 6070,
		code: 'N071',
		label: 'Nefropatía hereditaria, NCOP, lesiones glomerulares focales y segmentarias',
	},
	{
		id: 6071,
		code: 'N072',
		label: 'Nefropatía hereditaria, NCOP, glomerulonefritis membranosa difusa',
	},
	{
		id: 6072,
		code: 'N073',
		label: 'Nefropatía hereditaria, NCOP,  glomerulonefritis proliferativa mesangial difusa',
	},
	{
		id: 6073,
		code: 'N074',
		label: 'Nefropatía hereditaria, NCOP,  glomerulonefrItis proliferativa endocapilar difusa',
	},
	{
		id: 6074,
		code: 'N075',
		label: 'Nefropatía hereditaria, NCOP,  glomerulonefritis mesangiocapilar difusa',
	},
	{
		id: 6075,
		code: 'N076',
		label: 'Nefropatía hereditaria, NCOP, enfermedad por depósitos densos',
	},
	{
		id: 6076,
		code: 'N077',
		label: 'Nefropatía hereditaria, NCOP,  glomerulonefritis difusa en media luna',
	},
	{
		id: 6077,
		code: 'N078',
		label: 'Nefropatía hereditaria, NCOP,  otras',
	},
	{
		id: 6078,
		code: 'N079',
		label: 'Nefropatía hereditaria, NCOP, no especificada',
	},
	{
		id: 6079,
		code: 'N08',
		label: 'Trastornos glomerulares en enfermedades clasificadas en otra parte',
	},
	{
		id: 6080,
		code: 'N080',
		label: 'Trastornos glomerulares en enfermedades infecciosas y parasitarias clasificadas en otra parte',
	},
	{
		id: 6081,
		code: 'N081',
		label: 'Trastornos glomerulares en enfermedades neoplásicas',
	},
	{
		id: 6082,
		code: 'N082',
		label: 'Trastornos glomerulares en enfermedades de la sangre y otros trastornos que afectan el mecanismo inmunitario',
	},
	{
		id: 6083,
		code: 'N083',
		label: 'Trastornos glomerulares en diabetes mellitus',
	},
	{
		id: 6084,
		code: 'N084',
		label: 'Trastornos glomerulares en otras enfermedades endocrinas, nutricionales y metabólicas',
	},
	{
		id: 6085,
		code: 'N085',
		label: 'Trastornos glomerulares en trastornos sistémicos del tejido conjuntivo',
	},
	{
		id: 6086,
		code: 'N088',
		label: 'Trastornos glomerulares en otras enfermedades clasificadas en otra parte',
	},
	{
		id: 6087,
		code: 'N10X',
		label: 'Nefritis tubulointersticial aguda',
	},
	{
		id: 6088,
		code: 'N11',
		label: 'Nefritis  tubulointersticial crónica',
	},
	{
		id: 6089,
		code: 'N110',
		label: 'Pielonefritis crónica no obstructiva asociada con reflujo',
	},
	{
		id: 6090,
		code: 'N111',
		label: 'Pielonefritis crónica obstructiva',
	},
	{
		id: 6091,
		code: 'N118',
		label: 'Otras nefritis tubulointersticiales crónicas',
	},
	{
		id: 6092,
		code: 'N119',
		label: 'Nefritis tubulointersticial crónica, sin otra especificación',
	},
	{
		id: 6093,
		code: 'N12X',
		label: 'Nefritis tubulointersticial, no especificada como aguda o crónica',
	},
	{
		id: 6094,
		code: 'N13',
		label: 'Uropatía obstructiva y por reflujo',
	},
	{
		id: 6095,
		code: 'N130',
		label: 'Hidronefrosis con obstrucción de la unión urétero-pélvica',
	},
	{
		id: 6096,
		code: 'N131',
		label: 'Hidronefrosis con estrechez ureteral, no clasificada en otra parte',
	},
	{
		id: 6097,
		code: 'N132',
		label: 'Hidronefrosis con obstrucción por cálculos del riñón y del uréter',
	},
	{
		id: 6098,
		code: 'N133',
		label: 'Otras hidronefrosis y las no especificadas',
	},
	{
		id: 6099,
		code: 'N134',
		label: 'Hidrouréter',
	},
	{
		id: 6100,
		code: 'N135',
		label: 'Torsión y estrechez del uréter sin hidronefrosis',
	},
	{
		id: 6101,
		code: 'N136',
		label: 'Pionefrosis',
	},
	{
		id: 6102,
		code: 'N137',
		label: 'Uropatía asociada con reflujo vesicoureteral',
	},
	{
		id: 6103,
		code: 'N138',
		label: 'Otras uropatías obstructivas y por reflujo',
	},
	{
		id: 6104,
		code: 'N139',
		label: 'Uropatía obstructiva y por reflujo, sin otra especificación',
	},
	{
		id: 6105,
		code: 'N14',
		label: 'Afecciones tubulares y tubulointersticiales inducidas por drogas y por metales pesados',
	},
	{
		id: 6106,
		code: 'N140',
		label: 'Nefropatía inducida por analgésicos',
	},
	{
		id: 6107,
		code: 'N141',
		label: 'Nefropatía inducida por otras drogas, medicamentos y sustancias biológicas',
	},
	{
		id: 6108,
		code: 'N142',
		label: 'Nefropatía inducida por drogas, medicamentos y sustancias biológicas no especificadas',
	},
	{
		id: 6109,
		code: 'N143',
		label: 'Nefropatía inducida por metales pesados',
	},
	{
		id: 6110,
		code: 'N144',
		label: 'Nefropatía tóxica, no clasificada en otra parte',
	},
	{
		id: 6111,
		code: 'N15',
		label: 'Otras enfermedades renales tubulointersticiales',
	},
	{
		id: 6112,
		code: 'N150',
		label: 'Nefropatía de los Balcanes',
	},
	{
		id: 6113,
		code: 'N151',
		label: 'Absceso renal y perirrenal',
	},
	{
		id: 6114,
		code: 'N158',
		label: 'Otras enfermedades renales tubulointersticiales especificadas',
	},
	{
		id: 6115,
		code: 'N159',
		label: 'Enfermedad renal tubulointersticial, no especificada',
	},
	{
		id: 6116,
		code: 'N16',
		label: 'Trastornos renales tubulointersticiales en enfermedades clasificadas en otra parte',
	},
	{
		id: 6117,
		code: 'N160',
		label: 'Trastornos renales tubulointersticiales en enfermedades infecciosas y parasitarias clasificadas en otra parte',
	},
	{
		id: 6118,
		code: 'N161',
		label: 'Trastornos renales tubulointersticiales en enfermedades neoplásicas',
	},
	{
		id: 6119,
		code: 'N162',
		label: 'Trastornos renales tubulointersticiales en enfermedades de la sangre y en trastornos que afectan el mecanismo inmunitario',
	},
	{
		id: 6120,
		code: 'N163',
		label: 'Trastornos renales tubulointersticiales en enfermedades metabólicas',
	},
	{
		id: 6121,
		code: 'N164',
		label: 'Trastornos renales tubulointersticiales en enfermedades del tejido conjuntivo',
	},
	{
		id: 6122,
		code: 'N165',
		label: 'Trastornos renales tubulointersticiales en rechazo de trasplante',
	},
	{
		id: 6123,
		code: 'N168',
		label: 'Trastornos renales tubulointersticiales en otras enfermedades clasificadas en otra parte',
	},
	{
		id: 6124,
		code: 'N17',
		label: 'Insuficiencia renal aguda',
	},
	{
		id: 6125,
		code: 'N170',
		label: 'Insuficiencia renal aguda con necrosis tubular',
	},
	{
		id: 6126,
		code: 'N171',
		label: 'Insuficiencia renal aguda con necrosis cortical aguda',
	},
	{
		id: 6127,
		code: 'N172',
		label: 'Insuficiencia renal aguda con necrosis medular',
	},
	{
		id: 6128,
		code: 'N178',
		label: 'Otras insuficiencias renales agudas',
	},
	{
		id: 6129,
		code: 'N179',
		label: 'Insuficiencia renal aguda, no especificada',
	},
	{
		id: 6130,
		code: 'N18',
		label: 'Enfermedad renal crónica',
	},
	{
		id: 6131,
		code: 'N180',
		label: 'Insuficiencia renal terminal',
	},
	{
		id: 6132,
		code: 'N181',
		label: 'Enfermedad renal crónica, etapa 1',
	},
	{
		id: 6133,
		code: 'N182',
		label: 'Enfermedad renal crónica, etapa 2',
	},
	{
		id: 6134,
		code: 'N183',
		label: 'Enfermedad renal crónica, etapa 3',
	},
	{
		id: 6135,
		code: 'N184',
		label: 'Enfermedad renal crónica, etapa 4',
	},
	{
		id: 6136,
		code: 'N185',
		label: 'Enfermedad renal crónica, etapa 5',
	},
	{
		id: 6137,
		code: 'N188',
		label: 'Otras insuficiencias renales crónicas',
	},
	{
		id: 6138,
		code: 'N189',
		label: 'Enfermedad renal crónica, no especificada',
	},
	{
		id: 6139,
		code: 'N19X',
		label: 'Insuficiencia renal no especificada',
	},
	{
		id: 6140,
		code: 'N20',
		label: 'Cálculo del riñón y del uréter',
	},
	{
		id: 6141,
		code: 'N200',
		label: 'Cálculo del riñón',
	},
	{
		id: 6142,
		code: 'N201',
		label: 'Cálculo del uréter',
	},
	{
		id: 6143,
		code: 'N202',
		label: 'Cálculo del riñón con cálculo del uréter',
	},
	{
		id: 6144,
		code: 'N209',
		label: 'Cálculo urinario, no especificado',
	},
	{
		id: 6145,
		code: 'N21',
		label: 'Cálculo de las vías urinarias inferiores',
	},
	{
		id: 6146,
		code: 'N210',
		label: 'Cálculo en la vejiga',
	},
	{
		id: 6147,
		code: 'N211',
		label: 'Cálculo en la uretra',
	},
	{
		id: 6148,
		code: 'N218',
		label: 'Otros cálculos de las vías urinarias inferiores',
	},
	{
		id: 6149,
		code: 'N219',
		label: 'Cálculo de las vías urinarias inferiores, no especificado',
	},
	{
		id: 6150,
		code: 'N22',
		label: 'Cálculo de las vías urinarias en enfermedades clasificadas en otra parte',
	},
	{
		id: 6151,
		code: 'N220',
		label: 'Litiasis urinaria en esquistosomiasis [bilharziasis]',
	},
	{
		id: 6152,
		code: 'N228',
		label: 'Cálculo de las vías urinarias en otras enfermedades clasificadas en otra parte',
	},
	{
		id: 6153,
		code: 'N23X',
		label: 'Cólico renal, no especificado',
	},
	{
		id: 6154,
		code: 'N25',
		label: 'Trastornos resultantes de la función tubular renal alterada',
	},
	{
		id: 6155,
		code: 'N250',
		label: 'Osteodistrofia renal',
	},
	{
		id: 6156,
		code: 'N251',
		label: 'Diabetes insípida nefrógena',
	},
	{
		id: 6157,
		code: 'N258',
		label: 'Otros trastornos resultantes de la función tubular renal alterada',
	},
	{
		id: 6158,
		code: 'N259',
		label: 'Trastorno no especificado, resultante de la función tubular renal alterada',
	},
	{
		id: 6159,
		code: 'N26X',
		label: 'Riñón contraído, no especificado',
	},
	{
		id: 6160,
		code: 'N27',
		label: 'Riñón pequeño de causa desconocida',
	},
	{
		id: 6161,
		code: 'N270',
		label: 'Riñón pequeño, unilateral',
	},
	{
		id: 6162,
		code: 'N271',
		label: 'Riñón pequeño, bilateral',
	},
	{
		id: 6163,
		code: 'N279',
		label: 'Riñón pequeño, no especificado',
	},
	{
		id: 6164,
		code: 'N28',
		label: 'Otros trastornos del riñón y del uréter, no clasificados en otra parte',
	},
	{
		id: 6165,
		code: 'N280',
		label: 'Isquemia e infarto del riñón',
	},
	{
		id: 6166,
		code: 'N281',
		label: 'Quiste de riñón, adquirido',
	},
	{
		id: 6167,
		code: 'N288',
		label: 'Otros trastornos especificados del riñón y del uréter',
	},
	{
		id: 6168,
		code: 'N289',
		label: 'Trastorno del riñón y del uréter, no especificado',
	},
	{
		id: 6169,
		code: 'N29',
		label: 'Otros trastornos del riñón y del uréter en enfermedades clasificadas en otra parte',
	},
	{
		id: 6170,
		code: 'N290',
		label: 'Sífilis renal tardía',
	},
	{
		id: 6171,
		code: 'N291',
		label: 'Otros trastornos del riñón y del uréter en enfermedades infecciosas y parasitarias clasificadas en otra parte',
	},
	{
		id: 6172,
		code: 'N298',
		label: 'Otros trastornos del riñón y del uréter en otras enfermedades clasificadas en otra parte',
	},
	{
		id: 6173,
		code: 'N30',
		label: 'Cistitis',
	},
	{
		id: 6174,
		code: 'N300',
		label: 'Cistitis aguda',
	},
	{
		id: 6175,
		code: 'N301',
		label: 'Cistitis intersticial (crónica)',
	},
	{
		id: 6176,
		code: 'N302',
		label: 'Otras cistitis crónicas',
	},
	{
		id: 6177,
		code: 'N303',
		label: 'Trigonitis',
	},
	{
		id: 6178,
		code: 'N304',
		label: 'Cistitis por irradiación',
	},
	{
		id: 6179,
		code: 'N308',
		label: 'Otras cistitis',
	},
	{
		id: 6180,
		code: 'N309',
		label: 'Cistitis, no especificada',
	},
	{
		id: 6181,
		code: 'N31',
		label: 'Disfunción neuromuscular de la vejiga, no clasificada en otra parte',
	},
	{
		id: 6182,
		code: 'N310',
		label: 'Vejiga neuropática no inhibida, no clasificada en otra parte',
	},
	{
		id: 6183,
		code: 'N311',
		label: 'Vejiga neuropática refleja, no clasificada en otra parte',
	},
	{
		id: 6184,
		code: 'N312',
		label: 'Vejiga neuropática flácida, no clasificada en otra parte',
	},
	{
		id: 6185,
		code: 'N318',
		label: 'Otras disfunciones neuromusculares de la vejiga',
	},
	{
		id: 6186,
		code: 'N319',
		label: 'Disfunción neuromuscular de la vejiga, no especificada',
	},
	{
		id: 6187,
		code: 'N32',
		label: 'Otros trastornos de la vejiga',
	},
	{
		id: 6188,
		code: 'N320',
		label: 'Obstrucción de cuello de la vejiga',
	},
	{
		id: 6189,
		code: 'N321',
		label: 'Fístula vesicointestinal',
	},
	{
		id: 6190,
		code: 'N322',
		label: 'Fístula de la vejiga, no clasificada en otra parte',
	},
	{
		id: 6191,
		code: 'N323',
		label: 'Divertículo de la vejiga',
	},
	{
		id: 6192,
		code: 'N324',
		label: 'Ruptura de la vejiga, no traumática',
	},
	{
		id: 6193,
		code: 'N328',
		label: 'Otros trastornos especificados de la vejiga',
	},
	{
		id: 6194,
		code: 'N329',
		label: 'Trastorno de la vejiga, no especificado',
	},
	{
		id: 6195,
		code: 'N33',
		label: 'Trastornos de la vejiga en enfermedades clasificadas en otra parte',
	},
	{
		id: 6196,
		code: 'N330',
		label: 'Cistitis tuberculosa',
	},
	{
		id: 6197,
		code: 'N338',
		label: 'Trastornos de la vejiga en otras enfermedades clasificadas en otra parte',
	},
	{
		id: 6198,
		code: 'N34',
		label: 'Uretritis y síndrome uretral',
	},
	{
		id: 6199,
		code: 'N340',
		label: 'Absceso uretral',
	},
	{
		id: 6200,
		code: 'N341',
		label: 'Uretritis no específica',
	},
	{
		id: 6201,
		code: 'N342',
		label: 'Otras uretritis',
	},
	{
		id: 6202,
		code: 'N343',
		label: 'Síndrome uretral, no especificado',
	},
	{
		id: 6203,
		code: 'N35',
		label: 'Estrechez uretral',
	},
	{
		id: 6204,
		code: 'N350',
		label: 'Estrechez uretral postraumática',
	},
	{
		id: 6205,
		code: 'N351',
		label: 'Estrechez uretral postinfección, no clasificada en otra parte',
	},
	{
		id: 6206,
		code: 'N358',
		label: 'Otras estrecheces uretrales',
	},
	{
		id: 6207,
		code: 'N359',
		label: 'Estrechez uretral, no especificada',
	},
	{
		id: 6208,
		code: 'N36',
		label: 'Otros trastornos de la uretra',
	},
	{
		id: 6209,
		code: 'N360',
		label: 'Fístula de la uretra',
	},
	{
		id: 6210,
		code: 'N361',
		label: 'Divertículo de la uretra',
	},
	{
		id: 6211,
		code: 'N362',
		label: 'Carúncula uretral',
	},
	{
		id: 6212,
		code: 'N363',
		label: 'Prolapso de la mucosa uretral',
	},
	{
		id: 6213,
		code: 'N368',
		label: 'Otros trastornos especificados de la uretra',
	},
	{
		id: 6214,
		code: 'N369',
		label: 'Trastorno de la uretra, no especificado',
	},
	{
		id: 6215,
		code: 'N37',
		label: 'Trastornos de la uretra en enfermedades clasificadas en otra parte',
	},
	{
		id: 6216,
		code: 'N370',
		label: 'Uretritis en enfermedades clasificadas en otra parte',
	},
	{
		id: 6217,
		code: 'N378',
		label: 'Otros trastornos uretrales en enfermedades clasificadas en otra parte',
	},
	{
		id: 6218,
		code: 'N39',
		label: 'Otros trastornos del sistema urinario',
	},
	{
		id: 6219,
		code: 'N390',
		label: 'Infección de vías urinarias, sitio no especificado',
	},
	{
		id: 6220,
		code: 'N391',
		label: 'Proteinuria persistente, no especificada',
	},
	{
		id: 6221,
		code: 'N392',
		label: 'Proteinuria ortostática, no especificada',
	},
	{
		id: 6222,
		code: 'N393',
		label: 'Incontinencia urinaria por tensión',
	},
	{
		id: 6223,
		code: 'N394',
		label: 'Otras incontinencias urinarias especificadas',
	},
	{
		id: 6224,
		code: 'N398',
		label: 'Otros trastornos especificados del sistema urinario',
	},
	{
		id: 6225,
		code: 'N399',
		label: 'Trastorno del sistema urinario, no especificado',
	},
	{
		id: 6226,
		code: 'N40X',
		label: 'Hiperplasia de la próstata',
	},
	{
		id: 6227,
		code: 'N41',
		label: 'Enfermedades inflamatorias de la próstata',
	},
	{
		id: 6228,
		code: 'N410',
		label: 'Prostatitis aguda',
	},
	{
		id: 6229,
		code: 'N411',
		label: 'Prostatitis crónica',
	},
	{
		id: 6230,
		code: 'N412',
		label: 'Absceso de la próstata',
	},
	{
		id: 6231,
		code: 'N413',
		label: 'Prostatocistitis',
	},
	{
		id: 6232,
		code: 'N418',
		label: 'Otras enfermedades inflamatorias de la próstata',
	},
	{
		id: 6233,
		code: 'N419',
		label: 'Enfermedad inflamatoria de la próstata, no especificada',
	},
	{
		id: 6234,
		code: 'N42',
		label: 'Otros trastornos de la próstata',
	},
	{
		id: 6235,
		code: 'N420',
		label: 'Cálculo de la próstata',
	},
	{
		id: 6236,
		code: 'N421',
		label: 'Congestión y hemorragia de la próstata',
	},
	{
		id: 6237,
		code: 'N422',
		label: 'Atrofia de la próstata',
	},
	{
		id: 6238,
		code: 'N423',
		label: 'Displasia de la próstata',
	},
	{
		id: 6239,
		code: 'N428',
		label: 'Otros trastornos especificados de la próstata',
	},
	{
		id: 6240,
		code: 'N429',
		label: 'Trastorno de la próstata, no especificado',
	},
	{
		id: 6241,
		code: 'N43',
		label: 'Hidrocele y espermatocele',
	},
	{
		id: 6242,
		code: 'N430',
		label: 'Hidrocele enquistado',
	},
	{
		id: 6243,
		code: 'N431',
		label: 'Hidrocele infectado',
	},
	{
		id: 6244,
		code: 'N432',
		label: 'Otros hidroceles',
	},
	{
		id: 6245,
		code: 'N433',
		label: 'Hidrocele, no especificado',
	},
	{
		id: 6246,
		code: 'N434',
		label: 'Espermatocele',
	},
	{
		id: 6247,
		code: 'N44X',
		label: 'Torsión del testículo',
	},
	{
		id: 6248,
		code: 'N45',
		label: 'Orquitis y epididimitis',
	},
	{
		id: 6249,
		code: 'N450',
		label: 'Orquitis, epididimitis y orquiepididimitis con absceso',
	},
	{
		id: 6250,
		code: 'N459',
		label: 'Orquitis, epididimitis y orquiepididimitis sin absceso',
	},
	{
		id: 6251,
		code: 'N46X',
		label: 'Esterilidad en el varón',
	},
	{
		id: 6252,
		code: 'N47X',
		label: 'Prepucio redundante, fimosis y parafimosis',
	},
	{
		id: 6253,
		code: 'N48',
		label: 'Otros trastornos del pene',
	},
	{
		id: 6254,
		code: 'N480',
		label: 'Leucoplasia del pene',
	},
	{
		id: 6255,
		code: 'N481',
		label: 'Balanopostitis',
	},
	{
		id: 6256,
		code: 'N482',
		label: 'Otros trastornos inflamatorios del pene',
	},
	{
		id: 6257,
		code: 'N483',
		label: 'Priapismo',
	},
	{
		id: 6258,
		code: 'N484',
		label: 'Impotencia de origen orgánico',
	},
	{
		id: 6259,
		code: 'N485',
		label: 'Úlcera del pene',
	},
	{
		id: 6260,
		code: 'N486',
		label: 'Induración plástica del pene',
	},
	{
		id: 6261,
		code: 'N488',
		label: 'Otros trastornos especificados del pene',
	},
	{
		id: 6262,
		code: 'N489',
		label: 'Trastorno del pene, no especificado',
	},
	{
		id: 6263,
		code: 'N49',
		label: 'Trastornos inflamatorios de órganos genitales masculinos, no clasificados en otra parte',
	},
	{
		id: 6264,
		code: 'N490',
		label: 'Trastornos inflamatorios de vesícula seminal',
	},
	{
		id: 6265,
		code: 'N491',
		label: 'Trastornos inflamatorios del cordón espermático, túnica vaginal y conducto deferente',
	},
	{
		id: 6266,
		code: 'N492',
		label: 'Trastornos inflamatorios del escroto',
	},
	{
		id: 6267,
		code: 'N498',
		label: 'Otros trastornos inflamatorios de los órganos genitales masculinos',
	},
	{
		id: 6268,
		code: 'N499',
		label: 'Trastorno inflamatorio de órgano genital masculino, no especificado',
	},
	{
		id: 6269,
		code: 'N50',
		label: 'Otros trastornos de los órganos genitales masculinos',
	},
	{
		id: 6270,
		code: 'N500',
		label: 'Atrofia del testículo',
	},
	{
		id: 6271,
		code: 'N501',
		label: 'Trastornos vasculares de los órganos genitales masculinos',
	},
	{
		id: 6272,
		code: 'N508',
		label: 'Otros trastornos especificados de los órganos genitales masculinos',
	},
	{
		id: 6273,
		code: 'N509',
		label: 'Trastorno no especificado de los órganos genitales masculinos',
	},
	{
		id: 6274,
		code: 'N51',
		label: 'Trastornos de los órganos genitales masculinos en enfermedades clasificadas en otra parte',
	},
	{
		id: 6275,
		code: 'N510',
		label: 'Trastornos de próstata en enfermedades clasificadas en otra parte',
	},
	{
		id: 6276,
		code: 'N511',
		label: 'Trastornos del testículo y del epidídimo en enfermedades clasificadas en otra parte',
	},
	{
		id: 6277,
		code: 'N512',
		label: 'Balanitis en enfermedades clasificadas en otra parte',
	},
	{
		id: 6278,
		code: 'N518',
		label: 'Otros trastornos de los órganos genitales masculinos en enfermedades clasificadas en otra parte',
	},
	{
		id: 6279,
		code: 'N60',
		label: 'Displasia mamaria benigna',
	},
	{
		id: 6280,
		code: 'N600',
		label: 'Quiste solitario de la mama',
	},
	{
		id: 6281,
		code: 'N601',
		label: 'Mastopatía quística difusa',
	},
	{
		id: 6282,
		code: 'N602',
		label: 'Fibroadenosis de mama',
	},
	{
		id: 6283,
		code: 'N603',
		label: 'Fibroesclerosis de mama',
	},
	{
		id: 6284,
		code: 'N604',
		label: 'Ectasia de conducto mamario',
	},
	{
		id: 6285,
		code: 'N608',
		label: 'Otras displasias mamarias benignas',
	},
	{
		id: 6286,
		code: 'N609',
		label: 'Displasia mamaria benigna, sin otra especificación',
	},
	{
		id: 6287,
		code: 'N61X',
		label: 'Trastornos inflamatorios de la mama',
	},
	{
		id: 6288,
		code: 'N62X',
		label: 'Hipertrofia de la mama',
	},
	{
		id: 6289,
		code: 'N63X',
		label: 'Masa no especificada en la mama',
	},
	{
		id: 6290,
		code: 'N64',
		label: 'Otros trastornos de la mama',
	},
	{
		id: 6291,
		code: 'N640',
		label: 'Fisura y fístula del pezón',
	},
	{
		id: 6292,
		code: 'N641',
		label: 'Necrosis grasa de la mama',
	},
	{
		id: 6293,
		code: 'N642',
		label: 'Atrofia de la mama',
	},
	{
		id: 6294,
		code: 'N643',
		label: 'Galactorrea no asociada con el parto',
	},
	{
		id: 6295,
		code: 'N644',
		label: 'Mastodinia',
	},
	{
		id: 6296,
		code: 'N645',
		label: 'Otros signos y síntomas relativos a la mama',
	},
	{
		id: 6297,
		code: 'N648',
		label: 'Otros trastornos especificados de la mama',
	},
	{
		id: 6298,
		code: 'N649',
		label: 'Trastorno de la mama, no especificado',
	},
	{
		id: 6299,
		code: 'N70',
		label: 'Salpingitis y ooforitis',
	},
	{
		id: 6300,
		code: 'N700',
		label: 'Salpingitis y ooforitis aguda',
	},
	{
		id: 6301,
		code: 'N701',
		label: 'Salpingitis y ooforitis crónica',
	},
	{
		id: 6302,
		code: 'N709',
		label: 'Salpingitis y ooforitis, no especificadas',
	},
	{
		id: 6303,
		code: 'N71',
		label: 'Enfermedad inflamatoria del útero, excepto del cuello uterino',
	},
	{
		id: 6304,
		code: 'N710',
		label: 'Enfermedad inflamatoria aguda del útero',
	},
	{
		id: 6305,
		code: 'N711',
		label: 'Enfermedad inflamatoria crónica del útero',
	},
	{
		id: 6306,
		code: 'N719',
		label: 'Enfermedad inflamatoria del útero, no especificada',
	},
	{
		id: 6307,
		code: 'N72X',
		label: 'Enfermedad inflamatoria del cuello uterino',
	},
	{
		id: 6308,
		code: 'N73',
		label: 'Otras enfermedades pélvicas inflamatorias femeninas',
	},
	{
		id: 6309,
		code: 'N730',
		label: 'Parametritis y celulitis pélvica aguda',
	},
	{
		id: 6310,
		code: 'N731',
		label: 'Parametritis y celulitis pélvica crónica',
	},
	{
		id: 6311,
		code: 'N732',
		label: 'Parametritis y celulitis pélvica no especificada',
	},
	{
		id: 6312,
		code: 'N733',
		label: 'Peritonitis pélvica aguda, femenina',
	},
	{
		id: 6313,
		code: 'N734',
		label: 'Peritonitis pélvica crónica, femenina',
	},
	{
		id: 6314,
		code: 'N735',
		label: 'Peritonitis pélvica femenina, no especificada',
	},
	{
		id: 6315,
		code: 'N736',
		label: 'Adherencias peritoneales pélvicas femeninas',
	},
	{
		id: 6316,
		code: 'N738',
		label: 'Otras enfermedades inflamatorias pélvicas femeninas',
	},
	{
		id: 6317,
		code: 'N739',
		label: 'Enfermedad inflamatoria pélvica femenina, no especificada',
	},
	{
		id: 6318,
		code: 'N74',
		label: 'Trastornos inflamatorios de la pelvis femenina en enfermedades clasificadas en otra parte',
	},
	{
		id: 6319,
		code: 'N740',
		label: 'Infección tuberculosa del cuello del útero',
	},
	{
		id: 6320,
		code: 'N741',
		label: 'Enfermedad inflamatoria pélvica femenina por tuberculosis',
	},
	{
		id: 6321,
		code: 'N742',
		label: 'Enfermedad inflamatoria pélvica femenina por sífilis',
	},
	{
		id: 6322,
		code: 'N743',
		label: 'Enfermedad inflamatoria pélvica femenina por gonococos',
	},
	{
		id: 6323,
		code: 'N744',
		label: 'Enfermedad inflamatoria pélvica femenina por clamidias',
	},
	{
		id: 6324,
		code: 'N748',
		label: 'Trastornos inflamatorios pélvicos femeninos en otras enfermedades clasificadas en otra parte',
	},
	{
		id: 6325,
		code: 'N75',
		label: 'Enfermedades de la glándula de Bartholin',
	},
	{
		id: 6326,
		code: 'N750',
		label: 'Quiste de la glándula de Bartholin',
	},
	{
		id: 6327,
		code: 'N751',
		label: 'Absceso de la glándula de Bartholin',
	},
	{
		id: 6328,
		code: 'N758',
		label: 'Otras enfermedades de la glándula de Bartholin',
	},
	{
		id: 6329,
		code: 'N759',
		label: 'Enfermedad de la glándula de Bartholin, no especificada',
	},
	{
		id: 6330,
		code: 'N76',
		label: 'Otras afecciones inflamatorias de la vagina y de la vulva',
	},
	{
		id: 6331,
		code: 'N760',
		label: 'Vaginitis aguda',
	},
	{
		id: 6332,
		code: 'N761',
		label: 'Vaginitis subaguda y crónica',
	},
	{
		id: 6333,
		code: 'N762',
		label: 'Vulvitis aguda',
	},
	{
		id: 6334,
		code: 'N763',
		label: 'Vulvitis subaguda y crónica',
	},
	{
		id: 6335,
		code: 'N764',
		label: 'Absceso vulvar',
	},
	{
		id: 6336,
		code: 'N765',
		label: 'Ulceración de la vagina',
	},
	{
		id: 6337,
		code: 'N766',
		label: 'Ulceración de la vulva',
	},
	{
		id: 6338,
		code: 'N768',
		label: 'Otras inflamaciones especificadas de la vagina y de la vulva',
	},
	{
		id: 6339,
		code: 'N77',
		label: 'Ulceración e inflamación vulvovaginal en enfermedades clasificadas en otra parte',
	},
	{
		id: 6340,
		code: 'N770',
		label: 'Ulceración de la vulva en enfermedades infecciosas y parasitarias clasificadas en otra parte',
	},
	{
		id: 6341,
		code: 'N771',
		label: 'Vaginitis, vulvitis y vulvovaginitis en enfermedades infecciosas y parasitarias clasificadas en otra parte',
	},
	{
		id: 6342,
		code: 'N778',
		label: 'Ulceración e inflamación vulvovaginal en otras enfermedades clasificadas en otra parte',
	},
	{
		id: 6343,
		code: 'N80',
		label: 'Endometriosis',
	},
	{
		id: 6344,
		code: 'N800',
		label: 'Endometriosis del útero',
	},
	{
		id: 6345,
		code: 'N801',
		label: 'Endometriosis del ovario',
	},
	{
		id: 6346,
		code: 'N802',
		label: 'Endometriosis de la trompa de Falopio',
	},
	{
		id: 6347,
		code: 'N803',
		label: 'Endometriosis del peritoneo pélvico',
	},
	{
		id: 6348,
		code: 'N804',
		label: 'Endometriosis del tabique rectovaginal y de la vagina',
	},
	{
		id: 6349,
		code: 'N805',
		label: 'Endometriosis del intestino',
	},
	{
		id: 6350,
		code: 'N806',
		label: 'Endometriosis en cicatriz cutánea',
	},
	{
		id: 6351,
		code: 'N808',
		label: 'Otras endometriosis',
	},
	{
		id: 6352,
		code: 'N809',
		label: 'Endometriosis, no especificada',
	},
	{
		id: 6353,
		code: 'N81',
		label: 'Prolapso genital femenino',
	},
	{
		id: 6354,
		code: 'N810',
		label: 'Uretrocele femenino',
	},
	{
		id: 6355,
		code: 'N811',
		label: 'Cistocele',
	},
	{
		id: 6356,
		code: 'N812',
		label: 'Prolapso uterovaginal incompleto',
	},
	{
		id: 6357,
		code: 'N813',
		label: 'Prolapso uterovaginal completo',
	},
	{
		id: 6358,
		code: 'N814',
		label: 'Prolapso uterovaginal, sin otra especificación',
	},
	{
		id: 6359,
		code: 'N815',
		label: 'Enterocele vaginal',
	},
	{
		id: 6360,
		code: 'N816',
		label: 'Rectocele',
	},
	{
		id: 6361,
		code: 'N818',
		label: 'Otros prolapsos genitales femeninos',
	},
	{
		id: 6362,
		code: 'N819',
		label: 'Prolapso genital femenino, no especificado',
	},
	{
		id: 6363,
		code: 'N82',
		label: 'Fístulas que afectan el tracto genital femenino',
	},
	{
		id: 6364,
		code: 'N820',
		label: 'Fístula vesicovaginal',
	},
	{
		id: 6365,
		code: 'N821',
		label: 'Otras fístulas de las vías genitourinarias femeninas',
	},
	{
		id: 6366,
		code: 'N822',
		label: 'Fístula de la vagina al intestino delgado',
	},
	{
		id: 6367,
		code: 'N823',
		label: 'Fístula de la vagina al intestino grueso',
	},
	{
		id: 6368,
		code: 'N824',
		label: 'Otras fístulas del tracto genital femenino al tracto intestinal',
	},
	{
		id: 6369,
		code: 'N825',
		label: 'Fístula del tracto genital femenino a la piel',
	},
	{
		id: 6370,
		code: 'N828',
		label: 'Otras fístulas del tracto genital femenino',
	},
	{
		id: 6371,
		code: 'N829',
		label: 'Fístula del tracto genital femenino, sin otra especificación',
	},
	{
		id: 6372,
		code: 'N83',
		label: 'Tratornos no inflamatorios del ovario, de la trompa de Falopio y del ligamento ancho',
	},
	{
		id: 6373,
		code: 'N830',
		label: 'Quiste folicular del ovario',
	},
	{
		id: 6374,
		code: 'N831',
		label: 'Quiste del cuerpo amarillo',
	},
	{
		id: 6375,
		code: 'N832',
		label: 'Otros quistes ováricos y los no especificados',
	},
	{
		id: 6376,
		code: 'N833',
		label: 'Atrofia adquirida del ovario y de la trompa de Falopio',
	},
	{
		id: 6377,
		code: 'N834',
		label: 'Prolapso y hernia del ovario y de la trompa de Falopio',
	},
	{
		id: 6378,
		code: 'N835',
		label: 'Torsión de ovario, pedículo de ovario y trompa de Falopio',
	},
	{
		id: 6379,
		code: 'N836',
		label: 'Hematosalpinx',
	},
	{
		id: 6380,
		code: 'N837',
		label: 'Hematoma del ligamento ancho',
	},
	{
		id: 6381,
		code: 'N838',
		label: 'Otros trastornos no inflamatorios del ovario, de la trompa de Falopio y del ligamento ancho',
	},
	{
		id: 6382,
		code: 'N839',
		label: 'Enfermedad no inflamatoria del ovario, de la trompa de Falopio y del ligamento ancho, no especificada',
	},
	{
		id: 6383,
		code: 'N84',
		label: 'Pólipo del tracto genital femenino',
	},
	{
		id: 6384,
		code: 'N840',
		label: 'Pólipo del cuerpo del útero',
	},
	{
		id: 6385,
		code: 'N841',
		label: 'Pólipo del cuello del útero',
	},
	{
		id: 6386,
		code: 'N842',
		label: 'Pólipo de la vagina',
	},
	{
		id: 6387,
		code: 'N843',
		label: 'Pólipo de la vulva',
	},
	{
		id: 6388,
		code: 'N848',
		label: 'Pólipos de otras partes del tracto genital femenino',
	},
	{
		id: 6389,
		code: 'N849',
		label: 'Pólipo del tracto genital femenino, no especificado',
	},
	{
		id: 6390,
		code: 'N85',
		label: 'Otros trastornos no inflamatorios del útero, excepto del cuello',
	},
	{
		id: 6391,
		code: 'N850',
		label: 'Hiperplasia de glándula del endometrio',
	},
	{
		id: 6392,
		code: 'N851',
		label: 'Hiperplasia adenomatosa del endometrio',
	},
	{
		id: 6393,
		code: 'N852',
		label: 'Hipertrofia del útero',
	},
	{
		id: 6394,
		code: 'N853',
		label: 'Subinvolución del útero',
	},
	{
		id: 6395,
		code: 'N854',
		label: 'Mala posicion del útero',
	},
	{
		id: 6396,
		code: 'N855',
		label: 'Inversión del útero',
	},
	{
		id: 6397,
		code: 'N856',
		label: 'Sinequias intrauterinas',
	},
	{
		id: 6398,
		code: 'N857',
		label: 'Hematómetra',
	},
	{
		id: 6399,
		code: 'N858',
		label: 'Otros trastornos no inflamatorios especificados del útero',
	},
	{
		id: 6400,
		code: 'N859',
		label: 'Trastorno no inflamatorio del útero, no especificado',
	},
	{
		id: 6401,
		code: 'N86X',
		label: 'Erosión y ectropión del cuello del útero',
	},
	{
		id: 6402,
		code: 'N87',
		label: 'Displasia del cuello uterino',
	},
	{
		id: 6403,
		code: 'N870',
		label: 'Displasia cervical leve',
	},
	{
		id: 6404,
		code: 'N871',
		label: 'Displasia cervical moderada',
	},
	{
		id: 6405,
		code: 'N872',
		label: 'Displasia cervical severa, no clasificada en otra parte',
	},
	{
		id: 6406,
		code: 'N879',
		label: 'Displasia del cuello del útero, no especificada',
	},
	{
		id: 6407,
		code: 'N88',
		label: 'Otros trastornos no inflamatorios del cuello del útero',
	},
	{
		id: 6408,
		code: 'N880',
		label: 'Leucoplasia del cuello del útero',
	},
	{
		id: 6409,
		code: 'N881',
		label: 'Laceración antigua del cuello del útero',
	},
	{
		id: 6410,
		code: 'N882',
		label: 'Estrechez y estenosis del cuello del útero',
	},
	{
		id: 6411,
		code: 'N883',
		label: 'Incompetencia del cuello del útero',
	},
	{
		id: 6412,
		code: 'N884',
		label: 'Elongación hipertrofica del cuello del útero',
	},
	{
		id: 6413,
		code: 'N888',
		label: 'Otros trastornos no inflamatorios especificados del cuello del útero',
	},
	{
		id: 6414,
		code: 'N889',
		label: 'Trastorno no inflamatorio del cuello del útero, no especificado',
	},
	{
		id: 6415,
		code: 'N89',
		label: 'Otros trastornos no inflamatorios de la vagina',
	},
	{
		id: 6416,
		code: 'N890',
		label: 'Displasia vaginal leve',
	},
	{
		id: 6417,
		code: 'N891',
		label: 'Displasia vaginal moderada',
	},
	{
		id: 6418,
		code: 'N892',
		label: 'Displasia vaginal severa, no clasificada en otra parte',
	},
	{
		id: 6419,
		code: 'N893',
		label: 'Displasia de la vagina, no especificada',
	},
	{
		id: 6420,
		code: 'N894',
		label: 'Leucoplasia de la vagina',
	},
	{
		id: 6421,
		code: 'N895',
		label: 'Estrechez y atresia de la vagina',
	},
	{
		id: 6422,
		code: 'N896',
		label: 'Anillo de himen estrecho',
	},
	{
		id: 6423,
		code: 'N897',
		label: 'Hematocolpos',
	},
	{
		id: 6424,
		code: 'N898',
		label: 'Otros trastornos especificados no inflamatorios de la vagina',
	},
	{
		id: 6425,
		code: 'N899',
		label: 'Trastorno no inflamatorio de la vagina, no especificado',
	},
	{
		id: 6426,
		code: 'N90',
		label: 'Otros trastornos no inflamatorios de la vulva y del perineo',
	},
	{
		id: 6427,
		code: 'N900',
		label: 'Displasia vulvar leve',
	},
	{
		id: 6428,
		code: 'N901',
		label: 'Displasia vulvar moderada',
	},
	{
		id: 6429,
		code: 'N902',
		label: 'Displasia vulvar severa, no clasificada en otra parte',
	},
	{
		id: 6430,
		code: 'N903',
		label: 'Displasia de la vulva, no especificada',
	},
	{
		id: 6431,
		code: 'N904',
		label: 'Leucoplasia de la vulva',
	},
	{
		id: 6432,
		code: 'N905',
		label: 'Atrofia de la vulva',
	},
	{
		id: 6433,
		code: 'N906',
		label: 'Hipertrofia de la vulva',
	},
	{
		id: 6434,
		code: 'N907',
		label: 'Quiste de la vulva',
	},
	{
		id: 6435,
		code: 'N908',
		label: 'Otros trastornos no inflamatorios especificados de la vulva y del perineo',
	},
	{
		id: 6436,
		code: 'N909',
		label: 'Trastorno no inflamatorio de la vulva y del perineo, no especificado',
	},
	{
		id: 6437,
		code: 'N91',
		label: 'Menstruación ausente, escasa o rara',
	},
	{
		id: 6438,
		code: 'N910',
		label: 'Amenorrea primaria',
	},
	{
		id: 6439,
		code: 'N911',
		label: 'Amenorrea secundaria',
	},
	{
		id: 6440,
		code: 'N912',
		label: 'Amenorrea, sin otra especificación',
	},
	{
		id: 6441,
		code: 'N913',
		label: 'Oligomenorrea primaria',
	},
	{
		id: 6442,
		code: 'N914',
		label: 'Oligomenorrea secundaria',
	},
	{
		id: 6443,
		code: 'N915',
		label: 'Oligomenorrea, no especificada',
	},
	{
		id: 6444,
		code: 'N92',
		label: 'Menstruación excesiva, frecuente e irregular',
	},
	{
		id: 6445,
		code: 'N920',
		label: 'Menstruación excesiva y frecuente con ciclo regular',
	},
	{
		id: 6446,
		code: 'N921',
		label: 'Menstruación excesiva y frecuente con ciclo irregular',
	},
	{
		id: 6447,
		code: 'N922',
		label: 'Menstruación excesiva en la pubertad',
	},
	{
		id: 6448,
		code: 'N923',
		label: 'Hemorragia por ovulación',
	},
	{
		id: 6449,
		code: 'N924',
		label: 'Hemorragia excesiva en período premenopáusico',
	},
	{
		id: 6450,
		code: 'N925',
		label: 'Otras menstruaciones irregulares especificadas',
	},
	{
		id: 6451,
		code: 'N926',
		label: 'Menstruación irregular, no especificada',
	},
	{
		id: 6452,
		code: 'N93',
		label: 'Otras hemorragias uterinas o vaginales anormales',
	},
	{
		id: 6453,
		code: 'N930',
		label: 'Hemorragia postcoito y postcontacto',
	},
	{
		id: 6454,
		code: 'N938',
		label: 'Otras hemorragias uterinas o vaginales anormales especificadas',
	},
	{
		id: 6455,
		code: 'N939',
		label: 'Hemorragia vaginal y uterina anormal, no especificada',
	},
	{
		id: 6456,
		code: 'N94',
		label: 'Dolor y otras afecciones relacionadas con los órganos genitales femeninos y con el ciclo menstrual',
	},
	{
		id: 6457,
		code: 'N940',
		label: 'Dolor intermenstrual',
	},
	{
		id: 6458,
		code: 'N941',
		label: 'Dispareunia',
	},
	{
		id: 6459,
		code: 'N942',
		label: 'Vaginismo',
	},
	{
		id: 6460,
		code: 'N943',
		label: 'Síndrome de tensión premenstrual',
	},
	{
		id: 6461,
		code: 'N944',
		label: 'Dismenorrea primaria',
	},
	{
		id: 6462,
		code: 'N945',
		label: 'Dismenorrea secundaria',
	},
	{
		id: 6463,
		code: 'N946',
		label: 'Dismenorrea, no especificada',
	},
	{
		id: 6464,
		code: 'N948',
		label: 'Otras afecciones especificadas asociadas con los órganos genitales femeninos y el ciclo menstrual',
	},
	{
		id: 6465,
		code: 'N949',
		label: 'Afecciones no especificadas asociadas con los órganos genitales femeninos y el ciclo menstrual',
	},
	{
		id: 6466,
		code: 'N95',
		label: 'Otros trastornos menopáusicos y perimenopáusicos',
	},
	{
		id: 6467,
		code: 'N950',
		label: 'Hemorragia postmenopáusica',
	},
	{
		id: 6468,
		code: 'N951',
		label: 'Estados menopáusicos y climatéricos femeninos',
	},
	{
		id: 6469,
		code: 'N952',
		label: 'Vaginitis atrófica postmenopáusica',
	},
	{
		id: 6470,
		code: 'N953',
		label: 'Estados asociados con menopausia artificial',
	},
	{
		id: 6471,
		code: 'N958',
		label: 'Otros trastornos menopáusicos y perimenopáusicos especificados',
	},
	{
		id: 6472,
		code: 'N959',
		label: 'Trastorno menopáusico y perimenopáusico, no especificado',
	},
	{
		id: 6473,
		code: 'N96X',
		label: 'Abortadora habitual',
	},
	{
		id: 6474,
		code: 'N97',
		label: 'Infertilidad femenina',
	},
	{
		id: 6475,
		code: 'N970',
		label: 'Infertilidad femenina asociada con falta de ovulación',
	},
	{
		id: 6476,
		code: 'N971',
		label: 'Infertilidad femenina de origen tubárico',
	},
	{
		id: 6477,
		code: 'N972',
		label: 'Infertilidad femenina de origen uterino',
	},
	{
		id: 6478,
		code: 'N973',
		label: 'Infertilidad femenina de origen cervical',
	},
	{
		id: 6479,
		code: 'N974',
		label: 'Infertilidad femenina asociada con factores masculinos',
	},
	{
		id: 6480,
		code: 'N978',
		label: 'Infertilidad femenina de otro origen',
	},
	{
		id: 6481,
		code: 'N979',
		label: 'Infertilidad femenina, no especificada',
	},
	{
		id: 6482,
		code: 'N98',
		label: 'Complicaciones asociadas con la fecundación artificial',
	},
	{
		id: 6483,
		code: 'N980',
		label: 'Infección asociada con inseminación artificial',
	},
	{
		id: 6484,
		code: 'N981',
		label: 'Hiperestimulación de ovarios',
	},
	{
		id: 6485,
		code: 'N982',
		label: 'Complicaciones en el intento de introducción del huevo fecundado en la fertilización in vitro',
	},
	{
		id: 6486,
		code: 'N983',
		label: 'Complicaciones en el intento de introducción del embrión en la transferencia de embriones',
	},
	{
		id: 6487,
		code: 'N988',
		label: 'Otras complicaciones asociadas con la fecundación artificial',
	},
	{
		id: 6488,
		code: 'N989',
		label: 'Complicación no especificada asociada con la fecundación artificial',
	},
	{
		id: 6489,
		code: 'N99',
		label: 'Trastornos del sistema genitourinario consecutivos a procedimientos no clasificados en otra parte',
	},
	{
		id: 6490,
		code: 'N990',
		label: 'Insuficiencia renal consecutiva a procedimientos',
	},
	{
		id: 6491,
		code: 'N991',
		label: 'Estrechez uretral consecutiva a procedimientos',
	},
	{
		id: 6492,
		code: 'N992',
		label: 'Adherencias postoperatorias de la vagina',
	},
	{
		id: 6493,
		code: 'N993',
		label: 'Prolapso de la cúpula vaginal después de histerectomía',
	},
	{
		id: 6494,
		code: 'N994',
		label: 'Adherencias peritoneales pélvicas consecutivas a procedimientos',
	},
	{
		id: 6495,
		code: 'N995',
		label: 'Mal funcionamiento de estoma externo de vías urinarias',
	},
	{
		id: 6496,
		code: 'N998',
		label: 'Otros trastornos del sistema genitourinario consecutivos a procedimientos',
	},
	{
		id: 6497,
		code: 'N999',
		label: 'Trastorno no especificado del sistema genitourinario consecutivo a procedimientos',
	},
	{
		id: 6498,
		code: 'O00',
		label: 'Embarazo ectópico',
	},
	{
		id: 6499,
		code: 'O000',
		label: 'Embarazo abdominal',
	},
	{
		id: 6500,
		code: 'O001',
		label: 'Embarazo tubárico',
	},
	{
		id: 6501,
		code: 'O002',
		label: 'Embarazo ovárico',
	},
	{
		id: 6502,
		code: 'O008',
		label: 'Otros embarazos ectópicos',
	},
	{
		id: 6503,
		code: 'O009',
		label: 'Embarazo ectópico, no especificado',
	},
	{
		id: 6504,
		code: 'O01',
		label: 'Mola hidatiforme',
	},
	{
		id: 6505,
		code: 'O010',
		label: 'Mola hidatiforme clásica',
	},
	{
		id: 6506,
		code: 'O011',
		label: 'Mola hidatiforme, incompleta o parcial',
	},
	{
		id: 6507,
		code: 'O019',
		label: 'Mola hidatiforme, no especificada',
	},
	{
		id: 6508,
		code: 'O02',
		label: 'Otros productos anormales  de la concepción',
	},
	{
		id: 6509,
		code: 'O020',
		label: 'Detención del desarrollo del huevo y mola no hidatiforme',
	},
	{
		id: 6510,
		code: 'O021',
		label: 'Aborto retenido',
	},
	{
		id: 6511,
		code: 'O028',
		label: 'Otros productos anormales especificados de la concepción',
	},
	{
		id: 6512,
		code: 'O029',
		label: 'Producto anormal de la concepción, no especificado',
	},
	{
		id: 6513,
		code: 'O03',
		label: 'Aborto espontáneo',
	},
	{
		id: 6514,
		code: 'O030',
		label: 'Aborto espontáneo incompleto, complicado con infección genital y pelviana',
	},
	{
		id: 6515,
		code: 'O031',
		label: 'Aborto espontáneo incompleto, complicado por hemorragia excesiva o tardía',
	},
	{
		id: 6516,
		code: 'O032',
		label: 'Aborto espontáneo incompleto, complicado por embolia',
	},
	{
		id: 6517,
		code: 'O033',
		label: 'Aborto espontáneo incompleto, con otras complicaciones especificadas y las no especificadas',
	},
	{
		id: 6518,
		code: 'O034',
		label: 'Aborto espontáneo incompleto, sin complicación',
	},
	{
		id: 6519,
		code: 'O035',
		label: 'Aborto espontáneo completo o no especificado, complicado con infección genital y pelviana',
	},
	{
		id: 6520,
		code: 'O036',
		label: 'Aborto espontáneo completo o no especificado, complicado por hemorragia excesiva o tardía',
	},
	{
		id: 6521,
		code: 'O037',
		label: 'Aborto espontáneo completo o no especificado, complicado por embolia',
	},
	{
		id: 6522,
		code: 'O038',
		label: 'Aborto espontáneo completo o no especificado, con otras complicaciones especificadas y las no especificadas',
	},
	{
		id: 6523,
		code: 'O039',
		label: 'Aborto espontáneo completo o no especificado, sin complicación',
	},
	{
		id: 6524,
		code: 'O04',
		label: 'Aborto médico',
	},
	{
		id: 6525,
		code: 'O040',
		label: 'Aborto médico incompleto, complicado con infección genital y pelviana',
	},
	{
		id: 6526,
		code: 'O041',
		label: 'Aborto médico incompleto, complicado por hemorragia excesiva o tardía',
	},
	{
		id: 6527,
		code: 'O042',
		label: 'Aborto médico incompleto, complicado por embolia',
	},
	{
		id: 6528,
		code: 'O043',
		label: 'Aborto médico incompleto, con otras complicaciones especificadas y las no especificadas',
	},
	{
		id: 6529,
		code: 'O044',
		label: 'Aborto médico incompleto, sin complicación',
	},
	{
		id: 6530,
		code: 'O045',
		label: 'Aborto médico completo o no especificado, complicado con infección genital y pelviana',
	},
	{
		id: 6531,
		code: 'O046',
		label: 'Aborto médico completo o no especificado, complicado por hemorragia excesiva o tardía',
	},
	{
		id: 6532,
		code: 'O047',
		label: 'Aborto médico completo o no especificado, complicado por embolia',
	},
	{
		id: 6533,
		code: 'O048',
		label: 'Aborto médico completo o no especificado, con otras complicaciones especificadas y las no especificadas',
	},
	{
		id: 6534,
		code: 'O049',
		label: 'Aborto médico completo o no especificado, sin complicación',
	},
	{
		id: 6535,
		code: 'O05',
		label: 'Otro aborto',
	},
	{
		id: 6536,
		code: 'O050',
		label: 'Otro aborto incompleto, complicado con infección genital y pelviana',
	},
	{
		id: 6537,
		code: 'O051',
		label: 'Otro aborto incompleto, complicado por hemorragia excesiva o tardía',
	},
	{
		id: 6538,
		code: 'O052',
		label: 'Otro aborto incompleto, complicado por embolia',
	},
	{
		id: 6539,
		code: 'O053',
		label: 'Otro aborto incompleto, con otras complicaciones especificadas y las no especificadas',
	},
	{
		id: 6540,
		code: 'O054',
		label: 'Otro aborto incompleto, sin complicación',
	},
	{
		id: 6541,
		code: 'O055',
		label: 'Otro aborto completo o no especificado, complicado con infección genital y pelviana',
	},
	{
		id: 6542,
		code: 'O056',
		label: 'Otro aborto completo o no especificado, complicado por hemorragia excesiva o tardía',
	},
	{
		id: 6543,
		code: 'O057',
		label: 'Otro aborto completo o no especificado, complicado por embolia',
	},
	{
		id: 6544,
		code: 'O058',
		label: 'Otro aborto completo o no especificado, con otras complicaciones especificadas y las no especificadas',
	},
	{
		id: 6545,
		code: 'O059',
		label: 'Otro aborto completo o no especificado, sin complicación',
	},
	{
		id: 6546,
		code: 'O06',
		label: 'Aborto no especificado',
	},
	{
		id: 6547,
		code: 'O060',
		label: 'Aborto no especificado incompleto, complicado con infección genital y pelviana',
	},
	{
		id: 6548,
		code: 'O061',
		label: 'Aborto no especificado incompleto, complicado por hemorragia excesiva o tardía',
	},
	{
		id: 6549,
		code: 'O062',
		label: 'Aborto no especificado incompleto, complicado por embolia',
	},
	{
		id: 6550,
		code: 'O063',
		label: 'Aborto no especificado incompleto, con otras complicaciones especificadas y las no especificadas',
	},
	{
		id: 6551,
		code: 'O064',
		label: 'Aborto no especificado incompleto, sin complicación',
	},
	{
		id: 6552,
		code: 'O065',
		label: 'Aborto no especificado completo o no especificado, complicado con infección genital y pelviana',
	},
	{
		id: 6553,
		code: 'O066',
		label: 'Aborto no especificado completo o no especificado, complicado por hemorragia excesiva o tardía',
	},
	{
		id: 6554,
		code: 'O067',
		label: 'Aborto no especificado completo o no especificado, complicado por embolia',
	},
	{
		id: 6555,
		code: 'O068',
		label: 'Aborto no especificado completo o no especificado, con otras complicaciones especificadas y las no especificadas',
	},
	{
		id: 6556,
		code: 'O069',
		label: 'Aborto no especificado completo o no especificado, sin complicación',
	},
	{
		id: 6557,
		code: 'O07',
		label: 'Intento fallido de aborto',
	},
	{
		id: 6558,
		code: 'O070',
		label: 'Falla de la inducción médica del aborto, complicado por infección genital y pelviana',
	},
	{
		id: 6559,
		code: 'O071',
		label: 'Falla de la inducción médica del aborto, complicado por hemorragia excesiva o tardía',
	},
	{
		id: 6560,
		code: 'O072',
		label: 'Falla de la inducción médica del aborto, complicado por embolia',
	},
	{
		id: 6561,
		code: 'O073',
		label: 'Falla de la inducción médica del aborto, con otras complicaciones y las no especificadas',
	},
	{
		id: 6562,
		code: 'O074',
		label: 'Falla de la inducción médica del aborto, sin complicación',
	},
	{
		id: 6563,
		code: 'O075',
		label: 'Otros intentos fallidos de aborto y los no especificados, complicados por infección genital y pelviana',
	},
	{
		id: 6564,
		code: 'O076',
		label: 'Otros intentos fallidos de aborto y los no especificados, complicados por hemorragia excesiva o tardía',
	},
	{
		id: 6565,
		code: 'O077',
		label: 'Otros intentos fallidos de aborto y los no especificados, complicados por embolia',
	},
	{
		id: 6566,
		code: 'O078',
		label: 'Otros intentos fallidos de aborto y los no especificados, con otras complicaciones y las no especificadas',
	},
	{
		id: 6567,
		code: 'O079',
		label: 'Otros intentos fallidos de aborto y los no especificados, sin complicación',
	},
	{
		id: 6568,
		code: 'O08',
		label: 'Complicaciones consecutivas al aborto, al embarazo ectópico y al embarazo molar',
	},
	{
		id: 6569,
		code: 'O080',
		label: 'Infección genital y pelviana consecutiva al aborto, al embarazo ectópico y al embarazo molar',
	},
	{
		id: 6570,
		code: 'O081',
		label: 'Hemorragia excesiva o tardía consecutiva al aborto, al embarazo ectópico y al embarazo molar',
	},
	{
		id: 6571,
		code: 'O082',
		label: 'Embolia consecutiva al aborto, al embarazo ectópico y al embarazo molar',
	},
	{
		id: 6572,
		code: 'O083',
		label: 'Choque consecutivo al aborto, al embarazo ectópico y al embarazo molar',
	},
	{
		id: 6573,
		code: 'O084',
		label: 'Insuficiencia renal consecutiva al aborto, al embarazo ectópico y al embarazo molar',
	},
	{
		id: 6574,
		code: 'O085',
		label: 'Trastorno metabólico consecutivo al aborto, al embarazo ectópico y al embarazo molar',
	},
	{
		id: 6575,
		code: 'O086',
		label: 'Lesión de órganos o tejidos de la pelvis consecutivo al aborto, al embarazo ectópico y al embarazo molar',
	},
	{
		id: 6576,
		code: 'O087',
		label: 'Otras complicaciones venosas consecutivas al aborto, al embarazo ectópico y al embarazo molar',
	},
	{
		id: 6577,
		code: 'O088',
		label: 'Otras complicaciones consecutivas al aborto, al embarazo ectópico y al embarazo molar',
	},
	{
		id: 6578,
		code: 'O089',
		label: 'Complicación no especificada consecutiva al aborto, al embarazo ectópico y al embarazo molar',
	},
	{
		id: 6579,
		code: 'O10',
		label: 'Hipertensión preexistente que complica el embarazo, el parto y el puerperio',
	},
	{
		id: 6580,
		code: 'O100',
		label: 'Hipertensión esencial preexistente que complica el embarazo, el parto y el puerperio',
	},
	{
		id: 6581,
		code: 'O101',
		label: 'Enfermedad cardíaca hipertensiva preexistente que complica el embarazo, el parto y el puerperio',
	},
	{
		id: 6582,
		code: 'O102',
		label: 'Enfermedad renal hipertensiva preexistente que complica el embarazo, el parto y el puerperio',
	},
	{
		id: 6583,
		code: 'O103',
		label: 'Enfermedad cardiorrenal hipertensiva preexistente que complica el embarazo, el parto y el puerperio',
	},
	{
		id: 6584,
		code: 'O104',
		label: 'Hipertensión secundaria preexistente que complica el embarazo, el parto y el puerperio',
	},
	{
		id: 6585,
		code: 'O109',
		label: 'Hipertensión preexistente no especificada, que complica el embarazo, el parto y el puerperio',
	},
	{
		id: 6586,
		code: 'O11X',
		label: 'Preeclampsia superpuesta en hipertensión crónica',
	},
	{
		id: 6587,
		code: 'O12',
		label: 'Edema y proteinuria gestacionales [inducidos por el embarazo] sin hipertensión',
	},
	{
		id: 6588,
		code: 'O120',
		label: 'Edema gestacional',
	},
	{
		id: 6589,
		code: 'O121',
		label: 'Proteinuria gestacional',
	},
	{
		id: 6590,
		code: 'O122',
		label: 'Edema gestacional con proteinuria',
	},
	{
		id: 6591,
		code: 'O13X',
		label: 'Hipertensión gestacional [inducida por el embarazo]',
	},
	{
		id: 6592,
		code: 'O14',
		label: 'Preeclampsia',
	},
	{
		id: 6593,
		code: 'O140',
		label: 'Preeclampsia leve a moderada',
	},
	{
		id: 6594,
		code: 'O141',
		label: 'Preeclampsia severa',
	},
	{
		id: 6595,
		code: 'O142',
		label: 'Síndrome HELLP ',
	},
	{
		id: 6596,
		code: 'O149',
		label: 'Preeclampsia, no especificada',
	},
	{
		id: 6597,
		code: 'O15',
		label: 'Eclampsia',
	},
	{
		id: 6598,
		code: 'O150',
		label: 'Eclampsia en el embarazo',
	},
	{
		id: 6599,
		code: 'O151',
		label: 'Eclampsia durante el trabajo de parto',
	},
	{
		id: 6600,
		code: 'O152',
		label: 'Eclampsia en el puerperio',
	},
	{
		id: 6601,
		code: 'O159',
		label: 'Eclampsia, en período no especificado',
	},
	{
		id: 6602,
		code: 'O16X',
		label: 'Hipertensión materna, no especificada',
	},
	{
		id: 6603,
		code: 'O20',
		label: 'Hemorragia precoz del embarazo',
	},
	{
		id: 6604,
		code: 'O200',
		label: 'Amenaza de aborto',
	},
	{
		id: 6605,
		code: 'O208',
		label: 'Otras hemorragias precoces del embarazo',
	},
	{
		id: 6606,
		code: 'O209',
		label: 'Hemorragia precoz del embarazo, sin otra especificación',
	},
	{
		id: 6607,
		code: 'O21',
		label: 'Vómitos excesivos del embarazo',
	},
	{
		id: 6608,
		code: 'O210',
		label: 'Hiperemesis gravídica leve',
	},
	{
		id: 6609,
		code: 'O211',
		label: 'Hiperemesis gravídica con trastornos metabólicos',
	},
	{
		id: 6610,
		code: 'O212',
		label: 'Hiperemesis gravídica tardía',
	},
	{
		id: 6611,
		code: 'O218',
		label: 'Otros vómitos que complican el embarazo',
	},
	{
		id: 6612,
		code: 'O219',
		label: 'Vómitos del embarazo, no especificados',
	},
	{
		id: 6613,
		code: 'O22',
		label: 'Complicaciones venosas y hemorroides en el embarazo',
	},
	{
		id: 6614,
		code: 'O220',
		label: 'Venas varicosas de los miembros inferiores en el embarazo',
	},
	{
		id: 6615,
		code: 'O221',
		label: 'Várices genitales en el embarazo',
	},
	{
		id: 6616,
		code: 'O222',
		label: 'Tromboflebitis superficial en el embarazo',
	},
	{
		id: 6617,
		code: 'O223',
		label: 'Flebotrombosis profunda en el embarazo',
	},
	{
		id: 6618,
		code: 'O224',
		label: 'Hemorroides en el embarazo',
	},
	{
		id: 6619,
		code: 'O225',
		label: 'Trombosis venosa cerebral en el embarazo',
	},
	{
		id: 6620,
		code: 'O228',
		label: 'Otras complicaciones venosas en el embarazo',
	},
	{
		id: 6621,
		code: 'O229',
		label: 'Complicación venosa no especificada en el embarazo',
	},
	{
		id: 6622,
		code: 'O23',
		label: 'Infección de las vías genitourinarias en el embarazo',
	},
	{
		id: 6623,
		code: 'O230',
		label: 'Infección del riñón en el embarazo',
	},
	{
		id: 6624,
		code: 'O231',
		label: 'Infección de la vejiga urinaria en el embarazo',
	},
	{
		id: 6625,
		code: 'O232',
		label: 'Infección de la uretra en el embarazo',
	},
	{
		id: 6626,
		code: 'O233',
		label: 'Infección de otras partes de las vías urinarias en el embarazo',
	},
	{
		id: 6627,
		code: 'O234',
		label: 'Infección no especificada de las vías urinarias en el embarazo',
	},
	{
		id: 6628,
		code: 'O235',
		label: 'Infección genital en el embarazo',
	},
	{
		id: 6629,
		code: 'O239',
		label: 'Otras Infecciones y las no especificadas de las vías genitourinarias en el embarazo',
	},
	{
		id: 6630,
		code: 'O24',
		label: 'Diabetes mellitus en el embarazo',
	},
	{
		id: 6631,
		code: 'O240',
		label: 'Diabetes mellitus preexistente insulinodependiente, en el embarazo',
	},
	{
		id: 6632,
		code: 'O241',
		label: 'Diabetes mellitus preexistente no insulinodependiente, en el embarazo',
	},
	{
		id: 6633,
		code: 'O242',
		label: 'Diabetes mellitus preexistente relacionada con desnutrición, en el embarazo',
	},
	{
		id: 6634,
		code: 'O243',
		label: 'Diabetes mellitus preexistente, sin otra especificación, en el embarazo',
	},
	{
		id: 6635,
		code: 'O244',
		label: 'Diabetes mellitus que se origina con el embarazo',
	},
	{
		id: 6636,
		code: 'O249',
		label: 'Diabetes mellitus no especificada, en el embarazo',
	},
	{
		id: 6637,
		code: 'O25X',
		label: 'Desnutrición en el embarazo',
	},
	{
		id: 6638,
		code: 'O26',
		label: 'Atención a la madre por otras complicaciones principalmente relacionadas con el embarazo',
	},
	{
		id: 6639,
		code: 'O260',
		label: 'Aumento excesivo de peso en el embarazo',
	},
	{
		id: 6640,
		code: 'O261',
		label: 'Aumento pequeño de peso en el embarazo',
	},
	{
		id: 6641,
		code: 'O262',
		label: 'Atención del embarazo en una abortadora habitual',
	},
	{
		id: 6642,
		code: 'O263',
		label: 'Retención de dispositivo anticonceptivo intrauterino en el embarazo',
	},
	{
		id: 6643,
		code: 'O264',
		label: 'Herpes gestacional',
	},
	{
		id: 6644,
		code: 'O265',
		label: 'Síndrome de hipotensión materna',
	},
	{
		id: 6645,
		code: 'O266',
		label: 'Trastornos del hígado en el embarazo, el parto y el puerperio',
	},
	{
		id: 6646,
		code: 'O267',
		label: 'Subluxación de la sínfisis (del pubis) en el embarazo, el parto y el puerperio',
	},
	{
		id: 6647,
		code: 'O268',
		label: 'Otras complicaciones especificadas relacionadas con el embarazo',
	},
	{
		id: 6648,
		code: 'O269',
		label: 'Complicación relacionada con el embarazo, no especificada',
	},
	{
		id: 6649,
		code: 'O28',
		label: 'Hallazgos anormales en el examen prenatal de la madre',
	},
	{
		id: 6650,
		code: 'O280',
		label: 'Hallazgo hematológico anormal en el examen prenatal de la madre',
	},
	{
		id: 6651,
		code: 'O281',
		label: 'Hallazgo bioquímico anormal en el examen prenatal de la madre',
	},
	{
		id: 6652,
		code: 'O282',
		label: 'Hallazgo citológico anormal en el examen prenatal de la madre',
	},
	{
		id: 6653,
		code: 'O283',
		label: 'Hallazgo ultrasónico anormal en el examen prenatal de la madre',
	},
	{
		id: 6654,
		code: 'O284',
		label: 'Hallazgo radiológico anormal en el examen prenatal de la madre',
	},
	{
		id: 6655,
		code: 'O285',
		label: 'Hallazgo cromosómico o genético anormal en el examen prenatal de la madre',
	},
	{
		id: 6656,
		code: 'O288',
		label: 'Otros hallazgos anormales en el examen prenatal de la madre',
	},
	{
		id: 6657,
		code: 'O289',
		label: 'Hallazgo anormal no especificado en el examen prenatal de la madre',
	},
	{
		id: 6658,
		code: 'O29',
		label: 'Complicaciones de la anestesia administrada durante el embarazo',
	},
	{
		id: 6659,
		code: 'O290',
		label: 'Complicaciones pulmonares de la anestesia administrada durante el embarazo',
	},
	{
		id: 6660,
		code: 'O291',
		label: 'Complicaciones cardíacas de la anestesia administrada durante el embarazo',
	},
	{
		id: 6661,
		code: 'O292',
		label: 'Complicaciones del sistema nervioso central debidas a la anestesia administrada durante el embarazo',
	},
	{
		id: 6662,
		code: 'O293',
		label: 'Reacción tóxica a la anestesia local administrada durante el embarazo',
	},
	{
		id: 6663,
		code: 'O294',
		label: 'Cefalalgia inducida por la anestesia espinal o epidural administradas durante el embarazo',
	},
	{
		id: 6664,
		code: 'O295',
		label: 'Otras complicaciones de la anestesia espinal o epidural administradas durante el embarazo',
	},
	{
		id: 6665,
		code: 'O296',
		label: 'Falla o dificultad en la intubación durante el embarazo',
	},
	{
		id: 6666,
		code: 'O298',
		label: 'Otras complicaciones de la anestesia administrada durante el embarazo',
	},
	{
		id: 6667,
		code: 'O299',
		label: 'Complicación no especificada de la anestesia administrada durante el embarazo',
	},
	{
		id: 6668,
		code: 'O30',
		label: 'Embarazo múltiple',
	},
	{
		id: 6669,
		code: 'O300',
		label: 'Embarazo doble',
	},
	{
		id: 6670,
		code: 'O301',
		label: 'Embarazo triple',
	},
	{
		id: 6671,
		code: 'O302',
		label: 'Embarazo cuádruple',
	},
	{
		id: 6672,
		code: 'O308',
		label: 'Otros embarazos múltiples',
	},
	{
		id: 6673,
		code: 'O309',
		label: 'Embarazo múltiple, no especificado',
	},
	{
		id: 6674,
		code: 'O31',
		label: 'Complicaciones específicas del embarazo múltiple',
	},
	{
		id: 6675,
		code: 'O310',
		label: 'Feto papiráceo',
	},
	{
		id: 6676,
		code: 'O311',
		label: 'Embarazo que continúa después del aborto de un feto o más',
	},
	{
		id: 6677,
		code: 'O312',
		label: 'Embarazo que continúa después de la muerte intrauterina de un feto o más',
	},
	{
		id: 6678,
		code: 'O318',
		label: 'Otras complicaciones específicas del embarazo múltiple',
	},
	{
		id: 6679,
		code: 'O32',
		label: 'Atención materna por presentación anormal del feto, conocida o presunta',
	},
	{
		id: 6680,
		code: 'O320',
		label: 'Atención materna por posición fetal inestable',
	},
	{
		id: 6681,
		code: 'O321',
		label: 'Atención materna por presentación de nalgas',
	},
	{
		id: 6682,
		code: 'O322',
		label: 'Atención materna por posición fetal oblicua o transversa',
	},
	{
		id: 6683,
		code: 'O323',
		label: 'Atención materna por presentación de cara, de frente o de mentón',
	},
	{
		id: 6684,
		code: 'O324',
		label: 'Atención materna por cabeza alta en gestación a término',
	},
	{
		id: 6685,
		code: 'O325',
		label: 'Atención materna por embarazo múltiple con presentación anormal de un feto o más',
	},
	{
		id: 6686,
		code: 'O326',
		label: 'Atención materna por presentación compuesta',
	},
	{
		id: 6687,
		code: 'O328',
		label: 'Atención materna por otras presentaciones anormales del feto',
	},
	{
		id: 6688,
		code: 'O329',
		label: 'Atención materna por presentación anormal no especificada del feto',
	},
	{
		id: 6689,
		code: 'O33',
		label: 'Atención materna por desproporción conocida o presunta',
	},
	{
		id: 6690,
		code: 'O330',
		label: 'Atención materna por desproporción debida a deformidad de la pelvis ósea en la madre',
	},
	{
		id: 6691,
		code: 'O331',
		label: 'Atención materna por desproporción debida a estrechez general de la pelvis',
	},
	{
		id: 6692,
		code: 'O332',
		label: 'Atención materna por desproporción debida a disminución del estrecho superior de la pelvis',
	},
	{
		id: 6693,
		code: 'O333',
		label: 'Atención materna por desproporción debida a disminución del estrecho inferior de la pelvis',
	},
	{
		id: 6694,
		code: 'O334',
		label: 'Atención materna por desproporción fetopelviana de origen mixto, materno y fetal',
	},
	{
		id: 6695,
		code: 'O335',
		label: 'Atención materna por desproporción debida a feto demasiado grande',
	},
	{
		id: 6696,
		code: 'O336',
		label: 'Atención materna por desproporción debida a feto hidrocefálico',
	},
	{
		id: 6697,
		code: 'O337',
		label: 'Atención materna por desproporción debida a otra deformidad fetal',
	},
	{
		id: 6698,
		code: 'O338',
		label: 'Atención materna por desproporción de otro origen',
	},
	{
		id: 6699,
		code: 'O339',
		label: 'Atención materna por desproporción de origen no especificado',
	},
	{
		id: 6700,
		code: 'O34',
		label: 'Atención materna por anormalidades conocidas o presuntas de los órganos pelvianos de la madre',
	},
	{
		id: 6701,
		code: 'O340',
		label: 'Atención materna por anomalía congénita del útero',
	},
	{
		id: 6702,
		code: 'O341',
		label: 'Atención materna por tumor del cuerpo del útero',
	},
	{
		id: 6703,
		code: 'O342',
		label: 'Atención materna por cicatriz uterina debida a cirugía previa',
	},
	{
		id: 6704,
		code: 'O343',
		label: 'Atención materna por incompetencia del cuello uterino',
	},
	{
		id: 6705,
		code: 'O344',
		label: 'Atención materna por otra anormalidad del cuello uterino',
	},
	{
		id: 6706,
		code: 'O345',
		label: 'Atención materna por otras anormalidades del útero grávido',
	},
	{
		id: 6707,
		code: 'O346',
		label: 'Atención materna por anormalidad de la vagina',
	},
	{
		id: 6708,
		code: 'O347',
		label: 'Atención materna por anormalidad de la vulva y del perineo',
	},
	{
		id: 6709,
		code: 'O348',
		label: 'Atención materna por otras anormalidades de los órganos pelvianos',
	},
	{
		id: 6710,
		code: 'O349',
		label: 'Atención materna por anormalidad no especificada de órgano pelviano',
	},
	{
		id: 6711,
		code: 'O35',
		label: 'Atención materna por anormalidad o lesión fetal, conocida o presunta',
	},
	{
		id: 6712,
		code: 'O350',
		label: 'Atención materna por (presunta) malformación del sistema nervioso central en el feto',
	},
	{
		id: 6713,
		code: 'O351',
		label: 'Atención materna por (presunta) anormalidad cromosómica en el feto',
	},
	{
		id: 6714,
		code: 'O352',
		label: 'Atención materna por (presunta) enfermedad hereditaria en el feto',
	},
	{
		id: 6715,
		code: 'O353',
		label: 'Atención materna por (presunta) lesión fetal debida a enfermedad vírica en la madre',
	},
	{
		id: 6716,
		code: 'O354',
		label: 'Atención materna por (presunta) lesión al feto debida al alcohol',
	},
	{
		id: 6717,
		code: 'O355',
		label: 'Atención materna por (presunta) lesión fetal debida a drogas',
	},
	{
		id: 6718,
		code: 'O356',
		label: 'Atención materna por (presunta) lesión al feto debida a radiación',
	},
	{
		id: 6719,
		code: 'O357',
		label: 'Atención materna por (presunta) lesión fetal debida a otros procedimientos médicos',
	},
	{
		id: 6720,
		code: 'O358',
		label: 'Atención materna por otras (presuntas) anormalidades y lesiones fetales',
	},
	{
		id: 6721,
		code: 'O359',
		label: 'Atención materna por (presunta) anormalidad y lesión fetal no especificada',
	},
	{
		id: 6722,
		code: 'O36',
		label: 'Atención materna por otros problemas fetales conocidos o presuntos',
	},
	{
		id: 6723,
		code: 'O360',
		label: 'Atención materna por isoinmunización rhesus',
	},
	{
		id: 6724,
		code: 'O361',
		label: 'Atención materna por otra isoinmunización',
	},
	{
		id: 6725,
		code: 'O362',
		label: 'Atención materna por hidropesía fetal',
	},
	{
		id: 6726,
		code: 'O363',
		label: 'Atención materna por signos de hipoxia fetal',
	},
	{
		id: 6727,
		code: 'O364',
		label: 'Atención materna por muerte intrauterina',
	},
	{
		id: 6728,
		code: 'O365',
		label: 'Atención materna por déficit del crecimiento fetal',
	},
	{
		id: 6729,
		code: 'O366',
		label: 'Atención materna por crecimiento fetal excesivo',
	},
	{
		id: 6730,
		code: 'O367',
		label: 'Atención materna por feto viable en embarazo abdominal',
	},
	{
		id: 6731,
		code: 'O368',
		label: 'Atención materna por otros problemas fetales especificados',
	},
	{
		id: 6732,
		code: 'O369',
		label: 'Atención materna por problemas fetales no especificados',
	},
	{
		id: 6733,
		code: 'O40X',
		label: 'Polihidramnios',
	},
	{
		id: 6734,
		code: 'O41',
		label: 'Otros trastornos del líquido amniótico y de las membranas',
	},
	{
		id: 6735,
		code: 'O410',
		label: 'Oligohidramnios',
	},
	{
		id: 6736,
		code: 'O411',
		label: 'Infección de la bolsa amniótica o de las membranas',
	},
	{
		id: 6737,
		code: 'O418',
		label: 'Otros trastornos especificados del líquido amniótico y de las membranas',
	},
	{
		id: 6738,
		code: 'O419',
		label: 'Trastorno del líquido amniótico y de las membranas, no especificado',
	},
	{
		id: 6739,
		code: 'O42',
		label: 'Ruptura prematura de las membranas',
	},
	{
		id: 6740,
		code: 'O420',
		label: 'Ruptura prematura de las membranas, e inicio del trabajo de parto dentro de las 24 horas',
	},
	{
		id: 6741,
		code: 'O421',
		label: 'Ruptura prematura de las membranas, e inicio del trabajo de parto después de las 24 horas',
	},
	{
		id: 6742,
		code: 'O422',
		label: 'Ruptura prematura de las membranas, trabajo de parto retrasado por la terapéutica',
	},
	{
		id: 6743,
		code: 'O429',
		label: 'Ruptura prematura de las membranas, sin otra especificación',
	},
	{
		id: 6744,
		code: 'O43',
		label: 'Trastornos placentarios',
	},
	{
		id: 6745,
		code: 'O430',
		label: 'Síndrome de transfusión placentaria',
	},
	{
		id: 6746,
		code: 'O431',
		label: 'Malformación de la placenta',
	},
	{
		id: 6747,
		code: 'O432',
		label: 'Placenta anormalmente adherida',
	},
	{
		id: 6748,
		code: 'O438',
		label: 'Otros trastornos placentarios',
	},
	{
		id: 6749,
		code: 'O439',
		label: 'Trastorno de la placenta, no especificado',
	},
	{
		id: 6750,
		code: 'O44',
		label: 'Placenta previa',
	},
	{
		id: 6751,
		code: 'O440',
		label: 'Placenta previa con especificación de que no hubo hemorragia',
	},
	{
		id: 6752,
		code: 'O441',
		label: 'Placenta previa con hemorragia',
	},
	{
		id: 6753,
		code: 'O45',
		label: 'Desprendimiento prematuro de la placenta [Abruptio placentae]',
	},
	{
		id: 6754,
		code: 'O450',
		label: 'Desprendimiento prematuro de la placenta con defecto de la coagulación',
	},
	{
		id: 6755,
		code: 'O458',
		label: 'Otros desprendimientos prematuros de la placenta',
	},
	{
		id: 6756,
		code: 'O459',
		label: 'Desprendimiento prematuro de la placenta, sin otra especificación',
	},
	{
		id: 6757,
		code: 'O46',
		label: 'Hemorragia anteparto, no clasificada en otra parte',
	},
	{
		id: 6758,
		code: 'O460',
		label: 'Hemorragia anteparto con defecto de la coagulación',
	},
	{
		id: 6759,
		code: 'O468',
		label: 'Otras hemorragias anteparto',
	},
	{
		id: 6760,
		code: 'O469',
		label: 'Hemorragia anteparto, no especificada',
	},
	{
		id: 6761,
		code: 'O47',
		label: 'Falso trabajo de parto',
	},
	{
		id: 6762,
		code: 'O470',
		label: 'Falso trabajo de parto antes de las 37 semanas completas de gestación',
	},
	{
		id: 6763,
		code: 'O471',
		label: 'Falso trabajo de parto a las 37 y más semanas completas de gestación',
	},
	{
		id: 6764,
		code: 'O479',
		label: 'Falso trabajo de parto, sin otra especificación',
	},
	{
		id: 6765,
		code: 'O48X',
		label: 'Embarazo prolongado',
	},
	{
		id: 6766,
		code: 'O60X',
		label: 'Parto prematuro',
	},
	{
		id: 6767,
		code: 'O60',
		label: 'Trabajo de parto prematuro y parto',
	},
	{
		id: 6768,
		code: 'O600',
		label: 'Trabajo de parto prematuro sin parto',
	},
	{
		id: 6769,
		code: 'O601',
		label: 'Trabajo de parto prematuro espontáneo con parto prematuro',
	},
	{
		id: 6770,
		code: 'O602',
		label: 'Trabajo de parto prematuro espontáneo con parto a término',
	},
	{
		id: 6771,
		code: 'O603',
		label: 'Parto prematuro sin trabajo de parto espontáneo ',
	},
	{
		id: 6772,
		code: 'O61',
		label: 'Fracaso de la inducción del trabajo de parto',
	},
	{
		id: 6773,
		code: 'O610',
		label: 'Fracaso de la inducción médica del trabajo de parto',
	},
	{
		id: 6774,
		code: 'O611',
		label: 'Fracaso de la inducción instrumental del trabajo de parto',
	},
	{
		id: 6775,
		code: 'O618',
		label: 'Otros fracasos de la inducción del trabajo de parto',
	},
	{
		id: 6776,
		code: 'O619',
		label: 'Fracaso no especificado de la inducción del trabajo de parto',
	},
	{
		id: 6777,
		code: 'O62',
		label: 'Anormalidades de la dinámica del trabajo de parto',
	},
	{
		id: 6778,
		code: 'O620',
		label: 'Contracciones primarias inadecuadas',
	},
	{
		id: 6779,
		code: 'O621',
		label: 'Inercia uterina secundaria',
	},
	{
		id: 6780,
		code: 'O622',
		label: 'Otras inercias uterinas',
	},
	{
		id: 6781,
		code: 'O623',
		label: 'Trabajo de parto precipitado',
	},
	{
		id: 6782,
		code: 'O624',
		label: 'Contracciones uterinas hipertónicas, incoordinadas y prolongadas',
	},
	{
		id: 6783,
		code: 'O628',
		label: 'Otras anomalías dinámicas del trabajo de parto',
	},
	{
		id: 6784,
		code: 'O629',
		label: 'Anomalía dinámica del trabajo de parto, no especificada',
	},
	{
		id: 6785,
		code: 'O63',
		label: 'Trabajo de parto prolongado',
	},
	{
		id: 6786,
		code: 'O630',
		label: 'Prolongación del primer período (del trabajo de parto)',
	},
	{
		id: 6787,
		code: 'O631',
		label: 'Prolongación del segundo período (del trabajo de parto)',
	},
	{
		id: 6788,
		code: 'O632',
		label: 'Retraso de la expulsión del segundo gemelo, del tercero, etc.',
	},
	{
		id: 6789,
		code: 'O639',
		label: 'Trabajo de parto prolongado, no especificado',
	},
	{
		id: 6790,
		code: 'O64',
		label: 'Trabajo de parto obstruido debido a mala posición y presentación anormal del feto',
	},
	{
		id: 6791,
		code: 'O640',
		label: 'Trabajo de parto obstruido debido a rotación incompleta de la cabeza fetal',
	},
	{
		id: 6792,
		code: 'O641',
		label: 'Trabajo de parto obstruido debido a presentación de nalgas',
	},
	{
		id: 6793,
		code: 'O642',
		label: 'Trabajo de parto obstruido debido a presentación de cara',
	},
	{
		id: 6794,
		code: 'O643',
		label: 'Trabajo de parto obstruido debido a presentación de frente',
	},
	{
		id: 6795,
		code: 'O644',
		label: 'Trabajo de parto obstruido debido a presentación de hombro',
	},
	{
		id: 6796,
		code: 'O645',
		label: 'Trabajo de parto obstruido debido a presentación compuesta',
	},
	{
		id: 6797,
		code: 'O648',
		label: 'Trabajo de parto obstruido debido a otras presentaciones anormales del feto',
	},
	{
		id: 6798,
		code: 'O649',
		label: 'Trabajo de parto obstruido debido a presentación anormal del feto no especificada',
	},
	{
		id: 6799,
		code: 'O65',
		label: 'Trabajo de parto obstruido debido a anormalidad de la pelvis materna',
	},
	{
		id: 6800,
		code: 'O650',
		label: 'Trabajo de parto obstruido debido a deformidad de la pelvis',
	},
	{
		id: 6801,
		code: 'O651',
		label: 'Trabajo de parto obstruido debido a estrechez general de la pelvis',
	},
	{
		id: 6802,
		code: 'O652',
		label: 'Trabajo de parto obstruido debido a disminución del estrecho superior de la pelvis',
	},
	{
		id: 6803,
		code: 'O653',
		label: 'Trabajo de parto obstruido debido a disminución del estrecho inferior de la pelvis',
	},
	{
		id: 6804,
		code: 'O654',
		label: 'Trabajo de parto obstruido debido a desproporción fetopelviana, sin otra especificación',
	},
	{
		id: 6805,
		code: 'O655',
		label: 'Trabajo de parto obstruido debido a anomalías de los órganos pelvianos maternos',
	},
	{
		id: 6806,
		code: 'O658',
		label: 'Trabajo de parto obstruido debido a otras anomalías pelvianas maternas',
	},
	{
		id: 6807,
		code: 'O659',
		label: 'Trabajo de parto obstruido debido a anomalía pelviana no especificada',
	},
	{
		id: 6808,
		code: 'O66',
		label: 'Otras obstrucciones del trabajo de parto',
	},
	{
		id: 6809,
		code: 'O660',
		label: 'Trabajo de parto obstruido debido a distocia de hombros',
	},
	{
		id: 6810,
		code: 'O661',
		label: 'Trabajo de parto obstruido debido a distocia gemelar',
	},
	{
		id: 6811,
		code: 'O662',
		label: 'Trabajo de parto obstruido debido a distocia por feto inusualmente grande',
	},
	{
		id: 6812,
		code: 'O663',
		label: 'Trabajo de parto obstruido debido a otras anormalidades del feto',
	},
	{
		id: 6813,
		code: 'O664',
		label: 'Fracaso de la prueba del trabajo de parto, no especificada',
	},
	{
		id: 6814,
		code: 'O665',
		label: 'Fracaso no especificado de la aplicación de fórceps o de ventosa extractora',
	},
	{
		id: 6815,
		code: 'O668',
		label: 'Otras obstrucciones especificadas del trabajo de parto',
	},
	{
		id: 6816,
		code: 'O669',
		label: 'Trabajo de parto obstruido, sin otra especificación',
	},
	{
		id: 6817,
		code: 'O67',
		label: 'Trabajo de parto y parto complicados por hemorragia intraparto, no clasificados en otra parte',
	},
	{
		id: 6818,
		code: 'O670',
		label: 'Hemorragia intraparto con defectos de la coagulación',
	},
	{
		id: 6819,
		code: 'O678',
		label: 'Otras hemorragias intraparto',
	},
	{
		id: 6820,
		code: 'O679',
		label: 'Hemorragia intraparto, no especificada',
	},
	{
		id: 6821,
		code: 'O68',
		label: 'Trabajo de parto y parto complicados por sufrimiento fetal',
	},
	{
		id: 6822,
		code: 'O680',
		label: 'Trabajo de parto y parto complicados por anomalía de la frecuencia cardíaca fetal',
	},
	{
		id: 6823,
		code: 'O681',
		label: 'Trabajo de parto y parto complicados por la presencia de meconio en el líquido amniótico',
	},
	{
		id: 6824,
		code: 'O682',
		label: 'Trabajo de parto y parto complicados por anomalía de la frecuencia cardíaca fetal asociada con presencia de meconio en el líquido amniótico',
	},
	{
		id: 6825,
		code: 'O683',
		label: 'Trabajo de parto y parto complicados por evidencia bioquímica de sufrimiento fetal',
	},
	{
		id: 6826,
		code: 'O688',
		label: 'Trabajo de parto y parto complicados por otras evidencias de sufrimiento fetal',
	},
	{
		id: 6827,
		code: 'O689',
		label: 'Trabajo de parto y parto complicados por sufrimiento fetal, sin otra especificación',
	},
	{
		id: 6828,
		code: 'O69',
		label: 'Trabajo de parto y parto complicados por problemas del cordón umbilical',
	},
	{
		id: 6829,
		code: 'O690',
		label: 'Trabajo de parto y parto complicados por prolapso del cordón umbilical',
	},
	{
		id: 6830,
		code: 'O691',
		label: 'Trabajo de parto y parto complicados por circular pericervical del cordón, con compresión',
	},
	{
		id: 6831,
		code: 'O692',
		label: 'Trabajo de parto y parto complicados por otros enredos del cordón umblical con compresión',
	},
	{
		id: 6832,
		code: 'O693',
		label: 'Trabajo de parto y parto complicados por cordón umbilical corto',
	},
	{
		id: 6833,
		code: 'O694',
		label: 'Trabajo de parto y parto complicados por vasa previa',
	},
	{
		id: 6834,
		code: 'O695',
		label: 'Trabajo de parto y parto complicados por lesión vascular del cordón',
	},
	{
		id: 6835,
		code: 'O698',
		label: 'Trabajo de parto y parto complicados por otros problemas del cordón umbilical',
	},
	{
		id: 6836,
		code: 'O699',
		label: 'Trabajo de parto y parto complicados por problemas no especificados del cordón umbilical',
	},
	{
		id: 6837,
		code: 'O70',
		label: 'Desgarro perineal durante el parto',
	},
	{
		id: 6838,
		code: 'O700',
		label: 'Desgarro perineal de primer grado durante el parto',
	},
	{
		id: 6839,
		code: 'O701',
		label: 'Desgarro perineal de segundo grado durante el parto',
	},
	{
		id: 6840,
		code: 'O702',
		label: 'Desgarro perineal de tercer grado durante el parto',
	},
	{
		id: 6841,
		code: 'O703',
		label: 'Desgarro perineal de cuarto grado durante el parto',
	},
	{
		id: 6842,
		code: 'O709',
		label: 'Desgarro perineal durante el parto, de grado no epecificado',
	},
	{
		id: 6843,
		code: 'O71',
		label: 'Otro trauma obstétrico',
	},
	{
		id: 6844,
		code: 'O710',
		label: 'Ruptura del útero antes del inicio del trabajo de parto',
	},
	{
		id: 6845,
		code: 'O711',
		label: 'Ruptura del útero durante el trabajo de parto',
	},
	{
		id: 6846,
		code: 'O712',
		label: 'Inversión del útero, postparto',
	},
	{
		id: 6847,
		code: 'O713',
		label: 'Desgarro obstétrico del cuello uterino',
	},
	{
		id: 6848,
		code: 'O714',
		label: 'Desgarro vaginal obstétrico alto',
	},
	{
		id: 6849,
		code: 'O715',
		label: 'Otros traumatismos obstétricos de los órganos pelvianos',
	},
	{
		id: 6850,
		code: 'O716',
		label: 'Traumatismo obstétrico de los ligamentos y articulaciones de la pelvis',
	},
	{
		id: 6851,
		code: 'O717',
		label: 'Hematoma obstétrico de la pelvis',
	},
	{
		id: 6852,
		code: 'O718',
		label: 'Otros traumas obstétricos especificados',
	},
	{
		id: 6853,
		code: 'O719',
		label: 'Trauma obstétrico, no especificado',
	},
	{
		id: 6854,
		code: 'O72',
		label: 'Hemorragia postparto',
	},
	{
		id: 6855,
		code: 'O720',
		label: 'Hemorragia del tercer período del parto',
	},
	{
		id: 6856,
		code: 'O721',
		label: 'Otras hemorragias postparto inmediatas',
	},
	{
		id: 6857,
		code: 'O722',
		label: 'Hemorragia postparto secundaria o tardía',
	},
	{
		id: 6858,
		code: 'O723',
		label: 'Defecto de la coagulación postparto',
	},
	{
		id: 6859,
		code: 'O73',
		label: 'Retención de la placenta o de las membranas, sin hemorragia',
	},
	{
		id: 6860,
		code: 'O730',
		label: 'Retención de la placenta sin hemorragia',
	},
	{
		id: 6861,
		code: 'O731',
		label: 'Retención de fragmentos de la placenta o de las membranas, sin hemorragia',
	},
	{
		id: 6862,
		code: 'O74',
		label: 'Complicaciones de la anestesia administrada durante el trabajo de parto y el parto',
	},
	{
		id: 6863,
		code: 'O740',
		label: 'Neumonitis por aspiración debida a la anestesia administrada durante el trabajo de parto y el parto',
	},
	{
		id: 6864,
		code: 'O741',
		label: 'Otras complicaciones pulmonares debidas a la anestesia administrada durante el trabajo de parto y el parto',
	},
	{
		id: 6865,
		code: 'O742',
		label: 'Complicaciones cardíacas de la anestesia administrada durante el trabajo de parto y el parto',
	},
	{
		id: 6866,
		code: 'O743',
		label: 'Complicaciones del sistema nervioso central por la anestesia administrada durante el trabajo de parto y el parto',
	},
	{
		id: 6867,
		code: 'O744',
		label: 'Reacción tóxica a la anestesia local administrada durante el trabajo de parto y el parto',
	},
	{
		id: 6868,
		code: 'O745',
		label: 'Cefalalgia inducida por la anestesia espinal o epidural administradas durante el trabajo de parto y el parto',
	},
	{
		id: 6869,
		code: 'O746',
		label: 'Otras complicaciones de la anestesia espinal o epidural administradas durante el trabajo de parto y el parto',
	},
	{
		id: 6870,
		code: 'O747',
		label: 'Falla o dificultad en la intubación durante el trabajo de parto y el parto',
	},
	{
		id: 6871,
		code: 'O748',
		label: 'Otras complicaciones de la anestesia administrada durante el trabajo de parto y el parto',
	},
	{
		id: 6872,
		code: 'O749',
		label: 'Complicación no especificada de la anestesia administrada durante el trabajo de parto y el parto',
	},
	{
		id: 6873,
		code: 'O75',
		label: 'Otras complicaciones del trabajo de parto y del parto, no clasificadas en otra parte',
	},
	{
		id: 6874,
		code: 'O750',
		label: 'Sufrimiento materno durante el trabajo de parto y el parto',
	},
	{
		id: 6875,
		code: 'O751',
		label: 'Choque durante o después del trabajo de parto y el parto',
	},
	{
		id: 6876,
		code: 'O752',
		label: 'Pirexia durante el trabajo de parto, no clasificada en otra parte',
	},
	{
		id: 6877,
		code: 'O753',
		label: 'Otras infecciones durante el trabajo de parto',
	},
	{
		id: 6878,
		code: 'O754',
		label: 'Otras complicaciones de procedimientos y de cirugía  obstétrica',
	},
	{
		id: 6879,
		code: 'O755',
		label: 'Retraso del parto después de la ruptura artificial de las membranas',
	},
	{
		id: 6880,
		code: 'O756',
		label: 'Retraso del parto después de la ruptura espontánea o no especificada de las membranas',
	},
	{
		id: 6881,
		code: 'O757',
		label: 'Parto vaginal posterior a una cesárea previa',
	},
	{
		id: 6882,
		code: 'O758',
		label: 'Otras complicaciones especificadas del trabajo de parto y del parto',
	},
	{
		id: 6883,
		code: 'O759',
		label: 'Complicación no especificada del trabajo de parto y del parto',
	},
	{
		id: 6884,
		code: 'O80',
		label: 'Parto único espontáneo',
	},
	{
		id: 6885,
		code: 'O800',
		label: 'Parto único espontáneo, presentación cefálica de vértice',
	},
	{
		id: 6886,
		code: 'O801',
		label: 'Parto único espontáneo, presentación de nalgas o podálica',
	},
	{
		id: 6887,
		code: 'O808',
		label: 'Parto único espontáneo, otras presentaciones',
	},
	{
		id: 6888,
		code: 'O809',
		label: 'Parto único espontáneo, sin otra especificación',
	},
	{
		id: 6889,
		code: 'O81',
		label: 'Parto único con fórceps y ventosa extractora',
	},
	{
		id: 6890,
		code: 'O810',
		label: 'Parto con fórceps bajo',
	},
	{
		id: 6891,
		code: 'O811',
		label: 'Parto con fórceps medio',
	},
	{
		id: 6892,
		code: 'O812',
		label: 'Parto con fórceps medio con rotación',
	},
	{
		id: 6893,
		code: 'O813',
		label: 'Parto con fórceps de otros tipos y los no especificados',
	},
	{
		id: 6894,
		code: 'O814',
		label: 'Parto con ventosa extractora',
	},
	{
		id: 6895,
		code: 'O815',
		label: 'Parto con combinación de fórceps y ventosa extractora',
	},
	{
		id: 6896,
		code: 'O82',
		label: 'Parto único por cesárea',
	},
	{
		id: 6897,
		code: 'O820',
		label: 'Parto por cesárea electiva',
	},
	{
		id: 6898,
		code: 'O821',
		label: 'Parto por cesárea de emergencia',
	},
	{
		id: 6899,
		code: 'O822',
		label: 'Parto por cesárea con histerectomía',
	},
	{
		id: 6900,
		code: 'O828',
		label: 'Otros partos únicos por cesárea',
	},
	{
		id: 6901,
		code: 'O829',
		label: 'Parto por cesárea, sin otra especificación',
	},
	{
		id: 6902,
		code: 'O83',
		label: 'Otros partos únicos asistidos',
	},
	{
		id: 6903,
		code: 'O830',
		label: 'Extracción de nalgas',
	},
	{
		id: 6904,
		code: 'O831',
		label: 'Otros partos únicos asistidos, de nalgas',
	},
	{
		id: 6905,
		code: 'O832',
		label: 'Otros partos únicos con ayuda de manipulación obstétrica',
	},
	{
		id: 6906,
		code: 'O833',
		label: 'Parto de feto viable en embarazo abdominal',
	},
	{
		id: 6907,
		code: 'O834',
		label: 'Operación destructiva para facilitar el parto',
	},
	{
		id: 6908,
		code: 'O838',
		label: 'Otros partos únicos asistidos especificados',
	},
	{
		id: 6909,
		code: 'O839',
		label: 'Parto único asistido, sin otra especificación',
	},
	{
		id: 6910,
		code: 'O84',
		label: 'Parto múltiple',
	},
	{
		id: 6911,
		code: 'O840',
		label: 'Parto múltiple, todos espontáneos',
	},
	{
		id: 6912,
		code: 'O841',
		label: 'Parto múltiple, todos por fórceps y ventosa extractora',
	},
	{
		id: 6913,
		code: 'O842',
		label: 'Parto múltiple, todos por cesárea',
	},
	{
		id: 6914,
		code: 'O848',
		label: 'Otros partos múltiples',
	},
	{
		id: 6915,
		code: 'O849',
		label: 'Parto múltiple, no especificado',
	},
	{
		id: 6916,
		code: 'O85X',
		label: 'Sepsis puerperal',
	},
	{
		id: 6917,
		code: 'O86',
		label: 'Otras infecciones puerperales',
	},
	{
		id: 6918,
		code: 'O860',
		label: 'Infección de herida quirúrgica obstétrica',
	},
	{
		id: 6919,
		code: 'O861',
		label: 'Otras infecciones genitales consecutivas al parto',
	},
	{
		id: 6920,
		code: 'O862',
		label: 'Infección de las vías urinarias consecutiva al parto',
	},
	{
		id: 6921,
		code: 'O863',
		label: 'Otras infecciones de las vías genitourinarias consecutivas al parto',
	},
	{
		id: 6922,
		code: 'O864',
		label: 'Pirexia de origen desconocido consecutiva al parto',
	},
	{
		id: 6923,
		code: 'O868',
		label: 'Otras infecciones puerperales especificadas',
	},
	{
		id: 6924,
		code: 'O87',
		label: 'Complicaciones venosas y hemorroides en el puerperio',
	},
	{
		id: 6925,
		code: 'O870',
		label: 'Tromboflebitis superficial en el puerperio',
	},
	{
		id: 6926,
		code: 'O871',
		label: 'Flebotrombosis profunda en el puerperio',
	},
	{
		id: 6927,
		code: 'O872',
		label: 'Hemorroides en el puerperio',
	},
	{
		id: 6928,
		code: 'O873',
		label: 'Trombosis venosa cerebral en el puerperio',
	},
	{
		id: 6929,
		code: 'O878',
		label: 'Otras complicaciones venosas en el puerperio',
	},
	{
		id: 6930,
		code: 'O879',
		label: 'Complicación venosa en el puerperio, no especificada',
	},
	{
		id: 6931,
		code: 'O88',
		label: 'Embolia obstétrica',
	},
	{
		id: 6932,
		code: 'O880',
		label: 'Embolia gaseosa, obstétrica',
	},
	{
		id: 6933,
		code: 'O881',
		label: 'Embolia de líquido amniótico',
	},
	{
		id: 6934,
		code: 'O882',
		label: 'Embolia de coágulo sanguíneo, obstétrica',
	},
	{
		id: 6935,
		code: 'O883',
		label: 'Embolia séptica y piémica, obstétrica',
	},
	{
		id: 6936,
		code: 'O888',
		label: 'Otras embolias obstétricas',
	},
	{
		id: 6937,
		code: 'O89',
		label: 'Complicaciones de la anestesia administrada durante el puerperio',
	},
	{
		id: 6938,
		code: 'O890',
		label: 'Complicaciones pulmonares de la anestesia administrada durante el puerperio',
	},
	{
		id: 6939,
		code: 'O891',
		label: 'Complicaciones cardíacas de la anestesia administrada durante el puerperio',
	},
	{
		id: 6940,
		code: 'O892',
		label: 'Complicaciones del sistema nervioso central debidas a la anestesia administrada durante el puerperio',
	},
	{
		id: 6941,
		code: 'O893',
		label: 'Reacción tóxica a la anestesia local administrada durante el puerperio',
	},
	{
		id: 6942,
		code: 'O894',
		label: 'Cefalalgia inducida por la anestesia espinal o epidural administradas durante el puerperio',
	},
	{
		id: 6943,
		code: 'O895',
		label: 'Otras complicaciones de la anestesia espinal o epidural administradas durante el puerperio',
	},
	{
		id: 6944,
		code: 'O896',
		label: 'Falla o dificultad de intubación durante el puerperio',
	},
	{
		id: 6945,
		code: 'O898',
		label: 'Otras complicaciones de la anestesia administrada durante el puerperio',
	},
	{
		id: 6946,
		code: 'O899',
		label: 'Complicación no especificada de la anestesia administrada durante el puerperio',
	},
	{
		id: 6947,
		code: 'O90',
		label: 'Complicaciones del puerperio, no clasificadas en otra parte',
	},
	{
		id: 6948,
		code: 'O900',
		label: 'Dehiscencia de sutura de cesárea',
	},
	{
		id: 6949,
		code: 'O901',
		label: 'Dehiscencia de sutura obstétrica perineal',
	},
	{
		id: 6950,
		code: 'O902',
		label: 'Hematoma de herida quirúrgica obstétrica',
	},
	{
		id: 6951,
		code: 'O903',
		label: 'Cardiomiopatía en el puerperio',
	},
	{
		id: 6952,
		code: 'O904',
		label: 'Insuficiencia renal aguda postparto',
	},
	{
		id: 6953,
		code: 'O905',
		label: 'Tiroiditis postparto',
	},
	{
		id: 6954,
		code: 'O908',
		label: 'Otras complicaciones puerperales, no clasificadas en otra parte',
	},
	{
		id: 6955,
		code: 'O909',
		label: 'Complicación puerperal, no especificada',
	},
	{
		id: 6956,
		code: 'O91',
		label: 'Infecciones de la mama asociadas con el parto',
	},
	{
		id: 6957,
		code: 'O910',
		label: 'Infecciones del pezón asociados con el parto',
	},
	{
		id: 6958,
		code: 'O911',
		label: 'Absceso de la mama asociado con el parto',
	},
	{
		id: 6959,
		code: 'O912',
		label: 'Mastitis no purulenta asociada con el parto',
	},
	{
		id: 6960,
		code: 'O92',
		label: 'Otros trastornos de la mama y de la lactancia asociados con el parto',
	},
	{
		id: 6961,
		code: 'O920',
		label: 'Retracción del pezón asociada con el parto',
	},
	{
		id: 6962,
		code: 'O921',
		label: 'Fisuras del pezón asociadas con el parto',
	},
	{
		id: 6963,
		code: 'O922',
		label: 'Otros trastornos de la mama y los no especificados asociados con el parto',
	},
	{
		id: 6964,
		code: 'O923',
		label: 'Agalactia',
	},
	{
		id: 6965,
		code: 'O924',
		label: 'Hipogalactia',
	},
	{
		id: 6966,
		code: 'O925',
		label: 'Supresión de la lactancia',
	},
	{
		id: 6967,
		code: 'O926',
		label: 'Galactorrea',
	},
	{
		id: 6968,
		code: 'O927',
		label: 'Otros trastornos y los no especificados de la lactancia',
	},
	{
		id: 6969,
		code: 'O94X',
		label: 'Secuelas de complicaciones del embarazo, del parto y del puerperio',
	},
	{
		id: 6970,
		code: 'O95X',
		label: 'Muerte obstétrica de causa no especificada',
	},
	{
		id: 6971,
		code: 'O96',
		label: 'Muerte materna debida a cualquier causa obstétrica que ocurre después de 42 días pero antes de un año del parto',
	},
	{
		id: 6972,
		code: 'O960',
		label: 'Muerte por causa obstétrica directa que ocurre después de 42 días pero antes de un año del parto',
	},
	{
		id: 6973,
		code: 'O961',
		label: 'Muerte por causa obstétrica indirecta que ocurre después de 42 días pero antes de un año del parto',
	},
	{
		id: 6974,
		code: 'O969',
		label: 'Muerte por causa obstétrica no especificada, que ocurre después de 42 días pero antes de un año del parto',
	},
	{
		id: 6975,
		code: 'O96X',
		label: 'Muerte materna debida a cualquier causa obstétrica que ocurre después de 42 días pero antes de un año del parto',
	},
	{
		id: 6976,
		code: 'O97',
		label: 'Muerte por secuelas de causas obstétricas',
	},
	{
		id: 6977,
		code: 'O970',
		label: 'Muerte por secuelas de causa obstétrica directa ',
	},
	{
		id: 6978,
		code: 'O971',
		label: 'Muerte por secuelas de causa obstétrica indirecta ',
	},
	{
		id: 6979,
		code: 'O979',
		label: 'Muerte por secuelas de causa obstétrica no especificada',
	},
	{
		id: 6980,
		code: 'O97X',
		label: 'Muerte por secuelas de causas obstétricas directas',
	},
	{
		id: 6981,
		code: 'O98',
		label: 'Enfermedades maternas infecciosas y parasitarias clasificables en otra parte, pero que complican el embarazo, el parto y el puerperio',
	},
	{
		id: 6982,
		code: 'O980',
		label: 'Tuberculosis que complica el embarazo, el parto y el puerperio',
	},
	{
		id: 6983,
		code: 'O981',
		label: 'Sífilis que complica el embarazo, el parto y el puerperio',
	},
	{
		id: 6984,
		code: 'O982',
		label: 'Gonorrea que complica el embarazo, el parto y el puerperio',
	},
	{
		id: 6985,
		code: 'O983',
		label: 'Otras infecciones con un modo de transmisión predominantemente sexual que complican el embarazo, parto y puerperio',
	},
	{
		id: 6986,
		code: 'O984',
		label: 'Hepatitis viral que complica el embarazo, el parto y el puerperio',
	},
	{
		id: 6987,
		code: 'O985',
		label: 'Otras enfermedades virales que complican el embarazo, el parto y el puerperio',
	},
	{
		id: 6988,
		code: 'O986',
		label: 'Enfermedades causadas por protozoarios que complican el embarazo, el parto y el puerperio',
	},
	{
		id: 6989,
		code: 'O987',
		label: 'Enfermedad por virus de la inmunodeficiencia humana [VIH] que complica el embarazo, el parto y el puerperio',
	},
	{
		id: 6990,
		code: 'O988',
		label: 'Otras enfermedades infecciosas y parasitarias maternas que complican el embarazo, el parto y el puerperio',
	},
	{
		id: 6991,
		code: 'O989',
		label: 'Enfermedad infecciosa y parasitaria materna no especificada que complica el embarazo, el parto y el puerperio',
	},
	{
		id: 6992,
		code: 'O99',
		label: 'Otras enfermedades maternas clasificables en otra parte, pero que complican el embarazo, el parto y el puerperio',
	},
	{
		id: 6993,
		code: 'O990',
		label: 'Anemia que complica el embarazo, el parto y el puerperio',
	},
	{
		id: 6994,
		code: 'O991',
		label: 'Otras enfermedades de la sangre y órganos hematopoyéticos y ciertos trastornos que afectan el sistema inmunitario cuando complican el embarazo, el parto y puerperio',
	},
	{
		id: 6995,
		code: 'O992',
		label: 'Enfermedades endocrinas, de la nutrición y del metabolismo que complican el embarazo, el parto y el puerperio',
	},
	{
		id: 6996,
		code: 'O993',
		label: 'Trastornos mentales y enfermedades del sistema nervioso que complican el embarazo, el parto y el puerperio',
	},
	{
		id: 6997,
		code: 'O994',
		label: 'Enfermedades del sistema circulatorio que complican el embarazo, el parto y el purperio',
	},
	{
		id: 6998,
		code: 'O995',
		label: 'Enfermedades del sistema respiratorio que complican el embarazo, el parto y el puerperio',
	},
	{
		id: 6999,
		code: 'O996',
		label: 'Enfermedades del sistema digestivo que complican el embarazo, el parto y el puerperio',
	},
	{
		id: 7000,
		code: 'O997',
		label: 'Enfermedades de la piel y del tejido subcutáneo que complican el embarazo, el parto y el puerperio',
	},
	{
		id: 7001,
		code: 'O998',
		label: 'Otras enfermedades especificadas y afecciones que complican el embarazo, el parto y el puerperio',
	},
	{
		id: 7002,
		code: 'P00',
		label: 'Feto y recién nacido afectado por condiciones de la madre no necesariamente relacionadas con el embarazo presente',
	},
	{
		id: 7003,
		code: 'P000',
		label: 'Feto y recién nacido afectados por trastornos hipertensivos de la madre',
	},
	{
		id: 7004,
		code: 'P001',
		label: 'Feto y recién nacido afectados por enfermedades renales y de las vías urinarias de la madre',
	},
	{
		id: 7005,
		code: 'P002',
		label: 'Feto y recién nacido afectados por enfermedades infecciosas y parasitarias de la madre',
	},
	{
		id: 7006,
		code: 'P003',
		label: 'Feto y recién nacido afectados por otras enfermedades circulatorias y respiratorias de la madre',
	},
	{
		id: 7007,
		code: 'P004',
		label: 'Feto y recién nacido afectados por trastornos nutricionales de la madre',
	},
	{
		id: 7008,
		code: 'P005',
		label: 'Feto y recién nacido afectados por traumatismo de la madre',
	},
	{
		id: 7009,
		code: 'P006',
		label: 'Feto y recién nacido afectados por procedimiento quirúrgico en la madre',
	},
	{
		id: 7010,
		code: 'P007',
		label: 'Feto y recién nacido afectados por otro procedimiento médico en la madre, no clasificado en otra parte',
	},
	{
		id: 7011,
		code: 'P008',
		label: 'Feto y recién nacido afectados por otras afecciones maternas',
	},
	{
		id: 7012,
		code: 'P009',
		label: 'Feto y recién nacido afectados por afección materna no especificada',
	},
	{
		id: 7013,
		code: 'P01',
		label: 'Feto y recién nacido afectados por complicaciones maternas del embarazo',
	},
	{
		id: 7014,
		code: 'P010',
		label: 'Feto y recién nacido afectados por incompetencia del cuello uterino',
	},
	{
		id: 7015,
		code: 'P011',
		label: 'Feto y recién nacido afectados por ruptura prematura de las membranas',
	},
	{
		id: 7016,
		code: 'P012',
		label: 'Feto y recién nacido afectados por oligohidramnios',
	},
	{
		id: 7017,
		code: 'P013',
		label: 'Feto y recién nacido afectados por polihidramnios',
	},
	{
		id: 7018,
		code: 'P014',
		label: 'Feto y recién nacido afectados por embarazo ectópico',
	},
	{
		id: 7019,
		code: 'P015',
		label: 'Feto y recién nacido afectados por embarazo múltiple',
	},
	{
		id: 7020,
		code: 'P016',
		label: 'Feto y recién nacido afectados por muerte materna',
	},
	{
		id: 7021,
		code: 'P017',
		label: 'Feto y recién nacido afectados por presentación anómala antes del trabajo de parto',
	},
	{
		id: 7022,
		code: 'P018',
		label: 'Feto y recién nacido afectados por otras complicaciones maternas del embarazo',
	},
	{
		id: 7023,
		code: 'P019',
		label: 'Feto y recién nacido afectados por complicaciones maternas no especificadas del embarazo',
	},
	{
		id: 7024,
		code: 'P02',
		label: 'Feto y recién nacido afectado por complicaciones de la placenta, del cordón umbilical y de las membranas',
	},
	{
		id: 7025,
		code: 'P020',
		label: 'Feto y recién nacido afectados por placenta previa',
	},
	{
		id: 7026,
		code: 'P021',
		label: 'Feto y recién nacido afectados por otras formas de desprendimiento y de hemorragia placentarios',
	},
	{
		id: 7027,
		code: 'P022',
		label: 'Feto y recién nacido afectados por otras anormalidades morfológicas y funcionales de la placenta y las no especificadas',
	},
	{
		id: 7028,
		code: 'P023',
		label: 'Feto y recién nacido afectados por síndromes de transfusión placentaria',
	},
	{
		id: 7029,
		code: 'P024',
		label: 'Feto y recién nacido afectados por prolapso del cordón umbilical',
	},
	{
		id: 7030,
		code: 'P025',
		label: 'Feto y recién nacido afectados por otra compresión del cordón umbilical',
	},
	{
		id: 7031,
		code: 'P026',
		label: 'Feto y recién nacido afectados por otras complicaciones del cordón umbilical y las no especificadas',
	},
	{
		id: 7032,
		code: 'P027',
		label: 'Feto y recién nacido afectados por corioamnionitis',
	},
	{
		id: 7033,
		code: 'P028',
		label: 'Feto y recién nacido afectados por otras anormalidades de las membranas',
	},
	{
		id: 7034,
		code: 'P029',
		label: 'Feto y recién nacido afectados por anormalidad no especificada de las membranas',
	},
	{
		id: 7035,
		code: 'P03',
		label: 'Feto y recién nacido afectados por otras complicaciones del trabajo de parto y del parto',
	},
	{
		id: 7036,
		code: 'P030',
		label: 'Feto y recién nacido afectados por parto y extracción de nalgas',
	},
	{
		id: 7037,
		code: 'P031',
		label: 'Feto y recién nacido afectados por otra presentación anómala, posición anómala y desproporción durante el trabajo de parto y el parto',
	},
	{
		id: 7038,
		code: 'P032',
		label: 'Feto y recién nacido afectados por parto con fórceps',
	},
	{
		id: 7039,
		code: 'P033',
		label: 'Feto y recién nacido afectados por parto con ventosa extractora',
	},
	{
		id: 7040,
		code: 'P034',
		label: 'Feto y recién nacido afectados por parto por cesárea',
	},
	{
		id: 7041,
		code: 'P035',
		label: 'Feto y recién nacido afectados por parto precipitado',
	},
	{
		id: 7042,
		code: 'P036',
		label: 'Feto y recién nacido afectados por contracciones uterinas anormales',
	},
	{
		id: 7043,
		code: 'P038',
		label: 'Feto y recién nacido afectados por otras complicaciones especificadas del trabajo de parto y del parto',
	},
	{
		id: 7044,
		code: 'P039',
		label: 'Feto y recién nacido afectados por complicaciones no especificadas del trabajo de parto y del parto',
	},
	{
		id: 7045,
		code: 'P04',
		label: 'Feto y recién nacido afectados por influencias nocivas transmitidas a través de la placenta o de la leche materna',
	},
	{
		id: 7046,
		code: 'P040',
		label: 'Feto y recién nacido afectados por anestesia y analgesia materna en el embarazo, trabajo de parto y en el parto',
	},
	{
		id: 7047,
		code: 'P041',
		label: 'Feto y recién nacido afectados por otras medicaciones maternas',
	},
	{
		id: 7048,
		code: 'P042',
		label: 'Feto y recién nacido afectados por tabaquismo de la madre',
	},
	{
		id: 7049,
		code: 'P043',
		label: 'Feto y recién nacido afectados por alcoholismo de la madre',
	},
	{
		id: 7050,
		code: 'P044',
		label: 'Feto y recién nacido afectados por drogadicción materna',
	},
	{
		id: 7051,
		code: 'P045',
		label: 'Feto y recién nacido afectados por el uso materno de sustancias químicas nutricionales',
	},
	{
		id: 7052,
		code: 'P046',
		label: 'Feto y recién nacido afectados por exposición materna a sustancias químicas ambientales',
	},
	{
		id: 7053,
		code: 'P048',
		label: 'Feto y recién nacido afectados por otras influencias nocivas de la madre',
	},
	{
		id: 7054,
		code: 'P049',
		label: 'Feto y recién nacido afectados por influencias nocivas de la madre, no especificadas',
	},
	{
		id: 7055,
		code: 'P05',
		label: 'Retardo del crecimiento fetal y desnutrición fetal',
	},
	{
		id: 7056,
		code: 'P050',
		label: 'Bajo peso para la edad gestacional',
	},
	{
		id: 7057,
		code: 'P051',
		label: 'Pequeño para la edad gestacional',
	},
	{
		id: 7058,
		code: 'P052',
		label: 'Desnutrición fetal, sin mención de peso o talla bajos para la edad gestacional',
	},
	{
		id: 7059,
		code: 'P059',
		label: 'Retardo del crecimiento fetal, no especificado',
	},
	{
		id: 7060,
		code: 'P07',
		label: 'Trastornos relacionados con duración corta de la gestación y con bajo peso al nacer, no clasificados en otra parte',
	},
	{
		id: 7061,
		code: 'P070',
		label: 'Peso extremadamente bajo al nacer',
	},
	{
		id: 7062,
		code: 'P071',
		label: 'Otro peso bajo al nacer',
	},
	{
		id: 7063,
		code: 'P072',
		label: 'Inmaturidad extrema',
	},
	{
		id: 7064,
		code: 'P073',
		label: 'Otros recién nacidos pretérmino',
	},
	{
		id: 7065,
		code: 'P08',
		label: 'Trastornos relacionados con el embarazo prolongado y con sobrepeso al nacer',
	},
	{
		id: 7066,
		code: 'P080',
		label: 'Recién nacido excepcionalmente grande',
	},
	{
		id: 7067,
		code: 'P081',
		label: 'Otros recién nacidos con sobrepeso para la edad gestacional',
	},
	{
		id: 7068,
		code: 'P082',
		label: 'Recién nacido postérmino sin sobrepeso para su edad gestacional',
	},
	{
		id: 7069,
		code: 'P10',
		label: 'Hemorragia y laceración intracraneal debidas a traumatismo del nacimiento',
	},
	{
		id: 7070,
		code: 'P100',
		label: 'Hemorragia subdural debida a traumatismo del nacimiento',
	},
	{
		id: 7071,
		code: 'P101',
		label: 'Hemorragia cerebral debida a traumatismo del nacimiento',
	},
	{
		id: 7072,
		code: 'P102',
		label: 'Hemorragia intraventricular debida a traumatismo del nacimiento',
	},
	{
		id: 7073,
		code: 'P103',
		label: 'Hemorragia subaracnoidea debida a traumatismo del nacimiento',
	},
	{
		id: 7074,
		code: 'P104',
		label: 'Desgarro  tentorial debido a traumatismo del nacimiento',
	},
	{
		id: 7075,
		code: 'P108',
		label: 'Otras Hemorragias y laceraciones intracraneales debidas a traumatismo del nacimiento',
	},
	{
		id: 7076,
		code: 'P109',
		label: 'Hemorragia y laceración intracraneales no especificadas, debidas a traumatismo del nacimiento',
	},
	{
		id: 7077,
		code: 'P11',
		label: 'Otros traumatismos del nacimiento en el sistema nervioso central',
	},
	{
		id: 7078,
		code: 'P110',
		label: 'Edema cerebral debido a traumatismo del nacimiento',
	},
	{
		id: 7079,
		code: 'P111',
		label: 'Otras lesiones especificadas del encéfalo, debidas a traumatismo del nacimiento',
	},
	{
		id: 7080,
		code: 'P112',
		label: 'Lesión no especificada del encéfalo, debida a traumatismo del nacimiento',
	},
	{
		id: 7081,
		code: 'P113',
		label: 'Traumatismo del nacimiento en el nervio facial',
	},
	{
		id: 7082,
		code: 'P114',
		label: 'Traumatismo del nacimiento en otros nervios craneales',
	},
	{
		id: 7083,
		code: 'P115',
		label: 'Traumatismo del nacimiento en la columna vertebral y en la médula espinal',
	},
	{
		id: 7084,
		code: 'P119',
		label: 'Traumatismo del nacimiento en el sistema nervioso central, no especificado',
	},
	{
		id: 7085,
		code: 'P12',
		label: 'Traumatismo del nacimiento en el cuero cabelludo',
	},
	{
		id: 7086,
		code: 'P120',
		label: 'Cefalohematoma debido a traumatismo del nacimiento',
	},
	{
		id: 7087,
		code: 'P121',
		label: 'Caput succedaneum debido a traumatismo del nacimiento',
	},
	{
		id: 7088,
		code: 'P122',
		label: 'Hemorragia epicraneal subaponeurótica debida a traumatismo del nacimiento',
	},
	{
		id: 7089,
		code: 'P123',
		label: 'Equimosis del cuero cabelludo debida a traumatismo del nacimiento',
	},
	{
		id: 7090,
		code: 'P124',
		label: 'Traumatismo en el cuero cabelludo del recién nacido por monitoreo fetal',
	},
	{
		id: 7091,
		code: 'P128',
		label: 'Otros traumatismos del nacimiento en el cuero cabelludo',
	},
	{
		id: 7092,
		code: 'P129',
		label: 'Traumatismo del nacimiento en el cuero cabelludo, no especificado',
	},
	{
		id: 7093,
		code: 'P13',
		label: 'Traumatismo del esqueleto durante el nacimiento',
	},
	{
		id: 7094,
		code: 'P130',
		label: 'Fractura del cráneo debida a traumatismo del nacimiento',
	},
	{
		id: 7095,
		code: 'P131',
		label: 'Otros traumatismos del cráneo durante el nacimiento',
	},
	{
		id: 7096,
		code: 'P132',
		label: 'Traumatismo del fémur durante el nacimiento',
	},
	{
		id: 7097,
		code: 'P133',
		label: 'Traumatismo de otros huesos largos durante el nacimiento',
	},
	{
		id: 7098,
		code: 'P134',
		label: 'Fractura de la clavícula debida a traumatismo del nacimiento',
	},
	{
		id: 7099,
		code: 'P138',
		label: 'Traumatismo del nacimiento en otras partes del esqueleto',
	},
	{
		id: 7100,
		code: 'P139',
		label: 'Traumatismo no especificado del esqueleto durante el nacimiento',
	},
	{
		id: 7101,
		code: 'P14',
		label: 'Traumatismo del sistema nervioso periférico durante el nacimiento',
	},
	{
		id: 7102,
		code: 'P140',
		label: 'Parálisis de Erb debida a traumatismo del nacimiento',
	},
	{
		id: 7103,
		code: 'P141',
		label: 'Parálisis de Klumpke debida a traumatismo del nacimiento',
	},
	{
		id: 7104,
		code: 'P142',
		label: 'Parálisis del nervio frénico debida a traumatismo del nacimiento',
	},
	{
		id: 7105,
		code: 'P143',
		label: 'Otro traumatismo del plexo braquial durante el nacimiento',
	},
	{
		id: 7106,
		code: 'P148',
		label: 'Traumatismo durante el nacimiento en otras partes del sistema nervioso periférico',
	},
	{
		id: 7107,
		code: 'P149',
		label: 'Traumatismo no especificado del sistema nervioso periférico durante el nacimiento',
	},
	{
		id: 7108,
		code: 'P15',
		label: 'Otros traumatismos del nacimiento',
	},
	{
		id: 7109,
		code: 'P150',
		label: 'Lesión del hígado durante el nacimiento',
	},
	{
		id: 7110,
		code: 'P151',
		label: 'Lesión del bazo durante el nacimiento',
	},
	{
		id: 7111,
		code: 'P152',
		label: 'Traumatismo del músculo esternocleidomastoideo durante el nacimiento',
	},
	{
		id: 7112,
		code: 'P153',
		label: 'Traumatismo ocular durante el nacimiento',
	},
	{
		id: 7113,
		code: 'P154',
		label: 'Traumatismo facial durante el nacimiento',
	},
	{
		id: 7114,
		code: 'P155',
		label: 'Traumatismo de los genitales externos durante el nacimiento',
	},
	{
		id: 7115,
		code: 'P156',
		label: 'Necrosis grasa subcutánea debida a traumatismo del nacimiento',
	},
	{
		id: 7116,
		code: 'P158',
		label: 'Otros traumatismos especificados, durante el nacimiento',
	},
	{
		id: 7117,
		code: 'P159',
		label: 'Traumatismo no especificado, durante el nacimiento',
	},
	{
		id: 7118,
		code: 'P20',
		label: 'Hipoxia intrauterina',
	},
	{
		id: 7119,
		code: 'P200',
		label: 'Hipoxia intrauterina notada por primera vez antes del inicio del trabajo de parto',
	},
	{
		id: 7120,
		code: 'P201',
		label: 'Hipoxia intrauterina notada por primera vez durante el trabajo de parto y el parto',
	},
	{
		id: 7121,
		code: 'P209',
		label: 'Hipoxia intrauterina, no especificada',
	},
	{
		id: 7122,
		code: 'P21',
		label: 'Asfixia del nacimiento',
	},
	{
		id: 7123,
		code: 'P210',
		label: 'Asfixia del nacimiento, severa',
	},
	{
		id: 7124,
		code: 'P211',
		label: 'Asfixia del nacimiento, leve y moderada',
	},
	{
		id: 7125,
		code: 'P219',
		label: 'Asfixia del nacimiento, no especificada',
	},
	{
		id: 7126,
		code: 'P22',
		label: 'Dificultad respiratoria del recién nacido',
	},
	{
		id: 7127,
		code: 'P220',
		label: 'Síndrome de dificultad respiratoria del recién nacido',
	},
	{
		id: 7128,
		code: 'P221',
		label: 'Taquipnea transitoria del recién nacido',
	},
	{
		id: 7129,
		code: 'P228',
		label: 'Otras dificultades respiratorias del recién nacido',
	},
	{
		id: 7130,
		code: 'P229',
		label: 'Dificultad respiratoria del recién nacido, no especificada',
	},
	{
		id: 7131,
		code: 'P23',
		label: 'Neumonía congénita',
	},
	{
		id: 7132,
		code: 'P230',
		label: 'Neumonía congénita debida a agente viral',
	},
	{
		id: 7133,
		code: 'P231',
		label: 'Neumonía congénita debida a chlamydia',
	},
	{
		id: 7134,
		code: 'P232',
		label: 'Neumonía congénita debida a estafilococos',
	},
	{
		id: 7135,
		code: 'P233',
		label: 'Neumonía congénita debida a estreptococos del grupo B',
	},
	{
		id: 7136,
		code: 'P234',
		label: 'Neumonía congénita debida a Escherichia coli',
	},
	{
		id: 7137,
		code: 'P235',
		label: 'Neumonía congénita debida a Pseudomonas',
	},
	{
		id: 7138,
		code: 'P236',
		label: 'Neumonía congénita debida a otros agentes bacterianos',
	},
	{
		id: 7139,
		code: 'P238',
		label: 'Neumonía congénita debida a otros organismos',
	},
	{
		id: 7140,
		code: 'P239',
		label: 'Neumonía congénita, organismo no especificado',
	},
	{
		id: 7141,
		code: 'P24',
		label: 'Síndromes de aspiración neonatal',
	},
	{
		id: 7142,
		code: 'P240',
		label: 'Aspiración neonatal de meconio',
	},
	{
		id: 7143,
		code: 'P241',
		label: 'Aspiración neonatal de líquido amniótico y de moco',
	},
	{
		id: 7144,
		code: 'P242',
		label: 'Aspiración neonatal de sangre',
	},
	{
		id: 7145,
		code: 'P243',
		label: 'Aspiración neonatal de leche y alimento regurgitado',
	},
	{
		id: 7146,
		code: 'P248',
		label: 'Otros síndromes de aspiración neonatal',
	},
	{
		id: 7147,
		code: 'P249',
		label: 'Síndrome de aspiración neonatal, sin otra especificación',
	},
	{
		id: 7148,
		code: 'P25',
		label: 'Enfisema intersticial y afecciones relacionadas, originadas en el período perinatal',
	},
	{
		id: 7149,
		code: 'P250',
		label: 'Enfisema intersticial originado en el período perinatal',
	},
	{
		id: 7150,
		code: 'P251',
		label: 'Neumotórax originado en el período perinatal',
	},
	{
		id: 7151,
		code: 'P252',
		label: 'Neumomediastino originado en el período perinatal',
	},
	{
		id: 7152,
		code: 'P253',
		label: 'Neumopericardio originado en el período perinatal',
	},
	{
		id: 7153,
		code: 'P258',
		label: 'Otras afecciones relacionadas con el enfisema intersticial, originadas en el período perinatal',
	},
	{
		id: 7154,
		code: 'P26',
		label: 'Hemorragia pulmonar originada en el período perinatal',
	},
	{
		id: 7155,
		code: 'P260',
		label: 'Hemorragia traqueobronquial originada en el período perinatal',
	},
	{
		id: 7156,
		code: 'P261',
		label: 'Hemorragia pulmonar masiva originada en el período perinatal',
	},
	{
		id: 7157,
		code: 'P268',
		label: 'Otras Hemorragias pulmonares originadas en el período perinatal',
	},
	{
		id: 7158,
		code: 'P269',
		label: 'Hemorragia pulmonar no especificada, originada en el período perinatal',
	},
	{
		id: 7159,
		code: 'P27',
		label: 'Enfermedad respiratoria crónica originada en el período perinatal',
	},
	{
		id: 7160,
		code: 'P270',
		label: 'Síndrome de Wilson-Mikity',
	},
	{
		id: 7161,
		code: 'P271',
		label: 'Displasia broncopulmonar originada en el período perinatal',
	},
	{
		id: 7162,
		code: 'P278',
		label: 'Otras enfermedades respiratorias crónicas originadas en el período perinatal',
	},
	{
		id: 7163,
		code: 'P279',
		label: 'Enfermedad respiratoria crónica no especificada originada en el período perinatal',
	},
	{
		id: 7164,
		code: 'P28',
		label: 'Otros problemas respiratorios del recién nacido, originados en el período perinatal',
	},
	{
		id: 7165,
		code: 'P280',
		label: 'Atelectasia primaria del recién nacido',
	},
	{
		id: 7166,
		code: 'P281',
		label: 'Otras atelectasias del recién nacido y las no especificadas',
	},
	{
		id: 7167,
		code: 'P282',
		label: 'Ataque ciánotico del recién nacido',
	},
	{
		id: 7168,
		code: 'P283',
		label: 'Apnea primaria del sueño del recién nacido',
	},
	{
		id: 7169,
		code: 'P284',
		label: 'Otras apneas del recién nacido',
	},
	{
		id: 7170,
		code: 'P285',
		label: 'Insuficiencia respiratoria del recién nacido',
	},
	{
		id: 7171,
		code: 'P288',
		label: 'Otros problemas respiratorios especificados del recién nacido',
	},
	{
		id: 7172,
		code: 'P289',
		label: 'Afección respiratoria no especificada del recién nacido',
	},
	{
		id: 7173,
		code: 'P29',
		label: 'Trastornos cardiovasculares originados en el período perinatal',
	},
	{
		id: 7174,
		code: 'P290',
		label: 'Insuficiencia cardíaca neonatal',
	},
	{
		id: 7175,
		code: 'P291',
		label: 'Disritmia cardíaca neonatal',
	},
	{
		id: 7176,
		code: 'P292',
		label: 'Hipertensión neonatal',
	},
	{
		id: 7177,
		code: 'P293',
		label: 'Persistencia de la circulación fetal',
	},
	{
		id: 7178,
		code: 'P294',
		label: 'Isquemia miocárdica transitoria del recién nacido',
	},
	{
		id: 7179,
		code: 'P298',
		label: 'Otros trastornos cardiovasculares originados en el período perinatal',
	},
	{
		id: 7180,
		code: 'P299',
		label: 'Trastorno cardiovascular no especificado, originado en el período perinatal',
	},
	{
		id: 7181,
		code: 'P35',
		label: 'Enfermedades virales congénitas',
	},
	{
		id: 7182,
		code: 'P350',
		label: 'Síndrome de rubéola congénita',
	},
	{
		id: 7183,
		code: 'P351',
		label: 'Infección citomegalovírica congénita',
	},
	{
		id: 7184,
		code: 'P352',
		label: 'Infecciones congénitas por virus del herpes simple',
	},
	{
		id: 7185,
		code: 'P353',
		label: 'Hepatitis viral congénita',
	},
	{
		id: 7186,
		code: 'P358',
		label: 'Otras enfermedades virales congénitas',
	},
	{
		id: 7187,
		code: 'P359',
		label: 'Enfermedad viral congénita, sin otra especificación',
	},
	{
		id: 7188,
		code: 'P36',
		label: 'Sepsis bacteriana del recién nacido',
	},
	{
		id: 7189,
		code: 'P360',
		label: 'Sepsis del recién nacido debida a estreptococo del grupo B',
	},
	{
		id: 7190,
		code: 'P361',
		label: 'Sepsis del recién nacido debida a otros estreptococos  y a los no especificados',
	},
	{
		id: 7191,
		code: 'P362',
		label: 'Sepsis del recién nacido debida a Staphylococcus aureus',
	},
	{
		id: 7192,
		code: 'P363',
		label: 'Sepsis del recién nacido debida a otros estafilococos  y a los no especificados',
	},
	{
		id: 7193,
		code: 'P364',
		label: 'Sepsis del recién nacido debida a Escherichia coli',
	},
	{
		id: 7194,
		code: 'P365',
		label: 'Sepsis del recién nacido debida a anaerobios',
	},
	{
		id: 7195,
		code: 'P368',
		label: 'Sepsis del recién nacido debida a otras bacterias',
	},
	{
		id: 7196,
		code: 'P369',
		label: 'Sepsis bacteriana del recién nacido, no especificada',
	},
	{
		id: 7197,
		code: 'P37',
		label: 'Otras enfermedades infecciosas y parasitarias congénitas',
	},
	{
		id: 7198,
		code: 'P370',
		label: 'Tuberculosis congénita',
	},
	{
		id: 7199,
		code: 'P371',
		label: 'Toxoplasmosis congénita',
	},
	{
		id: 7200,
		code: 'P372',
		label: 'Listeriosis congénita (diseminada)',
	},
	{
		id: 7201,
		code: 'P373',
		label: 'Paludismo congénito por Plasmodium falciparum',
	},
	{
		id: 7202,
		code: 'P374',
		label: 'Otros paludismos congénitos',
	},
	{
		id: 7203,
		code: 'P375',
		label: 'Candidiasis neonatal',
	},
	{
		id: 7204,
		code: 'P378',
		label: 'Otras enfermedades neonatales infecciosas o parasitarias especificadas',
	},
	{
		id: 7205,
		code: 'P379',
		label: 'Enfermedad infecciosa y parasitaria congénita, no especificada',
	},
	{
		id: 7206,
		code: 'P38X',
		label: 'Onfalitis del recién nacido con o sin hemorragia leve',
	},
	{
		id: 7207,
		code: 'P39',
		label: 'Otras infecciones especificas del período perinatal',
	},
	{
		id: 7208,
		code: 'P390',
		label: 'Mastitis infecciosa neonatal',
	},
	{
		id: 7209,
		code: 'P391',
		label: 'Conjuntivitis y dacriocistitis neonatales',
	},
	{
		id: 7210,
		code: 'P392',
		label: 'Infección intraamniótica del feto, no clasificada en otra parte',
	},
	{
		id: 7211,
		code: 'P393',
		label: 'Infección neonatal de las vías urinarias',
	},
	{
		id: 7212,
		code: 'P394',
		label: 'Infección cutánea neonatal',
	},
	{
		id: 7213,
		code: 'P398',
		label: 'Otras infecciones especificadas propias del período perinatal',
	},
	{
		id: 7214,
		code: 'P399',
		label: 'Infección propia del período perinatal, no especificada',
	},
	{
		id: 7215,
		code: 'P50',
		label: 'Pérdida de sangre fetal',
	},
	{
		id: 7216,
		code: 'P500',
		label: 'Pérdida de sangre fetal por vasa previa',
	},
	{
		id: 7217,
		code: 'P501',
		label: 'Pérdida de sangre fetal por ruptura del cordón umbilical',
	},
	{
		id: 7218,
		code: 'P502',
		label: 'Pérdida de sangre fetal por la placenta',
	},
	{
		id: 7219,
		code: 'P503',
		label: 'Hemorragia fetal hacia el otro gemelo',
	},
	{
		id: 7220,
		code: 'P504',
		label: 'Hemorragia fetal hacia la circulación materna',
	},
	{
		id: 7221,
		code: 'P505',
		label: 'Pérdida de sangre fetal por el corte del cordón umbilical en el otro gemelo',
	},
	{
		id: 7222,
		code: 'P508',
		label: 'Otras pérdidas de sangre fetal',
	},
	{
		id: 7223,
		code: 'P509',
		label: 'Pérdida de sangre fetal, no especificada',
	},
	{
		id: 7224,
		code: 'P51',
		label: 'Hemorragia umbilical del recién nacido',
	},
	{
		id: 7225,
		code: 'P510',
		label: 'Hemorragia umbilical masiva del recién nacido',
	},
	{
		id: 7226,
		code: 'P518',
		label: 'Otras hemorragias umbilicales del recién nacido',
	},
	{
		id: 7227,
		code: 'P519',
		label: 'Hemorragia umbilical del recién nacido, sin otra especificación',
	},
	{
		id: 7228,
		code: 'P52',
		label: 'Hemorragia intracraneal no traumática del feto y del recién nacido',
	},
	{
		id: 7229,
		code: 'P520',
		label: 'Hemorragia intraventricular (no traumática) grado 1, del feto y del recién nacido',
	},
	{
		id: 7230,
		code: 'P521',
		label: 'Hemorragia intraventricular (no traumática) grado 2, del feto y del recién nacido',
	},
	{
		id: 7231,
		code: 'P522',
		label: 'Hemorragia intraventricular (no traumática) grado 3, del feto y del recién nacido',
	},
	{
		id: 7232,
		code: 'P523',
		label: 'Hemorragia intraventricular (no traumática) del feto y del recién nacido, sin otra especificación',
	},
	{
		id: 7233,
		code: 'P524',
		label: 'Hemorragia intracerebral (no traumática) del feto y del recién nacido',
	},
	{
		id: 7234,
		code: 'P525',
		label: 'Hemorragia subaracnoidea (no traumática) del feto y del recién nacido',
	},
	{
		id: 7235,
		code: 'P526',
		label: 'Hemorragia cerebelosa y de la fosa posterior (no traumática) del feto y del recién nacido',
	},
	{
		id: 7236,
		code: 'P528',
		label: 'Otras hemorragias intracraneales (no traumáticas) del feto y del recién nacido',
	},
	{
		id: 7237,
		code: 'P529',
		label: 'Hemorragia intracraneal (no traumática) del feto y del recién nacido, sin otra especificación',
	},
	{
		id: 7238,
		code: 'P53X',
		label: 'Enfermedad hemorrágica del feto y del recién nacido',
	},
	{
		id: 7239,
		code: 'P54',
		label: 'Otras hemorragias neonatales',
	},
	{
		id: 7240,
		code: 'P540',
		label: 'Hematemesis neonatal',
	},
	{
		id: 7241,
		code: 'P541',
		label: 'Melena neonatal',
	},
	{
		id: 7242,
		code: 'P542',
		label: 'Hemorragia rectal neonatal',
	},
	{
		id: 7243,
		code: 'P543',
		label: 'Otras hemorragias gastrointestinales neonatales',
	},
	{
		id: 7244,
		code: 'P544',
		label: 'Hemorragia suprarrenal neonatal',
	},
	{
		id: 7245,
		code: 'P545',
		label: 'Hemorragia cutánea neonatal',
	},
	{
		id: 7246,
		code: 'P546',
		label: 'Hemorragia vaginal neonatal',
	},
	{
		id: 7247,
		code: 'P548',
		label: 'Otras hemorragias fetales y neonatales especificadas',
	},
	{
		id: 7248,
		code: 'P549',
		label: 'Hemorragia neonatal, no especificada',
	},
	{
		id: 7249,
		code: 'P55',
		label: 'Enfermedad hemolítica del feto y del recién nacido',
	},
	{
		id: 7250,
		code: 'P550',
		label: 'Incompatibilidad Rh del feto y del recién nacido',
	},
	{
		id: 7251,
		code: 'P551',
		label: 'Incompatibilidad Abo del feto y del recién nacido',
	},
	{
		id: 7252,
		code: 'P558',
		label: 'Otras enfermedades hemolíticas del feto y del recién nacido',
	},
	{
		id: 7253,
		code: 'P559',
		label: 'Enfermedad hemolítica del feto y del recién nacido, no especificada',
	},
	{
		id: 7254,
		code: 'P56',
		label: 'Hidropesía fetal debida a enfermedad hemolítica',
	},
	{
		id: 7255,
		code: 'P560',
		label: 'Hidropesía fetal debida a incompatibilidad',
	},
	{
		id: 7256,
		code: 'P569',
		label: 'Hidropesía fetal debida a otras enfermedades hemolíticas especificadas y a las no especificadas',
	},
	{
		id: 7257,
		code: 'P57',
		label: 'Kernicterus',
	},
	{
		id: 7258,
		code: 'P570',
		label: 'Kernicterus debido a incompatibilidad',
	},
	{
		id: 7259,
		code: 'P578',
		label: 'Kernicterus debido a otras causas especificadas',
	},
	{
		id: 7260,
		code: 'P579',
		label: 'Kernicterus, no especificado',
	},
	{
		id: 7261,
		code: 'P58',
		label: 'Ictericia neonatal debida a otras hemólisis excesivas',
	},
	{
		id: 7262,
		code: 'P580',
		label: 'Ictericia neonatal debida a contusión',
	},
	{
		id: 7263,
		code: 'P581',
		label: 'Ictericia neonatal debida a hemorragia',
	},
	{
		id: 7264,
		code: 'P582',
		label: 'Ictericia neonatal debida a infección',
	},
	{
		id: 7265,
		code: 'P583',
		label: 'Ictericia neonatal debida a policitemia',
	},
	{
		id: 7266,
		code: 'P584',
		label: 'Ictericia neonatal debida a drogas o toxinas transmitidas por la madre o administradas al recién nacido',
	},
	{
		id: 7267,
		code: 'P585',
		label: 'Ictericia neonatal debida a deglución de sangre materna',
	},
	{
		id: 7268,
		code: 'P588',
		label: 'Ictericia neonatal debida a otras hemólisis excesivas especificadas',
	},
	{
		id: 7269,
		code: 'P589',
		label: 'Ictericia neonatal debida a hemólisis excesiva, sin otra especificación',
	},
	{
		id: 7270,
		code: 'P59',
		label: 'Ictericia neonatal por otras causas y por las no especificadas',
	},
	{
		id: 7271,
		code: 'P590',
		label: 'Ictericia neonatal asociada con el parto antes de término',
	},
	{
		id: 7272,
		code: 'P591',
		label: 'Síndrome de la bilis espesa',
	},
	{
		id: 7273,
		code: 'P592',
		label: 'Ictericia neonatal debida a otra lesión hepática especificada o no',
	},
	{
		id: 7274,
		code: 'P593',
		label: 'Ictericia neonatal por inhibidor de la leche materna',
	},
	{
		id: 7275,
		code: 'P598',
		label: 'Ictericia neonatal por otras causas especificadas',
	},
	{
		id: 7276,
		code: 'P599',
		label: 'Ictericia neonatal, no especificada',
	},
	{
		id: 7277,
		code: 'P60X',
		label: 'Coagulación intravascular diseminada en el feto y el recién nacido',
	},
	{
		id: 7278,
		code: 'P61',
		label: 'Otros trastornos hematológicos perinatales',
	},
	{
		id: 7279,
		code: 'P610',
		label: 'Trombocitopenia neonatal transitoria',
	},
	{
		id: 7280,
		code: 'P611',
		label: 'Policitemia neonatal',
	},
	{
		id: 7281,
		code: 'P612',
		label: 'Anemia de la prematuridad',
	},
	{
		id: 7282,
		code: 'P613',
		label: 'Anemia congénita debida a pérdida de sangre fetal',
	},
	{
		id: 7283,
		code: 'P614',
		label: 'Otras anemias congénitas, no clasificadas en otra parte',
	},
	{
		id: 7284,
		code: 'P615',
		label: 'Neutropenia neonatal transitoria',
	},
	{
		id: 7285,
		code: 'P616',
		label: 'Otros trastornos neonatales transitorios de la coagulación',
	},
	{
		id: 7286,
		code: 'P618',
		label: 'Otros trastornos hematológicos perinatales especificados',
	},
	{
		id: 7287,
		code: 'P619',
		label: 'Trastorno hematólogico perinatal, no especificado',
	},
	{
		id: 7288,
		code: 'P70',
		label: 'Trastornos transitorios del metabolismo de los carbohidratos específicos del feto y del recién nacido',
	},
	{
		id: 7289,
		code: 'P700',
		label: 'Síndrome del recién nacido de madre con diabetes gestacional',
	},
	{
		id: 7290,
		code: 'P701',
		label: 'Síndrome del recién nacido de madre diabética',
	},
	{
		id: 7291,
		code: 'P702',
		label: 'Diabetes mellitus neonatal',
	},
	{
		id: 7292,
		code: 'P703',
		label: 'Hipoglicemia neonatal yatrogénica',
	},
	{
		id: 7293,
		code: 'P704',
		label: 'Otras hipoglicemias neonatales',
	},
	{
		id: 7294,
		code: 'P708',
		label: 'Otros trastornos transitorios del metabolismo de los carbohidratos en el feto y el recién nacido',
	},
	{
		id: 7295,
		code: 'P709',
		label: 'Trastorno transitorio no especificado del metabolismo de los carbohidratos en el feto y el recién nacido',
	},
	{
		id: 7296,
		code: 'P71',
		label: 'Trastornos neonatales transitorios del metabolismo del calcio y del magnesio',
	},
	{
		id: 7297,
		code: 'P710',
		label: 'Hipocalcemia del recién nacido debida a la leche de vaca',
	},
	{
		id: 7298,
		code: 'P711',
		label: 'Otra hipocalcemia neonatal',
	},
	{
		id: 7299,
		code: 'P712',
		label: 'Hipomagnesemia neonatal',
	},
	{
		id: 7300,
		code: 'P713',
		label: 'Tetania neonatal sin mención de deficiencia de calcio o de magnesio',
	},
	{
		id: 7301,
		code: 'P714',
		label: 'Hipoparatiroidismo neonatal transitorio',
	},
	{
		id: 7302,
		code: 'P718',
		label: 'Otros trastornos neonatales transitorios del metabolismo del calcio y del magnesio',
	},
	{
		id: 7303,
		code: 'P719',
		label: 'Trastorno neonatal transitorio no especificado del metabolismo del calcio y del magnesio',
	},
	{
		id: 7304,
		code: 'P72',
		label: 'Otros trastornos endocrinos neonatales transitorios',
	},
	{
		id: 7305,
		code: 'P720',
		label: 'Bocio neonatal, no clasificado en otra parte',
	},
	{
		id: 7306,
		code: 'P721',
		label: 'Hipertiroidismo neonatal transitorio',
	},
	{
		id: 7307,
		code: 'P722',
		label: 'Otros trastornos neonatales transitorios de la función tiroidea, no clasificados en otra parte',
	},
	{
		id: 7308,
		code: 'P728',
		label: 'Otros trastornos endocrinos neonatales transitorios especificados',
	},
	{
		id: 7309,
		code: 'P729',
		label: 'Trastorno endocrino neonatal transitorio, no especificado',
	},
	{
		id: 7310,
		code: 'P74',
		label: 'Otras alteraciones metabólicas y electrolíticas neonatales transitorias',
	},
	{
		id: 7311,
		code: 'P740',
		label: 'Acidosis metabólica tardía del recién nacido',
	},
	{
		id: 7312,
		code: 'P741',
		label: 'Deshidratación del recién nacido',
	},
	{
		id: 7313,
		code: 'P742',
		label: 'Alteraciones del equilibrio del sodio en el recién nacido',
	},
	{
		id: 7314,
		code: 'P743',
		label: 'Alteraciones del equilibrio del potasio en el recién nacido',
	},
	{
		id: 7315,
		code: 'P744',
		label: 'Otras alteraciones electroliticas transitorias del recién nacido',
	},
	{
		id: 7316,
		code: 'P745',
		label: 'Tirosinemia transitoria del recién nacido',
	},
	{
		id: 7317,
		code: 'P748',
		label: 'Otras alteraciones metabólicas transitorias del recién nacido',
	},
	{
		id: 7318,
		code: 'P749',
		label: 'Trastorno metabólico transitorio del recién nacido, no especificado',
	},
	{
		id: 7319,
		code: 'P75X',
		label: 'Íleo meconial en la fibrosis quística (E84.1†)',
	},
	{
		id: 7320,
		code: 'P76',
		label: 'Otras obstrucciones intestinales del recién nacido',
	},
	{
		id: 7321,
		code: 'P760',
		label: 'Síndrome del tapón de meconio',
	},
	{
		id: 7322,
		code: 'P761',
		label: 'Íleo transitorio del recién nacido',
	},
	{
		id: 7323,
		code: 'P762',
		label: 'Obstrucción intestinal debida a la leche espesa',
	},
	{
		id: 7324,
		code: 'P768',
		label: 'Otras obstrucciones intestinales especificadas del recién nacido',
	},
	{
		id: 7325,
		code: 'P769',
		label: 'Obstrucción intestinal del recién nacido, no especificada',
	},
	{
		id: 7326,
		code: 'P77X',
		label: 'Enterocolitis necrotizante del feto y del recién nacido',
	},
	{
		id: 7327,
		code: 'P78',
		label: 'Otros trastornos perinatales del sistema digestivo',
	},
	{
		id: 7328,
		code: 'P780',
		label: 'Perforación intestinal perinatal',
	},
	{
		id: 7329,
		code: 'P781',
		label: 'Otras peritonitis neonatales',
	},
	{
		id: 7330,
		code: 'P782',
		label: 'Hematemesis y melena neonatales debidas a la deglución de sangre materna',
	},
	{
		id: 7331,
		code: 'P783',
		label: 'Diarrea neonatal no infecciosa',
	},
	{
		id: 7332,
		code: 'P788',
		label: 'Otros trastornos perinatales específicos del sistema digestivo',
	},
	{
		id: 7333,
		code: 'P789',
		label: 'Trastorno perinatal del sistema digestivo, no especificado',
	},
	{
		id: 7334,
		code: 'P80',
		label: 'Hipotermia del recién nacido',
	},
	{
		id: 7335,
		code: 'P800',
		label: 'Síndrome del enfriamiento',
	},
	{
		id: 7336,
		code: 'P808',
		label: 'Otras hipotermias del recién nacido',
	},
	{
		id: 7337,
		code: 'P809',
		label: 'Hipotermia del recién nacido, no especificada',
	},
	{
		id: 7338,
		code: 'P81',
		label: 'Otras alteraciones de la regulación de la temperatura en el recién nacido',
	},
	{
		id: 7339,
		code: 'P810',
		label: 'Hipertermia del recién nacido inducida por las condiciones ambientales',
	},
	{
		id: 7340,
		code: 'P818',
		label: 'Otras alteraciones especificadas de la regulación de la temperatura del recién nacido',
	},
	{
		id: 7341,
		code: 'P819',
		label: 'Alteración no especificada de la regulación de la temperatura en el recién nacido',
	},
	{
		id: 7342,
		code: 'P83',
		label: 'Otras afecciones de la piel especificas del feto y del recién nacido',
	},
	{
		id: 7343,
		code: 'P830',
		label: 'Esclerema neonatal',
	},
	{
		id: 7344,
		code: 'P831',
		label: 'Eritema tóxico neonatal',
	},
	{
		id: 7345,
		code: 'P832',
		label: 'Hidropesía fetal no debida a enfermedad hemolítica',
	},
	{
		id: 7346,
		code: 'P833',
		label: 'Otros edemas y los no especificados, propios del feto y del recién nacido',
	},
	{
		id: 7347,
		code: 'P834',
		label: 'Ingurgitación mamaria del recién nacido',
	},
	{
		id: 7348,
		code: 'P835',
		label: 'Hidrocele congénito',
	},
	{
		id: 7349,
		code: 'P836',
		label: 'Pólipo umbilical del recién nacido',
	},
	{
		id: 7350,
		code: 'P838',
		label: 'Otras afecciones especificadas de la piel, propias del feto y del recién nacido',
	},
	{
		id: 7351,
		code: 'P839',
		label: 'Afección no especificada de la piel, propia del feto y del recién nacido',
	},
	{
		id: 7352,
		code: 'P90X',
		label: 'Convulsiones del recién nacido',
	},
	{
		id: 7353,
		code: 'P91',
		label: 'Otras alteraciones cerebrales del recién nacido',
	},
	{
		id: 7354,
		code: 'P910',
		label: 'Isquemia cerebral neonatal',
	},
	{
		id: 7355,
		code: 'P911',
		label: 'Quistes periventriculares adquiridos del recién nacido',
	},
	{
		id: 7356,
		code: 'P912',
		label: 'Leucomalacia cerebral neonatal',
	},
	{
		id: 7357,
		code: 'P913',
		label: 'Irritabilidad cerebral neonatal',
	},
	{
		id: 7358,
		code: 'P914',
		label: 'Depresión cerebral neonatal',
	},
	{
		id: 7359,
		code: 'P915',
		label: 'Coma neonatal',
	},
	{
		id: 7360,
		code: 'P916',
		label: 'Encefalopatía hipoxico isquémica del recién nacido',
	},
	{
		id: 7361,
		code: 'P918',
		label: 'Otras alteraciones cerebrales especificadas del recién nacido',
	},
	{
		id: 7362,
		code: 'P919',
		label: 'Alteración cerebral no especificada del recién nacido',
	},
	{
		id: 7363,
		code: 'P92',
		label: 'Problemas de la ingestión de alimentos del recién nacido',
	},
	{
		id: 7364,
		code: 'P920',
		label: 'Vómitos del recién nacido',
	},
	{
		id: 7365,
		code: 'P921',
		label: 'Regurgitación y rumiación del recién nacido',
	},
	{
		id: 7366,
		code: 'P922',
		label: 'Lentitud en la ingestión de alimentos del recién nacido',
	},
	{
		id: 7367,
		code: 'P923',
		label: 'Hipoalimentación del recién nacido',
	},
	{
		id: 7368,
		code: 'P924',
		label: 'Hiperalimentación del recién nacido',
	},
	{
		id: 7369,
		code: 'P925',
		label: 'Dificultad neonatal en la lactancia materna',
	},
	{
		id: 7370,
		code: 'P928',
		label: 'Otros problemas de alimentación del recién nacido',
	},
	{
		id: 7371,
		code: 'P929',
		label: 'Problema no especificado de la alimentación del recién nacido',
	},
	{
		id: 7372,
		code: 'P93X',
		label: 'Reacciones e intoxicaciones debidas a drogas administradas al feto y al recién nacido',
	},
	{
		id: 7373,
		code: 'P94',
		label: 'Trastornos del tono muscular del recién nacido',
	},
	{
		id: 7374,
		code: 'P940',
		label: 'Miastenia grave neonatal transitoria',
	},
	{
		id: 7375,
		code: 'P941',
		label: 'Hipertonía congénita',
	},
	{
		id: 7376,
		code: 'P942',
		label: 'Hipotonía congénita',
	},
	{
		id: 7377,
		code: 'P948',
		label: 'Otros trastornos del tono muscular en el recién nacido',
	},
	{
		id: 7378,
		code: 'P949',
		label: 'Trastorno no especificado del tono muscular en el recién nacido',
	},
	{
		id: 7379,
		code: 'P95X',
		label: 'Muerte fetal de causa no especificada',
	},
	{
		id: 7380,
		code: 'P96',
		label: 'Otras afecciones originadas en el período perinatal',
	},
	{
		id: 7381,
		code: 'P960',
		label: 'Insuficiencia renal congénita',
	},
	{
		id: 7382,
		code: 'P961',
		label: 'Síntomas neonatales de abstinencia por drogadicción materna',
	},
	{
		id: 7383,
		code: 'P962',
		label: 'Síntomas de abstinencia por el uso terapéutico de drogas en el recién nacido',
	},
	{
		id: 7384,
		code: 'P963',
		label: 'Amplitud de las suturas craneales del recién nacido',
	},
	{
		id: 7385,
		code: 'P964',
		label: 'Terminación del embarazo, feto y recién nacido',
	},
	{
		id: 7386,
		code: 'P965',
		label: 'Complicaciones de procedimientos intrauterinos, no clasificados en otra parte',
	},
	{
		id: 7387,
		code: 'P968',
		label: 'Otras afecciones especificadas originadas en el período perinatal',
	},
	{
		id: 7388,
		code: 'P969',
		label: 'Afección no especificada originada en el período perinatal',
	},
	{
		id: 7389,
		code: 'Q00',
		label: 'Anencefalia y malformaciones congénitas similares',
	},
	{
		id: 7390,
		code: 'Q000',
		label: 'Anencefalia',
	},
	{
		id: 7391,
		code: 'Q001',
		label: 'Craneorraquisquisis',
	},
	{
		id: 7392,
		code: 'Q002',
		label: 'Iniencefalia',
	},
	{
		id: 7393,
		code: 'Q01',
		label: 'Encefalocele',
	},
	{
		id: 7394,
		code: 'Q010',
		label: 'Encefalocele frontal',
	},
	{
		id: 7395,
		code: 'Q011',
		label: 'Encefalocele nasofrontal',
	},
	{
		id: 7396,
		code: 'Q012',
		label: 'Encefalocele occipital',
	},
	{
		id: 7397,
		code: 'Q018',
		label: 'Encefalocele de otros sitios',
	},
	{
		id: 7398,
		code: 'Q019',
		label: 'Encefalocele, no especificado',
	},
	{
		id: 7399,
		code: 'Q02X',
		label: 'Microcefalia',
	},
	{
		id: 7400,
		code: 'Q03',
		label: 'Hidrocéfalo congénito',
	},
	{
		id: 7401,
		code: 'Q030',
		label: 'Malformaciones del acueducto de Silvio',
	},
	{
		id: 7402,
		code: 'Q031',
		label: 'Atresia de los agujeros de Magendie y de Luschka',
	},
	{
		id: 7403,
		code: 'Q038',
		label: 'Otros hidrocéfalos congénitos',
	},
	{
		id: 7404,
		code: 'Q039',
		label: 'Hidrocéfalo congénito, no especificado',
	},
	{
		id: 7405,
		code: 'Q04',
		label: 'Otras malformaciones congénitas del encéfalo',
	},
	{
		id: 7406,
		code: 'Q040',
		label: 'Malformaciones congénitas del cuerpo calloso',
	},
	{
		id: 7407,
		code: 'Q041',
		label: 'Arrinencefalia',
	},
	{
		id: 7408,
		code: 'Q042',
		label: 'Holoprosencefalia',
	},
	{
		id: 7409,
		code: 'Q043',
		label: 'Otras anomalías hipoplásicas del encéfalo',
	},
	{
		id: 7410,
		code: 'Q044',
		label: 'Displasia opticoseptal',
	},
	{
		id: 7411,
		code: 'Q045',
		label: 'Megalencefalia',
	},
	{
		id: 7412,
		code: 'Q046',
		label: 'Quistes cerebrales congénitos',
	},
	{
		id: 7413,
		code: 'Q048',
		label: 'Otras malformaciones congénitas del encéfalo, especificadas',
	},
	{
		id: 7414,
		code: 'Q049',
		label: 'Malformación congénita del encéfalo, no especificada',
	},
	{
		id: 7415,
		code: 'Q05',
		label: 'Espina bífida',
	},
	{
		id: 7416,
		code: 'Q050',
		label: 'Espina bífida cervical con hidrocéfalo',
	},
	{
		id: 7417,
		code: 'Q051',
		label: 'Espina bífida torácica con hidrocéfalo',
	},
	{
		id: 7418,
		code: 'Q052',
		label: 'Espina bífida lumbar con hidrocéfalo',
	},
	{
		id: 7419,
		code: 'Q053',
		label: 'Espina bífida sacra con hidrocéfalo',
	},
	{
		id: 7420,
		code: 'Q054',
		label: 'Espina bífida con hidrocéfalo, sin otra especificación',
	},
	{
		id: 7421,
		code: 'Q055',
		label: 'Espina bífida cervical sin hidrocéfalo',
	},
	{
		id: 7422,
		code: 'Q056',
		label: 'Espina bífida torácica sin hidrocéfalo',
	},
	{
		id: 7423,
		code: 'Q057',
		label: 'Espina bífida lumbar sin hidrocéfalo',
	},
	{
		id: 7424,
		code: 'Q058',
		label: 'Espina bífida sacra sin hidrocéfalo',
	},
	{
		id: 7425,
		code: 'Q059',
		label: 'Espina bífida, no especificada',
	},
	{
		id: 7426,
		code: 'Q06',
		label: 'Otras malformaciones congénitas de la médula espinal',
	},
	{
		id: 7427,
		code: 'Q060',
		label: 'Amielia',
	},
	{
		id: 7428,
		code: 'Q061',
		label: 'Hipoplasia y displasia de la médula espinal',
	},
	{
		id: 7429,
		code: 'Q062',
		label: 'Diastematomielia',
	},
	{
		id: 7430,
		code: 'Q063',
		label: 'Otras anomalías congénitas de la cola de caballo',
	},
	{
		id: 7431,
		code: 'Q064',
		label: 'Hidromielia',
	},
	{
		id: 7432,
		code: 'Q068',
		label: 'Otras malformaciones congénitas especificadas de la médula espinal',
	},
	{
		id: 7433,
		code: 'Q069',
		label: 'Malformación congénita de la médula espinal, no especificada',
	},
	{
		id: 7434,
		code: 'Q07',
		label: 'Otras malformaciones congénitas del sistema nervioso',
	},
	{
		id: 7435,
		code: 'Q070',
		label: 'Síndrome de Arnold-Chiari',
	},
	{
		id: 7436,
		code: 'Q078',
		label: 'Otras malformaciones congénitas del sistema nervioso, especificadas',
	},
	{
		id: 7437,
		code: 'Q079',
		label: 'Otras malformaciones congénitas del sistema nervioso, no especificada',
	},
	{
		id: 7438,
		code: 'Q10',
		label: 'Malformaciones congénitas de los párpados, del aparato lagrimal y de la órbita',
	},
	{
		id: 7439,
		code: 'Q100',
		label: 'Blefaroptosis congénita',
	},
	{
		id: 7440,
		code: 'Q101',
		label: 'Ectropión congénito',
	},
	{
		id: 7441,
		code: 'Q102',
		label: 'Entropión congénito',
	},
	{
		id: 7442,
		code: 'Q103',
		label: 'Otras malformaciones congénitas de los párpados',
	},
	{
		id: 7443,
		code: 'Q104',
		label: 'Ausencia y agenesia del aparato lagrimal',
	},
	{
		id: 7444,
		code: 'Q105',
		label: 'Estenosis y estrechez congénitas del conducto lagrimal',
	},
	{
		id: 7445,
		code: 'Q106',
		label: 'Otras malformaciones congénitas del aparato lagrimal',
	},
	{
		id: 7446,
		code: 'Q107',
		label: 'Malformación congénita de la órbita',
	},
	{
		id: 7447,
		code: 'Q11',
		label: 'Anoftalmía, microftalmía y macroftalmía',
	},
	{
		id: 7448,
		code: 'Q110',
		label: 'Globo ocular quístico',
	},
	{
		id: 7449,
		code: 'Q111',
		label: 'Otras anoftalmías',
	},
	{
		id: 7450,
		code: 'Q112',
		label: 'Microftalmía',
	},
	{
		id: 7451,
		code: 'Q113',
		label: 'Macroftalmía',
	},
	{
		id: 7452,
		code: 'Q12',
		label: 'Malformaciones congénitas del cristalino',
	},
	{
		id: 7453,
		code: 'Q120',
		label: 'Catarata congénita',
	},
	{
		id: 7454,
		code: 'Q121',
		label: 'Desplazamiento congénito del cristalino',
	},
	{
		id: 7455,
		code: 'Q122',
		label: 'Coloboma del cristalino',
	},
	{
		id: 7456,
		code: 'Q123',
		label: 'Afaquia congénita',
	},
	{
		id: 7457,
		code: 'Q124',
		label: 'Esferofaquia',
	},
	{
		id: 7458,
		code: 'Q128',
		label: 'Otras malformaciones congénitas del cristalino',
	},
	{
		id: 7459,
		code: 'Q129',
		label: 'Malformación congénita del cristalino, no especificada',
	},
	{
		id: 7460,
		code: 'Q13',
		label: 'Malformaciones congénitas del segmento anterior del ojo',
	},
	{
		id: 7461,
		code: 'Q130',
		label: 'Coloboma del iris',
	},
	{
		id: 7462,
		code: 'Q131',
		label: 'Ausencia del iris',
	},
	{
		id: 7463,
		code: 'Q132',
		label: 'Otras malformaciones congénitas del iris',
	},
	{
		id: 7464,
		code: 'Q133',
		label: 'Opacidad corneal congénita',
	},
	{
		id: 7465,
		code: 'Q134',
		label: 'Otras malformaciones congénitas de la córnea',
	},
	{
		id: 7466,
		code: 'Q135',
		label: 'Esclerótica azul',
	},
	{
		id: 7467,
		code: 'Q138',
		label: 'Otras malformaciones congénitas del segmento anterior del ojo',
	},
	{
		id: 7468,
		code: 'Q139',
		label: 'Malformación congénita del segmento anterior del ojo, no especificada',
	},
	{
		id: 7469,
		code: 'Q14',
		label: 'Malformaciones congénitas del segmento posterior del ojo',
	},
	{
		id: 7470,
		code: 'Q140',
		label: 'Malformación congénita del humor vítreo',
	},
	{
		id: 7471,
		code: 'Q141',
		label: 'Malformación congénita de la retina',
	},
	{
		id: 7472,
		code: 'Q142',
		label: 'Malformación congénita del disco óptico',
	},
	{
		id: 7473,
		code: 'Q143',
		label: 'Malformación congénita de la coroides',
	},
	{
		id: 7474,
		code: 'Q148',
		label: 'Otras Malformaciones congénitas del segmento posterior del ojo',
	},
	{
		id: 7475,
		code: 'Q149',
		label: 'Malformación congénita del segmento posterior del ojo, no especificada',
	},
	{
		id: 7476,
		code: 'Q15',
		label: 'Otras malformaciones congénitas del ojo',
	},
	{
		id: 7477,
		code: 'Q150',
		label: 'Glaucoma congénito',
	},
	{
		id: 7478,
		code: 'Q158',
		label: 'Otras malformaciones congénitas del ojo, especificadas',
	},
	{
		id: 7479,
		code: 'Q159',
		label: 'Malformaciones congénitas del ojo, no especificadas',
	},
	{
		id: 7480,
		code: 'Q16',
		label: 'Malformaciones congénitas del oído que causan la alteración de la audición',
	},
	{
		id: 7481,
		code: 'Q160',
		label: 'Ausencia congénita del pabellón (de la oreja)',
	},
	{
		id: 7482,
		code: 'Q161',
		label: 'Ausencia congénita, atresia o estrechez del conducto auditivo (externo)',
	},
	{
		id: 7483,
		code: 'Q162',
		label: 'Ausencia de la trompa de Eustaquio',
	},
	{
		id: 7484,
		code: 'Q163',
		label: 'Malformación congénita de los huesecillos del oído',
	},
	{
		id: 7485,
		code: 'Q164',
		label: 'Otras malformaciones congénitas del oído medio',
	},
	{
		id: 7486,
		code: 'Q165',
		label: 'Malformación congénita del oído interno',
	},
	{
		id: 7487,
		code: 'Q169',
		label: 'Malformación congénita del oído que causa alteración de la audición, sin otra especificación',
	},
	{
		id: 7488,
		code: 'Q17',
		label: 'Otras malformaciones congénitas del oído',
	},
	{
		id: 7489,
		code: 'Q170',
		label: 'Oreja supernumeraria',
	},
	{
		id: 7490,
		code: 'Q171',
		label: 'Macrotia',
	},
	{
		id: 7491,
		code: 'Q172',
		label: 'Microtia',
	},
	{
		id: 7492,
		code: 'Q173',
		label: 'Otras deformidades del pabellón auricular',
	},
	{
		id: 7493,
		code: 'Q174',
		label: 'Anomalía de la posición de la oreja',
	},
	{
		id: 7494,
		code: 'Q175',
		label: 'Oreja prominente',
	},
	{
		id: 7495,
		code: 'Q178',
		label: 'Otras malformaciones congénitas del oído, especificadas',
	},
	{
		id: 7496,
		code: 'Q179',
		label: 'Malformación congénita del oído, no especificada',
	},
	{
		id: 7497,
		code: 'Q18',
		label: 'Otras malformaciones congénitas del la cara y del cuello',
	},
	{
		id: 7498,
		code: 'Q180',
		label: 'Seno, fístula o quiste de la hendidura branquial',
	},
	{
		id: 7499,
		code: 'Q181',
		label: 'Seno y quiste preauricular',
	},
	{
		id: 7500,
		code: 'Q182',
		label: 'Otras malformaciones de las hendiduras branquiales',
	},
	{
		id: 7501,
		code: 'Q183',
		label: 'Pterigión del cuello',
	},
	{
		id: 7502,
		code: 'Q184',
		label: 'Macrostomía',
	},
	{
		id: 7503,
		code: 'Q185',
		label: 'Microstomía',
	},
	{
		id: 7504,
		code: 'Q186',
		label: 'Macroqueilia',
	},
	{
		id: 7505,
		code: 'Q187',
		label: 'Microqueilia',
	},
	{
		id: 7506,
		code: 'Q188',
		label: 'Otras malformaciones congénitas especificadas de cara y cuello',
	},
	{
		id: 7507,
		code: 'Q189',
		label: 'Malformación congénita de la cara y del cuello, no especificada',
	},
	{
		id: 7508,
		code: 'Q20',
		label: 'Malformaciones congénitas de las cámaras cardíacas y sus conexiones',
	},
	{
		id: 7509,
		code: 'Q200',
		label: 'Tronco arterioso común',
	},
	{
		id: 7510,
		code: 'Q201',
		label: 'Transposición de los grandes vasos en ventrículo derecho',
	},
	{
		id: 7511,
		code: 'Q202',
		label: 'Transposición de los grandes vasos en ventrículo izquierdo',
	},
	{
		id: 7512,
		code: 'Q203',
		label: 'Discordancia de la conexión ventriculoarterial',
	},
	{
		id: 7513,
		code: 'Q204',
		label: 'Ventrículo con doble entrada',
	},
	{
		id: 7514,
		code: 'Q205',
		label: 'Discordancia de la conexión auriculoventricular',
	},
	{
		id: 7515,
		code: 'Q206',
		label: 'Isomerismo de los apéndices auriculares',
	},
	{
		id: 7516,
		code: 'Q208',
		label: 'Otras malformaciones congénitas de las cámaras cardíacas y sus conexiones',
	},
	{
		id: 7517,
		code: 'Q209',
		label: 'Malformación congénita de las cámaras cardíacas y sus conexiones, no especificada',
	},
	{
		id: 7518,
		code: 'Q21',
		label: 'Malformaciones congénitas de los tabiques cardíacos',
	},
	{
		id: 7519,
		code: 'Q210',
		label: 'Defecto del tabique ventricular',
	},
	{
		id: 7520,
		code: 'Q211',
		label: 'Defecto del tabique auricular',
	},
	{
		id: 7521,
		code: 'Q212',
		label: 'Defecto del tabique auriculoventricular',
	},
	{
		id: 7522,
		code: 'Q213',
		label: 'Tetralogía de Fallot',
	},
	{
		id: 7523,
		code: 'Q214',
		label: 'Defecto del tabique aortopulmonar',
	},
	{
		id: 7524,
		code: 'Q218',
		label: 'Otras malformaciones congénitas de los tabiques cardíacos',
	},
	{
		id: 7525,
		code: 'Q219',
		label: 'Malformación congénita del tabique cardíaco, no especificada',
	},
	{
		id: 7526,
		code: 'Q22',
		label: 'Malformaciones congénitas de la válvulas pulmonar y tricúspide',
	},
	{
		id: 7527,
		code: 'Q220',
		label: 'Atresia de la válvula pulmonar',
	},
	{
		id: 7528,
		code: 'Q221',
		label: 'Estenosis congénita de la válvula pulmonar',
	},
	{
		id: 7529,
		code: 'Q222',
		label: 'Insuficiencia congénita de la válvula pulmonar',
	},
	{
		id: 7530,
		code: 'Q223',
		label: 'Otras malformaciones congénitas de la válvula pulmonar',
	},
	{
		id: 7531,
		code: 'Q224',
		label: 'Estenosis congénita de la válvula tricúspide',
	},
	{
		id: 7532,
		code: 'Q225',
		label: 'Anomalía de Ebstein',
	},
	{
		id: 7533,
		code: 'Q226',
		label: 'Síndrome de hipoplasia del corazón derecho',
	},
	{
		id: 7534,
		code: 'Q228',
		label: 'Otras malformaciones congénitas de la válvula tricúspide',
	},
	{
		id: 7535,
		code: 'Q229',
		label: 'Malformación congénita de la válvula tricúspide, no especificada',
	},
	{
		id: 7536,
		code: 'Q23',
		label: 'Malformaciones congénitas de las válvulas aórtica y mitral',
	},
	{
		id: 7537,
		code: 'Q230',
		label: 'Estenosis congénita de la válvula aórtica',
	},
	{
		id: 7538,
		code: 'Q231',
		label: 'Insuficiencia congénita de la válvula aórtica',
	},
	{
		id: 7539,
		code: 'Q232',
		label: 'Estenosis mitral congénita',
	},
	{
		id: 7540,
		code: 'Q233',
		label: 'Insuficiencia mitral congénita',
	},
	{
		id: 7541,
		code: 'Q234',
		label: 'Síndrome de hipoplasia del corazón izquierdo',
	},
	{
		id: 7542,
		code: 'Q238',
		label: 'Otras malformaciones congénitas de las válvulas aórtica y mitral',
	},
	{
		id: 7543,
		code: 'Q239',
		label: 'Malformación congénita de las válvulas aórtica y mitral, no especificada',
	},
	{
		id: 7544,
		code: 'Q24',
		label: 'Otras malformaciones congénitas del corazón',
	},
	{
		id: 7545,
		code: 'Q240',
		label: 'Dextrocardia',
	},
	{
		id: 7546,
		code: 'Q241',
		label: 'Levocardia',
	},
	{
		id: 7547,
		code: 'Q242',
		label: 'Corazón triauricular',
	},
	{
		id: 7548,
		code: 'Q243',
		label: 'Estenosis del infundíbulo pulmonar',
	},
	{
		id: 7549,
		code: 'Q244',
		label: 'Estenosis subaórtica congénita',
	},
	{
		id: 7550,
		code: 'Q245',
		label: 'Malformación de los vasos coronarios',
	},
	{
		id: 7551,
		code: 'Q246',
		label: 'Bloqueo cardíaco congénito',
	},
	{
		id: 7552,
		code: 'Q248',
		label: 'Otras malformaciones congénitas del corazón, especificadas',
	},
	{
		id: 7553,
		code: 'Q249',
		label: 'Malformación congénita del corazón, no especificada',
	},
	{
		id: 7554,
		code: 'Q25',
		label: 'Malformaciones congénitas de las grandes arterias',
	},
	{
		id: 7555,
		code: 'Q250',
		label: 'Conducto arterioso persistente',
	},
	{
		id: 7556,
		code: 'Q251',
		label: 'Coartación de la aorta',
	},
	{
		id: 7557,
		code: 'Q252',
		label: 'Atresia de la aorta',
	},
	{
		id: 7558,
		code: 'Q253',
		label: 'Estenosis de la aorta',
	},
	{
		id: 7559,
		code: 'Q254',
		label: 'Otras malformaciones congénitas de la aorta',
	},
	{
		id: 7560,
		code: 'Q255',
		label: 'Atresia de la arteria pulmonar',
	},
	{
		id: 7561,
		code: 'Q256',
		label: 'Estenosis de la arteria pulmonar',
	},
	{
		id: 7562,
		code: 'Q257',
		label: 'Otras malformaciones congénitas de la arteria pulmonar',
	},
	{
		id: 7563,
		code: 'Q258',
		label: 'Otras malformaciones congénitas de las grandes arterias',
	},
	{
		id: 7564,
		code: 'Q259',
		label: 'Malformación congénita de las grandes arterias, no especificada',
	},
	{
		id: 7565,
		code: 'Q26',
		label: 'Malformaciones congénitas de las grandes venas',
	},
	{
		id: 7566,
		code: 'Q260',
		label: 'Estenosis congénita de la vena cava',
	},
	{
		id: 7567,
		code: 'Q261',
		label: 'Persistencia de la vena cava superior izquierda',
	},
	{
		id: 7568,
		code: 'Q262',
		label: 'Conexión anómala total de las venas pulmonares',
	},
	{
		id: 7569,
		code: 'Q263',
		label: 'Conexión anómala parcial de las venas pulmonares',
	},
	{
		id: 7570,
		code: 'Q264',
		label: 'Conexión anómala de las venas pulmonares, sin otra especificación',
	},
	{
		id: 7571,
		code: 'Q265',
		label: 'Conexión anómala de la vena porta',
	},
	{
		id: 7572,
		code: 'Q266',
		label: 'Fístula arteria hepática-vena porta',
	},
	{
		id: 7573,
		code: 'Q268',
		label: 'Otras malformaciones congénitas de las grandes venas',
	},
	{
		id: 7574,
		code: 'Q269',
		label: 'Malformación congénita de las grandes venas, no especificada',
	},
	{
		id: 7575,
		code: 'Q27',
		label: 'Otras malformaciones congénitas del sistema vascular periférico',
	},
	{
		id: 7576,
		code: 'Q270',
		label: 'Ausencia e hipoplasia congénita de la arteria umbilical',
	},
	{
		id: 7577,
		code: 'Q271',
		label: 'Estenosis congénita de la arteria renal',
	},
	{
		id: 7578,
		code: 'Q272',
		label: 'Otras malformaciones congénitas de la arteria renal',
	},
	{
		id: 7579,
		code: 'Q273',
		label: 'Malformación arteriovenosa periférica',
	},
	{
		id: 7580,
		code: 'Q274',
		label: 'Flebectasia congénita',
	},
	{
		id: 7581,
		code: 'Q278',
		label: 'Otras malformaciones congénitas del sistema vascular periférico, especificadas',
	},
	{
		id: 7582,
		code: 'Q279',
		label: 'Malformación congénita del sistema vascular periférico, no especificada',
	},
	{
		id: 7583,
		code: 'Q28',
		label: 'Otras malformaciones congénitas del sistema circulatorio',
	},
	{
		id: 7584,
		code: 'Q280',
		label: 'Malformación arteriovenosa de los vasos precerebrales',
	},
	{
		id: 7585,
		code: 'Q281',
		label: 'Otras malformaciones de los vasos precerebrales',
	},
	{
		id: 7586,
		code: 'Q282',
		label: 'Malformación arteriovenosa de los vasos cerebrales',
	},
	{
		id: 7587,
		code: 'Q283',
		label: 'Otras malformaciones de los vasos cerebrales',
	},
	{
		id: 7588,
		code: 'Q288',
		label: 'Otras malformaciones congénitas del sistema circulatorio, especificadas',
	},
	{
		id: 7589,
		code: 'Q289',
		label: 'Malformación congénita del sistema circulatorio, no especificada',
	},
	{
		id: 7590,
		code: 'Q30',
		label: 'Malformaciones congénitas de la nariz',
	},
	{
		id: 7591,
		code: 'Q300',
		label: 'Atresia de las coanas',
	},
	{
		id: 7592,
		code: 'Q301',
		label: 'Agenesia o hipoplasia de la nariz',
	},
	{
		id: 7593,
		code: 'Q302',
		label: 'Hendidura, fisura o muesca de la nariz',
	},
	{
		id: 7594,
		code: 'Q303',
		label: 'Perforación congénita del tabique nasal',
	},
	{
		id: 7595,
		code: 'Q308',
		label: 'Otras malformaciones congénitas de la nariz',
	},
	{
		id: 7596,
		code: 'Q309',
		label: 'Malformación congénita de la nariz, no especificada',
	},
	{
		id: 7597,
		code: 'Q31',
		label: 'Malformaciones congénitas de la laringe',
	},
	{
		id: 7598,
		code: 'Q310',
		label: 'Pterigión de la laringe',
	},
	{
		id: 7599,
		code: 'Q311',
		label: 'Estenosis subglótica congénita',
	},
	{
		id: 7600,
		code: 'Q312',
		label: 'Hipoplasia laríngea',
	},
	{
		id: 7601,
		code: 'Q313',
		label: 'Laringocele',
	},
	{
		id: 7602,
		code: 'Q314',
		label: "Estridor lar'ingeo congénito",
	},
	{
		id: 7603,
		code: 'Q315',
		label: 'Laringomalasia congénita',
	},
	{
		id: 7604,
		code: 'Q318',
		label: 'Otras malformaciones congénitas de la laringe',
	},
	{
		id: 7605,
		code: 'Q319',
		label: 'Malformación congénita de la laringe, no especificada',
	},
	{
		id: 7606,
		code: 'Q32',
		label: 'Malformaciones congénitas de la tráquea y de los bronquios',
	},
	{
		id: 7607,
		code: 'Q320',
		label: 'Traqueomalacia congénita',
	},
	{
		id: 7608,
		code: 'Q321',
		label: 'Otras malformaciones congénitas de la tráquea',
	},
	{
		id: 7609,
		code: 'Q322',
		label: 'Broncomalacia congénita',
	},
	{
		id: 7610,
		code: 'Q323',
		label: 'Estenosis congénita de los bronquios',
	},
	{
		id: 7611,
		code: 'Q324',
		label: 'Otras malformaciones congénitas de los bronquios',
	},
	{
		id: 7612,
		code: 'Q33',
		label: 'Malformaciones congénitas del pulmón',
	},
	{
		id: 7613,
		code: 'Q330',
		label: 'Quiste pulmonar congénito',
	},
	{
		id: 7614,
		code: 'Q331',
		label: 'Lóbulo pulmonar supernumerario',
	},
	{
		id: 7615,
		code: 'Q332',
		label: 'Secuestro del pulmón',
	},
	{
		id: 7616,
		code: 'Q333',
		label: 'Agenesia del pulmón',
	},
	{
		id: 7617,
		code: 'Q334',
		label: 'Bronquiectasia congénita',
	},
	{
		id: 7618,
		code: 'Q335',
		label: 'Tejido ectópico en el pulmón',
	},
	{
		id: 7619,
		code: 'Q336',
		label: 'Hipoplasia y displasia pulmonar',
	},
	{
		id: 7620,
		code: 'Q338',
		label: 'Otras malformaciones congénitas del pulmón',
	},
	{
		id: 7621,
		code: 'Q339',
		label: 'Malformación congénita del pulmón, no especificada',
	},
	{
		id: 7622,
		code: 'Q34',
		label: 'Otras malformaciones congénitas del sistema respiratorio',
	},
	{
		id: 7623,
		code: 'Q340',
		label: 'Anomalía de la pleura',
	},
	{
		id: 7624,
		code: 'Q341',
		label: 'Quiste congénito del mediastino',
	},
	{
		id: 7625,
		code: 'Q348',
		label: 'Otras malformaciones congénitas especificadas del sistema respiratorio',
	},
	{
		id: 7626,
		code: 'Q349',
		label: 'Malformación congénita del sistema respiratorio, no especificada',
	},
	{
		id: 7627,
		code: 'Q35',
		label: 'Fisura del paladar',
	},
	{
		id: 7628,
		code: 'Q350',
		label: 'Fisura del paladar duro, bilateral',
	},
	{
		id: 7629,
		code: 'Q351',
		label: 'Fisura del paladar duro',
	},
	{
		id: 7630,
		code: 'Q352',
		label: 'Fisura del paladar blando, bilateral',
	},
	{
		id: 7631,
		code: 'Q353',
		label: 'Fisura del paladar blando',
	},
	{
		id: 7632,
		code: 'Q354',
		label: 'Fisura del paladar duro y del paladar blando, bilateral',
	},
	{
		id: 7633,
		code: 'Q355',
		label: 'Fisura del paladar duro y del paladar blando',
	},
	{
		id: 7634,
		code: 'Q356',
		label: 'Fisura del paladar, línea media',
	},
	{
		id: 7635,
		code: 'Q357',
		label: 'Fisura de la úvula',
	},
	{
		id: 7636,
		code: 'Q358',
		label: 'Fisura del paladar, bilateral sin otra especificación',
	},
	{
		id: 7637,
		code: 'Q359',
		label: 'Fisura del paladar, sin otra especificación',
	},
	{
		id: 7638,
		code: 'Q36',
		label: 'Labio leporino',
	},
	{
		id: 7639,
		code: 'Q360',
		label: 'Labio leporino, bilateral',
	},
	{
		id: 7640,
		code: 'Q361',
		label: 'Labio leporino, línea media',
	},
	{
		id: 7641,
		code: 'Q369',
		label: 'Labio leporino, unilateral',
	},
	{
		id: 7642,
		code: 'Q37',
		label: 'Fisura del paladar con labio leporino',
	},
	{
		id: 7643,
		code: 'Q370',
		label: 'Fisura del paladar duro con labio leporino bilateral',
	},
	{
		id: 7644,
		code: 'Q371',
		label: 'Fisura del paladar duro con labio leporino unilateral',
	},
	{
		id: 7645,
		code: 'Q372',
		label: 'Fisura del paladar blando con labio leporino bilateral',
	},
	{
		id: 7646,
		code: 'Q373',
		label: 'Fisura del paladar blando con labio leporino unilateral',
	},
	{
		id: 7647,
		code: 'Q374',
		label: 'Fisura del paladar duro y del paladar blando con labio leporino bilateral',
	},
	{
		id: 7648,
		code: 'Q375',
		label: 'Fisura del paladar duro y del paladar blando con labio leporino unilateral',
	},
	{
		id: 7649,
		code: 'Q378',
		label: 'Fisura del paladar con labio leporino bilateral, sin otra especificación',
	},
	{
		id: 7650,
		code: 'Q379',
		label: 'Fisura del paladar con labio leporino unilateral, sin otra especificación',
	},
	{
		id: 7651,
		code: 'Q38',
		label: 'Otras malformaciones congénitas de la lengua, de la boca y de la faringe',
	},
	{
		id: 7652,
		code: 'Q380',
		label: 'Malformaciones congénitas de los labios, no clasificadas en otra parte',
	},
	{
		id: 7653,
		code: 'Q381',
		label: 'Anquiloglosia',
	},
	{
		id: 7654,
		code: 'Q382',
		label: 'Macroglosia',
	},
	{
		id: 7655,
		code: 'Q383',
		label: 'Otras malformaciones congénitas de la lengua',
	},
	{
		id: 7656,
		code: 'Q384',
		label: 'Malformaciones congénitas de las glándulas y de los conductos salivales',
	},
	{
		id: 7657,
		code: 'Q385',
		label: 'Malformaciones congénitas del paladar, no clasificadas en otra parte',
	},
	{
		id: 7658,
		code: 'Q386',
		label: 'Otras malformaciones congénitas de la boca',
	},
	{
		id: 7659,
		code: 'Q387',
		label: 'Divertículo faríngeo',
	},
	{
		id: 7660,
		code: 'Q388',
		label: 'Otras malformaciones congénitas de la faringe',
	},
	{
		id: 7661,
		code: 'Q39',
		label: 'Malformaciones congénitas del esófago',
	},
	{
		id: 7662,
		code: 'Q390',
		label: 'Atresia del esófago sin mención de fístula',
	},
	{
		id: 7663,
		code: 'Q391',
		label: 'Atresia del esófago con fístula traqueoesofágica',
	},
	{
		id: 7664,
		code: 'Q392',
		label: 'Fístula traqueoesofágica congénita sin mención de atresia',
	},
	{
		id: 7665,
		code: 'Q393',
		label: 'Estrechez o estenosis congénita del esófago',
	},
	{
		id: 7666,
		code: 'Q394',
		label: 'Pterigión del esófago',
	},
	{
		id: 7667,
		code: 'Q395',
		label: 'Dilatación congénita del esófago',
	},
	{
		id: 7668,
		code: 'Q396',
		label: 'Divertículo del esófago',
	},
	{
		id: 7669,
		code: 'Q398',
		label: 'Otras malformaciones congénitas del esófago',
	},
	{
		id: 7670,
		code: 'Q399',
		label: 'Malformación congénita del esófago, no especificada',
	},
	{
		id: 7671,
		code: 'Q40',
		label: 'Otras malformaciones congénitas de la parte superior del tubo digestivo',
	},
	{
		id: 7672,
		code: 'Q400',
		label: 'Estenosis hipertrófica congénita del píloro',
	},
	{
		id: 7673,
		code: 'Q401',
		label: 'Hernia hiatal congénita',
	},
	{
		id: 7674,
		code: 'Q402',
		label: 'Otras malformaciones congénitas del estómago, especificadas',
	},
	{
		id: 7675,
		code: 'Q403',
		label: 'Malformación congénita del estómago, no especificada',
	},
	{
		id: 7676,
		code: 'Q408',
		label: 'Otras malformaciones congénitas de la parte superior del tubo digestivo',
	},
	{
		id: 7677,
		code: 'Q409',
		label: 'Malformación congénita de la parte superior del tubo digestivo, no especificada',
	},
	{
		id: 7678,
		code: 'Q41',
		label: 'Ausencia, atresia y estenosis congénita del intestino delgado',
	},
	{
		id: 7679,
		code: 'Q410',
		label: 'Ausencia, atresia y estenosis congénita del duodeno',
	},
	{
		id: 7680,
		code: 'Q411',
		label: 'Ausencia, atresia y estenosis congénita del yeyuno',
	},
	{
		id: 7681,
		code: 'Q412',
		label: 'Ausencia, atresia y estenosis congénita del íleon',
	},
	{
		id: 7682,
		code: 'Q418',
		label: 'Ausencia, atresia y estenosis congénita de otras partes especificadas del intestino delgado',
	},
	{
		id: 7683,
		code: 'Q419',
		label: 'Ausencia, atresia y estenosis congénita del intestino delgado, parte no especificada',
	},
	{
		id: 7684,
		code: 'Q42',
		label: 'Ausencia, atresia y estenosis congénita del intestino grueso',
	},
	{
		id: 7685,
		code: 'Q420',
		label: 'Ausencia, atresia y estenosis congénita del recto, con fístula',
	},
	{
		id: 7686,
		code: 'Q421',
		label: 'Ausencia, atresia y estenosis congénita del recto, sin fístula',
	},
	{
		id: 7687,
		code: 'Q422',
		label: 'Ausencia, atresia y estenosis congénita del ano, con fístula',
	},
	{
		id: 7688,
		code: 'Q423',
		label: 'Ausencia, atresia y estenosis congénita del ano, sin fístula',
	},
	{
		id: 7689,
		code: 'Q428',
		label: 'Ausencia, atresia y estenosis congénita de otras partes del intestino grueso',
	},
	{
		id: 7690,
		code: 'Q429',
		label: 'Ausencia, atresia y estenosis congénita del intestino grueso, parte no especificada',
	},
	{
		id: 7691,
		code: 'Q43',
		label: 'Otras malformaciones congénitas del intestino',
	},
	{
		id: 7692,
		code: 'Q430',
		label: 'Divertículo de Meckel',
	},
	{
		id: 7693,
		code: 'Q431',
		label: 'Enfermedad de Hirschsprung',
	},
	{
		id: 7694,
		code: 'Q432',
		label: 'Otros trastornos funcionales congénitos del colon',
	},
	{
		id: 7695,
		code: 'Q433',
		label: 'Malformaciones congénitas de la fijación del intestino',
	},
	{
		id: 7696,
		code: 'Q434',
		label: 'Duplicación del intestino',
	},
	{
		id: 7697,
		code: 'Q435',
		label: 'Ano ectópico',
	},
	{
		id: 7698,
		code: 'Q436',
		label: 'Fístula congénita del recto y del ano',
	},
	{
		id: 7699,
		code: 'Q437',
		label: 'Persistencia de la cloaca',
	},
	{
		id: 7700,
		code: 'Q438',
		label: 'Otras malformaciones congénitas del intestino, especificadas',
	},
	{
		id: 7701,
		code: 'Q439',
		label: 'Malformación congénita del intestino, no especificada',
	},
	{
		id: 7702,
		code: 'Q44',
		label: 'Malformaciones congénitas de la vesícula biliar, de los conductos biliares y del hígado',
	},
	{
		id: 7703,
		code: 'Q440',
		label: 'Agenesia, aplasia e hipoplasia de la vesícula biliar',
	},
	{
		id: 7704,
		code: 'Q441',
		label: 'Otras malformaciones congénitas de la vesícula biliar',
	},
	{
		id: 7705,
		code: 'Q442',
		label: 'Atresia de los conductos biliares',
	},
	{
		id: 7706,
		code: 'Q443',
		label: 'Estrechez y estenosis congénita de los conductos biliares',
	},
	{
		id: 7707,
		code: 'Q444',
		label: 'Quiste del colédoco',
	},
	{
		id: 7708,
		code: 'Q445',
		label: 'Otras malformaciones congénitas de los conductos biliares',
	},
	{
		id: 7709,
		code: 'Q446',
		label: 'Enfermedad quística del hígado',
	},
	{
		id: 7710,
		code: 'Q447',
		label: 'Otras malformaciones congénitas del hígado',
	},
	{
		id: 7711,
		code: 'Q45',
		label: 'Otras malformaciones congénitas del sistema digestivo',
	},
	{
		id: 7712,
		code: 'Q450',
		label: 'Agenesia, aplasia e hipoplasia del páncreas',
	},
	{
		id: 7713,
		code: 'Q451',
		label: 'Páncreas anular',
	},
	{
		id: 7714,
		code: 'Q452',
		label: 'Quiste congénito del páncreas',
	},
	{
		id: 7715,
		code: 'Q453',
		label: 'Otras malformaciones congénitas del páncreas y del conducto pancreático',
	},
	{
		id: 7716,
		code: 'Q458',
		label: 'Otras malformaciones congénitas del sistema digestivo, especificadas',
	},
	{
		id: 7717,
		code: 'Q459',
		label: 'Malformación congénita del sistema digestivo, no especificada',
	},
	{
		id: 7718,
		code: 'Q50',
		label: 'Malformaciones congénitas de los ovarios, de las trompas de Falopio y de los ligamentos anchos',
	},
	{
		id: 7719,
		code: 'Q500',
		label: 'Ausencia congénita de ovario',
	},
	{
		id: 7720,
		code: 'Q501',
		label: 'Quiste en desarrollo del ovario',
	},
	{
		id: 7721,
		code: 'Q502',
		label: 'Torsión congénita del ovario',
	},
	{
		id: 7722,
		code: 'Q503',
		label: 'Otras malformaciones congénitas de los ovarios',
	},
	{
		id: 7723,
		code: 'Q504',
		label: 'Quiste embrionario de la trompa de Falopio',
	},
	{
		id: 7724,
		code: 'Q505',
		label: 'Quiste embrionario del ligamento ancho',
	},
	{
		id: 7725,
		code: 'Q506',
		label: 'Otras malformaciones congénitas de la trompa de Falopio y del ligamento ancho',
	},
	{
		id: 7726,
		code: 'Q51',
		label: 'Malformaciones congénitas del útero y del cuello uterino',
	},
	{
		id: 7727,
		code: 'Q510',
		label: 'Agenesia y aplasia del útero',
	},
	{
		id: 7728,
		code: 'Q511',
		label: 'Duplicación del útero con duplicación del cuello uterino y de la vagina',
	},
	{
		id: 7729,
		code: 'Q512',
		label: 'Otra duplicación del útero',
	},
	{
		id: 7730,
		code: 'Q513',
		label: 'Útero bicorne',
	},
	{
		id: 7731,
		code: 'Q514',
		label: 'Útero unicorne',
	},
	{
		id: 7732,
		code: 'Q515',
		label: 'Agenesia y aplasia del cuello uterino',
	},
	{
		id: 7733,
		code: 'Q516',
		label: 'Quiste embrionario del cuello uterino',
	},
	{
		id: 7734,
		code: 'Q517',
		label: 'Fístula congénita entre el útero y el tracto digestivo y urinario',
	},
	{
		id: 7735,
		code: 'Q518',
		label: 'Otras malformaciones congénitas del útero y del cuello uterino',
	},
	{
		id: 7736,
		code: 'Q519',
		label: 'Malformación congénita del útero y del cuello uterino, no especificada',
	},
	{
		id: 7737,
		code: 'Q52',
		label: 'Otras malformaciones congénitas de los órganos genitales femeninos',
	},
	{
		id: 7738,
		code: 'Q520',
		label: 'Ausencia congénita de la vagina',
	},
	{
		id: 7739,
		code: 'Q521',
		label: 'Duplicación de la vagina',
	},
	{
		id: 7740,
		code: 'Q522',
		label: 'Fístula rectovaginal congénita',
	},
	{
		id: 7741,
		code: 'Q523',
		label: 'Himen imperforado',
	},
	{
		id: 7742,
		code: 'Q524',
		label: 'Otras malformaciones congénitas de la vagina',
	},
	{
		id: 7743,
		code: 'Q525',
		label: 'Fusión de labios de la vulva',
	},
	{
		id: 7744,
		code: 'Q526',
		label: 'Malformación congénita del clítoris',
	},
	{
		id: 7745,
		code: 'Q527',
		label: 'Otras malformaciones congénitas de la vulva',
	},
	{
		id: 7746,
		code: 'Q528',
		label: 'Otras malformaciones congénitas de los órganos genitales femeninos, especificadas',
	},
	{
		id: 7747,
		code: 'Q529',
		label: 'Malformación congénita de los genitales femeninos, no especificada',
	},
	{
		id: 7748,
		code: 'Q53',
		label: 'Testículo no descendido',
	},
	{
		id: 7749,
		code: 'Q530',
		label: 'Ectopia testicular',
	},
	{
		id: 7750,
		code: 'Q531',
		label: 'Testículo no descendido, unilateral',
	},
	{
		id: 7751,
		code: 'Q532',
		label: 'Testículo no descendido, bilateral',
	},
	{
		id: 7752,
		code: 'Q539',
		label: 'Testículo no descendido, sin otra especificación',
	},
	{
		id: 7753,
		code: 'Q54',
		label: 'Hipospadias',
	},
	{
		id: 7754,
		code: 'Q540',
		label: 'Hipospadias del glande',
	},
	{
		id: 7755,
		code: 'Q541',
		label: 'Hipospadias peneana',
	},
	{
		id: 7756,
		code: 'Q542',
		label: 'Hipospadias penoscrotal',
	},
	{
		id: 7757,
		code: 'Q543',
		label: 'Hipospadias perineal',
	},
	{
		id: 7758,
		code: 'Q544',
		label: 'Encordamiento congénito del pene',
	},
	{
		id: 7759,
		code: 'Q548',
		label: 'Otras hipospadias',
	},
	{
		id: 7760,
		code: 'Q549',
		label: 'Hipospadias, no especificada',
	},
	{
		id: 7761,
		code: 'Q55',
		label: 'Otras malformaciones congénitas de los órganos genitales masculinos',
	},
	{
		id: 7762,
		code: 'Q550',
		label: 'Ausencia y aplasia del testículo',
	},
	{
		id: 7763,
		code: 'Q551',
		label: 'Hipoplasia del testículo y del escroto',
	},
	{
		id: 7764,
		code: 'Q552',
		label: 'Otras malformaciones congénitas de los testículos y del escroto',
	},
	{
		id: 7765,
		code: 'Q553',
		label: 'Atresia del conducto deferente',
	},
	{
		id: 7766,
		code: 'Q554',
		label: 'Otras malformaciones congénitas de los conductos deferentes, del epidídimo, de las vesículas seminales y de la próstata',
	},
	{
		id: 7767,
		code: 'Q555',
		label: 'Aplasia y ausencia congénita del pene',
	},
	{
		id: 7768,
		code: 'Q556',
		label: 'Otras malformaciones congénitas del pene',
	},
	{
		id: 7769,
		code: 'Q558',
		label: 'Otras malformaciones congénitas de los órganos genitales masculinos, especificadas',
	},
	{
		id: 7770,
		code: 'Q559',
		label: 'Malformación congénita de los órganos genitales masculinos, no especificada',
	},
	{
		id: 7771,
		code: 'Q56',
		label: 'Sexo indeterminado y seudohermafroditismo',
	},
	{
		id: 7772,
		code: 'Q560',
		label: 'Hermafroditismo, no clasificado en otra parte',
	},
	{
		id: 7773,
		code: 'Q561',
		label: 'Seudohermafroditismo masculino, no clasificado en otra parte',
	},
	{
		id: 7774,
		code: 'Q562',
		label: 'Seudohermafroditismo femenino, no clasificado en otra parte',
	},
	{
		id: 7775,
		code: 'Q563',
		label: 'Seudohermafroditismo, no especificado',
	},
	{
		id: 7776,
		code: 'Q564',
		label: 'Sexo indeterminado, sin otra especificación',
	},
	{
		id: 7777,
		code: 'Q60',
		label: 'Agenesia renal y otras malformaciones hipoplásicas del riñón',
	},
	{
		id: 7778,
		code: 'Q600',
		label: 'Agenesia renal, unilateral',
	},
	{
		id: 7779,
		code: 'Q601',
		label: 'Agenesia renal, bilateral',
	},
	{
		id: 7780,
		code: 'Q602',
		label: 'Agenesia renal, sin otra especificación',
	},
	{
		id: 7781,
		code: 'Q603',
		label: 'Hipoplasia renal, unilateral',
	},
	{
		id: 7782,
		code: 'Q604',
		label: 'Hipoplasia renal, bilateral',
	},
	{
		id: 7783,
		code: 'Q605',
		label: 'Hipoplasia renal, no especificada',
	},
	{
		id: 7784,
		code: 'Q606',
		label: 'Síndrome de Potter',
	},
	{
		id: 7785,
		code: 'Q61',
		label: 'Enfermedad quística del riñón',
	},
	{
		id: 7786,
		code: 'Q610',
		label: 'Quiste renal solitario congénito',
	},
	{
		id: 7787,
		code: 'Q611',
		label: 'Riñón poliquístico, autosómico recesivo',
	},
	{
		id: 7788,
		code: 'Q612',
		label: 'Riñón poliquístico, autosómico dominante',
	},
	{
		id: 7789,
		code: 'Q613',
		label: 'Riñón poliquístico, tipo no especificado',
	},
	{
		id: 7790,
		code: 'Q614',
		label: 'Displasia renal',
	},
	{
		id: 7791,
		code: 'Q615',
		label: 'Riñón quístico medular',
	},
	{
		id: 7792,
		code: 'Q618',
		label: 'Otras enfermedades renales quísticas',
	},
	{
		id: 7793,
		code: 'Q619',
		label: 'Enfermedad quística del riñón, no especificada',
	},
	{
		id: 7794,
		code: 'Q62',
		label: 'Defectos obstructivos congénitos de la pelvis renal y malformaciones congénitas del uréter',
	},
	{
		id: 7795,
		code: 'Q620',
		label: 'Hidronefrosis congénita',
	},
	{
		id: 7796,
		code: 'Q621',
		label: 'Atresia y estenosis del uréter',
	},
	{
		id: 7797,
		code: 'Q622',
		label: 'Megalouréter congénito',
	},
	{
		id: 7798,
		code: 'Q623',
		label: 'Otros defectos obstructivos de la pelvis renal y del uréter',
	},
	{
		id: 7799,
		code: 'Q624',
		label: 'Agenesia del uréter',
	},
	{
		id: 7800,
		code: 'Q625',
		label: 'Duplicación del uréter',
	},
	{
		id: 7801,
		code: 'Q626',
		label: 'Mala posición del uréter',
	},
	{
		id: 7802,
		code: 'Q627',
		label: 'Reflujo vésico-urétero-renal congénito',
	},
	{
		id: 7803,
		code: 'Q628',
		label: 'Otras malformaciones congénitas del uréter',
	},
	{
		id: 7804,
		code: 'Q63',
		label: 'Otras malformaciones congénitas del riñón',
	},
	{
		id: 7805,
		code: 'Q630',
		label: 'Riñón supernumerario',
	},
	{
		id: 7806,
		code: 'Q631',
		label: 'Riñón lobulado, fusionado y en herradura',
	},
	{
		id: 7807,
		code: 'Q632',
		label: 'Riñón ectópico',
	},
	{
		id: 7808,
		code: 'Q633',
		label: 'Hiperplasia renal y riñón gigante',
	},
	{
		id: 7809,
		code: 'Q638',
		label: 'Otras malformaciones congénitas del riñón, especificadas',
	},
	{
		id: 7810,
		code: 'Q639',
		label: 'Malformación congénita del riñón, no especificada',
	},
	{
		id: 7811,
		code: 'Q64',
		label: 'Otras malformaciones congénitas del sistema urinario',
	},
	{
		id: 7812,
		code: 'Q640',
		label: 'Epispadias',
	},
	{
		id: 7813,
		code: 'Q641',
		label: 'Extrofia de la vejiga urinaria',
	},
	{
		id: 7814,
		code: 'Q642',
		label: 'Válvulas uretrales posteriores congénitas',
	},
	{
		id: 7815,
		code: 'Q643',
		label: 'Otras atresias y estenosis de la uretra y del cuello de la vejiga',
	},
	{
		id: 7816,
		code: 'Q644',
		label: 'Malformación del uraco',
	},
	{
		id: 7817,
		code: 'Q645',
		label: 'Ausencia congénita de la vejiga y de la uretra',
	},
	{
		id: 7818,
		code: 'Q646',
		label: 'Divertículo congénito de la vejiga',
	},
	{
		id: 7819,
		code: 'Q647',
		label: 'Otras malformaciones congénitas de la vejiga y de la uretra',
	},
	{
		id: 7820,
		code: 'Q648',
		label: 'Otras malformaciones congénitas del aparato urinario, especificadas',
	},
	{
		id: 7821,
		code: 'Q649',
		label: 'Malformación congénita del aparato urinario, no especificada',
	},
	{
		id: 7822,
		code: 'Q65',
		label: 'Deformidades congénitas de la cadera',
	},
	{
		id: 7823,
		code: 'Q650',
		label: 'Luxación congénita de la cadera, unilateral',
	},
	{
		id: 7824,
		code: 'Q651',
		label: 'Luxación congénita de la cadera, bilateral',
	},
	{
		id: 7825,
		code: 'Q652',
		label: 'Luxación congénita de la cadera, no especificada',
	},
	{
		id: 7826,
		code: 'Q653',
		label: 'Subluxación congénita de la cadera, unilateral',
	},
	{
		id: 7827,
		code: 'Q654',
		label: 'Subluxación congénita de la cadera, bilateral',
	},
	{
		id: 7828,
		code: 'Q655',
		label: 'Subluxación congénita de la cadera, no especificada',
	},
	{
		id: 7829,
		code: 'Q656',
		label: 'Cadera inestable',
	},
	{
		id: 7830,
		code: 'Q658',
		label: 'Otras deformidades congénitas de la cadera',
	},
	{
		id: 7831,
		code: 'Q659',
		label: 'Deformidad congénita de la cadera, no especificada',
	},
	{
		id: 7832,
		code: 'Q66',
		label: 'Deformidades congénitas de los pies',
	},
	{
		id: 7833,
		code: 'Q660',
		label: 'Talipes equinovarus',
	},
	{
		id: 7834,
		code: 'Q661',
		label: 'Talipes calcaneovarus',
	},
	{
		id: 7835,
		code: 'Q662',
		label: 'Metatarsus varus',
	},
	{
		id: 7836,
		code: 'Q663',
		label: 'Otras deformidades varus congénitas de los pies',
	},
	{
		id: 7837,
		code: 'Q664',
		label: 'Talipes calcaneovalgus',
	},
	{
		id: 7838,
		code: 'Q665',
		label: 'Pie plano congénito',
	},
	{
		id: 7839,
		code: 'Q666',
		label: 'Otras deformidades valgus congénitas de los pies',
	},
	{
		id: 7840,
		code: 'Q667',
		label: 'Pie cavus',
	},
	{
		id: 7841,
		code: 'Q668',
		label: 'Otras deformidades congénitas de los pies',
	},
	{
		id: 7842,
		code: 'Q669',
		label: 'Deformidad congénita de los pies, no especificada',
	},
	{
		id: 7843,
		code: 'Q67',
		label: 'Deformidades osteomusculares congénitas de la cabeza, de la cara, de la columna vertebral y del tórax',
	},
	{
		id: 7844,
		code: 'Q670',
		label: 'Asimetría facial',
	},
	{
		id: 7845,
		code: 'Q671',
		label: 'Facies comprimida',
	},
	{
		id: 7846,
		code: 'Q672',
		label: 'Dolicocefalia',
	},
	{
		id: 7847,
		code: 'Q673',
		label: 'Plagiocefalia',
	},
	{
		id: 7848,
		code: 'Q674',
		label: 'Otras deformidades congénitas del cráneo, de la cara y de la mandíbula',
	},
	{
		id: 7849,
		code: 'Q675',
		label: 'Deformidad congénita de la columna vertebral',
	},
	{
		id: 7850,
		code: 'Q676',
		label: 'tórax excavado',
	},
	{
		id: 7851,
		code: 'Q677',
		label: 'Tórax en quilla',
	},
	{
		id: 7852,
		code: 'Q678',
		label: 'Otras deformidades congénitas del tórax',
	},
	{
		id: 7853,
		code: 'Q68',
		label: 'Otras deformidades osteomusculares congénitas',
	},
	{
		id: 7854,
		code: 'Q680',
		label: 'Deformidad congénita del músculo esternocleidomastoideo',
	},
	{
		id: 7855,
		code: 'Q681',
		label: 'Deformidad congénita de la mano',
	},
	{
		id: 7856,
		code: 'Q682',
		label: 'Deformidad congénita de la rodilla',
	},
	{
		id: 7857,
		code: 'Q683',
		label: 'Curvatura congénita del fémur',
	},
	{
		id: 7858,
		code: 'Q684',
		label: 'Curvatura congénita de la tibia y del peroné',
	},
	{
		id: 7859,
		code: 'Q685',
		label: 'Curvatura congénita de hueso(s) largo(s) del miembro inferior, sin otra especificación',
	},
	{
		id: 7860,
		code: 'Q688',
		label: 'Otras deformidades congénitas osteomusculares, especificadas',
	},
	{
		id: 7861,
		code: 'Q69',
		label: 'Polidactilia',
	},
	{
		id: 7862,
		code: 'Q690',
		label: 'Dedo(s) supernumerario(s) de la mano',
	},
	{
		id: 7863,
		code: 'Q691',
		label: 'Pulgar(es) supernumerario(s)',
	},
	{
		id: 7864,
		code: 'Q692',
		label: 'Dedo(s) supernumerario(s) del pie',
	},
	{
		id: 7865,
		code: 'Q699',
		label: 'Polidactilia, no especificada',
	},
	{
		id: 7866,
		code: 'Q70',
		label: 'Sindactilia',
	},
	{
		id: 7867,
		code: 'Q700',
		label: 'Fusión de los dedos de la mano',
	},
	{
		id: 7868,
		code: 'Q701',
		label: 'Membrana interdigital de la mano',
	},
	{
		id: 7869,
		code: 'Q702',
		label: 'Fusión de los dedos del pie',
	},
	{
		id: 7870,
		code: 'Q703',
		label: 'Membrana interdigital del pie',
	},
	{
		id: 7871,
		code: 'Q704',
		label: 'Polisindactilia',
	},
	{
		id: 7872,
		code: 'Q709',
		label: 'Sindactilia, no especificada',
	},
	{
		id: 7873,
		code: 'Q71',
		label: 'Defectos por reducción del miembro superior',
	},
	{
		id: 7874,
		code: 'Q710',
		label: 'Ausencia congénita completa del (de los) miembro(s) superior(es)',
	},
	{
		id: 7875,
		code: 'Q711',
		label: 'Ausencia congénita del brazo y del antebrazo con presencia de la mano',
	},
	{
		id: 7876,
		code: 'Q712',
		label: 'Ausencia congénita del antebrazo y de la mano',
	},
	{
		id: 7877,
		code: 'Q713',
		label: 'Ausencia congénita de la mano y el (los) dedo(s)',
	},
	{
		id: 7878,
		code: 'Q714',
		label: 'Defecto por reducción longitudinal del radio',
	},
	{
		id: 7879,
		code: 'Q715',
		label: 'Defecto por reducción longitudinal del cúbito',
	},
	{
		id: 7880,
		code: 'Q716',
		label: 'Mano en pinza de langosta',
	},
	{
		id: 7881,
		code: 'Q718',
		label: 'Otros defectos por reducción del (de los) miembro(s) superior(es)',
	},
	{
		id: 7882,
		code: 'Q719',
		label: 'Defecto por reducción del miembro superior, no especificado',
	},
	{
		id: 7883,
		code: 'Q72',
		label: 'Defectos por reducción del miembro inferior',
	},
	{
		id: 7884,
		code: 'Q720',
		label: 'Ausencia congénita completa del (de los) miembro(s) inferior(es)',
	},
	{
		id: 7885,
		code: 'Q721',
		label: 'Ausencia congénita del muslo y de la pierna con presencia del pie',
	},
	{
		id: 7886,
		code: 'Q722',
		label: 'Ausencia congénita de la pierna y del pie',
	},
	{
		id: 7887,
		code: 'Q723',
		label: 'Ausencia congénita del pie y dedo(s) del pie',
	},
	{
		id: 7888,
		code: 'Q724',
		label: 'Defecto por reducción longitudinal del fémur',
	},
	{
		id: 7889,
		code: 'Q725',
		label: 'Defecto por reducción longitudinal de la tibia',
	},
	{
		id: 7890,
		code: 'Q726',
		label: 'Defecto por reducción longitudinal del peroné',
	},
	{
		id: 7891,
		code: 'Q727',
		label: 'Pie hendido',
	},
	{
		id: 7892,
		code: 'Q728',
		label: 'Otros defectos por reducción del (de los) miembro(s) inferior(es)',
	},
	{
		id: 7893,
		code: 'Q729',
		label: 'Defecto por reducción del miembro inferior, no especificado',
	},
	{
		id: 7894,
		code: 'Q73',
		label: 'Defectos por reducción de miembro no especificado',
	},
	{
		id: 7895,
		code: 'Q730',
		label: 'Ausencia completa de miembro(s) no especificado(s)',
	},
	{
		id: 7896,
		code: 'Q731',
		label: 'Focomelia, miembro(s) no especificado(s)',
	},
	{
		id: 7897,
		code: 'Q738',
		label: 'Otros defectos por reducción de miembro(s) no especificado(s)',
	},
	{
		id: 7898,
		code: 'Q74',
		label: 'Otras anomalías congénitas del (de los) miembro(s)',
	},
	{
		id: 7899,
		code: 'Q740',
		label: 'Otras malformaciones congénitas del (de los) miembro(s) superior(es), incluida la cintura escapular',
	},
	{
		id: 7900,
		code: 'Q741',
		label: 'Malformación congénita de la rodilla',
	},
	{
		id: 7901,
		code: 'Q742',
		label: 'Otras malformaciones congénitas del (de los) miembro(s) inferior(es), incluida la cintura pelviana',
	},
	{
		id: 7902,
		code: 'Q743',
		label: 'Artrogriposis múltiple congénita',
	},
	{
		id: 7903,
		code: 'Q748',
		label: 'Otras malformaciones congénitas especificadas del (de los) miembro(s)',
	},
	{
		id: 7904,
		code: 'Q749',
		label: 'Malformación congénita de miembro(s), no especificada',
	},
	{
		id: 7905,
		code: 'Q75',
		label: 'Otras malformaciones congénitas de los huesos del cráneo y de la cara',
	},
	{
		id: 7906,
		code: 'Q750',
		label: 'Craneosinostosis',
	},
	{
		id: 7907,
		code: 'Q751',
		label: 'Disostosis craneofacial',
	},
	{
		id: 7908,
		code: 'Q752',
		label: 'Hipertelorismo',
	},
	{
		id: 7909,
		code: 'Q753',
		label: 'Macrocefalia',
	},
	{
		id: 7910,
		code: 'Q754',
		label: 'Disostosis maxilofacial',
	},
	{
		id: 7911,
		code: 'Q755',
		label: 'Disostosis oculomaxilar',
	},
	{
		id: 7912,
		code: 'Q758',
		label: 'Otras malformaciones congénitas especificadas de los huesos del cráneo y de la cara',
	},
	{
		id: 7913,
		code: 'Q759',
		label: 'Malformación congénita no especificada de los huesos del cráneo y de la cara',
	},
	{
		id: 7914,
		code: 'Q76',
		label: 'Malformaciones congénitas de la columna vertebral y tórax óseo',
	},
	{
		id: 7915,
		code: 'Q760',
		label: 'Espina bífida oculta',
	},
	{
		id: 7916,
		code: 'Q761',
		label: 'Síndrome de Klippel-Feil',
	},
	{
		id: 7917,
		code: 'Q762',
		label: 'Espondilolistesis congénita',
	},
	{
		id: 7918,
		code: 'Q763',
		label: 'Escoliosis congénita debida a malformación congénita ósea',
	},
	{
		id: 7919,
		code: 'Q764',
		label: 'Otra malformación congénita de la columna vertebral, no asociada con escoliosis',
	},
	{
		id: 7920,
		code: 'Q765',
		label: 'Costilla cervical',
	},
	{
		id: 7921,
		code: 'Q766',
		label: 'Otras malformaciones congénitas de las costillas',
	},
	{
		id: 7922,
		code: 'Q767',
		label: 'Malformación congénita del esternón',
	},
	{
		id: 7923,
		code: 'Q768',
		label: 'Otras malformaciones congénitas del tórax óseo',
	},
	{
		id: 7924,
		code: 'Q769',
		label: 'Malformación congénita del tórax óseo, no especificada',
	},
	{
		id: 7925,
		code: 'Q77',
		label: 'Osteocondrodisplasia con defecto del crecimiento de los huesos largos y de la columna vertebral',
	},
	{
		id: 7926,
		code: 'Q770',
		label: 'Acondrogénesis',
	},
	{
		id: 7927,
		code: 'Q771',
		label: 'Enanismo tanatofórico',
	},
	{
		id: 7928,
		code: 'Q772',
		label: 'Síndrome de costilla corta',
	},
	{
		id: 7929,
		code: 'Q773',
		label: 'Condrodisplasia punctata',
	},
	{
		id: 7930,
		code: 'Q774',
		label: 'Acondroplasia',
	},
	{
		id: 7931,
		code: 'Q775',
		label: 'Displasia distrófica',
	},
	{
		id: 7932,
		code: 'Q776',
		label: 'Displasia condroectodérmica',
	},
	{
		id: 7933,
		code: 'Q777',
		label: 'Displasia espondiloepifisaria',
	},
	{
		id: 7934,
		code: 'Q778',
		label: 'Otras osteocondrodisplasias con defectos del crecimiento de los huesos largos y de la columna vertebral',
	},
	{
		id: 7935,
		code: 'Q779',
		label: 'Osteocondrodisplasia con defectos del crecimiento de los huesos largos y de la columna vertebral, sin otra especificación',
	},
	{
		id: 7936,
		code: 'Q78',
		label: 'Otras osteocondrodisplasias',
	},
	{
		id: 7937,
		code: 'Q780',
		label: 'Osteogénesis imperfecta',
	},
	{
		id: 7938,
		code: 'Q781',
		label: 'Displasia poliostótica fibrosa',
	},
	{
		id: 7939,
		code: 'Q782',
		label: 'Osteopetrosis',
	},
	{
		id: 7940,
		code: 'Q783',
		label: 'Displasia diafisaria progresiva',
	},
	{
		id: 7941,
		code: 'Q784',
		label: 'Encondromatosis',
	},
	{
		id: 7942,
		code: 'Q785',
		label: 'Displasia metafisaria',
	},
	{
		id: 7943,
		code: 'Q786',
		label: 'Exostosis congénita múltiple',
	},
	{
		id: 7944,
		code: 'Q788',
		label: 'Otras osteocondrodisplasias especificadas',
	},
	{
		id: 7945,
		code: 'Q789',
		label: 'Osteocondrodisplasia, no especificada',
	},
	{
		id: 7946,
		code: 'Q79',
		label: 'Malformaciones congénitas del sistema osteomuscular, no clasificadas en otra parte',
	},
	{
		id: 7947,
		code: 'Q790',
		label: 'Hernia diafragmática congénita',
	},
	{
		id: 7948,
		code: 'Q791',
		label: 'Otras malformaciones congénitas del diafragma',
	},
	{
		id: 7949,
		code: 'Q792',
		label: 'Exónfalos',
	},
	{
		id: 7950,
		code: 'Q793',
		label: 'Gastrosquisis',
	},
	{
		id: 7951,
		code: 'Q794',
		label: 'Síndrome del abdomen en ciruela pasa',
	},
	{
		id: 7952,
		code: 'Q795',
		label: 'Otras malformaciones congénitas de la pared abdominal',
	},
	{
		id: 7953,
		code: 'Q796',
		label: 'Síndrome de Ehlers-Danlos',
	},
	{
		id: 7954,
		code: 'Q798',
		label: 'Otras malformaciones congénitas del sistema osteomuscular',
	},
	{
		id: 7955,
		code: 'Q799',
		label: 'Malformación congénita del sistema osteomuscular, no especificada',
	},
	{
		id: 7956,
		code: 'Q80',
		label: 'Ictiosis congénita',
	},
	{
		id: 7957,
		code: 'Q800',
		label: 'Ictiosis vulgar',
	},
	{
		id: 7958,
		code: 'Q801',
		label: 'Ictiosis ligada al cromosoma X',
	},
	{
		id: 7959,
		code: 'Q802',
		label: 'Ictiosis lamelar',
	},
	{
		id: 7960,
		code: 'Q803',
		label: 'Eritrodermia ictiosiforme vesicular congénita',
	},
	{
		id: 7961,
		code: 'Q804',
		label: 'Feto Arlequín',
	},
	{
		id: 7962,
		code: 'Q808',
		label: 'Otras ictiosis congénitas',
	},
	{
		id: 7963,
		code: 'Q809',
		label: 'Ictiosis congénita, no especificada',
	},
	{
		id: 7964,
		code: 'Q81',
		label: 'Epidermólisis bullosa',
	},
	{
		id: 7965,
		code: 'Q810',
		label: 'Epidermólisis bullosa simple',
	},
	{
		id: 7966,
		code: 'Q811',
		label: 'Epidermólisis bullosa letal',
	},
	{
		id: 7967,
		code: 'Q812',
		label: 'Epidermólisis bullosa distrófica',
	},
	{
		id: 7968,
		code: 'Q818',
		label: 'Otras Epidermólisis bullosas',
	},
	{
		id: 7969,
		code: 'Q819',
		label: 'Epidermólisis bullosa, no especificada',
	},
	{
		id: 7970,
		code: 'Q82',
		label: 'Otras malformaciones congénitas de la piel',
	},
	{
		id: 7971,
		code: 'Q820',
		label: 'Linfedema hereditario',
	},
	{
		id: 7972,
		code: 'Q821',
		label: 'Xeroderma pigmentoso',
	},
	{
		id: 7973,
		code: 'Q822',
		label: 'Mastocitosis',
	},
	{
		id: 7974,
		code: 'Q823',
		label: 'Incontinencia pigmentaria',
	},
	{
		id: 7975,
		code: 'Q824',
		label: 'Displasia ectodérmica (anhidrótica)',
	},
	{
		id: 7976,
		code: 'Q825',
		label: 'Nevo no neoplásico, congénito',
	},
	{
		id: 7977,
		code: 'Q828',
		label: 'Otras malformaciones congénitas de la piel, especificadas',
	},
	{
		id: 7978,
		code: 'Q829',
		label: 'Malformación congénita de la piel, no especificada',
	},
	{
		id: 7979,
		code: 'Q83',
		label: 'Malformaciones congénitas de la mama',
	},
	{
		id: 7980,
		code: 'Q830',
		label: 'Ausencia congénita de la mama con ausencia del pezón',
	},
	{
		id: 7981,
		code: 'Q831',
		label: 'Mama supernumeraria',
	},
	{
		id: 7982,
		code: 'Q832',
		label: 'Ausencia del pezón',
	},
	{
		id: 7983,
		code: 'Q833',
		label: 'Pezón supernumerario',
	},
	{
		id: 7984,
		code: 'Q838',
		label: 'Otras malformaciones congénitas de la mama',
	},
	{
		id: 7985,
		code: 'Q839',
		label: 'Malformación congénita de la mama, no especificada',
	},
	{
		id: 7986,
		code: 'Q84',
		label: 'Otras malformaciones congénitas de las faneras',
	},
	{
		id: 7987,
		code: 'Q840',
		label: 'Alopecia congénita',
	},
	{
		id: 7988,
		code: 'Q841',
		label: 'Alteraciones morfológicas congénitas del pelo, no clasificadas en otra parte',
	},
	{
		id: 7989,
		code: 'Q842',
		label: 'Otras malformaciones congénitas del pelo',
	},
	{
		id: 7990,
		code: 'Q843',
		label: 'Anoniquia',
	},
	{
		id: 7991,
		code: 'Q844',
		label: 'Leuconiquia congénita',
	},
	{
		id: 7992,
		code: 'Q845',
		label: 'Agrandamiento e hipertrofia de las uñas',
	},
	{
		id: 7993,
		code: 'Q846',
		label: 'Otras malformaciones congénitas de las uñas',
	},
	{
		id: 7994,
		code: 'Q848',
		label: 'Otras malformaciones congénitas de las faneras, especificadas',
	},
	{
		id: 7995,
		code: 'Q849',
		label: 'Malformación congénita de las faneras, no especificada',
	},
	{
		id: 7996,
		code: 'Q85',
		label: 'Facomatosis, no clasificada en otra parte',
	},
	{
		id: 7997,
		code: 'Q850',
		label: 'Neurofibromatosis (no maligna)',
	},
	{
		id: 7998,
		code: 'Q851',
		label: 'Esclerosis tuberosa',
	},
	{
		id: 7999,
		code: 'Q858',
		label: 'Otras facomatosis, no clasificadas en otra parte',
	},
	{
		id: 8000,
		code: 'Q859',
		label: 'Facomatosis, no especificada',
	},
	{
		id: 8001,
		code: 'Q86',
		label: 'Síndromes de malformaciones congénitas debidos a causas exógenas conocidas, no clasificados en otra parte',
	},
	{
		id: 8002,
		code: 'Q860',
		label: 'Síndrome fetal (dismórfico) debido al alcohol',
	},
	{
		id: 8003,
		code: 'Q861',
		label: 'Síndrome de hidantoína fetal',
	},
	{
		id: 8004,
		code: 'Q862',
		label: 'Dismorfismo debido a warfarina',
	},
	{
		id: 8005,
		code: 'Q868',
		label: 'Otros síndromes de malformaciones congénitas debidos a causas exógenas conocidas',
	},
	{
		id: 8006,
		code: 'Q87',
		label: 'Otros síndromes de malformaciones congénitas especificados que afectan múltiples sistemas',
	},
	{
		id: 8007,
		code: 'Q870',
		label: 'Síndromes de malformaciones congénitas que afectan principalmente la apariencia facial',
	},
	{
		id: 8008,
		code: 'Q871',
		label: 'Síndromes de malformaciones congénitas asociadas principalmente con estatura baja',
	},
	{
		id: 8009,
		code: 'Q872',
		label: 'Síndromes de malformaciones congénitas que afectan principalmente los miembros',
	},
	{
		id: 8010,
		code: 'Q873',
		label: 'Síndromes de malformaciones congénitas con exceso de crecimiento precoz',
	},
	{
		id: 8011,
		code: 'Q874',
		label: 'Síndrome de Marfan',
	},
	{
		id: 8012,
		code: 'Q875',
		label: 'Otros síndromes de malformaciones congénitas con otros cambios esqueléticos',
	},
	{
		id: 8013,
		code: 'Q878',
		label: 'Otros síndromes de malformaciones congénitas especificados, no clasificados en otra parte',
	},
	{
		id: 8014,
		code: 'Q89',
		label: 'Otras malformaciones congénitas, no clasificadas en otra parte',
	},
	{
		id: 8015,
		code: 'Q890',
		label: 'Malformaciones congénitas del bazo',
	},
	{
		id: 8016,
		code: 'Q891',
		label: 'Malformaciones congénitas de la glándula suprarrenal',
	},
	{
		id: 8017,
		code: 'Q892',
		label: 'Malformaciones congénitas de otras glándulas endocrinas',
	},
	{
		id: 8018,
		code: 'Q893',
		label: 'Situs inversus',
	},
	{
		id: 8019,
		code: 'Q894',
		label: 'Gemelos siameses',
	},
	{
		id: 8020,
		code: 'Q897',
		label: 'Malformaciones congénitas múltiples, no clasificadas en otra parte',
	},
	{
		id: 8021,
		code: 'Q898',
		label: 'Otras malformaciones congénitas, especificadas',
	},
	{
		id: 8022,
		code: 'Q899',
		label: 'Malformación congénita, no especificada',
	},
	{
		id: 8023,
		code: 'Q90',
		label: 'Síndrome de Down',
	},
	{
		id: 8024,
		code: 'Q900',
		label: 'Trisomía 21, por falta de disyunción meiótica',
	},
	{
		id: 8025,
		code: 'Q901',
		label: 'Trisomía 21, mosaico (por falta de disyunción mitótica)',
	},
	{
		id: 8026,
		code: 'Q902',
		label: 'Trisomia 21, por translocación',
	},
	{
		id: 8027,
		code: 'Q909',
		label: 'Síndrome de Down, no especificado',
	},
	{
		id: 8028,
		code: 'Q91',
		label: 'Síndrome de Edwards y síndrome  de Patau',
	},
	{
		id: 8029,
		code: 'Q910',
		label: 'Trisomia 18, por falta de disyunción meiótica',
	},
	{
		id: 8030,
		code: 'Q911',
		label: 'Trisomía 18, mosaico (por falta de disyunción mitótica)',
	},
	{
		id: 8031,
		code: 'Q912',
		label: 'Trisomía 18, por translocación',
	},
	{
		id: 8032,
		code: 'Q913',
		label: 'Síndrome de Edwards, no especificado',
	},
	{
		id: 8033,
		code: 'Q914',
		label: 'Trisomía 13, por falta de disyunción meiótica',
	},
	{
		id: 8034,
		code: 'Q915',
		label: 'Trisomía 13, mosaico (por falta de disyunción mitótica)',
	},
	{
		id: 8035,
		code: 'Q916',
		label: 'Trisomía 13, por translocación',
	},
	{
		id: 8036,
		code: 'Q917',
		label: 'Síndrome de Patau, no especificado',
	},
	{
		id: 8037,
		code: 'Q92',
		label: 'Otras trisomías y trisomías parciales de los autosomas, no clasificadas en otra parte',
	},
	{
		id: 8038,
		code: 'Q920',
		label: 'Trisomía de un cromosoma completo, por falta de disyunción meiótica',
	},
	{
		id: 8039,
		code: 'Q921',
		label: 'Trisomía de un cromosoma completo, mosaico (por falta de disyunción mitótica)',
	},
	{
		id: 8040,
		code: 'Q922',
		label: 'Trisomía parcial mayor',
	},
	{
		id: 8041,
		code: 'Q923',
		label: 'Trisomía parcial menor',
	},
	{
		id: 8042,
		code: 'Q924',
		label: 'Duplicaciones visibles solo en la prometafase',
	},
	{
		id: 8043,
		code: 'Q925',
		label: 'Duplicaciones con otros reordenamientos complejos',
	},
	{
		id: 8044,
		code: 'Q926',
		label: 'Cromosomas marcadores suplementarios',
	},
	{
		id: 8045,
		code: 'Q927',
		label: 'Triploidía y poliploidía',
	},
	{
		id: 8046,
		code: 'Q928',
		label: 'Otras trisomías y trisomías parciales de los autosomas, especificadas',
	},
	{
		id: 8047,
		code: 'Q929',
		label: 'Trisomía y Trisomía parcial de los autosomas, sin otra especificación',
	},
	{
		id: 8048,
		code: 'Q93',
		label: 'Monosomías y supresiones de los autosomas, no  clasificadas en otra parte',
	},
	{
		id: 8049,
		code: 'Q930',
		label: 'Monosomía completa de un cromosoma, por falta de disyunción meiótica',
	},
	{
		id: 8050,
		code: 'Q931',
		label: 'Monosomía completa de un cromosoma, mosaico (por falta de disyunción mitótica)',
	},
	{
		id: 8051,
		code: 'Q932',
		label: 'Cromosoma reemplazado por anillo o dicéntrico',
	},
	{
		id: 8052,
		code: 'Q933',
		label: 'Supresión del brazo corto del cromosoma 4',
	},
	{
		id: 8053,
		code: 'Q934',
		label: 'Supresión del brazo corto del cromosoma 5',
	},
	{
		id: 8054,
		code: 'Q935',
		label: 'Otras supresiones de parte de un cromosoma',
	},
	{
		id: 8055,
		code: 'Q936',
		label: 'Supresiones visibles sólo en la prometafase',
	},
	{
		id: 8056,
		code: 'Q937',
		label: 'Supresiones con otros reordenamientos complejos',
	},
	{
		id: 8057,
		code: 'Q938',
		label: 'Otras supresiones de los autosomas',
	},
	{
		id: 8058,
		code: 'Q939',
		label: 'Supresión de los autosomas, no especificada',
	},
	{
		id: 8059,
		code: 'Q95',
		label: 'Reordenamientos equilibrados y marcadores estructurales, no clasificados en otra parte',
	},
	{
		id: 8060,
		code: 'Q950',
		label: 'Translocación equilibrada e inserción en individuo normal',
	},
	{
		id: 8061,
		code: 'Q951',
		label: 'Inversión cromosómica en individuo normal',
	},
	{
		id: 8062,
		code: 'Q952',
		label: 'Reordenamiento autosómico equilibrado en individuo anormal',
	},
	{
		id: 8063,
		code: 'Q953',
		label: 'Reordenamiento autosómico sexual equilibrado en individuo anormal',
	},
	{
		id: 8064,
		code: 'Q954',
		label: 'Individuos con heterocromatina marcadora',
	},
	{
		id: 8065,
		code: 'Q955',
		label: 'Individuos con sitio frágil autosómico',
	},
	{
		id: 8066,
		code: 'Q958',
		label: 'Otros reordenamientos equilibrados y marcadores estructurales',
	},
];

export default diagnossis;
