import {
	Typography,
	Grid,
	Card,
	CardHeader,
	CardContent,
	CardActions,
	TextField,
	Button,
} from '@material-ui/core';
import styled from 'styled-components';

export const CustomTypography = styled(Typography)`
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 133.4%;
	/* identical to box height, or 32px */

	text-align: center;

	color: #000000;
	margin-top: 2rem;
`;

export const SuggestionContainer = styled(Grid)`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-top: 1rem;
`;

export const AICardContainer = styled(Card)`
	background: #ffffff;
	max-width: 740px;
	min-height: 195px;
	margin: 2rem auto;
	padding: 0px 24px 10px 24px;
`;

export const AICardHeader = styled(CardHeader)`
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 160%;
	/* identical to box height, or 32px */

	letter-spacing: 0.15px;

	color: #000000;
	&.MuiCardHeader-root {
		padding: 16px 16px 0px 16px;
	}
`;

export const AICardContent = styled(CardContent)``;

export const AICardActions = styled(CardActions)`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	padding: 0px 16px 16px 16px;
`;

export const AITextField = styled(TextField)`
	width: 100%;
`;

export const AIButton = styled(Button)<{
	customDisabled?: boolean;
}>`
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	/* identical to box height, or 171% */

	letter-spacing: 0.4px;
	text-transform: uppercase;
	border-radius: 4px;
	${(props) =>
		props.customDisabled &&
		`
      :disabled {
          color: rgba(0, 0, 0, 0.26);
          flex: none;
          order: 0;
          flex-grow: 0;
          background: transparent !important;
          border: 1px solid rgba(0, 0, 0, 0.12);
      }
    `}
`;

export const AIReportContainer = styled(Grid)`
	display: flex;
	flex-direction: column;
	max-width: 740px;
	margin: 2rem auto;
`;

export const AIReportTitle = styled.p`
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 160%;
	/* identical to box height, or 32px */

	letter-spacing: 0.15px;

	color: #000000;

	@media print {
		margin-top: -5px;
		margin-bottom: 5px;
	}
`;

export const AIReportDescription = styled.p`
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 150%;
	/* or 24px */

	letter-spacing: 0.15px;

	color: #000000;
	margin-bottom: 1rem;
`;

export const PrintContainer = styled.div`
	display: flex;
	column-gap: 9.5px;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	@media print {
		display: none;
	}
`;

export const AISection = styled.div`
	display: flex;
	column-gap: 20.5px;
`;

export const PrintButton = styled.h4`
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 22px;
	/* identical to box height, or 169% */

	letter-spacing: 0.46px;
	text-transform: uppercase;

	/* Light/Primary/Main */

	color: #1976d2;
`;

export const AiReportDescriptionList = styled.ul`
	list-style-type: none;
	@media print {
		margin-top: -10px;
	}
`;
