import React from 'react';

const SenasaLogo = () => (
	<svg width="60" height="40" viewBox="0 0 61 67" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_2652_21401)">
			<path d="M60.5535 0.711182H0.553497V66.7112H60.5535V0.711182Z" fill="#98C555" />
			<path
				d="M52.7186 18.9725C52.3969 18.2321 52.1043 17.4951 51.7758 16.7719C51.0421 15.1516 50.1448 13.6112 49.098 12.1748C47.7787 10.3727 45.9753 9.35229 43.8433 8.86441C40.2501 8.04841 36.7082 8.25112 33.1971 9.30763C28.8048 10.6321 24.4502 12.0752 20.0887 13.501C17.5477 14.3239 15.0496 15.2602 12.5429 16.1655C12.3328 16.2575 12.1047 16.301 11.8756 16.2926C11.9915 16.1844 12.1268 16.0993 12.2743 16.0418C16.788 13.5594 21.3874 11.2454 25.9884 8.93656C28.259 7.79588 30.5347 6.65863 32.9062 5.7344C35.9861 4.53187 39.1807 3.76225 42.4881 3.58015C44.1555 3.46282 45.8308 3.61478 47.4502 4.03024C50.7115 4.91496 52.6706 7.02282 53.29 10.3676C53.6408 12.2573 53.5536 14.1607 53.2661 16.0555C53.1224 16.9935 52.9376 17.9229 52.7374 18.8506C52.7408 18.8952 52.7288 18.9347 52.7186 18.9725Z"
				fill="#367ABC"
			/>
			<path
				d="M49.3478 37.4157C48.7181 37.7593 48.0936 38.1029 47.4571 38.4293C43.2034 40.6213 38.9497 42.8151 34.6652 44.947C32.4579 46.0465 30.2558 47.1511 27.9493 48.0392C25.4769 48.9738 22.8957 49.5869 20.2683 49.8636C18.198 50.0904 16.1378 50.0354 14.1085 49.4994C11.8123 48.8879 10.0208 47.6115 8.95481 45.4177C8.30803 44.0915 8.07875 42.6691 8.024 41.2089C7.94016 39.0031 8.31659 36.8574 8.80766 34.7238C8.80766 34.7032 8.83504 34.686 8.86413 34.6482C9.13105 35.3354 9.39627 35.9967 9.69399 36.6478C10.452 38.3245 11.3833 39.9166 12.4728 41.3978C13.7406 43.1157 15.4603 44.131 17.5101 44.6137C20.5592 45.3301 23.6135 45.2717 26.6694 44.6395C28.2504 44.3131 29.7767 43.7943 31.3081 43.2944C36.1418 41.7139 40.9619 40.0922 45.7836 38.4722C46.7589 38.1441 47.7223 37.7851 48.6924 37.4415C48.7946 37.3941 48.9051 37.3675 49.0176 37.3631C49.13 37.3586 49.2422 37.3765 49.3478 37.4157V37.4157Z"
				fill="#367ABC"
			/>
			<path
				d="M53.4252 33.3512C53.2336 32.5747 53.0539 31.7948 52.8469 31.0217C52.3609 29.2145 51.7107 27.4829 50.6995 25.8921C49.4196 23.877 47.5751 22.8291 45.2635 22.4958C43.1613 22.2204 41.0318 22.2314 38.9325 22.5284C35.6815 22.9407 32.4767 23.5763 29.2565 24.157C28.2299 24.3425 27.2032 24.5659 26.1766 24.6947C26.1197 24.7003 26.0624 24.7003 26.0055 24.6947C25.9898 24.6918 25.975 24.6856 25.962 24.6764C25.949 24.6672 25.9381 24.6553 25.9302 24.6414C25.908 24.5951 25.9302 24.559 25.9798 24.5401C26.0551 24.5057 26.1355 24.4765 26.2125 24.4456C29.2428 23.2328 32.2731 22.0165 35.353 20.9376C37.9093 20.0426 40.4862 19.1888 43.1246 18.5961C45.1608 18.1409 47.2038 18.0808 49.2297 18.6786C50.8809 19.1596 52.2908 20.0065 53.302 21.4272C54.1575 22.6452 54.5459 24.0247 54.6315 25.4987C54.7581 27.6976 54.3885 29.8364 53.8478 31.9528C53.7298 32.4167 53.6049 32.8771 53.4817 33.3409L53.4252 33.3512Z"
				fill="#367ABC"
			/>
			<path
				d="M31.2807 30.1285C30.4081 30.5116 29.5098 30.8311 28.6183 31.1592C25.5445 32.3336 22.4132 33.3502 19.2366 34.2051C17.8953 34.5861 16.5085 34.7809 15.1146 34.784C13.4614 34.7757 11.8352 34.363 10.3767 33.5815C8.4449 32.537 7.36522 30.8741 6.97168 28.737C6.59867 26.7116 6.80057 24.7051 7.19925 22.7089C7.38575 21.7881 7.61675 20.8759 7.88367 19.974C7.89907 19.919 7.90078 19.8554 7.96751 19.8125C8.16257 20.5443 8.34566 21.271 8.55098 21.9925C8.99448 23.6386 9.6357 25.2244 10.4605 26.715C11.7233 28.9328 13.638 30.1508 16.1276 30.5288C18.3519 30.8723 20.5866 30.7693 22.8092 30.4549C25.5983 30.0581 28.3633 29.5238 31.1353 29.0308C31.2259 29.0153 31.3183 29.0084 31.4107 28.9981C31.4963 28.95 31.6007 29.0136 31.6828 28.95C31.6874 28.9428 31.6946 28.9376 31.7029 28.9357C31.7113 28.9338 31.72 28.9352 31.7273 28.9397C31.9069 28.8933 31.9446 29.0204 31.9703 29.1476C31.9853 29.2109 31.9748 29.2776 31.9412 29.3332C31.9076 29.3888 31.8535 29.4289 31.7906 29.4448C31.7094 29.4758 31.6209 29.4822 31.536 29.4633C31.4512 29.4443 31.3737 29.4009 31.3132 29.3383C31.2739 29.6131 31.3012 29.8777 31.2807 30.1285Z"
				fill="#367ABC"
			/>
			<path
				d="M23.8804 53.6053C24.2065 53.5669 24.5363 53.5732 24.8608 53.6242C25.0695 53.6448 25.1842 53.8252 25.3022 53.9798C25.8258 54.6669 26.3471 55.3541 26.8662 56.0413C27.611 57.0273 28.3525 58.0128 29.0905 58.9978C29.1864 59.1249 29.2959 59.24 29.4139 59.3791C29.461 59.2822 29.4765 59.1728 29.4584 59.0665C29.4584 57.3486 29.4584 55.6307 29.4584 53.9128C29.4584 53.6963 29.4995 53.6156 29.7339 53.6242C30.2301 53.6431 30.728 53.6345 31.2259 53.6242C31.3834 53.6242 31.4484 53.6568 31.4484 53.8338C31.4415 56.8241 31.4415 59.8149 31.4484 62.8064C31.4484 62.9575 31.409 63.0159 31.2499 63.0108C30.9077 63.0005 30.5655 62.9919 30.2387 63.0108C30.0875 63.0195 29.9369 62.9868 29.8028 62.9163C29.6688 62.8458 29.5562 62.7401 29.4772 62.6105C28.2544 60.99 27.027 59.374 25.795 57.7626C25.6298 57.507 25.43 57.2758 25.2013 57.0754C25.1628 57.1567 25.1514 57.2483 25.1688 57.3366C25.1688 59.1232 25.1688 60.9104 25.1688 62.6981C25.1688 62.906 25.1209 62.9747 24.907 62.9678C24.3937 62.9507 23.894 62.9558 23.3876 62.9678C23.2302 62.9678 23.1874 62.9197 23.1874 62.7668C23.1874 59.7594 23.1874 56.7519 23.1874 53.7444C23.1922 53.7044 23.202 53.6652 23.2165 53.6276C23.25 53.6662 23.2742 53.7119 23.2872 53.7614C23.3003 53.8108 23.3018 53.8626 23.2918 53.9128C23.2353 55.3764 23.2832 56.8418 23.2678 58.3055C23.2678 58.5769 23.2678 58.8466 23.2678 59.1163V54.3921C23.2678 53.9145 23.4389 53.7444 23.9231 53.7393C24.0942 53.7393 24.2653 53.7393 24.4467 53.7393C24.3047 53.7238 24.1627 53.6998 24.0207 53.6929C23.9728 53.686 23.8838 53.7152 23.8804 53.6053Z"
				fill="white"
			/>
			<path
				d="M10.5204 53.4575C11.6495 53.4746 12.7448 53.8471 13.6517 54.5226C13.7902 54.6222 13.8125 54.6944 13.7047 54.8421C13.4583 55.1737 13.2273 55.519 13.0032 55.8729C12.9917 55.8991 12.9748 55.9226 12.9536 55.9418C12.9323 55.9609 12.9072 55.9753 12.88 55.9839C12.8528 55.9925 12.824 55.9952 12.7957 55.9918C12.7674 55.9883 12.7401 55.9788 12.7157 55.9639C12.098 55.7337 11.4854 55.4984 10.8524 55.3163C10.479 55.1937 10.0732 55.217 9.71621 55.3815C9.56446 55.4389 9.4333 55.5405 9.33966 55.6734C9.24603 55.8063 9.19422 55.9644 9.19092 56.1271C9.18458 56.2898 9.22877 56.4504 9.31736 56.5868C9.40595 56.7231 9.53457 56.8284 9.68541 56.8881C10.4109 57.2111 11.186 57.4035 11.9269 57.6767C12.2714 57.7904 12.6076 57.9282 12.933 58.089C13.8518 58.5837 14.1906 59.3946 14.1307 60.3961C14.0486 61.9422 13.2016 62.6981 11.5847 63.0623C10.3681 63.3371 9.22856 63.04 8.12493 62.5349C7.6937 62.3215 7.28547 62.0642 6.90665 61.767C6.8057 61.6914 6.80228 61.6433 6.88441 61.5488C7.20267 61.1777 7.51693 60.8015 7.8272 60.4202C7.90078 60.3291 7.96409 60.3085 8.05478 60.3824C8.08215 60.403 8.11466 60.415 8.14204 60.4356C9.10023 61.1777 10.2227 61.2946 11.3759 61.2946C11.5503 61.2884 11.7179 61.2256 11.8536 61.1157C11.9894 61.0057 12.086 60.8545 12.1288 60.6847C12.2383 60.2535 12.1288 59.9168 11.7746 59.7175C11.4033 59.5363 11.0123 59.3991 10.6094 59.3087C9.94092 59.1291 9.28847 58.8941 8.65878 58.606C7.30362 57.9395 6.88783 57.0513 7.24544 55.5791C7.53632 54.3766 8.38672 53.8165 9.50746 53.5589C9.83939 53.4816 10.1798 53.4476 10.5204 53.4575V53.4575Z"
				fill="white"
			/>
			<path
				d="M43.9494 53.4575C44.9983 53.4695 46.0266 53.9024 46.9814 54.5518C47.0806 54.6188 47.0926 54.6738 47.0207 54.7751C46.7624 55.1393 46.5074 55.507 46.2593 55.8832C46.1686 56.0223 46.0779 55.9897 45.9616 55.9485C45.3251 55.72 44.6937 55.4846 44.0418 55.306C43.6902 55.2021 43.3134 55.2258 42.9775 55.373C42.8257 55.4287 42.6948 55.5303 42.6029 55.6639C42.5109 55.7974 42.4625 55.9562 42.4642 56.1185C42.4572 56.2855 42.502 56.4505 42.5925 56.5908C42.6829 56.7311 42.8144 56.8398 42.9689 56.9019C43.6996 57.2163 44.4713 57.407 45.219 57.6715C45.4089 57.7402 45.6005 57.8055 45.7888 57.8828C47.0686 58.4119 47.611 59.4289 47.3664 60.7912C47.1576 61.9594 46.4184 62.6569 45.3302 62.9472C43.4207 63.4626 41.7164 62.9644 40.197 61.7447C40.1063 61.6725 40.0978 61.6192 40.1816 61.523C40.493 61.1657 40.7959 60.8032 41.0919 60.4322C41.1843 60.3171 41.251 60.3274 41.3623 60.3978C41.9697 60.7826 42.5771 61.1657 43.294 61.3169C43.7661 61.4207 44.2562 61.4089 44.7228 61.2825C45.1745 61.1588 45.4226 60.8634 45.4397 60.4425C45.4551 60.0491 45.2464 59.7553 44.7929 59.611C44.0161 59.3516 43.2359 59.1111 42.4556 58.8637C42.1486 58.7694 41.8539 58.6385 41.5778 58.4738C40.6522 57.912 40.2586 56.9655 40.4725 55.799C40.5389 55.3341 40.7333 54.897 41.0338 54.537C41.3343 54.177 41.729 53.9085 42.1733 53.7616C42.7419 53.5522 43.3438 53.4492 43.9494 53.4575V53.4575Z"
				fill="white"
			/>
			<path
				d="M21.6988 58.0771C21.2539 56.8145 20.4103 55.9676 19.0808 55.7305C18.4758 55.6058 17.8481 55.6522 17.2678 55.8646C16.6875 56.0769 16.1772 56.4468 15.7939 56.933C14.9709 57.9088 14.7861 59.0787 15.0051 60.307C15.3028 61.9751 16.7897 63.1604 18.5692 63.1827C19.6592 63.1965 20.6037 62.8495 21.3463 62.018C21.4643 61.8857 21.49 61.8015 21.3257 61.6744C21.0772 61.4849 20.8406 61.2801 20.6174 61.0611C20.5963 61.0334 20.5697 61.0103 20.5393 60.9935C20.5088 60.9766 20.4752 60.9663 20.4406 60.9632C20.406 60.9602 20.3711 60.9644 20.3382 60.9756C20.3053 60.9869 20.2751 61.0049 20.2495 61.0285C19.8064 61.3274 19.2982 61.5148 18.7677 61.5748C17.8797 61.6675 17.161 61.1745 16.966 60.3362C16.9249 60.1644 16.954 60.0957 17.149 60.0991C17.9002 60.1077 18.6514 60.0991 19.4008 60.0991H21.6799C21.7775 60.0991 21.8853 60.1232 21.8904 59.96C21.9075 59.3329 21.9143 58.6887 21.6988 58.0771ZM19.7584 58.8914C19.3118 58.8914 18.8652 58.8914 18.4187 58.8914C17.9721 58.8914 17.5255 58.8914 17.0789 58.8914C16.9078 58.8914 16.8787 58.8416 16.9078 58.6853C17.0361 57.7044 17.7633 57.1392 18.7438 57.2817C19.083 57.3393 19.3904 57.517 19.6102 57.7828C19.83 58.0485 19.9475 58.3846 19.9415 58.7299C19.9398 58.8845 19.8731 58.8931 19.7584 58.8914Z"
				fill="white"
			/>
			<path
				d="M39.155 60.7073C39.1704 59.8775 39.167 59.0478 39.1293 58.2198C39.1268 57.7243 38.9663 57.2426 38.6713 56.8453C38.3764 56.4481 37.9624 56.1561 37.4901 56.0123C36.0905 55.5295 34.7336 55.7357 33.3956 56.2631C33.2672 56.3129 33.257 56.3799 33.3015 56.495C33.3767 56.6925 33.4418 56.8935 33.5068 57.0945C33.7138 57.7198 33.7121 57.713 34.3623 57.6099C34.968 57.5154 35.5737 57.3866 36.1949 57.4725C36.4849 57.4958 36.7556 57.6278 36.9531 57.8423C37.1506 58.0569 37.2605 58.3382 37.2608 58.6303C37.2608 58.7764 37.2283 58.8227 37.0897 58.8107C36.6774 58.7781 36.265 58.7523 35.8526 58.7283C35.3153 58.6723 34.7724 58.7019 34.2442 58.8159C33.3015 59.0581 32.7265 59.771 32.6239 60.8189C32.5782 61.2448 32.6699 61.6741 32.8856 62.0437C33.1012 62.4133 33.4293 62.7036 33.8216 62.8718C34.903 63.3374 35.9399 63.2635 36.9015 62.5454C37.0179 62.4578 37.1394 62.2723 37.2711 62.3444C37.3721 62.396 37.3105 62.6107 37.3019 62.7499C37.2916 62.9354 37.3669 62.9749 37.5363 62.9715C38 62.9629 38.4654 62.956 38.9291 62.9715C39.1396 62.9801 39.1807 62.9027 39.1789 62.7103C39.1499 62.0369 39.155 61.3669 39.155 60.7073ZM37.3361 60.587C37.2232 61.2742 36.6363 61.7621 35.8646 61.817C35.7979 61.817 35.7294 61.817 35.6935 61.817C35.5149 61.831 35.3354 61.81 35.1648 61.7552C34.9897 61.7052 34.8338 61.6031 34.7178 61.4623C34.6017 61.3215 34.5308 61.1487 34.5146 60.9667C34.4895 60.7714 34.5269 60.5731 34.6213 60.4005C34.7157 60.228 34.8623 60.0899 35.0399 60.0064C35.6881 59.7469 36.4107 59.7469 37.0589 60.0064C37.3105 60.0871 37.384 60.2881 37.3413 60.5784L37.3361 60.587Z"
				fill="white"
			/>
			<path
				d="M54.623 58.321C54.5169 56.969 53.8171 56.1616 52.5269 55.8712C51.3381 55.6226 50.1014 55.7554 48.9919 56.2509C48.8208 56.3368 48.7523 56.4227 48.8345 56.6202C48.9409 56.8788 49.029 57.1446 49.098 57.4156C49.1442 57.5874 49.228 57.6286 49.4025 57.6132C50.1741 57.5299 50.9505 57.5006 51.7261 57.5256C52.3832 57.5599 52.7169 57.9344 52.7203 58.5924C52.7203 58.7745 52.6689 58.8106 52.4927 58.8089C51.6834 58.8003 50.8758 58.7092 50.0664 58.7985C49.329 58.8793 48.7096 59.1421 48.3554 59.8379C47.582 61.3342 48.5419 63.0091 50.2255 63.1225C50.6837 63.1723 51.1471 63.1163 51.5803 62.9588C52.0136 62.8013 52.4052 62.5464 52.7254 62.2137C52.7442 62.4559 52.7613 62.6312 52.7699 62.8047C52.7699 62.9198 52.8161 62.9765 52.941 62.9765C53.4646 62.9765 53.9865 62.9765 54.51 62.9765C54.6435 62.9765 54.6811 62.9318 54.6811 62.8047C54.6418 61.3015 54.7325 59.8087 54.623 58.321ZM52.7408 60.4855C52.6604 61.1727 52.3079 61.5867 51.6235 61.7842C51.4221 61.8538 51.2077 61.8765 50.9963 61.8506C50.785 61.8247 50.5823 61.7509 50.4035 61.6348C50.2669 61.5489 50.1558 61.4276 50.082 61.2837C50.0083 61.1398 49.9745 60.9786 49.9843 60.8171C49.98 60.6195 50.0419 60.4263 50.1601 60.2683C50.2784 60.1104 50.446 59.9969 50.6362 59.9461C51.257 59.7313 51.9372 59.7714 52.5286 60.0578C52.6082 60.0924 52.6726 60.1548 52.7097 60.2335C52.7469 60.3122 52.7543 60.4018 52.7305 60.4855H52.7408Z"
				fill="white"
			/>
			<path
				d="M31.2807 30.1285C31.1626 29.8467 31.2259 29.553 31.2465 29.2695C31.255 29.1647 31.3885 29.2489 31.4176 29.3039C31.4672 29.4241 31.5579 29.4018 31.6486 29.4087C31.7075 29.4212 31.7689 29.4102 31.8199 29.3782C31.871 29.3462 31.9077 29.2955 31.9223 29.2369C31.9305 29.1724 31.9162 29.1071 31.8819 29.052C31.8476 28.9969 31.7954 28.9554 31.7341 28.9345L34.6823 28.3882L35.4266 28.2525C35.5521 28.2147 35.686 28.2147 35.8116 28.2525C35.827 28.3315 35.7756 28.3384 35.7414 28.3522L31.3526 30.1182C31.329 30.1241 31.3049 30.1276 31.2807 30.1285Z"
				fill="#3A79BB"
			/>
			<path
				d="M23.8804 53.6053L24.5939 53.6654C24.6521 53.6654 24.7291 53.6654 24.7291 53.741C24.7291 53.8166 24.6503 53.8166 24.5939 53.8166H23.8085C23.7449 53.8147 23.6815 53.8259 23.6223 53.8494C23.5631 53.873 23.5093 53.9085 23.4643 53.9537C23.4193 53.9989 23.3839 54.0529 23.3604 54.1123C23.3369 54.1718 23.3258 54.2354 23.3277 54.2993C23.3277 55.4091 23.3277 56.5188 23.3277 57.6286V59.4771H23.2267V53.6362C23.4423 53.5969 23.6621 53.5865 23.8804 53.6053V53.6053Z"
				fill="white"
			/>
			<path
				d="M31.6828 28.9501C31.6041 29.0394 31.5031 28.9931 31.4107 28.9982C31.4951 28.9567 31.5894 28.9401 31.6828 28.9501V28.9501Z"
				fill="#65A95F"
			/>
		</g>
		<defs>
			<clipPath id="clip0_2652_21401">
				<rect
					width="60"
					height="66"
					fill="white"
					transform="translate(0.553497 0.711182)"
				/>
			</clipPath>
		</defs>
	</svg>
);

export const SensaLogoBig = () => {
	return (
		<svg
			width="60"
			height="60"
			viewBox="0 0 90.83000004291534 100.06700003147125"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_2652_21401)">
				<path d="M90.83 1.067H0.83V100.067H90.83V1.067Z" fill="#98C555" />
				<path
					d="M79.078 28.459C78.595 27.348 78.156 26.243 77.664 25.158C76.563 22.727 75.217 20.417 73.647 18.262C71.668 15.559 68.963 14.028 65.765 13.297C60.375 12.073 55.062 12.377 49.796 13.961C43.207 15.948 36.675 18.113 30.133 20.252C26.322 21.486 22.574 22.89 18.814 24.248C18.499 24.386 18.157 24.451 17.813 24.439C17.987 24.277 18.19 24.149 18.411 24.063C25.182 20.339 32.081 16.868 38.983 13.405C42.389 11.694 45.802 9.988 49.359 8.602C53.979 6.798 58.771 5.643 63.732 5.37C66.233 5.194 68.746 5.422 71.175 6.045C76.067 7.372 79.006 10.534 79.935 15.551C80.461 18.386 80.33 21.241 79.899 24.083C79.684 25.49 79.406 26.884 79.106 28.276C79.111 28.343 79.093 28.402 79.078 28.459Z"
					fill="#367ABC"
				/>
				<path
					d="M74.022 56.124C73.077 56.639 72.14 57.154 71.186 57.644C64.805 60.932 58.425 64.223 51.998 67.421C48.687 69.07 45.384 70.727 41.924 72.059C38.215 73.461 34.344 74.38 30.402 74.795C27.297 75.136 24.207 75.053 21.163 74.249C17.718 73.332 15.031 71.417 13.432 68.127C12.462 66.137 12.118 64.004 12.036 61.813C11.91 58.505 12.475 55.286 13.211 52.086C13.211 52.055 13.253 52.029 13.296 51.972C13.697 53.003 14.094 53.995 14.541 54.972C15.678 57.487 17.075 59.875 18.709 62.097C20.611 64.674 23.19 66.197 26.265 66.921C30.839 67.995 35.42 67.908 40.004 66.959C42.376 66.47 44.665 65.691 46.962 64.942C54.213 62.571 61.443 60.138 68.675 57.708C70.138 57.216 71.583 56.678 73.039 56.162C73.192 56.091 73.358 56.051 73.526 56.045C73.695 56.038 73.863 56.065 74.022 56.124V56.124Z"
					fill="#367ABC"
				/>
				<path
					d="M80.138 50.027C79.85 48.862 79.581 47.692 79.27 46.533C78.541 43.822 77.566 41.224 76.049 38.838C74.129 35.816 71.363 34.244 67.895 33.744C64.742 33.331 61.548 33.347 58.399 33.793C53.522 34.411 48.715 35.364 43.885 36.236C42.345 36.514 40.805 36.849 39.265 37.042C39.18 37.05 39.094 37.05 39.008 37.042C38.985 37.038 38.963 37.028 38.943 37.015C38.924 37.001 38.907 36.983 38.895 36.962C38.862 36.893 38.895 36.839 38.97 36.81C39.083 36.759 39.203 36.715 39.319 36.668C43.864 34.849 48.41 33.025 53.029 31.406C56.864 30.064 60.729 28.783 64.687 27.894C67.741 27.211 70.806 27.121 73.845 28.018C76.321 28.739 78.436 30.01 79.953 32.141C81.236 33.968 81.819 36.037 81.947 38.248C82.137 41.546 81.583 44.755 80.772 47.929C80.595 48.625 80.407 49.316 80.223 50.011L80.138 50.027Z"
					fill="#367ABC"
				/>
				<path
					d="M46.921 45.193C45.612 45.767 44.265 46.247 42.927 46.739C38.317 48.5 33.62 50.025 28.855 51.308C26.843 51.879 24.763 52.171 22.672 52.176C20.192 52.164 17.753 51.544 15.565 50.372C12.667 48.805 11.048 46.311 10.458 43.105C9.898 40.067 10.201 37.058 10.799 34.063C11.079 32.682 11.425 31.314 11.826 29.961C11.849 29.879 11.851 29.783 11.951 29.719C12.244 30.816 12.518 31.907 12.826 32.989C13.492 35.458 14.454 37.837 15.691 40.072C17.585 43.399 20.457 45.226 24.191 45.793C27.528 46.308 30.88 46.154 34.214 45.682C38.397 45.087 42.545 44.286 46.703 43.546C46.839 43.523 46.977 43.513 47.116 43.497C47.244 43.425 47.401 43.52 47.524 43.425C47.531 43.414 47.542 43.406 47.554 43.404C47.567 43.401 47.58 43.403 47.591 43.41C47.86 43.34 47.917 43.531 47.955 43.721C47.978 43.816 47.962 43.916 47.912 44C47.861 44.083 47.78 44.143 47.686 44.167C47.564 44.214 47.431 44.223 47.304 44.195C47.177 44.166 47.061 44.101 46.97 44.007C46.911 44.42 46.952 44.817 46.921 45.193Z"
					fill="#367ABC"
				/>
				<path
					d="M35.821 80.408C36.31 80.35 36.804 80.36 37.291 80.436C37.604 80.467 37.776 80.738 37.953 80.97C38.739 82 39.521 83.031 40.299 84.062C41.416 85.541 42.529 87.019 43.636 88.497C43.78 88.687 43.944 88.86 44.121 89.069C44.191 88.923 44.215 88.759 44.188 88.6C44.188 86.023 44.188 83.446 44.188 80.869C44.188 80.544 44.249 80.423 44.601 80.436C45.345 80.465 46.092 80.452 46.839 80.436C47.075 80.436 47.173 80.485 47.173 80.751C47.162 85.236 47.162 89.722 47.173 94.21C47.173 94.436 47.114 94.524 46.875 94.516C46.362 94.501 45.848 94.488 45.358 94.516C45.131 94.529 44.905 94.48 44.704 94.374C44.503 94.269 44.334 94.11 44.216 93.916C42.382 91.485 40.541 89.061 38.693 86.644C38.445 86.26 38.145 85.914 37.802 85.613C37.744 85.735 37.727 85.872 37.753 86.005C37.753 88.685 37.753 91.366 37.753 94.047C37.753 94.359 37.681 94.462 37.361 94.452C36.591 94.426 35.841 94.434 35.081 94.452C34.845 94.452 34.781 94.38 34.781 94.15C34.781 89.639 34.781 85.128 34.781 80.617C34.788 80.557 34.803 80.498 34.825 80.441C34.875 80.499 34.911 80.568 34.931 80.642C34.95 80.716 34.953 80.794 34.938 80.869C34.853 83.065 34.925 85.263 34.902 87.458C34.902 87.865 34.902 88.27 34.902 88.674V81.588C34.902 80.872 35.158 80.617 35.885 80.609C36.141 80.609 36.398 80.609 36.67 80.609C36.457 80.586 36.244 80.55 36.031 80.539C35.959 80.529 35.826 80.573 35.821 80.408Z"
					fill="white"
				/>
				<path
					d="M15.781 80.186C17.474 80.212 19.117 80.771 20.478 81.784C20.685 81.933 20.719 82.042 20.557 82.263C20.187 82.761 19.841 83.278 19.505 83.809C19.488 83.849 19.462 83.884 19.43 83.913C19.398 83.941 19.361 83.963 19.32 83.976C19.279 83.989 19.236 83.993 19.194 83.988C19.151 83.982 19.11 83.968 19.074 83.946C18.147 83.601 17.228 83.248 16.279 82.974C15.718 82.791 15.11 82.826 14.574 83.072C14.347 83.158 14.15 83.311 14.009 83.51C13.869 83.709 13.791 83.947 13.786 84.191C13.777 84.435 13.843 84.676 13.976 84.88C14.109 85.085 14.302 85.243 14.528 85.332C15.616 85.817 16.779 86.105 17.89 86.515C18.407 86.686 18.911 86.892 19.399 87.133C20.778 87.876 21.286 89.092 21.196 90.594C21.073 92.913 19.802 94.047 17.377 94.593C15.552 95.006 13.843 94.56 12.187 93.802C11.541 93.482 10.928 93.096 10.36 92.651C10.209 92.537 10.203 92.465 10.327 92.323C10.804 91.767 11.275 91.202 11.741 90.63C11.851 90.494 11.946 90.463 12.082 90.574C12.123 90.605 12.172 90.623 12.213 90.653C13.65 91.767 15.334 91.942 17.064 91.942C17.325 91.933 17.577 91.838 17.78 91.674C17.984 91.509 18.129 91.282 18.193 91.027C18.357 90.38 18.193 89.875 17.662 89.576C17.105 89.304 16.518 89.099 15.914 88.963C14.911 88.694 13.933 88.341 12.988 87.909C10.955 86.909 10.332 85.577 10.868 83.369C11.304 81.565 12.58 80.725 14.261 80.338C14.759 80.222 15.27 80.171 15.781 80.186V80.186Z"
					fill="white"
				/>
				<path
					d="M65.924 80.186C67.497 80.204 69.04 80.854 70.472 81.828C70.621 81.928 70.639 82.011 70.531 82.163C70.144 82.709 69.761 83.26 69.389 83.825C69.253 84.033 69.117 83.985 68.942 83.923C67.988 83.58 67.041 83.227 66.063 82.959C65.535 82.803 64.97 82.839 64.466 83.059C64.239 83.143 64.042 83.295 63.904 83.496C63.766 83.696 63.694 83.934 63.696 84.178C63.686 84.428 63.753 84.676 63.889 84.886C64.024 85.097 64.222 85.26 64.453 85.353C65.549 85.824 66.707 86.111 67.829 86.507C68.113 86.61 68.401 86.708 68.683 86.824C70.603 87.618 71.416 89.143 71.05 91.187C70.736 92.939 69.628 93.985 67.995 94.421C65.131 95.194 62.575 94.447 60.296 92.617C60.159 92.509 60.147 92.429 60.272 92.285C60.74 91.749 61.194 91.205 61.638 90.648C61.776 90.476 61.876 90.491 62.043 90.597C62.955 91.174 63.866 91.749 64.941 91.975C65.649 92.131 66.384 92.113 67.084 91.924C67.762 91.738 68.134 91.295 68.16 90.664C68.183 90.074 67.87 89.633 67.189 89.416C66.024 89.027 64.854 88.667 63.683 88.296C63.223 88.154 62.781 87.958 62.367 87.711C60.978 86.868 60.388 85.448 60.709 83.698C60.808 83.001 61.1 82.346 61.551 81.805C62.001 81.266 62.593 80.863 63.26 80.642C64.113 80.328 65.016 80.174 65.924 80.186V80.186Z"
					fill="white"
				/>
				<path
					d="M32.548 87.116C31.881 85.222 30.615 83.951 28.621 83.596C27.714 83.409 26.772 83.478 25.902 83.797C25.031 84.115 24.266 84.67 23.691 85.4C22.456 86.863 22.179 88.618 22.508 90.46C22.954 92.963 25.185 94.741 27.854 94.774C29.489 94.795 30.906 94.274 32.019 93.027C32.196 92.829 32.235 92.702 31.989 92.512C31.616 92.227 31.261 91.92 30.926 91.592C30.894 91.55 30.855 91.515 30.809 91.49C30.763 91.465 30.713 91.449 30.661 91.445C30.609 91.44 30.557 91.447 30.507 91.463C30.458 91.48 30.413 91.507 30.374 91.543C29.71 91.991 28.947 92.272 28.152 92.362C26.82 92.501 25.742 91.762 25.449 90.504C25.387 90.247 25.431 90.144 25.724 90.149C26.85 90.162 27.977 90.149 29.101 90.149H32.52C32.666 90.149 32.828 90.185 32.836 89.94C32.861 88.999 32.871 88.033 32.548 87.116ZM29.638 88.337C28.968 88.337 28.298 88.337 27.628 88.337C26.958 88.337 26.288 88.337 25.618 88.337C25.362 88.337 25.318 88.262 25.362 88.028C25.554 86.557 26.645 85.709 28.116 85.923C28.624 86.009 29.086 86.276 29.415 86.674C29.745 87.073 29.921 87.577 29.912 88.095C29.91 88.327 29.81 88.34 29.638 88.337Z"
					fill="white"
				/>
				<path
					d="M58.733 91.061C58.756 89.816 58.751 88.572 58.694 87.33C58.69 86.586 58.449 85.864 58.007 85.268C57.565 84.672 56.944 84.234 56.235 84.018C54.136 83.294 52.1 83.604 50.093 84.395C49.901 84.469 49.885 84.57 49.952 84.742C50.065 85.039 50.163 85.34 50.26 85.642C50.571 86.58 50.568 86.57 51.543 86.415C52.452 86.273 53.361 86.08 54.292 86.209C54.727 86.244 55.133 86.442 55.43 86.763C55.726 87.085 55.891 87.507 55.891 87.945C55.891 88.165 55.842 88.234 55.635 88.216C55.016 88.167 54.398 88.128 53.779 88.092C52.973 88.008 52.159 88.053 51.366 88.224C49.952 88.587 49.09 89.656 48.936 91.228C48.867 91.867 49.005 92.511 49.328 93.066C49.652 93.62 50.144 94.055 50.732 94.308C52.355 95.006 53.91 94.895 55.352 93.818C55.527 93.687 55.709 93.408 55.907 93.517C56.058 93.594 55.966 93.916 55.953 94.125C55.937 94.403 56.05 94.462 56.304 94.457C57 94.444 57.698 94.434 58.394 94.457C58.709 94.47 58.771 94.354 58.768 94.065C58.725 93.055 58.733 92.05 58.733 91.061ZM56.004 90.881C55.835 91.911 54.954 92.643 53.797 92.725C53.697 92.725 53.594 92.725 53.54 92.725C53.272 92.746 53.003 92.715 52.747 92.633C52.485 92.558 52.251 92.405 52.077 92.193C51.903 91.982 51.796 91.723 51.772 91.45C51.734 91.157 51.79 90.86 51.932 90.601C52.074 90.342 52.293 90.135 52.56 90.01C53.532 89.62 54.616 89.62 55.588 90.01C55.966 90.131 56.076 90.432 56.012 90.868L56.004 90.881Z"
					fill="white"
				/>
				<path
					d="M81.934 87.481C81.775 85.454 80.726 84.242 78.79 83.807C77.007 83.434 75.152 83.633 73.488 84.376C73.231 84.505 73.128 84.634 73.252 84.93C73.411 85.318 73.544 85.717 73.647 86.123C73.716 86.381 73.842 86.443 74.104 86.42C75.261 86.295 76.426 86.251 77.589 86.288C78.575 86.34 79.075 86.902 79.08 87.889C79.08 88.162 79.003 88.216 78.739 88.213C77.525 88.2 76.314 88.064 75.1 88.198C73.993 88.319 73.064 88.713 72.533 89.757C71.373 92.001 72.813 94.514 75.338 94.684C76.026 94.758 76.721 94.674 77.37 94.438C78.02 94.202 78.608 93.82 79.088 93.321C79.116 93.684 79.142 93.947 79.155 94.207C79.155 94.38 79.224 94.465 79.412 94.465C80.197 94.465 80.98 94.465 81.765 94.465C81.965 94.465 82.022 94.398 82.022 94.207C81.963 91.952 82.099 89.713 81.934 87.481ZM79.111 90.728C78.991 91.759 78.462 92.38 77.435 92.676C77.133 92.781 76.812 92.815 76.494 92.776C76.177 92.737 75.873 92.626 75.605 92.452C75.4 92.323 75.234 92.141 75.123 91.926C75.012 91.71 74.962 91.468 74.976 91.226C74.97 90.929 75.063 90.639 75.24 90.402C75.418 90.166 75.669 89.995 75.954 89.919C76.885 89.597 77.906 89.657 78.793 90.087C78.912 90.139 79.009 90.232 79.065 90.35C79.12 90.468 79.131 90.603 79.096 90.728H79.111Z"
					fill="white"
				/>
				<path
					d="M46.921 45.193C46.744 44.77 46.839 44.33 46.87 43.904C46.883 43.747 47.083 43.873 47.126 43.956C47.201 44.136 47.337 44.103 47.473 44.113C47.561 44.132 47.653 44.115 47.73 44.067C47.806 44.019 47.862 43.943 47.883 43.855C47.896 43.759 47.874 43.661 47.823 43.578C47.771 43.495 47.693 43.433 47.601 43.402L52.023 42.582L53.14 42.379C53.328 42.322 53.529 42.322 53.717 42.379C53.74 42.497 53.663 42.508 53.612 42.528L47.029 45.177C46.993 45.186 46.957 45.191 46.921 45.193Z"
					fill="#3A79BB"
				/>
				<path
					d="M35.821 80.408L36.891 80.498C36.978 80.498 37.094 80.498 37.094 80.612C37.094 80.725 36.975 80.725 36.891 80.725H35.713C35.617 80.722 35.522 80.739 35.433 80.774C35.345 80.809 35.264 80.863 35.196 80.931C35.129 80.998 35.076 81.079 35.041 81.168C35.005 81.258 34.989 81.353 34.992 81.449C34.992 83.114 34.992 84.778 34.992 86.443V89.216H34.84V80.454C35.163 80.395 35.493 80.38 35.821 80.408V80.408Z"
					fill="white"
				/>
				<path
					d="M47.524 43.425C47.406 43.559 47.255 43.49 47.116 43.497C47.243 43.435 47.384 43.41 47.524 43.425V43.425Z"
					fill="#65A95F"
				/>
			</g>
			<defs>
				<clipPath id="clip0_2652_21401">
					<path
						width="60"
						height="66"
						fill="white"
						transform="translate(0.553497 0.711182)"
						d="M0 0H90V99H0V0z"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default SenasaLogo;
