/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from '../API';
type GeneratedSubscription<InputType, OutputType> = string & {
	__generatedSubscriptionInput: InputType;
	__generatedSubscriptionOutput: OutputType;
};

export const onCreateSecretary = /* GraphQL */ `subscription OnCreateSecretary {
  onCreateSecretary {
    id
    name
    email
    firstTime
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnCreateSecretarySubscriptionVariables,
	APITypes.OnCreateSecretarySubscription
>;
export const onUpdateSecretary = /* GraphQL */ `subscription OnUpdateSecretary {
  onUpdateSecretary {
    id
    name
    email
    firstTime
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnUpdateSecretarySubscriptionVariables,
	APITypes.OnUpdateSecretarySubscription
>;
export const onDeleteSecretary = /* GraphQL */ `subscription OnDeleteSecretary {
  onDeleteSecretary {
    id
    name
    email
    firstTime
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnDeleteSecretarySubscriptionVariables,
	APITypes.OnDeleteSecretarySubscription
>;
export const onCreateEmailDistributionLists =
	/* GraphQL */ `subscription OnCreateEmailDistributionLists {
  onCreateEmailDistributionLists {
    id
    email
    specialty
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnCreateEmailDistributionListsSubscriptionVariables,
		APITypes.OnCreateEmailDistributionListsSubscription
	>;
export const onUpdateEmailDistributionLists =
	/* GraphQL */ `subscription OnUpdateEmailDistributionLists {
  onUpdateEmailDistributionLists {
    id
    email
    specialty
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnUpdateEmailDistributionListsSubscriptionVariables,
		APITypes.OnUpdateEmailDistributionListsSubscription
	>;
export const onDeleteEmailDistributionLists =
	/* GraphQL */ `subscription OnDeleteEmailDistributionLists {
  onDeleteEmailDistributionLists {
    id
    email
    specialty
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnDeleteEmailDistributionListsSubscriptionVariables,
		APITypes.OnDeleteEmailDistributionListsSubscription
	>;
export const onCreateSecretaryHospitalDoctor =
	/* GraphQL */ `subscription OnCreateSecretaryHospitalDoctor {
  onCreateSecretaryHospitalDoctor {
    id
    hospitalDoctorID
    secretaryID
    hasHistoryPermission
    hasSurgeryPermission
    hasPrescriptionsPermission
    hasFilesPermission
    hasNotesPermission
    hasClaimsPermission
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    secretary {
      id
      name
      email
      firstTime
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    hospitalDoctor {
      id
      doctorID
      hospitalID
      lastWaitingListID
      hospitalLogo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospital {
        id
        name
        type
        province
        town
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnCreateSecretaryHospitalDoctorSubscriptionVariables,
		APITypes.OnCreateSecretaryHospitalDoctorSubscription
	>;
export const onUpdateSecretaryHospitalDoctor =
	/* GraphQL */ `subscription OnUpdateSecretaryHospitalDoctor {
  onUpdateSecretaryHospitalDoctor {
    id
    hospitalDoctorID
    secretaryID
    hasHistoryPermission
    hasSurgeryPermission
    hasPrescriptionsPermission
    hasFilesPermission
    hasNotesPermission
    hasClaimsPermission
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    secretary {
      id
      name
      email
      firstTime
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    hospitalDoctor {
      id
      doctorID
      hospitalID
      lastWaitingListID
      hospitalLogo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospital {
        id
        name
        type
        province
        town
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnUpdateSecretaryHospitalDoctorSubscriptionVariables,
		APITypes.OnUpdateSecretaryHospitalDoctorSubscription
	>;
export const onDeleteSecretaryHospitalDoctor =
	/* GraphQL */ `subscription OnDeleteSecretaryHospitalDoctor {
  onDeleteSecretaryHospitalDoctor {
    id
    hospitalDoctorID
    secretaryID
    hasHistoryPermission
    hasSurgeryPermission
    hasPrescriptionsPermission
    hasFilesPermission
    hasNotesPermission
    hasClaimsPermission
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    secretary {
      id
      name
      email
      firstTime
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    hospitalDoctor {
      id
      doctorID
      hospitalID
      lastWaitingListID
      hospitalLogo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospital {
        id
        name
        type
        province
        town
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnDeleteSecretaryHospitalDoctorSubscriptionVariables,
		APITypes.OnDeleteSecretaryHospitalDoctorSubscription
	>;
export const onCreateSecretaryHospitalDoctorClient =
	/* GraphQL */ `subscription OnCreateSecretaryHospitalDoctorClient {
  onCreateSecretaryHospitalDoctorClient {
    id
    hospitalDoctorID
    secretaryID
    clientID
    note
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnCreateSecretaryHospitalDoctorClientSubscriptionVariables,
		APITypes.OnCreateSecretaryHospitalDoctorClientSubscription
	>;
export const onUpdateSecretaryHospitalDoctorClient =
	/* GraphQL */ `subscription OnUpdateSecretaryHospitalDoctorClient {
  onUpdateSecretaryHospitalDoctorClient {
    id
    hospitalDoctorID
    secretaryID
    clientID
    note
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnUpdateSecretaryHospitalDoctorClientSubscriptionVariables,
		APITypes.OnUpdateSecretaryHospitalDoctorClientSubscription
	>;
export const onDeleteSecretaryHospitalDoctorClient =
	/* GraphQL */ `subscription OnDeleteSecretaryHospitalDoctorClient {
  onDeleteSecretaryHospitalDoctorClient {
    id
    hospitalDoctorID
    secretaryID
    clientID
    note
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnDeleteSecretaryHospitalDoctorClientSubscriptionVariables,
		APITypes.OnDeleteSecretaryHospitalDoctorClientSubscription
	>;
export const onCreateMemberHospitalDoctorClient =
	/* GraphQL */ `subscription OnCreateMemberHospitalDoctorClient {
  onCreateMemberHospitalDoctorClient {
    id
    hospitalDoctorID
    memberID
    ownerID
    clientID
    note
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    owner {
      id
      doctorID
      hospitalID
      lastWaitingListID
      hospitalLogo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospital {
        id
        name
        type
        province
        town
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnCreateMemberHospitalDoctorClientSubscriptionVariables,
		APITypes.OnCreateMemberHospitalDoctorClientSubscription
	>;
export const onUpdateMemberHospitalDoctorClient =
	/* GraphQL */ `subscription OnUpdateMemberHospitalDoctorClient {
  onUpdateMemberHospitalDoctorClient {
    id
    hospitalDoctorID
    memberID
    ownerID
    clientID
    note
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    owner {
      id
      doctorID
      hospitalID
      lastWaitingListID
      hospitalLogo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospital {
        id
        name
        type
        province
        town
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnUpdateMemberHospitalDoctorClientSubscriptionVariables,
		APITypes.OnUpdateMemberHospitalDoctorClientSubscription
	>;
export const onDeleteMemberHospitalDoctorClient =
	/* GraphQL */ `subscription OnDeleteMemberHospitalDoctorClient {
  onDeleteMemberHospitalDoctorClient {
    id
    hospitalDoctorID
    memberID
    ownerID
    clientID
    note
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    owner {
      id
      doctorID
      hospitalID
      lastWaitingListID
      hospitalLogo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospital {
        id
        name
        type
        province
        town
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnDeleteMemberHospitalDoctorClientSubscriptionVariables,
		APITypes.OnDeleteMemberHospitalDoctorClientSubscription
	>;
export const onCreateClient = /* GraphQL */ `subscription OnCreateClient {
  onCreateClient {
    id
    identificationName
    identificationData
    actualInsurance
    name
    lastName
    cellphoneNumber
    email
    bornDate
    parentOrTutorCedula
    parentOrTutorID
    gender
    phoneNumber
    addressStreet
    city
    isAdult
    sector
    bloodType
    company
    vitalSignsHeight
    vitalSignsWeight
    bodyMass
    vitalSignsBloodPressure
    heartRate
    respiratoryRate
    bodyTemperature
    oxygenSaturation
    occupation
    profileImage
    heartBeat
    weight
    height
    bloodPressure
    updateDateTime
    createdAt
    updatedAt
    code
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnCreateClientSubscriptionVariables,
	APITypes.OnCreateClientSubscription
>;
export const onUpdateClient = /* GraphQL */ `subscription OnUpdateClient {
  onUpdateClient {
    id
    identificationName
    identificationData
    actualInsurance
    name
    lastName
    cellphoneNumber
    email
    bornDate
    parentOrTutorCedula
    parentOrTutorID
    gender
    phoneNumber
    addressStreet
    city
    isAdult
    sector
    bloodType
    company
    vitalSignsHeight
    vitalSignsWeight
    bodyMass
    vitalSignsBloodPressure
    heartRate
    respiratoryRate
    bodyTemperature
    oxygenSaturation
    occupation
    profileImage
    heartBeat
    weight
    height
    bloodPressure
    updateDateTime
    createdAt
    updatedAt
    code
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnUpdateClientSubscriptionVariables,
	APITypes.OnUpdateClientSubscription
>;
export const onDeleteClient = /* GraphQL */ `subscription OnDeleteClient {
  onDeleteClient {
    id
    identificationName
    identificationData
    actualInsurance
    name
    lastName
    cellphoneNumber
    email
    bornDate
    parentOrTutorCedula
    parentOrTutorID
    gender
    phoneNumber
    addressStreet
    city
    isAdult
    sector
    bloodType
    company
    vitalSignsHeight
    vitalSignsWeight
    bodyMass
    vitalSignsBloodPressure
    heartRate
    respiratoryRate
    bodyTemperature
    oxygenSaturation
    occupation
    profileImage
    heartBeat
    weight
    height
    bloodPressure
    updateDateTime
    createdAt
    updatedAt
    code
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnDeleteClientSubscriptionVariables,
	APITypes.OnDeleteClientSubscription
>;
export const onCreateClientImageSection = /* GraphQL */ `subscription OnCreateClientImageSection {
  onCreateClientImageSection {
    id
    clientHospitalDoctorID
    date
    description
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnCreateClientImageSectionSubscriptionVariables,
	APITypes.OnCreateClientImageSectionSubscription
>;
export const onUpdateClientImageSection = /* GraphQL */ `subscription OnUpdateClientImageSection {
  onUpdateClientImageSection {
    id
    clientHospitalDoctorID
    date
    description
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnUpdateClientImageSectionSubscriptionVariables,
	APITypes.OnUpdateClientImageSectionSubscription
>;
export const onDeleteClientImageSection = /* GraphQL */ `subscription OnDeleteClientImageSection {
  onDeleteClientImageSection {
    id
    clientHospitalDoctorID
    date
    description
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnDeleteClientImageSectionSubscriptionVariables,
	APITypes.OnDeleteClientImageSectionSubscription
>;
export const onCreatePosibleClient = /* GraphQL */ `subscription OnCreatePosibleClient {
  onCreatePosibleClient {
    id
    phoneNumber
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnCreatePosibleClientSubscriptionVariables,
	APITypes.OnCreatePosibleClientSubscription
>;
export const onUpdatePosibleClient = /* GraphQL */ `subscription OnUpdatePosibleClient {
  onUpdatePosibleClient {
    id
    phoneNumber
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnUpdatePosibleClientSubscriptionVariables,
	APITypes.OnUpdatePosibleClientSubscription
>;
export const onDeletePosibleClient = /* GraphQL */ `subscription OnDeletePosibleClient {
  onDeletePosibleClient {
    id
    phoneNumber
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnDeletePosibleClientSubscriptionVariables,
	APITypes.OnDeletePosibleClientSubscription
>;
export const onCreateClientImage = /* GraphQL */ `subscription OnCreateClientImage {
  onCreateClientImage {
    id
    pathFile
    description
    ClientImageDateID
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnCreateClientImageSubscriptionVariables,
	APITypes.OnCreateClientImageSubscription
>;
export const onUpdateClientImage = /* GraphQL */ `subscription OnUpdateClientImage {
  onUpdateClientImage {
    id
    pathFile
    description
    ClientImageDateID
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnUpdateClientImageSubscriptionVariables,
	APITypes.OnUpdateClientImageSubscription
>;
export const onDeleteClientImage = /* GraphQL */ `subscription OnDeleteClientImage {
  onDeleteClientImage {
    id
    pathFile
    description
    ClientImageDateID
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnDeleteClientImageSubscriptionVariables,
	APITypes.OnDeleteClientImageSubscription
>;
export const onCreateConsultationDiagnostic =
	/* GraphQL */ `subscription OnCreateConsultationDiagnostic {
  onCreateConsultationDiagnostic {
    id
    clientConsultationID
    name
    code
    type
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnCreateConsultationDiagnosticSubscriptionVariables,
		APITypes.OnCreateConsultationDiagnosticSubscription
	>;
export const onUpdateConsultationDiagnostic =
	/* GraphQL */ `subscription OnUpdateConsultationDiagnostic {
  onUpdateConsultationDiagnostic {
    id
    clientConsultationID
    name
    code
    type
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnUpdateConsultationDiagnosticSubscriptionVariables,
		APITypes.OnUpdateConsultationDiagnosticSubscription
	>;
export const onDeleteConsultationDiagnostic =
	/* GraphQL */ `subscription OnDeleteConsultationDiagnostic {
  onDeleteConsultationDiagnostic {
    id
    clientConsultationID
    name
    code
    type
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnDeleteConsultationDiagnosticSubscriptionVariables,
		APITypes.OnDeleteConsultationDiagnosticSubscription
	>;
export const onCreateClientConsultation = /* GraphQL */ `subscription OnCreateClientConsultation {
  onCreateClientConsultation {
    id
    clientHospitalDoctorID
    doctorID
    visitReason
    visitDescription
    physicTestNote
    height
    weight
    bodyMass
    bloodPressure
    heartRate
    respiratoryRate
    bodyTemperature
    oxygenSaturation
    clientMedicinePrescriptionID
    clientAnalisisPrescriptionID
    clientFreePrescriptionID
    treatment
    procedureName
    procedureDescription
    createdAt
    updatedAt
    status
    _version
    _deleted
    _lastChangedAt
    doctor {
      id
      name
      specialty
      exequator
      email
      firstTime
      studyPlace
      isBeenReview
      isFirstAddSecretary
      hasTrialOnCreation
      title
      phone
      code
      password
      isAdmin
      daysOfTrial
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnCreateClientConsultationSubscriptionVariables,
	APITypes.OnCreateClientConsultationSubscription
>;
export const onUpdateClientConsultation = /* GraphQL */ `subscription OnUpdateClientConsultation {
  onUpdateClientConsultation {
    id
    clientHospitalDoctorID
    doctorID
    visitReason
    visitDescription
    physicTestNote
    height
    weight
    bodyMass
    bloodPressure
    heartRate
    respiratoryRate
    bodyTemperature
    oxygenSaturation
    clientMedicinePrescriptionID
    clientAnalisisPrescriptionID
    clientFreePrescriptionID
    treatment
    procedureName
    procedureDescription
    createdAt
    updatedAt
    status
    _version
    _deleted
    _lastChangedAt
    doctor {
      id
      name
      specialty
      exequator
      email
      firstTime
      studyPlace
      isBeenReview
      isFirstAddSecretary
      hasTrialOnCreation
      title
      phone
      code
      password
      isAdmin
      daysOfTrial
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnUpdateClientConsultationSubscriptionVariables,
	APITypes.OnUpdateClientConsultationSubscription
>;
export const onDeleteClientConsultation = /* GraphQL */ `subscription OnDeleteClientConsultation {
  onDeleteClientConsultation {
    id
    clientHospitalDoctorID
    doctorID
    visitReason
    visitDescription
    physicTestNote
    height
    weight
    bodyMass
    bloodPressure
    heartRate
    respiratoryRate
    bodyTemperature
    oxygenSaturation
    clientMedicinePrescriptionID
    clientAnalisisPrescriptionID
    clientFreePrescriptionID
    treatment
    procedureName
    procedureDescription
    createdAt
    updatedAt
    status
    _version
    _deleted
    _lastChangedAt
    doctor {
      id
      name
      specialty
      exequator
      email
      firstTime
      studyPlace
      isBeenReview
      isFirstAddSecretary
      hasTrialOnCreation
      title
      phone
      code
      password
      isAdmin
      daysOfTrial
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnDeleteClientConsultationSubscriptionVariables,
	APITypes.OnDeleteClientConsultationSubscription
>;
export const onCreateMedicine = /* GraphQL */ `subscription OnCreateMedicine {
  onCreateMedicine {
    id
    name
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnCreateMedicineSubscriptionVariables,
	APITypes.OnCreateMedicineSubscription
>;
export const onUpdateMedicine = /* GraphQL */ `subscription OnUpdateMedicine {
  onUpdateMedicine {
    id
    name
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnUpdateMedicineSubscriptionVariables,
	APITypes.OnUpdateMedicineSubscription
>;
export const onDeleteMedicine = /* GraphQL */ `subscription OnDeleteMedicine {
  onDeleteMedicine {
    id
    name
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnDeleteMedicineSubscriptionVariables,
	APITypes.OnDeleteMedicineSubscription
>;
export const onCreateClientAllergy = /* GraphQL */ `subscription OnCreateClientAllergy {
  onCreateClientAllergy {
    id
    clientID
    name
    status
    note
    diagnosedDate
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnCreateClientAllergySubscriptionVariables,
	APITypes.OnCreateClientAllergySubscription
>;
export const onUpdateClientAllergy = /* GraphQL */ `subscription OnUpdateClientAllergy {
  onUpdateClientAllergy {
    id
    clientID
    name
    status
    note
    diagnosedDate
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnUpdateClientAllergySubscriptionVariables,
	APITypes.OnUpdateClientAllergySubscription
>;
export const onDeleteClientAllergy = /* GraphQL */ `subscription OnDeleteClientAllergy {
  onDeleteClientAllergy {
    id
    clientID
    name
    status
    note
    diagnosedDate
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnDeleteClientAllergySubscriptionVariables,
	APITypes.OnDeleteClientAllergySubscription
>;
export const onCreateClientDisease = /* GraphQL */ `subscription OnCreateClientDisease {
  onCreateClientDisease {
    id
    clientID
    name
    status
    note
    diagnosedDate
    updatedAt
    createdAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnCreateClientDiseaseSubscriptionVariables,
	APITypes.OnCreateClientDiseaseSubscription
>;
export const onUpdateClientDisease = /* GraphQL */ `subscription OnUpdateClientDisease {
  onUpdateClientDisease {
    id
    clientID
    name
    status
    note
    diagnosedDate
    updatedAt
    createdAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnUpdateClientDiseaseSubscriptionVariables,
	APITypes.OnUpdateClientDiseaseSubscription
>;
export const onDeleteClientDisease = /* GraphQL */ `subscription OnDeleteClientDisease {
  onDeleteClientDisease {
    id
    clientID
    name
    status
    note
    diagnosedDate
    updatedAt
    createdAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnDeleteClientDiseaseSubscriptionVariables,
	APITypes.OnDeleteClientDiseaseSubscription
>;
export const onCreateClientProcedure = /* GraphQL */ `subscription OnCreateClientProcedure {
  onCreateClientProcedure {
    id
    clientID
    name
    note
    realizationDate
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnCreateClientProcedureSubscriptionVariables,
	APITypes.OnCreateClientProcedureSubscription
>;
export const onUpdateClientProcedure = /* GraphQL */ `subscription OnUpdateClientProcedure {
  onUpdateClientProcedure {
    id
    clientID
    name
    note
    realizationDate
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnUpdateClientProcedureSubscriptionVariables,
	APITypes.OnUpdateClientProcedureSubscription
>;
export const onDeleteClientProcedure = /* GraphQL */ `subscription OnDeleteClientProcedure {
  onDeleteClientProcedure {
    id
    clientID
    name
    note
    realizationDate
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnDeleteClientProcedureSubscriptionVariables,
	APITypes.OnDeleteClientProcedureSubscription
>;
export const onCreateClientHospitalization =
	/* GraphQL */ `subscription OnCreateClientHospitalization {
  onCreateClientHospitalization {
    id
    clientID
    name
    note
    date
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnCreateClientHospitalizationSubscriptionVariables,
		APITypes.OnCreateClientHospitalizationSubscription
	>;
export const onUpdateClientHospitalization =
	/* GraphQL */ `subscription OnUpdateClientHospitalization {
  onUpdateClientHospitalization {
    id
    clientID
    name
    note
    date
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnUpdateClientHospitalizationSubscriptionVariables,
		APITypes.OnUpdateClientHospitalizationSubscription
	>;
export const onDeleteClientHospitalization =
	/* GraphQL */ `subscription OnDeleteClientHospitalization {
  onDeleteClientHospitalization {
    id
    clientID
    name
    note
    date
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnDeleteClientHospitalizationSubscriptionVariables,
		APITypes.OnDeleteClientHospitalizationSubscription
	>;
export const onCreateClientFamilyHistory = /* GraphQL */ `subscription OnCreateClientFamilyHistory {
  onCreateClientFamilyHistory {
    id
    clientID
    familyMember
    mellitusDiabetes
    mellitusDiabetesNote
    arterialHypertension
    arterialHypertensionNote
    heartDisease
    heartDiseaseNote
    nephropathy
    nephropathyNote
    neuropathies
    neuropathiesNote
    epilepsy
    epilepsyNote
    rheumatoidArthritis
    rheumatoidArthritisNote
    psychiatricDiseases
    psychiatricDiseasesNote
    cancer
    cancerNote
    other
    otherNote
    updatedAt
    createdAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnCreateClientFamilyHistorySubscriptionVariables,
	APITypes.OnCreateClientFamilyHistorySubscription
>;
export const onUpdateClientFamilyHistory = /* GraphQL */ `subscription OnUpdateClientFamilyHistory {
  onUpdateClientFamilyHistory {
    id
    clientID
    familyMember
    mellitusDiabetes
    mellitusDiabetesNote
    arterialHypertension
    arterialHypertensionNote
    heartDisease
    heartDiseaseNote
    nephropathy
    nephropathyNote
    neuropathies
    neuropathiesNote
    epilepsy
    epilepsyNote
    rheumatoidArthritis
    rheumatoidArthritisNote
    psychiatricDiseases
    psychiatricDiseasesNote
    cancer
    cancerNote
    other
    otherNote
    updatedAt
    createdAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnUpdateClientFamilyHistorySubscriptionVariables,
	APITypes.OnUpdateClientFamilyHistorySubscription
>;
export const onDeleteClientFamilyHistory = /* GraphQL */ `subscription OnDeleteClientFamilyHistory {
  onDeleteClientFamilyHistory {
    id
    clientID
    familyMember
    mellitusDiabetes
    mellitusDiabetesNote
    arterialHypertension
    arterialHypertensionNote
    heartDisease
    heartDiseaseNote
    nephropathy
    nephropathyNote
    neuropathies
    neuropathiesNote
    epilepsy
    epilepsyNote
    rheumatoidArthritis
    rheumatoidArthritisNote
    psychiatricDiseases
    psychiatricDiseasesNote
    cancer
    cancerNote
    other
    otherNote
    updatedAt
    createdAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnDeleteClientFamilyHistorySubscriptionVariables,
	APITypes.OnDeleteClientFamilyHistorySubscription
>;
export const onCreateClientLifeStyle = /* GraphQL */ `subscription OnCreateClientLifeStyle {
  onCreateClientLifeStyle {
    id
    clientID
    lifeStyle
    name
    description
    updatedAt
    createdAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnCreateClientLifeStyleSubscriptionVariables,
	APITypes.OnCreateClientLifeStyleSubscription
>;
export const onUpdateClientLifeStyle = /* GraphQL */ `subscription OnUpdateClientLifeStyle {
  onUpdateClientLifeStyle {
    id
    clientID
    lifeStyle
    name
    description
    updatedAt
    createdAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnUpdateClientLifeStyleSubscriptionVariables,
	APITypes.OnUpdateClientLifeStyleSubscription
>;
export const onDeleteClientLifeStyle = /* GraphQL */ `subscription OnDeleteClientLifeStyle {
  onDeleteClientLifeStyle {
    id
    clientID
    lifeStyle
    name
    description
    updatedAt
    createdAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnDeleteClientLifeStyleSubscriptionVariables,
	APITypes.OnDeleteClientLifeStyleSubscription
>;
export const onCreateClientPrescription = /* GraphQL */ `subscription OnCreateClientPrescription {
  onCreateClientPrescription {
    id
    clientHospitalDoctorID
    creationDate
    type
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnCreateClientPrescriptionSubscriptionVariables,
	APITypes.OnCreateClientPrescriptionSubscription
>;
export const onUpdateClientPrescription = /* GraphQL */ `subscription OnUpdateClientPrescription {
  onUpdateClientPrescription {
    id
    clientHospitalDoctorID
    creationDate
    type
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnUpdateClientPrescriptionSubscriptionVariables,
	APITypes.OnUpdateClientPrescriptionSubscription
>;
export const onDeleteClientPrescription = /* GraphQL */ `subscription OnDeleteClientPrescription {
  onDeleteClientPrescription {
    id
    clientHospitalDoctorID
    creationDate
    type
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnDeleteClientPrescriptionSubscriptionVariables,
	APITypes.OnDeleteClientPrescriptionSubscription
>;
export const onCreateClientPrescriptionMedicine =
	/* GraphQL */ `subscription OnCreateClientPrescriptionMedicine {
  onCreateClientPrescriptionMedicine {
    id
    clientPrescriptionID
    medicineID
    name
    take
    indication
    duration
    description
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnCreateClientPrescriptionMedicineSubscriptionVariables,
		APITypes.OnCreateClientPrescriptionMedicineSubscription
	>;
export const onUpdateClientPrescriptionMedicine =
	/* GraphQL */ `subscription OnUpdateClientPrescriptionMedicine {
  onUpdateClientPrescriptionMedicine {
    id
    clientPrescriptionID
    medicineID
    name
    take
    indication
    duration
    description
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnUpdateClientPrescriptionMedicineSubscriptionVariables,
		APITypes.OnUpdateClientPrescriptionMedicineSubscription
	>;
export const onDeleteClientPrescriptionMedicine =
	/* GraphQL */ `subscription OnDeleteClientPrescriptionMedicine {
  onDeleteClientPrescriptionMedicine {
    id
    clientPrescriptionID
    medicineID
    name
    take
    indication
    duration
    description
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnDeleteClientPrescriptionMedicineSubscriptionVariables,
		APITypes.OnDeleteClientPrescriptionMedicineSubscription
	>;
export const onCreateClientFreePrescription =
	/* GraphQL */ `subscription OnCreateClientFreePrescription {
  onCreateClientFreePrescription {
    id
    clientPrescriptionID
    description
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnCreateClientFreePrescriptionSubscriptionVariables,
		APITypes.OnCreateClientFreePrescriptionSubscription
	>;
export const onUpdateClientFreePrescription =
	/* GraphQL */ `subscription OnUpdateClientFreePrescription {
  onUpdateClientFreePrescription {
    id
    clientPrescriptionID
    description
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnUpdateClientFreePrescriptionSubscriptionVariables,
		APITypes.OnUpdateClientFreePrescriptionSubscription
	>;
export const onDeleteClientFreePrescription =
	/* GraphQL */ `subscription OnDeleteClientFreePrescription {
  onDeleteClientFreePrescription {
    id
    clientPrescriptionID
    description
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnDeleteClientFreePrescriptionSubscriptionVariables,
		APITypes.OnDeleteClientFreePrescriptionSubscription
	>;
export const onCreateClientPrescriptionAnalisis =
	/* GraphQL */ `subscription OnCreateClientPrescriptionAnalisis {
  onCreateClientPrescriptionAnalisis {
    id
    clientPrescriptionID
    name
    description
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnCreateClientPrescriptionAnalisisSubscriptionVariables,
		APITypes.OnCreateClientPrescriptionAnalisisSubscription
	>;
export const onUpdateClientPrescriptionAnalisis =
	/* GraphQL */ `subscription OnUpdateClientPrescriptionAnalisis {
  onUpdateClientPrescriptionAnalisis {
    id
    clientPrescriptionID
    name
    description
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnUpdateClientPrescriptionAnalisisSubscriptionVariables,
		APITypes.OnUpdateClientPrescriptionAnalisisSubscription
	>;
export const onDeleteClientPrescriptionAnalisis =
	/* GraphQL */ `subscription OnDeleteClientPrescriptionAnalisis {
  onDeleteClientPrescriptionAnalisis {
    id
    clientPrescriptionID
    name
    description
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnDeleteClientPrescriptionAnalisisSubscriptionVariables,
		APITypes.OnDeleteClientPrescriptionAnalisisSubscription
	>;
export const onCreateDoctorLabImage = /* GraphQL */ `subscription OnCreateDoctorLabImage {
  onCreateDoctorLabImage {
    id
    doctorID
    labImageID
    updatedAt
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    labImage {
      id
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnCreateDoctorLabImageSubscriptionVariables,
	APITypes.OnCreateDoctorLabImageSubscription
>;
export const onUpdateDoctorLabImage = /* GraphQL */ `subscription OnUpdateDoctorLabImage {
  onUpdateDoctorLabImage {
    id
    doctorID
    labImageID
    updatedAt
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    labImage {
      id
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnUpdateDoctorLabImageSubscriptionVariables,
	APITypes.OnUpdateDoctorLabImageSubscription
>;
export const onDeleteDoctorLabImage = /* GraphQL */ `subscription OnDeleteDoctorLabImage {
  onDeleteDoctorLabImage {
    id
    doctorID
    labImageID
    updatedAt
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    labImage {
      id
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnDeleteDoctorLabImageSubscriptionVariables,
	APITypes.OnDeleteDoctorLabImageSubscription
>;
export const onCreateLabImage = /* GraphQL */ `subscription OnCreateLabImage {
  onCreateLabImage {
    id
    name
    description
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnCreateLabImageSubscriptionVariables,
	APITypes.OnCreateLabImageSubscription
>;
export const onUpdateLabImage = /* GraphQL */ `subscription OnUpdateLabImage {
  onUpdateLabImage {
    id
    name
    description
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnUpdateLabImageSubscriptionVariables,
	APITypes.OnUpdateLabImageSubscription
>;
export const onDeleteLabImage = /* GraphQL */ `subscription OnDeleteLabImage {
  onDeleteLabImage {
    id
    name
    description
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnDeleteLabImageSubscriptionVariables,
	APITypes.OnDeleteLabImageSubscription
>;
export const onCreatePharmacyMedicine = /* GraphQL */ `subscription OnCreatePharmacyMedicine {
  onCreatePharmacyMedicine {
    id
    name
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnCreatePharmacyMedicineSubscriptionVariables,
	APITypes.OnCreatePharmacyMedicineSubscription
>;
export const onUpdatePharmacyMedicine = /* GraphQL */ `subscription OnUpdatePharmacyMedicine {
  onUpdatePharmacyMedicine {
    id
    name
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnUpdatePharmacyMedicineSubscriptionVariables,
	APITypes.OnUpdatePharmacyMedicineSubscription
>;
export const onDeletePharmacyMedicine = /* GraphQL */ `subscription OnDeletePharmacyMedicine {
  onDeletePharmacyMedicine {
    id
    name
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnDeletePharmacyMedicineSubscriptionVariables,
	APITypes.OnDeletePharmacyMedicineSubscription
>;
export const onCreatePharmacyMedicineMeasure =
	/* GraphQL */ `subscription OnCreatePharmacyMedicineMeasure {
  onCreatePharmacyMedicineMeasure {
    id
    pharmacyMedicineID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnCreatePharmacyMedicineMeasureSubscriptionVariables,
		APITypes.OnCreatePharmacyMedicineMeasureSubscription
	>;
export const onUpdatePharmacyMedicineMeasure =
	/* GraphQL */ `subscription OnUpdatePharmacyMedicineMeasure {
  onUpdatePharmacyMedicineMeasure {
    id
    pharmacyMedicineID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnUpdatePharmacyMedicineMeasureSubscriptionVariables,
		APITypes.OnUpdatePharmacyMedicineMeasureSubscription
	>;
export const onDeletePharmacyMedicineMeasure =
	/* GraphQL */ `subscription OnDeletePharmacyMedicineMeasure {
  onDeletePharmacyMedicineMeasure {
    id
    pharmacyMedicineID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnDeletePharmacyMedicineMeasureSubscriptionVariables,
		APITypes.OnDeletePharmacyMedicineMeasureSubscription
	>;
export const onCreateTemplate = /* GraphQL */ `subscription OnCreateTemplate {
  onCreateTemplate {
    id
    doctorID
    templateName
    templateType
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnCreateTemplateSubscriptionVariables,
	APITypes.OnCreateTemplateSubscription
>;
export const onUpdateTemplate = /* GraphQL */ `subscription OnUpdateTemplate {
  onUpdateTemplate {
    id
    doctorID
    templateName
    templateType
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnUpdateTemplateSubscriptionVariables,
	APITypes.OnUpdateTemplateSubscription
>;
export const onDeleteTemplate = /* GraphQL */ `subscription OnDeleteTemplate {
  onDeleteTemplate {
    id
    doctorID
    templateName
    templateType
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnDeleteTemplateSubscriptionVariables,
	APITypes.OnDeleteTemplateSubscription
>;
export const onCreateTemplateMedicine = /* GraphQL */ `subscription OnCreateTemplateMedicine {
  onCreateTemplateMedicine {
    id
    templateID
    medicineID
    take
    indication
    duration
    description
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnCreateTemplateMedicineSubscriptionVariables,
	APITypes.OnCreateTemplateMedicineSubscription
>;
export const onUpdateTemplateMedicine = /* GraphQL */ `subscription OnUpdateTemplateMedicine {
  onUpdateTemplateMedicine {
    id
    templateID
    medicineID
    take
    indication
    duration
    description
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnUpdateTemplateMedicineSubscriptionVariables,
	APITypes.OnUpdateTemplateMedicineSubscription
>;
export const onDeleteTemplateMedicine = /* GraphQL */ `subscription OnDeleteTemplateMedicine {
  onDeleteTemplateMedicine {
    id
    templateID
    medicineID
    take
    indication
    duration
    description
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnDeleteTemplateMedicineSubscriptionVariables,
	APITypes.OnDeleteTemplateMedicineSubscription
>;
export const onCreateDoctorRecentDiagnosis =
	/* GraphQL */ `subscription OnCreateDoctorRecentDiagnosis {
  onCreateDoctorRecentDiagnosis {
    id
    doctorID
    name
    code
    type
    updateDateTime
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnCreateDoctorRecentDiagnosisSubscriptionVariables,
		APITypes.OnCreateDoctorRecentDiagnosisSubscription
	>;
export const onUpdateDoctorRecentDiagnosis =
	/* GraphQL */ `subscription OnUpdateDoctorRecentDiagnosis {
  onUpdateDoctorRecentDiagnosis {
    id
    doctorID
    name
    code
    type
    updateDateTime
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnUpdateDoctorRecentDiagnosisSubscriptionVariables,
		APITypes.OnUpdateDoctorRecentDiagnosisSubscription
	>;
export const onDeleteDoctorRecentDiagnosis =
	/* GraphQL */ `subscription OnDeleteDoctorRecentDiagnosis {
  onDeleteDoctorRecentDiagnosis {
    id
    doctorID
    name
    code
    type
    updateDateTime
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnDeleteDoctorRecentDiagnosisSubscriptionVariables,
		APITypes.OnDeleteDoctorRecentDiagnosisSubscription
	>;
export const onCreateTemplateLabImage = /* GraphQL */ `subscription OnCreateTemplateLabImage {
  onCreateTemplateLabImage {
    id
    templateID
    name
    description
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnCreateTemplateLabImageSubscriptionVariables,
	APITypes.OnCreateTemplateLabImageSubscription
>;
export const onUpdateTemplateLabImage = /* GraphQL */ `subscription OnUpdateTemplateLabImage {
  onUpdateTemplateLabImage {
    id
    templateID
    name
    description
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnUpdateTemplateLabImageSubscriptionVariables,
	APITypes.OnUpdateTemplateLabImageSubscription
>;
export const onDeleteTemplateLabImage = /* GraphQL */ `subscription OnDeleteTemplateLabImage {
  onDeleteTemplateLabImage {
    id
    templateID
    name
    description
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnDeleteTemplateLabImageSubscriptionVariables,
	APITypes.OnDeleteTemplateLabImageSubscription
>;
export const onCreateDoctorMedicine = /* GraphQL */ `subscription OnCreateDoctorMedicine {
  onCreateDoctorMedicine {
    id
    doctorID
    medicineID
    isDeleted
    updatedAt
    _version
    _deleted
    _lastChangedAt
    createdAt
    medicine {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnCreateDoctorMedicineSubscriptionVariables,
	APITypes.OnCreateDoctorMedicineSubscription
>;
export const onUpdateDoctorMedicine = /* GraphQL */ `subscription OnUpdateDoctorMedicine {
  onUpdateDoctorMedicine {
    id
    doctorID
    medicineID
    isDeleted
    updatedAt
    _version
    _deleted
    _lastChangedAt
    createdAt
    medicine {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnUpdateDoctorMedicineSubscriptionVariables,
	APITypes.OnUpdateDoctorMedicineSubscription
>;
export const onDeleteDoctorMedicine = /* GraphQL */ `subscription OnDeleteDoctorMedicine {
  onDeleteDoctorMedicine {
    id
    doctorID
    medicineID
    isDeleted
    updatedAt
    _version
    _deleted
    _lastChangedAt
    createdAt
    medicine {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnDeleteDoctorMedicineSubscriptionVariables,
	APITypes.OnDeleteDoctorMedicineSubscription
>;
export const onCreateRecentDoctorMedicinePrescription =
	/* GraphQL */ `subscription OnCreateRecentDoctorMedicinePrescription {
  onCreateRecentDoctorMedicinePrescription {
    id
    doctorID
    medicineID
    take
    indication
    duration
    description
    updateDateTime
    createdAt
    updatedAt
    status
    _version
    _deleted
    _lastChangedAt
    medicine {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnCreateRecentDoctorMedicinePrescriptionSubscriptionVariables,
		APITypes.OnCreateRecentDoctorMedicinePrescriptionSubscription
	>;
export const onUpdateRecentDoctorMedicinePrescription =
	/* GraphQL */ `subscription OnUpdateRecentDoctorMedicinePrescription {
  onUpdateRecentDoctorMedicinePrescription {
    id
    doctorID
    medicineID
    take
    indication
    duration
    description
    updateDateTime
    createdAt
    updatedAt
    status
    _version
    _deleted
    _lastChangedAt
    medicine {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnUpdateRecentDoctorMedicinePrescriptionSubscriptionVariables,
		APITypes.OnUpdateRecentDoctorMedicinePrescriptionSubscription
	>;
export const onDeleteRecentDoctorMedicinePrescription =
	/* GraphQL */ `subscription OnDeleteRecentDoctorMedicinePrescription {
  onDeleteRecentDoctorMedicinePrescription {
    id
    doctorID
    medicineID
    take
    indication
    duration
    description
    updateDateTime
    createdAt
    updatedAt
    status
    _version
    _deleted
    _lastChangedAt
    medicine {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnDeleteRecentDoctorMedicinePrescriptionSubscriptionVariables,
		APITypes.OnDeleteRecentDoctorMedicinePrescriptionSubscription
	>;
export const onCreateRecentDoctorLabImagePrescription =
	/* GraphQL */ `subscription OnCreateRecentDoctorLabImagePrescription {
  onCreateRecentDoctorLabImagePrescription {
    id
    doctorID
    labImageID
    name
    description
    updateDateTime
    createdAt
    updatedAt
    status
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnCreateRecentDoctorLabImagePrescriptionSubscriptionVariables,
		APITypes.OnCreateRecentDoctorLabImagePrescriptionSubscription
	>;
export const onUpdateRecentDoctorLabImagePrescription =
	/* GraphQL */ `subscription OnUpdateRecentDoctorLabImagePrescription {
  onUpdateRecentDoctorLabImagePrescription {
    id
    doctorID
    labImageID
    name
    description
    updateDateTime
    createdAt
    updatedAt
    status
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnUpdateRecentDoctorLabImagePrescriptionSubscriptionVariables,
		APITypes.OnUpdateRecentDoctorLabImagePrescriptionSubscription
	>;
export const onDeleteRecentDoctorLabImagePrescription =
	/* GraphQL */ `subscription OnDeleteRecentDoctorLabImagePrescription {
  onDeleteRecentDoctorLabImagePrescription {
    id
    doctorID
    labImageID
    name
    description
    updateDateTime
    createdAt
    updatedAt
    status
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnDeleteRecentDoctorLabImagePrescriptionSubscriptionVariables,
		APITypes.OnDeleteRecentDoctorLabImagePrescriptionSubscription
	>;
export const onCreateTemplateAnalisis = /* GraphQL */ `subscription OnCreateTemplateAnalisis {
  onCreateTemplateAnalisis {
    id
    templateID
    analisisID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnCreateTemplateAnalisisSubscriptionVariables,
	APITypes.OnCreateTemplateAnalisisSubscription
>;
export const onUpdateTemplateAnalisis = /* GraphQL */ `subscription OnUpdateTemplateAnalisis {
  onUpdateTemplateAnalisis {
    id
    templateID
    analisisID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnUpdateTemplateAnalisisSubscriptionVariables,
	APITypes.OnUpdateTemplateAnalisisSubscription
>;
export const onDeleteTemplateAnalisis = /* GraphQL */ `subscription OnDeleteTemplateAnalisis {
  onDeleteTemplateAnalisis {
    id
    templateID
    analisisID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnDeleteTemplateAnalisisSubscriptionVariables,
	APITypes.OnDeleteTemplateAnalisisSubscription
>;
export const onCreateAnalisis = /* GraphQL */ `subscription OnCreateAnalisis {
  onCreateAnalisis {
    id
    analisisName
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnCreateAnalisisSubscriptionVariables,
	APITypes.OnCreateAnalisisSubscription
>;
export const onUpdateAnalisis = /* GraphQL */ `subscription OnUpdateAnalisis {
  onUpdateAnalisis {
    id
    analisisName
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnUpdateAnalisisSubscriptionVariables,
	APITypes.OnUpdateAnalisisSubscription
>;
export const onDeleteAnalisis = /* GraphQL */ `subscription OnDeleteAnalisis {
  onDeleteAnalisis {
    id
    analisisName
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnDeleteAnalisisSubscriptionVariables,
	APITypes.OnDeleteAnalisisSubscription
>;
export const onCreateClientActiveDisease = /* GraphQL */ `subscription OnCreateClientActiveDisease {
  onCreateClientActiveDisease {
    id
    clientID
    name
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnCreateClientActiveDiseaseSubscriptionVariables,
	APITypes.OnCreateClientActiveDiseaseSubscription
>;
export const onUpdateClientActiveDisease = /* GraphQL */ `subscription OnUpdateClientActiveDisease {
  onUpdateClientActiveDisease {
    id
    clientID
    name
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnUpdateClientActiveDiseaseSubscriptionVariables,
	APITypes.OnUpdateClientActiveDiseaseSubscription
>;
export const onDeleteClientActiveDisease = /* GraphQL */ `subscription OnDeleteClientActiveDisease {
  onDeleteClientActiveDisease {
    id
    clientID
    name
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnDeleteClientActiveDiseaseSubscriptionVariables,
	APITypes.OnDeleteClientActiveDiseaseSubscription
>;
export const onCreateClientHistory = /* GraphQL */ `subscription OnCreateClientHistory {
  onCreateClientHistory {
    id
    clientHospitalDoctorID
    title
    treatment
    endModifyDate
    authorizationNumber
    description
    authorizationDetail
    personalPathologicalHistory
    familyPathologicalHistory
    physicalTest
    createdDate
    insuranceID
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnCreateClientHistorySubscriptionVariables,
	APITypes.OnCreateClientHistorySubscription
>;
export const onUpdateClientHistory = /* GraphQL */ `subscription OnUpdateClientHistory {
  onUpdateClientHistory {
    id
    clientHospitalDoctorID
    title
    treatment
    endModifyDate
    authorizationNumber
    description
    authorizationDetail
    personalPathologicalHistory
    familyPathologicalHistory
    physicalTest
    createdDate
    insuranceID
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnUpdateClientHistorySubscriptionVariables,
	APITypes.OnUpdateClientHistorySubscription
>;
export const onDeleteClientHistory = /* GraphQL */ `subscription OnDeleteClientHistory {
  onDeleteClientHistory {
    id
    clientHospitalDoctorID
    title
    treatment
    endModifyDate
    authorizationNumber
    description
    authorizationDetail
    personalPathologicalHistory
    familyPathologicalHistory
    physicalTest
    createdDate
    insuranceID
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnDeleteClientHistorySubscriptionVariables,
	APITypes.OnDeleteClientHistorySubscription
>;
export const onCreateClientNotes = /* GraphQL */ `subscription OnCreateClientNotes {
  onCreateClientNotes {
    id
    clientHospitalDoctorID
    title
    noteBody
    endModifyDate
    createdDate
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnCreateClientNotesSubscriptionVariables,
	APITypes.OnCreateClientNotesSubscription
>;
export const onUpdateClientNotes = /* GraphQL */ `subscription OnUpdateClientNotes {
  onUpdateClientNotes {
    id
    clientHospitalDoctorID
    title
    noteBody
    endModifyDate
    createdDate
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnUpdateClientNotesSubscriptionVariables,
	APITypes.OnUpdateClientNotesSubscription
>;
export const onDeleteClientNotes = /* GraphQL */ `subscription OnDeleteClientNotes {
  onDeleteClientNotes {
    id
    clientHospitalDoctorID
    title
    noteBody
    endModifyDate
    createdDate
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnDeleteClientNotesSubscriptionVariables,
	APITypes.OnDeleteClientNotesSubscription
>;
export const onCreateClientProcedureSurgery =
	/* GraphQL */ `subscription OnCreateClientProcedureSurgery {
  onCreateClientProcedureSurgery {
    id
    clientHospitalDoctorID
    title
    description
    endModifyDate
    createdDate
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnCreateClientProcedureSurgerySubscriptionVariables,
		APITypes.OnCreateClientProcedureSurgerySubscription
	>;
export const onUpdateClientProcedureSurgery =
	/* GraphQL */ `subscription OnUpdateClientProcedureSurgery {
  onUpdateClientProcedureSurgery {
    id
    clientHospitalDoctorID
    title
    description
    endModifyDate
    createdDate
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnUpdateClientProcedureSurgerySubscriptionVariables,
		APITypes.OnUpdateClientProcedureSurgerySubscription
	>;
export const onDeleteClientProcedureSurgery =
	/* GraphQL */ `subscription OnDeleteClientProcedureSurgery {
  onDeleteClientProcedureSurgery {
    id
    clientHospitalDoctorID
    title
    description
    endModifyDate
    createdDate
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnDeleteClientProcedureSurgerySubscriptionVariables,
		APITypes.OnDeleteClientProcedureSurgerySubscription
	>;
export const onCreateDoctor = /* GraphQL */ `subscription OnCreateDoctor {
  onCreateDoctor {
    id
    name
    specialty
    exequator
    email
    firstTime
    studyPlace
    isBeenReview
    isFirstAddSecretary
    hasTrialOnCreation
    title
    phone
    code
    password
    isAdmin
    daysOfTrial
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnCreateDoctorSubscriptionVariables,
	APITypes.OnCreateDoctorSubscription
>;
export const onUpdateDoctor = /* GraphQL */ `subscription OnUpdateDoctor {
  onUpdateDoctor {
    id
    name
    specialty
    exequator
    email
    firstTime
    studyPlace
    isBeenReview
    isFirstAddSecretary
    hasTrialOnCreation
    title
    phone
    code
    password
    isAdmin
    daysOfTrial
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnUpdateDoctorSubscriptionVariables,
	APITypes.OnUpdateDoctorSubscription
>;
export const onDeleteDoctor = /* GraphQL */ `subscription OnDeleteDoctor {
  onDeleteDoctor {
    id
    name
    specialty
    exequator
    email
    firstTime
    studyPlace
    isBeenReview
    isFirstAddSecretary
    hasTrialOnCreation
    title
    phone
    code
    password
    isAdmin
    daysOfTrial
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnDeleteDoctorSubscriptionVariables,
	APITypes.OnDeleteDoctorSubscription
>;
export const onCreatePlan = /* GraphQL */ `subscription OnCreatePlan {
  onCreatePlan {
    id
    refID
    title
    subTitle
    stripePlanKey
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnCreatePlanSubscriptionVariables,
	APITypes.OnCreatePlanSubscription
>;
export const onUpdatePlan = /* GraphQL */ `subscription OnUpdatePlan {
  onUpdatePlan {
    id
    refID
    title
    subTitle
    stripePlanKey
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnUpdatePlanSubscriptionVariables,
	APITypes.OnUpdatePlanSubscription
>;
export const onDeletePlan = /* GraphQL */ `subscription OnDeletePlan {
  onDeletePlan {
    id
    refID
    title
    subTitle
    stripePlanKey
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnDeletePlanSubscriptionVariables,
	APITypes.OnDeletePlanSubscription
>;
export const onCreateDoctorStripeCustomerSubscription =
	/* GraphQL */ `subscription OnCreateDoctorStripeCustomerSubscription {
  onCreateDoctorStripeCustomerSubscription {
    id
    doctorID
    currentPlanID
    stripeCustomerID
    stripeSubscriptionID
    defaultCard
    nextPaymentDate
    cancelAtPeriodEnd
    trialsEndsAt
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnCreateDoctorStripeCustomerSubscriptionSubscriptionVariables,
		APITypes.OnCreateDoctorStripeCustomerSubscriptionSubscription
	>;
export const onUpdateDoctorStripeCustomerSubscription =
	/* GraphQL */ `subscription OnUpdateDoctorStripeCustomerSubscription {
  onUpdateDoctorStripeCustomerSubscription {
    id
    doctorID
    currentPlanID
    stripeCustomerID
    stripeSubscriptionID
    defaultCard
    nextPaymentDate
    cancelAtPeriodEnd
    trialsEndsAt
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnUpdateDoctorStripeCustomerSubscriptionSubscriptionVariables,
		APITypes.OnUpdateDoctorStripeCustomerSubscriptionSubscription
	>;
export const onDeleteDoctorStripeCustomerSubscription =
	/* GraphQL */ `subscription OnDeleteDoctorStripeCustomerSubscription {
  onDeleteDoctorStripeCustomerSubscription {
    id
    doctorID
    currentPlanID
    stripeCustomerID
    stripeSubscriptionID
    defaultCard
    nextPaymentDate
    cancelAtPeriodEnd
    trialsEndsAt
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnDeleteDoctorStripeCustomerSubscriptionSubscriptionVariables,
		APITypes.OnDeleteDoctorStripeCustomerSubscriptionSubscription
	>;
export const onCreateDoctorStripeCards = /* GraphQL */ `subscription OnCreateDoctorStripeCards {
  onCreateDoctorStripeCards {
    id
    DoctorID
    cardLastDigits
    stripePaymentMethodID
    defaultCard
    cardBrand
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnCreateDoctorStripeCardsSubscriptionVariables,
	APITypes.OnCreateDoctorStripeCardsSubscription
>;
export const onUpdateDoctorStripeCards = /* GraphQL */ `subscription OnUpdateDoctorStripeCards {
  onUpdateDoctorStripeCards {
    id
    DoctorID
    cardLastDigits
    stripePaymentMethodID
    defaultCard
    cardBrand
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnUpdateDoctorStripeCardsSubscriptionVariables,
	APITypes.OnUpdateDoctorStripeCardsSubscription
>;
export const onDeleteDoctorStripeCards = /* GraphQL */ `subscription OnDeleteDoctorStripeCards {
  onDeleteDoctorStripeCards {
    id
    DoctorID
    cardLastDigits
    stripePaymentMethodID
    defaultCard
    cardBrand
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnDeleteDoctorStripeCardsSubscriptionVariables,
	APITypes.OnDeleteDoctorStripeCardsSubscription
>;
export const onCreateHospital = /* GraphQL */ `subscription OnCreateHospital {
  onCreateHospital {
    id
    name
    type
    province
    town
    sector
    phone
    address
    pssCode
    rnc
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnCreateHospitalSubscriptionVariables,
	APITypes.OnCreateHospitalSubscription
>;
export const onUpdateHospital = /* GraphQL */ `subscription OnUpdateHospital {
  onUpdateHospital {
    id
    name
    type
    province
    town
    sector
    phone
    address
    pssCode
    rnc
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnUpdateHospitalSubscriptionVariables,
	APITypes.OnUpdateHospitalSubscription
>;
export const onDeleteHospital = /* GraphQL */ `subscription OnDeleteHospital {
  onDeleteHospital {
    id
    name
    type
    province
    town
    sector
    phone
    address
    pssCode
    rnc
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnDeleteHospitalSubscriptionVariables,
	APITypes.OnDeleteHospitalSubscription
>;
export const onCreateHospitalDoctor = /* GraphQL */ `subscription OnCreateHospitalDoctor {
  onCreateHospitalDoctor {
    id
    doctorID
    hospitalID
    lastWaitingListID
    hospitalLogo
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    doctor {
      id
      name
      specialty
      exequator
      email
      firstTime
      studyPlace
      isBeenReview
      isFirstAddSecretary
      hasTrialOnCreation
      title
      phone
      code
      password
      isAdmin
      daysOfTrial
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    hospital {
      id
      name
      type
      province
      town
      sector
      phone
      address
      pssCode
      rnc
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnCreateHospitalDoctorSubscriptionVariables,
	APITypes.OnCreateHospitalDoctorSubscription
>;
export const onUpdateHospitalDoctor = /* GraphQL */ `subscription OnUpdateHospitalDoctor {
  onUpdateHospitalDoctor {
    id
    doctorID
    hospitalID
    lastWaitingListID
    hospitalLogo
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    doctor {
      id
      name
      specialty
      exequator
      email
      firstTime
      studyPlace
      isBeenReview
      isFirstAddSecretary
      hasTrialOnCreation
      title
      phone
      code
      password
      isAdmin
      daysOfTrial
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    hospital {
      id
      name
      type
      province
      town
      sector
      phone
      address
      pssCode
      rnc
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnUpdateHospitalDoctorSubscriptionVariables,
	APITypes.OnUpdateHospitalDoctorSubscription
>;
export const onDeleteHospitalDoctor = /* GraphQL */ `subscription OnDeleteHospitalDoctor {
  onDeleteHospitalDoctor {
    id
    doctorID
    hospitalID
    lastWaitingListID
    hospitalLogo
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    doctor {
      id
      name
      specialty
      exequator
      email
      firstTime
      studyPlace
      isBeenReview
      isFirstAddSecretary
      hasTrialOnCreation
      title
      phone
      code
      password
      isAdmin
      daysOfTrial
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    hospital {
      id
      name
      type
      province
      town
      sector
      phone
      address
      pssCode
      rnc
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnDeleteHospitalDoctorSubscriptionVariables,
	APITypes.OnDeleteHospitalDoctorSubscription
>;
export const onCreateTeams = /* GraphQL */ `subscription OnCreateTeams {
  onCreateTeams {
    id
    OwnerID
    MemberID
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    Owner {
      id
      doctorID
      hospitalID
      lastWaitingListID
      hospitalLogo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospital {
        id
        name
        type
        province
        town
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    Member {
      id
      doctorID
      hospitalID
      lastWaitingListID
      hospitalLogo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospital {
        id
        name
        type
        province
        town
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnCreateTeamsSubscriptionVariables,
	APITypes.OnCreateTeamsSubscription
>;
export const onUpdateTeams = /* GraphQL */ `subscription OnUpdateTeams {
  onUpdateTeams {
    id
    OwnerID
    MemberID
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    Owner {
      id
      doctorID
      hospitalID
      lastWaitingListID
      hospitalLogo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospital {
        id
        name
        type
        province
        town
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    Member {
      id
      doctorID
      hospitalID
      lastWaitingListID
      hospitalLogo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospital {
        id
        name
        type
        province
        town
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnUpdateTeamsSubscriptionVariables,
	APITypes.OnUpdateTeamsSubscription
>;
export const onDeleteTeams = /* GraphQL */ `subscription OnDeleteTeams {
  onDeleteTeams {
    id
    OwnerID
    MemberID
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    Owner {
      id
      doctorID
      hospitalID
      lastWaitingListID
      hospitalLogo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospital {
        id
        name
        type
        province
        town
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    Member {
      id
      doctorID
      hospitalID
      lastWaitingListID
      hospitalLogo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospital {
        id
        name
        type
        province
        town
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnDeleteTeamsSubscriptionVariables,
	APITypes.OnDeleteTeamsSubscription
>;
export const onCreateHospitalDoctorCliente =
	/* GraphQL */ `subscription OnCreateHospitalDoctorCliente {
  onCreateHospitalDoctorCliente {
    id
    clientID
    hospitalDoctorID
    lastHealthInsurranceID
    clientStatus
    note
    recordNo
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    client {
      id
      identificationName
      identificationData
      actualInsurance
      name
      lastName
      cellphoneNumber
      email
      bornDate
      parentOrTutorCedula
      parentOrTutorID
      gender
      phoneNumber
      addressStreet
      city
      isAdult
      sector
      bloodType
      company
      vitalSignsHeight
      vitalSignsWeight
      bodyMass
      vitalSignsBloodPressure
      heartRate
      respiratoryRate
      bodyTemperature
      oxygenSaturation
      occupation
      profileImage
      heartBeat
      weight
      height
      bloodPressure
      updateDateTime
      createdAt
      updatedAt
      code
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    hospitalDoctor {
      id
      doctorID
      hospitalID
      lastWaitingListID
      hospitalLogo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospital {
        id
        name
        type
        province
        town
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    clientInsurance {
      id
      insuranceID
      clientID
      contractNumber
      affiliateNumber
      affiliateType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      insurance {
        id
        name
        allowClaim
        code
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnCreateHospitalDoctorClienteSubscriptionVariables,
		APITypes.OnCreateHospitalDoctorClienteSubscription
	>;
export const onUpdateHospitalDoctorCliente =
	/* GraphQL */ `subscription OnUpdateHospitalDoctorCliente {
  onUpdateHospitalDoctorCliente {
    id
    clientID
    hospitalDoctorID
    lastHealthInsurranceID
    clientStatus
    note
    recordNo
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    client {
      id
      identificationName
      identificationData
      actualInsurance
      name
      lastName
      cellphoneNumber
      email
      bornDate
      parentOrTutorCedula
      parentOrTutorID
      gender
      phoneNumber
      addressStreet
      city
      isAdult
      sector
      bloodType
      company
      vitalSignsHeight
      vitalSignsWeight
      bodyMass
      vitalSignsBloodPressure
      heartRate
      respiratoryRate
      bodyTemperature
      oxygenSaturation
      occupation
      profileImage
      heartBeat
      weight
      height
      bloodPressure
      updateDateTime
      createdAt
      updatedAt
      code
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    hospitalDoctor {
      id
      doctorID
      hospitalID
      lastWaitingListID
      hospitalLogo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospital {
        id
        name
        type
        province
        town
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    clientInsurance {
      id
      insuranceID
      clientID
      contractNumber
      affiliateNumber
      affiliateType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      insurance {
        id
        name
        allowClaim
        code
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnUpdateHospitalDoctorClienteSubscriptionVariables,
		APITypes.OnUpdateHospitalDoctorClienteSubscription
	>;
export const onDeleteHospitalDoctorCliente =
	/* GraphQL */ `subscription OnDeleteHospitalDoctorCliente {
  onDeleteHospitalDoctorCliente {
    id
    clientID
    hospitalDoctorID
    lastHealthInsurranceID
    clientStatus
    note
    recordNo
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    client {
      id
      identificationName
      identificationData
      actualInsurance
      name
      lastName
      cellphoneNumber
      email
      bornDate
      parentOrTutorCedula
      parentOrTutorID
      gender
      phoneNumber
      addressStreet
      city
      isAdult
      sector
      bloodType
      company
      vitalSignsHeight
      vitalSignsWeight
      bodyMass
      vitalSignsBloodPressure
      heartRate
      respiratoryRate
      bodyTemperature
      oxygenSaturation
      occupation
      profileImage
      heartBeat
      weight
      height
      bloodPressure
      updateDateTime
      createdAt
      updatedAt
      code
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    hospitalDoctor {
      id
      doctorID
      hospitalID
      lastWaitingListID
      hospitalLogo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospital {
        id
        name
        type
        province
        town
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    clientInsurance {
      id
      insuranceID
      clientID
      contractNumber
      affiliateNumber
      affiliateType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      insurance {
        id
        name
        allowClaim
        code
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
		APITypes.OnDeleteHospitalDoctorClienteSubscriptionVariables,
		APITypes.OnDeleteHospitalDoctorClienteSubscription
	>;
export const onCreateWaitList = /* GraphQL */ `subscription OnCreateWaitList {
  onCreateWaitList {
    id
    hospitalDoctorID
    createdAt
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnCreateWaitListSubscriptionVariables,
	APITypes.OnCreateWaitListSubscription
>;
export const onUpdateWaitList = /* GraphQL */ `subscription OnUpdateWaitList {
  onUpdateWaitList {
    id
    hospitalDoctorID
    createdAt
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnUpdateWaitListSubscriptionVariables,
	APITypes.OnUpdateWaitListSubscription
>;
export const onDeleteWaitList = /* GraphQL */ `subscription OnDeleteWaitList {
  onDeleteWaitList {
    id
    hospitalDoctorID
    createdAt
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnDeleteWaitListSubscriptionVariables,
	APITypes.OnDeleteWaitListSubscription
>;
export const onCreateWaitingListItem = /* GraphQL */ `subscription OnCreateWaitingListItem {
  onCreateWaitingListItem {
    id
    waitingListID
    clientID
    status
    clientHealthInsurrance
    positionNumber
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnCreateWaitingListItemSubscriptionVariables,
	APITypes.OnCreateWaitingListItemSubscription
>;
export const onUpdateWaitingListItem = /* GraphQL */ `subscription OnUpdateWaitingListItem {
  onUpdateWaitingListItem {
    id
    waitingListID
    clientID
    status
    clientHealthInsurrance
    positionNumber
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnUpdateWaitingListItemSubscriptionVariables,
	APITypes.OnUpdateWaitingListItemSubscription
>;
export const onDeleteWaitingListItem = /* GraphQL */ `subscription OnDeleteWaitingListItem {
  onDeleteWaitingListItem {
    id
    waitingListID
    clientID
    status
    clientHealthInsurrance
    positionNumber
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnDeleteWaitingListItemSubscriptionVariables,
	APITypes.OnDeleteWaitingListItemSubscription
>;
export const onCreateClientInsurance = /* GraphQL */ `subscription OnCreateClientInsurance {
  onCreateClientInsurance {
    id
    insuranceID
    clientID
    contractNumber
    affiliateNumber
    affiliateType
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    insurance {
      id
      name
      allowClaim
      code
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnCreateClientInsuranceSubscriptionVariables,
	APITypes.OnCreateClientInsuranceSubscription
>;
export const onUpdateClientInsurance = /* GraphQL */ `subscription OnUpdateClientInsurance {
  onUpdateClientInsurance {
    id
    insuranceID
    clientID
    contractNumber
    affiliateNumber
    affiliateType
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    insurance {
      id
      name
      allowClaim
      code
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnUpdateClientInsuranceSubscriptionVariables,
	APITypes.OnUpdateClientInsuranceSubscription
>;
export const onDeleteClientInsurance = /* GraphQL */ `subscription OnDeleteClientInsurance {
  onDeleteClientInsurance {
    id
    insuranceID
    clientID
    contractNumber
    affiliateNumber
    affiliateType
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    insurance {
      id
      name
      allowClaim
      code
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnDeleteClientInsuranceSubscriptionVariables,
	APITypes.OnDeleteClientInsuranceSubscription
>;
export const onCreateInsurance = /* GraphQL */ `subscription OnCreateInsurance {
  onCreateInsurance {
    id
    name
    allowClaim
    code
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnCreateInsuranceSubscriptionVariables,
	APITypes.OnCreateInsuranceSubscription
>;
export const onUpdateInsurance = /* GraphQL */ `subscription OnUpdateInsurance {
  onUpdateInsurance {
    id
    name
    allowClaim
    code
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnUpdateInsuranceSubscriptionVariables,
	APITypes.OnUpdateInsuranceSubscription
>;
export const onDeleteInsurance = /* GraphQL */ `subscription OnDeleteInsurance {
  onDeleteInsurance {
    id
    name
    allowClaim
    code
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnDeleteInsuranceSubscriptionVariables,
	APITypes.OnDeleteInsuranceSubscription
>;
export const onCreateClientClaim = /* GraphQL */ `subscription OnCreateClientClaim {
  onCreateClientClaim {
    id
    clientHospitalDoctorID
    healthInsuranceID
    diagnostic
    authorization
    date
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    healthInsurance {
      id
      name
      allowClaim
      code
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnCreateClientClaimSubscriptionVariables,
	APITypes.OnCreateClientClaimSubscription
>;
export const onUpdateClientClaim = /* GraphQL */ `subscription OnUpdateClientClaim {
  onUpdateClientClaim {
    id
    clientHospitalDoctorID
    healthInsuranceID
    diagnostic
    authorization
    date
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    healthInsurance {
      id
      name
      allowClaim
      code
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnUpdateClientClaimSubscriptionVariables,
	APITypes.OnUpdateClientClaimSubscription
>;
export const onDeleteClientClaim = /* GraphQL */ `subscription OnDeleteClientClaim {
  onDeleteClientClaim {
    id
    clientHospitalDoctorID
    healthInsuranceID
    diagnostic
    authorization
    date
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    healthInsurance {
      id
      name
      allowClaim
      code
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnDeleteClientClaimSubscriptionVariables,
	APITypes.OnDeleteClientClaimSubscription
>;
export const onCreateClaimProcess = /* GraphQL */ `subscription OnCreateClaimProcess {
  onCreateClaimProcess {
    id
    claimID
    name
    code
    claimed
    difference
    authorized
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnCreateClaimProcessSubscriptionVariables,
	APITypes.OnCreateClaimProcessSubscription
>;
export const onUpdateClaimProcess = /* GraphQL */ `subscription OnUpdateClaimProcess {
  onUpdateClaimProcess {
    id
    claimID
    name
    code
    claimed
    difference
    authorized
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnUpdateClaimProcessSubscriptionVariables,
	APITypes.OnUpdateClaimProcessSubscription
>;
export const onDeleteClaimProcess = /* GraphQL */ `subscription OnDeleteClaimProcess {
  onDeleteClaimProcess {
    id
    claimID
    name
    code
    claimed
    difference
    authorized
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnDeleteClaimProcessSubscriptionVariables,
	APITypes.OnDeleteClaimProcessSubscription
>;
export const onCreateForms = /* GraphQL */ `subscription OnCreateForms {
  onCreateForms {
    id
    DoctorID
    public
    name
    formImage
    description
    elements
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnCreateFormsSubscriptionVariables,
	APITypes.OnCreateFormsSubscription
>;
export const onUpdateForms = /* GraphQL */ `subscription OnUpdateForms {
  onUpdateForms {
    id
    DoctorID
    public
    name
    formImage
    description
    elements
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnUpdateFormsSubscriptionVariables,
	APITypes.OnUpdateFormsSubscription
>;
export const onDeleteForms = /* GraphQL */ `subscription OnDeleteForms {
  onDeleteForms {
    id
    DoctorID
    public
    name
    formImage
    description
    elements
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnDeleteFormsSubscriptionVariables,
	APITypes.OnDeleteFormsSubscription
>;
export const onCreateAppointment = /* GraphQL */ `subscription OnCreateAppointment {
  onCreateAppointment {
    id
    clientID
    doctorID
    hospitalID
    description
    date
    start
    end
    status
    shouldSendReminder
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    client {
      id
      identificationName
      identificationData
      actualInsurance
      name
      lastName
      cellphoneNumber
      email
      bornDate
      parentOrTutorCedula
      parentOrTutorID
      gender
      phoneNumber
      addressStreet
      city
      isAdult
      sector
      bloodType
      company
      vitalSignsHeight
      vitalSignsWeight
      bodyMass
      vitalSignsBloodPressure
      heartRate
      respiratoryRate
      bodyTemperature
      oxygenSaturation
      occupation
      profileImage
      heartBeat
      weight
      height
      bloodPressure
      updateDateTime
      createdAt
      updatedAt
      code
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    doctor {
      id
      name
      specialty
      exequator
      email
      firstTime
      studyPlace
      isBeenReview
      isFirstAddSecretary
      hasTrialOnCreation
      title
      phone
      code
      password
      isAdmin
      daysOfTrial
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    hospital {
      id
      name
      type
      province
      town
      sector
      phone
      address
      pssCode
      rnc
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnCreateAppointmentSubscriptionVariables,
	APITypes.OnCreateAppointmentSubscription
>;
export const onUpdateAppointment = /* GraphQL */ `subscription OnUpdateAppointment {
  onUpdateAppointment {
    id
    clientID
    doctorID
    hospitalID
    description
    date
    start
    end
    status
    shouldSendReminder
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    client {
      id
      identificationName
      identificationData
      actualInsurance
      name
      lastName
      cellphoneNumber
      email
      bornDate
      parentOrTutorCedula
      parentOrTutorID
      gender
      phoneNumber
      addressStreet
      city
      isAdult
      sector
      bloodType
      company
      vitalSignsHeight
      vitalSignsWeight
      bodyMass
      vitalSignsBloodPressure
      heartRate
      respiratoryRate
      bodyTemperature
      oxygenSaturation
      occupation
      profileImage
      heartBeat
      weight
      height
      bloodPressure
      updateDateTime
      createdAt
      updatedAt
      code
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    doctor {
      id
      name
      specialty
      exequator
      email
      firstTime
      studyPlace
      isBeenReview
      isFirstAddSecretary
      hasTrialOnCreation
      title
      phone
      code
      password
      isAdmin
      daysOfTrial
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    hospital {
      id
      name
      type
      province
      town
      sector
      phone
      address
      pssCode
      rnc
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnUpdateAppointmentSubscriptionVariables,
	APITypes.OnUpdateAppointmentSubscription
>;
export const onDeleteAppointment = /* GraphQL */ `subscription OnDeleteAppointment {
  onDeleteAppointment {
    id
    clientID
    doctorID
    hospitalID
    description
    date
    start
    end
    status
    shouldSendReminder
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    client {
      id
      identificationName
      identificationData
      actualInsurance
      name
      lastName
      cellphoneNumber
      email
      bornDate
      parentOrTutorCedula
      parentOrTutorID
      gender
      phoneNumber
      addressStreet
      city
      isAdult
      sector
      bloodType
      company
      vitalSignsHeight
      vitalSignsWeight
      bodyMass
      vitalSignsBloodPressure
      heartRate
      respiratoryRate
      bodyTemperature
      oxygenSaturation
      occupation
      profileImage
      heartBeat
      weight
      height
      bloodPressure
      updateDateTime
      createdAt
      updatedAt
      code
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    doctor {
      id
      name
      specialty
      exequator
      email
      firstTime
      studyPlace
      isBeenReview
      isFirstAddSecretary
      hasTrialOnCreation
      title
      phone
      code
      password
      isAdmin
      daysOfTrial
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    hospital {
      id
      name
      type
      province
      town
      sector
      phone
      address
      pssCode
      rnc
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
	APITypes.OnDeleteAppointmentSubscriptionVariables,
	APITypes.OnDeleteAppointmentSubscription
>;
