// @ts-nocheck
import React, { useContext, useState, useEffect } from 'react';
import { RelationsContext } from '../context/relations-context';
import { RelationsContextProps, UserContextProps, UserRoles } from '../context/types';
import { Hospital } from 'models';
import { formatFile } from 'shared/utilFunctions';
import Storage from '@aws-amplify/storage';
import { ImageFile } from 'shared/type';
import { StyledButtonHospitalConfig, StyledTextFieldHospitalConfig } from 'shared/style';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	IconButton,
	Link,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { HospitalLogo } from 'components/svg';
import { useForm } from 'react-hook-form';
import Secretaries from 'components/configuration/secretary/Secretaries';
import MobileCardHeader from 'components/mobileNavigation/MobileCardHeader';
import useMobile from 'hooks/useMobile';
import CustomDialog from 'components/dialog/CustomDialog';
import MobileDialogHeader from '../components/dialog/MobileDialogHeader';
import { UserContext } from 'context/user-context';
import DoctorsMembers from 'components/configuration/doctors/DoctorsMembers';
const ConfigurationHospitals = () => {
	const {
		updateHospital,
		actualHospital,
		setActualHospital,
		addLogoToHospitalDoctor,
		actualHospitalDoctor,
		updateHospitalDoctor,
		setAlertMessage,
	} = useContext(RelationsContext) as RelationsContextProps;
	const [hospitalLogo, setHospitalLogo] = useState<string>('');

	const [showModal, setShowModal] = useState<boolean>(false);
	const [hospital, setHospital] = useState<Hospital | null>(actualHospital as Hospital);
	const {
		register,
		watch,
		setValue,
		setError,
		clearErrors,
		formState: { errors },
	} = useForm();
	const [hospitalImg, setHospitalImg] = useState<ImageFile | null>(null);
	const [profilePreview, setProfilePreview] = useState<string | undefined>(undefined);
	const { userRole } = useContext(UserContext) as UserContextProps;
	const pictureRef = React.useRef<HTMLInputElement>(null);
	const isOwner = userRole && userRole === UserRoles.Owner;

	useEffect(() => {
		const getImage = async (imageKey: string) => {
			const url = await Storage.get(imageKey);
			setHospitalLogo(url);
		};
		if (!actualHospitalDoctor?.hospitalLogo) return;

		getImage(actualHospitalDoctor.hospitalLogo);
	}, [actualHospitalDoctor]);

	const handleUpdateHospitalLogo = async (file: ImageFile) => {
		const { key } = await Storage.put(file.name, file.file);

		if (actualHospitalDoctor?.hospitalLogo) {
			await Storage.remove(actualHospitalDoctor?.hospitalLogo);
		}

		await addLogoToHospitalDoctor(key);
	};

	const validateFields = () => {
		const fieldValidations = [
			{
				field: 'name',
				value: watch('name'),
				rules: [
					{ condition: (val) => !val, message: 'El Hospital o Consultorio es requerido' },
				],
			},
			{
				field: 'phone',
				value: watch('phone'),
				rules: [
					{ condition: (val) => !val, message: 'El teléfono es requerido' },
					{
						condition: (val) => val && val.length < 10,
						message: 'El teléfono debe tener 10 dígitos',
					},
					{
						condition: (val) => val && val.length > 10,
						message: 'El teléfono debe tener 10 dígitos',
					},
					{
						condition: (val) => val && !/^\d+$/.test(val),
						message: 'El teléfono solo debe contener números',
					},
				],
			},
			{
				field: 'address',
				value: watch('address'),
				rules: [
					{ condition: (val) => !val, message: 'La dirección es requerida' },
					{
						condition: (val) => val && val.length < 5,
						message: 'La dirección debe tener al menos 5 caracteres',
					},
				],
			},
		];

		let hasError = false;

		fieldValidations.forEach(({ field, value, rules }) => {
			const errorRule = rules.find(({ condition }) => condition(value));
			if (errorRule) {
				setError(field, { type: 'manual', message: errorRule.message });
				hasError = true;
			} else {
				clearErrors(field);
				setValue(field, value);
			}
		});

		return !hasError;
	};

	const handleUpdateDoctor = async () => {
		try {
			const name = watch('name');
			const phone = watch('phone');
			const address = watch('address');
			const hospitalId = hospital?.id ?? (actualHospitalDoctor?.hospitalID as string);

			const hasHospitalChanged =
				actualHospital?.name !== name ||
				actualHospital?.phone !== phone ||
				actualHospital?.address !== address ||
				actualHospitalDoctor?.hospitalID !== hospital?.id;

			if (hasHospitalChanged) {
				if (!validateFields()) return;

				clearErrors();

				const updatedHospital: Hospital = {
					...hospital,
					phone,
					address,
					name,
					id: hospitalId,
				};

				await updateHospital(updatedHospital);
				await updateHospitalDoctor(actualHospitalDoctor?.id as string, hospitalId);
				setActualHospital(updatedHospital);
			}

			if (hospitalImg?.file) {
				await handleUpdateHospitalLogo(hospitalImg);
			}

			setShowModal(false);
			setAlertMessage({
				message: 'Centro médico actualizado correctamente',
				severity: 'success',
			});
		} catch (error) {
			setAlertMessage({
				message: 'Error al actualizar el centro médico',
				severity: 'error',
			});
		}
	};

	const handleProfilePicture = (e: React.ChangeEvent<HTMLInputElement>) => {
		const reader = new FileReader();
		const file = e.target.files?.item(0) as File;

		reader.onloadend = (event: ProgressEvent<FileReader>) => {
			const formattedFile = formatFile(file);
			setHospitalImg(formattedFile);
			setProfilePreview(event.target?.result as string);
		};
		if (file) {
			reader.readAsDataURL(file);
		}
	};

	const handlerCancel = () => {
		setShowModal(false);
		setHospitalImg(null);
		setProfilePreview(undefined);
		setHospital(actualHospital as Hospital);
	};

	const { isMobile } = useMobile();

	const mobileStyles = isMobile ? {} : { width: 716, height: 231 };
	const mobileContainerStyles = isMobile
		? {
				padding: '16px 16px',
				justifyContent: 'start',
		  }
		: { marginTop: '60px', alignItems: 'center' };

	const mobileModalStyles = isMobile
		? {
				flexDirection: 'column',
				gap: '24px',
				marginBottom: '24px',
		  }
		: {
				marginTop: '30px',
				marginBottom: '30px',
		  };

	const modalBodyPrinter = () => {
		return (
			<div className="modal-configuration-hospital">
				<HospitalLogo image={profilePreview ? profilePreview : hospitalLogo} />
				<Link
					className={isMobile ? '!mx-auto !mt-[12px] !mb-[32px] block' : ''}
					component="button"
					variant="body2"
					onClick={() => {
						pictureRef.current?.click();
					}}
					style={{
						color: '#1565C0',
						fontSize: '13px',
						lineHeight: '21.58px',
						letterSpacing: '0.4px',
						fontFamily: 'Roboto',
						fontStyle: 'normal',
						fontWeight: '400',
						textDecoration: 'underline',
						textAlign: isMobile ? 'center' : 'left',
					}}
				>
					Cambiar Logo
				</Link>
				<input
					type="file"
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleProfilePicture(e)}
					id="avatar-input-file"
					accept="image/*"
					ref={pictureRef}
					hidden
				/>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						width: !isMobile ? '530px' : '100%',
						...mobileModalStyles,
					}}
				>
					<StyledTextFieldHospitalConfig
						required
						label="Hospital o consultorio"
						defaultValue={
							!!watch('name')
								? watch('name')
								: !!actualHospital?.name
								? actualHospital?.name
								: hospital?.name
						}
						value={watch('name')}
						placeholder="Hospital o consultorio*"
						InputLabelProps={{
							shrink: true,
						}}
						variant="outlined"
						error={!!errors.name}
						helperText={errors.name?.message}
						{...register('name')}
					/>
					<StyledTextFieldHospitalConfig
						required
						label="Teléfono"
						InputLabelProps={{
							shrink: true,
						}}
						defaultValue={
							!!watch('phone')
								? watch('phone')
								: !!actualHospital?.phone
								? actualHospital?.phone
								: hospital?.phone
						}
						value={watch('phone')}
						variant="outlined"
						error={!!errors.phone}
						helperText={errors.phone?.message}
						{...register('phone')}
					/>
				</div>
				<div
					style={{
						width: '100%',
					}}
				>
					<StyledTextFieldHospitalConfig
						required
						id="standard-required"
						label="Dirección"
						InputLabelProps={{
							shrink: true,
						}}
						defaultValue={
							watch('address') !== undefined
								? watch('address')
								: !!actualHospital?.address
								? actualHospital?.address
								: hospital?.address
						}
						variant="outlined"
						textFieldWidth="100%"
						style={{
							width: '100%',
						}}
						value={
							watch('address') !== undefined
								? watch('address')
								: !!actualHospital?.address
								? actualHospital?.address
								: hospital?.address
						}
						error={!!errors.address}
						helperText={errors.address?.message}
						{...register('address')}
					/>
				</div>
			</div>
		);
	};
	const bodyPrinter = () => {
		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					width: '100%',
				}}
			>
				{isMobile ? (
					<CustomDialog
						open={showModal}
						handleClose={handlerCancel}
						handleAgree={handleUpdateDoctor}
						title={
							<MobileDialogHeader
								goBack={handlerCancel}
								title="Editar centros médicos"
							/>
						}
						className="fullscreen-modal"
						fullWidth
						fullScreen
						disagreeText="Cancel"
						agreeText="Guardar"
					>
						<div>{modalBodyPrinter()}</div>
					</CustomDialog>
				) : (
					<Dialog open={showModal} onClose={handlerCancel} fullWidth>
						<DialogTitle id="customized-dialog-title">
							<h1
								style={{
									fontFamily: 'Roboto',
									fontStyle: 'normal',
									fontWeight: '500',
									fontSize: '20px',
									lineHeight: '32px',
									letterSpacing: '0.15px',
									color: '#000000DE',
								}}
							>
								<b>Editar Centro Médico</b>
							</h1>
						</DialogTitle>
						<DialogContent
							style={{
								maxWidth: '582px',
								width: '582px',
							}}
						>
							{modalBodyPrinter()}
						</DialogContent>
						<DialogActions
							style={{
								maxWidth: '582px',
								width: '582px',
							}}
						>
							<div
								style={{
									display: 'flex',
									gap: '8px',
								}}
							>
								<StyledButtonHospitalConfig onClick={handlerCancel}>
									Cancelar
								</StyledButtonHospitalConfig>
								<StyledButtonHospitalConfig onClick={handleUpdateDoctor}>
									Guardar
								</StyledButtonHospitalConfig>
							</div>
						</DialogActions>
					</Dialog>
				)}
				<div
					className="pb-8 pages-container overflow-y-auto w-full"
					style={{
						...mobileContainerStyles,
						display: 'flex',
						flexDirection: 'column',
						rowGap: '20px',
					}}
				>
					<div
						id="myAccount"
						className="bg-white-section lg:m-auto"
						style={{
							...mobileStyles,
							boxShadow:
								'0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)',
							borderRadius: '4px',
						}}
					>
						<div
							className="border-b-2 border-green-content py-4"
							style={{
								padding: isMobile ? '16px' : '13px 16px',
							}}
						>
							<h1
								style={{
									fontFamily: 'Roboto',
									display: 'flex',
									alignItems: 'center',
									fontStyle: 'normal',
									fontWeight: '500',
									fontSize: '20px',
									lineHeight: '160%',
									letterSpacing: '0.15px',
									color: '#000000DE',
								}}
							>
								Centros Médicos
							</h1>
						</div>
						<div className="mb-[16px]">
							<div
								style={{
									padding: '8px 0px',
									margin: '0px 16px',
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
								}}
							>
								<h2
									style={{
										fontFamily: 'Roboto',
										fontStyle: 'normal',
										fontWeight: '400',
										fontSize: '16px',
										lineHeight: '150%',
										letterSpacing: '0.15px',
										color: '#000000DE',
									}}
								>
									{actualHospital?.name ?? hospital?.name}
								</h2>
								{isOwner && (
									<IconButton
										aria-label="edit"
										onClick={() => setShowModal(true)}
									>
										<EditIcon />
									</IconButton>
								)}
							</div>
							<Divider variant="middle" />
						</div>
					</div>
					{isOwner ? <DoctorsMembers /> : null}
					{isOwner ? <Secretaries /> : null}
				</div>
			</div>
		);
	};

	return isMobile ? (
		<div className="mobile-wrapper">
			<div className="mobile-header">
				<MobileCardHeader />
			</div>
			{bodyPrinter()}
		</div>
	) : (
		bodyPrinter()
	);
};

export default ConfigurationHospitals;
