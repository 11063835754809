import React, { useRef, ElementRef, ChangeEvent, useContext } from 'react';
import { Button } from '@material-ui/core';
import useMobile from 'hooks/useMobile';
import { FormatDate, getDateAndTime, getDateToString } from 'shared/dateUtils';
import { formatFile } from 'shared/utilFunctions';
import { ClientsContext } from 'context/client-context';
import { ClientContextProps, RelationsContextProps } from 'context/types';

import Storage from '@aws-amplify/storage';
import { ClientImage } from 'models';
import { RelationsContext } from 'context/relations-context';

const UploadFileIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
		<path
			d="M14.5125 7.53C14.0025 4.9425 11.73 3 9 3C6.8325 3 4.95 4.23 4.0125 6.03C1.755 6.27 0 8.1825 0 10.5C0 12.9825 2.0175 15 4.5 15H14.25C16.32 15 18 13.32 18 11.25C18 9.27 16.4625 7.665 14.5125 7.53ZM10.5 9.75V12.75H7.5V9.75H5.25L9 6L12.75 9.75H10.5Z"
			fill="white"
		/>
	</svg>
);

const MobileHeader = () => {
	const { isMobile } = useMobile();
	const clientsContext = useContext(ClientsContext) as ClientContextProps;
	const relationContext = useContext(RelationsContext) as RelationsContextProps;
	const { addImage, setImageLoading, updateImage } = clientsContext;
	const inputPictureRef = useRef<ElementRef<'input'>>(null);

	const handleImageUpload = () => {
		inputPictureRef?.current?.click();
	};

	const uploadImageHandler = async (
		e: ChangeEvent<HTMLInputElement>,
		image: ClientImage,
		imageSectionID: string
	) => {
		const file = e.target.files![0];
		const handleUpdateImage = async (file: File) => {
			if (!file) {
				relationContext.setAlertMessage({
					message: 'No se pudo cargar el archivo',
					severity: 'error',
				});
				return;
			}
			const extension = file.type?.split('/')[1];
			const fileName = `${file.name}-${getDateAndTime()}.${extension[extension.length - 1]}`;
			const { key } = await Storage.put(fileName, file);

			await updateImage(image.id, file.name, key, imageSectionID);
		};
		try {
			if ((file && file['type'].split('/')[0]) === 'image') {
				new Compressor(file, {
					quality: 0.8,
					success: async (file: File) => handleUpdateImage(file),
				});
			} else {
				handleUpdateImage(file);
			}
		} catch (error) {
			console.error('Error uploading image', error);
		}
	};

	const onChangeInputFileHandler = async (e: ChangeEvent<HTMLInputElement>) => {
		setImageLoading(true);
		const file = e.target.files![0];
		const date = getDateToString(new Date(), FormatDate['Monthname DD, YYYY']);
		const { name: fileName } = formatFile(file);
		const { key } = await Storage.put(fileName, file);
		const { image, imageSectionID } = await addImage(date, file.name, key);
		uploadImageHandler(e, image, imageSectionID);
		if (inputPictureRef && inputPictureRef.current) {
			inputPictureRef.current.value = '';
		}
	};

	if (!isMobile) return null;

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
			}}
		>
			<div className="invisible absolute top-0">
				<input
					type="file"
					ref={inputPictureRef}
					onChange={onChangeInputFileHandler}
					accept="image/jpeg,image/jpg,image/png,.pdf,.doc,.docx"
				/>
			</div>
			<h1
				style={{
					color: '#00000061',
					fontSize: '14px',
					fontStyle: 'normal',
					fontWeight: 500,
					lineHeight: '24px',
					letterSpacing: '0.4px',
					textTransform: 'uppercase',
				}}
			>
				archivos
			</h1>
			<Button
				startIcon={<UploadFileIcon />}
				onClick={handleImageUpload}
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					padding: '10px',
					alignItems: 'center',
					borderRadius: '4px',
					color: 'var(--Light-Secondary-Main, #fff)',
					background: 'var(--Light-Secondary-Main, #9C27B0)',
					boxShadow:
						'0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
					textTransform: 'uppercase',
				}}
				size="small"
			>
				Subir archivos
			</Button>
		</div>
	);
};

export default MobileHeader;
