import React, { useContext, useEffect, useState } from 'react';
import { RelationsContext } from '../context/relations-context';
import MedicineRow from '../components/medicine/MedicineRow';
import { MedicineType, RelationsContextProps, SuggestionType } from '../context/types';
import MedicineDialog from '../components/medicineDialog/MedicineDialog';
import ListContainer from '../components/list/ListContainer';
import { CardTitle, PageContainer } from '../shared/style';
import ModalConfirmation from '../components/modalConfirmation/ModalConfirmation';
import { usePagination } from '../shared/paginationUtils';
import { Pagination } from '@material-ui/lab';
import { MedicineOption, MedicineConsultation } from '../shared/type';
import LoadingSpinner from '../components/loading/LoadingSpinner';
import { clearSuggestions, filterSuggestions } from 'shared/medicinesAndLabImagesUtils';
import useMobile from 'hooks/useMobile';
import { NoItemButton } from '../shared/style';
import Add from '@material-ui/icons/Add';
import NoItem from 'components/noItems';

const Medicine = () => {
	const {
		setAlertMessage,
		medicines,
		fetchDoctorMedicines,
		createDoctorMedicine,
		fetchMedicines,
		actualDoctor,
		createMedicine,
		editMedicine,
		deleteMedicine,
	} = useContext(RelationsContext) as RelationsContextProps;
	const [isUpdateMedicine, setIsUpdateMedicine] = useState(false);
	const [openMedicineModal, setOpenMedicineModal] = useState(false);
	const [medicine, setMedicine] = useState<MedicineConsultation>({} as MedicineConsultation);
	const [filterText, setFilterText] = useState<string>('');
	const [selectedMedicine, setSelectedMedicine] = useState<MedicineType>({} as MedicineType);
	const [isDisabledCreate, setIsDisabledCreate] = useState<boolean>(false);
	const [deleteMedicineModal, setDeleteMedicineModal] = useState<boolean>(false);
	const [medicinesSuggestions, setMedicinesSuggestions] = useState<MedicineType[]>([]);
	const LIST_PER_PAGE = 15;

	const [isLoading, setIsLoading] = useState(false);
	const { isMobile } = useMobile();

	const filteredMedicines = actualDoctor?.medicines?.filter((el) =>
		el.name?.toLowerCase().trim().includes(filterText.toLowerCase().trim())
	);

	const count = Math.ceil((filteredMedicines?.length as number) / LIST_PER_PAGE);
	const _DATA = usePagination(filteredMedicines as MedicineType[], LIST_PER_PAGE);

	const handleRequests = async () => {
		setIsLoading(true);
		await fetchMedicines();
		await fetchDoctorMedicines();
		setIsLoading(false);
	};

	useEffect(() => {
		if (medicines.length === 0 || actualDoctor?.medicines?.length === 0) {
			handleRequests();
		}
	}, []);

	useEffect(() => {
		const { medicineName } = medicine;
		const timerId = filterSuggestions(
			SuggestionType.MEDICINES,
			medicines,
			medicineName,
			setMedicinesSuggestions
		);

		return () => {
			clearSuggestions(timerId, setMedicinesSuggestions);
		};
	}, [medicine.medicineName]);

	const clearForm = () => {
		fetchMedicines();
		setOpenMedicineModal(false);
		setIsDisabledCreate(false);
		setIsUpdateMedicine(false);
	};

	const handleCreateMedicine = async () => {
		const checkIfMedicineIsActive = actualDoctor?.medicines?.some(
			(el) => el.name === medicine.medicineName
		);
		if (checkIfMedicineIsActive) {
			clearForm();
			setAlertMessage({
				message: 'Ya existe un medicamento con ese nombre',
				severity: 'info',
			});
			return;
		}
		const createdMedicine = await createMedicine(medicine.medicineName);
		await createDoctorMedicine(createdMedicine.id);
		clearForm();
	};

	const handleUpdateMedicine = async () => {
		await editMedicine(medicine.medicineName, selectedMedicine);
		clearForm();
	};

	const handleAddNewMedicine = () => {
		setIsDisabledCreate(true);
		setOpenMedicineModal(true);
		setMedicine({ medicineName: '' } as MedicineConsultation);
	};

	const handleEditMedicine = (el: MedicineType) => {
		setIsUpdateMedicine(true);
		setOpenMedicineModal(true);
		setMedicine({
			...el,
			medicineName: el.name as string,
		});
		setSelectedMedicine(el);
	};

	const handleChangename = (value: string) => {
		if (value !== undefined) {
			setMedicine({ ...medicine, medicineName: value });
			if (value) {
				setIsDisabledCreate(false);
			} else {
				setIsDisabledCreate(true);
			}
		}
	};
	const handleClickMedicine = async (value: MedicineType) => {
		setMedicine({ ...medicine, medicineName: value.name as string });
	};

	const deleteMedicineHandler = async (el: MedicineType) => {
		setSelectedMedicine(el);
		setDeleteMedicineModal(true);
	};

	const isMedicinesAvailable = _DATA.currentData() && _DATA.currentData().length > 0;

	const medicinesList = !isMedicinesAvailable ? (
		<NoItem
			title="No tienes medicamentos en tu lista"
			onClickHandler={handleAddNewMedicine}
			btnLabel={'Agregar medicamento'}
			isRow={isMobile}
			containerStyle={{
				alignItems: 'center',
			}}
		/>
	) : (
		_DATA
			.currentData()
			.map((el: MedicineType) => (
				<MedicineRow
					key={el.id}
					medicine={el}
					deleteMedicineHandler={() => deleteMedicineHandler(el)}
					editMedicineHandler={() => handleEditMedicine(el)}
				/>
			))
	);

	const handleCloseMedicineModal = () => {
		clearForm();
		setMedicine({} as MedicineConsultation);
	};

	const handleDelete = async () => {
		setDeleteMedicineModal(false);
		await deleteMedicine(selectedMedicine);
		setSelectedMedicine({} as MedicineType);
		if (
			(filteredMedicines?.length as number) > LIST_PER_PAGE ||
			filteredMedicines?.length === LIST_PER_PAGE
		) {
			_DATA.jump(_DATA.currentPage - 1);
		}
	};

	const populatedMedicinesStyles = {
		justifyContent: 'flex-start',
	};

	return (
		<PageContainer id="medicines-labimage">
			{openMedicineModal && (
				<MedicineDialog
					isDisabled={isDisabledCreate}
					isUpdate={isUpdateMedicine}
					open={openMedicineModal}
					searchMedicines={medicinesSuggestions}
					handleClose={handleCloseMedicineModal}
					medicine={medicine as MedicineOption}
					handleClickMedicine={handleClickMedicine}
					handleChangename={handleChangename}
					handleAgree={isUpdateMedicine ? handleUpdateMedicine : handleCreateMedicine}
				/>
			)}
			{isLoading ? (
				<LoadingSpinner />
			) : (
				<ListContainer
					className="medicines-labimage-list"
					placeholder={isMobile ? 'Buscar Medicamentos' : undefined}
					title={<CardTitle>{!isMobile && 'Mis '}Medicamentos</CardTitle>}
					handleAdd={isMobile ? undefined : handleAddNewMedicine}
					addTitle={isMobile ? undefined : 'Crear Medicamento'}
					filterText={filterText}
					setFilterText={setFilterText}
					isFooter={(filteredMedicines?.length as number) > LIST_PER_PAGE}
					footer={
						<Pagination
							page={_DATA.currentPage}
							onChange={(_e: any, number: number) => {
								_DATA.jump(number);
							}}
							count={count}
							variant="outlined"
							color="primary"
						/>
					}
					contentStyle={{
						display: 'flex',
						flexDirection: 'column',
						maxHeight: '89%',
						...(isMedicinesAvailable ? populatedMedicinesStyles : {}),
					}}
				>
					{isMobile && _DATA.currentData().length > 0 && (
						<div className="flex py-[20px] justify-center">
							<NoItemButton
								variant="contained"
								color="primary"
								size="small"
								onClick={handleAddNewMedicine}
							>
								<Add style={{ marginRight: 8 }} /> CREAR MEDICAMENTO
							</NoItemButton>
						</div>
					)}
					{medicinesList}
				</ListContainer>
			)}

			<ModalConfirmation
				className="modal-deletion"
				modalState={deleteMedicineModal}
				approveFunction={handleDelete}
				name={`¿Estás seguro de borrar el medicamento ${selectedMedicine.name}?`}
				closeFunction={() => setDeleteMedicineModal(false)}
			/>
		</PageContainer>
	);
};

export default Medicine;
