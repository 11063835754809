import { Divider, Grid, ListItemText } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import CustomCard from '../../card/CustomCard';
import EditIcon from '@material-ui/icons/Edit';
import { ClientsContext } from '../../../context/client-context';
import { ClientContextProps } from '../../../context/types';
import { displayAge } from '../../../shared/dateUtils';
import { StyledListItem, TitleTypography } from './PatientSummary.style';
import moment from 'moment';
import { insuranceType, NoSpecified } from '../../../shared/constants';
import { Client } from 'models';
import { PatientIconButton } from '../../../shared/style';
import useAPI from 'hooks/API';
import { syncClients } from 'graphql/queries';

interface Props {
	handleEditPatient: () => void;
}

const SummaryUnderAgePatientInformation: React.FC<Props> = ({ handleEditPatient }) => {
	const [parentInfo, setParentInfo] = useState<Client>({} as Client);
	const { selectedClient } = useContext(ClientsContext) as ClientContextProps;
	const yearsOld = selectedClient?.bornDate && displayAge(moment(selectedClient.bornDate));

	const { execute } = useAPI();

	useEffect(() => {
		const fetchParentInfo = async () => {
			if (selectedClient && selectedClient.parentOrTutorID) {
				const clientResponse = await execute(syncClients, {
					filter: {
						id: {
							eq: selectedClient.parentOrTutorID,
						},
					},
				});

				const client = clientResponse?.data?.syncClients?.items[0];

				if (client) {
					setParentInfo(client);
				}
			}
		};

		fetchParentInfo();
	}, [selectedClient]);

	return (
		<CustomCard
			title="Información Personal"
			headerAction={
				<PatientIconButton aria-label="edit" size="medium" onClick={handleEditPatient}>
					<EditIcon />
				</PatientIconButton>
			}
			paddingTop={'0'}
			paddingBottom={'0'}
		>
			<TitleTypography variant="body2">Datos del menor de edad</TitleTypography>
			<Grid container>
				<Grid item xs={12} md={6}>
					<StyledListItem>
						<ListItemText primary={`${yearsOld}`} secondary="Edad" />
					</StyledListItem>
				</Grid>
				<Grid item xs={12} md={6}>
					<StyledListItem>
						<ListItemText
							primary={selectedClient?.gender || 'No especificado'}
							secondary="Sexo biológico"
						/>
					</StyledListItem>
				</Grid>
			</Grid>
			<Divider />

			<Grid container>
				<Grid item xs={12} md={6}>
					<StyledListItem>
						<ListItemText
							primary={selectedClient?.bloodType || NoSpecified}
							secondary="Tipo de Sangre"
						/>
					</StyledListItem>
				</Grid>
				{parentInfo && (
					<Grid item xs={12} md={6}>
						<StyledListItem>
							<ListItemText
								primary={selectedClient?.healthInsuranceName || NoSpecified}
								secondary="Seguro"
							/>
						</StyledListItem>
					</Grid>
				)}
			</Grid>
			<Divider />

			{!(selectedClient?.healthInsuranceName === insuranceType.SINSEGURO || parentInfo) && (
				<>
					<Grid container>
						<Grid item xs={12} md={6}>
							<StyledListItem>
								<ListItemText
									primary={selectedClient?.affiliateNumber || NoSpecified}
									secondary="No. de Afiliado"
								/>
							</StyledListItem>
						</Grid>
						<Grid item xs={12} md={6}>
							<StyledListItem>
								<ListItemText
									primary={selectedClient?.contractNumber || NoSpecified}
									secondary="No. de Contrato"
								/>
							</StyledListItem>
						</Grid>
					</Grid>
					<Divider />
				</>
			)}

			<Grid container>
				{parentInfo && (
					<>
						<Grid item xs={12} md={6}>
							<StyledListItem>
								<ListItemText
									primary={selectedClient?.addressStreet || NoSpecified}
									secondary="Calle y número"
								/>
							</StyledListItem>
						</Grid>
						<Grid item xs={12} md={6}>
							<StyledListItem>
								<ListItemText
									primary={selectedClient?.city || NoSpecified}
									secondary="Ciudad"
								/>
							</StyledListItem>
						</Grid>
					</>
				)}
			</Grid>
			<Divider />

			{parentInfo && (
				<>
					<Grid container>
						<Grid item xs={12} md={6}>
							<StyledListItem>
								<ListItemText
									primary={selectedClient?.sector || NoSpecified}
									secondary="Sector"
								/>
							</StyledListItem>
						</Grid>
					</Grid>
					<Divider />
				</>
			)}

			<TitleTypography variant="body2">Datos del Padre o tutor</TitleTypography>
			<Grid container>
				{parentInfo && (
					<>
						<Grid item xs={12} md={6}>
							<StyledListItem>
								<ListItemText
									primary={parentInfo?.identificationData || NoSpecified}
									secondary="Cédula"
								/>
							</StyledListItem>
						</Grid>
						<Grid item xs={12} md={6}>
							<StyledListItem>
								<ListItemText
									primary={parentInfo?.name || NoSpecified}
									secondary="Nombre del padre o tutor
"
								/>
							</StyledListItem>
						</Grid>
					</>
				)}
			</Grid>
			<Divider />

			<Grid container>
				{parentInfo && (
					<>
						<Grid item xs={12} md={6}>
							<StyledListItem>
								<ListItemText
									primary={parentInfo?.lastName || NoSpecified}
									secondary="Apellido del padre o tutor"
								/>
							</StyledListItem>
						</Grid>
						<Grid item xs={12} md={6}>
							<StyledListItem>
								<ListItemText
									primary={parentInfo?.cellphoneNumber || NoSpecified}
									secondary="Celular"
								/>
							</StyledListItem>
						</Grid>
					</>
				)}
			</Grid>
			<Divider />

			{parentInfo && (
				<Grid container>
					<Grid item xs={12} md={6}>
						<StyledListItem>
							<ListItemText
								primary={parentInfo?.email || NoSpecified}
								secondary="Correo"
							/>
						</StyledListItem>
					</Grid>
				</Grid>
			)}
		</CustomCard>
	);
};

export default SummaryUnderAgePatientInformation;
