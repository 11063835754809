import React from 'react';

const ProfileReview = () => (
	<svg
		width="193"
		height="121"
		style={{ marginBottom: '59px', marginTop: '30px' }}
		viewBox="0 0 193 121"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_4566_99995)">
			<path
				d="M165.604 78.7682L165.242 78.8076L169.713 119.46L170.075 119.421L165.604 78.7682Z"
				fill="#292A2E"
			/>
			<path
				d="M177.184 96.5152L174.984 118.166L175.347 118.202L177.546 96.5517L177.184 96.5152Z"
				fill="#292A2E"
			/>
			<path d="M180.964 115.549H164.645V120.819H180.964V115.549Z" fill="#292A2E" />
			<path
				opacity="0.2"
				d="M180.964 115.549H164.645V120.819H180.964V115.549Z"
				fill="white"
			/>
			<path
				d="M190.803 105.846C193.895 101.757 192.421 95.4629 187.511 91.7872C182.601 88.1114 176.115 88.446 173.024 92.5345C169.932 96.6231 171.406 102.917 176.316 106.593C181.225 110.269 187.712 109.934 190.803 105.846Z"
				fill="#0CBC8B"
			/>
			<path
				opacity="0.2"
				d="M190.803 105.846C193.895 101.757 192.421 95.4629 187.511 91.7872C182.601 88.1114 176.115 88.446 173.024 92.5345C169.932 96.6231 171.406 102.917 176.316 106.593C181.225 110.269 187.712 109.934 190.803 105.846Z"
				fill="white"
			/>
			<path
				opacity="0.5"
				d="M190.802 105.846C190.595 106.122 190.368 106.383 190.124 106.628C186.721 108.791 181.698 108.45 177.713 105.466C172.804 101.791 171.33 95.4971 174.422 91.4087C174.63 91.1336 174.856 90.8725 175.098 90.627C178.504 88.462 183.525 88.8041 187.512 91.7867C192.421 95.4631 193.894 101.757 190.802 105.846Z"
				fill="white"
			/>
			<path
				d="M168.028 90.3338C172.892 86.5981 174.214 80.1911 170.981 76.0235C167.748 71.8559 161.184 71.5059 156.32 75.2416C151.457 78.9774 150.135 85.3844 153.368 89.552C156.601 93.7196 163.164 94.0696 168.028 90.3338Z"
				fill="#0CBC8B"
			/>
			<path
				opacity="0.2"
				d="M166.486 89.262C162.72 92.1569 157.933 92.598 154.463 90.7134C154.061 90.3615 153.694 89.9721 153.367 89.5506C150.134 85.3843 151.455 78.9767 156.319 75.2416C160.086 72.3483 164.873 71.9056 168.341 73.7885C168.744 74.1406 169.111 74.5305 169.438 74.953C172.672 79.1193 171.35 85.5269 166.486 89.262Z"
				fill="white"
			/>
			<path
				d="M19.1131 47.0868C28.8967 47.0868 36.8278 39.195 36.8278 29.4599C36.8278 19.7248 28.8967 11.833 19.1131 11.833C9.32958 11.833 1.39844 19.7248 1.39844 29.4599C1.39844 39.195 9.32958 47.0868 19.1131 47.0868Z"
				fill="#F4F4F4"
			/>
			<g opacity="0.05">
				<path
					d="M19.1137 43.4339C26.8696 43.4339 33.1571 37.1776 33.1571 29.4601C33.1571 21.7426 26.8696 15.4863 19.1137 15.4863C11.3577 15.4863 5.07031 21.7426 5.07031 29.4601C5.07031 37.1776 11.3577 43.4339 19.1137 43.4339Z"
					fill="#292A2E"
				/>
			</g>
			<path
				d="M14.5156 34.451L15.8257 32.4297C16.8637 33.2214 17.7723 33.6256 18.738 33.6256C19.8959 33.6256 20.4052 33.1478 20.4052 32.2227C20.4052 30.1316 15.0131 30.1693 15.0131 26.5015C15.0131 24.1436 16.6545 22.6418 19.2428 22.6418C20.9902 22.6418 22.1773 23.3594 23.1314 24.3661L21.629 26.0787C20.8865 25.4068 20.257 25.0529 19.404 25.0529C18.4206 25.0529 17.8883 25.4472 17.8883 26.3603C17.8883 28.2992 23.2802 28.1295 23.2802 32.014C23.2802 34.3481 21.7281 36.0368 18.738 36.0368C17.448 36.0362 15.6693 35.4912 14.5156 34.451ZM18.1085 20.6494H20.1011V23.5766H18.1085V20.6494ZM18.1085 34.8163H20.1011V38.0281H18.1085V34.8163Z"
				fill="white"
			/>
			<path
				d="M175.285 35.2538C185.069 35.2538 193 27.362 193 17.6269C193 7.89183 185.069 0 175.285 0C165.501 0 157.57 7.89183 157.57 17.6269C157.57 27.362 165.501 35.2538 175.285 35.2538Z"
				fill="#F4F4F4"
			/>
			<g opacity="0.05">
				<path
					d="M175.286 31.6009C183.042 31.6009 189.329 25.3446 189.329 17.6271C189.329 9.90959 183.042 3.65332 175.286 3.65332C167.53 3.65332 161.242 9.90959 161.242 17.6271C161.242 25.3446 167.53 31.6009 175.286 31.6009Z"
					fill="#292A2E"
				/>
			</g>
			<path
				d="M170.688 22.6178L171.998 20.5967C173.036 21.3882 173.944 21.7926 174.91 21.7926C176.068 21.7926 176.577 21.3146 176.577 20.3895C176.577 18.2984 171.185 18.3361 171.185 14.6683C171.185 12.3106 172.826 10.8086 175.415 10.8086C177.162 10.8086 178.349 11.526 179.303 12.5328L177.801 14.2454C177.058 13.5735 176.429 13.2197 175.576 13.2197C174.592 13.2197 174.06 13.614 174.06 14.5271C174.06 16.4661 179.452 16.2961 179.452 20.1808C179.452 22.5147 177.9 24.2036 174.91 24.2036C173.62 24.2032 171.841 23.6582 170.688 22.6178ZM174.28 8.81641H176.273V11.7434H174.28V8.81641ZM174.28 22.9832H176.273V26.1951H174.28V22.9832Z"
				fill="white"
			/>
			<path
				d="M160.166 9.07227H37.2992C34.2819 9.07227 31.8359 11.5061 31.8359 14.5085V104.02C31.8359 107.022 34.2819 109.456 37.2992 109.456H160.166C163.184 109.456 165.63 107.022 165.63 104.02V14.5085C165.63 11.5061 163.184 9.07227 160.166 9.07227Z"
				fill="white"
			/>
			<path
				d="M160.164 109.637H37.2977C35.801 109.635 34.3661 109.043 33.3078 107.99C32.2494 106.937 31.6541 105.509 31.6523 104.02V14.508C31.654 13.0187 32.2494 11.5909 33.3077 10.5378C34.3661 9.48468 35.801 8.8923 37.2977 8.89062H160.164C161.661 8.89226 163.096 9.48461 164.155 10.5377C165.213 11.5908 165.808 13.0187 165.81 14.508V104.02C165.808 105.509 165.213 106.937 164.154 107.99C163.096 109.043 161.661 109.635 160.164 109.637ZM37.2977 9.25304C35.8976 9.25457 34.5552 9.80872 33.5651 10.7939C32.575 11.7791 32.0181 13.1148 32.0166 14.508V104.02C32.0181 105.413 32.575 106.749 33.5651 107.734C34.5552 108.719 35.8976 109.273 37.2977 109.275H160.164C161.565 109.273 162.907 108.719 163.897 107.734C164.887 106.749 165.444 105.413 165.446 104.02V14.508C165.444 13.1148 164.887 11.7791 163.897 10.7939C162.907 9.80872 161.565 9.25457 160.164 9.25304H37.2977Z"
				fill="#292A2E"
			/>
			<path
				d="M55.7161 58.135C55.6443 58.1351 55.5733 58.1211 55.507 58.0937C55.4407 58.0664 55.3805 58.0263 55.3299 57.9758L52.7803 55.4389C52.6808 55.3363 52.6257 55.199 52.627 55.0565C52.6282 54.9139 52.6857 54.7776 52.787 54.6768C52.8883 54.576 53.0253 54.5188 53.1685 54.5176C53.3118 54.5164 53.4498 54.5712 53.5528 54.6702L56.1024 57.2071C56.1787 57.2831 56.2308 57.38 56.2518 57.4854C56.2729 57.5908 56.2621 57.7001 56.2207 57.7994C56.1794 57.8988 56.1094 57.9836 56.0196 58.0434C55.9297 58.1031 55.8241 58.135 55.7161 58.135Z"
				fill="#0CBC8B"
			/>
			<path
				d="M53.1673 58.135C53.0593 58.135 52.9537 58.1031 52.8638 58.0434C52.774 57.9837 52.704 57.8988 52.6627 57.7994C52.6213 57.7001 52.6105 57.5908 52.6316 57.4854C52.6527 57.38 52.7047 57.2831 52.7811 57.2071L55.3306 54.6702C55.4336 54.5712 55.5716 54.5164 55.7149 54.5176C55.8581 54.5188 55.9951 54.576 56.0964 54.6768C56.1977 54.7776 56.2552 54.9139 56.2564 55.0565C56.2577 55.199 56.2026 55.3363 56.1031 55.4389L53.5536 57.9758C53.5029 58.0263 53.4427 58.0664 53.3764 58.0938C53.3101 58.1211 53.2391 58.1351 53.1673 58.135Z"
				fill="#0CBC8B"
			/>
			<path
				d="M128.716 82.3819L127.988 84.043L132.467 85.9858L133.195 84.3247L128.716 82.3819Z"
				fill="#0CBC8B"
			/>
			<path
				d="M130.128 37.8485L125.916 40.3115L126.839 41.8738L131.051 39.4108L130.128 37.8485Z"
				fill="#0CBC8B"
			/>
			<path
				d="M59.9353 42.0661L59.3711 43.7891L64.0167 45.2952L64.5809 43.5723L59.9353 42.0661Z"
				fill="#0CBC8B"
			/>
			<path
				d="M65.3726 70.9643L61.3711 73.75L62.4146 75.2341L66.4161 72.4484L65.3726 70.9643Z"
				fill="#0CBC8B"
			/>
			<path
				d="M138.767 64.5784L138.6 66.3828L143.359 66.8193L143.526 65.0149L138.767 64.5784Z"
				fill="#0CBC8B"
			/>
			<path
				d="M143.612 51.5986C143.541 51.5987 143.47 51.5847 143.403 51.5573C143.337 51.53 143.277 51.4899 143.226 51.4393L140.677 48.9024C140.624 48.8523 140.583 48.7923 140.554 48.726C140.525 48.6596 140.51 48.5883 140.51 48.5161C140.509 48.4439 140.523 48.3724 140.55 48.3055C140.578 48.2387 140.618 48.178 140.67 48.127C140.721 48.076 140.782 48.0356 140.849 48.0083C140.916 47.9809 140.988 47.9672 141.061 47.9678C141.133 47.9684 141.205 47.9834 141.272 48.0119C141.338 48.0404 141.399 48.0818 141.449 48.1337L143.999 50.6706C144.075 50.7467 144.127 50.8435 144.148 50.9489C144.169 51.0544 144.158 51.1637 144.117 51.263C144.076 51.3623 144.006 51.4472 143.916 51.5069C143.826 51.5667 143.72 51.5986 143.612 51.5986Z"
				fill="#0CBC8B"
			/>
			<path
				d="M141.062 51.5986C140.954 51.5986 140.848 51.5667 140.758 51.5069C140.669 51.4472 140.599 51.3623 140.557 51.263C140.516 51.1637 140.505 51.0544 140.526 50.9489C140.547 50.8435 140.599 50.7467 140.676 50.6706L143.225 48.1337C143.276 48.0818 143.336 48.0404 143.402 48.0119C143.469 47.9834 143.541 47.9684 143.613 47.9678C143.686 47.9672 143.758 47.9809 143.825 48.0083C143.892 48.0356 143.953 48.076 144.004 48.127C144.056 48.178 144.096 48.2387 144.124 48.3055C144.151 48.3724 144.165 48.4439 144.164 48.5161C144.164 48.5883 144.149 48.6596 144.12 48.726C144.091 48.7923 144.05 48.8523 143.998 48.9024L141.448 51.4393C141.397 51.4899 141.337 51.53 141.271 51.5573C141.205 51.5846 141.134 51.5987 141.062 51.5986Z"
				fill="#0CBC8B"
			/>
			<path
				opacity="0.4"
				d="M98.7314 90.8009C114.776 90.8009 127.783 77.8584 127.783 61.8931C127.783 45.9278 114.776 32.9854 98.7314 32.9854C82.6866 32.9854 69.6797 45.9278 69.6797 61.8931C69.6797 77.8584 82.6866 90.8009 98.7314 90.8009Z"
				fill="#0CBC8B"
			/>
			<path
				d="M98.7315 82.1102C109.952 82.1102 119.049 73.0588 119.049 61.8935C119.049 50.7281 109.952 41.6768 98.7315 41.6768C87.5105 41.6768 78.4141 50.7281 78.4141 61.8935C78.4141 73.0588 87.5105 82.1102 98.7315 82.1102Z"
				fill="#0CBC8B"
			/>
			<path
				d="M94.5779 70.5665C94.4184 70.5666 94.2605 70.5345 94.1138 70.4723C93.9671 70.41 93.8345 70.3187 93.7242 70.2041L87.2573 63.5072C87.1476 63.3961 87.0611 63.2644 87.0029 63.1198C86.9447 62.9752 86.9158 62.8206 86.9181 62.6648C86.9203 62.509 86.9536 62.3553 87.016 62.2124C87.0784 62.0695 87.1686 61.9404 87.2815 61.8324C87.3944 61.7245 87.5277 61.6399 87.6736 61.5836C87.8196 61.5273 87.9753 61.5003 88.1318 61.5043C88.2883 61.5082 88.4425 61.543 88.5854 61.6067C88.7283 61.6703 88.857 61.7616 88.9642 61.8751L94.6388 67.7513L109.474 54.5088C109.59 54.4038 109.725 54.3226 109.872 54.2699C110.019 54.2171 110.175 54.1939 110.331 54.2016C110.488 54.2093 110.641 54.2477 110.782 54.3146C110.923 54.3816 111.05 54.4756 111.154 54.5915C111.258 54.7073 111.339 54.8426 111.39 54.9895C111.442 55.1364 111.464 55.292 111.455 55.4474C111.445 55.6027 111.405 55.7547 111.337 55.8946C111.268 56.0344 111.172 56.1594 111.055 56.2622L95.3683 70.2648C95.1514 70.4591 94.8698 70.5666 94.5779 70.5665Z"
				fill="white"
			/>
			<path
				d="M165.629 14.5075V19.9776H31.8359V14.5075C31.8359 13.0657 32.4115 11.683 33.4361 10.6635C34.4607 9.64403 35.8503 9.07129 37.2992 9.07129H160.165C161.614 9.07129 163.004 9.64403 164.029 10.6635C165.053 11.683 165.629 13.0657 165.629 14.5075Z"
				fill="#292A2E"
			/>
			<path
				d="M6.82803 110.112C5.72787 110.111 4.64404 109.847 3.66877 109.34C2.69351 108.834 1.85568 108.1 1.2266 107.202C0.59752 106.304 0.195806 105.268 0.0556473 104.182C-0.084511 103.096 0.0410357 101.993 0.421603 100.966C0.807857 99.9228 10.7153 74.4745 12.4408 70.0518L24.3637 76.9557C23.394 79.4428 19.8804 88.8692 17.0577 96.4628L21.7472 95.9555L23.2232 108.379L7.56794 110.073C7.32219 110.099 7.07519 110.112 6.82803 110.112Z"
				fill="#367CFF"
			/>
			<path
				d="M15.0121 110.505L12.9297 120.819H53.376L51.0463 110.309L15.0121 110.505Z"
				fill="#262626"
			/>
			<path
				d="M45.8261 64.7592C45.7843 64.7538 44.637 69.0502 35.6826 68.3816C29.4836 67.9195 27.9721 63.7209 27.8301 63.7209C28.4984 63.5796 29.5838 63.4328 29.8806 63.2752C30.8567 62.7533 31.5677 62.118 31.9257 60.7546C32.2056 59.6883 32.3482 55.5838 32.3482 53.5244L41.8871 55.6572C41.7542 57.9803 40.8236 63.3658 44.0852 64.3189C44.6279 64.4766 45.2124 64.6215 45.8261 64.7592Z"
				fill="#E98862"
			/>
			<path
				d="M41.5574 60.2242C37.797 60.5196 32.9663 58.9396 32.0487 53.9474L31.2208 49.4429C30.3032 44.4507 32.1946 39.9241 36.9185 39.0645C41.6422 38.2049 45.9171 41.6092 46.8345 46.6014L47.2011 48.5958C47.5526 50.5112 48.2663 59.6976 41.5574 60.2242Z"
				fill="#E98862"
			/>
			<path
				d="M23.7573 64.412C20.3781 64.6615 16.9324 65.0255 14.4459 67.3162C11.4513 70.0749 10.9086 73.4669 12.8191 82.8016C14.4628 90.8336 13.8109 94.3434 14.9105 109.165C14.9429 109.601 15.0123 110.504 15.0123 110.504L51.161 110.445C51.161 110.445 54.5482 90.7806 56.4183 81.7111C57.8404 74.816 61.5758 66.4455 45.8261 64.7586C45.7846 64.7541 33.1529 63.7183 23.7573 64.412Z"
				fill="#367CFF"
			/>
			<path
				d="M15.0111 110.595C14.9876 110.595 14.9649 110.586 14.9479 110.57C14.931 110.553 14.921 110.531 14.9201 110.508L13.7872 81.2475C13.7863 81.2235 13.795 81.2001 13.8114 81.1825C13.8279 81.1649 13.8506 81.1545 13.8748 81.1536C13.8868 81.1526 13.899 81.1542 13.9104 81.1581C13.9218 81.162 13.9323 81.1683 13.9412 81.1764C13.9501 81.1846 13.9572 81.1945 13.962 81.2056C13.9668 81.2166 13.9693 81.2285 13.9693 81.2406L15.1022 110.501C15.1031 110.525 15.0943 110.548 15.0779 110.566C15.0615 110.584 15.0387 110.594 15.0146 110.595L15.0111 110.595Z"
				fill="#0C0C0C"
			/>
			<path
				d="M73.0171 74.8589L75.5568 69.1902C75.6126 69.0657 75.6939 68.9541 75.7954 68.8625C75.897 68.7709 76.0166 68.7014 76.1466 68.6583C76.2767 68.6152 76.4143 68.5996 76.5507 68.6124C76.6872 68.6252 76.8195 68.6661 76.9392 68.7325C77.1565 68.862 77.3177 69.0672 77.3915 69.3083C77.4654 69.5494 77.4465 69.8092 77.3387 70.0372L76.7942 71.2526L80.27 68.2123C80.6504 67.868 81.3389 67.6868 81.6995 68.1369C81.8496 68.3011 81.9394 68.5111 81.9541 68.7327C81.9688 68.9542 81.9076 69.1741 81.7806 69.3566C81.7848 69.3592 81.7886 69.3624 81.792 69.366C81.7942 69.3691 81.7962 69.3724 81.7979 69.3758L82.3846 68.8443C82.4933 68.7459 82.6228 68.6729 82.7634 68.6306C82.9041 68.5883 83.0525 68.5777 83.1978 68.5997C83.3431 68.6217 83.4817 68.6756 83.6033 68.7576C83.725 68.8396 83.8268 68.9476 83.9012 69.0737C84.0144 69.2747 84.0549 69.5082 84.0158 69.7353C83.9766 69.9624 83.8603 70.1692 83.6863 70.3212L83.4367 70.5473C83.4398 70.5575 83.4411 70.5682 83.4407 70.5788C83.645 70.465 83.8834 70.4272 84.1132 70.4722C84.343 70.5172 84.5492 70.642 84.695 70.8244C84.8486 71.0286 84.9192 71.283 84.8926 71.5367C84.866 71.7904 84.7443 72.0249 84.5517 72.1932L83.9325 72.7549C83.9469 72.7531 83.9615 72.7548 83.9752 72.7597C83.9888 72.7647 84.001 72.7728 84.0108 72.7834C84.0219 72.7965 84.0289 72.8126 84.0311 72.8296C84.2413 72.7785 84.4626 72.7976 84.6609 72.8838C84.8593 72.97 85.0236 73.1187 85.1288 73.3069C85.2353 73.5069 85.2707 73.7369 85.2294 73.9595C85.1882 74.1821 85.0726 74.3843 84.9014 74.5333L79.6684 79.2739C78.9364 79.9371 78.0219 80.3676 77.0423 80.5103C76.0627 80.653 75.0626 80.5013 74.1702 80.0748C73.4007 79.7071 72.8056 79.0552 72.5114 78.2578C72.2172 77.4604 72.2471 76.5803 72.5948 75.8045L73.0171 74.8589Z"
				fill="#E98862"
			/>
			<g opacity="0.2">
				<path
					d="M78.3556 72.3825C78.3717 72.4 78.3941 72.4105 78.418 72.4117C78.4418 72.413 78.4652 72.4048 78.483 72.3891L81.7849 69.4935C81.7938 69.4857 81.8012 69.4761 81.8064 69.4654C81.8117 69.4547 81.8148 69.4431 81.8155 69.4312C81.8163 69.4194 81.8146 69.4074 81.8108 69.3962C81.8069 69.3849 81.8008 69.3745 81.7929 69.3656C81.785 69.3567 81.7754 69.3494 81.7646 69.3442C81.7539 69.3389 81.7422 69.3359 81.7303 69.3351C81.7183 69.3344 81.7064 69.336 81.695 69.3399C81.6837 69.3437 81.6733 69.3498 81.6643 69.3576L78.3629 72.253C78.3539 72.2608 78.3466 72.2704 78.3413 72.2811C78.336 72.2918 78.333 72.3034 78.3322 72.3153C78.3315 72.3272 78.3331 72.3391 78.337 72.3503C78.3408 72.3616 78.3469 72.372 78.3548 72.3809L78.3556 72.3825Z"
					fill="#0C0C0C"
				/>
			</g>
			<g opacity="0.2">
				<path
					d="M79.5981 73.8667C79.6143 73.8842 79.6367 73.8946 79.6606 73.8958C79.6844 73.897 79.7078 73.8888 79.7256 73.8731L83.4104 70.6418C83.4194 70.6339 83.4267 70.6244 83.432 70.6137C83.4372 70.603 83.4403 70.5913 83.4411 70.5795C83.4418 70.5676 83.4402 70.5557 83.4363 70.5444C83.4324 70.5332 83.4263 70.5228 83.4184 70.5138C83.402 70.4965 83.3796 70.486 83.3557 70.4846C83.3318 70.4831 83.3083 70.4907 83.2898 70.5059L79.605 73.7372C79.5961 73.745 79.5887 73.7546 79.5835 73.7653C79.5782 73.776 79.5751 73.7876 79.5744 73.7995C79.5737 73.8114 79.5753 73.8233 79.5792 73.8345C79.583 73.8458 79.5891 73.8562 79.597 73.8651L79.5981 73.8667Z"
					fill="#0C0C0C"
				/>
			</g>
			<g opacity="0.2">
				<path
					d="M80.936 75.4817C80.9522 75.4992 80.9746 75.5097 80.9984 75.5109C81.0223 75.5121 81.0456 75.5039 81.0635 75.4882L84.0027 72.9107C84.0209 72.8948 84.0319 72.8724 84.0334 72.8484C84.035 72.8244 84.0268 72.8007 84.0108 72.7827C83.9949 72.7646 83.9723 72.7536 83.9482 72.7521C83.9241 72.7506 83.9003 72.7587 83.8822 72.7746L80.9429 75.3523C80.934 75.3602 80.9266 75.3697 80.9214 75.3804C80.9161 75.3911 80.913 75.4027 80.9123 75.4146C80.9115 75.4265 80.9132 75.4384 80.917 75.4496C80.9209 75.4609 80.927 75.4713 80.9349 75.4802L80.936 75.4817Z"
					fill="#0C0C0C"
				/>
			</g>
			<path
				d="M45.0433 55.6409C44.3684 52.2661 44.0588 50.0146 44.0947 47.6392C45.0455 45.9797 44.7954 44.0752 44.5793 43.2522C45.5111 43.7581 45.9008 43.8139 46.7779 43.8139C46.7779 43.8139 45.2055 37.5349 39.8614 35.8453C38.878 35.4648 36.3696 34.9841 33.0381 35.4387C30.2045 35.8254 23.2195 37.9336 22.2208 45.7584V45.763C22.1876 46.0225 21.5171 53.2318 20.1801 56.6908C18.3825 61.341 15.8591 62.7857 15.9231 68.1507C16.0054 75.0518 28.7556 75.9705 36.4468 75.4534C44.138 74.9362 48.4296 72.421 48.4857 67.4476C48.5476 61.8753 46.3938 62.3929 45.0433 55.6409Z"
				fill="#0A0A0A"
			/>
			<path
				d="M45.0453 52.8126C43.6097 53.0737 42.165 51.7572 41.9026 50.3289C41.6402 48.9007 42.6589 47.9008 44.0945 47.6396L45.0453 52.8126Z"
				fill="#E98862"
			/>
			<path
				d="M60.4854 109.083C59.0414 109.082 57.6346 108.627 56.4669 107.782C55.2992 106.936 54.4307 105.745 53.986 104.378L44.498 75.1792L57.4937 70.998L62.4835 86.3561L71.107 72.7103L82.6693 79.9446L66.265 105.904C65.6503 106.877 64.7977 107.679 63.7869 108.235C62.7762 108.791 61.6403 109.082 60.4854 109.083Z"
				fill="#367CFF"
			/>
			<path
				d="M52.8294 101.021C52.8238 101.021 52.8182 101.02 52.8127 101.019C52.7889 101.015 52.7679 101.001 52.7543 100.981C52.7406 100.962 52.7354 100.937 52.7398 100.914L55.9652 83.4922C55.9695 83.4686 55.9832 83.4476 56.0031 83.434C56.0229 83.4203 56.0475 83.4151 56.0712 83.4195C56.095 83.4238 56.1161 83.4374 56.1298 83.4572C56.1435 83.477 56.1487 83.5014 56.1444 83.525L52.919 100.947C52.9151 100.967 52.9041 100.986 52.8877 101C52.8713 101.013 52.8507 101.021 52.8294 101.021Z"
				fill="#0C0C0C"
			/>
			<path
				d="M63.6442 90.4682C63.624 90.4682 63.6044 90.4616 63.5884 90.4493C63.5724 90.437 63.561 90.4198 63.5559 90.4004L62.4814 86.2823C62.4784 86.2708 62.4777 86.2588 62.4794 86.247C62.481 86.2352 62.485 86.2239 62.4911 86.2136C62.4971 86.2034 62.5052 86.1944 62.5147 86.1872C62.5243 86.1801 62.5351 86.1748 62.5467 86.1718C62.5583 86.1689 62.5703 86.1682 62.5822 86.1698C62.594 86.1715 62.6054 86.1754 62.6157 86.1815C62.626 86.1875 62.6351 86.1955 62.6423 86.205C62.6495 86.2145 62.6547 86.2253 62.6577 86.2368L63.7322 90.3549C63.7382 90.3782 63.7347 90.4029 63.7224 90.4236C63.7102 90.4443 63.6902 90.4593 63.6668 90.4653C63.6594 90.4673 63.6518 90.4683 63.6442 90.4682Z"
				fill="#0C0C0C"
			/>
			<path
				d="M4.145 121H186.189C186.237 121 186.284 120.981 186.318 120.947C186.352 120.913 186.371 120.867 186.371 120.819C186.371 120.771 186.352 120.725 186.318 120.691C186.284 120.657 186.237 120.638 186.189 120.638H4.145C4.0967 120.638 4.05038 120.657 4.01623 120.691C3.98208 120.725 3.96289 120.771 3.96289 120.819C3.96289 120.867 3.98208 120.913 4.01623 120.947C4.05038 120.981 4.0967 121 4.145 121Z"
				fill="#292A2E"
			/>
		</g>
		<defs>
			<clipPath id="clip0_4566_99995">
				<rect width="193" height="121" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

export default ProfileReview;
