import styled from 'styled-components';
import { createTheme, Grid } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';

const theme = createTheme();

export const CreateUpdatePrescriptionContainer = styled.div`
	display: flex;
`;
export const PrescriptionListContainer = styled(Grid)`
	margin-top: 2px;
` as typeof Grid;
export const PrescriptionContainer = styled(Grid)`
	display: flex;
	margin: 10px 0 0 20px;
	padding-bottom: 80px;
	overflow-y: auto;
	height: calc(100dvh - 185px);
	@media (max-width: 1024px) {
		margin: 0px 0 0 10px;
		height: calc(100dvh - 167px);
		overflow-y: auto;
		display: block;
	}
` as typeof Grid;
export const PrescriptionCardContainer = styled(Grid)`
	max-width: 90%;
	flex-basis: 90%;

	@media (max-width: 600px) {
		max-width: 100%;
		flex-basis: 100%;
		padding: 0;
		margin-bottom: 16px;
	}
` as typeof Grid;

export const SideBarSection = styled(Grid)`
	display: flex;
	margin: ${theme.spacing(2.5, 0, 0, 2.5)};
` as typeof Grid;
export const SideBarContainer = styled.div`
	position: fixed;
`;

export const PreviousContainer = styled(Grid)`
	padding: ${theme.spacing(2.5, 27)};
` as typeof Grid;

export const SimpleParagraph = styled.p`
	font-size: 14px;
	margin-bottom: ${theme.spacing(0.5)}px;
`;

export const StyledPagination = styled(Pagination)`
	display: flex;
	justify-content: center;
	padding-bottom: 0px;
` as typeof Pagination;
