import React from 'react';
import { BrowserRouter as Router, Redirect } from 'react-router-dom';
import ClientContextProvider from './context/client-context';
import HospitalContextProvider from './context/hospital-context';
import DoctorContextProvider from './context/doctor-context';
import RelationsProvider from './context/relations-context';
import WaitingListsContextProvider from './context/waiting-list-context';
import HistoryProvider from './context/history-context';
import UserProvider from './context/user-context';
import AIContextProvider from './context/ai-context';
import { default as Amplify } from '@aws-amplify/core';
import Storage from '@aws-amplify/storage';
import { default as awsConfig } from 'aws-exports';
import Routes from './Routes';
import LoaderContext from './context/loader-context';
import StripeProvider from './context/stripe-verification-context';
import AutoUpdateProvider from './provider/AutoUpdateProvider';
import ErrorBoundary from 'provider/ErrorBoundary';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

Amplify.configure(awsConfig);
Storage.configure({ ...awsConfig, level: 'public' });

export const App = () => {
	return (
		<Router>
			<ErrorBoundary>
				<LoaderContext>
					<RelationsProvider>
						<HistoryProvider>
							<HospitalContextProvider>
								<DoctorContextProvider>
									<ClientContextProvider>
										<WaitingListsContextProvider>
											<AIContextProvider>
												<UserProvider>
													<StripeProvider>
														<AutoUpdateProvider>
															{window.location.pathname.includes(
																'index.html'
															) && <Redirect to="/" />}
															<LocalizationProvider
																dateAdapter={AdapterMoment}
															>
																<Routes />
															</LocalizationProvider>
														</AutoUpdateProvider>
													</StripeProvider>
												</UserProvider>
											</AIContextProvider>
										</WaitingListsContextProvider>
									</ClientContextProvider>
								</DoctorContextProvider>
							</HospitalContextProvider>
						</HistoryProvider>
					</RelationsProvider>
				</LoaderContext>
			</ErrorBoundary>
		</Router>
	);
};
