export const syncTeamsForSecretary = /* GraphQL */ `
	query SyncTeams(
		$filter: ModelTeamsFilterInput
		$limit: Int
		$nextToken: String
		$lastSync: AWSTimestamp
	) {
		syncTeams(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
			items {
				Member {
					id
					lastWaitingListID
					doctor {
						name
						__typename
					}
					__typename
				}
				__typename
			}
			nextToken
			startedAt
			__typename
		}
	}
`;
