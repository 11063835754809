import { Grid } from '@material-ui/core';
import React, { FC } from 'react';
import { PictureSection, StyledAvatar, TitleGrid } from './styles';
import SplitButton from '../splitButton/SplitButton';
import { insurancesOptions } from '../../shared/constants';
import useMobile from 'hooks/useMobile';
import MobileDialogHeader from '../dialog/MobileDialogHeader';

interface Props {
	isDisabled: boolean;
	userImg: string | undefined;
	handleProfilePicture: (e: React.ChangeEvent<HTMLInputElement>) => void;
	isUpdate: boolean;
	insuranceValue: number;
	setInsuranceValue: (id: number) => void;
	handleClose?: () => void;
	idType: string;
}

const PatientTitle: FC<Props> = ({
	isDisabled,
	isUpdate,
	insuranceValue,
	setInsuranceValue,
	userImg,
	handleProfilePicture,
	idType,
	handleClose,
}) => {
	const { isMobile } = useMobile();

	const title = isUpdate ? 'Editar Paciente' : 'Nuevo Paciente';

	return (
		<Grid container>
			{!isMobile ? (
				<TitleGrid item md={6}>
					{title}
				</TitleGrid>
			) : (
				<MobileDialogHeader goBack={handleClose} title={title} />
			)}
			<PictureSection item xs={12} md={6}>
				<label htmlFor="avatar-input-file">
					<StyledAvatar src={userImg} alt="profile-picture" />
				</label>
				<input
					type="file"
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleProfilePicture(e)}
					id="avatar-input-file"
					accept="image/*"
					hidden
				/>
				<SplitButton
					options={insurancesOptions}
					value={insuranceValue}
					setValue={(value) => setInsuranceValue(value)}
				/>
			</PictureSection>
		</Grid>
	);
};

export default PatientTitle;
