import styled from 'styled-components';
import { createTheme, Grid } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';

const theme = createTheme();

export const Container = styled(Grid)`
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
` as typeof Grid;

export const PreviousContainer = styled(Grid)`
	width: 100%;
	padding: ${theme.spacing(1.75, 27)};
	flex-basis: 0;
` as typeof Grid;

export const Paragraph = styled.p`
	font-size: 14px;
	margin-bottom: ${theme.spacing(2.5)}px;
`;

export const List = styled.ul`
	list-style-type: circle;
	margin-left: 25px;
	margin-bottom: ${theme.spacing(2)}px;
`;

export const SimpleParagraph = styled.p`
	font-size: 14px;
`;

export const LastParagraph = styled.p`
	font-size: 14px;
	margin-top: ${theme.spacing(2.5)}px;
`;

export const StyledPagination = styled(Pagination)`
	padding-bottom: ${theme.spacing(2)}px;
` as typeof Pagination;
