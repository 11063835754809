import React from 'react';
import LineSeparator from '../lineSeparator/LineSeparator';
import { MedicineType } from '../../context/types';
import { IconContainer, StyledLocalPharmacyIcon } from '../../shared/style';
import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

interface Props {
	className?: string;
	medicine: MedicineType;
	deleteMedicineHandler?: () => void;
	editMedicineHandler?: () => void;
	isSimpleRow?: boolean;
	onClick?: () => void;
}

const MedicineRow: React.FC<Props> = ({
	medicine,
	deleteMedicineHandler,
	editMedicineHandler,
	isSimpleRow,
	onClick,
}) => {
	return (
		<div
			className={`medicineRow px-[16px] md:px-0 ${isSimpleRow ? 'w-full !px-0' : ''}`}
			onClick={onClick}
		>
			<div className={`md:ml-9 grid grid-cols-12 ${isSimpleRow ? 'py-[12px]' : ''}`}>
				<div
					className={`${
						isSimpleRow ? 'col-span-12' : 'col-span-8'
					} md:col-span-6 flex items-center`}
				>
					{!isSimpleRow && (
						<IconContainer>
							<StyledLocalPharmacyIcon />
						</IconContainer>
					)}
					<Tooltip title={medicine.name as string} arrow>
						<p data-fx-name="medicineName">{medicine.name as string}</p>
					</Tooltip>
				</div>
				{isSimpleRow ? null : (
					<div className={`col-span-4 md:col-span-6 flex justify-end md:pr-8`}>
						<IconButton onClick={editMedicineHandler} data-fx-name="editIconButton">
							<EditRoundedIcon />
						</IconButton>

						<IconButton
							onClick={deleteMedicineHandler}
							data-fx-name="deleteIconButton"
							className="!pr-0 !md:pr-[12px]"
						>
							<DeleteForeverIcon />
						</IconButton>
					</div>
				)}
			</div>
			<LineSeparator />
		</div>
	);
};

export default MedicineRow;
