const TensionIcon = () => (
	<svg width="20" height="20" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M12.89 0.550039C12.55 -0.129961 11.44 -0.129961 11.1 0.550039L8 6.76004L6.89 4.55004C6.72 4.21004 6.38 4.00004 6 4.00004H0V6.00004H5.38L7.1 9.45004C7.28 9.79004 7.62 10 8 10C8.38 10 8.72 9.79004 8.89 9.45004L12 3.24004L13.11 5.45004C13.28 5.79004 13.62 6.00004 14 6.00004H20V4.00004H14.62L12.89 0.550039Z"
			fill="black"
			fill-opacity="0.54"
		/>
	</svg>
);

export default TensionIcon;
