import React, { Fragment, useContext, useRef } from 'react';
import { Button, Divider, Grid } from '@material-ui/core';
import CustomMenu from '../../../../menu/CustomMenu';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {
	HeaderListItem,
	PrescriptionList,
	DeleteIconButton,
	LabHeaderGrid,
	LabHeaderActionGrid,
	BodyListItem,
	BodyContainer,
	LabBodyGrid,
	LabBodyActionGrid,
	DescriptionListItem,
	StyledTextField,
	FooterListItem,
} from '../../../../../shared/style';
import PrintIcon from '@material-ui/icons/Print';
import ReactToPrint from 'react-to-print';
import PrescriptionPrintLayout from '../../../../../printLayout/Prescription';
import { ClientsContext } from '../../../../../context/client-context';
import { ClientContextProps } from '../../../../../context/types';
import { LabImages, Medicine } from '../../../../../shared/type';
import moment from 'moment';

interface OptionMenu {
	id: number | string;
	label: string;
	onClick: () => void;
}

interface Props {
	optionsForMenu: OptionMenu[];
	labImages: LabImages[];
	handleDeleteLabImage: (id: number | string) => void;
	handleChangeLabImage: (id: number | string, value: string) => void;
	headerMenu: null | HTMLElement;
	handleClick: (event: React.MouseEvent<HTMLElement>) => void;
	handleClose: () => void;
	height?: string;
}

const LabList: React.FC<Props> = ({
	optionsForMenu,
	labImages,
	handleDeleteLabImage,
	handleChangeLabImage,
	headerMenu,
	handleClick,
	handleClose,
	height,
}) => {
	const { selectedClient } = useContext(ClientsContext) as ClientContextProps;
	const printRef = useRef(null);
	return (
		<div
			style={{
				border: '1px solid rgba(0, 0, 0, 0.12)',
			}}
		>
			<HeaderListItem>
				<Grid container>
					<LabHeaderGrid item xs={11}>
						Nombre y descripción
					</LabHeaderGrid>
					<LabHeaderActionGrid item xs={1}>
						<CustomMenu
							options={optionsForMenu}
							headerMenu={headerMenu}
							handleClick={handleClick}
							handleClose={handleClose}
						/>
					</LabHeaderActionGrid>
				</Grid>
			</HeaderListItem>
			<PrescriptionList
				style={{
					maxHeight: height ? `calc(${height} + 140px)` : 'calc(100dvh - 300px)',
				}}
			>
				{labImages.map((l) => (
					<Fragment key={l.id}>
						<BodyListItem>
							<BodyContainer container>
								<LabBodyGrid item xs={11} data-fx-name="selectedLabImage">
									{l.label}
								</LabBodyGrid>
								<LabBodyActionGrid item xs={1}>
									<DeleteIconButton
										aria-label="add"
										onClick={() => handleDeleteLabImage(l.id)}
									>
										<DeleteForeverIcon fontSize="small" />
									</DeleteIconButton>
								</LabBodyActionGrid>
							</BodyContainer>
						</BodyListItem>
						<DescriptionListItem>
							<StyledTextField
								fullWidth
								id="description"
								label="Descripción"
								variant="outlined"
								value={l.description}
								onChange={(e: any) => handleChangeLabImage(l.id, e.target.value)}
							/>
						</DescriptionListItem>
					</Fragment>
				))}
				<Divider />
			</PrescriptionList>
			<FooterListItem>
				<ReactToPrint
					removeAfterPrint
					trigger={() => (
						<Button color="primary" startIcon={<PrintIcon />}>
							Imprimir
						</Button>
					)}
					content={() => printRef.current}
				/>
				<div ref={printRef} className="printLayout">
					<PrescriptionPrintLayout
						client={selectedClient!}
						prescription={{
							creationDate: moment(new Date()).format('DD/MM/YYYY'),
							medicines: [] as Medicine[],
							analisis: labImages,
						}}
					/>
				</div>
			</FooterListItem>
		</div>
	);
};

export default LabList;
