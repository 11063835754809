import React from 'react';

const VisaIcon = () => (
	<svg
		width="29"
		height="18"
		viewBox="0 0 2105 684"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M912.299 671.753H740.619L847.917 12.3394H1019.61L912.299 671.753ZM596.192 12.3394L432.52 465.889L413.153 368.222L413.17 368.258L355.403 71.7121C355.403 71.7121 348.418 12.3394 273.964 12.3394H3.38302L0.208008 23.5049C0.208008 23.5049 82.9522 40.7204 179.79 98.876L328.945 671.771H507.821L780.96 12.3394H596.192V12.3394ZM1946.54 671.753H2104.18L1966.74 12.3218H1828.73C1765 12.3218 1749.48 61.4638 1749.48 61.4638L1493.43 671.753H1672.4L1708.19 573.804H1926.43L1946.54 671.753ZM1757.63 438.496L1847.83 191.727L1898.58 438.496H1757.63ZM1506.85 170.913L1531.35 29.308C1531.35 29.308 1455.75 0.556641 1376.94 0.556641C1291.75 0.556641 1089.43 37.7924 1089.43 218.856C1089.43 389.213 1326.88 391.329 1326.88 480.812C1326.88 570.294 1113.89 554.26 1043.6 497.833L1018.08 645.894C1018.08 645.894 1094.74 683.13 1211.86 683.13C1329.02 683.13 1505.76 622.47 1505.76 457.369C1505.76 285.919 1266.17 269.956 1266.17 195.414C1266.19 120.854 1433.39 130.432 1506.85 170.913V170.913Z"
			fill="#2566AF"
		/>
		<path
			d="M413.17 368.24L355.403 71.6948C355.403 71.6948 348.418 12.3223 273.964 12.3223H3.38302L0.208008 23.4877C0.208008 23.4877 130.26 50.4399 255.002 151.423C374.277 247.943 413.17 368.24 413.17 368.24Z"
			fill="#E6A540"
		/>
	</svg>
);

export default VisaIcon;
