import React from 'react';

const AlcoholIcon = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M17.5 4.16667V2.5H2.5V4.16667L9.16667 11.6667V15.8333H5V17.5H15V15.8333H10.8333V11.6667L17.5 4.16667ZM6.19167 5.83333L4.71667 4.16667H15.2917L13.8083 5.83333H6.19167Z"
				fill="#455A64"
			/>
		</svg>
	);
};

export default AlcoholIcon;
