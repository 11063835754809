import styled from 'styled-components';
import { Button, Chip, createTheme, Grid, TextField } from '@material-ui/core';

const theme = createTheme();

export const InputContainer = styled(Grid)`
	margin-bottom: ${theme.spacing(2)};
` as typeof Grid;

export const ChipLayout = styled(Chip)`
	margin-right: ${theme.spacing(2)}px;
	margin-top: ${theme.spacing(0.4)}px;
	margin-bottom: ${theme.spacing(0.8)}px;
` as typeof Chip;

export const StyledTextField = styled(TextField)`
	margin-right: ${theme.spacing(2)}px;
	width: 20%;
` as typeof TextField;

export const AddMeasureBtn = styled(Button)`
	top: 2px;
` as typeof Button;
