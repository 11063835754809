import { Grid } from '@material-ui/core';
import React, { FC } from 'react';
import MobileDialogHeader from 'components/dialog/MobileDialogHeader';

interface Props {
	title: string;
	goBack: () => void;
}

const HistoryTitle: FC<Props> = ({ title, goBack }) => {
	return (
		<Grid container>
			<MobileDialogHeader goBack={goBack} title={title} />
		</Grid>
	);
};

export default HistoryTitle;
