import React, { FC } from 'react';
import { Tooltip, Zoom, withStyles } from '@material-ui/core';
import { SideBarListItem, StyledListItemText, StyledIconButton } from '../../../../../shared/style';
import AddIcon from '@material-ui/icons/Add';
import { SidebarOptions } from '../../../../../shared/type';

const SideBarListItemStyled = withStyles({
	selected: {
		backgroundColor: 'rgba(25, 118, 210, 0.08) !important',
	},
})(SideBarListItem) as typeof SideBarListItem;

interface Props {
	option: SidebarOptions;
	handleAddOption: (value: SidebarOptions) => void;
}

const SidebarTooltip: FC<Props> = ({ option, handleAddOption }) => {
	return (
		<Tooltip
			placement="top"
			TransitionComponent={Zoom}
			title={option.label}
			enterNextDelay={1000}
			disableTouchListener
			leaveDelay={100}
			arrow
		>
			<SideBarListItemStyled onClick={() => handleAddOption(option)} button divider>
				<StyledListItemText primary={option.label} />
				<StyledIconButton aria-label="add">
					<AddIcon fontSize="small" />
				</StyledIconButton>
			</SideBarListItemStyled>
		</Tooltip>
	);
};

export default SidebarTooltip;
