import useMobile from 'hooks/useMobile';
import React from 'react';

const NoItemsIcon = () => {
	const { isMobile } = useMobile();

	let width = '182';
	let height = '200';

	if (isMobile) {
		width = '132';
		height = '135';
	}

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 182 200"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				opacity="0.1"
				d="M91.0002 199.643C141.258 199.643 182 158.906 182 108.655C182 58.4037 141.258 17.667 91.0002 17.667C40.7422 17.667 0 58.4037 0 108.655C0 158.906 40.7422 199.643 91.0002 199.643Z"
				fill="#367CFF"
			/>
			<path
				d="M60.9451 51.5007C60.8943 51.5006 60.8446 51.4865 60.8012 51.46C60.7579 51.4336 60.7226 51.3957 60.6994 51.3506C60.6761 51.3055 60.6656 51.2548 60.6692 51.2041C60.6727 51.1535 60.6901 51.1048 60.7195 51.0633C60.7705 50.9907 65.7803 43.7495 60.1879 37.1482C59.1979 35.9734 57.963 35.0294 56.5696 34.3823C55.1763 33.7352 53.6582 33.4006 52.1219 33.402H52.0481C52.5792 35.2467 52.4844 36.8868 51.7324 38.1451C51.412 38.7137 50.9391 39.1815 50.367 39.4958C49.795 39.8102 49.1465 39.9586 48.4947 39.9243C47.9552 39.8802 47.4402 39.6796 47.013 39.3472C46.5857 39.0149 46.2647 38.5651 46.0893 38.0531C45.4742 36.4489 46.117 34.8936 47.8109 33.8912C48.8841 33.2994 50.0766 32.9565 51.3003 32.8879C50.9023 31.8326 50.3848 30.8262 49.7578 29.8886C47.7385 26.8051 44.0581 24.6708 39.6603 24.0326C35.9336 23.4916 32.3619 24.1285 30.5599 25.6544C30.5038 25.702 30.4312 25.7252 30.3579 25.7192C30.2846 25.7131 30.2168 25.6782 30.1692 25.6221C30.1217 25.5661 30.0984 25.4934 30.1045 25.4201C30.1105 25.3469 30.1454 25.279 30.2015 25.2315C32.1193 23.6074 35.8632 22.921 39.7399 23.4841C44.2951 24.1451 48.1152 26.3689 50.2218 29.5852C50.9048 30.6051 51.4603 31.7047 51.876 32.8596C53.533 32.8198 55.1779 33.1523 56.6893 33.8324C58.2008 34.5125 59.5404 35.5231 60.6095 36.7896C66.484 43.7234 61.2242 51.3081 61.1705 51.3837C61.1449 51.4197 61.1112 51.4492 61.072 51.4695C61.0328 51.4898 60.9893 51.5005 60.9451 51.5007ZM51.4802 33.4234C50.2937 33.4736 49.1349 33.797 48.0939 34.3684C46.6315 35.2339 46.0898 36.5046 46.6073 37.8549C46.7475 38.2687 47.0053 38.6326 47.3491 38.9021C47.6929 39.1717 48.1079 39.3352 48.5432 39.3725C49.0912 39.3971 49.6353 39.2687 50.1146 39.0017C50.5939 38.7348 50.9894 38.3398 51.2571 37.861C51.9484 36.7053 52.0118 35.1694 51.4802 33.4234Z"
				fill="#292A2E"
			/>
			<path
				d="M25.7753 30.1264C25.3048 30.2674 24.7978 30.2191 24.3624 29.9918C23.9269 29.7646 23.5974 29.3764 23.4439 28.9099C23.2905 28.4433 23.3253 27.9353 23.5408 27.494C23.7564 27.0527 24.1357 26.713 24.598 26.5472C25.0604 26.3814 25.5692 26.4026 26.0161 26.6062C26.4631 26.8099 26.8129 27.18 26.991 27.6377C27.1691 28.0954 27.1615 28.6045 26.9698 29.0567C26.7781 29.5088 26.4174 29.8683 25.9646 30.0585C25.9027 30.0845 25.8395 30.1072 25.7753 30.1264ZM24.8537 27.0454C24.8087 27.0591 24.7645 27.075 24.7212 27.0931C24.4019 27.2275 24.1477 27.4811 24.0127 27.8001C23.8778 28.1191 23.8727 28.4782 23.9986 28.8008C24.1244 29.1235 24.3713 29.3843 24.6867 29.5277C25.002 29.671 25.3608 29.6856 25.6868 29.5683C26.0127 29.4511 26.28 29.2112 26.4317 28.8998C26.5834 28.5884 26.6075 28.2302 26.4989 27.9013C26.3902 27.5724 26.1575 27.2989 25.8502 27.139C25.5429 26.9792 25.1854 26.9456 24.8537 27.0454Z"
				fill="#292A2E"
			/>
			<path
				d="M25.7715 25.7806C25.3259 25.9135 24.8469 25.8771 24.4266 25.6783C24.0063 25.4795 23.6743 25.1323 23.4945 24.7036C23.3502 24.3599 23.3112 23.9811 23.3822 23.6152C23.4533 23.2493 23.6313 22.9126 23.8937 22.6479C24.1561 22.3831 24.4912 22.2021 24.8565 22.1277C25.2218 22.0534 25.6009 22.089 25.946 22.2301C26.2911 22.3712 26.5865 22.6115 26.795 22.9205C27.0035 23.2295 27.1157 23.5934 27.1173 23.9661C27.119 24.3389 27.01 24.7037 26.8043 25.0146C26.5985 25.3254 26.3052 25.5683 25.9614 25.7124C25.8993 25.7383 25.8359 25.7611 25.7715 25.7806ZM24.851 22.7004C24.8056 22.714 24.761 22.73 24.7174 22.7483C24.3922 22.8848 24.1345 23.1449 24.0009 23.4713C23.8674 23.7977 23.869 24.1638 24.0053 24.4891C24.1047 24.7262 24.2707 24.9294 24.4832 25.0741C24.6957 25.2188 24.9456 25.2989 25.2027 25.3046C25.4597 25.3103 25.7129 25.2414 25.9317 25.1063C26.1504 24.9711 26.3253 24.7755 26.4351 24.5431C26.545 24.3107 26.5852 24.0514 26.5508 23.7966C26.5164 23.5418 26.4089 23.3025 26.2413 23.1075C26.0737 22.9126 25.8532 22.7703 25.6065 22.698C25.3597 22.6257 25.0973 22.6264 24.851 22.7001V22.7004Z"
				fill="#292A2E"
			/>
			<path
				d="M25.2342 27.7411C26.5954 27.7411 27.6989 27.0042 27.6989 26.0952C27.6989 25.1861 26.5954 24.4492 25.2342 24.4492C23.873 24.4492 22.7695 25.1861 22.7695 26.0952C22.7695 27.0042 23.873 27.7411 25.2342 27.7411Z"
				fill="white"
			/>
			<path
				d="M26.3228 27.8614C25.9695 27.966 25.6028 28.0187 25.2343 28.0177C23.6968 28.0177 22.4916 27.1752 22.4902 26.0969C22.4888 25.0185 23.6932 24.1729 25.2305 24.1715C25.9007 24.1587 26.5601 24.3417 27.1278 24.6981C27.3826 24.831 27.5963 25.0311 27.7454 25.2768C27.8945 25.5225 27.9735 25.8043 27.9737 26.0917C27.974 26.3792 27.8954 26.6611 27.7467 26.9071C27.5979 27.153 27.3847 27.3535 27.13 27.4867C26.881 27.6509 26.609 27.7772 26.3228 27.8614ZM24.3108 24.86C23.5701 25.0817 23.0449 25.56 23.0454 26.0977C23.0454 26.8399 24.048 27.4657 25.2338 27.4648C25.7945 27.4766 26.3465 27.3247 26.8223 27.0278C27.0008 26.9456 27.152 26.8139 27.2579 26.6484C27.3638 26.4829 27.42 26.2905 27.4198 26.094C27.4196 25.8975 27.3631 25.7052 27.2569 25.5398C27.1507 25.3745 26.9993 25.2431 26.8206 25.1612C26.3444 24.8652 25.7922 24.7144 25.2316 24.7272C24.9198 24.7266 24.6096 24.7713 24.3108 24.86Z"
				fill="#292A2E"
			/>
			<path
				d="M25.8377 27.6902L25.7795 24.4895L24.9834 24.457L24.9277 27.7276L25.8377 27.6902Z"
				fill="#292A2E"
			/>
			<path
				d="M21.9634 26.7911C22.104 26.7911 22.2179 26.6772 22.2179 26.5366C22.2179 26.3961 22.104 26.2822 21.9634 26.2822C21.8229 26.2822 21.709 26.3961 21.709 26.5366C21.709 26.6772 21.8229 26.7911 21.9634 26.7911Z"
				fill="#292A2E"
			/>
			<path
				d="M22.1607 25.4297C22.3012 25.4297 22.4152 25.3158 22.4152 25.1753C22.4152 25.0348 22.3012 24.9209 22.1607 24.9209C22.0202 24.9209 21.9062 25.0348 21.9062 25.1753C21.9062 25.3158 22.0202 25.4297 22.1607 25.4297Z"
				fill="#292A2E"
			/>
			<path
				d="M106.615 36.7954C106.556 36.7954 106.498 36.7763 106.451 36.7411C106.403 36.7058 106.368 36.6562 106.35 36.5995C105.431 33.1667 105.186 29.5882 105.628 26.0621C106.345 20.9072 109.188 14.1419 118.664 11.1795C119.997 10.7508 121.365 10.4373 122.752 10.2424C123.198 7.45519 124.897 4.42904 128.694 2.34214C137.72 -2.61653 143.817 1.83247 143.877 1.87793C143.936 1.92203 143.975 1.98768 143.985 2.06045C143.996 2.13321 143.977 2.20713 143.933 2.26593C143.889 2.32473 143.823 2.36361 143.75 2.374C143.677 2.3844 143.603 2.36546 143.545 2.32136C143.487 2.27812 137.661 -1.95138 128.962 2.82826C125.412 4.77852 123.787 7.57769 123.325 10.1762C127.505 9.76463 129.506 11.1587 130.404 12.2115C130.961 12.8446 131.318 13.6272 131.432 14.462C131.547 15.2967 131.413 16.1466 131.047 16.9058C130.758 17.5192 130.29 18.0305 129.704 18.3723C129.119 18.7142 128.443 18.8705 127.767 18.8206C125.433 18.6837 123.501 16.7204 122.846 13.8187C122.63 12.8365 122.573 11.826 122.676 10.8255C121.372 11.0129 120.085 11.3087 118.83 11.7099C111.444 14.0188 107.188 18.8741 106.177 26.14C105.744 29.5861 105.982 33.0835 106.88 36.4387C106.893 36.4801 106.896 36.524 106.889 36.5667C106.881 36.6094 106.864 36.6498 106.838 36.6847C106.812 36.7195 106.779 36.7478 106.74 36.7674C106.701 36.7869 106.659 36.7971 106.615 36.7971V36.7954ZM123.241 10.7563C123.126 11.7376 123.175 12.7314 123.387 13.6965C123.986 16.351 125.718 18.1449 127.799 18.2672C128.367 18.3078 128.933 18.176 129.424 17.8891C129.915 17.6023 130.308 17.1738 130.551 16.6597C130.868 15.9978 130.983 15.2574 130.882 14.5306C130.781 13.8038 130.469 13.1227 129.983 12.5721C128.687 11.0514 126.322 10.4373 123.241 10.7563Z"
				fill="#292A2E"
			/>
			<path
				d="M150.335 4.68041C149.962 4.67968 149.596 4.57341 149.281 4.37385C148.965 4.1743 148.713 3.8896 148.552 3.55262C148.391 3.21564 148.329 2.84011 148.373 2.46938C148.417 2.09866 148.564 1.74784 148.799 1.45742C149.033 1.16701 149.345 0.948839 149.699 0.828091C150.052 0.707343 150.432 0.688942 150.795 0.775011C151.159 0.86108 151.49 1.04811 151.752 1.31452C152.013 1.58092 152.194 1.91584 152.273 2.28062C152.336 2.56974 152.332 2.8691 152.264 3.15678C152.195 3.44445 152.063 3.71316 151.877 3.94322C151.692 4.17329 151.457 4.3589 151.19 4.48645C150.923 4.614 150.631 4.68027 150.335 4.68041ZM150.339 1.27597C150.044 1.2759 149.757 1.36727 149.516 1.53749C149.275 1.70771 149.093 1.94838 148.995 2.22635C148.897 2.50432 148.888 2.80589 148.968 3.08949C149.048 3.37309 149.215 3.62477 149.444 3.80983C149.674 3.99489 149.955 4.10422 150.249 4.12275C150.544 4.14128 150.836 4.0681 151.087 3.9133C151.338 3.75849 151.535 3.52969 151.65 3.25842C151.766 2.98714 151.794 2.68676 151.732 2.39868C151.665 2.07974 151.489 1.79376 151.236 1.58916C150.982 1.38456 150.665 1.27392 150.339 1.27597Z"
				fill="#292A2E"
			/>
			<path
				d="M149.5 9.20323C148.992 9.20312 148.504 9.00828 148.135 8.65882C147.766 8.30937 147.546 7.83189 147.518 7.32467C147.491 6.81745 147.66 6.3191 147.989 5.93221C148.318 5.54531 148.783 5.29931 149.288 5.24485C149.793 5.19038 150.3 5.3316 150.704 5.63943C151.108 5.94726 151.379 6.39828 151.46 6.89964C151.542 7.40099 151.428 7.91454 151.142 8.33455C150.857 8.75457 150.421 9.04909 149.924 9.1575C149.785 9.1879 149.643 9.20323 149.5 9.20323ZM149.508 5.7985C149.405 5.79857 149.303 5.80962 149.203 5.83148C148.849 5.90923 148.538 6.11972 148.334 6.41962C148.131 6.71952 148.05 7.08603 148.108 7.44375C148.167 7.80146 148.36 8.12317 148.649 8.34268C148.937 8.56218 149.299 8.6628 149.659 8.62381C150.019 8.58483 150.351 8.40921 150.586 8.1331C150.821 7.85699 150.941 7.50138 150.922 7.13944C150.902 6.7775 150.745 6.43676 150.482 6.18733C150.219 5.9379 149.87 5.79874 149.508 5.7985Z"
				fill="#292A2E"
			/>
			<path
				d="M152.477 5.49088C152.652 4.54425 151.644 3.56484 150.227 3.3033C148.809 3.04177 147.518 3.59716 147.344 4.54379C147.169 5.49042 148.176 6.46983 149.594 6.73136C151.011 6.99289 152.302 6.43751 152.477 5.49088Z"
				fill="white"
			/>
			<path
				d="M150.295 7.07257C150.044 7.07216 149.793 7.04897 149.546 7.00328C148.849 6.88764 148.201 6.57114 147.681 6.09258C147.454 5.89848 147.279 5.65014 147.172 5.37054C147.066 5.09094 147.032 4.78909 147.074 4.4929C147.141 4.20146 147.28 3.93163 147.479 3.70833C147.678 3.48502 147.93 3.31545 148.212 3.21527C148.872 2.96617 149.587 2.90209 150.28 3.02998C150.973 3.15786 151.618 3.47284 152.145 3.94083C152.373 4.13494 152.548 4.38327 152.654 4.66287C152.76 4.94248 152.794 5.24432 152.752 5.54051C152.685 5.83195 152.546 6.10179 152.347 6.32509C152.148 6.54839 151.896 6.71797 151.614 6.81815C151.196 6.99116 150.747 7.07771 150.295 7.07257ZM149.529 3.51458C149.153 3.5098 148.779 3.58116 148.431 3.72438C148.234 3.79033 148.057 3.90433 147.915 4.05608C147.773 4.20783 147.671 4.39254 147.619 4.59351C147.596 4.79998 147.625 5.00889 147.703 5.20128C147.782 5.39366 147.907 5.56343 148.067 5.69516C148.516 6.08643 149.061 6.34988 149.646 6.45789C150.232 6.5659 150.835 6.51448 151.394 6.30903C151.591 6.24311 151.768 6.12912 151.91 5.97736C152.052 5.8256 152.153 5.64088 152.206 5.43991C152.229 5.23345 152.199 5.02459 152.121 4.83223C152.043 4.63987 151.918 4.47009 151.757 4.33826C151.316 3.93578 150.767 3.67064 150.177 3.57528C149.963 3.53548 149.746 3.51516 149.529 3.51458Z"
				fill="#292A2E"
			/>
			<path
				d="M149.591 3.23926L149.033 6.58329L149.856 6.77064L150.545 3.37589L149.591 3.23926Z"
				fill="#292A2E"
			/>
			<path
				d="M152.933 6.83662C153.082 6.83662 153.203 6.71601 153.203 6.56724C153.203 6.41846 153.082 6.29785 152.933 6.29785C152.785 6.29785 152.664 6.41846 152.664 6.56724C152.664 6.71601 152.785 6.83662 152.933 6.83662Z"
				fill="#292A2E"
			/>
			<path
				d="M153.4 5.45772C153.549 5.45772 153.67 5.33711 153.67 5.18833C153.67 5.03955 153.549 4.91895 153.4 4.91895C153.251 4.91895 153.131 5.03955 153.131 5.18833C153.131 5.33711 153.251 5.45772 153.4 5.45772Z"
				fill="#292A2E"
			/>
			<path
				d="M153.965 55.513V159.027C153.965 160.208 153.496 161.34 152.66 162.176C151.825 163.011 150.692 163.48 149.511 163.48H18.6337C17.4524 163.48 16.3195 163.011 15.4842 162.176C14.649 161.34 14.1797 160.208 14.1797 159.027V75.7224C14.1798 74.5413 14.649 73.4086 15.4843 72.5734C16.3196 71.7383 17.4525 71.269 18.6337 71.269H97.1363C98.3176 71.269 99.4505 70.7998 100.286 69.9646C101.121 69.1294 101.59 67.9967 101.59 66.8155V55.513C101.59 54.3319 102.06 53.1991 102.895 52.364C103.73 51.5288 104.863 51.0596 106.044 51.0596H149.511C150.692 51.0596 151.825 51.5288 152.66 52.364C153.496 53.1991 153.965 54.3319 153.965 55.513Z"
				fill="#367CFF"
			/>
			<path
				d="M149.511 163.757H18.6338C17.3795 163.755 16.1769 163.256 15.2899 162.37C14.4029 161.483 13.9039 160.281 13.9023 159.026V75.7222C13.9039 74.468 14.4029 73.2657 15.2899 72.3789C16.1769 71.4921 17.3795 70.9933 18.6338 70.9919H97.1364C98.2438 70.9906 99.3055 70.5501 100.089 69.7671C100.872 68.9841 101.312 67.9226 101.313 66.8153V55.5128C101.315 54.2587 101.814 53.0563 102.7 52.1694C103.587 51.2826 104.79 50.7837 106.044 50.7822H149.511C150.765 50.7836 151.968 51.2825 152.855 52.1693C153.742 53.0562 154.241 54.2586 154.242 55.5128V159.026C154.24 160.281 153.742 161.483 152.855 162.37C151.968 163.256 150.765 163.755 149.511 163.757ZM18.6338 71.5462C17.5264 71.5474 16.4647 71.9877 15.6817 72.7706C14.8986 73.5535 14.458 74.615 14.4567 75.7222V159.026C14.458 160.134 14.8985 161.195 15.6816 161.978C16.4647 162.761 17.5264 163.201 18.6338 163.203H149.511C150.618 163.201 151.68 162.761 152.463 161.978C153.246 161.195 153.686 160.134 153.688 159.026V55.5128C153.686 54.4056 153.246 53.344 152.463 52.5611C151.68 51.7782 150.618 51.3378 149.511 51.3365H106.044C104.937 51.3378 103.875 51.7783 103.092 52.5612C102.309 53.3441 101.869 54.4056 101.868 55.5128V66.8145C101.866 68.0687 101.367 69.2712 100.48 70.1581C99.5934 71.045 98.3908 71.5439 97.1364 71.5453L18.6338 71.5462Z"
				fill="#262626"
			/>
			<path
				d="M162.281 55.512V159.026C162.281 160.207 161.812 161.339 160.977 162.175C160.141 163.01 159.009 163.479 157.827 163.479H26.9501C25.7688 163.479 24.6359 163.01 23.8006 162.175C22.9654 161.339 22.4961 160.207 22.4961 159.026V75.7214C22.4962 74.5403 22.9655 73.4076 23.8007 72.5725C24.636 71.7373 25.7689 71.2681 26.9501 71.268H105.453C106.634 71.268 107.767 70.7988 108.602 69.9636C109.437 69.1284 109.907 67.9957 109.907 66.8145V55.512C109.907 54.3309 110.376 53.1982 111.211 52.363C112.047 51.5278 113.179 51.0586 114.361 51.0586H157.827C159.009 51.0586 160.141 51.5278 160.977 52.363C161.812 53.1982 162.281 54.3309 162.281 55.512Z"
				fill="white"
			/>
			<path
				d="M157.827 163.756H26.9502C25.6959 163.754 24.4933 163.255 23.6063 162.369C22.7193 161.482 22.2203 160.28 22.2188 159.025V75.7212C22.2203 74.467 22.7193 73.2647 23.6063 72.3779C24.4933 71.4911 25.6959 70.9923 26.9502 70.9909H105.453C106.56 70.9896 107.622 70.5491 108.405 69.7662C109.188 68.9832 109.628 67.9216 109.63 66.8143V55.5118C109.631 54.2577 110.13 53.0553 111.017 52.1684C111.904 51.2816 113.106 50.7827 114.361 50.7812H157.827C159.081 50.7826 160.284 51.2815 161.171 52.1683C162.058 53.0552 162.557 54.2576 162.558 55.5118V159.025C162.557 160.28 162.058 161.482 161.171 162.369C160.284 163.255 159.081 163.754 157.827 163.756ZM26.9502 71.5452C25.8429 71.5464 24.7812 71.9867 23.9981 72.7696C23.215 73.5525 22.7744 74.614 22.7731 75.7212V159.025C22.7744 160.133 23.2149 161.194 23.998 161.977C24.7811 162.76 25.8428 163.201 26.9502 163.202H157.827C158.935 163.2 159.996 162.76 160.779 161.977C161.562 161.194 162.003 160.133 162.004 159.025V55.5118C162.003 54.4046 161.562 53.3431 160.779 52.5601C159.996 51.7772 158.935 51.3368 157.827 51.3355H114.361C113.253 51.3369 112.192 51.7773 111.409 52.5602C110.626 53.3432 110.185 54.4046 110.184 55.5118V66.8135C110.183 68.0677 109.684 69.2702 108.797 70.1571C107.91 71.044 106.707 71.5429 105.453 71.5444L26.9502 71.5452Z"
				fill="#262626"
			/>
			<path
				d="M81.8281 107.733C83.5292 107.733 84.9081 106.354 84.9081 104.653C84.9081 102.952 83.5292 101.573 81.8281 101.573C80.127 101.573 78.748 102.952 78.748 104.653C78.748 106.354 80.127 107.733 81.8281 107.733Z"
				fill="#292A2E"
			/>
			<path
				d="M102.947 107.733C104.648 107.733 106.027 106.354 106.027 104.653C106.027 102.952 104.648 101.573 102.947 101.573C101.246 101.573 99.8672 102.952 99.8672 104.653C99.8672 106.354 101.246 107.733 102.947 107.733Z"
				fill="#292A2E"
			/>
			<path d="M96.517 112.688H88.2578V113.242H96.517V112.688Z" fill="#262626" />
			<g opacity="0.1">
				<path
					opacity="0.1"
					d="M78.652 113.463C80.1695 113.463 81.3997 112.816 81.3997 112.017C81.3997 111.219 80.1695 110.571 78.652 110.571C77.1345 110.571 75.9043 111.219 75.9043 112.017C75.9043 112.816 77.1345 113.463 78.652 113.463Z"
					fill="#367CFF"
				/>
				<path
					opacity="0.1"
					d="M106.129 113.461C107.646 113.461 108.876 112.814 108.876 112.015C108.876 111.217 107.646 110.569 106.129 110.569C104.611 110.569 103.381 111.217 103.381 112.015C103.381 112.814 104.611 113.461 106.129 113.461Z"
					fill="#367CFF"
				/>
			</g>
		</svg>
	);
};

export default NoItemsIcon;
