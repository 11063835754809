// @ts-nocheck
import React, { useState, useContext } from 'react';
import ModalClient from '../components/modalClient/ModalPatient';
import { RelationsContext } from '../context/relations-context';
import { UserContext } from '../context/user-context';

const Help = () => {
	const { updateActualClientInsurance } = useContext(RelationsContext);
	const { logOut } = useContext(UserContext);

	const [modalState, setModalState] = useState(false);

	return (
		<div>
			<button className="" onClick={() => setModalState(true)}>
				edit
			</button>
			<ModalClient
				modalState={modalState}
				setModalState={setModalState}
				updatingStatus={true}
			/>
			<br />

			<button
				onClick={() => updateActualClientInsurance('09e6218a-ccd2-4971-ba91-65bdb656169b')}
			>
				Dale click a un cliente
			</button>
			<br />
			<button onClick={logOut}>Logout</button>
		</div>
	);
};

export default Help;
