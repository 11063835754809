import { HistoryFamilyMember } from 'models';

export const getFamilyMemberFromLabel = (familyMember: string) => {
	let historyFamilyMember = HistoryFamilyMember.FATHER;
	if (familyMember === 'Padre') {
		historyFamilyMember = HistoryFamilyMember.FATHER;
	}
	if (familyMember === 'Madre') {
		historyFamilyMember = HistoryFamilyMember.MOTHER;
	}
	if (familyMember === 'Hermanos') {
		historyFamilyMember = HistoryFamilyMember.BROTHERS;
	}
	if (familyMember === 'Abuelos') {
		historyFamilyMember = HistoryFamilyMember.GRANDPARENTS;
	}
	if (familyMember === 'Bisabuelos') {
		historyFamilyMember = HistoryFamilyMember.GREATGRANDPARENTS;
	}
	if (familyMember === 'Hijos') {
		historyFamilyMember = HistoryFamilyMember.SONS;
	}
	return historyFamilyMember;
};

export const getFamilyLabel = (familyEnum: HistoryFamilyMember): string => {
	let label = 'Padre';
	if (familyEnum === HistoryFamilyMember.FATHER) {
		label = 'Padre';
	}
	if (familyEnum === HistoryFamilyMember.MOTHER) {
		label = 'Madre';
	}
	if (familyEnum === HistoryFamilyMember.BROTHERS) {
		label = 'Hermanos';
	}
	if (familyEnum === HistoryFamilyMember.GRANDPARENTS) {
		label = 'Abuelos';
	}
	if (familyEnum === HistoryFamilyMember.GREATGRANDPARENTS) {
		label = 'Bisabuelos';
	}
	if (familyEnum === HistoryFamilyMember.SONS) {
		label = 'Hijos';
	}
	return label;
};
