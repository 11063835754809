// @ts-nocheck
import { TextField } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import CustomCard from '../../../card/CustomCard';
import { InputContainer, SecondContainer } from '../../../../shared/style';
import AIAlertSuggestion from './AIAlertSuggestion';
import { AIContextProps } from 'context/types';
import { AiContext } from 'context/ai-context';
import useMobile from 'hooks/useMobile';

interface Props {
	treatmentDescription: string;
	setTreatmentDescription: (data: string) => void;
}

const DescriptionTreatmentForm: React.FC<Props> = ({
	treatmentDescription,
	setTreatmentDescription,
}) => {
	const { aiSuggestions, suggestionClickHandler } = useContext(AiContext) as AIContextProps;
	const [showAIRecommendations, setShowAIRecommendations] = React.useState<boolean>(true);
	const { isMobile } = useMobile();
	const AISuggestionClickHandler = (suggestion: string) => {
		if (treatmentDescription === '') {
			setTreatmentDescription(`${suggestion}`);
			return;
		}
		setTreatmentDescription(`${treatmentDescription}, ${suggestion}`);
	};

	useEffect(() => {
		setShowAIRecommendations(true);
	}, [aiSuggestions?.tratamientos]);

	return (
		<SecondContainer item xs={12} style={isMobile ? { paddingTop: 0, paddingBottom: 20 } : {}}>
			<CustomCard divider={true} title="Recomendaciones o plan de tratamiento">
				<InputContainer item xs={12} md={10}>
					<TextField
						id="treatment-notes"
						label="Escribe recomendaciones o plan de tratamiento"
						variant="outlined"
						fullWidth
						multiline
						minRows={4}
						value={treatmentDescription}
						onChange={(e) => setTreatmentDescription(e.target.value)}
					/>
				</InputContainer>
				{aiSuggestions &&
					aiSuggestions.tratamientos.length > 0 &&
					showAIRecommendations && (
						<AIAlertSuggestion
							add={suggestionClickHandler}
							cb={AISuggestionClickHandler}
							suggestions={aiSuggestions}
							type="tratamientos"
							onHide={() => setShowAIRecommendations(false)}
						/>
					)}
			</CustomCard>
		</SecondContainer>
	);
};

export default DescriptionTreatmentForm;
