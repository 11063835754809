import { TableCell } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';

export const StyledTableCell = withStyles(() =>
	createStyles({
		root: {
			padding: '6px 16px 6px 16px',
			// width: '100%',
		},
		head: {
			backgroundColor: '#E8EAF6',
			color: 'rgba(0, 0, 0, 0.87)',
		},
		body: {
			'&:first-child': {
				borderLeft: 'none',
			},
			fontSize: 14,
			borderLeft: '1px solid rgba(224, 224, 224, 1)',
		},
	})
)(TableCell) as typeof TableCell;
