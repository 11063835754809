import React from 'react';
import LineSeparator from '../lineSeparator/LineSeparator';
import { IconContainer } from '../../shared/style';
import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { LabImage } from 'models';
import Biotech from '../svg/Biotech';

interface Props {
	className?: string;
	labImage: LabImage;
	deleteLabImageHandler?: () => void;
	editLabImageHandler?: () => void;
	isSimpleRow?: boolean;
	onClick?: (props: any) => void;
}

const LabImageRow: React.FC<Props> = ({
	labImage,
	deleteLabImageHandler,
	editLabImageHandler,
	isSimpleRow,
	onClick,
}) => {
	return (
		<div
			className={`labimageRow px-[16px] md:px-0 ${isSimpleRow ? 'w-full !px-0' : ''}`}
			onClick={onClick}
		>
			<div className={`md:ml-9 grid grid-cols-12 ${isSimpleRow ? 'py-[12px]' : ''}`}>
				<div className="col-span-8 md:col-span-6 flex items-center">
					{!isSimpleRow && (
						<IconContainer>
							<Biotech height="24" viewBox="2 2 28 28" />
						</IconContainer>
					)}
					<Tooltip title={labImage.name as string} arrow>
						<p data-fx-name="labName">{labImage.name as string}</p>
					</Tooltip>
				</div>
				{isSimpleRow ? null : (
					<div className="col-span-4 md:col-span-6 flex justify-end md:pr-8">
						<IconButton onClick={editLabImageHandler} data-fx-name="editIconButton">
							<EditRoundedIcon />
						</IconButton>
						<IconButton
							onClick={deleteLabImageHandler}
							data-fx-name="deleteIconButton"
							className="!pr-0 !md:pr-[12px]"
						>
							<DeleteForeverIcon />
						</IconButton>
					</div>
				)}
			</div>
			<LineSeparator />
		</div>
	);
};

export default LabImageRow;
