import React, { FC, useContext, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import PatientAdultForm from '../forms/client/createUpdate/PatientAdultForm';
import CustomDialog from '../dialog/CustomDialog';
import PatientTitle from './PatientTitle';
import { bloodTypeOptions, insurancesOptions, identificationType } from '../../shared/constants';
import {
	IdataForm,
	WaitingListItemType,
	ClientContextProps,
	LoaderContextProps,
	RelationsContextProps,
	ClientType,
} from '../../context/types';
import { ClientsContext } from '../../context/client-context';
import { WaitingListsContext, WaitingListContextProps } from '../../context/waiting-list-context';
import moment, { Moment } from 'moment';
import { Client, SexType, WaitingListItemStatus } from 'models';
import { LoaderContext } from '../../context/loader-context';
import { RelationsContext } from '../../context/relations-context';
import { FormatDate, getDateToString } from '../../shared/dateUtils';
import { AffiliateTypes, IdentificationTypes } from 'API';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
	capitalizeFirstLetter,
	formatFile,
	isValidEmail,
	validateNoSpecialCharacters,
} from '../../shared/utilFunctions';
import Storage from '@aws-amplify/storage';
import { ImageFile } from 'shared/type';
import { HospitalDoctorByIdIdentification } from 'customQueries/custom-query-types';
import useMobile from 'hooks/useMobile';
import useAPI from 'hooks/API';

interface IPatientModal {
	modalState?: boolean;
	setModalState?: (data: boolean) => void;
	isUpdate?: boolean;
	setIsUpdate?: (data: boolean) => void;
	updatePaginatedClients?: () => Promise<void>;
}

const ModalPatient: FC<IPatientModal> = ({
	modalState,
	setModalState,
	isUpdate,
	setIsUpdate,
	updatePaginatedClients,
}) => {
	const history = useHistory();
	const {
		setClients,
		createClient,
		saveClient,
		updateClientInfo,
		fetchPatientByIdentificationData,
		fetchPatientById,
		updateClient,
		selectedClient,
		setSelectedClient,
		deleteClient,
		checkIfClientExistInHospitalDoctor,
		getClientData,
	} = useContext(ClientsContext) as ClientContextProps;
	const { waitingListItems, updateWaitingListItemStatus, getWaitingListItems } = useContext(
		WaitingListsContext
	) as WaitingListContextProps;
	const { showSpinner } = useContext(LoaderContext) as LoaderContextProps;
	const { verifyClientExistInHospitalDoctor, setAlertMessage } = useContext(
		RelationsContext
	) as RelationsContextProps;

	const { isMobile } = useMobile();
	const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);

	const [insuranceValue, setInsuranceValue] = useState<number>(1);
	const [userImg, setUserImg] = useState<ImageFile | null>(null);
	const [profilePreview, setProfilePreview] = useState<string | undefined>(undefined);
	const [idType, setIdType] = useState<string>(identificationType.CEDULA);
	const [idNumber, setIdNumber] = useState<string>('');
	const [idNumberError, setIdNumberError] = useState<string>('');
	const [firstName, setFirstName] = useState<string>('');
	const [lastName, setLastName] = useState<string>('');
	const [birthday, setBirthday] = useState<string | null | undefined | Moment>(null);
	const [birthdayErrorText, setBirthdayErrorText] = useState<string>('');
	const [gender, setGender] = useState<string>('');
	const [phoneNumber, setPhoneNumber] = useState<string>('');
	const [cellPhone, setCellPhone] = useState<string>('');
	const [address, setAddress] = useState<string>('');
	const [sector, setSector] = useState<string>('');
	const [city, setCity] = useState<string>('');
	const [contractNo, setContractNo] = useState<string>('');
	const [occupation, setOccupation] = useState<string>('');
	const [bloodType, setBloodType] = useState<string>('');
	const [affiliate, setAffiliate] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [isInvalidName, setIsInvalidName] = useState<boolean>(false);
	const [isInvalidLastName, setIsInvalidLastName] = useState<boolean>(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [isInvalidParentName, setIsInvalidParentName] = useState<boolean>(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [isInvalidParentLastName, setIsInvalidParentLastName] = useState<boolean>(false);
	const [isInvalidEmail, setIsInvalidEmail] = useState<boolean>(false);
	const [isDisabled, setIsDisabled] = useState<boolean>(true);
	const [isAdult, setIsAdult] = useState<boolean>(true);
	const [isInvalidAdultDate, setInvalidAdultDate] = useState<boolean>(false);
	const [nameErrorText, setNameErrorText] = useState<string>('');
	const [lastNameErrorText, setLastNameErrorText] = useState<string>('');
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [parentNameErrorText, setParentNameErrorText] = useState<string>('');
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [parentLastNameErrorText, setParentLastNameErrorText] = useState<string>('');
	const [parentFirstName, setParentFirstName] = useState<string>('');
	const [parentLastName, setParentLastName] = useState<string>('');
	let isThereAnError = false;
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const idNumber_validation_text = 'La cédula debe contener 11 dígitos';
	const name_validation_text =
		'El nombre no debe incluir los siguientes caracteres: !@#$%^&*+_=<>';
	const [existedClient, setExistedClient] = useState<HospitalDoctorByIdIdentification | null>(
		null
	);
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		if (isAdult) {
			if (!isUpdate) {
				if (idNumber !== undefined && idNumber !== '' && idNumber.length >= 13) {
					setIsDisabled(false);
				} else {
					setIsDisabled(true);
				}
			} else {
				if (selectedClient?.identificationData !== idNumber) {
					setIsDisabled(true);
				} else {
					setIsDisabled(false);
				}
			}
		} else {
			if (idNumber && idNumber !== '' && idNumber.length >= 13) {
				setIsDisabled(false);
			} else {
				setIsDisabled(true);
				setParentFirstName('');
				setParentLastName('');
				setCellPhone('');
				setEmail('');
			}
		}
	}, [isAdult, isUpdate, idNumber, idType]);

	useEffect(() => {
		if (selectedClient && selectedClient !== null && isUpdate) {
			if (selectedClient.actualInsurance) {
				setInsuranceValue(
					selectedClient.actualInsurance
						? insurancesOptions!.find(
								(i) => i.label === selectedClient!.actualInsurance
						  )!.id
						: 1
				);
			}
			if (selectedClient.identificationName!) {
				setIdType(selectedClient.identificationName!.toUpperCase());
			}
			setIdNumber(selectedClient.identificationData as string);
			setFirstName(selectedClient.name);
			setLastName(selectedClient.lastName);
			setBirthday(
				selectedClient.bornDate
					? selectedClient?.bornDate
					: getDateToString(new Date(), FormatDate['YYYY-MM-DD'])
			);
			setCellPhone(selectedClient.cellphoneNumber as string);
			if (selectedClient.gender) {
				setGender(selectedClient.gender.toUpperCase() || '');
			}
			setIsAdult(
				selectedClient.isAdult !== null ? (selectedClient.isAdult as boolean) : true
			);
			setPhoneNumber(selectedClient.phoneNumber as string);
			setAddress(selectedClient.addressStreet as string);
			setSector(selectedClient.sector as string);
			setCity(selectedClient.city as string);
			setContractNo(selectedClient.contractNumber as string);
			setAffiliate((selectedClient.affiliateNumber as string) || '');
			setEmail((selectedClient.email as string) || '');
			setBloodType((selectedClient.bloodType as string) || '');
			setOccupation((selectedClient.occupation as string) || '');
			setProfilePreview(selectedClient.profileImage);
		}
	}, [selectedClient, isUpdate]);

	const resetForm = () => {
		setUserImg(null);
		setInsuranceValue(1);
		setProfilePreview(undefined);
		setIdType(identificationType.CEDULA);
		setIdNumber('');
		setIdNumberError('');
		setFirstName('');
		setNameErrorText('');
		setLastName('');
		setLastNameErrorText('');
		setParentNameErrorText('');
		setParentLastNameErrorText('');
		setBirthday(moment());
		setBirthdayErrorText('');
		setInvalidAdultDate(false);
		setGender('');
		setPhoneNumber('');
		setCellPhone('');
		setAddress('');
		setSector('');
		setCity('');
		setBloodType('');
		setOccupation('');
		setContractNo('');
		setAffiliate('');
		setEmail('');
		setIsInvalidName(false);
		setIsInvalidLastName(false);
		setIsInvalidParentName(false);
		setIsInvalidParentLastName(false);
		setIsInvalidEmail(false);
		setParentFirstName('');
		setParentLastName('');
		setExistedClient(null);
	};

	const validateForm = () => {
		let isValid = true;

		if (firstName === '') {
			setIsInvalidName(true);
			setNameErrorText('El nombre es obligatorio');
			isValid = false;
		} else {
			setIsInvalidName(false);
			setNameErrorText('');
		}

		if (lastName === '') {
			setIsInvalidLastName(true);
			setLastNameErrorText('El apellido es obligatorio');
			isValid = false;
		} else {
			setIsInvalidLastName(false);
			setLastNameErrorText('');
		}

		if (email !== '' && !isValidEmail(email)) {
			setIsInvalidEmail(true);
			isValid = false;
		} else {
			setIsInvalidEmail(false);
		}

		return isValid;
	};

	const onCloseModal = () => {
		if (
			!history.location.pathname.includes('summary') &&
			!history.location.pathname.includes('claim') &&
			isUpdate
		) {
			setSelectedClient(null);
		}
		setModalState?.(false);
		setIsUpdate?.(false);
		resetForm();
		showSpinner(false);
		if (waitingListItems.length > 0) {
			getWaitingListItems();
		}
	};

	// Function to create Client if it's under age
	const handleCreateUnderAgeClient = async (
		adultInfo: IdataForm,
		underAgeInfo: IdataForm,
		profilePreview: string | undefined
	) => {
		const parentOrTutorObj = {
			identificationName: adultInfo.identificationName,
			identificationData: adultInfo.identificationData,
			actualInsurance: insurancesOptions[0].label,
			name: adultInfo.name,
			lastName: adultInfo.lastName,
			cellphoneNumber: adultInfo.cellphoneNumber,
			email: adultInfo.email,
			gender: SexType.NOSEX,
			phoneNumber: '',
			addressStreet: '',
			city: '',
			isAdult: true,
			sector: '',
			bloodType: bloodTypeOptions[0].label,
			company: '',
			occupation: '',
			profileImage: undefined,
			heartBeat: '',
			weight: '',
			height: '',
			bloodPressure: '',
			updateDateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
		};

		let adultPatientInfo: Client;
		if (adultInfo.identificationData) {
			const patientCreated = await fetchPatientByIdentificationData(
				adultInfo.identificationData as string
			);

			if (patientCreated) {
				adultPatientInfo = await updateClientInfo(
					patientCreated,
					parentOrTutorObj as Client
				);
			} else {
				adultPatientInfo = await saveClient(
					parentOrTutorObj as ClientType,
					undefined,
					false
				);
			}
		} else {
			adultPatientInfo = await saveClient(parentOrTutorObj as ClientType, undefined, false);
		}

		const underAgeObj = {
			identificationName: null,
			identificationData: null,
			cellphoneNumber: '',
			phoneNumber: '',
			email: '',
			company: '',
			occupation: '',
			name: underAgeInfo.name,
			lastName: underAgeInfo.lastName,
			bornDate: underAgeInfo.bornDate,
			gender: underAgeInfo.gender as SexType,
			bloodType: underAgeInfo.bloodType,
			actualInsurance: underAgeInfo.actualInsurance,
			affiliateNumber: underAgeInfo.affiliateNumber,
			contractNumber: underAgeInfo.contractNumber,
			affiliateType: underAgeInfo.affiliateType,
			addressStreet: underAgeInfo.addressStreet,
			isAdult: false,
			city: underAgeInfo.city,
			sector: underAgeInfo.sector,
			parentOrTutorCedula: adultInfo.identificationData,
			parentOrTutorID: adultPatientInfo.id,
			updateDateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
		};

		await createClient(underAgeObj, profilePreview);
	};

	// Function to update Client if it's under age
	const handleUpdateUnderAgeClient = async (
		adultInfo: IdataForm,
		underAgeInfo: IdataForm,
		userImage?: string
	) => {
		if (selectedClient) {
			const clientInfo = await fetchPatientById(selectedClient.id as string);
			let parentOrTutor: Client;

			if (selectedClient.parentOrTutorID) {
				const parentOrTutorObj = {
					identificationData: adultInfo.identificationData,
					name: adultInfo.name,
					lastName: adultInfo.lastName,
					actualInsurance: adultInfo.actualInsurance,
					identificationName: adultInfo.identificationName,
					isAdult: true,
					cellphoneNumber: adultInfo.cellphoneNumber,
					email: adultInfo.email,
				};
				const adultClientInfo = await fetchPatientByIdentificationData(
					adultInfo.identificationData as string
				);

				if (adultClientInfo) {
					parentOrTutor = await updateClientInfo(
						adultClientInfo,
						parentOrTutorObj as Client
					);
				} else {
					parentOrTutor = await saveClient(
						parentOrTutorObj as ClientType,
						undefined,
						false
					);
				}

				const underAgeClient = {
					...clientInfo,
					name: underAgeInfo.name,
					lastName: underAgeInfo.lastName,
					bornDate: underAgeInfo.bornDate,
					gender: underAgeInfo.gender as SexType,
					bloodType: underAgeInfo.bloodType,
					identificationData: '',
					identificationName: identificationType.CEDULA as IdentificationTypes,
					cellphoneNumber: '',
					email: '',
					company: '',
					occupation: '',
					phoneNumber: underAgeInfo.phoneNumber,
					affiliateNumber: underAgeInfo.affiliateNumber,
					affiliateType: underAgeInfo.affiliateType,
					contractNumber: underAgeInfo.contractNumber,
					isAdult: false,
					parentOrTutorCedula: parentOrTutor.identificationData,
					parentOrTutorID: parentOrTutor.id,
					addressStreet: underAgeInfo.addressStreet,
					actualInsurance: underAgeInfo.actualInsurance,
					city: underAgeInfo.city,
					sector: underAgeInfo.sector,
				};

				await updateClient(underAgeClient, userImage);
			}
		}
	};

	const onSubmit = async () => {
		try {
			if (!validateForm()) return;
			setLoading(true);
			showSpinner(true);
			const data = {
				identificationData: idNumber,
				name: capitalizeFirstLetter(firstName.toLowerCase()),
				lastName: capitalizeFirstLetter(lastName.toLowerCase()),
				cellphoneNumber: cellPhone,
				email: email,
				phoneNumber: phoneNumber,
				addressStreet: address,
				city: city,
				sector: sector,
				bloodType: bloodType,
				isAdult: isAdult,
				occupation: occupation,
				affiliateNumber: affiliate,
				contractNumber: contractNo,
				gender: gender ? gender : SexType.NOSEX,
				identificationName: idType,
				bornDate: moment(birthday).format('YYYY-MM-DD'),
				actualInsurance:
					insurancesOptions && insurancesOptions.length !== 0
						? insurancesOptions.find((i) => i.id === insuranceValue)!.label
						: '',
				affiliateType: AffiliateTypes.PRINCIPAL,
			};
			const adultInfo = {
				identificationData: idNumber,
				name: capitalizeFirstLetter(parentFirstName.toLowerCase()),
				lastName: capitalizeFirstLetter(parentLastName.toLowerCase()),
				cellphoneNumber: cellPhone,
				actualInsurance: insurancesOptions[0].label,
				identificationName: idType,
				email: email,
			};
			const underAgeInfo = {
				name: capitalizeFirstLetter(firstName.toLowerCase()),
				lastName: capitalizeFirstLetter(lastName.toLowerCase()),
				bornDate: moment(birthday).format('YYYY-MM-DD'),
				gender: gender ? gender : SexType.NOSEX,
				bloodType: bloodType,
				phoneNumber: phoneNumber,
				actualInsurance:
					insurancesOptions && insurancesOptions.length !== 0
						? insurancesOptions.find((i) => i.id === insuranceValue)!.label
						: '',
				affiliateNumber: affiliate,
				contractNumber: contractNo,
				affiliateType: AffiliateTypes.PRINCIPAL,
				addressStreet: address,
				city: city,
				sector: sector,
			};
			if (!isThereAnError) {
				if (isUpdate && selectedClient?.identificationData !== idNumber) {
					const checkClientExist = await checkIfClientExistInHospitalDoctor(idNumber);

					if (checkClientExist) {
						setIdNumberError('El paciente ya existe');
						setExistedClient(checkClientExist);
						isThereAnError = true;
						showSpinner(false);
						return;
					}
				}

				if (!isUpdate && idNumber) {
					const checkClientExist = await checkIfClientExistInHospitalDoctor(idNumber);

					if (checkClientExist) {
						setIdNumberError('El paciente ya existe');
						setExistedClient(checkClientExist);
						isThereAnError = true;
						showSpinner(false);
						return;
					}
				}

				let key: string | null = null;

				if (userImg) {
					const { key: imageKey } = await Storage.put(userImg?.name, userImg?.file);
					key = imageKey;
				}

				if (isUpdate) {
					if (selectedClient?.profileImageKey && userImg) {
						await Storage.remove(selectedClient?.profileImageKey);
					}

					isAdult
						? await updateClient(
								data as IdataForm,
								key ?? selectedClient?.profileImageKey
						  )
						: await handleUpdateUnderAgeClient(
								adultInfo as IdataForm,
								underAgeInfo as IdataForm,
								key ?? selectedClient?.profileImageKey
						  );
				} else {
					isAdult
						? await createClient(data as IdataForm, key ?? '')
						: await handleCreateUnderAgeClient(
								adultInfo as IdataForm,
								underAgeInfo as IdataForm,
								key ?? ''
						  );
				}
				onCloseModal();
			}
		} catch (error: any) {
			showSpinner(false);
			setAlertMessage({
				severity: 'error',
				message: 'Ha ocurrido un error, por favor intente de nuevo',
			});
		} finally {
			setLoading(false);
			showSpinner(false);
		}
	};
	// Handle picture for previus picture and file
	const handleProfilePicture = (e: React.ChangeEvent<HTMLInputElement>) => {
		const reader = new FileReader();
		const file = e.target.files?.item(0) as File;

		reader.onloadend = (event: ProgressEvent<FileReader>) => {
			const formattedFile = formatFile(file);
			setUserImg(formattedFile);
			setProfilePreview(event.target?.result as string);
		};
		if (file) {
			reader.readAsDataURL(file);
		}
	};

	const handleIdType = (
		e: React.ChangeEvent<{
			name?: string | undefined;
			value: unknown | string;
		}>
	) => {
		setIdType(e.target.value as string);
	};

	const handleIdNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		if (value) {
			setIdNumber(value);
		} else {
			setIdNumber('');
		}
	};

	const handleBirthdayDate = (date: MaterialUiPickersDate) => {
		const birthDayFormat = moment(date).format('YYYY-MM-DD');
		setBirthday(birthDayFormat);
		if (moment().diff(birthDayFormat, 'days') < 1) {
			setInvalidAdultDate(true);
			setBirthdayErrorText(
				'La fecha de nacimiento no puede ser igual o mayor a la fecha actual'
			);
		} else {
			setInvalidAdultDate(false);
			setBirthdayErrorText('');
		}
	};

	const handleName = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value != null && e.target.value !== undefined && e.target.value) {
			setFirstName(e.target.value);
			if (e.target.value === '') {
				setIsInvalidName(true);
				setNameErrorText('');
			} else {
				setIsInvalidName(false);
				setNameErrorText('');
				if (validateNoSpecialCharacters(e.target.value)) {
					setIsInvalidName(true);
					setNameErrorText(name_validation_text);
				}
			}
		} else {
			setFirstName('');
			setIsInvalidName(true);
			setNameErrorText('');
		}
	};

	const handleLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
		setLastName(e.target.value);
		if (e.target.value === '') {
			setIsInvalidLastName(true);
			setLastNameErrorText('');
		} else {
			setIsInvalidLastName(false);
			setLastNameErrorText('');
			if (validateNoSpecialCharacters(e.target.value)) {
				setIsInvalidLastName(true);
				setLastNameErrorText(name_validation_text);
			}
		}
	};

	const handleAffiliate = (e: React.ChangeEvent<HTMLInputElement>) => {
		setAffiliate(e.target.value);
	};

	const handleBloodType = (
		e: React.ChangeEvent<{
			name?: string | undefined;
			value: unknown | string;
		}>
	) => {
		setBloodType(e.target.value as string);
	};

	const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(e.target.value);
		if (!isValidEmail(e.target.value)) {
			setIsInvalidEmail(true);
		}

		if (isValidEmail(e.target.value) || e.target.value === '') {
			setIsInvalidEmail(false);
		}
	};

	// Handle Delete Patient
	const deletePatientAndWaiting = async () => {
		try {
			if (selectedClient?.id) {
				const getClientFromWaitingList = waitingListItems.find(
					(item: WaitingListItemType) => item.clientID === selectedClient?.id
				);
				await deleteClient(selectedClient.id as string);
				if (getClientFromWaitingList) {
					await updateWaitingListItemStatus(
						getClientFromWaitingList!.id,
						WaitingListItemStatus.TERMINADA
					);
				}
			}
			if (history.location.pathname.includes('summary')) {
				return history.push('/');
			}
		} catch (e) {
			console.warn(e);
		} finally {
			setSelectedClient(null);
		}
	};

	const onDeletePatient = async () => {
		try {
			setLoading(true);
			await deletePatientAndWaiting();
			onCloseModal();
		} catch (error) {
			console.warn(error);
		} finally {
			setLoading(false);
			setOpenConfirmationModal(false);
		}
	};

	const handleClickAddPatient = async (patient: Client) => {
		const data = {
			...patient,
			affiliateType: AffiliateTypes.PRINCIPAL,
		};
		showSpinner(true);
		await verifyClientExistInHospitalDoctor(data as any);
		setClients((prevClients) => [data as any, ...(prevClients as ClientType[])]);
		history.push(`/Clients/${patient.id}`);
		onCloseModal();
	};

	return (
		<>
			<CustomDialog
				disableBackdropClick
				open={modalState as boolean}
				handleClose={onCloseModal}
				maxWidth={'sm'}
				fullScreen={isMobile}
				title={
					<PatientTitle
						handleClose={onCloseModal}
						isDisabled={isDisabled}
						isUpdate={!!selectedClient}
						userImg={profilePreview}
						handleProfilePicture={handleProfilePicture}
						insuranceValue={insuranceValue}
						setInsuranceValue={setInsuranceValue}
						idType={idType}
					/>
				}
				fullWidth
				agreeText="Guardar"
				disagreeText="Cancelar"
				handleAgree={onSubmit}
				deleteText={isUpdate ? `Eliminar ${!isMobile ? 'Cliente' : ''}` : ''}
				handleDelete={() => setOpenConfirmationModal(true)}
				className="fullscreen-modal"
				dataFxName="patientForm"
			>
				<PatientAdultForm
					isDisabled={isDisabled}
					insuranceValue={insuranceValue}
					idType={idType}
					handleIdType={handleIdType}
					idNumber={idNumber}
					idNumberError={idNumberError}
					handleIdNumber={handleIdNumber}
					firstName={firstName}
					nameErrorText={nameErrorText}
					lastNameErrorText={lastNameErrorText}
					handleName={handleName}
					lastName={lastName}
					handleLastName={handleLastName}
					birthday={birthday}
					handleBirthdayDate={handleBirthdayDate}
					isInvalidAdultDate={isInvalidAdultDate}
					gender={gender}
					setGender={setGender}
					birthdayErrorText={birthdayErrorText}
					phoneNumber={phoneNumber}
					setPhoneNumber={setPhoneNumber}
					cellPhone={cellPhone}
					setCellPhone={setCellPhone}
					address={address}
					setAdress={setAddress}
					sector={sector}
					setSector={setSector}
					city={city}
					setCity={setCity}
					contractNo={contractNo}
					setContractNo={setContractNo}
					affiliate={affiliate}
					handleAffiliate={handleAffiliate}
					email={email}
					handleEmail={handleEmail}
					isInvalidEmail={isInvalidEmail}
					isInvalidName={isInvalidName}
					isInvalidLastName={isInvalidLastName}
					occupation={occupation}
					setOccupation={setOccupation}
					bloodType={bloodType}
					handleBloodType={handleBloodType}
					handleClickAddPatient={handleClickAddPatient}
					existedClient={existedClient}
					getPatientData={getClientData}
					setSelectedClient={setSelectedClient}
					closeModal={onCloseModal}
				/>
			</CustomDialog>

			<CustomDialog
				maxWidth={'xs'}
				open={openConfirmationModal}
				handleClose={() => setOpenConfirmationModal(false)}
				title={`¿Estás seguro que quieres eliminar a ${selectedClient?.name} ${selectedClient?.lastName}?`}
				agreeText="Confirmar"
				disagreeText="cancelar"
				handleAgree={onDeletePatient}
				loading={loading}
				loadingText="Eliminando..."
			/>
		</>
	);
};

export default ModalPatient;
