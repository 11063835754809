import React from 'react';
import { NewTrashIcon } from 'components/svg/TranshIcon';
import { TeamStatus } from 'models';

interface Props {
	name: string;
	status?: TeamStatus | null;
	deleteFunction: () => void;
	sendConfirmationCode: () => Promise<void>;
}

const DoctorMemberBox: React.FC<Props> = ({
	deleteFunction,
	status,
	sendConfirmationCode,
	name,
}) => {
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				gap: '8px',
				padding: '8px 0px',
				background: 'rgba(255, 255, 255, 0.00)',
				boxShadow: '0px -1px 0px 0px rgba(0, 0, 0, 0.12) inset',
			}}
		>
			<span
				style={{
					fontStyle: 'normal',
					fontWeight: '500',
					fontSize: '16px',
					lineHeight: '24px',
					letterSpacing: '0.15px',
					fontFeatureSettings: 'clig off, liga off',
				}}
				className="capitalize"
			>
				{name}
			</span>

			<div className="flex justify-center items-center gap-6">
				{status === TeamStatus.PENDING ? (
					<button
						onClick={sendConfirmationCode}
						id="edit-card"
						style={{
							cursor: 'pointer',
							textTransform: 'uppercase',
							fontSize: '13px',
							color: '#1976D2',
							fontStyle: 'normal',
							fontWeight: '500',
							lineHeight: '22px',
							letterSpacing: '0.46px',
						}}
					>
						reenviar invitación
					</button>
				) : null}
				<button
					onClick={deleteFunction}
					id="delete-card"
					style={{
						cursor: 'pointer',
					}}
				>
					<NewTrashIcon />
				</button>
			</div>
		</div>
	);
};

export default DoctorMemberBox;
