import React from 'react';
import Cards from './Cards';

interface Props {
	customCss?: string;
	planId: number;
}

const NeedPayment: React.FC<Props> = ({ customCss = '', children, planId }) => {
	return (
		<div
			className={`flex flex-col items-center ${customCss ? customCss : '-mt-10'} w-full `}
			style={{ width: 500 }}
		>
			<div className="w-full">{children}</div>
			<Cards planId={planId} />
		</div>
	);
};

export default NeedPayment;
