import { ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import moment from 'moment';
import React, { FC } from 'react';
import { AllergyStatus } from 'models';
import { HistoryListItem, InactiveChip } from '../../../../shared/style';
import useMobile from 'hooks/useMobile';

interface Props {
	name: string;
	diagnosedDate?: string;
	status: AllergyStatus;
	showDivider: boolean;
}

const ResumenListItem: FC<Props> = ({ name, diagnosedDate, status, showDivider }) => {
	const { isMobile } = useMobile();
	return (
		<HistoryListItem
			selected={status !== AllergyStatus.ACTIVE}
			divider={showDivider}
			disableGutters={isMobile}
		>
			<ListItemText
				primary={name}
				secondary={diagnosedDate && `Empezó: ${moment(diagnosedDate).format('DD/MM/YYYY')}`}
			/>
			{status !== AllergyStatus.ACTIVE && (
				<ListItemSecondaryAction>
					<InactiveChip
						style={{
							background: status === AllergyStatus.INACTIVE ? '#E0E0E0' : '#CFD8DC',
						}}
						size="small"
						label={status === AllergyStatus.INACTIVE ? 'Inactiva' : 'Curada'}
					/>
				</ListItemSecondaryAction>
			)}
		</HistoryListItem>
	);
};

export default ResumenListItem;
