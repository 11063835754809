import { FC } from 'react';

const SearchInputHelper: FC<{
	idNumberError: string;
	goToExistedClient: () => void;
}> = ({ idNumberError, goToExistedClient }) => {
	if (!idNumberError) return null;

	return (
		<p
			style={{
				fontSize: '12px',
				fontWeight: 400,
				lineHeight: '20px',
				letterSpacing: '0.14px',
				fontStyle: 'normal',
			}}
		>
			El paciente ya existe.{' '}
			<span
				style={{
					color: '#039BE5',
					cursor: 'pointer',
					fontSize: '12px',
					fontWeight: 500,
					lineHeight: '20px',
					letterSpacing: '0.14px',
				}}
				onClick={goToExistedClient}
			>
				Ver Paciente
			</span>
		</p>
	);
};

export default SearchInputHelper;
