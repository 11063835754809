import React from 'react';
import ListEmptyState from './ListEmptyState';

const NutritionList = () => {
	const isTrue = true;
	return isTrue ? (
		<ListEmptyState btnLabel="Agregar Nutrición" onClickHandler={() => {}} />
	) : (
		<></>
	);
};

export default NutritionList;
