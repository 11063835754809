import { CSSProperties, FC } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import FooterPrescription from './footer';
import BackgroundImg from 'components/svg/BackgroundImg';

const arialFont: CSSProperties = {
	fontFamily: 'Arial, Helvetica, sans-serif',
	fontWeight: 700,
	wordSpacing: '0.075em',
	fontStyle: 'normal',
};

const flexRow: CSSProperties = {
	display: 'flex',
};

const flexCol: CSSProperties = {
	...flexRow,
	flexDirection: 'column',
};

const bodyStyle: CSSProperties = {
	...arialFont,
	fontWeight: 400,
	fontSize: '12px',
	overflowWrap: 'break-word',
};

const InfoHeaderStyle: CSSProperties = {
	...arialFont,
	fontWeight: 700,
	fontSize: '12px',
};

const BodyPrescription: FC<{
	prescriptionMedicines: any[];
	prescriptionAnalisis: any[];
	freePrescription?: string;
	client: any;
	doctorName?: string | null;
}> = ({ prescriptionMedicines, prescriptionAnalisis, freePrescription, client, doctorName }) => {
	const svgString = encodeURIComponent(renderToStaticMarkup(<BackgroundImg />));
	return (
		<div
			style={{
				position: 'relative',
				border: '2.56px solid #F3EFEF',
				borderRadius: '12.8px',
				marginTop: '30.67px',
				marginBottom: '26.67px',
				width: '478.67px',
				maxHeight: '629.33px',
				height: '629.33px',
				backgroundImage: `url("data:image/svg+xml,${svgString}")`,
				backgroundRepeat: 'no-repeat',
				backgroundPosition: '50% 20%',
				backgroundSize: '70% 60%',
				...flexCol,
			}}
		>
			<div
				style={{
					...arialFont,
					display: 'flex',
					flexDirection: 'column',
					padding: '20px',
					height: '70%',
				}}
			>
				{prescriptionMedicines?.map((el, i) => (
					<div
						key={i}
						className="mb-4 flex flex-col"
						style={{ ...flexCol, marginBottom: 16 }}
					>
						<span className="" style={bodyStyle}>
							&#x2022; {el.label}
							{el.selectedMeasure && (
								<>
									,{' '}
									<span style={{ ...InfoHeaderStyle }}>{el.selectedMeasure}</span>
								</>
							)}
						</span>
						<span style={{ ...bodyStyle, fontStyle: 'italic' }}>
							{el.take}
							{el.indication && `, ${el.indication}`}
							{el.duration && `, ${el.duration}`}
						</span>
						<span style={{ ...bodyStyle, fontStyle: 'italic' }}>{el.description}</span>
					</div>
				))}

				{prescriptionAnalisis
					?.filter((el) => el.label !== '')
					.map((el, i) => (
						<div
							key={`analisis-${i}-key-${el.label}`}
							className="mb-4 flex flex-col"
							style={{ ...flexCol, marginBottom: 16 }}
						>
							<span className="" style={bodyStyle}>
								&#x2022; {el.label}
							</span>
							<span style={{ ...bodyStyle, fontStyle: 'italic' }}>
								{el.description}
							</span>
						</div>
					))}
				{freePrescription}
			</div>
			<FooterPrescription client={client} doctorName={doctorName} />
		</div>
	);
};

export default BodyPrescription;
