import { useState } from 'react';

const useLocalStorage = (key: string, initialValue: any) => {
	const [storedValue, setStoredValue] = useState(() => {
		try {
			const item = localStorage.getItem(key);

			return item ? JSON.parse(item) : initialValue;
		} catch (error) {
			return initialValue;
		}
	});

	const setValue = (value: any) => {
		try {
			localStorage.setItem(key, JSON.stringify(value));
			const valueToStore = value instanceof Function ? value(storedValue) : value;

			setStoredValue(valueToStore);
		} catch (error) {}
	};

	const removeValue = () => {
		try {
			localStorage.removeItem(key);
			setStoredValue(null);
		} catch (error) {}
	};

	return [storedValue, setValue, removeValue];
};

export default useLocalStorage;
