import { API, graphqlOperation } from '@aws-amplify/api';

const useAPI = () => {
	const execute = async (query: any, variables?: any): Promise<any> => {
		const response = await API.graphql(graphqlOperation(query, variables));
		return response;
	};
	return {
		execute,
	};
};

export default useAPI;
