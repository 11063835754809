import React from 'react';

const NoItemsInWaitingListIcon = () => (
	<svg
		width="114"
		height="114"
		viewBox="0 0 114 114"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M73.5007 69.0827H78.334V70.291H75.9173V72.7077H78.334V73.916H73.5007V76.3327H80.7507V66.666H73.5007V69.0827ZM75.9173 47.3327H78.334V37.666H73.5007V40.0827H75.9173V47.3327ZM73.5007 54.5827H77.8507L73.5007 59.6577V61.8327H80.7507V59.416H76.4007L80.7507 54.341V52.166H73.5007V54.5827ZM34.834 40.0827H68.6673V44.916H34.834V40.0827ZM34.834 69.0827H68.6673V73.916H34.834V69.0827ZM34.834 54.5827H68.6673V59.416H34.834V54.5827Z"
			fill="black"
			fillOpacity="0.54"
		/>
		<path
			opacity="0.1"
			d="M57 114C88.4802 114 114 88.4802 114 57C114 25.5198 88.4802 0 57 0C25.5198 0 0 25.5198 0 57C0 88.4802 25.5198 114 57 114Z"
			fill="#367CFF"
		/>
	</svg>
);

export default NoItemsInWaitingListIcon;
