import React, { FC } from 'react';
import {
	StyledFormControlLabel,
	CardDescription,
	StyledRadio,
	PriceContainer,
	PaymentHelperText,
	PaymentSubscriptionCard,
} from '../../shared/style';

interface Props {
	selectedPlan: number;
	handleSelectPlan: (value: number) => void;
	value: number;
	title: string;
	subtitle: string;
	price: string;
}

const PlanCard: FC<Props> = ({ selectedPlan, handleSelectPlan, value, title, subtitle, price }) => {
	return (
		<PaymentSubscriptionCard
			onClick={() => handleSelectPlan(value)}
			style={{ border: selectedPlan === value ? 'solid 3px #007dff' : 'solid 3px #949494' }}
		>
			<CardDescription>
				<StyledFormControlLabel
					control={
						<StyledRadio
							name="plan_price"
							checked={selectedPlan === value}
							value={value}
							color="primary"
							onChange={(e) => handleSelectPlan(parseInt(e.target.value))}
						/>
					}
					label={title}
				/>
				<PaymentHelperText>{subtitle}</PaymentHelperText>
			</CardDescription>
			<PriceContainer className="font-medium text-[14px] no-italic tracking-[0.1px] decoration-[rgba(21, 101, 192, 1)]">
				RD$ {price}
			</PriceContainer>
		</PaymentSubscriptionCard>
	);
};

export default PlanCard;
