import { Avatar, FormControl, Grid } from '@material-ui/core';
import styled from 'styled-components';
import { BREAKPOINTS } from 'shared/style';
export const PictureSection = styled(Grid)`
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 20px;
	margin-top: 80px;
	@media (min-width: ${BREAKPOINTS.md}px) {
		margin-top: 0;
		justify-content: flex-end;
		flex-direction: row;
		gap: 0;
	}
` as typeof Grid;

export const TitleGrid = styled(Grid)`
	font-size: 20px;
	font-weight: 600;
	color: rgba(0, 0, 0, 0.87);
` as typeof Grid;

export const StyledAvatar = styled(Avatar)`
	width: 80px;
	height: 80px;
	@media (min-width: ${BREAKPOINTS.md}px) {
		width: 32px;
		height: 32px;
	}
` as typeof Avatar;

export const RadioSection = styled(FormControl)`
	margin-bottom: 30px;
	margin-left: 10px;
` as typeof FormControl;
