import React, { useContext, useEffect } from 'react';
import { RelationsContext } from '../../context/relations-context';
import { ClientType, RelationsContextProps } from '../../context/types';
import { Medicine, LabImages } from '../../shared/type';

import Storage from '@aws-amplify/storage';
import PrescriptionHeader from './UI/header';
import BodyPrescription from './UI/body';

interface PrescriptionT {
	creationDate: string;
	medicines: Medicine[];
	analisis: LabImages[];
	freePrescription?: string;
}

interface PrescriptionPrintLayoutProps {
	client: ClientType;
	prescription: PrescriptionT;
}

const PrescriptionPrintLayout: React.FC<PrescriptionPrintLayoutProps> = ({
	client,
	prescription,
}) => {
	const prescriptionMedicines =
		prescription && prescription.medicines && (prescription.medicines as Medicine[]);
	const { actualHospital, actualDoctor, actualHospitalDoctor } = useContext(
		RelationsContext
	) as RelationsContextProps;
	const [hospitalLogo, setHospitalLogo] = React.useState<string | null>(null);

	useEffect(() => {
		const getImage = async (imageKey: string) => {
			const url = await Storage.get(imageKey);
			setHospitalLogo(url);
		};
		if (!actualHospitalDoctor?.hospitalLogo) return;

		getImage(actualHospitalDoctor.hospitalLogo);
	}, [actualHospitalDoctor]);

	return (
		<div
			style={{
				transform: 'rotate(90deg)',
				display: 'flex',
				flexDirection: 'column',
				padding: '0px 0px 30px 12px',
			}}
		>
			<PrescriptionHeader
				hospitalLogo={hospitalLogo}
				doctorName={actualDoctor?.name}
				doctorEmail={actualDoctor?.email}
				doctorSpecialty={actualDoctor?.specialty}
				doctorPhone={actualHospital?.phone}
				doctorTitle={actualDoctor?.title}
				doctorCellphone={actualDoctor?.phone}
				hospitalName={actualHospital?.name}
				hospitalAddress={actualHospital?.address}
			/>
			<BodyPrescription
				prescriptionMedicines={prescriptionMedicines}
				prescriptionAnalisis={prescription?.analisis}
				freePrescription={prescription?.freePrescription}
				client={client}
				doctorName={actualDoctor?.name}
			/>
		</div>
	);
};

export default PrescriptionPrintLayout;
