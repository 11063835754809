import React, { FC } from 'react';
import { PrescriptionListContainer } from './styles';

interface Props {
	children: React.ReactNode;
	styles?: React.CSSProperties;
}

const PrescriptionList: FC<Props> = ({ children, styles }) => {
	return (
		<PrescriptionListContainer
			xs={12}
			item
			md={12}
			justifyContent="space-around"
			wrap="wrap"
			alignItems="center"
			spacing={3}
			style={styles}
		>
			{children}
		</PrescriptionListContainer>
	);
};

export default PrescriptionList;
