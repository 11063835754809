import { CSSProperties, FC } from 'react';
import { HospitalLogo } from '../../../components/svg';

const arialFont: CSSProperties = {
	fontFamily: 'Arial, Helvetica, sans-serif',
	fontWeight: 700,
	wordSpacing: '0.075em',
	fontStyle: 'normal',
	padding: '0',
	margin: '0',
	boxSizing: 'border-box',
};

const headerStyle: CSSProperties = {
	...arialFont,
	display: 'flex',
	overflowWrap: 'break-word',
	width: '414.67px',
	gap: '21.12px',
	padding: '29px 0px 0px 0px',
};

const logoContainerStyle: CSSProperties = {
	padding: '29px 0px 0 0',
};

const infoContainerStyle: CSSProperties = {
	...arialFont,
	display: 'flex',
	flexDirection: 'column',
	gap: '10px',
	padding: '32px 0px 12px 12px',
};

const doctorInfoStyle: CSSProperties = {
	...arialFont,
	display: 'flex',
	flexDirection: 'column',
	overflowWrap: 'break-word',
};

const headerTextStyle: CSSProperties = {
	...arialFont,
	fontSize: '14.67px',
	lineHeight: '142.9%',
};

const hospitalNameStyle: CSSProperties = {
	...arialFont,
	fontSize: '10.67px',
	fontWeight: 600,
};

const doctorSpecialtyStyle: CSSProperties = {
	...arialFont,
	fontWeight: 400,
	fontSize: '10.67px',
	marginTop: '5px',
};

const innerDivStyle: CSSProperties = {
	display: 'flex',
	gap: '10px',
};

const contactDetailStyle: CSSProperties = {
	...arialFont,
	fontWeight: 500,
	fontSize: '10.67px',
};

const PrescriptionHeader: FC<{
	doctorName?: string | null;
	doctorEmail?: string | null;
	doctorSpecialty?: string | null;
	doctorPhone?: string | null;
	doctorCellphone?: string | null;
	doctorTitle?: string | null;
	hospitalName?: string | null;
	hospitalAddress?: string | null;
	hospitalLogo: string | null;
}> = ({
	doctorName,
	doctorEmail,
	doctorSpecialty,
	doctorPhone,
	doctorTitle,
	doctorCellphone,
	hospitalName,
	hospitalAddress,
	hospitalLogo,
}) => {
	return (
		<header style={headerStyle}>
			<div style={logoContainerStyle}>
				<HospitalLogo image={hospitalLogo} />
			</div>
			<div style={infoContainerStyle}>
				<div style={doctorInfoStyle}>
					<h2 style={headerTextStyle}>{doctorName}</h2>
					<h3 style={hospitalNameStyle}>{hospitalName}</h3>
					<p style={doctorSpecialtyStyle}>
						{doctorSpecialty}
						{doctorSpecialty && doctorTitle && ' - '}
						{doctorTitle}
					</p>
				</div>
				<div>
					<p style={contactDetailStyle}>{hospitalAddress}</p>
					<p style={contactDetailStyle}>{doctorEmail}</p>
					<div style={innerDivStyle}>
						{doctorPhone && <p style={contactDetailStyle}>Tel. {doctorPhone}</p>}
						{doctorCellphone && (
							<p style={contactDetailStyle}>Cel. {doctorCellphone}</p>
						)}
					</div>
				</div>
			</div>
		</header>
	);
};

export default PrescriptionHeader;
