// @ts-nocheck
import { useContext, useState, ChangeEvent, useEffect, useRef } from 'react';
import { RelationsContext } from '../context/relations-context';
import { ClientContextProps, DoctorType, RelationsContextProps } from '../context/types';
import { SPECIALTIES } from '../shared/constants';
import { compareObjectValues } from 'shared/utilFunctions';
import { StyledAutocomplete, StyledSaveButton, StyledTextFieldHospitalConfig } from 'shared/style';
import { TextField } from '@material-ui/core';
import { ClientsContext } from 'context/client-context';
import useMobile from 'hooks/useMobile';
import MobileCardHeader from 'components/mobileNavigation/MobileCardHeader';

const Configuration = () => {
	const saveButtonRef = useRef(null);
	const { actualDoctor, updateDoctorInfo } = useContext(
		RelationsContext
	) as RelationsContextProps;
	const [doctor, setDoctor] = useState<DoctorType>({ ...actualDoctor! });
	const [originalDoctor, setOriginalDoctor] = useState<DoctorType>({
		...actualDoctor!,
	});
	const [enableSaveButton, setEnableSaveButton] = useState<boolean>(false);

	const { setModalClientState, setEditingClient } = useContext(
		ClientsContext
	) as ClientContextProps;
	const { isMobile } = useMobile();
	const onChangeHandler = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		e.persist();
		setDoctor((prevDoctor) => ({
			...prevDoctor,
			[e.target.id]: e.target.value,
		}));
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const handleModalClientState = (editing: boolean) => {
		setEditingClient(editing);
		setModalClientState(true);
	};

	const handleUpdateDoctor = async () => {
		await updateDoctorInfo(doctor);
		await setOriginalDoctor({ ...doctor });

		if (saveButtonRef.current) {
			saveButtonRef.current.blur();
		}
	};

	useEffect(() => {
		if (compareObjectValues(doctor, originalDoctor)) {
			setEnableSaveButton(true);
		} else {
			setEnableSaveButton(false);
		}
	}, [doctor, originalDoctor]);

	const bodyPrinter = () => {
		return (
			<>
				<div
					className="pb-8 pages-container overflow-y-auto w-full"
					style={
						isMobile
							? {}
							: {
									marginTop: '60px',
							  }
					}
				>
					<div
						id="myAccount"
						className="bg-white-section rounded-xl m-auto"
						style={
							isMobile
								? {
										borderRadius: '0px',
										paddingTop: 16,
										paddingBottom: 16,
										minHeight: 'calc(100dvh - 152px)',
								  }
								: {
										width: 716,
										boxShadow:
											'0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)',
										background: '#FFFFFF',
										borderRadius: '4px',
								  }
						}
					>
						<div
							className="lg:border-b-2 border-green-content"
							style={
								isMobile
									? { padding: '0 16px' }
									: {
											padding: '13px 16px',
									  }
							}
						>
							<h1
								className="font-bold raleway-font text-lg"
								style={{
									fontFamily: 'Roboto',
								}}
							>
								Datos personales
							</h1>
						</div>

						<div
							style={
								isMobile
									? {
											padding: '20px 16px',
									  }
									: {
											padding: '20px',
									  }
							}
						>
							<div>
								<div
									className="grid lg:grid-cols-2"
									style={
										isMobile
											? {}
											: {
													gap: '30px',
											  }
									}
								>
									<StyledTextFieldHospitalConfig
										id="name"
										label="Nombre Completo"
										value={doctor?.name!}
										onChange={onChangeHandler}
										variant="outlined"
										InputLabelProps={{
											shrink: true,
										}}
										fullWidth
										textFieldWidth="100%"
										style={isMobile ? { marginBottom: 24 } : {}}
									/>
									<StyledTextFieldHospitalConfig
										id="exequator"
										label="Exequatur"
										value={doctor?.exequator!}
										onChange={onChangeHandler}
										variant="outlined"
										InputLabelProps={{
											shrink: true,
										}}
										fullWidth
										textFieldWidth="100%"
										style={isMobile ? { marginBottom: 24 } : {}}
									/>
									<StyledAutocomplete
										id="specialty"
										value={doctor?.specialty!}
										options={SPECIALTIES}
										getOptionLabel={(option: any) => option}
										style={
											isMobile ? { marginBottom: 24 } : { marginBottom: 20 }
										}
										onInputChange={(e: any, newValue: string | null) => {
											if (newValue !== null) {
												setDoctor((prevDoctor) => ({
													...prevDoctor,
													specialty: newValue,
												}));
											} else {
												setDoctor((prevDoctor) => ({
													...prevDoctor,
													specialty: '',
												}));
											}
										}}
										onChange={(e: any, newValue: string | null) => {
											if (newValue !== null && e.target.value !== undefined) {
												setDoctor((prevDoctor) => ({
													...prevDoctor,
													specialty: newValue,
												}));
											} else {
												setDoctor((prevDoctor) => ({
													...prevDoctor,
													specialty: '',
												}));
											}
										}}
										renderInput={(params: any) => (
											<TextField
												{...params}
												label="Selecciona tu especialidad*"
												placeholder="Selecciona tu especialidad*"
												variant="outlined"
											/>
										)}
										fullWidth
										textFieldWidth="100%"
									/>
									<StyledTextFieldHospitalConfig
										id="email"
										label="Email"
										value={doctor?.email!}
										variant="outlined"
										InputLabelProps={{
											shrink: true,
										}}
										fullWidth
										textFieldWidth="100%"
										style={isMobile ? { marginBottom: 24 } : {}}
										disabled
									/>
									<StyledTextFieldHospitalConfig
										id="title"
										label="Titulo ( Opcional )"
										value={doctor?.title!}
										onChange={onChangeHandler}
										variant="outlined"
										InputLabelProps={{
											shrink: true,
										}}
										fullWidth
										textFieldWidth="100%"
										style={isMobile ? { marginBottom: 24 } : {}}
									/>
									<StyledTextFieldHospitalConfig
										id="phone"
										label="Celular"
										value={doctor?.phone!}
										onChange={onChangeHandler}
										placeholder="Celular"
										maxlength={14}
										variant="outlined"
										InputLabelProps={{
											shrink: true,
										}}
										fullWidth
										textFieldWidth="100%"
									/>
								</div>
							</div>

							<div
								className="flex justify-end"
								style={{
									marginTop: '30px',
								}}
							>
								<StyledSaveButton
									fullWidth={isMobile}
									onClick={handleUpdateDoctor}
									disabled={enableSaveButton}
									ref={saveButtonRef}
								>
									guardar cambios
								</StyledSaveButton>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	};
	return isMobile ? (
		<div className="mobile-wrapper">
			<div className="mobile-header">
				<MobileCardHeader />
			</div>
			{bodyPrinter()}
		</div>
	) : (
		bodyPrinter()
	);
};

export default Configuration;
