import { useState } from 'react';
import { useLocation } from 'react-router-dom';

const useTolt = (variant: string, removeParam = true) => {
	const [refId, setRefId] = useState<string | null>(null);
	const [userNameStored, setUserNameStored] = useState<string | null>(null);
	const { search } = useLocation();
	const params = new URLSearchParams(search);
	const ref = params.get(variant);

	const username = params.get('user');

	if (username && !userNameStored) {
		setUserNameStored(username);
	}

	if (ref && removeParam && !refId) {
		localStorage.setItem(variant, ref);

		// @ts-ignore
		const ref_value = window.tolt_referral;
		if (ref_value) {
			setRefId(ref_value);
		}
	}

	if (ref && !removeParam && !refId) {
		// @ts-ignore
		const ref_value = window.tolt_referral;
		if (ref_value) {
			setRefId(ref_value);
		}
	}

	const getRefStored = () => {
		const ref = localStorage.getItem(variant);
		if (ref) {
			return ref;
		}
		return null;
	};

	const resetRef = () => {
		localStorage.removeItem(variant);
	};

	return {
		refId,
		getRefStored,
		resetRef,
		userNameStored,
	};
};

export default useTolt;
