import { Card } from '@material-ui/core';
import CardPaymentIcons from '../svg/CardPaymentIcons';
import SecurePayment from '../svg/SecurePayment';

const SecureModule = () => {
	return (
		<Card
			style={{
				display: 'flex',
				justifyContent: 'space-between',
				padding: '25px',
				alignItems: 'center',
				borderRadius: '4px',
				backgroundColor: '#ffffff',
				marginTop: '21px',
				boxShadow:
					'0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)',
			}}
			className="max-w-[488px] w-full"
		>
			<SecurePayment />
			<CardPaymentIcons />
		</Card>
	);
};

export default SecureModule;
