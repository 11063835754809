import Skeleton from '@material-ui/lab/Skeleton';
import react from 'react';

const DoctorMembersSkeleton = () => {
	return (
		<>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					gap: '8px',
					padding: '8px 8px',
					background: 'rgba(255, 255, 255, 0.00)',
					boxShadow: '0px -1px 0px 0px rgba(0, 0, 0, 0.12) inset',
				}}
			>
				<Skeleton animation="wave" width="100%" height={50} />
			</div>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					gap: '8px',
					padding: '8px 8px',
					background: 'rgba(255, 255, 255, 0.00)',
					boxShadow: '0px -1px 0px 0px rgba(0, 0, 0, 0.12) inset',
				}}
			>
				<Skeleton animation="wave" width="100%" height={50} />
			</div>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					gap: '8px',
					padding: '8px 8px',
					background: 'rgba(255, 255, 255, 0.00)',
					boxShadow: '0px -1px 0px 0px rgba(0, 0, 0, 0.12) inset',
				}}
			>
				<Skeleton animation="wave" width="100%" height={50} />
			</div>
		</>
	);
};

export default DoctorMembersSkeleton;
