import ListSubheader, { ListSubheaderProps } from '@material-ui/core/ListSubheader';

interface Props extends ListSubheaderProps {
	title: string;
}

const CustomGroupTitle = (props: Props) => {
	const { title, ...rest } = props;
	return (
		<ListSubheader
			component="li"
			color="default"
			style={{
				fontSize: '14px',
				fontWeight: 500,
				letterSpacing: '0.1px',
				lineHeight: '48px',
				fontStyle: 'normal',
				fontFeatureSettings: "'clig' off, 'liga' off",
				padding: '0px 16px',
				color: '#000000DE',
			}}
			key="subheader"
			{...rest}
		>
			{title}
		</ListSubheader>
	);
};

export default CustomGroupTitle;
