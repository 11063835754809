import React, { FC, useRef } from 'react';
import { AIReportContainer, AIReportTitle, AISection, PrintButton, PrintContainer } from './styles';
import PrintAIIcon from 'components/svg/PrintAIIcon';
import { IADiagnostic } from 'shared/ai/types';
import ReactToPrint from 'react-to-print';
import DiagnosticSection from './AIDiagnosticSection';

interface props {
	diagnostics: IADiagnostic[];
}

const AIDiagnosticReport: FC<props> = ({ diagnostics }) => {
	const render = () => {
		return diagnostics.map((diagnostic: IADiagnostic, index: number) => (
			<DiagnosticSection diagnostic={diagnostic} index={index} />
		));
	};
	const claimRef = useRef(null);

	return (
		<>
			<AIReportContainer ref={claimRef}>
				<AISection>
					<AIReportTitle className="flex flex-col justify-center">
						Posibles diagnósticos
					</AIReportTitle>
					<ReactToPrint
						trigger={() => {
							return (
								<PrintContainer>
									<PrintAIIcon />
									<PrintButton>imprimir</PrintButton>
								</PrintContainer>
							);
						}}
						content={() => claimRef.current}
					/>
				</AISection>
				{render()}
			</AIReportContainer>
		</>
	);
};

export default AIDiagnosticReport;
