import { Card, CardContent } from '@material-ui/core';
import React, { useContext } from 'react';
import ProfileReview from '../components/svg/ProfileReview';
import { UserContextProps } from '../context/types';
import { UserContext } from '../context/user-context';
import { PreviewTypography, SendButton } from '../shared/style';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const DoctorReview = () => {
	const { logOut } = useContext(UserContext) as UserContextProps;

	return (
		<div className="relative top-0 left-0 w-full h-screen min-wh-login flex flex-col items-center justify-center">
			<Card
				className="flex flex-col bg-white-section p-8 rounded-md"
				style={{ minWidth: 600 }}
			>
				<CardContent className="flex flex-col items-center">
					<ProfileReview />
					<PreviewTypography variant="h5" align="center">
						Tu perfil está siendo revisado
					</PreviewTypography>
					<PreviewTypography variant="body2" align="center">
						Te contactaremos lo más pronto posible para darte acceso a la plataforma.
					</PreviewTypography>
					<SendButton
						size="large"
						variant="contained"
						style={{ margin: '35px' }}
						color="primary"
						startIcon={<ExitToAppIcon />}
						onClick={logOut}
					>
						Desloguearse
					</SendButton>
				</CardContent>
			</Card>
		</div>
	);
};

export default DoctorReview;
