import React from 'react';
import { StyledRadio, SubscriptionCard } from '../../shared/style';
import { MasterCardIcon, VisaIcon, AmericanExpresIcon, TranshIcon as DeleteIcon } from '../svg';

interface Props {
	className?: string;
	cardDetail: any;
	selected: boolean;
	selectFunction: () => void;
	deleteFunction: () => void;
}

const CardBox: React.FC<Props> = ({
	className,
	cardDetail,
	selectFunction,
	deleteFunction,
	selected,
}) => {
	const { card } = cardDetail;

	return (
		<SubscriptionCard
			className={className}
			style={{
				border: selected ? 'solid 3px #007DFF' : 'solid 1px #546e7a',
				borderRadius: '4px',
			}}
		>
			<StyledRadio
				className="!pl-0 !md:pl-[8px]"
				name="plan_price"
				checked={selected}
				color="primary"
				onClick={selectFunction}
			/>
			<div className="mr-[12px] md:mr-8">
				{card.brand === 'visa' && <VisaIcon />}
				{card.brand === 'mastercard' && <MasterCardIcon />}
				{card.brand === 'amex' && <AmericanExpresIcon />}
			</div>
			<span
				className="text-sm font-semibold raleway-font"
				style={{
					fontFamily: 'Roboto',
					fontStyle: 'normal',
					fontWeight: 400,
					fontSize: '14px',
					lineHeight: '24px',
					letterSpacing: '0.17px',
				}}
			>
				**** **** **** {card.last4}
			</span>
			<div className="ml-auto flex">
				<button onClick={deleteFunction} id="delete-card" className="cursor-pointer">
					<DeleteIcon />
				</button>
			</div>
		</SubscriptionCard>
	);
};

export default CardBox;
