import { Button } from '@material-ui/core';
import React, { FC } from 'react';
import NotFoundSVG from '../components/svg/NotFoundSVG';
import { NotFoundContainer, TypographyTitle, TypographyDescription } from '../shared/style';

type NotFoundProps = {
	resetError?: () => void;
};

const NotFound: FC<NotFoundProps> = ({ resetError }) => {
	const goHomePage = () => {
		window.location.reload();
	};

	return (
		<NotFoundContainer>
			<NotFoundSVG />
			<TypographyTitle variant="h2">Pasó algo inesperado</TypographyTitle>
			<TypographyDescription variant="body2">
				Ya hemos sido notificados y estamos investigando que pudo haber sido.
			</TypographyDescription>
			<Button variant="contained" color="primary" onClick={goHomePage}>
				Volver al inicio
			</Button>
		</NotFoundContainer>
	);
};

export default NotFound;
