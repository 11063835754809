import React, { useState, useContext, useEffect } from 'react';
import { ClientsContext } from 'context/client-context';
import { ClientContextProps, ConsultationType } from 'context/types';
import ModalConfirmation from '../../modalConfirmation/ModalConfirmation';
import ConsultationCard from 'pages/PatientConsultation/Previous/ConsultationCard';
import {
	PreviousContainer,
	Container,
	StyledPagination,
} from 'pages/PatientConsultation/Previous/style';
import { useHistory } from 'react-router-dom';
import { usePagination } from 'shared/paginationUtils';
import CustomMenu from '../../menu/CustomMenu';
import NoItem from '../../noItems/NoItem';
import { Consultation } from '../../../shared/type';
import ConsultationsSkeleton from './Consultation.skeleton';
import useMobile from 'hooks/useMobile';

interface Props {
	height?: string;
}

const Consultations: React.FC<Props> = ({ height }) => {
	const history = useHistory();
	const {
		deleteClientConsultation,
		getConsultationsData,
		loadingConsultations,
		clientConsultations,
	} = useContext(ClientsContext) as ClientContextProps;

	const LIST_PER_PAGE = 6;
	const [headerMenu, setHeaderMenu] = useState<null | HTMLElement>(null);
	const [consultationCard, setConsultationCard] = useState<ConsultationType | null>(
		{} as ConsultationType
	);
	const [openDeleteConsultation, setOpenDeleteConsultation] = useState<boolean>(false);
	const path = history.location.pathname.replace('/consultation', '');
	const { isMobile } = useMobile();
	useEffect(() => {
		const getConsultations = async () => {
			await getConsultationsData();
		};
		if (!clientConsultations || clientConsultations.length === 0) {
			getConsultations();
		}
	}, []);

	const _DATA = usePagination(
		(clientConsultations as ConsultationType[]).map((consultation) => {
			return {
				id: consultation.id,
				title: consultation.visitReason,
				date: consultation.updatedAt,
				description: consultation.visitDescription,
				note_physic_test: consultation.physicTestNote,
				medicines_prescripted: consultation.prescription?.medicines,
				labs_images: consultation.prescription?.analisis,
				freePrescription: consultation.prescription?.freePrescription,
				diagnostics: consultation.diagnostics,
				diffDiagnostics: consultation.diffDiagnostics,
				treatment: consultation.treatment,
				procedures_cirgury: consultation.procedureName,
				proceures_cirugy_description: consultation.procedureDescription,
				heartRate: consultation.heartRate,
				bloodPressure: consultation.bloodPressure,
				bodyTemperature: consultation.bodyTemperature,
				weight: consultation.weight,
				height: consultation.height,
				respiratoryRate: consultation.respiratoryRate,
				oxygenSaturation: consultation.oxygenSaturation,
				bodyMass: consultation.bodyMass,
				doctorID: consultation.doctorID,
			};
		}),
		LIST_PER_PAGE
	);
	const count = Math.ceil(clientConsultations!.length / LIST_PER_PAGE);

	const handleChangePage = (_e: any, p: number) => {
		_DATA.jump(p);
	};

	const deleteConsultation = () => {
		setOpenDeleteConsultation(true);
	};

	const confirmationDeleteConsultation = async () => {
		setHeaderMenu(null);
		await deleteClientConsultation(consultationCard as ConsultationType);
		setOpenDeleteConsultation(false);
		setConsultationCard(null);
	};

	const handleEditConsultation = () => {
		const consultationID = consultationCard && consultationCard.id;
		history.push(`${path}/client-consultation?consultationID=${consultationID}`);
	};

	const optionsForMenu = [
		{
			id: 1,
			label: 'Editar',
			onClick: handleEditConsultation,
		},
		{
			id: 2,
			label: 'Eliminar',
			onClick: deleteConsultation,
		},
	];

	const handleClickMenu = (event: React.MouseEvent<HTMLElement>, consultation: Consultation) => {
		setHeaderMenu(event.currentTarget);
		setConsultationCard(
			(clientConsultations as ConsultationType[]).find(
				(x) => x.id === consultation.id
			) as ConsultationType
		);
	};

	const handleCloseMenu = () => {
		setConsultationCard(null);
		setHeaderMenu(null);
	};

	const handleCloseConfirmation = () => {
		setConsultationCard(null);
		setOpenDeleteConsultation(false);
	};

	if (loadingConsultations) {
		return <ConsultationsSkeleton height={height} />;
	}

	return (
		<>
			{(clientConsultations as ConsultationType[]).length > 0 ? (
				<Container
					item
					xs={12}
					style={
						isMobile
							? {
									padding: '16px ',
									backgroundColor: '#eceff1',
									height: `calc(100dvh - ${height ? height : '167px'})`,
							  }
							: {
									height: `calc(100dvh - ${height ? height : '167px'})`,
							  }
					}
				>
					{_DATA.currentData().map((consultation) => {
						return (
							<PreviousContainer
								key={consultation.id}
								item
								xs={12}
								style={isMobile ? { padding: '0', marginBottom: '16px' } : {}}
							>
								<ConsultationCard
									headerAction={
										<CustomMenu
											options={optionsForMenu}
											headerMenu={headerMenu}
											handleClick={(e) => handleClickMenu(e, consultation)}
											handleClose={handleCloseMenu}
										/>
									}
									{...consultation}
								/>
							</PreviousContainer>
						);
					})}
					{clientConsultations!.length > LIST_PER_PAGE && (
						<StyledPagination
							page={_DATA.currentPage}
							onChange={handleChangePage}
							count={count}
							variant="outlined"
							color="primary"
						/>
					)}
				</Container>
			) : (
				<div className="m-auto">
					<NoItem
						height="calc(100dvh - 250px)"
						title="No tienes consultas"
						name="consulta"
						width={isMobile ? '100%' : '244px'}
						isFem={true}
						isRow={isMobile}
						btnLabel="Iniciar consulta"
						onClickHandler={() => history.push(`${path}/client-consultation`)}
					/>
				</div>
			)}
			{openDeleteConsultation && (
				<ModalConfirmation
					modalState={openDeleteConsultation}
					approveFunction={confirmationDeleteConsultation}
					name="¿Estás seguro de que deseas eliminar esta consulta?"
					closeFunction={handleCloseConfirmation}
				/>
			)}
		</>
	);
};

export default Consultations;
