import React, { FC } from 'react';

interface Props {
	data: string;
}

const AIReportDescriptionListElements: FC<Props> = ({ data }) => {
	const dataFormatted = data.split(', ');

	return (
		<>
			{dataFormatted.map((item, index) => {
				return (
					<li key={index}>
						- {item}
						<br />
					</li>
				);
			})}
		</>
	);
};

export default AIReportDescriptionListElements;
