import React from 'react';

const TranshIcon = () => (
	<svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M5.287 19.6719H12.9335C14.2518 19.6719 15.0956 18.8896 15.1571 17.5625L15.7196 5.64453H16.5897C17.0468 5.64453 17.3895 5.30176 17.3895 4.85352C17.3895 4.40527 17.0468 4.0625 16.5897 4.0625H12.9423V2.83203C12.9423 1.52246 12.1161 0.757812 10.6835 0.757812H7.51942C6.08681 0.757812 5.25185 1.52246 5.25185 2.83203V4.0625H1.62196C1.17372 4.0625 0.822159 4.40527 0.822159 4.85352C0.822159 5.30176 1.17372 5.64453 1.62196 5.64453H2.50087L3.05458 17.5713C3.1161 18.8984 3.95107 19.6719 5.287 19.6719ZM6.91298 2.90234C6.91298 2.49805 7.19423 2.23438 7.63368 2.23438H10.5604C11.0087 2.23438 11.2899 2.49805 11.2899 2.90234V4.0625H6.91298V2.90234ZM5.48036 18.0723C5.02333 18.0723 4.70692 17.7471 4.68056 17.2549L4.13564 5.64453H14.0585L13.5311 17.2549C13.5048 17.7471 13.1972 18.0723 12.7313 18.0723H5.48036ZM6.6581 16.7715C7.03603 16.7715 7.27333 16.5342 7.26454 16.1914L7.00966 7.57812C7.00087 7.22656 6.75478 7.00684 6.40321 7.00684C6.03407 7.00684 5.79677 7.23535 5.81435 7.58691L6.06044 16.2002C6.06923 16.543 6.31532 16.7715 6.6581 16.7715ZM9.11024 16.7715C9.4706 16.7715 9.71669 16.543 9.71669 16.2002V7.57812C9.71669 7.23535 9.4706 7.00684 9.11024 7.00684C8.74989 7.00684 8.49501 7.23535 8.49501 7.57812V16.2002C8.49501 16.543 8.74989 16.7715 9.11024 16.7715ZM11.5536 16.7715C11.8964 16.7715 12.1425 16.543 12.1513 16.2002L12.3974 7.58691C12.4149 7.23535 12.1776 7.00684 11.8085 7.00684C11.4569 7.00684 11.2108 7.23535 11.202 7.57812L10.9559 16.1914C10.9384 16.5342 11.1757 16.7715 11.5536 16.7715Z"
			fill="#80868B"
		/>
	</svg>
);

export const NewTrashIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM19 4H15.5L14.5 3H9.5L8.5 4H5V6H19V4Z"
			fill="black"
			fill-opacity="0.54"
		/>
	</svg>
);

export default TranshIcon;
