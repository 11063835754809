// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const PrescriptionTypes = {
  "MEDICINE": "MEDICINE",
  "LABIMAGE": "LABIMAGE",
  "FREE_PRESCRIPTION": "FREE_PRESCRIPTION"
};

const DiagnosticTypes = {
  "PRESUMPTIVE": "PRESUMPTIVE",
  "DIFFERENTIAL": "DIFFERENTIAL"
};

const TeamStatus = {
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE",
  "PENDING": "PENDING"
};

const WaitingListItemStatus = {
  "CONSULTA": "CONSULTA",
  "ESPERA": "ESPERA",
  "TERMINADA": "TERMINADA"
};

const WaitListStatus = {
  "CONSULTA": "CONSULTA",
  "ESPERA": "ESPERA"
};

const SexType = {
  "FEMENINO": "FEMENINO",
  "MASCULINO": "MASCULINO",
  "NOSEX": "NOSEX"
};

const AffiliateTypes = {
  "PRINCIPAL": "PRINCIPAL",
  "TITULAR": "TITULAR",
  "DEPENDIENTE": "DEPENDIENTE",
  "PARENTESCO": "PARENTESCO"
};

const IdentificationTypes = {
  "CEDULA": "CEDULA",
  "PASAPORTE": "PASAPORTE",
  "SINDOCUMENTOS": "SINDOCUMENTOS"
};

const AllergyStatus = {
  "ACTIVE": "ACTIVE",
  "CURED": "CURED",
  "INACTIVE": "INACTIVE"
};

const LifeStyle = {
  "ALCOHOL": "ALCOHOL",
  "TABACO": "TABACO",
  "ACTIVITY": "ACTIVITY",
  "DRUG": "DRUG"
};

const HistoryFamilyMember = {
  "FATHER": "FATHER",
  "MOTHER": "MOTHER",
  "BROTHERS": "BROTHERS",
  "GRANDPARENTS": "GRANDPARENTS",
  "GREATGRANDPARENTS": "GREATGRANDPARENTS",
  "SONS": "SONS"
};

const Status = {
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE"
};

const { Secretary, EmailDistributionLists, SecretaryHospitalDoctor, SecretaryHospitalDoctorClient, MemberHospitalDoctorClient, Client, ClientImageSection, PosibleClient, ClientImage, ConsultationDiagnostic, HabitsConsultation, SymptomsConsultation, ClientConsultation, Medicine, ClientAllergy, ClientDisease, ClientProcedure, ClientHospitalization, ClientFamilyHistory, ClientLifeStyle, ClientPrescription, ClientPrescriptionMedicine, ClientFreePrescription, ClientPrescriptionAnalisis, DoctorLabImage, LabImage, PharmacyMedicine, PharmacyMedicineMeasure, Template, TemplateMedicine, DoctorRecentDiagnosis, TemplateLabImage, DoctorMedicine, RecentDoctorMedicinePrescription, RecentDoctorLabImagePrescription, TemplateAnalisis, Analisis, ClientActiveDisease, ClientHistory, ClientNotes, ClientProcedureSurgery, Doctor, Plan, DoctorStripeCustomerSubscription, DoctorStripeCards, Hospital, HospitalDoctor, Teams, HospitalDoctorCliente, WaitList, WaitingListItem, ClientInsurance, Insurance, ClientClaim, ClaimProcess, Forms, Appointment } = initSchema(schema);

export {
  Secretary,
  EmailDistributionLists,
  SecretaryHospitalDoctor,
  SecretaryHospitalDoctorClient,
  MemberHospitalDoctorClient,
  Client,
  ClientImageSection,
  PosibleClient,
  ClientImage,
  ConsultationDiagnostic,
  HabitsConsultation,
  SymptomsConsultation,
  ClientConsultation,
  Medicine,
  ClientAllergy,
  ClientDisease,
  ClientProcedure,
  ClientHospitalization,
  ClientFamilyHistory,
  ClientLifeStyle,
  ClientPrescription,
  ClientPrescriptionMedicine,
  ClientFreePrescription,
  ClientPrescriptionAnalisis,
  DoctorLabImage,
  LabImage,
  PharmacyMedicine,
  PharmacyMedicineMeasure,
  Template,
  TemplateMedicine,
  DoctorRecentDiagnosis,
  TemplateLabImage,
  DoctorMedicine,
  RecentDoctorMedicinePrescription,
  RecentDoctorLabImagePrescription,
  TemplateAnalisis,
  Analisis,
  ClientActiveDisease,
  ClientHistory,
  ClientNotes,
  ClientProcedureSurgery,
  Doctor,
  Plan,
  DoctorStripeCustomerSubscription,
  DoctorStripeCards,
  Hospital,
  HospitalDoctor,
  Teams,
  HospitalDoctorCliente,
  WaitList,
  WaitingListItem,
  ClientInsurance,
  Insurance,
  ClientClaim,
  ClaimProcess,
  Forms,
  Appointment,
  PrescriptionTypes,
  DiagnosticTypes,
  TeamStatus,
  WaitingListItemStatus,
  WaitListStatus,
  SexType,
  AffiliateTypes,
  IdentificationTypes,
  AllergyStatus,
  LifeStyle,
  HistoryFamilyMember,
  Status
};