import React, { FC } from 'react';
import {
	SecretaryConfirmationDialog,
	SecretaryConfirmationDialogContent,
	SecretaryTitleTypography,
	SecretaryDescription,
	SendButton,
} from '../../shared/style';
import Correct from '../svg/Correct';

interface Props {
	open: boolean;
	handleClose: () => void;
}

const SecretaryConfirmation: FC<Props> = ({ open, handleClose }) => {
	return (
		<SecretaryConfirmationDialog
			className="secreatry-confirmation-modal"
			open={open}
			onClose={(_, reason) => {
				if (reason !== 'backdropClick') {
					handleClose();
				}
			}}
			aria-labelledby="secretary-dialog-title"
			aria-describedby="secretary-dialog-description"
		>
			<SecretaryConfirmationDialogContent>
				<Correct />
				<SecretaryTitleTypography variant="h2">
					Secretaria agregada
				</SecretaryTitleTypography>
				<SecretaryDescription variant="body2">
					Le hemos enviado un correo con instrucciones a la secretaria agregada.
				</SecretaryDescription>
				<SendButton
					size="large"
					variant="contained"
					color="primary"
					fullWidth
					onClick={handleClose}
				>
					Listo
				</SendButton>
			</SecretaryConfirmationDialogContent>
		</SecretaryConfirmationDialog>
	);
};

export default SecretaryConfirmation;
