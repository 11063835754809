import React, { useContext, useState, useEffect, useCallback } from 'react';
import { ClientsContext } from '../../../context/client-context';
import NoItem from '../../noItems';
import Claim from './Claim';
import ModelConfirmation from '../../modalConfirmation/ModalConfirmation';
import ClaimDetail from './ClaimDetail';
import { ClaimType, ClientContextProps } from '../../../context/types';
import { RouteProps, useHistory, useLocation } from 'react-router-dom';
import { ClaimProcess } from 'models';
import { Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import NoClaimsAllowed from './NoClaimsAllowed';
import PatientClaimSkeleton from './PatientClaim.skeleton';

const emptyClaim: ClaimType = {
	authorization: '',
	date: '',
	diagnostic: '',
	processes: [],
	id: '',
	clientHospitalDoctorID: '',
	healthInsuranceName: '',
	healthInsuranceID: '',
};

const PatientClaimPage: React.FC<RouteProps> = () => {
	const history = useHistory();
	const location = useLocation();
	const {
		selectedClient: client,
		deleteClaim,
		getClientClaimsData,
		clientClaims: claims,
		loadingClientClaims,
	} = useContext(ClientsContext) as ClientContextProps;
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [showClaimDetail, setShowClaimDetail] = useState(false);
	const [selectedClaim, setSelectedClaim] = useState<ClaimType>(emptyClaim);

	const generateNewClaim = useCallback(
		(claim: ClaimType) => {
			let processes: ClaimProcess[] = [];

			if (claims[0]) {
				const claimsFiltered = claims?.filter(
					(claim) => claim.healthInsuranceName === client?.healthInsuranceName
				);

				if (claimsFiltered.length > 0) {
					processes = claimsFiltered[0].processes;
				}
			}

			return { ...claim, processes };
		},
		[claims, client]
	);

	useEffect(() => {
		if (location && location?.state !== null && location?.state !== undefined) {
			showClaimDetailHandler(generateNewClaim(emptyClaim));
			history.push(location?.pathname, null);
		}
	}, [generateNewClaim, history, location]);

	useEffect(() => {
		const getClaims = async () => {
			await getClientClaimsData();
		};

		if (claims.length === 0 || !claims) {
			getClaims();
		}
	}, [claims, getClientClaimsData]);

	const deleteConfirmationHandler = (claim: ClaimType) => {
		setShowConfirmation(true);
		setSelectedClaim(claim);
	};

	const deleteClaimHandler = async () => {
		await deleteClaim(selectedClaim.id!);
		setSelectedClaim(emptyClaim);
		setShowConfirmation(false);
	};

	const showClaimDetailHandler = (claim: ClaimType) => {
		setSelectedClaim(claim);
		setShowClaimDetail(true);
	};

	const clickDirectAccessHanlder = () => {
		if (client!.id!) {
			const path = `/Clients/${client!.id}/claim`;
			history.push(path, { create: true });
		}
	};

	return <NoClaimsAllowed />;

	// eslint-disable-next-line no-unreachable
	if (loadingClientClaims) {
		return <PatientClaimSkeleton />;
	}

	return (
		<div className="client-pages-container relative">
			<ModelConfirmation
				approveFunction={() => deleteClaimHandler()}
				modalState={showConfirmation}
				closeFunction={() => setShowConfirmation(false)}
				name="Estás seguro que quieres eliminar esta reclamación?"
				confirmLabel={'ACEPTAR'}
			/>
			{claims?.length === 0 ? (
				<NoItem
					isRow
					name="reclamacion"
					title="No tienes reclamaciones"
					btnLabel="CREAR RECLAMACIÓN"
					onClickHandler={() => showClaimDetailHandler(generateNewClaim(emptyClaim))}
				/>
			) : (
				<div className="pl-11 pt-10 pr-3">
					{claims?.length > 0 && (
						<Fab
							style={{
								position: 'absolute',
								right: '250px',
								marginTop: '-66px',
								marginRight: '-218px',
								zIndex: 9999,
							}}
							size="small"
							color="inherit"
							onClick={clickDirectAccessHanlder}
							aria-label="add"
						>
							<AddIcon />
						</Fab>
					)}
					<h1 className="font-semibold text-blueLucki-default raleway-font mb-8 claim-page-title">
						Reclamaciones Médicas
					</h1>
					<div className="flex flex-wrap">
						{claims.map((el) => (
							<Claim
								key={el.id}
								claim={el}
								clickHandler={() => showClaimDetailHandler(el)}
								deleteHandler={() => deleteConfirmationHandler(el)}
							/>
						))}
					</div>
				</div>
			)}
			{showClaimDetail && (
				<ClaimDetail claim={selectedClaim} setClaimDetail={setShowClaimDetail} />
			)}
		</div>
	);
};

export default PatientClaimPage;
