import { Button, Card, CardContent, CardHeader } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LoginPulseara } from '../components/svg';
import { LoaderContext } from '../context/loader-context';
import { RelationsContext } from '../context/relations-context';
import { LoaderContextProps, RelationsContextProps, UserContextProps } from '../context/types';
import { UserContext } from '../context/user-context';
import { ForgotTextField, SendButton } from '../shared/style';
import { isValidEmail } from '../shared/utilFunctions';

const ForgotPassword = () => {
	const history = useHistory();
	const { showSpinner } = useContext(LoaderContext) as LoaderContextProps;
	const { forgotPassword } = useContext(UserContext) as UserContextProps;
	const { setAlertMessage } = useContext(RelationsContext) as RelationsContextProps;
	const [userEmail, setUserEmail] = useState<string>('');
	const [invalidEmailText, setInvalidEmailText] = useState<string>('');

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		setUserEmail(event.target.value);
		if (!isValidEmail(event.target.value)) {
			setInvalidEmailText('El correo no es válido.');
		} else {
			setInvalidEmailText('');
		}
	};

	const handleForgotPassword = async (email: string) => {
		if (email !== '') {
			showSpinner(true);
			const value = await forgotPassword(email);
			showSpinner(false);
			if (value) {
				setInvalidEmailText('');
				history.push(`/change-password?email=${email}`);
				setAlertMessage({
					message: 'El código ha sido enviado a su correo',
					severity: 'success',
				});
			} else {
				setInvalidEmailText('No encontramos ese correo electrónico. Vuelve a intentarlo.');
			}
		} else {
			setInvalidEmailText('El correo no es válido.');
		}
	};

	return (
		<div className="relative top-0 left-0 w-full h-screen min-wh-login flex flex-col items-center justify-center">
			<div className="mb-16">
				<div>
					<LoginPulseara />
				</div>
			</div>
			<Card className="flex flex-col bg-white-section p-8 rounded-md">
				<CardHeader
					title="Cambiar contraseña"
					subheader="Ingresa tu Email. Si estas registrado te enviaremos un código."
				/>
				<CardContent className="flex flex-col">
					<ForgotTextField
						value={userEmail}
						onChange={onChangeHandler}
						label="Ingresa tu correo"
						error={invalidEmailText !== ''}
						type="email"
						id="username"
						name="username"
						variant="outlined"
						placeholder="Ingresa tu correo"
						helperText={invalidEmailText}
					/>
					<SendButton
						size="large"
						variant="contained"
						color="primary"
						fullWidth
						onClick={() => handleForgotPassword(userEmail)}
					>
						Enviar
					</SendButton>
					<Button size="large" color="primary" fullWidth onClick={() => history.goBack()}>
						Cancelar
					</Button>
				</CardContent>
			</Card>
		</div>
	);
};

export default ForgotPassword;
