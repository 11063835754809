import { Box, CircularProgress, Modal } from '@material-ui/core';
import { FC, useContext, useEffect, useState } from 'react';
import { updateSubscriptionStripe } from '../../../adapters/stripe';
import PlanCard from '../../../components/modalRenewSubscription/PlanCard';
import { RelationsContext } from '../../../context/relations-context';
import { IDoctorContextProps, RelationsContextProps } from '../../../context/types';
import { formatPrice } from '../../../shared/paymentUtils';
import { ModalBody, ModalBodyActions, ModalBodyButton, ModalBodyTitle } from './ChangePlan.view';
import useStripeConfiguration from 'hooks/useStripeConfiguration';
import { DoctorsContext } from 'context/doctor-context';

const ChangePlan: FC<{
	open: boolean;
	handleClose: () => void;
}> = ({ open, handleClose }) => {
	const { shouldUpdateDoctorPayments } = useContext(DoctorsContext) as IDoctorContextProps;
	const { actualDoctor, setAlertMessage } = useContext(RelationsContext) as RelationsContextProps;
	const [changingPlan, setChangingPlan] = useState<boolean>(false);
	const { plans, actualSubs, fetchSubscription } = useStripeConfiguration();
	const defaultPlan = plans.find((p) => p.stripePlanKey === actualSubs?.plan.id)?.id as number;
	const [selectedPlan, setSelectedPlan] = useState<number | undefined>(defaultPlan);
	const handleSelectPlan = (value: number) => {
		setSelectedPlan(value);
	};

	const updatePlan = async () => {
		try {
			setChangingPlan(true);
			if (selectedPlan === defaultPlan) {
				setAlertMessage({
					message: 'Debes seleccionar un plan diferente',
					severity: 'error',
				});
				return;
			}
			const currentPlanObj = plans.find((p) => p.id === selectedPlan);
			if (!currentPlanObj) return;
			const keyPlan = currentPlanObj.stripePlanKey;
			const subscriptionData = {
				cancel_at_period_end: false,
				proration_behavior: 'create_prorations',
				items: [
					{
						id: actualSubs.items.data[0].id,
						price: keyPlan,
						quantity: actualSubs.quantity,
					},
				],
			};

			await updateSubscriptionStripe(
				actualDoctor?.stripeSubscriptionID as string,
				subscriptionData
			);
			shouldUpdateDoctorPayments(true);
			setAlertMessage({
				message: 'Plan actualizado correctamente',
				severity: 'success',
			});
		} catch (error) {
			setAlertMessage({
				message: 'Ocurrió un error al actualizar el plan',
				severity: 'error',
			});
		} finally {
			setChangingPlan(false);
			handleClose();
		}
	};

	useEffect(() => {
		if (!selectedPlan && defaultPlan) {
			setSelectedPlan(defaultPlan);
		}
	}, [defaultPlan, selectedPlan]);

	useEffect(() => {
		fetchSubscription();
	}, []);

	if (!selectedPlan) return null;

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<ModalBody>
				<ModalBodyTitle>Selecciona tu plan de preferencia</ModalBodyTitle>
				<Box>
					{plans.map((plan) => {
						return (
							<PlanCard
								key={plan.id}
								selectedPlan={selectedPlan}
								handleSelectPlan={handleSelectPlan}
								value={plan.id}
								title={plan.title}
								subtitle={plan.subtitle}
								price={formatPrice(plan.price.slice(0, -2) as string)}
								showTrial={false}
							/>
						);
					})}
				</Box>
				<ModalBodyActions>
					<ModalBodyButton
						variant="contained"
						onClick={updatePlan}
						disabled={changingPlan}
						hasBackGround={true}
					>
						Cambiar Plan
						{changingPlan ? (
							<CircularProgress
								size={20}
								style={{ marginLeft: '10px', color: '#FFF' }}
							/>
						) : null}
					</ModalBodyButton>
					<ModalBodyButton variant="text" onClick={handleClose}>
						Cancelar
					</ModalBodyButton>
				</ModalBodyActions>
			</ModalBody>
		</Modal>
	);
};

export default ChangePlan;
