import { FC } from 'react';
// import UpdateLoader from 'components/Loader/UpdateLoader';
// import ApplicationUpdateButtonAlert from 'components/bottomAlert/ApplicationUpdateButtonAlert';

const AutoUpdateProvider: FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	// const [isLoading, setIsLoading] = useState(false);
	// const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);

	// const scheduleEvent = (eventName: string, callback: Function) => {
	//   const activeTimeoutKey = `check_update_active_event_${eventName}`;
	//   const activeTimeout = localStorage.getItem(activeTimeoutKey);
	//   if (activeTimeout) {
	//     clearTimeout(Number(activeTimeout));
	//   }
	//   const timeout = setTimeout(() => {
	//     callback();
	//     localStorage.removeItem(activeTimeoutKey);
	//   }, 7200000);
	//   localStorage.setItem(activeTimeoutKey, String(timeout));
	// };

	// const onGetLastDeployedVersion = () => {
	//   scheduleEvent('_available_update', onGetLastDeployedVersion);
	// };

	// useEffect(() => {
	//   onGetLastDeployedVersion();
	// }, []);

	// const onDownloadUpdate = () => {
	//   setIsLoading(true);
	//   setIsUpdateAvailable(false);
	// };

	return (
		<>
			{/* {isLoading && <UpdateLoader />} */}
			{children}
			{/* <ApplicationUpdateButtonAlert
        downloadUpdate={onDownloadUpdate}
        closeUpdateNotification={() => setIsUpdateAvailable(false)}
        showUpdateNotification={isUpdateAvailable}
      /> */}
		</>
	);
};

export default AutoUpdateProvider;
