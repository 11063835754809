import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { LoadingContainer } from '../../shared/style';

const LoadingSpinner = () => {
	return (
		<LoadingContainer>
			<CircularProgress />
		</LoadingContainer>
	);
};

export default LoadingSpinner;
