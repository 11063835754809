import { Button, Card, CardContent, CardHeader, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { RouteProps, useHistory } from 'react-router-dom';
import { Hospital } from 'models';
import { LoginPulseara } from '../components/svg';
import phoneMask from '../components/textMask/phoneMask';
import { HospitalContextProps, HospitalsContext } from '../context/hospital-context';
import { LoaderContext } from '../context/loader-context';
import { RelationsContext } from '../context/relations-context';
import {
	ClientContextProps,
	DoctorType,
	LoaderContextProps,
	RelationsContextProps,
	UserContextProps,
} from '../context/types';
import { UserContext } from '../context/user-context';
import { SPECIALTIES } from '../shared/constants';
import { LogingTextField, SendButton } from '../shared/style';
import { ClientsContext } from 'context/client-context';

export type HospitalAddress = {
	address: string;
	editable: boolean;
	error: string;
	hasError: boolean;
};

const CompleteRegistrationDoctor: React.FC<RouteProps> = () => {
	const { actualHospital, updateHospital } = useContext(
		RelationsContext
	) as RelationsContextProps;
	const { logOut, completeDoctorRegistration } = useContext(UserContext) as UserContextProps;
	const {
		hospitals: DATA,
		fetchHospitals,
		createHospital,
	} = useContext(HospitalsContext) as HospitalContextProps;
	const { setOnboardingData } = useContext(ClientsContext) as ClientContextProps;
	const { showSpinner } = useContext(LoaderContext) as LoaderContextProps;
	const [specialty, setSpecialty] = useState<string>('');
	const [hospital, setHospital] = useState<Hospital | null>(null);
	const [hospitalPhone, setHospitalPhone] = useState<string>('');
	const [specialtyError, setSpecialtyError] = useState<string>('');
	const [hospitalError, setHospitalError] = useState<string>('');
	const [hospitals, setHospitals] = useState<Hospital[]>([]);
	const [hospitalAddress, setHospitalAddress] = useState<HospitalAddress>({
		address: '',
		editable: true,
		error: '',
		hasError: false,
	});
	const history = useHistory();

	const filterHospital = useCallback(
		(value: string) => {
			const filtered = DATA.filter((hospital: Hospital) =>
				hospital.name?.toLowerCase().includes(value?.toLowerCase())
			);
			setHospitals(filtered);
		},
		[DATA]
	);

	useEffect(() => {
		const getHospitals = async () => {
			await fetchHospitals();
		};
		getHospitals();
		showSpinner(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let timeout: ReturnType<typeof setTimeout>;
		if (hospital?.name) {
			timeout = setTimeout(() => {
				filterHospital(hospital?.name as string);
			}, 700);
		} else {
			if (hospitals.length !== DATA.length) {
				setHospitals(DATA);
			}
		}

		return () => {
			if (timeout) {
				clearTimeout(timeout);
			}
		};
	}, [DATA, filterHospital, hospital?.name, hospitals.length]);

	const validateForm = () => {
		let hasError = false;

		if (hospital?.name === '' || hospital?.name === undefined) {
			setHospitalError('El hospital es requerido.');
			hasError = true;
		}

		if (specialty === '') {
			setSpecialtyError('La especialidad es requerida.');
			hasError = true;
		}

		if (hospitalAddress.address === '') {
			setHospitalAddress({
				...hospitalAddress,
				error: 'La dirección es requerida.',
				hasError: true,
			});
			hasError = true;
		}

		return hasError;
	};

	const onSubmitHandler = async () => {
		showSpinner(true);

		try {
			if (!validateForm()) {
				if (actualHospital && actualHospital?.phone !== hospitalPhone) {
					await updateHospital({
						...actualHospital,
						phone: hospitalPhone,
						address: hospitalAddress.address,
					});
				}

				const newHospital = await createHospital({
					...hospital,
					address: hospitalAddress?.address,
					phone: hospitalPhone,
				} as Hospital);

				const [hospitalDoctor, user] =
					((await completeDoctorRegistration({
						hospitalID: newHospital?.id ?? hospital?.id,
						specialty,
					} as DoctorType)) as any) ?? [];

				if (hospitalDoctor) {
					setOnboardingData({
						hospitalDoctor,
						user,
					});
					history.push('/doctor-onboarding');
				}
			}
		} catch (error) {
			console.error(error);
		}
		showSpinner(false);
	};

	return (
		<div className="relative top-0 left-0 w-full h-screen min-wh-login flex flex-col items-center justify-center px-[20px]">
			<div className="mb-[40px] md:mb-10">
				<div>
					<LoginPulseara className="w-[188px] md:w-[277px] h-[45px]" />
				</div>
			</div>
			<Card className="flex flex-col bg-white-section md:p-8 rounded-md max-w-[490px] w-full">
				<CardHeader title="Datos Profesionales" className="w-full text-center" />
				<CardContent className="flex flex-col">
					<Autocomplete
						id="specialty"
						value={specialty}
						options={SPECIALTIES}
						getOptionLabel={(option: any) => option}
						style={{ marginBottom: 20 }}
						onChange={(e: any, newValue: string | null) => {
							if (newValue !== null && e.target.value !== undefined) {
								setSpecialty(newValue);
								setSpecialtyError('');
							} else {
								setSpecialty('');
							}
						}}
						renderInput={(params: any) => (
							<TextField
								{...params}
								label="Selecciona tu especialidad*"
								placeholder="Selecciona tu especialidad*"
								variant="outlined"
								helperText={specialtyError}
								error={specialtyError !== ''}
							/>
						)}
					/>
					<LogingTextField
						value={hospital?.name}
						label="Hospital o consultorio*"
						onChange={(e: any) => {
							setHospital({
								name: e.target.value,
							} as Hospital);
						}}
						type="text"
						id="hospital"
						name="hospital"
						variant="outlined"
						placeholder="Hospital o consultorio*"
						disabled={!hospitalAddress.editable}
						error={hospitalAddress.hasError}
						helperText={hospitalAddress.error}
					/>
					<LogingTextField
						value={hospitalAddress.address}
						label="Dirección*"
						onChange={(e: any) => {
							setHospitalAddress({
								...hospitalAddress,
								address: e.target.value,
								hasError: false,
								error: '',
							});
						}}
						type="text"
						id="Direccion"
						name="Direccion"
						variant="outlined"
						placeholder="Dirección*"
						disabled={!hospitalAddress.editable}
						error={hospitalAddress.hasError}
						helperText={hospitalAddress.error}
					/>
					<LogingTextField
						value={hospitalPhone}
						label="Teléfono del hospital o consultorio (Opcional)"
						onChange={(e: any) => {
							setHospitalPhone(e.target.value);
						}}
						type="text"
						id="hospitalPhone"
						name="hospitalPhone"
						InputProps={{ inputComponent: phoneMask as any }}
						variant="outlined"
						placeholder="Teléfono del hospital o consultorio (Opcional)"
					/>
					<SendButton
						size="large"
						variant="contained"
						color="primary"
						fullWidth
						onClick={onSubmitHandler}
					>
						EMPEZAR PRUEBA GRATIS
					</SendButton>
					<Button size="large" color="primary" fullWidth onClick={logOut}>
						Agregar Luego y Desloguearme
					</Button>
				</CardContent>
			</Card>
		</div>
	);
};

export default CompleteRegistrationDoctor;
