import { useContext, useReducer } from 'react';
import { Button, Card, CardContent, CardHeader, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import LoginPulseara from 'components/svg/LoginPulseara';
import phoneMask from 'components/textMask/phoneMask';
import { RelationsContextProps, ResponseStatusType, UserContextProps } from 'context/types';
import { UserContext } from 'context/user-context';
import { SPECIALTIES } from 'shared/constants';
import { LogingTextField, SendButton } from 'shared/style';
import { INITIAL_STATE, MemberActionsTypes, reducer } from './reducer/DoctorMemberReducer';
import { RelationsContext } from 'context/relations-context';

const CompleteRegistrationMember = () => {
	const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
	const { logOut, completeDoctorMemberRegistration, checkIsLogged } = useContext(
		UserContext
	) as UserContextProps;
	const { setAlertMessage } = useContext(RelationsContext) as RelationsContextProps;

	const validateFormHandler = () => {
		let isValid = true;

		if (state.specialty === '') {
			dispatch({
				type: MemberActionsTypes.setSpecialtyError,
				payload: 'Debes seleccionar una especialidad',
			});
			isValid = false;
		} else {
			dispatch({ type: MemberActionsTypes.setSpecialtyError, payload: '' });
		}

		if (state.doctorName === '') {
			dispatch({
				type: MemberActionsTypes.setDoctorNameError,
				payload: 'Debes ingresar tu nombre',
			});
			isValid = false;
		} else {
			dispatch({ type: MemberActionsTypes.setDoctorNameError, payload: '' });
		}

		if (state.doctorPhone === '') {
			dispatch({
				type: MemberActionsTypes.setDoctorPhoneError,
				payload: 'Debes ingresar tu número de celular',
			});
			isValid = false;
		} else {
			dispatch({ type: MemberActionsTypes.setDoctorPhoneError, payload: '' });
		}

		if (state.doctorPhone.length < 10) {
			dispatch({
				type: MemberActionsTypes.setDoctorPhoneError,
				payload: 'Debes ingresar un número de celular válido',
			});
			isValid = false;
		} else {
			dispatch({ type: MemberActionsTypes.setDoctorPhoneError, payload: '' });
		}

		if (state.password === '') {
			dispatch({
				type: MemberActionsTypes.setNewPasswordError,
				payload: 'Debes ingresar una contraseña',
			});
			isValid = false;
		} else {
			dispatch({ type: MemberActionsTypes.setNewPasswordError, payload: '' });
		}

		if (state.confirmPassword === '') {
			dispatch({
				type: MemberActionsTypes.setConfirmPasswordError,
				payload: 'Debes confirmar tu contraseña',
			});
			isValid = false;
		} else {
			dispatch({ type: MemberActionsTypes.setConfirmPasswordError, payload: '' });
		}

		if (state.password !== state.confirmPassword) {
			dispatch({
				type: MemberActionsTypes.setConfirmPasswordError,
				payload: 'Las contraseñas no coinciden',
			});
			isValid = false;
		} else {
			dispatch({ type: MemberActionsTypes.setConfirmPasswordError, payload: '' });
		}

		return isValid;
	};

	const onSubmitHandler = async () => {
		try {
			dispatch({ type: MemberActionsTypes.setLoading, payload: true });
			if (!validateFormHandler()) return;
			const response = await completeDoctorMemberRegistration(state);

			if (response.status !== ResponseStatusType.SUCCESS) return;

			setAlertMessage({
				message: 'Registro completado con éxito',
				severity: 'success',
			});

			await checkIsLogged();
		} catch (error) {
			setAlertMessage({
				message: 'Ocurrió un error al completar el registro',
				severity: 'error',
			});
		} finally {
			dispatch({ type: MemberActionsTypes.setLoading, payload: false });
		}
	};

	return (
		<div className="relative top-0 left-0 w-full h-screen min-wh-login flex flex-col items-center justify-center px-[20px]">
			<div className="mb-[40px] md:mb-10">
				<div>
					<LoginPulseara className="w-[188px] md:w-[277px] h-[45px]" />
				</div>
			</div>
			<Card className="flex flex-col bg-white-section md:p-8 rounded-md max-w-[490px] w-full">
				<CardHeader title="Datos Profesionales" className="w-full text-center" />
				<CardContent className="flex flex-col">
					<Autocomplete
						id="specialty"
						value={state.specialty}
						options={SPECIALTIES}
						getOptionLabel={(option: any) => option}
						style={{ marginBottom: 20 }}
						onChange={(e: any, newValue: string | null) => {
							if (newValue !== null && e.target.value !== undefined) {
								dispatch({
									type: MemberActionsTypes.setSpecialty,
									payload: newValue,
								});
								dispatch({
									type: MemberActionsTypes.setSpecialtyError,
									payload: '',
								});
							} else {
								dispatch({ type: MemberActionsTypes.setSpecialty, payload: '' });
							}
						}}
						renderInput={(params: any) => (
							<TextField
								{...params}
								label="Selecciona tu especialidad*"
								placeholder="Selecciona tu especialidad*"
								variant="outlined"
								helperText={state.specialtyError}
								error={state.specialtyError !== ''}
							/>
						)}
					/>
					<LogingTextField
						value={state.doctorName}
						label="Nombre*"
						onChange={(e: any) => {
							dispatch({
								type: MemberActionsTypes.setDoctorName,
								payload: e.target.value,
							});
						}}
						type="text"
						id="doctor-name"
						name="Nombre"
						variant="outlined"
						placeholder="Nombre*"
						error={state.doctorNameError !== ''}
						helperText={state.doctorNameError}
					/>
					<LogingTextField
						value={state.doctorPhone}
						label="Número de celular"
						onChange={(e: any) => {
							dispatch({
								type: MemberActionsTypes.setDoctorPhone,
								payload: e.target.value,
							});
						}}
						type="text"
						id="doctor-phone"
						name="doctor-phone"
						InputProps={{ inputComponent: phoneMask as any }}
						variant="outlined"
						placeholder="Número de celular*"
						error={state.doctorPhoneError !== ''}
						helperText={state.doctorPhoneError}
					/>
					<LogingTextField
						value={state.password}
						label="Nueva contraseña*"
						onChange={(e: any) => {
							dispatch({
								type: MemberActionsTypes.setNewPassword,
								payload: e.target.value,
							});
						}}
						type="password"
						id="doctor-password"
						name="doctor-password"
						variant="outlined"
						placeholder="Nueva contraseña*"
						error={state.passwordError !== ''}
						helperText={state.passwordError}
					/>
					<LogingTextField
						value={state.confirmPassword}
						label="Confirmar contraseña*"
						onChange={(e: any) => {
							dispatch({
								type: MemberActionsTypes.setConfirmPassword,
								payload: e.target.value,
							});
						}}
						type="password"
						id="doctor-confirm-password"
						name="doctor-confirm-password"
						variant="outlined"
						placeholder="Confirmar contraseña*"
						error={state.confirmPasswordError !== ''}
						helperText={state.confirmPasswordError}
					/>
					<SendButton
						size="large"
						variant="contained"
						color="primary"
						fullWidth
						onClick={onSubmitHandler}
						disabled={state.loading}
					>
						{state.loading ? 'Completando registro...' : 'Completar Registro'}
					</SendButton>
					<Button
						size="large"
						color="primary"
						fullWidth
						onClick={logOut}
						disabled={state.loading}
					>
						Agregar Luego y Desloguearme
					</Button>
				</CardContent>
			</Card>
		</div>
	);
};

export default CompleteRegistrationMember;
