import React, { ReactElement } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { withStyles } from '@material-ui/core';

import { StyledCardActionBtn } from '../../shared/style';

interface Option {
	id: number | string;
	label: string;
	onClick: (itemId?: number | string) => void;
	onlyMobile?: boolean;
}
interface Options {
	options?: Option[];
	headerMenu: null | HTMLElement;
	handleClick: (event: React.MouseEvent<HTMLElement>) => void;
	handleClose: () => void;
	isCustomButton?: boolean;
	customButtonContent?: ReactElement[] | ReactElement;
	id?: string;
	menuVisible?: boolean;
}

const StyledIconButton = withStyles({
	root: {
		padding: 0,
	},
})(IconButton);

const CustomMenu: React.FC<Options> = ({
	options = [],
	isCustomButton,
	customButtonContent,
	headerMenu,
	handleClick,
	handleClose,
}) => {
	const open = Boolean(headerMenu);

	return (
		<>
			{isCustomButton && customButtonContent ? (
				<StyledCardActionBtn
					size={'small'}
					color="primary"
					aria-label="more"
					aria-controls="long-menu"
					aria-haspopup="true"
					onClick={handleClick}
				>
					{customButtonContent}
				</StyledCardActionBtn>
			) : (
				<StyledIconButton
					aria-label="more"
					aria-controls="long-menu"
					aria-haspopup="true"
					onClick={handleClick}
				>
					<MoreVertIcon />
				</StyledIconButton>
			)}
			{options.length > 0 && (
				<Menu
					id="long-menu"
					anchorEl={headerMenu}
					keepMounted
					open={open}
					onClose={handleClose}
					PaperProps={{
						style: {
							width: '20ch',
						},
					}}
					data-fx-name="customMenuContainer"
				>
					{options.map((option) => (
						<MenuItem
							key={option.id}
							onClick={() => option.onClick()}
							data-fx-name={option.label}
						>
							{option.label}
						</MenuItem>
					))}
				</Menu>
			)}
		</>
	);
};

export default CustomMenu;
