import { Alert } from 'components/svg/Alert';

const AlertPayment = () => (
	<div
		className="max-w-[528px] w-full h-[68px] bg-[#ED6C02] flex gap-2 justify-center items-center fixed z-[99999] bottom-[12%]"
		style={{
			left: '50%',
			transform: 'translateX(-50%)',
		}}
	>
		<Alert />
	</div>
);

export default AlertPayment;
