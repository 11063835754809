import React from 'react';

const MasterCardIcon = () => (
	<svg width="29" height="18" viewBox="0 0 29 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M19.603 17.7641C24.3035 17.7641 28.114 13.9049 28.114 9.14427C28.114 4.38366 24.3035 0.524414 19.603 0.524414C17.4963 0.524414 15.5683 1.29966 14.0821 2.58385C12.5959 1.29997 10.6682 0.524927 8.56175 0.524927C3.86127 0.524927 0.0507812 4.38417 0.0507812 9.14478C0.0507812 13.9054 3.86127 17.7646 8.56175 17.7646C10.6685 17.7646 12.5965 16.9894 14.0827 15.7052C15.5688 16.9891 17.4966 17.7641 19.603 17.7641Z"
			fill="#ED0006"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.082 15.705C15.912 14.1239 17.0724 11.7714 17.0724 9.14427C17.0724 6.51719 15.912 4.1646 14.082 2.58356C15.5682 1.29955 17.4961 0.524414 19.6026 0.524414C24.3031 0.524414 28.1136 4.38366 28.1136 9.14427C28.1136 13.9049 24.3031 17.7641 19.6026 17.7641C17.4961 17.7641 15.5682 16.989 14.082 15.705Z"
			fill="#F9A000"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.0822 15.7044C15.9122 14.1234 17.0725 11.7708 17.0725 9.14372C17.0725 6.51664 15.9122 4.16405 14.0822 2.58301C12.2522 4.16405 11.0918 6.51664 11.0918 9.14372C11.0918 11.7708 12.2522 14.1234 14.0822 15.7044Z"
			fill="#FF5E00"
		/>
	</svg>
);

export default MasterCardIcon;
