import React, { useState, useEffect, useContext, Dispatch } from 'react';
import {
	UserContextProps,
	DoctorType,
	SecretaryType,
	ClientType,
	RelationsContextProps,
	LoaderContextProps,
	ClientContextProps,
	PlanType,
	ResponseStatusType,
	UserRoles,
	IMemberDropdown,
} from './types';
import { default as Amplify } from '@aws-amplify/core';
import { RelationsContext } from './relations-context';
import { HospitalDoctor, Hospital, Secretary, Plan, Status } from 'models';
import { WaitingListContextProps, WaitingListsContext } from './waiting-list-context';
import {
	createSubscription,
	getCurrentSubscription,
	getCurrentSubscriptionPlan,
} from '../adapters/stripe';
import { useHistory } from 'react-router-dom';
import { LoaderContext } from './loader-context';
import { ClientsContext } from './client-context';
import { getFormatNextPaymentDate } from '../shared/paymentUtils';
import {
	CUSTOM_PASSWORD_IDENTIFIER,
	DOCTOR_IDENTIFIER,
	IS_TRIAL_IDENTIFIER,
	MONTHLY_PLAN_ID,
	NOT_AUTHENTICATED,
	SECRETARY_CUSTOM_PASSWORD_IDENTIFIER,
	SECRETARY_IDENTIFIER,
	StripePaymentStatus,
} from '../shared/constants';
import { generatePassword, isDoctor, isDoctorMember, isSecretary } from 'shared/utilFunctions';
import useAPI from 'hooks/API';
import {
	listDoctorStripeCustomerSubscriptions,
	listPlans,
	syncDoctorStripeCards,
	syncDoctorStripeCustomerSubscriptions,
	syncDoctors,
	syncHospitalDoctors,
	syncSecretaries,
	syncSecretaryHospitalDoctors,
	syncTeams,
} from 'graphql/queries';
import {
	createHospitalDoctor,
	createSecretary,
	createSecretaryHospitalDoctor,
	updateDoctor,
	updateDoctorStripeCustomerSubscription,
	updateSecretary,
	updateSecretaryHospitalDoctor,
	updateTeams,
} from 'graphql/mutations';
import useLocalStorage from 'hooks/useLocalStorage';
import { MemberState } from 'pages/reducer/DoctorMemberReducer';
import { TeamStatus } from 'models';
import { CognitoError } from 'pages/Login';
import { syncTeamsForSecretary } from 'customQueries/members-custom-queries';

export const UserContext = React.createContext<Partial<UserContextProps>>({});

const UserProvider: React.FC = (props) => {
	const history = useHistory();
	const { Auth } = Amplify;
	const [userAuthenticated, setUserAuthenticated] = useState<any | null | undefined>(null);
	const [plans, setPlans] = useState<PlanType[]>([]);
	const {
		setAlertMessage,
		setActualHospitalDoctor,
		setOwnerHospitalDoctorId,
		setActualHospital,
		setActualDoctor,
		updatePaymentDate,
		actualDoctor,
		setActualSecretary,
	} = useContext(RelationsContext) as RelationsContextProps;
	const [isError, setIsError] = useState(false);
	const { showSpinner } = useContext(LoaderContext) as LoaderContextProps;
	const [_, setValue] = useLocalStorage('username', '');
	const { upsertCurrentWaitingList, setWaitingListItems } = useContext(
		WaitingListsContext
	) as WaitingListContextProps;
	const [userRole, setUserRole] = useState<UserRoles>(UserRoles.Owner);
	const { setClients, setPaginatedClients } = useContext(ClientsContext) as ClientContextProps;
	const [doctorMembers, setDoctorMembers] = useState<IMemberDropdown[]>([]);
	const [doctorMemberSelected, setDoctorMemberSelected] = useState<any>(null);
	const { execute } = useAPI();
	useEffect(() => {
		if (isError) {
			return history.push('/Not-Found');
		}
	}, [isError]);

	useEffect(() => {
		if (actualDoctor && userAuthenticated) {
			updateDateForPayment();
		}
	}, [userAuthenticated]);

	const fetchPlans = async () => {
		const currentPlans: PlanType[] = [];
		const plansResponse = await execute(listPlans);
		const dbPlans = plansResponse.data.listPlans.items as Plan[];
		await Promise.all(
			dbPlans.map(async (plan) => {
				const { amount_decimal } = await getCurrentSubscriptionPlan(
					plan.stripePlanKey as string
				);
				currentPlans.push({
					id: plan.refID as number,
					title: plan.title as string,
					subtitle: plan.subTitle as string,
					price: amount_decimal ? amount_decimal : '',
					stripePlanKey: plan.stripePlanKey as string,
					refID: plan.refID as number,
				});
			})
		);
		setPlans(currentPlans);
	};

	useEffect(() => {
		checkIsLogged();
		if (plans.length === 0) {
			fetchPlans();
		}
	}, []);

	const updateDateForPayment = async () => {
		await updatePaymentDate();
		showSpinner(false);
	};

	const doctorMemberSelectedHandler = (doctor: any) => {
		setDoctorMemberSelected(doctor);
	};

	const forgotPassword = async (username: string) => {
		return Auth.forgotPassword(username)
			.then((data: any) => {
				setAlertMessage({
					message: 'El código ha sido enviado a su correo',
					severity: 'success',
				});
				return data;
			})
			.catch(() => {
				return null;
			});
	};

	const changePassword = async (username: string, code: string, newPassword: string) => {
		try {
			const data = await Auth.forgotPasswordSubmit(username, code, newPassword);
			setAlertMessage({
				message: 'La contraseña ha cambiado satisfactoriamente.',
				severity: 'success',
			});
			return data;
		} catch (error) {
			setAlertMessage({
				message: 'El código es incorrecto.',
				severity: 'error',
			});
			return null;
		}
	};

	const getDoctorByEmail = async (email: string) => {
		try {
			const doctorResponse = await execute(syncDoctors, {
				filter: {
					email: {
						eq: email,
					},
				},
			});
			return doctorResponse.data.syncDoctors.items[0];
		} catch (error) {
			setIsError(true);
			return null;
		}
	};

	const getStripeOwnerSubscription = async (subID: string) => {
		try {
			const subscription = await getCurrentSubscription(subID);
			return subscription;
		} catch (error) {
			setIsError(true);
			return null;
		}
	};

	const checkIfStripeSubIsCancelled = async (subID: string) => {
		const ownerSub = await getStripeOwnerSubscription(subID);

		return (
			ownerSub &&
			ownerSub.status !== StripePaymentStatus.Trialing &&
			ownerSub.status !== StripePaymentStatus.Active &&
			ownerSub.status !== StripePaymentStatus.Succeeded
		);
	};

	const transformDoctor = (
		doctor: DoctorType,
		doctorStripeCustomerSubscription: any,
		user: any,
		doctorStripeCards: any = null
	): DoctorType => {
		return {
			...doctor,
			stripeCustomerID: doctorStripeCustomerSubscription?.stripeCustomerID || '',
			cancelAtPeriodEnd: doctorStripeCustomerSubscription?.cancelAtPeriodEnd || false,
			currentPlanID: doctorStripeCustomerSubscription?.currentPlanID || MONTHLY_PLAN_ID,
			nextPaymentDate: doctorStripeCustomerSubscription?.nextPaymentDate || '',
			stripeSubscriptionID: doctorStripeCustomerSubscription?.stripeSubscriptionID || '',
			trialsEndsAt: doctorStripeCustomerSubscription?.trialsEndsAt || '',
			stripeCards: doctorStripeCards || [],
			defaultCard: doctorStripeCustomerSubscription?.defaultCard || '',
			hasTrial: user[IS_TRIAL_IDENTIFIER] === 'true',
			confirmPassword: '',
			medicines: [],
			labImages: [],
			templates: [],
			recentLabImages: [],
			recentMedicines: [],
		};
	};

	const handleDoctorMemberLogin = async (
		properties: any,
		user: any,
		currentUserRole?: UserRoles
	): Promise<ResponseStatusType | void | DoctorType | null> => {
		try {
			const doctorResponse = await execute(syncDoctors, {
				filter: {
					id: {
						eq: properties[DOCTOR_IDENTIFIER],
					},
				},
			});

			const doctor = doctorResponse.data.syncDoctors.items[0];

			if (!doctor || Array.isArray(doctor)) {
				return ResponseStatusType.ERROR;
			}

			if (doctor && doctor?.firstTime === true) {
				if (isDoctorMember(user)) {
					return history.push('/complete-registration-member');
				} else {
					return history.push('/complete-registration-doctor', {
						email: doctor?.email,
					});
				}
			}

			if (doctor && doctor?.isBeenReview === true) {
				return history.push('/review');
			}

			const hospitalDoctorApiResponse = await execute(syncHospitalDoctors, {
				filter: {
					doctorID: {
						eq: doctor?.id,
					},
				},
			});

			const hospitalDoctor = hospitalDoctorApiResponse.data.syncHospitalDoctors.items[0];

			const memberResponse = await execute(syncTeams, {
				filter: {
					MemberID: {
						eq: hospitalDoctor?.id,
					},
				},
			});
			const member = memberResponse.data.syncTeams.items[0];
			const ownerId = member.OwnerID;
			const doctorID = member.Owner.doctorID;

			const DoctorStripeCustomerSubscriptionResponse = await execute(
				syncDoctorStripeCustomerSubscriptions,
				{
					filter: {
						doctorID: {
							eq: doctorID ?? doctor?.id,
						},
					},
				}
			);

			const doctorStripeCustomerSubscription =
				DoctorStripeCustomerSubscriptionResponse.data.syncDoctorStripeCustomerSubscriptions
					.items[0];

			const doctorStripeCardsResponse = await execute(syncDoctorStripeCards, {
				filter: {
					DoctorID: {
						eq: doctor?.id,
					},
					status: {
						eq: Status.ACTIVE,
					},
				},
			});
			const isStripeSubCancelled = await checkIfStripeSubIsCancelled(
				doctorStripeCustomerSubscription.stripeSubscriptionID
			);

			if (isStripeSubCancelled) {
				return history.push('/blocked-account');
			}
			const doctorStripeCards = doctorStripeCardsResponse.data.syncDoctorStripeCards.items;
			const transformedDoctor = transformDoctor(
				doctor,
				doctorStripeCustomerSubscription,
				user,
				doctorStripeCards
			);
			setActualDoctor(transformedDoctor);

			if (
				!doctor?.isAdmin &&
				doctorStripeCustomerSubscription &&
				!doctorStripeCustomerSubscription.stripeSubscriptionID
			) {
				await fetchPlans();
				return history.push('/select-plan');
			}

			if (hospitalDoctor) {
				setActualHospitalDoctor(hospitalDoctor);
				setActualHospital(hospitalDoctor.hospital);
				setOwnerHospitalDoctorId(ownerId);
				await upsertCurrentWaitingList(hospitalDoctor);
			}

			setUserAuthenticated(user);
			return transformedDoctor;
		} catch (error) {
			setIsError(true);
		}
	};

	const handleDoctorLogin = async (
		user: any,
		userAttributes: any,
		hideSetUser?: boolean,
		currentUserRole?: UserRoles
	): Promise<ResponseStatusType | void | DoctorType | null> => {
		try {
			const doctorResponse = await execute(syncDoctors, {
				filter: {
					id: {
						eq: user[DOCTOR_IDENTIFIER],
					},
				},
			});
			const doctor = doctorResponse.data.syncDoctors.items[0];

			if (!doctor || Array.isArray(doctor)) {
				return ResponseStatusType.ERROR;
			}

			if (doctor && doctor?.firstTime === true) {
				return history.push('/complete-registration-doctor', {
					email: doctor?.email,
				});
			}

			if (doctor && doctor?.isBeenReview === true) {
				return history.push('/review');
			}

			const hospitalDoctorApiResponse = await execute(syncHospitalDoctors, {
				filter: {
					doctorID: {
						eq: doctor?.id,
					},
				},
			});

			const hospitalDoctor = hospitalDoctorApiResponse.data.syncHospitalDoctors.items[0];

			const DoctorStripeCustomerSubscriptionResponse = await execute(
				syncDoctorStripeCustomerSubscriptions,
				{
					filter: {
						doctorID: {
							eq: doctor?.id,
						},
					},
				}
			);

			const doctorStripeCustomerSubscription =
				DoctorStripeCustomerSubscriptionResponse.data.syncDoctorStripeCustomerSubscriptions
					.items[0];

			const doctorStripeCardsResponse = await execute(syncDoctorStripeCards, {
				filter: {
					DoctorID: {
						eq: doctor?.id,
					},
					status: {
						eq: Status.ACTIVE,
					},
				},
			});

			const doctorStripeCards = doctorStripeCardsResponse.data.syncDoctorStripeCards.items;
			const transformedDoctor = transformDoctor(
				doctor,
				doctorStripeCustomerSubscription,
				user,
				doctorStripeCards
			);
			setActualDoctor(transformedDoctor);

			if (
				!doctor?.isAdmin &&
				doctorStripeCustomerSubscription &&
				!doctorStripeCustomerSubscription.stripeSubscriptionID
			) {
				await fetchPlans();
			}

			if (hospitalDoctor) {
				setActualHospitalDoctor(hospitalDoctor);
				setActualHospital(hospitalDoctor.hospital);
				setOwnerHospitalDoctorId('');
				await upsertCurrentWaitingList(hospitalDoctor);
			}

			setUserAuthenticated(userAttributes);

			return transformedDoctor;
		} catch (error) {
			setIsError(true);
		}
	};

	const getDoctorMembersByDropDown = async (doctorID: string): Promise<IMemberDropdown[]> => {
		try {
			const doctorMembersResponse = await execute(syncTeamsForSecretary, {
				filter: {
					OwnerID: {
						eq: doctorID,
					},
					status: {
						eq: TeamStatus.ACTIVE,
					},
				},
			});

			const members = doctorMembersResponse.data.syncTeams.items;

			const membersFormatted = members.map((member: any) => {
				const { doctor, lastWaitingListID, id } = member.Member;
				return {
					doctorID: id,
					doctorName: doctor.name,
					lastWaitingListID: lastWaitingListID,
				};
			});

			return membersFormatted satisfies IMemberDropdown[];
		} catch (error) {
			setIsError(true);
			return [] as IMemberDropdown[];
		}
	};

	const handleSecretaryLogin = async (
		attributes: any,
		user: any
	): Promise<ResponseStatusType | void> => {
		try {
			const secretaryHospitalDoctorApiResponse = await execute(syncSecretaryHospitalDoctors, {
				filter: {
					secretaryID: {
						eq: attributes[SECRETARY_IDENTIFIER],
					},
				},
			});

			const secretaryHospitalDoctor =
				secretaryHospitalDoctorApiResponse.data.syncSecretaryHospitalDoctors.items[0];
			if (!secretaryHospitalDoctor) {
				return ResponseStatusType.ERROR;
			}

			if (secretaryHospitalDoctor.status === Status.INACTIVE) {
				return ResponseStatusType.ERROR;
			}

			if (
				secretaryHospitalDoctor?.secretary &&
				secretaryHospitalDoctor?.secretary.firstTime
			) {
				return history.push('/complete-registration-secretary');
			}

			setActualSecretary({
				...secretaryHospitalDoctor,
				...(secretaryHospitalDoctor.secretary as Secretary),
			});
			setActualHospitalDoctor(secretaryHospitalDoctor.hospitalDoctor as HospitalDoctor);

			setActualHospital(secretaryHospitalDoctor.hospitalDoctor.hospital as Hospital);
			const members = await getDoctorMembersByDropDown(
				secretaryHospitalDoctor.hospitalDoctor.id
			);

			const ownerForDropdown = {
				doctorID: secretaryHospitalDoctor.hospitalDoctor.doctor.id,
				doctorName: secretaryHospitalDoctor.hospitalDoctor.doctor.name,
				lastWaitingListID: secretaryHospitalDoctor.hospitalDoctor.lastWaitingListID,
			} satisfies IMemberDropdown;

			const doctorStripeCustomerSubscriptionResponse = await execute(
				syncDoctorStripeCustomerSubscriptions,
				{
					filter: {
						doctorID: {
							eq: secretaryHospitalDoctor.hospitalDoctor.doctor.id,
						},
					},
				}
			);

			const doctorStripeCustomerSubscription =
				doctorStripeCustomerSubscriptionResponse.data.syncDoctorStripeCustomerSubscriptions
					.items[0];

			const doctor = secretaryHospitalDoctor.hospitalDoctor.doctor;
			const transformedDoctor = transformDoctor(
				doctor,
				doctorStripeCustomerSubscription,
				user
			);
			setActualDoctor(transformedDoctor);

			const isStripeSubCancelled = await checkIfStripeSubIsCancelled(
				doctorStripeCustomerSubscription.stripeSubscriptionID
			);

			if (isStripeSubCancelled) {
				return ResponseStatusType.INACTIVE;
			}

			setDoctorMembers([ownerForDropdown, ...members]);
			await upsertCurrentWaitingList(secretaryHospitalDoctor.hospitalDoctor);
			setUserAuthenticated(user);
		} catch (error) {
			return ResponseStatusType.ERROR;
		}
	};

	const checkIsLogged = async (redirectTo: string = '/') => {
		try {
			const user = await Auth.currentAuthenticatedUser();
			if (isDoctorMember(user)) {
				showSpinner(true);
				setUserRole(UserRoles.Member);
				await handleDoctorMemberLogin(user.attributes, user, UserRoles.Member);
				showSpinner(false);
				return history.push(redirectTo);
			} else if (isDoctor(user)) {
				showSpinner(true);
				setUserRole(UserRoles.Owner);
				await handleDoctorLogin(user.attributes, user, false, UserRoles.Owner);
				showSpinner(false);
				return history.push(redirectTo);
			} else if (isSecretary(user)) {
				showSpinner(true);
				setUserRole(UserRoles.Secretary);
				await handleSecretaryLogin(user.attributes, user);
				showSpinner(false);
				return history.push(redirectTo);
			}
		} catch (e) {
			if (e === NOT_AUTHENTICATED) {
				showSpinner(false);
				setUserAuthenticated(null);
				return;
			}
			setIsError(true);
			showSpinner(false);
			setUserAuthenticated(null);
		}
	};

	const getDoctorMemberStatus = async (doctorID: string) => {
		try {
			const hospitalDoctorResponse = await execute(syncHospitalDoctors, {
				filter: {
					doctorID: {
						eq: doctorID,
					},
				},
			});

			const hospitalDoctor = hospitalDoctorResponse.data.syncHospitalDoctors.items[0];

			const teamResponse = await execute(syncTeams, {
				filter: {
					MemberID: {
						eq: hospitalDoctor.id,
					},
				},
			});

			const team = teamResponse.data.syncTeams.items[0];

			return team.status;
		} catch (error) {
			setIsError(true);
		}
	};

	const logIn = async (
		username: string,
		password: string,
		setErrorLoginText: Dispatch<React.SetStateAction<string>>
	): Promise<ResponseStatusType | void> => {
		const user = await signIn(username, password);
		if (isDoctorMember(user)) {
			showSpinner(true);
			setUserRole(UserRoles.Member);
			const doctor = await getDoctorByEmail(username);
			if (doctor && doctor.firstTime) {
				history.push('/complete-registration-member', { username, password });
			}
			const doctorMemberStatus = await getDoctorMemberStatus(doctor.id);
			if (doctorMemberStatus === TeamStatus.INACTIVE) {
				setErrorLoginText('El doctor no está activo.');
				showSpinner(false);
				await Auth.signOut();
				return ResponseStatusType.INACTIVE;
			}
			const response = await handleDoctorMemberLogin(user.attributes, user, UserRoles.Member);
			if (response === ResponseStatusType.INACTIVE || response === ResponseStatusType.ERROR) {
				setErrorLoginText('El doctor no está activo.');
			}

			showSpinner(false);
			return ResponseStatusType.SUCCESS;
		}
		if (isDoctor(user)) {
			showSpinner(true);
			setUserRole(UserRoles.Owner);
			const resDoctorLogin = await handleDoctorLogin(
				user.attributes,
				user,
				true,
				UserRoles.Owner
			);
			if (
				resDoctorLogin === ResponseStatusType.INACTIVE ||
				resDoctorLogin === ResponseStatusType.ERROR
			) {
				setErrorLoginText('El doctor no está activo.');
			}

			showSpinner(false);
		} else if (isSecretary(user)) {
			showSpinner(true);
			const resSecretaryLogIn = await handleSecretaryLogin(user.attributes, user);

			if (
				resSecretaryLogIn === ResponseStatusType.INACTIVE ||
				resSecretaryLogIn === ResponseStatusType.ERROR
			) {
				setErrorLoginText('La secretaria no está activa.');
			}

			setUserRole(UserRoles.Secretary);
			showSpinner(false);
		} else {
			setIsError(true);
			return ResponseStatusType.ERROR;
		}

		setValue(username);
		return ResponseStatusType.SUCCESS;
	};

	const signIn = async (username: string, password: string) => {
		const user = await Amplify.Auth.signIn(username, password);
		return user;
	};

	const logOut = async () => {
		await Auth.signOut();
		setUserAuthenticated(null);
		setActualDoctor(null);
		setActualHospital(null);
		setActualHospitalDoctor(null);
		setActualSecretary!(null);
		setClients([] as ClientType[]);
		setPaginatedClients({
			clients: [],
			token: [
				{
					page: 1,
					token: '',
				},
			],
			pageCount: 0,
			pageIndex: 0,
			pageSize: 0,
			totalItemCount: 0,
		});
		setWaitingListItems([]);
		const userName = localStorage.getItem('username');
		window.localStorage.clear();
		localStorage.setItem('username', userName as string);

		history.push('/');
	};

	const confirmSignUp = async (username: string, confirmationCode: string) => {
		await Auth.confirmSignUp(username, confirmationCode);
	};

	const resendConfirmationCode = async (username: string) => {
		try {
			await Auth.resendSignUp(username);
		} catch (error) {
			setIsError(true);
		}
	};

	const upsertSecretary = async (secretary: SecretaryType): Promise<SecretaryType | null> => {
		/*
      First Verify if there is already a secretary with this email, if there is then it's goint to be used to be added to the doctor
    */
		try {
			const secretaryApiResponse = await execute(syncSecretaries, {
				filter: {
					email: {
						eq: secretary.email,
					},
				},
			});

			const secretaryExist = secretaryApiResponse.data.syncSecretaries.items[0];
			if (!secretaryExist) {
				const newSecretaryApiResponse = await execute(createSecretary, {
					input: {
						email: secretary.email,
						firstTime: true,
					},
				});

				const newSecretary = newSecretaryApiResponse.data.createSecretary;
				const hospitalDoctorApiResponse = await execute(syncHospitalDoctors, {
					filter: {
						hospitalID: {
							eq: secretary.hospitalDoctorID,
						},
						doctorID: {
							eq: actualDoctor!.id,
						},
					},
				});

				const hospitalDoctor = hospitalDoctorApiResponse.data.syncHospitalDoctors.items[0];

				const newSecretaryHospitalDoctorApiResponse = await execute(
					createSecretaryHospitalDoctor,
					{
						input: {
							hospitalDoctorID: hospitalDoctor.id,
							secretaryID: newSecretary.id,
							hasClaimsPermission: secretary.hasClaimsPermission,
							hasFilesPermission: secretary.hasFilesPermission,
							hasHistoryPermission: secretary.hasHistoryPermission,
							hasNotesPermission: secretary.hasNotesPermission,
							hasPrescriptionsPermission: secretary.hasPrescriptionsPermission,
							hasSurgeryPermission: secretary.hasSurgeryPermission,
							status: Status.ACTIVE,
						},
					}
				);

				const newSecretaryHospitalDoctor =
					newSecretaryHospitalDoctorApiResponse.data.createSecretaryHospitalDoctor;

				const customPassword = generatePassword();
				await Auth.signUp({
					username: secretary.email,
					password: customPassword,
					attributes: {
						email: secretary.email,
						[SECRETARY_IDENTIFIER]: newSecretary.id,
						'custom:tempPassword': customPassword,
						'custom:name': secretary.email,
					},
				});

				const newSecretaryReturn: SecretaryType = {
					...secretary,
					...newSecretaryHospitalDoctor,
					id: newSecretary.id,
				};

				return newSecretaryReturn;
			} else {
				const currentSecretaryHospitalDoctorResponse = await execute(
					syncSecretaryHospitalDoctors,
					{
						filter: {
							secretaryID: {
								eq: secretaryExist.id,
							},
						},
					}
				);

				const secretaryHospitalDoctor =
					currentSecretaryHospitalDoctorResponse.data.syncSecretaryHospitalDoctors
						.items[0];
				if (!secretaryHospitalDoctor) {
					setIsError(true);
					return null;
				}
				await execute(updateSecretaryHospitalDoctor, {
					input: {
						id: secretaryHospitalDoctor.id,
						hasClaimsPermission: secretary.hasClaimsPermission,
						hasFilesPermission: secretary.hasFilesPermission,
						hasHistoryPermission: secretary.hasHistoryPermission,
						hasNotesPermission: secretary.hasNotesPermission,
						hasPrescriptionsPermission: secretary.hasPrescriptionsPermission,
						hasSurgeryPermission: secretary.hasSurgeryPermission,
						status: Status.ACTIVE,
						_version: secretaryHospitalDoctor._version,
					},
				});
			}

			return secretary;
		} catch (error) {
			setIsError(true);
			return null;
		}
	};

	const editSecretary = async (secretary: SecretaryType): Promise<SecretaryType | null> => {
		try {
			const secretaryHospitalDoctorApiResponse = await execute(syncSecretaryHospitalDoctors, {
				filter: {
					secretaryID: {
						eq: secretary.secretaryID,
					},
				},
			});

			const secretaryHospitalDoctor =
				secretaryHospitalDoctorApiResponse.data.syncSecretaryHospitalDoctors.items[0];

			const editedSecretaryApiResponse = await execute(updateSecretaryHospitalDoctor, {
				input: {
					id: secretaryHospitalDoctor.id,
					hospitalDoctorID: secretaryHospitalDoctor.hospitalDoctor.id,
					hasClaimsPermission: secretary.hasClaimsPermission,
					hasFilesPermission: secretary.hasFilesPermission,
					hasHistoryPermission: secretary.hasHistoryPermission,
					hasNotesPermission: secretary.hasNotesPermission,
					hasPrescriptionsPermission: secretary.hasPrescriptionsPermission,
					hasSurgeryPermission: secretary.hasSurgeryPermission,
					_version: secretaryHospitalDoctor._version,
				},
			});

			const editedSecretary = editedSecretaryApiResponse.data.updateSecretaryHospitalDoctor;

			return {
				...secretary,
				...editedSecretary,
			};
		} catch (error) {
			setIsError(true);
			return null;
		}
	};

	const completeSecretaryRegistration = async (fullName: string, password: string) => {
		try {
			const user = await Auth.currentAuthenticatedUser();
			await Auth.changePassword(
				user,
				user.attributes[SECRETARY_CUSTOM_PASSWORD_IDENTIFIER],
				password
			);

			const secretaryApiResponse = await execute(syncSecretaries, {
				filter: {
					id: {
						eq: user.attributes[SECRETARY_IDENTIFIER],
					},
				},
			});

			const secretary = secretaryApiResponse.data.syncSecretaries.items[0];

			await execute(updateSecretary, {
				input: {
					id: secretary.id,
					name: fullName,
					firstTime: false,
					_version: secretary._version,
				},
			});

			handleSecretaryLogin(user.attributes, user);
		} catch (error) {
			setIsError(true);
		}
	};

	const getSecretaryByEmail = async (email: string) => {
		try {
			const secretaryResponse = await execute(syncSecretaries, {
				filter: {
					email: {
						eq: email,
					},
				},
			});
			return secretaryResponse.data.syncSecretaries.items[0];
		} catch (error) {
			setIsError(true);
			return null;
		}
	};

	const completeDoctorRegistration = async (doctorInfo: DoctorType) => {
		try {
			const user = await Auth.currentAuthenticatedUser();

			const doctorResponse = await execute(syncDoctors, {
				filter: {
					id: {
						eq: user.attributes[DOCTOR_IDENTIFIER],
					},
				},
			});

			const doctor = doctorResponse.data.syncDoctors.items[0];

			await execute(updateDoctor, {
				input: {
					id: doctor.id,
					specialty: doctorInfo.specialty,
					firstTime: false,
					_version: doctor._version,
				},
			});
			let hospitalDoctor: HospitalDoctor | null = null;
			if (doctorInfo.hospitalID) {
				const hospitalDoctorResponse = await execute(createHospitalDoctor, {
					input: {
						hospitalID: doctorInfo.hospitalID,
						doctorID: doctor.id,
						lastWaitingListID: '',
					},
				});
				hospitalDoctor = hospitalDoctorResponse.data.createHospitalDoctor;
			}

			const doctorStripeSubscriptionApiResponse = await execute(
				listDoctorStripeCustomerSubscriptions,
				{
					filter: {
						doctorID: {
							eq: user.attributes[DOCTOR_IDENTIFIER],
						},
					},
				}
			);

			const doctorStripeSubscription =
				doctorStripeSubscriptionApiResponse.data.listDoctorStripeCustomerSubscriptions
					.items[0];

			if (doctor.hasTrialOnCreation) {
				const monthly_plan = plans.find((p) => p.id === MONTHLY_PLAN_ID);
				// @ts-ignore
				const referral = window?.tolt_referral;
				const subscriptionData = {
					customer: doctorStripeSubscription.stripeCustomerID,
					items: [{ price: monthly_plan?.stripePlanKey }],
					trial_period_days: doctor.daysOfTrial,
				};

				if (referral) {
					// @ts-ignore
					subscriptionData['metadata'] = {
						tolt_referral: referral,
					};
				}

				const subscription = await createSubscription(subscriptionData);

				await execute(updateDoctorStripeCustomerSubscription, {
					input: {
						id: doctorStripeSubscription.id,
						stripeSubscriptionID: subscription.id,
						trialsEndsAt: getFormatNextPaymentDate(subscription.trial_end),
					},
				});
			}
			return [hospitalDoctor, user];
		} catch (error) {
			setIsError(true);
		}
	};

	const completeDoctorMemberRegistration = async (
		doctorInfo: MemberState
	): Promise<{
		user: any;
		status: ResponseStatusType;
	}> => {
		try {
			const currentSession = await Auth.currentAuthenticatedUser();

			await Auth.changePassword(
				currentSession,
				currentSession.attributes[CUSTOM_PASSWORD_IDENTIFIER],
				doctorInfo.password
			);

			const userResponse = await execute(syncHospitalDoctors, {
				filter: {
					doctorID: {
						eq: currentSession.attributes[DOCTOR_IDENTIFIER],
					},
				},
			});

			const hospitalDoctor = userResponse.data.syncHospitalDoctors.items[0];
			const doctor = hospitalDoctor.doctor;

			await execute(updateDoctor, {
				input: {
					id: doctor.id,
					specialty: doctorInfo.specialty,
					name: doctorInfo.doctorName,
					phone: doctorInfo.doctorPhone,
					firstTime: false,
					_version: doctor._version,
				},
			});

			const teamMemberResponse = await execute(syncTeams, {
				filter: {
					MemberID: {
						eq: hospitalDoctor!.id,
					},
				},
			});

			const teamMember = teamMemberResponse.data.syncTeams.items[0];

			await execute(updateTeams, {
				input: {
					id: teamMember.id,
					status: TeamStatus.ACTIVE,
					_version: teamMember._version,
				},
			});

			return {
				user: currentSession,
				status: ResponseStatusType.SUCCESS,
			};
		} catch (error) {
			setAlertMessage({
				message: 'Ha ocurrido un error, intenta de nuevo',
				severity: 'error',
			});
			return {
				user: null,
				status: ResponseStatusType.ERROR,
			};
		}
	};

	return (
		<UserContext.Provider
			value={{
				fetchPlans,
				plans,
				userAuthenticated,
				logIn,
				signIn,
				logOut,
				updateDateForPayment,
				forgotPassword,
				changePassword,
				confirmSignUp,
				resendConfirmationCode,
				upsertSecretary,
				completeSecretaryRegistration,
				completeDoctorRegistration,
				editSecretary,
				checkIsLogged,
				getSecretaryByEmail,
				completeDoctorMemberRegistration,
				userRole,
				getDoctorMembersByDropDown,
				doctorMembers,
				handleDoctorLogin,
				doctorMemberSelectedHandler,
				doctorMemberSelected,
			}}
		>
			{props.children}
		</UserContext.Provider>
	);
};

export default UserProvider;
