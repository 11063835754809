import React, { useContext, useEffect } from 'react';
import { RelationsContext } from '../../context/relations-context';
import { RelationsContextProps } from '../../context/types';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { StyledSnackbar as Snackbar } from 'shared/style';
import useMobile from 'hooks/useMobile';

// The standar behabior of this component is controlled from the RelationsContext context

const BottomAlert = () => {
	const { alertMessage, setAlertMessage } = useContext(RelationsContext) as RelationsContextProps;
	const { isMobile } = useMobile();
	useEffect(() => {
		if (navigator.onLine && alertMessage?.message) {
			const alertTimer = setTimeout(function () {
				setAlertMessage({ message: null, severity: 'success' });
			}, 4000);

			return () => clearTimeout(alertTimer);
		}
	}, [alertMessage, setAlertMessage]);

	return alertMessage?.message ? (
		<>
			<Snackbar
				open={Boolean(alertMessage.message)}
				autoHideDuration={3000}
				style={{
					bottom: isMobile ? '80px' : '15px',
				}}
			>
				<Alert
					action={
						navigator.onLine && (
							<IconButton
								aria-label="close"
								color="inherit"
								size="small"
								onClick={() => {
									setAlertMessage({ message: null, severity: 'success' });
								}}
							>
								<CloseIcon fontSize="inherit" />
							</IconButton>
						)
					}
					variant="filled"
					severity={alertMessage.severity}
				>
					{alertMessage.message}
				</Alert>
			</Snackbar>
		</>
	) : null;
};

export default BottomAlert;
