// @ts-nocheck
import React, {
	useContext,
	useRef,
	ChangeEvent,
	useEffect,
	useState,
	useCallback,
	ReactNode,
} from 'react';
import { useRouteMatch, useParams } from 'react-router';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ClientsContext } from '../context/client-context';
import { RelationsContext } from '../context/relations-context';
import { LoaderContext } from '../context/loader-context';
import {
	AIContextProps,
	ClientContextProps,
	ClientImageType,
	HistorySidebarOptions,
	LoaderContextProps,
	PrescriptionType,
	RelationsContextProps,
} from '../context/types';
import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CustomTabs from '../components/customTabs/CustomTabs';
import ClientHeader from '../components/clients/shared/ClientHeader';
import { SpeedDial, SpeedDialIcon } from '@material-ui/lab';
import Compressor from 'compressorjs';
import { ClientImage } from 'models';
import Storage from '@aws-amplify/storage';
import { OnUpdateClientSubscription } from 'API';
import { onUpdateClient } from 'graphql/subscriptions';
import { API, graphqlOperation, GraphQLSubscription } from '@aws-amplify/api';

// Icons
import LocalPharmacyIcon from '@material-ui/icons/LocalPharmacy';

import { StyledBackdrop, StyledSpeedDialAction, StyledCancelButton } from '../shared/style';
import { FormatDate, getDateAndTime, getDateToString } from '../shared/dateUtils';
import { IAppointment, SummaryTab } from '../shared/type';
import { HistoryContext, HistoryContextProps } from '../context/history-context';
import { formatFile } from 'shared/utilFunctions';
import { AiContext } from 'context/ai-context';
import PatientSummary from '../components/clients/clientSummary/PatientSummary';
import PatientHistory from '../components/clients/history/PatientHistory';
import PatientClaim from '../components/clients/claim/PatientClaim';
import PatientConsultation from '../components/clients/consultations/Consultations';
import AIDiagnostic from '../components/clients/aiDiagnostic/AIDiagnostic';
import Prescriptions from '../components/clients/prescriptions/Prescriptions';
import CreateUpdatePrescription from '../components/clients/prescriptions/CreateUpdatePrescription';
import Files from '../components/clients/files/Files';
import { CalendarIconAdd } from 'components/svg/CalendarIconAdd';
import { ModalAppointment } from 'components/appointment/components/ModalAppointment';
import moment from 'moment';
import useMobile from 'hooks/useMobile';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		exampleWrapper: {
			position: 'relative',
			right: '75px',
			top: '58px',
		},
		speedDial: {
			position: 'absolute',
			'&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
				bottom: theme.spacing(2),
				right: theme.spacing(2),
			},
			'&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
				left: theme.spacing(2),
			},
		},
	})
);

const PatientDetail: React.FC = () => {
	const history = useHistory();
	const location = useLocation();
	const classes = useStyles();
	const { url, path } = useRouteMatch();
	const { setCurrentSidebarTab } = useContext(HistoryContext) as HistoryContextProps;
	const clientsContext = useContext(ClientsContext) as ClientContextProps;
	const { showSpinner } = useContext(LoaderContext) as LoaderContextProps;
	const relationsContext = useContext(RelationsContext) as RelationsContextProps;
	const { clearIASuggestions, setAiSection } = useContext(AiContext) as AIContextProps;
	const {
		addImage,
		getMainPageClientData,
		getClientData,
		prescriptionToAdd,
		setPrescriptionToAdd,
		createClientPrescription,
		setSelectedClient,
		updateImage,
		deleteImage,
		selectedClient,
		setImageLoading,
		fetchPaginatedClients,
		page,
		resetPatientData,
	} = clientsContext;
	const client = selectedClient;
	const [status, setStatus] = useState<string>('');
	const { clientID } = useParams<{ clientID: string }>();
	const [actualTab, setActualtab] = useState<SummaryTab>(SummaryTab.summary);
	const [openAddBtn, setOpenAddBtn] = useState(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const inputPicture = useRef<HTMLInputElement>(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [appointment, setAppointment] = useState<IAppointment>({
		client: null,
		description: '',
		date: moment(),
		start: null,
		end: null,
	});
	const { isMobile } = useMobile();

	const clientDetailButtons: any[] = [
		{
			id: SummaryTab.summary,
			label: 'Resumen',
			body: <div></div>,
			path: `${url}/summary`,
		},
	];

	const handleClose = () => {
		setOpenAddBtn(false);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const onClickNewAppointment = () => {
		setIsModalOpen(true);
		setAppointment({
			description: '',
			client: {
				name: selectedClient!.name + ' ' + selectedClient!.lastName,
				id: selectedClient!.id,
				email: selectedClient!.email!,
				_version: (selectedClient as any)._version!,
			},
			date: moment(),
			start: null,
			end: null,
		});
	};

	const handleOpen = () => {
		setOpenAddBtn(true);
	};

	const handleImageUpload = () => {
		inputPicture?.current?.click();
	};

	const actions = [
		{
			icon: <LocalPharmacyIcon />,
			name: 'Prescripción',
			onClick: () => {
				const prescriptionBtn = clientDetailButtons.find(
					(b) => b.id === SummaryTab.prescription
				);
				history.push(`${url}/prescription/form`);
				setActualtab(prescriptionBtn.id - 1);
				handleClose();
			},
		},
		{
			icon: <CloudUploadIcon />,
			name: 'Subir imagen',
			onClick: handleImageUpload,
		},
	];

	const handleChange = (_event: React.ChangeEvent<Record<string, unknown>>, newValue: number) => {
		setActualtab(newValue);
		if (newValue === SummaryTab.history) {
			setCurrentSidebarTab(HistorySidebarOptions.RESUMEN);
		}
	};

	const getInfoClient = async () => {
		showSpinner(true);
		const clientInfo = await getMainPageClientData(clientID);
		setSelectedClient(clientInfo);
		showSpinner(false);
	};

	useEffect(() => {
		showSpinner(true);
		setIsLoading(true);
		if (clientID && clientID !== selectedClient?.id) {
			console.log('Getting client data');
			resetPatientData();
			getInfoClient();
		} else {
			showSpinner(false);
		}

		setIsLoading(false);
	}, [clientID]);

	useEffect(() => {
		let syncTimeOut: NodeJS.Timeout;

		const subscribeToClients = async () => {
			const customFilter =
				await relationsContext.getCustomHospitalDoctorFiltersWithOutCurrentUser();
			const updateSub = API.graphql<GraphQLSubscription<OnUpdateClientSubscription>>(
				graphqlOperation(onUpdateClient, {
					or: customFilter,
				})
			).subscribe({
				next: async (data: any) => {
					if (syncTimeOut) clearTimeout(syncTimeOut);
					const updatedClient = data.value.data.onUpdateClient;

					if (clientID === updatedClient.id) {
						syncTimeOut = setTimeout(async () => {
							await getMainPageClientData(clientID);
							const max = page;
							let iterations = 0;
							while (iterations <= max) {
								await fetchPaginatedClients(iterations, 30);
								iterations++;
							}
						}, 7000);
						return;
					}
				},
			});

			return { updateSub };
		};

		let subscriptions: { updateSub: any };

		const initializeSubscriptions = async () => {
			subscriptions = await subscribeToClients();
		};

		initializeSubscriptions();

		return () => {
			if (syncTimeOut) clearTimeout(syncTimeOut);
			subscriptions?.updateSub?.unsubscribe();
		};
	}, [clientID]);

	const uploadImageHandler = async (
		e: ChangeEvent<HTMLInputElement>,
		image: ClientImage,
		imageSectionID: string
	) => {
		const file = e.target.files![0];
		const handleUpdateImage = async (file: File) => {
			if (!file) {
				relationsContext.setAlertMessage({
					message: 'No se pudo cargar el archivo',
					severity: 'error',
				});
				return;
			}
			const extension = file.type?.split('/')[1];
			const fileName = `${file.name}-${getDateAndTime()}.${extension[extension.length - 1]}`;
			const { key } = await Storage.put(fileName, file);
			await updateImage(image.id, file.name, key, imageSectionID);
		};
		try {
			if ((file && file['type'].split('/')[0]) === 'image') {
				new Compressor(file, {
					quality: 0.8,
					success: async (file: File) => handleUpdateImage(file),
				});
			} else {
				handleUpdateImage(file);
			}
		} catch (error) {
			await deleteImage(image as ClientImageType);
		}
	};

	const onChangeInputFileHandler = async (e: ChangeEvent<HTMLInputElement>) => {
		setImageLoading(true);
		const file = e.target.files![0];
		const date = getDateToString(new Date(), FormatDate['Monthname DD, YYYY']);
		const { name: fileName } = formatFile(file);
		const { key } = await Storage.put(fileName, file);
		const { image, imageSectionID } = await addImage(date, file.name, key);
		uploadImageHandler(e, image, imageSectionID);
		if (inputPicture && inputPicture.current) {
			inputPicture.current.value = '';
		}
	};

	useEffect(() => {
		setIsLoading(true);
		if (client && !status) {
			setStatus(client.status);
		}
		setIsLoading(false);
	}, [client]);

	if (!relationsContext.actualSecretary) {
		clientDetailButtons.push({
			id: SummaryTab.consultation,
			label: 'Consultas',
			body: <div></div>,
			path: `${url}/consultation`,
		});
		clientDetailButtons.push({
			id: SummaryTab.prescription,
			label: 'Prescripciones',
			body: <div></div>,
			path: `${url}/prescription`,
		});
		if (!isMobile) {
			clientDetailButtons.push({
				id: SummaryTab.ai_diagnostic,
				label: 'Diagnóstico IA',
				body: <div></div>,
				path: `${url}/ai_diagnostic`,
			});
		}
		clientDetailButtons.push({
			id: SummaryTab.history,
			label: 'Historial',
			body: <div></div>,
			path: `${url}/history`,
		});
		clientsContext.selectedClient?.actualInsurance !== 'Sin seguro' &&
			clientsContext.selectedClient?.actualInsurance !== '' &&
			clientDetailButtons.push({
				id: SummaryTab.claim,
				label: 'Reclamaciones',
				body: <div></div>,
				path: `${url}/claim`,
			});
		// We are commenting this because it will be implemented in the future and Francis request to commented it.
		clientDetailButtons.push({
			id: SummaryTab.files,
			label: 'Archivos',
			body: <div></div>,
			path: `${url}/files`,
		});
	}

	if (relationsContext.actualSecretary) {
		relationsContext.actualSecretary.hasPrescriptionsPermission &&
			clientDetailButtons.push({
				id: SummaryTab.prescription,
				label: 'Prescripciones',
				body: <div></div>,
				path: `${url}/prescription`,
			});

		clientsContext.selectedClient?.actualInsurance !== 'Sin seguro' &&
			clientsContext.selectedClient?.actualInsurance !== '' &&
			relationsContext.actualSecretary.hasClaimsPermission &&
			clientDetailButtons.push({
				id: SummaryTab.claim,
				label: 'Reclamaciones',
				body: <div></div>,
				path: `${url}/claim`,
			});

		relationsContext.actualSecretary.hasFilesPermission &&
			clientDetailButtons.push({
				id: SummaryTab.files,
				label: 'Archivos',
				body: <div></div>,
				path: `${url}/files`,
			});
	}

	let saveAction;
	if (location.pathname.includes('procedures')) {
		saveAction = () => {
			history.push(`/Clients/${clientID}/procedures`);
		};
	}
	if (location.pathname.includes('prescription')) {
		saveAction = async () => {
			if (prescriptionToAdd !== null) {
				const prescriptionList = prescriptionToAdd as PrescriptionType[];
				prescriptionList.map(async (p) => {
					await createClientPrescription(p);
				});
			}
			history.push(`/Clients/${clientID}/prescription`);
			const clientInfo = await getClientData(clientID);
			setSelectedClient(clientInfo);
			setPrescriptionToAdd(null);
		};
	}
	if (location.pathname.includes('claim')) {
		saveAction = () => {
			history.push(`/Clients/${clientID}/claim`);
		};
	}

	let cancelRoute = '';
	if (location.pathname.includes('procedures')) {
		cancelRoute = `/Clients/${clientID}/procedures`;
	}
	if (location.pathname.includes('prescription')) {
		cancelRoute = `/Clients/${clientID}/prescription`;
	}
	if (location.pathname.includes('claim')) {
		cancelRoute = `/Clients/${clientID}/claim`;
	}

	if (location.pathname.includes('ai_diagnostic')) {
		cancelRoute = `/Clients/${clientID}/ai_diagnostic`;
	}

	useEffect(() => {
		if (location.pathname.includes('/summary')) {
			setActualtab(SummaryTab.summary);
		}
		if (location.pathname.includes('/consultation')) {
			setActualtab(SummaryTab.consultation);
		}
		if (location.pathname.includes('/prescription')) {
			setActualtab(SummaryTab.prescription);
		}
		if (location.pathname.includes('/ai_diagnostic')) {
			setActualtab(SummaryTab.ai_diagnostic);
		}
		if (location.pathname.includes('/history')) {
			setActualtab(SummaryTab.history);
		}
		if (location.pathname.includes('/procedures')) {
			setActualtab(SummaryTab.procedure);
		}
		if (location.pathname.includes('/claim')) {
			setActualtab(SummaryTab.claim);
		}
		// We are commenting this because it will be implemented in the future and Francis request to commented it.
		if (location.pathname.includes('/files')) {
			setActualtab(SummaryTab.files);
		}
	}, [location]);

	const buttonPrinter = (buttons: ReactNode) => {
		if (isMobile) {
			return <div className="patient-detail-buttons-mobile"> {buttons} </div>;
		} else {
			return buttons;
		}
	};

	const actionButtons = useCallback(() => {
		return (
			<>
				<Button
					color="primary"
					variant="outlined"
					size="small"
					onClick={onClickNewAppointment}
					startIcon={<CalendarIconAdd />}
				>
					Agendar cita
				</Button>
				<Button
					variant="contained"
					color="primary"
					size="small"
					onClick={() => {
						setAiSection('consultation');
						clearIASuggestions();
						history.push(`/Clients/${clientID}/client-consultation`);
					}}
					startIcon={<AddIcon fontSize="large" />}
				>
					Iniciar Consulta
				</Button>
			</>
		);
	}, [clientID]);

	// define actions buttons
	const actionsButtons = (
		<>
			<div className="invisible absolute top-0">
				<input
					type="file"
					ref={inputPicture}
					onChange={onChangeInputFileHandler}
					accept="image/jpeg,image/jpg,image/png,.pdf,.doc,.docx"
				/>
			</div>
			{!location.pathname.includes('/form') ? (
				!relationsContext.actualSecretary && buttonPrinter(actionButtons())
			) : (
				<>
					<StyledCancelButton
						variant="outlined"
						color="primary"
						size="small"
						onClick={() => history.push(cancelRoute)}
					>
						Cancelar
					</StyledCancelButton>
					<Button variant="contained" color="primary" size="small" onClick={saveAction}>
						Guardar
					</Button>
				</>
			)}
			{
				<div className={classes.exampleWrapper}>
					{openAddBtn && <StyledBackdrop open onClick={handleClose} />}
					{!isMobile &&
						!location.pathname.includes('/form') &&
						!location.pathname.includes('/procedures') &&
						!location.pathname.includes('claim') && (
							<SpeedDial
								ariaLabel="SpeedDial example"
								className={classes.speedDial}
								FabProps={{ color: 'inherit', size: 'small' }}
								icon={<SpeedDialIcon />}
								style={{ top: relationsContext.actualSecretary ? -24 : 8 }}
								onClick={handleOpen}
								open={openAddBtn}
								direction={'down'}
							>
								{actions.map((action) => (
									<StyledSpeedDialAction
										key={action.name}
										icon={action.icon}
										onBlur={handleClose}
										FabProps={{ color: 'inherit', size: 'small' }}
										tooltipTitle={action.name}
										tooltipOpen
										onClick={(e) => {
											e.stopPropagation();
											action.onClick();
										}}
									/>
								))}
							</SpeedDial>
						)}
				</div>
			}
		</>
	);

	return (
		<div
			className="w-full"
			data-fx-name="patientNav"
			style={isMobile ? { touchAction: 'none', overflow: 'hidden' } : { overflowY: 'scroll' }}
		>
			<CustomTabs
				isLink={true}
				customHeader={<ClientHeader actionsButtons={actionsButtons} />}
				isLoading={isLoading}
				tabList={clientDetailButtons}
				actualTab={actualTab}
				handleChange={handleChange}
			/>
			<Switch>
				<Route path={path + '/summary'} component={PatientSummary} />
				<Route path={path + '/consultation'} component={PatientConsultation} />
				<Route path={path + '/history'} component={PatientHistory} />

				<Route path={path + '/procedures/form'}>
					<div>CreateUpdateProcedures</div>
				</Route>
				<Route path={path + '/claim/form'}>
					<div>CreateUpdateClaims</div>
				</Route>
				<Route path={path + '/claim'} component={PatientClaim} />
				<Route path={path + '/prescription/form'} component={CreateUpdatePrescription} />
				<Route path={path + '/prescription'} component={Prescriptions} />
				<Route path={path + '/ai_diagnostic'} component={AIDiagnostic} />
				<Route path={path + '/files/form'}>
					<div>CreateUpdateFiles</div>
				</Route>
				<Route
					path={path + '/files'}
					render={() => <Files handleImageUpload={handleImageUpload} />}
				/>
				<Route path={path + '/'}>{client && <Redirect to={url + '/summary'} />}</Route>
			</Switch>

			<ModalAppointment
				modalState={isModalOpen}
				disabledUserInput
				closeModal={closeModal}
				isUpdating={false}
				selectedClient={selectedClient}
				initialValues={appointment}
			/>
		</div>
	);
};

export default PatientDetail;
