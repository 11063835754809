import { Button } from '@material-ui/core';
import styled from 'styled-components';
import { BREAKPOINTS } from 'shared/style';

export const Title = styled.span`
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 700;
	font-size: 19px;
	line-height: 27px;

	color: #000000;
	margin-bottom: 3px;

	@media (max-width: 600px) {
		font-size: 14px;
		margin-bottom: 1px;
	}
`;

export const SubTitle = styled.h2`
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 150%;

	color: #000000;

	@media (max-width: 600px) {
		font-size: 14px;
	}
`;

export const NoItemButton = styled(Button)`
	background: #9c27b0 !important;
	box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14),
		0px 1px 5px rgba(0, 0, 0, 0.12);
	border-radius: 4px;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 22px;
	text-align: right;
	letter-spacing: 0.46px;
	text-transform: uppercase;
	color: #ffffff !important;
	flex: none;
	order: 1;
	flex-grow: 0;
	margin-top: 20.5px;
	padding-right: 10px;
	padding-top: 4px;
	padding-bottom: 4px;
	@media (max-width: ${BREAKPOINTS.md}px) {
		margin-left: auto;
		margin-right: auto;
	}
`;
