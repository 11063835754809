import { FC } from 'react';

const NoClaimsAllowed: FC<{
	healthInsuranceName?: string | undefined;
}> = ({ healthInsuranceName }) => {
	return (
		<div
			className="flex flex-col justify-center items-center justify-items-center w-full px-4 md:px-0"
			style={{
				height: '80%',
			}}
		>
			<h1 className="font-bold font-['Raleway'] text-[19px] not-italic leading-[140.9%] text-center text-balance">
				En proceso de conexión con las ARS
			</h1>
			<p className="md:w-[430px] text-[16px] not-italic font-normal font-['Open Sans'] leading-[150%] text-center">
				Estamos trabajando para que puedas crear reclamaciones para todos los seguros.
			</p>
		</div>
	);
};

export default NoClaimsAllowed;
