import { FC } from 'react';
import { Container } from 'pages/PatientConsultation/Previous/style';
import Skeleton from '@material-ui/lab/Skeleton';

const ConsultationsSkeleton: FC<{
	height?: string;
}> = ({ height }) => {
	return (
		<Container
			item
			xs={12}
			style={{
				height: `calc(100dvh - ${height ? height : '167px'})`,
				// padding: isMobile ? "20px" : "14px 216px",
				gap: '28px',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Skeleton
				variant="rect"
				width="100%"
				height={415}
				style={{
					borderRadius: '4px',
				}}
			/>
			<Skeleton
				variant="rect"
				width="100%"
				height={415}
				style={{
					borderRadius: '4px',
				}}
			/>
		</Container>
	);
};

export default ConsultationsSkeleton;
