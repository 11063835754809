import * as React from 'react';
import {
	StyledBottomNavigationMUI as BottomNavigationMUI,
	StyledBottomNavigationAction as BottomNavigationAction,
} from './style';
import PersonIcon from '@material-ui/icons/Person';
import FormatListNumberedRtlIcon from '@material-ui/icons/FormatListNumberedRtl';
import DateRangeIcon from '@material-ui/icons/DateRange';
import MenuIcon from '@material-ui/icons/Menu';
import { ISideBar } from 'shared/type';
import { LoaderContext } from 'context/loader-context';
import { MobileNavigationOptions } from 'context/types';
import { useHistory, useLocation } from 'react-router-dom';
export const BottomNavigation: React.FC<ISideBar> = ({ sidebarToggle, setsidebarToggle }) => {
	const [value, setValue] = React.useState(0);
	const { pathname } = useLocation();
	const history = useHistory();
	const { setCurrentViewMobile } = React.useContext(LoaderContext);

	React.useEffect(() => {
		if (
			[
				'/Medicine',
				'/Lab-Image',
				'/Configuration/Profile',
				'/Configuration/Institution',
				'/configuration/Subscription',
			].includes(pathname)
		) {
			setValue(3);
		}
	}, [pathname]);
	return (
		<BottomNavigationMUI
			id="bottom-navigation"
			showLabels
			value={value}
			onChange={(_, newValue) => {
				if ([0, 1, 2].includes(newValue) && pathname !== '/') {
					history.push('/');
				}

				if (newValue === 0) setCurrentViewMobile?.(MobileNavigationOptions.PATIENTS);
				if (newValue === 1) setCurrentViewMobile?.(MobileNavigationOptions.WAITLIST);
				if (newValue === 2) setCurrentViewMobile?.(MobileNavigationOptions.CALENDAR);

				if (newValue !== 3) setValue(newValue);
			}}
			className="fixed bottom-0 w-full"
		>
			<BottomNavigationAction label="Pacientes" icon={<PersonIcon />} />
			<BottomNavigationAction label="Lista de espera" icon={<FormatListNumberedRtlIcon />} />
			<BottomNavigationAction label="Calendario" icon={<DateRangeIcon />} />
			<BottomNavigationAction
				label="Menu"
				id="bottom-navigation-menu-btn"
				onClick={() => {
					setsidebarToggle(!sidebarToggle);
				}}
				icon={<MenuIcon />}
			/>
		</BottomNavigationMUI>
	);
};

export default BottomNavigation;
