import { useEffect, useState } from 'react';

const getOnLineStatus = () =>
	typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
		? navigator.onLine
		: true;

const useOnlineHook = () => {
	const [onlineStatus, setOnlineStatus] = useState<boolean>(getOnLineStatus());

	const setOnline = () => setOnlineStatus(true);
	const setOffline = () => setOnlineStatus(false);

	useEffect(() => {
		window.addEventListener('online', setOnline);
		window.addEventListener('offline', setOffline);

		return () => {
			window.removeEventListener('online', setOnline);
			window.removeEventListener('offline', setOffline);
		};
	}, []);

	return onlineStatus;
};

export default useOnlineHook;
