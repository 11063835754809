// @ts-nocheck
import './style.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './index.css';

import { render } from 'react-dom';
import { App } from './App';
import 'regenerator-runtime/runtime';
import { DataStore } from '@aws-amplify/datastore';
import { datadogRum } from '@datadog/browser-rum';

const enviroment = DataStore.amplifyConfig['aws_user_files_s3_bucket'];
let actual_env = 'development';
if (enviroment.includes('staging')) {
	actual_env = 'staging';
}
if (enviroment.includes('produccion')) {
	actual_env = 'production';
}

datadogRum.init({
	applicationId: `${process.env.REACT_APP_DG_APP_ID}`,
	clientToken: `${process.env.REACT_APP_DG_CLIENT_TOKEN}`,
	site: 'datadoghq.com',
	service: 'pulseara-desktop',
	env: actual_env,
	trackUserInteractions: true,
	trackResources: true,
	trackLongTasks: true,
	defaultPrivacyLevel: 'mask-user-input',
	allowFallbackToLocalStorage: true,
});

datadogRum.startSessionReplayRecording();

render(<App />, document.getElementById('root'));
