import React, { FC, useContext, useState, useEffect } from 'react';
import clsx from 'clsx';
import { withStyles, useTheme, styled } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import SettingsIcon from '@material-ui/icons/Settings';
import LocalPharmacyIcon from '@material-ui/icons/LocalPharmacy';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Box, Button } from '@material-ui/core';
import { Link as ReactLink, useLocation, useHistory } from 'react-router-dom';
import BigLogo from '../svg/BigLogo';
import SmallLogo from '../svg/SmallLogo';
import { UserContext } from '../../context/user-context';
import { ClientsContext } from '../../context/client-context';
import CustomDialog from '../dialog/CustomDialog';
import { pathNameList } from '../../shared/constants';
import { useStyles } from './styles';
import Biotech from '../svg/Biotech';
import { ClientContextProps, RelationsContextProps, UserContextProps } from '../../context/types';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ModalClient from '../modalClient/ModalPatient';
import { RelationsContext } from 'context/relations-context';
import { CalendarIcon } from 'components/svg/CalendarIcon';
import CalendarSideIcon from '@material-ui/icons/InsertInvitation';
import useMobile from 'hooks/useMobile';
import { ISideBar } from 'shared/type';
import './sidebar.css';
import GlobalSearchPatientBar from './GlobalSearchPatientBar';
import { drawerWidth } from '../../shared/constants';
import HelpButton from './HelpButton';
import MemberDropDown from './memberDropdown/MemberDropDown';

const StyledButton = styled(Button)({
	borderRadius: '4px',
	background: 'var(--Grey-300, #E0E0E0)',
	boxShadow:
		'0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)',
	color: 'var(--Light-Text-Primary, rgba(0, 0, 0, 0.87))',
	fontFamily: 'Roboto',
	fontSize: '13px',
	fontStyle: 'normal',
	fontWeight: 'bold',
	lineHeight: '22px',
	letterSpacing: '0.46px',
	textTransform: 'uppercase',
	border: 'none',
	'&:hover': {
		border: 'none',
	},
});

const MainSideBar: FC<ISideBar> = ({ setsidebarToggle, sidebarToggle }) => {
	const classes = useStyles();
	const theme = useTheme();
	const location = useLocation<{ prevPath: '' }>();
	const history = useHistory<{ prevPath: '' }>();
	const { actualSecretary } = useContext(RelationsContext) as RelationsContextProps;
	const { logOut } = useContext(UserContext) as UserContextProps;
	const { setIsCalendarSideViewOpen, isCalendarSideViewOpen } = useContext(RelationsContext);

	const { modalClientState, setModalClientState, editingClient, setEditingClient } = useContext(
		ClientsContext
	) as ClientContextProps;

	const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [prevLocation, setPrevLocation] = useState<unknown>('/');
	const { isMobile } = useMobile();
	const {
		location: { state },
	} = history;

	const sideBarRoutes = [
		{ id: 1, label: 'Inicio', path: pathNameList.home, icon: <HomeIcon /> },
		{
			id: 2,
			label: 'Calendario',
			path: pathNameList.calendar,
			icon: <CalendarSideIcon />,
		},
		{
			id: 3,
			label: 'Medicamentos',
			path: pathNameList.medicines,
			icon: <LocalPharmacyIcon />,
		},
		{
			id: 4,
			label: 'Lab/Imágenes',
			path: pathNameList.labImages,
			icon: <Biotech height="27px" viewBox="5 0 28 28" />,
		},
	];

	if (isMobile) {
		sideBarRoutes.splice(1, 1);
	}

	if (!actualSecretary) {
		sideBarRoutes.push({
			id: 5,
			label: 'Configuración',
			path: pathNameList.configuration,
			icon: <SettingsIcon />,
		});
	}

	const configRoutes = [
		{
			id: 1,
			label: 'Perfil',
			path: pathNameList.profile,
		},
		{
			id: 2,
			label: 'Institución',
			path: pathNameList.institution,
		},
		{
			id: 3,
			label: 'Suscripción',
			path: pathNameList.subscription,
		},
	];

	const StyledAppBar = withStyles({
		root: {
			width: sidebarToggle
				? `calc(100% - ${drawerWidth}px)`
				: `calc(100% - ${theme.spacing(6) + 1}px)`,
			zIndex: theme.zIndex.drawer + 1,
			transition: sidebarToggle
				? theme.transitions.create(['width', 'margin'], {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.enteringScreen,
				  })
				: theme.transitions.create(['width', 'margin'], {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.leavingScreen,
				  }),
			backgroundColor: '#fff',
			color: '#000',
			minHeight: '53px',
			boxShadow: 'none',
			borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
		},
	})(AppBar);

	useEffect(() => {
		state && state.prevPath && setPrevLocation(state.prevPath);
	}, [state]);

	const onCloseSection = () => {
		setOpenConfirmationModal(false);
		setsidebarToggle(false);
		logOut();
	};

	const redirectHome = () => {
		history.push('/');
	};

	const footerSidebarOptions = [
		{
			id: 3,
			label: 'Salir',
			path: '/',
			isDoctor: true,
			onClick: () => setOpenConfirmationModal(true),
			icon: <ExitToAppIcon />,
		},
	];

	const hideTopBar = !history.location.pathname.includes('/client-consultation');

	const handleModalClientState = (editing: boolean) => {
		setEditingClient(editing);
		setModalClientState(true);
	};

	const handleOpenCalendar = () => {
		if (isCalendarSideViewOpen) {
			setIsCalendarSideViewOpen!(false);
		} else {
			setIsCalendarSideViewOpen!(true);
		}
	};

	const configBannerStyles = sidebarToggle
		? classes.subDrawerOpen
		: classes.subDrawerOpenHideSideBar;

	useEffect(() => {
		if (!isMobile)
			sideBarRoutes.push({
				id: 2,
				label: 'Calendario',
				path: pathNameList.calendar,
				icon: <CalendarSideIcon />,
			});
	}, [isMobile]);

	return (
		<div className={classes.root}>
			{modalClientState && (
				<ModalClient
					modalState={modalClientState}
					setModalState={setModalClientState}
					isUpdate={editingClient}
					setIsUpdate={setEditingClient}
				/>
			)}
			<CssBaseline />

			{hideTopBar && !isMobile && (
				<StyledAppBar position="fixed">
					<Toolbar className={classes.header}>
						<div className={classes.container}>
							<IconButton
								color="inherit"
								aria-label="open drawer"
								onClick={() => {
									setsidebarToggle(true);
								}}
								edge="start"
								className={classes.menuButton}
							>
								<MenuIcon />
							</IconButton>
							<MemberDropDown />
							<GlobalSearchPatientBar />
						</div>
						<div className={classes.actionSection}>
							<Box display={'flex'} alignItems={'center'}>
								{location.pathname === '/' ||
								location.pathname.includes('/Chat') ? (
									<div>
										<IconButton
											onClick={handleOpenCalendar}
											color={isCalendarSideViewOpen ? 'primary' : 'default'}
											className={`${
												isCalendarSideViewOpen ? 'active' : ''
											} calendar-button`}
										>
											<CalendarIcon />
										</IconButton>
										<HelpButton />
									</div>
								) : null}
							</Box>
							<StyledButton
								variant="outlined"
								size="medium"
								color="primary"
								onClick={() => handleModalClientState(false)}
								startIcon={<PersonAddIcon />}
								data-fx-name="newPatienteNavBtn"
							>
								Crear Paciente
							</StyledButton>
						</div>
					</Toolbar>
				</StyledAppBar>
			)}

			<Drawer
				id="navigation-drawer"
				variant={isMobile ? 'temporary' : 'permanent'}
				anchor={isMobile ? 'left' : undefined}
				open={isMobile && sidebarToggle}
				onClose={() => {
					setsidebarToggle(false);
				}}
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: sidebarToggle,
					[classes.drawerClose]: !sidebarToggle,
				})}
				classes={{
					paper: clsx({
						[classes.drawerOpen]: sidebarToggle,
						[classes.drawerClose]: !sidebarToggle,
					}),
				}}
			>
				<div className={classes.toolbar}>
					{sidebarToggle ? (
						<BigLogo onClick={redirectHome} />
					) : (
						<SmallLogo onClick={redirectHome} />
					)}
					{!isMobile && sidebarToggle && (
						<IconButton onClick={() => setsidebarToggle(false)}>
							{theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
						</IconButton>
					)}
				</div>
				<Divider />
				<div
					className={clsx(classes.menuContainer, {
						[classes.menuContainerMobile]: isMobile,
					})}
				>
					{isMobile ? (
						<div className={clsx(classes.searchSection, classes.searchSectionMobile)}>
							<MemberDropDown selectCustomStyles={{ marginBottom: '14px' }} />
							<GlobalSearchPatientBar callback={() => setsidebarToggle!(false)} />
						</div>
					) : null}
					<List className={clsx(classes.listContainer, classes.list)}>
						{sideBarRoutes.map((option) => {
							const selected =
								location.pathname === option.path ||
								(location.pathname.includes('/Configuration') &&
									option.path.includes('/Configuration'));
							return (
								<ListItem
									component={ReactLink}
									className={`nav-link ${classes.listItem}`}
									to={option.path}
									selected={selected}
									button
									key={option.id}
									onClick={() => {
										if (isMobile) setsidebarToggle(false);
									}}
									divider
									data-fx-name={option.label}
								>
									<ListItemIcon
										className={clsx(classes.listIcon)}
										title={!sidebarToggle ? option.label : undefined}
									>
										{option.icon}
									</ListItemIcon>
									<ListItemText
										className={clsx(classes.listText, {
											[classes.listTextMobile]: isMobile,
											[classes.listTextMobileSelected]: isMobile && selected,
										})}
										primary={option.label}
									/>
								</ListItem>
							);
						})}
					</List>
					<List className={classes.list}>
						{footerSidebarOptions.map((option) => {
							return (
								option.isDoctor && (
									<ListItem
										className={`nav-link-bottom ${classes.listItem}`}
										onClick={option.onClick}
										button
										selected={
											option?.path
												? location.pathname === option?.path
												: false
										}
										key={option.id}
										divider
									>
										<ListItemIcon
											className={classes.listIcon}
											title={!sidebarToggle ? option.label : undefined}
										>
											{option.icon}
										</ListItemIcon>
										<ListItemText
											className={clsx(classes.listText, {
												[classes.listTextMobile]: isMobile,
											})}
											primary={option.label}
										/>
									</ListItem>
								)
							);
						})}
					</List>
				</div>
			</Drawer>
			{!isMobile && (
				<Drawer
					variant="permanent"
					className={clsx(classes.drawer, {
						[configBannerStyles]: location.pathname.includes('Configuration'),
						[classes.subDrawerClose]: !location.pathname.includes('Configuration'),
					})}
					classes={{
						paper: clsx({
							[classes.subDrawerOpen]: location.pathname.includes('Configuration'),
							[classes.subDrawerClose]: !location.pathname.includes('Configuration'),
						}),
					}}
					PaperProps={{
						style: {
							position: 'relative',
						},
					}}
				>
					<div className={classes.menuContainer}>
						<List className={classes.list}>
							{configRoutes.map((option) => (
								<ListItem
									component={ReactLink}
									className={classes.subDrawerListItem}
									to={option.path}
									selected={location.pathname === option.path}
									button
									key={option.id}
								>
									<ListItemText
										className={classes.subDrawerListText}
										primaryTypographyProps={{
											style: {
												fontWeight:
													location.pathname === option.path ? 600 : 400,
												fontSize: '14px',
											},
										}}
										primary={option.label}
										style={{
											fontWeight:
												location.pathname === option.path ? 600 : 400,
										}}
									/>
								</ListItem>
							))}
						</List>
					</div>
				</Drawer>
			)}

			{openConfirmationModal && (
				<CustomDialog
					open={openConfirmationModal}
					maxWidth={'xl'}
					handleClose={() => setOpenConfirmationModal(false)}
					title={'¿Estás seguro que quieres cerrar la sección?'}
					disagreeText="Cancelar"
					agreeText="Confirmar"
					handleAgree={onCloseSection}
				/>
			)}
		</div>
	);
};

export default MainSideBar;
