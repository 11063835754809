import React, { useContext, useState } from 'react';
import { RelationsContext } from '../../context/relations-context';
import {
	IDoctorContextProps,
	RelationsContextProps,
	UserContextProps,
	UserRoles,
} from '../../context/types';

import { CancelActivateSubscriptionBtn, CancelSubscriptionContainer } from '../../shared/style';
import ModalConfirmation from '../modalConfirmation/ModalConfirmation';
import Cards from '../payment/Cards';
import { Skeleton } from '@material-ui/lab';
import MobileCardHeader from 'components/mobileNavigation/MobileCardHeader';
import useMobile from 'hooks/useMobile';
import useStripeConfiguration from 'hooks/useStripeConfiguration';
import { UserContext } from 'context/user-context';
import { CardsButton, CardsButtonContainer } from './Payment.view';
import ChangePlan from './changePlan/ChangePlan';
import { DoctorsContext } from 'context/doctor-context';

const Payment = () => {
	const { userRole } = useContext(UserContext) as UserContextProps;
	const [changePlan, setChangePlan] = useState<boolean>(false);
	const { actualDoctor } = useContext(RelationsContext) as RelationsContextProps;
	const {
		loadingPrice,
		price,
		cancelOrActivateSubscriptionHandler,
		openModal,
		showModal: showCancel,
		closeModal: setShowCancel,
		nextPaymentDate,
		allowUserToUpdatePlan,
		fetchSubscription,
	} = useStripeConfiguration();
	const { updateDoctorPayments, shouldUpdateDoctorPayments } = useContext(
		DoctorsContext
	) as IDoctorContextProps;

	const { isMobile } = useMobile();

	const myCardsStyle = isMobile
		? {}
		: {
				width: 716,
				marginTop: '60px',
		  };

	if (loadingPrice) {
		return (
			<div
				id="myCards"
				className="bg-white-section rounded-xl m-auto"
				style={{
					width: 716,
					marginTop: '60px',
					borderRadius: '4px',
					background: '#FFFFFF',
					boxShadow:
						'0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)',
				}}
			>
				<div
					className="border-b-2 border-green-content"
					style={{
						padding: ' 13px 16px',
						height: '58px',
					}}
				>
					<h1
						className="font-bold raleway-font"
						style={{
							fontFamily: 'Roboto',
							fontSize: '20px',
						}}
					>
						<Skeleton variant="rect" width="100%" height={30} />
					</h1>
				</div>

				<div
					style={{
						padding: '20px',
					}}
				>
					<div>
						<h2
							className="text-blueLucki-default font-bold raleway-font mb-1"
							style={{
								fontSize: '16px',
							}}
						>
							<Skeleton variant="rect" width="100%" height={50} />
						</h2>

						<span
							className="text-base font-normal raleway-font"
							style={{
								color: '#00000099',
								fontFamily: 'Roboto',
								fontStyle: 'normal',
								fontWeight: 400,
								fontSize: '16px',
								lineHeight: '24px',
								letterSpacing: '0.15px',
							}}
						>
							<Skeleton variant="rect" width="100%" height={50} />
						</span>
					</div>

					<Skeleton variant="rect" width="100%" height={20} />

					<CancelSubscriptionContainer>
						<Skeleton
							variant="rect"
							width={150}
							height={50}
							style={{
								marginTop: '20px',
							}}
						/>
					</CancelSubscriptionContainer>
				</div>
			</div>
		);
	}

	const formatPriceHandler = (price: number) => {
		return price.toLocaleString('en-US');
	};

	const isOwnerUser = userRole === UserRoles.Owner;
	const cancelationMessage = `Membrecía cancelada- Tendrás acceso a tu cuenta hasta ${nextPaymentDate}`;
	const nextPaymentMessage = `Pagarás RD$${formatPriceHandler(
		price
	)} pesos el ${nextPaymentDate}`;
	const activeMessage = `Tu membresía está activa. ${isOwnerUser ? nextPaymentMessage : ''}`;
	const bodyPrinter = () => (
		<div
			className={isMobile ? 'pages-container' : ''}
			style={isMobile ? { padding: '16px 16px 156px' } : { width: '100%' }}
		>
			{showCancel && (
				<ModalConfirmation
					className={
						isMobile
							? actualDoctor?.cancelAtPeriodEnd
								? 'activate-membership-modal'
								: 'cancel-membership-modal'
							: ''
					}
					modalState={showCancel}
					closeFunction={setShowCancel}
					approveFunction={cancelOrActivateSubscriptionHandler}
					name={`¿Estás seguro que quieres ${
						actualDoctor?.cancelAtPeriodEnd ? 'activar' : 'cancelar'
					} tu membresía?`}
					validationLabel={
						actualDoctor?.cancelAtPeriodEnd
							? `Tu próximo pago será ${nextPaymentMessage}`
							: `Podrás usar tu cuenta hasta el ${nextPaymentDate}`
					}
					cancelStyleColor={actualDoctor?.cancelAtPeriodEnd ? 'primary' : 'secondary'}
				/>
			)}
			<ChangePlan open={changePlan} handleClose={() => setChangePlan(false)} />
			<div
				id="myCards"
				className="bg-white-section rounded-xl m-auto"
				style={{
					...myCardsStyle,
					borderRadius: '4px',
					background: '#FFFFFF',
					boxShadow:
						'0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)',
				}}
			>
				{!isMobile && (
					<div
						className="border-b-2 border-green-content"
						style={{
							padding: '13px 16px',
							height: '58px',
						}}
					>
						<h1
							className="font-bold raleway-font"
							style={{
								fontFamily: 'Roboto',
								fontSize: '20px',
							}}
						>
							Métodos de pago
						</h1>
					</div>
				)}

				<div
					style={
						isMobile
							? { padding: '16px' }
							: {
									padding: '20px',
							  }
					}
				>
					<div>
						<h2
							className="text-blueLucki-default font-bold mb-1"
							style={{
								fontSize: '16px',
							}}
						>
							Mi membresía
						</h2>

						<span
							className="text-base font-normal raleway-font"
							style={{
								color: '#00000099',
								fontFamily: 'Roboto',
								fontStyle: 'normal',
								fontWeight: 400,
								fontSize: '16px',
								lineHeight: '24px',
								letterSpacing: '0.15px',
							}}
						>
							{actualDoctor?.cancelAtPeriodEnd ? cancelationMessage : activeMessage}
						</span>
					</div>
					{isOwnerUser && (
						<Cards
							planId={actualDoctor?.currentPlanID as number}
							openChangePlan={() => {
								setChangePlan(true);
							}}
						/>
					)}
					{!isMobile && (
						<CardsButtonContainer>
							{allowUserToUpdatePlan && isOwnerUser && (
								<CardsButton
									variant="outlined"
									onClick={() => {
										setChangePlan(true);
									}}
								>
									Cambiar plan
								</CardsButton>
							)}
							{isOwnerUser && (
								<CancelActivateSubscriptionBtn
									onClick={openModal}
									color="primary"
									className="!border-transparent !bg-transparent !border-0 !py-[2px]"
								>
									{actualDoctor?.cancelAtPeriodEnd
										? 'Activar membresía'
										: 'Cancelar membresía'}
								</CancelActivateSubscriptionBtn>
							)}
						</CardsButtonContainer>
					)}
				</div>
			</div>

			{isMobile && (
				<div
					className="mt-[16px] bg-white-section rounded-xl m-auto px-[16px] py-[16px] flex items-center justify-between"
					style={{
						...myCardsStyle,
						borderRadius: '4px',
						background: '#FFFFFF',
						boxShadow:
							'0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)',
					}}
				>
					<h2
						className="text-blueLucki-default font-regular"
						style={{
							fontSize: '16px',
						}}
					>
						Membresía
					</h2>

					<CardsButtonContainer>
						{isOwnerUser ? (
							<CancelActivateSubscriptionBtn
								onClick={openModal}
								color="primary"
								className="!border-transparent !bg-transparent !border-0 !py-[2px]"
							>
								{actualDoctor?.cancelAtPeriodEnd
									? 'Activar membresía'
									: 'Cancelar membresía'}
							</CancelActivateSubscriptionBtn>
						) : null}
					</CardsButtonContainer>
				</div>
			)}
		</div>
	);

	const reFetchSubscription = async () => {
		shouldUpdateDoctorPayments(false);
		await fetchSubscription();
	};

	if (updateDoctorPayments) {
		reFetchSubscription();
	}

	return isMobile ? (
		<div className="mobile-wrapper">
			<div className="mobile-header">
				<MobileCardHeader />
			</div>
			{bodyPrinter()}
		</div>
	) : (
		bodyPrinter()
	);
};

export default Payment;
