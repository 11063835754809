// @ts-nocheck
import moment, { Moment } from 'moment';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const months = {
	'01': 'Enero',
	'02': 'Febrero',
	'03': 'Marzo',
	'04': 'Abril',
	'05': 'Mayo',
	'06': 'Junio',
	'07': 'Julio',
	'08': 'Agosto',
	'09': 'Septiembre',
	'10': 'Octubre',
	'11': 'Noviembre',
	'12': 'Diciembre',
};

export enum FormatDate {
	'MM-DD-YYYY',
	'Monthname DD, YYYY',
	'DD/MM/YYYY',
	'YYYY-MM-DD',
}

//returns date as string in specific format: MM-DD-YYYY
const getDateToString = (date: Date, format: FormatDate): string => {
	if (format === FormatDate['MM-DD-YYYY']) {
		return `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
	}
	if (format === FormatDate['Monthname DD, YYYY']) {
		const monthString = date.toLocaleString('es-ES', { month: 'long' });
		return `${monthString.charAt(0).toUpperCase() + monthString.slice(1)} ${date.toLocaleString(
			'es-ES',
			{ day: '2-digit' }
		)}, ${date.toLocaleString('es-ES', { year: 'numeric' })}`;
	}
	if (format === FormatDate['DD/MM/YYYY']) {
		return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
	}
	if (format === FormatDate['YYYY-MM-DD']) {
		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const day = date.getDate().toString().padStart(2, '0');
		const year = date.getFullYear();
		return [year, month, day].join('-');
	}
	return 'No valid format';
};

export const DateErrorMessage = 'Invalid date';

const displayAge = (birth: Moment) => {
	const months = moment().diff(birth, 'months', true);
	const birthSpan = {
		year: Math.floor(months / 12),
		month: Math.floor(months) % 12,
		day: Math.round((months % 1) * moment().daysInMonth()),
	};
	// you can adjust below logic as your requirements by yourself
	if (birthSpan.year < 1 && birthSpan.month < 1) {
		return birthSpan.day + ' día' + (birthSpan.day > 1 ? 's' : '');
	} else if (birthSpan.year < 1) {
		return (
			birthSpan.month +
			' mes' +
			(birthSpan.month > 1 ? 'es ' : ' ') +
			birthSpan.day +
			' día' +
			(birthSpan.day > 1 ? 's' : '')
		);
	} else if (birthSpan.year < 6) {
		return (
			birthSpan.year +
			' año' +
			(birthSpan.year > 1 ? 's ' : ' ') +
			birthSpan.month +
			' mes' +
			(birthSpan.month > 1 ? 'es ' : ' ')
		);
	} else {
		return birthSpan.year + ' año' + (birthSpan.year > 1 ? 's' : '');
	}
};

const calculateAge = (bornDate: string) => {
	return Math.abs(new Date(Date.now()).getUTCFullYear() - new Date(bornDate).getUTCFullYear());
};

//get today's date
const getTodayDate = () => {
	const date = new Date();
	const todaysDate =
		date.getFullYear().toString() +
		'-' +
		(date.getMonth() + 1).toString().padStart(2, 0) +
		'-' +
		date.getDate().toString().padStart(2, 0);
	return todaysDate;
};

const getDateAndTime = () => {
	const dateObject = new Date();
	const dateArray = JSON.stringify(dateObject).replace(/"/g, '');
	const [year, month, dayAndTime] = dateArray.split('-');
	const [day, time] = dayAndTime.split('T');

	return [year, month, day].join('-').concat(`T${time}`);
};

const paymentDate = () => {
	const actualDate = moment();
	const newDate = actualDate.add(15, 'days');
	return newDate.format('D [de] MMMM [de] YYYY');
};

const getTrialEndDate = () => {
	const currentDate = moment.utc();
	const trialEndDate = currentDate.clone().add(15, 'days');
	return trialEndDate;
};

export {
	getDateToString,
	calculateAge,
	getTodayDate,
	getDateAndTime,
	displayAge,
	paymentDate,
	getTrialEndDate,
};
