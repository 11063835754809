import { Grid, Avatar } from '@material-ui/core';
import React, { FC, ReactNode, useContext } from 'react';
import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
	GridHeader,
	ActionButtonContainer,
	ProfilePicture,
	InformationSection,
	TypographyDetail,
	GridTitleContainer,
} from './styles';
import { ClientsContext } from '../../../context/client-context';
import { ClientContextProps } from '../../../context/types';
import { useHistory } from 'react-router-dom';
import useMobile from 'hooks/useMobile';
interface Props {
	actionsButtons: ReactNode;
	infoSection?: ReactNode;
	style?: React.CSSProperties;
}

const ClientHeader: FC<Props> = ({ actionsButtons, infoSection, style }) => {
	const { selectedClient } = useContext(ClientsContext) as ClientContextProps;
	const history = useHistory();
	const { isMobile } = useMobile();
	return (
		<GridHeader container style={style}>
			<InformationSection container>
				<GridTitleContainer
					container
					xs={12}
					alignContent="center"
					spacing={2}
					style={{
						columnGap: isMobile ? '0' : '16px',
						alignItems: 'center',
					}}
				>
					{isMobile && (
						<IconButton
							aria-label="back"
							style={{
								padding: '4px',
								height: '36px',
								width: '36px',
								marginLeft: '8px',
							}}
							onClick={() => history.push('/home')}
						>
							<ArrowBackIcon />
						</IconButton>
					)}

					{isMobile && history.location.pathname.includes('/client-consultation') ? (
						<span className="mr-[8px]" />
					) : (
						<Grid
							item
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							{selectedClient?.profileImage ? (
								<ProfilePicture
									src={selectedClient.profileImage}
									alt="profile-picture"
								/>
							) : (
								<div className="flex items-center justify-center rounded-full border-2 border-black-default w-10 h-10 font-bold raleway-font text-2xl">
									<Avatar
										alt="Foto de Perfil"
										style={{
											width: '40px',
											height: '40px',
										}}
									/>
								</div>
							)}
						</Grid>
					)}
					<Grid
						style={{
							display: 'flex',
							flexDirection: 'column',
							padding: '8px 0px',
							alignItems: 'center',
							justifyContent: 'center',
						}}
						alignItems="center"
					>
						<TypographyDetail
							variant="h5"
							style={{
								fontSize: isMobile ? '16px' : '20px',
							}}
							data-fx-name="patientFullName"
						>
							{selectedClient?.name} {selectedClient?.lastName}
						</TypographyDetail>
						{isMobile && history.location.pathname.includes('/client-consultation')
							? null
							: selectedClient?.recordNo && (
									<span className="w-full text-[#1E2469] not-italic font-roboto text-xs font-medium leading-snug tracking-tight">
										Expediente: {selectedClient.recordNo}
									</span>
							  )}
						{infoSection && (
							<Grid container>
								<Grid item xs={6}>
									{infoSection}
								</Grid>
							</Grid>
						)}
					</Grid>
				</GridTitleContainer>
			</InformationSection>
			<ActionButtonContainer
				item
				xs={12}
				style={{
					flexBasis: '46%',
				}}
			>
				{actionsButtons}
			</ActionButtonContainer>
		</GridHeader>
	);
};

export default ClientHeader;
