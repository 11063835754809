import React from 'react';
import CustomCard from '../../card/CustomCard';
import { EmptyStateContainer, StyledButton } from './PatientSummary.style';
import { ConsultationType } from '../../../context/types';
import SummaryConsultationCard from './SummaryConsultationCard';

interface Props {
	handleSeeAllConsultation: () => void;
	consultations: ConsultationType[];
	handleDetailEnterConsultation: (
		e: React.MouseEvent<HTMLButtonElement>,
		consultation: ConsultationType
	) => Promise<void>;
	previousId: string | undefined;
}

const SummaryConsultation: React.FC<Props> = ({
	handleSeeAllConsultation,
	consultations,
	handleDetailEnterConsultation,
	previousId,
}) => {
	return (
		<CustomCard
			title="Consultas"
			divider={true}
			headerAction={
				<StyledButton onClick={handleSeeAllConsultation} color="primary" size="small">
					Ver todas
				</StyledButton>
			}
			paddingTop={'0'}
			paddingBottom={'0'}
			contentHeight={'216px'}
			hasMarginBottom
		>
			{consultations.length > 0 ? (
				consultations.map((consultation) => {
					return (
						<SummaryConsultationCard
							key={consultation.id}
							consultation={consultation}
							handleDetailEnterConsultation={handleDetailEnterConsultation}
							previousId={previousId}
						/>
					);
				})
			) : (
				<EmptyStateContainer>No existen consultas.</EmptyStateContainer>
			)}
		</CustomCard>
	);
};

export default SummaryConsultation;
