import styled from 'styled-components';
import BottomNavigationMUI from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

export const StyledBottomNavigationMUI = styled(BottomNavigationMUI)`
	z-index: 99;
` as typeof BottomNavigationMUI;

export const StyledBottomNavigationAction = styled(BottomNavigationAction)`
	padding-left: 0 !important;
	padding-right: 0 !important;
` as typeof BottomNavigationAction;
