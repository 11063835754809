import { TextField } from '@material-ui/core';
import React from 'react';
import CustomCard from '../../../card/CustomCard';
import { InputContainer } from '../../../../shared/style';
import { ConsultationContainer } from './styles';

interface Props {
	physicalDescription: string;
	setPhysicalDescription: (data: string) => void;
}

const PhysicalTestForm: React.FC<Props> = ({ physicalDescription, setPhysicalDescription }) => {
	return (
		<ConsultationContainer item xs={12}>
			<CustomCard divider={true} title="Notas de examen físico">
				<InputContainer item xs={12} md={10}>
					<TextField
						id="notes"
						label="Notas del examen"
						variant="outlined"
						fullWidth
						multiline
						minRows={4}
						value={physicalDescription}
						onChange={(e) => setPhysicalDescription(e.target.value)}
					/>
				</InputContainer>
			</CustomCard>
		</ConsultationContainer>
	);
};

export default PhysicalTestForm;
