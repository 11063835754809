import React, { MouseEventHandler, useEffect } from 'react';
import SaveButton from '../saveButton/SaveButton';
import CancelButton from '../cancelButton/CancelButton';
import { AddIcon, CloseIcon } from '../svg';
import CustomDialog from '../dialog/CustomDialog';

interface Props {
	modalState: boolean;
	setModalState: (state: boolean) => void;
	approveFunction?: () => void;
	title?: string;
	cancelFunction?: () => void;
	confirmLabel?: string;
	cancelLabel?: string;
	addTitle?: string;
	addFunction?: MouseEventHandler | null;
	cssClasses?: string;
	customConfirmBtn?: any;
	withOutButtons?: boolean;
	hideConfirmBtn?: boolean;
	hasCancelBtn?: boolean;
	hideHeader?: boolean;
}

let timeout: ReturnType<typeof setTimeout>;

const ModalContainer: React.FC<Props> = ({
	children,
	withOutButtons = false,
	modalState,
	confirmLabel,
	hideConfirmBtn = false,
	hasCancelBtn = false,
	cancelLabel,
	setModalState,
	approveFunction,
	title,
	addTitle,
	addFunction,
	cancelFunction = () => null,
	cssClasses,
	customConfirmBtn: customBtn,
	hideHeader = false,
}) => {
	const handleApprove = () => {
		try {
			approveFunction?.();
			handleCloseAnimation();
		} catch (e) {
			console.log(e);
		}
	};

	const handleCancel = () => {
		cancelFunction();
		handleCloseAnimation();
	};

	useEffect(() => {
		return () => {
			clearTimeout(timeout);
		};
	}, []);

	const handleCloseAnimation = () => {
		timeout = setTimeout(() => {
			setModalState(false);
		}, 180);

		return () => clearTimeout(timeout);
	};

	return (
		<>
			{modalState && (
				<CustomDialog
					open={modalState}
					handleClose={handleCancel}
					maxWidth={'lg'}
					disableBackdropClick
				>
					<div className={`modal-contain p-8 bg-white-section ${cssClasses} `}>
						{!hideHeader ? (
							<>
								<button className="modal-close-icon" onClick={handleCancel}>
									<CloseIcon />
								</button>
								<div className="w-full flex align-items mb-10">
									<h2 className="text-3xl text-blueLucki-default	font-medium raleway-font leading-10">
										{title}
									</h2>
									{addTitle && addFunction && (
										<button
											onClick={addFunction}
											className="text-base text-mountainMeadow-default ml-4 flex items-center font-bold cursor-pointer"
										>
											<div className="mr-1">
												<AddIcon />
											</div>
											Añadir {addTitle}
										</button>
									)}
								</div>
							</>
						) : null}
						{children}
						{!withOutButtons && (
							<div className="flex justify-end ">
								<div className="flex items-center pt-9">
									{hasCancelBtn && (
										<div className="mr-7">
											<CancelButton
												onClick={handleCancel}
												label={cancelLabel || ''}
											/>
										</div>
									)}
									{customBtn ? (
										customBtn
									) : hideConfirmBtn ? null : (
										<SaveButton
											onClick={handleApprove}
											label={confirmLabel || ''}
										/>
									)}
								</div>
							</div>
						)}
					</div>
				</CustomDialog>
			)}
		</>
	);
};

export default ModalContainer;
