// @ts-nocheck
import React, { useState, useContext, useEffect, forwardRef, Ref } from 'react';
import { Grid, TextField } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CustomCard from '../../../card/CustomCard';
import {
	StyledChip,
	InputContainer,
	AddButton,
	OptionList,
	StyledDiagnosticTitle,
	theme,
} from '../../../../shared/style';
import { withStyles } from '@material-ui/styles';
import { DiagnosisOptions, SelectedDiagnosis } from '../../../../shared/type';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ListboxComponent, { renderGroup } from '../../../listComponent/ListboxComponent';
import { matchSorter } from 'match-sorter';
import diagnosisList from '../../../../shared/diagnosis';
import { AIContextProps, IDoctorContextProps } from 'context/types';
import { DoctorsContext } from 'context/doctor-context';
import AIAlertSuggestion from './AIAlertSuggestion';
import { DiagnosticTypes } from 'models';
import { AiContext } from 'context/ai-context';
import { ConsultationContainer } from './styles';
import useMobile from 'hooks/useMobile';
import { truncateText } from 'shared/utilFunctions';

const StyledTextField = withStyles({
	root: {
		margin: 0,
	},
})(TextField) as typeof TextField;

interface Prop {
	selectedDiagnosis: SelectedDiagnosis[];
	selectedDiffDiagnosis: SelectedDiagnosis[];
	handleDeleteDiagnosis: (id: number | string) => void;
	handleDeleteDiffDiagnosis: (id: number | string) => void;
	handleAddDiagnosist: () => void;
	handleAddDiffDiagnosist: () => void;
	handleClickAddDiagnosist: (option: DiagnosisOptions | null) => void;
	handleClickAddDiffDiagnosist: (option: DiagnosisOptions | null) => void;
	diagnosisInputValue: string;
	setDiagnosisInputValue: (data: string) => void;
	diffDiagnosisInputValue: string;
	setDiffDiagnosisInputValue: (data: string) => void;
	selectedDiagnosisValue: string | null;
	selectedDiffDiagnosisValue: string | null;
}

const DiagnosisForm: React.FC<Prop> = forwardRef((props, ref: Ref<null | HTMLElement>) => {
	const {
		selectedDiagnosisValue,
		handleDeleteDiagnosis,
		handleDeleteDiffDiagnosis,
		handleAddDiagnosist,
		handleAddDiffDiagnosist,
		handleClickAddDiagnosist,
		handleClickAddDiffDiagnosist,
		diagnosisInputValue,
		setDiagnosisInputValue,
		diffDiagnosisInputValue,
		setDiffDiagnosisInputValue,
		selectedDiagnosis,
		selectedDiffDiagnosis,
		selectedDiffDiagnosisValue,
	} = props;

	const { aiSuggestions, suggestionClickHandler } = useContext(AiContext) as AIContextProps;
	const { fetchDoctorDiagnosis } = useContext(DoctorsContext) as IDoctorContextProps;
	const [recentDoctorDiagnosis, setRecentDoctorDiagnosis] = useState<DiagnosisOptions[]>([]);
	const [diagnosisOptions, setDiagnosisOptions] = useState<DiagnosisOptions[]>([]);
	const [showAIDiagnostics, setShowAIDiagnostics] = React.useState<boolean>(true);
	const [showAIDiffDiagnostics, setShowAIDiffDiagnostics] = React.useState<boolean>(true);
	const { isMobile } = useMobile();
	const filterOptions = (options: DiagnosisOptions[], state: any) =>
		matchSorter(options, state.inputValue, { keys: ['label', 'code'] });
	const isMdQuery = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });

	const getDoctorDiagnosis = async () => {
		const doctorDiagnosis = await fetchDoctorDiagnosis();
		setRecentDoctorDiagnosis(
			doctorDiagnosis.map((rcd) => ({
				id: rcd.id,
				label: rcd.name,
				code: rcd.code || '',
			})) || []
		);
	};
	const DIAGNOSIS_SELECT_TEXT = 'Escribe o busca en CIE 10 (Nombre o código)';
	const AISuggestionClickHandler = (suggestion: string, diagnosticType: DiagnosticTypes) => {
		if (diagnosticType === DiagnosticTypes.DIFFERENTIAL) {
			handleClickAddDiffDiagnosist({
				id: 0,
				label: suggestion,
				code: '',
			} as DiagnosisOptions);
		}

		if (diagnosticType === DiagnosticTypes.PRESUMPTIVE) {
			handleClickAddDiagnosist({
				id: 0,
				label: suggestion,
				code: '',
			} as DiagnosisOptions);
		}
	};

	useEffect(() => {
		getDoctorDiagnosis();
	}, []);

	useEffect(() => {
		if (diagnosisInputValue === '') {
			setDiagnosisOptions(recentDoctorDiagnosis);
		} else {
			setDiagnosisOptions(diagnosisList);
		}
	}, [diagnosisInputValue, recentDoctorDiagnosis]);

	useEffect(() => {
		if (diffDiagnosisInputValue === '') {
			setDiagnosisOptions(recentDoctorDiagnosis);
		} else {
			setDiagnosisOptions(diagnosisList);
		}
	}, [diffDiagnosisInputValue, recentDoctorDiagnosis]);

	useEffect(() => {
		setShowAIDiagnostics(true);
	}, [aiSuggestions?.diagnosticos]);

	useEffect(() => {
		setShowAIDiffDiagnostics(true);
	}, [aiSuggestions?.diagnosticosDiferenciales]);

	return (
		<ConsultationContainer item xs={12} innerRef={ref}>
			<CustomCard
				divider={true}
				title={() => (
					<StyledDiagnosticTitle style={isMobile ? { backgroundColor: '#fff' } : {}}>
						Diagnóstico:{' '}
						<span
							style={{
								color: 'rgba(0, 0, 0, 0.6)',
							}}
						>
							Presuntivo
						</span>
					</StyledDiagnosticTitle>
				)}
				hasMarginBottom
				overflow={false}
			>
				<InputContainer
					item
					xs={12}
					md={10}
					justifyContent={isMobile ? 'center' : undefined}
					alignItems={isMobile ? 'center' : undefined}
					style={{ gap: isMobile ? '16px' : 0 }}
				>
					<Grid item xs={12} md={8}>
						<Autocomplete
							id="diagnosis-select"
							freeSolo
							value={selectedDiagnosisValue}
							inputValue={diagnosisInputValue}
							disableListWrap
							ListboxComponent={
								ListboxComponent as React.ComponentType<
									React.HTMLAttributes<HTMLElement>
								>
							}
							style={{ width: '100%', margin: 0 }}
							options={diagnosisOptions}
							filterOptions={
								diagnosisInputValue && diagnosisInputValue.length >= 1
									? filterOptions
									: undefined
							}
							getOptionLabel={(option) => option.label || ''}
							onChange={(_event: any, value: DiagnosisOptions | null) => {
								if (value) {
									handleClickAddDiagnosist(value);
								} else {
									handleClickAddDiagnosist(null);
								}
							}}
							onInputChange={(_event, newInputValue) => {
								setDiagnosisInputValue(newInputValue);
							}}
							renderOption={(option: DiagnosisOptions) => {
								return (
									<OptionList>
										<span>{option.label}</span>
										<span>{option.code}</span>
									</OptionList>
								);
							}}
							renderGroup={renderGroup}
							renderInput={(params: any) => (
								<StyledTextField
									{...params}
									label={
										isMobile
											? truncateText(DIAGNOSIS_SELECT_TEXT, 34)
											: DIAGNOSIS_SELECT_TEXT
									}
									margin="normal"
									variant="outlined"
									style={{ width: '100%' }}
									InputProps={{
										...params.InputProps,
									}}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={5} md={2}>
						<AddButton
							color="primary"
							onClick={() => handleAddDiagnosist()}
							// disabled={diagnosisInputValue === ''}
						>
							Agregar
						</AddButton>
					</Grid>
				</InputContainer>
				<InputContainer item xs={12}>
					{selectedDiagnosis.map((s) => (
						<StyledChip
							key={s.id}
							label={s.label}
							onDelete={() => handleDeleteDiagnosis(s.id)}
							variant="outlined"
							className="mt-2"
							isMdQuery={isMdQuery}
							data-fx-name="selectedDiagnosis"
						/>
					))}
				</InputContainer>
				{aiSuggestions && aiSuggestions.diagnosticos.length > 0 && showAIDiagnostics && (
					<AIAlertSuggestion
						add={suggestionClickHandler}
						cb={AISuggestionClickHandler}
						suggestions={aiSuggestions}
						type="diagnosticos"
						onHide={() => setShowAIDiagnostics(false)}
					/>
				)}
			</CustomCard>
			<CustomCard
				divider={true}
				title={() => (
					<StyledDiagnosticTitle style={isMobile ? { backgroundColor: '#fff' } : {}}>
						Diagnóstico:{' '}
						<span
							style={{
								color: 'rgba(0, 0, 0, 0.6)',
							}}
						>
							Diferencial
						</span>
					</StyledDiagnosticTitle>
				)}
				overflow={false}
			>
				<InputContainer
					item
					xs={12}
					md={10}
					style={{ gap: isMobile ? '16px' : 0 }}
					justifyContent={isMobile ? 'center' : undefined}
					alignItems={isMobile ? 'center' : undefined}
				>
					<Grid item xs={12} md={8}>
						<Autocomplete
							id="diagnosis-select"
							freeSolo
							value={selectedDiffDiagnosisValue}
							inputValue={diffDiagnosisInputValue}
							disableListWrap
							ListboxComponent={
								ListboxComponent as React.ComponentType<
									React.HTMLAttributes<HTMLElement>
								>
							}
							style={{ width: '100%', margin: 0 }}
							options={diagnosisOptions}
							filterOptions={
								diffDiagnosisInputValue && diffDiagnosisInputValue.length >= 1
									? filterOptions
									: undefined
							}
							getOptionLabel={(option) => option.label || ''}
							onChange={(_event: any, newValue: DiagnosisOptions | null) => {
								if (newValue) {
									handleClickAddDiffDiagnosist(newValue);
								} else {
									handleClickAddDiffDiagnosist(null);
								}
							}}
							onInputChange={(_event, newInputValue) => {
								setDiffDiagnosisInputValue(newInputValue);
							}}
							renderOption={(option: DiagnosisOptions) => {
								return (
									<OptionList>
										<span>{option.label}</span>
										<span>{option.code}</span>
									</OptionList>
								);
							}}
							renderGroup={renderGroup}
							renderInput={(params: any) => (
								<StyledTextField
									{...params}
									label={
										isMobile
											? truncateText(DIAGNOSIS_SELECT_TEXT, 34)
											: DIAGNOSIS_SELECT_TEXT
									}
									margin="normal"
									variant="outlined"
									style={{ width: '100%' }}
									InputProps={{
										...params.InputProps,
									}}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={5} md={2}>
						<AddButton
							color="primary"
							onClick={() => handleAddDiffDiagnosist()}
							disabled={diffDiagnosisInputValue === ''}
						>
							Agregar
						</AddButton>
					</Grid>
				</InputContainer>
				<InputContainer item xs={12}>
					{selectedDiffDiagnosis.map((s) => (
						<StyledChip
							key={s.id}
							label={s.label}
							onDelete={() => handleDeleteDiffDiagnosis(s.id)}
							variant="outlined"
							className="mt-2"
							isMdQuery={isMdQuery}
						/>
					))}
				</InputContainer>
				{aiSuggestions &&
					aiSuggestions.diagnosticosDiferenciales.length > 0 &&
					showAIDiffDiagnostics && (
						<AIAlertSuggestion
							add={suggestionClickHandler}
							cb={AISuggestionClickHandler}
							suggestions={aiSuggestions}
							type="diagnosticosDiferenciales"
							onHide={() => setShowAIDiffDiagnostics(false)}
						/>
					)}
			</CustomCard>
		</ConsultationContainer>
	);
});

export default DiagnosisForm;
