// @ts-nocheck
import React, { FC, useContext } from 'react';
import ModalConfirmation from '../modalConfirmation/ModalConfirmation';
import CheckOutForm from '../configuration/CheckOutForm';
import { Elements } from '@stripe/react-stripe-js';
import CardBox from '../configuration/CardBox';
import { RelationsContext } from '../../context/relations-context';
import { loadStripe } from '@stripe/stripe-js';
import { DoctorType, RelationsContextProps } from '../../context/types';
import AddIcon from '@material-ui/icons/Add';
import { Button } from '@material-ui/core';
import { AddMethodContainer } from '../../shared/style';
import useMobile from 'hooks/useMobile';
import useStripeConfiguration from 'hooks/useStripeConfiguration';
import { CardsButton } from 'components/configuration/Payment.view';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

interface Props {
	planId: number;
	openChangePlan?: () => void;
}

const CardsContainer: FC<Props> = ({ planId, openChangePlan }) => {
	const {
		paymentCards,
		defaultPaymentMethod,
		updateSelectedCard,
		deleteCardHandler,
		selectCardHandler,
		errorMessage,
		errorModal,
		changeCardModal,
		deleteCardModal,
		selectedCard,
		closeErrorModal,
		closeChangeCardModal,
		closeDeleteCardModal,
		openCardModal,
		closeCardModal,
		openAddCardModal,
		deleteCard,
		fetchCreditCards,
		allowUserToUpdatePlan,
	} = useStripeConfiguration();
	const { actualDoctor, addCardToCustomer } = useContext(
		RelationsContext
	) as RelationsContextProps;

	const { isMobile } = useMobile();
	return (
		<>
			<div
				className="grid grid-cols-1 gap-[10px] md:gap-y-4 w-full"
				style={{
					marginTop: '20px',
				}}
			>
				{paymentCards &&
					paymentCards?.data?.length > 0 &&
					paymentCards?.data?.map((card) => {
						return (
							<CardBox
								className="!px-[16px] !mb-0  !md:px-[24px]"
								key={card.id}
								cardDetail={card}
								selected={card.id === defaultPaymentMethod}
								selectFunction={() => selectCardHandler(card)}
								deleteFunction={() => deleteCardHandler(card)}
							/>
						);
					})}
			</div>
			<AddMethodContainer
				className={`justify-center !md:block mb-[16px] md:mb-0 w-full ${
					isMobile ? 'flex flex-col' : 'inline-block'
				}`}
			>
				<Button
					color="primary"
					className="!mt-[16px]   !md:mt-8 !py-[8px] !md:py-[6px]"
					style={{
						color: '#1976D2',
					}}
					onClick={openCardModal}
					startIcon={<AddIcon />}
				>
					Agregar método de pago
				</Button>
				{allowUserToUpdatePlan && isMobile && (
					<CardsButton variant="outlined" onClick={openChangePlan}>
						Cambiar plan
					</CardsButton>
				)}
			</AddMethodContainer>
			<Elements stripe={stripePromise}>
				<CheckOutForm
					modalState={openAddCardModal}
					paymentCards={paymentCards}
					closeCardModal={closeCardModal}
					doctor={actualDoctor as DoctorType}
					addCardToCustomer={addCardToCustomer}
					fetchCreditCards={fetchCreditCards}
				/>
			</Elements>
			<ModalConfirmation
				modalState={errorModal}
				approveFunction={() => null}
				cancelLabel="Entendido"
				name={errorMessage}
				closeFunction={closeErrorModal}
				hideConfirmBtn
			/>
			<ModalConfirmation
				className={isMobile ? 'cancel-card-modal' : ''}
				modalState={deleteCardModal}
				approveFunction={() => deleteCard(selectedCard)}
				name={`¿Estás seguro de borrar la tarjeta terminando en ${selectedCard?.card.last4}?`}
				closeFunction={closeDeleteCardModal}
			/>
			<ModalConfirmation
				modalState={changeCardModal}
				approveFunction={() => updateSelectedCard(selectedCard)}
				name={`¿Estás seguro de cambiar la tarjeta de pagos a la tarjeta terminando en ${selectedCard?.card.last4}?`}
				closeFunction={closeChangeCardModal}
			/>
		</>
	);
};

export default CardsContainer;
