import React from 'react';
import { SecondContainer } from 'shared/style';
import CustomCard from 'components/card/CustomCard';

const NoPrescriptionAllowed = () => {
	return (
		<SecondContainer item xs={12}>
			<CustomCard divider={false} title="Crea e imprime prescripciones desde tu computadora">
				<p
					style={{
						color: 'var(--Light-Text-Secondary, rgba(0, 0, 0, 0.60))',
						fontFeatureSettings: "'clig' off, 'liga' off",
						fontFamily: 'Roboto',
						fontSize: '16px',
						fontStyle: 'normal',
						fontWeight: '400',
						lineHeight: '150%',
						letterSpacing: '0.15px',
						marginTop: '-15px',
					}}
				>
					Pronto podrás hacerlo en tu celular
				</p>
			</CustomCard>
		</SecondContainer>
	);
};

export default NoPrescriptionAllowed;
