import {
	Button,
	Card,
	CardContent,
	CardHeader,
	IconButton,
	InputAdornment,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LoginPulseara } from '../components/svg';
import { LoaderContext } from '../context/loader-context';
import { LoaderContextProps, UserContextProps } from '../context/types';
import { UserContext } from '../context/user-context';
import { LogingTextField, SendButton } from '../shared/style';

const CompleteRegistrationSecretary = () => {
	const { showSpinner } = useContext(LoaderContext) as LoaderContextProps;
	const { completeSecretaryRegistration, logOut } = useContext(UserContext) as UserContextProps;
	const [userInfo, setUserInfo] = useState({ fullName: '', password: '', confirmPassword: '' });
	const [passwordError, setPasswordError] = useState('');
	const [showConfPassword, setShowConfPassword] = useState<boolean>(false);
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const history = useHistory();

	useEffect(() => {
		showSpinner(false);
	}, [showSpinner]);

	const onSubmitHandler = async (event: any) => {
		event.preventDefault();
		try {
			if (userInfo.password.length < 8) {
				setPasswordError('La contraseña debe contener 8 o más caracteres!');
				return;
			}

			if (userInfo.password !== userInfo.confirmPassword) {
				setPasswordError('Las contraseñas no coinciden!');
				return;
			}

			await completeSecretaryRegistration(userInfo.fullName, userInfo.password);

			history.push('/');
		} catch (e: any) {
			if (e.code === 'UserNotConfirmedException') {
				history.push('/confirm', userInfo);
			}
			if (e.code === 'InvalidPasswordException') {
				setPasswordError('La contraseña debe contener 8 o más caracteres!');
			}
		}
	};

	const onChangeHandler = ({ target }: any) => {
		setUserInfo((userInfo) => ({ ...userInfo, [target.id]: target.value }));
	};

	const onConfirmationChangePassword = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setUserInfo((prevUser) => ({ ...prevUser, confirmPassword: event.target.value }));

		if (event.target.value.length < 8) {
			setPasswordError('La contraseña debe contener 8 o más caracteres!');
		} else {
			setPasswordError('');
		}
		if (event.target.value === userInfo.password) {
			setPasswordError('');
		} else {
			setPasswordError('Las contraseñas no coinciden!');
		}
	};

	const handleClickShowConfPassword = () => {
		setShowConfPassword((prevShowPassword) => !prevShowPassword);
	};

	const handleClickShowPassword = () => {
		setShowPassword((prevShowPassword) => !prevShowPassword);
	};

	const handleMouseDownConfPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	return (
		<div className="relative top-0 left-0 w-full h-screen min-wh-login flex flex-col items-center justify-center">
			<div className="mb-16">
				<div>
					<LoginPulseara />
				</div>
			</div>
			<Card
				style={{ minWidth: 490 }}
				className="flex flex-col bg-white-section p-8 rounded-md"
			>
				<CardHeader title="Completar Registro" />
				<CardContent className="flex flex-col overflow-y-auto">
					<LogingTextField
						value={userInfo.fullName}
						onChange={onChangeHandler}
						type="text"
						id="fullName"
						name="fullName"
						variant="outlined"
						label="Nombre Completo"
						placeholder="Nombre Completo"
					/>
					<LogingTextField
						value={userInfo.password}
						onChange={onChangeHandler}
						type={showPassword ? 'text' : 'password'}
						id="password"
						name="password"
						variant="outlined"
						label="Contraseña"
						placeholder="Contraseña"
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										size="small"
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
										edge="end"
									>
										{showPassword ? (
											<Visibility fontSize="small" />
										) : (
											<VisibilityOff fontSize="small" />
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					<LogingTextField
						value={userInfo.confirmPassword}
						onChange={onConfirmationChangePassword}
						type={showConfPassword ? 'text' : 'password'}
						id="password"
						name="password"
						variant="outlined"
						label="Confirma Contraseña"
						placeholder="Confirma Contraseña"
						error={passwordError !== ''}
						helperText={passwordError}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										size="small"
										aria-label="toggle confirmation password visibility"
										onClick={handleClickShowConfPassword}
										onMouseDown={handleMouseDownConfPassword}
										edge="end"
									>
										{showConfPassword ? (
											<Visibility fontSize="small" />
										) : (
											<VisibilityOff fontSize="small" />
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					<SendButton
						size="large"
						variant="contained"
						color="primary"
						fullWidth
						onClick={onSubmitHandler}
					>
						Completar Registro
					</SendButton>
					<Button size="large" color="primary" fullWidth onClick={logOut}>
						Cancelar
					</Button>
				</CardContent>
			</Card>
		</div>
	);
};

export default CompleteRegistrationSecretary;
