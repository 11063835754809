import React, { useContext, useState } from 'react';
import {
	CardNumberElement,
	CardExpiryElement,
	CardCvcElement,
	useStripe,
	useElements,
} from '@stripe/react-stripe-js';
import {
	attachNewPaymentMethodToCustomer,
	setDefaultPaymentMethod,
	updateSubscriptionPaymentMethod,
} from '../../adapters/stripe';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { DoctorType, IDoctorContextProps, LoaderContextProps } from '../../context/types';
import ModalConfirmation from '../modalConfirmation/ModalConfirmation';
import { LoaderContext } from '../../context/loader-context';
import {
	ActionContainer,
	AddDialogContent,
	BackButton,
	ContainerForm,
	RenewHeader,
	StyledPaymentIcon,
	TitleTypography,
} from '../../shared/style';
import CustomDialog from 'components/dialog/CustomDialog';
import { Button } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { StripeError } from '../../shared/type';
import useMobile from 'hooks/useMobile';
import MobileDialogHeader from '../dialog/MobileDialogHeader';
import RequiredStar from 'components/inputs/requiredStar';
import { DoctorsContext } from 'context/doctor-context';
interface Props {
	closeCardModal: () => void;
	modalState: boolean;
	doctor: DoctorType;
	paymentCards: any;
	addCardToCustomer?: (paymentMethodId: string, last4: string, brand: string) => Promise<void>;
	fetchCreditCards: () => Promise<void>;
}

const CheckOutForm: React.FC<Props> = ({
	closeCardModal,
	modalState,
	doctor,
	paymentCards,
	addCardToCustomer,
	fetchCreditCards,
}) => {
	const { showSpinner } = useContext(LoaderContext) as LoaderContextProps;
	const { shouldUpdateDoctorPayments } = useContext(DoctorsContext) as IDoctorContextProps;
	const [errorModal, setErrorModal] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const stripe = useStripe() as Stripe;
	const elements = useElements() as StripeElements;

	const handleSubmitStripe = async () => {
		const { error, paymentMethod } = await stripe.createPaymentMethod({
			type: 'card',
			card: elements.getElement(CardNumberElement)!,
		});
		if (!error) {
			// If the doctor already has a customer in stripe it will just update the customer and subscription payment method
			if (doctor.stripeSubscriptionID) {
				try {
					await attachNewPaymentMethodToCustomer(
						paymentMethod.id,
						doctor?.stripeCustomerID!
					);
					await updateSubscriptionPaymentMethod(
						doctor.stripeSubscriptionID,
						paymentMethod.id
					);

					if (paymentCards.data.length === 0) {
						await setDefaultPaymentMethod(doctor.stripeCustomerID!, paymentMethod.id);
					}
					shouldUpdateDoctorPayments(true);
					await fetchCreditCards();
				} catch (error: any) {
					setErrorMessage(
						StripeError[error.message as keyof typeof StripeError] ??
							'Ha ocurrido un error con los datos ingresados, por favor inténtelo nuevamente. Si el error persiste, contáctenos.'
					);
					setErrorModal(true);
				}
				return;
			}

			//if the doctor doesn't have a customer in stripe it will create a new one, and a new subscription nan then added it to datastore
			try {
				//customer data requiered for stripe
				//subscription data requiered for stripe
				await attachNewPaymentMethodToCustomer(paymentMethod.id, doctor.stripeCustomerID);
				await addCardToCustomer!(
					paymentMethod.id,
					paymentMethod.card!.last4,
					paymentMethod.card!.brand
				);
				if (paymentCards.data.length === 0) {
					await setDefaultPaymentMethod(doctor.stripeCustomerID!, paymentMethod.id);
				}
				shouldUpdateDoctorPayments(true);
				await fetchCreditCards();
			} catch (error: any) {
				setErrorMessage(
					StripeError[error.message as keyof typeof StripeError] ??
						'Ha ocurrido un error con los datos ingresados, por favor inténtelo nuevamente. Si el error persiste, contáctenos.'
				);
				setErrorModal(true);
			}
		} else {
			setErrorMessage(
				StripeError[error.message as keyof typeof StripeError] ??
					'Ha ocurrido un error con los datos ingresados, por favor inténtelo nuevamente. Si el error persiste, contáctenos.'
			);
			setErrorModal(true);
		}
	};

	const onSubmitHandler = async () => {
		showSpinner(true);
		await handleSubmitStripe();
		showSpinner(false);
		closeCardModal();
	};

	const handleClose = () => {
		closeCardModal();
	};

	const { isMobile } = useMobile();

	return (
		<>
			<ModalConfirmation
				modalState={errorModal}
				approveFunction={() => null}
				name={errorMessage}
				closeFunction={() => setErrorModal(false)}
				cancelLabel="Entendido"
				hideConfirmBtn
			/>
			<CustomDialog
				className="fullscreen-modal add-card-modal"
				fullScreen={isMobile}
				fullWidth
				open={modalState}
				handleClose={(_event, reason): void => {
					if (reason !== 'backdropClick') {
						handleClose();
					}
				}}
				handleAgree={() => onSubmitHandler()}
				aria-labelledby="renew-dialog-title"
				aria-describedby="renew-dialog-description"
				title={
					isMobile ? (
						<MobileDialogHeader goBack={handleClose} title="Agregar método de pago" />
					) : null
				}
				agreeText={isMobile ? 'Agregar' : undefined}
				disagreeText={isMobile ? 'Cancelar' : undefined}
			>
				<AddDialogContent className=" !px-0 !md:px-[40px]">
					<RenewHeader className="!pt-[12px] !md:pt-[40px]">
						<StyledPaymentIcon className="!pb-0 !md:pb-[16px] max-w-[80px] md:max-w-auto" />
						{!isMobile && (
							<TitleTypography variant="h5">Agregar tarjeta</TitleTypography>
						)}
						<ContainerForm>
							<div className="mt-5">
								<span>
									Número de tarjeta <RequiredStar className="md:hidden" />
								</span>
								<div
									className="py-3 px-2 font-normal OpenSansLight bg-white-lilac rounded-md border border-slate-400"
									style={{ borderColor: 'rgba(0, 0, 0, 0.23)' }}
								>
									<CardNumberElement />
								</div>
							</div>
							<div className="mt-3">
								<span>
									Fecha de vencimiento <RequiredStar className="md:hidden" />
								</span>
								<div
									className="py-3 px-2 font-normal OpenSansLight bg-white-lilac rounded-md border border-slate-400"
									style={{ borderColor: 'rgba(0, 0, 0, 0.23)' }}
								>
									<CardExpiryElement />
								</div>
							</div>
							<div className="mt-3 mb-5">
								<span>
									Código de seguridad
									<RequiredStar className="md:hidden" />
								</span>
								<div
									className="py-3 px-2 font-normal OpenSansLight bg-white-lilac rounded-md border border-slate-400"
									style={{ borderColor: 'rgba(0, 0, 0, 0.23)' }}
								>
									<CardCvcElement />
								</div>
							</div>
						</ContainerForm>
					</RenewHeader>

					{!isMobile && (
						<ActionContainer>
							<Button
								variant="contained"
								color="primary"
								type="submit"
								onClick={onSubmitHandler}
								endIcon={<ArrowForwardIcon />}
							>
								Agregar
							</Button>
							<BackButton color="primary" onClick={handleClose}>
								Cancelar
							</BackButton>
						</ActionContainer>
					)}
				</AddDialogContent>
			</CustomDialog>
		</>
	);
};

export default CheckOutForm;
