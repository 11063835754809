import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core';
import { Card, CardHeader, CardContent, CardActions, Divider } from '@material-ui/core';
import useMobile from 'hooks/useMobile';

interface Props {
	title?: string | React.ComponentType;
	subheader?: React.ReactNode;
	headerAction?: React.ReactNode;
	children: React.ReactNode;
	divider?: boolean;
	action?: React.ReactNode;
	paddingTop?: string;
	paddingBottom?: string;
	contentHeight?: string;
	hasMarginBottom?: boolean;
	overflow?: boolean;
	dataFxName?: string;
	style?: React.CSSProperties;
}
const theme = createTheme();
const StyledCardHeader = withStyles({
	root: {
		size: theme.spacing(1),
		padding: theme.spacing(2, 2),
		[theme.breakpoints.down('md')]: {
			display: 'flex',
			alignItems: 'center',
			width: '100%',
		},
		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(2, 2),
		},
		backgroundColor: theme.breakpoints.down('md') ? '#fff' : '',
	},
	title: {
		fontSize: theme.spacing(2.5),
		fontWeight: 500,
		lineHeight: 1.6,
		[theme.breakpoints.up('md')]: {
			lineHeight: 1.35,
			fontSize: theme.spacing(2.5),
		},
	},
	content: {},
	action: {
		marginTop: 0,
		marginRight: 0,
	},
})(CardHeader) as typeof CardHeader;

const StyledCardContent = withStyles({
	root: {},
})(CardContent) as typeof CardContent;

const CustomCard: React.FC<Props> = ({
	title,
	headerAction,
	subheader,
	children,
	divider,
	action,
	paddingTop,
	paddingBottom,
	contentHeight,
	hasMarginBottom = false,
	overflow = true,
	dataFxName,
	style,
}) => {
	const titleComponent = () => {
		if (typeof title === 'function') {
			return (
				<StyledCardHeader subheader={subheader} action={headerAction} component={title} />
			);
		}

		return <StyledCardHeader title={title} subheader={subheader} action={headerAction} />;
	};
	const { isMobile } = useMobile();
	return (
		<Card
			style={{
				marginBottom: hasMarginBottom ? '20px' : '0',
				...style,
				...(isMobile && { padding: 0 }),
				width: '100%',
			}}
			data-fx-name={dataFxName}
		>
			<span data-fx-name="cardTitle">{title && titleComponent()}</span>
			{divider && <Divider light />}
			<StyledCardContent
				style={{
					paddingTop: paddingTop,
					paddingBottom: paddingBottom,
					maxHeight: contentHeight,
					overflowY: overflow ? 'auto' : 'hidden',
				}}
				data-fx-name="cardContent"
			>
				{children}
			</StyledCardContent>
			{action && <CardActions>{action}</CardActions>}
		</Card>
	);
};

export default CustomCard;
