// @ts-nocheck
import React, { useContext, useState } from 'react';
import { RelationsContext } from './relations-context';
import { DoctorRecentDiagnosis } from 'models';
import { IDoctorContextProps, RelationsContextProps } from './types';
import useAPI from 'hooks/API';
import { syncDoctorRecentDiagnoses } from 'graphql/queries';

export const DoctorsContext = React.createContext<Partial<IDoctorContextProps>>({});

const ContextProvider: React.FC = (props) => {
	const { actualDoctor, handleError } = useContext(RelationsContext) as RelationsContextProps;
	const [updateDoctorPayments, setUpdateDoctorPayments] = useState(false);
	const { execute } = useAPI();

	const getAllRecentDoctorDiagnosis = async () => {
		try {
			const result = [];
			let nextToken = null;

			do {
				const listResponse = await execute(syncDoctorRecentDiagnoses, {
					filter: {
						doctorID: {
							eq: actualDoctor?.id,
						},
					},
					limit: 1000,
					nextToken,
				});

				const list = listResponse.data.syncDoctorRecentDiagnoses.items;
				result.push(...list);

				nextToken = listResponse.data.syncDoctorRecentDiagnoses.nextToken;
			} while (nextToken);

			return result;
		} catch (error) {
			handleError(error, true);
			return [];
		}
	};

	const fetchDoctorDiagnosis = async (): Promise<DoctorRecentDiagnosis[]> => {
		try {
			const list = await getAllRecentDoctorDiagnosis();

			const orderByUpdateDateTime = (a: any, b: any) => {
				const date1 = new Date(a.updateDateTime);
				const date2 = new Date(b.updateDateTime);
				return date2.getTime() - date1.getTime();
			};

			return list.sort(orderByUpdateDateTime).slice(0, 15);
		} catch (error) {
			handleError(error, true);

			return [];
		}
	};

	const shouldUpdateDoctorPayments = (state: boolean) => {
		setUpdateDoctorPayments(state);
	};

	return (
		<DoctorsContext.Provider
			value={{
				fetchDoctorDiagnosis,
				shouldUpdateDoctorPayments,
				updateDoctorPayments,
			}}
		>
			{props.children}
		</DoctorsContext.Provider>
	);
};

export default ContextProvider;
