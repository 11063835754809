import React from 'react';

const EditIcon = () => (
	<svg width="18" height="20" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M15.8687 2.40625L16.5103 1.73828C16.8443 1.38672 16.8706 0.90332 16.5366 0.569336L16.3081 0.332031C16.0181 0.0332031 15.5171 0.0947266 15.1919 0.411133L14.5327 1.06152L15.8687 2.40625ZM6.10403 11.1602L7.83548 10.4395L15.271 3.00391L13.9263 1.66797L6.49954 9.10352L5.74368 10.791C5.65579 11.0107 5.8931 11.2393 6.10403 11.1602ZM2.88724 16.8115H12.5288C14.1724 16.8115 15.1392 15.8535 15.1392 14.043V5.31543L13.3374 7.11719V13.8584C13.3374 14.6406 12.9331 15.0186 12.3794 15.0186H3.02786C2.28958 15.0186 1.88528 14.6406 1.88528 13.8584V4.71777C1.88528 3.94434 2.28958 3.56641 3.02786 3.56641H9.85696L11.6499 1.76465H2.88724C1.05911 1.76465 0.0923138 2.72266 0.0923138 4.5332V14.043C0.0923138 15.8535 1.05911 16.8115 2.88724 16.8115Z"
			fill="#80868B"
		/>
	</svg>
);

export default EditIcon;
