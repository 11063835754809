import React, { CSSProperties } from 'react';

interface CancelButtonProps {
	onClick: () => void;
	style?: CSSProperties;
	label?: string;
}

const CancelButton: React.FC<CancelButtonProps> = ({ onClick, style, label }) => {
	return (
		<button
			type="button"
			className="cancel border-2 border-greySnuff-default rounded-lg px-5 h-11 text-grayStorm-default bg-white-section raleway-font cancel-button font-medium text-base"
			onClick={() => onClick()}
			style={style}
		>
			{label || 'CANCELAR'}
		</button>
	);
};

export default CancelButton;
