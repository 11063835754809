// @ts-nocheck
import React from 'react';
import { Popover } from '@material-ui/core';
import { ClientPrescriptionAnalisis, ClientPrescriptionMedicine } from '../../../../API';
import { ConsultationType, ConsultationDiagnosticType } from '../../../context/types';
import ConsultationCard from '../../../pages/PatientConsultation/Previous/ConsultationCard';

interface Props {
	openConsultation: boolean;
	handleDetailLeaveConsultation: () => void;
	consultationCard: ConsultationType;
	previousId: string | undefined;
	anchorEl: EventTarget | null;
}

const PreviousConsultationModal: React.FC<Props> = ({
	openConsultation,
	handleDetailLeaveConsultation,
	consultationCard,
	previousId,
	anchorEl,
}) => {
	return (
		<Popover
			id={previousId}
			open={openConsultation}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'center',
				horizontal: 'left',
			}}
			transformOrigin={{
				vertical: 'center',
				horizontal: 'right',
			}}
			onClose={handleDetailLeaveConsultation}
			disableRestoreFocus
			getContentAnchorEl={null}
		>
			<ConsultationCard
				title={consultationCard.visitReason as string}
				date={consultationCard.updatedAt as string}
				description={consultationCard.visitDescription as string}
				note_physic_test={consultationCard.physicTestNote as string}
				medicines_prescripted={
					consultationCard.prescription?.medicines as ClientPrescriptionMedicine[]
				}
				labs_images={
					consultationCard.prescription?.analisis as ClientPrescriptionAnalisis[]
				}
				diagnostics={consultationCard.diagnostics as ConsultationDiagnosticType[]}
				diffDiagnostics={consultationCard.diffDiagnostics as ConsultationDiagnosticType[]}
				treatment={consultationCard.treatment as string}
				procedures_cirgury={consultationCard.procedureName as string}
				proceures_cirugy_description={consultationCard.procedureDescription as string}
			/>
		</Popover>
	);
};

export default PreviousConsultationModal;
