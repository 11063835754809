// @ts-nocheck
import React, { CSSProperties, useContext } from 'react';
import { RelationsContext } from '../context/relations-context';
import { ClientType, RelationsContextProps } from '../context/types';
import { displayAge } from '../shared/dateUtils';
import { ClaimProcess } from 'models';
import { SensaLogoBig } from '../components/svg/SenasaLogo';
import moment from 'moment';

interface HospitalPrintLayoutProps {
	client: ClientType | null;
	processes: ClaimProcess[];
	claim: { date: string; authorization: string; diagnostic: string };
}

const ARSSenasaPrintLayout: React.FC<HospitalPrintLayoutProps> = ({ client, processes, claim }) => {
	const { actualDoctor, actualHospital } = useContext(RelationsContext) as RelationsContextProps;

	const pacientInfoLabels: CSSProperties = {
		fontWeight: 400,
		fontSize: 11.25,
		fontFamily: 'Arial, Helvetica, sans-serif',
	};

	const labelValues: CSSProperties = {
		fontWeight: 'normal',
		fontFamily: 'Arial, Helvetica, sans-serif',
	};
	const borderGray: CSSProperties = {
		borderColor: '#c7c7c7',
	};

	const ProccesStyle: CSSProperties = {
		border: '1px solid black',
		borderTop: 0,
		fontFamily: 'Arial, Helvetica, sans-serif',
	};

	const ProccesStyleCentered: CSSProperties = {
		...ProccesStyle,
		textAlign: 'center',
		borderLeft: 0,
	};

	const flexRow: CSSProperties = {
		display: 'flex',
	};

	const flexCol: CSSProperties = {
		...flexRow,
		flexDirection: 'column',
	};

	const grid: CSSProperties = {
		display: 'grid',
	};

	const Process = ({ process }: any) => (
		<>
			<span style={{ ...ProccesStyle, paddingLeft: 16, gridColumn: 'span 4 / span 4' }}>
				{process.name || '-'}
			</span>
			<span style={ProccesStyleCentered}>{process.code || '-'}</span>
			<span style={ProccesStyleCentered}>{process.claimed || '-'}</span>
			<span style={ProccesStyleCentered}>{process.difference || '-'}</span>
			<span style={ProccesStyleCentered}>{process.authorized || '-'}</span>
		</>
	);

	return (
		<div
			style={{
				...flexCol,
				width: '100%',
				height: '100%',
				maxHeight: 540,
				backgroundColor: 'white',
				marginTop: 0,
				padding: 24,
				fontFamily: 'Arial, Helvetica, sans-serif',
			}}
		>
			<div style={{ ...flexRow, paddingBottom: 8 }}>
				<div className="" style={{ marginRight: 22 }}>
					<SensaLogoBig />
				</div>
				<div style={{ ...flexCol, marginRight: 48 }}>
					<div
						style={{
							fontWeight: 700,
							borderBottom: '1px solid black',
							paddingBottom: 8,
						}}
					>
						<span style={{ fontSize: 9.75 }}>SEGURO NACIONAL DE SALUD</span>
					</div>
					<div style={{ ...flexCol, paddingTop: 4 }}>
						<span style={{ fontSize: 9.75, paddingBottom: 8 }}>
							FORMULARIO DE{' '}
							<span style={{ fontWeight: 700 }}>RECLAMACIONES MÉDICAS</span>
						</span>
						<span style={{ fontSize: 9.75, fontWeight: 700 }}>
							Autorizaciones Médicas:{' '}
							<span>Tel.: 809-732-3821 | www.arssenasa.gov.do</span>
						</span>
					</div>
				</div>
				<div style={{ marginLeft: 'auto' }}>
					<div style={flexRow}>
						<div style={{ paddingRight: 4 }}>
							<span style={{ fontSize: 11.25 }}>Fecha:</span>
						</div>
						<div
							style={{
								...flexCol,
								textAlign: 'center',
								borderBottom: '1px solid black',
							}}
						>
							<span style={{ fontSize: 11.25 }}>{claim?.date}</span>
							<div
								style={{ borderBottom: 1, borderColor: '#000000', width: 140 }}
							></div>
						</div>
					</div>
				</div>
			</div>

			<div style={{ paddingBottom: 12 }}>
				<div style={flexRow}>
					<div
						style={{
							backgroundColor: '#CDCBBE',
							paddingTop: 3,
							paddingBottom: 5,
							paddingLeft: 12,
							width: 600,
							marginRight: 6,
						}}
					>
						<span style={{ fontSize: 10.5, fontWeight: 700 }}>
							DATOS DEL PRESENTADOR
						</span>
					</div>
					<div>
						<div style={flexRow}>
							<div style={{ paddingRight: 4 }}>
								<span style={{ fontSize: 11.25 }}>Código PSS:</span>
							</div>
							<div
								style={{
									...flexCol,
									borderBottom: '1px solid black',
									textAlign: 'center',
								}}
							>
								<span style={{ fontSize: 11.25 }}>
									{client?.healthInsuranceAuthCode}
								</span>
								<div
									style={{ borderBottom: 1, borderColor: '#000000', width: 100 }}
								></div>
							</div>
						</div>
					</div>
				</div>

				<div
					style={{
						...grid,
						gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
						columnGap: 8,
						rowGap: 12,
						paddingTop: 4,
					}}
				>
					<div
						style={{ ...pacientInfoLabels, ...flexRow, gridColumn: 'span 2 / span 2' }}
					>
						<span style={{ width: 120 }}>Nombre del PSS:</span>
						<span
							style={{
								...labelValues,
								marginTop: -2,
								paddingBottom: 2,
								width: '100%',
								borderBottom: '1px solid black',
							}}
						>
							{actualHospital?.name}
						</span>
					</div>
					<div style={{ ...pacientInfoLabels, ...flexRow }}>
						<span style={{ width: 245 }}>No. de Autorización:</span>
						<span
							style={{
								...labelValues,
								marginTop: -2,
								paddingBottom: 2,
								width: '100%',
								borderBottom: '1px solid black',
							}}
						>
							{claim?.authorization}
						</span>
					</div>
					<div style={{ ...pacientInfoLabels, ...flexRow }}>
						<span style={{ width: 30 }}>NAF:</span>
						<span
							style={{
								...labelValues,
								marginTop: -2,
								paddingBottom: 2,
								width: '100%',
								borderBottom: '1px solid black',
							}}
						></span>
					</div>
					<div
						style={{ ...pacientInfoLabels, ...flexRow, gridColumn: 'span 2 / span 2' }}
					>
						<span style={{ width: 120 }}>Médico Tratante:</span>
						<span
							style={{
								...labelValues,
								marginTop: -2,
								paddingBottom: 2,
								width: '100%',
								borderBottom: '1px solid black',
							}}
							className="w-full border-b"
						>
							{actualDoctor?.name}
						</span>
					</div>
					<div style={{ ...pacientInfoLabels, ...flexRow }}>
						<span style={{ width: 110 }}>Especialidad:</span>
						<span
							style={{
								...labelValues,
								marginTop: -2,
								paddingBottom: 2,
								width: '100%',
								borderBottom: '1px solid black',
							}}
						>
							{actualDoctor?.specialty}
						</span>
					</div>
					<div style={{ ...pacientInfoLabels, ...flexRow }}>
						<span style={{ width: 70 }}>Teléfono:</span>
						<span
							style={{
								...labelValues,
								marginTop: -2,
								paddingBottom: 2,
								width: '100%',
								borderBottom: '1px solid black',
							}}
						>
							{actualDoctor?.phone}
						</span>
					</div>
				</div>
			</div>

			<div style={{ paddingBottom: 12 }}>
				<div
					style={{
						backgroundColor: '#CDCBBE',
						paddingTop: 3,
						paddingBottom: 5,
						paddingLeft: 12,
					}}
				>
					<span style={{ fontSize: 10.5, fontWeight: 700 }}>DATOS DEL AFILIADO</span>
				</div>
				<div
					style={{
						...grid,
						gridTemplateColumns: 'repeat(12, minmax(0, 1fr))',
						columnGap: 8,
						rowGap: 12,
						paddingTop: 8,
					}}
				>
					<div
						style={{ ...pacientInfoLabels, ...flexRow, gridColumn: 'span 4 / span 4' }}
					>
						<span style={{ width: 60 }}>Nombre:</span>
						<span
							style={{
								...labelValues,
								marginTop: -2,
								paddingBottom: 2,
								width: '100%',
								borderBottom: '1px solid black',
							}}
						>{`${client?.name} ${client?.lastName}`}</span>
					</div>
					<div style={{ ...pacientInfoLabels, ...flexRow }}>
						<span style={{ width: 70 }}>Edad:</span>
						<span
							style={{
								...labelValues,
								marginTop: -2,
								paddingBottom: 2,
								width: '100%',
								borderBottom: '1px solid black',
							}}
						>
							{displayAge(moment(client.bornDate))}
						</span>
					</div>
					<div
						style={{ ...pacientInfoLabels, ...flexRow, gridColumn: 'span 2 / span 2' }}
					>
						<span style={{ width: 45 }}>Sexo:</span>
						<span
							style={{
								...labelValues,
								marginTop: -2,
								paddingBottom: 2,
								width: '100%',
								borderBottom: '1px solid black',
							}}
						>
							{client?.gender}
						</span>
					</div>
					<div
						style={{ ...pacientInfoLabels, ...flexRow, gridColumn: 'span 2 / span 2' }}
					>
						<span style={{ width: 40 }}>NSS:</span>
						<span
							style={{
								...labelValues,
								marginTop: -2,
								paddingBottom: 2,
								width: '100%',
								borderBottom: '1px solid black',
							}}
						>
							{client?.affiliateNumber}
						</span>
					</div>
					<div
						style={{ ...pacientInfoLabels, ...flexRow, gridColumn: 'span 3 / span 3' }}
					>
						<span style={{ width: 55 }}>Cedula:</span>
						<span
							style={{
								...labelValues,
								marginTop: -2,
								paddingBottom: 2,
								width: '100%',
								borderBottom: '1px solid black',
							}}
						>
							{client?.identificationData}
						</span>
					</div>
					<div
						style={{ ...pacientInfoLabels, ...flexRow, gridColumn: 'span 4 / span 4' }}
					>
						<span style={{ width: 62 }}>Teléfono:</span>
						<span
							style={{
								...labelValues,
								marginTop: -2,
								paddingBottom: 2,
								width: '100%',
								borderBottom: '1px solid black',
							}}
						>
							{client?.phoneNumber}
						</span>
					</div>
					<div
						style={{ ...pacientInfoLabels, ...flexRow, gridColumn: 'span 4 / span 4' }}
					>
						<span style={{ width: 50 }}>Celular:</span>
						<span
							style={{
								...labelValues,
								marginTop: -2,
								paddingBottom: 2,
								width: '100%',
								borderBottom: '1px solid black',
							}}
						>
							{client?.cellphoneNumber}
						</span>
					</div>
					<div
						style={{ ...pacientInfoLabels, ...flexRow, gridColumn: 'span 4 / span 4' }}
					>
						<span style={{ width: 165 }}>Correo Electrónico:</span>
						<span
							style={{
								...labelValues,
								marginTop: -2,
								paddingBottom: 2,
								width: '100%',
								borderBottom: '1px solid black',
							}}
						>
							{client?.email}
						</span>
					</div>
					<div
						style={{ ...pacientInfoLabels, ...flexRow, gridColumn: 'span 6 / span 6' }}
					>
						<span style={{ width: 62 }}>Dirección:</span>
						<span
							style={{
								...labelValues,
								marginTop: -2,
								paddingBottom: 2,
								...flexRow,
								width: '100%',
								gridColumn: 'span 3 / span 3',
								borderBottom: '1px solid black',
							}}
						>
							{client?.addressStreet}
						</span>
					</div>
					<div
						style={{ ...pacientInfoLabels, ...flexRow, gridColumn: 'span 3 / span 3' }}
					>
						<span style={{ width: 50 }}>Sector:</span>
						<span
							style={{
								...labelValues,
								marginTop: -2,
								paddingBottom: 2,
								width: '100%',
								borderBottom: '1px solid black',
							}}
						>
							{client?.sector}
						</span>
					</div>
					<div
						style={{ ...pacientInfoLabels, ...flexRow, gridColumn: 'span 3 / span 3' }}
					>
						<span style={{ width: 55 }}>Ciudad:</span>
						<span
							style={{
								...labelValues,
								marginTop: -2,
								paddingBottom: 2,
								width: '100%',
								borderBottom: '1px solid black',
							}}
						>
							{client?.city}
						</span>
					</div>
				</div>
			</div>

			<div style={{ paddingBottom: 12 }}>
				<div
					style={{
						backgroundColor: '#CDCBBE',
						paddingTop: 3,
						paddingBottom: 5,
						paddingLeft: 12,
					}}
				>
					<span style={{ fontSize: 10.5, fontWeight: 700 }}>DATOS DEL SERVICIO</span>
				</div>
				<div
					style={{
						...grid,
						gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
						columnGap: 8,
						rowGap: 12,
						paddingTop: 8,
					}}
				>
					<div style={{ ...pacientInfoLabels, ...flexRow }}>
						<span style={{ width: 100 }}>Tipo de Servicio:</span>
						<span
							style={{
								...labelValues,
								marginTop: -2,
								paddingBottom: 2,
								width: '100%',
								borderBottom: '1px solid black',
							}}
						></span>
					</div>
					<div style={{ ...pacientInfoLabels, ...flexRow }}>
						<span style={{ width: 75 }}>Diagnóstico:</span>
						<span
							style={{
								...labelValues,
								marginTop: -2,
								paddingBottom: 2,
								width: '100%',
								borderBottom: '1px solid black',
							}}
						>
							{claim?.diagnostic}
						</span>
					</div>
				</div>
				<div
					style={{
						fontSize: 9.75,
						...grid,
						gridTemplateColumns: 'repeat(8, minmax(0, 1fr))',
						marginTop: 12,
					}}
				>
					<span
						style={{
							paddingLeft: 4,
							gridColumn: 'span 4 / span 4',
							border: '1px solid black',
						}}
					>
						Detalles del Servicio
					</span>
					<span
						style={{
							border: '1px solid black',
							borderLeft: 0,
							textAlign: 'center',
						}}
					>
						Valor Total
					</span>
					<span
						style={{
							border: '1px solid black',
							borderLeft: 0,
							textAlign: 'center',
						}}
					>
						No Procede
					</span>
					<span
						style={{
							border: '1px solid black',
							borderLeft: 0,
							textAlign: 'center',
						}}
					>
						Valor Reclamado
					</span>
					<span
						style={{
							border: '1px solid black',
							borderLeft: 0,
							textAlign: 'center',
						}}
					>
						Copado
					</span>
					{processes.length ? (
						processes.map((process: any) => (
							<Process key={process.id} process={process} />
						))
					) : (
						<Process
							process={{
								name: '',
								code: '',
								claimed: '',
								difference: '',
								authorized: '',
							}}
						/>
					)}
				</div>
			</div>

			<div
				style={{
					...grid,
					marginTop: 30,
					gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
					columnGap: 144,
					marginBottom: 12,
				}}
			>
				<div
					style={{
						...pacientInfoLabels,
						...borderGray,
						borderTop: '1px solid black',
						paddingTop: 4,
						textAlign: 'center',
						fontWeight: 500,
					}}
				>
					<span>Firma y Sello del Reclamante</span>
				</div>
				<div
					style={{
						...pacientInfoLabels,
						...borderGray,
						borderTop: '1px solid black',
						paddingTop: 4,
						textAlign: 'center',
						fontWeight: 500,
					}}
				>
					<span>Firma del Paciente / Responsable</span>
				</div>
			</div>
		</div>
	);
};

export default ARSSenasaPrintLayout;
