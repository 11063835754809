import React, { useContext, FC } from 'react';
import { Button, Grid, IconButton, List, ListItemText, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import CustomCard from '../../../card/CustomCard';
import {
	ButtonContainer,
	EmptyStateText,
	FamilyHistoryContainer,
	HistoryListItem,
} from '../../../../shared/style';
import { HistoryContext, HistoryContextProps } from '../../../../context/history-context';
import { HistorySidebarOptions } from '../../../../context/types';
import { ClientFamilyHistory, HistoryFamilyMember } from 'models';
import FamilySkeleton from './historySkeletons/FamilyHistory.Skeleton';
import useMobile from 'hooks/useMobile';

const FamilyHistoryCard: FC<{
	familyHistories: any;
	loading: boolean;
}> = ({ familyHistories, loading }) => {
	const { isMobile } = useMobile();
	const { setCurrentSidebarTab } = useContext(HistoryContext) as HistoryContextProps;
	const getSelectedClientByFamilyMember = (familyMember: HistoryFamilyMember) => {
		return familyHistories?.find((cfm: any) => cfm.familyMember === familyMember);
	};

	const clientFamilyFatherMember = getSelectedClientByFamilyMember(HistoryFamilyMember.FATHER);
	const clientFamilyMotherMember = getSelectedClientByFamilyMember(HistoryFamilyMember.MOTHER);
	const clientFamilyBrotherMember = getSelectedClientByFamilyMember(HistoryFamilyMember.BROTHERS);
	const clientFamilyGrandparentMember = getSelectedClientByFamilyMember(
		HistoryFamilyMember.GRANDPARENTS
	);
	const clientFamilyGreatGrandparentMember = getSelectedClientByFamilyMember(
		HistoryFamilyMember.GREATGRANDPARENTS
	);
	const clientFamilySonMember = getSelectedClientByFamilyMember(HistoryFamilyMember.SONS);

	const getRenderDiseaseName = (clientFamilyMember: ClientFamilyHistory) => {
		return clientFamilyMember.mellitusDiabetes ||
			clientFamilyMember.arterialHypertension ||
			clientFamilyMember.heartDisease ||
			clientFamilyMember.nephropathy ||
			clientFamilyMember.neuropathies ||
			clientFamilyMember.epilepsy ||
			clientFamilyMember.rheumatoidArthritis ||
			clientFamilyMember.psychiatricDiseases ||
			clientFamilyMember.cancer ||
			clientFamilyMember.other ? (
			<>
				{clientFamilyMember.mellitusDiabetes && (
					<>
						<Typography component="span" variant="body2">
							Diabetes mellitus
							{clientFamilyMember.mellitusDiabetesNote
								? ` - ${clientFamilyMember.mellitusDiabetesNote}`
								: ''}
						</Typography>
						<br />
					</>
				)}
				{clientFamilyMember.arterialHypertension && (
					<>
						<Typography component="span" variant="body2">
							Hipertensión arterial
							{clientFamilyMember.arterialHypertensionNote
								? ` - ${clientFamilyMember.arterialHypertensionNote}`
								: ''}
						</Typography>
						<br />
					</>
				)}
				{clientFamilyMember.heartDisease && (
					<>
						<Typography component="span" variant="body2">
							Cardiopatías
							{clientFamilyMember.heartDiseaseNote
								? ` - ${clientFamilyMember.heartDiseaseNote}`
								: ''}
						</Typography>
						<br />
					</>
				)}
				{clientFamilyMember.nephropathy && (
					<>
						<Typography component="span" variant="body2">
							Nefropatías
							{clientFamilyMember.nephropathyNote
								? ` - ${clientFamilyMember.nephropathyNote}`
								: ''}
						</Typography>
						<br />
					</>
				)}
				{clientFamilyMember.neuropathies && (
					<>
						<Typography component="span" variant="body2">
							Neuropatías
							{clientFamilyMember.neuropathiesNote
								? ` - ${clientFamilyMember.neuropathiesNote}`
								: ''}
						</Typography>
						<br />
					</>
				)}
				{clientFamilyMember.epilepsy && (
					<>
						<Typography component="span" variant="body2">
							Epilepsia
							{clientFamilyMember.epilepsyNote
								? ` - ${clientFamilyMember.epilepsyNote}`
								: ''}
						</Typography>
						<br />
					</>
				)}
				{clientFamilyMember.rheumatoidArthritis && (
					<>
						<Typography component="span" variant="body2">
							Artritis reumatoide
							{clientFamilyMember.rheumatoidArthritisNote
								? ` - ${clientFamilyMember.rheumatoidArthritisNote}`
								: ''}
						</Typography>
						<br />
					</>
				)}
				{clientFamilyMember.psychiatricDiseases && (
					<>
						<Typography component="span" variant="body2">
							Enfermedades psiquiátricas
							{clientFamilyMember.psychiatricDiseasesNote
								? ` - ${clientFamilyMember.psychiatricDiseasesNote}`
								: ''}
						</Typography>
						<br />
					</>
				)}
				{clientFamilyMember.cancer && (
					<>
						<Typography component="span" variant="body2">
							Cáncer
							{clientFamilyMember.cancerNote
								? ` - ${clientFamilyMember.cancerNote}`
								: ''}
						</Typography>
						<br />
					</>
				)}
			</>
		) : null;
	};

	const hasFamilyHistories =
		familyHistories &&
		((clientFamilyFatherMember && getRenderDiseaseName(clientFamilyFatherMember)) ||
			(clientFamilyMotherMember && getRenderDiseaseName(clientFamilyMotherMember)) ||
			(clientFamilyBrotherMember && getRenderDiseaseName(clientFamilyBrotherMember)) ||
			(clientFamilyGrandparentMember &&
				getRenderDiseaseName(clientFamilyGrandparentMember)) ||
			(clientFamilySonMember && getRenderDiseaseName(clientFamilySonMember)));

	return (
		<FamilyHistoryContainer>
			<CustomCard
				title="Antecedentes familiares"
				divider
				paddingTop={isMobile ? '5px' : '0px'}
				paddingBottom={isMobile ? '20px' : '0px'}
				headerAction={
					hasFamilyHistories && (
						<IconButton
							aria-label="edit"
							onClick={() =>
								setCurrentSidebarTab(HistorySidebarOptions.FAMILY_HISTORY)
							}
						>
							<EditIcon />
						</IconButton>
					)
				}
			>
				<Grid container direction="column">
					{hasFamilyHistories ? (
						<List
							dense
							disablePadding
							component="nav"
							aria-label="secondary mailbox folder"
						>
							{clientFamilyFatherMember &&
								getRenderDiseaseName(clientFamilyFatherMember) && (
									<HistoryListItem
										divider={clientFamilyMotherMember}
										disableGutters={isMobile}
									>
										<ListItemText
											primary={getRenderDiseaseName(clientFamilyFatherMember)}
											secondary="Padre"
										/>
									</HistoryListItem>
								)}
							{clientFamilyMotherMember &&
								getRenderDiseaseName(clientFamilyMotherMember) && (
									<HistoryListItem
										divider={clientFamilyBrotherMember}
										disableGutters={isMobile}
									>
										<ListItemText
											primary={getRenderDiseaseName(clientFamilyMotherMember)}
											secondary="Madre"
										/>
									</HistoryListItem>
								)}
							{clientFamilyBrotherMember &&
								getRenderDiseaseName(clientFamilyBrotherMember) && (
									<HistoryListItem
										divider={clientFamilyGrandparentMember}
										disableGutters={isMobile}
									>
										<ListItemText
											primary={getRenderDiseaseName(
												clientFamilyBrotherMember
											)}
											secondary="Hermanos"
										/>
									</HistoryListItem>
								)}
							{clientFamilyGrandparentMember &&
								getRenderDiseaseName(clientFamilyGrandparentMember) && (
									<HistoryListItem
										divider={clientFamilyGreatGrandparentMember}
										disableGutters={isMobile}
									>
										<ListItemText
											primary={getRenderDiseaseName(
												clientFamilyGrandparentMember
											)}
											secondary="Abuelos"
										/>
									</HistoryListItem>
								)}
							{clientFamilyGreatGrandparentMember &&
								getRenderDiseaseName(clientFamilyGreatGrandparentMember) && (
									<HistoryListItem
										divider={clientFamilySonMember}
										disableGutters={isMobile}
									>
										<ListItemText
											primary={getRenderDiseaseName(
												clientFamilyGreatGrandparentMember
											)}
											secondary="Bisabuelos"
										/>
									</HistoryListItem>
								)}
							{clientFamilySonMember &&
								getRenderDiseaseName(clientFamilySonMember) && (
									<HistoryListItem divider disableGutters={isMobile}>
										<ListItemText
											primary={getRenderDiseaseName(clientFamilySonMember)}
											secondary="Hijos"
										/>
									</HistoryListItem>
								)}
						</List>
					) : loading ? (
						<FamilySkeleton
							style={{
								marginTop: '16px',
							}}
						/>
					) : (
						<>
							<EmptyStateText variant="body2">
								No hay datos de familiares
							</EmptyStateText>
							<ButtonContainer>
								<Button
									startIcon={<AddIcon />}
									variant="text"
									color="primary"
									size="small"
									onClick={() =>
										setCurrentSidebarTab(HistorySidebarOptions.FAMILY_HISTORY)
									}
								>
									Actualizar Historial Familiar
								</Button>
							</ButtonContainer>
						</>
					)}
				</Grid>
			</CustomCard>
		</FamilyHistoryContainer>
	);
};

export default FamilyHistoryCard;
