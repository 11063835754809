import { Button, Grid, List, ListItemText, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import React, { useContext, FC } from 'react';
import {
	SubtitleContainer,
	FamilyHistoryContainer,
	EditIconButton,
	HistoryListItem,
	ButtonContainer,
} from '../../../../shared/style';
import CustomCard from '../../../card/CustomCard';
import { HistoryContext, HistoryContextProps } from '../../../../context/history-context';
import { HistorySidebarOptions } from '../../../../context/types';
import { LifeStyle } from 'models';
import moment from 'moment';
import FamilySkeleton from './historySkeletons/FamilyHistory.Skeleton';
import useMobile from 'hooks/useMobile';

const NonPathologicalHistoryCard: FC<{
	lifeStyles: any;
	loading: boolean;
}> = ({ lifeStyles, loading }) => {
	const { setCurrentSidebarTab } = useContext(HistoryContext) as HistoryContextProps;
	const hasLifeStyle = lifeStyles && lifeStyles.length > 0;
	const { isMobile } = useMobile();
	const getClientLifeStyle = (lifeStyle: LifeStyle) => {
		return lifeStyles?.find((ls: any) => ls.lifeStyle === lifeStyle);
	};

	const getColorAndText = (lifeStyle: LifeStyle) => {
		let text = 'Uso de alcohol:';
		let color = '#455A64';
		if (lifeStyle === LifeStyle.TABACO) {
			text = 'Uso de tabaco:';
			color = '#827717';
		}
		if (lifeStyle === LifeStyle.ACTIVITY) {
			text = 'Actividad física:';
			color = '#9C27B0';
		}
		if (lifeStyle === LifeStyle.DRUG) {
			text = 'Otras drogas recreativas:';
			color = 'rgba(0, 0, 0, 0.6)';
		}

		return { text, color };
	};

	const getItemByLifeStyle = (lifeStyle: LifeStyle) => {
		const { text, color } = getColorAndText(lifeStyle);

		return (
			<HistoryListItem selected={false} divider={true} disableGutters>
				<ListItemText
					primary={
						<div>
							<p style={{ color: color, fontWeight: 500, fontSize: '14px' }}>
								{text}
							</p>
							<p>
								{getClientLifeStyle(lifeStyle)?.name}
								{getClientLifeStyle(lifeStyle)?.description &&
									` - ${getClientLifeStyle(lifeStyle)?.description}`}
							</p>
						</div>
					}
					secondary={`Ultima edición: ${moment(
						getClientLifeStyle(lifeStyle)?.updatedAt
					).format('DD/MM/YYYY')}`}
				/>
			</HistoryListItem>
		);
	};

	const getEmptyItemByLifeStyle = (lifeStyle: LifeStyle) => {
		const { text, color } = getColorAndText(lifeStyle);
		return (
			<HistoryListItem selected={false} divider={lifeStyle !== LifeStyle.DRUG} disableGutters>
				{loading ? (
					<ListItemText primary={<FamilySkeleton />} />
				) : (
					<ListItemText
						primary={
							<div>
								<p style={{ color: color, fontWeight: 500, fontSize: '14px' }}>
									{text}
								</p>
								<p>No hay datos agregados</p>
							</div>
						}
					/>
				)}
			</HistoryListItem>
		);
	};

	return (
		<FamilyHistoryContainer>
			<CustomCard
				title="Antecedentes no Patológicos"
				divider
				paddingBottom={isMobile ? '20px' : '0px'}
				hasMarginBottom
			>
				<Grid
					container
					direction="column"
					style={{
						padding: '0px',
					}}
				>
					<SubtitleContainer
						style={{
							...(isMobile && { height: '30px' }),
						}}
						container
						direction="row"
						alignItems="center"
						justifyContent="space-between"
					>
						<Typography variant="subtitle2">Estilo de vida</Typography>
						{hasLifeStyle && (
							<EditIconButton
								aria-label="edit"
								onClick={() =>
									setCurrentSidebarTab(HistorySidebarOptions.LIFE_STYLE)
								}
							>
								<EditIcon />
							</EditIconButton>
						)}
					</SubtitleContainer>
					{hasLifeStyle ? (
						<>
							<List
								dense
								disablePadding
								component="nav"
								aria-label="secondary lifestyle folder"
							>
								{getClientLifeStyle(LifeStyle.ALCOHOL)
									? getItemByLifeStyle(LifeStyle.ALCOHOL)
									: getEmptyItemByLifeStyle(LifeStyle.ALCOHOL)}
								{getClientLifeStyle(LifeStyle.TABACO)
									? getItemByLifeStyle(LifeStyle.TABACO)
									: getEmptyItemByLifeStyle(LifeStyle.TABACO)}
								{getClientLifeStyle(LifeStyle.ACTIVITY)
									? getItemByLifeStyle(LifeStyle.ACTIVITY)
									: getEmptyItemByLifeStyle(LifeStyle.ACTIVITY)}
								{getClientLifeStyle(LifeStyle.DRUG)
									? getItemByLifeStyle(LifeStyle.DRUG)
									: getEmptyItemByLifeStyle(LifeStyle.DRUG)}
							</List>
						</>
					) : (
						<>
							<List
								style={{ paddingLeft: '0px', paddingRight: '0px' }}
								dense
								disablePadding
								component="nav"
								aria-label="secondary lifestyle folder"
							>
								{getEmptyItemByLifeStyle(LifeStyle.ALCOHOL)}
								{getEmptyItemByLifeStyle(LifeStyle.TABACO)}
								{getEmptyItemByLifeStyle(LifeStyle.ACTIVITY)}
								{getEmptyItemByLifeStyle(LifeStyle.DRUG)}
							</List>
							<ButtonContainer>
								<Button
									startIcon={<AddIcon />}
									variant="text"
									color="primary"
									size="small"
									onClick={() =>
										setCurrentSidebarTab(HistorySidebarOptions.LIFE_STYLE)
									}
								>
									Actualizar Estilo de Vida
								</Button>
							</ButtonContainer>
						</>
					)}
				</Grid>
			</CustomCard>
		</FamilyHistoryContainer>
	);
};

export default NonPathologicalHistoryCard;
