import React, { useContext, useRef } from 'react';
import { Button, Divider, Grid } from '@material-ui/core';
import CustomMenu from '../../../../menu/CustomMenu';
import { LabImages, Medicine } from '../../../../../shared/type';
import {
	HeaderListItem,
	PrescriptionList,
	HeaderActionGrid,
	HeaderGrid,
	FooterListItem,
	MedicineHeaderGrid,
} from '../../../../../shared/style';
import PrintIcon from '@material-ui/icons/Print';
import MedicineBody from './MedicineBody';
import ReactToPrint from 'react-to-print';
import PrescriptionPrintLayout from '../../../../../printLayout/Prescription';
import { ClientsContext } from '../../../../../context/client-context';
import { ClientContextProps } from '../../../../../context/types';
import moment from 'moment';
import useMobile from 'hooks/useMobile';

interface OptionMenu {
	id: number;
	label: string;
	onClick: () => void;
}

interface Props {
	handleMouseLeaveMedicine: () => void;
	optionsForMenu: OptionMenu[];
	medicines: Medicine[];
	handleMouseOverMedicine: (id: number | string) => void;
	handleDeleteMedicine: (id: number | string) => void;
	handleFocusMedicine: (e: any, id: number | string) => void;
	handleUnFocuseMedicine: (e: any, id: number | string) => void;
	handleChangeMedicine: (id: number | string, label: string, value: any) => void;
	headerMenu: null | HTMLElement;
	handleClick: (event: React.MouseEvent<HTMLElement>) => void;
	handleClose: () => void;
	height?: string;
}

const MedicineList: React.FC<Props> = ({
	handleMouseLeaveMedicine,
	optionsForMenu,
	medicines,
	handleMouseOverMedicine,
	handleDeleteMedicine,
	handleFocusMedicine,
	handleUnFocuseMedicine,
	handleChangeMedicine,
	headerMenu,
	handleClick,
	handleClose,
	height,
}) => {
	const { selectedClient } = useContext(ClientsContext) as ClientContextProps;
	const printRef = useRef(null);
	const { isMobile } = useMobile();
	return (
		<div
			onMouseLeave={handleMouseLeaveMedicine}
			style={{
				border: '1px solid rgba(0, 0, 0, 0.12)',
			}}
		>
			{!isMobile && (
				<HeaderListItem component="nav" aria-label="secondary mailbox folders">
					<Grid container>
						<MedicineHeaderGrid item>Medicamento</MedicineHeaderGrid>
						<HeaderGrid item>Tomar</HeaderGrid>
						<HeaderGrid item>Indicación</HeaderGrid>
						<HeaderGrid item>Duración</HeaderGrid>
						<HeaderActionGrid item>
							<CustomMenu
								options={optionsForMenu}
								headerMenu={headerMenu}
								handleClick={handleClick}
								handleClose={handleClose}
							/>
						</HeaderActionGrid>
					</Grid>
				</HeaderListItem>
			)}
			<PrescriptionList
				style={{
					maxHeight: height ? `calc(${height} + 140px )` : 'calc(100dvh - 100px)',
				}}
			>
				{medicines &&
					medicines.length > 0 &&
					medicines.map((m, i) => {
						return (
							<MedicineBody
								key={i}
								medicine={m}
								handleMouseOverMedicine={handleMouseOverMedicine}
								handleDeleteMedicine={handleDeleteMedicine}
								handleFocusMedicine={handleFocusMedicine}
								handleUnFocuseMedicine={handleUnFocuseMedicine}
								handleChangeMedicine={handleChangeMedicine}
							/>
						);
					})}
				<Divider />
			</PrescriptionList>
			<FooterListItem>
				<ReactToPrint
					removeAfterPrint
					trigger={() => (
						<Button color="primary" startIcon={<PrintIcon />}>
							Imprimir
						</Button>
					)}
					content={() => printRef.current}
				/>
				<div ref={printRef} className="printLayout">
					<PrescriptionPrintLayout
						client={selectedClient!}
						prescription={{
							creationDate: moment(new Date()).format('DD/MM/YYYY'),
							medicines: medicines,
							analisis: [] as LabImages[],
						}}
					/>
				</div>
			</FooterListItem>
		</div>
	);
};

export default MedicineList;
