import styled from 'styled-components';
import { createTheme, Grid, List, ListItem, ListItemText } from '@material-ui/core';

const theme = createTheme();

export const Container = styled(Grid)`
	height: calc(100dvh - 132px);
	overflow-y: auto;
	padding: ${theme.spacing(3)};
` as typeof Grid;
export const SideBarSection = styled(Grid)`
	display: flex;
	justify-content: space-around;
` as typeof Grid;
export const SidebarQueryList = styled(List)`
	position: fixed;
	padding: ${theme.spacing(0, 1)};
` as typeof List;
export const ListItemLink = styled(ListItem)`
	font-size: 14px;
	&:active,
	&:hover {
		background-color: rgba(25, 118, 210, 0.08);
	}
` as typeof ListItem;
export const StyledListItemText = styled(ListItemText)`
	& span {
		font-size: 14px;
	}
` as typeof ListItemText;
