import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { Tooltip } from '@material-ui/core';
import { GridLeft } from '../../shared/style';
import './SplitButton.style.css';

interface Option {
	id: number;
	label: string;
	image: string | ((props: Props) => JSX.Element);
}

interface Props {
	isDisabled?: boolean;
	value: number;
	setValue: (value: number) => void;
	options: Option[];
}

const SplitButton: FC<Props> = ({ value, setValue, options, isDisabled }) => {
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef<HTMLDivElement>(null);
	const handleMenuItemClick = (
		_event: React.MouseEvent<HTMLLIElement, MouseEvent>,
		id: number
	) => {
		setValue(id);
		setOpen(false);
	};

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
		if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
			return;
		}

		setOpen(false);
	};

	const btnFormattedLabel = (label: string) => {
		return label.length > 15 ? `${label.slice(0, 15)}...` : label;
	};

	const tooltipMessage = (label: string) => {
		return label.length > 15 ? label : '';
	};

	return (
		<GridLeft>
			<Grid item xs={12} className="relative">
				<ButtonGroup
					variant="contained"
					color="default"
					ref={anchorRef}
					aria-label="split button"
					className="split-button-group"
				>
					<Tooltip title={tooltipMessage(options.find((o) => o.id === value)!.label)}>
						<Button
							disabled={isDisabled}
							className="split-button-actual md:max-w-[180px]"
						>
							{btnFormattedLabel(options.find((o) => o.id === value)!.label)}
						</Button>
					</Tooltip>
					<Button
						color="default"
						size="small"
						disabled={isDisabled}
						aria-controls={open ? 'split-button-menu' : undefined}
						aria-expanded={open ? 'true' : undefined}
						aria-label="select merge strategy"
						aria-haspopup="menu"
						onClick={handleToggle}
					>
						<ArrowDropDownIcon />
					</Button>
				</ButtonGroup>
				<Popper
					open={open}
					anchorEl={anchorRef.current}
					role={undefined}
					transition
					style={{
						zIndex: 8888,
						left: '10px',
						boxShadow:
							'0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.20)',
					}}
					disablePortal
					className="h-[220px] max-md:w-full split-button-popper !md:w-[354px] overflow-y-scroll rounded-[4px] bg-['#FFFFFF'] left"
				>
					{({ TransitionProps, placement }) => (
						<Grow
							{...TransitionProps}
							style={{
								transformOrigin:
									placement === 'bottom' ? 'center top' : 'center bottom',
							}}
						>
							<Paper>
								<ClickAwayListener onClickAway={handleClose}>
									<MenuList id="split-button-menu">
										{options.map((option) => (
											<MenuItem
												key={option.id}
												selected={option.id === value}
												onClick={(event) =>
													handleMenuItemClick(event, option.id)
												}
											>
												{option.label.toUpperCase()}
											</MenuItem>
										))}
									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
			</Grid>
		</GridLeft>
	);
};
export default SplitButton;
