import { useHistory, useLocation } from 'react-router-dom';
import { ActionHeader, StyledSearchIcon } from 'shared/style';
import MobileActionsButton from 'components/mobileActionsButton/MobileActionsButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { CardHeader, createTheme, withStyles, Button, IconButton } from '@material-ui/core';
import { ClientsContext } from 'context/client-context';
import { useContext, useState } from 'react';
import { ClientContextProps } from 'context/types';
import { ModalAppointment } from 'components/appointment/components/ModalAppointment';
import moment from 'moment';
import { IAppointment } from 'shared/type';
import GlobalSearchPatientBar from 'components/sidebar/GlobalSearchPatientBar';

const theme = createTheme();

const StyledCardHeader = withStyles({
	root: {
		padding: theme.spacing(1.5, 2),
	},
	title: {
		fontSize: theme.spacing(2.5),
		fontWeight: 500,
	},
	action: {
		marginTop: 0,
		marginRight: 0,
	},
})(CardHeader) as typeof CardHeader;

const MobileCardHeader = () => {
	const { pathname } = useLocation();
	const { push } = useHistory();
	const [isModalOpen, setModalOpen] = useState(false);
	const appointment: IAppointment = {
		client: null,
		description: '',
		date: moment(),
		start: null,
		end: null,
	};
	const [showSearchMobile, toggleSearchMobile] = useState(false);

	const { setModalClientState, setEditingClient } = useContext(
		ClientsContext
	) as ClientContextProps;

	const handleModalClientState = (editing: boolean) => {
		setEditingClient(editing);
		setModalClientState(true);
	};

	const closeModal = () => {
		setModalOpen(false);
	};
	const actions = [
		{
			name: 'Crear Paciente',
			onClick: () => handleModalClientState(false),
		},
		{
			name: 'Agendar cita',
			onClick: () => setModalOpen(true),
		},
	];

	return (
		<>
			<ModalAppointment
				modalState={isModalOpen}
				closeModal={closeModal}
				isUpdating={false}
				initialValues={appointment}
			/>
			{!showSearchMobile && (
				<StyledCardHeader
					title="Ajustes"
					className="card-header"
					action={
						<ActionHeader>
							<>
								<button
									style={{
										minWidth: '36px',
									}}
									onClick={() => {
										toggleSearchMobile(true);
									}}
								>
									<StyledSearchIcon />
								</button>

								<MobileActionsButton actions={actions} />
							</>
						</ActionHeader>
					}
				/>
			)}
			{showSearchMobile && (
				<StyledCardHeader
					className="header-search-mobile"
					title={
						<IconButton onClick={() => toggleSearchMobile(false)}>
							<ArrowBackIcon />
						</IconButton>
					}
					action={
						<ActionHeader>
							<GlobalSearchPatientBar />
						</ActionHeader>
					}
				/>
			)}
			<div className="tab-links-container">
				{[
					{
						id: 'profile',
						label: 'Perfil',
						path: '/Configuration/Profile',
					},
					{
						id: 'insitutions',
						label: 'Institución',

						path: '/Configuration/Institution',
					},
					{
						id: 'subscription',
						label: 'Suscripción',

						path: '/configuration/Subscription',
					},
				].map((link) => (
					<Button
						key={link.id}
						onClick={() => push(link.path)}
						className={`tab-link ${
							pathname === link.path || pathname === '/Configuration' ? 'active' : ''
						}`}
					>
						{link.label}
					</Button>
				))}
			</div>
		</>
	);
};

export default MobileCardHeader;
