import { Box, Grid, InputAdornment, TextField } from '@material-ui/core';
import React, { useEffect } from 'react';
import CustomCard from '../../../card/CustomCard';
import { InputContainer, SecondContainer } from '../../../../shared/style';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
	EstaturaIcon,
	FrecuenciaIcon,
	FrecuenciaREIcon,
	MasaIcon,
	PesoIcon,
	SaturacionIcon,
	TemperatureIcon,
	TensionIcon,
} from 'components/svg';
import {
	FieldErrors,
	FieldValues,
	UseFormRegister,
	UseFormSetError,
	UseFormSetValue,
	UseFormWatch,
} from 'react-hook-form/dist/types';
import { VitalSigns } from 'pages/PatientConsultation/types';
import { calculateBodyMass } from './utils';
import useMobile from 'hooks/useMobile';

interface Props {
	registerVitalSigns: UseFormRegister<FieldValues>;
	watchVitalSigns: UseFormWatch<FieldValues>;
	vitalSignsErrors: FieldValues;
	setVitalSignValues: UseFormSetValue<FieldValues>;
	setVitalSignErrors: UseFormSetError<FieldValues>;
	vitalsSigns: VitalSigns;
}

const useStyles = makeStyles((theme) => ({
	margin: {
		margin: theme.spacing(1),
	},
	box: {
		display: 'flex',
		alignItems: 'center',
		minWidth: 260,
	},
}));

const DarkerDisabledTextField = withStyles({
	root: {
		marginRight: 8,
		'& .MuiInputBase-root.Mui-disabled': {
			color: 'rgba(0, 0, 0, 0.87)',
		},
	},
})(TextField);

const VitalSignsForm: React.FC<Props> = ({
	registerVitalSigns,
	watchVitalSigns,
	vitalSignsErrors,
	setVitalSignValues,
	setVitalSignErrors,
	vitalsSigns,
}) => {
	const classes = useStyles();
	const { isMobile } = useMobile();
	useEffect(() => {
		if (watchVitalSigns('height') && watchVitalSigns('weight')) {
			setVitalSignValues(
				'bodyMass',
				calculateBodyMass(watchVitalSigns('height'), watchVitalSigns('weight'))
			);
			return;
		}
	}, [watchVitalSigns('height'), watchVitalSigns('weight')]);

	useEffect(() => {
		const errorHandler = (errorsData: FieldErrors<FieldValues>) => {
			if (errorsData.height && !errorsData.height.message) {
				if (errorsData.height.type === 'required') {
					setVitalSignErrors('height', {
						type: 'required',
						message: 'Estatura es requerido',
					});
				}
				if (errorsData.height.type === 'maxLength') {
					setVitalSignErrors('height', {
						type: 'maxLength',
						message: 'Estatura debe ser menor o igual a 4 caracteres',
					});
				}
				if (errorsData.height.type === 'pattern') {
					setVitalSignErrors('height', {
						type: 'pattern',
						message: 'Estatura solo debe contener números y punto.',
					});
				}
			}

			if (errorsData.weight && !errorsData.weight.message) {
				if (errorsData.weight.type === 'required') {
					setVitalSignErrors('weight', {
						type: 'required',
						message: 'Peso es requerido',
					});
				}
				if (errorsData.weight.type === 'maxLength') {
					setVitalSignErrors('weight', {
						type: 'maxLength',
						message: 'Peso debe ser menor o igual a 6 caracteres',
					});
				}
				if (errorsData.weight.type === 'pattern') {
					setVitalSignErrors('weight', {
						type: 'pattern',
						message: 'Peso solo debe contener números y punto.',
					});
				}
			}

			if (errorsData.bloodPressure && !errorsData.bloodPressure.message) {
				if (errorsData.bloodPressure.type === 'required') {
					setVitalSignErrors('bloodPressure', {
						type: 'required',
						message: 'Tensión arterial es requerido',
					});
				}

				if (errorsData.bloodPressure.type === 'pattern') {
					setVitalSignErrors('bloodPressure', {
						type: 'pattern',
						message: 'Tensión arterial solo debe contener números.',
					});
				}
			}

			if (errorsData.heartRate && !errorsData.heartRate.message) {
				if (errorsData.heartRate.type === 'required') {
					setVitalSignErrors('heartRate', {
						type: 'required',
						message: 'Frecuencia cardíaca es requerido',
					});
				}
				if (errorsData.heartRate.type === 'maxLength') {
					setVitalSignErrors('heartRate', {
						type: 'maxLength',
						message: 'Frecuencia cardíaca debe ser menor o igual a 5 caracteres',
					});
				}
				if (errorsData.heartRate.type === 'pattern') {
					setVitalSignErrors('heartRate', {
						type: 'pattern',
						message: 'Frecuencia cardíaca solo debe contener números y punto.',
					});
				}
			}

			if (errorsData.respiratoryRate && !errorsData.respiratoryRate.message) {
				if (errorsData.respiratoryRate.type === 'required') {
					setVitalSignErrors('respiratoryRate', {
						type: 'required',
						message: 'Frecuencia respiratoria es requerido',
					});
				}
				if (errorsData.respiratoryRate.type === 'maxLength') {
					setVitalSignErrors('respiratoryRate', {
						type: 'maxLength',
						message: 'Frecuencia respiratoria debe ser menor o igual a 2 caracteres',
					});
				}
				if (errorsData.respiratoryRate.type === 'pattern') {
					setVitalSignErrors('respiratoryRate', {
						type: 'pattern',
						message: 'Frecuencia respiratoria solo debe contener números.',
					});
				}
			}

			if (errorsData.bodyTemperature && !errorsData.bodyTemperature.message) {
				if (errorsData.bodyTemperature.type === 'required') {
					setVitalSignErrors('bodyTemperature', {
						type: 'required',
						message: 'Temperatura corporal es requerido',
					});
				}
				if (errorsData.bodyTemperature.type === 'maxLength') {
					setVitalSignErrors('bodyTemperature', {
						type: 'maxLength',
						message: 'Temperatura corporal debe ser menor o igual a 5 caracteres',
					});
				}
				if (errorsData.bodyTemperature.type === 'pattern') {
					setVitalSignErrors('bodyTemperature', {
						type: 'pattern',
						message: 'Temperatura corporal solo debe contener números y punto.',
					});
				}
			}

			if (errorsData.oxygenSaturation && !errorsData.oxygenSaturation.message) {
				if (errorsData.oxygenSaturation.type === 'required') {
					setVitalSignErrors('oxygenSaturation', {
						type: 'required',
						message: 'Saturación de oxígeno es requerido',
					});
				}
				if (errorsData.oxygenSaturation.type === 'maxLength') {
					setVitalSignErrors('oxygenSaturation', {
						type: 'maxLength',
						message: 'Saturación de oxígeno debe ser menor o igual a 3 caracteres',
					});
				}
				if (errorsData.oxygenSaturation.type === 'pattern') {
					setVitalSignErrors('oxygenSaturation', {
						type: 'pattern',
						message: 'Saturación de oxígeno solo debe contener números.',
					});
				}
			}
		};

		errorHandler(vitalSignsErrors);
	}, [{ ...vitalSignsErrors }]);

	useEffect(() => {
		if (vitalsSigns) {
			setVitalSignValues('height', vitalsSigns.height);
			setVitalSignValues('weight', vitalsSigns.weight);
			setVitalSignValues('bloodPressure', vitalsSigns.bloodPressure);
			setVitalSignValues('heartRate', vitalsSigns.heartRate);
			setVitalSignValues('respiratoryRate', vitalsSigns.respiratoryRate);
			setVitalSignValues('bodyTemperature', vitalsSigns.bodyTemperature);
			setVitalSignValues('oxygenSaturation', vitalsSigns.oxygenSaturation);
		}
	}, [vitalsSigns]);

	return (
		<SecondContainer
			item
			xs={12}
			style={isMobile ? { marginTop: '0', paddingTop: '0' } : { marginTop: '10px' }}
		>
			<CustomCard divider={true} title="Signos vitales">
				<InputContainer container spacing={1}>
					<Grid item xs={12} md={4} style={isMobile ? { paddingRight: '32px' } : {}}>
						<Box className={classes.box} style={isMobile ? { gap: '4px' } : {}}>
							<EstaturaIcon />
							<TextField
								label="ESTATURA"
								id="outlined-start-adornment"
								className={classes.margin}
								size="small"
								placeholder={'1.8'}
								fullWidth
								InputProps={{
									inputProps: {
										step: '0.1',
										maxLength: 4,
									},
									startAdornment: (
										<InputAdornment position="start">m</InputAdornment>
									),
								}}
								InputLabelProps={{
									style: {
										color: 'rgba(0, 0, 0, 0.6)',
										fontFamily: 'Roboto',
										fontStyle: 'normal',
										fontWeight: '600',
										letterSpacing: '0.46px',
									},
								}}
								type="text"
								value={watchVitalSigns('height')}
								{...registerVitalSigns('height', {
									maxLength: 4,
									pattern: /^[0-9.]+$/,
								})}
								variant="outlined"
								error={!!vitalSignsErrors.height}
								helperText={
									vitalSignsErrors.height && vitalSignsErrors.height.message
								}
							/>
						</Box>
					</Grid>
					<Grid item xs={12} md={4} style={isMobile ? { paddingRight: '32px' } : {}}>
						<Box className={classes.box} style={isMobile ? { gap: '4px' } : {}}>
							<PesoIcon />
							<TextField
								label="PESO"
								id="outlined-start-adornment"
								className={classes.margin}
								size="small"
								placeholder={'50'}
								fullWidth
								InputProps={{
									inputProps: {
										step: '0.1',
										maxLength: 6,
									},
									startAdornment: (
										<InputAdornment position="start">kg</InputAdornment>
									),
								}}
								InputLabelProps={{
									style: {
										color: 'rgba(0, 0, 0, 0.6)',
										fontFamily: 'Roboto',
										fontStyle: 'normal',
										fontWeight: '600',
										letterSpacing: '0.46px',
									},
								}}
								type="text"
								value={watchVitalSigns('weight')}
								{...registerVitalSigns('weight', {
									maxLength: 6,
									pattern: /^[0-9.]+$/,
								})}
								variant="outlined"
								error={!!vitalSignsErrors.weight}
								helperText={
									vitalSignsErrors.weight && vitalSignsErrors.weight.message
								}
							/>
						</Box>
					</Grid>
					<Grid item xs={12} md={4} style={isMobile ? { paddingRight: '32px' } : {}}>
						<Box className={classes.box} style={isMobile ? { gap: '4px' } : {}}>
							<MasaIcon />
							<DarkerDisabledTextField
								label="MASA CORPORAL"
								id="outlined-start-adornment"
								className={classes.margin}
								size="small"
								placeholder={'23'}
								fullWidth
								InputProps={{
									inputProps: {
										step: '0.1',
									},
									startAdornment: (
										<InputAdornment
											position="start"
											data-fx-name="masaCorporal"
										>
											kg/m2
										</InputAdornment>
									),
								}}
								InputLabelProps={{
									style: {
										color: 'rgba(0, 0, 0, 0.6)',
										fontFamily: 'Roboto',
										fontStyle: 'normal',
										fontWeight: '600',
										letterSpacing: '0.46px',
									},
								}}
								type="number"
								value={watchVitalSigns('bodyMass')}
								disabled
								variant="outlined"
							/>
						</Box>
					</Grid>
					<Grid item xs={12} md={4} style={isMobile ? { paddingRight: '32px' } : {}}>
						<Box className={classes.box} style={isMobile ? { gap: '4px' } : {}}>
							<TensionIcon />
							<TextField
								label="TENSIÓN ARTERIAL"
								id="outlined-start-adornment"
								className={classes.margin}
								size="small"
								placeholder={'120/80'}
								fullWidth
								InputProps={{
									inputProps: {
										step: '0.1',
										maxLength: 7,
									},
									startAdornment: (
										<InputAdornment position="start">mmHg</InputAdornment>
									),
								}}
								InputLabelProps={{
									style: {
										color: 'rgba(0, 0, 0, 0.6)',
										fontFamily: 'Roboto',
										fontStyle: 'normal',
										fontWeight: '600',
										letterSpacing: '0.46px',
									},
								}}
								type="text"
								value={watchVitalSigns('bloodPressure')}
								{...registerVitalSigns('bloodPressure', {
									maxLength: 7,
								})}
								variant="outlined"
								error={!!vitalSignsErrors.bloodPressure}
								helperText={
									vitalSignsErrors.bloodPressure &&
									vitalSignsErrors.bloodPressure.message
								}
							/>
						</Box>
					</Grid>
					<Grid item xs={12} md={4} style={isMobile ? { paddingRight: '32px' } : {}}>
						<Box className={classes.box} style={isMobile ? { gap: '4px' } : {}}>
							<FrecuenciaIcon />
							<TextField
								label="FRECUENCIA CARDÍACA"
								id="outlined-start-adornment"
								className={classes.margin}
								size="small"
								placeholder={'70'}
								fullWidth
								InputProps={{
									inputProps: {
										step: '0.1',
										maxLength: 5,
									},
									startAdornment: (
										<InputAdornment position="start">bpm</InputAdornment>
									),
								}}
								InputLabelProps={{
									style: {
										color: 'rgba(0, 0, 0, 0.6)',
										fontFamily: 'Roboto',
										fontStyle: 'normal',
										fontWeight: '600',
										letterSpacing: '0.46px',
									},
								}}
								type="text"
								value={watchVitalSigns('heartRate')}
								{...registerVitalSigns('heartRate', {
									maxLength: 5,
									pattern: /^[0-9.]+$/,
								})}
								variant="outlined"
								error={!!vitalSignsErrors.heartRate}
								helperText={
									vitalSignsErrors.heartRate && vitalSignsErrors.heartRate.message
								}
							/>
						</Box>
					</Grid>
					<Grid item xs={12} md={4} style={isMobile ? { paddingRight: '32px' } : {}}>
						<Box className={classes.box} style={isMobile ? { gap: '4px' } : {}}>
							<FrecuenciaREIcon />
							<TextField
								label="FRECUENCIA RESPIRATORIA"
								id="outlined-start-adornment"
								className={classes.margin}
								size="small"
								placeholder={'15'}
								fullWidth
								InputProps={{
									inputProps: {
										step: '0.1',
										maxLength: 2,
									},
									startAdornment: (
										<InputAdornment position="start">r/m</InputAdornment>
									),
								}}
								InputLabelProps={{
									style: {
										color: 'rgba(0, 0, 0, 0.6)',
										fontFamily: 'Roboto',
										fontStyle: 'normal',
										fontWeight: '600',
										letterSpacing: '0.46px',
									},
								}}
								type="text"
								value={watchVitalSigns('respiratoryRate')}
								{...registerVitalSigns('respiratoryRate', {
									maxLength: 2,
									pattern: /^[0-9]+$/,
								})}
								variant="outlined"
								error={!!vitalSignsErrors.respiratoryRate}
								helperText={
									vitalSignsErrors.respiratoryRate &&
									vitalSignsErrors.respiratoryRate.message
								}
							/>
						</Box>
					</Grid>
					<Grid item xs={12} md={4} style={isMobile ? { paddingRight: '32px' } : {}}>
						<Box className={classes.box} style={isMobile ? { gap: '4px' } : {}}>
							<TemperatureIcon />
							<TextField
								label="TEMPERATURA CORPORAL"
								id="outlined-start-adornment"
								className={classes.margin}
								size="small"
								placeholder={'37'}
								fullWidth
								InputProps={{
									inputProps: {
										step: '0.1',
										maxLength: 5,
									},
									startAdornment: (
										<InputAdornment position="start">c</InputAdornment>
									),
								}}
								InputLabelProps={{
									style: {
										color: 'rgba(0, 0, 0, 0.6)',
										fontFamily: 'Roboto',
										fontStyle: 'normal',
										fontWeight: '600',
										letterSpacing: '0.46px',
									},
								}}
								type="text"
								value={watchVitalSigns('bodyTemperature')}
								{...registerVitalSigns('bodyTemperature', {
									maxLength: 5,
									pattern: /^[0-9.]+$/,
								})}
								variant="outlined"
								error={!!vitalSignsErrors.bodyTemperature}
								helperText={
									vitalSignsErrors.bodyTemperature &&
									vitalSignsErrors.bodyTemperature.message
								}
							/>
						</Box>
					</Grid>
					<Grid item xs={12} md={4} style={isMobile ? { paddingRight: '32px' } : {}}>
						<Box className={classes.box} style={isMobile ? { gap: '4px' } : {}}>
							<SaturacionIcon />
							<TextField
								label="SATURACIÓN DE OXÍGENO"
								id="outlined-start-adornment"
								className={classes.margin}
								size="small"
								placeholder={'97'}
								fullWidth
								InputProps={{
									inputProps: {
										step: '0.1',
										maxLength: 3,
									},
									startAdornment: (
										<InputAdornment position="start">%</InputAdornment>
									),
								}}
								InputLabelProps={{
									style: {
										color: 'rgba(0, 0, 0, 0.6)',
										fontFamily: 'Roboto',
										fontStyle: 'normal',
										fontWeight: '600',
										letterSpacing: '0.46px',
									},
								}}
								type="text"
								value={watchVitalSigns('oxygenSaturation')}
								{...registerVitalSigns('oxygenSaturation', {
									maxLength: 3,
									pattern: /^[0-9]+$/,
								})}
								variant="outlined"
								error={!!vitalSignsErrors.oxygenSaturation}
								helperText={
									vitalSignsErrors.oxygenSaturation &&
									vitalSignsErrors.oxygenSaturation.message
								}
							/>
						</Box>
					</Grid>
				</InputContainer>
			</CustomCard>
		</SecondContainer>
	);
};

export default VitalSignsForm;
