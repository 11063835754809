import { Button, Grid, List, ListItemText, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import React, { FC, useContext } from 'react';
import {
	SubtitleContainer,
	EmptyStateText,
	HistoryAlergyContainer,
	EditIconButton,
	HistoryDivider,
	HistoryListItem,
	ButtonContainer,
} from '../../../../shared/style';
import CustomCard from '../../../card/CustomCard';
import { HistoryContext, HistoryContextProps } from '../../../../context/history-context';
import { HistorySidebarOptions } from '../../../../context/types';
import { AllergyStatus } from 'models';
import ResumenListItem from './ResumenListItem';
import moment from 'moment';
import FamilySkeleton from './historySkeletons/FamilyHistory.Skeleton';
import useMobile from 'hooks/useMobile';

interface Props {
	handleOpenDiseaseForm: () => void;
	handleOpenProcedureForm: () => void;
	handleOpenHospitalizationForm: () => void;
	diseases: any;
	procedures: any;
	hospitalizations: any;
	loading: boolean;
}

const PathologicalHistoryCard: FC<Props> = ({
	handleOpenDiseaseForm,
	handleOpenProcedureForm,
	handleOpenHospitalizationForm,
	diseases,
	procedures,
	hospitalizations,
	loading,
}) => {
	const { setCurrentSidebarTab } = useContext(HistoryContext) as HistoryContextProps;
	const { isMobile } = useMobile();
	const hasDiseases = diseases && diseases.length > 0;
	const hasProcedures = procedures && procedures.length > 0;
	const hasHospitalizations = hospitalizations && hospitalizations.length > 0;
	const latsFiveDisease = diseases && diseases.slice(0, 5);
	const latsFiveProcedures = procedures && procedures.slice(0, 5);
	const latsFiveHospitalizations = hospitalizations && hospitalizations.slice(0, 5);
	const sortedLastFiveDiseas = latsFiveDisease
		? [
				...latsFiveDisease.filter((d: any) => d.status === AllergyStatus.ACTIVE),
				...latsFiveDisease.filter((d: any) => d.status !== AllergyStatus.ACTIVE),
		  ]
		: [];

	return (
		<HistoryAlergyContainer style={isMobile ? { marginBottom: '0' } : {}}>
			<CustomCard
				title="Antecedentes Patológicos"
				divider
				paddingBottom={isMobile ? '5px' : '0px'}
				hasMarginBottom
			>
				<Grid container direction="column">
					<SubtitleContainer
						style={{
							...(isMobile && { padding: 'height: 40px' }),
						}}
						container
						direction="row"
						alignItems="center"
						justifyContent="space-between"
					>
						<Typography variant="subtitle2">Problemas o enfermedades</Typography>
						{hasDiseases && (
							<EditIconButton
								aria-label="edit"
								onClick={() => setCurrentSidebarTab(HistorySidebarOptions.DISEASES)}
							>
								<EditIcon />
							</EditIconButton>
						)}
					</SubtitleContainer>
					{hasDiseases ? (
						<>
							<List
								dense
								disablePadding={isMobile}
								component="nav"
								aria-label="secondary diseases folder"
							>
								{sortedLastFiveDiseas.map((disease, index) => {
									return (
										<ResumenListItem
											key={disease.id}
											name={disease.name}
											diagnosedDate={disease.diagnosedDate as string}
											status={disease.status as AllergyStatus}
											showDivider={index !== sortedLastFiveDiseas.length - 1}
										/>
									);
								})}
							</List>
							{diseases && diseases.length > 5 && (
								<ButtonContainer>
									<Button
										variant="text"
										color="primary"
										size="small"
										onClick={() =>
											setCurrentSidebarTab(HistorySidebarOptions.DISEASES)
										}
									>
										Ver todas (+{diseases?.length - 5})
									</Button>
								</ButtonContainer>
							)}
						</>
					) : loading ? (
						<FamilySkeleton />
					) : (
						<>
							<EmptyStateText variant="body2">
								No hay enfermedades agregados
							</EmptyStateText>
							<ButtonContainer>
								<Button
									startIcon={<AddIcon />}
									variant="text"
									color="primary"
									size="small"
									onClick={handleOpenDiseaseForm}
								>
									Añadir Enfermedad
								</Button>
							</ButtonContainer>
						</>
					)}
					<HistoryDivider style={{ marginTop: hasDiseases ? '0px' : '9.6px' }} />
				</Grid>
				<Grid container direction="column">
					<SubtitleContainer
						container
						direction="row"
						alignItems="center"
						justifyContent="space-between"
					>
						<Typography variant="subtitle2">Procedimientos</Typography>
						{hasProcedures && (
							<EditIconButton
								aria-label="edit"
								onClick={() =>
									setCurrentSidebarTab(HistorySidebarOptions.PROCEDURES)
								}
							>
								<EditIcon />
							</EditIconButton>
						)}
					</SubtitleContainer>
					{hasProcedures ? (
						<>
							<List
								dense
								disablePadding
								component="nav"
								aria-label="secondary procedures folder"
							>
								{latsFiveProcedures &&
									latsFiveProcedures.map((procedure: any, index: any) => {
										return (
											<HistoryListItem
												selected={false}
												divider={index !== latsFiveProcedures.length - 1}
												key={procedure.id}
												disableGutters={isMobile}
											>
												<ListItemText
													primary={procedure.name}
													secondary={`En fecha: ${moment(
														procedure.realizationDate
													).format('DD/MM/YYYY')}`}
												/>
											</HistoryListItem>
										);
									})}
							</List>
							{procedures && procedures.length > 5 && (
								<ButtonContainer>
									<Button
										variant="text"
										color="primary"
										size="small"
										onClick={() =>
											setCurrentSidebarTab(HistorySidebarOptions.PROCEDURES)
										}
									>
										Ver todas (+{procedures?.length - 5})
									</Button>
								</ButtonContainer>
							)}
						</>
					) : loading ? (
						<FamilySkeleton />
					) : (
						<>
							<EmptyStateText variant="body2">
								No hay procedimientos agregados
							</EmptyStateText>
							<ButtonContainer>
								<Button
									startIcon={<AddIcon />}
									variant="text"
									color="primary"
									size="small"
									onClick={handleOpenProcedureForm}
								>
									Añadir Procedimiento
								</Button>
							</ButtonContainer>
						</>
					)}
					<HistoryDivider style={{ marginTop: hasProcedures ? '0px' : '9.6px' }} />
				</Grid>
				<Grid container direction="column">
					<SubtitleContainer
						container
						direction="row"
						alignItems="center"
						justifyContent="space-between"
					>
						<Typography variant="subtitle2">Hospitalización previa</Typography>
						{hasHospitalizations && (
							<EditIconButton
								aria-label="edit"
								onClick={() =>
									setCurrentSidebarTab(
										HistorySidebarOptions.PREVIOUS_HOSPITALIZATION
									)
								}
							>
								<EditIcon />
							</EditIconButton>
						)}
					</SubtitleContainer>
					{hasHospitalizations ? (
						<>
							<List
								dense
								disablePadding
								component="nav"
								aria-label="secondary hospitalizations folder"
							>
								{latsFiveHospitalizations &&
									latsFiveHospitalizations.map(
										(hospitalization: any, index: any) => {
											return (
												<HistoryListItem
													selected={false}
													divider={
														index !==
														latsFiveHospitalizations.length - 1
													}
													disableGutters={isMobile}
													key={hospitalization.id}
												>
													<ListItemText
														primary={hospitalization.name}
														secondary={`En fecha: ${moment(
															hospitalization.date
														).format('DD/MM/YYYY')}`}
													/>
												</HistoryListItem>
											);
										}
									)}
							</List>
							{hospitalizations && hospitalizations.length > 5 && (
								<ButtonContainer>
									<Button
										variant="text"
										color="primary"
										size="small"
										onClick={() =>
											setCurrentSidebarTab(
												HistorySidebarOptions.PREVIOUS_HOSPITALIZATION
											)
										}
									>
										Ver todas (+
										{hospitalizations?.length - 5})
									</Button>
								</ButtonContainer>
							)}
						</>
					) : loading ? (
						<FamilySkeleton />
					) : (
						<>
							<EmptyStateText variant="body2">
								No hay hospitalizaciones previas
							</EmptyStateText>
							<ButtonContainer>
								<Button
									startIcon={<AddIcon />}
									variant="text"
									color="primary"
									size="small"
									onClick={handleOpenHospitalizationForm}
								>
									Añadir Hospitalización
								</Button>
							</ButtonContainer>
						</>
					)}
				</Grid>
			</CustomCard>
		</HistoryAlergyContainer>
	);
};

export default PathologicalHistoryCard;
