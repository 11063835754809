import { Grid } from '@material-ui/core';
import React, { FC, useContext } from 'react';
import AddIcon from '@material-ui/icons/Add';
import {
	EditIconButton,
	HeaderHistoryContainer,
	HistoryListTitle,
	NoItemButton,
} from '../../../../shared/style';
import EditIcon from '@material-ui/icons/Edit';
import ListEmptyState from './ListEmptyState';
import CTable from '../../../table/CTable';
import ModalConfirmation from '../../../modalConfirmation/ModalConfirmation';
import { IColumnType } from '../../../../shared/type';
import { RelationsContextProps } from '../../../../context/types';
import moment from 'moment';
import { HistoryContext, HistoryContextProps } from '../../../../context/history-context';
import { RelationsContext } from '../../../../context/relations-context';
import useMobile from 'hooks/useMobile';

interface IData {
	procedure_name: string | JSX.Element;
	realization_date: string;
	procedure_note: string;
	action: string;
	align?: 'center' | 'inherit' | 'justify' | 'left' | 'right';
	isDisabled?: boolean;
}

interface Props {
	procedureId: string;
	clearProcedureForm: () => void;
	handleEditProcedure: (id: string) => void;
	handleOpenProcedureForm: () => void;
	isProcedureDeleteOpen: boolean;
	procedureName: string;
	procedures: any;
	setClientHistory: (client: any) => void;
	isAnyModalOpen: boolean;
}

const ProcedureList: FC<Props> = ({
	procedureId,
	clearProcedureForm,
	handleEditProcedure,
	handleOpenProcedureForm,
	isProcedureDeleteOpen,
	procedureName,
	procedures,
	setClientHistory,
	isAnyModalOpen = false,
}) => {
	const { deletePatientProcedure } = useContext(HistoryContext) as HistoryContextProps;
	const { setAlertMessage } = useContext(RelationsContext) as RelationsContextProps;
	const { isMobile } = useMobile();

	const approveDelete = async () => {
		await deletePatientProcedure(procedureId);
		setClientHistory((prevState: any) => ({
			...prevState,
			procedures: prevState.procedures.filter((p: any) => p.id !== procedureId),
		}));
		clearProcedureForm();
		setAlertMessage({
			message: 'Procedimiento eliminado',
			severity: 'success',
		});
	};

	const columns: IColumnType<IData>[] = [
		{
			key: 'procedure_name',
			title: 'Nombre de enfermedad',
			width: isMobile ? 300 : 200,
		},
		{
			key: 'procedure_note',
			title: 'Notas',
			width: 320,
		},
		{
			key: 'realization_date',
			title: 'Fecha de realización',
			width: 170,
		},
		{
			key: 'action',
			title: '',
			width: 35,
			render: (_, { action }) => (
				<EditIconButton
					custommarginleft="0px"
					aria-label="edit"
					onClick={() => handleEditProcedure(action)}
				>
					<EditIcon />
				</EditIconButton>
			),
		},
	];

	const columnMobile: IColumnType<IData>[] = [
		{
			key: 'procedure_name',
			title: 'Procedimiento',
			width: 250,
		},
		{
			key: 'action',
			title: '',
			width: 30,
			render: (_, { action }) => (
				<EditIconButton
					custommarginleft="0px"
					aria-label="edit"
					onClick={() => handleEditProcedure(action)}
				>
					<EditIcon />
				</EditIconButton>
			),
		},
	];

	const columnsToRender = isMobile ? columnMobile : columns;

	let bodyContainer = (
		<ListEmptyState btnLabel="Agregar Procedimiento" onClickHandler={handleOpenProcedureForm} />
	);

	if (procedures && procedures.length > 0) {
		const data: IData[] = procedures.map((d: any) => {
			if (isMobile) {
				return {
					procedure_name: (
						<div>
							<b>{d.name}</b>
							<br />
							<span
								style={{
									color: 'var(--Light-Text-Secondary, rgba(0, 0, 0, 0.60))',
									fontFeatureSettings: "'clig' off, 'liga' off",
									fontFamily: 'Roboto',
									fontSize: '14px',
									fontStyle: 'normal',
									fontWeight: 400,
									lineHeight: '143%',
									letterSpacing: '0.17px',
								}}
							>
								{moment(d.realizationDate).format('DD/MM/YYYY')}
							</span>
							<p className="mt-2">{d.note ? d.note : 'Sin descripción'}</p>
						</div>
					),
					action: d.id,
					isDisabled: false,
				};
			}

			return {
				procedure_name: d.name,
				realization_date: moment(d.realizationDate).format('DD/MM/YYYY') as string,
				procedure_note: d.note ? d.note : 'Sin descripción',
				action: d.id,
				isDisabled: false,
			};
		});
		bodyContainer = (
			<Grid container>
				{!isMobile ? (
					<HeaderHistoryContainer item>
						<HistoryListTitle variant="h6">Procedimientos</HistoryListTitle>
						<NoItemButton
							variant="contained"
							color="primary"
							size="small"
							onClick={handleOpenProcedureForm}
							startIcon={<AddIcon fontSize="large" />}
						>
							{isMobile ? '' : 'Agregar Procedimiento'}
						</NoItemButton>
					</HeaderHistoryContainer>
				) : null}
				<CTable data={data} columns={columnsToRender} />
			</Grid>
		);
	}

	return (
		<div
			style={{
				paddingBottom: isMobile && !isAnyModalOpen ? '70px' : '0px',
			}}
		>
			{isProcedureDeleteOpen && (
				<ModalConfirmation
					modalState={isProcedureDeleteOpen}
					name="¿Estás seguro que quieres eliminar el siguiente procedimiento? "
					validationLabel={procedureName}
					agreeTextColor="#D32F2F"
					closeFunction={clearProcedureForm}
					approveFunction={approveDelete}
					agreeVariant="text"
					cancelVariant="text"
				></ModalConfirmation>
			)}
			{bodyContainer}
		</div>
	);
};

export default ProcedureList;
