import styled from 'styled-components';
import {
	Button,
	Chip,
	createTheme,
	Grid,
	List,
	ListItem,
	ListItemText,
	Typography,
	IconButton,
	TextField,
	Tooltip,
	Select,
	Dialog,
	DialogContent,
	DialogActions,
	createStyles,
	Theme,
	InputLabel,
	withStyles,
	RadioGroup,
	FormLabel,
	Backdrop,
	OutlinedInput,
	Radio,
	FormControlLabel,
	Card,
	Toolbar,
	Badge,
	Divider,
	FormControl,
	FormGroup,
	DialogTitle,
	Checkbox,
	ButtonBase,
	Snackbar,
	DialogProps,
	DialogContentText,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Autocomplete, SpeedDialAction } from '@material-ui/lab';
// Icons
import SearchIcon from '@material-ui/icons/Search';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import LocalPharmacyIcon from '@material-ui/icons/LocalPharmacy';
import TimelineIcon from '@material-ui/icons/Timeline';
import PaymentIcon from '@material-ui/icons/Payment';
import ErrorIcon from '@material-ui/icons/Error';
import { purple } from '@material-ui/core/colors';
import CustomDialog from 'components/dialog/CustomDialog';

export const BREAKPOINTS = {
	xs: 0,
	sm: 600,
	md: 900,
	lg: 1100,
	xl: 1536,
};

export const theme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1100,
			xl: 1536,
		},
	},
});

export const ButtonContainer = styled.div`
	display: inline-flex;
`;
export const StyledCancelButton = withStyles({
	root: {
		marginRight: theme.spacing(2),
	},
})(Button) as typeof Button;
export const LifeStyleContainer = withStyles({
	root: {
		marginBottom: theme.spacing(2.5),
	},
})(Grid) as typeof Grid;
export const TitleSection = styled.section`
	display: flex;
	justify-content: space-between;
`;
export const EditionDate = styled.p`
	color: rgba(0, 0, 0, 0.6);
	font-weight: 400;
`;
export const StyledPatientList = withStyles({
	root: {
		padding: 0,
		width: '100%',
	},
})(List) as typeof List;

export const MultiSelectListItemText = withStyles({
	root: {
		padding: 0,
		fontSize: '1rem',
	},
})(ListItemText) as typeof ListItemText;
export const MultiSelectCheckbox = withStyles({
	root: {
		padding: theme.spacing(0, 1),
		fontSize: '1rem',
	},
})(Checkbox) as typeof Checkbox;

export const WhiteTextField = withStyles({
	root: {
		backgroundColor: '#ffffff',
	},
})(TextField) as typeof TextField;

export const CardTitle = styled.h1`
	height: 32px;
	flex-grow: 1;
	font-size: 20px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.6;
	letter-spacing: 0.15px;
	text-align: left;
	color: rgba(0, 0, 0, 0.87);
	min-width: 180px;
`;

export const StyledClientContent = styled.div`
	width: 100%;
`;

export const WaitingListSelect = styled(Select)`
	color: #1976d2;
	font-size: 15px;
	font-weight: 500;
	&:before,
	&:after,
	&:hover:not(.Mui-disabled):before {
		border: none;
	}
	&.MuiSelect-select {
		color: #1976d2;
	}

	&.MuiSelect-icon {
		fill: #1976d2;
	}
`;

export const ActionHeader = styled.header`
	display: flex;
	justify-content: flex-end;
`;
export const IconContainer = styled.div`
	min-width: 40px;
	height: 40px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background-color: #bdbdbd;
	margin-right: 16px;
`;

export const StyledTimelineIcon = withStyles({
	root: {
		color: '#fff',
	},
})(TimelineIcon);
export const StyledFormLabel = withStyles({
	focused: {
		color: 'rgba(0, 0, 0, 0.54) !important',
	},
})(FormLabel);
export const StyledLocalPharmacyIcon = withStyles({
	root: {
		color: '#fff',
	},
})(LocalPharmacyIcon);
export const StyledSearchIcon = withStyles({
	root: {
		color: '#666666',
	},
})(SearchIcon) as typeof SearchIcon;

export const StyledCardActionBtn = withStyles({
	root: {
		marginRight: 20,
	},
	label: {
		fontWeight: 600,
	},
})(Button) as typeof Button;

export const PatientListItemText = withStyles({
	root: {
		cursor: 'pointer',
		textOverflow: 'ellipsis',
		maxWidth: '140px',
		whiteSpace: 'nowrap',
		width: '100%',
		overflow: 'hidden',

		[theme.breakpoints.up('md')]: {
			maxWidth: 'calc(100% - 330px)',
			minWidth: '50%',
		},
	},
	primary: {
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
	},
})(ListItemText) as typeof ListItemText;

export const StyledBackdrop = withStyles({
	root: {
		zIndex: 5,
	},
})(Backdrop) as typeof Backdrop;

export const StyledTransparentBackdrop = withStyles({
	root: {
		zIndex: 5,
		opacity: '0 !important',
	},
})(Backdrop) as typeof Backdrop;

export const StyledSpeedDialAction = withStyles({
	staticTooltipLabel: {
		minWidth: '155px',
		textAlign: 'center',
		cursor: 'pointer',
	},
})(SpeedDialAction) as typeof SpeedDialAction;

interface TypographyProps {
	ishover?: boolean;
}
export const FileDescription = styled(Typography)<TypographyProps>`
	width: 141.8px;
	color: ${(props) => (props.ishover ? '#1976d2' : '#1e2469')};
	text-decoration: ${(props) => (props.ishover ? 'underline' : undefined)};
	cursor: ${(props) => (props.ishover ? 'pointer' : undefined)};
	padding-left: 10px;
	overflow: hidden;
	font-size: 12px;
	line-height: 1.66;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	-webkit-box-orient: vertical;
	margin-left: -3px;
	margin-top: 10px;
`;
export const FileInputDescription = styled.input`
	width: 40%;
	color: #757575;
	padding: 0px 1px;

	@media (max-width: 600px) {
		width: 100%;
	}
`;
export const StyledRadioGroup = styled(RadioGroup)`
	margin: ${theme.spacing(1.5, 0, 0, 1.5)};
` as typeof RadioGroup;
export const MedicineHeaderGrid = styled(Grid)`
	flex-grow: 0;
	max-width: 42%;
	flex-basis: 42%;
	display: flex;
	padding: ${theme.spacing(0.75, 0.75, 0.75, 1.5)};
	justify-content: flex-start;
	align-items: center;
	@media screen and (min-width: 960px) and (max-width: 1200px) {
		max-width: 24%;
		flex-basis: 24%;
	}
	@media screen and (min-width: 1200px) and (max-width: 1400px) {
		max-width: 33%;
		flex-basis: 33%;
	}
` as typeof Grid;
export const MedicineGrid = styled(MedicineHeaderGrid)`
	border-right: 1px solid rgba(0, 0, 0, 0.12);
	padding: ${theme.spacing(0.75, 0.75)};
`;
export const ContainerForm = styled.form`
	width: 100%;
`;
export const AddDialogContent = withStyles({
	root: {
		maxWidth: '470px',
		minHeight: '470px',
		width: '470px',
		overflowX: 'hidden',
		padding: theme.spacing(0, 5, 4, 5),
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',

		'@media (max-width: 470px)': {
			width: '100%',
			maxWidth: '100%',
			minWidth: '100%',
		},
	},
})(DialogContent) as typeof DialogContent;
export const StyledToolbar = withStyles({
	root: {
		borderRadius: '0 0 4px 4px',
		boxShadow:
			'0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.2)',
		backgroundColor: '#fff',
	},
})(Toolbar) as typeof Toolbar;
export const StyledPictureDialog = withStyles({
	paper: {
		backgroundColor: '#eceff1',
	},
})(Dialog) as typeof Dialog;
export const NoItemButton = withStyles((theme: Theme) => ({
	root: {
		color: theme.palette.getContrastText(purple[500]),
		backgroundColor: purple[500],
		'&:hover': {
			backgroundColor: purple[700],
		},
	},
}))(Button) as typeof Button;
export const EmptyDescriptionTypography = withStyles({
	root: {
		margin: theme.spacing(1.2, 0, 2, 0),
		color: '#000000',
		fontWeight: 700,
		textAlign: 'center',
	},
})(Typography) as typeof Typography;

interface StyledDialogProps extends DialogProps {
	maxHeight?: string;
}

export const StyledDialog = withStyles((theme) => ({
	paper: {
		margin: '20px',
		'@media (max-width: 470px)': {
			width: '100%',
		},
	},
	paperScrollPaper: {
		maxHeight: (props: StyledDialogProps) =>
			`${props.maxHeight} !important` || 'calc(100% - 35px)',
	},
}))(Dialog);
export const PaymentStyledDialog = withStyles({
	paper: {
		margin: '25px',
		width: '528px',
		height: '480px',
	},
})(Dialog) as typeof Dialog;
export const SecretaryFormLabel = withStyles({
	root: {
		color: 'rgba(0, 0, 0, 0.87)',
		fontWeight: 500,
		fontSize: '14px',
		marginBottom: '17px',
	},
})(FormLabel) as typeof FormLabel;
export const SecretaryFormGroup = withStyles({
	root: {
		marginLeft: '15px',
		marginBottom: '22px',
	},
})(FormGroup) as typeof FormGroup;
export const SecretaryButton = withStyles({
	root: {
		margin: '15px 0 25px 0',
	},
})(Button) as typeof Button;
export const SecretaryDialog = withStyles({
	paper: {
		margin: theme.spacing(2),
	},
	paperScrollPaper: {
		maxHeight: 'calc(100% - 35px)',
		maxWidth: '488px',
	},
})(CustomDialog) as typeof CustomDialog;
export const SecretaryConfirmationDialog = withStyles({
	paper: {
		margin: theme.spacing(2),
	},
	paperScrollPaper: {
		maxHeight: 'calc(100% - 35px)',
		minWidth: '488px',
	},
})(Dialog) as typeof Dialog;
export const StyledPaymentIcon = withStyles({
	root: {
		fontSize: '95px',
		paddingBottom: `${theme.spacing(2)}px`,
	},
})(PaymentIcon) as typeof PaymentIcon;
export const AddMethodContainer = withStyles({
	root: {
		width: '100%',
		height: 'auto',
	},
})(Grid) as typeof Grid;
export const HeaderHistoryContainer = withStyles({
	root: {
		display: 'flex',
		marginBottom: theme.spacing(2.5),
	},
})(Grid) as typeof Grid;
export const SelectLifeStyleContainer = withStyles({
	root: {
		width: 320,
	},
})(Grid) as typeof Grid;
export const InputLifeStyleContainer = styled(Grid)`
	width: calc(100% - 320px);
` as typeof Grid;
export const HistoryListTitle = withStyles({
	root: {
		marginRight: theme.spacing(2.5),
	},
})(Typography) as typeof Typography;
export const StyledAutorenewIcon = withStyles({
	root: {
		fontSize: '95px',
		paddingBottom: `${theme.spacing(2)}px`,
	},
})(AutorenewIcon) as typeof AutorenewIcon;
export const CancelActivateSubscriptionBtn = withStyles({
	root: {
		border: 'solid 2px #1976D2',
		color: '#1976D2',
		'&:hover': {
			border: 'solid 2px #1976D2',
		},
	},
})(Button) as typeof Button;
export const CancelSubscriptionContainer = styled.div`
	display: flex;
	justify-content: flex-end;
`;
export const SubscriptionCard = withStyles({
	root: {
		width: '100%',
		minHeight: '64px',
		borderRadius: '4px',
		border: 'solid 3px #546e7a',
		display: 'flex',
		alignItems: 'center',
		marginBottom: `${theme.spacing(2)}px`,
		padding: theme.spacing(2, 3),
		justifyContent: 'space-between',
	},
})(Grid) as typeof Grid;

export const PaymentSubscriptionCard = withStyles({
	root: {
		width: '100%',
		height: '82px',
		borderRadius: '4px',
		border: 'solid 3px #546e7a',
		display: 'flex',
		alignItems: 'center',
		marginBottom: `${theme.spacing(2)}px`,
		padding: theme.spacing(2, 3),
		justifyContent: 'space-between',
	},
})(Grid) as typeof Grid;
export const StyledFormControlLabel = withStyles({
	label: {
		fontWeight: 600,
	},
})(FormControlLabel) as typeof FormControlLabel;
export const CheckboxFormControlLabel = withStyles({
	root: {
		marginLeft: 0,
	},
})(FormControlLabel) as typeof FormControlLabel;
export const HelperText = withStyles({
	root: {
		color: 'rgba(30, 36, 105, 1)',
		marginLeft: `${theme.spacing(3.4)}px`,
		fontFamily: 'Roboto',
		fontSize: '14px',
		fontWeight: 400,
		lineHeight: '143%',
		letterSpacing: '0.17px',
	},
})(Typography) as typeof Typography;
export const PaymentHelperText = withStyles({
	root: {
		color: '#1E2469',
		marginLeft: `${theme.spacing(3.4)}px`,
		fontFamily: 'Roboto',
		fontSize: '14px',
		fontWeight: 400,
		lineHeight: '143%',
		letterSpacing: '0.17px',
	},
})(Typography) as typeof Typography;
export const PriceContainer = withStyles({
	root: {
		color: '#007dff',
	},
})(Typography) as typeof Typography;
export const PreviewTypography = withStyles({
	root: {
		color: '#1E2469',
		maxWidth: '312px',
		margin: theme.spacing(0.52),
	},
})(Typography) as typeof Typography;
export const StyledRadio = withStyles({
	root: {
		padding: theme.spacing(0, 1),
	},
})(Radio) as typeof Radio;
export const CardDescription = withStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
})(Grid) as typeof Grid;
export const StyledCard = withStyles({
	root: {
		maxWidth: '488px',
		minHeight: '400px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
})(Card) as typeof Card;
export const StyledAddPaymentCard = withStyles({
	root: {
		maxWidth: '488px',
		minHeight: '348px',
		height: 'auto',
		padding: theme.spacing(0, 5, 4, 5),
		display: 'flex',
		flexDirection: 'column',
		rowGap: '16px',
		paddingTop: '41px',
	},
})(Card) as typeof Card;
export const TitleTypography = withStyles({
	root: {
		color: '#1e2469',
		fontWeight: 600,
		marginBottom: `${theme.spacing(1.2)}px`,
	},
})(Typography) as typeof Typography;

export const TitlePaymentModalTypography = withStyles({
	root: {
		marginTop: '41px',
		color: 'rgba(0, 0, 0, 0.87)',
		/* Typography/H5 */
		fontFamily: 'Roboto',
		fontSize: '24px',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: '133.4%',
	},
})(Typography) as typeof Typography;

export const ButtonPaymentModal = withStyles({
	root: {
		color: '#FFFFFF',
		fontFamily: 'Roboto',
		fontSize: '15px',
		fontStyle: 'normal',
		fontWeight: 600,
		lineHeight: '26px',
		letterSpacing: '0.46px',
		textTransform: 'uppercase',
		backgroundColor: '#1976D2',
	},
})(Button) as typeof Button;
export const ParagraphTypography = withStyles({
	root: {
		color: 'rgba(0, 0, 0, 0.6)',
		width: '400px',
		textAlign: 'left',
		fontSize: '14px',
		fontWeight: 400,
		fontFamily: 'Roboto',
		marginBottom: '16px',
	},
})(Typography) as typeof Typography;
export const RenewHeader = withStyles({
	root: {
		paddingTop: `${theme.spacing(5)}px`,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
})(Grid) as typeof Grid;

export const PaymentRenewHeader = withStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',

		justifyContent: 'space-between',
		flex: 1,
		gap: '32px',
	},
})(Grid) as typeof Grid;
export const RenewDialogContent = withStyles({
	root: {
		minWidth: '595px',
		minHeight: '580px',
		overflowX: 'hidden',
		padding: theme.spacing(0, 5, 4, 5),
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
})(DialogContent) as typeof DialogContent;
export const NewDialogContent = withStyles({
	root: {
		width: '528px',
		height: '455px',
		overflowX: 'hidden',
		padding: '41px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
})(DialogContent) as typeof DialogContent;
export const StyledSelect = withStyles((theme: Theme) =>
	createStyles({
		selectMenu: { padding: theme.spacing(1.3, 1.75) },
	})
)(Select) as typeof Select;
export const ActionContainer = withStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		rowGap: '16px',
	},
})(Grid) as typeof Grid;
export const PaymentActionContainer = withStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		rowGap: '16px',
		marginTop: '16px',
	},
})(Grid) as typeof Grid;
export const StyledFormControl = withStyles({
	root: {},
})(FormControl) as typeof FormControl;
export const NextButton = withStyles({
	root: {
		color: '',
	},
})(Button) as typeof Button;
export const BackButton = withStyles({
	root: {
		marginRight: `${theme.spacing(1)}px`,
	},
})(Button) as typeof Button;
export const StyledKeyboardDatePicker = styled(KeyboardDatePicker)`
	input {
		padding: ${theme.spacing(1.3, 1.75)};
	}
	.MuiInputLabel-outlined {
		transform: translate(14px, 13px) scale(1);
	}

	.MuiInputLabel-shrink {
		transform: translate(14px, -6px) scale(0.75);
	}
`;
export const CustomKeyboardDatePicker = styled(KeyboardDatePicker)`
	margin-bottom: ${theme.spacing(2.5)};
	input {
		padding: ${theme.spacing(1.3, 1.75)};
	}
` as typeof KeyboardDatePicker;
export const StyledDialogContent = styled(DialogContent)`
	padding: ${theme.spacing(1.5, 3)};
` as typeof DialogContent;
export const GridEnd = styled(Grid)`
	display: flex;
	justify-content: flex-end;
` as typeof Grid;
export const SecretaryDialogTitle = withStyles({
	'secretary-dialog-title': {
		background: 'red',
	},
	root: {
		padding: '41px 41px 0 41px',
	},
})(DialogTitle) as typeof DialogTitle;
export const SecretaryDialogContent = withStyles({
	root: {
		padding: '0 41px',
	},
})(DialogContent) as typeof DialogContent;
export const SecretaryConfirmationDialogContent = withStyles({
	root: {
		padding: '41px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		textAlign: 'center',
	},
})(DialogContent) as typeof DialogContent;
export const SecretaryTitleTypography = withStyles({
	root: {
		fontWeight: 600,
		fontSize: '26px',
		lineHeight: '31px',
		color: '#1E2469',
		marginTop: '10px',
		marginBottom: '5px',
	},
})(Typography) as typeof Typography;
export const SecretaryDescription = withStyles({
	root: {
		fontWeight: 400,
		fontSize: '16px',
		color: '#1E2469',
		marginTop: '10px',
		marginBottom: '39px',
		maxWidth: '317.01px',
	},
})(Typography) as typeof Typography;
export const SecretaryTextField = withStyles({
	root: {
		margin: '20px 0 20px 0',
	},
})(TextField) as typeof TextField;
export const SecretaryDialogContentText = withStyles({
	root: {
		margin: '20px 0 0 0',
	},
})(DialogContentText) as typeof DialogContentText;
export const StyledDialogActions = styled(DialogActions)`
	padding: ${theme.spacing(1.75, 2)};
` as typeof DialogActions;
export const StyledInputLabel = withStyles(() =>
	createStyles({
		outlined: { transform: 'translate(14px, 13px) scale(1)' },
	})
)(InputLabel) as typeof InputLabel;

export const LabelGrid = styled(Grid)`
	margin-bottom: ${theme.spacing(1.75)}px;
	font-size: 14px;
	font-weight: 600;
` as typeof Grid;

export const SpeedDialButtonBase = styled(ButtonBase)`
	padding: 16px 0;
	text-align: left;
	width: 100%;
	justify-content: flex-start;
` as typeof ButtonBase;

export const GridLeft = styled(Grid)`
	width: 100%;
	@media (min-width: ${BREAKPOINTS.md}px) {
		width: auto;
		margin-left: ${theme.spacing(1.5)}px;
	}
` as typeof Grid;
export const StyledSnackbar = styled(Snackbar)`
	bottom: 64px;
	@media (min-width: ${BREAKPOINTS.md}px) {
		bottom: 8px;
	}
` as typeof Snackbar;

export const StyledOutlinedInput = withStyles({
	root: {
		backgroundColor: 'rgba(237, 243, 241, 1)',
	},
	input: {
		padding: theme.spacing(1.7, 1.7),
	},
})(OutlinedInput) as typeof OutlinedInput;
export const NotFoundContainer = withStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'center',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: '100dvh',
	},
})(Grid) as typeof Grid;
export const TypographyTitle = withStyles({
	root: {
		color: '#1e2469',
		fontSize: '26px',
		fontWeight: 600,
		margin: theme.spacing(1.5, 0),
	},
})(Typography) as typeof Typography;
export const TypographyDescription = withStyles({
	root: {
		color: '#1e2469',
		fontSize: '16px',
		width: '300px',
		textAlign: 'center',
		marginBottom: `${theme.spacing(2)}px`,
	},
})(Typography) as typeof Typography;
export const CheckboxFormLabel = withStyles({
	root: {
		color: 'rgba(0, 0, 0, 0.87)',
		fontWeight: 500,
		marginBottom: theme.spacing(1.2),
		fontSize: '14px',
	},
})(FormLabel) as typeof FormLabel;
export const ForgotTextField = withStyles({
	root: {
		marginBottom: `${theme.spacing(2.5)}px`,
	},
})(TextField) as typeof TextField;
export const CustomTextField = withStyles({
	root: {},
})(TextField) as typeof TextField;
export const LogingTextField = withStyles({
	root: {
		marginBottom: `${theme.spacing(2.5)}px`,
	},
})(TextField) as typeof TextField;
export const RedErrorIcon = withStyles({
	root: {
		color: '#D32F2F',
	},
})(ErrorIcon) as typeof ErrorIcon;
export const LoginErrorText = withStyles({
	root: {
		color: '#D32F2F',
		marginBottom: `${theme.spacing(2.5)}px`,
	},
})(Typography) as typeof Typography;
export const SendButton = withStyles({
	root: {
		marginBottom: `${theme.spacing(1)}px`,
	},
})(Button) as typeof Button;
export const StyledHeaderChip = styled(Chip)`
	margin-right: ${theme.spacing(1)};
	height: 24px;
` as typeof Chip;

export const InputContainer = styled(Grid)`
  display: flex;
  &:not(:last-of-type) {
    margin-bottom: ${theme.breakpoints.down('md') ? 0 : theme.spacing(2) + 'px'};
  }
  align-items: center;
  flex-wrap: wrap;
  }
  #treatment-notes-label {
    max-width: 90%;
    text-overflow: ellipsis;
  }
` as typeof Grid;
export const SecondContainer = styled(Grid)`` as typeof Grid;
export const AddButton = styled(Button)`
	margin-left: ${theme.spacing(2)}px;
` as typeof Button;
export const StyledChip = styled(Chip)<{
	removeDisabledStyle?: boolean;
	customHoverEffect?: boolean;
	isMdQuery?: boolean;
	labelLength?: number;
}>`
	margin-right: ${theme.spacing(2)}px;
	margin-bottom: ${theme.spacing(2)}px;

	${(props) =>
		props.removeDisabledStyle &&
		`
    &.MuiChip-root.Mui-disabled {
      opacity: 1;
    }`}
	${(props) =>
		props.customHoverEffect &&
		`
    &:hover, &:focus {
      background-color: #DFDECE !important;
      border-color: transparent !important;
      border: 1px solid !important;
    }
    `}
    @media (max-width: ${BREAKPOINTS.md}px) {
		width: 100%;
	}
` as typeof Chip;
export const InactiveChip = styled(Chip)`
	background: #cfd8dc;
	font-weight: 500;
` as typeof Chip;
export const HistoryListItem = withStyles({
	selected: {
		backgroundColor: '#F5F5F5 !important',
	},
})(ListItem) as typeof ListItem;
export const OptionList = styled.ul`
	display: flex;
	width: 100%;
	justify-content: space-between;
	gap: 1rem;
	padding: 0.5rem 0;
`;
export const ListContainer = styled(Grid)`
	height: 100%;
	background-color: #ffff;
	border-right: 1px solid rgba(0, 0, 0, 0.12);
	margin-right: 2%;
	overflow-y: auto;
	flex-grow: 0;
	max-width: 20%;
	flex-basis: 20%;
` as typeof Grid;
export const HistoryListContainer = styled(Grid)`
	height: 100%;
	background-color: #ffff;
	border-right: 1px solid rgba(0, 0, 0, 0.12);
	overflow-y: auto;
	flex-grow: 0;
	width: 100%;
	@media screen and (min-width: 960px) {
		width: 240px;
	}
` as typeof Grid;
export const HistoryBodyContainer = styled(Grid)`
	display: flex;
	overflow-y: auto;
	flex-grow: 0;
	flex-wrap: wrap;
	padding: 16px 16px 56px;
	height: calc(100dvh - 112px);
	@media screen and (min-width: 960px) {
		padding: 20px 100px;
		height: calc(100dvh - 167px);
		flex-wrap: nowrap;
	}
` as typeof Grid;
export const HistoryListBodyContainer = styled(Grid)<{
	isMobile?: boolean;
}>`
	padding: ${(props) => (props.isMobile ? '0px 16px 16px 16px' : '20px 20px')};
	overflow-y: auto;
	flex-grow: 0;
	height: calc(100dvh - 177px);
`;
export const GrayedBodyContainer = styled(Grid)`
	background-color: #fafafa;
	padding: 16px;
` as typeof Grid;
export const PatientIconButton = withStyles({
	root: {
		padding: 0,
	},
})(IconButton) as typeof IconButton;
export const HistoryContainer = styled(Grid)`
	height: 100%;
	width: 100%;
	@media screen and (min-width: 960px) {
		width: calc(100% - 240px);
	}
` as typeof Grid;
export const HistoryAlergyContainer = styled(Grid)`
	margin: 0 0 20px 0;
	flex-grow: 0;
	max-width: 100%;
	@media screen and (min-width: 960px) {
		margin: 0 20px 20px 0;
	}
` as typeof Grid;
export const SummaryContainer = styled(Grid)`
	display: flex;
	flex-direction: column;
	width: 100%;
` as typeof Grid;
export const FamilyHistoryContainer = styled(Grid)`
	margin: 0 0 20px 0;
	flex-grow: 0;
	max-width: 100%;
` as typeof Grid;
export const FormBodyContainer = styled(Grid)`
	height: 100%;
	border: 1px solid rgba(0, 0, 0, 0.12);
	flex-grow: 0;
	max-width: 72%;
	flex-basis: 72%;
	margin-top: 29px !important;
	margin-left: 21px !important;
` as typeof Grid;
export const FormContainer = styled(Grid)`
	height: 100%;
	margin: ${theme.spacing(2, 0)};
	flex-grow: 0;
	max-width: 100%;
	flex-basis: 40%;
` as typeof Grid;
export const StyledList = styled(List)`
	padding-bottom: 0;
` as typeof List;
export const PrescriptionContainer = withStyles({
	root: {
		overflowX: 'hidden',
		overflowY: 'auto',
	},
})(Grid) as typeof Grid;
export const PrescriptionList = styled(List)`
	padding: 0;
	overflow: auto;
	margin: 0px;
` as typeof List;
export const HeaderListItem = styled(ListItem)`
	background-color: rgba(102, 187, 106, 0.08);
	font-weight: 600;
	padding: 0;
` as typeof ListItem;
export const SubtitleContainer = withStyles({
	root: {
		marginBottom: theme.spacing(0),
	},
})(Grid) as typeof Grid;
export const EditIconButton = styled(IconButton)<{ custommarginleft?: string }>`
	padding: ${theme.spacing(0)};
	width: 24px;
	margin-left: ${(props) =>
		props.custommarginleft ? props.custommarginleft : theme.spacing(0.9)};
`;
export const HistoryDivider = withStyles({
	root: {
		margin: theme.spacing(1.2, 0),
	},
})(Divider) as typeof Divider;
export const LabelText = styled(Typography)`
	padding: ${theme.spacing(3, 2, 1, 2)};
	font-weight: 600;
` as typeof Typography;
export const SubtitleText = styled(Typography)`
	margin-bottom: ${theme.spacing(1.4)};
` as typeof Typography;
export const EmptyStateText = styled(Typography)`
	margin-left: ${theme.spacing(2.5)};
	margin-bottom: ${theme.spacing(1.4)};
	margin-top: ${theme.spacing(1.4)};
` as typeof Typography;
export const HistoryLabelText = styled(Typography)`
	padding: ${theme.spacing(3.8, 2, 2, 2)};
	font-weight: 600;
` as typeof Typography;
export const CustomBadget = styled(Badge)`
	margin-right: 8px;
` as typeof Badge;
export const SideBarListItem = styled(ListItem)`
	padding: ${theme.spacing(0, 2)};
	justify-content: space-between;
	height: 36px;
	font-size: 14px;
	&:active,
	&:hover {
		background-color: rgba(25, 118, 210, 0.08);
	}
` as typeof ListItem;
export const HistorySideBarListItem = styled(ListItem)`
	padding: ${theme.spacing(0, 2)};
	justify-content: space-between;
	height: 36px;
	font-size: 14px;
	&:active,
	&:hover {
		background-color: rgba(25, 118, 210, 0.08);
	}
` as typeof ListItem;
export const SearchListItem = styled(ListItem)`
	padding: 0;
	&:hover,
	&:active {
		background-color: transparent;
	}
` as typeof ListItem;
export const StyledListItemText = styled(ListItemText)`
	font-size: 0.875rem;
	width: 100%;
	span {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		font-size: 14px;
	}
` as typeof ListItemText;
export const SearchListItemText = styled(ListItemText)`
	font-size: 0.875rem;
	width: 100%;
	margin-top: 0;
	margin-bottom: 0;
	span {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
` as typeof ListItemText;
export const LoadingContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(100dvh - 112px);
	@media (min-width: ${BREAKPOINTS.md}px) {
		height: 100%;
	}
`;
export const StyledTooltip = styled(Tooltip)`
	max-width: 350px;
	margin-right: 5px;
	margin-left: 10px;
	overflow: hidden;
	text-align: center;
	white-space: nowrap;
	text-overflow: ellipsis;
	@media screen and (min-width: 960px) and (max-width: 1200px) {
		max-width: 100px;
	}
	@media screen and (min-width: 1200px) and (max-width: 1400px) {
		max-width: 200px;
	}
` as typeof Tooltip;
export const DeleteIconButton = styled(IconButton)`
	padding: 0;
	&:hover {
		background-color: transparent;
		transform: scale(1.1);
	}
` as typeof IconButton;
export const MobileAddIconButton = styled(IconButton)`
	border-radius: 20px;
	padding: 4px;
	background: #e0e0e0;
	box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
		0px 3px 1px -2px rgba(0, 0, 0, 0.2);
` as typeof IconButton;

export const HeaderGrid = styled(Grid)`
	flex-grow: 0;
	max-width: 18%;
	flex-basis: 18%;
	padding: ${theme.spacing(0.75, 0.75)};
	display: flex;
	justify-content: flex-start;
	align-items: center;
	@media screen and (min-width: 960px) and (max-width: 1200px) {
		max-width: 24%;
		flex-basis: 24%;
	}
	@media screen and (min-width: 1200px) and (max-width: 1400px) {
		max-width: 21%;
		flex-basis: 21%;
	}
` as typeof Grid;
export const HeaderActionGrid = styled(Grid)`
	flex-grow: 0;
	max-width: 4%;
	flex-basis: 4%;
	align-content: flex-end;
	justify-content: center;
	display: flex;
	@media screen and (min-width: 960px) and (max-width: 1200px) {
		max-width: 3%;
		flex-basis: 3%;
	}
` as typeof Grid;
export const BodyContainer = styled(Grid)`
	&:nth-child(odd) {
		border-bottom: 1px solid rgba(0, 0, 0, 0.12);
	}
	&:not(:last-of-type) {
		border-bottom: 1px solid rgba(0, 0, 0, 0.12);
	}
	border-top: 1px solid rgba(0, 0, 0, 0.12);
` as typeof Grid;
export const BodyGrid = styled(HeaderGrid)`
	border-right: 1px solid rgba(0, 0, 0, 0.12);
` as typeof Grid;
export const BodyActionGrid = styled(HeaderActionGrid)`
	align-content: flex-end;
` as typeof Grid;
export const BodyListItem = styled(ListItem)`
	background-color: #ffff;
	padding: 0;
` as typeof ListItem;
export const FooterListItem = styled(ListItem)`
	justify-content: flex-end;
	background-color: #ffff;
	padding: 5px 16px;
` as typeof ListItem;
export const DescriptionListItem = styled(ListItem)`
	padding: ${theme.spacing(1, 2)};
	background-color: #ffff;
` as typeof ListItem;
export const LabHeaderGrid = styled(Grid)`
	flex-grow: 0;
	max-width: 90%;
	padding: ${theme.spacing(0.75, 2)};
	flex-basis: 90%;
	display: flex;
` as typeof Grid;
export const LabHeaderActionGrid = styled(Grid)`
	flex-grow: 0;
	max-width: 10%;
	flex-basis: 10%;
	align-content: flex-end;
	justify-content: space-around;
	display: flex;
` as typeof Grid;
export const LabBodyGrid = styled(LabHeaderGrid)`
	border-right: 1px solid rgba(0, 0, 0, 0.12);
	padding: ${theme.spacing(2)}px;
` as typeof Grid;
export const LabBodyActionGrid = styled(LabHeaderActionGrid)`
	align-content: flex-end;
` as typeof Grid;
export const StyledTextField = styled(TextField)`
	label {
		transform: translate(14px, 10px) scale(1);
	}
	input {
		padding: ${theme.spacing(1.2, 1.75)};
	}
` as typeof TextField;
export const StyledIconButton = styled(IconButton)`
	padding: 5px;
	width: 30px;
` as typeof IconButton;
export const ChatContainer = styled.iframe`
	margin: 50px 20px;
	height: 685px;
	width: 1445px;
	border-radius: 14px;
`;
export const InformationContainer = styled.section`
	text-align: center;
`;
export const TitleOffline = styled(Typography)`
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 600;
	font-size: 26px;
	margin: 20px 0 10px 0;
	line-height: 31px;
	color: #1e2469;
	letter-spacing: 0.15px;
` as typeof Typography;
export const DescriptionOffline = styled(Typography)`
	font-family: 'Roboto';
	font-style: normal;
	max-width: 308px;
	font-weight: 400;
	font-size: 16px;
	color: #1e2469;
	letter-spacing: 0.15px;
` as typeof Typography;
export const PageContainer = styled.section`
	padding: 3rem 1.5rem 1.5rem 1.5rem;
	width: 100%;
	height: 100%;
`;
export const ActionSection = styled.section`
	display: flex;
	width: 330px;
	align-items: center;
	justify-content: flex-end;
`;
export const NoInternetContainer = styled.div`
	display: flex;
	width: 1445px;
	justify-content: center;
	align-items: center;
`;

export const HospitalLogoFrame = styled.div`
	width: 87px;
	height: 61px;
	border-radius: 5.12px;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
`;
export const HospitalLogoImg = styled.img`
	width: 100%;
	object-fit: cover;
`;
export const StyledButton = styled(Button)<{
	btnColor?: string;
	txtColor?: string;
	btnHoverColor?: string;
}>`
	background-color: ${(props) => props.btnColor ?? '#00A8E8'} !important;
	color: ${(props) => props.txtColor || '#FFFFFF'} !important;

	&:hover {
		background-color: ${(props) => props.btnHoverColor || '#00A8E8'} !important;
	}
`;

export const StyledDiagnosticTitle = styled.p`
	font-size: 20px;
	font-weight: 700;
	color: rgba(0, 0, 0, 0.87);
	padding: 8px 16px;
	line-height: 1.6;
	@media screen and (min-width: 960px) {
		padding: 16px;
		line-height: 1.35;
	}
`;

export const StyledAutocomplete = styled(Autocomplete)<{
	textFieldWidth?: string;
}>`
	.MuiOutlinedInput-root {
		width: 255px;
		height: 40px;
	}

	.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input {
		padding: 0 4px;
	}

	.MuiFormControl-root,
	.MuiInputBase-root {
		width: ${(props) => (props.textFieldWidth ? props.textFieldWidth : '255px')} !important;
		height: 40px; /* You can set the height as needed */
	}

	.MuiInputLabel-root {
		font-family: Roboto;
		font-style: normal;
		font-weight: 400;
		line-height: 2px; /* 100% */
		letter-spacing: 0.15px;
		color: #00000099;
	}
`;

export const StyledTextFieldHospitalConfig = styled(TextField)<{
	textFieldWidth?: string;
}>`
	.MuiOutlinedInput-root {
		width: 255px;
		height: 40px;
	}

	.MuiFormControl-root,
	.MuiInputBase-root {
		width: ${(props) => (props.textFieldWidth ? props.textFieldWidth : '255px')} !important;
		height: 40px; /* You can set the height as needed */
	}

	.MuiInputLabel-root {
		font-family: Roboto;
		font-style: normal;
		font-weight: 400;
		line-height: 12px; /* 100% */
		letter-spacing: 0.15px;
		color: #00000099;
	}
`;

export const StyledButtonHospitalConfig = styled(Button)`
	.MuiButton-label {
		color: #1976d2;
		font-family: Roboto;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px; /* 171.429% */
		letter-spacing: 0.4px;
		text-transform: uppercase;
	}
`;

export const StyledSaveButton = styled(Button)`
	&.MuiButton-root {
		background-color: #1976d2;
		border-radius: 4px;
		box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
			0px 3px 1px -2px rgba(0, 0, 0, 0.2);
		width: 167px;
		height: 36px;

		&:hover {
			background-color: #1976d2;
		}
	}

	.MuiButton-label {
		color: #ffffff;
		font-family: Roboto;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px; /* 171.429% */
		letter-spacing: 0.4px;
		text-transform: uppercase;
	}

	:disabled {
		background-color: #0000001f;
		.MuiButton-label {
			color: #00000042;
		}
	}
`;
export const OnboardingStyled = styled.section`
	background-color: #fff;
	height: 100dvh;
	display: flex;
	justify-content: center;
	align-items: center;
	.onboarding-container {
		h1 {
			font-size: 1.2rem;
			font-weight: 500;
			color: #7b7b7b;
		}
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 1rem;
	}
	.onboarding-loader {
		width: 12px;
		height: 12px !important;
		border-radius: 50%;
		display: block;
		margin: 15px auto;
		position: relative !important;
		color: #1976d2;
		box-sizing: border-box;
		animation: animloader 2s linear infinite;
	}

	@keyframes animloader {
		0% {
			box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;
		}
		25% {
			box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 2px;
		}
		50% {
			box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 2px, -38px 0 0 -2px;
		}
		75% {
			box-shadow: 14px 0 0 2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;
		}
		100% {
			box-shadow: 14px 0 0 -2px, 38px 0 0 2px, -14px 0 0 -2px, -38px 0 0 -2px;
		}
	}
`;
