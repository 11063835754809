import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import {
	format,
	startOfMonth,
	endOfMonth,
	startOfWeek,
	endOfWeek,
	eachDayOfInterval,
	addMonths,
} from 'date-fns';
import { ArrowLeftIcon, ArrowRightIcon } from '@mui/x-date-pickers';
import moment from 'moment';

function ScrollableMonthCalendar({ onChange }: { onChange: (D: moment.Moment | Date) => void }) {
	//SelectedDate has to be controlled on this component.
	// Using selected date from parent is breaking the feature.
	// Something in parent comp is trigering multiple renders on mounting. Didn't find the cause of it.
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [currentMonth, setCurrentMonth] = useState(selectedDate);
	const [selectedBox, setSelectedBox] = useState<string | null>(null);
	const startDay = startOfWeek(startOfMonth(currentMonth), { weekStartsOn: 0 });
	const endDay = endOfWeek(endOfMonth(currentMonth), { weekStartsOn: 0 });
	const days = eachDayOfInterval({ start: startDay, end: endDay });
	const weeks: any[] = [];
	let week: any[] = [];

	days.forEach((day, index) => {
		week.push(day);

		if ((index + 1) % 7 === 0) {
			weeks.push(week);
			week = [];
		}
	});

	const handleOnChange = (date: Date) => {
		setSelectedDate(date);
		onChange(moment(date));
	};
	const handlePrevMonth = () => {
		const newMonth = startOfMonth(addMonths(currentMonth, -1));
		setSelectedDate(newMonth);
	};

	const handleNextMonth = () => {
		const newMonth = startOfMonth(addMonths(currentMonth, 1));
		setSelectedDate(newMonth);
	};

	useEffect(() => {
		setCurrentMonth(selectedDate);
	}, [selectedDate]);

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					gap: 2,
					padding: '0 16px',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						width: '100%',
						justifyContent: 'space-between',
						padding: '4px 0',
					}}
				>
					<div className="mobile-month-year-picker">
						<h3
							style={{
								fontWeight: 500,
								fontSize: 16,
								letterSpacing: '0.15px',
								display: 'flex',
								alignItems: 'center',
								position: 'absolute',
								top: 0,
								bottom: 0,
								gap: 12,
							}}
						>
							{format(selectedDate, 'MMMM yyyy')}

							<ArrowDropDownIcon
								style={{
									color: '#0000008A',
								}}
							/>
						</h3>
						<DatePicker
							views={['month', 'year']}
							minDate={new Date('2000-01-01')}
							maxDate={new Date('2100-12-31')}
							value={selectedDate}
							onChange={(newValue) => setSelectedDate(newValue!)}
						/>
					</div>
					<div style={{ display: 'inline-flex', maxWidth: '96px', gap: 16 }}>
						<ArrowLeftIcon onClick={handlePrevMonth} />
						<ArrowRightIcon onClick={handleNextMonth} />
					</div>
				</Box>
				<Box
					id="days-grid"
					sx={{
						display: 'flex',
						flexDirection: 'row',
						overflowX: 'scroll',
						width: '100%',
						'&::-webkit-scrollbar': {
							display: 'none',
						},
						gap: '16px!important',
						paddingBottom: '8px',
					}}
				>
					{weeks.map((week, i) => (
						<Box key={i} sx={{ display: 'flex', gap: '16px' }}>
							{week.map((day: Date, idx: string) => (
								<Box
									key={idx}
									sx={{
										width: 36,
										height: 56,
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
										justifyContent: 'center',
										padding: 0,
									}}
									onClick={() => {
										handleOnChange(day);
										setSelectedBox(i + '-' + idx);
									}}
								>
									<Typography
										sx={{
											color: '#00000061',
											fontSize: 12,
											minWidth: 32,
											minHeight: 24,
											padding: 0,
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
											justifyContent: 'center',
										}}
									>
										{format(day, 'EEE').charAt(0).toUpperCase()}
									</Typography>
									<Typography
										variant="body1"
										sx={{
											backgroundColor:
												selectedBox === i + '-' + idx
													? '#1976d2'
													: 'transparent',
											color:
												selectedBox === i + '-' + idx ? 'white' : 'inherit',

											minWidth: 24,
											minHeight: 24,
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
											justifyContent: 'center',
											borderRadius: 36,
											fontSize: 14,
										}}
									>
										{format(day, 'd')}
									</Typography>
								</Box>
							))}
						</Box>
					))}
				</Box>
			</Box>
		</LocalizationProvider>
	);
}

export default ScrollableMonthCalendar;
