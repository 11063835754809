// @ts-nocheck
import {
	Button,
	Card,
	CardContent,
	CardHeader,
	IconButton,
	InputAdornment,
} from '@material-ui/core';
import React, { useContext, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { LoginPulseara } from '../components/svg';
import { LoaderContext } from '../context/loader-context';
import { LoaderContextProps, RelationsContextProps, UserContextProps } from '../context/types';
import { UserContext } from '../context/user-context';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { LogingTextField, SendButton, LoginErrorText, RedErrorIcon } from '../shared/style';
import { RelationsContext } from '../context/relations-context';
import { isValidEmail } from '../shared/utilFunctions';
import useOnlineHook from '../shared/hooks/useOnlineHook';
import CreateAccountBanner from './login/CreateAccountBanner';
import useLocalStorage from 'hooks/useLocalStorage';
import useTolt from 'hooks/useTolt';

export interface CognitoError {
	code: string;
	message: string;
}
interface IUserCredentials {
	username: string;
	password: string;
}

const Login: FC<{
	location: any;
}> = ({ location }) => {
	const { setAlertMessage } = useContext(RelationsContext) as RelationsContextProps;
	const { showSpinner } = useContext(LoaderContext) as LoaderContextProps;
	const { logIn } = useContext(UserContext) as UserContextProps;
	const [userInfo, setUserInfo] = useState<IUserCredentials>({
		username: '',
		password: '',
	});
	const [userError, setUserError] = useState({
		usernameError: '',
		passwordError: '',
	});
	const [isFirstTime, setIsFirstTime] = useState(true);
	const history = useHistory();
	const [showPassword, setShowPassword] = useState(false);
	const [errorLoginText, setErrorLoginText] = useState<string>('');
	const onlineStatus = useOnlineHook();
	const cardContentRef = useRef<HTMLDivElement>(null);
	const { username, password } = userInfo;
	const userNameFormatted = username.trim();
	const [storedValue] = useLocalStorage('username', userNameFormatted);
	const { userNameStored } = useTolt('ref');
	useEffect(() => {
		if (storedValue || userNameStored) {
			setUserInfo((prevUser) => ({
				...prevUser,
				username: userNameStored ?? storedValue,
			}));
			cardContentRef.current?.querySelector<HTMLInputElement>('#password')?.focus();
		}
	}, []);

	useEffect(() => {
		if (onlineStatus) {
			if (!isFirstTime) {
				setAlertMessage({
					message: 'Conectado!',
					severity: 'success',
				});
			}
		} else {
			setIsFirstTime(false);
			setAlertMessage({
				message: 'Sin internet. Para iniciar sección debes de conectarte a internet.',
				severity: 'warning',
			});
		}
	}, [onlineStatus]);

	const onValidFormHandler = () => {
		let valid = true;
		if (!isValidEmail(userNameFormatted)) {
			setUserError((prevUserErr) => ({
				...prevUserErr,
				usernameError: 'El correo electrónico no es válido.',
			}));
			valid = false;
		}
		if (password === '') {
			setUserError((prevUserErr) => ({
				...prevUserErr,
				passwordError: 'La contraseña es requerida.',
			}));
			valid = false;
		}
		if (userNameFormatted === '') {
			setUserError((prevUserErr) => ({
				...prevUserErr,
				usernameError: 'El correo electrónico es requerido.',
			}));
			valid = false;
		}
		return valid;
	};

	const onSubmitHandler = async (event: any) => {
		event.preventDefault();
		showSpinner(true);
		try {
			if (onValidFormHandler()) {
				await logIn(userNameFormatted, password, setErrorLoginText);
			}
		} catch (e: any) {
			const error = e as CognitoError;
			if (error.code === 'UserNotConfirmedException') {
				showSpinner(false);
				history.push('/confirm', { username, password });
				return;
			}
			showSpinner(false);
			if (error.code === 'InvalidParameterException') {
				return;
			}
			if (error.code === 'UserNotFoundException') {
				setUserError({
					usernameError: 'El correo electrónico no está registrado.',
					passwordError: '',
				});
				return;
			}
			if (error.message == 'Incorrect username or password.') {
				setErrorLoginText('Correo electrónico o contraseña incorrecta.');
				return;
			} else {
				//default error message
				setErrorLoginText(
					'Ha ocurrido un error de nuestro lado, por favor intenta de nuevo. Si el error persiste, favor contactarnos'
				);
			}
		} finally {
			showSpinner(false);
		}
	};

	const onChangeEmailHandler = ({ target }: any) => {
		setUserInfo((userInfo) => ({ ...userInfo, username: target.value }));
		setErrorLoginText('');
		setUserError((prev) => ({ ...prev, usernameError: '' }));
	};

	const onChangePasswordHandler = ({ target }: any) => {
		setUserInfo((userInfo) => ({ ...userInfo, password: target.value }));
		setErrorLoginText('');
		setUserError((prev) => ({ ...prev, passwordError: '' }));
	};

	const handleClickShowPassword = () => {
		setShowPassword((prevShowPassword) => !prevShowPassword);
	};

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	const handleForgotPassword = () => {
		history.push('/forgot-password');
	};

	const handleEnterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			onSubmitHandler(event);
		}
	};

	const enableLoginButton = () => {
		return navigator.onLine && userInfo.username !== '' && userInfo.password !== '';
	};

	return (
		<div className="relative top-0 left-0 w-full h-screen min-wh-login flex flex-col items-center justify-center px-[20px]">
			<div className="mb-[40px] md:mb-16">
				<div>
					<LoginPulseara className="w-[188px] md:w-[277px] h-[45px]" />
				</div>
			</div>
			<Card className="flex flex-col bg-white-section md:p-8 rounded-md max-w-[490px] w-full">
				<CardHeader title="Iniciar sesión" className="w-full text-center" />
				<CardContent className="flex flex-col" ref={cardContentRef}>
					<LogingTextField
						value={userInfo.username}
						onChange={onChangeEmailHandler}
						type="text"
						id="username"
						onKeyPress={handleEnterPress}
						variant="outlined"
						name="username"
						label="Email"
						placeholder="Ej. alan@gmail.com"
						error={userError.usernameError !== ''}
						helperText={userError.usernameError}
					/>
					<LogingTextField
						value={userInfo.password}
						type={showPassword ? 'text' : 'password'}
						onChange={onChangePasswordHandler}
						label="Contraseña"
						variant="outlined"
						id="password"
						onKeyPress={handleEnterPress}
						name="password"
						placeholder="Escribe tu contraseña"
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										size="small"
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
										edge="end"
									>
										{showPassword ? (
											<Visibility fontSize="small" />
										) : (
											<VisibilityOff fontSize="small" />
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
						error={userError.passwordError !== ''}
						helperText={userError.passwordError}
					/>
					{errorLoginText !== '' && (
						<LoginErrorText variant="body2">
							<RedErrorIcon /> {errorLoginText}
						</LoginErrorText>
					)}
					<SendButton
						size="large"
						variant="contained"
						color="primary"
						fullWidth
						onClick={onSubmitHandler}
						disabled={!enableLoginButton()}
					>
						Entrar
					</SendButton>
					<Button
						size="large"
						color="primary"
						fullWidth
						onClick={handleForgotPassword}
						disabled={!navigator.onLine}
					>
						Olvidé mi contraseña
					</Button>
				</CardContent>
			</Card>
			<CreateAccountBanner />
		</div>
	);
};

export default Login;
