// @ts-nocheck
import React, { useState, useContext, useEffect } from 'react';
import { default as Amplify } from '@aws-amplify/core';
import {
	ClientType,
	ClientContextProps,
	IdataForm,
	RelationsContextProps,
	PrescriptionType,
	ClaimType,
	MedicineType,
	ClientImageType,
	ClientHistoryType,
	ClientPrescriptionType,
	FilesType,
	ConsultationType,
	PrescriptionAnalisisType,
	IPaginatedClients,
	ConsultationDiagnosticType,
	IClientFilter,
	LoaderContextProps,
	DoctorType,
} from './types';
import {
	Client,
	HospitalDoctorCliente,
	Insurance,
	ClientNotes,
	SexType,
	ClientHistory,
	ClaimProcess,
	ClientProcedureSurgery,
	ClientImage,
	ClientPrescriptionMedicine,
	ClientPrescriptionAnalisis,
	LazyClientFreePrescription as ClientFreePrescription,
	ClientPrescription,
	ClientInsurance,
	AffiliateTypes,
	PrescriptionTypes,
	RecentDoctorMedicinePrescription,
	ClientConsultation,
	ConsultationDiagnostic,
	DiagnosticTypes,
	Appointment,
	Status,
} from 'models';
import { RelationsContext } from './relations-context';
import Storage from '@aws-amplify/storage';
import { useHistory } from 'react-router-dom';
import { getUniqueValues, isDoctor, isDoctorMember } from '../shared/utilFunctions';
import { CreateAppointmentInput, IdentificationTypes } from 'API';
import { HistoryContext, HistoryContextProps } from './history-context';
import moment from 'moment';
import {
	syncClaimProcesses,
	syncClientClaims,
	syncClientConsultations,
	syncClientHistories,
	syncClientImageSections,
	syncClientImages,
	syncClientInsurances,
	syncClientNotes,
	syncClientPrescriptionAnalises,
	syncClientPrescriptionMedicines,
	syncClientPrescriptions,
	syncClientFreePrescriptions,
	syncClientProcedureSurgeries,
	syncClients,
	syncConsultationDiagnostics,
	syncDoctorLabImages,
	syncDoctorMedicines,
	syncDoctorRecentDiagnoses,
	syncHospitalDoctorClientes,
	syncInsurances,
	syncRecentDoctorLabImagePrescriptions,
	syncRecentDoctorMedicinePrescriptions,
	syncAppointments,
	syncMemberHospitalDoctorClients,
} from 'graphql/queries';
import useAPI from 'hooks/API';
import {
	createDoctorRecentDiagnosis,
	updateClient as updateClientMutation,
	updateDoctorRecentDiagnosis,
	updateRecentDoctorMedicinePrescription,
	createClientPrescription as createClientPrescriptionMutation,
	createRecentDoctorMedicinePrescription,
	createClientPrescriptionMedicine,
	updateDoctorLabImage,
	updateRecentDoctorLabImagePrescription,
	createRecentDoctorLabImagePrescription,
	createClientConsultation as createClientConsultationMutation,
	createConsultationDiagnostic,
	updateConsultationDiagnostic,
	updateClientPrescription,
	updateClientPrescriptionMedicine,
	createClientPrescriptionAnalisis,
	updateClientPrescriptionAnalisis,
	updateClientConsultation as updateClientConsultationMutation,
	createClient as createClientMutation,
	createClientInsurance,
	updateClientHistory as updateClientHistoryMutation,
	updateClientNotes as updateClientNotesMutation,
	createHospitalDoctorCliente,
	updateClientInsurance,
	updateHospitalDoctorCliente,
	createClientHistory,
	createClientNotes,
	updateClientProcedureSurgery,
	createClientProcedureSurgery,
	updateClientClaim,
	createClientClaim,
	createClaimProcess,
	updateClientImageSection,
	updateClientImage,
	createClientImageSection,
	createClientImage,
	createClientFreePrescription as createClientFreePrescriptionMutation,
	updateClientFreePrescription,
	createAppointment,
	updateAppointment,
	createMemberHospitalDoctorClient,
	updateMemberHospitalDoctorClient,
} from 'graphql/mutations';
import {
	createSecretaryHospitalDoctorClient,
	syncClientConsultationsByMainPage,
	syncHospitalDoctorClientesByIdIdentification,
	syncHospitalDoctorClientesForEditClient,
	syncHospitalDoctorClientesForFetchPaginatedClientsByName,
	syncHospitalDoctorClientesPaginated,
	syncSecretaryHospitalDoctorClients,
	updateSecretaryHospitalDoctorClient,
} from 'customQueries/client-custom-queries';
import { LoaderContext } from './loader-context';
import {
	CustomQueryRecentDoctorMedicinePrescription,
	CustomQuerySyncRecentDoctorMedicinePrescriptionsQueryResponse,
} from 'customQueries/types';
import {
	EXAMPLE_CLIENT_INFORMATION,
	EXAMPLE_CLIENT_CONSULTATION,
	EXAMPLE_CLIENT_CONSULTATION_DIAGNOSTICS,
} from 'shared/constants';

export const ClientsContext = React.createContext<Partial<ClientContextProps>>({});

const ContextProvider: React.FC = (props) => {
	const history = useHistory();
	const { Auth } = Amplify;
	const [clients, setClients] = useState<ClientType[] | null>([] as ClientType[]);
	const [selectedClient, setSelectedClient] = useState<ClientType | null>(null);
	const [imageLoading, setImageLoading] = useState(false);
	const [loadClientEditModal, setLoadClientEditModal] = useState(false);
	const [onboardingData, setOnboardingData] = useState<any>(null);
	const [paginatedClients, setPaginatedClients] = useState<IPaginatedClients>({
		clients: [],
		token: [
			{
				page: 1,
				token: '',
			},
		],
		pageCount: 0,
		pageIndex: 0,
		pageSize: 0,
		totalItemCount: 0,
	});
	const [loadingConsultations, setLoadingConsultations] = useState(false);
	const [loadingPrescriptions, setLoadingPrescriptions] = useState(false);
	const [loadingClientHistory, setLoadingClientHistory] = useState(false);
	const [loadingClientClaims, setLoadingClientClaims] = useState(false);
	const [clientClaims, setClientClaims] = useState<any>([]);
	const [clientConsultations, setClientConsultations] = useState<any>([]);
	const [clientPrescriptions, setClientPrescriptions] = useState<any>([]);
	const [clientHistory, setClientHistory] = useState<any>(null);
	const [clientFiles, setClientFiles] = useState<any>([]);
	const [page, setPage] = useState(0);
	const [nextCursor, setNextCursor] = useState('');
	const [rowsPerPage] = useState(30);
	const [prescriptionToAdd, setPrescriptionToAdd] = useState<
		PrescriptionType | PrescriptionType[] | null
	>(null);
	const [clientsLoading, setClientsLoading] = useState<boolean>(false);
	const relationsContext = useContext(RelationsContext) as RelationsContextProps;
	const {
		fetchAlergyByClientId,
		fetchDiseaseByClientId,
		fetchProcedureByClientId,
		fetchHospitalizationByClientId,
		fetchFamilyHistoryByClientId,
		fetchLifeStyleByClientId,
		createPatientAllergy,
		createPatientDisease,
		createPatientProcedure,
		createPatientHospitalization,
		createPatientFamilyHistory,
		createPatientLifeStyle,
	} = useContext(HistoryContext) as HistoryContextProps;
	const [editingClient, setEditingClient] = useState<boolean>(false);
	const [modalClientState, setModalClientState] = useState<boolean>(false);

	const { showSpinner } = useContext(LoaderContext) as LoaderContextProps;
	const { execute } = useAPI();

	const clearClientInformation = () => {
		setSelectedClient(null);
		setClientConsultations([]);
		setClientPrescriptions([]);
		setClientClaims([]);
		setClientHistory(null);
		setClientFiles([]);
	};

	const handleUpdateRecentDoctorMedicinePrescription = async (
		recentDoctorMedicine: CustomQueryRecentDoctorMedicinePrescription,
		newRecentDoctorMedicine: RecentDoctorMedicinePrescription
	) => {
		try {
			await execute(updateRecentDoctorMedicinePrescription, {
				input: {
					id: recentDoctorMedicine.id,
					take: newRecentDoctorMedicine.take,
					indication: newRecentDoctorMedicine.indication,
					duration: newRecentDoctorMedicine.duration,
					description: newRecentDoctorMedicine.description,
					_version: recentDoctorMedicine._version,
				},
			});
		} catch (error) {
			relationsContext.handleError(error, false);
		}
	};

	const areAllVitalSignsPresent = (vitalSignsUpdated: any): boolean => {
		return (
			vitalSignsUpdated.height ||
			vitalSignsUpdated.weight ||
			vitalSignsUpdated.bloodPressure ||
			vitalSignsUpdated.heartRate ||
			vitalSignsUpdated.respiratoryRate ||
			vitalSignsUpdated.oxygenSaturation ||
			vitalSignsUpdated.bodyTemperature ||
			vitalSignsUpdated.bodyMass
		);
	};

	const updateClientVitalSigns = async (consultation: ClientConsultation) => {
		try {
			if (selectedClient) {
				const currentClientResponse = await execute(syncClients, {
					filter: {
						id: {
							eq: selectedClient.id,
						},
					},
				});
				const currentClient = currentClientResponse.data.syncClients.items[0];
				if (currentClient && areAllVitalSignsPresent(consultation)) {
					await execute(updateClientMutation, {
						input: {
							id: currentClient.id,
							vitalSignsWeight:
								consultation.weight !== ''
									? consultation.weight
									: currentClient.vitalSignsWeight,
							vitalSignsHeight:
								consultation.height !== ''
									? consultation.height
									: currentClient.vitalSignsHeight,
							bodyTemperature:
								consultation.bodyTemperature !== ''
									? consultation.bodyTemperature
									: currentClient.bodyTemperature,
							vitalSignsBloodPressure:
								consultation.bloodPressure !== ''
									? consultation.bloodPressure
									: currentClient.vitalSignsBloodPressure,
							bodyMass:
								consultation.bodyMass !== ''
									? consultation.bodyMass
									: currentClient.bodyMass,
							respiratoryRate:
								consultation.respiratoryRate !== ''
									? consultation.respiratoryRate
									: currentClient.respiratoryRate,
							oxygenSaturation:
								consultation.oxygenSaturation !== ''
									? consultation.oxygenSaturation
									: currentClient.oxygenSaturation,
							heartRate:
								consultation.heartRate !== ''
									? consultation.heartRate
									: currentClient.heartRate,
							_version: currentClient._version,
						},
					});
					return consultation;
				}
			}
			return consultation;
		} catch (error) {
			relationsContext.handleError(error, false);
			return consultation;
		}
	};

	const upsertClientRecentDiagnosis = async (
		doctorID: string,
		diagnosis: ConsultationDiagnostic[]
	) => {
		try {
			const recentDiagnosisResponse = await execute(syncDoctorRecentDiagnoses, {
				filter: {
					doctorID: {
						eq: doctorID,
					},
				},
			});

			const recentDiagnosis = recentDiagnosisResponse.data.syncDoctorRecentDiagnoses.items;

			await Promise.all(
				diagnosis.map(async (diagnostic) => {
					const diagnosisFound = recentDiagnosis.find(
						(x: any) =>
							x.name === diagnostic.name &&
							(!diagnostic.code || x.code === diagnostic?.code)
					);
					if (!diagnosisFound) {
						await execute(createDoctorRecentDiagnosis, {
							input: {
								doctorID: doctorID,
								name: diagnostic.name as string,
								code: diagnostic.code || '',
								type: diagnostic.type,
								updateDateTime: moment().toISOString(),
							},
						});
					} else {
						await execute(updateDoctorRecentDiagnosis, {
							input: {
								id: diagnosisFound.id,
								updateDateTime: moment().toISOString(),
								_version: diagnosisFound._version,
							},
						});
					}
				})
			);
		} catch (error) {
			relationsContext.handleError(error, false);
		}
	};

	const createClientConsultation = async (
		consultation: ConsultationType,
		clientID?: string,
		hospitalID?: string,
		hideMessage?: boolean = false,
		hideLoader?: boolean = false
	) => {
		try {
			if (!hideLoader) {
				showSpinner(true);
			}
			const customFilter = await relationsContext.getCustomHospitalDoctorFilters();
			const hospitalDoctorClientResponse = await execute(syncHospitalDoctorClientes, {
				filter: {
					clientID: {
						eq: clientID || (selectedClient?.id as string),
					},
					or: customFilter,
				},
			});

			const HospitalDoctorClient =
				hospitalDoctorClientResponse.data.syncHospitalDoctorClientes.items[0];

			if (!HospitalDoctorClient) {
				relationsContext.handleError('HospitalDoctorClient not found', false);
				return;
			}

			let newMedicinePrescription: ClientPrescription = {} as ClientPrescription;
			let newAnalisisPrescription: ClientPrescription = {} as ClientPrescription;
			let newFreePrescriptionID: string = '';
			let medicinesPrescriptions = [] as ClientPrescriptionMedicine[];
			let analisisPrescriptions = [] as ClientPrescriptionAnalisis[];
			let freePrescriptions = [] as ClientFreePrescription[];
			if (
				consultation.prescription.medicines &&
				consultation.prescription.medicines.length > 0
			) {
				const prescriptionObj = {
					clientHospitalDoctorID: HospitalDoctorClient.id,
					creationDate: consultation.prescription.creationDate,
					type: PrescriptionTypes.MEDICINE,
					status: Status.ACTIVE,
				};

				const newMedicinePrescriptionResponse = await execute(
					createClientPrescriptionMutation,
					{
						input: {
							clientHospitalDoctorID: prescriptionObj.clientHospitalDoctorID,
							creationDate: prescriptionObj.creationDate,
							type: prescriptionObj.type,
							status: prescriptionObj.status,
						},
					}
				);

				newMedicinePrescription =
					newMedicinePrescriptionResponse.data.createClientPrescription;

				const medicines = consultation.prescription
					.medicines as ClientPrescriptionMedicine[];

				await Promise.all(
					getUniqueValues(medicines, 'name').map(async (m: MedicineType) => {
						const recentDoctorMedicineResponse: CustomQuerySyncRecentDoctorMedicinePrescriptionsQueryResponse =
							await execute(syncRecentDoctorMedicinePrescriptions, {
								filter: {
									medicineID: {
										eq: m.id,
									},
									status: {
										ne: Status.INACTIVE,
									},
								},
							});

						const recentDoctorMedicine =
							recentDoctorMedicineResponse.data.syncRecentDoctorMedicinePrescriptions
								.items[0];

						const doctorMedicineResponse = await execute(syncDoctorMedicines, {
							filter: {
								medicineID: {
									eq: m.medicineID,
								},
							},
						});

						const doctorMedicine =
							doctorMedicineResponse.data.syncDoctorMedicines.items[0];

						if (doctorMedicine) {
							await relationsContext.updateDoctorMedicine(doctorMedicine.id, false);
						} else {
							await relationsContext.createDoctorMedicine(m.medicineID, false);
						}

						if (recentDoctorMedicine) {
							await handleUpdateRecentDoctorMedicinePrescription(
								recentDoctorMedicine,
								{
									take: m.take,
									indication: m.indication,
									duration: m.duration,
									description: m.description,
								} as RecentDoctorMedicinePrescription
							);
						} else {
							if (
								!relationsContext.actualDoctor?.recentMedicines.some(
									(rmedicine) => rmedicine.name === m.name
								)
							) {
								const doctorPrescriptionMedicine = {
									doctorID: relationsContext.actualDoctor?.id as string,
									medicineID: m.id,
									take: m.take,
									indication: m.indication,
									duration: m.duration,
									description: m.description,
									status: Status.ACTIVE,
								};
								await execute(createRecentDoctorMedicinePrescription, {
									input: {
										doctorID: doctorPrescriptionMedicine.doctorID,
										medicineID: doctorPrescriptionMedicine.medicineID,
										take: doctorPrescriptionMedicine.take,
										indication: doctorPrescriptionMedicine.indication,
										duration: doctorPrescriptionMedicine.duration,
										description: doctorPrescriptionMedicine.description,
										status: doctorPrescriptionMedicine.status,
									},
								});
							}
						}
					})
				);

				const newMedicineList = await Promise.all(
					medicines.map(async (m: ClientPrescriptionMedicine) => {
						const prescriptionMedicine = {
							clientPrescriptionID: newMedicinePrescription.id,
							name: m.name,
							medicineID: m.medicineID,
							take: m.take,
							indication: m.indication,
							duration: m.duration,
							description: m.description,
							status: Status.ACTIVE,
						};
						const newMedicineResponse = await execute(
							createClientPrescriptionMedicine,
							{
								input: {
									clientPrescriptionID: prescriptionMedicine.clientPrescriptionID,
									name: prescriptionMedicine.name,
									medicineID: prescriptionMedicine.medicineID,
									take: prescriptionMedicine.take,
									indication: prescriptionMedicine.indication,
									duration: prescriptionMedicine.duration,
									description: prescriptionMedicine.description,
									status: prescriptionMedicine.status,
								},
							}
						);

						const newMedicine =
							newMedicineResponse.data.createClientPrescriptionMedicine;

						return newMedicine;
					})
				);

				medicinesPrescriptions = newMedicineList;
			}

			if (
				consultation.prescription.customPrescription &&
				consultation.prescription.customPrescription.length > 0
			) {
				const prescriptionObj = {
					clientHospitalDoctorID: HospitalDoctorClient.id,
					creationDate: consultation.prescription.creationDate,
					type: PrescriptionTypes.FREE_PRESCRIPTION,
					status: Status.ACTIVE,
				};

				const newFreePrescriptionResponse = await execute(
					createClientPrescriptionMutation,
					{
						input: {
							clientHospitalDoctorID: prescriptionObj.clientHospitalDoctorID,
							creationDate: prescriptionObj.creationDate,
							type: prescriptionObj.type,
							status: prescriptionObj.status,
						},
					}
				);

				const newFreePrescriptionData =
					newFreePrescriptionResponse.data.createClientPrescription;
				newFreePrescriptionID = newFreePrescriptionData.id as string;
				const customPrescriptionPayload = {
					clientPrescriptionID: newFreePrescriptionID,
					description: consultation.prescription.customPrescription[0].description,
					status: Status.ACTIVE,
				};

				const newFreePrescription = await execute(createClientFreePrescriptionMutation, {
					input: customPrescriptionPayload,
				});

				freePrescriptions = [newFreePrescription.data.createClientFreePrescription];
			}

			if (
				consultation.prescription.analisis &&
				consultation.prescription.analisis.length > 0
			) {
				const prescriptionObj = {
					clientHospitalDoctorID: HospitalDoctorClient.id,
					creationDate: consultation.prescription.creationDate,
					type: PrescriptionTypes.LABIMAGE,
					status: Status.ACTIVE,
				};

				const newAnalisisPrescriptionResponse = await execute(
					createClientPrescriptionMutation,
					{
						input: {
							clientHospitalDoctorID: prescriptionObj.clientHospitalDoctorID,
							creationDate: prescriptionObj.creationDate,
							type: prescriptionObj.type,
							status: prescriptionObj.status,
						},
					}
				);
				newAnalisisPrescription =
					newAnalisisPrescriptionResponse.data.createClientPrescription;

				const analisis = consultation.prescription.analisis as ClientPrescriptionAnalisis[];

				await Promise.all(
					getUniqueValues(analisis, 'name').map(async (cpa: PrescriptionAnalisisType) => {
						const recentDoctorLabResponse = await execute(
							syncRecentDoctorLabImagePrescriptions,
							{
								filter: {
									name: {
										eq: cpa.name,
									},
								},
							}
						);

						const recentDoctorLab =
							recentDoctorLabResponse.data.syncRecentDoctorLabImagePrescriptions
								.items[0];

						const doctorLabImageResponse = await execute(syncDoctorLabImages, {
							filter: {
								labImageID: {
									eq: cpa.labImageID as string,
								},
							},
						});

						const doctorLabImage =
							doctorLabImageResponse.data.syncDoctorLabImages.items[0];

						if (!doctorLabImage) {
							await relationsContext.createDoctorLabImage(cpa.labImageID as string);
						} else {
							await execute(updateDoctorLabImage, {
								input: {
									id: doctorLabImage.id,
									labImageID: cpa.labImageID,
									_version: doctorLabImage._version,
								},
							});
						}

						if (recentDoctorLab) {
							execute(updateRecentDoctorLabImagePrescription, {
								input: {
									id: recentDoctorLab.id,
									description: cpa.description,
									_version: recentDoctorLab._version,
								},
							});
						} else {
							const doctorPrescriptionLabImage = {
								doctorID: relationsContext.actualDoctor?.id as string,
								labImageID: cpa.labImageID as string,
								name: cpa.name,
								description: cpa.description,
							};
							await execute(createRecentDoctorLabImagePrescription, {
								input: {
									doctorID: doctorPrescriptionLabImage.doctorID,
									labImageID: doctorPrescriptionLabImage.labImageID,
									name: doctorPrescriptionLabImage.name,
									description: doctorPrescriptionLabImage.description,
								},
							});
						}
					})
				);

				const newAnalisisList = await Promise.all(
					analisis.map(async (cpa: ClientPrescriptionAnalisis) => {
						const prescriptionAnalisis = {
							clientPrescriptionID: newAnalisisPrescription.id,
							name: cpa.name,
							description: cpa.description,
							status: Status.ACTIVE,
						};
						const newAnalisisResponse = await execute(
							createClientPrescriptionAnalisis,
							{
								input: {
									clientPrescriptionID: newAnalisisPrescription.id,
									name: prescriptionAnalisis.name,
									description: prescriptionAnalisis.description,
									status: prescriptionAnalisis.status,
								},
							}
						);

						const newAnalisis =
							newAnalisisResponse.data.createClientPrescriptionAnalisis;

						return newAnalisis;
					})
				);

				analisisPrescriptions = newAnalisisList;
			}

			const consultationObj = {
				clientHospitalDoctorID: HospitalDoctorClient.id,
				visitReason: consultation.visitReason,
				visitDescription: consultation.visitDescription,
				physicTestNote: consultation.physicTestNote,
				bloodPressure: consultation.bloodPressure,
				heartRate: consultation.heartRate,
				weight: consultation.weight,
				height: consultation.height,
				oxygenSaturation: consultation.oxygenSaturation,
				bodyMass: consultation.bodyMass,
				bodyTemperature: consultation.bodyTemperature,
				respiratoryRate: consultation.respiratoryRate,
				clientMedicinePrescriptionID: newMedicinePrescription && newMedicinePrescription.id,
				clientAnalisisPrescriptionID: newAnalisisPrescription && newAnalisisPrescription.id,
				clientFreePrescriptionID: newFreePrescriptionID,
				treatment: consultation.treatment,
				procedureName: consultation.procedureName,
				procedureDescription: consultation.procedureDescription,
				createdAt: consultation.createdAt,
				updatedAt: consultation.updatedAt,
				status: Status.ACTIVE,
			};

			const newClientConsultationResponse = await execute(createClientConsultationMutation, {
				input: {
					clientHospitalDoctorID: consultationObj.clientHospitalDoctorID,
					visitReason: consultationObj.visitReason,
					visitDescription: consultationObj.visitDescription,
					physicTestNote: consultationObj.physicTestNote,
					bloodPressure: consultationObj.bloodPressure,
					heartRate: consultationObj.heartRate,
					weight: consultationObj.weight,
					height: consultationObj.height,
					oxygenSaturation: consultationObj.oxygenSaturation,
					bodyMass: consultationObj.bodyMass,
					bodyTemperature: consultationObj.bodyTemperature,
					respiratoryRate: consultationObj.respiratoryRate,
					clientMedicinePrescriptionID: consultationObj.clientMedicinePrescriptionID,
					clientAnalisisPrescriptionID: consultationObj.clientAnalisisPrescriptionID,
					clientFreePrescriptionID: newFreePrescriptionID,
					treatment: consultationObj.treatment,
					procedureName: consultationObj.procedureName,
					procedureDescription: consultationObj.procedureDescription,
					createdAt: consultationObj.createdAt,
					updatedAt: consultationObj.updatedAt,
					status: consultationObj.status,
					doctorID: relationsContext.actualDoctor?.id as string,
				},
			});

			const newClientConsultation =
				newClientConsultationResponse.data.createClientConsultation;

			const vitalSignsUpdated = await updateClientVitalSigns(
				consultation as ClientConsultation
			);

			let diagnostics = [] as ConsultationDiagnosticType[];
			if (consultation.diagnostics.length > 0) {
				await upsertClientRecentDiagnosis(
					relationsContext.actualDoctor?.id as string,
					consultation.diagnostics
				);
				const newDiagnosticList = await Promise.all(
					consultation.diagnostics.map(async (diagnostic) => {
						const diagnosticObj = {
							clientConsultationID: newClientConsultation.id,
							name: diagnostic.name,
							code: diagnostic.code || '',
							type: DiagnosticTypes.PRESUMPTIVE,
							status: Status.ACTIVE,
						};

						const newDiagnosticResponse = await execute(createConsultationDiagnostic, {
							input: {
								clientConsultationID: diagnosticObj.clientConsultationID,
								name: diagnosticObj.name,
								code: diagnosticObj.code,
								type: diagnosticObj.type,
								status: diagnosticObj.status,
							},
						});
						const newDiagnostic =
							newDiagnosticResponse.data.createConsultationDiagnostic;
						return newDiagnostic;
					})
				);
				diagnostics = newDiagnosticList;
			}

			if (consultation.diffDiagnostics.length > 0) {
				await upsertClientRecentDiagnosis(
					relationsContext.actualDoctor?.id as string,
					consultation.diffDiagnostics
				);
				const newDiagnosticList = await Promise.all(
					consultation.diffDiagnostics.map(async (diagnostic) => {
						const diagnosticObj = {
							clientConsultationID: newClientConsultation.id,
							name: diagnostic.name,
							code: diagnostic.code || '',
							type: DiagnosticTypes.DIFFERENTIAL,
							status: Status.ACTIVE,
						};

						const newDiagnosticResponse = await execute(createConsultationDiagnostic, {
							input: {
								clientConsultationID: diagnosticObj.clientConsultationID,
								name: diagnosticObj.name,
								code: diagnosticObj.code,
								type: diagnosticObj.type,
								status: diagnosticObj.status,
							},
						});

						const newDiagnostic =
							newDiagnosticResponse.data.createConsultationDiagnostic;
						return newDiagnostic;
					})
				);
				diagnostics = [...diagnostics, ...newDiagnosticList];
			}
			const newPrescriptions: ClientPrescriptionType[] = [];
			if (medicinesPrescriptions.length > 0) {
				newPrescriptions.push({
					...newMedicinePrescription,
					type: PrescriptionTypes.MEDICINE,
					title: '1 - Prescripción: Medicamentos',
					date: consultation.prescription.creationDate,
					list: medicinesPrescriptions,
				} as ClientPrescriptionType);
			}
			if (analisisPrescriptions.length > 0) {
				newPrescriptions.push({
					...newAnalisisPrescription,
					type: PrescriptionTypes.LABIMAGE,
					title: `${medicinesPrescriptions.length ? 2 : 1} - Prescripción: Lab/Imágenes`,
					date: consultation.prescription.creationDate,
					list: analisisPrescriptions,
				} as ClientPrescriptionType);
			}

			if (freePrescriptions.length > 0) {
				newPrescriptions.push({
					type: PrescriptionTypes.FREE_PRESCRIPTION,
					title: 'Prescripción: Libre',
					date: consultation.prescription.creationDate,
					list: freePrescriptions,
				} as ClientPrescriptionType);
			}

			if (areAllVitalSignsPresent(consultation)) {
				setSelectedClient((prevClient) => {
					return {
						...prevClient,
						vitalSignsHeight:
							vitalSignsUpdated.height !== ''
								? vitalSignsUpdated.height
								: prevClient?.vitalSignsHeight,
						vitalSignsWeight:
							vitalSignsUpdated.weight !== ''
								? vitalSignsUpdated.weight
								: prevClient?.vitalSignsWeight,
						vitalSignsBloodPressure:
							vitalSignsUpdated.bloodPressure !== ''
								? vitalSignsUpdated.bloodPressure
								: prevClient?.vitalSignsBloodPressure,
						heartRate:
							vitalSignsUpdated.heartRate !== ''
								? vitalSignsUpdated.heartRate
								: prevClient?.heartRate,
						respiratoryRate:
							vitalSignsUpdated.respiratoryRate !== ''
								? vitalSignsUpdated.respiratoryRate
								: prevClient?.respiratoryRate,
						oxygenSaturation:
							vitalSignsUpdated.oxygenSaturation !== ''
								? vitalSignsUpdated.oxygenSaturation
								: prevClient?.oxygenSaturation,
						bodyTemperature:
							vitalSignsUpdated.bodyTemperature !== ''
								? vitalSignsUpdated.bodyTemperature
								: prevClient?.bodyTemperature,
						bodyMass:
							vitalSignsUpdated.bodyMass !== ''
								? vitalSignsUpdated.bodyMass
								: prevClient?.bodyMass,
					} as ClientType;
				});
			}

			const newConsultationData = {
				...newClientConsultation,
				diagnostics: diagnostics,
				diffDiagnostics: diagnostics,
				prescription: {
					...consultation.prescription,
					medicines: medicinesPrescriptions,
					analisis: analisisPrescriptions,
					freePrescription: freePrescriptions,
				},
			};

			if (clientConsultations.length === 0) {
				await getConsultationsData(clientID, hospitalID);
			} else {
				setClientConsultations((prevConsultations: any) => {
					return [newConsultationData, ...prevConsultations];
				});
			}
			if (newConsultationData) {
				setSelectedClient((prevClient) => {
					return {
						...prevClient,
						consultations: [newConsultationData, ...(prevClient?.consultations || [])],
						prescriptions: newPrescriptions,
					} as ClientType;
				});
			}

			await getPrescriptionsData(clientID, hospitalID);
			if (!hideMessage) {
				relationsContext.setAlertMessage({
					message: 'Consulta creada satisfactoriamente.',
					severity: 'success',
				});
			}
		} catch (error) {
			relationsContext.handleError(error, false);
		} finally {
			showSpinner(false);
		}
	};

	const updateClientEmailOnAppointment = async (
		appointment: Appointment & { email: string; clientVersion: number }
	) => {
		if (appointment.shouldSendReminder && appointment.email) {
			await execute(updateClientMutation, {
				input: {
					id: appointment.clientID!,
					email: appointment.email,
					_version: appointment._version!,
				},
			});
		}
	};

	const createClientAppointment = async (
		appointment: Appointment & { email: string; clientVersion: number },
		docId: string | null = null
	) => {
		try {
			showSpinner(true);

			const startString = `${appointment.date} ${appointment.start}`.replaceAll('-', '/');
			const endString = `${appointment.date} ${appointment.end}`.replaceAll('-', '/');
			const input: CreateAppointmentInput = {
				clientID: appointment.clientID!,
				description: appointment.description.trim(),
				date: appointment.date,
				start: moment(startString).toISOString(),
				end: moment(endString).toISOString(),
				status: Status.ACTIVE,
				shouldSendReminder: appointment.shouldSendReminder,
				doctorID: docId ?? relationsContext.actualDoctor?.id!,
				hospitalID: relationsContext.actualHospitalDoctor?.hospitalID!,
				createdAt: moment().toISOString(),
				updatedAt: moment().toISOString(),
			};

			await updateClientEmailOnAppointment(appointment);

			const response = await execute(createAppointment, { input: input });
			relationsContext.setAlertMessage({
				message: 'Cita creada satisfactoriamente.',
				severity: 'success',
			});

			if (selectedClient && selectedClient.id === appointment.clientID) {
				const createdAppointment = response.data.createAppointment;
				const newSelectedClient = {
					...selectedClient,
					appointments: [...selectedClient.appointments!, createdAppointment],
				};

				newSelectedClient.email =
					appointment.shouldSendReminder && appointment.email
						? appointment.email
						: selectedClient.email;

				setSelectedClient(newSelectedClient);
			}
		} catch (error) {
			relationsContext.setAlertMessage({
				message: 'Error al crear la cita.',
				severity: 'error',
			});
		} finally {
			showSpinner(false);
		}
	};

	const updateClientAppointment = async (
		appointment: Appointment & { email: string; clientVersion: number }
	) => {
		try {
			showSpinner(true);

			const startString = `${appointment.date} ${appointment.start}`.replaceAll('-', '/');
			const endString = `${appointment.date} ${appointment.end}`.replaceAll('-', '/');
			const input: CreateAppointmentInput = {
				id: appointment.id!,
				clientID: appointment.clientID!,
				description: appointment.description.trim(),
				date: appointment.date,
				start: moment(startString).toISOString(),
				end: moment(endString).toISOString(),
				shouldSendReminder: appointment.shouldSendReminder,
				updatedAt: moment().toISOString(),
				_version: appointment._version!,
			};

			await updateClientEmailOnAppointment(appointment);

			const response = await execute(updateAppointment, { input: input });

			if (selectedClient && selectedClient.id === appointment.clientID) {
				const updatedAppointment = response.data.updateAppointment;
				const newAppointments = selectedClient.appointments!.map((a) =>
					a.id === appointment.id ? updatedAppointment : a
				);

				const newSelectedClient = {
					...selectedClient,
					appointments: newAppointments,
				};

				newSelectedClient.email =
					appointment.shouldSendReminder && appointment.email
						? appointment.email
						: selectedClient.email;

				setSelectedClient(newSelectedClient);
			}

			relationsContext.setAlertMessage({
				message: 'Cita actualizada satisfactoriamente.',
				severity: 'success',
			});
		} catch (error) {
			relationsContext.setAlertMessage({
				message: 'Error al actualizar la cita.',
				severity: 'error',
			});
		} finally {
			showSpinner(false);
		}
	};

	const deleteClientAppointment = async (appointment: Appointment) => {
		try {
			showSpinner(true);
			await execute(updateAppointment, {
				input: {
					id: appointment.id!,
					status: Status.INACTIVE,
					_version: appointment._version!,
				},
			});

			if (
				selectedClient &&
				selectedClient.appointments.some((a) => a.id === appointment.id)
			) {
				const newSelectedClient = {
					...selectedClient,
					appointments: selectedClient.appointments!.filter(
						(a) => a.id !== appointment.id
					),
				};
				setSelectedClient(newSelectedClient);
			}

			relationsContext.setAlertMessage({
				message: 'Cita eliminada satisfactoriamente.',
				severity: 'success',
			});
		} catch (error) {
			relationsContext.setAlertMessage({
				message: 'Error al eliminar la cita.',
				severity: 'error',
			});
		} finally {
			showSpinner(false);
		}
	};

	const updateClientConsultation = async (
		consultationID: string,
		consultationUpdated: ConsultationType
	) => {
		try {
			showSpinner(true);
			const customFilter = await relationsContext.getCustomHospitalDoctorFilters();
			const hospitalDoctorClientResponse = await execute(syncHospitalDoctorClientes, {
				filter: {
					clientStatus: {
						ne: Status.INACTIVE,
					},
					or: customFilter,
				},
			});

			const HospitalDoctorClient =
				hospitalDoctorClientResponse.data.syncHospitalDoctorClientes.items[0];

			const clientConsultation = clientConsultations!.find(
				(c: any) => c.id === consultationID
			);

			const consultationResponse = await execute(syncClientConsultations, {
				filter: {
					id: {
						eq: consultationID,
					},
					status: {
						ne: Status.INACTIVE,
					},
				},
			});

			const consultation = consultationResponse.data.syncClientConsultations.items[0];

			const consultationUpdateArray = consultationUpdated.diagnostics.map((d) => d.id);
			const consultationUpdateDiffArray = consultationUpdated.diffDiagnostics.map(
				(d) => d.id
			);

			// If on updated consultation there is not a diagnotic id it will remove from the list of diagnostic

			try {
				const clientConsultationFiltered = clientConsultation?.diagnostics?.filter(
					(d: any) =>
						d.type === DiagnosticTypes.PRESUMPTIVE &&
						!consultationUpdateArray.includes(d.id)
				);
				if (clientConsultationFiltered && clientConsultationFiltered?.length > 0) {
					await Promise.all(
						clientConsultationFiltered.map(async (d: any) => {
							const currentConsultationDiagnosticResponse = await execute(
								syncConsultationDiagnostics,
								{
									filter: {
										id: {
											eq: d?.id,
										},
									},
								}
							);

							const currentConsultationDiagnostic =
								currentConsultationDiagnosticResponse.data
									.syncConsultationDiagnostics.items[0];
							if (currentConsultationDiagnostic) {
								await execute(updateConsultationDiagnostic, {
									input: {
										id: currentConsultationDiagnostic.id,
										status: Status.INACTIVE,
										_version: currentConsultationDiagnostic._version,
									},
								});
							}
						})
					);
				}

				const clientConsultationDifferentialFiltered =
					clientConsultation?.diagnostics?.filter(
						(d: any) =>
							d.type === DiagnosticTypes.DIFFERENTIAL &&
							!consultationUpdateDiffArray.includes(d.id)
					);

				if (
					clientConsultationDifferentialFiltered &&
					clientConsultationDifferentialFiltered?.length > 0
				) {
					await Promise.all(
						clientConsultationDifferentialFiltered.map(async (d: any) => {
							const currentConsultationDiagnosticResponse = await execute(
								syncConsultationDiagnostics,
								{
									filter: {
										id: {
											eq: d?.id,
										},
									},
								}
							);

							const currentConsultationDiagnostic =
								currentConsultationDiagnosticResponse.data
									.syncConsultationDiagnostics.items[0];

							if (currentConsultationDiagnostic) {
								await execute(updateConsultationDiagnostic, {
									input: {
										id: currentConsultationDiagnostic.id,
										status: Status.INACTIVE,
										_version: currentConsultationDiagnostic._version,
									},
								});
							}
						})
					);
				}
			} catch (error) {
				relationsContext.setAlertMessage({
					message: 'Error al actualizar la consulta.',
					severity: 'error',
				});
				relationsContext.handleError(error, false);
				return;
			}

			try {
				// If is not a string it will create a new Diagnostic
				await upsertClientRecentDiagnosis(
					relationsContext.actualDoctor?.id as string,
					consultationUpdated?.diagnostics
				);
				consultationUpdated?.diagnostics
					.filter((diagnosis) => !(typeof diagnosis.id === 'string'))
					.map(async (diagnosis) => {
						await execute(createConsultationDiagnostic, {
							input: {
								clientConsultationID: consultationID,
								name: diagnosis.name,
								code: diagnosis.code || '',
								type: DiagnosticTypes.PRESUMPTIVE,
								status: Status.ACTIVE,
							},
						});
					});

				await upsertClientRecentDiagnosis(
					relationsContext.actualDoctor?.id as string,
					consultationUpdated?.diffDiagnostics
				);
				consultationUpdated?.diffDiagnostics
					.filter((diagnosis) => !(typeof diagnosis.id === 'string'))
					.map(async (diagnosis) => {
						await execute(createConsultationDiagnostic, {
							input: {
								clientConsultationID: consultationID,
								name: diagnosis.name,
								code: diagnosis.code || '',
								type: DiagnosticTypes.DIFFERENTIAL,
								status: Status.ACTIVE,
							},
						});
					});
			} catch (error) {
				relationsContext.setAlertMessage({
					message: 'Error al actualizar la consulta.',
					severity: 'error',
				});
				relationsContext.handleError(error, false);
				return;
			}

			// Medicines
			// If on updated consultation there is not a medicines id it will remove from the list of medicinesPrescription
			let medicineClientPrescriptionID = clientConsultation?.clientMedicinePrescriptionID;
			const consultationMedicinePrescriptions = consultationUpdated.prescription.medicines;

			const currentConsultationMedicinePrescriptionResponse = await execute(
				syncClientPrescriptionMedicines,
				{
					filter: {
						clientPrescriptionID: {
							eq: clientConsultation?.clientMedicinePrescriptionID,
						},
						status: {
							ne: Status.INACTIVE,
						},
					},
				}
			);

			const currentConsultationMedicinePrescription =
				currentConsultationMedicinePrescriptionResponse.data.syncClientPrescriptionMedicines
					.items;

			// add/update medicines to prescription and consultation
			if (consultationMedicinePrescriptions && consultationMedicinePrescriptions.length > 0) {
				if (!medicineClientPrescriptionID) {
					const prescriptionObj = {
						clientHospitalDoctorID: HospitalDoctorClient.id,
						creationDate: consultationUpdated?.prescription.creationDate,
						type: PrescriptionTypes.MEDICINE,
						status: Status.ACTIVE,
					};

					const newPrescriptionResponse = await execute(
						createClientPrescriptionMutation,
						{
							input: {
								clientHospitalDoctorID: prescriptionObj.clientHospitalDoctorID,
								creationDate: prescriptionObj.creationDate,
								type: prescriptionObj.type,
								status: prescriptionObj.status,
							},
						}
					);
					const newPrescription = newPrescriptionResponse.data.createClientPrescription;
					medicineClientPrescriptionID = newPrescription.id;
				} else {
					const currentPrescriptionResponse = await execute(syncClientPrescriptions, {
						filter: {
							id: {
								eq: medicineClientPrescriptionID as string,
							},
						},
					});

					const currentPrescription =
						currentPrescriptionResponse.data.syncClientPrescriptions.items[0];

					if (currentPrescription.status !== Status.ACTIVE) {
						await execute(updateClientPrescription, {
							input: {
								id: currentPrescription.id,
								status: Status.ACTIVE,
								_version: currentPrescription._version,
							},
						});
					}
				}

				const updateConsultationMedicinePrescriptionPromises =
					consultationMedicinePrescriptions.map(async (m) => {
						let medicinePrescription = currentConsultationMedicinePrescription.find(
							(cm: any) => cm.medicineID === m.id
						);

						if (!medicinePrescription) {
							await execute(createClientPrescriptionMedicine, {
								input: {
									clientPrescriptionID: medicineClientPrescriptionID,
									description: m.description,
									duration: m.duration,
									indication: m.indication,
									medicineID: m.medicineID,
									take: m.take,
									name: m.name,
									status: Status.ACTIVE,
								},
							});

							const currentRecentMedicineResponse = await execute(
								syncRecentDoctorMedicinePrescriptions,
								{
									filter: {
										medicineID: {
											eq: m.id,
										},
										doctorID: {
											eq: relationsContext.actualDoctor?.id as string,
										},
										status: {
											ne: Status.INACTIVE,
										},
									},
								}
							);

							const currentRecentMedicine =
								currentRecentMedicineResponse.data
									.syncRecentDoctorMedicinePrescriptions.items[0];

							if (!currentRecentMedicine) {
								const recentMedicine = {
									doctorID: relationsContext.actualDoctor?.id as string,
									medicineID: m.id,
									take: m.take,
									indication: m.indication,
									duration: m.duration,
									description: m.description,
									status: Status.ACTIVE,
								};

								await execute(createRecentDoctorMedicinePrescription, {
									input: {
										doctorID: recentMedicine.doctorID,
										medicineID: recentMedicine.medicineID,
										take: recentMedicine.take,
										indication: recentMedicine.indication,
										duration: recentMedicine.duration,
										description: recentMedicine.description,
										status: recentMedicine.status,
									},
								});
							}
						}

						if (medicinePrescription) {
							await execute(updateClientPrescriptionMedicine, {
								input: {
									id: medicinePrescription.id,
									name: m.name,
									description: m.description,
									duration: m.duration,
									take: m.take,
									indication: m.indication,
									_version: medicinePrescription._version,
								},
							});
						}
					});

				await Promise.all(updateConsultationMedicinePrescriptionPromises);
			}

			// remove and filter medicines from prescription if requested
			if (currentConsultationMedicinePrescription.length > 0) {
				const removeConsultationMedicinePrescriptionPromises =
					currentConsultationMedicinePrescription.map(async (cm: any) => {
						let medicinePrescription = consultationMedicinePrescriptions!.find(
							(m) => m.id === cm.medicineID
						);

						if (!medicinePrescription) {
							await execute(updateClientPrescriptionMedicine, {
								input: {
									id: cm.id,
									status: Status.INACTIVE,
									_version: cm._version,
								},
							});
						}
					});

				await Promise.all(removeConsultationMedicinePrescriptionPromises);

				if (
					consultationMedicinePrescriptions?.length === 0 &&
					medicineClientPrescriptionID
				) {
					const currentPrescriptionResponse = await execute(syncClientPrescriptions, {
						filter: {
							id: {
								eq: medicineClientPrescriptionID as string,
							},
							type: {
								eq: PrescriptionTypes.MEDICINE,
							},
						},
					});

					const currentPrescription =
						currentPrescriptionResponse.data.syncClientPrescriptions.items[0];

					await execute(updateClientPrescription, {
						input: {
							id: currentPrescription.id,
							status: Status.INACTIVE,
							_version: currentPrescription._version,
						},
					});
				}
			}

			// Analisis
			let analisisClientPrescriptionID = clientConsultation?.clientAnalisisPrescriptionID;
			const consultationAnalisisPrescriptions = consultationUpdated.prescription.analisis;

			const currentConsultationAnalisisPrescriptionResponse = await execute(
				syncClientPrescriptionAnalises,
				{
					filter: {
						clientPrescriptionID: {
							eq: analisisClientPrescriptionID as string,
						},
						status: {
							ne: Status.INACTIVE,
						},
					},
				}
			);

			const currentConsultationAnalisisPrescription =
				currentConsultationAnalisisPrescriptionResponse.data.syncClientPrescriptionAnalises
					.items;

			// add/update analisis to prescription and consultation
			if (consultationAnalisisPrescriptions && consultationAnalisisPrescriptions.length > 0) {
				if (!analisisClientPrescriptionID) {
					const prescriptionObj = {
						clientHospitalDoctorID: HospitalDoctorClient.id,
						creationDate: consultationUpdated?.prescription.creationDate,
						type: PrescriptionTypes.LABIMAGE,
						status: Status.ACTIVE,
					};

					const newPrescriptionResponse = await execute(
						createClientPrescriptionMutation,
						{
							input: {
								clientHospitalDoctorID: prescriptionObj.clientHospitalDoctorID,
								creationDate: prescriptionObj.creationDate,
								type: prescriptionObj.type,
								status: prescriptionObj.status,
							},
						}
					);

					const newPrescription = newPrescriptionResponse.data.createClientPrescription;

					analisisClientPrescriptionID = newPrescription.id;
				} else {
					const currentPrescriptionResponse = await execute(syncClientPrescriptions, {
						filter: {
							id: {
								eq: analisisClientPrescriptionID as string,
							},
						},
					});

					const currentPrescription =
						currentPrescriptionResponse.data.syncClientPrescriptions.items[0];

					if (currentPrescription.status !== Status.ACTIVE) {
						await execute(updateClientPrescription, {
							input: {
								id: currentPrescription.id,
								status: Status.ACTIVE,
								_version: currentPrescription._version,
							},
						});
					}
				}

				const updateConsultationAnalisisPrescriptionPromises =
					consultationAnalisisPrescriptions.map(async (lab) => {
						let analisisPrescription = currentConsultationAnalisisPrescription.find(
							(cm: any) => cm.id === lab.id
						);
						if (!analisisPrescription) {
							await execute(createClientPrescriptionAnalisis, {
								input: {
									clientPrescriptionID: analisisClientPrescriptionID,
									description: lab.description,
									name: lab.name,
									status: Status.ACTIVE,
								},
							});

							const recentDoctorLabResponse = await execute(
								syncRecentDoctorLabImagePrescriptions,
								{
									filter: {
										labImageID: {
											eq: lab.id,
										},
										doctorID: {
											eq: relationsContext.actualDoctor?.id as string,
										},
									},
								}
							);

							const recentDoctorLab =
								recentDoctorLabResponse.data.syncRecentDoctorLabImagePrescriptions
									.items[0];

							if (!recentDoctorLab) {
								await execute(createRecentDoctorLabImagePrescription, {
									input: {
										doctorID: relationsContext.actualDoctor?.id as string,
										description: lab.description,
										name: lab.name,
										labImageID: lab.id as string,
									},
								});
							}
						}

						if (analisisPrescription) {
							await execute(updateClientPrescriptionAnalisis, {
								input: {
									id: analisisPrescription.id,
									name: lab.name,
									description: lab.description,
									_version: analisisPrescription._version,
								},
							});
						}
					});

				await Promise.all(updateConsultationAnalisisPrescriptionPromises);
			}

			// remove and filter analisis from prescription if requested
			if (currentConsultationAnalisisPrescription.length > 0) {
				const removeConsultationAnalisisPrescriptionPromises =
					currentConsultationAnalisisPrescription.map(async (clab: any) => {
						let analisisPrescription = consultationAnalisisPrescriptions!.find(
							(lab) => lab.id === clab.id
						);

						if (!analisisPrescription) {
							await execute(updateClientPrescriptionAnalisis, {
								input: {
									id: clab.id,
									status: Status.INACTIVE,
									_version: clab._version,
								},
							});
						}
					});

				await Promise.all(removeConsultationAnalisisPrescriptionPromises);

				if (
					consultationAnalisisPrescriptions?.length === 0 &&
					analisisClientPrescriptionID
				) {
					const currentPrescriptionResponse = await execute(syncClientPrescriptions, {
						filter: {
							id: {
								eq: analisisClientPrescriptionID as string,
							},
							type: {
								eq: PrescriptionTypes.LABIMAGE,
							},
						},
					});

					const currentPrescription =
						currentPrescriptionResponse.data.syncClientPrescriptions.items[0];

					await execute(updateClientPrescription, {
						input: {
							id: currentPrescription.id,
							status: Status.INACTIVE,
							_version: currentPrescription._version,
						},
					});
				}
			}

			// free prescriptions management
			let analisisClientFreePrescriptionID = clientConsultation?.clientFreePrescriptionID;
			const consultationFreePrescriptions =
				consultationUpdated.prescription.customPrescription;

			const currentConsultationFreePrescriptionResponse = await execute(
				syncClientFreePrescriptions,
				{
					filter: {
						clientPrescriptionID: {
							eq: analisisClientFreePrescriptionID as string,
						},
						status: {
							ne: Status.INACTIVE,
						},
					},
				}
			);

			const currentConsultationFreePrescription =
				currentConsultationFreePrescriptionResponse.data.syncClientFreePrescriptions.items;

			// add/update free prescriptions to prescription and consultation
			if (consultationFreePrescriptions && consultationFreePrescriptions.length > 0) {
				if (!analisisClientFreePrescriptionID) {
					const prescriptionObj = {
						clientHospitalDoctorID: HospitalDoctorClient.id,
						creationDate: consultationUpdated?.prescription.creationDate,
						type: PrescriptionTypes.FREE_PRESCRIPTION,
						status: Status.ACTIVE,
					};

					const newPrescriptionResponse = await execute(
						createClientPrescriptionMutation,
						{
							input: {
								clientHospitalDoctorID: prescriptionObj.clientHospitalDoctorID,
								creationDate: prescriptionObj.creationDate,
								type: prescriptionObj.type,
								status: prescriptionObj.status,
							},
						}
					);

					const newPrescription = newPrescriptionResponse.data.createClientPrescription;

					analisisClientFreePrescriptionID = newPrescription.id;
				} else {
					const currentPrescriptionResponse = await execute(syncClientPrescriptions, {
						filter: {
							id: {
								eq: analisisClientFreePrescriptionID as string,
							},
						},
					});

					const currentPrescription =
						currentPrescriptionResponse.data.syncClientPrescriptions.items[0];

					if (currentPrescription.status !== Status.ACTIVE) {
						await execute(updateClientPrescription, {
							input: {
								id: currentPrescription.id,
								status: Status.ACTIVE,
								_version: currentPrescription._version,
							},
						});
					}
				}

				const updateConsultationFreePrescriptionPromises =
					consultationFreePrescriptions.map(async (free) => {
						let freePrescription = currentConsultationFreePrescription.find(
							(cm: any) => cm?.id === free.id
						);

						if (!freePrescription) {
							await execute(createClientFreePrescriptionMutation, {
								input: {
									clientPrescriptionID: analisisClientFreePrescriptionID,
									description: free.description,
									status: Status.ACTIVE,
								},
							});
						}

						if (freePrescription) {
							await execute(updateClientFreePrescription, {
								input: {
									id: freePrescription.id,
									description: free.description,
									_version: freePrescription._version,
								},
							});
						}
					});

				await Promise.all(updateConsultationFreePrescriptionPromises);
			}

			// remove and filter free prescriptions
			if (currentConsultationFreePrescription.length > 0) {
				const removeConsultationFreePrescriptionPromises =
					currentConsultationFreePrescription.map(async (cfree: any) => {
						let freePrescription = consultationFreePrescriptions!.find(
							(free) => free.id === cfree.id
						);

						if (!freePrescription) {
							await execute(updateClientFreePrescription, {
								input: {
									id: cfree.id,
									status: Status.INACTIVE,
									_version: cfree._version,
								},
							});
						}
					});

				await Promise.all(removeConsultationFreePrescriptionPromises);

				if (
					consultationFreePrescriptions?.length === 0 &&
					analisisClientFreePrescriptionID
				) {
					const currentPrescriptionResponse = await execute(syncClientPrescriptions, {
						filter: {
							id: {
								eq: analisisClientFreePrescriptionID as string,
							},
							type: {
								eq: PrescriptionTypes.FREE_PRESCRIPTION,
							},
						},
					});

					const currentPrescription =
						currentPrescriptionResponse.data.syncClientPrescriptions.items[0];

					await execute(updateClientPrescription, {
						input: {
							id: currentPrescription.id,
							status: Status.INACTIVE,
							_version: currentPrescription._version,
						},
					});
				}
			}

			//Update everything related to consultation
			await execute(updateClientConsultationMutation, {
				input: {
					id: consultationID,
					visitReason: consultationUpdated.visitReason,
					visitDescription: consultationUpdated.visitDescription,
					physicTestNote: consultationUpdated.physicTestNote,
					bloodPressure: consultationUpdated.bloodPressure,
					bodyTemperature: consultationUpdated.bodyTemperature,
					weight: consultationUpdated.weight,
					height: consultationUpdated.height,
					bodyMass: consultationUpdated.bodyMass,
					oxygenSaturation: consultationUpdated.oxygenSaturation,
					heartRate: consultationUpdated.heartRate,
					respiratoryRate: consultationUpdated.respiratoryRate,
					clientMedicinePrescriptionID: medicineClientPrescriptionID,
					clientAnalisisPrescriptionID: analisisClientPrescriptionID,
					clientFreePrescriptionID: analisisClientFreePrescriptionID,
					treatment: consultationUpdated.treatment,
					procedureName: consultationUpdated.procedureName,
					procedureDescription: consultationUpdated.procedureDescription,
					_version: consultation!._version,
				},
			});

			await getPrescriptionsData();

			const vitalSignsUpdated = await updateClientVitalSigns(
				consultationUpdated as ClientConsultation
			);

			setSelectedClient(
				(prevClient) =>
					({
						...prevClient,
						vitalSignsHeight:
							vitalSignsUpdated.height !== ''
								? vitalSignsUpdated.height
								: prevClient?.vitalSignsHeight,
						vitalSignsWeight:
							vitalSignsUpdated.weight !== ''
								? vitalSignsUpdated.weight
								: prevClient?.vitalSignsWeight,
						vitalSignsBloodPressure:
							vitalSignsUpdated.bloodPressure !== ''
								? vitalSignsUpdated.bloodPressure
								: prevClient?.vitalSignsBloodPressure,
						heartRate:
							vitalSignsUpdated.heartRate !== ''
								? vitalSignsUpdated.heartRate
								: prevClient?.heartRate,
						respiratoryRate:
							vitalSignsUpdated.respiratoryRate !== ''
								? vitalSignsUpdated.respiratoryRate
								: prevClient?.respiratoryRate,
						oxygenSaturation:
							vitalSignsUpdated.oxygenSaturation !== ''
								? vitalSignsUpdated.oxygenSaturation
								: prevClient?.oxygenSaturation,
						bodyTemperature:
							vitalSignsUpdated.bodyTemperature !== ''
								? vitalSignsUpdated.bodyTemperature
								: prevClient?.bodyTemperature,
						bodyMass:
							vitalSignsUpdated.bodyMass !== ''
								? vitalSignsUpdated.bodyMass
								: prevClient?.bodyMass,
					} as ClientType)
			);

			setClientConsultations((prevConsultations: any) => {
				return prevConsultations.map((c: any) => {
					if (c.id === consultationID) {
						return {
							...c,
							...consultationUpdated,
							diagnostics: consultationUpdated.diagnostics,
							diffDiagnostics: consultationUpdated.diffDiagnostics,
							prescription: {
								...consultationUpdated.prescription,
								medicines: consultationUpdated.prescription.medicines,
								analisis: consultationUpdated.prescription.analisis,
								freePrescription:
									consultationUpdated.prescription.customPrescription,
							},
						};
					}
					return c;
				});
			});

			relationsContext.setAlertMessage({
				message: 'Consulta editada satisfactoriamente.',
				severity: 'success',
			});
		} catch (error) {
			relationsContext.handleError(error, false);
		} finally {
			showSpinner(false);
		}
	};

	const deleteClientConsultation = async (consultation: ConsultationType) => {
		if (consultation.prescription.analisis) {
			await Promise.all(
				consultation.prescription.analisis.map(async (ap) => {
					const ClientPrescriptionResponse = await execute(syncClientPrescriptions, {
						filter: {
							id: {
								eq: ap.clientPrescriptionID,
							},
						},
					});

					const ClientPrescription =
						ClientPrescriptionResponse.data.syncClientPrescriptions.items[0];

					if (ClientPrescription) {
						await execute(updateClientPrescription, {
							input: {
								id: ClientPrescription.id,
								status: Status.INACTIVE,
								_version: ClientPrescription._version,
							},
						});
					}
				})
			);
			await Promise.all(
				consultation.prescription.analisis.map(async (ap) => {
					const clientPrescriptionAnalisisResponse = await execute(
						syncClientPrescriptionAnalises,
						{
							filter: {
								id: {
									eq: ap.id,
								},
							},
						}
					);
					const clientPrescriptionAnalisis =
						clientPrescriptionAnalisisResponse.data.syncClientPrescriptionAnalises
							.items[0];
					if (clientPrescriptionAnalisis) {
						await execute(updateClientPrescriptionAnalisis, {
							input: {
								id: clientPrescriptionAnalisis.id,
								status: Status.INACTIVE,
								_version: clientPrescriptionAnalisis._version,
							},
						});
					}
				})
			);
		}
		if (consultation.prescription.medicines) {
			const medicineFormatted = consultation.prescription
				.medicines as ClientPrescriptionMedicine[];
			await Promise.all(
				medicineFormatted.map(async (mp) => {
					const clientPrescriptionResponse = await execute(syncClientPrescriptions, {
						filter: {
							id: {
								eq: mp.clientPrescriptionID,
							},
						},
					});

					const clientPrescription =
						clientPrescriptionResponse.data.syncClientPrescriptions.items[0];

					if (clientPrescription) {
						await execute(updateClientPrescription, {
							input: {
								id: clientPrescription.id,
								status: Status.INACTIVE,
								_version: clientPrescription._version,
							},
						});
					}
				})
			);
			await Promise.all(
				consultation.prescription.medicines.map(async (mp) => {
					const clientPrescriptionMedicineResponse = await execute(
						syncClientPrescriptionMedicines,
						{
							filter: {
								id: {
									eq: mp.id,
								},
							},
						}
					);
					const clientPrescriptionMedicine =
						clientPrescriptionMedicineResponse.data.syncClientPrescriptionMedicines
							.items[0];
					if (clientPrescriptionMedicine) {
						await execute(updateClientPrescriptionMedicine, {
							input: {
								id: clientPrescriptionMedicine.id,
								status: Status.INACTIVE,
								_version: clientPrescriptionMedicine._version,
							},
						});
					}
				})
			);
		}

		if (consultation.diagnostics && consultation.diagnostics.length > 0) {
			await Promise.all(
				consultation.diagnostics.map(async (d) => {
					const consultationDiagnosticResponse = await execute(
						syncConsultationDiagnostics,
						{
							filter: {
								id: {
									eq: d.id,
								},
							},
						}
					);
					const consultationDiagnostic =
						consultationDiagnosticResponse.data.syncConsultationDiagnostics.items[0];
					if (consultationDiagnostic) {
						await execute(updateConsultationDiagnostic, {
							input: {
								id: consultationDiagnostic.id,
								status: Status.INACTIVE,
								_version: consultationDiagnostic._version,
							},
						});
					}
				})
			);
		}

		if (consultation.diffDiagnostics && consultation.diffDiagnostics.length > 0) {
			consultation.diffDiagnostics.map(async (d) => {
				const consultationDiagnosticResponse = await execute(syncConsultationDiagnostics, {
					filter: {
						id: {
							eq: d.id,
						},
						status: {
							ne: Status.INACTIVE,
						},
					},
				});

				const consultationDiagnostic =
					consultationDiagnosticResponse.data.syncConsultationDiagnostics.items[0];

				if (consultationDiagnostic) {
					await execute(updateConsultationDiagnostic, {
						input: {
							id: consultationDiagnostic.id,
							status: Status.INACTIVE,
							_version: consultationDiagnostic._version,
						},
					});
				}
			});
		}

		const clientConsultationResponse = await execute(syncClientConsultations, {
			filter: {
				id: {
					eq: consultation.id,
				},
				status: {
					ne: Status.INACTIVE,
				},
			},
		});

		const clientConsultation = clientConsultationResponse.data.syncClientConsultations.items[0];
		if (clientConsultation) {
			await execute(updateClientConsultationMutation, {
				input: {
					id: clientConsultation.id,
					status: Status.INACTIVE,
					_version: clientConsultation._version,
				},
			});
		}

		setSelectedClient(
			(prev) =>
				({
					...prev,
					consultations: prev?.consultations?.filter((c) => c.id !== consultation.id),
				} as ClientType)
		);

		setClientConsultations((prevConsultations: any) => {
			return prevConsultations?.filter((c: any) => c.id !== consultation.id);
		});
		relationsContext.setAlertMessage({
			message: 'Consulta eliminada satisfactoriamente.',
			severity: 'success',
		});
	};

	const createClientPrescription = async (prescription: PrescriptionType) => {
		try {
			setLoadingPrescriptions(true);
			const customFilter = await relationsContext.getCustomHospitalDoctorFilters();
			const hospitalDoctorClientResponse = await execute(syncHospitalDoctorClientes, {
				filter: {
					clientID: {
						eq: selectedClient?.id as string,
					},
					clientStatus: {
						ne: Status.INACTIVE,
					},
					or: customFilter,
				},
			});
			const hospitalDoctorClient =
				hospitalDoctorClientResponse.data.syncHospitalDoctorClientes.items[0];

			const prescriptionObj = {
				clientHospitalDoctorID: hospitalDoctorClient.id,
				creationDate: prescription.creationDate,
				type: prescription.type,
				status: Status.ACTIVE,
			};

			const newPrescriptionResponse = await execute(createClientPrescriptionMutation, {
				input: {
					clientHospitalDoctorID: prescriptionObj.clientHospitalDoctorID,
					creationDate: prescriptionObj.creationDate,
					type: prescriptionObj.type,
					status: prescriptionObj.status,
				},
			});

			const newPrescription = newPrescriptionResponse.data.createClientPrescription;

			if (prescription.medicines && prescription.medicines?.length > 0) {
				const medicines = prescription.medicines as ClientPrescriptionMedicine[];

				await Promise.all(
					getUniqueValues(medicines, 'name').map(async (m: MedicineType) => {
						const recentDoctorMedicineResponse = await execute(
							syncRecentDoctorMedicinePrescriptions,
							{
								filter: {
									medicineID: {
										eq: m.id,
									},
									status: {
										ne: Status.INACTIVE,
									},
								},
							}
						);

						const recentDoctorMedicine =
							recentDoctorMedicineResponse.data.syncRecentDoctorMedicinePrescriptions
								.items[0];

						const doctorMedicineResponse = await execute(syncDoctorMedicines, {
							filter: {
								medicineID: {
									eq: m.medicineID,
								},
							},
						});

						const doctorMedicine =
							doctorMedicineResponse.data.syncDoctorMedicines.items[0];

						if (doctorMedicine) {
							await relationsContext.updateDoctorMedicine(doctorMedicine.id, false);
						} else {
							await relationsContext.createDoctorMedicine(m.medicineID, false);
						}

						if (recentDoctorMedicine) {
							await handleUpdateRecentDoctorMedicinePrescription(
								recentDoctorMedicine,
								{
									take: m.take,
									indication: m.indication,
									duration: m.duration,
									description: m.description,
								} as RecentDoctorMedicinePrescription
							);
						} else {
							const doctorPrescriptionMedicine = {
								doctorID: relationsContext.actualDoctor?.id as string,
								medicineID: m.id,
								take: m.take,
								indication: m.indication,
								duration: m.duration,
								description: m.description,
								status: Status.ACTIVE,
							};
							if (
								!relationsContext.actualDoctor?.recentMedicines.some(
									(rmedicine) => rmedicine.name === m.name
								)
							) {
								await execute(createRecentDoctorMedicinePrescription, {
									input: {
										doctorID: doctorPrescriptionMedicine.doctorID,
										medicineID: doctorPrescriptionMedicine.medicineID,
										take: doctorPrescriptionMedicine.take,
										indication: doctorPrescriptionMedicine.indication,
										duration: doctorPrescriptionMedicine.duration,
										description: doctorPrescriptionMedicine.description,
										status: doctorPrescriptionMedicine.status,
									},
								});
							}
						}
					})
				);

				medicines.map(async (m: ClientPrescriptionMedicine) => {
					const prescriptionMedicine = {
						clientPrescriptionID: newPrescription.id,
						name: m.name,
						medicineID: m.medicineID,
						take: m.take,
						indication: m.indication,
						duration: m.duration,
						description: m.description,
						status: Status.ACTIVE,
					};

					await execute(createClientPrescriptionMedicine, {
						input: {
							clientPrescriptionID: prescriptionMedicine.clientPrescriptionID,
							name: prescriptionMedicine.name,
							medicineID: prescriptionMedicine.medicineID,
							take: prescriptionMedicine.take,
							indication: prescriptionMedicine.indication,
							duration: prescriptionMedicine.duration,
							description: prescriptionMedicine.description,
							status: prescriptionMedicine.status,
						},
					});
				});
			}

			if (prescription.analisis && prescription.analisis?.length > 0) {
				const analisis = prescription.analisis as PrescriptionAnalisisType[];

				await Promise.all(
					analisis.map(async (cpa: PrescriptionAnalisisType) => {
						const prescriptionAnalisis = {
							clientPrescriptionID: newPrescription.id,
							name: cpa.name,
							description: cpa.description,
							status: Status.ACTIVE,
						};
						await execute(createClientPrescriptionAnalisis, {
							input: {
								clientPrescriptionID: prescriptionAnalisis.clientPrescriptionID,
								name: prescriptionAnalisis.name,
								description: prescriptionAnalisis.description,
								status: prescriptionAnalisis.status,
							},
						});
					})
				);

				await Promise.all(
					getUniqueValues(analisis, 'name').map(async (cpa: PrescriptionAnalisisType) => {
						const recentDoctorLabResponse = await execute(
							syncRecentDoctorLabImagePrescriptions,
							{
								filter: {
									name: {
										eq: cpa.name,
									},
								},
							}
						);

						const recentDoctorLab =
							recentDoctorLabResponse.data.syncRecentDoctorLabImagePrescriptions
								.items[0];

						const doctorLabImageResponse = await execute(syncDoctorLabImages, {
							filter: {
								labImageID: {
									eq: cpa.labImageID as string,
								},
								status: {
									ne: Status.INACTIVE,
								},
							},
						});

						const doctorLabImage =
							doctorLabImageResponse.data.syncDoctorLabImages.items[0];

						if (!doctorLabImage) {
							await relationsContext.createDoctorLabImage(cpa.labImageID as string);
						} else {
							await execute(updateDoctorLabImage, {
								input: {
									id: doctorLabImage.id,
									status: Status.ACTIVE,
									_version: doctorLabImage._version,
								},
							});
						}

						if (recentDoctorLab) {
							await execute(updateRecentDoctorLabImagePrescription, {
								input: {
									id: recentDoctorLab.id,
									description: cpa.description,
									_version: recentDoctorLab._version,
								},
							});
						} else {
							const doctorPrescriptionLabImage = {
								doctorID: relationsContext.actualDoctor?.id as string,
								labImageID: cpa.labImageID as string,
								name: cpa.name,
								description: cpa.description,
							};
							await execute(createRecentDoctorLabImagePrescription, {
								input: {
									doctorID: doctorPrescriptionLabImage.doctorID,
									labImageID: doctorPrescriptionLabImage.labImageID,
									name: doctorPrescriptionLabImage.name,
									description: doctorPrescriptionLabImage.description,
								},
							});
						}
					})
				);
			}

			if (
				prescription &&
				prescription.customPrescription &&
				prescription.customPrescription.length > 0
			) {
				const customPrescription =
					prescription.customPrescription as PrescriptionAnalisisType[];

				const customPrescriptionPayload = {
					clientPrescriptionID: newPrescription.id,
					description: customPrescription[0].description,
					status: Status.ACTIVE,
				};

				await execute(createClientFreePrescriptionMutation, {
					input: customPrescriptionPayload,
				});
			}

			await updateClientWithOutChanges(selectedClient?.id as string);

			relationsContext.setAlertMessage({
				message: 'Prescripción creada satisfactoriamente.',
				severity: 'success',
			});

			await getPrescriptionsData();
		} catch (error) {
			relationsContext.handleError(error, false);
		} finally {
			setLoadingPrescriptions(false);
		}
	};

	const deleteClientPrescription = async (prescription: ClientPrescriptionType) => {
		try {
			if (!selectedClient) return;
			const clientPrescriptionResponse = await execute(syncClientPrescriptions, {
				filter: {
					id: {
						eq: prescription.id,
					},
				},
			});

			const clientPrescription =
				clientPrescriptionResponse.data.syncClientPrescriptions.items[0];

			await execute(updateClientPrescription, {
				input: {
					id: clientPrescription.id,
					status: Status.INACTIVE,
					_version: clientPrescription._version,
				},
			});

			if (prescription.list && prescription.type === PrescriptionTypes.MEDICINE) {
				await Promise.all(
					prescription.list.map(async (m) => {
						const clientPrescriptionMedicineResponse = await execute(
							syncClientPrescriptionMedicines,
							{
								filter: {
									id: {
										eq: m.id,
									},
								},
							}
						);

						const clientPrescriptionMedicine =
							clientPrescriptionMedicineResponse.data.syncClientPrescriptionMedicines
								.items[0];

						await execute(updateClientPrescriptionMedicine, {
							input: {
								id: clientPrescriptionMedicine.id,
								status: Status.INACTIVE,
								_version: clientPrescriptionMedicine._version,
							},
						});
					})
				);
			}

			if (prescription.list && prescription.type === PrescriptionTypes.LABIMAGE) {
				await Promise.all(
					prescription.list.map(async (a) => {
						const clientPrescriptionAnalisisResponse = await execute(
							syncClientPrescriptionAnalises,
							{
								filter: {
									id: {
										eq: a.id,
									},
								},
							}
						);

						const clientPrescriptionAnalisis =
							clientPrescriptionAnalisisResponse.data.syncClientPrescriptionAnalises
								.items[0];

						await execute(updateClientPrescriptionAnalisis, {
							input: {
								id: clientPrescriptionAnalisis.id,
								status: Status.INACTIVE,
								_version: clientPrescriptionAnalisis._version,
							},
						});
					})
				);
			}

			setSelectedClient(
				(prev) =>
					({
						...prev,
						prescriptions: prev?.prescriptions?.filter((p) => p.id !== prescription.id),
					} as ClientType)
			);

			setClientPrescriptions((prevPrescriptions: any) => {
				return prevPrescriptions?.filter((p: any) => p.id !== prescription.id);
			});
			await updateClientWithOutChanges(selectedClient?.id as string);
			relationsContext.setAlertMessage({
				message: 'Prescripción eliminada satisfactoriamente.',
				severity: 'success',
			});
		} catch (error) {
			relationsContext.handleError(error, false);
		}
	};

	const redirectToClientDetailPage = async (ClientId: string) => {
		const newClientData = await getMainPageClientData(ClientId);
		setSelectedClient(newClientData);
		history.replace(`/Clients/${ClientId}`);
	};

	const createClient = async (dataForm: IdataForm, profileImage?: string): Promise<void> => {
		try {
			showSpinner(true);
			resetPatientData();
			const clientObj: any = {
				identificationName: dataForm.identificationName as IdentificationTypes,
				identificationData: dataForm.identificationData,
				actualInsurance: dataForm.actualInsurance,
				name: dataForm.name,
				lastName: dataForm.lastName,
				isAdult: dataForm.isAdult,
				cellphoneNumber: dataForm.cellphoneNumber,
				email: dataForm.email,
				bornDate: dataForm.bornDate,
				gender: dataForm.gender
					? (dataForm.gender?.toUpperCase() as SexType)
					: SexType.NOSEX,
				phoneNumber: dataForm.phoneNumber,
				addressStreet: dataForm.addressStreet,
				parentOrTutorCedula: dataForm.parentOrTutorCedula || '',
				parentOrTutorID: dataForm.parentOrTutorID || '',
				city: dataForm.city,
				sector: dataForm.sector,
				bloodType: dataForm.bloodType,
				occupation: dataForm.occupation,
				affiliateNumber: dataForm.affiliateNumber,
				contractNumber: dataForm.contractNumber,
			};

			const res = await saveClient(clientObj, profileImage);

			let userImg: string | undefined = undefined;
			if (res.profileImage) {
				const url = await Storage.get(res.profileImage);
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				userImg = url;
			}
			setPaginatedClients((prev) => ({
				...prev,
				clients: [res].concat(prev.clients),
			}));
			relationsContext.setAlertMessage({
				message: 'Paciente creado correctamente',
				severity: 'success',
			});

			history.replace(`/Clients/${res.id}`);
		} catch (error) {
			relationsContext.handleError(error, false);
		} finally {
			showSpinner(false);
		}
	};

	async function getRecordNo(filter: string = ''): string {
		const clients = await getAllClients(syncHospitalDoctorClientesPaginated, '', filter);
		const getLastRecord = orderAndGetLast(clients);
		const newRecord = generateNewRecord(getLastRecord);
		return newRecord;
	}

	function orderAndGetLast(objectsList) {
		if (!objectsList || objectsList.length === 0) {
			return null;
		}

		function compare(a, b) {
			const numberA = parseInt(a.recordNo.split('P')[1]);

			const numberB = parseInt(b.recordNo.split('P')[1]);
			if (isNaN(numberB) || isNaN(numberA)) {
				return 0;
			}
			return numberA - numberB;
		}

		const filteredRecords = objectsList.filter(
			(obj) => obj.recordNo && obj.recordNo.match(/^P\d+$/)
		);

		if (filteredRecords.length === 0) {
			return null;
		}

		const sortedRecords = filteredRecords.sort(compare);

		return sortedRecords[sortedRecords.length - 1];
	}

	function generateNewRecord(lastRecord) {
		if (!lastRecord) {
			return 'P1';
		}

		const number = parseInt(lastRecord.recordNo.split('P')[1]) + 1;
		const numberString = number.toString();
		return `P${numberString}`;
	}

	const saveClient = async (
		client: ClientType,
		profileImage?: string,
		createRelationships = true
	): Promise<Client> => {
		const clientObj = {
			identificationName: client.identificationName,
			identificationData: client.identificationData,
			actualInsurance: client.actualInsurance,
			name: client.name,
			lastName: client.lastName,
			cellphoneNumber: client.cellphoneNumber,
			email: client.email,
			bornDate: client.bornDate,
			parentOrTutorCedula: client.parentOrTutorCedula,
			parentOrTutorID: client.parentOrTutorID,
			gender: client.gender as SexType,
			phoneNumber: client.phoneNumber,
			addressStreet: client.addressStreet,
			city: client.city,
			isAdult: client.isAdult,
			sector: client.sector,
			bloodType: client.bloodType,
			company: client.company,
			occupation: client.occupation,
			profileImage: profileImage,
			heartBeat: client.heartBeat,
			weight: client.weight,
			height: client.height,
			bloodPressure: client.bloodPressure,
		};

		const createdClientResponse = await execute(createClientMutation, { input: clientObj });
		const createdClient = createdClientResponse.data.createClient;

		if (createRelationships) {
			const insuranceName = client?.actualInsurance
				? (client?.actualInsurance as string)
				: 'Sin seguro';

			const clientInsurance = await associateClientWithInsurance(
				{
					...client,
					id: createdClient.id,
				},
				insuranceName
			);
			const filter = await relationsContext.getCustomHospitalDoctorFilters();
			const ownerId =
				relationsContext.ownerHospitalDoctorId || relationsContext.actualHospitalDoctor?.id;
			const newRecord = await getRecordNo(filter);
			setSelectedClient({
				...createdClient,
				affiliateNumber: clientInsurance.affiliateNumber,
				contractNumber: clientInsurance.contractNumber,
				recordNo: newRecord,
			});

			await relationsContext.createHospitalDoctorClients(
				createdClient.id,
				ownerId,
				clientInsurance.id,
				newRecord
			);
		}

		return createdClient;
	};

	const updateClientInfo = async (oldClient: Client, client: Client): Promise<Client> => {
		const currentClientResponse = await execute(syncClients, {
			filter: {
				id: {
					eq: oldClient.id,
				},
			},
		});

		const currentClient = currentClientResponse.data.syncClients.items[0];

		const updatedClientResponse = await execute(updateClientMutation, {
			input: {
				id: currentClient.id,
				name: client.name,
				lastName: client.lastName,
				cellphoneNumber: client.cellphoneNumber,
				email: client.email,
				_version: currentClient._version,
			},
		});

		const updatedClient = updatedClientResponse.data.updateClient;

		if (!selectedClient) {
			try {
				setClientsLoading(true);
				setPaginatedClients((prev) => ({
					...prev,
					clients:
						prev.clients &&
						prev.clients
							.map((c) => {
								if (c.id === updatedClient.id) {
									return {
										...c,
										name: updatedClient.name,
										lastName: updatedClient.lastName,
										cellphoneNumber: updatedClient.cellphoneNumber,
										email: updatedClient.email,
									};
								}
								return c;
							})
							.reduce((acc, curr) => {
								if (curr.id === updatedClient.id) {
									return [curr, ...acc];
								}
								return [...acc, curr];
							}, []),
					pageIndex: page,
					pageSize: rowsPerPage,
				}));
			} catch (error) {
				relationsContext.setAlertMessage({
					message: 'Error al cargar lista de clientes actualizada.',
					severity: 'error',
				});
			} finally {
				setClientsLoading(false);
			}
		}

		return updatedClient;
	};

	const fetchClients = async (filterText?: string, paginated?: boolean) => {
		try {
			const filterTextValue = filterText ? filterText.trim() : '';
			const customFilter = await relationsContext.getCustomHospitalDoctorFilters();
			const hospitalDoctorClientsResponse = await execute(syncHospitalDoctorClientes, {
				filter: {
					clientStatus: {
						ne: Status.INACTIVE,
					},
					client: {
						name: {
							contains: filterTextValue,
						},
					},
					or: customFilter,
				},
			});

			const hospitalDoctorClients =
				hospitalDoctorClientsResponse.data.syncHospitalDoctorClientes.items;

			const result = await Promise.all(
				hospitalDoctorClients.map(async (hospitalDoctorClient: any) => {
					let imageUrl: string | undefined = undefined;
					if (hospitalDoctorClients.client.profileImage) {
						const url = await Storage.get(hospitalDoctorClients.client.profileImage);
						imageUrl = url;
					}
					return {
						...hospitalDoctorClient.client,
						name: hospitalDoctorClient.client.name as string,
						profileImage: imageUrl,
						profileImageKey: hospitalDoctorClient.client.profileImage,
						lastName: hospitalDoctorClient.client.client.lastName as string,
						gender:
							hospitalDoctorClient.client.client.gender === SexType.NOSEX
								? ''
								: hospitalDoctorClient.client.gender,
						healthInsuranceId: hospitalDoctorClient?.lastHealthInsurranceID,
						healthInsuranceName: hospitalDoctorClient.clientInsurance.insurance
							? hospitalDoctorClient.clientInsurance.insurance.name
							: '',
						actualInsurance: hospitalDoctorClients.client.actualInsurance,
						contractNumber: hospitalDoctorClient.clientInsurance
							? hospitalDoctorClient.clientInsurance.contractNumber
							: '',
						affiliateNumber: hospitalDoctorClient.clientInsurance
							? hospitalDoctorClient.clientInsurance.affiliateNumber
							: '',
						status: hospitalDoctorClient?.clientStatus || '',
						updatedAt: hospitalDoctorClient.client.updatedAt || '',
					} as ClientType;
				})
			);

			setClients(
				result.sort(
					(objA, objB) =>
						new Date(objB.updatedAt).getTime() - new Date(objA.updatedAt).getTime()
				) as ClientType[]
			);
		} catch (error) {
			relationsContext.handleError(error, false);
		}
	};

	const fetchPaginatedClients = async (page: number, limit: number, filterText?: string) => {
		try {
			let pages: number = 0;
			const filter = await relationsContext.getCustomHospitalDoctorFilters();
			const hospitalDoctorClients = await getAllClients(
				syncHospitalDoctorClientesPaginated,
				'',
				filter
			);

			let hospitalDoctorClientsFiltered = hospitalDoctorClients;

			const orderByDate = (user_1: any, user_2: any) => {
				const timestamp_1 = user_1.client._lastChangedAt;
				const timestamp_2 = user_2.client._lastChangedAt;

				return timestamp_2 - timestamp_1;
			};
			pages = hospitalDoctorClients.length;
			if (filterText) {
				const filterTextValue = filterText ? filterText.trim() : '';
				hospitalDoctorClientsFiltered = hospitalDoctorClients.filter(
					(hospitalDoctorClient: any) => {
						return (
							hospitalDoctorClient.client?.name
								?.toLowerCase()
								.includes(filterTextValue.toLowerCase()) ||
							hospitalDoctorClient.client?.lastName
								?.toLowerCase()
								.includes(filterTextValue.toLowerCase())
						);
					}
				);

				pages = hospitalDoctorClients.length;
			}

			const hospitalDoctorClientsOrdered = [...hospitalDoctorClientsFiltered].sort(
				orderByDate
			);

			const hospitalDoctorClientFilteredPage = hospitalDoctorClientsOrdered;

			const result = await Promise.all(
				hospitalDoctorClientFilteredPage.map(async (hospitalDoctorClient: any) => {
					const { client: currentClient } = hospitalDoctorClient;
					const { profileImage, name, lastName, clientInsurance } = currentClient;

					const imageUrl = profileImage ? await Storage.get(profileImage) : undefined;

					return {
						...currentClient,
						name,
						lastName,
						profileImage: imageUrl,
						healthInsuranceName: clientInsurance?.insurance?.name || '',
					} as ClientType;
				})
			);

			const pageCount = Math.ceil(pages / limit);
			const totalItemsCount = result.length;

			setPaginatedClients((prev) => ({
				...prev,
				clients: result as ClientType[],
				pageCount: pageCount,
				totalItemCount: totalItemsCount,
				pageIndex: page,
				pageSize: limit,
			}));
		} catch (error) {
			relationsContext.handleError(error, false);
		}
	};

	const fetchPaginatedClientsByCursor = async (
		limit: number,
		filterText?: string,
		move?: MOVE
	) => {
		try {
			const currentClients = paginatedClients.clients;

			if (!move && currentClients.length !== 0) return;

			const hospitalDoctorClientsPerPage = await execute(
				syncHospitalDoctorClientesPaginated,
				{
					filter: {
						hospitalDoctorID: {
							eq: relationsContext.actualHospitalDoctor?.id,
						},
						clientStatus: {
							ne: Status.INACTIVE,
						},
					},
					limit: rowsPerPage,
					nextToken: nextCursor,
				}
			);

			let hospitalDoctorClientsFiltered =
				hospitalDoctorClientsPerPage.data.syncHospitalDoctorClientes.items;

			const currentToken =
				hospitalDoctorClientsPerPage.data.syncHospitalDoctorClientes.nextToken;

			setNextCursor(currentToken);

			const orderByDate = (user_1: any, user_2: any) => {
				const timestamp_1 = user_1.client._lastChangedAt;
				const timestamp_2 = user_2.client._lastChangedAt;

				return timestamp_2 - timestamp_1;
			};

			if (filterText) {
				const filterTextValue = filterText ? filterText.trim() : '';

				hospitalDoctorClientsFiltered = hospitalDoctorClients.filter(
					(hospitalDoctorClient: any) => {
						return (
							hospitalDoctorClient.client?.name
								?.toLowerCase()
								.includes(filterTextValue.toLowerCase()) ||
							hospitalDoctorClient.client?.lastName
								?.toLowerCase()
								.includes(filterTextValue.toLowerCase())
						);
					}
				);
			}

			const hospitalDoctorClientsOrdered = [...hospitalDoctorClientsFiltered].sort(
				orderByDate
			);

			const result = await Promise.all(
				hospitalDoctorClientsOrdered.map(async (hospitalDoctorClient: any) => {
					const currentClient = hospitalDoctorClient.client!;

					let imageUrl: string | undefined = undefined;
					if (currentClient.profileImage) {
						const url = await Storage.get(currentClient.profileImage);
						imageUrl = url;
					}

					return {
						...currentClient,
						name: currentClient.name as string,
						profileImage: imageUrl,
						lastName: currentClient.lastName as string,
						healthInsuranceName: currentClient?.clientInsurance?.insurance?.name || '',
					} as ClientType;
				})
			);

			const newClients = [...currentClients, ...result];

			setPaginatedClients((prev) => ({
				...prev,
				clients: prev.clients.length === 0 ? result : (newClients as ClientType[]),
				pageIndex: page,
				pageSize: limit,
			}));
			if (move) {
				if (move === 'next' && !!currentToken) {
					setPage((prev) => prev + 1);
				}
			}
		} catch (error) {
			relationsContext.handleError(error, false);
		}
	};

	const reFetchPaginatedClientsByCursor = async (cursorReference?: string) => {
		let cursorTokenOption = cursorReference ?? nextCursor;
		try {
			const hospitalDoctorClientsPerPage = await execute(
				syncHospitalDoctorClientesPaginated,
				{
					filter: {
						hospitalDoctorID: {
							eq: relationsContext.actualHospitalDoctor?.id,
						},
						clientStatus: {
							ne: Status.INACTIVE,
						},
					},
					limit: rowsPerPage,
					nextToken: cursorTokenOption,
				}
			);

			let hospitalDoctorClientsFiltered =
				hospitalDoctorClientsPerPage.data.syncHospitalDoctorClientes.items;

			const currentToken =
				hospitalDoctorClientsPerPage.data.syncHospitalDoctorClientes.nextToken;

			setNextCursor(currentToken);

			const orderByDate = (user_1: any, user_2: any) => {
				const timestamp_1 = user_1.client._lastChangedAt;
				const timestamp_2 = user_2.client._lastChangedAt;

				return timestamp_2 - timestamp_1;
			};

			const hospitalDoctorClientsOrdered = [...hospitalDoctorClientsFiltered].sort(
				orderByDate
			);

			const result = await Promise.all(
				hospitalDoctorClientsOrdered.map(async (hospitalDoctorClient: any) => {
					const currentClient = hospitalDoctorClient.client!;

					let imageUrl: string | undefined = undefined;
					if (currentClient.profileImage) {
						const url = await Storage.get(currentClient.profileImage);
						imageUrl = url;
					}

					return {
						...currentClient,
						name: currentClient.name as string,
						profileImage: imageUrl,
						lastName: currentClient.lastName as string,
						healthInsuranceName: currentClient?.clientInsurance?.insurance?.name || '',
					} as ClientType;
				})
			);

			setPaginatedClients((prev) => ({
				...prev,
				clients: result as ClientType[],
			}));
		} catch (error) {
			relationsContext.handleError(error, false);
		}
	};

	const getAllClients = async (query: string, nextTokenData: string, filter: any) => {
		try {
			let nextToken = nextTokenData;
			const allClients = [];

			do {
				const hospitalDoctorClientsResponse = await execute(query, {
					filter: {
						clientStatus: {
							ne: Status.INACTIVE,
						},
						or: filter,
					},
					limit: 1000,
					nextToken,
				});

				const hospitalDoctorClients =
					hospitalDoctorClientsResponse.data.syncHospitalDoctorClientes;

				allClients.push(...hospitalDoctorClients.items);

				nextToken = hospitalDoctorClients.nextToken;
			} while (nextToken);

			return allClients;
		} catch (error) {
			return [];
		}
	};

	const fetchPaginatedClientsByName = async (filterText: string): Promise<IClientFilter[]> => {
		try {
			let hospitalDoctorClients: HospitalDoctorCliente[] = [];

			const filterTextValue = `${filterText}`.toLowerCase();
			const customFilter = await relationsContext.getCustomHospitalDoctorFilters();
			const allClients = await getAllClients(
				syncHospitalDoctorClientesForFetchPaginatedClientsByName,
				'',
				customFilter
			);
			hospitalDoctorClients = allClients.filter((hospitalDoctorClient: any) => {
				if (!hospitalDoctorClient.client) return false;
				return (
					`${hospitalDoctorClient.client.name.toLowerCase()} ${hospitalDoctorClient.client.lastName.toLowerCase()}`.includes(
						filterTextValue
					) ||
					(hospitalDoctorClient.client.identificationData &&
						hospitalDoctorClient.client.identificationData
							.toLowerCase()
							.includes(filterTextValue)) ||
					(hospitalDoctorClient.recordNo &&
						hospitalDoctorClient.recordNo.toLowerCase().includes(filterTextValue))
				);
			});
			const result: IClientFilter[] = hospitalDoctorClients.map((hospitalDoctorClient) => {
				return {
					id: hospitalDoctorClient.client?.id as string,
					name: `${hospitalDoctorClient.client?.name} ${hospitalDoctorClient.client?.lastName}`,
					email: hospitalDoctorClient.client?.email as string,
					healthInsuranceName:
						hospitalDoctorClient?.clientInsurance?.insurance?.name || '',
					_version: hospitalDoctorClient.client?._version as number,
					recordNo: hospitalDoctorClient.recordNo,
				};
			});
			return result;
		} catch (error) {
			relationsContext.handleError(error, false);
			return [];
		}
	};

	const fetchPatientByIdentificationData = async (
		identificationData: string
	): Promise<Client> => {
		const patientCreatedREsponse = await execute(syncClients, {
			filter: {
				identificationData: {
					eq: identificationData,
				},
			},
		});

		const patientCreated = patientCreatedREsponse.data.syncClients.items[0];

		return patientCreated;
	};

	const getAllClientsToCheckIfExist = async () => {
		try {
			const allClients = [];
			let nextToken = '';
			do {
				const customFilter = await relationsContext.getCustomHospitalDoctorFilters();
				const hospitalDoctorClientsResponse = await execute(
					syncHospitalDoctorClientesByIdIdentification,
					{
						filter: {
							clientStatus: {
								ne: Status.INACTIVE,
							},
							or: customFilter,
						},
						limit: 1000,
						nextToken,
					}
				);

				const hospitalDoctorClients =
					hospitalDoctorClientsResponse.data.syncHospitalDoctorClientes;

				allClients.push(...hospitalDoctorClients.items);

				nextToken = hospitalDoctorClients.nextToken;
			} while (nextToken);

			return allClients;
		} catch (error) {
			relationsContext.handleError(error, false);
			return [];
		}
	};

	const checkIfClientExistInHospitalDoctor = async (
		identificationData: string
	): Promise<HospitalDoctorByIdIdentification | undefined> => {
		try {
			const hospitalDoctorClients: HospitalDoctorByIdIdentification[] =
				await getAllClientsToCheckIfExist();
			return hospitalDoctorClients.find(
				(hospitalDoctorClient: HospitalDoctorByIdIdentification) =>
					hospitalDoctorClient.client.identificationData === identificationData
			);
		} catch (error) {
			relationsContext.handleError(error, false);
		}
	};

	const fetchPatientById = async (id: string): Promise<any> => {
		const patientsResponse = await execute(syncClients, {
			filter: {
				id: {
					eq: id,
				},
			},
		});

		const patient = patientsResponse.data.syncClients.items[0];

		return patient;
	};

	const getClientData = async (clientId: string): Promise<ClientType> => {
		try {
			const customFilter = await relationsContext.getCustomHospitalDoctorFilters();
			const hospitalDoctorClientResponse = await execute(syncHospitalDoctorClientes, {
				filter: {
					clientID: {
						eq: clientId,
					},
					or: customFilter,
				},
			});

			const hospitalDoctorClient =
				hospitalDoctorClientResponse.data.syncHospitalDoctorClientes.items[0];

			let healthInsurance = {} as Insurance;
			let clientHealthInsurance = {} as ClientInsurance;
			if (hospitalDoctorClient?.lastHealthInsurranceID) {
				const clientInsuranceResponse = await execute(syncClientInsurances, {
					filter: {
						clientID: {
							eq: hospitalDoctorClient.client!.id,
						},
					},
				});

				const clientInsurance = clientInsuranceResponse.data.syncClientInsurances.items[0];
				clientHealthInsurance = clientInsurance;
				healthInsurance = clientInsurance.insurance!;
			}

			// Get Client Allergies
			const allergies = await fetchAlergyByClientId(hospitalDoctorClient.client!.id);
			// Get Client Diseases
			const diseases = await fetchDiseaseByClientId(hospitalDoctorClient.client!.id);
			// Get Client Procedures
			const procedures = await fetchProcedureByClientId(hospitalDoctorClient.client!.id);
			// Get Client Hospitalizations
			const hospitalizations = await fetchHospitalizationByClientId(
				hospitalDoctorClient.client!.id
			);
			// Get client Family History
			const familyHistories = await fetchFamilyHistoryByClientId(
				hospitalDoctorClient.client!.id
			);
			// Get client Life Style
			const lifeStyles = await fetchLifeStyleByClientId(hospitalDoctorClient.client!.id);

			const historyResponse = await execute(syncClientHistories, {
				filter: {
					clientHospitalDoctorID: {
						eq: hospitalDoctorClient.id,
					},
					status: {
						ne: Status.INACTIVE,
					},
				},
			});

			let history = historyResponse.data.syncClientHistories.items;

			history = (await Promise.all(
				history.map(async (history: any) => {
					const healthInsuranceResponse = await execute(syncInsurances, {
						filter: {
							id: {
								eq: history.insuranceID,
							},
						},
					});

					const healthInsurance = healthInsuranceResponse.data.syncInsurances.items[0];

					return {
						...history,
						healthInsuranceName: healthInsurance?.name,
						healthInsuranceAuthCode: healthInsurance?.code,
					};
				})
			)) as ClientHistoryType[];

			const notesResponse = await execute(syncClientNotes, {
				filter: {
					clientHospitalDoctorID: {
						eq: hospitalDoctorClient.id,
					},
					status: {
						ne: Status.INACTIVE,
					},
				},
			});

			const notes = notesResponse.data.syncClientNotes.items;

			const user = await Auth.currentAuthenticatedUser();
			const isDoctorHandler = isDoctor(user);
			const isMember = isDoctorMember(user);
			let note: string = '';
			if (isMember) {
				const doctorNoteResponse = await execute(syncMemberHospitalDoctorClients, {
					filter: {
						memberID: {
							eq: relationsContext.actualHospitalDoctor?.doctor?.id as string,
						},
						clientID: {
							eq: hospitalDoctorClient.client!.id,
						},
					},
				});

				const doctorNote = doctorNoteResponse.data.syncMemberHospitalDoctorClients.items[0];

				note = doctorNote?.note || '';
			} else if (isDoctorHandler) {
				note = hospitalDoctorClient?.note || '';
			} else {
				const doctorNoteResponse = await execute(syncSecretaryHospitalDoctorClients, {
					filter: {
						secretaryID: {
							eq: relationsContext.actualSecretary?.id as string,
						},
						clientID: {
							eq: hospitalDoctorClient.client!.id,
						},
					},
				});

				const doctorNote =
					doctorNoteResponse.data.syncSecretaryHospitalDoctorClients.items[0];

				note = doctorNote?.note || '';
			}

			const surgeriesResponse = await execute(syncClientProcedureSurgeries, {
				filter: {
					clientHospitalDoctorID: {
						eq: hospitalDoctorClient.id,
					},
					status: {
						ne: Status.INACTIVE,
					},
				},
			});

			const surgeries = surgeriesResponse.data.syncClientProcedureSurgeries.items;

			const claimsResponse = await execute(syncClientClaims, {
				filter: {
					clientHospitalDoctorID: {
						eq: hospitalDoctorClient.id,
					},
					status: {
						ne: Status.INACTIVE,
					},
				},
			});

			let claims = claimsResponse.data.syncClientClaims.items.reverse() as ClaimType[];

			claims = await Promise.all(
				claims.map(async (claim) => {
					const healthInsuranceResponse = await execute(syncInsurances, {
						filter: {
							id: {
								eq: claim.healthInsuranceID,
							},
						},
					});

					const healthInsurance = healthInsuranceResponse.data.syncInsurances.items[0];

					const processesResponse = await execute(syncClaimProcesses, {
						filter: {
							claimID: {
								eq: claim.id,
							},
						},
					});

					const processes = processesResponse.data.syncClaimProcesses.items;

					return {
						...claim,
						healthInsuranceName: healthInsurance!.name || '',
						processes,
					};
				})
			);

			let filesResponse = await execute(syncClientImageSections, {
				filter: {
					clientHospitalDoctorID: {
						eq: hospitalDoctorClient.id,
					},
					status: {
						ne: Status.INACTIVE,
					},
				},
			});

			let files = filesResponse.data.syncClientImageSections.items;

			if (files && files.length > 0) {
				files = await Promise.all(
					files.map(async (file: any) => {
						const imagesResponse = await execute(syncClientImages, {
							filter: {
								ClientImageDateID: {
									eq: file.id,
								},
								status: {
									ne: Status.INACTIVE,
								},
							},
						});

						let images = imagesResponse.data.syncClientImages.items;

						images = await Promise.all(
							images.map(async (img: any) => {
								const url = await Storage.get(img.pathFile!, {
									download: true,
								});
								return {
									...img,
									url: url.Body,
								};
							})
						);

						return {
							...file,
							images,
						};
					})
				);
			}

			const prescriptionsResponse = await execute(syncClientPrescriptions, {
				filter: {
					clientHospitalDoctorID: {
						eq: hospitalDoctorClient.id,
					},
					status: {
						ne: Status.INACTIVE,
					},
				},
			});

			let prescriptions = prescriptionsResponse.data.syncClientPrescriptions.items;

			prescriptions = await Promise.all(
				prescriptions.map(async (p: any) => {
					let list = [] as ClientPrescriptionMedicine[];
					if (p.type === PrescriptionTypes.MEDICINE) {
						const listResponse = await execute(syncClientPrescriptionMedicines, {
							filter: {
								clientPrescriptionID: {
									eq: p.id,
								},
								status: {
									ne: Status.INACTIVE,
								},
							},
						});

						list = listResponse.data.syncClientPrescriptionMedicines.items;
					}
					if (p.type === PrescriptionTypes.LABIMAGE) {
						const listResponse = await execute(syncClientPrescriptionAnalises, {
							filter: {
								clientPrescriptionID: {
									eq: p.id,
								},
								status: {
									ne: Status.INACTIVE,
								},
							},
						});

						list = listResponse.data.syncClientPrescriptionAnalises.items;
					}

					return {
						...p!,
						list: list,
					};
				})
			);

			const last3Medicines: MedicineType[] = [];
			for (let i = 0; i < prescriptions.length; i++) {
				const p = prescriptions[i];
				p.medicines?.forEach((m: any) => {
					if (last3Medicines.length !== 3) {
						if (!last3Medicines.some((lm) => lm.medicineID === m.medicineID))
							last3Medicines.push(m);
					}
				});

				if (last3Medicines.length === 3) break;
			}
			const consultationsResponse = await execute(syncClientConsultations, {
				filter: {
					clientHospitalDoctorID: {
						eq: hospitalDoctorClient.id,
					},
					status: {
						ne: Status.INACTIVE,
					},
				},
			});

			let consultations = consultationsResponse.data.syncClientConsultations.items;

			consultations = await Promise.all(
				consultations.map(async (c: any) => {
					let consultationMedicinePrescription;
					let consultationAnalisisPrescription;
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					let consultationFreePrescription;

					if (c.clientMedicinePrescriptionID) {
						let list = [] as ClientPrescriptionMedicine[];

						const medicinePrescriptionResponse = await execute(
							syncClientPrescriptions,
							{
								filter: {
									id: {
										eq: c.clientMedicinePrescriptionID,
									},
									status: {
										ne: Status.INACTIVE,
									},
								},
							}
						);

						const medicinePrescriptions =
							medicinePrescriptionResponse.data.syncClientPrescriptions.items[0];

						if (
							medicinePrescriptions &&
							medicinePrescriptions.type === PrescriptionTypes.MEDICINE
						) {
							const clientPrescriptionMedicineResponse = await execute(
								syncClientPrescriptionMedicines,
								{
									filter: {
										clientPrescriptionID: {
											eq: medicinePrescriptions.id,
										},
										status: {
											ne: Status.INACTIVE,
										},
									},
								}
							);

							list =
								clientPrescriptionMedicineResponse.data
									.syncClientPrescriptionMedicines.items;
						}
						consultationMedicinePrescription = list;
					}

					if (c.clientAnalisisPrescriptionID) {
						let list = [] as ClientPrescriptionAnalisis[];

						const analisisPrescriptionResponse = await execute(
							syncClientPrescriptions,
							{
								filter: {
									id: {
										eq: c.clientAnalisisPrescriptionID,
									},
									status: {
										ne: Status.INACTIVE,
									},
								},
							}
						);

						const analisisPrescription =
							analisisPrescriptionResponse.data.syncClientPrescriptions.items[0];

						if (
							analisisPrescription &&
							analisisPrescription.type === PrescriptionTypes.LABIMAGE
						) {
							const clientPrescriptionResponse = await execute(
								syncClientPrescriptionAnalises,
								{
									filter: {
										clientPrescriptionID: {
											eq: analisisPrescription.id,
										},
										status: {
											ne: Status.INACTIVE,
										},
									},
								}
							);

							list =
								clientPrescriptionResponse.data.syncClientPrescriptionAnalises
									.items;
						}
						consultationAnalisisPrescription = list;
					}

					const diagnosticResponse = await execute(syncConsultationDiagnostics, {
						filter: {
							clientConsultationID: {
								eq: c.id,
							},
							status: {
								ne: Status.INACTIVE,
							},
						},
					});

					const diagnostics = diagnosticResponse.data.syncConsultationDiagnostics.items;

					return {
						...c,
						prescription: {
							medicines: consultationMedicinePrescription,
							analisis: consultationAnalisisPrescription,
						},
						diagnostics,
					};
				})
			);

			let profileImage: string | undefined = undefined;
			if (hospitalDoctorClient.client!.profileImage) {
				const url = await Storage.get(hospitalDoctorClient.client!.profileImage);
				profileImage = url;
			}

			const appointments = await fetchClientAppointments(hospitalDoctorClient.client!.id);

			const transformedClient: ClientType = {
				...hospitalDoctorClient.client!,
				height: hospitalDoctorClient.client!.height || '',
				profileImageKey: hospitalDoctorClient.client!.profileImage || '',
				profileImage,
				heartBeat: hospitalDoctorClient.client!.heartBeat || '',
				weight: hospitalDoctorClient.client!.weight || '',
				gender: (hospitalDoctorClient.client!.gender === SexType.NOSEX
					? ''
					: hospitalDoctorClient.client!.gender!) as SexType,
				healthInsuranceId: clientHealthInsurance ? clientHealthInsurance.insuranceID! : '',
				actualInsurance: hospitalDoctorClient.client!.actualInsurance,
				allowInsuranceClaim: healthInsurance?.allowClaim ?? false,
				healthInsuranceAuthCode: healthInsurance ? (healthInsurance.code as string) : '',
				healthInsuranceName: healthInsurance ? healthInsurance.name : '',
				contractNumber: clientHealthInsurance
					? (clientHealthInsurance.contractNumber as string)
					: '',
				affiliateNumber: clientHealthInsurance
					? (clientHealthInsurance.affiliateNumber as string)
					: '',
				affiliateType: clientHealthInsurance
					? (clientHealthInsurance.affiliateType as AffiliateTypes)
					: AffiliateTypes.PRINCIPAL,
				allergies: allergies || [],
				diseases: diseases.reverse() || [],
				procedures: procedures || [],
				hospitalizations: hospitalizations || [],
				familyHistories: familyHistories || [],
				lifeStyles: lifeStyles || [],
				history: history || [],
				status: hospitalDoctorClient.clientStatus || '',
				notes: notes || [],
				surgeries: surgeries || [],
				claims,
				appointments: appointments || [],
				prescriptions: prescriptions.reverse(),
				files: files as FilesType[],
				consultations: consultations.sort(
					(objA: any, objB: any) =>
						new Date(objB.createdAt as string).getTime() -
						new Date(objA.createdAt as string).getTime()
				) as ConsultationType[],
				note: note || '',
			};

			return transformedClient;
		} catch (error) {
			relationsContext.handleError(error, false);
			return {} as ClientType;
		}
	};

	const getClientFilesData = async (): Promise<any> => {
		try {
			setImageLoading(true);

			if (!selectedClient) {
				relationsContext.handleError('Selected client not found', false);
				return;
			}
			const customFilter = await relationsContext.getCustomHospitalDoctorFilters();
			const hospitalDoctorClientResponse = await execute(syncHospitalDoctorClientes, {
				filter: {
					clientID: {
						eq: selectedClient.id,
					},
					or: customFilter,
				},
			});

			const hospitalDoctorClient =
				hospitalDoctorClientResponse.data.syncHospitalDoctorClientes.items[0];

			if (!hospitalDoctorClient) {
				relationsContext.handleError('HospitalDoctorClient not found', false);
				return;
			}

			let filesResponse = await execute(syncClientImageSections, {
				filter: {
					clientHospitalDoctorID: {
						eq: hospitalDoctorClient.id,
					},
					status: {
						ne: Status.INACTIVE,
					},
				},
			});

			let files = filesResponse.data.syncClientImageSections.items;

			if (files && files.length > 0) {
				files = await Promise.all(
					files.map(async (file: any) => {
						const imagesResponse = await execute(syncClientImages, {
							filter: {
								ClientImageDateID: {
									eq: file.id,
								},
								status: {
									ne: Status.INACTIVE,
								},
							},
						});

						let images = imagesResponse.data.syncClientImages.items;

						images = await Promise.all(
							images.map(async (img: any) => {
								const url = await Storage.get(img.pathFile!, {
									download: true,
								});
								return {
									...img,
									url: url.Body,
								};
							})
						);

						return {
							...file,
							images,
						};
					})
				);
			}

			setClientFiles(files);
		} catch (error) {
			relationsContext.handleError(error, false);
		} finally {
			setImageLoading(false);
		}
	};

	const getClientClaimsData = async (): Promise<any> => {
		try {
			setLoadingClientClaims(true);
			if (!selectedClient) {
				relationsContext.handleError('selected client not found', false);
				return;
			}
			const customFilter = await relationsContext.getCustomHospitalDoctorFilters();
			const hospitalDoctorClientResponse = await execute(syncHospitalDoctorClientes, {
				filter: {
					clientID: {
						eq: selectedClient.id,
					},
					or: customFilter,
				},
			});

			const hospitalDoctorClient =
				hospitalDoctorClientResponse.data.syncHospitalDoctorClientes.items[0];

			if (!hospitalDoctorClient) {
				relationsContext.handleError('Hospital doctor not found', false);
				return;
			}

			const claimsResponse = await execute(syncClientClaims, {
				filter: {
					clientHospitalDoctorID: {
						eq: hospitalDoctorClient.id,
					},
					status: {
						ne: Status.INACTIVE,
					},
				},
			});

			let claims = claimsResponse.data.syncClientClaims.items.reverse();

			const claimsData = await Promise.all(
				claims.map(async (claim: any) => {
					const processesResponse = await execute(syncClaimProcesses, {
						filter: {
							claimID: {
								eq: claim.id,
							},
						},
					});

					const processes = processesResponse.data.syncClaimProcesses.items;
					return {
						...claim,
						healthInsuranceName: claim.healthInsurance!.name || '',
						processes,
					};
				})
			);

			const clientClaimsOrdered = claimsData.sort(
				(objA: any, objB: any) =>
					new Date(objB.createdAt as string).getTime() -
					new Date(objA.createdAt as string).getTime()
			);

			setClientClaims(clientClaimsOrdered);
		} catch (error) {
			relationsContext.handleError(error, false);
		} finally {
			setLoadingClientClaims(false);
		}
	};

	const getClientHistoryData = async (): Promise<any> => {
		try {
			setLoadingClientHistory(true);
			if (!selectedClient) {
				relationsContext.handleError('Selected client not found', false);
				return;
			}

			// Get Client Allergies
			const allergies = await fetchAlergyByClientId(selectedClient.id);
			// Get Client Diseases
			const diseases = await fetchDiseaseByClientId(selectedClient.id);
			// Get Client Procedures
			const procedures = await fetchProcedureByClientId(selectedClient.id);
			// Get Client Hospitalizations
			const hospitalizations = await fetchHospitalizationByClientId(selectedClient.id);
			// Get client Family History
			const familyHistories = await fetchFamilyHistoryByClientId(selectedClient.id);
			// Get client Life Style
			const lifeStyles = await fetchLifeStyleByClientId(selectedClient.id);

			const responseData = {
				allergies,
				diseases,
				procedures,
				hospitalizations,
				familyHistories,
				lifeStyles,
			};

			setClientHistory(responseData);
		} catch (error) {
			relationsContext.handleError(error, false);
		} finally {
			setLoadingClientHistory(false);
		}
	};

	const fetchConsultationsByClientId = async (hospitalDoctorClientId: string): Promise<any> => {
		try {
			const clientConsultationsResponse = await execute(syncClientConsultationsByMainPage, {
				filter: {
					clientHospitalDoctorID: {
						eq: hospitalDoctorClientId,
					},
					status: {
						ne: Status.INACTIVE,
					},
				},
			});

			const clientConsultations =
				clientConsultationsResponse.data.syncClientConsultations.items;

			const orderedConsultationsData = clientConsultations.sort(
				(objA: any, objB: any) =>
					new Date(objB.createdAt as string).getTime() -
					new Date(objA.createdAt as string).getTime()
			);

			return orderedConsultationsData;
		} catch (error) {
			return [];
		}
	};

	const getPrescriptionsData = async (clientID?: string, hospitalID?: string) => {
		try {
			setLoadingPrescriptions(true);
			const customFilter = await relationsContext.getCustomHospitalDoctorFilters();
			const hospitalDoctorClientResponse = await execute(syncHospitalDoctorClientes, {
				filter: {
					clientID: {
						eq: selectedClient?.id || clientID,
					},
					or: customFilter,
				},
			});

			const hospitalDoctorClient =
				hospitalDoctorClientResponse.data.syncHospitalDoctorClientes.items[0];

			if (!hospitalDoctorClient) {
				relationsContext.handleError('Hospital doctor client not found', false);
				return;
			}

			const prescriptionsResponse = await execute(syncClientPrescriptions, {
				filter: {
					clientHospitalDoctorID: {
						eq: hospitalDoctorClient.id,
					},
					status: {
						ne: Status.INACTIVE,
					},
				},
				limit: 20000,
			});

			const prescriptions = prescriptionsResponse.data.syncClientPrescriptions.items;

			if (!prescriptions) {
				relationsContext.handleError('Prescription not found', false);
				return;
			}

			if (prescriptions.length === 0) {
				return [];
			}

			const prescriptionsData = await Promise.all(
				prescriptions.map(async (p: any) => {
					let list = [] as ClientPrescriptionMedicine[];
					if (p.type === PrescriptionTypes.FREE_PRESCRIPTION) {
						const listResponse = await execute(syncClientFreePrescriptions, {
							filter: {
								clientPrescriptionID: {
									eq: p.id,
								},
								status: {
									ne: Status.INACTIVE,
								},
							},
						});

						list = listResponse.data.syncClientFreePrescriptions.items.reverse();
					}
					if (p.type === PrescriptionTypes.MEDICINE) {
						const listResponse = await execute(syncClientPrescriptionMedicines, {
							filter: {
								clientPrescriptionID: {
									eq: p.id,
								},
								status: {
									ne: Status.INACTIVE,
								},
							},
						});

						list = listResponse.data.syncClientPrescriptionMedicines.items.reverse();
					}
					if (p.type === PrescriptionTypes.LABIMAGE) {
						const listResponse = await execute(syncClientPrescriptionAnalises, {
							filter: {
								clientPrescriptionID: {
									eq: p.id,
								},
								status: {
									ne: Status.INACTIVE,
								},
							},
						});

						list = listResponse.data.syncClientPrescriptionAnalises.items.reverse();
					}

					return {
						...p!,
						list: list,
					};
				})
			);

			const clientPrescriptionOrdered = prescriptionsData.sort(
				(objA: any, objB: any) =>
					new Date(objB.createdAt as string).getTime() -
					new Date(objA.createdAt as string).getTime()
			);

			const filteredPrescriptions = clientPrescriptionOrdered.filter(
				(p: any) => p.list.length > 0
			);

			setClientPrescriptions(filteredPrescriptions);
		} catch (error) {
			relationsContext.handleError(error, false);
			return [];
		} finally {
			setLoadingPrescriptions(false);
		}
	};

	const getConsultationDataById = async (consultationId: string): Promise<any> => {
		try {
			showSpinner(true);
			const consultationResponse = await execute(syncClientConsultations, {
				filter: {
					id: {
						eq: consultationId,
					},
					status: {
						ne: Status.INACTIVE,
					},
				},
			});

			const consultation = consultationResponse.data.syncClientConsultations.items[0];

			if (!consultation) {
				relationsContext.handleError('consultation not found', false);
				return;
			}
			let consultationMedicinePrescription;
			let consultationAnalisisPrescription;
			let consultationFreePrescription;
			if (consultation.clientMedicinePrescriptionID) {
				let list = [] as ClientPrescriptionMedicine[];

				const medicinePrescriptionResponse = await execute(syncClientPrescriptions, {
					filter: {
						id: {
							eq: consultation.clientMedicinePrescriptionID,
						},
						status: {
							ne: Status.INACTIVE,
						},
					},
				});

				const medicinePrescriptions =
					medicinePrescriptionResponse.data.syncClientPrescriptions.items[0];

				if (
					medicinePrescriptions &&
					medicinePrescriptions.type === PrescriptionTypes.MEDICINE
				) {
					const clientPrescriptionMedicineResponse = await execute(
						syncClientPrescriptionMedicines,
						{
							filter: {
								clientPrescriptionID: {
									eq: medicinePrescriptions.id,
								},
								status: {
									ne: Status.INACTIVE,
								},
							},
						}
					);

					list =
						clientPrescriptionMedicineResponse.data.syncClientPrescriptionMedicines
							.items;
				}
				consultationMedicinePrescription = list;
			}

			if (consultation.clientAnalisisPrescriptionID) {
				let list = [] as ClientPrescriptionAnalisis[];

				const analisisPrescriptionResponse = await execute(syncClientPrescriptions, {
					filter: {
						id: {
							eq: consultation.clientAnalisisPrescriptionID,
						},
						status: {
							ne: Status.INACTIVE,
						},
					},
				});

				const analisisPrescription =
					analisisPrescriptionResponse.data.syncClientPrescriptions.items[0];

				if (
					analisisPrescription &&
					analisisPrescription.type === PrescriptionTypes.LABIMAGE
				) {
					const clientPrescriptionResponse = await execute(
						syncClientPrescriptionAnalises,
						{
							filter: {
								clientPrescriptionID: {
									eq: analisisPrescription.id,
								},
								status: {
									ne: Status.INACTIVE,
								},
							},
						}
					);

					list = clientPrescriptionResponse.data.syncClientPrescriptionAnalises.items;
				}
				consultationAnalisisPrescription = list;
			}

			if (consultation.clientFreePrescriptionID) {
				const freePrescriptionResponse = await execute(syncClientPrescriptions, {
					filter: {
						id: {
							eq: consultation.clientFreePrescriptionID,
						},
						status: {
							ne: Status.INACTIVE,
						},
					},
				});

				const freePrescription =
					freePrescriptionResponse.data.syncClientPrescriptions.items[0];

				if (freePrescription) {
					const freePrescriptionsResponse = await execute(syncClientFreePrescriptions, {
						filter: {
							clientPrescriptionID: {
								eq: freePrescription.id,
							},
							status: {
								ne: Status.INACTIVE,
							},
						},
					});

					consultationFreePrescription =
						freePrescriptionsResponse.data.syncClientFreePrescriptions.items;
				}
			}

			const diagnosticResponse = await execute(syncConsultationDiagnostics, {
				filter: {
					clientConsultationID: {
						eq: consultation.id,
					},
					status: {
						ne: Status.INACTIVE,
					},
				},
			});

			const diagnostics = diagnosticResponse.data.syncConsultationDiagnostics.items;

			return {
				...consultation,
				prescription: {
					medicines: consultationMedicinePrescription,
					analisis: consultationAnalisisPrescription,
					freePrescription: consultationFreePrescription,
				},
				diagnostics,
			};
		} catch (error) {
			relationsContext.handleError(error, false);
		} finally {
			showSpinner(false);
		}
	};

	const getConsultationsData = async (clientID?: string, hospitalID?: string) => {
		try {
			setLoadingConsultations(true);
			const customFilter = await relationsContext.getCustomHospitalDoctorFilters();
			const hospitalDoctorClientResponse = await execute(syncHospitalDoctorClientes, {
				filter: {
					clientID: {
						eq: selectedClient?.id || clientID,
					},
					or: customFilter,
				},
				limit: 20000,
			});

			const hospitalDoctorClient =
				hospitalDoctorClientResponse.data.syncHospitalDoctorClientes.items[0];

			if (!hospitalDoctorClient) {
				relationsContext.handleError('hospital doctor client not found', false);
				return;
			}

			const consultationsResponse = await execute(syncClientConsultations, {
				filter: {
					clientHospitalDoctorID: {
						eq: hospitalDoctorClient?.id,
					},
					status: {
						ne: Status.INACTIVE,
					},
				},
			});
			const consultations = consultationsResponse.data.syncClientConsultations.items;
			let consultationFreePrescription = [] as ClientFreePrescription[];

			const consultationsData = await Promise.all(
				consultations.map(async (consultation: any) => {
					let consultationMedicinePrescription;
					let consultationAnalisisPrescription;
					if (consultation.clientMedicinePrescriptionID) {
						let list = [] as ClientPrescriptionMedicine[];

						const medicinePrescriptionResponse = await execute(
							syncClientPrescriptions,
							{
								filter: {
									id: {
										eq: consultation.clientMedicinePrescriptionID,
									},
									status: {
										ne: Status.INACTIVE,
									},
								},
							}
						);

						const medicinePrescriptions =
							medicinePrescriptionResponse.data.syncClientPrescriptions.items[0];

						if (
							medicinePrescriptions &&
							medicinePrescriptions.type === PrescriptionTypes.MEDICINE
						) {
							const clientPrescriptionMedicineResponse = await execute(
								syncClientPrescriptionMedicines,
								{
									filter: {
										clientPrescriptionID: {
											eq: medicinePrescriptions.id,
										},
										status: {
											ne: Status.INACTIVE,
										},
									},
								}
							);

							list =
								clientPrescriptionMedicineResponse.data
									.syncClientPrescriptionMedicines.items;
						}
						consultationMedicinePrescription = list;
					}

					if (consultation.clientAnalisisPrescriptionID) {
						let list = [] as ClientPrescriptionAnalisis[];

						const analisisPrescriptionResponse = await execute(
							syncClientPrescriptions,
							{
								filter: {
									id: {
										eq: consultation.clientAnalisisPrescriptionID,
									},
									status: {
										ne: Status.INACTIVE,
									},
								},
							}
						);

						const analisisPrescription =
							analisisPrescriptionResponse.data.syncClientPrescriptions.items[0];

						if (
							analisisPrescription &&
							analisisPrescription.type === PrescriptionTypes.LABIMAGE
						) {
							const clientPrescriptionResponse = await execute(
								syncClientPrescriptionAnalises,
								{
									filter: {
										clientPrescriptionID: {
											eq: analisisPrescription.id,
										},
										status: {
											ne: Status.INACTIVE,
										},
									},
								}
							);

							list =
								clientPrescriptionResponse.data.syncClientPrescriptionAnalises
									.items;
						}
						consultationAnalisisPrescription = list;
					}

					if (consultation.clientFreePrescriptionID) {
						const freePrescriptionResponse = await execute(syncClientPrescriptions, {
							filter: {
								id: {
									eq: consultation.clientFreePrescriptionID,
								},
								status: {
									ne: Status.INACTIVE,
								},
							},
						});

						const freePrescription =
							freePrescriptionResponse.data.syncClientPrescriptions.items[0];

						if (freePrescription) {
							const freePrescriptionsResponse = await execute(
								syncClientFreePrescriptions,
								{
									filter: {
										clientPrescriptionID: {
											eq: freePrescription.id,
										},
										status: {
											ne: Status.INACTIVE,
										},
									},
								}
							);

							consultationFreePrescription =
								freePrescriptionsResponse.data.syncClientFreePrescriptions.items;
						}
					}

					const diagnosticResponse = await execute(syncConsultationDiagnostics, {
						filter: {
							clientConsultationID: {
								eq: consultation.id,
							},
							status: {
								ne: Status.INACTIVE,
							},
						},
					});

					const diagnostics = diagnosticResponse.data.syncConsultationDiagnostics.items;

					return {
						...consultation,
						prescription: {
							medicines: consultationMedicinePrescription,
							analisis: consultationAnalisisPrescription,
							freePrescription: consultationFreePrescription,
						},
						diagnostics,
					};
				})
			);

			const orderedConsultationsData = consultationsData.sort(
				(objA: any, objB: any) =>
					new Date(objB.createdAt as string).getTime() -
					new Date(objA.createdAt as string).getTime()
			);

			setClientConsultations(orderedConsultationsData);
		} catch (error) {
			relationsContext.handleError(error, false);
			return [];
		} finally {
			setLoadingConsultations(false);
		}
	};

	const getClientNotes = async (clientId: string, hospitalClient: any) => {
		const user = await Auth.currentAuthenticatedUser();
		const isDoctorHandler = isDoctor(user);
		const isDoctorMemberHandler = isDoctorMember(user);
		let note = '';
		if (isDoctorMemberHandler) {
			const clientNoteResponse = await execute(syncMemberHospitalDoctorClients, {
				filter: {
					memberID: {
						eq: relationsContext.actualHospitalDoctor?.doctor?.id as string,
					},
					clientID: {
						eq: clientId,
					},
				},
			});

			const clientNote = clientNoteResponse.data.syncMemberHospitalDoctorClients.items[0];

			note = clientNote?.note || '';
		} else if (isDoctorHandler) {
			note = hospitalClient?.note || '';
		} else {
			const doctorNoteResponse = await execute(syncSecretaryHospitalDoctorClients, {
				filter: {
					secretaryID: {
						eq: relationsContext.actualSecretary?.id as string,
					},
					clientID: {
						eq: clientId,
					},
				},
			});

			const doctorNote = doctorNoteResponse.data.syncSecretaryHospitalDoctorClients.items[0];

			note = doctorNote?.note || '';
		}
		return note;
	};

	const getMainPageClientData = async (clientId: string): Promise<any> => {
		try {
			const customFilter = await relationsContext.getCustomHospitalDoctorFilters();
			const hospitalDoctorClientResponse = await execute(syncHospitalDoctorClientes, {
				filter: {
					clientID: {
						eq: clientId,
					},
					or: customFilter,
				},
			});

			const hospitalClient =
				hospitalDoctorClientResponse.data.syncHospitalDoctorClientes.items[0];

			if (!hospitalClient) {
				relationsContext.handleError('hospitalClient not found', false);
				return;
			}
			const consultations = await fetchConsultationsByClientId(hospitalClient.id);

			let profileImage: string | undefined = undefined;
			if (hospitalClient.client!.profileImage) {
				const url = await Storage.get(hospitalClient.client!.profileImage);
				profileImage = url;
			}

			let healthInsurance = {} as Insurance;
			let clientHealthInsurance = {} as ClientInsurance;
			if (hospitalClient?.lastHealthInsurranceID) {
				const clientInsuranceResponse = await execute(syncClientInsurances, {
					filter: {
						clientID: {
							eq: hospitalClient.client!.id,
						},
					},
				});
				const clientInsurance = clientInsuranceResponse.data.syncClientInsurances.items[0];
				if (clientInsurance) {
					clientHealthInsurance = clientInsurance;

					healthInsurance = clientInsurance.insurance!;
				}
			}

			const appointments = await fetchClientAppointments(hospitalClient.client!.id);
			let note: string = await getClientNotes(clientId, hospitalClient);

			return {
				...hospitalClient.client!,
				height: hospitalClient.client!.height || '',
				profileImageKey: hospitalClient.client!.profileImage || '',
				profileImage,
				heartBeat: hospitalClient.client!.heartBeat || '',
				weight: hospitalClient.client!.weight || '',
				gender: (hospitalClient.client!.gender === SexType.NOSEX
					? ''
					: hospitalClient.client!.gender!) as SexType,
				healthInsuranceId: clientHealthInsurance ? clientHealthInsurance.insuranceID! : '',
				actualInsurance: hospitalClient.client!.actualInsurance,
				allowInsuranceClaim: healthInsurance?.allowClaim ?? false,
				healthInsuranceAuthCode: healthInsurance ? (healthInsurance.code as string) : '',
				healthInsuranceName: healthInsurance ? healthInsurance.name : '',
				contractNumber: clientHealthInsurance
					? (clientHealthInsurance.contractNumber as string)
					: '',
				affiliateNumber: clientHealthInsurance
					? (clientHealthInsurance.affiliateNumber as string)
					: '',
				affiliateType: clientHealthInsurance
					? (clientHealthInsurance.affiliateType as AffiliateTypes)
					: AffiliateTypes.PRINCIPAL,
				history: history || [],
				status: hospitalClient.clientStatus || '',
				consultations: consultations || [],
				note: note,
				appointments: appointments || [],
				recordNo: hospitalClient?.recordNo || '',
			};
		} catch (error) {
			relationsContext.handleError(error, false);
		}
	};

	const getClientDataToEdit = async (clientId: string): Promise<ClientType> => {
		try {
			const clientResponse = await execute(syncHospitalDoctorClientesForEditClient, {
				filter: {
					clientID: {
						eq: clientId,
					},
				},
			});

			const hospitalClient = clientResponse.data.syncHospitalDoctorClientes.items[0];

			let profileImage: string | undefined = undefined;
			if (hospitalClient.client!.profileImage) {
				const url = await Storage.get(hospitalClient.client!.profileImage);
				profileImage = url;
			}

			if (hospitalClient?.lastHealthInsurranceID && !hospitalClient.clientInsurance) {
				const clientInsuranceResponse = await execute(syncClientInsurances, {
					filter: {
						clientID: {
							eq: hospitalClient.client!.id,
						},
					},
				});

				const clientInsurance = clientInsuranceResponse.data.syncClientInsurances.items[0];

				hospitalClient.clientInsurance = clientInsurance;
			}
			return {
				...hospitalClient.client!,
				height: hospitalClient.client!.height || '',
				profileImageKey: hospitalClient.client!.profileImage || '',
				profileImage,
				heartBeat: hospitalClient.client!.heartBeat || '',
				weight: hospitalClient.client!.weight || '',
				gender: (hospitalClient.client!.gender === SexType.NOSEX
					? ''
					: hospitalClient.client!.gender!) as SexType,
				healthInsuranceId: hospitalClient.clientInsurance
					? hospitalClient.clientInsurance.insuranceID!
					: '',
				actualInsurance: hospitalClient.client!.actualInsurance,
				allowInsuranceClaim: hospitalClient.clientInsurance?.insurance?.allowClaim ?? false,
				healthInsuranceAuthCode: hospitalClient.clientInsurance?.insurance
					? (hospitalClient.clientInsurance.insurance.code as string)
					: '',
				healthInsuranceName: hospitalClient.clientInsurance?.insurance
					? hospitalClient.clientInsurance.insurance.name
					: '',
				contractNumber: hospitalClient.clientInsurance
					? (hospitalClient.clientInsurance.contractNumber as string)
					: '',
				affiliateNumber: hospitalClient.clientInsurance
					? (hospitalClient.clientInsurance.affiliateNumber as string)
					: '',
				affiliateType: hospitalClient.clientInsurance
					? (hospitalClient.clientInsurance.affiliateType as AffiliateTypes)
					: AffiliateTypes.PRINCIPAL,
				history: history || [],
				status: hospitalClient.clientStatus || '',
			};
		} catch (error) {
			relationsContext.handleError(error, false);
			return {} as ClientType;
		}
	};

	const updateClientDoctorNote = async (clientID: string, note: string) => {
		try {
			const user = await Auth.currentAuthenticatedUser();
			const isDoctorHandler = isDoctor(user);
			const isMember = isDoctorMember(user);
			if (isMember) {
				const clientNoteResponse = await execute(syncMemberHospitalDoctorClients, {
					filter: {
						memberID: {
							eq: relationsContext.actualHospitalDoctor?.doctor?.id as string,
						},
						clientID: {
							eq: clientID,
						},
					},
				});

				const clientNote = clientNoteResponse.data.syncMemberHospitalDoctorClients.items[0];

				if (clientNote) {
					await execute(updateMemberHospitalDoctorClient, {
						input: {
							id: clientNote.id,
							note: note,
							_version: clientNote._version,
						},
					});
				} else {
					await execute(createMemberHospitalDoctorClient, {
						input: {
							note: note,
							clientID: clientID,
							ownerID: relationsContext?.ownerHospitalDoctorId,
							memberID: relationsContext.actualHospitalDoctor?.doctor?.id as string,
						},
					});
				}
			} else if (isDoctorHandler) {
				const customFilter = await relationsContext.getCustomHospitalDoctorFilters();
				const clientNoteResponse = await execute(syncHospitalDoctorClientes, {
					filter: {
						clientID: {
							eq: clientID,
						},
						or: customFilter,
					},
				});

				const clientNote = clientNoteResponse.data.syncHospitalDoctorClientes.items[0];

				if (clientNote) {
					await execute(updateHospitalDoctorCliente, {
						input: {
							id: clientNote.id,
							note: note,
							_version: clientNote._version,
						},
					});
				} else {
					await execute(createHospitalDoctorCliente, {
						input: {
							note: note,
							clientID: clientID,
							hospitalDoctorID: relationsContext.actualHospitalDoctor?.id as string,
							clientStatus: Status.ACTIVE,
						},
					});
				}
			} else {
				const clientNoteResponse = await execute(syncSecretaryHospitalDoctorClients, {
					filter: {
						secretaryID: {
							eq: relationsContext.actualSecretary?.id as string,
						},
						clientID: {
							eq: clientID,
						},
					},
				});
				const clientNote =
					clientNoteResponse.data.syncSecretaryHospitalDoctorClients.items[0];

				if (clientNote) {
					await execute(updateSecretaryHospitalDoctorClient, {
						input: {
							id: clientNote.id,
							note: note,
							_version: clientNote._version,
						},
					});
				} else {
					await execute(createSecretaryHospitalDoctorClient, {
						input: {
							note: note,
							clientID: clientID,
							secretaryID: relationsContext.actualSecretary?.id as string,
						},
					});
				}
			}

			await updateClientWithOutChanges(clientID as string);

			setSelectedClient((prevClient) => ({ ...prevClient, note: note } as ClientType));
		} catch (error) {
			relationsContext.handleError(error, false);
		}
	};

	const updateClient = async (dataForm: IdataForm, profileImage?: string) => {
		try {
			if (selectedClient) {
				const clientInfoResponse = await execute(syncClients, {
					filter: {
						id: {
							eq: selectedClient.id,
						},
					},
				});

				const clientInfo = clientInfoResponse.data.syncClients.items[0];

				const savedClientResponse = await execute(updateClientMutation, {
					input: {
						id: clientInfo.id,
						identificationName: dataForm.identificationName,
						identificationData: dataForm.identificationData,
						name: dataForm.name,
						lastName: dataForm.lastName,
						cellphoneNumber: dataForm.cellphoneNumber,
						occupation: dataForm.occupation,
						isAdult: dataForm.isAdult,
						phoneNumber: dataForm.phoneNumber,
						actualInsurance: dataForm.actualInsurance,
						addressStreet: dataForm.addressStreet,
						bloodType: dataForm.bloodType,
						bornDate: dataForm.bornDate,
						city: dataForm.city,
						company: dataForm.company,
						email: dataForm.email,
						gender: dataForm.gender,
						sector: dataForm.sector,
						profileImage: profileImage,
						updateDateTime: moment().toISOString(),
						_version: clientInfo._version,
					},
				});

				const savedClient = savedClientResponse.data.updateClient;
				const customFilter = await relationsContext.getCustomHospitalDoctorFilters();
				const hospitalDoctorClientResponse = await execute(syncHospitalDoctorClientes, {
					filter: {
						clientID: {
							eq: savedClient.id,
						},
						or: customFilter,
					},
				});

				const hospitalDoctorClient =
					hospitalDoctorClientResponse.data.syncHospitalDoctorClientes.items[0];
				let record = '';
				if (!hospitalDoctorClient.recordNo) {
					record = await getRecordNo(customFilter);

					execute(updateHospitalDoctorCliente, {
						input: {
							id: hospitalDoctorClient.id,
							recordNo: record,
							_version: hospitalDoctorClient._version,
						},
					});
				}

				const insuranceResponse = await getInsuranceResponse(dataForm.actualInsurance);

				const insurance = insuranceResponse.data.syncInsurances.items[0];

				const actualHospitalDoctorResponse = await execute(syncHospitalDoctorClientes, {
					filter: {
						clientID: {
							eq: selectedClient.id,
						},
						or: customFilter,
					},
				});

				const actualHospitalDoctor =
					actualHospitalDoctorResponse.data.syncHospitalDoctorClientes.items[0];
				if (selectedClient?.healthInsuranceId) {
					const clientInsuranceResponse = await execute(syncClientInsurances, {
						filter: {
							clientID: {
								eq: selectedClient.id,
							},
							insuranceID: {
								eq: selectedClient.healthInsuranceId as string,
							},
						},
					});

					const clientInsuranceOriginal =
						clientInsuranceResponse.data.syncClientInsurances.items[0];

					let clientInsurance: ClientInsurance;

					if (clientInsuranceOriginal) {
						const clientInsuranceUpdatedResponse = await execute(
							updateClientInsurance,
							{
								input: {
									id: clientInsuranceOriginal.id,
									affiliateNumber: dataForm.affiliateNumber,
									affiliateType:
										dataForm.affiliateType || AffiliateTypes.PRINCIPAL,
									contractNumber: dataForm.contractNumber,
									insuranceID: insurance.id,
									_version: clientInsuranceOriginal._version,
								},
							}
						);

						clientInsurance = clientInsuranceUpdatedResponse.data.updateClientInsurance;
					} else {
						const newInsuranceCreated = await execute(createClientInsurance, {
							input: {
								affiliateNumber: dataForm.affiliateNumber,
								clientID: selectedClient.id,
								affiliateType: dataForm.affiliateType || AffiliateTypes.PRINCIPAL,
								contractNumber: dataForm.contractNumber,
								insuranceID: insurance.id,
							},
						});

						// eslint-disable-next-line @typescript-eslint/no-unused-vars
						clientInsurance = newInsuranceCreated.data.createClientInsurance;
					}
				}

				if (actualHospitalDoctor) {
					await execute(updateHospitalDoctorCliente, {
						input: {
							id: actualHospitalDoctor.id,
							lastHealthInsurranceID: insurance.id,
							_version: actualHospitalDoctor._version,
						},
					});
				} else {
					await relationsContext.createHospitalDoctorClients(
						savedClient.id,
						relationsContext.actualHospitalDoctor?.id as string,
						insurance.id
					);
				}

				let userImg: string | undefined = undefined;
				if (savedClient.profileImage) {
					const url = await Storage.get(savedClient.profileImage);
					userImg = url;
				}
				let newClients = [] as ClientType[];
				if (!selectedClient) {
					const currentPath = history.location.pathname;

					if (currentPath === '/') {
						try {
							setClientsLoading(true);
							setPaginatedClients((prev) => {
								const updatedClient = {
									...savedClient,
									profileImage: userImg,
									recordNo: record,
								};

								const otherClients = (
									prev.clients.length === 0 ? result : newClients
								).filter((client) => client.id !== savedClient.id);
								return {
									...prev,
									clients: [updatedClient, ...otherClients],
									pageIndex: page,
									pageSize: limit,
								};
							});
						} catch (error) {
							relationsContext.setAlertMessage({
								message: 'Error al actualizar la lista de clientes.',
								severity: 'error',
							});
						} finally {
							setClientsLoading(false);
						}
					}
				} else {
					setPaginatedClients((prev) => {
						const updatedClient = {
							...savedClient,
							profileImage: userImg,
							recordNo: record,
						};

						const otherClients = (
							prev.clients.length === 0 ? result : prev.clients
						).filter((client) => client.id !== savedClient.id);
						return {
							...prev,
							clients: [updatedClient, ...otherClients],
							pageIndex: page,
							pageSize: page,
						};
					});
					setSelectedClient((prev) => ({ ...prev, ...dataForm, profileImage: userImg }));
				}

				relationsContext.setAlertMessage({
					message: `${dataForm.name} ${dataForm.lastName} actualizado correctamente`,
					severity: 'success',
				});
			}
		} catch (error) {
			relationsContext.handleError(error, false);
		}
	};

	const updateClientHistory = async (original: ClientHistoryType, updated: ClientHistory) => {
		const originalHistoryResponse = await execute(syncClientHistories, {
			filter: {
				id: {
					eq: original.id,
				},
				status: {
					ne: Status.INACTIVE,
				},
			},
		});

		const originalHistory = originalHistoryResponse.data.syncClientHistories.items[0];

		const historyWithUpdate = ClientHistory.copyOf(originalHistory!, (draft) => ({
			...draft,
			title: updated.title,
			description: updated.description,
			personalPathologicalHistory: updated.personalPathologicalHistory,
			familyPathologicalHistory: updated.familyPathologicalHistory,
			physicalTest: updated.physicalTest,
			treatment: updated.treatment,
			authorizationDetail: updated.authorizationDetail,
		}));

		await execute(updateClientHistoryMutation, {
			input: {
				title: historyWithUpdate.title,
				description: historyWithUpdate.description,
				personalPathologicalHistory: historyWithUpdate.personalPathologicalHistory,
				familyPathologicalHistory: historyWithUpdate.familyPathologicalHistory,
				physicalTest: historyWithUpdate.physicalTest,
				treatment: historyWithUpdate.treatment,
				authorizationDetail: historyWithUpdate.authorizationDetail,
			},
		});

		if (!selectedClient) return;

		const newClientData = await getMainPageClientData(selectedClient?.id);

		setSelectedClient(newClientData);
	};

	const updateClientNote = async (original: ClientNotes, updated: ClientNotes) => {
		const originalNoteResponse = await execute(syncClientNotes, {
			filter: {
				id: {
					eq: original.id,
				},
			},
		});
		const originalNote = originalNoteResponse.data.syncClientNotes.items[0];

		const noteUpdatedResponse = await execute(updateClientNotesMutation, {
			input: {
				id: originalNote.id,
				title: updated.title,
				noteBody: updated.noteBody,
				createdDate: updated.createdDate,
				endModifyDate: updated.endModifyDate,
				_version: originalNote._version,
			},
		});

		const noteUpdated = noteUpdatedResponse.data.updateClientNotes;

		const newClient: ClientType = {
			...selectedClient!,
			notes: selectedClient
				? selectedClient?.notes?.map((note) => {
						if (note.id === original.id) {
							return noteUpdated;
						}
						return note;
				  })
				: [],
		};

		setSelectedClient(newClient);
	};

	const deleteClientHistory = async (history: ClientHistory) => {
		if (!selectedClient) return;

		const clientHistoryResponse = await execute(syncClientHistories, {
			filter: {
				id: {
					eq: history?.id,
				},
			},
		});

		const clientHistory = clientHistoryResponse.data.syncClientHistories.items[0];

		await execute(updateClientHistoryMutation, {
			input: {
				id: clientHistory.id,
				status: Status.INACTIVE,
				_version: clientHistory._version,
			},
		});

		setSelectedClient((selectedClient) => ({
			...selectedClient!,
			history: selectedClient?.history?.filter((h) => h.id !== history.id),
		}));

		relationsContext.setAlertMessage({
			message: 'Historial eliminado correctamente',
			severity: 'success',
		});
	};

	const deleteClientNote = async (note: ClientNotes) => {
		if (!selectedClient) return;

		const clientNoteResponse = await execute(syncClientNotes, {
			filter: {
				id: {
					eq: note.id,
				},
			},
		});

		const clientNote = clientNoteResponse.data.syncClientNotes.items[0];

		await execute(updateClientNotesMutation, {
			input: {
				id: clientNote.id,
				status: Status.INACTIVE,
				_version: clientNote._version,
			},
		});

		const newSelectedClient: ClientType = {
			...selectedClient!,
			notes: selectedClient.notes?.filter((n) => n.id !== note.id),
		};

		setSelectedClient(newSelectedClient);
		relationsContext.setAlertMessage({
			message: 'Nota eliminada correctamente',
			severity: 'success',
		});
	};

	const addClientHistory = async (newHistory: ClientHistory) => {
		if (!selectedClient) return;
		const customFilter = await relationsContext.getCustomHospitalDoctorFilters();
		const hospitalDoctorClientResponse = await execute(syncHospitalDoctorClientes, {
			filter: {
				clientID: {
					eq: selectedClient?.id as string,
				},
				or: customFilter,
			},
		});

		const HospitalDoctorClient =
			hospitalDoctorClientResponse.data.syncHospitalDoctorClientes.items[0];

		const newHistoryClient: ClientHistory = {
			...newHistory,
			clientHospitalDoctorID: HospitalDoctorClient.id,
			status: Status.ACTIVE,
		};

		const newHistoryResponse = await execute(createClientHistory, {
			input: newHistoryClient,
		});

		const history = newHistoryResponse.data.createClientHistory;

		setSelectedClient((sc) => ({
			...sc!,
			history: [
				{
					...history,
					healthInsuranceAuthCode: selectedClient.healthInsuranceAuthCode,
					healthInsuranceName: selectedClient.healthInsuranceName,
				},
			].concat(selectedClient.history?.filter((note) => note.id !== '-1')),
		}));

		relationsContext.setAlertMessage({
			message: 'Historial creado correctamente',
			severity: 'success',
		});
	};

	const addClientNote = async (newNote: ClientNotes) => {
		if (!selectedClient) return;
		const customFilter = await relationsContext.getCustomHospitalDoctorFilters();
		const hospitalDoctorClientResponse = await execute(syncHospitalDoctorClientes, {
			filter: {
				clientID: {
					eq: selectedClient?.id as string,
				},
				or: customFilter,
			},
		});

		const HospitalDoctorClient =
			hospitalDoctorClientResponse.data.syncHospitalDoctorClientes.items[0];

		const newNoteClient: ClientNotes = {
			...newNote,
			clientHospitalDoctorID: HospitalDoctorClient.id,
			status: Status.ACTIVE,
		};

		const noteResponse = await execute(createClientNotes, {
			input: newNoteClient,
		});

		const note = noteResponse.data.createClientNotes;

		//adding new note at the beggining of the list and also removing the temp note
		const newSelectedClient: ClientType = {
			...selectedClient,
			notes: [note].concat(selectedClient.notes?.filter((note) => note.id !== '-1')),
		};

		setSelectedClient(newSelectedClient);

		relationsContext.setAlertMessage({
			message: 'Nota creada correctamente',
			severity: 'success',
		});
	};

	const updateClientProcSurg = async (
		original: ClientProcedureSurgery,
		updated: ClientProcedureSurgery
	) => {
		const originalProcSurgResponse = await execute(syncClientProcedureSurgeries, {
			filter: {
				id: {
					eq: original.id,
				},
			},
		});

		const orignalProcSurg = originalProcSurgResponse.data.syncClientProcedureSurgeries.items[0];

		const updatedProcSurg = ClientProcedureSurgery.copyOf(orignalProcSurg!, (draft) => ({
			...draft,
			title: updated.title,
			description: updated.description,
			createdDate: updated.createdDate,
			endModifyDate: updated.endModifyDate,
		}));

		await execute(updateClientProcedureSurgery, {
			input: {
				id: orignalProcSurg.id,
				title: updatedProcSurg.title,
				description: updatedProcSurg.description,
				createdDate: updatedProcSurg.createdDate,
				endModifyDate: updatedProcSurg.endModifyDate,
				_version: orignalProcSurg._version,
			},
		});

		const newClient: ClientType = {
			...selectedClient!,
			surgeries: selectedClient
				? selectedClient?.surgeries?.map((surgery) => {
						if (surgery.id === original.id) {
							return updatedProcSurg;
						}
						return surgery;
				  })
				: [],
		};

		setSelectedClient(newClient);
	};

	const addClientProcSurg = async (newProcSurg: ClientProcedureSurgery) => {
		if (!selectedClient) return;
		const customFilter = await relationsContext.getCustomHospitalDoctorFilters();
		const hospitalDoctorClientResponse = await execute(syncHospitalDoctorClientes, {
			filter: {
				clientID: {
					eq: selectedClient?.id as string,
				},
				or: customFilter,
			},
		});

		const HospitalDoctorClient =
			hospitalDoctorClientResponse.data.syncHospitalDoctorClientes.items[0];

		const newProcSurgClient: ClientProcedureSurgery = {
			...newProcSurg,
			clientHospitalDoctorID: HospitalDoctorClient.id,
			status: Status.ACTIVE,
		};

		const newProcSurgResponse = await execute(createClientProcedureSurgery, {
			input: newProcSurgClient,
		});

		const procSurgClient = newProcSurgResponse.data.createClientProcedureSurgery;

		const newSelectedClient: ClientType = {
			...selectedClient,
			surgeries: [procSurgClient].concat(
				selectedClient.surgeries?.filter((s) => s.id !== '-1')
			),
		};

		//adding new note at the beggining of the list and also removing the temp note
		setSelectedClient(newSelectedClient);

		relationsContext.setAlertMessage({
			message: 'Procedimiento/Cirugía creada correctamente',
			severity: 'success',
		});
	};

	const deleteClientProcSurg = async (surgery: ClientProcedureSurgery) => {
		if (!selectedClient) return;
		const clientProcedureSurgeryResponse = await execute(syncClientProcedureSurgeries, {
			filter: {
				id: {
					eq: surgery.id,
				},
			},
		});

		const client_procedure_surgery =
			clientProcedureSurgeryResponse.data.syncClientProcedureSurgeries.items[0];

		await execute(updateClientProcedureSurgery, {
			input: {
				id: client_procedure_surgery.id,
				status: Status.INACTIVE,
				_version: client_procedure_surgery._version,
			},
		});

		const newSelectedClient: ClientType = {
			...selectedClient!,
			surgeries: selectedClient.surgeries?.filter(
				(s) => s.id !== client_procedure_surgery.id
			),
		};

		setSelectedClient(newSelectedClient);
		relationsContext.setAlertMessage({
			message: 'Procedimiento/Cirugía eliminada correctamente',
			severity: 'success',
		});
	};

	const deleteClient = async (id: string) => {
		const patientInfoResponse = await execute(syncHospitalDoctorClientes, {
			filter: {
				clientID: {
					eq: id,
				},
			},
		});

		const patientInfo = patientInfoResponse.data.syncHospitalDoctorClientes.items[0];

		await execute(updateHospitalDoctorCliente, {
			input: {
				id: patientInfo.id,
				clientStatus: Status.INACTIVE,
				_version: patientInfo._version,
			},
		});

		setPaginatedClients(
			(prevClients) =>
				({
					...prevClients!,
					clients: prevClients?.clients?.filter((c) => c.id !== id),
				} as IPaginatedClients)
		);

		relationsContext.setActualClient({} as Client);
		relationsContext.setAlertMessage({
			message: 'Paciente eliminado correctamente',
			severity: 'success',
		});
	};

	const updateClientHeartBeat = async (heartBeat: string) => {
		const originalClientResponse = await execute(syncClients, {
			filter: {
				id: {
					eq: selectedClient?.id,
				},
			},
		});

		const originalClient = originalClientResponse.data.syncClients.items[0];

		const updatedClientResponse = await execute(updateClientMutation, {
			input: {
				id: originalClient.id,
				heartBeat: heartBeat,
				_version: originalClient._version,
			},
		});

		const updatedClient = updatedClientResponse.data.updateClient;

		setSelectedClient!((client) => ({
			...client!,
			heartBeat: updatedClient?.heartBeat!,
		}));
	};

	const updateClientBloodPressure = async (bloddPressure: string) => {
		const originalClientResponse = await execute(syncClients, {
			filter: {
				id: {
					eq: selectedClient?.id,
				},
			},
		});

		const originalClient = originalClientResponse.data.syncClients.items[0];

		const updatedClientResponse = await execute(updateClientMutation, {
			input: {
				id: originalClient.id,
				bloodPressure: bloddPressure,
				_version: originalClient._version,
			},
		});

		const updatedClient = updatedClientResponse.data.updateClient;

		setSelectedClient!(
			(client) =>
				({
					...client!,
					bloodPressure: updatedClient?.bloodPressure as string,
				} as ClientType)
		);
	};

	const updateClientWeight = async (weight: string) => {
		const originalClientResponse = await execute(syncClients, {
			filter: {
				id: {
					eq: selectedClient?.id,
				},
			},
		});

		const originalClient = originalClientResponse.data.syncClients.items[0];

		const updatedClientResponse = await execute(updateClientMutation, {
			input: {
				id: originalClient.id,
				weight: weight,
				_version: originalClient._version,
			},
		});

		const updatedClient = updatedClientResponse.data.updateClient;

		setSelectedClient!(
			(client) =>
				({
					...client!,
					weight: updatedClient?.weight as string,
				} as ClientType)
		);
	};

	const updateClientHeight = async (height: string) => {
		const originalClientResponse = await execute(syncClients, {
			filter: {
				id: {
					eq: selectedClient?.id,
				},
			},
		});

		const originalClient = originalClientResponse.data.syncClients.items[0];

		const updatedClientResponse = await execute(updateClientMutation, {
			input: {
				id: originalClient.id,
				height: height,
				_version: originalClient._version,
			},
		});

		const updatedClient = updatedClientResponse.data.updateClient;

		setSelectedClient(
			(client) =>
				({
					...client,
					height: updatedClient?.height as string,
				} as ClientType)
		);
	};

	const updateHospitalDoctorClientStatus = async (status: string) => {
		const customFilter = await relationsContext.getCustomHospitalDoctorFilters();
		const originalHospitalDoctorClientStatusResponse = await execute(
			syncHospitalDoctorClientes,
			{
				filter: {
					clientID: {
						eq: selectedClient?.id as string,
					},
					or: customFilter,
				},
			}
		);

		const originalHospitalDoctorClientStatus =
			originalHospitalDoctorClientStatusResponse.data.syncHospitalDoctorClientes.items[0];

		const updatedHospitalDoctorClientStatusResponse = await execute(
			updateHospitalDoctorCliente,
			{
				input: {
					id: originalHospitalDoctorClientStatus.id,
					clientStatus: status,
					_version: originalHospitalDoctorClientStatus._version,
				},
			}
		);

		const updatedClient =
			updatedHospitalDoctorClientStatusResponse.data.updateHospitalDoctorCliente;

		setSelectedClient!((client) => ({
			...client!,
			status: updatedClient?.clientStatus as string,
		}));
	};

	const deleteClaim = async (id: string) => {
		try {
			const clientClaim = await execute(syncClientClaims, {
				filter: {
					id: {
						eq: id,
					},
				},
			});

			const client_claim = clientClaim.data.syncClientClaims.items[0];

			await execute(updateClientClaim, {
				input: {
					id: client_claim.id,
					status: Status.INACTIVE,
					_version: client_claim._version,
				},
			});

			setSelectedClient((client: any) => ({
				...client,
				claims: client.claims.filter((claim: any) => claim.id !== id),
			}));
		} catch (error) {
			relationsContext.handleError(error, false);
		}
	};

	const updateClientWithOutChanges = async (clientId: string) => {
		const clientToUpdate = await fetchPatientById(clientId);

		await execute(updateClientMutation, {
			input: {
				id: clientToUpdate?.id,
				updateDateTime: moment().toISOString(),
				_version: clientToUpdate?._version,
			},
		});
	};

	const createClaim = async (claimValues: any, processes: ClaimProcess[]) => {
		try {
			const customFilter = await relationsContext.getCustomHospitalDoctorFilters();
			const hospitalDoctorClientResponse = await execute(syncHospitalDoctorClientes, {
				filter: {
					clientID: {
						eq: selectedClient?.id as string,
					},
					or: customFilter,
				},
			});

			const HospitalDoctorClient =
				hospitalDoctorClientResponse.data.syncHospitalDoctorClientes.items[0];

			const newClaimResponse = await execute(createClientClaim, {
				input: {
					clientHospitalDoctorID: HospitalDoctorClient.id,
					healthInsuranceID: HospitalDoctorClient.lastHealthInsurranceID,
					diagnostic: claimValues.diagnostic,
					authorization: claimValues.authorization,
					date: claimValues.date,
					status: Status.ACTIVE,
				},
			});

			const newClaim = newClaimResponse.data.createClientClaim;

			await Promise.all(
				processes.map(async (process: any) => {
					await execute(createClaimProcess, {
						input: {
							claimID: newClaim.id,
							name: process.name,
							code: process.code,
							claimed: process.claimed,
							difference: process.difference,
							authorized: process.authorized,
						},
					});
				})
			);

			setClientClaims((claims: any) => [
				{
					...newClaim,
					healthInsuranceName: selectedClient?.healthInsuranceName,
					processes: processes.map((process: any) => ({
						claimID: newClaim.id,
						...process,
					})),
				},
				...claims,
			]);

			await updateClientWithOutChanges(selectedClient?.id as string);
		} catch (error) {
			relationsContext.handleError(error, false);
		}
	};

	const addImage = async (
		date: string,
		filename: string,
		pathFile?: string
	): Promise<{ image: ClientImage; imageSectionID: string }> => {
		try {
			const customFilter = await relationsContext.getCustomHospitalDoctorFilters();
			const hospitalDoctorclientResponse = await execute(syncHospitalDoctorClientes, {
				filter: {
					clientID: {
						eq: selectedClient?.id as string,
					},
					or: customFilter,
				},
			});

			const HospitalDoctorClient =
				hospitalDoctorclientResponse.data.syncHospitalDoctorClientes.items[0];

			const clientImageSectionOriginalResponse = await execute(syncClientImageSections, {
				filter: {
					date: {
						eq: date,
					},
					clientHospitalDoctorID: {
						eq: HospitalDoctorClient.id,
					},
					status: {
						ne: Status.INACTIVE,
					},
				},
			});

			const ClientImageSectionOriginal =
				clientImageSectionOriginalResponse.data.syncClientImageSections.items;

			const url = await Storage.get(pathFile!, {
				download: true,
			});
			if (ClientImageSectionOriginal.length === 0) {
				const newClientImageSectionResponse = await execute(createClientImageSection, {
					input: {
						clientHospitalDoctorID: HospitalDoctorClient.id,
						date,
						description: '',
						status: Status.ACTIVE,
					},
				});

				const newClientImageSection =
					newClientImageSectionResponse.data.createClientImageSection;

				const newImageResponse = await execute(createClientImage, {
					input: {
						ClientImageDateID: newClientImageSection.id,
						pathFile: pathFile,
						description: filename,
						status: Status.ACTIVE,
					},
				});

				const newImage = newImageResponse.data.createClientImage;

				await updateClientWithOutChanges(selectedClient?.id as string);
				setClientFiles((files: any) => [
					...files,
					{
						...newClientImageSection!,
						images: [{ ...newImage, url: url.Body }],
					},
				]);
				setImageLoading(false);
				return { image: newImage, imageSectionID: newClientImageSection.id };
			} else {
				const newImageResponse = await execute(createClientImage, {
					input: {
						ClientImageDateID: ClientImageSectionOriginal[0].id,
						pathFile: pathFile,
						description: filename,
						status: Status.ACTIVE,
					},
				});

				const newImage = newImageResponse.data.createClientImage;

				await getClientFilesData();

				await updateClientWithOutChanges(selectedClient?.id as string);

				setImageLoading(false);
				return {
					image: newImage,
					imageSectionID: ClientImageSectionOriginal[0].id,
				};
			}
		} catch (error) {
			relationsContext.handleError(error, false);
			setImageLoading(false);
			return {} as { image: ClientImage; imageSectionID: string };
		}
	};

	const updateImageDateSection = async (id: string, description: string) => {
		const originalImageDateSectionResponse = await execute(syncClientImageSections, {
			filter: {
				id: {
					eq: id,
				},
			},
		});

		const originalImageDateSection =
			originalImageDateSectionResponse.data.syncClientImageSections.items[0];

		await execute(updateClientImageSection, {
			input: {
				id: originalImageDateSection.id,
				description: description,
				_version: originalImageDateSection._version,
			},
		});

		setClientFiles((files: any) => [
			...files.map((file: any) => (file.id === id ? { ...file, description } : file)),
		]);
	};

	const updateImage = async (
		id: string,
		description: string,
		pathFile?: string,
		imageSectionId?: string
	) => {
		const originalImageDateSectionResponse = await execute(syncClientImages, {
			filter: {
				id: {
					eq: id,
				},
			},
		});
		const originalImageDateSection =
			originalImageDateSectionResponse.data.syncClientImages.items[0];
		const url = await Storage.get(pathFile! || originalImageDateSection.pathFile!, {
			download: true,
		});
		let imageUpdated: ClientImageType;

		if (pathFile) {
			const imageUpdatedResponse = await execute(updateClientImage, {
				input: {
					id: originalImageDateSection.id,
					pathFile: pathFile,
					_version: originalImageDateSection._version,
				},
			});
			imageUpdated = imageUpdatedResponse.data.updateClientImage;
		} else {
			const imageUpdatedResponse = await execute(updateClientImage, {
				input: {
					id: originalImageDateSection.id,
					description: description,
					_version: originalImageDateSection._version,
				},
			});
			imageUpdated = imageUpdatedResponse.data.updateClientImage;
		}

		if (imageSectionId) {
			relationsContext.setAlertMessage({
				message: 'El archivo fue subido correctamente!',
				severity: 'success',
			});

			setClientFiles((files: any) => [
				...files.map((file: any) =>
					file.id === imageSectionId
						? {
								...file,
								images: file.images.map((img: any) =>
									img.id === id
										? {
												...imageUpdated!,
												url: url.Body,
										  }
										: img
								),
						  }
						: file
				),
			]);
		}
	};
	//{...file, images: [...file.images, ]}
	const deleteImageDateSection = async (id: string) => {
		const clientImageSectionResponse = await execute(syncClientImageSections, {
			filter: {
				id: {
					eq: id,
				},
			},
		});

		const client_image_section =
			clientImageSectionResponse.data.syncClientImageSections.items[0];

		await execute(updateClientImageSection, {
			input: {
				id: client_image_section.id,
				status: Status.INACTIVE,
				_version: client_image_section._version,
			},
		});

		const file = clientFiles?.find((files: any) => files.id === id)!;

		await Promise.all(
			file.images.map(async (img: any) => {
				await Storage.remove(img.pathFile!);

				const currentImageResponse = await execute(syncClientImages, {
					filter: {
						id: {
							eq: img.id,
						},
					},
				});

				const current_image = currentImageResponse.data.syncClientImages.items[0];

				const updatedImageResponse = await execute(updateClientImage, {
					input: {
						id: current_image.id,
						status: Status.INACTIVE,
						_version: current_image._version,
					},
				});

				const updated_image = updatedImageResponse.data.updateClientImage;

				return updated_image;
			})
		);

		relationsContext.setAlertMessage({
			message: 'Sección eliminada correctamente!',
			severity: 'success',
		});

		setClientFiles((files: any) => files.filter((file: any) => file.id !== id));
	};

	const deleteImage = async (img: ClientImageType) => {
		const clientImageResponse = await execute(syncClientImages, {
			filter: {
				id: {
					eq: img.id,
				},
			},
		});
		const clientImage = clientImageResponse.data.syncClientImages.items[0];

		await execute(updateClientImage, {
			input: {
				id: clientImage.id,
				status: Status.INACTIVE,
				_version: clientImage._version,
			},
		});
		Storage.remove(img.pathFile!);

		const files = clientFiles?.map((file: any) => {
			if (file.id === img.ClientImageDateID) {
				return {
					...file,
					images: file.images?.filter((image: any) => image.id !== img.id),
				};
			}
			return file;
		});

		const updatedFiles = files!.filter((file: any) => file.images.length !== 0);

		if (files) {
			await Promise.all(
				files.map(async (file: any) => {
					if (file.images.length === 0) {
						const clientImageSectionResponse = await execute(syncClientImageSections, {
							filter: {
								id: {
									eq: file.id,
								},
							},
						});

						const clientImageSection =
							clientImageSectionResponse.data.syncClientImageSections.items[0];

						await execute(updateClientImageSection, {
							input: {
								id: clientImageSection.id,
								status: Status.INACTIVE,
								_version: clientImageSection._version,
							},
						});
					}
				})
			);
		}
		setClientFiles(updatedFiles);
		await updateClientWithOutChanges(selectedClient?.id as string);

		relationsContext.setAlertMessage({
			message: 'Archivo eliminada correctamente!',
			severity: 'success',
		});
	};

	const fetchClientAppointments = async (clientId: string) => {
		try {
			const doctorId =
				relationsContext.actualDoctor?.memberId ?? relationsContext.actualDoctor!.id;
			const appointmentsResponse = await execute(syncAppointments, {
				filter: {
					doctorID: {
						eq: doctorId,
					},
					clientID: {
						eq: clientId,
					},
					start: {
						gt: moment().toISOString(),
					},
					status: {
						ne: Status.INACTIVE,
					},
				},
			});

			const appointments = structuredClone(
				appointmentsResponse.data.syncAppointments.items
			) as Appointment[];
			appointments.sort((a, b) => (moment(a.start).isBefore(moment(b.start)) ? -1 : 1));
			return appointments;
		} catch (error) {
			return [];
		}
	};
	const saveClientExampleData = async (hospitalDoctor: HospitalDoctor) => {
		try {
			const createdClientResponse = await execute(createClientMutation, {
				input: EXAMPLE_CLIENT_INFORMATION.personalInformation,
			});
			const createdClient = createdClientResponse.data.createClient as Client;
			const clientInsurance = await associateClientWithInsurance(
				createdClient,
				createdClient?.actualInsurance as string
			);
			const getLastRecord = orderAndGetLast(clients);
			const newRecord = generateNewRecord(getLastRecord);
			await relationsContext.createHospitalDoctorClients(
				createdClient.id,
				hospitalDoctor.id,
				clientInsurance.id,
				newRecord
			);
			if (createdClient) {
				setPaginatedClients((prev) => ({
					...prev,
					clients: [createdClient].concat(prev.clients),
				}));
				setSelectedClient({
					...createdClient,
					recordNo: newRecord,
				});
				return createdClient;
			}
		} catch (error) {}
	};
	const createClientHistoryExampleData = async (clientID: string) => {
		try {
			await Promise.all([
				createPatientAllergy({
					clientID: clientID,
					name: EXAMPLE_CLIENT_INFORMATION.allergies.name,
					status: EXAMPLE_CLIENT_INFORMATION.allergies.status,
					note: EXAMPLE_CLIENT_INFORMATION.allergies.note,
					diagnosedDate: EXAMPLE_CLIENT_INFORMATION.allergies.diagnosedDate,
				}),
				createPatientDisease({
					clientID: clientID,
					name: EXAMPLE_CLIENT_INFORMATION.diseases.name,
					status: EXAMPLE_CLIENT_INFORMATION.diseases.status,
					note: EXAMPLE_CLIENT_INFORMATION.diseases.note,
					diagnosedDate: EXAMPLE_CLIENT_INFORMATION.diseases.diagnosedDate,
				}),
				EXAMPLE_CLIENT_INFORMATION.lifestyle.forEach(async (lifestyle) => {
					await createPatientLifeStyle({
						clientID: clientID,
						name: lifestyle.name,
						description: lifestyle.description,
						lifeStyle: lifestyle.lifeStyle,
					});
				}),
				createPatientProcedure({
					clientID: clientID,
					name: EXAMPLE_CLIENT_INFORMATION.procedures.name,
					note: EXAMPLE_CLIENT_INFORMATION.procedures.note,
					realizationDate: EXAMPLE_CLIENT_INFORMATION.procedures.realizationDate,
				}),
				createPatientHospitalization({
					clientID: clientID,
					name: EXAMPLE_CLIENT_INFORMATION.hospitalizations.name,
					note: EXAMPLE_CLIENT_INFORMATION.hospitalizations.note,
					date: EXAMPLE_CLIENT_INFORMATION.hospitalizations.date,
				}),
				createPatientFamilyHistory({
					clientID: clientID,
					familyMember: EXAMPLE_CLIENT_INFORMATION.familyHistory.familyMember,
					mellitusDiabetes: EXAMPLE_CLIENT_INFORMATION.familyHistory.mellitusDiabetes,
					arterialHypertension:
						EXAMPLE_CLIENT_INFORMATION.familyHistory.arterialHypertension,
				}),
			]);
		} catch (error) {}
	};
	const createClientExampleNote = async (clientID: string, hospitalID: string) => {
		try {
			const noteObj = {
				clientHospitalDoctorID: clientID,
				title: 'Nota de Elias',
				noteBody:
					'El paciente se debe realizar una revisión el próximo 3 de Agosto del 2023',
				status: Status.ACTIVE,
				endModifyDate: new Date().toISOString(),
				createdDate: new Date().toISOString(),
			};
			const customFilter = await relationsContext.getCustomHospitalDoctorFilters();
			const hospitalDoctorClientResponse = await execute(syncHospitalDoctorClientes, {
				filter: {
					clientID: {
						eq: (selectedClient?.id as string) || clientID,
					},
					or: customFilter,
				},
			});

			const HospitalDoctorClient =
				hospitalDoctorClientResponse.data.syncHospitalDoctorClientes.items[0];

			const newNoteClient: ClientNotes = {
				...noteObj,
				clientHospitalDoctorID: HospitalDoctorClient.id,
				status: Status.ACTIVE,
			};

			await execute(createClientNotes, {
				input: newNoteClient,
			});
		} catch (error) {
			console.log(error);
		}
	};
	const createClientConsultationExampleData = async (clientID: string, doctor: DoctorType) => {
		try {
			const consultationObj = {
				clientHospitalDoctorID: clientID,
				visitReason: EXAMPLE_CLIENT_CONSULTATION.visitReason,
				visitDescription: EXAMPLE_CLIENT_CONSULTATION.visitDescription,
				physicTestNote: EXAMPLE_CLIENT_CONSULTATION.physicTestNote,
				bloodPressure: EXAMPLE_CLIENT_CONSULTATION.bloodPressure,
				heartRate: EXAMPLE_CLIENT_CONSULTATION.heartRate,
				weight: EXAMPLE_CLIENT_CONSULTATION.weight,
				height: EXAMPLE_CLIENT_CONSULTATION.height,
				oxygenSaturation: EXAMPLE_CLIENT_CONSULTATION.oxygenSaturation,
				bodyMass: EXAMPLE_CLIENT_CONSULTATION.bodyMass,
				bodyTemperature: EXAMPLE_CLIENT_CONSULTATION.bodyTemperature,
				respiratoryRate: EXAMPLE_CLIENT_CONSULTATION.respiratoryRate,
				clientMedicinePrescriptionID:
					EXAMPLE_CLIENT_CONSULTATION.clientMedicinePrescriptionID,
				clientAnalisisPrescriptionID:
					EXAMPLE_CLIENT_CONSULTATION.clientAnalisisPrescriptionID,
				clientFreePrescriptionID: EXAMPLE_CLIENT_CONSULTATION.clientFreePrescriptionID,
				treatment: EXAMPLE_CLIENT_CONSULTATION.treatment,
				procedureName: EXAMPLE_CLIENT_CONSULTATION.procedureName,
				procedureDescription: EXAMPLE_CLIENT_CONSULTATION.procedureDescription,
				diagnostics: EXAMPLE_CLIENT_CONSULTATION_DIAGNOSTICS.diagnostics,
				diffDiagnostics: EXAMPLE_CLIENT_CONSULTATION_DIAGNOSTICS.diffDiagnostics,
				createdAt: EXAMPLE_CLIENT_CONSULTATION.createdAt,
				updatedAt: EXAMPLE_CLIENT_CONSULTATION.updatedAt,
				prescription: EXAMPLE_CLIENT_CONSULTATION.prescription,
				status: Status.ACTIVE,
			};
			await createClientConsultation(consultationObj, clientID, doctor.id, true, true);
		} catch (error) {
			console.log(error);
		}
	};

	const createClientWithExampleData = async (doctor: DoctorType): Promise<Client | null> => {
		try {
			const client = await saveClientExampleData(doctor);
			if (client) {
				await createClientHistoryExampleData(client.id);
				await createClientConsultationExampleData(client.id, doctor);
				// await createClientExampleNote(client.id, doctor.id);
				return client;
			}
		} catch (error) {
			console.error('Error creating example client data:', error);
		}
	};

	async function getInsuranceResponse(insuranceName: string) {
		return await execute(syncInsurances, {
			filter: {
				name: {
					eq: insuranceName,
				},
			},
		});
	}

	async function associateClientWithInsurance(createdClient: Client, insuranceName: string) {
		const insuranceResponse = await getInsuranceResponse(insuranceName);

		const insurance = insuranceResponse.data.syncInsurances.items[0];
		console.log('insurance', insurance);
		console.log('createdClient', createdClient);
		const clientInsuranceResponse = await execute(createClientInsurance, {
			input: {
				clientID: createdClient.id,
				affiliateNumber: createdClient.affiliateNumber,
				insuranceID: insurance.id,
				affiliateType: createdClient.affiliateType || AffiliateTypes.PRINCIPAL,
				contractNumber: createdClient.contractNumber,
			},
		});

		return clientInsuranceResponse.data.createClientInsurance;
	}

	const patientSeederFunction = async (
		hospitalDoctorId: string,
		patientData: any,
		doctorActualPatients: [Client]
	): Promise<Client> => {
		const client = {
			identificationName: (patientData.identificationName || 'CEDULA') as IdentificationTypes,
			identificationData: patientData.identificationData || '',
			actualInsurance: patientData.actualInsurance || 'Sin seguro',
			name: patientData.name,
			lastName: patientData.lastName,
			cellphoneNumber: patientData.cellphoneNumber || '',
			email: patientData.email || '',
			bornDate: patientData.bornDate || '',
			gender: patientData.gender
				? (patientData.gender?.toUpperCase() as SexType)
				: SexType.NOSEX,
			phoneNumber: patientData.phoneNumber || '',
			addressStreet: patientData.addressStreet || '',
			city: patientData.city || '',
			isAdult: true,
			sector: patientData.sector || '',
			bloodType: patientData.bloodType || '',
			company: patientData.company || '',
			occupation: patientData.occupation || '',
			profileImage: patientData.profileImage || '',
			heartBeat: patientData.heartBeat || '',
			weight: patientData.weight || '',
			height: patientData.height || '',
			bloodPressure: patientData.bloodPressure || '',
		};

		try {
			const createdClientResponse = await execute(createClientMutation, {
				input: client,
			});
			const createdClient = createdClientResponse.data.createClient as Client;

			const clientInsurance = await associateClientWithInsurance(
				{
					...client,
					id: createdClient.id,
				},
				client.actualInsurance as string
			);

			const getLastRecord = orderAndGetLast(doctorActualPatients);
			const newRecord = generateNewRecord(getLastRecord);
			await relationsContext.createHospitalDoctorClients(
				createdClient.id,
				hospitalDoctorId,
				clientInsurance.id,
				newRecord
			);

			return createdClient;
		} catch (error) {
			console.log('Error creating patient', error);
		}
	};

	const resetPatientData = () => {
		setSelectedClient(null);
		setClientHistory(null);
		setClientConsultations([]);
		setClientPrescriptions([]);
		setClientClaims([]);
		setClientFiles([]);
	};

	return (
		<ClientsContext.Provider
			value={{
				clients: clients,
				saveClient,
				updateClientInfo,
				createClientAppointment,
				updateClientAppointment,
				deleteClientAppointment,
				setClients,
				createClient,
				deleteClient,
				prescriptionToAdd,
				setPrescriptionToAdd,
				createClientConsultation,
				updateClientConsultation,
				deleteClientConsultation,
				createClientPrescription,
				deleteClientPrescription,
				fetchClients,
				fetchPaginatedClients,
				paginatedClients,
				fetchPatientByIdentificationData,
				fetchPatientById,
				updateClient,
				selectedClient,
				setSelectedClient,
				updateClientHistory,
				deleteClientHistory,
				addClientHistory,
				updateClientHeartBeat,
				updateClientWeight,
				updateClientHeight,
				updateHospitalDoctorClientStatus,
				updateClientBloodPressure,
				updateClientDoctorNote,
				addClientNote,
				deleteClientNote,
				updateClientNote,
				updateClientProcSurg,
				addClientProcSurg,
				deleteClientProcSurg,
				deleteClaim,
				createClaim,
				addImage,
				updateImageDateSection,
				updateImage,
				deleteImageDateSection,
				deleteImage,
				getClientData,
				updateClientWithOutChanges,
				setPaginatedClients,
				imageLoading,
				setImageLoading,
				fetchPaginatedClientsByName,
				getClientDataToEdit,
				getMainPageClientData,
				getConsultationDataById,
				getConsultationsData,
				loadingConsultations,
				getPrescriptionsData,
				loadingPrescriptions,
				getClientHistoryData,
				loadingClientHistory,
				getClientClaimsData,
				clientClaims,
				clientConsultations,
				clearClientInformation,
				clientPrescriptions,
				loadingClientClaims,
				clientHistory,
				setClientHistory,
				getClientFilesData,
				clientFiles,
				page,
				rowsPerPage,
				setPage,
				clientsLoading,
				setClientsLoading,
				checkIfClientExistInHospitalDoctor,
				editingClient,
				setEditingClient,
				modalClientState,
				setModalClientState,
				nextCursor,
				setLoadClientEditModal,
				loadClientEditModal,
				createClientWithExampleData,
				createClientConsultationExampleData,
				onboardingData,
				setOnboardingData,
				resetPatientData,
				patientSeederFunction,
			}}
		>
			{props.children}
		</ClientsContext.Provider>
	);
};

export default ContextProvider;
