import styled from 'styled-components';
import { createTheme, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const theme = createTheme();
export const StyledTextField = styled(TextField)`
	margin: 0;
	label {
        transform: translate(14px, 10px) scale(1);
		word-break: break-all;
	}
	input {
		word-break: break-all;

		&::placeholder {
			color: rgba(0, 0, 0, 0.8);
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 24px;
		}
	}
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
        label {
            transform: translate(14px, 10px) scale(1)
            word-break: break-all;
        }
    }
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input{
	    padding: ${theme.spacing(0.25, 0.25)};
    }


`;
export const StyledAutocomplete = styled(Autocomplete)`
	inputroot {
		padding: ${theme.spacing(0, 0.25)};
	}
`;
