// @ts-nocheck
import React from 'react';
import { ListChildComponentProps } from 'react-window';
import {
	DOCTOR_IDENTIFIER,
	LISTBOX_PADDING,
	MAX_FILE_NAME_LENGTH,
	MAX_FILE_SIZE,
	MEMBER_IDENTIFIER,
	SECRETARY_IDENTIFIER,
} from './constants';
import { ImageFile } from './type';
import { getDateAndTime } from './dateUtils';
import crypto from 'crypto-js';

export const virtualCloneElement = (props: ListChildComponentProps) => {
	const { data, index, style } = props;
	return React.cloneElement(data[index], {
		style: {
			...style,
			top: (style.top as number) + LISTBOX_PADDING,
			borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
		},
	});
};

export const getUniqueValues = (list: any[], key: string) => {
	return [...new Map(list.map((item) => [item[key], item])).values()];
};

export const capitalizeFirstLetter = (str: string) => {
	// converting first letter to uppercase
	const capitalized = str.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
	return capitalized;
};

export const validateNoSpecialCharacters = (str: string) => {
	const validation = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
	return validation.test(str);
};

export const isValidEmail = (str: string) => {
	const regexEmail =
		/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
	return regexEmail.test(str);
};

let previousLength = 0;
export const returBulletOnEnter = (event: React.ChangeEvent<HTMLInputElement>) => {
	const bullet = '\u2022';
	const newLength = event.target.value.length;
	const characterCode = event.target.value.substr(-1).charCodeAt(0);

	if (newLength > previousLength) {
		if (characterCode === 10) {
			event.target.value = `${event.target.value}${bullet} `;
		} else if (newLength === 1) {
			event.target.value = `${bullet} ${event.target.value}`;
		}
	}

	previousLength = newLength;
	return event.target.value;
};

export const removeBulletAddComma = (value: string) => {
	return value.replaceAll('\n', ',').replaceAll('\u2022', '').trim();
};

export const replaceCommanForBullet = (value: string) => {
	const bullet = '\u2022';
	const listBullet = value.split(',');
	return listBullet
		.map((b, i) =>
			b !== '' ? `${bullet} ${b.trim()}${i === listBullet.length - 1 ? '' : '\n'}` : ''
		)
		.join('');
};

export const formatImageLabel = (name: string, extension?: string): string => {
	if (name.length <= MAX_FILE_NAME_LENGTH) {
		return '';
	}

	const regex = /\.(ai|bmp|gif|ico|jpe?g|png|ps|psd|svg|tif?f|webp)/i;
	const match = regex.exec(name);
	if (match) {
		extension = match[0];
	}

	return `${name.substring(0, 6)}...${name.slice(-6)}${extension}`;
};

export const formatFile = (file: File): ImageFile => {
	const extension = file?.type.split('/')[1];
	const name = `${file.name}-${getDateAndTime()}.${extension[extension.length - 1]}`;
	const label = formatImageLabel(name);

	return {
		name,
		extension,
		tooltip: file.name,
		label,
		file,
	};
};

export const validateFileSize = (fileSize: number) => {
	return fileSize <= MAX_FILE_SIZE;
};

export const isDoctor = (user: any) => {
	return (
		user &&
		user.attributes &&
		Object.prototype.hasOwnProperty.call(user.attributes, DOCTOR_IDENTIFIER)
	);
};

export const isSecretary = (user: any) => {
	return (
		user &&
		user.attributes &&
		Object.prototype.hasOwnProperty.call(user.attributes, SECRETARY_IDENTIFIER)
	);
};

export const isDoctorMember = (user: any) => {
	return (
		user &&
		user.attributes &&
		Object.prototype.hasOwnProperty.call(user.attributes, MEMBER_IDENTIFIER)
	);
};

export const compareObjectValues = (obj1: any, obj2: any): boolean => {
	const keys = Object.keys(obj1);

	for (const key of keys) {
		if (obj1[key] !== obj2[key]) {
			return false;
		}
	}

	return true;
};

export function generatePassword() {
	const length = 8;
	const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+';
	let password = '';

	for (let i = 0; i < length; i++) {
		const index = Math.floor(Math.random() * characters.length);
		password += characters.charAt(index);
	}

	return password;
}

export const truncateText = (text: string, maxLength: number) => {
	if (text.length <= maxLength) {
		return text;
	} else {
		return text.substring(0, maxLength) + '...';
	}
};
export const decryptMessage = (message: string) => {
	const decodedEncryptedMessage = decodeURIComponent(message);
	const bytes = crypto.AES.decrypt(decodedEncryptedMessage, 'pulseara');
	const decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
	return decryptedData;
};
export const orderByLastChangedDate = (a: ClientType, b: ClientType): number => {
	const dateA = a._lastChangedAt ? a._lastChangedAt : null;
	const dateB = b._lastChangedAt ? b._lastChangedAt : null;

	if (dateA === null && dateB === null) return 0;
	if (dateA === null) return 1;
	if (dateB === null) return -1;

	return dateB - dateA;
};
