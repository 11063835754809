import { AIPatienDiagnostic } from '../types';

export const promtDiagnosis = (data: AIPatienDiagnostic) => {
	return `
Eres un medico consultor experto,
Analiza los siguientes datos del paciente:

Datos del paciente:

${data.bornDate ? 'nacimiento: ' + data.bornDate : '\r'}
${data.gender ? 'genero: ' + data.gender : '\r'}
${data.clinicPresentation ? 'Razon de la visita: ' + data.clinicPresentation : '\r'}
${data.alergias ? 'Alergias: ' + data.alergias : '\r'}
${data.antecFamiliares ? 'Antecedentes familiares: ' + data.antecFamiliares : '\r'}
${data.antecPatologico ? 'Antecedentes no Patológicos: ' + data.antecPatologico : '\r'}
${data.enfermActiva ? 'Enfermedades activas: ' + data.enfermActiva : '\r'}
${data.enfermInactivas ? 'Enfermedades inactivas: ' + data.enfermInactivas : '\r'}
${data.hospitalPrevias ? 'Hospitalizaciones previas: ' + data.hospitalPrevias : '\r'}
${data.estiloDeVida ? 'Estilo de vida: ' + data.estiloDeVida : '\r'}

Tomando en cuenta el analisis de los datos anteriores, dame 5 posibles diagnósticos médicos y descripcion de porque este diagnostico es sugerido.

Toda esta informacion retornala en formato JSON sin ningun texto adicional fuera de este. MUY IMPORTANTE: No puede haber ningun texto asignado al json, como por ejemplo; Respuesta: ect.. Solo quiero el JSON dentro de un objecto.

Toma el siguiente objeto como un ejemplo de lo que quiero que me devuelvas.

{
"diagnosticos": [{
"nombre": "Migraña",
"descripcion": "Debido a los síntomas de dolor de cabeza persistentes en la región frontal, se puede determinar que el paciente sufre de migraña."
}]
}

Dame el JSON dentro de un objeto sin asignarlo a ninguna variable. Asegúrate de colocar las comas y comillas dobles en los lugares correctos para evitar errores de sintaxis en el JSON que retornaràs 
`;
};
