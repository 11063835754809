import React, { useState, useContext } from 'react';
import { useLocation, Switch, Route, Redirect } from 'react-router-dom';
import Home from './pages/Home';
import Patients from './pages/Patients';
import Configuration from './pages/Configuration';
import Help from './pages/Help';
import PatientDetail from './pages/PatientDetail';
import Login from './pages/Login';
import { UserContext } from './context/user-context';
import ConfirmAccount from './pages/ConfirmAccount';
import CompleteRegistrationSecretary from './pages/CompleteRegistrationSecretary';
import CompleteRegistrationDoctor from './pages/CompleteRegistrationDoctor';
import AddPayment from './pages/AddPayment';
import MainSideBar from './components/sidebar/MainSideBar';
import PatientConsultation from './pages/PatientConsultation/PatientConsultation';
import Medicine from './pages/Medicine';
import { UserContextProps } from './context/types';
import LabImage from './pages/LabImage';
import ForgotPassword from './pages/ForgotPassword';
import ChangePassword from './pages/ChangePassword';
import NotFound from './pages/NotFound';
import MobileNotAuthorize from './pages/mobile/NotAuthorize';
import SelectPlan from './pages/SelectPlan';
import BottomAlert from './components/bottomAlert/BottomAlert';
import DoctorReview from './pages/DoctorReview';
import Chat from './pages/Chat';
import NoInternet from './components/noInternet/NoInternet';
import useOnlineHook from './shared/hooks/useOnlineHook';
import Payment from './components/configuration/Payment';
import ConfigurationHospitals from './pages/ConfigurationHospital';
import { datadogRum } from '@datadog/browser-rum';
import ConfirmRegistration from 'pages/mobile/ConfirmRegistration';
import { AppointmentsCalendar } from 'components/appointment/AppointmentsCalendar';
import BottomNavigation from './components/mobileNavigation/BottomNavigation';
import useMobile from 'hooks/useMobile';
import VerifyLoginPage from 'pages/VerifyLogin';
import CompleteRegistrationMember from 'pages/CompleteRegistrationMember';
import BlockedAccount from 'pages/BlockedAccount';
import { DoctorOnboardingScreen } from 'pages/DoctorOnboardingScreen';

const Routes = () => {
	const onlineStatus = useOnlineHook();
	const location = useLocation();
	const { userAuthenticated } = useContext(UserContext) as UserContextProps;
	const [sidebarToggle, setsidebarToggle] = useState(false);
	const { isMobile } = useMobile();

	if (userAuthenticated) {
		datadogRum.setUser({
			id:
				userAuthenticated.attributes['custom:doctorID'] ||
				userAuthenticated.attributes['custom:secretaryID'],
			email: userAuthenticated.attributes.email,
			email_verified: userAuthenticated.attributes.email_verified,
			//app_version: packageJson.version,
			user_type: userAuthenticated.attributes['custom:doctorID'] ? 'Doctor' : 'Secretary',
		});
	}

	let routes = (
		<>
			<Switch>
				<Route path="/login" component={Login} />
				<Route path={'/doctor-onboarding'} component={DoctorOnboardingScreen} />
				<Route path="/verify/:message" component={VerifyLoginPage} />
				<Route path="/forgot-password" component={ForgotPassword} />
				<Route path="/change-password" component={ChangePassword} />
				<Route path="/confirm" component={ConfirmAccount} />
				<Route path="/review" component={DoctorReview} />
				<Route
					path="/complete-registration-doctor"
					component={CompleteRegistrationDoctor}
				/>
				<Route
					path="/complete-registration-secretary"
					component={CompleteRegistrationSecretary}
				/>
				<Route
					path="/complete-registration-member"
					component={CompleteRegistrationMember}
				/>
				<Route path="/select-plan" component={SelectPlan} />
				<Route path="/add-payment-method" component={AddPayment} />
				<Route exact path="/mobile/register/confirm" component={ConfirmRegistration} />
				<Route exact path="/mobile/not-authorize" component={MobileNotAuthorize} />
				<Route path="/blocked-account">
					<BlockedAccount />
				</Route>
				<Route path="/">
					<Redirect to="/login" />
				</Route>
				<Route path="/Not-Found">
					<NotFound />
				</Route>
			</Switch>
			<BottomAlert />
		</>
	);

	const excludeClientConsultationRoute = !location.pathname.includes('/client-consultation');
	const hideTopBar = excludeClientConsultationRoute;
	const MOBILE_NAV_ROUTES = [
		'/',
		'/Medicine',
		'/Lab-Image',
		'/Configuration/Profile',
		'/Configuration/Institution',
		'/configuration/Subscription',
	];
	const styleContainer = {
		paddingTop: hideTopBar && !isMobile ? '1.6rem' : '0',
		overflow: !isMobile && excludeClientConsultationRoute ? 'hidden' : 'auto',
	};

	if (userAuthenticated) {
		routes = (
			<div className="h-full">
				<div
					className={isMobile ? `flex w-full` : `flex w-full h-screen`}
					style={styleContainer}
				>
					<MainSideBar
						sidebarToggle={sidebarToggle}
						setsidebarToggle={setsidebarToggle}
					/>
					<Switch>
						<Route exact path="/">
							<Home />
						</Route>
						<Route path={'/Calendar'}>
							<AppointmentsCalendar />
						</Route>
						<Route path="/Clients/:clientID/client-consultation">
							<PatientConsultation />
						</Route>
						<Route path="/Clients/:clientID">
							<PatientDetail />
						</Route>
						<Route path="/Clients">
							<Patients />
						</Route>
						<Route path="/Configuration" exact>
							{onlineStatus ? (
								<Configuration />
							) : (
								<NoInternet message="Para ver o modificar los datos de configuración debes conectarte a internet." />
							)}
						</Route>
						<Route path="/Configuration/Profile">
							{onlineStatus ? (
								<Configuration />
							) : (
								<NoInternet message="Para ver o modificar los datos de configuración debes conectarte a internet." />
							)}
						</Route>
						<Route path="/Configuration/Subscription">
							{onlineStatus ? (
								<Payment />
							) : (
								<NoInternet message="Para ver o modificar los datos de configuración debes conectarte a internet." />
							)}
						</Route>
						<Route path="/Configuration/Institution">
							{onlineStatus ? (
								<ConfigurationHospitals />
							) : (
								<NoInternet message="Para ver o modificar los datos de configuración debes conectarte a internet." />
							)}
						</Route>
						<Route path="/blocked-account">
							<BlockedAccount />
						</Route>
						<Route path="/Chat">
							<Chat />
						</Route>
						<Route path="/Medicine">
							<Medicine />
						</Route>
						<Route path="/Lab-Image">
							<LabImage />
						</Route>
						<Route path="/Help">
							<Help />
						</Route>
						<Route path="/Not-Found">
							<NotFound />
						</Route>
						<Route path="/mobile/register/confirm" component={ConfirmRegistration} />
						<Route path="*">
							<Redirect to="/" />
						</Route>
					</Switch>
					<BottomAlert />
				</div>
				{isMobile && MOBILE_NAV_ROUTES.includes(location.pathname) ? (
					<BottomNavigation
						sidebarToggle={sidebarToggle}
						setsidebarToggle={setsidebarToggle}
					/>
				) : null}
			</div>
		);
	}

	return routes;
};

export default Routes;
