import React, { FC, useState, useContext } from 'react';
import AddIcon from '@material-ui/icons/Add';
import {
	ActionHeader,
	StyledCardActionBtn,
	StyledClientContent,
	StyledSearchIcon,
} from '../../shared/style';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	createTheme,
	InputAdornment,
	withStyles,
	IconButton,
	TextField,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Autocomplete } from '@material-ui/lab';
import MobileActionsButton from 'components/mobileActionsButton/MobileActionsButton';
import clsx from 'clsx';
import useMobile from 'hooks/useMobile';
import { ClientsContext } from 'context/client-context';
import { ClientContextProps } from 'context/types';
import { ModalAppointment } from 'components/appointment/components/ModalAppointment';
import { IAppointment } from 'shared/type';
import moment from 'moment';
import GlobalSearchPatientBar from 'components/sidebar/GlobalSearchPatientBar';

const theme = createTheme();
const StyledCardHeader = withStyles({
	root: {
		padding: theme.spacing(1.5, 2),
	},
	title: {
		fontSize: theme.spacing(2.5),
		fontWeight: 500,
	},
	action: {
		marginTop: 0,
		marginRight: 0,
	},
})(CardHeader) as typeof CardHeader;

const StyledContent = withStyles({
	root: {
		padding: 0,
		overflowY: 'auto',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		rowGap: '1rem',
	},
})(CardContent) as typeof CardContent;

const StyledTextField = withStyles({
	root: {
		margin: 0,
	},
})(TextField);

interface Props {
	title: string | React.ReactNode;
	handleAdd?: () => void;
	children: React.ReactNode;
	addTitle?: string;
	filterText: string;
	setFilterText: (value: string) => void;
	footer?: React.ReactNode;
	isFooter?: boolean;
	isDisplayContent?: boolean;
	contentStyle?: React.CSSProperties;
	id?: string;
	className?: string;
	placeholder?: string;
	['data-fx-name']?: string;
	customStyles?: React.CSSProperties;
}

const ListContainer: FC<Props> = ({
	title,
	handleAdd,
	addTitle,
	filterText,
	setFilterText,
	children,
	footer,
	isFooter,
	isDisplayContent = false,
	contentStyle,
	id,
	className,
	'data-fx-name': dataFxName,
	placeholder = 'Buscar',
	customStyles = {},
}) => {
	const { isMobile } = useMobile();
	const [showSearchMobile, toggleSearchMobile] = useState(false);
	const [isModalOpen, setModalOpen] = useState(false);
	const { setModalClientState, setEditingClient } = useContext(
		ClientsContext
	) as ClientContextProps;
	const [appointment, setAppointment] = useState<IAppointment>({
		client: null,
		description: '',
		date: moment(),
		start: null,
		end: null,
	});

	const handleModalClientState = (editing: boolean) => {
		setEditingClient(editing);
		setModalClientState(true);
	};

	const actions = [
		{
			name: 'Crear Paciente',
			onClick: () => handleModalClientState(false),
		},
		{
			name: 'Agendar cita',
			onClick: () => setModalOpen(true),
		},
	];

	const closeModal = () => {
		setModalOpen(false);
	};

	const isPatientList = id === 'patients-list';
	const searchToRender = !isPatientList ? (
		<Autocomplete
			freeSolo
			id="list-search"
			options={[]}
			size="small"
			inputValue={filterText}
			disableListWrap
			onInputChange={(e: React.ChangeEvent<any>) => {
				e !== null && e.target.value !== undefined
					? setFilterText(e.target.value)
					: setFilterText('');
			}}
			renderInput={(params: any) => (
				<StyledTextField
					{...params}
					label=""
					placeholder={placeholder}
					margin="normal"
					variant="outlined"
					style={{ width: '100%' }}
					InputProps={{
						...params.InputProps,
						startAdornment: (
							<InputAdornment position="start">
								<StyledSearchIcon />
							</InputAdornment>
						),
						style: {
							padding: '2px',
							color: 'rgba(0, 0, 0, 0.60)',
						},
					}}
				/>
			)}
		/>
	) : isMobile ? (
		<GlobalSearchPatientBar />
	) : null;
	return (
		<>
			<ModalAppointment
				modalState={isModalOpen}
				closeModal={closeModal}
				isUpdating={false}
				initialValues={appointment}
			/>
			<Card data-fx-name={dataFxName} id={id} style={customStyles}>
				<StyledCardHeader
					title={title}
					className="card-header"
					action={
						<ActionHeader>
							{addTitle && (
								<StyledCardActionBtn
									size={'small'}
									onClick={handleAdd}
									color="primary"
									startIcon={<AddIcon />}
								>
									{addTitle}
								</StyledCardActionBtn>
							)}
							{isMobile ? (
								<>
									<button
										style={{
											minWidth: '36px',
										}}
										onClick={() => {
											toggleSearchMobile(true);
										}}
									>
										<StyledSearchIcon />
									</button>

									<MobileActionsButton actions={actions} />
								</>
							) : (
								<div style={{ width: '220px' }}>{searchToRender}</div>
							)}
						</ActionHeader>
					}
				/>

				{showSearchMobile && (
					<StyledCardHeader
						className="header-search-mobile"
						title={
							<IconButton onClick={() => toggleSearchMobile(false)}>
								<ArrowBackIcon />
							</IconButton>
						}
						action={<ActionHeader>{searchToRender}</ActionHeader>}
					/>
				)}
				<Divider light />
				<StyledContent
					style={{
						height: `calc(100dvh - 154px)`,
						...contentStyle,
					}}
					className={clsx(`justify-center`, className)}
				>
					<StyledClientContent
						style={{
							display: `${isDisplayContent ? 'contents' : ''}`,
						}}
					>
						{children}
					</StyledClientContent>
					{isFooter && footer}
				</StyledContent>
			</Card>
		</>
	);
};

export default ListContainer;
