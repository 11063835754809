import React, { useState, useContext } from 'react';
import {
	WaitingListItem,
	WaitList,
	Insurance,
	WaitingListItemStatus,
	HospitalDoctor,
} from 'models';
import {
	WaitingListItemType,
	RelationsContextProps,
	ClientType,
	IMemberDropdown,
	ClientContextProps,
} from '../context/types';
import { RelationsContext } from './relations-context';
import moment from 'moment';
import {
	getClient,
	getClientInsurance,
	getInsurance,
	getWaitingListItem,
	listHospitalDoctorClientes,
	syncHospitalDoctorClientes,
	syncHospitalDoctors,
	syncWaitLists,
	syncWaitingListItems,
} from 'graphql/queries';
import {
	createWaitList,
	createWaitingListItem,
	updateHospitalDoctor,
	updateWaitingListItem,
} from 'graphql/mutations';
import useAPI from 'hooks/API';
import { ClientsContext } from './client-context';

export interface WaitingListContextProps {
	waitingListItems: WaitingListItemType[];
	actualWaitingList: WaitList | null;
	getWaitingListItems: () => Promise<void>;
	getWaitingListItemsSync: () => Promise<void>;
	addClientToCurrentWaitingList: (client: ClientType) => Promise<void>;
	updateWaitingListItemStatus: (
		waitingListItemID: string,
		status: keyof typeof WaitingListItemStatus
	) => Promise<void>;
	updateWaitingItemPositionNumber: (
		waitingListItemID: string,
		newPositionNumber: number
	) => Promise<void>;
	upsertCurrentWaitingList: (hospitalDoctor: HospitalDoctor) => Promise<void>;
	setWaitingListItems: (waitingListItemType: WaitingListItemType[]) => void;
	upsertMemberWaitingList: (member: IMemberDropdown) => Promise<void>;
}

export const WaitingListsContext = React.createContext<Partial<WaitingListContextProps>>({});

const ContextProvider: React.FC = (props) => {
	const relationContext = useContext(RelationsContext) as RelationsContextProps;
	const [actualWaitingList, setActualWaitingList] = useState<WaitList | null>(null);
	const [waitingListItems, setWaitingListItems] = useState<WaitingListItemType[]>(
		[] as WaitingListItemType[]
	);
	const { actualHospitalDoctor, getCustomHospitalDoctorFilters } = useContext(
		RelationsContext
	) as RelationsContextProps;

	const { execute } = useAPI();

	const getWaitingListItems = async () => {
		try {
			const response = await execute(syncWaitingListItems, {
				filter: {
					waitingListID: { eq: actualWaitingList?.id },
				},
			});

			const waitingItems: any[] = response.data.syncWaitingListItems?.items;

			const waitingListFiltered = await Promise.all<WaitingListItemType>(
				waitingItems.map(async (waitingItem: any) => {
					const clientResponse = await execute(getClient, {
						id: waitingItem.clientID,
					});

					const hospitalDoctorClienteResponse = await execute(
						listHospitalDoctorClientes,
						{
							filter: {
								clientID: { eq: waitingItem.clientID },
								hospitalDoctorID: { eq: actualHospitalDoctor?.id },
							},
						}
					);

					let healthInsurance: Insurance | undefined = undefined;
					if (
						hospitalDoctorClienteResponse.data &&
						hospitalDoctorClienteResponse.data.listHospitalDoctorClientes.items.length >
							0
					) {
						const clientHealthInsuranceResponse = await execute(getClientInsurance, {
							id: hospitalDoctorClienteResponse.data.listHospitalDoctorClientes
								.items[0].lastHealthInsurranceID,
						});

						if (
							clientHealthInsuranceResponse.data.getClientInsurance &&
							clientHealthInsuranceResponse.data.getClientInsurance.insuranceID
						) {
							const insuranceID =
								clientHealthInsuranceResponse.data.getClientInsurance.insuranceID;

							const healthInsuranceResponse = await execute(getInsurance, {
								id: insuranceID,
							});

							healthInsurance = healthInsuranceResponse.data.getInsurance;
						}
					}

					return {
						id: waitingItem.id,
						status: waitingItem.status,
						positionNumber: waitingItem.positionNumber,
						clientHealthInsurrance: healthInsurance
							? healthInsurance.name
							: 'Sin seguro',
						clientName: clientResponse.data.getClient.name || '',
						clientLastName: clientResponse.data.getClient.lastName || '',
						clientID: clientResponse.data.getClient.id || '',
					};
				})
			);

			setWaitingListItems(waitingListFiltered);
		} catch (error) {
			console.log(error);
		}
	};

	const getWaitingListItemsByWaitingListID = async (
		waitingListID: string
	): Promise<WaitingListItemType[]> => {
		const response = await execute(syncWaitingListItems, {
			filter: {
				waitingListID: { eq: waitingListID },
			},
		});

		const waitingItems: any[] = response.data.syncWaitingListItems?.items;

		const waitingListFiltered = await Promise.all<WaitingListItemType>(
			waitingItems.map(async (waitingItem: any) => {
				const clientResponse = await execute(getClient, {
					id: waitingItem.clientID,
				});

				const hospitalDoctorClienteResponse = await execute(listHospitalDoctorClientes, {
					filter: {
						clientID: { eq: waitingItem.clientID },
						hospitalDoctorID: { eq: actualHospitalDoctor?.id },
					},
				});

				let healthInsurance: Insurance | undefined = undefined;
				if (
					hospitalDoctorClienteResponse.data &&
					hospitalDoctorClienteResponse.data.listHospitalDoctorClientes.items.length > 0
				) {
					const clientHealthInsuranceResponse = await execute(getClientInsurance, {
						id: hospitalDoctorClienteResponse.data.listHospitalDoctorClientes.items[0]
							.lastHealthInsurranceID,
					});

					if (
						clientHealthInsuranceResponse.data.getClientInsurance &&
						clientHealthInsuranceResponse.data.getClientInsurance.insuranceID
					) {
						const insuranceID =
							clientHealthInsuranceResponse.data.getClientInsurance.insuranceID;

						const healthInsuranceResponse = await execute(getInsurance, {
							id: insuranceID,
						});

						healthInsurance = healthInsuranceResponse.data.getInsurance;
					}
				}

				return {
					id: waitingItem.id,
					status: waitingItem.status,
					positionNumber: waitingItem.positionNumber,
					clientHealthInsurrance: healthInsurance ? healthInsurance.name : 'Sin seguro',
					clientName: clientResponse.data.getClient.name || '',
					clientLastName: clientResponse.data.getClient.lastName || '',
					clientID: clientResponse.data.getClient.id || '',
				};
			})
		);

		return waitingListFiltered;
	};

	const addClientToCurrentWaitingList = async (client: ClientType): Promise<void> => {
		if (!actualWaitingList) return;

		const waitingItemsResponse = await execute(syncWaitingListItems, {
			filter: {
				waitingListID: { eq: actualWaitingList.id },
			},
		});

		const local = waitingItemsResponse.data.syncWaitingListItems.items;
		if (
			local.some((el: any) => {
				return el.clientID === client.id && el.status !== WaitingListItemStatus.TERMINADA;
			})
		) {
			relationContext.setAlertMessage({
				message: `El paciente ${client?.name} ${client?.lastName} ya está en la lista de espera`,
				severity: 'success',
			});
			return;
		}

		try {
			const response = await execute(createWaitingListItem, {
				input: {
					waitingListID: actualWaitingList.id,
					clientID: client.id,
					status: WaitingListItemStatus.ESPERA,
					clientHealthInsurrance: client.actualInsurance,
					positionNumber: waitingItemsResponse.data.syncWaitingListItems.items.length + 1,
				},
			});

			const newItem: WaitingListItemType = response.data.createWaitingListItem;
			const customFilter = await getCustomHospitalDoctorFilters();
			const hospitalDoctorClienteResponse = await execute(syncHospitalDoctorClientes, {
				filter: {
					clientID: { eq: client.id },
					or: customFilter,
				},
			});

			let clientHealthInsurance = null;
			if (
				hospitalDoctorClienteResponse.data.syncHospitalDoctorClientes &&
				hospitalDoctorClienteResponse.data.syncHospitalDoctorClientes.items.length > 0
			) {
				const lastHealthInsuranceID =
					hospitalDoctorClienteResponse.data.syncHospitalDoctorClientes.items[0]
						.lastHealthInsurranceID;

				const clientHealthInsuranceResponse = await execute(getInsurance, {
					id: lastHealthInsuranceID,
				});

				clientHealthInsurance = clientHealthInsuranceResponse.data.getInsurance;
			}

			const transformedWaitingItem: WaitingListItemType = {
				id: newItem.id,
				status: newItem.status,
				positionNumber: newItem.positionNumber,
				clientHealthInsurrance: clientHealthInsurance
					? clientHealthInsurance.name
					: (client.actualInsurance as string),
				clientName: client.name,
				clientLastName: client.lastName,
				clientID: client.id,
			};

			setWaitingListItems((prevItems) => [...prevItems, transformedWaitingItem]);

			relationContext.setAlertMessage({
				message: `El paciente ${client?.name} ${client?.lastName} ha sido agregado a la lista de espera.`,
				severity: 'success',
			});
		} catch (error) {
			relationContext.setAlertMessage({
				message: `Ocurrió un error al agregar el paciente a la lista de espera.`,
				severity: 'error',
			});
		}
	};

	const updateWaitingListItemStatus = async (
		waitingListItemID: string,
		status: keyof typeof WaitingListItemStatus
	): Promise<void> => {
		try {
			const currentWaitingList = await execute(getWaitingListItem, {
				id: waitingListItemID,
			});

			await execute(updateWaitingListItem, {
				input: {
					id: currentWaitingList.data.getWaitingListItem.id,
					status: status,
					_version: currentWaitingList.data.getWaitingListItem._version,
				},
			});

			setWaitingListItems((prevItems) =>
				prevItems.map((item) =>
					item.id === waitingListItemID ? { ...item, status: status } : item
				)
			);

			relationContext.setAlertMessage({
				message: `El estado del elemento de la lista de espera ha sido actualizado a ${status}.`,
				severity: 'success',
			});
		} catch (error) {
			relationContext.setAlertMessage({
				message: `Ocurrió un error al actualizar el estado del elemento de la lista de espera.`,
				severity: 'error',
			});
		}
	};

	const updateWaitingItemPositionNumber = async (
		waitingListItemID: string,
		newPositionNumber: number | string
	) => {
		const response = await execute(getWaitingListItem, {
			id: waitingListItemID,
		});

		const waitingListItem = response.data.getWaitingListItem;

		if (typeof newPositionNumber === 'string') {
			await execute(updateWaitingListItem, {
				input: {
					id: waitingListItemID,
					status: newPositionNumber,
					_version: waitingListItem._version,
				},
			});

			const waitingListItemsUpdated = waitingListItems.map((item) => {
				if (item.id === waitingListItemID) {
					return {
						...item,
						status: newPositionNumber as keyof typeof WaitingListItemStatus,
					};
				}

				return item;
			});

			setWaitingListItems(waitingListItemsUpdated);

			relationContext.setAlertMessage({
				message: `El estado del elemento de la lista de espera ha sido actualizado a ${newPositionNumber}.`,
				severity: 'success',
			});
			return;
		}

		if (!waitingListItem) {
			relationContext.setAlertMessage({
				message: `La posicióm del paciente no pudo ser actualizada.`,
				severity: 'error',
			});
			return;
		}
		if (newPositionNumber === waitingListItem.positionNumber) return;

		if (newPositionNumber > waitingListItem.positionNumber) {
			const responseOthers = await execute(syncWaitingListItems, {
				filter: {
					waitingListID: { eq: actualWaitingList?.id },
					status: { ne: WaitingListItemStatus.TERMINADA },
					positionNumber: {
						le: newPositionNumber,
						gt: waitingListItem.positionNumber,
					},
				},
			});

			const othersWaitingItems = responseOthers.data.syncWaitingListItems.items;
			// now update position numbers
			// eslint-disable-next-line no-sparse-arrays
			await Promise.all([
				await execute(updateWaitingListItem, {
					input: {
						id: waitingListItemID,
						positionNumber: newPositionNumber,
						_version: waitingListItem._version,
					},
				}),
				,
				...othersWaitingItems.map(async (item: any) => {
					if (item.positionNumber === 1) return;
					if (item.status !== WaitingListItemStatus.ESPERA) return;
					await execute(updateWaitingListItem, {
						input: {
							id: item.id,
							positionNumber: item.positionNumber - 1,
							_version: item._version,
						},
					});
				}),
			]);

			const newWaitingItems = waitingListItems
				.filter((item) => item.status !== WaitingListItemStatus.TERMINADA)
				.map((item) => {
					if (item.status !== WaitingListItemStatus.ESPERA) return item;

					if (
						othersWaitingItems.findIndex((x: WaitingListItem) => x.id === item.id) ===
							-1 &&
						item.id !== waitingListItemID
					)
						return item;

					if (item.id === waitingListItemID) {
						return {
							...item,
							positionNumber: newPositionNumber,
						};
					}

					if (item.positionNumber === 1) return item;
					return {
						...item,
						positionNumber: item.positionNumber - 1,
					};
				});
			setWaitingListItems(newWaitingItems);
		} else {
			const responseOthers = await execute(syncWaitingListItems, {
				filter: {
					waitingListID: { eq: actualWaitingList?.id },
					status: { ne: WaitingListItemStatus.TERMINADA },
					positionNumber: {
						lt: waitingListItem.positionNumber,
						ge: newPositionNumber,
					},
				},
			});

			const othersWaitingItems = responseOthers.data.syncWaitingListItems.items;

			// now update position numbers

			await Promise.all([
				await execute(updateWaitingListItem, {
					input: {
						id: waitingListItemID,
						positionNumber: newPositionNumber,
						_version: waitingListItem._version,
					},
				}),
				...othersWaitingItems.map(async (item: any) => {
					if (item.status !== WaitingListItemStatus.ESPERA) return item;

					await execute(updateWaitingListItem, {
						input: {
							id: item.id,
							positionNumber: item.positionNumber + 1,
							_version: item._version,
						},
					});
				}),
			]);

			const newWaitingItems = waitingListItems
				.filter((item) => item.status !== WaitingListItemStatus.TERMINADA)
				.map((item) => {
					if (item.status !== WaitingListItemStatus.ESPERA) return item;
					if (item.id === waitingListItemID) {
						return {
							...item,
							positionNumber: newPositionNumber,
						};
					}

					if (
						item.positionNumber <= waitingListItem.positionNumber &&
						item.positionNumber >= newPositionNumber
					) {
						return {
							...item,
							positionNumber: item.positionNumber + 1,
						};
					}

					return item;
				});

			setWaitingListItems(newWaitingItems);
		}
	};

	const upsertCurrentWaitingList = async (hospitalDoctor: any): Promise<void> => {
		try {
			if (hospitalDoctor) {
				const currentWaitingListResponse = await execute(syncWaitLists, {
					filter: {
						id: {
							eq: hospitalDoctor.lastWaitingListID,
						},
					},
				});

				const currentWaitingList = currentWaitingListResponse.data.syncWaitLists.items[0];
				if (currentWaitingList && moment().isSame(currentWaitingList.createdAt, 'day')) {
					setActualWaitingList(currentWaitingList);
				} else {
					const newWaitingListResponse = await execute(createWaitList, {
						input: {
							hospitalDoctorID: hospitalDoctor.id,
						},
					});

					const newWaitingList = newWaitingListResponse.data.createWaitList;

					await execute(updateHospitalDoctor, {
						input: {
							id: hospitalDoctor.id,
							lastWaitingListID: newWaitingList.id,
							_version: hospitalDoctor._version,
						},
					});

					setActualWaitingList(newWaitingList);
				}
			}
		} catch (error) {
			relationContext.setAlertMessage({
				message: `Ocurrió un error al crear o actualizar la lista de espera actual.`,
				severity: 'error',
			});
		}
	};

	const upsertMemberWaitingList = async (member: IMemberDropdown): Promise<void> => {
		try {
			if (member) {
				const currentWaitingListResponse = await execute(syncWaitLists, {
					filter: {
						id: {
							eq: member.lastWaitingListID,
						},
					},
				});

				const currentWaitingList = currentWaitingListResponse.data.syncWaitLists.items[0];
				if (currentWaitingList && moment().isSame(currentWaitingList.createdAt, 'day')) {
					const newWaitingListItems = await getWaitingListItemsByWaitingListID(
						currentWaitingList.id
					);
					setActualWaitingList(currentWaitingList);
					setWaitingListItems(newWaitingListItems);
				} else {
					const hospitalDoctorResponse = await execute(syncHospitalDoctors, {
						filter: {
							id: { eq: member.doctorID },
						},
					});

					const hospitalDoctor = hospitalDoctorResponse.data.syncHospitalDoctors.items[0];
					if (!hospitalDoctor) {
						setWaitingListItems([]);
						return;
					}
					const newWaitingListResponse = await execute(createWaitList, {
						input: {
							hospitalDoctorID: hospitalDoctor.id,
						},
					});

					const newWaitingList = newWaitingListResponse.data.createWaitList;
					await execute(updateHospitalDoctor, {
						input: {
							id: hospitalDoctor.id,
							lastWaitingListID: newWaitingList.id,
							_version: hospitalDoctor._version,
						},
					});
					setActualWaitingList(newWaitingList);
					setWaitingListItems(newWaitingList);
				}
			}
		} catch (error) {
			console.log(error);
			relationContext.setAlertMessage({
				message: `Ocurrió un error al crear o actualizar la lista de espera actual.`,
				severity: 'error',
			});
		}
	};

	return (
		<WaitingListsContext.Provider
			value={{
				waitingListItems,
				getWaitingListItems,
				addClientToCurrentWaitingList,
				updateWaitingListItemStatus,
				updateWaitingItemPositionNumber,
				upsertCurrentWaitingList,
				setWaitingListItems,
				actualWaitingList,
				upsertMemberWaitingList,
			}}
		>
			{props.children}
		</WaitingListsContext.Provider>
	);
};

export default ContextProvider;
