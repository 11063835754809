// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import {
	AIButton,
	AICardActions,
	AICardContainer,
	AICardContent,
	AICardHeader,
	AITextField,
	CustomTypography,
	SuggestionContainer,
} from './styles';
import { StyledChip } from 'shared/style';
import AIReport from './AIReport';
import { AIDiagnosticExamples, AIDiagnosticExamplesTypes } from 'shared/type';
import { AIPatienDiagnostic, AISection } from 'shared/ai/types';
import { ClientsContext } from 'context/client-context';
import { AIContextProps, ClientContextProps } from 'context/types';
import { formatFamilyHistory } from 'components/forms/client/consultation/utils';
import { AllergyStatus } from 'API';
import { AiContext } from 'context/ai-context';
import AIDiagnosticReport from './AIDiagnosticReport';
import useMobile from 'hooks/useMobile';
import { useHistory } from 'react-router-dom';

const AIDiagnostic = () => {
	const [inputValue, setInputValue] = useState<string>('');
	const { isMobile } = useMobile();
	const history = useHistory();
	const { selectedClient: client } = useContext(ClientsContext) as ClientContextProps;
	const { setAiSection, setIAPrompt, aiSuggestions, aiSection, clearIASuggestions } = useContext(
		AiContext
	) as AIContextProps;
	const onClickExample = (value: AIDiagnosticExamplesTypes) => {
		if (value === 'chest_pain') {
			setInputValue(AIDiagnosticExamples.chest_pain);
		}
		if (value === 'inverted_t_waves') {
			setInputValue(AIDiagnosticExamples.inverted_t_waves);
		}
		if (value === 'st_elevation_hypertensive') {
			setInputValue(AIDiagnosticExamples.st_elevation_hypertensive);
		}
	};

	const dataToPromp: AIPatienDiagnostic = {
		alergias: client?.allergies?.map((el) => el.name).join(', ') ?? '',
		antecFamiliares: formatFamilyHistory(client?.familyHistories),
		bornDate: client?.bornDate ?? '',
		enfermActiva:
			client?.diseases
				?.filter((d) => d.status === AllergyStatus.ACTIVE)
				.map((d) => d.name)
				.join(', ') ?? '',
		enfermInactivas:
			client?.diseases
				?.filter((d) => d.status === AllergyStatus.INACTIVE)
				.map((d) => d.name)
				.join(', ') ?? '',
		gender: client?.gender ?? '',
		antecPatologico: '',
		estiloDeVida: client?.lifeStyles?.map((el) => el.name).join(', ') ?? '',
		clinicPresentation: inputValue,
		hospitalPrevias: client?.hospitalizations?.join(', ') ?? '',
	};

	useEffect(() => {
		setAiSection('medical_plan');
		clearIASuggestions();
	}, []);

	const renderReport = () => {
		if (aiSection === 'diagnosis') {
			return <AIDiagnosticReport diagnostics={aiSuggestions?.diagnosticos} />;
		}

		if (aiSection === 'medical_plan') {
			return (
				<AIReport
					descripcion={aiSuggestions?.planMedico as string}
					treatment={aiSuggestions?.tratamientos?.map((t) => t.nombre).join(', ') ?? ''}
					study={aiSuggestions?.estudios?.map((e) => e.nombre).join(', ') ?? ''}
					medicines={aiSuggestions?.medicamentos?.map((m) => m.nombre).join(', ') ?? ''}
					diagnostics={aiSuggestions?.diagnosticos?.map((d) => d.nombre).join(', ') ?? ''}
				/>
			);
		}
	};

	const onClickHandler = (section: AISection) => {
		clearIASuggestions();
		setAiSection(section);
		setIAPrompt(dataToPromp);
	};

	if (isMobile) {
		const currentUrl = history.location.pathname;
		const updatedUrl = currentUrl.replace('ai_diagnostic', 'summary');
		history.push(updatedUrl);
		return null;
	}
	const styleContainer = {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		overflowY: 'auto',
		height: 'calc(100dvh - 185px)',
	};
	return (
		<div style={styleContainer}>
			<CustomTypography as={'h5'}>
				Sugerencias médicas con inteligencia artificial
			</CustomTypography>
			<CustomTypography
				as={'h5'}
				style={{
					fontSize: '14px',
					marginTop: '7px',
				}}
			>
				Analizamos el historial y síntomas del paciente para sugerir Dx y plan médico
			</CustomTypography>
			<SuggestionContainer>
				<CustomTypography
					style={{
						fontSize: '14px',
						marginRight: '10px',
						marginTop: '0px',
					}}
				>
					Prueba estos ejemplos:
				</CustomTypography>
				<StyledChip
					label={'ST elevado en hipertenso'}
					clickable
					color="primary"
					variant="outlined"
					style={{
						fontFamily: 'Roboto',
						fontStyle: 'normal',
						fontWeight: 500,
						fontSize: '14px',
						lineHeight: '24px',
						letterSpacing: '0.17px',
						color: 'rgba(0, 0, 0, 0.87)',
						border: '1px solid rgba(0, 0, 0, 0.26)',
						borderRadius: '16px',
						marginBottom: '0px',
						marginRight: '10px',
					}}
					onClick={() => onClickExample('st_elevation_hypertensive')}
					customHoverEffect
				/>
				<StyledChip
					label={'Ondas T invertidas'}
					clickable
					color="primary"
					variant="outlined"
					style={{
						fontFamily: 'Roboto',
						fontStyle: 'normal',
						fontWeight: 500,
						fontSize: '14px',
						lineHeight: '24px',
						letterSpacing: '0.17px',
						color: 'rgba(0, 0, 0, 0.87)',
						border: '1px solid rgba(0, 0, 0, 0.26)',
						borderRadius: '16px',
						marginBottom: '0px',
						marginRight: '10px',
					}}
					onClick={() => onClickExample('inverted_t_waves')}
					customHoverEffect
				/>
				<StyledChip
					label={'Dolor torácico'}
					clickable
					color="primary"
					variant="outlined"
					style={{
						fontFamily: 'Roboto',
						fontStyle: 'normal',
						fontWeight: 500,
						fontSize: '14px',
						lineHeight: '24px',
						letterSpacing: '0.17px',
						color: 'rgba(0, 0, 0, 0.87)',
						border: '1px solid rgba(0, 0, 0, 0.26)',
						borderRadius: '16px',
						marginBottom: '0px',
					}}
					onClick={() => onClickExample('chest_pain')}
					customHoverEffect
				/>
			</SuggestionContainer>
			<div style={{ width: '100%' }}>
				<AICardContainer>
					<AICardHeader title="Ingrese presentación clínica del paciente" />
					<AICardContent>
						<AITextField
							placeholder="Ejemplo:  Un hombre de 50 años se presenta en el hospital con dolor en el pecho..."
							variant="outlined"
							value={inputValue}
							multiline
							onChange={(e) => setInputValue(e.target.value)}
						/>
					</AICardContent>
					<AICardActions>
						<AIButton
							size="small"
							color="primary"
							variant="contained"
							disabled={!inputValue}
							customDisabled
							onClick={() => onClickHandler('diagnosis')}
						>
							posibles Diagnósticos
						</AIButton>

						<AIButton
							size="small"
							color="primary"
							variant="contained"
							disabled={!inputValue}
							onClick={() => onClickHandler('medical_plan')}
						>
							Generar plan médico
						</AIButton>
					</AICardActions>
				</AICardContainer>
			</div>
			{aiSuggestions && renderReport()}
		</div>
	);
};

export default AIDiagnostic;
