import { Box, Button, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const ModalBody = styled(Box)`
	background: #fff;
	box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
		0px 3px 1px -2px rgba(0, 0, 0, 0.2);
	border-radius: 4px;
	padding: 25px;
	width: 100%;
	height: 100%;
	max-width: 488px;
	max-height: 427px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	gap: 32px;
	display: flex;
	flex-direction: column;
`;

export const ModalBodyTitle = styled(Typography)`
	color: rgba(0, 0, 0, 0.87);
	font-size: 24px;
	font-weight: 400;
	line-height: 133.4%;
	font-style: normal;
	width: 100%;
	text-align: left;
`;

export const ModalBodyActions = styled(Box)`
	display: flex;
	flex-direction: column;
	gap: 24px;
`;

export const ModalBodyButton = styled(Button)<{
	hasBackGround?: boolean;
}>`
	width: 100%;
	background: ${({ hasBackGround }) => (hasBackGround ? '#1976D2' : 'transparent')};
	color: ${({ hasBackGround }) => (hasBackGround ? '#FFF' : '#1976D2')};
	font-size: 15px;
	font-weight: 600;
	line-height: 26px;
	font-style: normal;
	text-transform: uppercase;
	letter-spacing: 0.46px;
	&:hover {
		background: ${({ hasBackGround }) => (hasBackGround ? '#1976D2' : '#E8F0FE')};
	}
`;
