/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateSecretaryInput = {
	id?: string | null;
	name?: string | null;
	email: string;
	firstTime?: boolean | null;
	_version?: number | null;
};

export type ModelSecretaryConditionInput = {
	name?: ModelStringInput | null;
	email?: ModelStringInput | null;
	firstTime?: ModelBooleanInput | null;
	and?: Array<ModelSecretaryConditionInput | null> | null;
	or?: Array<ModelSecretaryConditionInput | null> | null;
	not?: ModelSecretaryConditionInput | null;
};

export type ModelStringInput = {
	ne?: string | null;
	eq?: string | null;
	le?: string | null;
	lt?: string | null;
	ge?: string | null;
	gt?: string | null;
	contains?: string | null;
	notContains?: string | null;
	between?: Array<string | null> | null;
	beginsWith?: string | null;
	attributeExists?: boolean | null;
	attributeType?: ModelAttributeTypes | null;
	size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
	binary = 'binary',
	binarySet = 'binarySet',
	bool = 'bool',
	list = 'list',
	map = 'map',
	number = 'number',
	numberSet = 'numberSet',
	string = 'string',
	stringSet = 'stringSet',
	_null = '_null',
}

export type ModelSizeInput = {
	ne?: number | null;
	eq?: number | null;
	le?: number | null;
	lt?: number | null;
	ge?: number | null;
	gt?: number | null;
	between?: Array<number | null> | null;
};

export type ModelBooleanInput = {
	ne?: boolean | null;
	eq?: boolean | null;
	attributeExists?: boolean | null;
	attributeType?: ModelAttributeTypes | null;
};

export type Secretary = {
	__typename: 'Secretary';
	id: string;
	name?: string | null;
	email: string;
	firstTime?: boolean | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
};

export type UpdateSecretaryInput = {
	id: string;
	name?: string | null;
	email?: string | null;
	firstTime?: boolean | null;
	_version?: number | null;
};

export type DeleteSecretaryInput = {
	id: string;
	_version?: number | null;
};

export type CreateEmailDistributionListsInput = {
	id?: string | null;
	email?: string | null;
	specialty?: string | null;
	createdAt?: string | null;
	updatedAt?: string | null;
	_version?: number | null;
};

export type ModelEmailDistributionListsConditionInput = {
	email?: ModelStringInput | null;
	specialty?: ModelStringInput | null;
	createdAt?: ModelStringInput | null;
	updatedAt?: ModelStringInput | null;
	and?: Array<ModelEmailDistributionListsConditionInput | null> | null;
	or?: Array<ModelEmailDistributionListsConditionInput | null> | null;
	not?: ModelEmailDistributionListsConditionInput | null;
};

export type EmailDistributionLists = {
	__typename: 'EmailDistributionLists';
	id: string;
	email?: string | null;
	specialty?: string | null;
	createdAt: string;
	updatedAt: string;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
};

export type UpdateEmailDistributionListsInput = {
	id: string;
	email?: string | null;
	specialty?: string | null;
	createdAt?: string | null;
	updatedAt?: string | null;
	_version?: number | null;
};

export type DeleteEmailDistributionListsInput = {
	id: string;
	_version?: number | null;
};

export type CreateSecretaryHospitalDoctorInput = {
	id?: string | null;
	hospitalDoctorID: string;
	secretaryID: string;
	hasHistoryPermission?: boolean | null;
	hasSurgeryPermission?: boolean | null;
	hasPrescriptionsPermission?: boolean | null;
	hasFilesPermission?: boolean | null;
	hasNotesPermission?: boolean | null;
	hasClaimsPermission?: boolean | null;
	status?: Status | null;
	_version?: number | null;
};

export enum Status {
	ACTIVE = 'ACTIVE',
	INACTIVE = 'INACTIVE',
}

export type ModelSecretaryHospitalDoctorConditionInput = {
	hospitalDoctorID?: ModelIDInput | null;
	secretaryID?: ModelIDInput | null;
	hasHistoryPermission?: ModelBooleanInput | null;
	hasSurgeryPermission?: ModelBooleanInput | null;
	hasPrescriptionsPermission?: ModelBooleanInput | null;
	hasFilesPermission?: ModelBooleanInput | null;
	hasNotesPermission?: ModelBooleanInput | null;
	hasClaimsPermission?: ModelBooleanInput | null;
	status?: ModelStatusInput | null;
	and?: Array<ModelSecretaryHospitalDoctorConditionInput | null> | null;
	or?: Array<ModelSecretaryHospitalDoctorConditionInput | null> | null;
	not?: ModelSecretaryHospitalDoctorConditionInput | null;
};

export type ModelIDInput = {
	ne?: string | null;
	eq?: string | null;
	le?: string | null;
	lt?: string | null;
	ge?: string | null;
	gt?: string | null;
	contains?: string | null;
	notContains?: string | null;
	between?: Array<string | null> | null;
	beginsWith?: string | null;
	attributeExists?: boolean | null;
	attributeType?: ModelAttributeTypes | null;
	size?: ModelSizeInput | null;
};

export type ModelStatusInput = {
	eq?: Status | null;
	ne?: Status | null;
};

export type SecretaryHospitalDoctor = {
	__typename: 'SecretaryHospitalDoctor';
	id: string;
	hospitalDoctorID: string;
	secretaryID: string;
	hasHistoryPermission?: boolean | null;
	hasSurgeryPermission?: boolean | null;
	hasPrescriptionsPermission?: boolean | null;
	hasFilesPermission?: boolean | null;
	hasNotesPermission?: boolean | null;
	hasClaimsPermission?: boolean | null;
	status?: Status | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
	secretary?: Secretary | null;
	hospitalDoctor?: HospitalDoctor | null;
};

export type HospitalDoctor = {
	__typename: 'HospitalDoctor';
	id: string;
	doctorID: string;
	hospitalID: string;
	lastWaitingListID: string;
	hospitalLogo?: string | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
	doctor?: Doctor | null;
	hospital?: Hospital | null;
};

export type Doctor = {
	__typename: 'Doctor';
	id: string;
	name?: string | null;
	specialty?: string | null;
	exequator?: string | null;
	email: string;
	firstTime?: boolean | null;
	studyPlace?: string | null;
	isBeenReview?: boolean | null;
	isFirstAddSecretary?: boolean | null;
	hasTrialOnCreation?: boolean | null;
	title?: string | null;
	phone?: string | null;
	code?: string | null;
	password?: string | null;
	isAdmin?: boolean | null;
	daysOfTrial?: number | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
};

export type Hospital = {
	__typename: 'Hospital';
	id: string;
	name: string;
	type?: string | null;
	province?: string | null;
	town?: string | null;
	sector?: string | null;
	phone?: string | null;
	address?: string | null;
	pssCode?: string | null;
	rnc?: string | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
};

export type UpdateSecretaryHospitalDoctorInput = {
	id: string;
	hospitalDoctorID?: string | null;
	secretaryID?: string | null;
	hasHistoryPermission?: boolean | null;
	hasSurgeryPermission?: boolean | null;
	hasPrescriptionsPermission?: boolean | null;
	hasFilesPermission?: boolean | null;
	hasNotesPermission?: boolean | null;
	hasClaimsPermission?: boolean | null;
	status?: Status | null;
	_version?: number | null;
};

export type DeleteSecretaryHospitalDoctorInput = {
	id: string;
	_version?: number | null;
};

export type CreateSecretaryHospitalDoctorClientInput = {
	id?: string | null;
	hospitalDoctorID?: string | null;
	secretaryID?: string | null;
	clientID?: string | null;
	note?: string | null;
	_version?: number | null;
};

export type ModelSecretaryHospitalDoctorClientConditionInput = {
	hospitalDoctorID?: ModelIDInput | null;
	secretaryID?: ModelIDInput | null;
	clientID?: ModelIDInput | null;
	note?: ModelStringInput | null;
	and?: Array<ModelSecretaryHospitalDoctorClientConditionInput | null> | null;
	or?: Array<ModelSecretaryHospitalDoctorClientConditionInput | null> | null;
	not?: ModelSecretaryHospitalDoctorClientConditionInput | null;
};

export type SecretaryHospitalDoctorClient = {
	__typename: 'SecretaryHospitalDoctorClient';
	id: string;
	hospitalDoctorID?: string | null;
	secretaryID?: string | null;
	clientID?: string | null;
	note?: string | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
};

export type UpdateSecretaryHospitalDoctorClientInput = {
	id: string;
	hospitalDoctorID?: string | null;
	secretaryID?: string | null;
	clientID?: string | null;
	note?: string | null;
	_version?: number | null;
};

export type DeleteSecretaryHospitalDoctorClientInput = {
	id: string;
	_version?: number | null;
};

export type CreateMemberHospitalDoctorClientInput = {
	id?: string | null;
	hospitalDoctorID?: string | null;
	memberID?: string | null;
	ownerID?: string | null;
	clientID?: string | null;
	note?: string | null;
	_version?: number | null;
};

export type ModelMemberHospitalDoctorClientConditionInput = {
	hospitalDoctorID?: ModelIDInput | null;
	memberID?: ModelIDInput | null;
	ownerID?: ModelIDInput | null;
	clientID?: ModelIDInput | null;
	note?: ModelStringInput | null;
	and?: Array<ModelMemberHospitalDoctorClientConditionInput | null> | null;
	or?: Array<ModelMemberHospitalDoctorClientConditionInput | null> | null;
	not?: ModelMemberHospitalDoctorClientConditionInput | null;
};

export type MemberHospitalDoctorClient = {
	__typename: 'MemberHospitalDoctorClient';
	id: string;
	hospitalDoctorID?: string | null;
	memberID?: string | null;
	ownerID?: string | null;
	clientID?: string | null;
	note?: string | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
	owner?: HospitalDoctor | null;
};

export type UpdateMemberHospitalDoctorClientInput = {
	id: string;
	hospitalDoctorID?: string | null;
	memberID?: string | null;
	ownerID?: string | null;
	clientID?: string | null;
	note?: string | null;
	_version?: number | null;
};

export type DeleteMemberHospitalDoctorClientInput = {
	id: string;
	_version?: number | null;
};

export type CreateClientInput = {
	id?: string | null;
	identificationName?: IdentificationTypes | null;
	identificationData?: string | null;
	actualInsurance?: string | null;
	name: string;
	lastName: string;
	cellphoneNumber?: string | null;
	email?: string | null;
	bornDate?: string | null;
	parentOrTutorCedula?: string | null;
	parentOrTutorID?: string | null;
	gender?: SexType | null;
	phoneNumber?: string | null;
	addressStreet?: string | null;
	city?: string | null;
	isAdult?: boolean | null;
	sector?: string | null;
	bloodType?: string | null;
	company?: string | null;
	vitalSignsHeight?: string | null;
	vitalSignsWeight?: string | null;
	bodyMass?: string | null;
	vitalSignsBloodPressure?: string | null;
	heartRate?: string | null;
	respiratoryRate?: string | null;
	bodyTemperature?: string | null;
	oxygenSaturation?: string | null;
	occupation?: string | null;
	profileImage?: string | null;
	heartBeat?: string | null;
	weight?: string | null;
	height?: string | null;
	bloodPressure?: string | null;
	updateDateTime?: string | null;
	createdAt?: string | null;
	updatedAt?: string | null;
	code?: string | null;
	_version?: number | null;
};

export enum IdentificationTypes {
	CEDULA = 'CEDULA',
	PASAPORTE = 'PASAPORTE',
	SINDOCUMENTOS = 'SINDOCUMENTOS',
}

export enum SexType {
	FEMENINO = 'FEMENINO',
	MASCULINO = 'MASCULINO',
	NOSEX = 'NOSEX',
}

export type ModelClientConditionInput = {
	identificationName?: ModelIdentificationTypesInput | null;
	identificationData?: ModelStringInput | null;
	actualInsurance?: ModelStringInput | null;
	name?: ModelStringInput | null;
	lastName?: ModelStringInput | null;
	cellphoneNumber?: ModelStringInput | null;
	email?: ModelStringInput | null;
	bornDate?: ModelStringInput | null;
	parentOrTutorCedula?: ModelStringInput | null;
	parentOrTutorID?: ModelStringInput | null;
	gender?: ModelSexTypeInput | null;
	phoneNumber?: ModelStringInput | null;
	addressStreet?: ModelStringInput | null;
	city?: ModelStringInput | null;
	isAdult?: ModelBooleanInput | null;
	sector?: ModelStringInput | null;
	bloodType?: ModelStringInput | null;
	company?: ModelStringInput | null;
	vitalSignsHeight?: ModelStringInput | null;
	vitalSignsWeight?: ModelStringInput | null;
	bodyMass?: ModelStringInput | null;
	vitalSignsBloodPressure?: ModelStringInput | null;
	heartRate?: ModelStringInput | null;
	respiratoryRate?: ModelStringInput | null;
	bodyTemperature?: ModelStringInput | null;
	oxygenSaturation?: ModelStringInput | null;
	occupation?: ModelStringInput | null;
	profileImage?: ModelStringInput | null;
	heartBeat?: ModelStringInput | null;
	weight?: ModelStringInput | null;
	height?: ModelStringInput | null;
	bloodPressure?: ModelStringInput | null;
	updateDateTime?: ModelStringInput | null;
	createdAt?: ModelStringInput | null;
	updatedAt?: ModelStringInput | null;
	code?: ModelStringInput | null;
	and?: Array<ModelClientConditionInput | null> | null;
	or?: Array<ModelClientConditionInput | null> | null;
	not?: ModelClientConditionInput | null;
};

export type ModelIdentificationTypesInput = {
	eq?: IdentificationTypes | null;
	ne?: IdentificationTypes | null;
};

export type ModelSexTypeInput = {
	eq?: SexType | null;
	ne?: SexType | null;
};

export type Client = {
	__typename: 'Client';
	id: string;
	identificationName?: IdentificationTypes | null;
	identificationData?: string | null;
	actualInsurance?: string | null;
	name: string;
	lastName: string;
	cellphoneNumber?: string | null;
	email?: string | null;
	bornDate?: string | null;
	parentOrTutorCedula?: string | null;
	parentOrTutorID?: string | null;
	gender?: SexType | null;
	phoneNumber?: string | null;
	addressStreet?: string | null;
	city?: string | null;
	isAdult?: boolean | null;
	sector?: string | null;
	bloodType?: string | null;
	company?: string | null;
	vitalSignsHeight?: string | null;
	vitalSignsWeight?: string | null;
	bodyMass?: string | null;
	vitalSignsBloodPressure?: string | null;
	heartRate?: string | null;
	respiratoryRate?: string | null;
	bodyTemperature?: string | null;
	oxygenSaturation?: string | null;
	occupation?: string | null;
	profileImage?: string | null;
	heartBeat?: string | null;
	weight?: string | null;
	height?: string | null;
	bloodPressure?: string | null;
	updateDateTime?: string | null;
	createdAt: string;
	updatedAt: string;
	code?: string | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
};

export type UpdateClientInput = {
	id: string;
	identificationName?: IdentificationTypes | null;
	identificationData?: string | null;
	actualInsurance?: string | null;
	name?: string | null;
	lastName?: string | null;
	cellphoneNumber?: string | null;
	email?: string | null;
	bornDate?: string | null;
	parentOrTutorCedula?: string | null;
	parentOrTutorID?: string | null;
	gender?: SexType | null;
	phoneNumber?: string | null;
	addressStreet?: string | null;
	city?: string | null;
	isAdult?: boolean | null;
	sector?: string | null;
	bloodType?: string | null;
	company?: string | null;
	vitalSignsHeight?: string | null;
	vitalSignsWeight?: string | null;
	bodyMass?: string | null;
	vitalSignsBloodPressure?: string | null;
	heartRate?: string | null;
	respiratoryRate?: string | null;
	bodyTemperature?: string | null;
	oxygenSaturation?: string | null;
	occupation?: string | null;
	profileImage?: string | null;
	heartBeat?: string | null;
	weight?: string | null;
	height?: string | null;
	bloodPressure?: string | null;
	updateDateTime?: string | null;
	createdAt?: string | null;
	updatedAt?: string | null;
	code?: string | null;
	_version?: number | null;
};

export type DeleteClientInput = {
	id: string;
	_version?: number | null;
};

export type CreateClientImageSectionInput = {
	id?: string | null;
	clientHospitalDoctorID?: string | null;
	date?: string | null;
	description?: string | null;
	status?: Status | null;
	_version?: number | null;
};

export type ModelClientImageSectionConditionInput = {
	clientHospitalDoctorID?: ModelIDInput | null;
	date?: ModelStringInput | null;
	description?: ModelStringInput | null;
	status?: ModelStatusInput | null;
	and?: Array<ModelClientImageSectionConditionInput | null> | null;
	or?: Array<ModelClientImageSectionConditionInput | null> | null;
	not?: ModelClientImageSectionConditionInput | null;
};

export type ClientImageSection = {
	__typename: 'ClientImageSection';
	id: string;
	clientHospitalDoctorID?: string | null;
	date?: string | null;
	description?: string | null;
	status?: Status | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
};

export type UpdateClientImageSectionInput = {
	id: string;
	clientHospitalDoctorID?: string | null;
	date?: string | null;
	description?: string | null;
	status?: Status | null;
	_version?: number | null;
};

export type DeleteClientImageSectionInput = {
	id: string;
	_version?: number | null;
};

export type CreatePosibleClientInput = {
	id?: string | null;
	phoneNumber?: string | null;
	_version?: number | null;
};

export type ModelPosibleClientConditionInput = {
	phoneNumber?: ModelStringInput | null;
	and?: Array<ModelPosibleClientConditionInput | null> | null;
	or?: Array<ModelPosibleClientConditionInput | null> | null;
	not?: ModelPosibleClientConditionInput | null;
};

export type PosibleClient = {
	__typename: 'PosibleClient';
	id: string;
	phoneNumber?: string | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
};

export type UpdatePosibleClientInput = {
	id: string;
	phoneNumber?: string | null;
	_version?: number | null;
};

export type DeletePosibleClientInput = {
	id: string;
	_version?: number | null;
};

export type CreateClientImageInput = {
	id?: string | null;
	pathFile?: string | null;
	description?: string | null;
	ClientImageDateID?: string | null;
	status?: Status | null;
	_version?: number | null;
};

export type ModelClientImageConditionInput = {
	pathFile?: ModelStringInput | null;
	description?: ModelStringInput | null;
	ClientImageDateID?: ModelIDInput | null;
	status?: ModelStatusInput | null;
	and?: Array<ModelClientImageConditionInput | null> | null;
	or?: Array<ModelClientImageConditionInput | null> | null;
	not?: ModelClientImageConditionInput | null;
};

export type ClientImage = {
	__typename: 'ClientImage';
	id: string;
	pathFile?: string | null;
	description?: string | null;
	ClientImageDateID?: string | null;
	status?: Status | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
};

export type UpdateClientImageInput = {
	id: string;
	pathFile?: string | null;
	description?: string | null;
	ClientImageDateID?: string | null;
	status?: Status | null;
	_version?: number | null;
};

export type DeleteClientImageInput = {
	id: string;
	_version?: number | null;
};

export type CreateConsultationDiagnosticInput = {
	id?: string | null;
	clientConsultationID: string;
	name?: string | null;
	code?: string | null;
	type?: DiagnosticTypes | null;
	status?: Status | null;
	_version?: number | null;
};

export enum DiagnosticTypes {
	PRESUMPTIVE = 'PRESUMPTIVE',
	DIFFERENTIAL = 'DIFFERENTIAL',
}

export type ModelConsultationDiagnosticConditionInput = {
	clientConsultationID?: ModelIDInput | null;
	name?: ModelStringInput | null;
	code?: ModelStringInput | null;
	type?: ModelDiagnosticTypesInput | null;
	status?: ModelStatusInput | null;
	and?: Array<ModelConsultationDiagnosticConditionInput | null> | null;
	or?: Array<ModelConsultationDiagnosticConditionInput | null> | null;
	not?: ModelConsultationDiagnosticConditionInput | null;
};

export type ModelDiagnosticTypesInput = {
	eq?: DiagnosticTypes | null;
	ne?: DiagnosticTypes | null;
};

export type ConsultationDiagnostic = {
	__typename: 'ConsultationDiagnostic';
	id: string;
	clientConsultationID: string;
	name?: string | null;
	code?: string | null;
	type?: DiagnosticTypes | null;
	status?: Status | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
};

export type UpdateConsultationDiagnosticInput = {
	id: string;
	clientConsultationID?: string | null;
	name?: string | null;
	code?: string | null;
	type?: DiagnosticTypes | null;
	status?: Status | null;
	_version?: number | null;
};

export type DeleteConsultationDiagnosticInput = {
	id: string;
	_version?: number | null;
};

export type CreateClientConsultationInput = {
	id?: string | null;
	clientHospitalDoctorID: string;
	doctorID?: string | null;
	visitReason?: string | null;
	visitDescription?: string | null;
	physicTestNote?: string | null;
	height?: string | null;
	weight?: string | null;
	bodyMass?: string | null;
	bloodPressure?: string | null;
	heartRate?: string | null;
	respiratoryRate?: string | null;
	bodyTemperature?: string | null;
	oxygenSaturation?: string | null;
	clientMedicinePrescriptionID?: string | null;
	clientAnalisisPrescriptionID?: string | null;
	clientFreePrescriptionID?: string | null;
	treatment?: string | null;
	procedureName?: string | null;
	procedureDescription?: string | null;
	createdAt?: string | null;
	updatedAt?: string | null;
	status?: Status | null;
	_version?: number | null;
};

export type ModelClientConsultationConditionInput = {
	clientHospitalDoctorID?: ModelIDInput | null;
	doctorID?: ModelIDInput | null;
	visitReason?: ModelStringInput | null;
	visitDescription?: ModelStringInput | null;
	physicTestNote?: ModelStringInput | null;
	height?: ModelStringInput | null;
	weight?: ModelStringInput | null;
	bodyMass?: ModelStringInput | null;
	bloodPressure?: ModelStringInput | null;
	heartRate?: ModelStringInput | null;
	respiratoryRate?: ModelStringInput | null;
	bodyTemperature?: ModelStringInput | null;
	oxygenSaturation?: ModelStringInput | null;
	clientMedicinePrescriptionID?: ModelIDInput | null;
	clientAnalisisPrescriptionID?: ModelIDInput | null;
	clientFreePrescriptionID?: ModelIDInput | null;
	treatment?: ModelStringInput | null;
	procedureName?: ModelStringInput | null;
	procedureDescription?: ModelStringInput | null;
	createdAt?: ModelStringInput | null;
	updatedAt?: ModelStringInput | null;
	status?: ModelStatusInput | null;
	and?: Array<ModelClientConsultationConditionInput | null> | null;
	or?: Array<ModelClientConsultationConditionInput | null> | null;
	not?: ModelClientConsultationConditionInput | null;
};

export type ClientConsultation = {
	__typename: 'ClientConsultation';
	id: string;
	clientHospitalDoctorID: string;
	doctorID?: string | null;
	visitReason?: string | null;
	visitDescription?: string | null;
	physicTestNote?: string | null;
	height?: string | null;
	weight?: string | null;
	bodyMass?: string | null;
	bloodPressure?: string | null;
	heartRate?: string | null;
	respiratoryRate?: string | null;
	bodyTemperature?: string | null;
	oxygenSaturation?: string | null;
	clientMedicinePrescriptionID?: string | null;
	clientAnalisisPrescriptionID?: string | null;
	clientFreePrescriptionID?: string | null;
	treatment?: string | null;
	procedureName?: string | null;
	procedureDescription?: string | null;
	createdAt?: string | null;
	updatedAt?: string | null;
	status?: Status | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	doctor?: Doctor | null;
};

export type UpdateClientConsultationInput = {
	id: string;
	clientHospitalDoctorID?: string | null;
	doctorID?: string | null;
	visitReason?: string | null;
	visitDescription?: string | null;
	physicTestNote?: string | null;
	height?: string | null;
	weight?: string | null;
	bodyMass?: string | null;
	bloodPressure?: string | null;
	heartRate?: string | null;
	respiratoryRate?: string | null;
	bodyTemperature?: string | null;
	oxygenSaturation?: string | null;
	clientMedicinePrescriptionID?: string | null;
	clientAnalisisPrescriptionID?: string | null;
	clientFreePrescriptionID?: string | null;
	treatment?: string | null;
	procedureName?: string | null;
	procedureDescription?: string | null;
	createdAt?: string | null;
	updatedAt?: string | null;
	status?: Status | null;
	_version?: number | null;
};

export type DeleteClientConsultationInput = {
	id: string;
	_version?: number | null;
};

export type CreateMedicineInput = {
	id?: string | null;
	name: string;
	_version?: number | null;
};

export type ModelMedicineConditionInput = {
	name?: ModelStringInput | null;
	and?: Array<ModelMedicineConditionInput | null> | null;
	or?: Array<ModelMedicineConditionInput | null> | null;
	not?: ModelMedicineConditionInput | null;
};

export type Medicine = {
	__typename: 'Medicine';
	id: string;
	name: string;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
};

export type UpdateMedicineInput = {
	id: string;
	name?: string | null;
	_version?: number | null;
};

export type DeleteMedicineInput = {
	id: string;
	_version?: number | null;
};

export type CreateClientAllergyInput = {
	id?: string | null;
	clientID: string;
	name: string;
	status?: AllergyStatus | null;
	note?: string | null;
	diagnosedDate?: string | null;
	_version?: number | null;
};

export enum AllergyStatus {
	ACTIVE = 'ACTIVE',
	CURED = 'CURED',
	INACTIVE = 'INACTIVE',
}

export type ModelClientAllergyConditionInput = {
	clientID?: ModelIDInput | null;
	name?: ModelStringInput | null;
	status?: ModelAllergyStatusInput | null;
	note?: ModelStringInput | null;
	diagnosedDate?: ModelStringInput | null;
	and?: Array<ModelClientAllergyConditionInput | null> | null;
	or?: Array<ModelClientAllergyConditionInput | null> | null;
	not?: ModelClientAllergyConditionInput | null;
};

export type ModelAllergyStatusInput = {
	eq?: AllergyStatus | null;
	ne?: AllergyStatus | null;
};

export type ClientAllergy = {
	__typename: 'ClientAllergy';
	id: string;
	clientID: string;
	name: string;
	status?: AllergyStatus | null;
	note?: string | null;
	diagnosedDate?: string | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
};

export type UpdateClientAllergyInput = {
	id: string;
	clientID?: string | null;
	name?: string | null;
	status?: AllergyStatus | null;
	note?: string | null;
	diagnosedDate?: string | null;
	_version?: number | null;
};

export type DeleteClientAllergyInput = {
	id: string;
	_version?: number | null;
};

export type CreateClientDiseaseInput = {
	id?: string | null;
	clientID: string;
	name: string;
	status?: AllergyStatus | null;
	note?: string | null;
	diagnosedDate?: string | null;
	updatedAt?: string | null;
	createdAt?: string | null;
	_version?: number | null;
};

export type ModelClientDiseaseConditionInput = {
	clientID?: ModelIDInput | null;
	name?: ModelStringInput | null;
	status?: ModelAllergyStatusInput | null;
	note?: ModelStringInput | null;
	diagnosedDate?: ModelStringInput | null;
	updatedAt?: ModelStringInput | null;
	createdAt?: ModelStringInput | null;
	and?: Array<ModelClientDiseaseConditionInput | null> | null;
	or?: Array<ModelClientDiseaseConditionInput | null> | null;
	not?: ModelClientDiseaseConditionInput | null;
};

export type ClientDisease = {
	__typename: 'ClientDisease';
	id: string;
	clientID: string;
	name: string;
	status?: AllergyStatus | null;
	note?: string | null;
	diagnosedDate?: string | null;
	updatedAt?: string | null;
	createdAt?: string | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
};

export type UpdateClientDiseaseInput = {
	id: string;
	clientID?: string | null;
	name?: string | null;
	status?: AllergyStatus | null;
	note?: string | null;
	diagnosedDate?: string | null;
	updatedAt?: string | null;
	createdAt?: string | null;
	_version?: number | null;
};

export type DeleteClientDiseaseInput = {
	id: string;
	_version?: number | null;
};

export type CreateClientProcedureInput = {
	id?: string | null;
	clientID: string;
	name: string;
	note?: string | null;
	realizationDate?: string | null;
	status?: Status | null;
	_version?: number | null;
};

export type ModelClientProcedureConditionInput = {
	clientID?: ModelIDInput | null;
	name?: ModelStringInput | null;
	note?: ModelStringInput | null;
	realizationDate?: ModelStringInput | null;
	status?: ModelStatusInput | null;
	and?: Array<ModelClientProcedureConditionInput | null> | null;
	or?: Array<ModelClientProcedureConditionInput | null> | null;
	not?: ModelClientProcedureConditionInput | null;
};

export type ClientProcedure = {
	__typename: 'ClientProcedure';
	id: string;
	clientID: string;
	name: string;
	note?: string | null;
	realizationDate?: string | null;
	status?: Status | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
};

export type UpdateClientProcedureInput = {
	id: string;
	clientID?: string | null;
	name?: string | null;
	note?: string | null;
	realizationDate?: string | null;
	status?: Status | null;
	_version?: number | null;
};

export type DeleteClientProcedureInput = {
	id: string;
	_version?: number | null;
};

export type CreateClientHospitalizationInput = {
	id?: string | null;
	clientID: string;
	name: string;
	note?: string | null;
	date?: string | null;
	status?: Status | null;
	_version?: number | null;
};

export type ModelClientHospitalizationConditionInput = {
	clientID?: ModelIDInput | null;
	name?: ModelStringInput | null;
	note?: ModelStringInput | null;
	date?: ModelStringInput | null;
	status?: ModelStatusInput | null;
	and?: Array<ModelClientHospitalizationConditionInput | null> | null;
	or?: Array<ModelClientHospitalizationConditionInput | null> | null;
	not?: ModelClientHospitalizationConditionInput | null;
};

export type ClientHospitalization = {
	__typename: 'ClientHospitalization';
	id: string;
	clientID: string;
	name: string;
	note?: string | null;
	date?: string | null;
	status?: Status | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
};

export type UpdateClientHospitalizationInput = {
	id: string;
	clientID?: string | null;
	name?: string | null;
	note?: string | null;
	date?: string | null;
	status?: Status | null;
	_version?: number | null;
};

export type DeleteClientHospitalizationInput = {
	id: string;
	_version?: number | null;
};

export type CreateClientFamilyHistoryInput = {
	id?: string | null;
	clientID: string;
	familyMember: HistoryFamilyMember;
	mellitusDiabetes?: boolean | null;
	mellitusDiabetesNote?: string | null;
	arterialHypertension?: boolean | null;
	arterialHypertensionNote?: string | null;
	heartDisease?: boolean | null;
	heartDiseaseNote?: string | null;
	nephropathy?: boolean | null;
	nephropathyNote?: string | null;
	neuropathies?: boolean | null;
	neuropathiesNote?: string | null;
	epilepsy?: boolean | null;
	epilepsyNote?: string | null;
	rheumatoidArthritis?: boolean | null;
	rheumatoidArthritisNote?: string | null;
	psychiatricDiseases?: boolean | null;
	psychiatricDiseasesNote?: string | null;
	cancer?: boolean | null;
	cancerNote?: string | null;
	other?: boolean | null;
	otherNote?: string | null;
	updatedAt?: string | null;
	createdAt?: string | null;
	_version?: number | null;
};

export enum HistoryFamilyMember {
	FATHER = 'FATHER',
	MOTHER = 'MOTHER',
	BROTHERS = 'BROTHERS',
	GRANDPARENTS = 'GRANDPARENTS',
	GREATGRANDPARENTS = 'GREATGRANDPARENTS',
	SONS = 'SONS',
}

export type ModelClientFamilyHistoryConditionInput = {
	clientID?: ModelIDInput | null;
	familyMember?: ModelHistoryFamilyMemberInput | null;
	mellitusDiabetes?: ModelBooleanInput | null;
	mellitusDiabetesNote?: ModelStringInput | null;
	arterialHypertension?: ModelBooleanInput | null;
	arterialHypertensionNote?: ModelStringInput | null;
	heartDisease?: ModelBooleanInput | null;
	heartDiseaseNote?: ModelStringInput | null;
	nephropathy?: ModelBooleanInput | null;
	nephropathyNote?: ModelStringInput | null;
	neuropathies?: ModelBooleanInput | null;
	neuropathiesNote?: ModelStringInput | null;
	epilepsy?: ModelBooleanInput | null;
	epilepsyNote?: ModelStringInput | null;
	rheumatoidArthritis?: ModelBooleanInput | null;
	rheumatoidArthritisNote?: ModelStringInput | null;
	psychiatricDiseases?: ModelBooleanInput | null;
	psychiatricDiseasesNote?: ModelStringInput | null;
	cancer?: ModelBooleanInput | null;
	cancerNote?: ModelStringInput | null;
	other?: ModelBooleanInput | null;
	otherNote?: ModelStringInput | null;
	updatedAt?: ModelStringInput | null;
	createdAt?: ModelStringInput | null;
	and?: Array<ModelClientFamilyHistoryConditionInput | null> | null;
	or?: Array<ModelClientFamilyHistoryConditionInput | null> | null;
	not?: ModelClientFamilyHistoryConditionInput | null;
};

export type ModelHistoryFamilyMemberInput = {
	eq?: HistoryFamilyMember | null;
	ne?: HistoryFamilyMember | null;
};

export type ClientFamilyHistory = {
	__typename: 'ClientFamilyHistory';
	id: string;
	clientID: string;
	familyMember: HistoryFamilyMember;
	mellitusDiabetes?: boolean | null;
	mellitusDiabetesNote?: string | null;
	arterialHypertension?: boolean | null;
	arterialHypertensionNote?: string | null;
	heartDisease?: boolean | null;
	heartDiseaseNote?: string | null;
	nephropathy?: boolean | null;
	nephropathyNote?: string | null;
	neuropathies?: boolean | null;
	neuropathiesNote?: string | null;
	epilepsy?: boolean | null;
	epilepsyNote?: string | null;
	rheumatoidArthritis?: boolean | null;
	rheumatoidArthritisNote?: string | null;
	psychiatricDiseases?: boolean | null;
	psychiatricDiseasesNote?: string | null;
	cancer?: boolean | null;
	cancerNote?: string | null;
	other?: boolean | null;
	otherNote?: string | null;
	updatedAt?: string | null;
	createdAt?: string | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
};

export type UpdateClientFamilyHistoryInput = {
	id: string;
	clientID?: string | null;
	familyMember?: HistoryFamilyMember | null;
	mellitusDiabetes?: boolean | null;
	mellitusDiabetesNote?: string | null;
	arterialHypertension?: boolean | null;
	arterialHypertensionNote?: string | null;
	heartDisease?: boolean | null;
	heartDiseaseNote?: string | null;
	nephropathy?: boolean | null;
	nephropathyNote?: string | null;
	neuropathies?: boolean | null;
	neuropathiesNote?: string | null;
	epilepsy?: boolean | null;
	epilepsyNote?: string | null;
	rheumatoidArthritis?: boolean | null;
	rheumatoidArthritisNote?: string | null;
	psychiatricDiseases?: boolean | null;
	psychiatricDiseasesNote?: string | null;
	cancer?: boolean | null;
	cancerNote?: string | null;
	other?: boolean | null;
	otherNote?: string | null;
	updatedAt?: string | null;
	createdAt?: string | null;
	_version?: number | null;
};

export type DeleteClientFamilyHistoryInput = {
	id: string;
	_version?: number | null;
};

export type CreateClientLifeStyleInput = {
	id?: string | null;
	clientID: string;
	lifeStyle: LifeStyle;
	name: string;
	description: string;
	updatedAt?: string | null;
	createdAt?: string | null;
	_version?: number | null;
};

export enum LifeStyle {
	ALCOHOL = 'ALCOHOL',
	TABACO = 'TABACO',
	ACTIVITY = 'ACTIVITY',
	DRUG = 'DRUG',
}

export type ModelClientLifeStyleConditionInput = {
	clientID?: ModelIDInput | null;
	lifeStyle?: ModelLifeStyleInput | null;
	name?: ModelStringInput | null;
	description?: ModelStringInput | null;
	updatedAt?: ModelStringInput | null;
	createdAt?: ModelStringInput | null;
	and?: Array<ModelClientLifeStyleConditionInput | null> | null;
	or?: Array<ModelClientLifeStyleConditionInput | null> | null;
	not?: ModelClientLifeStyleConditionInput | null;
};

export type ModelLifeStyleInput = {
	eq?: LifeStyle | null;
	ne?: LifeStyle | null;
};

export type ClientLifeStyle = {
	__typename: 'ClientLifeStyle';
	id: string;
	clientID: string;
	lifeStyle: LifeStyle;
	name: string;
	description: string;
	updatedAt: string;
	createdAt: string;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
};

export type UpdateClientLifeStyleInput = {
	id: string;
	clientID?: string | null;
	lifeStyle?: LifeStyle | null;
	name?: string | null;
	description?: string | null;
	updatedAt?: string | null;
	createdAt?: string | null;
	_version?: number | null;
};

export type DeleteClientLifeStyleInput = {
	id: string;
	_version?: number | null;
};

export type CreateClientPrescriptionInput = {
	id?: string | null;
	clientHospitalDoctorID: string;
	creationDate?: string | null;
	type: PrescriptionTypes;
	status?: Status | null;
	_version?: number | null;
};

export enum PrescriptionTypes {
	MEDICINE = 'MEDICINE',
	LABIMAGE = 'LABIMAGE',
	FREE_PRESCRIPTION = 'FREE_PRESCRIPTION',
}

export type ModelClientPrescriptionConditionInput = {
	clientHospitalDoctorID?: ModelIDInput | null;
	creationDate?: ModelStringInput | null;
	type?: ModelPrescriptionTypesInput | null;
	status?: ModelStatusInput | null;
	and?: Array<ModelClientPrescriptionConditionInput | null> | null;
	or?: Array<ModelClientPrescriptionConditionInput | null> | null;
	not?: ModelClientPrescriptionConditionInput | null;
};

export type ModelPrescriptionTypesInput = {
	eq?: PrescriptionTypes | null;
	ne?: PrescriptionTypes | null;
};

export type ClientPrescription = {
	__typename: 'ClientPrescription';
	id: string;
	clientHospitalDoctorID: string;
	creationDate?: string | null;
	type: PrescriptionTypes;
	status?: Status | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
};

export type UpdateClientPrescriptionInput = {
	id: string;
	clientHospitalDoctorID?: string | null;
	creationDate?: string | null;
	type?: PrescriptionTypes | null;
	status?: Status | null;
	_version?: number | null;
};

export type DeleteClientPrescriptionInput = {
	id: string;
	_version?: number | null;
};

export type CreateClientPrescriptionMedicineInput = {
	id?: string | null;
	clientPrescriptionID: string;
	medicineID?: string | null;
	name?: string | null;
	take?: string | null;
	indication?: string | null;
	duration?: string | null;
	description?: string | null;
	status?: Status | null;
	_version?: number | null;
};

export type ModelClientPrescriptionMedicineConditionInput = {
	clientPrescriptionID?: ModelIDInput | null;
	medicineID?: ModelStringInput | null;
	name?: ModelStringInput | null;
	take?: ModelStringInput | null;
	indication?: ModelStringInput | null;
	duration?: ModelStringInput | null;
	description?: ModelStringInput | null;
	status?: ModelStatusInput | null;
	and?: Array<ModelClientPrescriptionMedicineConditionInput | null> | null;
	or?: Array<ModelClientPrescriptionMedicineConditionInput | null> | null;
	not?: ModelClientPrescriptionMedicineConditionInput | null;
};

export type ClientPrescriptionMedicine = {
	__typename: 'ClientPrescriptionMedicine';
	id: string;
	clientPrescriptionID: string;
	medicineID?: string | null;
	name?: string | null;
	take?: string | null;
	indication?: string | null;
	duration?: string | null;
	description?: string | null;
	status?: Status | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
};

export type UpdateClientPrescriptionMedicineInput = {
	id: string;
	clientPrescriptionID?: string | null;
	medicineID?: string | null;
	name?: string | null;
	take?: string | null;
	indication?: string | null;
	duration?: string | null;
	description?: string | null;
	status?: Status | null;
	_version?: number | null;
};

export type DeleteClientPrescriptionMedicineInput = {
	id: string;
	_version?: number | null;
};

export type CreateClientFreePrescriptionInput = {
	id?: string | null;
	clientPrescriptionID: string;
	description?: string | null;
	status?: Status | null;
	_version?: number | null;
};

export type ModelClientFreePrescriptionConditionInput = {
	clientPrescriptionID?: ModelIDInput | null;
	description?: ModelStringInput | null;
	status?: ModelStatusInput | null;
	and?: Array<ModelClientFreePrescriptionConditionInput | null> | null;
	or?: Array<ModelClientFreePrescriptionConditionInput | null> | null;
	not?: ModelClientFreePrescriptionConditionInput | null;
};

export type ClientFreePrescription = {
	__typename: 'ClientFreePrescription';
	id: string;
	clientPrescriptionID: string;
	description?: string | null;
	status?: Status | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
};

export type UpdateClientFreePrescriptionInput = {
	id: string;
	clientPrescriptionID?: string | null;
	description?: string | null;
	status?: Status | null;
	_version?: number | null;
};

export type DeleteClientFreePrescriptionInput = {
	id: string;
	_version?: number | null;
};

export type CreateClientPrescriptionAnalisisInput = {
	id?: string | null;
	clientPrescriptionID: string;
	name?: string | null;
	description?: string | null;
	status?: Status | null;
	_version?: number | null;
};

export type ModelClientPrescriptionAnalisisConditionInput = {
	clientPrescriptionID?: ModelIDInput | null;
	name?: ModelStringInput | null;
	description?: ModelStringInput | null;
	status?: ModelStatusInput | null;
	and?: Array<ModelClientPrescriptionAnalisisConditionInput | null> | null;
	or?: Array<ModelClientPrescriptionAnalisisConditionInput | null> | null;
	not?: ModelClientPrescriptionAnalisisConditionInput | null;
};

export type ClientPrescriptionAnalisis = {
	__typename: 'ClientPrescriptionAnalisis';
	id: string;
	clientPrescriptionID: string;
	name?: string | null;
	description?: string | null;
	status?: Status | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
};

export type UpdateClientPrescriptionAnalisisInput = {
	id: string;
	clientPrescriptionID?: string | null;
	name?: string | null;
	description?: string | null;
	status?: Status | null;
	_version?: number | null;
};

export type DeleteClientPrescriptionAnalisisInput = {
	id: string;
	_version?: number | null;
};

export type CreateDoctorLabImageInput = {
	id?: string | null;
	doctorID: string;
	labImageID: string;
	updatedAt?: string | null;
	status?: Status | null;
	_version?: number | null;
};

export type ModelDoctorLabImageConditionInput = {
	doctorID?: ModelIDInput | null;
	labImageID?: ModelIDInput | null;
	updatedAt?: ModelStringInput | null;
	status?: ModelStatusInput | null;
	and?: Array<ModelDoctorLabImageConditionInput | null> | null;
	or?: Array<ModelDoctorLabImageConditionInput | null> | null;
	not?: ModelDoctorLabImageConditionInput | null;
};

export type DoctorLabImage = {
	__typename: 'DoctorLabImage';
	id: string;
	doctorID: string;
	labImageID: string;
	updatedAt?: string | null;
	status?: Status | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	labImage?: LabImage | null;
};

export type LabImage = {
	__typename: 'LabImage';
	id: string;
	name: string;
	description?: string | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
};

export type UpdateDoctorLabImageInput = {
	id: string;
	doctorID?: string | null;
	labImageID?: string | null;
	updatedAt?: string | null;
	status?: Status | null;
	_version?: number | null;
};

export type DeleteDoctorLabImageInput = {
	id: string;
	_version?: number | null;
};

export type CreateLabImageInput = {
	id?: string | null;
	name: string;
	description?: string | null;
	_version?: number | null;
};

export type ModelLabImageConditionInput = {
	name?: ModelStringInput | null;
	description?: ModelStringInput | null;
	and?: Array<ModelLabImageConditionInput | null> | null;
	or?: Array<ModelLabImageConditionInput | null> | null;
	not?: ModelLabImageConditionInput | null;
};

export type UpdateLabImageInput = {
	id: string;
	name?: string | null;
	description?: string | null;
	_version?: number | null;
};

export type DeleteLabImageInput = {
	id: string;
	_version?: number | null;
};

export type CreatePharmacyMedicineInput = {
	id?: string | null;
	name?: string | null;
	_version?: number | null;
};

export type ModelPharmacyMedicineConditionInput = {
	name?: ModelStringInput | null;
	and?: Array<ModelPharmacyMedicineConditionInput | null> | null;
	or?: Array<ModelPharmacyMedicineConditionInput | null> | null;
	not?: ModelPharmacyMedicineConditionInput | null;
};

export type PharmacyMedicine = {
	__typename: 'PharmacyMedicine';
	id: string;
	name?: string | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
};

export type UpdatePharmacyMedicineInput = {
	id: string;
	name?: string | null;
	_version?: number | null;
};

export type DeletePharmacyMedicineInput = {
	id: string;
	_version?: number | null;
};

export type CreatePharmacyMedicineMeasureInput = {
	id?: string | null;
	pharmacyMedicineID: string;
	_version?: number | null;
};

export type ModelPharmacyMedicineMeasureConditionInput = {
	pharmacyMedicineID?: ModelIDInput | null;
	and?: Array<ModelPharmacyMedicineMeasureConditionInput | null> | null;
	or?: Array<ModelPharmacyMedicineMeasureConditionInput | null> | null;
	not?: ModelPharmacyMedicineMeasureConditionInput | null;
};

export type PharmacyMedicineMeasure = {
	__typename: 'PharmacyMedicineMeasure';
	id: string;
	pharmacyMedicineID: string;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
};

export type UpdatePharmacyMedicineMeasureInput = {
	id: string;
	pharmacyMedicineID?: string | null;
	_version?: number | null;
};

export type DeletePharmacyMedicineMeasureInput = {
	id: string;
	_version?: number | null;
};

export type CreateTemplateInput = {
	id?: string | null;
	doctorID: string;
	templateName?: string | null;
	templateType: PrescriptionTypes;
	_version?: number | null;
};

export type ModelTemplateConditionInput = {
	doctorID?: ModelIDInput | null;
	templateName?: ModelStringInput | null;
	templateType?: ModelPrescriptionTypesInput | null;
	and?: Array<ModelTemplateConditionInput | null> | null;
	or?: Array<ModelTemplateConditionInput | null> | null;
	not?: ModelTemplateConditionInput | null;
};

export type Template = {
	__typename: 'Template';
	id: string;
	doctorID: string;
	templateName?: string | null;
	templateType: PrescriptionTypes;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
};

export type UpdateTemplateInput = {
	id: string;
	doctorID?: string | null;
	templateName?: string | null;
	templateType?: PrescriptionTypes | null;
	_version?: number | null;
};

export type DeleteTemplateInput = {
	id: string;
	_version?: number | null;
};

export type CreateTemplateMedicineInput = {
	id?: string | null;
	templateID: string;
	medicineID: string;
	take?: string | null;
	indication?: string | null;
	duration?: string | null;
	description?: string | null;
	_version?: number | null;
};

export type ModelTemplateMedicineConditionInput = {
	templateID?: ModelIDInput | null;
	medicineID?: ModelIDInput | null;
	take?: ModelStringInput | null;
	indication?: ModelStringInput | null;
	duration?: ModelStringInput | null;
	description?: ModelStringInput | null;
	and?: Array<ModelTemplateMedicineConditionInput | null> | null;
	or?: Array<ModelTemplateMedicineConditionInput | null> | null;
	not?: ModelTemplateMedicineConditionInput | null;
};

export type TemplateMedicine = {
	__typename: 'TemplateMedicine';
	id: string;
	templateID: string;
	medicineID: string;
	take?: string | null;
	indication?: string | null;
	duration?: string | null;
	description?: string | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
};

export type UpdateTemplateMedicineInput = {
	id: string;
	templateID?: string | null;
	medicineID?: string | null;
	take?: string | null;
	indication?: string | null;
	duration?: string | null;
	description?: string | null;
	_version?: number | null;
};

export type DeleteTemplateMedicineInput = {
	id: string;
	_version?: number | null;
};

export type CreateDoctorRecentDiagnosisInput = {
	id?: string | null;
	doctorID: string;
	name: string;
	code?: string | null;
	type?: DiagnosticTypes | null;
	updateDateTime?: string | null;
	createdAt?: string | null;
	updatedAt?: string | null;
	_version?: number | null;
};

export type ModelDoctorRecentDiagnosisConditionInput = {
	doctorID?: ModelIDInput | null;
	name?: ModelStringInput | null;
	code?: ModelStringInput | null;
	type?: ModelDiagnosticTypesInput | null;
	updateDateTime?: ModelStringInput | null;
	createdAt?: ModelStringInput | null;
	updatedAt?: ModelStringInput | null;
	and?: Array<ModelDoctorRecentDiagnosisConditionInput | null> | null;
	or?: Array<ModelDoctorRecentDiagnosisConditionInput | null> | null;
	not?: ModelDoctorRecentDiagnosisConditionInput | null;
};

export type DoctorRecentDiagnosis = {
	__typename: 'DoctorRecentDiagnosis';
	id: string;
	doctorID: string;
	name: string;
	code?: string | null;
	type?: DiagnosticTypes | null;
	updateDateTime?: string | null;
	createdAt?: string | null;
	updatedAt?: string | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
};

export type UpdateDoctorRecentDiagnosisInput = {
	id: string;
	doctorID?: string | null;
	name?: string | null;
	code?: string | null;
	type?: DiagnosticTypes | null;
	updateDateTime?: string | null;
	createdAt?: string | null;
	updatedAt?: string | null;
	_version?: number | null;
};

export type DeleteDoctorRecentDiagnosisInput = {
	id: string;
	_version?: number | null;
};

export type CreateTemplateLabImageInput = {
	id?: string | null;
	templateID: string;
	name?: string | null;
	description?: string | null;
	_version?: number | null;
};

export type ModelTemplateLabImageConditionInput = {
	templateID?: ModelIDInput | null;
	name?: ModelStringInput | null;
	description?: ModelStringInput | null;
	and?: Array<ModelTemplateLabImageConditionInput | null> | null;
	or?: Array<ModelTemplateLabImageConditionInput | null> | null;
	not?: ModelTemplateLabImageConditionInput | null;
};

export type TemplateLabImage = {
	__typename: 'TemplateLabImage';
	id: string;
	templateID: string;
	name?: string | null;
	description?: string | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
};

export type UpdateTemplateLabImageInput = {
	id: string;
	templateID?: string | null;
	name?: string | null;
	description?: string | null;
	_version?: number | null;
};

export type DeleteTemplateLabImageInput = {
	id: string;
	_version?: number | null;
};

export type CreateDoctorMedicineInput = {
	id?: string | null;
	doctorID: string;
	medicineID: string;
	isDeleted?: boolean | null;
	updatedAt?: string | null;
	_version?: number | null;
};

export type ModelDoctorMedicineConditionInput = {
	doctorID?: ModelIDInput | null;
	medicineID?: ModelIDInput | null;
	isDeleted?: ModelBooleanInput | null;
	updatedAt?: ModelStringInput | null;
	and?: Array<ModelDoctorMedicineConditionInput | null> | null;
	or?: Array<ModelDoctorMedicineConditionInput | null> | null;
	not?: ModelDoctorMedicineConditionInput | null;
};

export type DoctorMedicine = {
	__typename: 'DoctorMedicine';
	id: string;
	doctorID: string;
	medicineID: string;
	isDeleted?: boolean | null;
	updatedAt?: string | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	medicine?: Medicine | null;
};

export type UpdateDoctorMedicineInput = {
	id: string;
	doctorID?: string | null;
	medicineID?: string | null;
	isDeleted?: boolean | null;
	updatedAt?: string | null;
	_version?: number | null;
};

export type DeleteDoctorMedicineInput = {
	id: string;
	_version?: number | null;
};

export type CreateRecentDoctorMedicinePrescriptionInput = {
	id?: string | null;
	doctorID: string;
	medicineID: string;
	take?: string | null;
	indication?: string | null;
	duration?: string | null;
	description?: string | null;
	updateDateTime?: string | null;
	createdAt?: string | null;
	updatedAt?: string | null;
	status?: Status | null;
	_version?: number | null;
};

export type ModelRecentDoctorMedicinePrescriptionConditionInput = {
	doctorID?: ModelIDInput | null;
	medicineID?: ModelIDInput | null;
	take?: ModelStringInput | null;
	indication?: ModelStringInput | null;
	duration?: ModelStringInput | null;
	description?: ModelStringInput | null;
	updateDateTime?: ModelStringInput | null;
	createdAt?: ModelStringInput | null;
	updatedAt?: ModelStringInput | null;
	status?: ModelStatusInput | null;
	and?: Array<ModelRecentDoctorMedicinePrescriptionConditionInput | null> | null;
	or?: Array<ModelRecentDoctorMedicinePrescriptionConditionInput | null> | null;
	not?: ModelRecentDoctorMedicinePrescriptionConditionInput | null;
};

export type RecentDoctorMedicinePrescription = {
	__typename: 'RecentDoctorMedicinePrescription';
	id: string;
	doctorID: string;
	medicineID: string;
	take?: string | null;
	indication?: string | null;
	duration?: string | null;
	description?: string | null;
	updateDateTime?: string | null;
	createdAt?: string | null;
	updatedAt?: string | null;
	status?: Status | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	medicine?: Medicine | null;
};

export type UpdateRecentDoctorMedicinePrescriptionInput = {
	id: string;
	doctorID?: string | null;
	medicineID?: string | null;
	take?: string | null;
	indication?: string | null;
	duration?: string | null;
	description?: string | null;
	updateDateTime?: string | null;
	createdAt?: string | null;
	updatedAt?: string | null;
	status?: Status | null;
	_version?: number | null;
};

export type DeleteRecentDoctorMedicinePrescriptionInput = {
	id: string;
	_version?: number | null;
};

export type CreateRecentDoctorLabImagePrescriptionInput = {
	id?: string | null;
	doctorID: string;
	labImageID?: string | null;
	name?: string | null;
	description?: string | null;
	updateDateTime?: string | null;
	createdAt?: string | null;
	updatedAt?: string | null;
	status?: Status | null;
	_version?: number | null;
};

export type ModelRecentDoctorLabImagePrescriptionConditionInput = {
	doctorID?: ModelIDInput | null;
	labImageID?: ModelIDInput | null;
	name?: ModelStringInput | null;
	description?: ModelStringInput | null;
	updateDateTime?: ModelStringInput | null;
	createdAt?: ModelStringInput | null;
	updatedAt?: ModelStringInput | null;
	status?: ModelStatusInput | null;
	and?: Array<ModelRecentDoctorLabImagePrescriptionConditionInput | null> | null;
	or?: Array<ModelRecentDoctorLabImagePrescriptionConditionInput | null> | null;
	not?: ModelRecentDoctorLabImagePrescriptionConditionInput | null;
};

export type RecentDoctorLabImagePrescription = {
	__typename: 'RecentDoctorLabImagePrescription';
	id: string;
	doctorID: string;
	labImageID?: string | null;
	name?: string | null;
	description?: string | null;
	updateDateTime?: string | null;
	createdAt?: string | null;
	updatedAt?: string | null;
	status?: Status | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
};

export type UpdateRecentDoctorLabImagePrescriptionInput = {
	id: string;
	doctorID?: string | null;
	labImageID?: string | null;
	name?: string | null;
	description?: string | null;
	updateDateTime?: string | null;
	createdAt?: string | null;
	updatedAt?: string | null;
	status?: Status | null;
	_version?: number | null;
};

export type DeleteRecentDoctorLabImagePrescriptionInput = {
	id: string;
	_version?: number | null;
};

export type CreateTemplateAnalisisInput = {
	id?: string | null;
	templateID: string;
	analisisID: string;
	_version?: number | null;
};

export type ModelTemplateAnalisisConditionInput = {
	templateID?: ModelIDInput | null;
	analisisID?: ModelIDInput | null;
	and?: Array<ModelTemplateAnalisisConditionInput | null> | null;
	or?: Array<ModelTemplateAnalisisConditionInput | null> | null;
	not?: ModelTemplateAnalisisConditionInput | null;
};

export type TemplateAnalisis = {
	__typename: 'TemplateAnalisis';
	id: string;
	templateID: string;
	analisisID: string;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
};

export type UpdateTemplateAnalisisInput = {
	id: string;
	templateID?: string | null;
	analisisID?: string | null;
	_version?: number | null;
};

export type DeleteTemplateAnalisisInput = {
	id: string;
	_version?: number | null;
};

export type CreateAnalisisInput = {
	id?: string | null;
	analisisName?: string | null;
	_version?: number | null;
};

export type ModelAnalisisConditionInput = {
	analisisName?: ModelStringInput | null;
	and?: Array<ModelAnalisisConditionInput | null> | null;
	or?: Array<ModelAnalisisConditionInput | null> | null;
	not?: ModelAnalisisConditionInput | null;
};

export type Analisis = {
	__typename: 'Analisis';
	id: string;
	analisisName?: string | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
};

export type UpdateAnalisisInput = {
	id: string;
	analisisName?: string | null;
	_version?: number | null;
};

export type DeleteAnalisisInput = {
	id: string;
	_version?: number | null;
};

export type CreateClientActiveDiseaseInput = {
	id?: string | null;
	clientID: string;
	name: string;
	_version?: number | null;
};

export type ModelClientActiveDiseaseConditionInput = {
	clientID?: ModelIDInput | null;
	name?: ModelStringInput | null;
	and?: Array<ModelClientActiveDiseaseConditionInput | null> | null;
	or?: Array<ModelClientActiveDiseaseConditionInput | null> | null;
	not?: ModelClientActiveDiseaseConditionInput | null;
};

export type ClientActiveDisease = {
	__typename: 'ClientActiveDisease';
	id: string;
	clientID: string;
	name: string;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
};

export type UpdateClientActiveDiseaseInput = {
	id: string;
	clientID?: string | null;
	name?: string | null;
	_version?: number | null;
};

export type DeleteClientActiveDiseaseInput = {
	id: string;
	_version?: number | null;
};

export type CreateClientHistoryInput = {
	id?: string | null;
	clientHospitalDoctorID: string;
	title?: string | null;
	treatment?: string | null;
	endModifyDate?: string | null;
	authorizationNumber?: string | null;
	description?: string | null;
	authorizationDetail?: string | null;
	personalPathologicalHistory?: string | null;
	familyPathologicalHistory?: string | null;
	physicalTest?: string | null;
	createdDate?: string | null;
	insuranceID?: string | null;
	status?: Status | null;
	_version?: number | null;
};

export type ModelClientHistoryConditionInput = {
	clientHospitalDoctorID?: ModelIDInput | null;
	title?: ModelStringInput | null;
	treatment?: ModelStringInput | null;
	endModifyDate?: ModelStringInput | null;
	authorizationNumber?: ModelStringInput | null;
	description?: ModelStringInput | null;
	authorizationDetail?: ModelStringInput | null;
	personalPathologicalHistory?: ModelStringInput | null;
	familyPathologicalHistory?: ModelStringInput | null;
	physicalTest?: ModelStringInput | null;
	createdDate?: ModelStringInput | null;
	insuranceID?: ModelIDInput | null;
	status?: ModelStatusInput | null;
	and?: Array<ModelClientHistoryConditionInput | null> | null;
	or?: Array<ModelClientHistoryConditionInput | null> | null;
	not?: ModelClientHistoryConditionInput | null;
};

export type ClientHistory = {
	__typename: 'ClientHistory';
	id: string;
	clientHospitalDoctorID: string;
	title?: string | null;
	treatment?: string | null;
	endModifyDate?: string | null;
	authorizationNumber?: string | null;
	description?: string | null;
	authorizationDetail?: string | null;
	personalPathologicalHistory?: string | null;
	familyPathologicalHistory?: string | null;
	physicalTest?: string | null;
	createdDate?: string | null;
	insuranceID?: string | null;
	status?: Status | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
};

export type UpdateClientHistoryInput = {
	id: string;
	clientHospitalDoctorID?: string | null;
	title?: string | null;
	treatment?: string | null;
	endModifyDate?: string | null;
	authorizationNumber?: string | null;
	description?: string | null;
	authorizationDetail?: string | null;
	personalPathologicalHistory?: string | null;
	familyPathologicalHistory?: string | null;
	physicalTest?: string | null;
	createdDate?: string | null;
	insuranceID?: string | null;
	status?: Status | null;
	_version?: number | null;
};

export type DeleteClientHistoryInput = {
	id: string;
	_version?: number | null;
};

export type CreateClientNotesInput = {
	id?: string | null;
	clientHospitalDoctorID: string;
	title?: string | null;
	noteBody?: string | null;
	endModifyDate?: string | null;
	createdDate?: string | null;
	status?: Status | null;
	_version?: number | null;
};

export type ModelClientNotesConditionInput = {
	clientHospitalDoctorID?: ModelIDInput | null;
	title?: ModelStringInput | null;
	noteBody?: ModelStringInput | null;
	endModifyDate?: ModelStringInput | null;
	createdDate?: ModelStringInput | null;
	status?: ModelStatusInput | null;
	and?: Array<ModelClientNotesConditionInput | null> | null;
	or?: Array<ModelClientNotesConditionInput | null> | null;
	not?: ModelClientNotesConditionInput | null;
};

export type ClientNotes = {
	__typename: 'ClientNotes';
	id: string;
	clientHospitalDoctorID: string;
	title?: string | null;
	noteBody?: string | null;
	endModifyDate?: string | null;
	createdDate?: string | null;
	status?: Status | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
};

export type UpdateClientNotesInput = {
	id: string;
	clientHospitalDoctorID?: string | null;
	title?: string | null;
	noteBody?: string | null;
	endModifyDate?: string | null;
	createdDate?: string | null;
	status?: Status | null;
	_version?: number | null;
};

export type DeleteClientNotesInput = {
	id: string;
	_version?: number | null;
};

export type CreateClientProcedureSurgeryInput = {
	id?: string | null;
	clientHospitalDoctorID: string;
	title?: string | null;
	description?: string | null;
	endModifyDate?: string | null;
	createdDate?: string | null;
	status?: Status | null;
	_version?: number | null;
};

export type ModelClientProcedureSurgeryConditionInput = {
	clientHospitalDoctorID?: ModelIDInput | null;
	title?: ModelStringInput | null;
	description?: ModelStringInput | null;
	endModifyDate?: ModelStringInput | null;
	createdDate?: ModelStringInput | null;
	status?: ModelStatusInput | null;
	and?: Array<ModelClientProcedureSurgeryConditionInput | null> | null;
	or?: Array<ModelClientProcedureSurgeryConditionInput | null> | null;
	not?: ModelClientProcedureSurgeryConditionInput | null;
};

export type ClientProcedureSurgery = {
	__typename: 'ClientProcedureSurgery';
	id: string;
	clientHospitalDoctorID: string;
	title?: string | null;
	description?: string | null;
	endModifyDate?: string | null;
	createdDate?: string | null;
	status?: Status | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
};

export type UpdateClientProcedureSurgeryInput = {
	id: string;
	clientHospitalDoctorID?: string | null;
	title?: string | null;
	description?: string | null;
	endModifyDate?: string | null;
	createdDate?: string | null;
	status?: Status | null;
	_version?: number | null;
};

export type DeleteClientProcedureSurgeryInput = {
	id: string;
	_version?: number | null;
};

export type CreateDoctorInput = {
	id?: string | null;
	name?: string | null;
	specialty?: string | null;
	exequator?: string | null;
	email: string;
	firstTime?: boolean | null;
	studyPlace?: string | null;
	isBeenReview?: boolean | null;
	isFirstAddSecretary?: boolean | null;
	hasTrialOnCreation?: boolean | null;
	title?: string | null;
	phone?: string | null;
	code?: string | null;
	password?: string | null;
	isAdmin?: boolean | null;
	daysOfTrial?: number | null;
	_version?: number | null;
};

export type ModelDoctorConditionInput = {
	name?: ModelStringInput | null;
	specialty?: ModelStringInput | null;
	exequator?: ModelStringInput | null;
	email?: ModelStringInput | null;
	firstTime?: ModelBooleanInput | null;
	studyPlace?: ModelStringInput | null;
	isBeenReview?: ModelBooleanInput | null;
	isFirstAddSecretary?: ModelBooleanInput | null;
	hasTrialOnCreation?: ModelBooleanInput | null;
	title?: ModelStringInput | null;
	phone?: ModelStringInput | null;
	code?: ModelStringInput | null;
	password?: ModelStringInput | null;
	isAdmin?: ModelBooleanInput | null;
	daysOfTrial?: ModelIntInput | null;
	and?: Array<ModelDoctorConditionInput | null> | null;
	or?: Array<ModelDoctorConditionInput | null> | null;
	not?: ModelDoctorConditionInput | null;
};

export type ModelIntInput = {
	ne?: number | null;
	eq?: number | null;
	le?: number | null;
	lt?: number | null;
	ge?: number | null;
	gt?: number | null;
	between?: Array<number | null> | null;
	attributeExists?: boolean | null;
	attributeType?: ModelAttributeTypes | null;
};

export type UpdateDoctorInput = {
	id: string;
	name?: string | null;
	specialty?: string | null;
	exequator?: string | null;
	email?: string | null;
	firstTime?: boolean | null;
	studyPlace?: string | null;
	isBeenReview?: boolean | null;
	isFirstAddSecretary?: boolean | null;
	hasTrialOnCreation?: boolean | null;
	title?: string | null;
	phone?: string | null;
	code?: string | null;
	password?: string | null;
	isAdmin?: boolean | null;
	daysOfTrial?: number | null;
	_version?: number | null;
};

export type DeleteDoctorInput = {
	id: string;
	_version?: number | null;
};

export type CreatePlanInput = {
	id?: string | null;
	refID?: number | null;
	title?: string | null;
	subTitle?: string | null;
	stripePlanKey?: string | null;
	_version?: number | null;
};

export type ModelPlanConditionInput = {
	refID?: ModelIntInput | null;
	title?: ModelStringInput | null;
	subTitle?: ModelStringInput | null;
	stripePlanKey?: ModelStringInput | null;
	and?: Array<ModelPlanConditionInput | null> | null;
	or?: Array<ModelPlanConditionInput | null> | null;
	not?: ModelPlanConditionInput | null;
};

export type Plan = {
	__typename: 'Plan';
	id: string;
	refID?: number | null;
	title?: string | null;
	subTitle?: string | null;
	stripePlanKey?: string | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
};

export type UpdatePlanInput = {
	id: string;
	refID?: number | null;
	title?: string | null;
	subTitle?: string | null;
	stripePlanKey?: string | null;
	_version?: number | null;
};

export type DeletePlanInput = {
	id: string;
	_version?: number | null;
};

export type CreateDoctorStripeCustomerSubscriptionInput = {
	id?: string | null;
	doctorID: string;
	currentPlanID?: number | null;
	stripeCustomerID?: string | null;
	stripeSubscriptionID?: string | null;
	defaultCard?: string | null;
	nextPaymentDate?: string | null;
	cancelAtPeriodEnd?: boolean | null;
	trialsEndsAt?: string | null;
	_version?: number | null;
};

export type ModelDoctorStripeCustomerSubscriptionConditionInput = {
	doctorID?: ModelIDInput | null;
	currentPlanID?: ModelIntInput | null;
	stripeCustomerID?: ModelStringInput | null;
	stripeSubscriptionID?: ModelStringInput | null;
	defaultCard?: ModelIDInput | null;
	nextPaymentDate?: ModelStringInput | null;
	cancelAtPeriodEnd?: ModelBooleanInput | null;
	trialsEndsAt?: ModelStringInput | null;
	and?: Array<ModelDoctorStripeCustomerSubscriptionConditionInput | null> | null;
	or?: Array<ModelDoctorStripeCustomerSubscriptionConditionInput | null> | null;
	not?: ModelDoctorStripeCustomerSubscriptionConditionInput | null;
};

export type DoctorStripeCustomerSubscription = {
	__typename: 'DoctorStripeCustomerSubscription';
	id: string;
	doctorID: string;
	currentPlanID?: number | null;
	stripeCustomerID?: string | null;
	stripeSubscriptionID?: string | null;
	defaultCard?: string | null;
	nextPaymentDate?: string | null;
	cancelAtPeriodEnd?: boolean | null;
	trialsEndsAt?: string | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
};

export type UpdateDoctorStripeCustomerSubscriptionInput = {
	id: string;
	doctorID?: string | null;
	currentPlanID?: number | null;
	stripeCustomerID?: string | null;
	stripeSubscriptionID?: string | null;
	defaultCard?: string | null;
	nextPaymentDate?: string | null;
	cancelAtPeriodEnd?: boolean | null;
	trialsEndsAt?: string | null;
	_version?: number | null;
};

export type DeleteDoctorStripeCustomerSubscriptionInput = {
	id: string;
	_version?: number | null;
};

export type CreateDoctorStripeCardsInput = {
	id?: string | null;
	DoctorID: string;
	cardLastDigits?: string | null;
	stripePaymentMethodID?: string | null;
	defaultCard?: boolean | null;
	cardBrand?: string | null;
	status?: Status | null;
	_version?: number | null;
};

export type ModelDoctorStripeCardsConditionInput = {
	DoctorID?: ModelIDInput | null;
	cardLastDigits?: ModelStringInput | null;
	stripePaymentMethodID?: ModelStringInput | null;
	defaultCard?: ModelBooleanInput | null;
	cardBrand?: ModelStringInput | null;
	status?: ModelStatusInput | null;
	and?: Array<ModelDoctorStripeCardsConditionInput | null> | null;
	or?: Array<ModelDoctorStripeCardsConditionInput | null> | null;
	not?: ModelDoctorStripeCardsConditionInput | null;
};

export type DoctorStripeCards = {
	__typename: 'DoctorStripeCards';
	id: string;
	DoctorID: string;
	cardLastDigits?: string | null;
	stripePaymentMethodID?: string | null;
	defaultCard?: boolean | null;
	cardBrand?: string | null;
	status?: Status | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
};

export type UpdateDoctorStripeCardsInput = {
	id: string;
	DoctorID?: string | null;
	cardLastDigits?: string | null;
	stripePaymentMethodID?: string | null;
	defaultCard?: boolean | null;
	cardBrand?: string | null;
	status?: Status | null;
	_version?: number | null;
};

export type DeleteDoctorStripeCardsInput = {
	id: string;
	_version?: number | null;
};

export type CreateHospitalInput = {
	id?: string | null;
	name: string;
	type?: string | null;
	province?: string | null;
	town?: string | null;
	sector?: string | null;
	phone?: string | null;
	address?: string | null;
	pssCode?: string | null;
	rnc?: string | null;
	_version?: number | null;
};

export type ModelHospitalConditionInput = {
	name?: ModelStringInput | null;
	type?: ModelStringInput | null;
	province?: ModelStringInput | null;
	town?: ModelStringInput | null;
	sector?: ModelStringInput | null;
	phone?: ModelStringInput | null;
	address?: ModelStringInput | null;
	pssCode?: ModelStringInput | null;
	rnc?: ModelStringInput | null;
	and?: Array<ModelHospitalConditionInput | null> | null;
	or?: Array<ModelHospitalConditionInput | null> | null;
	not?: ModelHospitalConditionInput | null;
};

export type UpdateHospitalInput = {
	id: string;
	name?: string | null;
	type?: string | null;
	province?: string | null;
	town?: string | null;
	sector?: string | null;
	phone?: string | null;
	address?: string | null;
	pssCode?: string | null;
	rnc?: string | null;
	_version?: number | null;
};

export type DeleteHospitalInput = {
	id: string;
	_version?: number | null;
};

export type CreateHospitalDoctorInput = {
	id?: string | null;
	doctorID: string;
	hospitalID: string;
	lastWaitingListID: string;
	hospitalLogo?: string | null;
	_version?: number | null;
};

export type ModelHospitalDoctorConditionInput = {
	doctorID?: ModelIDInput | null;
	hospitalID?: ModelIDInput | null;
	lastWaitingListID?: ModelIDInput | null;
	hospitalLogo?: ModelStringInput | null;
	and?: Array<ModelHospitalDoctorConditionInput | null> | null;
	or?: Array<ModelHospitalDoctorConditionInput | null> | null;
	not?: ModelHospitalDoctorConditionInput | null;
};

export type UpdateHospitalDoctorInput = {
	id: string;
	doctorID?: string | null;
	hospitalID?: string | null;
	lastWaitingListID?: string | null;
	hospitalLogo?: string | null;
	_version?: number | null;
};

export type DeleteHospitalDoctorInput = {
	id: string;
	_version?: number | null;
};

export type CreateTeamsInput = {
	id?: string | null;
	OwnerID: string;
	MemberID: string;
	status?: TeamStatus | null;
	_version?: number | null;
};

export enum TeamStatus {
	ACTIVE = 'ACTIVE',
	INACTIVE = 'INACTIVE',
	PENDING = 'PENDING',
}

export type ModelTeamsConditionInput = {
	OwnerID?: ModelIDInput | null;
	MemberID?: ModelIDInput | null;
	status?: ModelTeamStatusInput | null;
	and?: Array<ModelTeamsConditionInput | null> | null;
	or?: Array<ModelTeamsConditionInput | null> | null;
	not?: ModelTeamsConditionInput | null;
};

export type ModelTeamStatusInput = {
	eq?: TeamStatus | null;
	ne?: TeamStatus | null;
};

export type Teams = {
	__typename: 'Teams';
	id: string;
	OwnerID: string;
	MemberID: string;
	status?: TeamStatus | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
	Owner?: HospitalDoctor | null;
	Member?: HospitalDoctor | null;
};

export type UpdateTeamsInput = {
	id: string;
	OwnerID?: string | null;
	MemberID?: string | null;
	status?: TeamStatus | null;
	_version?: number | null;
};

export type DeleteTeamsInput = {
	id: string;
	_version?: number | null;
};

export type CreateHospitalDoctorClienteInput = {
	id?: string | null;
	clientID: string;
	hospitalDoctorID: string;
	lastHealthInsurranceID?: string | null;
	clientStatus?: string | null;
	note?: string | null;
	recordNo?: string | null;
	_version?: number | null;
};

export type ModelHospitalDoctorClienteConditionInput = {
	clientID?: ModelIDInput | null;
	hospitalDoctorID?: ModelIDInput | null;
	lastHealthInsurranceID?: ModelIDInput | null;
	clientStatus?: ModelStringInput | null;
	note?: ModelStringInput | null;
	recordNo?: ModelStringInput | null;
	and?: Array<ModelHospitalDoctorClienteConditionInput | null> | null;
	or?: Array<ModelHospitalDoctorClienteConditionInput | null> | null;
	not?: ModelHospitalDoctorClienteConditionInput | null;
};

export type HospitalDoctorCliente = {
	__typename: 'HospitalDoctorCliente';
	id: string;
	clientID: string;
	hospitalDoctorID: string;
	lastHealthInsurranceID?: string | null;
	clientStatus?: string | null;
	note?: string | null;
	recordNo?: string | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
	client?: Client | null;
	hospitalDoctor?: HospitalDoctor | null;
	clientInsurance?: ClientInsurance | null;
};

export type ClientInsurance = {
	__typename: 'ClientInsurance';
	id: string;
	insuranceID?: string | null;
	clientID?: string | null;
	contractNumber?: string | null;
	affiliateNumber?: string | null;
	affiliateType?: AffiliateTypes | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
	insurance?: Insurance | null;
};

export enum AffiliateTypes {
	PRINCIPAL = 'PRINCIPAL',
	TITULAR = 'TITULAR',
	DEPENDIENTE = 'DEPENDIENTE',
	PARENTESCO = 'PARENTESCO',
}

export type Insurance = {
	__typename: 'Insurance';
	id: string;
	name: string;
	allowClaim?: boolean | null;
	code?: string | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
};

export type UpdateHospitalDoctorClienteInput = {
	id: string;
	clientID?: string | null;
	hospitalDoctorID?: string | null;
	lastHealthInsurranceID?: string | null;
	clientStatus?: string | null;
	note?: string | null;
	recordNo?: string | null;
	_version?: number | null;
};

export type DeleteHospitalDoctorClienteInput = {
	id: string;
	_version?: number | null;
};

export type CreateWaitListInput = {
	id?: string | null;
	hospitalDoctorID: string;
	createdAt?: string | null;
	_version?: number | null;
};

export type ModelWaitListConditionInput = {
	hospitalDoctorID?: ModelIDInput | null;
	createdAt?: ModelStringInput | null;
	and?: Array<ModelWaitListConditionInput | null> | null;
	or?: Array<ModelWaitListConditionInput | null> | null;
	not?: ModelWaitListConditionInput | null;
};

export type WaitList = {
	__typename: 'WaitList';
	id: string;
	hospitalDoctorID: string;
	createdAt?: string | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	updatedAt: string;
};

export type UpdateWaitListInput = {
	id: string;
	hospitalDoctorID?: string | null;
	createdAt?: string | null;
	_version?: number | null;
};

export type DeleteWaitListInput = {
	id: string;
	_version?: number | null;
};

export type CreateWaitingListItemInput = {
	id?: string | null;
	waitingListID: string;
	clientID: string;
	status: WaitingListItemStatus;
	clientHealthInsurrance?: string | null;
	positionNumber: number;
	_version?: number | null;
};

export enum WaitingListItemStatus {
	CONSULTA = 'CONSULTA',
	ESPERA = 'ESPERA',
	TERMINADA = 'TERMINADA',
}

export type ModelWaitingListItemConditionInput = {
	waitingListID?: ModelIDInput | null;
	clientID?: ModelIDInput | null;
	status?: ModelWaitingListItemStatusInput | null;
	clientHealthInsurrance?: ModelStringInput | null;
	positionNumber?: ModelIntInput | null;
	and?: Array<ModelWaitingListItemConditionInput | null> | null;
	or?: Array<ModelWaitingListItemConditionInput | null> | null;
	not?: ModelWaitingListItemConditionInput | null;
};

export type ModelWaitingListItemStatusInput = {
	eq?: WaitingListItemStatus | null;
	ne?: WaitingListItemStatus | null;
};

export type WaitingListItem = {
	__typename: 'WaitingListItem';
	id: string;
	waitingListID: string;
	clientID: string;
	status: WaitingListItemStatus;
	clientHealthInsurrance?: string | null;
	positionNumber: number;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
};

export type UpdateWaitingListItemInput = {
	id: string;
	waitingListID?: string | null;
	clientID?: string | null;
	status?: WaitingListItemStatus | null;
	clientHealthInsurrance?: string | null;
	positionNumber?: number | null;
	_version?: number | null;
};

export type DeleteWaitingListItemInput = {
	id: string;
	_version?: number | null;
};

export type CreateClientInsuranceInput = {
	id?: string | null;
	insuranceID?: string | null;
	clientID?: string | null;
	contractNumber?: string | null;
	affiliateNumber?: string | null;
	affiliateType?: AffiliateTypes | null;
	_version?: number | null;
};

export type ModelClientInsuranceConditionInput = {
	insuranceID?: ModelIDInput | null;
	clientID?: ModelStringInput | null;
	contractNumber?: ModelStringInput | null;
	affiliateNumber?: ModelStringInput | null;
	affiliateType?: ModelAffiliateTypesInput | null;
	and?: Array<ModelClientInsuranceConditionInput | null> | null;
	or?: Array<ModelClientInsuranceConditionInput | null> | null;
	not?: ModelClientInsuranceConditionInput | null;
};

export type ModelAffiliateTypesInput = {
	eq?: AffiliateTypes | null;
	ne?: AffiliateTypes | null;
};

export type UpdateClientInsuranceInput = {
	id: string;
	insuranceID?: string | null;
	clientID?: string | null;
	contractNumber?: string | null;
	affiliateNumber?: string | null;
	affiliateType?: AffiliateTypes | null;
	_version?: number | null;
};

export type DeleteClientInsuranceInput = {
	id: string;
	_version?: number | null;
};

export type CreateInsuranceInput = {
	id?: string | null;
	name: string;
	allowClaim?: boolean | null;
	code?: string | null;
	_version?: number | null;
};

export type ModelInsuranceConditionInput = {
	name?: ModelStringInput | null;
	allowClaim?: ModelBooleanInput | null;
	code?: ModelStringInput | null;
	and?: Array<ModelInsuranceConditionInput | null> | null;
	or?: Array<ModelInsuranceConditionInput | null> | null;
	not?: ModelInsuranceConditionInput | null;
};

export type UpdateInsuranceInput = {
	id: string;
	name?: string | null;
	allowClaim?: boolean | null;
	code?: string | null;
	_version?: number | null;
};

export type DeleteInsuranceInput = {
	id: string;
	_version?: number | null;
};

export type CreateClientClaimInput = {
	id?: string | null;
	clientHospitalDoctorID: string;
	healthInsuranceID: string;
	diagnostic: string;
	authorization: string;
	date: string;
	status?: Status | null;
	_version?: number | null;
};

export type ModelClientClaimConditionInput = {
	clientHospitalDoctorID?: ModelIDInput | null;
	healthInsuranceID?: ModelIDInput | null;
	diagnostic?: ModelStringInput | null;
	authorization?: ModelStringInput | null;
	date?: ModelStringInput | null;
	status?: ModelStatusInput | null;
	and?: Array<ModelClientClaimConditionInput | null> | null;
	or?: Array<ModelClientClaimConditionInput | null> | null;
	not?: ModelClientClaimConditionInput | null;
};

export type ClientClaim = {
	__typename: 'ClientClaim';
	id: string;
	clientHospitalDoctorID: string;
	healthInsuranceID: string;
	diagnostic: string;
	authorization: string;
	date: string;
	status?: Status | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
	healthInsurance?: Insurance | null;
};

export type UpdateClientClaimInput = {
	id: string;
	clientHospitalDoctorID?: string | null;
	healthInsuranceID?: string | null;
	diagnostic?: string | null;
	authorization?: string | null;
	date?: string | null;
	status?: Status | null;
	_version?: number | null;
};

export type DeleteClientClaimInput = {
	id: string;
	_version?: number | null;
};

export type CreateClaimProcessInput = {
	id?: string | null;
	claimID: string;
	name: string;
	code: string;
	claimed: string;
	difference: string;
	authorized: string;
	_version?: number | null;
};

export type ModelClaimProcessConditionInput = {
	claimID?: ModelIDInput | null;
	name?: ModelStringInput | null;
	code?: ModelStringInput | null;
	claimed?: ModelStringInput | null;
	difference?: ModelStringInput | null;
	authorized?: ModelStringInput | null;
	and?: Array<ModelClaimProcessConditionInput | null> | null;
	or?: Array<ModelClaimProcessConditionInput | null> | null;
	not?: ModelClaimProcessConditionInput | null;
};

export type ClaimProcess = {
	__typename: 'ClaimProcess';
	id: string;
	claimID: string;
	name: string;
	code: string;
	claimed: string;
	difference: string;
	authorized: string;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
};

export type UpdateClaimProcessInput = {
	id: string;
	claimID?: string | null;
	name?: string | null;
	code?: string | null;
	claimed?: string | null;
	difference?: string | null;
	authorized?: string | null;
	_version?: number | null;
};

export type DeleteClaimProcessInput = {
	id: string;
	_version?: number | null;
};

export type CreateFormsInput = {
	id?: string | null;
	DoctorID?: string | null;
	public?: boolean | null;
	name?: string | null;
	formImage?: string | null;
	description?: string | null;
	elements?: Array<string | null> | null;
	_version?: number | null;
};

export type ModelFormsConditionInput = {
	DoctorID?: ModelIDInput | null;
	public?: ModelBooleanInput | null;
	name?: ModelStringInput | null;
	formImage?: ModelStringInput | null;
	description?: ModelStringInput | null;
	elements?: ModelStringInput | null;
	and?: Array<ModelFormsConditionInput | null> | null;
	or?: Array<ModelFormsConditionInput | null> | null;
	not?: ModelFormsConditionInput | null;
};

export type Forms = {
	__typename: 'Forms';
	id: string;
	DoctorID?: string | null;
	public?: boolean | null;
	name?: string | null;
	formImage?: string | null;
	description?: string | null;
	elements?: Array<string | null> | null;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
};

export type UpdateFormsInput = {
	id: string;
	DoctorID?: string | null;
	public?: boolean | null;
	name?: string | null;
	formImage?: string | null;
	description?: string | null;
	elements?: Array<string | null> | null;
	_version?: number | null;
};

export type DeleteFormsInput = {
	id: string;
	_version?: number | null;
};

export type CreateAppointmentInput = {
	id?: string | null;
	clientID: string;
	doctorID: string;
	hospitalID: string;
	description: string;
	date: string;
	start: string;
	end: string;
	status?: Status | null;
	shouldSendReminder?: boolean | null;
	createdAt?: string | null;
	updatedAt?: string | null;
	_version?: number | null;
};

export type ModelAppointmentConditionInput = {
	clientID?: ModelIDInput | null;
	doctorID?: ModelIDInput | null;
	hospitalID?: ModelIDInput | null;
	description?: ModelStringInput | null;
	date?: ModelStringInput | null;
	start?: ModelStringInput | null;
	end?: ModelStringInput | null;
	status?: ModelStatusInput | null;
	shouldSendReminder?: ModelBooleanInput | null;
	createdAt?: ModelStringInput | null;
	updatedAt?: ModelStringInput | null;
	and?: Array<ModelAppointmentConditionInput | null> | null;
	or?: Array<ModelAppointmentConditionInput | null> | null;
	not?: ModelAppointmentConditionInput | null;
};

export type Appointment = {
	__typename: 'Appointment';
	id: string;
	clientID: string;
	doctorID: string;
	hospitalID: string;
	description: string;
	date: string;
	start: string;
	end: string;
	status?: Status | null;
	shouldSendReminder?: boolean | null;
	createdAt: string;
	updatedAt: string;
	_version: number;
	_deleted?: boolean | null;
	_lastChangedAt: number;
	client?: Client | null;
	doctor?: Doctor | null;
	hospital?: Hospital | null;
};

export type UpdateAppointmentInput = {
	id: string;
	clientID?: string | null;
	doctorID?: string | null;
	hospitalID?: string | null;
	description?: string | null;
	date?: string | null;
	start?: string | null;
	end?: string | null;
	status?: Status | null;
	shouldSendReminder?: boolean | null;
	createdAt?: string | null;
	updatedAt?: string | null;
	_version?: number | null;
};

export type DeleteAppointmentInput = {
	id: string;
	_version?: number | null;
};

export type ModelSecretaryFilterInput = {
	id?: ModelIDInput | null;
	name?: ModelStringInput | null;
	email?: ModelStringInput | null;
	firstTime?: ModelBooleanInput | null;
	and?: Array<ModelSecretaryFilterInput | null> | null;
	or?: Array<ModelSecretaryFilterInput | null> | null;
	not?: ModelSecretaryFilterInput | null;
};

export type ModelSecretaryConnection = {
	__typename: 'ModelSecretaryConnection';
	items: Array<Secretary | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelEmailDistributionListsFilterInput = {
	id?: ModelIDInput | null;
	email?: ModelStringInput | null;
	specialty?: ModelStringInput | null;
	createdAt?: ModelStringInput | null;
	updatedAt?: ModelStringInput | null;
	and?: Array<ModelEmailDistributionListsFilterInput | null> | null;
	or?: Array<ModelEmailDistributionListsFilterInput | null> | null;
	not?: ModelEmailDistributionListsFilterInput | null;
};

export type ModelEmailDistributionListsConnection = {
	__typename: 'ModelEmailDistributionListsConnection';
	items: Array<EmailDistributionLists | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelSecretaryHospitalDoctorFilterInput = {
	id?: ModelIDInput | null;
	hospitalDoctorID?: ModelIDInput | null;
	secretaryID?: ModelIDInput | null;
	hasHistoryPermission?: ModelBooleanInput | null;
	hasSurgeryPermission?: ModelBooleanInput | null;
	hasPrescriptionsPermission?: ModelBooleanInput | null;
	hasFilesPermission?: ModelBooleanInput | null;
	hasNotesPermission?: ModelBooleanInput | null;
	hasClaimsPermission?: ModelBooleanInput | null;
	status?: ModelStatusInput | null;
	and?: Array<ModelSecretaryHospitalDoctorFilterInput | null> | null;
	or?: Array<ModelSecretaryHospitalDoctorFilterInput | null> | null;
	not?: ModelSecretaryHospitalDoctorFilterInput | null;
};

export type ModelSecretaryHospitalDoctorConnection = {
	__typename: 'ModelSecretaryHospitalDoctorConnection';
	items: Array<SecretaryHospitalDoctor | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelSecretaryHospitalDoctorClientFilterInput = {
	id?: ModelIDInput | null;
	hospitalDoctorID?: ModelIDInput | null;
	secretaryID?: ModelIDInput | null;
	clientID?: ModelIDInput | null;
	note?: ModelStringInput | null;
	and?: Array<ModelSecretaryHospitalDoctorClientFilterInput | null> | null;
	or?: Array<ModelSecretaryHospitalDoctorClientFilterInput | null> | null;
	not?: ModelSecretaryHospitalDoctorClientFilterInput | null;
};

export type ModelSecretaryHospitalDoctorClientConnection = {
	__typename: 'ModelSecretaryHospitalDoctorClientConnection';
	items: Array<SecretaryHospitalDoctorClient | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelMemberHospitalDoctorClientFilterInput = {
	id?: ModelIDInput | null;
	hospitalDoctorID?: ModelIDInput | null;
	memberID?: ModelIDInput | null;
	ownerID?: ModelIDInput | null;
	clientID?: ModelIDInput | null;
	note?: ModelStringInput | null;
	and?: Array<ModelMemberHospitalDoctorClientFilterInput | null> | null;
	or?: Array<ModelMemberHospitalDoctorClientFilterInput | null> | null;
	not?: ModelMemberHospitalDoctorClientFilterInput | null;
};

export type ModelMemberHospitalDoctorClientConnection = {
	__typename: 'ModelMemberHospitalDoctorClientConnection';
	items: Array<MemberHospitalDoctorClient | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelClientFilterInput = {
	id?: ModelIDInput | null;
	identificationName?: ModelIdentificationTypesInput | null;
	identificationData?: ModelStringInput | null;
	actualInsurance?: ModelStringInput | null;
	name?: ModelStringInput | null;
	lastName?: ModelStringInput | null;
	cellphoneNumber?: ModelStringInput | null;
	email?: ModelStringInput | null;
	bornDate?: ModelStringInput | null;
	parentOrTutorCedula?: ModelStringInput | null;
	parentOrTutorID?: ModelStringInput | null;
	gender?: ModelSexTypeInput | null;
	phoneNumber?: ModelStringInput | null;
	addressStreet?: ModelStringInput | null;
	city?: ModelStringInput | null;
	isAdult?: ModelBooleanInput | null;
	sector?: ModelStringInput | null;
	bloodType?: ModelStringInput | null;
	company?: ModelStringInput | null;
	vitalSignsHeight?: ModelStringInput | null;
	vitalSignsWeight?: ModelStringInput | null;
	bodyMass?: ModelStringInput | null;
	vitalSignsBloodPressure?: ModelStringInput | null;
	heartRate?: ModelStringInput | null;
	respiratoryRate?: ModelStringInput | null;
	bodyTemperature?: ModelStringInput | null;
	oxygenSaturation?: ModelStringInput | null;
	occupation?: ModelStringInput | null;
	profileImage?: ModelStringInput | null;
	heartBeat?: ModelStringInput | null;
	weight?: ModelStringInput | null;
	height?: ModelStringInput | null;
	bloodPressure?: ModelStringInput | null;
	updateDateTime?: ModelStringInput | null;
	createdAt?: ModelStringInput | null;
	updatedAt?: ModelStringInput | null;
	code?: ModelStringInput | null;
	and?: Array<ModelClientFilterInput | null> | null;
	or?: Array<ModelClientFilterInput | null> | null;
	not?: ModelClientFilterInput | null;
};

export type ModelClientConnection = {
	__typename: 'ModelClientConnection';
	items: Array<Client | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelClientImageSectionFilterInput = {
	id?: ModelIDInput | null;
	clientHospitalDoctorID?: ModelIDInput | null;
	date?: ModelStringInput | null;
	description?: ModelStringInput | null;
	status?: ModelStatusInput | null;
	and?: Array<ModelClientImageSectionFilterInput | null> | null;
	or?: Array<ModelClientImageSectionFilterInput | null> | null;
	not?: ModelClientImageSectionFilterInput | null;
};

export type ModelClientImageSectionConnection = {
	__typename: 'ModelClientImageSectionConnection';
	items: Array<ClientImageSection | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelPosibleClientFilterInput = {
	id?: ModelIDInput | null;
	phoneNumber?: ModelStringInput | null;
	and?: Array<ModelPosibleClientFilterInput | null> | null;
	or?: Array<ModelPosibleClientFilterInput | null> | null;
	not?: ModelPosibleClientFilterInput | null;
};

export type ModelPosibleClientConnection = {
	__typename: 'ModelPosibleClientConnection';
	items: Array<PosibleClient | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelClientImageFilterInput = {
	id?: ModelIDInput | null;
	pathFile?: ModelStringInput | null;
	description?: ModelStringInput | null;
	ClientImageDateID?: ModelIDInput | null;
	status?: ModelStatusInput | null;
	and?: Array<ModelClientImageFilterInput | null> | null;
	or?: Array<ModelClientImageFilterInput | null> | null;
	not?: ModelClientImageFilterInput | null;
};

export type ModelClientImageConnection = {
	__typename: 'ModelClientImageConnection';
	items: Array<ClientImage | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelConsultationDiagnosticFilterInput = {
	id?: ModelIDInput | null;
	clientConsultationID?: ModelIDInput | null;
	name?: ModelStringInput | null;
	code?: ModelStringInput | null;
	type?: ModelDiagnosticTypesInput | null;
	status?: ModelStatusInput | null;
	and?: Array<ModelConsultationDiagnosticFilterInput | null> | null;
	or?: Array<ModelConsultationDiagnosticFilterInput | null> | null;
	not?: ModelConsultationDiagnosticFilterInput | null;
};

export type ModelConsultationDiagnosticConnection = {
	__typename: 'ModelConsultationDiagnosticConnection';
	items: Array<ConsultationDiagnostic | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelClientConsultationFilterInput = {
	id?: ModelIDInput | null;
	clientHospitalDoctorID?: ModelIDInput | null;
	doctorID?: ModelIDInput | null;
	visitReason?: ModelStringInput | null;
	visitDescription?: ModelStringInput | null;
	physicTestNote?: ModelStringInput | null;
	height?: ModelStringInput | null;
	weight?: ModelStringInput | null;
	bodyMass?: ModelStringInput | null;
	bloodPressure?: ModelStringInput | null;
	heartRate?: ModelStringInput | null;
	respiratoryRate?: ModelStringInput | null;
	bodyTemperature?: ModelStringInput | null;
	oxygenSaturation?: ModelStringInput | null;
	clientMedicinePrescriptionID?: ModelIDInput | null;
	clientAnalisisPrescriptionID?: ModelIDInput | null;
	clientFreePrescriptionID?: ModelIDInput | null;
	treatment?: ModelStringInput | null;
	procedureName?: ModelStringInput | null;
	procedureDescription?: ModelStringInput | null;
	createdAt?: ModelStringInput | null;
	updatedAt?: ModelStringInput | null;
	status?: ModelStatusInput | null;
	and?: Array<ModelClientConsultationFilterInput | null> | null;
	or?: Array<ModelClientConsultationFilterInput | null> | null;
	not?: ModelClientConsultationFilterInput | null;
};

export type ModelClientConsultationConnection = {
	__typename: 'ModelClientConsultationConnection';
	items: Array<ClientConsultation | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelMedicineFilterInput = {
	id?: ModelIDInput | null;
	name?: ModelStringInput | null;
	and?: Array<ModelMedicineFilterInput | null> | null;
	or?: Array<ModelMedicineFilterInput | null> | null;
	not?: ModelMedicineFilterInput | null;
};

export type ModelMedicineConnection = {
	__typename: 'ModelMedicineConnection';
	items: Array<Medicine | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelClientAllergyFilterInput = {
	id?: ModelIDInput | null;
	clientID?: ModelIDInput | null;
	name?: ModelStringInput | null;
	status?: ModelAllergyStatusInput | null;
	note?: ModelStringInput | null;
	diagnosedDate?: ModelStringInput | null;
	and?: Array<ModelClientAllergyFilterInput | null> | null;
	or?: Array<ModelClientAllergyFilterInput | null> | null;
	not?: ModelClientAllergyFilterInput | null;
};

export type ModelClientAllergyConnection = {
	__typename: 'ModelClientAllergyConnection';
	items: Array<ClientAllergy | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelClientDiseaseFilterInput = {
	id?: ModelIDInput | null;
	clientID?: ModelIDInput | null;
	name?: ModelStringInput | null;
	status?: ModelAllergyStatusInput | null;
	note?: ModelStringInput | null;
	diagnosedDate?: ModelStringInput | null;
	updatedAt?: ModelStringInput | null;
	createdAt?: ModelStringInput | null;
	and?: Array<ModelClientDiseaseFilterInput | null> | null;
	or?: Array<ModelClientDiseaseFilterInput | null> | null;
	not?: ModelClientDiseaseFilterInput | null;
};

export type ModelClientDiseaseConnection = {
	__typename: 'ModelClientDiseaseConnection';
	items: Array<ClientDisease | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelClientProcedureFilterInput = {
	id?: ModelIDInput | null;
	clientID?: ModelIDInput | null;
	name?: ModelStringInput | null;
	note?: ModelStringInput | null;
	realizationDate?: ModelStringInput | null;
	status?: ModelStatusInput | null;
	and?: Array<ModelClientProcedureFilterInput | null> | null;
	or?: Array<ModelClientProcedureFilterInput | null> | null;
	not?: ModelClientProcedureFilterInput | null;
};

export type ModelClientProcedureConnection = {
	__typename: 'ModelClientProcedureConnection';
	items: Array<ClientProcedure | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelClientHospitalizationFilterInput = {
	id?: ModelIDInput | null;
	clientID?: ModelIDInput | null;
	name?: ModelStringInput | null;
	note?: ModelStringInput | null;
	date?: ModelStringInput | null;
	status?: ModelStatusInput | null;
	and?: Array<ModelClientHospitalizationFilterInput | null> | null;
	or?: Array<ModelClientHospitalizationFilterInput | null> | null;
	not?: ModelClientHospitalizationFilterInput | null;
};

export type ModelClientHospitalizationConnection = {
	__typename: 'ModelClientHospitalizationConnection';
	items: Array<ClientHospitalization | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelClientFamilyHistoryFilterInput = {
	id?: ModelIDInput | null;
	clientID?: ModelIDInput | null;
	familyMember?: ModelHistoryFamilyMemberInput | null;
	mellitusDiabetes?: ModelBooleanInput | null;
	mellitusDiabetesNote?: ModelStringInput | null;
	arterialHypertension?: ModelBooleanInput | null;
	arterialHypertensionNote?: ModelStringInput | null;
	heartDisease?: ModelBooleanInput | null;
	heartDiseaseNote?: ModelStringInput | null;
	nephropathy?: ModelBooleanInput | null;
	nephropathyNote?: ModelStringInput | null;
	neuropathies?: ModelBooleanInput | null;
	neuropathiesNote?: ModelStringInput | null;
	epilepsy?: ModelBooleanInput | null;
	epilepsyNote?: ModelStringInput | null;
	rheumatoidArthritis?: ModelBooleanInput | null;
	rheumatoidArthritisNote?: ModelStringInput | null;
	psychiatricDiseases?: ModelBooleanInput | null;
	psychiatricDiseasesNote?: ModelStringInput | null;
	cancer?: ModelBooleanInput | null;
	cancerNote?: ModelStringInput | null;
	other?: ModelBooleanInput | null;
	otherNote?: ModelStringInput | null;
	updatedAt?: ModelStringInput | null;
	createdAt?: ModelStringInput | null;
	and?: Array<ModelClientFamilyHistoryFilterInput | null> | null;
	or?: Array<ModelClientFamilyHistoryFilterInput | null> | null;
	not?: ModelClientFamilyHistoryFilterInput | null;
};

export type ModelClientFamilyHistoryConnection = {
	__typename: 'ModelClientFamilyHistoryConnection';
	items: Array<ClientFamilyHistory | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelClientLifeStyleFilterInput = {
	id?: ModelIDInput | null;
	clientID?: ModelIDInput | null;
	lifeStyle?: ModelLifeStyleInput | null;
	name?: ModelStringInput | null;
	description?: ModelStringInput | null;
	updatedAt?: ModelStringInput | null;
	createdAt?: ModelStringInput | null;
	and?: Array<ModelClientLifeStyleFilterInput | null> | null;
	or?: Array<ModelClientLifeStyleFilterInput | null> | null;
	not?: ModelClientLifeStyleFilterInput | null;
};

export type ModelClientLifeStyleConnection = {
	__typename: 'ModelClientLifeStyleConnection';
	items: Array<ClientLifeStyle | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelClientPrescriptionFilterInput = {
	id?: ModelIDInput | null;
	clientHospitalDoctorID?: ModelIDInput | null;
	creationDate?: ModelStringInput | null;
	type?: ModelPrescriptionTypesInput | null;
	status?: ModelStatusInput | null;
	and?: Array<ModelClientPrescriptionFilterInput | null> | null;
	or?: Array<ModelClientPrescriptionFilterInput | null> | null;
	not?: ModelClientPrescriptionFilterInput | null;
};

export type ModelClientPrescriptionConnection = {
	__typename: 'ModelClientPrescriptionConnection';
	items: Array<ClientPrescription | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelClientPrescriptionMedicineFilterInput = {
	id?: ModelIDInput | null;
	clientPrescriptionID?: ModelIDInput | null;
	medicineID?: ModelStringInput | null;
	name?: ModelStringInput | null;
	take?: ModelStringInput | null;
	indication?: ModelStringInput | null;
	duration?: ModelStringInput | null;
	description?: ModelStringInput | null;
	status?: ModelStatusInput | null;
	and?: Array<ModelClientPrescriptionMedicineFilterInput | null> | null;
	or?: Array<ModelClientPrescriptionMedicineFilterInput | null> | null;
	not?: ModelClientPrescriptionMedicineFilterInput | null;
};

export type ModelClientPrescriptionMedicineConnection = {
	__typename: 'ModelClientPrescriptionMedicineConnection';
	items: Array<ClientPrescriptionMedicine | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelClientFreePrescriptionFilterInput = {
	id?: ModelIDInput | null;
	clientPrescriptionID?: ModelIDInput | null;
	description?: ModelStringInput | null;
	status?: ModelStatusInput | null;
	and?: Array<ModelClientFreePrescriptionFilterInput | null> | null;
	or?: Array<ModelClientFreePrescriptionFilterInput | null> | null;
	not?: ModelClientFreePrescriptionFilterInput | null;
};

export type ModelClientFreePrescriptionConnection = {
	__typename: 'ModelClientFreePrescriptionConnection';
	items: Array<ClientFreePrescription | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelClientPrescriptionAnalisisFilterInput = {
	id?: ModelIDInput | null;
	clientPrescriptionID?: ModelIDInput | null;
	name?: ModelStringInput | null;
	description?: ModelStringInput | null;
	status?: ModelStatusInput | null;
	and?: Array<ModelClientPrescriptionAnalisisFilterInput | null> | null;
	or?: Array<ModelClientPrescriptionAnalisisFilterInput | null> | null;
	not?: ModelClientPrescriptionAnalisisFilterInput | null;
};

export type ModelClientPrescriptionAnalisisConnection = {
	__typename: 'ModelClientPrescriptionAnalisisConnection';
	items: Array<ClientPrescriptionAnalisis | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelDoctorLabImageFilterInput = {
	id?: ModelIDInput | null;
	doctorID?: ModelIDInput | null;
	labImageID?: ModelIDInput | null;
	updatedAt?: ModelStringInput | null;
	status?: ModelStatusInput | null;
	and?: Array<ModelDoctorLabImageFilterInput | null> | null;
	or?: Array<ModelDoctorLabImageFilterInput | null> | null;
	not?: ModelDoctorLabImageFilterInput | null;
};

export type ModelDoctorLabImageConnection = {
	__typename: 'ModelDoctorLabImageConnection';
	items: Array<DoctorLabImage | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelLabImageFilterInput = {
	id?: ModelIDInput | null;
	name?: ModelStringInput | null;
	description?: ModelStringInput | null;
	and?: Array<ModelLabImageFilterInput | null> | null;
	or?: Array<ModelLabImageFilterInput | null> | null;
	not?: ModelLabImageFilterInput | null;
};

export type ModelLabImageConnection = {
	__typename: 'ModelLabImageConnection';
	items: Array<LabImage | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelPharmacyMedicineFilterInput = {
	id?: ModelIDInput | null;
	name?: ModelStringInput | null;
	and?: Array<ModelPharmacyMedicineFilterInput | null> | null;
	or?: Array<ModelPharmacyMedicineFilterInput | null> | null;
	not?: ModelPharmacyMedicineFilterInput | null;
};

export type ModelPharmacyMedicineConnection = {
	__typename: 'ModelPharmacyMedicineConnection';
	items: Array<PharmacyMedicine | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelPharmacyMedicineMeasureFilterInput = {
	id?: ModelIDInput | null;
	pharmacyMedicineID?: ModelIDInput | null;
	and?: Array<ModelPharmacyMedicineMeasureFilterInput | null> | null;
	or?: Array<ModelPharmacyMedicineMeasureFilterInput | null> | null;
	not?: ModelPharmacyMedicineMeasureFilterInput | null;
};

export type ModelPharmacyMedicineMeasureConnection = {
	__typename: 'ModelPharmacyMedicineMeasureConnection';
	items: Array<PharmacyMedicineMeasure | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelTemplateFilterInput = {
	id?: ModelIDInput | null;
	doctorID?: ModelIDInput | null;
	templateName?: ModelStringInput | null;
	templateType?: ModelPrescriptionTypesInput | null;
	and?: Array<ModelTemplateFilterInput | null> | null;
	or?: Array<ModelTemplateFilterInput | null> | null;
	not?: ModelTemplateFilterInput | null;
};

export type ModelTemplateConnection = {
	__typename: 'ModelTemplateConnection';
	items: Array<Template | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelTemplateMedicineFilterInput = {
	id?: ModelIDInput | null;
	templateID?: ModelIDInput | null;
	medicineID?: ModelIDInput | null;
	take?: ModelStringInput | null;
	indication?: ModelStringInput | null;
	duration?: ModelStringInput | null;
	description?: ModelStringInput | null;
	and?: Array<ModelTemplateMedicineFilterInput | null> | null;
	or?: Array<ModelTemplateMedicineFilterInput | null> | null;
	not?: ModelTemplateMedicineFilterInput | null;
};

export type ModelTemplateMedicineConnection = {
	__typename: 'ModelTemplateMedicineConnection';
	items: Array<TemplateMedicine | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelDoctorRecentDiagnosisFilterInput = {
	id?: ModelIDInput | null;
	doctorID?: ModelIDInput | null;
	name?: ModelStringInput | null;
	code?: ModelStringInput | null;
	type?: ModelDiagnosticTypesInput | null;
	updateDateTime?: ModelStringInput | null;
	createdAt?: ModelStringInput | null;
	updatedAt?: ModelStringInput | null;
	and?: Array<ModelDoctorRecentDiagnosisFilterInput | null> | null;
	or?: Array<ModelDoctorRecentDiagnosisFilterInput | null> | null;
	not?: ModelDoctorRecentDiagnosisFilterInput | null;
};

export type ModelDoctorRecentDiagnosisConnection = {
	__typename: 'ModelDoctorRecentDiagnosisConnection';
	items: Array<DoctorRecentDiagnosis | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelTemplateLabImageFilterInput = {
	id?: ModelIDInput | null;
	templateID?: ModelIDInput | null;
	name?: ModelStringInput | null;
	description?: ModelStringInput | null;
	and?: Array<ModelTemplateLabImageFilterInput | null> | null;
	or?: Array<ModelTemplateLabImageFilterInput | null> | null;
	not?: ModelTemplateLabImageFilterInput | null;
};

export type ModelTemplateLabImageConnection = {
	__typename: 'ModelTemplateLabImageConnection';
	items: Array<TemplateLabImage | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelDoctorMedicineFilterInput = {
	id?: ModelIDInput | null;
	doctorID?: ModelIDInput | null;
	medicineID?: ModelIDInput | null;
	isDeleted?: ModelBooleanInput | null;
	updatedAt?: ModelStringInput | null;
	and?: Array<ModelDoctorMedicineFilterInput | null> | null;
	or?: Array<ModelDoctorMedicineFilterInput | null> | null;
	not?: ModelDoctorMedicineFilterInput | null;
};

export type ModelDoctorMedicineConnection = {
	__typename: 'ModelDoctorMedicineConnection';
	items: Array<DoctorMedicine | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelRecentDoctorMedicinePrescriptionFilterInput = {
	id?: ModelIDInput | null;
	doctorID?: ModelIDInput | null;
	medicineID?: ModelIDInput | null;
	take?: ModelStringInput | null;
	indication?: ModelStringInput | null;
	duration?: ModelStringInput | null;
	description?: ModelStringInput | null;
	updateDateTime?: ModelStringInput | null;
	createdAt?: ModelStringInput | null;
	updatedAt?: ModelStringInput | null;
	status?: ModelStatusInput | null;
	and?: Array<ModelRecentDoctorMedicinePrescriptionFilterInput | null> | null;
	or?: Array<ModelRecentDoctorMedicinePrescriptionFilterInput | null> | null;
	not?: ModelRecentDoctorMedicinePrescriptionFilterInput | null;
};

export type ModelRecentDoctorMedicinePrescriptionConnection = {
	__typename: 'ModelRecentDoctorMedicinePrescriptionConnection';
	items: Array<RecentDoctorMedicinePrescription | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelRecentDoctorLabImagePrescriptionFilterInput = {
	id?: ModelIDInput | null;
	doctorID?: ModelIDInput | null;
	labImageID?: ModelIDInput | null;
	name?: ModelStringInput | null;
	description?: ModelStringInput | null;
	updateDateTime?: ModelStringInput | null;
	createdAt?: ModelStringInput | null;
	updatedAt?: ModelStringInput | null;
	status?: ModelStatusInput | null;
	and?: Array<ModelRecentDoctorLabImagePrescriptionFilterInput | null> | null;
	or?: Array<ModelRecentDoctorLabImagePrescriptionFilterInput | null> | null;
	not?: ModelRecentDoctorLabImagePrescriptionFilterInput | null;
};

export type ModelRecentDoctorLabImagePrescriptionConnection = {
	__typename: 'ModelRecentDoctorLabImagePrescriptionConnection';
	items: Array<RecentDoctorLabImagePrescription | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelTemplateAnalisisFilterInput = {
	id?: ModelIDInput | null;
	templateID?: ModelIDInput | null;
	analisisID?: ModelIDInput | null;
	and?: Array<ModelTemplateAnalisisFilterInput | null> | null;
	or?: Array<ModelTemplateAnalisisFilterInput | null> | null;
	not?: ModelTemplateAnalisisFilterInput | null;
};

export type ModelTemplateAnalisisConnection = {
	__typename: 'ModelTemplateAnalisisConnection';
	items: Array<TemplateAnalisis | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelAnalisisFilterInput = {
	id?: ModelIDInput | null;
	analisisName?: ModelStringInput | null;
	and?: Array<ModelAnalisisFilterInput | null> | null;
	or?: Array<ModelAnalisisFilterInput | null> | null;
	not?: ModelAnalisisFilterInput | null;
};

export type ModelAnalisisConnection = {
	__typename: 'ModelAnalisisConnection';
	items: Array<Analisis | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelClientActiveDiseaseFilterInput = {
	id?: ModelIDInput | null;
	clientID?: ModelIDInput | null;
	name?: ModelStringInput | null;
	and?: Array<ModelClientActiveDiseaseFilterInput | null> | null;
	or?: Array<ModelClientActiveDiseaseFilterInput | null> | null;
	not?: ModelClientActiveDiseaseFilterInput | null;
};

export type ModelClientActiveDiseaseConnection = {
	__typename: 'ModelClientActiveDiseaseConnection';
	items: Array<ClientActiveDisease | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelClientHistoryFilterInput = {
	id?: ModelIDInput | null;
	clientHospitalDoctorID?: ModelIDInput | null;
	title?: ModelStringInput | null;
	treatment?: ModelStringInput | null;
	endModifyDate?: ModelStringInput | null;
	authorizationNumber?: ModelStringInput | null;
	description?: ModelStringInput | null;
	authorizationDetail?: ModelStringInput | null;
	personalPathologicalHistory?: ModelStringInput | null;
	familyPathologicalHistory?: ModelStringInput | null;
	physicalTest?: ModelStringInput | null;
	createdDate?: ModelStringInput | null;
	insuranceID?: ModelIDInput | null;
	status?: ModelStatusInput | null;
	and?: Array<ModelClientHistoryFilterInput | null> | null;
	or?: Array<ModelClientHistoryFilterInput | null> | null;
	not?: ModelClientHistoryFilterInput | null;
};

export type ModelClientHistoryConnection = {
	__typename: 'ModelClientHistoryConnection';
	items: Array<ClientHistory | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelClientNotesFilterInput = {
	id?: ModelIDInput | null;
	clientHospitalDoctorID?: ModelIDInput | null;
	title?: ModelStringInput | null;
	noteBody?: ModelStringInput | null;
	endModifyDate?: ModelStringInput | null;
	createdDate?: ModelStringInput | null;
	status?: ModelStatusInput | null;
	and?: Array<ModelClientNotesFilterInput | null> | null;
	or?: Array<ModelClientNotesFilterInput | null> | null;
	not?: ModelClientNotesFilterInput | null;
};

export type ModelClientNotesConnection = {
	__typename: 'ModelClientNotesConnection';
	items: Array<ClientNotes | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelClientProcedureSurgeryFilterInput = {
	id?: ModelIDInput | null;
	clientHospitalDoctorID?: ModelIDInput | null;
	title?: ModelStringInput | null;
	description?: ModelStringInput | null;
	endModifyDate?: ModelStringInput | null;
	createdDate?: ModelStringInput | null;
	status?: ModelStatusInput | null;
	and?: Array<ModelClientProcedureSurgeryFilterInput | null> | null;
	or?: Array<ModelClientProcedureSurgeryFilterInput | null> | null;
	not?: ModelClientProcedureSurgeryFilterInput | null;
};

export type ModelClientProcedureSurgeryConnection = {
	__typename: 'ModelClientProcedureSurgeryConnection';
	items: Array<ClientProcedureSurgery | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelDoctorFilterInput = {
	id?: ModelIDInput | null;
	name?: ModelStringInput | null;
	specialty?: ModelStringInput | null;
	exequator?: ModelStringInput | null;
	email?: ModelStringInput | null;
	firstTime?: ModelBooleanInput | null;
	studyPlace?: ModelStringInput | null;
	isBeenReview?: ModelBooleanInput | null;
	isFirstAddSecretary?: ModelBooleanInput | null;
	hasTrialOnCreation?: ModelBooleanInput | null;
	title?: ModelStringInput | null;
	phone?: ModelStringInput | null;
	code?: ModelStringInput | null;
	password?: ModelStringInput | null;
	isAdmin?: ModelBooleanInput | null;
	daysOfTrial?: ModelIntInput | null;
	and?: Array<ModelDoctorFilterInput | null> | null;
	or?: Array<ModelDoctorFilterInput | null> | null;
	not?: ModelDoctorFilterInput | null;
};

export type ModelDoctorConnection = {
	__typename: 'ModelDoctorConnection';
	items: Array<Doctor | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelPlanFilterInput = {
	id?: ModelIDInput | null;
	refID?: ModelIntInput | null;
	title?: ModelStringInput | null;
	subTitle?: ModelStringInput | null;
	stripePlanKey?: ModelStringInput | null;
	and?: Array<ModelPlanFilterInput | null> | null;
	or?: Array<ModelPlanFilterInput | null> | null;
	not?: ModelPlanFilterInput | null;
};

export type ModelPlanConnection = {
	__typename: 'ModelPlanConnection';
	items: Array<Plan | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelDoctorStripeCustomerSubscriptionFilterInput = {
	id?: ModelIDInput | null;
	doctorID?: ModelIDInput | null;
	currentPlanID?: ModelIntInput | null;
	stripeCustomerID?: ModelStringInput | null;
	stripeSubscriptionID?: ModelStringInput | null;
	defaultCard?: ModelIDInput | null;
	nextPaymentDate?: ModelStringInput | null;
	cancelAtPeriodEnd?: ModelBooleanInput | null;
	trialsEndsAt?: ModelStringInput | null;
	and?: Array<ModelDoctorStripeCustomerSubscriptionFilterInput | null> | null;
	or?: Array<ModelDoctorStripeCustomerSubscriptionFilterInput | null> | null;
	not?: ModelDoctorStripeCustomerSubscriptionFilterInput | null;
};

export type ModelDoctorStripeCustomerSubscriptionConnection = {
	__typename: 'ModelDoctorStripeCustomerSubscriptionConnection';
	items: Array<DoctorStripeCustomerSubscription | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelDoctorStripeCardsFilterInput = {
	id?: ModelIDInput | null;
	DoctorID?: ModelIDInput | null;
	cardLastDigits?: ModelStringInput | null;
	stripePaymentMethodID?: ModelStringInput | null;
	defaultCard?: ModelBooleanInput | null;
	cardBrand?: ModelStringInput | null;
	status?: ModelStatusInput | null;
	and?: Array<ModelDoctorStripeCardsFilterInput | null> | null;
	or?: Array<ModelDoctorStripeCardsFilterInput | null> | null;
	not?: ModelDoctorStripeCardsFilterInput | null;
};

export type ModelDoctorStripeCardsConnection = {
	__typename: 'ModelDoctorStripeCardsConnection';
	items: Array<DoctorStripeCards | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelHospitalFilterInput = {
	id?: ModelIDInput | null;
	name?: ModelStringInput | null;
	type?: ModelStringInput | null;
	province?: ModelStringInput | null;
	town?: ModelStringInput | null;
	sector?: ModelStringInput | null;
	phone?: ModelStringInput | null;
	address?: ModelStringInput | null;
	pssCode?: ModelStringInput | null;
	rnc?: ModelStringInput | null;
	and?: Array<ModelHospitalFilterInput | null> | null;
	or?: Array<ModelHospitalFilterInput | null> | null;
	not?: ModelHospitalFilterInput | null;
};

export type ModelHospitalConnection = {
	__typename: 'ModelHospitalConnection';
	items: Array<Hospital | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelHospitalDoctorFilterInput = {
	id?: ModelIDInput | null;
	doctorID?: ModelIDInput | null;
	hospitalID?: ModelIDInput | null;
	lastWaitingListID?: ModelIDInput | null;
	hospitalLogo?: ModelStringInput | null;
	and?: Array<ModelHospitalDoctorFilterInput | null> | null;
	or?: Array<ModelHospitalDoctorFilterInput | null> | null;
	not?: ModelHospitalDoctorFilterInput | null;
};

export type ModelHospitalDoctorConnection = {
	__typename: 'ModelHospitalDoctorConnection';
	items: Array<HospitalDoctor | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelTeamsFilterInput = {
	id?: ModelIDInput | null;
	OwnerID?: ModelIDInput | null;
	MemberID?: ModelIDInput | null;
	status?: ModelTeamStatusInput | null;
	and?: Array<ModelTeamsFilterInput | null> | null;
	or?: Array<ModelTeamsFilterInput | null> | null;
	not?: ModelTeamsFilterInput | null;
};

export type ModelTeamsConnection = {
	__typename: 'ModelTeamsConnection';
	items: Array<Teams | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelHospitalDoctorClienteFilterInput = {
	id?: ModelIDInput | null;
	clientID?: ModelIDInput | null;
	hospitalDoctorID?: ModelIDInput | null;
	lastHealthInsurranceID?: ModelIDInput | null;
	clientStatus?: ModelStringInput | null;
	note?: ModelStringInput | null;
	recordNo?: ModelStringInput | null;
	and?: Array<ModelHospitalDoctorClienteFilterInput | null> | null;
	or?: Array<ModelHospitalDoctorClienteFilterInput | null> | null;
	not?: ModelHospitalDoctorClienteFilterInput | null;
};

export type ModelHospitalDoctorClienteConnection = {
	__typename: 'ModelHospitalDoctorClienteConnection';
	items: Array<HospitalDoctorCliente | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelWaitListFilterInput = {
	id?: ModelIDInput | null;
	hospitalDoctorID?: ModelIDInput | null;
	createdAt?: ModelStringInput | null;
	and?: Array<ModelWaitListFilterInput | null> | null;
	or?: Array<ModelWaitListFilterInput | null> | null;
	not?: ModelWaitListFilterInput | null;
};

export type ModelWaitListConnection = {
	__typename: 'ModelWaitListConnection';
	items: Array<WaitList | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelWaitingListItemFilterInput = {
	id?: ModelIDInput | null;
	waitingListID?: ModelIDInput | null;
	clientID?: ModelIDInput | null;
	status?: ModelWaitingListItemStatusInput | null;
	clientHealthInsurrance?: ModelStringInput | null;
	positionNumber?: ModelIntInput | null;
	and?: Array<ModelWaitingListItemFilterInput | null> | null;
	or?: Array<ModelWaitingListItemFilterInput | null> | null;
	not?: ModelWaitingListItemFilterInput | null;
};

export type ModelWaitingListItemConnection = {
	__typename: 'ModelWaitingListItemConnection';
	items: Array<WaitingListItem | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelClientInsuranceFilterInput = {
	id?: ModelIDInput | null;
	insuranceID?: ModelIDInput | null;
	clientID?: ModelStringInput | null;
	contractNumber?: ModelStringInput | null;
	affiliateNumber?: ModelStringInput | null;
	affiliateType?: ModelAffiliateTypesInput | null;
	and?: Array<ModelClientInsuranceFilterInput | null> | null;
	or?: Array<ModelClientInsuranceFilterInput | null> | null;
	not?: ModelClientInsuranceFilterInput | null;
};

export type ModelClientInsuranceConnection = {
	__typename: 'ModelClientInsuranceConnection';
	items: Array<ClientInsurance | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelInsuranceFilterInput = {
	id?: ModelIDInput | null;
	name?: ModelStringInput | null;
	allowClaim?: ModelBooleanInput | null;
	code?: ModelStringInput | null;
	and?: Array<ModelInsuranceFilterInput | null> | null;
	or?: Array<ModelInsuranceFilterInput | null> | null;
	not?: ModelInsuranceFilterInput | null;
};

export type ModelInsuranceConnection = {
	__typename: 'ModelInsuranceConnection';
	items: Array<Insurance | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelClientClaimFilterInput = {
	id?: ModelIDInput | null;
	clientHospitalDoctorID?: ModelIDInput | null;
	healthInsuranceID?: ModelIDInput | null;
	diagnostic?: ModelStringInput | null;
	authorization?: ModelStringInput | null;
	date?: ModelStringInput | null;
	status?: ModelStatusInput | null;
	and?: Array<ModelClientClaimFilterInput | null> | null;
	or?: Array<ModelClientClaimFilterInput | null> | null;
	not?: ModelClientClaimFilterInput | null;
};

export type ModelClientClaimConnection = {
	__typename: 'ModelClientClaimConnection';
	items: Array<ClientClaim | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelClaimProcessFilterInput = {
	id?: ModelIDInput | null;
	claimID?: ModelIDInput | null;
	name?: ModelStringInput | null;
	code?: ModelStringInput | null;
	claimed?: ModelStringInput | null;
	difference?: ModelStringInput | null;
	authorized?: ModelStringInput | null;
	and?: Array<ModelClaimProcessFilterInput | null> | null;
	or?: Array<ModelClaimProcessFilterInput | null> | null;
	not?: ModelClaimProcessFilterInput | null;
};

export type ModelClaimProcessConnection = {
	__typename: 'ModelClaimProcessConnection';
	items: Array<ClaimProcess | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelFormsFilterInput = {
	id?: ModelIDInput | null;
	DoctorID?: ModelIDInput | null;
	public?: ModelBooleanInput | null;
	name?: ModelStringInput | null;
	formImage?: ModelStringInput | null;
	description?: ModelStringInput | null;
	elements?: ModelStringInput | null;
	and?: Array<ModelFormsFilterInput | null> | null;
	or?: Array<ModelFormsFilterInput | null> | null;
	not?: ModelFormsFilterInput | null;
};

export type ModelFormsConnection = {
	__typename: 'ModelFormsConnection';
	items: Array<Forms | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type ModelAppointmentFilterInput = {
	id?: ModelIDInput | null;
	clientID?: ModelIDInput | null;
	doctorID?: ModelIDInput | null;
	hospitalID?: ModelIDInput | null;
	description?: ModelStringInput | null;
	date?: ModelStringInput | null;
	start?: ModelStringInput | null;
	end?: ModelStringInput | null;
	status?: ModelStatusInput | null;
	shouldSendReminder?: ModelBooleanInput | null;
	createdAt?: ModelStringInput | null;
	updatedAt?: ModelStringInput | null;
	and?: Array<ModelAppointmentFilterInput | null> | null;
	or?: Array<ModelAppointmentFilterInput | null> | null;
	not?: ModelAppointmentFilterInput | null;
};

export type ModelAppointmentConnection = {
	__typename: 'ModelAppointmentConnection';
	items: Array<Appointment | null>;
	nextToken?: string | null;
	startedAt?: number | null;
};

export type CreateSecretaryMutationVariables = {
	input: CreateSecretaryInput;
	condition?: ModelSecretaryConditionInput | null;
};

export type CreateSecretaryMutation = {
	createSecretary?: {
		__typename: 'Secretary';
		id: string;
		name?: string | null;
		email: string;
		firstTime?: boolean | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type UpdateSecretaryMutationVariables = {
	input: UpdateSecretaryInput;
	condition?: ModelSecretaryConditionInput | null;
};

export type UpdateSecretaryMutation = {
	updateSecretary?: {
		__typename: 'Secretary';
		id: string;
		name?: string | null;
		email: string;
		firstTime?: boolean | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type DeleteSecretaryMutationVariables = {
	input: DeleteSecretaryInput;
	condition?: ModelSecretaryConditionInput | null;
};

export type DeleteSecretaryMutation = {
	deleteSecretary?: {
		__typename: 'Secretary';
		id: string;
		name?: string | null;
		email: string;
		firstTime?: boolean | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type CreateEmailDistributionListsMutationVariables = {
	input: CreateEmailDistributionListsInput;
	condition?: ModelEmailDistributionListsConditionInput | null;
};

export type CreateEmailDistributionListsMutation = {
	createEmailDistributionLists?: {
		__typename: 'EmailDistributionLists';
		id: string;
		email?: string | null;
		specialty?: string | null;
		createdAt: string;
		updatedAt: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type UpdateEmailDistributionListsMutationVariables = {
	input: UpdateEmailDistributionListsInput;
	condition?: ModelEmailDistributionListsConditionInput | null;
};

export type UpdateEmailDistributionListsMutation = {
	updateEmailDistributionLists?: {
		__typename: 'EmailDistributionLists';
		id: string;
		email?: string | null;
		specialty?: string | null;
		createdAt: string;
		updatedAt: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type DeleteEmailDistributionListsMutationVariables = {
	input: DeleteEmailDistributionListsInput;
	condition?: ModelEmailDistributionListsConditionInput | null;
};

export type DeleteEmailDistributionListsMutation = {
	deleteEmailDistributionLists?: {
		__typename: 'EmailDistributionLists';
		id: string;
		email?: string | null;
		specialty?: string | null;
		createdAt: string;
		updatedAt: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type CreateSecretaryHospitalDoctorMutationVariables = {
	input: CreateSecretaryHospitalDoctorInput;
	condition?: ModelSecretaryHospitalDoctorConditionInput | null;
};

export type CreateSecretaryHospitalDoctorMutation = {
	createSecretaryHospitalDoctor?: {
		__typename: 'SecretaryHospitalDoctor';
		id: string;
		hospitalDoctorID: string;
		secretaryID: string;
		hasHistoryPermission?: boolean | null;
		hasSurgeryPermission?: boolean | null;
		hasPrescriptionsPermission?: boolean | null;
		hasFilesPermission?: boolean | null;
		hasNotesPermission?: boolean | null;
		hasClaimsPermission?: boolean | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		secretary?: {
			__typename: 'Secretary';
			id: string;
			name?: string | null;
			email: string;
			firstTime?: boolean | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
		hospitalDoctor?: {
			__typename: 'HospitalDoctor';
			id: string;
			doctorID: string;
			hospitalID: string;
			lastWaitingListID: string;
			hospitalLogo?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospital?: {
				__typename: 'Hospital';
				id: string;
				name: string;
				type?: string | null;
				province?: string | null;
				town?: string | null;
				sector?: string | null;
				phone?: string | null;
				address?: string | null;
				pssCode?: string | null;
				rnc?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
	} | null;
};

export type UpdateSecretaryHospitalDoctorMutationVariables = {
	input: UpdateSecretaryHospitalDoctorInput;
	condition?: ModelSecretaryHospitalDoctorConditionInput | null;
};

export type UpdateSecretaryHospitalDoctorMutation = {
	updateSecretaryHospitalDoctor?: {
		__typename: 'SecretaryHospitalDoctor';
		id: string;
		hospitalDoctorID: string;
		secretaryID: string;
		hasHistoryPermission?: boolean | null;
		hasSurgeryPermission?: boolean | null;
		hasPrescriptionsPermission?: boolean | null;
		hasFilesPermission?: boolean | null;
		hasNotesPermission?: boolean | null;
		hasClaimsPermission?: boolean | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		secretary?: {
			__typename: 'Secretary';
			id: string;
			name?: string | null;
			email: string;
			firstTime?: boolean | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
		hospitalDoctor?: {
			__typename: 'HospitalDoctor';
			id: string;
			doctorID: string;
			hospitalID: string;
			lastWaitingListID: string;
			hospitalLogo?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospital?: {
				__typename: 'Hospital';
				id: string;
				name: string;
				type?: string | null;
				province?: string | null;
				town?: string | null;
				sector?: string | null;
				phone?: string | null;
				address?: string | null;
				pssCode?: string | null;
				rnc?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
	} | null;
};

export type DeleteSecretaryHospitalDoctorMutationVariables = {
	input: DeleteSecretaryHospitalDoctorInput;
	condition?: ModelSecretaryHospitalDoctorConditionInput | null;
};

export type DeleteSecretaryHospitalDoctorMutation = {
	deleteSecretaryHospitalDoctor?: {
		__typename: 'SecretaryHospitalDoctor';
		id: string;
		hospitalDoctorID: string;
		secretaryID: string;
		hasHistoryPermission?: boolean | null;
		hasSurgeryPermission?: boolean | null;
		hasPrescriptionsPermission?: boolean | null;
		hasFilesPermission?: boolean | null;
		hasNotesPermission?: boolean | null;
		hasClaimsPermission?: boolean | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		secretary?: {
			__typename: 'Secretary';
			id: string;
			name?: string | null;
			email: string;
			firstTime?: boolean | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
		hospitalDoctor?: {
			__typename: 'HospitalDoctor';
			id: string;
			doctorID: string;
			hospitalID: string;
			lastWaitingListID: string;
			hospitalLogo?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospital?: {
				__typename: 'Hospital';
				id: string;
				name: string;
				type?: string | null;
				province?: string | null;
				town?: string | null;
				sector?: string | null;
				phone?: string | null;
				address?: string | null;
				pssCode?: string | null;
				rnc?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
	} | null;
};

export type CreateSecretaryHospitalDoctorClientMutationVariables = {
	input: CreateSecretaryHospitalDoctorClientInput;
	condition?: ModelSecretaryHospitalDoctorClientConditionInput | null;
};

export type CreateSecretaryHospitalDoctorClientMutation = {
	createSecretaryHospitalDoctorClient?: {
		__typename: 'SecretaryHospitalDoctorClient';
		id: string;
		hospitalDoctorID?: string | null;
		secretaryID?: string | null;
		clientID?: string | null;
		note?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type UpdateSecretaryHospitalDoctorClientMutationVariables = {
	input: UpdateSecretaryHospitalDoctorClientInput;
	condition?: ModelSecretaryHospitalDoctorClientConditionInput | null;
};

export type UpdateSecretaryHospitalDoctorClientMutation = {
	updateSecretaryHospitalDoctorClient?: {
		__typename: 'SecretaryHospitalDoctorClient';
		id: string;
		hospitalDoctorID?: string | null;
		secretaryID?: string | null;
		clientID?: string | null;
		note?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type DeleteSecretaryHospitalDoctorClientMutationVariables = {
	input: DeleteSecretaryHospitalDoctorClientInput;
	condition?: ModelSecretaryHospitalDoctorClientConditionInput | null;
};

export type DeleteSecretaryHospitalDoctorClientMutation = {
	deleteSecretaryHospitalDoctorClient?: {
		__typename: 'SecretaryHospitalDoctorClient';
		id: string;
		hospitalDoctorID?: string | null;
		secretaryID?: string | null;
		clientID?: string | null;
		note?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type CreateMemberHospitalDoctorClientMutationVariables = {
	input: CreateMemberHospitalDoctorClientInput;
	condition?: ModelMemberHospitalDoctorClientConditionInput | null;
};

export type CreateMemberHospitalDoctorClientMutation = {
	createMemberHospitalDoctorClient?: {
		__typename: 'MemberHospitalDoctorClient';
		id: string;
		hospitalDoctorID?: string | null;
		memberID?: string | null;
		ownerID?: string | null;
		clientID?: string | null;
		note?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		owner?: {
			__typename: 'HospitalDoctor';
			id: string;
			doctorID: string;
			hospitalID: string;
			lastWaitingListID: string;
			hospitalLogo?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospital?: {
				__typename: 'Hospital';
				id: string;
				name: string;
				type?: string | null;
				province?: string | null;
				town?: string | null;
				sector?: string | null;
				phone?: string | null;
				address?: string | null;
				pssCode?: string | null;
				rnc?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
	} | null;
};

export type UpdateMemberHospitalDoctorClientMutationVariables = {
	input: UpdateMemberHospitalDoctorClientInput;
	condition?: ModelMemberHospitalDoctorClientConditionInput | null;
};

export type UpdateMemberHospitalDoctorClientMutation = {
	updateMemberHospitalDoctorClient?: {
		__typename: 'MemberHospitalDoctorClient';
		id: string;
		hospitalDoctorID?: string | null;
		memberID?: string | null;
		ownerID?: string | null;
		clientID?: string | null;
		note?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		owner?: {
			__typename: 'HospitalDoctor';
			id: string;
			doctorID: string;
			hospitalID: string;
			lastWaitingListID: string;
			hospitalLogo?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospital?: {
				__typename: 'Hospital';
				id: string;
				name: string;
				type?: string | null;
				province?: string | null;
				town?: string | null;
				sector?: string | null;
				phone?: string | null;
				address?: string | null;
				pssCode?: string | null;
				rnc?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
	} | null;
};

export type DeleteMemberHospitalDoctorClientMutationVariables = {
	input: DeleteMemberHospitalDoctorClientInput;
	condition?: ModelMemberHospitalDoctorClientConditionInput | null;
};

export type DeleteMemberHospitalDoctorClientMutation = {
	deleteMemberHospitalDoctorClient?: {
		__typename: 'MemberHospitalDoctorClient';
		id: string;
		hospitalDoctorID?: string | null;
		memberID?: string | null;
		ownerID?: string | null;
		clientID?: string | null;
		note?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		owner?: {
			__typename: 'HospitalDoctor';
			id: string;
			doctorID: string;
			hospitalID: string;
			lastWaitingListID: string;
			hospitalLogo?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospital?: {
				__typename: 'Hospital';
				id: string;
				name: string;
				type?: string | null;
				province?: string | null;
				town?: string | null;
				sector?: string | null;
				phone?: string | null;
				address?: string | null;
				pssCode?: string | null;
				rnc?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
	} | null;
};

export type CreateClientMutationVariables = {
	input: CreateClientInput;
	condition?: ModelClientConditionInput | null;
};

export type CreateClientMutation = {
	createClient?: {
		__typename: 'Client';
		id: string;
		identificationName?: IdentificationTypes | null;
		identificationData?: string | null;
		actualInsurance?: string | null;
		name: string;
		lastName: string;
		cellphoneNumber?: string | null;
		email?: string | null;
		bornDate?: string | null;
		parentOrTutorCedula?: string | null;
		parentOrTutorID?: string | null;
		gender?: SexType | null;
		phoneNumber?: string | null;
		addressStreet?: string | null;
		city?: string | null;
		isAdult?: boolean | null;
		sector?: string | null;
		bloodType?: string | null;
		company?: string | null;
		vitalSignsHeight?: string | null;
		vitalSignsWeight?: string | null;
		bodyMass?: string | null;
		vitalSignsBloodPressure?: string | null;
		heartRate?: string | null;
		respiratoryRate?: string | null;
		bodyTemperature?: string | null;
		oxygenSaturation?: string | null;
		occupation?: string | null;
		profileImage?: string | null;
		heartBeat?: string | null;
		weight?: string | null;
		height?: string | null;
		bloodPressure?: string | null;
		updateDateTime?: string | null;
		createdAt: string;
		updatedAt: string;
		code?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type UpdateClientMutationVariables = {
	input: UpdateClientInput;
	condition?: ModelClientConditionInput | null;
};

export type UpdateClientMutation = {
	updateClient?: {
		__typename: 'Client';
		id: string;
		identificationName?: IdentificationTypes | null;
		identificationData?: string | null;
		actualInsurance?: string | null;
		name: string;
		lastName: string;
		cellphoneNumber?: string | null;
		email?: string | null;
		bornDate?: string | null;
		parentOrTutorCedula?: string | null;
		parentOrTutorID?: string | null;
		gender?: SexType | null;
		phoneNumber?: string | null;
		addressStreet?: string | null;
		city?: string | null;
		isAdult?: boolean | null;
		sector?: string | null;
		bloodType?: string | null;
		company?: string | null;
		vitalSignsHeight?: string | null;
		vitalSignsWeight?: string | null;
		bodyMass?: string | null;
		vitalSignsBloodPressure?: string | null;
		heartRate?: string | null;
		respiratoryRate?: string | null;
		bodyTemperature?: string | null;
		oxygenSaturation?: string | null;
		occupation?: string | null;
		profileImage?: string | null;
		heartBeat?: string | null;
		weight?: string | null;
		height?: string | null;
		bloodPressure?: string | null;
		updateDateTime?: string | null;
		createdAt: string;
		updatedAt: string;
		code?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type DeleteClientMutationVariables = {
	input: DeleteClientInput;
	condition?: ModelClientConditionInput | null;
};

export type DeleteClientMutation = {
	deleteClient?: {
		__typename: 'Client';
		id: string;
		identificationName?: IdentificationTypes | null;
		identificationData?: string | null;
		actualInsurance?: string | null;
		name: string;
		lastName: string;
		cellphoneNumber?: string | null;
		email?: string | null;
		bornDate?: string | null;
		parentOrTutorCedula?: string | null;
		parentOrTutorID?: string | null;
		gender?: SexType | null;
		phoneNumber?: string | null;
		addressStreet?: string | null;
		city?: string | null;
		isAdult?: boolean | null;
		sector?: string | null;
		bloodType?: string | null;
		company?: string | null;
		vitalSignsHeight?: string | null;
		vitalSignsWeight?: string | null;
		bodyMass?: string | null;
		vitalSignsBloodPressure?: string | null;
		heartRate?: string | null;
		respiratoryRate?: string | null;
		bodyTemperature?: string | null;
		oxygenSaturation?: string | null;
		occupation?: string | null;
		profileImage?: string | null;
		heartBeat?: string | null;
		weight?: string | null;
		height?: string | null;
		bloodPressure?: string | null;
		updateDateTime?: string | null;
		createdAt: string;
		updatedAt: string;
		code?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type CreateClientImageSectionMutationVariables = {
	input: CreateClientImageSectionInput;
	condition?: ModelClientImageSectionConditionInput | null;
};

export type CreateClientImageSectionMutation = {
	createClientImageSection?: {
		__typename: 'ClientImageSection';
		id: string;
		clientHospitalDoctorID?: string | null;
		date?: string | null;
		description?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type UpdateClientImageSectionMutationVariables = {
	input: UpdateClientImageSectionInput;
	condition?: ModelClientImageSectionConditionInput | null;
};

export type UpdateClientImageSectionMutation = {
	updateClientImageSection?: {
		__typename: 'ClientImageSection';
		id: string;
		clientHospitalDoctorID?: string | null;
		date?: string | null;
		description?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type DeleteClientImageSectionMutationVariables = {
	input: DeleteClientImageSectionInput;
	condition?: ModelClientImageSectionConditionInput | null;
};

export type DeleteClientImageSectionMutation = {
	deleteClientImageSection?: {
		__typename: 'ClientImageSection';
		id: string;
		clientHospitalDoctorID?: string | null;
		date?: string | null;
		description?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type CreatePosibleClientMutationVariables = {
	input: CreatePosibleClientInput;
	condition?: ModelPosibleClientConditionInput | null;
};

export type CreatePosibleClientMutation = {
	createPosibleClient?: {
		__typename: 'PosibleClient';
		id: string;
		phoneNumber?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type UpdatePosibleClientMutationVariables = {
	input: UpdatePosibleClientInput;
	condition?: ModelPosibleClientConditionInput | null;
};

export type UpdatePosibleClientMutation = {
	updatePosibleClient?: {
		__typename: 'PosibleClient';
		id: string;
		phoneNumber?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type DeletePosibleClientMutationVariables = {
	input: DeletePosibleClientInput;
	condition?: ModelPosibleClientConditionInput | null;
};

export type DeletePosibleClientMutation = {
	deletePosibleClient?: {
		__typename: 'PosibleClient';
		id: string;
		phoneNumber?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type CreateClientImageMutationVariables = {
	input: CreateClientImageInput;
	condition?: ModelClientImageConditionInput | null;
};

export type CreateClientImageMutation = {
	createClientImage?: {
		__typename: 'ClientImage';
		id: string;
		pathFile?: string | null;
		description?: string | null;
		ClientImageDateID?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type UpdateClientImageMutationVariables = {
	input: UpdateClientImageInput;
	condition?: ModelClientImageConditionInput | null;
};

export type UpdateClientImageMutation = {
	updateClientImage?: {
		__typename: 'ClientImage';
		id: string;
		pathFile?: string | null;
		description?: string | null;
		ClientImageDateID?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type DeleteClientImageMutationVariables = {
	input: DeleteClientImageInput;
	condition?: ModelClientImageConditionInput | null;
};

export type DeleteClientImageMutation = {
	deleteClientImage?: {
		__typename: 'ClientImage';
		id: string;
		pathFile?: string | null;
		description?: string | null;
		ClientImageDateID?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type CreateConsultationDiagnosticMutationVariables = {
	input: CreateConsultationDiagnosticInput;
	condition?: ModelConsultationDiagnosticConditionInput | null;
};

export type CreateConsultationDiagnosticMutation = {
	createConsultationDiagnostic?: {
		__typename: 'ConsultationDiagnostic';
		id: string;
		clientConsultationID: string;
		name?: string | null;
		code?: string | null;
		type?: DiagnosticTypes | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type UpdateConsultationDiagnosticMutationVariables = {
	input: UpdateConsultationDiagnosticInput;
	condition?: ModelConsultationDiagnosticConditionInput | null;
};

export type UpdateConsultationDiagnosticMutation = {
	updateConsultationDiagnostic?: {
		__typename: 'ConsultationDiagnostic';
		id: string;
		clientConsultationID: string;
		name?: string | null;
		code?: string | null;
		type?: DiagnosticTypes | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type DeleteConsultationDiagnosticMutationVariables = {
	input: DeleteConsultationDiagnosticInput;
	condition?: ModelConsultationDiagnosticConditionInput | null;
};

export type DeleteConsultationDiagnosticMutation = {
	deleteConsultationDiagnostic?: {
		__typename: 'ConsultationDiagnostic';
		id: string;
		clientConsultationID: string;
		name?: string | null;
		code?: string | null;
		type?: DiagnosticTypes | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type CreateClientConsultationMutationVariables = {
	input: CreateClientConsultationInput;
	condition?: ModelClientConsultationConditionInput | null;
};

export type CreateClientConsultationMutation = {
	createClientConsultation?: {
		__typename: 'ClientConsultation';
		id: string;
		clientHospitalDoctorID: string;
		doctorID?: string | null;
		visitReason?: string | null;
		visitDescription?: string | null;
		physicTestNote?: string | null;
		height?: string | null;
		weight?: string | null;
		bodyMass?: string | null;
		bloodPressure?: string | null;
		heartRate?: string | null;
		respiratoryRate?: string | null;
		bodyTemperature?: string | null;
		oxygenSaturation?: string | null;
		clientMedicinePrescriptionID?: string | null;
		clientAnalisisPrescriptionID?: string | null;
		clientFreePrescriptionID?: string | null;
		treatment?: string | null;
		procedureName?: string | null;
		procedureDescription?: string | null;
		createdAt?: string | null;
		updatedAt?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		doctor?: {
			__typename: 'Doctor';
			id: string;
			name?: string | null;
			specialty?: string | null;
			exequator?: string | null;
			email: string;
			firstTime?: boolean | null;
			studyPlace?: string | null;
			isBeenReview?: boolean | null;
			isFirstAddSecretary?: boolean | null;
			hasTrialOnCreation?: boolean | null;
			title?: string | null;
			phone?: string | null;
			code?: string | null;
			password?: string | null;
			isAdmin?: boolean | null;
			daysOfTrial?: number | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type UpdateClientConsultationMutationVariables = {
	input: UpdateClientConsultationInput;
	condition?: ModelClientConsultationConditionInput | null;
};

export type UpdateClientConsultationMutation = {
	updateClientConsultation?: {
		__typename: 'ClientConsultation';
		id: string;
		clientHospitalDoctorID: string;
		doctorID?: string | null;
		visitReason?: string | null;
		visitDescription?: string | null;
		physicTestNote?: string | null;
		height?: string | null;
		weight?: string | null;
		bodyMass?: string | null;
		bloodPressure?: string | null;
		heartRate?: string | null;
		respiratoryRate?: string | null;
		bodyTemperature?: string | null;
		oxygenSaturation?: string | null;
		clientMedicinePrescriptionID?: string | null;
		clientAnalisisPrescriptionID?: string | null;
		clientFreePrescriptionID?: string | null;
		treatment?: string | null;
		procedureName?: string | null;
		procedureDescription?: string | null;
		createdAt?: string | null;
		updatedAt?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		doctor?: {
			__typename: 'Doctor';
			id: string;
			name?: string | null;
			specialty?: string | null;
			exequator?: string | null;
			email: string;
			firstTime?: boolean | null;
			studyPlace?: string | null;
			isBeenReview?: boolean | null;
			isFirstAddSecretary?: boolean | null;
			hasTrialOnCreation?: boolean | null;
			title?: string | null;
			phone?: string | null;
			code?: string | null;
			password?: string | null;
			isAdmin?: boolean | null;
			daysOfTrial?: number | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type DeleteClientConsultationMutationVariables = {
	input: DeleteClientConsultationInput;
	condition?: ModelClientConsultationConditionInput | null;
};

export type DeleteClientConsultationMutation = {
	deleteClientConsultation?: {
		__typename: 'ClientConsultation';
		id: string;
		clientHospitalDoctorID: string;
		doctorID?: string | null;
		visitReason?: string | null;
		visitDescription?: string | null;
		physicTestNote?: string | null;
		height?: string | null;
		weight?: string | null;
		bodyMass?: string | null;
		bloodPressure?: string | null;
		heartRate?: string | null;
		respiratoryRate?: string | null;
		bodyTemperature?: string | null;
		oxygenSaturation?: string | null;
		clientMedicinePrescriptionID?: string | null;
		clientAnalisisPrescriptionID?: string | null;
		clientFreePrescriptionID?: string | null;
		treatment?: string | null;
		procedureName?: string | null;
		procedureDescription?: string | null;
		createdAt?: string | null;
		updatedAt?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		doctor?: {
			__typename: 'Doctor';
			id: string;
			name?: string | null;
			specialty?: string | null;
			exequator?: string | null;
			email: string;
			firstTime?: boolean | null;
			studyPlace?: string | null;
			isBeenReview?: boolean | null;
			isFirstAddSecretary?: boolean | null;
			hasTrialOnCreation?: boolean | null;
			title?: string | null;
			phone?: string | null;
			code?: string | null;
			password?: string | null;
			isAdmin?: boolean | null;
			daysOfTrial?: number | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type CreateMedicineMutationVariables = {
	input: CreateMedicineInput;
	condition?: ModelMedicineConditionInput | null;
};

export type CreateMedicineMutation = {
	createMedicine?: {
		__typename: 'Medicine';
		id: string;
		name: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type UpdateMedicineMutationVariables = {
	input: UpdateMedicineInput;
	condition?: ModelMedicineConditionInput | null;
};

export type UpdateMedicineMutation = {
	updateMedicine?: {
		__typename: 'Medicine';
		id: string;
		name: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type DeleteMedicineMutationVariables = {
	input: DeleteMedicineInput;
	condition?: ModelMedicineConditionInput | null;
};

export type DeleteMedicineMutation = {
	deleteMedicine?: {
		__typename: 'Medicine';
		id: string;
		name: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type CreateClientAllergyMutationVariables = {
	input: CreateClientAllergyInput;
	condition?: ModelClientAllergyConditionInput | null;
};

export type CreateClientAllergyMutation = {
	createClientAllergy?: {
		__typename: 'ClientAllergy';
		id: string;
		clientID: string;
		name: string;
		status?: AllergyStatus | null;
		note?: string | null;
		diagnosedDate?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type UpdateClientAllergyMutationVariables = {
	input: UpdateClientAllergyInput;
	condition?: ModelClientAllergyConditionInput | null;
};

export type UpdateClientAllergyMutation = {
	updateClientAllergy?: {
		__typename: 'ClientAllergy';
		id: string;
		clientID: string;
		name: string;
		status?: AllergyStatus | null;
		note?: string | null;
		diagnosedDate?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type DeleteClientAllergyMutationVariables = {
	input: DeleteClientAllergyInput;
	condition?: ModelClientAllergyConditionInput | null;
};

export type DeleteClientAllergyMutation = {
	deleteClientAllergy?: {
		__typename: 'ClientAllergy';
		id: string;
		clientID: string;
		name: string;
		status?: AllergyStatus | null;
		note?: string | null;
		diagnosedDate?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type CreateClientDiseaseMutationVariables = {
	input: CreateClientDiseaseInput;
	condition?: ModelClientDiseaseConditionInput | null;
};

export type CreateClientDiseaseMutation = {
	createClientDisease?: {
		__typename: 'ClientDisease';
		id: string;
		clientID: string;
		name: string;
		status?: AllergyStatus | null;
		note?: string | null;
		diagnosedDate?: string | null;
		updatedAt?: string | null;
		createdAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type UpdateClientDiseaseMutationVariables = {
	input: UpdateClientDiseaseInput;
	condition?: ModelClientDiseaseConditionInput | null;
};

export type UpdateClientDiseaseMutation = {
	updateClientDisease?: {
		__typename: 'ClientDisease';
		id: string;
		clientID: string;
		name: string;
		status?: AllergyStatus | null;
		note?: string | null;
		diagnosedDate?: string | null;
		updatedAt?: string | null;
		createdAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type DeleteClientDiseaseMutationVariables = {
	input: DeleteClientDiseaseInput;
	condition?: ModelClientDiseaseConditionInput | null;
};

export type DeleteClientDiseaseMutation = {
	deleteClientDisease?: {
		__typename: 'ClientDisease';
		id: string;
		clientID: string;
		name: string;
		status?: AllergyStatus | null;
		note?: string | null;
		diagnosedDate?: string | null;
		updatedAt?: string | null;
		createdAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type CreateClientProcedureMutationVariables = {
	input: CreateClientProcedureInput;
	condition?: ModelClientProcedureConditionInput | null;
};

export type CreateClientProcedureMutation = {
	createClientProcedure?: {
		__typename: 'ClientProcedure';
		id: string;
		clientID: string;
		name: string;
		note?: string | null;
		realizationDate?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type UpdateClientProcedureMutationVariables = {
	input: UpdateClientProcedureInput;
	condition?: ModelClientProcedureConditionInput | null;
};

export type UpdateClientProcedureMutation = {
	updateClientProcedure?: {
		__typename: 'ClientProcedure';
		id: string;
		clientID: string;
		name: string;
		note?: string | null;
		realizationDate?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type DeleteClientProcedureMutationVariables = {
	input: DeleteClientProcedureInput;
	condition?: ModelClientProcedureConditionInput | null;
};

export type DeleteClientProcedureMutation = {
	deleteClientProcedure?: {
		__typename: 'ClientProcedure';
		id: string;
		clientID: string;
		name: string;
		note?: string | null;
		realizationDate?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type CreateClientHospitalizationMutationVariables = {
	input: CreateClientHospitalizationInput;
	condition?: ModelClientHospitalizationConditionInput | null;
};

export type CreateClientHospitalizationMutation = {
	createClientHospitalization?: {
		__typename: 'ClientHospitalization';
		id: string;
		clientID: string;
		name: string;
		note?: string | null;
		date?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type UpdateClientHospitalizationMutationVariables = {
	input: UpdateClientHospitalizationInput;
	condition?: ModelClientHospitalizationConditionInput | null;
};

export type UpdateClientHospitalizationMutation = {
	updateClientHospitalization?: {
		__typename: 'ClientHospitalization';
		id: string;
		clientID: string;
		name: string;
		note?: string | null;
		date?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type DeleteClientHospitalizationMutationVariables = {
	input: DeleteClientHospitalizationInput;
	condition?: ModelClientHospitalizationConditionInput | null;
};

export type DeleteClientHospitalizationMutation = {
	deleteClientHospitalization?: {
		__typename: 'ClientHospitalization';
		id: string;
		clientID: string;
		name: string;
		note?: string | null;
		date?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type CreateClientFamilyHistoryMutationVariables = {
	input: CreateClientFamilyHistoryInput;
	condition?: ModelClientFamilyHistoryConditionInput | null;
};

export type CreateClientFamilyHistoryMutation = {
	createClientFamilyHistory?: {
		__typename: 'ClientFamilyHistory';
		id: string;
		clientID: string;
		familyMember: HistoryFamilyMember;
		mellitusDiabetes?: boolean | null;
		mellitusDiabetesNote?: string | null;
		arterialHypertension?: boolean | null;
		arterialHypertensionNote?: string | null;
		heartDisease?: boolean | null;
		heartDiseaseNote?: string | null;
		nephropathy?: boolean | null;
		nephropathyNote?: string | null;
		neuropathies?: boolean | null;
		neuropathiesNote?: string | null;
		epilepsy?: boolean | null;
		epilepsyNote?: string | null;
		rheumatoidArthritis?: boolean | null;
		rheumatoidArthritisNote?: string | null;
		psychiatricDiseases?: boolean | null;
		psychiatricDiseasesNote?: string | null;
		cancer?: boolean | null;
		cancerNote?: string | null;
		other?: boolean | null;
		otherNote?: string | null;
		updatedAt?: string | null;
		createdAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type UpdateClientFamilyHistoryMutationVariables = {
	input: UpdateClientFamilyHistoryInput;
	condition?: ModelClientFamilyHistoryConditionInput | null;
};

export type UpdateClientFamilyHistoryMutation = {
	updateClientFamilyHistory?: {
		__typename: 'ClientFamilyHistory';
		id: string;
		clientID: string;
		familyMember: HistoryFamilyMember;
		mellitusDiabetes?: boolean | null;
		mellitusDiabetesNote?: string | null;
		arterialHypertension?: boolean | null;
		arterialHypertensionNote?: string | null;
		heartDisease?: boolean | null;
		heartDiseaseNote?: string | null;
		nephropathy?: boolean | null;
		nephropathyNote?: string | null;
		neuropathies?: boolean | null;
		neuropathiesNote?: string | null;
		epilepsy?: boolean | null;
		epilepsyNote?: string | null;
		rheumatoidArthritis?: boolean | null;
		rheumatoidArthritisNote?: string | null;
		psychiatricDiseases?: boolean | null;
		psychiatricDiseasesNote?: string | null;
		cancer?: boolean | null;
		cancerNote?: string | null;
		other?: boolean | null;
		otherNote?: string | null;
		updatedAt?: string | null;
		createdAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type DeleteClientFamilyHistoryMutationVariables = {
	input: DeleteClientFamilyHistoryInput;
	condition?: ModelClientFamilyHistoryConditionInput | null;
};

export type DeleteClientFamilyHistoryMutation = {
	deleteClientFamilyHistory?: {
		__typename: 'ClientFamilyHistory';
		id: string;
		clientID: string;
		familyMember: HistoryFamilyMember;
		mellitusDiabetes?: boolean | null;
		mellitusDiabetesNote?: string | null;
		arterialHypertension?: boolean | null;
		arterialHypertensionNote?: string | null;
		heartDisease?: boolean | null;
		heartDiseaseNote?: string | null;
		nephropathy?: boolean | null;
		nephropathyNote?: string | null;
		neuropathies?: boolean | null;
		neuropathiesNote?: string | null;
		epilepsy?: boolean | null;
		epilepsyNote?: string | null;
		rheumatoidArthritis?: boolean | null;
		rheumatoidArthritisNote?: string | null;
		psychiatricDiseases?: boolean | null;
		psychiatricDiseasesNote?: string | null;
		cancer?: boolean | null;
		cancerNote?: string | null;
		other?: boolean | null;
		otherNote?: string | null;
		updatedAt?: string | null;
		createdAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type CreateClientLifeStyleMutationVariables = {
	input: CreateClientLifeStyleInput;
	condition?: ModelClientLifeStyleConditionInput | null;
};

export type CreateClientLifeStyleMutation = {
	createClientLifeStyle?: {
		__typename: 'ClientLifeStyle';
		id: string;
		clientID: string;
		lifeStyle: LifeStyle;
		name: string;
		description: string;
		updatedAt: string;
		createdAt: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type UpdateClientLifeStyleMutationVariables = {
	input: UpdateClientLifeStyleInput;
	condition?: ModelClientLifeStyleConditionInput | null;
};

export type UpdateClientLifeStyleMutation = {
	updateClientLifeStyle?: {
		__typename: 'ClientLifeStyle';
		id: string;
		clientID: string;
		lifeStyle: LifeStyle;
		name: string;
		description: string;
		updatedAt: string;
		createdAt: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type DeleteClientLifeStyleMutationVariables = {
	input: DeleteClientLifeStyleInput;
	condition?: ModelClientLifeStyleConditionInput | null;
};

export type DeleteClientLifeStyleMutation = {
	deleteClientLifeStyle?: {
		__typename: 'ClientLifeStyle';
		id: string;
		clientID: string;
		lifeStyle: LifeStyle;
		name: string;
		description: string;
		updatedAt: string;
		createdAt: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type CreateClientPrescriptionMutationVariables = {
	input: CreateClientPrescriptionInput;
	condition?: ModelClientPrescriptionConditionInput | null;
};

export type CreateClientPrescriptionMutation = {
	createClientPrescription?: {
		__typename: 'ClientPrescription';
		id: string;
		clientHospitalDoctorID: string;
		creationDate?: string | null;
		type: PrescriptionTypes;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type UpdateClientPrescriptionMutationVariables = {
	input: UpdateClientPrescriptionInput;
	condition?: ModelClientPrescriptionConditionInput | null;
};

export type UpdateClientPrescriptionMutation = {
	updateClientPrescription?: {
		__typename: 'ClientPrescription';
		id: string;
		clientHospitalDoctorID: string;
		creationDate?: string | null;
		type: PrescriptionTypes;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type DeleteClientPrescriptionMutationVariables = {
	input: DeleteClientPrescriptionInput;
	condition?: ModelClientPrescriptionConditionInput | null;
};

export type DeleteClientPrescriptionMutation = {
	deleteClientPrescription?: {
		__typename: 'ClientPrescription';
		id: string;
		clientHospitalDoctorID: string;
		creationDate?: string | null;
		type: PrescriptionTypes;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type CreateClientPrescriptionMedicineMutationVariables = {
	input: CreateClientPrescriptionMedicineInput;
	condition?: ModelClientPrescriptionMedicineConditionInput | null;
};

export type CreateClientPrescriptionMedicineMutation = {
	createClientPrescriptionMedicine?: {
		__typename: 'ClientPrescriptionMedicine';
		id: string;
		clientPrescriptionID: string;
		medicineID?: string | null;
		name?: string | null;
		take?: string | null;
		indication?: string | null;
		duration?: string | null;
		description?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type UpdateClientPrescriptionMedicineMutationVariables = {
	input: UpdateClientPrescriptionMedicineInput;
	condition?: ModelClientPrescriptionMedicineConditionInput | null;
};

export type UpdateClientPrescriptionMedicineMutation = {
	updateClientPrescriptionMedicine?: {
		__typename: 'ClientPrescriptionMedicine';
		id: string;
		clientPrescriptionID: string;
		medicineID?: string | null;
		name?: string | null;
		take?: string | null;
		indication?: string | null;
		duration?: string | null;
		description?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type DeleteClientPrescriptionMedicineMutationVariables = {
	input: DeleteClientPrescriptionMedicineInput;
	condition?: ModelClientPrescriptionMedicineConditionInput | null;
};

export type DeleteClientPrescriptionMedicineMutation = {
	deleteClientPrescriptionMedicine?: {
		__typename: 'ClientPrescriptionMedicine';
		id: string;
		clientPrescriptionID: string;
		medicineID?: string | null;
		name?: string | null;
		take?: string | null;
		indication?: string | null;
		duration?: string | null;
		description?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type CreateClientFreePrescriptionMutationVariables = {
	input: CreateClientFreePrescriptionInput;
	condition?: ModelClientFreePrescriptionConditionInput | null;
};

export type CreateClientFreePrescriptionMutation = {
	createClientFreePrescription?: {
		__typename: 'ClientFreePrescription';
		id: string;
		clientPrescriptionID: string;
		description?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type UpdateClientFreePrescriptionMutationVariables = {
	input: UpdateClientFreePrescriptionInput;
	condition?: ModelClientFreePrescriptionConditionInput | null;
};

export type UpdateClientFreePrescriptionMutation = {
	updateClientFreePrescription?: {
		__typename: 'ClientFreePrescription';
		id: string;
		clientPrescriptionID: string;
		description?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type DeleteClientFreePrescriptionMutationVariables = {
	input: DeleteClientFreePrescriptionInput;
	condition?: ModelClientFreePrescriptionConditionInput | null;
};

export type DeleteClientFreePrescriptionMutation = {
	deleteClientFreePrescription?: {
		__typename: 'ClientFreePrescription';
		id: string;
		clientPrescriptionID: string;
		description?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type CreateClientPrescriptionAnalisisMutationVariables = {
	input: CreateClientPrescriptionAnalisisInput;
	condition?: ModelClientPrescriptionAnalisisConditionInput | null;
};

export type CreateClientPrescriptionAnalisisMutation = {
	createClientPrescriptionAnalisis?: {
		__typename: 'ClientPrescriptionAnalisis';
		id: string;
		clientPrescriptionID: string;
		name?: string | null;
		description?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type UpdateClientPrescriptionAnalisisMutationVariables = {
	input: UpdateClientPrescriptionAnalisisInput;
	condition?: ModelClientPrescriptionAnalisisConditionInput | null;
};

export type UpdateClientPrescriptionAnalisisMutation = {
	updateClientPrescriptionAnalisis?: {
		__typename: 'ClientPrescriptionAnalisis';
		id: string;
		clientPrescriptionID: string;
		name?: string | null;
		description?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type DeleteClientPrescriptionAnalisisMutationVariables = {
	input: DeleteClientPrescriptionAnalisisInput;
	condition?: ModelClientPrescriptionAnalisisConditionInput | null;
};

export type DeleteClientPrescriptionAnalisisMutation = {
	deleteClientPrescriptionAnalisis?: {
		__typename: 'ClientPrescriptionAnalisis';
		id: string;
		clientPrescriptionID: string;
		name?: string | null;
		description?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type CreateDoctorLabImageMutationVariables = {
	input: CreateDoctorLabImageInput;
	condition?: ModelDoctorLabImageConditionInput | null;
};

export type CreateDoctorLabImageMutation = {
	createDoctorLabImage?: {
		__typename: 'DoctorLabImage';
		id: string;
		doctorID: string;
		labImageID: string;
		updatedAt?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		labImage?: {
			__typename: 'LabImage';
			id: string;
			name: string;
			description?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type UpdateDoctorLabImageMutationVariables = {
	input: UpdateDoctorLabImageInput;
	condition?: ModelDoctorLabImageConditionInput | null;
};

export type UpdateDoctorLabImageMutation = {
	updateDoctorLabImage?: {
		__typename: 'DoctorLabImage';
		id: string;
		doctorID: string;
		labImageID: string;
		updatedAt?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		labImage?: {
			__typename: 'LabImage';
			id: string;
			name: string;
			description?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type DeleteDoctorLabImageMutationVariables = {
	input: DeleteDoctorLabImageInput;
	condition?: ModelDoctorLabImageConditionInput | null;
};

export type DeleteDoctorLabImageMutation = {
	deleteDoctorLabImage?: {
		__typename: 'DoctorLabImage';
		id: string;
		doctorID: string;
		labImageID: string;
		updatedAt?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		labImage?: {
			__typename: 'LabImage';
			id: string;
			name: string;
			description?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type CreateLabImageMutationVariables = {
	input: CreateLabImageInput;
	condition?: ModelLabImageConditionInput | null;
};

export type CreateLabImageMutation = {
	createLabImage?: {
		__typename: 'LabImage';
		id: string;
		name: string;
		description?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type UpdateLabImageMutationVariables = {
	input: UpdateLabImageInput;
	condition?: ModelLabImageConditionInput | null;
};

export type UpdateLabImageMutation = {
	updateLabImage?: {
		__typename: 'LabImage';
		id: string;
		name: string;
		description?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type DeleteLabImageMutationVariables = {
	input: DeleteLabImageInput;
	condition?: ModelLabImageConditionInput | null;
};

export type DeleteLabImageMutation = {
	deleteLabImage?: {
		__typename: 'LabImage';
		id: string;
		name: string;
		description?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type CreatePharmacyMedicineMutationVariables = {
	input: CreatePharmacyMedicineInput;
	condition?: ModelPharmacyMedicineConditionInput | null;
};

export type CreatePharmacyMedicineMutation = {
	createPharmacyMedicine?: {
		__typename: 'PharmacyMedicine';
		id: string;
		name?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type UpdatePharmacyMedicineMutationVariables = {
	input: UpdatePharmacyMedicineInput;
	condition?: ModelPharmacyMedicineConditionInput | null;
};

export type UpdatePharmacyMedicineMutation = {
	updatePharmacyMedicine?: {
		__typename: 'PharmacyMedicine';
		id: string;
		name?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type DeletePharmacyMedicineMutationVariables = {
	input: DeletePharmacyMedicineInput;
	condition?: ModelPharmacyMedicineConditionInput | null;
};

export type DeletePharmacyMedicineMutation = {
	deletePharmacyMedicine?: {
		__typename: 'PharmacyMedicine';
		id: string;
		name?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type CreatePharmacyMedicineMeasureMutationVariables = {
	input: CreatePharmacyMedicineMeasureInput;
	condition?: ModelPharmacyMedicineMeasureConditionInput | null;
};

export type CreatePharmacyMedicineMeasureMutation = {
	createPharmacyMedicineMeasure?: {
		__typename: 'PharmacyMedicineMeasure';
		id: string;
		pharmacyMedicineID: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type UpdatePharmacyMedicineMeasureMutationVariables = {
	input: UpdatePharmacyMedicineMeasureInput;
	condition?: ModelPharmacyMedicineMeasureConditionInput | null;
};

export type UpdatePharmacyMedicineMeasureMutation = {
	updatePharmacyMedicineMeasure?: {
		__typename: 'PharmacyMedicineMeasure';
		id: string;
		pharmacyMedicineID: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type DeletePharmacyMedicineMeasureMutationVariables = {
	input: DeletePharmacyMedicineMeasureInput;
	condition?: ModelPharmacyMedicineMeasureConditionInput | null;
};

export type DeletePharmacyMedicineMeasureMutation = {
	deletePharmacyMedicineMeasure?: {
		__typename: 'PharmacyMedicineMeasure';
		id: string;
		pharmacyMedicineID: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type CreateTemplateMutationVariables = {
	input: CreateTemplateInput;
	condition?: ModelTemplateConditionInput | null;
};

export type CreateTemplateMutation = {
	createTemplate?: {
		__typename: 'Template';
		id: string;
		doctorID: string;
		templateName?: string | null;
		templateType: PrescriptionTypes;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type UpdateTemplateMutationVariables = {
	input: UpdateTemplateInput;
	condition?: ModelTemplateConditionInput | null;
};

export type UpdateTemplateMutation = {
	updateTemplate?: {
		__typename: 'Template';
		id: string;
		doctorID: string;
		templateName?: string | null;
		templateType: PrescriptionTypes;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type DeleteTemplateMutationVariables = {
	input: DeleteTemplateInput;
	condition?: ModelTemplateConditionInput | null;
};

export type DeleteTemplateMutation = {
	deleteTemplate?: {
		__typename: 'Template';
		id: string;
		doctorID: string;
		templateName?: string | null;
		templateType: PrescriptionTypes;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type CreateTemplateMedicineMutationVariables = {
	input: CreateTemplateMedicineInput;
	condition?: ModelTemplateMedicineConditionInput | null;
};

export type CreateTemplateMedicineMutation = {
	createTemplateMedicine?: {
		__typename: 'TemplateMedicine';
		id: string;
		templateID: string;
		medicineID: string;
		take?: string | null;
		indication?: string | null;
		duration?: string | null;
		description?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type UpdateTemplateMedicineMutationVariables = {
	input: UpdateTemplateMedicineInput;
	condition?: ModelTemplateMedicineConditionInput | null;
};

export type UpdateTemplateMedicineMutation = {
	updateTemplateMedicine?: {
		__typename: 'TemplateMedicine';
		id: string;
		templateID: string;
		medicineID: string;
		take?: string | null;
		indication?: string | null;
		duration?: string | null;
		description?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type DeleteTemplateMedicineMutationVariables = {
	input: DeleteTemplateMedicineInput;
	condition?: ModelTemplateMedicineConditionInput | null;
};

export type DeleteTemplateMedicineMutation = {
	deleteTemplateMedicine?: {
		__typename: 'TemplateMedicine';
		id: string;
		templateID: string;
		medicineID: string;
		take?: string | null;
		indication?: string | null;
		duration?: string | null;
		description?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type CreateDoctorRecentDiagnosisMutationVariables = {
	input: CreateDoctorRecentDiagnosisInput;
	condition?: ModelDoctorRecentDiagnosisConditionInput | null;
};

export type CreateDoctorRecentDiagnosisMutation = {
	createDoctorRecentDiagnosis?: {
		__typename: 'DoctorRecentDiagnosis';
		id: string;
		doctorID: string;
		name: string;
		code?: string | null;
		type?: DiagnosticTypes | null;
		updateDateTime?: string | null;
		createdAt?: string | null;
		updatedAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type UpdateDoctorRecentDiagnosisMutationVariables = {
	input: UpdateDoctorRecentDiagnosisInput;
	condition?: ModelDoctorRecentDiagnosisConditionInput | null;
};

export type UpdateDoctorRecentDiagnosisMutation = {
	updateDoctorRecentDiagnosis?: {
		__typename: 'DoctorRecentDiagnosis';
		id: string;
		doctorID: string;
		name: string;
		code?: string | null;
		type?: DiagnosticTypes | null;
		updateDateTime?: string | null;
		createdAt?: string | null;
		updatedAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type DeleteDoctorRecentDiagnosisMutationVariables = {
	input: DeleteDoctorRecentDiagnosisInput;
	condition?: ModelDoctorRecentDiagnosisConditionInput | null;
};

export type DeleteDoctorRecentDiagnosisMutation = {
	deleteDoctorRecentDiagnosis?: {
		__typename: 'DoctorRecentDiagnosis';
		id: string;
		doctorID: string;
		name: string;
		code?: string | null;
		type?: DiagnosticTypes | null;
		updateDateTime?: string | null;
		createdAt?: string | null;
		updatedAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type CreateTemplateLabImageMutationVariables = {
	input: CreateTemplateLabImageInput;
	condition?: ModelTemplateLabImageConditionInput | null;
};

export type CreateTemplateLabImageMutation = {
	createTemplateLabImage?: {
		__typename: 'TemplateLabImage';
		id: string;
		templateID: string;
		name?: string | null;
		description?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type UpdateTemplateLabImageMutationVariables = {
	input: UpdateTemplateLabImageInput;
	condition?: ModelTemplateLabImageConditionInput | null;
};

export type UpdateTemplateLabImageMutation = {
	updateTemplateLabImage?: {
		__typename: 'TemplateLabImage';
		id: string;
		templateID: string;
		name?: string | null;
		description?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type DeleteTemplateLabImageMutationVariables = {
	input: DeleteTemplateLabImageInput;
	condition?: ModelTemplateLabImageConditionInput | null;
};

export type DeleteTemplateLabImageMutation = {
	deleteTemplateLabImage?: {
		__typename: 'TemplateLabImage';
		id: string;
		templateID: string;
		name?: string | null;
		description?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type CreateDoctorMedicineMutationVariables = {
	input: CreateDoctorMedicineInput;
	condition?: ModelDoctorMedicineConditionInput | null;
};

export type CreateDoctorMedicineMutation = {
	createDoctorMedicine?: {
		__typename: 'DoctorMedicine';
		id: string;
		doctorID: string;
		medicineID: string;
		isDeleted?: boolean | null;
		updatedAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		medicine?: {
			__typename: 'Medicine';
			id: string;
			name: string;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type UpdateDoctorMedicineMutationVariables = {
	input: UpdateDoctorMedicineInput;
	condition?: ModelDoctorMedicineConditionInput | null;
};

export type UpdateDoctorMedicineMutation = {
	updateDoctorMedicine?: {
		__typename: 'DoctorMedicine';
		id: string;
		doctorID: string;
		medicineID: string;
		isDeleted?: boolean | null;
		updatedAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		medicine?: {
			__typename: 'Medicine';
			id: string;
			name: string;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type DeleteDoctorMedicineMutationVariables = {
	input: DeleteDoctorMedicineInput;
	condition?: ModelDoctorMedicineConditionInput | null;
};

export type DeleteDoctorMedicineMutation = {
	deleteDoctorMedicine?: {
		__typename: 'DoctorMedicine';
		id: string;
		doctorID: string;
		medicineID: string;
		isDeleted?: boolean | null;
		updatedAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		medicine?: {
			__typename: 'Medicine';
			id: string;
			name: string;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type CreateRecentDoctorMedicinePrescriptionMutationVariables = {
	input: CreateRecentDoctorMedicinePrescriptionInput;
	condition?: ModelRecentDoctorMedicinePrescriptionConditionInput | null;
};

export type CreateRecentDoctorMedicinePrescriptionMutation = {
	createRecentDoctorMedicinePrescription?: {
		__typename: 'RecentDoctorMedicinePrescription';
		id: string;
		doctorID: string;
		medicineID: string;
		take?: string | null;
		indication?: string | null;
		duration?: string | null;
		description?: string | null;
		updateDateTime?: string | null;
		createdAt?: string | null;
		updatedAt?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		medicine?: {
			__typename: 'Medicine';
			id: string;
			name: string;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type UpdateRecentDoctorMedicinePrescriptionMutationVariables = {
	input: UpdateRecentDoctorMedicinePrescriptionInput;
	condition?: ModelRecentDoctorMedicinePrescriptionConditionInput | null;
};

export type UpdateRecentDoctorMedicinePrescriptionMutation = {
	updateRecentDoctorMedicinePrescription?: {
		__typename: 'RecentDoctorMedicinePrescription';
		id: string;
		doctorID: string;
		medicineID: string;
		take?: string | null;
		indication?: string | null;
		duration?: string | null;
		description?: string | null;
		updateDateTime?: string | null;
		createdAt?: string | null;
		updatedAt?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		medicine?: {
			__typename: 'Medicine';
			id: string;
			name: string;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type DeleteRecentDoctorMedicinePrescriptionMutationVariables = {
	input: DeleteRecentDoctorMedicinePrescriptionInput;
	condition?: ModelRecentDoctorMedicinePrescriptionConditionInput | null;
};

export type DeleteRecentDoctorMedicinePrescriptionMutation = {
	deleteRecentDoctorMedicinePrescription?: {
		__typename: 'RecentDoctorMedicinePrescription';
		id: string;
		doctorID: string;
		medicineID: string;
		take?: string | null;
		indication?: string | null;
		duration?: string | null;
		description?: string | null;
		updateDateTime?: string | null;
		createdAt?: string | null;
		updatedAt?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		medicine?: {
			__typename: 'Medicine';
			id: string;
			name: string;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type CreateRecentDoctorLabImagePrescriptionMutationVariables = {
	input: CreateRecentDoctorLabImagePrescriptionInput;
	condition?: ModelRecentDoctorLabImagePrescriptionConditionInput | null;
};

export type CreateRecentDoctorLabImagePrescriptionMutation = {
	createRecentDoctorLabImagePrescription?: {
		__typename: 'RecentDoctorLabImagePrescription';
		id: string;
		doctorID: string;
		labImageID?: string | null;
		name?: string | null;
		description?: string | null;
		updateDateTime?: string | null;
		createdAt?: string | null;
		updatedAt?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type UpdateRecentDoctorLabImagePrescriptionMutationVariables = {
	input: UpdateRecentDoctorLabImagePrescriptionInput;
	condition?: ModelRecentDoctorLabImagePrescriptionConditionInput | null;
};

export type UpdateRecentDoctorLabImagePrescriptionMutation = {
	updateRecentDoctorLabImagePrescription?: {
		__typename: 'RecentDoctorLabImagePrescription';
		id: string;
		doctorID: string;
		labImageID?: string | null;
		name?: string | null;
		description?: string | null;
		updateDateTime?: string | null;
		createdAt?: string | null;
		updatedAt?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type DeleteRecentDoctorLabImagePrescriptionMutationVariables = {
	input: DeleteRecentDoctorLabImagePrescriptionInput;
	condition?: ModelRecentDoctorLabImagePrescriptionConditionInput | null;
};

export type DeleteRecentDoctorLabImagePrescriptionMutation = {
	deleteRecentDoctorLabImagePrescription?: {
		__typename: 'RecentDoctorLabImagePrescription';
		id: string;
		doctorID: string;
		labImageID?: string | null;
		name?: string | null;
		description?: string | null;
		updateDateTime?: string | null;
		createdAt?: string | null;
		updatedAt?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type CreateTemplateAnalisisMutationVariables = {
	input: CreateTemplateAnalisisInput;
	condition?: ModelTemplateAnalisisConditionInput | null;
};

export type CreateTemplateAnalisisMutation = {
	createTemplateAnalisis?: {
		__typename: 'TemplateAnalisis';
		id: string;
		templateID: string;
		analisisID: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type UpdateTemplateAnalisisMutationVariables = {
	input: UpdateTemplateAnalisisInput;
	condition?: ModelTemplateAnalisisConditionInput | null;
};

export type UpdateTemplateAnalisisMutation = {
	updateTemplateAnalisis?: {
		__typename: 'TemplateAnalisis';
		id: string;
		templateID: string;
		analisisID: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type DeleteTemplateAnalisisMutationVariables = {
	input: DeleteTemplateAnalisisInput;
	condition?: ModelTemplateAnalisisConditionInput | null;
};

export type DeleteTemplateAnalisisMutation = {
	deleteTemplateAnalisis?: {
		__typename: 'TemplateAnalisis';
		id: string;
		templateID: string;
		analisisID: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type CreateAnalisisMutationVariables = {
	input: CreateAnalisisInput;
	condition?: ModelAnalisisConditionInput | null;
};

export type CreateAnalisisMutation = {
	createAnalisis?: {
		__typename: 'Analisis';
		id: string;
		analisisName?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type UpdateAnalisisMutationVariables = {
	input: UpdateAnalisisInput;
	condition?: ModelAnalisisConditionInput | null;
};

export type UpdateAnalisisMutation = {
	updateAnalisis?: {
		__typename: 'Analisis';
		id: string;
		analisisName?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type DeleteAnalisisMutationVariables = {
	input: DeleteAnalisisInput;
	condition?: ModelAnalisisConditionInput | null;
};

export type DeleteAnalisisMutation = {
	deleteAnalisis?: {
		__typename: 'Analisis';
		id: string;
		analisisName?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type CreateClientActiveDiseaseMutationVariables = {
	input: CreateClientActiveDiseaseInput;
	condition?: ModelClientActiveDiseaseConditionInput | null;
};

export type CreateClientActiveDiseaseMutation = {
	createClientActiveDisease?: {
		__typename: 'ClientActiveDisease';
		id: string;
		clientID: string;
		name: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type UpdateClientActiveDiseaseMutationVariables = {
	input: UpdateClientActiveDiseaseInput;
	condition?: ModelClientActiveDiseaseConditionInput | null;
};

export type UpdateClientActiveDiseaseMutation = {
	updateClientActiveDisease?: {
		__typename: 'ClientActiveDisease';
		id: string;
		clientID: string;
		name: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type DeleteClientActiveDiseaseMutationVariables = {
	input: DeleteClientActiveDiseaseInput;
	condition?: ModelClientActiveDiseaseConditionInput | null;
};

export type DeleteClientActiveDiseaseMutation = {
	deleteClientActiveDisease?: {
		__typename: 'ClientActiveDisease';
		id: string;
		clientID: string;
		name: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type CreateClientHistoryMutationVariables = {
	input: CreateClientHistoryInput;
	condition?: ModelClientHistoryConditionInput | null;
};

export type CreateClientHistoryMutation = {
	createClientHistory?: {
		__typename: 'ClientHistory';
		id: string;
		clientHospitalDoctorID: string;
		title?: string | null;
		treatment?: string | null;
		endModifyDate?: string | null;
		authorizationNumber?: string | null;
		description?: string | null;
		authorizationDetail?: string | null;
		personalPathologicalHistory?: string | null;
		familyPathologicalHistory?: string | null;
		physicalTest?: string | null;
		createdDate?: string | null;
		insuranceID?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type UpdateClientHistoryMutationVariables = {
	input: UpdateClientHistoryInput;
	condition?: ModelClientHistoryConditionInput | null;
};

export type UpdateClientHistoryMutation = {
	updateClientHistory?: {
		__typename: 'ClientHistory';
		id: string;
		clientHospitalDoctorID: string;
		title?: string | null;
		treatment?: string | null;
		endModifyDate?: string | null;
		authorizationNumber?: string | null;
		description?: string | null;
		authorizationDetail?: string | null;
		personalPathologicalHistory?: string | null;
		familyPathologicalHistory?: string | null;
		physicalTest?: string | null;
		createdDate?: string | null;
		insuranceID?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type DeleteClientHistoryMutationVariables = {
	input: DeleteClientHistoryInput;
	condition?: ModelClientHistoryConditionInput | null;
};

export type DeleteClientHistoryMutation = {
	deleteClientHistory?: {
		__typename: 'ClientHistory';
		id: string;
		clientHospitalDoctorID: string;
		title?: string | null;
		treatment?: string | null;
		endModifyDate?: string | null;
		authorizationNumber?: string | null;
		description?: string | null;
		authorizationDetail?: string | null;
		personalPathologicalHistory?: string | null;
		familyPathologicalHistory?: string | null;
		physicalTest?: string | null;
		createdDate?: string | null;
		insuranceID?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type CreateClientNotesMutationVariables = {
	input: CreateClientNotesInput;
	condition?: ModelClientNotesConditionInput | null;
};

export type CreateClientNotesMutation = {
	createClientNotes?: {
		__typename: 'ClientNotes';
		id: string;
		clientHospitalDoctorID: string;
		title?: string | null;
		noteBody?: string | null;
		endModifyDate?: string | null;
		createdDate?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type UpdateClientNotesMutationVariables = {
	input: UpdateClientNotesInput;
	condition?: ModelClientNotesConditionInput | null;
};

export type UpdateClientNotesMutation = {
	updateClientNotes?: {
		__typename: 'ClientNotes';
		id: string;
		clientHospitalDoctorID: string;
		title?: string | null;
		noteBody?: string | null;
		endModifyDate?: string | null;
		createdDate?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type DeleteClientNotesMutationVariables = {
	input: DeleteClientNotesInput;
	condition?: ModelClientNotesConditionInput | null;
};

export type DeleteClientNotesMutation = {
	deleteClientNotes?: {
		__typename: 'ClientNotes';
		id: string;
		clientHospitalDoctorID: string;
		title?: string | null;
		noteBody?: string | null;
		endModifyDate?: string | null;
		createdDate?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type CreateClientProcedureSurgeryMutationVariables = {
	input: CreateClientProcedureSurgeryInput;
	condition?: ModelClientProcedureSurgeryConditionInput | null;
};

export type CreateClientProcedureSurgeryMutation = {
	createClientProcedureSurgery?: {
		__typename: 'ClientProcedureSurgery';
		id: string;
		clientHospitalDoctorID: string;
		title?: string | null;
		description?: string | null;
		endModifyDate?: string | null;
		createdDate?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type UpdateClientProcedureSurgeryMutationVariables = {
	input: UpdateClientProcedureSurgeryInput;
	condition?: ModelClientProcedureSurgeryConditionInput | null;
};

export type UpdateClientProcedureSurgeryMutation = {
	updateClientProcedureSurgery?: {
		__typename: 'ClientProcedureSurgery';
		id: string;
		clientHospitalDoctorID: string;
		title?: string | null;
		description?: string | null;
		endModifyDate?: string | null;
		createdDate?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type DeleteClientProcedureSurgeryMutationVariables = {
	input: DeleteClientProcedureSurgeryInput;
	condition?: ModelClientProcedureSurgeryConditionInput | null;
};

export type DeleteClientProcedureSurgeryMutation = {
	deleteClientProcedureSurgery?: {
		__typename: 'ClientProcedureSurgery';
		id: string;
		clientHospitalDoctorID: string;
		title?: string | null;
		description?: string | null;
		endModifyDate?: string | null;
		createdDate?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type CreateDoctorMutationVariables = {
	input: CreateDoctorInput;
	condition?: ModelDoctorConditionInput | null;
};

export type CreateDoctorMutation = {
	createDoctor?: {
		__typename: 'Doctor';
		id: string;
		name?: string | null;
		specialty?: string | null;
		exequator?: string | null;
		email: string;
		firstTime?: boolean | null;
		studyPlace?: string | null;
		isBeenReview?: boolean | null;
		isFirstAddSecretary?: boolean | null;
		hasTrialOnCreation?: boolean | null;
		title?: string | null;
		phone?: string | null;
		code?: string | null;
		password?: string | null;
		isAdmin?: boolean | null;
		daysOfTrial?: number | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type UpdateDoctorMutationVariables = {
	input: UpdateDoctorInput;
	condition?: ModelDoctorConditionInput | null;
};

export type UpdateDoctorMutation = {
	updateDoctor?: {
		__typename: 'Doctor';
		id: string;
		name?: string | null;
		specialty?: string | null;
		exequator?: string | null;
		email: string;
		firstTime?: boolean | null;
		studyPlace?: string | null;
		isBeenReview?: boolean | null;
		isFirstAddSecretary?: boolean | null;
		hasTrialOnCreation?: boolean | null;
		title?: string | null;
		phone?: string | null;
		code?: string | null;
		password?: string | null;
		isAdmin?: boolean | null;
		daysOfTrial?: number | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type DeleteDoctorMutationVariables = {
	input: DeleteDoctorInput;
	condition?: ModelDoctorConditionInput | null;
};

export type DeleteDoctorMutation = {
	deleteDoctor?: {
		__typename: 'Doctor';
		id: string;
		name?: string | null;
		specialty?: string | null;
		exequator?: string | null;
		email: string;
		firstTime?: boolean | null;
		studyPlace?: string | null;
		isBeenReview?: boolean | null;
		isFirstAddSecretary?: boolean | null;
		hasTrialOnCreation?: boolean | null;
		title?: string | null;
		phone?: string | null;
		code?: string | null;
		password?: string | null;
		isAdmin?: boolean | null;
		daysOfTrial?: number | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type CreatePlanMutationVariables = {
	input: CreatePlanInput;
	condition?: ModelPlanConditionInput | null;
};

export type CreatePlanMutation = {
	createPlan?: {
		__typename: 'Plan';
		id: string;
		refID?: number | null;
		title?: string | null;
		subTitle?: string | null;
		stripePlanKey?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type UpdatePlanMutationVariables = {
	input: UpdatePlanInput;
	condition?: ModelPlanConditionInput | null;
};

export type UpdatePlanMutation = {
	updatePlan?: {
		__typename: 'Plan';
		id: string;
		refID?: number | null;
		title?: string | null;
		subTitle?: string | null;
		stripePlanKey?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type DeletePlanMutationVariables = {
	input: DeletePlanInput;
	condition?: ModelPlanConditionInput | null;
};

export type DeletePlanMutation = {
	deletePlan?: {
		__typename: 'Plan';
		id: string;
		refID?: number | null;
		title?: string | null;
		subTitle?: string | null;
		stripePlanKey?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type CreateDoctorStripeCustomerSubscriptionMutationVariables = {
	input: CreateDoctorStripeCustomerSubscriptionInput;
	condition?: ModelDoctorStripeCustomerSubscriptionConditionInput | null;
};

export type CreateDoctorStripeCustomerSubscriptionMutation = {
	createDoctorStripeCustomerSubscription?: {
		__typename: 'DoctorStripeCustomerSubscription';
		id: string;
		doctorID: string;
		currentPlanID?: number | null;
		stripeCustomerID?: string | null;
		stripeSubscriptionID?: string | null;
		defaultCard?: string | null;
		nextPaymentDate?: string | null;
		cancelAtPeriodEnd?: boolean | null;
		trialsEndsAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type UpdateDoctorStripeCustomerSubscriptionMutationVariables = {
	input: UpdateDoctorStripeCustomerSubscriptionInput;
	condition?: ModelDoctorStripeCustomerSubscriptionConditionInput | null;
};

export type UpdateDoctorStripeCustomerSubscriptionMutation = {
	updateDoctorStripeCustomerSubscription?: {
		__typename: 'DoctorStripeCustomerSubscription';
		id: string;
		doctorID: string;
		currentPlanID?: number | null;
		stripeCustomerID?: string | null;
		stripeSubscriptionID?: string | null;
		defaultCard?: string | null;
		nextPaymentDate?: string | null;
		cancelAtPeriodEnd?: boolean | null;
		trialsEndsAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type DeleteDoctorStripeCustomerSubscriptionMutationVariables = {
	input: DeleteDoctorStripeCustomerSubscriptionInput;
	condition?: ModelDoctorStripeCustomerSubscriptionConditionInput | null;
};

export type DeleteDoctorStripeCustomerSubscriptionMutation = {
	deleteDoctorStripeCustomerSubscription?: {
		__typename: 'DoctorStripeCustomerSubscription';
		id: string;
		doctorID: string;
		currentPlanID?: number | null;
		stripeCustomerID?: string | null;
		stripeSubscriptionID?: string | null;
		defaultCard?: string | null;
		nextPaymentDate?: string | null;
		cancelAtPeriodEnd?: boolean | null;
		trialsEndsAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type CreateDoctorStripeCardsMutationVariables = {
	input: CreateDoctorStripeCardsInput;
	condition?: ModelDoctorStripeCardsConditionInput | null;
};

export type CreateDoctorStripeCardsMutation = {
	createDoctorStripeCards?: {
		__typename: 'DoctorStripeCards';
		id: string;
		DoctorID: string;
		cardLastDigits?: string | null;
		stripePaymentMethodID?: string | null;
		defaultCard?: boolean | null;
		cardBrand?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type UpdateDoctorStripeCardsMutationVariables = {
	input: UpdateDoctorStripeCardsInput;
	condition?: ModelDoctorStripeCardsConditionInput | null;
};

export type UpdateDoctorStripeCardsMutation = {
	updateDoctorStripeCards?: {
		__typename: 'DoctorStripeCards';
		id: string;
		DoctorID: string;
		cardLastDigits?: string | null;
		stripePaymentMethodID?: string | null;
		defaultCard?: boolean | null;
		cardBrand?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type DeleteDoctorStripeCardsMutationVariables = {
	input: DeleteDoctorStripeCardsInput;
	condition?: ModelDoctorStripeCardsConditionInput | null;
};

export type DeleteDoctorStripeCardsMutation = {
	deleteDoctorStripeCards?: {
		__typename: 'DoctorStripeCards';
		id: string;
		DoctorID: string;
		cardLastDigits?: string | null;
		stripePaymentMethodID?: string | null;
		defaultCard?: boolean | null;
		cardBrand?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type CreateHospitalMutationVariables = {
	input: CreateHospitalInput;
	condition?: ModelHospitalConditionInput | null;
};

export type CreateHospitalMutation = {
	createHospital?: {
		__typename: 'Hospital';
		id: string;
		name: string;
		type?: string | null;
		province?: string | null;
		town?: string | null;
		sector?: string | null;
		phone?: string | null;
		address?: string | null;
		pssCode?: string | null;
		rnc?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type UpdateHospitalMutationVariables = {
	input: UpdateHospitalInput;
	condition?: ModelHospitalConditionInput | null;
};

export type UpdateHospitalMutation = {
	updateHospital?: {
		__typename: 'Hospital';
		id: string;
		name: string;
		type?: string | null;
		province?: string | null;
		town?: string | null;
		sector?: string | null;
		phone?: string | null;
		address?: string | null;
		pssCode?: string | null;
		rnc?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type DeleteHospitalMutationVariables = {
	input: DeleteHospitalInput;
	condition?: ModelHospitalConditionInput | null;
};

export type DeleteHospitalMutation = {
	deleteHospital?: {
		__typename: 'Hospital';
		id: string;
		name: string;
		type?: string | null;
		province?: string | null;
		town?: string | null;
		sector?: string | null;
		phone?: string | null;
		address?: string | null;
		pssCode?: string | null;
		rnc?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type CreateHospitalDoctorMutationVariables = {
	input: CreateHospitalDoctorInput;
	condition?: ModelHospitalDoctorConditionInput | null;
};

export type CreateHospitalDoctorMutation = {
	createHospitalDoctor?: {
		__typename: 'HospitalDoctor';
		id: string;
		doctorID: string;
		hospitalID: string;
		lastWaitingListID: string;
		hospitalLogo?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		doctor?: {
			__typename: 'Doctor';
			id: string;
			name?: string | null;
			specialty?: string | null;
			exequator?: string | null;
			email: string;
			firstTime?: boolean | null;
			studyPlace?: string | null;
			isBeenReview?: boolean | null;
			isFirstAddSecretary?: boolean | null;
			hasTrialOnCreation?: boolean | null;
			title?: string | null;
			phone?: string | null;
			code?: string | null;
			password?: string | null;
			isAdmin?: boolean | null;
			daysOfTrial?: number | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
		hospital?: {
			__typename: 'Hospital';
			id: string;
			name: string;
			type?: string | null;
			province?: string | null;
			town?: string | null;
			sector?: string | null;
			phone?: string | null;
			address?: string | null;
			pssCode?: string | null;
			rnc?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type UpdateHospitalDoctorMutationVariables = {
	input: UpdateHospitalDoctorInput;
	condition?: ModelHospitalDoctorConditionInput | null;
};

export type UpdateHospitalDoctorMutation = {
	updateHospitalDoctor?: {
		__typename: 'HospitalDoctor';
		id: string;
		doctorID: string;
		hospitalID: string;
		lastWaitingListID: string;
		hospitalLogo?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		doctor?: {
			__typename: 'Doctor';
			id: string;
			name?: string | null;
			specialty?: string | null;
			exequator?: string | null;
			email: string;
			firstTime?: boolean | null;
			studyPlace?: string | null;
			isBeenReview?: boolean | null;
			isFirstAddSecretary?: boolean | null;
			hasTrialOnCreation?: boolean | null;
			title?: string | null;
			phone?: string | null;
			code?: string | null;
			password?: string | null;
			isAdmin?: boolean | null;
			daysOfTrial?: number | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
		hospital?: {
			__typename: 'Hospital';
			id: string;
			name: string;
			type?: string | null;
			province?: string | null;
			town?: string | null;
			sector?: string | null;
			phone?: string | null;
			address?: string | null;
			pssCode?: string | null;
			rnc?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type DeleteHospitalDoctorMutationVariables = {
	input: DeleteHospitalDoctorInput;
	condition?: ModelHospitalDoctorConditionInput | null;
};

export type DeleteHospitalDoctorMutation = {
	deleteHospitalDoctor?: {
		__typename: 'HospitalDoctor';
		id: string;
		doctorID: string;
		hospitalID: string;
		lastWaitingListID: string;
		hospitalLogo?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		doctor?: {
			__typename: 'Doctor';
			id: string;
			name?: string | null;
			specialty?: string | null;
			exequator?: string | null;
			email: string;
			firstTime?: boolean | null;
			studyPlace?: string | null;
			isBeenReview?: boolean | null;
			isFirstAddSecretary?: boolean | null;
			hasTrialOnCreation?: boolean | null;
			title?: string | null;
			phone?: string | null;
			code?: string | null;
			password?: string | null;
			isAdmin?: boolean | null;
			daysOfTrial?: number | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
		hospital?: {
			__typename: 'Hospital';
			id: string;
			name: string;
			type?: string | null;
			province?: string | null;
			town?: string | null;
			sector?: string | null;
			phone?: string | null;
			address?: string | null;
			pssCode?: string | null;
			rnc?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type CreateTeamsMutationVariables = {
	input: CreateTeamsInput;
	condition?: ModelTeamsConditionInput | null;
};

export type CreateTeamsMutation = {
	createTeams?: {
		__typename: 'Teams';
		id: string;
		OwnerID: string;
		MemberID: string;
		status?: TeamStatus | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		Owner?: {
			__typename: 'HospitalDoctor';
			id: string;
			doctorID: string;
			hospitalID: string;
			lastWaitingListID: string;
			hospitalLogo?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospital?: {
				__typename: 'Hospital';
				id: string;
				name: string;
				type?: string | null;
				province?: string | null;
				town?: string | null;
				sector?: string | null;
				phone?: string | null;
				address?: string | null;
				pssCode?: string | null;
				rnc?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
		Member?: {
			__typename: 'HospitalDoctor';
			id: string;
			doctorID: string;
			hospitalID: string;
			lastWaitingListID: string;
			hospitalLogo?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospital?: {
				__typename: 'Hospital';
				id: string;
				name: string;
				type?: string | null;
				province?: string | null;
				town?: string | null;
				sector?: string | null;
				phone?: string | null;
				address?: string | null;
				pssCode?: string | null;
				rnc?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
	} | null;
};

export type UpdateTeamsMutationVariables = {
	input: UpdateTeamsInput;
	condition?: ModelTeamsConditionInput | null;
};

export type UpdateTeamsMutation = {
	updateTeams?: {
		__typename: 'Teams';
		id: string;
		OwnerID: string;
		MemberID: string;
		status?: TeamStatus | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		Owner?: {
			__typename: 'HospitalDoctor';
			id: string;
			doctorID: string;
			hospitalID: string;
			lastWaitingListID: string;
			hospitalLogo?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospital?: {
				__typename: 'Hospital';
				id: string;
				name: string;
				type?: string | null;
				province?: string | null;
				town?: string | null;
				sector?: string | null;
				phone?: string | null;
				address?: string | null;
				pssCode?: string | null;
				rnc?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
		Member?: {
			__typename: 'HospitalDoctor';
			id: string;
			doctorID: string;
			hospitalID: string;
			lastWaitingListID: string;
			hospitalLogo?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospital?: {
				__typename: 'Hospital';
				id: string;
				name: string;
				type?: string | null;
				province?: string | null;
				town?: string | null;
				sector?: string | null;
				phone?: string | null;
				address?: string | null;
				pssCode?: string | null;
				rnc?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
	} | null;
};

export type DeleteTeamsMutationVariables = {
	input: DeleteTeamsInput;
	condition?: ModelTeamsConditionInput | null;
};

export type DeleteTeamsMutation = {
	deleteTeams?: {
		__typename: 'Teams';
		id: string;
		OwnerID: string;
		MemberID: string;
		status?: TeamStatus | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		Owner?: {
			__typename: 'HospitalDoctor';
			id: string;
			doctorID: string;
			hospitalID: string;
			lastWaitingListID: string;
			hospitalLogo?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospital?: {
				__typename: 'Hospital';
				id: string;
				name: string;
				type?: string | null;
				province?: string | null;
				town?: string | null;
				sector?: string | null;
				phone?: string | null;
				address?: string | null;
				pssCode?: string | null;
				rnc?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
		Member?: {
			__typename: 'HospitalDoctor';
			id: string;
			doctorID: string;
			hospitalID: string;
			lastWaitingListID: string;
			hospitalLogo?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospital?: {
				__typename: 'Hospital';
				id: string;
				name: string;
				type?: string | null;
				province?: string | null;
				town?: string | null;
				sector?: string | null;
				phone?: string | null;
				address?: string | null;
				pssCode?: string | null;
				rnc?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
	} | null;
};

export type CreateHospitalDoctorClienteMutationVariables = {
	input: CreateHospitalDoctorClienteInput;
	condition?: ModelHospitalDoctorClienteConditionInput | null;
};

export type CreateHospitalDoctorClienteMutation = {
	createHospitalDoctorCliente?: {
		__typename: 'HospitalDoctorCliente';
		id: string;
		clientID: string;
		hospitalDoctorID: string;
		lastHealthInsurranceID?: string | null;
		clientStatus?: string | null;
		note?: string | null;
		recordNo?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		client?: {
			__typename: 'Client';
			id: string;
			identificationName?: IdentificationTypes | null;
			identificationData?: string | null;
			actualInsurance?: string | null;
			name: string;
			lastName: string;
			cellphoneNumber?: string | null;
			email?: string | null;
			bornDate?: string | null;
			parentOrTutorCedula?: string | null;
			parentOrTutorID?: string | null;
			gender?: SexType | null;
			phoneNumber?: string | null;
			addressStreet?: string | null;
			city?: string | null;
			isAdult?: boolean | null;
			sector?: string | null;
			bloodType?: string | null;
			company?: string | null;
			vitalSignsHeight?: string | null;
			vitalSignsWeight?: string | null;
			bodyMass?: string | null;
			vitalSignsBloodPressure?: string | null;
			heartRate?: string | null;
			respiratoryRate?: string | null;
			bodyTemperature?: string | null;
			oxygenSaturation?: string | null;
			occupation?: string | null;
			profileImage?: string | null;
			heartBeat?: string | null;
			weight?: string | null;
			height?: string | null;
			bloodPressure?: string | null;
			updateDateTime?: string | null;
			createdAt: string;
			updatedAt: string;
			code?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
		} | null;
		hospitalDoctor?: {
			__typename: 'HospitalDoctor';
			id: string;
			doctorID: string;
			hospitalID: string;
			lastWaitingListID: string;
			hospitalLogo?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospital?: {
				__typename: 'Hospital';
				id: string;
				name: string;
				type?: string | null;
				province?: string | null;
				town?: string | null;
				sector?: string | null;
				phone?: string | null;
				address?: string | null;
				pssCode?: string | null;
				rnc?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
		clientInsurance?: {
			__typename: 'ClientInsurance';
			id: string;
			insuranceID?: string | null;
			clientID?: string | null;
			contractNumber?: string | null;
			affiliateNumber?: string | null;
			affiliateType?: AffiliateTypes | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			insurance?: {
				__typename: 'Insurance';
				id: string;
				name: string;
				allowClaim?: boolean | null;
				code?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
	} | null;
};

export type UpdateHospitalDoctorClienteMutationVariables = {
	input: UpdateHospitalDoctorClienteInput;
	condition?: ModelHospitalDoctorClienteConditionInput | null;
};

export type UpdateHospitalDoctorClienteMutation = {
	updateHospitalDoctorCliente?: {
		__typename: 'HospitalDoctorCliente';
		id: string;
		clientID: string;
		hospitalDoctorID: string;
		lastHealthInsurranceID?: string | null;
		clientStatus?: string | null;
		note?: string | null;
		recordNo?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		client?: {
			__typename: 'Client';
			id: string;
			identificationName?: IdentificationTypes | null;
			identificationData?: string | null;
			actualInsurance?: string | null;
			name: string;
			lastName: string;
			cellphoneNumber?: string | null;
			email?: string | null;
			bornDate?: string | null;
			parentOrTutorCedula?: string | null;
			parentOrTutorID?: string | null;
			gender?: SexType | null;
			phoneNumber?: string | null;
			addressStreet?: string | null;
			city?: string | null;
			isAdult?: boolean | null;
			sector?: string | null;
			bloodType?: string | null;
			company?: string | null;
			vitalSignsHeight?: string | null;
			vitalSignsWeight?: string | null;
			bodyMass?: string | null;
			vitalSignsBloodPressure?: string | null;
			heartRate?: string | null;
			respiratoryRate?: string | null;
			bodyTemperature?: string | null;
			oxygenSaturation?: string | null;
			occupation?: string | null;
			profileImage?: string | null;
			heartBeat?: string | null;
			weight?: string | null;
			height?: string | null;
			bloodPressure?: string | null;
			updateDateTime?: string | null;
			createdAt: string;
			updatedAt: string;
			code?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
		} | null;
		hospitalDoctor?: {
			__typename: 'HospitalDoctor';
			id: string;
			doctorID: string;
			hospitalID: string;
			lastWaitingListID: string;
			hospitalLogo?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospital?: {
				__typename: 'Hospital';
				id: string;
				name: string;
				type?: string | null;
				province?: string | null;
				town?: string | null;
				sector?: string | null;
				phone?: string | null;
				address?: string | null;
				pssCode?: string | null;
				rnc?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
		clientInsurance?: {
			__typename: 'ClientInsurance';
			id: string;
			insuranceID?: string | null;
			clientID?: string | null;
			contractNumber?: string | null;
			affiliateNumber?: string | null;
			affiliateType?: AffiliateTypes | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			insurance?: {
				__typename: 'Insurance';
				id: string;
				name: string;
				allowClaim?: boolean | null;
				code?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
	} | null;
};

export type DeleteHospitalDoctorClienteMutationVariables = {
	input: DeleteHospitalDoctorClienteInput;
	condition?: ModelHospitalDoctorClienteConditionInput | null;
};

export type DeleteHospitalDoctorClienteMutation = {
	deleteHospitalDoctorCliente?: {
		__typename: 'HospitalDoctorCliente';
		id: string;
		clientID: string;
		hospitalDoctorID: string;
		lastHealthInsurranceID?: string | null;
		clientStatus?: string | null;
		note?: string | null;
		recordNo?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		client?: {
			__typename: 'Client';
			id: string;
			identificationName?: IdentificationTypes | null;
			identificationData?: string | null;
			actualInsurance?: string | null;
			name: string;
			lastName: string;
			cellphoneNumber?: string | null;
			email?: string | null;
			bornDate?: string | null;
			parentOrTutorCedula?: string | null;
			parentOrTutorID?: string | null;
			gender?: SexType | null;
			phoneNumber?: string | null;
			addressStreet?: string | null;
			city?: string | null;
			isAdult?: boolean | null;
			sector?: string | null;
			bloodType?: string | null;
			company?: string | null;
			vitalSignsHeight?: string | null;
			vitalSignsWeight?: string | null;
			bodyMass?: string | null;
			vitalSignsBloodPressure?: string | null;
			heartRate?: string | null;
			respiratoryRate?: string | null;
			bodyTemperature?: string | null;
			oxygenSaturation?: string | null;
			occupation?: string | null;
			profileImage?: string | null;
			heartBeat?: string | null;
			weight?: string | null;
			height?: string | null;
			bloodPressure?: string | null;
			updateDateTime?: string | null;
			createdAt: string;
			updatedAt: string;
			code?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
		} | null;
		hospitalDoctor?: {
			__typename: 'HospitalDoctor';
			id: string;
			doctorID: string;
			hospitalID: string;
			lastWaitingListID: string;
			hospitalLogo?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospital?: {
				__typename: 'Hospital';
				id: string;
				name: string;
				type?: string | null;
				province?: string | null;
				town?: string | null;
				sector?: string | null;
				phone?: string | null;
				address?: string | null;
				pssCode?: string | null;
				rnc?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
		clientInsurance?: {
			__typename: 'ClientInsurance';
			id: string;
			insuranceID?: string | null;
			clientID?: string | null;
			contractNumber?: string | null;
			affiliateNumber?: string | null;
			affiliateType?: AffiliateTypes | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			insurance?: {
				__typename: 'Insurance';
				id: string;
				name: string;
				allowClaim?: boolean | null;
				code?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
	} | null;
};

export type CreateWaitListMutationVariables = {
	input: CreateWaitListInput;
	condition?: ModelWaitListConditionInput | null;
};

export type CreateWaitListMutation = {
	createWaitList?: {
		__typename: 'WaitList';
		id: string;
		hospitalDoctorID: string;
		createdAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		updatedAt: string;
	} | null;
};

export type UpdateWaitListMutationVariables = {
	input: UpdateWaitListInput;
	condition?: ModelWaitListConditionInput | null;
};

export type UpdateWaitListMutation = {
	updateWaitList?: {
		__typename: 'WaitList';
		id: string;
		hospitalDoctorID: string;
		createdAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		updatedAt: string;
	} | null;
};

export type DeleteWaitListMutationVariables = {
	input: DeleteWaitListInput;
	condition?: ModelWaitListConditionInput | null;
};

export type DeleteWaitListMutation = {
	deleteWaitList?: {
		__typename: 'WaitList';
		id: string;
		hospitalDoctorID: string;
		createdAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		updatedAt: string;
	} | null;
};

export type CreateWaitingListItemMutationVariables = {
	input: CreateWaitingListItemInput;
	condition?: ModelWaitingListItemConditionInput | null;
};

export type CreateWaitingListItemMutation = {
	createWaitingListItem?: {
		__typename: 'WaitingListItem';
		id: string;
		waitingListID: string;
		clientID: string;
		status: WaitingListItemStatus;
		clientHealthInsurrance?: string | null;
		positionNumber: number;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type UpdateWaitingListItemMutationVariables = {
	input: UpdateWaitingListItemInput;
	condition?: ModelWaitingListItemConditionInput | null;
};

export type UpdateWaitingListItemMutation = {
	updateWaitingListItem?: {
		__typename: 'WaitingListItem';
		id: string;
		waitingListID: string;
		clientID: string;
		status: WaitingListItemStatus;
		clientHealthInsurrance?: string | null;
		positionNumber: number;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type DeleteWaitingListItemMutationVariables = {
	input: DeleteWaitingListItemInput;
	condition?: ModelWaitingListItemConditionInput | null;
};

export type DeleteWaitingListItemMutation = {
	deleteWaitingListItem?: {
		__typename: 'WaitingListItem';
		id: string;
		waitingListID: string;
		clientID: string;
		status: WaitingListItemStatus;
		clientHealthInsurrance?: string | null;
		positionNumber: number;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type CreateClientInsuranceMutationVariables = {
	input: CreateClientInsuranceInput;
	condition?: ModelClientInsuranceConditionInput | null;
};

export type CreateClientInsuranceMutation = {
	createClientInsurance?: {
		__typename: 'ClientInsurance';
		id: string;
		insuranceID?: string | null;
		clientID?: string | null;
		contractNumber?: string | null;
		affiliateNumber?: string | null;
		affiliateType?: AffiliateTypes | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		insurance?: {
			__typename: 'Insurance';
			id: string;
			name: string;
			allowClaim?: boolean | null;
			code?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type UpdateClientInsuranceMutationVariables = {
	input: UpdateClientInsuranceInput;
	condition?: ModelClientInsuranceConditionInput | null;
};

export type UpdateClientInsuranceMutation = {
	updateClientInsurance?: {
		__typename: 'ClientInsurance';
		id: string;
		insuranceID?: string | null;
		clientID?: string | null;
		contractNumber?: string | null;
		affiliateNumber?: string | null;
		affiliateType?: AffiliateTypes | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		insurance?: {
			__typename: 'Insurance';
			id: string;
			name: string;
			allowClaim?: boolean | null;
			code?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type DeleteClientInsuranceMutationVariables = {
	input: DeleteClientInsuranceInput;
	condition?: ModelClientInsuranceConditionInput | null;
};

export type DeleteClientInsuranceMutation = {
	deleteClientInsurance?: {
		__typename: 'ClientInsurance';
		id: string;
		insuranceID?: string | null;
		clientID?: string | null;
		contractNumber?: string | null;
		affiliateNumber?: string | null;
		affiliateType?: AffiliateTypes | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		insurance?: {
			__typename: 'Insurance';
			id: string;
			name: string;
			allowClaim?: boolean | null;
			code?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type CreateInsuranceMutationVariables = {
	input: CreateInsuranceInput;
	condition?: ModelInsuranceConditionInput | null;
};

export type CreateInsuranceMutation = {
	createInsurance?: {
		__typename: 'Insurance';
		id: string;
		name: string;
		allowClaim?: boolean | null;
		code?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type UpdateInsuranceMutationVariables = {
	input: UpdateInsuranceInput;
	condition?: ModelInsuranceConditionInput | null;
};

export type UpdateInsuranceMutation = {
	updateInsurance?: {
		__typename: 'Insurance';
		id: string;
		name: string;
		allowClaim?: boolean | null;
		code?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type DeleteInsuranceMutationVariables = {
	input: DeleteInsuranceInput;
	condition?: ModelInsuranceConditionInput | null;
};

export type DeleteInsuranceMutation = {
	deleteInsurance?: {
		__typename: 'Insurance';
		id: string;
		name: string;
		allowClaim?: boolean | null;
		code?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type CreateClientClaimMutationVariables = {
	input: CreateClientClaimInput;
	condition?: ModelClientClaimConditionInput | null;
};

export type CreateClientClaimMutation = {
	createClientClaim?: {
		__typename: 'ClientClaim';
		id: string;
		clientHospitalDoctorID: string;
		healthInsuranceID: string;
		diagnostic: string;
		authorization: string;
		date: string;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		healthInsurance?: {
			__typename: 'Insurance';
			id: string;
			name: string;
			allowClaim?: boolean | null;
			code?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type UpdateClientClaimMutationVariables = {
	input: UpdateClientClaimInput;
	condition?: ModelClientClaimConditionInput | null;
};

export type UpdateClientClaimMutation = {
	updateClientClaim?: {
		__typename: 'ClientClaim';
		id: string;
		clientHospitalDoctorID: string;
		healthInsuranceID: string;
		diagnostic: string;
		authorization: string;
		date: string;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		healthInsurance?: {
			__typename: 'Insurance';
			id: string;
			name: string;
			allowClaim?: boolean | null;
			code?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type DeleteClientClaimMutationVariables = {
	input: DeleteClientClaimInput;
	condition?: ModelClientClaimConditionInput | null;
};

export type DeleteClientClaimMutation = {
	deleteClientClaim?: {
		__typename: 'ClientClaim';
		id: string;
		clientHospitalDoctorID: string;
		healthInsuranceID: string;
		diagnostic: string;
		authorization: string;
		date: string;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		healthInsurance?: {
			__typename: 'Insurance';
			id: string;
			name: string;
			allowClaim?: boolean | null;
			code?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type CreateClaimProcessMutationVariables = {
	input: CreateClaimProcessInput;
	condition?: ModelClaimProcessConditionInput | null;
};

export type CreateClaimProcessMutation = {
	createClaimProcess?: {
		__typename: 'ClaimProcess';
		id: string;
		claimID: string;
		name: string;
		code: string;
		claimed: string;
		difference: string;
		authorized: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type UpdateClaimProcessMutationVariables = {
	input: UpdateClaimProcessInput;
	condition?: ModelClaimProcessConditionInput | null;
};

export type UpdateClaimProcessMutation = {
	updateClaimProcess?: {
		__typename: 'ClaimProcess';
		id: string;
		claimID: string;
		name: string;
		code: string;
		claimed: string;
		difference: string;
		authorized: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type DeleteClaimProcessMutationVariables = {
	input: DeleteClaimProcessInput;
	condition?: ModelClaimProcessConditionInput | null;
};

export type DeleteClaimProcessMutation = {
	deleteClaimProcess?: {
		__typename: 'ClaimProcess';
		id: string;
		claimID: string;
		name: string;
		code: string;
		claimed: string;
		difference: string;
		authorized: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type CreateFormsMutationVariables = {
	input: CreateFormsInput;
	condition?: ModelFormsConditionInput | null;
};

export type CreateFormsMutation = {
	createForms?: {
		__typename: 'Forms';
		id: string;
		DoctorID?: string | null;
		public?: boolean | null;
		name?: string | null;
		formImage?: string | null;
		description?: string | null;
		elements?: Array<string | null> | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type UpdateFormsMutationVariables = {
	input: UpdateFormsInput;
	condition?: ModelFormsConditionInput | null;
};

export type UpdateFormsMutation = {
	updateForms?: {
		__typename: 'Forms';
		id: string;
		DoctorID?: string | null;
		public?: boolean | null;
		name?: string | null;
		formImage?: string | null;
		description?: string | null;
		elements?: Array<string | null> | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type DeleteFormsMutationVariables = {
	input: DeleteFormsInput;
	condition?: ModelFormsConditionInput | null;
};

export type DeleteFormsMutation = {
	deleteForms?: {
		__typename: 'Forms';
		id: string;
		DoctorID?: string | null;
		public?: boolean | null;
		name?: string | null;
		formImage?: string | null;
		description?: string | null;
		elements?: Array<string | null> | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type CreateAppointmentMutationVariables = {
	input: CreateAppointmentInput;
	condition?: ModelAppointmentConditionInput | null;
};

export type CreateAppointmentMutation = {
	createAppointment?: {
		__typename: 'Appointment';
		id: string;
		clientID: string;
		doctorID: string;
		hospitalID: string;
		description: string;
		date: string;
		start: string;
		end: string;
		status?: Status | null;
		shouldSendReminder?: boolean | null;
		createdAt: string;
		updatedAt: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		client?: {
			__typename: 'Client';
			id: string;
			identificationName?: IdentificationTypes | null;
			identificationData?: string | null;
			actualInsurance?: string | null;
			name: string;
			lastName: string;
			cellphoneNumber?: string | null;
			email?: string | null;
			bornDate?: string | null;
			parentOrTutorCedula?: string | null;
			parentOrTutorID?: string | null;
			gender?: SexType | null;
			phoneNumber?: string | null;
			addressStreet?: string | null;
			city?: string | null;
			isAdult?: boolean | null;
			sector?: string | null;
			bloodType?: string | null;
			company?: string | null;
			vitalSignsHeight?: string | null;
			vitalSignsWeight?: string | null;
			bodyMass?: string | null;
			vitalSignsBloodPressure?: string | null;
			heartRate?: string | null;
			respiratoryRate?: string | null;
			bodyTemperature?: string | null;
			oxygenSaturation?: string | null;
			occupation?: string | null;
			profileImage?: string | null;
			heartBeat?: string | null;
			weight?: string | null;
			height?: string | null;
			bloodPressure?: string | null;
			updateDateTime?: string | null;
			createdAt: string;
			updatedAt: string;
			code?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
		} | null;
		doctor?: {
			__typename: 'Doctor';
			id: string;
			name?: string | null;
			specialty?: string | null;
			exequator?: string | null;
			email: string;
			firstTime?: boolean | null;
			studyPlace?: string | null;
			isBeenReview?: boolean | null;
			isFirstAddSecretary?: boolean | null;
			hasTrialOnCreation?: boolean | null;
			title?: string | null;
			phone?: string | null;
			code?: string | null;
			password?: string | null;
			isAdmin?: boolean | null;
			daysOfTrial?: number | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
		hospital?: {
			__typename: 'Hospital';
			id: string;
			name: string;
			type?: string | null;
			province?: string | null;
			town?: string | null;
			sector?: string | null;
			phone?: string | null;
			address?: string | null;
			pssCode?: string | null;
			rnc?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type UpdateAppointmentMutationVariables = {
	input: UpdateAppointmentInput;
	condition?: ModelAppointmentConditionInput | null;
};

export type UpdateAppointmentMutation = {
	updateAppointment?: {
		__typename: 'Appointment';
		id: string;
		clientID: string;
		doctorID: string;
		hospitalID: string;
		description: string;
		date: string;
		start: string;
		end: string;
		status?: Status | null;
		shouldSendReminder?: boolean | null;
		createdAt: string;
		updatedAt: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		client?: {
			__typename: 'Client';
			id: string;
			identificationName?: IdentificationTypes | null;
			identificationData?: string | null;
			actualInsurance?: string | null;
			name: string;
			lastName: string;
			cellphoneNumber?: string | null;
			email?: string | null;
			bornDate?: string | null;
			parentOrTutorCedula?: string | null;
			parentOrTutorID?: string | null;
			gender?: SexType | null;
			phoneNumber?: string | null;
			addressStreet?: string | null;
			city?: string | null;
			isAdult?: boolean | null;
			sector?: string | null;
			bloodType?: string | null;
			company?: string | null;
			vitalSignsHeight?: string | null;
			vitalSignsWeight?: string | null;
			bodyMass?: string | null;
			vitalSignsBloodPressure?: string | null;
			heartRate?: string | null;
			respiratoryRate?: string | null;
			bodyTemperature?: string | null;
			oxygenSaturation?: string | null;
			occupation?: string | null;
			profileImage?: string | null;
			heartBeat?: string | null;
			weight?: string | null;
			height?: string | null;
			bloodPressure?: string | null;
			updateDateTime?: string | null;
			createdAt: string;
			updatedAt: string;
			code?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
		} | null;
		doctor?: {
			__typename: 'Doctor';
			id: string;
			name?: string | null;
			specialty?: string | null;
			exequator?: string | null;
			email: string;
			firstTime?: boolean | null;
			studyPlace?: string | null;
			isBeenReview?: boolean | null;
			isFirstAddSecretary?: boolean | null;
			hasTrialOnCreation?: boolean | null;
			title?: string | null;
			phone?: string | null;
			code?: string | null;
			password?: string | null;
			isAdmin?: boolean | null;
			daysOfTrial?: number | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
		hospital?: {
			__typename: 'Hospital';
			id: string;
			name: string;
			type?: string | null;
			province?: string | null;
			town?: string | null;
			sector?: string | null;
			phone?: string | null;
			address?: string | null;
			pssCode?: string | null;
			rnc?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type DeleteAppointmentMutationVariables = {
	input: DeleteAppointmentInput;
	condition?: ModelAppointmentConditionInput | null;
};

export type DeleteAppointmentMutation = {
	deleteAppointment?: {
		__typename: 'Appointment';
		id: string;
		clientID: string;
		doctorID: string;
		hospitalID: string;
		description: string;
		date: string;
		start: string;
		end: string;
		status?: Status | null;
		shouldSendReminder?: boolean | null;
		createdAt: string;
		updatedAt: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		client?: {
			__typename: 'Client';
			id: string;
			identificationName?: IdentificationTypes | null;
			identificationData?: string | null;
			actualInsurance?: string | null;
			name: string;
			lastName: string;
			cellphoneNumber?: string | null;
			email?: string | null;
			bornDate?: string | null;
			parentOrTutorCedula?: string | null;
			parentOrTutorID?: string | null;
			gender?: SexType | null;
			phoneNumber?: string | null;
			addressStreet?: string | null;
			city?: string | null;
			isAdult?: boolean | null;
			sector?: string | null;
			bloodType?: string | null;
			company?: string | null;
			vitalSignsHeight?: string | null;
			vitalSignsWeight?: string | null;
			bodyMass?: string | null;
			vitalSignsBloodPressure?: string | null;
			heartRate?: string | null;
			respiratoryRate?: string | null;
			bodyTemperature?: string | null;
			oxygenSaturation?: string | null;
			occupation?: string | null;
			profileImage?: string | null;
			heartBeat?: string | null;
			weight?: string | null;
			height?: string | null;
			bloodPressure?: string | null;
			updateDateTime?: string | null;
			createdAt: string;
			updatedAt: string;
			code?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
		} | null;
		doctor?: {
			__typename: 'Doctor';
			id: string;
			name?: string | null;
			specialty?: string | null;
			exequator?: string | null;
			email: string;
			firstTime?: boolean | null;
			studyPlace?: string | null;
			isBeenReview?: boolean | null;
			isFirstAddSecretary?: boolean | null;
			hasTrialOnCreation?: boolean | null;
			title?: string | null;
			phone?: string | null;
			code?: string | null;
			password?: string | null;
			isAdmin?: boolean | null;
			daysOfTrial?: number | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
		hospital?: {
			__typename: 'Hospital';
			id: string;
			name: string;
			type?: string | null;
			province?: string | null;
			town?: string | null;
			sector?: string | null;
			phone?: string | null;
			address?: string | null;
			pssCode?: string | null;
			rnc?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type GetSecretaryQueryVariables = {
	id: string;
};

export type GetSecretaryQuery = {
	getSecretary?: {
		__typename: 'Secretary';
		id: string;
		name?: string | null;
		email: string;
		firstTime?: boolean | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type ListSecretarysQueryVariables = {
	filter?: ModelSecretaryFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListSecretarysQuery = {
	listSecretarys?: {
		__typename: 'ModelSecretaryConnection';
		items: Array<{
			__typename: 'Secretary';
			id: string;
			name?: string | null;
			email: string;
			firstTime?: boolean | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncSecretariesQueryVariables = {
	filter?: ModelSecretaryFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncSecretariesQuery = {
	syncSecretaries?: {
		__typename: 'ModelSecretaryConnection';
		items: Array<{
			__typename: 'Secretary';
			id: string;
			name?: string | null;
			email: string;
			firstTime?: boolean | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetEmailDistributionListsQueryVariables = {
	id: string;
};

export type GetEmailDistributionListsQuery = {
	getEmailDistributionLists?: {
		__typename: 'EmailDistributionLists';
		id: string;
		email?: string | null;
		specialty?: string | null;
		createdAt: string;
		updatedAt: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type ListEmailDistributionListssQueryVariables = {
	filter?: ModelEmailDistributionListsFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListEmailDistributionListssQuery = {
	listEmailDistributionListss?: {
		__typename: 'ModelEmailDistributionListsConnection';
		items: Array<{
			__typename: 'EmailDistributionLists';
			id: string;
			email?: string | null;
			specialty?: string | null;
			createdAt: string;
			updatedAt: string;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncEmailDistributionListsQueryVariables = {
	filter?: ModelEmailDistributionListsFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncEmailDistributionListsQuery = {
	syncEmailDistributionLists?: {
		__typename: 'ModelEmailDistributionListsConnection';
		items: Array<{
			__typename: 'EmailDistributionLists';
			id: string;
			email?: string | null;
			specialty?: string | null;
			createdAt: string;
			updatedAt: string;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetSecretaryHospitalDoctorQueryVariables = {
	id: string;
};

export type GetSecretaryHospitalDoctorQuery = {
	getSecretaryHospitalDoctor?: {
		__typename: 'SecretaryHospitalDoctor';
		id: string;
		hospitalDoctorID: string;
		secretaryID: string;
		hasHistoryPermission?: boolean | null;
		hasSurgeryPermission?: boolean | null;
		hasPrescriptionsPermission?: boolean | null;
		hasFilesPermission?: boolean | null;
		hasNotesPermission?: boolean | null;
		hasClaimsPermission?: boolean | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		secretary?: {
			__typename: 'Secretary';
			id: string;
			name?: string | null;
			email: string;
			firstTime?: boolean | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
		hospitalDoctor?: {
			__typename: 'HospitalDoctor';
			id: string;
			doctorID: string;
			hospitalID: string;
			lastWaitingListID: string;
			hospitalLogo?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospital?: {
				__typename: 'Hospital';
				id: string;
				name: string;
				type?: string | null;
				province?: string | null;
				town?: string | null;
				sector?: string | null;
				phone?: string | null;
				address?: string | null;
				pssCode?: string | null;
				rnc?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
	} | null;
};

export type ListSecretaryHospitalDoctorsQueryVariables = {
	filter?: ModelSecretaryHospitalDoctorFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListSecretaryHospitalDoctorsQuery = {
	listSecretaryHospitalDoctors?: {
		__typename: 'ModelSecretaryHospitalDoctorConnection';
		items: Array<{
			__typename: 'SecretaryHospitalDoctor';
			id: string;
			hospitalDoctorID: string;
			secretaryID: string;
			hasHistoryPermission?: boolean | null;
			hasSurgeryPermission?: boolean | null;
			hasPrescriptionsPermission?: boolean | null;
			hasFilesPermission?: boolean | null;
			hasNotesPermission?: boolean | null;
			hasClaimsPermission?: boolean | null;
			status?: Status | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			secretary?: {
				__typename: 'Secretary';
				id: string;
				name?: string | null;
				email: string;
				firstTime?: boolean | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospitalDoctor?: {
				__typename: 'HospitalDoctor';
				id: string;
				doctorID: string;
				hospitalID: string;
				lastWaitingListID: string;
				hospitalLogo?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
				doctor?: {
					__typename: 'Doctor';
					id: string;
					name?: string | null;
					specialty?: string | null;
					exequator?: string | null;
					email: string;
					firstTime?: boolean | null;
					studyPlace?: string | null;
					isBeenReview?: boolean | null;
					isFirstAddSecretary?: boolean | null;
					hasTrialOnCreation?: boolean | null;
					title?: string | null;
					phone?: string | null;
					code?: string | null;
					password?: string | null;
					isAdmin?: boolean | null;
					daysOfTrial?: number | null;
					_version: number;
					_deleted?: boolean | null;
					_lastChangedAt: number;
					createdAt: string;
					updatedAt: string;
				} | null;
				hospital?: {
					__typename: 'Hospital';
					id: string;
					name: string;
					type?: string | null;
					province?: string | null;
					town?: string | null;
					sector?: string | null;
					phone?: string | null;
					address?: string | null;
					pssCode?: string | null;
					rnc?: string | null;
					_version: number;
					_deleted?: boolean | null;
					_lastChangedAt: number;
					createdAt: string;
					updatedAt: string;
				} | null;
			} | null;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncSecretaryHospitalDoctorsQueryVariables = {
	filter?: ModelSecretaryHospitalDoctorFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncSecretaryHospitalDoctorsQuery = {
	syncSecretaryHospitalDoctors?: {
		__typename: 'ModelSecretaryHospitalDoctorConnection';
		items: Array<{
			__typename: 'SecretaryHospitalDoctor';
			id: string;
			hospitalDoctorID: string;
			secretaryID: string;
			hasHistoryPermission?: boolean | null;
			hasSurgeryPermission?: boolean | null;
			hasPrescriptionsPermission?: boolean | null;
			hasFilesPermission?: boolean | null;
			hasNotesPermission?: boolean | null;
			hasClaimsPermission?: boolean | null;
			status?: Status | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			secretary?: {
				__typename: 'Secretary';
				id: string;
				name?: string | null;
				email: string;
				firstTime?: boolean | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospitalDoctor?: {
				__typename: 'HospitalDoctor';
				id: string;
				doctorID: string;
				hospitalID: string;
				lastWaitingListID: string;
				hospitalLogo?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
				doctor?: {
					__typename: 'Doctor';
					id: string;
					name?: string | null;
					specialty?: string | null;
					exequator?: string | null;
					email: string;
					firstTime?: boolean | null;
					studyPlace?: string | null;
					isBeenReview?: boolean | null;
					isFirstAddSecretary?: boolean | null;
					hasTrialOnCreation?: boolean | null;
					title?: string | null;
					phone?: string | null;
					code?: string | null;
					password?: string | null;
					isAdmin?: boolean | null;
					daysOfTrial?: number | null;
					_version: number;
					_deleted?: boolean | null;
					_lastChangedAt: number;
					createdAt: string;
					updatedAt: string;
				} | null;
				hospital?: {
					__typename: 'Hospital';
					id: string;
					name: string;
					type?: string | null;
					province?: string | null;
					town?: string | null;
					sector?: string | null;
					phone?: string | null;
					address?: string | null;
					pssCode?: string | null;
					rnc?: string | null;
					_version: number;
					_deleted?: boolean | null;
					_lastChangedAt: number;
					createdAt: string;
					updatedAt: string;
				} | null;
			} | null;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetSecretaryHospitalDoctorClientQueryVariables = {
	id: string;
};

export type GetSecretaryHospitalDoctorClientQuery = {
	getSecretaryHospitalDoctorClient?: {
		__typename: 'SecretaryHospitalDoctorClient';
		id: string;
		hospitalDoctorID?: string | null;
		secretaryID?: string | null;
		clientID?: string | null;
		note?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type ListSecretaryHospitalDoctorClientsQueryVariables = {
	filter?: ModelSecretaryHospitalDoctorClientFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListSecretaryHospitalDoctorClientsQuery = {
	listSecretaryHospitalDoctorClients?: {
		__typename: 'ModelSecretaryHospitalDoctorClientConnection';
		items: Array<{
			__typename: 'SecretaryHospitalDoctorClient';
			id: string;
			hospitalDoctorID?: string | null;
			secretaryID?: string | null;
			clientID?: string | null;
			note?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncSecretaryHospitalDoctorClientsQueryVariables = {
	filter?: ModelSecretaryHospitalDoctorClientFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncSecretaryHospitalDoctorClientsQuery = {
	syncSecretaryHospitalDoctorClients?: {
		__typename: 'ModelSecretaryHospitalDoctorClientConnection';
		items: Array<{
			__typename: 'SecretaryHospitalDoctorClient';
			id: string;
			hospitalDoctorID?: string | null;
			secretaryID?: string | null;
			clientID?: string | null;
			note?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetMemberHospitalDoctorClientQueryVariables = {
	id: string;
};

export type GetMemberHospitalDoctorClientQuery = {
	getMemberHospitalDoctorClient?: {
		__typename: 'MemberHospitalDoctorClient';
		id: string;
		hospitalDoctorID?: string | null;
		memberID?: string | null;
		ownerID?: string | null;
		clientID?: string | null;
		note?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		owner?: {
			__typename: 'HospitalDoctor';
			id: string;
			doctorID: string;
			hospitalID: string;
			lastWaitingListID: string;
			hospitalLogo?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospital?: {
				__typename: 'Hospital';
				id: string;
				name: string;
				type?: string | null;
				province?: string | null;
				town?: string | null;
				sector?: string | null;
				phone?: string | null;
				address?: string | null;
				pssCode?: string | null;
				rnc?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
	} | null;
};

export type ListMemberHospitalDoctorClientsQueryVariables = {
	filter?: ModelMemberHospitalDoctorClientFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListMemberHospitalDoctorClientsQuery = {
	listMemberHospitalDoctorClients?: {
		__typename: 'ModelMemberHospitalDoctorClientConnection';
		items: Array<{
			__typename: 'MemberHospitalDoctorClient';
			id: string;
			hospitalDoctorID?: string | null;
			memberID?: string | null;
			ownerID?: string | null;
			clientID?: string | null;
			note?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			owner?: {
				__typename: 'HospitalDoctor';
				id: string;
				doctorID: string;
				hospitalID: string;
				lastWaitingListID: string;
				hospitalLogo?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
				doctor?: {
					__typename: 'Doctor';
					id: string;
					name?: string | null;
					specialty?: string | null;
					exequator?: string | null;
					email: string;
					firstTime?: boolean | null;
					studyPlace?: string | null;
					isBeenReview?: boolean | null;
					isFirstAddSecretary?: boolean | null;
					hasTrialOnCreation?: boolean | null;
					title?: string | null;
					phone?: string | null;
					code?: string | null;
					password?: string | null;
					isAdmin?: boolean | null;
					daysOfTrial?: number | null;
					_version: number;
					_deleted?: boolean | null;
					_lastChangedAt: number;
					createdAt: string;
					updatedAt: string;
				} | null;
				hospital?: {
					__typename: 'Hospital';
					id: string;
					name: string;
					type?: string | null;
					province?: string | null;
					town?: string | null;
					sector?: string | null;
					phone?: string | null;
					address?: string | null;
					pssCode?: string | null;
					rnc?: string | null;
					_version: number;
					_deleted?: boolean | null;
					_lastChangedAt: number;
					createdAt: string;
					updatedAt: string;
				} | null;
			} | null;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncMemberHospitalDoctorClientsQueryVariables = {
	filter?: ModelMemberHospitalDoctorClientFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncMemberHospitalDoctorClientsQuery = {
	syncMemberHospitalDoctorClients?: {
		__typename: 'ModelMemberHospitalDoctorClientConnection';
		items: Array<{
			__typename: 'MemberHospitalDoctorClient';
			id: string;
			hospitalDoctorID?: string | null;
			memberID?: string | null;
			ownerID?: string | null;
			clientID?: string | null;
			note?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			owner?: {
				__typename: 'HospitalDoctor';
				id: string;
				doctorID: string;
				hospitalID: string;
				lastWaitingListID: string;
				hospitalLogo?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
				doctor?: {
					__typename: 'Doctor';
					id: string;
					name?: string | null;
					specialty?: string | null;
					exequator?: string | null;
					email: string;
					firstTime?: boolean | null;
					studyPlace?: string | null;
					isBeenReview?: boolean | null;
					isFirstAddSecretary?: boolean | null;
					hasTrialOnCreation?: boolean | null;
					title?: string | null;
					phone?: string | null;
					code?: string | null;
					password?: string | null;
					isAdmin?: boolean | null;
					daysOfTrial?: number | null;
					_version: number;
					_deleted?: boolean | null;
					_lastChangedAt: number;
					createdAt: string;
					updatedAt: string;
				} | null;
				hospital?: {
					__typename: 'Hospital';
					id: string;
					name: string;
					type?: string | null;
					province?: string | null;
					town?: string | null;
					sector?: string | null;
					phone?: string | null;
					address?: string | null;
					pssCode?: string | null;
					rnc?: string | null;
					_version: number;
					_deleted?: boolean | null;
					_lastChangedAt: number;
					createdAt: string;
					updatedAt: string;
				} | null;
			} | null;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetClientQueryVariables = {
	id: string;
};

export type GetClientQuery = {
	getClient?: {
		__typename: 'Client';
		id: string;
		identificationName?: IdentificationTypes | null;
		identificationData?: string | null;
		actualInsurance?: string | null;
		name: string;
		lastName: string;
		cellphoneNumber?: string | null;
		email?: string | null;
		bornDate?: string | null;
		parentOrTutorCedula?: string | null;
		parentOrTutorID?: string | null;
		gender?: SexType | null;
		phoneNumber?: string | null;
		addressStreet?: string | null;
		city?: string | null;
		isAdult?: boolean | null;
		sector?: string | null;
		bloodType?: string | null;
		company?: string | null;
		vitalSignsHeight?: string | null;
		vitalSignsWeight?: string | null;
		bodyMass?: string | null;
		vitalSignsBloodPressure?: string | null;
		heartRate?: string | null;
		respiratoryRate?: string | null;
		bodyTemperature?: string | null;
		oxygenSaturation?: string | null;
		occupation?: string | null;
		profileImage?: string | null;
		heartBeat?: string | null;
		weight?: string | null;
		height?: string | null;
		bloodPressure?: string | null;
		updateDateTime?: string | null;
		createdAt: string;
		updatedAt: string;
		code?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type ListClientsQueryVariables = {
	filter?: ModelClientFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListClientsQuery = {
	listClients?: {
		__typename: 'ModelClientConnection';
		items: Array<{
			__typename: 'Client';
			id: string;
			identificationName?: IdentificationTypes | null;
			identificationData?: string | null;
			actualInsurance?: string | null;
			name: string;
			lastName: string;
			cellphoneNumber?: string | null;
			email?: string | null;
			bornDate?: string | null;
			parentOrTutorCedula?: string | null;
			parentOrTutorID?: string | null;
			gender?: SexType | null;
			phoneNumber?: string | null;
			addressStreet?: string | null;
			city?: string | null;
			isAdult?: boolean | null;
			sector?: string | null;
			bloodType?: string | null;
			company?: string | null;
			vitalSignsHeight?: string | null;
			vitalSignsWeight?: string | null;
			bodyMass?: string | null;
			vitalSignsBloodPressure?: string | null;
			heartRate?: string | null;
			respiratoryRate?: string | null;
			bodyTemperature?: string | null;
			oxygenSaturation?: string | null;
			occupation?: string | null;
			profileImage?: string | null;
			heartBeat?: string | null;
			weight?: string | null;
			height?: string | null;
			bloodPressure?: string | null;
			updateDateTime?: string | null;
			createdAt: string;
			updatedAt: string;
			code?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncClientsQueryVariables = {
	filter?: ModelClientFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncClientsQuery = {
	syncClients?: {
		__typename: 'ModelClientConnection';
		items: Array<{
			__typename: 'Client';
			id: string;
			identificationName?: IdentificationTypes | null;
			identificationData?: string | null;
			actualInsurance?: string | null;
			name: string;
			lastName: string;
			cellphoneNumber?: string | null;
			email?: string | null;
			bornDate?: string | null;
			parentOrTutorCedula?: string | null;
			parentOrTutorID?: string | null;
			gender?: SexType | null;
			phoneNumber?: string | null;
			addressStreet?: string | null;
			city?: string | null;
			isAdult?: boolean | null;
			sector?: string | null;
			bloodType?: string | null;
			company?: string | null;
			vitalSignsHeight?: string | null;
			vitalSignsWeight?: string | null;
			bodyMass?: string | null;
			vitalSignsBloodPressure?: string | null;
			heartRate?: string | null;
			respiratoryRate?: string | null;
			bodyTemperature?: string | null;
			oxygenSaturation?: string | null;
			occupation?: string | null;
			profileImage?: string | null;
			heartBeat?: string | null;
			weight?: string | null;
			height?: string | null;
			bloodPressure?: string | null;
			updateDateTime?: string | null;
			createdAt: string;
			updatedAt: string;
			code?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetClientImageSectionQueryVariables = {
	id: string;
};

export type GetClientImageSectionQuery = {
	getClientImageSection?: {
		__typename: 'ClientImageSection';
		id: string;
		clientHospitalDoctorID?: string | null;
		date?: string | null;
		description?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type ListClientImageSectionsQueryVariables = {
	filter?: ModelClientImageSectionFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListClientImageSectionsQuery = {
	listClientImageSections?: {
		__typename: 'ModelClientImageSectionConnection';
		items: Array<{
			__typename: 'ClientImageSection';
			id: string;
			clientHospitalDoctorID?: string | null;
			date?: string | null;
			description?: string | null;
			status?: Status | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncClientImageSectionsQueryVariables = {
	filter?: ModelClientImageSectionFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncClientImageSectionsQuery = {
	syncClientImageSections?: {
		__typename: 'ModelClientImageSectionConnection';
		items: Array<{
			__typename: 'ClientImageSection';
			id: string;
			clientHospitalDoctorID?: string | null;
			date?: string | null;
			description?: string | null;
			status?: Status | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetPosibleClientQueryVariables = {
	id: string;
};

export type GetPosibleClientQuery = {
	getPosibleClient?: {
		__typename: 'PosibleClient';
		id: string;
		phoneNumber?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type ListPosibleClientsQueryVariables = {
	filter?: ModelPosibleClientFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListPosibleClientsQuery = {
	listPosibleClients?: {
		__typename: 'ModelPosibleClientConnection';
		items: Array<{
			__typename: 'PosibleClient';
			id: string;
			phoneNumber?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncPosibleClientsQueryVariables = {
	filter?: ModelPosibleClientFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncPosibleClientsQuery = {
	syncPosibleClients?: {
		__typename: 'ModelPosibleClientConnection';
		items: Array<{
			__typename: 'PosibleClient';
			id: string;
			phoneNumber?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetClientImageQueryVariables = {
	id: string;
};

export type GetClientImageQuery = {
	getClientImage?: {
		__typename: 'ClientImage';
		id: string;
		pathFile?: string | null;
		description?: string | null;
		ClientImageDateID?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type ListClientImagesQueryVariables = {
	filter?: ModelClientImageFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListClientImagesQuery = {
	listClientImages?: {
		__typename: 'ModelClientImageConnection';
		items: Array<{
			__typename: 'ClientImage';
			id: string;
			pathFile?: string | null;
			description?: string | null;
			ClientImageDateID?: string | null;
			status?: Status | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncClientImagesQueryVariables = {
	filter?: ModelClientImageFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncClientImagesQuery = {
	syncClientImages?: {
		__typename: 'ModelClientImageConnection';
		items: Array<{
			__typename: 'ClientImage';
			id: string;
			pathFile?: string | null;
			description?: string | null;
			ClientImageDateID?: string | null;
			status?: Status | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetConsultationDiagnosticQueryVariables = {
	id: string;
};

export type GetConsultationDiagnosticQuery = {
	getConsultationDiagnostic?: {
		__typename: 'ConsultationDiagnostic';
		id: string;
		clientConsultationID: string;
		name?: string | null;
		code?: string | null;
		type?: DiagnosticTypes | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type ListConsultationDiagnosticsQueryVariables = {
	filter?: ModelConsultationDiagnosticFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListConsultationDiagnosticsQuery = {
	listConsultationDiagnostics?: {
		__typename: 'ModelConsultationDiagnosticConnection';
		items: Array<{
			__typename: 'ConsultationDiagnostic';
			id: string;
			clientConsultationID: string;
			name?: string | null;
			code?: string | null;
			type?: DiagnosticTypes | null;
			status?: Status | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncConsultationDiagnosticsQueryVariables = {
	filter?: ModelConsultationDiagnosticFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncConsultationDiagnosticsQuery = {
	syncConsultationDiagnostics?: {
		__typename: 'ModelConsultationDiagnosticConnection';
		items: Array<{
			__typename: 'ConsultationDiagnostic';
			id: string;
			clientConsultationID: string;
			name?: string | null;
			code?: string | null;
			type?: DiagnosticTypes | null;
			status?: Status | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetClientConsultationQueryVariables = {
	id: string;
};

export type GetClientConsultationQuery = {
	getClientConsultation?: {
		__typename: 'ClientConsultation';
		id: string;
		clientHospitalDoctorID: string;
		doctorID?: string | null;
		visitReason?: string | null;
		visitDescription?: string | null;
		physicTestNote?: string | null;
		height?: string | null;
		weight?: string | null;
		bodyMass?: string | null;
		bloodPressure?: string | null;
		heartRate?: string | null;
		respiratoryRate?: string | null;
		bodyTemperature?: string | null;
		oxygenSaturation?: string | null;
		clientMedicinePrescriptionID?: string | null;
		clientAnalisisPrescriptionID?: string | null;
		clientFreePrescriptionID?: string | null;
		treatment?: string | null;
		procedureName?: string | null;
		procedureDescription?: string | null;
		createdAt?: string | null;
		updatedAt?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		doctor?: {
			__typename: 'Doctor';
			id: string;
			name?: string | null;
			specialty?: string | null;
			exequator?: string | null;
			email: string;
			firstTime?: boolean | null;
			studyPlace?: string | null;
			isBeenReview?: boolean | null;
			isFirstAddSecretary?: boolean | null;
			hasTrialOnCreation?: boolean | null;
			title?: string | null;
			phone?: string | null;
			code?: string | null;
			password?: string | null;
			isAdmin?: boolean | null;
			daysOfTrial?: number | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type ListClientConsultationsQueryVariables = {
	filter?: ModelClientConsultationFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListClientConsultationsQuery = {
	listClientConsultations?: {
		__typename: 'ModelClientConsultationConnection';
		items: Array<{
			__typename: 'ClientConsultation';
			id: string;
			clientHospitalDoctorID: string;
			doctorID?: string | null;
			visitReason?: string | null;
			visitDescription?: string | null;
			physicTestNote?: string | null;
			height?: string | null;
			weight?: string | null;
			bodyMass?: string | null;
			bloodPressure?: string | null;
			heartRate?: string | null;
			respiratoryRate?: string | null;
			bodyTemperature?: string | null;
			oxygenSaturation?: string | null;
			clientMedicinePrescriptionID?: string | null;
			clientAnalisisPrescriptionID?: string | null;
			clientFreePrescriptionID?: string | null;
			treatment?: string | null;
			procedureName?: string | null;
			procedureDescription?: string | null;
			createdAt?: string | null;
			updatedAt?: string | null;
			status?: Status | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncClientConsultationsQueryVariables = {
	filter?: ModelClientConsultationFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncClientConsultationsQuery = {
	syncClientConsultations?: {
		__typename: 'ModelClientConsultationConnection';
		items: Array<{
			__typename: 'ClientConsultation';
			id: string;
			clientHospitalDoctorID: string;
			doctorID?: string | null;
			visitReason?: string | null;
			visitDescription?: string | null;
			physicTestNote?: string | null;
			height?: string | null;
			weight?: string | null;
			bodyMass?: string | null;
			bloodPressure?: string | null;
			heartRate?: string | null;
			respiratoryRate?: string | null;
			bodyTemperature?: string | null;
			oxygenSaturation?: string | null;
			clientMedicinePrescriptionID?: string | null;
			clientAnalisisPrescriptionID?: string | null;
			clientFreePrescriptionID?: string | null;
			treatment?: string | null;
			procedureName?: string | null;
			procedureDescription?: string | null;
			createdAt?: string | null;
			updatedAt?: string | null;
			status?: Status | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetMedicineQueryVariables = {
	id: string;
};

export type GetMedicineQuery = {
	getMedicine?: {
		__typename: 'Medicine';
		id: string;
		name: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type ListMedicinesQueryVariables = {
	filter?: ModelMedicineFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListMedicinesQuery = {
	listMedicines?: {
		__typename: 'ModelMedicineConnection';
		items: Array<{
			__typename: 'Medicine';
			id: string;
			name: string;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncMedicinesQueryVariables = {
	filter?: ModelMedicineFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncMedicinesQuery = {
	syncMedicines?: {
		__typename: 'ModelMedicineConnection';
		items: Array<{
			__typename: 'Medicine';
			id: string;
			name: string;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetClientAllergyQueryVariables = {
	id: string;
};

export type GetClientAllergyQuery = {
	getClientAllergy?: {
		__typename: 'ClientAllergy';
		id: string;
		clientID: string;
		name: string;
		status?: AllergyStatus | null;
		note?: string | null;
		diagnosedDate?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type ListClientAllergysQueryVariables = {
	filter?: ModelClientAllergyFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListClientAllergysQuery = {
	listClientAllergys?: {
		__typename: 'ModelClientAllergyConnection';
		items: Array<{
			__typename: 'ClientAllergy';
			id: string;
			clientID: string;
			name: string;
			status?: AllergyStatus | null;
			note?: string | null;
			diagnosedDate?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncClientAllergiesQueryVariables = {
	filter?: ModelClientAllergyFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncClientAllergiesQuery = {
	syncClientAllergies?: {
		__typename: 'ModelClientAllergyConnection';
		items: Array<{
			__typename: 'ClientAllergy';
			id: string;
			clientID: string;
			name: string;
			status?: AllergyStatus | null;
			note?: string | null;
			diagnosedDate?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetClientDiseaseQueryVariables = {
	id: string;
};

export type GetClientDiseaseQuery = {
	getClientDisease?: {
		__typename: 'ClientDisease';
		id: string;
		clientID: string;
		name: string;
		status?: AllergyStatus | null;
		note?: string | null;
		diagnosedDate?: string | null;
		updatedAt?: string | null;
		createdAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type ListClientDiseasesQueryVariables = {
	filter?: ModelClientDiseaseFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListClientDiseasesQuery = {
	listClientDiseases?: {
		__typename: 'ModelClientDiseaseConnection';
		items: Array<{
			__typename: 'ClientDisease';
			id: string;
			clientID: string;
			name: string;
			status?: AllergyStatus | null;
			note?: string | null;
			diagnosedDate?: string | null;
			updatedAt?: string | null;
			createdAt?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncClientDiseasesQueryVariables = {
	filter?: ModelClientDiseaseFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncClientDiseasesQuery = {
	syncClientDiseases?: {
		__typename: 'ModelClientDiseaseConnection';
		items: Array<{
			__typename: 'ClientDisease';
			id: string;
			clientID: string;
			name: string;
			status?: AllergyStatus | null;
			note?: string | null;
			diagnosedDate?: string | null;
			updatedAt?: string | null;
			createdAt?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetClientProcedureQueryVariables = {
	id: string;
};

export type GetClientProcedureQuery = {
	getClientProcedure?: {
		__typename: 'ClientProcedure';
		id: string;
		clientID: string;
		name: string;
		note?: string | null;
		realizationDate?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type ListClientProceduresQueryVariables = {
	filter?: ModelClientProcedureFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListClientProceduresQuery = {
	listClientProcedures?: {
		__typename: 'ModelClientProcedureConnection';
		items: Array<{
			__typename: 'ClientProcedure';
			id: string;
			clientID: string;
			name: string;
			note?: string | null;
			realizationDate?: string | null;
			status?: Status | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncClientProceduresQueryVariables = {
	filter?: ModelClientProcedureFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncClientProceduresQuery = {
	syncClientProcedures?: {
		__typename: 'ModelClientProcedureConnection';
		items: Array<{
			__typename: 'ClientProcedure';
			id: string;
			clientID: string;
			name: string;
			note?: string | null;
			realizationDate?: string | null;
			status?: Status | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetClientHospitalizationQueryVariables = {
	id: string;
};

export type GetClientHospitalizationQuery = {
	getClientHospitalization?: {
		__typename: 'ClientHospitalization';
		id: string;
		clientID: string;
		name: string;
		note?: string | null;
		date?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type ListClientHospitalizationsQueryVariables = {
	filter?: ModelClientHospitalizationFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListClientHospitalizationsQuery = {
	listClientHospitalizations?: {
		__typename: 'ModelClientHospitalizationConnection';
		items: Array<{
			__typename: 'ClientHospitalization';
			id: string;
			clientID: string;
			name: string;
			note?: string | null;
			date?: string | null;
			status?: Status | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncClientHospitalizationsQueryVariables = {
	filter?: ModelClientHospitalizationFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncClientHospitalizationsQuery = {
	syncClientHospitalizations?: {
		__typename: 'ModelClientHospitalizationConnection';
		items: Array<{
			__typename: 'ClientHospitalization';
			id: string;
			clientID: string;
			name: string;
			note?: string | null;
			date?: string | null;
			status?: Status | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetClientFamilyHistoryQueryVariables = {
	id: string;
};

export type GetClientFamilyHistoryQuery = {
	getClientFamilyHistory?: {
		__typename: 'ClientFamilyHistory';
		id: string;
		clientID: string;
		familyMember: HistoryFamilyMember;
		mellitusDiabetes?: boolean | null;
		mellitusDiabetesNote?: string | null;
		arterialHypertension?: boolean | null;
		arterialHypertensionNote?: string | null;
		heartDisease?: boolean | null;
		heartDiseaseNote?: string | null;
		nephropathy?: boolean | null;
		nephropathyNote?: string | null;
		neuropathies?: boolean | null;
		neuropathiesNote?: string | null;
		epilepsy?: boolean | null;
		epilepsyNote?: string | null;
		rheumatoidArthritis?: boolean | null;
		rheumatoidArthritisNote?: string | null;
		psychiatricDiseases?: boolean | null;
		psychiatricDiseasesNote?: string | null;
		cancer?: boolean | null;
		cancerNote?: string | null;
		other?: boolean | null;
		otherNote?: string | null;
		updatedAt?: string | null;
		createdAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type ListClientFamilyHistorysQueryVariables = {
	filter?: ModelClientFamilyHistoryFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListClientFamilyHistorysQuery = {
	listClientFamilyHistorys?: {
		__typename: 'ModelClientFamilyHistoryConnection';
		items: Array<{
			__typename: 'ClientFamilyHistory';
			id: string;
			clientID: string;
			familyMember: HistoryFamilyMember;
			mellitusDiabetes?: boolean | null;
			mellitusDiabetesNote?: string | null;
			arterialHypertension?: boolean | null;
			arterialHypertensionNote?: string | null;
			heartDisease?: boolean | null;
			heartDiseaseNote?: string | null;
			nephropathy?: boolean | null;
			nephropathyNote?: string | null;
			neuropathies?: boolean | null;
			neuropathiesNote?: string | null;
			epilepsy?: boolean | null;
			epilepsyNote?: string | null;
			rheumatoidArthritis?: boolean | null;
			rheumatoidArthritisNote?: string | null;
			psychiatricDiseases?: boolean | null;
			psychiatricDiseasesNote?: string | null;
			cancer?: boolean | null;
			cancerNote?: string | null;
			other?: boolean | null;
			otherNote?: string | null;
			updatedAt?: string | null;
			createdAt?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncClientFamilyHistoriesQueryVariables = {
	filter?: ModelClientFamilyHistoryFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncClientFamilyHistoriesQuery = {
	syncClientFamilyHistories?: {
		__typename: 'ModelClientFamilyHistoryConnection';
		items: Array<{
			__typename: 'ClientFamilyHistory';
			id: string;
			clientID: string;
			familyMember: HistoryFamilyMember;
			mellitusDiabetes?: boolean | null;
			mellitusDiabetesNote?: string | null;
			arterialHypertension?: boolean | null;
			arterialHypertensionNote?: string | null;
			heartDisease?: boolean | null;
			heartDiseaseNote?: string | null;
			nephropathy?: boolean | null;
			nephropathyNote?: string | null;
			neuropathies?: boolean | null;
			neuropathiesNote?: string | null;
			epilepsy?: boolean | null;
			epilepsyNote?: string | null;
			rheumatoidArthritis?: boolean | null;
			rheumatoidArthritisNote?: string | null;
			psychiatricDiseases?: boolean | null;
			psychiatricDiseasesNote?: string | null;
			cancer?: boolean | null;
			cancerNote?: string | null;
			other?: boolean | null;
			otherNote?: string | null;
			updatedAt?: string | null;
			createdAt?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetClientLifeStyleQueryVariables = {
	id: string;
};

export type GetClientLifeStyleQuery = {
	getClientLifeStyle?: {
		__typename: 'ClientLifeStyle';
		id: string;
		clientID: string;
		lifeStyle: LifeStyle;
		name: string;
		description: string;
		updatedAt: string;
		createdAt: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type ListClientLifeStylesQueryVariables = {
	filter?: ModelClientLifeStyleFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListClientLifeStylesQuery = {
	listClientLifeStyles?: {
		__typename: 'ModelClientLifeStyleConnection';
		items: Array<{
			__typename: 'ClientLifeStyle';
			id: string;
			clientID: string;
			lifeStyle: LifeStyle;
			name: string;
			description: string;
			updatedAt: string;
			createdAt: string;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncClientLifeStylesQueryVariables = {
	filter?: ModelClientLifeStyleFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncClientLifeStylesQuery = {
	syncClientLifeStyles?: {
		__typename: 'ModelClientLifeStyleConnection';
		items: Array<{
			__typename: 'ClientLifeStyle';
			id: string;
			clientID: string;
			lifeStyle: LifeStyle;
			name: string;
			description: string;
			updatedAt: string;
			createdAt: string;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetClientPrescriptionQueryVariables = {
	id: string;
};

export type GetClientPrescriptionQuery = {
	getClientPrescription?: {
		__typename: 'ClientPrescription';
		id: string;
		clientHospitalDoctorID: string;
		creationDate?: string | null;
		type: PrescriptionTypes;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type ListClientPrescriptionsQueryVariables = {
	filter?: ModelClientPrescriptionFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListClientPrescriptionsQuery = {
	listClientPrescriptions?: {
		__typename: 'ModelClientPrescriptionConnection';
		items: Array<{
			__typename: 'ClientPrescription';
			id: string;
			clientHospitalDoctorID: string;
			creationDate?: string | null;
			type: PrescriptionTypes;
			status?: Status | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncClientPrescriptionsQueryVariables = {
	filter?: ModelClientPrescriptionFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncClientPrescriptionsQuery = {
	syncClientPrescriptions?: {
		__typename: 'ModelClientPrescriptionConnection';
		items: Array<{
			__typename: 'ClientPrescription';
			id: string;
			clientHospitalDoctorID: string;
			creationDate?: string | null;
			type: PrescriptionTypes;
			status?: Status | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetClientPrescriptionMedicineQueryVariables = {
	id: string;
};

export type GetClientPrescriptionMedicineQuery = {
	getClientPrescriptionMedicine?: {
		__typename: 'ClientPrescriptionMedicine';
		id: string;
		clientPrescriptionID: string;
		medicineID?: string | null;
		name?: string | null;
		take?: string | null;
		indication?: string | null;
		duration?: string | null;
		description?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type ListClientPrescriptionMedicinesQueryVariables = {
	filter?: ModelClientPrescriptionMedicineFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListClientPrescriptionMedicinesQuery = {
	listClientPrescriptionMedicines?: {
		__typename: 'ModelClientPrescriptionMedicineConnection';
		items: Array<{
			__typename: 'ClientPrescriptionMedicine';
			id: string;
			clientPrescriptionID: string;
			medicineID?: string | null;
			name?: string | null;
			take?: string | null;
			indication?: string | null;
			duration?: string | null;
			description?: string | null;
			status?: Status | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncClientPrescriptionMedicinesQueryVariables = {
	filter?: ModelClientPrescriptionMedicineFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncClientPrescriptionMedicinesQuery = {
	syncClientPrescriptionMedicines?: {
		__typename: 'ModelClientPrescriptionMedicineConnection';
		items: Array<{
			__typename: 'ClientPrescriptionMedicine';
			id: string;
			clientPrescriptionID: string;
			medicineID?: string | null;
			name?: string | null;
			take?: string | null;
			indication?: string | null;
			duration?: string | null;
			description?: string | null;
			status?: Status | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetClientFreePrescriptionQueryVariables = {
	id: string;
};

export type GetClientFreePrescriptionQuery = {
	getClientFreePrescription?: {
		__typename: 'ClientFreePrescription';
		id: string;
		clientPrescriptionID: string;
		description?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type ListClientFreePrescriptionsQueryVariables = {
	filter?: ModelClientFreePrescriptionFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListClientFreePrescriptionsQuery = {
	listClientFreePrescriptions?: {
		__typename: 'ModelClientFreePrescriptionConnection';
		items: Array<{
			__typename: 'ClientFreePrescription';
			id: string;
			clientPrescriptionID: string;
			description?: string | null;
			status?: Status | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncClientFreePrescriptionsQueryVariables = {
	filter?: ModelClientFreePrescriptionFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncClientFreePrescriptionsQuery = {
	syncClientFreePrescriptions?: {
		__typename: 'ModelClientFreePrescriptionConnection';
		items: Array<{
			__typename: 'ClientFreePrescription';
			id: string;
			clientPrescriptionID: string;
			description?: string | null;
			status?: Status | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetClientPrescriptionAnalisisQueryVariables = {
	id: string;
};

export type GetClientPrescriptionAnalisisQuery = {
	getClientPrescriptionAnalisis?: {
		__typename: 'ClientPrescriptionAnalisis';
		id: string;
		clientPrescriptionID: string;
		name?: string | null;
		description?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type ListClientPrescriptionAnalisissQueryVariables = {
	filter?: ModelClientPrescriptionAnalisisFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListClientPrescriptionAnalisissQuery = {
	listClientPrescriptionAnalisiss?: {
		__typename: 'ModelClientPrescriptionAnalisisConnection';
		items: Array<{
			__typename: 'ClientPrescriptionAnalisis';
			id: string;
			clientPrescriptionID: string;
			name?: string | null;
			description?: string | null;
			status?: Status | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncClientPrescriptionAnalisesQueryVariables = {
	filter?: ModelClientPrescriptionAnalisisFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncClientPrescriptionAnalisesQuery = {
	syncClientPrescriptionAnalises?: {
		__typename: 'ModelClientPrescriptionAnalisisConnection';
		items: Array<{
			__typename: 'ClientPrescriptionAnalisis';
			id: string;
			clientPrescriptionID: string;
			name?: string | null;
			description?: string | null;
			status?: Status | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetDoctorLabImageQueryVariables = {
	id: string;
};

export type GetDoctorLabImageQuery = {
	getDoctorLabImage?: {
		__typename: 'DoctorLabImage';
		id: string;
		doctorID: string;
		labImageID: string;
		updatedAt?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		labImage?: {
			__typename: 'LabImage';
			id: string;
			name: string;
			description?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type ListDoctorLabImagesQueryVariables = {
	filter?: ModelDoctorLabImageFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListDoctorLabImagesQuery = {
	listDoctorLabImages?: {
		__typename: 'ModelDoctorLabImageConnection';
		items: Array<{
			__typename: 'DoctorLabImage';
			id: string;
			doctorID: string;
			labImageID: string;
			updatedAt?: string | null;
			status?: Status | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			labImage?: {
				__typename: 'LabImage';
				id: string;
				name: string;
				description?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncDoctorLabImagesQueryVariables = {
	filter?: ModelDoctorLabImageFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncDoctorLabImagesQuery = {
	syncDoctorLabImages?: {
		__typename: 'ModelDoctorLabImageConnection';
		items: Array<{
			__typename: 'DoctorLabImage';
			id: string;
			doctorID: string;
			labImageID: string;
			updatedAt?: string | null;
			status?: Status | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			labImage?: {
				__typename: 'LabImage';
				id: string;
				name: string;
				description?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetLabImageQueryVariables = {
	id: string;
};

export type GetLabImageQuery = {
	getLabImage?: {
		__typename: 'LabImage';
		id: string;
		name: string;
		description?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type ListLabImagesQueryVariables = {
	filter?: ModelLabImageFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListLabImagesQuery = {
	listLabImages?: {
		__typename: 'ModelLabImageConnection';
		items: Array<{
			__typename: 'LabImage';
			id: string;
			name: string;
			description?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncLabImagesQueryVariables = {
	filter?: ModelLabImageFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncLabImagesQuery = {
	syncLabImages?: {
		__typename: 'ModelLabImageConnection';
		items: Array<{
			__typename: 'LabImage';
			id: string;
			name: string;
			description?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetPharmacyMedicineQueryVariables = {
	id: string;
};

export type GetPharmacyMedicineQuery = {
	getPharmacyMedicine?: {
		__typename: 'PharmacyMedicine';
		id: string;
		name?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type ListPharmacyMedicinesQueryVariables = {
	filter?: ModelPharmacyMedicineFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListPharmacyMedicinesQuery = {
	listPharmacyMedicines?: {
		__typename: 'ModelPharmacyMedicineConnection';
		items: Array<{
			__typename: 'PharmacyMedicine';
			id: string;
			name?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncPharmacyMedicinesQueryVariables = {
	filter?: ModelPharmacyMedicineFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncPharmacyMedicinesQuery = {
	syncPharmacyMedicines?: {
		__typename: 'ModelPharmacyMedicineConnection';
		items: Array<{
			__typename: 'PharmacyMedicine';
			id: string;
			name?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetPharmacyMedicineMeasureQueryVariables = {
	id: string;
};

export type GetPharmacyMedicineMeasureQuery = {
	getPharmacyMedicineMeasure?: {
		__typename: 'PharmacyMedicineMeasure';
		id: string;
		pharmacyMedicineID: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type ListPharmacyMedicineMeasuresQueryVariables = {
	filter?: ModelPharmacyMedicineMeasureFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListPharmacyMedicineMeasuresQuery = {
	listPharmacyMedicineMeasures?: {
		__typename: 'ModelPharmacyMedicineMeasureConnection';
		items: Array<{
			__typename: 'PharmacyMedicineMeasure';
			id: string;
			pharmacyMedicineID: string;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncPharmacyMedicineMeasuresQueryVariables = {
	filter?: ModelPharmacyMedicineMeasureFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncPharmacyMedicineMeasuresQuery = {
	syncPharmacyMedicineMeasures?: {
		__typename: 'ModelPharmacyMedicineMeasureConnection';
		items: Array<{
			__typename: 'PharmacyMedicineMeasure';
			id: string;
			pharmacyMedicineID: string;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetTemplateQueryVariables = {
	id: string;
};

export type GetTemplateQuery = {
	getTemplate?: {
		__typename: 'Template';
		id: string;
		doctorID: string;
		templateName?: string | null;
		templateType: PrescriptionTypes;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type ListTemplatesQueryVariables = {
	filter?: ModelTemplateFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListTemplatesQuery = {
	listTemplates?: {
		__typename: 'ModelTemplateConnection';
		items: Array<{
			__typename: 'Template';
			id: string;
			doctorID: string;
			templateName?: string | null;
			templateType: PrescriptionTypes;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncTemplatesQueryVariables = {
	filter?: ModelTemplateFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncTemplatesQuery = {
	syncTemplates?: {
		__typename: 'ModelTemplateConnection';
		items: Array<{
			__typename: 'Template';
			id: string;
			doctorID: string;
			templateName?: string | null;
			templateType: PrescriptionTypes;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetTemplateMedicineQueryVariables = {
	id: string;
};

export type GetTemplateMedicineQuery = {
	getTemplateMedicine?: {
		__typename: 'TemplateMedicine';
		id: string;
		templateID: string;
		medicineID: string;
		take?: string | null;
		indication?: string | null;
		duration?: string | null;
		description?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type ListTemplateMedicinesQueryVariables = {
	filter?: ModelTemplateMedicineFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListTemplateMedicinesQuery = {
	listTemplateMedicines?: {
		__typename: 'ModelTemplateMedicineConnection';
		items: Array<{
			__typename: 'TemplateMedicine';
			id: string;
			templateID: string;
			medicineID: string;
			take?: string | null;
			indication?: string | null;
			duration?: string | null;
			description?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncTemplateMedicinesQueryVariables = {
	filter?: ModelTemplateMedicineFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncTemplateMedicinesQuery = {
	syncTemplateMedicines?: {
		__typename: 'ModelTemplateMedicineConnection';
		items: Array<{
			__typename: 'TemplateMedicine';
			id: string;
			templateID: string;
			medicineID: string;
			take?: string | null;
			indication?: string | null;
			duration?: string | null;
			description?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetDoctorRecentDiagnosisQueryVariables = {
	id: string;
};

export type GetDoctorRecentDiagnosisQuery = {
	getDoctorRecentDiagnosis?: {
		__typename: 'DoctorRecentDiagnosis';
		id: string;
		doctorID: string;
		name: string;
		code?: string | null;
		type?: DiagnosticTypes | null;
		updateDateTime?: string | null;
		createdAt?: string | null;
		updatedAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type ListDoctorRecentDiagnosissQueryVariables = {
	filter?: ModelDoctorRecentDiagnosisFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListDoctorRecentDiagnosissQuery = {
	listDoctorRecentDiagnosiss?: {
		__typename: 'ModelDoctorRecentDiagnosisConnection';
		items: Array<{
			__typename: 'DoctorRecentDiagnosis';
			id: string;
			doctorID: string;
			name: string;
			code?: string | null;
			type?: DiagnosticTypes | null;
			updateDateTime?: string | null;
			createdAt?: string | null;
			updatedAt?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncDoctorRecentDiagnosesQueryVariables = {
	filter?: ModelDoctorRecentDiagnosisFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncDoctorRecentDiagnosesQuery = {
	syncDoctorRecentDiagnoses?: {
		__typename: 'ModelDoctorRecentDiagnosisConnection';
		items: Array<{
			__typename: 'DoctorRecentDiagnosis';
			id: string;
			doctorID: string;
			name: string;
			code?: string | null;
			type?: DiagnosticTypes | null;
			updateDateTime?: string | null;
			createdAt?: string | null;
			updatedAt?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetTemplateLabImageQueryVariables = {
	id: string;
};

export type GetTemplateLabImageQuery = {
	getTemplateLabImage?: {
		__typename: 'TemplateLabImage';
		id: string;
		templateID: string;
		name?: string | null;
		description?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type ListTemplateLabImagesQueryVariables = {
	filter?: ModelTemplateLabImageFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListTemplateLabImagesQuery = {
	listTemplateLabImages?: {
		__typename: 'ModelTemplateLabImageConnection';
		items: Array<{
			__typename: 'TemplateLabImage';
			id: string;
			templateID: string;
			name?: string | null;
			description?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncTemplateLabImagesQueryVariables = {
	filter?: ModelTemplateLabImageFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncTemplateLabImagesQuery = {
	syncTemplateLabImages?: {
		__typename: 'ModelTemplateLabImageConnection';
		items: Array<{
			__typename: 'TemplateLabImage';
			id: string;
			templateID: string;
			name?: string | null;
			description?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetDoctorMedicineQueryVariables = {
	id: string;
};

export type GetDoctorMedicineQuery = {
	getDoctorMedicine?: {
		__typename: 'DoctorMedicine';
		id: string;
		doctorID: string;
		medicineID: string;
		isDeleted?: boolean | null;
		updatedAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		medicine?: {
			__typename: 'Medicine';
			id: string;
			name: string;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type ListDoctorMedicinesQueryVariables = {
	filter?: ModelDoctorMedicineFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListDoctorMedicinesQuery = {
	listDoctorMedicines?: {
		__typename: 'ModelDoctorMedicineConnection';
		items: Array<{
			__typename: 'DoctorMedicine';
			id: string;
			doctorID: string;
			medicineID: string;
			isDeleted?: boolean | null;
			updatedAt?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			medicine?: {
				__typename: 'Medicine';
				id: string;
				name: string;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncDoctorMedicinesQueryVariables = {
	filter?: ModelDoctorMedicineFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncDoctorMedicinesQuery = {
	syncDoctorMedicines?: {
		__typename: 'ModelDoctorMedicineConnection';
		items: Array<{
			__typename: 'DoctorMedicine';
			id: string;
			doctorID: string;
			medicineID: string;
			isDeleted?: boolean | null;
			updatedAt?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			medicine?: {
				__typename: 'Medicine';
				id: string;
				name: string;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetRecentDoctorMedicinePrescriptionQueryVariables = {
	id: string;
};

export type GetRecentDoctorMedicinePrescriptionQuery = {
	getRecentDoctorMedicinePrescription?: {
		__typename: 'RecentDoctorMedicinePrescription';
		id: string;
		doctorID: string;
		medicineID: string;
		take?: string | null;
		indication?: string | null;
		duration?: string | null;
		description?: string | null;
		updateDateTime?: string | null;
		createdAt?: string | null;
		updatedAt?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		medicine?: {
			__typename: 'Medicine';
			id: string;
			name: string;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type ListRecentDoctorMedicinePrescriptionsQueryVariables = {
	filter?: ModelRecentDoctorMedicinePrescriptionFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListRecentDoctorMedicinePrescriptionsQuery = {
	listRecentDoctorMedicinePrescriptions?: {
		__typename: 'ModelRecentDoctorMedicinePrescriptionConnection';
		items: Array<{
			__typename: 'RecentDoctorMedicinePrescription';
			id: string;
			doctorID: string;
			medicineID: string;
			take?: string | null;
			indication?: string | null;
			duration?: string | null;
			description?: string | null;
			updateDateTime?: string | null;
			createdAt?: string | null;
			updatedAt?: string | null;
			status?: Status | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			medicine?: {
				__typename: 'Medicine';
				id: string;
				name: string;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncRecentDoctorMedicinePrescriptionsQueryVariables = {
	filter?: ModelRecentDoctorMedicinePrescriptionFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncRecentDoctorMedicinePrescriptionsQuery = {
	syncRecentDoctorMedicinePrescriptions?: {
		__typename: 'ModelRecentDoctorMedicinePrescriptionConnection';
		items: Array<{
			__typename: 'RecentDoctorMedicinePrescription';
			id: string;
			doctorID: string;
			medicineID: string;
			take?: string | null;
			indication?: string | null;
			duration?: string | null;
			description?: string | null;
			updateDateTime?: string | null;
			createdAt?: string | null;
			updatedAt?: string | null;
			status?: Status | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			medicine?: {
				__typename: 'Medicine';
				id: string;
				name: string;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetRecentDoctorLabImagePrescriptionQueryVariables = {
	id: string;
};

export type GetRecentDoctorLabImagePrescriptionQuery = {
	getRecentDoctorLabImagePrescription?: {
		__typename: 'RecentDoctorLabImagePrescription';
		id: string;
		doctorID: string;
		labImageID?: string | null;
		name?: string | null;
		description?: string | null;
		updateDateTime?: string | null;
		createdAt?: string | null;
		updatedAt?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type ListRecentDoctorLabImagePrescriptionsQueryVariables = {
	filter?: ModelRecentDoctorLabImagePrescriptionFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListRecentDoctorLabImagePrescriptionsQuery = {
	listRecentDoctorLabImagePrescriptions?: {
		__typename: 'ModelRecentDoctorLabImagePrescriptionConnection';
		items: Array<{
			__typename: 'RecentDoctorLabImagePrescription';
			id: string;
			doctorID: string;
			labImageID?: string | null;
			name?: string | null;
			description?: string | null;
			updateDateTime?: string | null;
			createdAt?: string | null;
			updatedAt?: string | null;
			status?: Status | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncRecentDoctorLabImagePrescriptionsQueryVariables = {
	filter?: ModelRecentDoctorLabImagePrescriptionFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncRecentDoctorLabImagePrescriptionsQuery = {
	syncRecentDoctorLabImagePrescriptions?: {
		__typename: 'ModelRecentDoctorLabImagePrescriptionConnection';
		items: Array<{
			__typename: 'RecentDoctorLabImagePrescription';
			id: string;
			doctorID: string;
			labImageID?: string | null;
			name?: string | null;
			description?: string | null;
			updateDateTime?: string | null;
			createdAt?: string | null;
			updatedAt?: string | null;
			status?: Status | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetTemplateAnalisisQueryVariables = {
	id: string;
};

export type GetTemplateAnalisisQuery = {
	getTemplateAnalisis?: {
		__typename: 'TemplateAnalisis';
		id: string;
		templateID: string;
		analisisID: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type ListTemplateAnalisissQueryVariables = {
	filter?: ModelTemplateAnalisisFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListTemplateAnalisissQuery = {
	listTemplateAnalisiss?: {
		__typename: 'ModelTemplateAnalisisConnection';
		items: Array<{
			__typename: 'TemplateAnalisis';
			id: string;
			templateID: string;
			analisisID: string;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncTemplateAnalisesQueryVariables = {
	filter?: ModelTemplateAnalisisFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncTemplateAnalisesQuery = {
	syncTemplateAnalises?: {
		__typename: 'ModelTemplateAnalisisConnection';
		items: Array<{
			__typename: 'TemplateAnalisis';
			id: string;
			templateID: string;
			analisisID: string;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetAnalisisQueryVariables = {
	id: string;
};

export type GetAnalisisQuery = {
	getAnalisis?: {
		__typename: 'Analisis';
		id: string;
		analisisName?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type ListAnalisissQueryVariables = {
	filter?: ModelAnalisisFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListAnalisissQuery = {
	listAnalisiss?: {
		__typename: 'ModelAnalisisConnection';
		items: Array<{
			__typename: 'Analisis';
			id: string;
			analisisName?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncAnalisesQueryVariables = {
	filter?: ModelAnalisisFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncAnalisesQuery = {
	syncAnalises?: {
		__typename: 'ModelAnalisisConnection';
		items: Array<{
			__typename: 'Analisis';
			id: string;
			analisisName?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetClientActiveDiseaseQueryVariables = {
	id: string;
};

export type GetClientActiveDiseaseQuery = {
	getClientActiveDisease?: {
		__typename: 'ClientActiveDisease';
		id: string;
		clientID: string;
		name: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type ListClientActiveDiseasesQueryVariables = {
	filter?: ModelClientActiveDiseaseFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListClientActiveDiseasesQuery = {
	listClientActiveDiseases?: {
		__typename: 'ModelClientActiveDiseaseConnection';
		items: Array<{
			__typename: 'ClientActiveDisease';
			id: string;
			clientID: string;
			name: string;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncClientActiveDiseasesQueryVariables = {
	filter?: ModelClientActiveDiseaseFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncClientActiveDiseasesQuery = {
	syncClientActiveDiseases?: {
		__typename: 'ModelClientActiveDiseaseConnection';
		items: Array<{
			__typename: 'ClientActiveDisease';
			id: string;
			clientID: string;
			name: string;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetClientHistoryQueryVariables = {
	id: string;
};

export type GetClientHistoryQuery = {
	getClientHistory?: {
		__typename: 'ClientHistory';
		id: string;
		clientHospitalDoctorID: string;
		title?: string | null;
		treatment?: string | null;
		endModifyDate?: string | null;
		authorizationNumber?: string | null;
		description?: string | null;
		authorizationDetail?: string | null;
		personalPathologicalHistory?: string | null;
		familyPathologicalHistory?: string | null;
		physicalTest?: string | null;
		createdDate?: string | null;
		insuranceID?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type ListClientHistorysQueryVariables = {
	filter?: ModelClientHistoryFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListClientHistorysQuery = {
	listClientHistorys?: {
		__typename: 'ModelClientHistoryConnection';
		items: Array<{
			__typename: 'ClientHistory';
			id: string;
			clientHospitalDoctorID: string;
			title?: string | null;
			treatment?: string | null;
			endModifyDate?: string | null;
			authorizationNumber?: string | null;
			description?: string | null;
			authorizationDetail?: string | null;
			personalPathologicalHistory?: string | null;
			familyPathologicalHistory?: string | null;
			physicalTest?: string | null;
			createdDate?: string | null;
			insuranceID?: string | null;
			status?: Status | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncClientHistoriesQueryVariables = {
	filter?: ModelClientHistoryFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncClientHistoriesQuery = {
	syncClientHistories?: {
		__typename: 'ModelClientHistoryConnection';
		items: Array<{
			__typename: 'ClientHistory';
			id: string;
			clientHospitalDoctorID: string;
			title?: string | null;
			treatment?: string | null;
			endModifyDate?: string | null;
			authorizationNumber?: string | null;
			description?: string | null;
			authorizationDetail?: string | null;
			personalPathologicalHistory?: string | null;
			familyPathologicalHistory?: string | null;
			physicalTest?: string | null;
			createdDate?: string | null;
			insuranceID?: string | null;
			status?: Status | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetClientNotesQueryVariables = {
	id: string;
};

export type GetClientNotesQuery = {
	getClientNotes?: {
		__typename: 'ClientNotes';
		id: string;
		clientHospitalDoctorID: string;
		title?: string | null;
		noteBody?: string | null;
		endModifyDate?: string | null;
		createdDate?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type ListClientNotessQueryVariables = {
	filter?: ModelClientNotesFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListClientNotessQuery = {
	listClientNotess?: {
		__typename: 'ModelClientNotesConnection';
		items: Array<{
			__typename: 'ClientNotes';
			id: string;
			clientHospitalDoctorID: string;
			title?: string | null;
			noteBody?: string | null;
			endModifyDate?: string | null;
			createdDate?: string | null;
			status?: Status | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncClientNotesQueryVariables = {
	filter?: ModelClientNotesFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncClientNotesQuery = {
	syncClientNotes?: {
		__typename: 'ModelClientNotesConnection';
		items: Array<{
			__typename: 'ClientNotes';
			id: string;
			clientHospitalDoctorID: string;
			title?: string | null;
			noteBody?: string | null;
			endModifyDate?: string | null;
			createdDate?: string | null;
			status?: Status | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetClientProcedureSurgeryQueryVariables = {
	id: string;
};

export type GetClientProcedureSurgeryQuery = {
	getClientProcedureSurgery?: {
		__typename: 'ClientProcedureSurgery';
		id: string;
		clientHospitalDoctorID: string;
		title?: string | null;
		description?: string | null;
		endModifyDate?: string | null;
		createdDate?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type ListClientProcedureSurgerysQueryVariables = {
	filter?: ModelClientProcedureSurgeryFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListClientProcedureSurgerysQuery = {
	listClientProcedureSurgerys?: {
		__typename: 'ModelClientProcedureSurgeryConnection';
		items: Array<{
			__typename: 'ClientProcedureSurgery';
			id: string;
			clientHospitalDoctorID: string;
			title?: string | null;
			description?: string | null;
			endModifyDate?: string | null;
			createdDate?: string | null;
			status?: Status | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncClientProcedureSurgeriesQueryVariables = {
	filter?: ModelClientProcedureSurgeryFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncClientProcedureSurgeriesQuery = {
	syncClientProcedureSurgeries?: {
		__typename: 'ModelClientProcedureSurgeryConnection';
		items: Array<{
			__typename: 'ClientProcedureSurgery';
			id: string;
			clientHospitalDoctorID: string;
			title?: string | null;
			description?: string | null;
			endModifyDate?: string | null;
			createdDate?: string | null;
			status?: Status | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetDoctorQueryVariables = {
	id: string;
};

export type GetDoctorQuery = {
	getDoctor?: {
		__typename: 'Doctor';
		id: string;
		name?: string | null;
		specialty?: string | null;
		exequator?: string | null;
		email: string;
		firstTime?: boolean | null;
		studyPlace?: string | null;
		isBeenReview?: boolean | null;
		isFirstAddSecretary?: boolean | null;
		hasTrialOnCreation?: boolean | null;
		title?: string | null;
		phone?: string | null;
		code?: string | null;
		password?: string | null;
		isAdmin?: boolean | null;
		daysOfTrial?: number | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type ListDoctorsQueryVariables = {
	filter?: ModelDoctorFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListDoctorsQuery = {
	listDoctors?: {
		__typename: 'ModelDoctorConnection';
		items: Array<{
			__typename: 'Doctor';
			id: string;
			name?: string | null;
			specialty?: string | null;
			exequator?: string | null;
			email: string;
			firstTime?: boolean | null;
			studyPlace?: string | null;
			isBeenReview?: boolean | null;
			isFirstAddSecretary?: boolean | null;
			hasTrialOnCreation?: boolean | null;
			title?: string | null;
			phone?: string | null;
			code?: string | null;
			password?: string | null;
			isAdmin?: boolean | null;
			daysOfTrial?: number | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncDoctorsQueryVariables = {
	filter?: ModelDoctorFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncDoctorsQuery = {
	syncDoctors?: {
		__typename: 'ModelDoctorConnection';
		items: Array<{
			__typename: 'Doctor';
			id: string;
			name?: string | null;
			specialty?: string | null;
			exequator?: string | null;
			email: string;
			firstTime?: boolean | null;
			studyPlace?: string | null;
			isBeenReview?: boolean | null;
			isFirstAddSecretary?: boolean | null;
			hasTrialOnCreation?: boolean | null;
			title?: string | null;
			phone?: string | null;
			code?: string | null;
			password?: string | null;
			isAdmin?: boolean | null;
			daysOfTrial?: number | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetPlanQueryVariables = {
	id: string;
};

export type GetPlanQuery = {
	getPlan?: {
		__typename: 'Plan';
		id: string;
		refID?: number | null;
		title?: string | null;
		subTitle?: string | null;
		stripePlanKey?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type ListPlansQueryVariables = {
	filter?: ModelPlanFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListPlansQuery = {
	listPlans?: {
		__typename: 'ModelPlanConnection';
		items: Array<{
			__typename: 'Plan';
			id: string;
			refID?: number | null;
			title?: string | null;
			subTitle?: string | null;
			stripePlanKey?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncPlansQueryVariables = {
	filter?: ModelPlanFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncPlansQuery = {
	syncPlans?: {
		__typename: 'ModelPlanConnection';
		items: Array<{
			__typename: 'Plan';
			id: string;
			refID?: number | null;
			title?: string | null;
			subTitle?: string | null;
			stripePlanKey?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetDoctorStripeCustomerSubscriptionQueryVariables = {
	id: string;
};

export type GetDoctorStripeCustomerSubscriptionQuery = {
	getDoctorStripeCustomerSubscription?: {
		__typename: 'DoctorStripeCustomerSubscription';
		id: string;
		doctorID: string;
		currentPlanID?: number | null;
		stripeCustomerID?: string | null;
		stripeSubscriptionID?: string | null;
		defaultCard?: string | null;
		nextPaymentDate?: string | null;
		cancelAtPeriodEnd?: boolean | null;
		trialsEndsAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type ListDoctorStripeCustomerSubscriptionsQueryVariables = {
	filter?: ModelDoctorStripeCustomerSubscriptionFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListDoctorStripeCustomerSubscriptionsQuery = {
	listDoctorStripeCustomerSubscriptions?: {
		__typename: 'ModelDoctorStripeCustomerSubscriptionConnection';
		items: Array<{
			__typename: 'DoctorStripeCustomerSubscription';
			id: string;
			doctorID: string;
			currentPlanID?: number | null;
			stripeCustomerID?: string | null;
			stripeSubscriptionID?: string | null;
			defaultCard?: string | null;
			nextPaymentDate?: string | null;
			cancelAtPeriodEnd?: boolean | null;
			trialsEndsAt?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncDoctorStripeCustomerSubscriptionsQueryVariables = {
	filter?: ModelDoctorStripeCustomerSubscriptionFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncDoctorStripeCustomerSubscriptionsQuery = {
	syncDoctorStripeCustomerSubscriptions?: {
		__typename: 'ModelDoctorStripeCustomerSubscriptionConnection';
		items: Array<{
			__typename: 'DoctorStripeCustomerSubscription';
			id: string;
			doctorID: string;
			currentPlanID?: number | null;
			stripeCustomerID?: string | null;
			stripeSubscriptionID?: string | null;
			defaultCard?: string | null;
			nextPaymentDate?: string | null;
			cancelAtPeriodEnd?: boolean | null;
			trialsEndsAt?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetDoctorStripeCardsQueryVariables = {
	id: string;
};

export type GetDoctorStripeCardsQuery = {
	getDoctorStripeCards?: {
		__typename: 'DoctorStripeCards';
		id: string;
		DoctorID: string;
		cardLastDigits?: string | null;
		stripePaymentMethodID?: string | null;
		defaultCard?: boolean | null;
		cardBrand?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type ListDoctorStripeCardssQueryVariables = {
	filter?: ModelDoctorStripeCardsFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListDoctorStripeCardssQuery = {
	listDoctorStripeCardss?: {
		__typename: 'ModelDoctorStripeCardsConnection';
		items: Array<{
			__typename: 'DoctorStripeCards';
			id: string;
			DoctorID: string;
			cardLastDigits?: string | null;
			stripePaymentMethodID?: string | null;
			defaultCard?: boolean | null;
			cardBrand?: string | null;
			status?: Status | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncDoctorStripeCardsQueryVariables = {
	filter?: ModelDoctorStripeCardsFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncDoctorStripeCardsQuery = {
	syncDoctorStripeCards?: {
		__typename: 'ModelDoctorStripeCardsConnection';
		items: Array<{
			__typename: 'DoctorStripeCards';
			id: string;
			DoctorID: string;
			cardLastDigits?: string | null;
			stripePaymentMethodID?: string | null;
			defaultCard?: boolean | null;
			cardBrand?: string | null;
			status?: Status | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetHospitalQueryVariables = {
	id: string;
};

export type GetHospitalQuery = {
	getHospital?: {
		__typename: 'Hospital';
		id: string;
		name: string;
		type?: string | null;
		province?: string | null;
		town?: string | null;
		sector?: string | null;
		phone?: string | null;
		address?: string | null;
		pssCode?: string | null;
		rnc?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type ListHospitalsQueryVariables = {
	filter?: ModelHospitalFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListHospitalsQuery = {
	listHospitals?: {
		__typename: 'ModelHospitalConnection';
		items: Array<{
			__typename: 'Hospital';
			id: string;
			name: string;
			type?: string | null;
			province?: string | null;
			town?: string | null;
			sector?: string | null;
			phone?: string | null;
			address?: string | null;
			pssCode?: string | null;
			rnc?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncHospitalsQueryVariables = {
	filter?: ModelHospitalFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncHospitalsQuery = {
	syncHospitals?: {
		__typename: 'ModelHospitalConnection';
		items: Array<{
			__typename: 'Hospital';
			id: string;
			name: string;
			type?: string | null;
			province?: string | null;
			town?: string | null;
			sector?: string | null;
			phone?: string | null;
			address?: string | null;
			pssCode?: string | null;
			rnc?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetHospitalDoctorQueryVariables = {
	id: string;
};

export type GetHospitalDoctorQuery = {
	getHospitalDoctor?: {
		__typename: 'HospitalDoctor';
		id: string;
		doctorID: string;
		hospitalID: string;
		lastWaitingListID: string;
		hospitalLogo?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		doctor?: {
			__typename: 'Doctor';
			id: string;
			name?: string | null;
			specialty?: string | null;
			exequator?: string | null;
			email: string;
			firstTime?: boolean | null;
			studyPlace?: string | null;
			isBeenReview?: boolean | null;
			isFirstAddSecretary?: boolean | null;
			hasTrialOnCreation?: boolean | null;
			title?: string | null;
			phone?: string | null;
			code?: string | null;
			password?: string | null;
			isAdmin?: boolean | null;
			daysOfTrial?: number | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
		hospital?: {
			__typename: 'Hospital';
			id: string;
			name: string;
			type?: string | null;
			province?: string | null;
			town?: string | null;
			sector?: string | null;
			phone?: string | null;
			address?: string | null;
			pssCode?: string | null;
			rnc?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type ListHospitalDoctorsQueryVariables = {
	filter?: ModelHospitalDoctorFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListHospitalDoctorsQuery = {
	listHospitalDoctors?: {
		__typename: 'ModelHospitalDoctorConnection';
		items: Array<{
			__typename: 'HospitalDoctor';
			id: string;
			doctorID: string;
			hospitalID: string;
			lastWaitingListID: string;
			hospitalLogo?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospital?: {
				__typename: 'Hospital';
				id: string;
				name: string;
				type?: string | null;
				province?: string | null;
				town?: string | null;
				sector?: string | null;
				phone?: string | null;
				address?: string | null;
				pssCode?: string | null;
				rnc?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncHospitalDoctorsQueryVariables = {
	filter?: ModelHospitalDoctorFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncHospitalDoctorsQuery = {
	syncHospitalDoctors?: {
		__typename: 'ModelHospitalDoctorConnection';
		items: Array<{
			__typename: 'HospitalDoctor';
			id: string;
			doctorID: string;
			hospitalID: string;
			lastWaitingListID: string;
			hospitalLogo?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospital?: {
				__typename: 'Hospital';
				id: string;
				name: string;
				type?: string | null;
				province?: string | null;
				town?: string | null;
				sector?: string | null;
				phone?: string | null;
				address?: string | null;
				pssCode?: string | null;
				rnc?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetTeamsQueryVariables = {
	id: string;
};

export type GetTeamsQuery = {
	getTeams?: {
		__typename: 'Teams';
		id: string;
		OwnerID: string;
		MemberID: string;
		status?: TeamStatus | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		Owner?: {
			__typename: 'HospitalDoctor';
			id: string;
			doctorID: string;
			hospitalID: string;
			lastWaitingListID: string;
			hospitalLogo?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospital?: {
				__typename: 'Hospital';
				id: string;
				name: string;
				type?: string | null;
				province?: string | null;
				town?: string | null;
				sector?: string | null;
				phone?: string | null;
				address?: string | null;
				pssCode?: string | null;
				rnc?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
		Member?: {
			__typename: 'HospitalDoctor';
			id: string;
			doctorID: string;
			hospitalID: string;
			lastWaitingListID: string;
			hospitalLogo?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospital?: {
				__typename: 'Hospital';
				id: string;
				name: string;
				type?: string | null;
				province?: string | null;
				town?: string | null;
				sector?: string | null;
				phone?: string | null;
				address?: string | null;
				pssCode?: string | null;
				rnc?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
	} | null;
};

export type ListTeamssQueryVariables = {
	filter?: ModelTeamsFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListTeamssQuery = {
	listTeamss?: {
		__typename: 'ModelTeamsConnection';
		items: Array<{
			__typename: 'Teams';
			id: string;
			OwnerID: string;
			MemberID: string;
			status?: TeamStatus | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			Owner?: {
				__typename: 'HospitalDoctor';
				id: string;
				doctorID: string;
				hospitalID: string;
				lastWaitingListID: string;
				hospitalLogo?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
				doctor?: {
					__typename: 'Doctor';
					id: string;
					name?: string | null;
					specialty?: string | null;
					exequator?: string | null;
					email: string;
					firstTime?: boolean | null;
					studyPlace?: string | null;
					isBeenReview?: boolean | null;
					isFirstAddSecretary?: boolean | null;
					hasTrialOnCreation?: boolean | null;
					title?: string | null;
					phone?: string | null;
					code?: string | null;
					password?: string | null;
					isAdmin?: boolean | null;
					daysOfTrial?: number | null;
					_version: number;
					_deleted?: boolean | null;
					_lastChangedAt: number;
					createdAt: string;
					updatedAt: string;
				} | null;
				hospital?: {
					__typename: 'Hospital';
					id: string;
					name: string;
					type?: string | null;
					province?: string | null;
					town?: string | null;
					sector?: string | null;
					phone?: string | null;
					address?: string | null;
					pssCode?: string | null;
					rnc?: string | null;
					_version: number;
					_deleted?: boolean | null;
					_lastChangedAt: number;
					createdAt: string;
					updatedAt: string;
				} | null;
			} | null;
			Member?: {
				__typename: 'HospitalDoctor';
				id: string;
				doctorID: string;
				hospitalID: string;
				lastWaitingListID: string;
				hospitalLogo?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
				doctor?: {
					__typename: 'Doctor';
					id: string;
					name?: string | null;
					specialty?: string | null;
					exequator?: string | null;
					email: string;
					firstTime?: boolean | null;
					studyPlace?: string | null;
					isBeenReview?: boolean | null;
					isFirstAddSecretary?: boolean | null;
					hasTrialOnCreation?: boolean | null;
					title?: string | null;
					phone?: string | null;
					code?: string | null;
					password?: string | null;
					isAdmin?: boolean | null;
					daysOfTrial?: number | null;
					_version: number;
					_deleted?: boolean | null;
					_lastChangedAt: number;
					createdAt: string;
					updatedAt: string;
				} | null;
				hospital?: {
					__typename: 'Hospital';
					id: string;
					name: string;
					type?: string | null;
					province?: string | null;
					town?: string | null;
					sector?: string | null;
					phone?: string | null;
					address?: string | null;
					pssCode?: string | null;
					rnc?: string | null;
					_version: number;
					_deleted?: boolean | null;
					_lastChangedAt: number;
					createdAt: string;
					updatedAt: string;
				} | null;
			} | null;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncTeamsQueryVariables = {
	filter?: ModelTeamsFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncTeamsQuery = {
	syncTeams?: {
		__typename: 'ModelTeamsConnection';
		items: Array<{
			__typename: 'Teams';
			id: string;
			OwnerID: string;
			MemberID: string;
			status?: TeamStatus | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			Owner?: {
				__typename: 'HospitalDoctor';
				id: string;
				doctorID: string;
				hospitalID: string;
				lastWaitingListID: string;
				hospitalLogo?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
				doctor?: {
					__typename: 'Doctor';
					id: string;
					name?: string | null;
					specialty?: string | null;
					exequator?: string | null;
					email: string;
					firstTime?: boolean | null;
					studyPlace?: string | null;
					isBeenReview?: boolean | null;
					isFirstAddSecretary?: boolean | null;
					hasTrialOnCreation?: boolean | null;
					title?: string | null;
					phone?: string | null;
					code?: string | null;
					password?: string | null;
					isAdmin?: boolean | null;
					daysOfTrial?: number | null;
					_version: number;
					_deleted?: boolean | null;
					_lastChangedAt: number;
					createdAt: string;
					updatedAt: string;
				} | null;
				hospital?: {
					__typename: 'Hospital';
					id: string;
					name: string;
					type?: string | null;
					province?: string | null;
					town?: string | null;
					sector?: string | null;
					phone?: string | null;
					address?: string | null;
					pssCode?: string | null;
					rnc?: string | null;
					_version: number;
					_deleted?: boolean | null;
					_lastChangedAt: number;
					createdAt: string;
					updatedAt: string;
				} | null;
			} | null;
			Member?: {
				__typename: 'HospitalDoctor';
				id: string;
				doctorID: string;
				hospitalID: string;
				lastWaitingListID: string;
				hospitalLogo?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
				doctor?: {
					__typename: 'Doctor';
					id: string;
					name?: string | null;
					specialty?: string | null;
					exequator?: string | null;
					email: string;
					firstTime?: boolean | null;
					studyPlace?: string | null;
					isBeenReview?: boolean | null;
					isFirstAddSecretary?: boolean | null;
					hasTrialOnCreation?: boolean | null;
					title?: string | null;
					phone?: string | null;
					code?: string | null;
					password?: string | null;
					isAdmin?: boolean | null;
					daysOfTrial?: number | null;
					_version: number;
					_deleted?: boolean | null;
					_lastChangedAt: number;
					createdAt: string;
					updatedAt: string;
				} | null;
				hospital?: {
					__typename: 'Hospital';
					id: string;
					name: string;
					type?: string | null;
					province?: string | null;
					town?: string | null;
					sector?: string | null;
					phone?: string | null;
					address?: string | null;
					pssCode?: string | null;
					rnc?: string | null;
					_version: number;
					_deleted?: boolean | null;
					_lastChangedAt: number;
					createdAt: string;
					updatedAt: string;
				} | null;
			} | null;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetHospitalDoctorClienteQueryVariables = {
	id: string;
};

export type GetHospitalDoctorClienteQuery = {
	getHospitalDoctorCliente?: {
		__typename: 'HospitalDoctorCliente';
		id: string;
		clientID: string;
		hospitalDoctorID: string;
		lastHealthInsurranceID?: string | null;
		clientStatus?: string | null;
		note?: string | null;
		recordNo?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		client?: {
			__typename: 'Client';
			id: string;
			identificationName?: IdentificationTypes | null;
			identificationData?: string | null;
			actualInsurance?: string | null;
			name: string;
			lastName: string;
			cellphoneNumber?: string | null;
			email?: string | null;
			bornDate?: string | null;
			parentOrTutorCedula?: string | null;
			parentOrTutorID?: string | null;
			gender?: SexType | null;
			phoneNumber?: string | null;
			addressStreet?: string | null;
			city?: string | null;
			isAdult?: boolean | null;
			sector?: string | null;
			bloodType?: string | null;
			company?: string | null;
			vitalSignsHeight?: string | null;
			vitalSignsWeight?: string | null;
			bodyMass?: string | null;
			vitalSignsBloodPressure?: string | null;
			heartRate?: string | null;
			respiratoryRate?: string | null;
			bodyTemperature?: string | null;
			oxygenSaturation?: string | null;
			occupation?: string | null;
			profileImage?: string | null;
			heartBeat?: string | null;
			weight?: string | null;
			height?: string | null;
			bloodPressure?: string | null;
			updateDateTime?: string | null;
			createdAt: string;
			updatedAt: string;
			code?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
		} | null;
		hospitalDoctor?: {
			__typename: 'HospitalDoctor';
			id: string;
			doctorID: string;
			hospitalID: string;
			lastWaitingListID: string;
			hospitalLogo?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospital?: {
				__typename: 'Hospital';
				id: string;
				name: string;
				type?: string | null;
				province?: string | null;
				town?: string | null;
				sector?: string | null;
				phone?: string | null;
				address?: string | null;
				pssCode?: string | null;
				rnc?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
		clientInsurance?: {
			__typename: 'ClientInsurance';
			id: string;
			insuranceID?: string | null;
			clientID?: string | null;
			contractNumber?: string | null;
			affiliateNumber?: string | null;
			affiliateType?: AffiliateTypes | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			insurance?: {
				__typename: 'Insurance';
				id: string;
				name: string;
				allowClaim?: boolean | null;
				code?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
	} | null;
};

export type ListHospitalDoctorClientesQueryVariables = {
	filter?: ModelHospitalDoctorClienteFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListHospitalDoctorClientesQuery = {
	listHospitalDoctorClientes?: {
		__typename: 'ModelHospitalDoctorClienteConnection';
		items: Array<{
			__typename: 'HospitalDoctorCliente';
			id: string;
			clientID: string;
			hospitalDoctorID: string;
			lastHealthInsurranceID?: string | null;
			clientStatus?: string | null;
			note?: string | null;
			recordNo?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			client?: {
				__typename: 'Client';
				id: string;
				identificationName?: IdentificationTypes | null;
				identificationData?: string | null;
				actualInsurance?: string | null;
				name: string;
				lastName: string;
				cellphoneNumber?: string | null;
				email?: string | null;
				bornDate?: string | null;
				parentOrTutorCedula?: string | null;
				parentOrTutorID?: string | null;
				gender?: SexType | null;
				phoneNumber?: string | null;
				addressStreet?: string | null;
				city?: string | null;
				isAdult?: boolean | null;
				sector?: string | null;
				bloodType?: string | null;
				company?: string | null;
				vitalSignsHeight?: string | null;
				vitalSignsWeight?: string | null;
				bodyMass?: string | null;
				vitalSignsBloodPressure?: string | null;
				heartRate?: string | null;
				respiratoryRate?: string | null;
				bodyTemperature?: string | null;
				oxygenSaturation?: string | null;
				occupation?: string | null;
				profileImage?: string | null;
				heartBeat?: string | null;
				weight?: string | null;
				height?: string | null;
				bloodPressure?: string | null;
				updateDateTime?: string | null;
				createdAt: string;
				updatedAt: string;
				code?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
			} | null;
			hospitalDoctor?: {
				__typename: 'HospitalDoctor';
				id: string;
				doctorID: string;
				hospitalID: string;
				lastWaitingListID: string;
				hospitalLogo?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
				doctor?: {
					__typename: 'Doctor';
					id: string;
					name?: string | null;
					specialty?: string | null;
					exequator?: string | null;
					email: string;
					firstTime?: boolean | null;
					studyPlace?: string | null;
					isBeenReview?: boolean | null;
					isFirstAddSecretary?: boolean | null;
					hasTrialOnCreation?: boolean | null;
					title?: string | null;
					phone?: string | null;
					code?: string | null;
					password?: string | null;
					isAdmin?: boolean | null;
					daysOfTrial?: number | null;
					_version: number;
					_deleted?: boolean | null;
					_lastChangedAt: number;
					createdAt: string;
					updatedAt: string;
				} | null;
				hospital?: {
					__typename: 'Hospital';
					id: string;
					name: string;
					type?: string | null;
					province?: string | null;
					town?: string | null;
					sector?: string | null;
					phone?: string | null;
					address?: string | null;
					pssCode?: string | null;
					rnc?: string | null;
					_version: number;
					_deleted?: boolean | null;
					_lastChangedAt: number;
					createdAt: string;
					updatedAt: string;
				} | null;
			} | null;
			clientInsurance?: {
				__typename: 'ClientInsurance';
				id: string;
				insuranceID?: string | null;
				clientID?: string | null;
				contractNumber?: string | null;
				affiliateNumber?: string | null;
				affiliateType?: AffiliateTypes | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
				insurance?: {
					__typename: 'Insurance';
					id: string;
					name: string;
					allowClaim?: boolean | null;
					code?: string | null;
					_version: number;
					_deleted?: boolean | null;
					_lastChangedAt: number;
					createdAt: string;
					updatedAt: string;
				} | null;
			} | null;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncHospitalDoctorClientesQueryVariables = {
	filter?: ModelHospitalDoctorClienteFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncHospitalDoctorClientesQuery = {
	syncHospitalDoctorClientes?: {
		__typename: 'ModelHospitalDoctorClienteConnection';
		items: Array<{
			__typename: 'HospitalDoctorCliente';
			id: string;
			clientID: string;
			hospitalDoctorID: string;
			lastHealthInsurranceID?: string | null;
			clientStatus?: string | null;
			note?: string | null;
			recordNo?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			client?: {
				__typename: 'Client';
				id: string;
				identificationName?: IdentificationTypes | null;
				identificationData?: string | null;
				actualInsurance?: string | null;
				name: string;
				lastName: string;
				cellphoneNumber?: string | null;
				email?: string | null;
				bornDate?: string | null;
				parentOrTutorCedula?: string | null;
				parentOrTutorID?: string | null;
				gender?: SexType | null;
				phoneNumber?: string | null;
				addressStreet?: string | null;
				city?: string | null;
				isAdult?: boolean | null;
				sector?: string | null;
				bloodType?: string | null;
				company?: string | null;
				vitalSignsHeight?: string | null;
				vitalSignsWeight?: string | null;
				bodyMass?: string | null;
				vitalSignsBloodPressure?: string | null;
				heartRate?: string | null;
				respiratoryRate?: string | null;
				bodyTemperature?: string | null;
				oxygenSaturation?: string | null;
				occupation?: string | null;
				profileImage?: string | null;
				heartBeat?: string | null;
				weight?: string | null;
				height?: string | null;
				bloodPressure?: string | null;
				updateDateTime?: string | null;
				createdAt: string;
				updatedAt: string;
				code?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
			} | null;
			hospitalDoctor?: {
				__typename: 'HospitalDoctor';
				id: string;
				doctorID: string;
				hospitalID: string;
				lastWaitingListID: string;
				hospitalLogo?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
				doctor?: {
					__typename: 'Doctor';
					id: string;
					name?: string | null;
					specialty?: string | null;
					exequator?: string | null;
					email: string;
					firstTime?: boolean | null;
					studyPlace?: string | null;
					isBeenReview?: boolean | null;
					isFirstAddSecretary?: boolean | null;
					hasTrialOnCreation?: boolean | null;
					title?: string | null;
					phone?: string | null;
					code?: string | null;
					password?: string | null;
					isAdmin?: boolean | null;
					daysOfTrial?: number | null;
					_version: number;
					_deleted?: boolean | null;
					_lastChangedAt: number;
					createdAt: string;
					updatedAt: string;
				} | null;
				hospital?: {
					__typename: 'Hospital';
					id: string;
					name: string;
					type?: string | null;
					province?: string | null;
					town?: string | null;
					sector?: string | null;
					phone?: string | null;
					address?: string | null;
					pssCode?: string | null;
					rnc?: string | null;
					_version: number;
					_deleted?: boolean | null;
					_lastChangedAt: number;
					createdAt: string;
					updatedAt: string;
				} | null;
			} | null;
			clientInsurance?: {
				__typename: 'ClientInsurance';
				id: string;
				insuranceID?: string | null;
				clientID?: string | null;
				contractNumber?: string | null;
				affiliateNumber?: string | null;
				affiliateType?: AffiliateTypes | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
				insurance?: {
					__typename: 'Insurance';
					id: string;
					name: string;
					allowClaim?: boolean | null;
					code?: string | null;
					_version: number;
					_deleted?: boolean | null;
					_lastChangedAt: number;
					createdAt: string;
					updatedAt: string;
				} | null;
			} | null;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetWaitListQueryVariables = {
	id: string;
};

export type GetWaitListQuery = {
	getWaitList?: {
		__typename: 'WaitList';
		id: string;
		hospitalDoctorID: string;
		createdAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		updatedAt: string;
	} | null;
};

export type ListWaitListsQueryVariables = {
	filter?: ModelWaitListFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListWaitListsQuery = {
	listWaitLists?: {
		__typename: 'ModelWaitListConnection';
		items: Array<{
			__typename: 'WaitList';
			id: string;
			hospitalDoctorID: string;
			createdAt?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncWaitListsQueryVariables = {
	filter?: ModelWaitListFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncWaitListsQuery = {
	syncWaitLists?: {
		__typename: 'ModelWaitListConnection';
		items: Array<{
			__typename: 'WaitList';
			id: string;
			hospitalDoctorID: string;
			createdAt?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetWaitingListItemQueryVariables = {
	id: string;
};

export type GetWaitingListItemQuery = {
	getWaitingListItem?: {
		__typename: 'WaitingListItem';
		id: string;
		waitingListID: string;
		clientID: string;
		status: WaitingListItemStatus;
		clientHealthInsurrance?: string | null;
		positionNumber: number;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type ListWaitingListItemsQueryVariables = {
	filter?: ModelWaitingListItemFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListWaitingListItemsQuery = {
	listWaitingListItems?: {
		__typename: 'ModelWaitingListItemConnection';
		items: Array<{
			__typename: 'WaitingListItem';
			id: string;
			waitingListID: string;
			clientID: string;
			status: WaitingListItemStatus;
			clientHealthInsurrance?: string | null;
			positionNumber: number;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncWaitingListItemsQueryVariables = {
	filter?: ModelWaitingListItemFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncWaitingListItemsQuery = {
	syncWaitingListItems?: {
		__typename: 'ModelWaitingListItemConnection';
		items: Array<{
			__typename: 'WaitingListItem';
			id: string;
			waitingListID: string;
			clientID: string;
			status: WaitingListItemStatus;
			clientHealthInsurrance?: string | null;
			positionNumber: number;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetClientInsuranceQueryVariables = {
	id: string;
};

export type GetClientInsuranceQuery = {
	getClientInsurance?: {
		__typename: 'ClientInsurance';
		id: string;
		insuranceID?: string | null;
		clientID?: string | null;
		contractNumber?: string | null;
		affiliateNumber?: string | null;
		affiliateType?: AffiliateTypes | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		insurance?: {
			__typename: 'Insurance';
			id: string;
			name: string;
			allowClaim?: boolean | null;
			code?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type ListClientInsurancesQueryVariables = {
	filter?: ModelClientInsuranceFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListClientInsurancesQuery = {
	listClientInsurances?: {
		__typename: 'ModelClientInsuranceConnection';
		items: Array<{
			__typename: 'ClientInsurance';
			id: string;
			insuranceID?: string | null;
			clientID?: string | null;
			contractNumber?: string | null;
			affiliateNumber?: string | null;
			affiliateType?: AffiliateTypes | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			insurance?: {
				__typename: 'Insurance';
				id: string;
				name: string;
				allowClaim?: boolean | null;
				code?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncClientInsurancesQueryVariables = {
	filter?: ModelClientInsuranceFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncClientInsurancesQuery = {
	syncClientInsurances?: {
		__typename: 'ModelClientInsuranceConnection';
		items: Array<{
			__typename: 'ClientInsurance';
			id: string;
			insuranceID?: string | null;
			clientID?: string | null;
			contractNumber?: string | null;
			affiliateNumber?: string | null;
			affiliateType?: AffiliateTypes | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			insurance?: {
				__typename: 'Insurance';
				id: string;
				name: string;
				allowClaim?: boolean | null;
				code?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetInsuranceQueryVariables = {
	id: string;
};

export type GetInsuranceQuery = {
	getInsurance?: {
		__typename: 'Insurance';
		id: string;
		name: string;
		allowClaim?: boolean | null;
		code?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type ListInsurancesQueryVariables = {
	filter?: ModelInsuranceFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListInsurancesQuery = {
	listInsurances?: {
		__typename: 'ModelInsuranceConnection';
		items: Array<{
			__typename: 'Insurance';
			id: string;
			name: string;
			allowClaim?: boolean | null;
			code?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncInsurancesQueryVariables = {
	filter?: ModelInsuranceFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncInsurancesQuery = {
	syncInsurances?: {
		__typename: 'ModelInsuranceConnection';
		items: Array<{
			__typename: 'Insurance';
			id: string;
			name: string;
			allowClaim?: boolean | null;
			code?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetClientClaimQueryVariables = {
	id: string;
};

export type GetClientClaimQuery = {
	getClientClaim?: {
		__typename: 'ClientClaim';
		id: string;
		clientHospitalDoctorID: string;
		healthInsuranceID: string;
		diagnostic: string;
		authorization: string;
		date: string;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		healthInsurance?: {
			__typename: 'Insurance';
			id: string;
			name: string;
			allowClaim?: boolean | null;
			code?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type ListClientClaimsQueryVariables = {
	filter?: ModelClientClaimFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListClientClaimsQuery = {
	listClientClaims?: {
		__typename: 'ModelClientClaimConnection';
		items: Array<{
			__typename: 'ClientClaim';
			id: string;
			clientHospitalDoctorID: string;
			healthInsuranceID: string;
			diagnostic: string;
			authorization: string;
			date: string;
			status?: Status | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			healthInsurance?: {
				__typename: 'Insurance';
				id: string;
				name: string;
				allowClaim?: boolean | null;
				code?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncClientClaimsQueryVariables = {
	filter?: ModelClientClaimFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncClientClaimsQuery = {
	syncClientClaims?: {
		__typename: 'ModelClientClaimConnection';
		items: Array<{
			__typename: 'ClientClaim';
			id: string;
			clientHospitalDoctorID: string;
			healthInsuranceID: string;
			diagnostic: string;
			authorization: string;
			date: string;
			status?: Status | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			healthInsurance?: {
				__typename: 'Insurance';
				id: string;
				name: string;
				allowClaim?: boolean | null;
				code?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetClaimProcessQueryVariables = {
	id: string;
};

export type GetClaimProcessQuery = {
	getClaimProcess?: {
		__typename: 'ClaimProcess';
		id: string;
		claimID: string;
		name: string;
		code: string;
		claimed: string;
		difference: string;
		authorized: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type ListClaimProcesssQueryVariables = {
	filter?: ModelClaimProcessFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListClaimProcesssQuery = {
	listClaimProcesss?: {
		__typename: 'ModelClaimProcessConnection';
		items: Array<{
			__typename: 'ClaimProcess';
			id: string;
			claimID: string;
			name: string;
			code: string;
			claimed: string;
			difference: string;
			authorized: string;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncClaimProcessesQueryVariables = {
	filter?: ModelClaimProcessFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncClaimProcessesQuery = {
	syncClaimProcesses?: {
		__typename: 'ModelClaimProcessConnection';
		items: Array<{
			__typename: 'ClaimProcess';
			id: string;
			claimID: string;
			name: string;
			code: string;
			claimed: string;
			difference: string;
			authorized: string;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetFormsQueryVariables = {
	id: string;
};

export type GetFormsQuery = {
	getForms?: {
		__typename: 'Forms';
		id: string;
		DoctorID?: string | null;
		public?: boolean | null;
		name?: string | null;
		formImage?: string | null;
		description?: string | null;
		elements?: Array<string | null> | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type ListFormssQueryVariables = {
	filter?: ModelFormsFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListFormssQuery = {
	listFormss?: {
		__typename: 'ModelFormsConnection';
		items: Array<{
			__typename: 'Forms';
			id: string;
			DoctorID?: string | null;
			public?: boolean | null;
			name?: string | null;
			formImage?: string | null;
			description?: string | null;
			elements?: Array<string | null> | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncFormsQueryVariables = {
	filter?: ModelFormsFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncFormsQuery = {
	syncForms?: {
		__typename: 'ModelFormsConnection';
		items: Array<{
			__typename: 'Forms';
			id: string;
			DoctorID?: string | null;
			public?: boolean | null;
			name?: string | null;
			formImage?: string | null;
			description?: string | null;
			elements?: Array<string | null> | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type GetAppointmentQueryVariables = {
	id: string;
};

export type GetAppointmentQuery = {
	getAppointment?: {
		__typename: 'Appointment';
		id: string;
		clientID: string;
		doctorID: string;
		hospitalID: string;
		description: string;
		date: string;
		start: string;
		end: string;
		status?: Status | null;
		shouldSendReminder?: boolean | null;
		createdAt: string;
		updatedAt: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		client?: {
			__typename: 'Client';
			id: string;
			identificationName?: IdentificationTypes | null;
			identificationData?: string | null;
			actualInsurance?: string | null;
			name: string;
			lastName: string;
			cellphoneNumber?: string | null;
			email?: string | null;
			bornDate?: string | null;
			parentOrTutorCedula?: string | null;
			parentOrTutorID?: string | null;
			gender?: SexType | null;
			phoneNumber?: string | null;
			addressStreet?: string | null;
			city?: string | null;
			isAdult?: boolean | null;
			sector?: string | null;
			bloodType?: string | null;
			company?: string | null;
			vitalSignsHeight?: string | null;
			vitalSignsWeight?: string | null;
			bodyMass?: string | null;
			vitalSignsBloodPressure?: string | null;
			heartRate?: string | null;
			respiratoryRate?: string | null;
			bodyTemperature?: string | null;
			oxygenSaturation?: string | null;
			occupation?: string | null;
			profileImage?: string | null;
			heartBeat?: string | null;
			weight?: string | null;
			height?: string | null;
			bloodPressure?: string | null;
			updateDateTime?: string | null;
			createdAt: string;
			updatedAt: string;
			code?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
		} | null;
		doctor?: {
			__typename: 'Doctor';
			id: string;
			name?: string | null;
			specialty?: string | null;
			exequator?: string | null;
			email: string;
			firstTime?: boolean | null;
			studyPlace?: string | null;
			isBeenReview?: boolean | null;
			isFirstAddSecretary?: boolean | null;
			hasTrialOnCreation?: boolean | null;
			title?: string | null;
			phone?: string | null;
			code?: string | null;
			password?: string | null;
			isAdmin?: boolean | null;
			daysOfTrial?: number | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
		hospital?: {
			__typename: 'Hospital';
			id: string;
			name: string;
			type?: string | null;
			province?: string | null;
			town?: string | null;
			sector?: string | null;
			phone?: string | null;
			address?: string | null;
			pssCode?: string | null;
			rnc?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type ListAppointmentsQueryVariables = {
	filter?: ModelAppointmentFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
};

export type ListAppointmentsQuery = {
	listAppointments?: {
		__typename: 'ModelAppointmentConnection';
		items: Array<{
			__typename: 'Appointment';
			id: string;
			clientID: string;
			doctorID: string;
			hospitalID: string;
			description: string;
			date: string;
			start: string;
			end: string;
			status?: Status | null;
			shouldSendReminder?: boolean | null;
			createdAt: string;
			updatedAt: string;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			client?: {
				__typename: 'Client';
				id: string;
				identificationName?: IdentificationTypes | null;
				identificationData?: string | null;
				actualInsurance?: string | null;
				name: string;
				lastName: string;
				cellphoneNumber?: string | null;
				email?: string | null;
				bornDate?: string | null;
				parentOrTutorCedula?: string | null;
				parentOrTutorID?: string | null;
				gender?: SexType | null;
				phoneNumber?: string | null;
				addressStreet?: string | null;
				city?: string | null;
				isAdult?: boolean | null;
				sector?: string | null;
				bloodType?: string | null;
				company?: string | null;
				vitalSignsHeight?: string | null;
				vitalSignsWeight?: string | null;
				bodyMass?: string | null;
				vitalSignsBloodPressure?: string | null;
				heartRate?: string | null;
				respiratoryRate?: string | null;
				bodyTemperature?: string | null;
				oxygenSaturation?: string | null;
				occupation?: string | null;
				profileImage?: string | null;
				heartBeat?: string | null;
				weight?: string | null;
				height?: string | null;
				bloodPressure?: string | null;
				updateDateTime?: string | null;
				createdAt: string;
				updatedAt: string;
				code?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
			} | null;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospital?: {
				__typename: 'Hospital';
				id: string;
				name: string;
				type?: string | null;
				province?: string | null;
				town?: string | null;
				sector?: string | null;
				phone?: string | null;
				address?: string | null;
				pssCode?: string | null;
				rnc?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type SyncAppointmentsQueryVariables = {
	filter?: ModelAppointmentFilterInput | null;
	limit?: number | null;
	nextToken?: string | null;
	lastSync?: number | null;
};

export type SyncAppointmentsQuery = {
	syncAppointments?: {
		__typename: 'ModelAppointmentConnection';
		items: Array<{
			__typename: 'Appointment';
			id: string;
			clientID: string;
			doctorID: string;
			hospitalID: string;
			description: string;
			date: string;
			start: string;
			end: string;
			status?: Status | null;
			shouldSendReminder?: boolean | null;
			createdAt: string;
			updatedAt: string;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			client?: {
				__typename: 'Client';
				id: string;
				identificationName?: IdentificationTypes | null;
				identificationData?: string | null;
				actualInsurance?: string | null;
				name: string;
				lastName: string;
				cellphoneNumber?: string | null;
				email?: string | null;
				bornDate?: string | null;
				parentOrTutorCedula?: string | null;
				parentOrTutorID?: string | null;
				gender?: SexType | null;
				phoneNumber?: string | null;
				addressStreet?: string | null;
				city?: string | null;
				isAdult?: boolean | null;
				sector?: string | null;
				bloodType?: string | null;
				company?: string | null;
				vitalSignsHeight?: string | null;
				vitalSignsWeight?: string | null;
				bodyMass?: string | null;
				vitalSignsBloodPressure?: string | null;
				heartRate?: string | null;
				respiratoryRate?: string | null;
				bodyTemperature?: string | null;
				oxygenSaturation?: string | null;
				occupation?: string | null;
				profileImage?: string | null;
				heartBeat?: string | null;
				weight?: string | null;
				height?: string | null;
				bloodPressure?: string | null;
				updateDateTime?: string | null;
				createdAt: string;
				updatedAt: string;
				code?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
			} | null;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospital?: {
				__typename: 'Hospital';
				id: string;
				name: string;
				type?: string | null;
				province?: string | null;
				town?: string | null;
				sector?: string | null;
				phone?: string | null;
				address?: string | null;
				pssCode?: string | null;
				rnc?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null>;
		nextToken?: string | null;
		startedAt?: number | null;
	} | null;
};

export type OnCreateSecretarySubscriptionVariables = {};

export type OnCreateSecretarySubscription = {
	onCreateSecretary?: {
		__typename: 'Secretary';
		id: string;
		name?: string | null;
		email: string;
		firstTime?: boolean | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnUpdateSecretarySubscriptionVariables = {};

export type OnUpdateSecretarySubscription = {
	onUpdateSecretary?: {
		__typename: 'Secretary';
		id: string;
		name?: string | null;
		email: string;
		firstTime?: boolean | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnDeleteSecretarySubscriptionVariables = {};

export type OnDeleteSecretarySubscription = {
	onDeleteSecretary?: {
		__typename: 'Secretary';
		id: string;
		name?: string | null;
		email: string;
		firstTime?: boolean | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnCreateEmailDistributionListsSubscriptionVariables = {};

export type OnCreateEmailDistributionListsSubscription = {
	onCreateEmailDistributionLists?: {
		__typename: 'EmailDistributionLists';
		id: string;
		email?: string | null;
		specialty?: string | null;
		createdAt: string;
		updatedAt: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type OnUpdateEmailDistributionListsSubscriptionVariables = {};

export type OnUpdateEmailDistributionListsSubscription = {
	onUpdateEmailDistributionLists?: {
		__typename: 'EmailDistributionLists';
		id: string;
		email?: string | null;
		specialty?: string | null;
		createdAt: string;
		updatedAt: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type OnDeleteEmailDistributionListsSubscriptionVariables = {};

export type OnDeleteEmailDistributionListsSubscription = {
	onDeleteEmailDistributionLists?: {
		__typename: 'EmailDistributionLists';
		id: string;
		email?: string | null;
		specialty?: string | null;
		createdAt: string;
		updatedAt: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type OnCreateSecretaryHospitalDoctorSubscriptionVariables = {};

export type OnCreateSecretaryHospitalDoctorSubscription = {
	onCreateSecretaryHospitalDoctor?: {
		__typename: 'SecretaryHospitalDoctor';
		id: string;
		hospitalDoctorID: string;
		secretaryID: string;
		hasHistoryPermission?: boolean | null;
		hasSurgeryPermission?: boolean | null;
		hasPrescriptionsPermission?: boolean | null;
		hasFilesPermission?: boolean | null;
		hasNotesPermission?: boolean | null;
		hasClaimsPermission?: boolean | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		secretary?: {
			__typename: 'Secretary';
			id: string;
			name?: string | null;
			email: string;
			firstTime?: boolean | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
		hospitalDoctor?: {
			__typename: 'HospitalDoctor';
			id: string;
			doctorID: string;
			hospitalID: string;
			lastWaitingListID: string;
			hospitalLogo?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospital?: {
				__typename: 'Hospital';
				id: string;
				name: string;
				type?: string | null;
				province?: string | null;
				town?: string | null;
				sector?: string | null;
				phone?: string | null;
				address?: string | null;
				pssCode?: string | null;
				rnc?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
	} | null;
};

export type OnUpdateSecretaryHospitalDoctorSubscriptionVariables = {};

export type OnUpdateSecretaryHospitalDoctorSubscription = {
	onUpdateSecretaryHospitalDoctor?: {
		__typename: 'SecretaryHospitalDoctor';
		id: string;
		hospitalDoctorID: string;
		secretaryID: string;
		hasHistoryPermission?: boolean | null;
		hasSurgeryPermission?: boolean | null;
		hasPrescriptionsPermission?: boolean | null;
		hasFilesPermission?: boolean | null;
		hasNotesPermission?: boolean | null;
		hasClaimsPermission?: boolean | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		secretary?: {
			__typename: 'Secretary';
			id: string;
			name?: string | null;
			email: string;
			firstTime?: boolean | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
		hospitalDoctor?: {
			__typename: 'HospitalDoctor';
			id: string;
			doctorID: string;
			hospitalID: string;
			lastWaitingListID: string;
			hospitalLogo?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospital?: {
				__typename: 'Hospital';
				id: string;
				name: string;
				type?: string | null;
				province?: string | null;
				town?: string | null;
				sector?: string | null;
				phone?: string | null;
				address?: string | null;
				pssCode?: string | null;
				rnc?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
	} | null;
};

export type OnDeleteSecretaryHospitalDoctorSubscriptionVariables = {};

export type OnDeleteSecretaryHospitalDoctorSubscription = {
	onDeleteSecretaryHospitalDoctor?: {
		__typename: 'SecretaryHospitalDoctor';
		id: string;
		hospitalDoctorID: string;
		secretaryID: string;
		hasHistoryPermission?: boolean | null;
		hasSurgeryPermission?: boolean | null;
		hasPrescriptionsPermission?: boolean | null;
		hasFilesPermission?: boolean | null;
		hasNotesPermission?: boolean | null;
		hasClaimsPermission?: boolean | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		secretary?: {
			__typename: 'Secretary';
			id: string;
			name?: string | null;
			email: string;
			firstTime?: boolean | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
		hospitalDoctor?: {
			__typename: 'HospitalDoctor';
			id: string;
			doctorID: string;
			hospitalID: string;
			lastWaitingListID: string;
			hospitalLogo?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospital?: {
				__typename: 'Hospital';
				id: string;
				name: string;
				type?: string | null;
				province?: string | null;
				town?: string | null;
				sector?: string | null;
				phone?: string | null;
				address?: string | null;
				pssCode?: string | null;
				rnc?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
	} | null;
};

export type OnCreateSecretaryHospitalDoctorClientSubscriptionVariables = {};

export type OnCreateSecretaryHospitalDoctorClientSubscription = {
	onCreateSecretaryHospitalDoctorClient?: {
		__typename: 'SecretaryHospitalDoctorClient';
		id: string;
		hospitalDoctorID?: string | null;
		secretaryID?: string | null;
		clientID?: string | null;
		note?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnUpdateSecretaryHospitalDoctorClientSubscriptionVariables = {};

export type OnUpdateSecretaryHospitalDoctorClientSubscription = {
	onUpdateSecretaryHospitalDoctorClient?: {
		__typename: 'SecretaryHospitalDoctorClient';
		id: string;
		hospitalDoctorID?: string | null;
		secretaryID?: string | null;
		clientID?: string | null;
		note?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnDeleteSecretaryHospitalDoctorClientSubscriptionVariables = {};

export type OnDeleteSecretaryHospitalDoctorClientSubscription = {
	onDeleteSecretaryHospitalDoctorClient?: {
		__typename: 'SecretaryHospitalDoctorClient';
		id: string;
		hospitalDoctorID?: string | null;
		secretaryID?: string | null;
		clientID?: string | null;
		note?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnCreateMemberHospitalDoctorClientSubscriptionVariables = {};

export type OnCreateMemberHospitalDoctorClientSubscription = {
	onCreateMemberHospitalDoctorClient?: {
		__typename: 'MemberHospitalDoctorClient';
		id: string;
		hospitalDoctorID?: string | null;
		memberID?: string | null;
		ownerID?: string | null;
		clientID?: string | null;
		note?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		owner?: {
			__typename: 'HospitalDoctor';
			id: string;
			doctorID: string;
			hospitalID: string;
			lastWaitingListID: string;
			hospitalLogo?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospital?: {
				__typename: 'Hospital';
				id: string;
				name: string;
				type?: string | null;
				province?: string | null;
				town?: string | null;
				sector?: string | null;
				phone?: string | null;
				address?: string | null;
				pssCode?: string | null;
				rnc?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
	} | null;
};

export type OnUpdateMemberHospitalDoctorClientSubscriptionVariables = {};

export type OnUpdateMemberHospitalDoctorClientSubscription = {
	onUpdateMemberHospitalDoctorClient?: {
		__typename: 'MemberHospitalDoctorClient';
		id: string;
		hospitalDoctorID?: string | null;
		memberID?: string | null;
		ownerID?: string | null;
		clientID?: string | null;
		note?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		owner?: {
			__typename: 'HospitalDoctor';
			id: string;
			doctorID: string;
			hospitalID: string;
			lastWaitingListID: string;
			hospitalLogo?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospital?: {
				__typename: 'Hospital';
				id: string;
				name: string;
				type?: string | null;
				province?: string | null;
				town?: string | null;
				sector?: string | null;
				phone?: string | null;
				address?: string | null;
				pssCode?: string | null;
				rnc?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
	} | null;
};

export type OnDeleteMemberHospitalDoctorClientSubscriptionVariables = {};

export type OnDeleteMemberHospitalDoctorClientSubscription = {
	onDeleteMemberHospitalDoctorClient?: {
		__typename: 'MemberHospitalDoctorClient';
		id: string;
		hospitalDoctorID?: string | null;
		memberID?: string | null;
		ownerID?: string | null;
		clientID?: string | null;
		note?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		owner?: {
			__typename: 'HospitalDoctor';
			id: string;
			doctorID: string;
			hospitalID: string;
			lastWaitingListID: string;
			hospitalLogo?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospital?: {
				__typename: 'Hospital';
				id: string;
				name: string;
				type?: string | null;
				province?: string | null;
				town?: string | null;
				sector?: string | null;
				phone?: string | null;
				address?: string | null;
				pssCode?: string | null;
				rnc?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
	} | null;
};

export type OnCreateClientSubscriptionVariables = {};

export type OnCreateClientSubscription = {
	onCreateClient?: {
		__typename: 'Client';
		id: string;
		identificationName?: IdentificationTypes | null;
		identificationData?: string | null;
		actualInsurance?: string | null;
		name: string;
		lastName: string;
		cellphoneNumber?: string | null;
		email?: string | null;
		bornDate?: string | null;
		parentOrTutorCedula?: string | null;
		parentOrTutorID?: string | null;
		gender?: SexType | null;
		phoneNumber?: string | null;
		addressStreet?: string | null;
		city?: string | null;
		isAdult?: boolean | null;
		sector?: string | null;
		bloodType?: string | null;
		company?: string | null;
		vitalSignsHeight?: string | null;
		vitalSignsWeight?: string | null;
		bodyMass?: string | null;
		vitalSignsBloodPressure?: string | null;
		heartRate?: string | null;
		respiratoryRate?: string | null;
		bodyTemperature?: string | null;
		oxygenSaturation?: string | null;
		occupation?: string | null;
		profileImage?: string | null;
		heartBeat?: string | null;
		weight?: string | null;
		height?: string | null;
		bloodPressure?: string | null;
		updateDateTime?: string | null;
		createdAt: string;
		updatedAt: string;
		code?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type OnUpdateClientSubscriptionVariables = {};

export type OnUpdateClientSubscription = {
	onUpdateClient?: {
		__typename: 'Client';
		id: string;
		identificationName?: IdentificationTypes | null;
		identificationData?: string | null;
		actualInsurance?: string | null;
		name: string;
		lastName: string;
		cellphoneNumber?: string | null;
		email?: string | null;
		bornDate?: string | null;
		parentOrTutorCedula?: string | null;
		parentOrTutorID?: string | null;
		gender?: SexType | null;
		phoneNumber?: string | null;
		addressStreet?: string | null;
		city?: string | null;
		isAdult?: boolean | null;
		sector?: string | null;
		bloodType?: string | null;
		company?: string | null;
		vitalSignsHeight?: string | null;
		vitalSignsWeight?: string | null;
		bodyMass?: string | null;
		vitalSignsBloodPressure?: string | null;
		heartRate?: string | null;
		respiratoryRate?: string | null;
		bodyTemperature?: string | null;
		oxygenSaturation?: string | null;
		occupation?: string | null;
		profileImage?: string | null;
		heartBeat?: string | null;
		weight?: string | null;
		height?: string | null;
		bloodPressure?: string | null;
		updateDateTime?: string | null;
		createdAt: string;
		updatedAt: string;
		code?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type OnDeleteClientSubscriptionVariables = {};

export type OnDeleteClientSubscription = {
	onDeleteClient?: {
		__typename: 'Client';
		id: string;
		identificationName?: IdentificationTypes | null;
		identificationData?: string | null;
		actualInsurance?: string | null;
		name: string;
		lastName: string;
		cellphoneNumber?: string | null;
		email?: string | null;
		bornDate?: string | null;
		parentOrTutorCedula?: string | null;
		parentOrTutorID?: string | null;
		gender?: SexType | null;
		phoneNumber?: string | null;
		addressStreet?: string | null;
		city?: string | null;
		isAdult?: boolean | null;
		sector?: string | null;
		bloodType?: string | null;
		company?: string | null;
		vitalSignsHeight?: string | null;
		vitalSignsWeight?: string | null;
		bodyMass?: string | null;
		vitalSignsBloodPressure?: string | null;
		heartRate?: string | null;
		respiratoryRate?: string | null;
		bodyTemperature?: string | null;
		oxygenSaturation?: string | null;
		occupation?: string | null;
		profileImage?: string | null;
		heartBeat?: string | null;
		weight?: string | null;
		height?: string | null;
		bloodPressure?: string | null;
		updateDateTime?: string | null;
		createdAt: string;
		updatedAt: string;
		code?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type OnCreateClientImageSectionSubscriptionVariables = {};

export type OnCreateClientImageSectionSubscription = {
	onCreateClientImageSection?: {
		__typename: 'ClientImageSection';
		id: string;
		clientHospitalDoctorID?: string | null;
		date?: string | null;
		description?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnUpdateClientImageSectionSubscriptionVariables = {};

export type OnUpdateClientImageSectionSubscription = {
	onUpdateClientImageSection?: {
		__typename: 'ClientImageSection';
		id: string;
		clientHospitalDoctorID?: string | null;
		date?: string | null;
		description?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnDeleteClientImageSectionSubscriptionVariables = {};

export type OnDeleteClientImageSectionSubscription = {
	onDeleteClientImageSection?: {
		__typename: 'ClientImageSection';
		id: string;
		clientHospitalDoctorID?: string | null;
		date?: string | null;
		description?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnCreatePosibleClientSubscriptionVariables = {};

export type OnCreatePosibleClientSubscription = {
	onCreatePosibleClient?: {
		__typename: 'PosibleClient';
		id: string;
		phoneNumber?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnUpdatePosibleClientSubscriptionVariables = {};

export type OnUpdatePosibleClientSubscription = {
	onUpdatePosibleClient?: {
		__typename: 'PosibleClient';
		id: string;
		phoneNumber?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnDeletePosibleClientSubscriptionVariables = {};

export type OnDeletePosibleClientSubscription = {
	onDeletePosibleClient?: {
		__typename: 'PosibleClient';
		id: string;
		phoneNumber?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnCreateClientImageSubscriptionVariables = {};

export type OnCreateClientImageSubscription = {
	onCreateClientImage?: {
		__typename: 'ClientImage';
		id: string;
		pathFile?: string | null;
		description?: string | null;
		ClientImageDateID?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnUpdateClientImageSubscriptionVariables = {};

export type OnUpdateClientImageSubscription = {
	onUpdateClientImage?: {
		__typename: 'ClientImage';
		id: string;
		pathFile?: string | null;
		description?: string | null;
		ClientImageDateID?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnDeleteClientImageSubscriptionVariables = {};

export type OnDeleteClientImageSubscription = {
	onDeleteClientImage?: {
		__typename: 'ClientImage';
		id: string;
		pathFile?: string | null;
		description?: string | null;
		ClientImageDateID?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnCreateConsultationDiagnosticSubscriptionVariables = {};

export type OnCreateConsultationDiagnosticSubscription = {
	onCreateConsultationDiagnostic?: {
		__typename: 'ConsultationDiagnostic';
		id: string;
		clientConsultationID: string;
		name?: string | null;
		code?: string | null;
		type?: DiagnosticTypes | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnUpdateConsultationDiagnosticSubscriptionVariables = {};

export type OnUpdateConsultationDiagnosticSubscription = {
	onUpdateConsultationDiagnostic?: {
		__typename: 'ConsultationDiagnostic';
		id: string;
		clientConsultationID: string;
		name?: string | null;
		code?: string | null;
		type?: DiagnosticTypes | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnDeleteConsultationDiagnosticSubscriptionVariables = {};

export type OnDeleteConsultationDiagnosticSubscription = {
	onDeleteConsultationDiagnostic?: {
		__typename: 'ConsultationDiagnostic';
		id: string;
		clientConsultationID: string;
		name?: string | null;
		code?: string | null;
		type?: DiagnosticTypes | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnCreateClientConsultationSubscriptionVariables = {};

export type OnCreateClientConsultationSubscription = {
	onCreateClientConsultation?: {
		__typename: 'ClientConsultation';
		id: string;
		clientHospitalDoctorID: string;
		doctorID?: string | null;
		visitReason?: string | null;
		visitDescription?: string | null;
		physicTestNote?: string | null;
		height?: string | null;
		weight?: string | null;
		bodyMass?: string | null;
		bloodPressure?: string | null;
		heartRate?: string | null;
		respiratoryRate?: string | null;
		bodyTemperature?: string | null;
		oxygenSaturation?: string | null;
		clientMedicinePrescriptionID?: string | null;
		clientAnalisisPrescriptionID?: string | null;
		clientFreePrescriptionID?: string | null;
		treatment?: string | null;
		procedureName?: string | null;
		procedureDescription?: string | null;
		createdAt?: string | null;
		updatedAt?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		doctor?: {
			__typename: 'Doctor';
			id: string;
			name?: string | null;
			specialty?: string | null;
			exequator?: string | null;
			email: string;
			firstTime?: boolean | null;
			studyPlace?: string | null;
			isBeenReview?: boolean | null;
			isFirstAddSecretary?: boolean | null;
			hasTrialOnCreation?: boolean | null;
			title?: string | null;
			phone?: string | null;
			code?: string | null;
			password?: string | null;
			isAdmin?: boolean | null;
			daysOfTrial?: number | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type OnUpdateClientConsultationSubscriptionVariables = {};

export type OnUpdateClientConsultationSubscription = {
	onUpdateClientConsultation?: {
		__typename: 'ClientConsultation';
		id: string;
		clientHospitalDoctorID: string;
		doctorID?: string | null;
		visitReason?: string | null;
		visitDescription?: string | null;
		physicTestNote?: string | null;
		height?: string | null;
		weight?: string | null;
		bodyMass?: string | null;
		bloodPressure?: string | null;
		heartRate?: string | null;
		respiratoryRate?: string | null;
		bodyTemperature?: string | null;
		oxygenSaturation?: string | null;
		clientMedicinePrescriptionID?: string | null;
		clientAnalisisPrescriptionID?: string | null;
		clientFreePrescriptionID?: string | null;
		treatment?: string | null;
		procedureName?: string | null;
		procedureDescription?: string | null;
		createdAt?: string | null;
		updatedAt?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		doctor?: {
			__typename: 'Doctor';
			id: string;
			name?: string | null;
			specialty?: string | null;
			exequator?: string | null;
			email: string;
			firstTime?: boolean | null;
			studyPlace?: string | null;
			isBeenReview?: boolean | null;
			isFirstAddSecretary?: boolean | null;
			hasTrialOnCreation?: boolean | null;
			title?: string | null;
			phone?: string | null;
			code?: string | null;
			password?: string | null;
			isAdmin?: boolean | null;
			daysOfTrial?: number | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type OnDeleteClientConsultationSubscriptionVariables = {};

export type OnDeleteClientConsultationSubscription = {
	onDeleteClientConsultation?: {
		__typename: 'ClientConsultation';
		id: string;
		clientHospitalDoctorID: string;
		doctorID?: string | null;
		visitReason?: string | null;
		visitDescription?: string | null;
		physicTestNote?: string | null;
		height?: string | null;
		weight?: string | null;
		bodyMass?: string | null;
		bloodPressure?: string | null;
		heartRate?: string | null;
		respiratoryRate?: string | null;
		bodyTemperature?: string | null;
		oxygenSaturation?: string | null;
		clientMedicinePrescriptionID?: string | null;
		clientAnalisisPrescriptionID?: string | null;
		clientFreePrescriptionID?: string | null;
		treatment?: string | null;
		procedureName?: string | null;
		procedureDescription?: string | null;
		createdAt?: string | null;
		updatedAt?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		doctor?: {
			__typename: 'Doctor';
			id: string;
			name?: string | null;
			specialty?: string | null;
			exequator?: string | null;
			email: string;
			firstTime?: boolean | null;
			studyPlace?: string | null;
			isBeenReview?: boolean | null;
			isFirstAddSecretary?: boolean | null;
			hasTrialOnCreation?: boolean | null;
			title?: string | null;
			phone?: string | null;
			code?: string | null;
			password?: string | null;
			isAdmin?: boolean | null;
			daysOfTrial?: number | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type OnCreateMedicineSubscriptionVariables = {};

export type OnCreateMedicineSubscription = {
	onCreateMedicine?: {
		__typename: 'Medicine';
		id: string;
		name: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnUpdateMedicineSubscriptionVariables = {};

export type OnUpdateMedicineSubscription = {
	onUpdateMedicine?: {
		__typename: 'Medicine';
		id: string;
		name: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnDeleteMedicineSubscriptionVariables = {};

export type OnDeleteMedicineSubscription = {
	onDeleteMedicine?: {
		__typename: 'Medicine';
		id: string;
		name: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnCreateClientAllergySubscriptionVariables = {};

export type OnCreateClientAllergySubscription = {
	onCreateClientAllergy?: {
		__typename: 'ClientAllergy';
		id: string;
		clientID: string;
		name: string;
		status?: AllergyStatus | null;
		note?: string | null;
		diagnosedDate?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnUpdateClientAllergySubscriptionVariables = {};

export type OnUpdateClientAllergySubscription = {
	onUpdateClientAllergy?: {
		__typename: 'ClientAllergy';
		id: string;
		clientID: string;
		name: string;
		status?: AllergyStatus | null;
		note?: string | null;
		diagnosedDate?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnDeleteClientAllergySubscriptionVariables = {};

export type OnDeleteClientAllergySubscription = {
	onDeleteClientAllergy?: {
		__typename: 'ClientAllergy';
		id: string;
		clientID: string;
		name: string;
		status?: AllergyStatus | null;
		note?: string | null;
		diagnosedDate?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnCreateClientDiseaseSubscriptionVariables = {};

export type OnCreateClientDiseaseSubscription = {
	onCreateClientDisease?: {
		__typename: 'ClientDisease';
		id: string;
		clientID: string;
		name: string;
		status?: AllergyStatus | null;
		note?: string | null;
		diagnosedDate?: string | null;
		updatedAt?: string | null;
		createdAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type OnUpdateClientDiseaseSubscriptionVariables = {};

export type OnUpdateClientDiseaseSubscription = {
	onUpdateClientDisease?: {
		__typename: 'ClientDisease';
		id: string;
		clientID: string;
		name: string;
		status?: AllergyStatus | null;
		note?: string | null;
		diagnosedDate?: string | null;
		updatedAt?: string | null;
		createdAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type OnDeleteClientDiseaseSubscriptionVariables = {};

export type OnDeleteClientDiseaseSubscription = {
	onDeleteClientDisease?: {
		__typename: 'ClientDisease';
		id: string;
		clientID: string;
		name: string;
		status?: AllergyStatus | null;
		note?: string | null;
		diagnosedDate?: string | null;
		updatedAt?: string | null;
		createdAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type OnCreateClientProcedureSubscriptionVariables = {};

export type OnCreateClientProcedureSubscription = {
	onCreateClientProcedure?: {
		__typename: 'ClientProcedure';
		id: string;
		clientID: string;
		name: string;
		note?: string | null;
		realizationDate?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnUpdateClientProcedureSubscriptionVariables = {};

export type OnUpdateClientProcedureSubscription = {
	onUpdateClientProcedure?: {
		__typename: 'ClientProcedure';
		id: string;
		clientID: string;
		name: string;
		note?: string | null;
		realizationDate?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnDeleteClientProcedureSubscriptionVariables = {};

export type OnDeleteClientProcedureSubscription = {
	onDeleteClientProcedure?: {
		__typename: 'ClientProcedure';
		id: string;
		clientID: string;
		name: string;
		note?: string | null;
		realizationDate?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnCreateClientHospitalizationSubscriptionVariables = {};

export type OnCreateClientHospitalizationSubscription = {
	onCreateClientHospitalization?: {
		__typename: 'ClientHospitalization';
		id: string;
		clientID: string;
		name: string;
		note?: string | null;
		date?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnUpdateClientHospitalizationSubscriptionVariables = {};

export type OnUpdateClientHospitalizationSubscription = {
	onUpdateClientHospitalization?: {
		__typename: 'ClientHospitalization';
		id: string;
		clientID: string;
		name: string;
		note?: string | null;
		date?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnDeleteClientHospitalizationSubscriptionVariables = {};

export type OnDeleteClientHospitalizationSubscription = {
	onDeleteClientHospitalization?: {
		__typename: 'ClientHospitalization';
		id: string;
		clientID: string;
		name: string;
		note?: string | null;
		date?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnCreateClientFamilyHistorySubscriptionVariables = {};

export type OnCreateClientFamilyHistorySubscription = {
	onCreateClientFamilyHistory?: {
		__typename: 'ClientFamilyHistory';
		id: string;
		clientID: string;
		familyMember: HistoryFamilyMember;
		mellitusDiabetes?: boolean | null;
		mellitusDiabetesNote?: string | null;
		arterialHypertension?: boolean | null;
		arterialHypertensionNote?: string | null;
		heartDisease?: boolean | null;
		heartDiseaseNote?: string | null;
		nephropathy?: boolean | null;
		nephropathyNote?: string | null;
		neuropathies?: boolean | null;
		neuropathiesNote?: string | null;
		epilepsy?: boolean | null;
		epilepsyNote?: string | null;
		rheumatoidArthritis?: boolean | null;
		rheumatoidArthritisNote?: string | null;
		psychiatricDiseases?: boolean | null;
		psychiatricDiseasesNote?: string | null;
		cancer?: boolean | null;
		cancerNote?: string | null;
		other?: boolean | null;
		otherNote?: string | null;
		updatedAt?: string | null;
		createdAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type OnUpdateClientFamilyHistorySubscriptionVariables = {};

export type OnUpdateClientFamilyHistorySubscription = {
	onUpdateClientFamilyHistory?: {
		__typename: 'ClientFamilyHistory';
		id: string;
		clientID: string;
		familyMember: HistoryFamilyMember;
		mellitusDiabetes?: boolean | null;
		mellitusDiabetesNote?: string | null;
		arterialHypertension?: boolean | null;
		arterialHypertensionNote?: string | null;
		heartDisease?: boolean | null;
		heartDiseaseNote?: string | null;
		nephropathy?: boolean | null;
		nephropathyNote?: string | null;
		neuropathies?: boolean | null;
		neuropathiesNote?: string | null;
		epilepsy?: boolean | null;
		epilepsyNote?: string | null;
		rheumatoidArthritis?: boolean | null;
		rheumatoidArthritisNote?: string | null;
		psychiatricDiseases?: boolean | null;
		psychiatricDiseasesNote?: string | null;
		cancer?: boolean | null;
		cancerNote?: string | null;
		other?: boolean | null;
		otherNote?: string | null;
		updatedAt?: string | null;
		createdAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type OnDeleteClientFamilyHistorySubscriptionVariables = {};

export type OnDeleteClientFamilyHistorySubscription = {
	onDeleteClientFamilyHistory?: {
		__typename: 'ClientFamilyHistory';
		id: string;
		clientID: string;
		familyMember: HistoryFamilyMember;
		mellitusDiabetes?: boolean | null;
		mellitusDiabetesNote?: string | null;
		arterialHypertension?: boolean | null;
		arterialHypertensionNote?: string | null;
		heartDisease?: boolean | null;
		heartDiseaseNote?: string | null;
		nephropathy?: boolean | null;
		nephropathyNote?: string | null;
		neuropathies?: boolean | null;
		neuropathiesNote?: string | null;
		epilepsy?: boolean | null;
		epilepsyNote?: string | null;
		rheumatoidArthritis?: boolean | null;
		rheumatoidArthritisNote?: string | null;
		psychiatricDiseases?: boolean | null;
		psychiatricDiseasesNote?: string | null;
		cancer?: boolean | null;
		cancerNote?: string | null;
		other?: boolean | null;
		otherNote?: string | null;
		updatedAt?: string | null;
		createdAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type OnCreateClientLifeStyleSubscriptionVariables = {};

export type OnCreateClientLifeStyleSubscription = {
	onCreateClientLifeStyle?: {
		__typename: 'ClientLifeStyle';
		id: string;
		clientID: string;
		lifeStyle: LifeStyle;
		name: string;
		description: string;
		updatedAt: string;
		createdAt: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type OnUpdateClientLifeStyleSubscriptionVariables = {};

export type OnUpdateClientLifeStyleSubscription = {
	onUpdateClientLifeStyle?: {
		__typename: 'ClientLifeStyle';
		id: string;
		clientID: string;
		lifeStyle: LifeStyle;
		name: string;
		description: string;
		updatedAt: string;
		createdAt: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type OnDeleteClientLifeStyleSubscriptionVariables = {};

export type OnDeleteClientLifeStyleSubscription = {
	onDeleteClientLifeStyle?: {
		__typename: 'ClientLifeStyle';
		id: string;
		clientID: string;
		lifeStyle: LifeStyle;
		name: string;
		description: string;
		updatedAt: string;
		createdAt: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type OnCreateClientPrescriptionSubscriptionVariables = {};

export type OnCreateClientPrescriptionSubscription = {
	onCreateClientPrescription?: {
		__typename: 'ClientPrescription';
		id: string;
		clientHospitalDoctorID: string;
		creationDate?: string | null;
		type: PrescriptionTypes;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnUpdateClientPrescriptionSubscriptionVariables = {};

export type OnUpdateClientPrescriptionSubscription = {
	onUpdateClientPrescription?: {
		__typename: 'ClientPrescription';
		id: string;
		clientHospitalDoctorID: string;
		creationDate?: string | null;
		type: PrescriptionTypes;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnDeleteClientPrescriptionSubscriptionVariables = {};

export type OnDeleteClientPrescriptionSubscription = {
	onDeleteClientPrescription?: {
		__typename: 'ClientPrescription';
		id: string;
		clientHospitalDoctorID: string;
		creationDate?: string | null;
		type: PrescriptionTypes;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnCreateClientPrescriptionMedicineSubscriptionVariables = {};

export type OnCreateClientPrescriptionMedicineSubscription = {
	onCreateClientPrescriptionMedicine?: {
		__typename: 'ClientPrescriptionMedicine';
		id: string;
		clientPrescriptionID: string;
		medicineID?: string | null;
		name?: string | null;
		take?: string | null;
		indication?: string | null;
		duration?: string | null;
		description?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnUpdateClientPrescriptionMedicineSubscriptionVariables = {};

export type OnUpdateClientPrescriptionMedicineSubscription = {
	onUpdateClientPrescriptionMedicine?: {
		__typename: 'ClientPrescriptionMedicine';
		id: string;
		clientPrescriptionID: string;
		medicineID?: string | null;
		name?: string | null;
		take?: string | null;
		indication?: string | null;
		duration?: string | null;
		description?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnDeleteClientPrescriptionMedicineSubscriptionVariables = {};

export type OnDeleteClientPrescriptionMedicineSubscription = {
	onDeleteClientPrescriptionMedicine?: {
		__typename: 'ClientPrescriptionMedicine';
		id: string;
		clientPrescriptionID: string;
		medicineID?: string | null;
		name?: string | null;
		take?: string | null;
		indication?: string | null;
		duration?: string | null;
		description?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnCreateClientFreePrescriptionSubscriptionVariables = {};

export type OnCreateClientFreePrescriptionSubscription = {
	onCreateClientFreePrescription?: {
		__typename: 'ClientFreePrescription';
		id: string;
		clientPrescriptionID: string;
		description?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnUpdateClientFreePrescriptionSubscriptionVariables = {};

export type OnUpdateClientFreePrescriptionSubscription = {
	onUpdateClientFreePrescription?: {
		__typename: 'ClientFreePrescription';
		id: string;
		clientPrescriptionID: string;
		description?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnDeleteClientFreePrescriptionSubscriptionVariables = {};

export type OnDeleteClientFreePrescriptionSubscription = {
	onDeleteClientFreePrescription?: {
		__typename: 'ClientFreePrescription';
		id: string;
		clientPrescriptionID: string;
		description?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnCreateClientPrescriptionAnalisisSubscriptionVariables = {};

export type OnCreateClientPrescriptionAnalisisSubscription = {
	onCreateClientPrescriptionAnalisis?: {
		__typename: 'ClientPrescriptionAnalisis';
		id: string;
		clientPrescriptionID: string;
		name?: string | null;
		description?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnUpdateClientPrescriptionAnalisisSubscriptionVariables = {};

export type OnUpdateClientPrescriptionAnalisisSubscription = {
	onUpdateClientPrescriptionAnalisis?: {
		__typename: 'ClientPrescriptionAnalisis';
		id: string;
		clientPrescriptionID: string;
		name?: string | null;
		description?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnDeleteClientPrescriptionAnalisisSubscriptionVariables = {};

export type OnDeleteClientPrescriptionAnalisisSubscription = {
	onDeleteClientPrescriptionAnalisis?: {
		__typename: 'ClientPrescriptionAnalisis';
		id: string;
		clientPrescriptionID: string;
		name?: string | null;
		description?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnCreateDoctorLabImageSubscriptionVariables = {};

export type OnCreateDoctorLabImageSubscription = {
	onCreateDoctorLabImage?: {
		__typename: 'DoctorLabImage';
		id: string;
		doctorID: string;
		labImageID: string;
		updatedAt?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		labImage?: {
			__typename: 'LabImage';
			id: string;
			name: string;
			description?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type OnUpdateDoctorLabImageSubscriptionVariables = {};

export type OnUpdateDoctorLabImageSubscription = {
	onUpdateDoctorLabImage?: {
		__typename: 'DoctorLabImage';
		id: string;
		doctorID: string;
		labImageID: string;
		updatedAt?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		labImage?: {
			__typename: 'LabImage';
			id: string;
			name: string;
			description?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type OnDeleteDoctorLabImageSubscriptionVariables = {};

export type OnDeleteDoctorLabImageSubscription = {
	onDeleteDoctorLabImage?: {
		__typename: 'DoctorLabImage';
		id: string;
		doctorID: string;
		labImageID: string;
		updatedAt?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		labImage?: {
			__typename: 'LabImage';
			id: string;
			name: string;
			description?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type OnCreateLabImageSubscriptionVariables = {};

export type OnCreateLabImageSubscription = {
	onCreateLabImage?: {
		__typename: 'LabImage';
		id: string;
		name: string;
		description?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnUpdateLabImageSubscriptionVariables = {};

export type OnUpdateLabImageSubscription = {
	onUpdateLabImage?: {
		__typename: 'LabImage';
		id: string;
		name: string;
		description?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnDeleteLabImageSubscriptionVariables = {};

export type OnDeleteLabImageSubscription = {
	onDeleteLabImage?: {
		__typename: 'LabImage';
		id: string;
		name: string;
		description?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnCreatePharmacyMedicineSubscriptionVariables = {};

export type OnCreatePharmacyMedicineSubscription = {
	onCreatePharmacyMedicine?: {
		__typename: 'PharmacyMedicine';
		id: string;
		name?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnUpdatePharmacyMedicineSubscriptionVariables = {};

export type OnUpdatePharmacyMedicineSubscription = {
	onUpdatePharmacyMedicine?: {
		__typename: 'PharmacyMedicine';
		id: string;
		name?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnDeletePharmacyMedicineSubscriptionVariables = {};

export type OnDeletePharmacyMedicineSubscription = {
	onDeletePharmacyMedicine?: {
		__typename: 'PharmacyMedicine';
		id: string;
		name?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnCreatePharmacyMedicineMeasureSubscriptionVariables = {};

export type OnCreatePharmacyMedicineMeasureSubscription = {
	onCreatePharmacyMedicineMeasure?: {
		__typename: 'PharmacyMedicineMeasure';
		id: string;
		pharmacyMedicineID: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnUpdatePharmacyMedicineMeasureSubscriptionVariables = {};

export type OnUpdatePharmacyMedicineMeasureSubscription = {
	onUpdatePharmacyMedicineMeasure?: {
		__typename: 'PharmacyMedicineMeasure';
		id: string;
		pharmacyMedicineID: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnDeletePharmacyMedicineMeasureSubscriptionVariables = {};

export type OnDeletePharmacyMedicineMeasureSubscription = {
	onDeletePharmacyMedicineMeasure?: {
		__typename: 'PharmacyMedicineMeasure';
		id: string;
		pharmacyMedicineID: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnCreateTemplateSubscriptionVariables = {};

export type OnCreateTemplateSubscription = {
	onCreateTemplate?: {
		__typename: 'Template';
		id: string;
		doctorID: string;
		templateName?: string | null;
		templateType: PrescriptionTypes;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnUpdateTemplateSubscriptionVariables = {};

export type OnUpdateTemplateSubscription = {
	onUpdateTemplate?: {
		__typename: 'Template';
		id: string;
		doctorID: string;
		templateName?: string | null;
		templateType: PrescriptionTypes;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnDeleteTemplateSubscriptionVariables = {};

export type OnDeleteTemplateSubscription = {
	onDeleteTemplate?: {
		__typename: 'Template';
		id: string;
		doctorID: string;
		templateName?: string | null;
		templateType: PrescriptionTypes;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnCreateTemplateMedicineSubscriptionVariables = {};

export type OnCreateTemplateMedicineSubscription = {
	onCreateTemplateMedicine?: {
		__typename: 'TemplateMedicine';
		id: string;
		templateID: string;
		medicineID: string;
		take?: string | null;
		indication?: string | null;
		duration?: string | null;
		description?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnUpdateTemplateMedicineSubscriptionVariables = {};

export type OnUpdateTemplateMedicineSubscription = {
	onUpdateTemplateMedicine?: {
		__typename: 'TemplateMedicine';
		id: string;
		templateID: string;
		medicineID: string;
		take?: string | null;
		indication?: string | null;
		duration?: string | null;
		description?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnDeleteTemplateMedicineSubscriptionVariables = {};

export type OnDeleteTemplateMedicineSubscription = {
	onDeleteTemplateMedicine?: {
		__typename: 'TemplateMedicine';
		id: string;
		templateID: string;
		medicineID: string;
		take?: string | null;
		indication?: string | null;
		duration?: string | null;
		description?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnCreateDoctorRecentDiagnosisSubscriptionVariables = {};

export type OnCreateDoctorRecentDiagnosisSubscription = {
	onCreateDoctorRecentDiagnosis?: {
		__typename: 'DoctorRecentDiagnosis';
		id: string;
		doctorID: string;
		name: string;
		code?: string | null;
		type?: DiagnosticTypes | null;
		updateDateTime?: string | null;
		createdAt?: string | null;
		updatedAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type OnUpdateDoctorRecentDiagnosisSubscriptionVariables = {};

export type OnUpdateDoctorRecentDiagnosisSubscription = {
	onUpdateDoctorRecentDiagnosis?: {
		__typename: 'DoctorRecentDiagnosis';
		id: string;
		doctorID: string;
		name: string;
		code?: string | null;
		type?: DiagnosticTypes | null;
		updateDateTime?: string | null;
		createdAt?: string | null;
		updatedAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type OnDeleteDoctorRecentDiagnosisSubscriptionVariables = {};

export type OnDeleteDoctorRecentDiagnosisSubscription = {
	onDeleteDoctorRecentDiagnosis?: {
		__typename: 'DoctorRecentDiagnosis';
		id: string;
		doctorID: string;
		name: string;
		code?: string | null;
		type?: DiagnosticTypes | null;
		updateDateTime?: string | null;
		createdAt?: string | null;
		updatedAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type OnCreateTemplateLabImageSubscriptionVariables = {};

export type OnCreateTemplateLabImageSubscription = {
	onCreateTemplateLabImage?: {
		__typename: 'TemplateLabImage';
		id: string;
		templateID: string;
		name?: string | null;
		description?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnUpdateTemplateLabImageSubscriptionVariables = {};

export type OnUpdateTemplateLabImageSubscription = {
	onUpdateTemplateLabImage?: {
		__typename: 'TemplateLabImage';
		id: string;
		templateID: string;
		name?: string | null;
		description?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnDeleteTemplateLabImageSubscriptionVariables = {};

export type OnDeleteTemplateLabImageSubscription = {
	onDeleteTemplateLabImage?: {
		__typename: 'TemplateLabImage';
		id: string;
		templateID: string;
		name?: string | null;
		description?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnCreateDoctorMedicineSubscriptionVariables = {};

export type OnCreateDoctorMedicineSubscription = {
	onCreateDoctorMedicine?: {
		__typename: 'DoctorMedicine';
		id: string;
		doctorID: string;
		medicineID: string;
		isDeleted?: boolean | null;
		updatedAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		medicine?: {
			__typename: 'Medicine';
			id: string;
			name: string;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type OnUpdateDoctorMedicineSubscriptionVariables = {};

export type OnUpdateDoctorMedicineSubscription = {
	onUpdateDoctorMedicine?: {
		__typename: 'DoctorMedicine';
		id: string;
		doctorID: string;
		medicineID: string;
		isDeleted?: boolean | null;
		updatedAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		medicine?: {
			__typename: 'Medicine';
			id: string;
			name: string;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type OnDeleteDoctorMedicineSubscriptionVariables = {};

export type OnDeleteDoctorMedicineSubscription = {
	onDeleteDoctorMedicine?: {
		__typename: 'DoctorMedicine';
		id: string;
		doctorID: string;
		medicineID: string;
		isDeleted?: boolean | null;
		updatedAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		medicine?: {
			__typename: 'Medicine';
			id: string;
			name: string;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type OnCreateRecentDoctorMedicinePrescriptionSubscriptionVariables = {};

export type OnCreateRecentDoctorMedicinePrescriptionSubscription = {
	onCreateRecentDoctorMedicinePrescription?: {
		__typename: 'RecentDoctorMedicinePrescription';
		id: string;
		doctorID: string;
		medicineID: string;
		take?: string | null;
		indication?: string | null;
		duration?: string | null;
		description?: string | null;
		updateDateTime?: string | null;
		createdAt?: string | null;
		updatedAt?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		medicine?: {
			__typename: 'Medicine';
			id: string;
			name: string;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type OnUpdateRecentDoctorMedicinePrescriptionSubscriptionVariables = {};

export type OnUpdateRecentDoctorMedicinePrescriptionSubscription = {
	onUpdateRecentDoctorMedicinePrescription?: {
		__typename: 'RecentDoctorMedicinePrescription';
		id: string;
		doctorID: string;
		medicineID: string;
		take?: string | null;
		indication?: string | null;
		duration?: string | null;
		description?: string | null;
		updateDateTime?: string | null;
		createdAt?: string | null;
		updatedAt?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		medicine?: {
			__typename: 'Medicine';
			id: string;
			name: string;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type OnDeleteRecentDoctorMedicinePrescriptionSubscriptionVariables = {};

export type OnDeleteRecentDoctorMedicinePrescriptionSubscription = {
	onDeleteRecentDoctorMedicinePrescription?: {
		__typename: 'RecentDoctorMedicinePrescription';
		id: string;
		doctorID: string;
		medicineID: string;
		take?: string | null;
		indication?: string | null;
		duration?: string | null;
		description?: string | null;
		updateDateTime?: string | null;
		createdAt?: string | null;
		updatedAt?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		medicine?: {
			__typename: 'Medicine';
			id: string;
			name: string;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type OnCreateRecentDoctorLabImagePrescriptionSubscriptionVariables = {};

export type OnCreateRecentDoctorLabImagePrescriptionSubscription = {
	onCreateRecentDoctorLabImagePrescription?: {
		__typename: 'RecentDoctorLabImagePrescription';
		id: string;
		doctorID: string;
		labImageID?: string | null;
		name?: string | null;
		description?: string | null;
		updateDateTime?: string | null;
		createdAt?: string | null;
		updatedAt?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type OnUpdateRecentDoctorLabImagePrescriptionSubscriptionVariables = {};

export type OnUpdateRecentDoctorLabImagePrescriptionSubscription = {
	onUpdateRecentDoctorLabImagePrescription?: {
		__typename: 'RecentDoctorLabImagePrescription';
		id: string;
		doctorID: string;
		labImageID?: string | null;
		name?: string | null;
		description?: string | null;
		updateDateTime?: string | null;
		createdAt?: string | null;
		updatedAt?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type OnDeleteRecentDoctorLabImagePrescriptionSubscriptionVariables = {};

export type OnDeleteRecentDoctorLabImagePrescriptionSubscription = {
	onDeleteRecentDoctorLabImagePrescription?: {
		__typename: 'RecentDoctorLabImagePrescription';
		id: string;
		doctorID: string;
		labImageID?: string | null;
		name?: string | null;
		description?: string | null;
		updateDateTime?: string | null;
		createdAt?: string | null;
		updatedAt?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
	} | null;
};

export type OnCreateTemplateAnalisisSubscriptionVariables = {};

export type OnCreateTemplateAnalisisSubscription = {
	onCreateTemplateAnalisis?: {
		__typename: 'TemplateAnalisis';
		id: string;
		templateID: string;
		analisisID: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnUpdateTemplateAnalisisSubscriptionVariables = {};

export type OnUpdateTemplateAnalisisSubscription = {
	onUpdateTemplateAnalisis?: {
		__typename: 'TemplateAnalisis';
		id: string;
		templateID: string;
		analisisID: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnDeleteTemplateAnalisisSubscriptionVariables = {};

export type OnDeleteTemplateAnalisisSubscription = {
	onDeleteTemplateAnalisis?: {
		__typename: 'TemplateAnalisis';
		id: string;
		templateID: string;
		analisisID: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnCreateAnalisisSubscriptionVariables = {};

export type OnCreateAnalisisSubscription = {
	onCreateAnalisis?: {
		__typename: 'Analisis';
		id: string;
		analisisName?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnUpdateAnalisisSubscriptionVariables = {};

export type OnUpdateAnalisisSubscription = {
	onUpdateAnalisis?: {
		__typename: 'Analisis';
		id: string;
		analisisName?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnDeleteAnalisisSubscriptionVariables = {};

export type OnDeleteAnalisisSubscription = {
	onDeleteAnalisis?: {
		__typename: 'Analisis';
		id: string;
		analisisName?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnCreateClientActiveDiseaseSubscriptionVariables = {};

export type OnCreateClientActiveDiseaseSubscription = {
	onCreateClientActiveDisease?: {
		__typename: 'ClientActiveDisease';
		id: string;
		clientID: string;
		name: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnUpdateClientActiveDiseaseSubscriptionVariables = {};

export type OnUpdateClientActiveDiseaseSubscription = {
	onUpdateClientActiveDisease?: {
		__typename: 'ClientActiveDisease';
		id: string;
		clientID: string;
		name: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnDeleteClientActiveDiseaseSubscriptionVariables = {};

export type OnDeleteClientActiveDiseaseSubscription = {
	onDeleteClientActiveDisease?: {
		__typename: 'ClientActiveDisease';
		id: string;
		clientID: string;
		name: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnCreateClientHistorySubscriptionVariables = {};

export type OnCreateClientHistorySubscription = {
	onCreateClientHistory?: {
		__typename: 'ClientHistory';
		id: string;
		clientHospitalDoctorID: string;
		title?: string | null;
		treatment?: string | null;
		endModifyDate?: string | null;
		authorizationNumber?: string | null;
		description?: string | null;
		authorizationDetail?: string | null;
		personalPathologicalHistory?: string | null;
		familyPathologicalHistory?: string | null;
		physicalTest?: string | null;
		createdDate?: string | null;
		insuranceID?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnUpdateClientHistorySubscriptionVariables = {};

export type OnUpdateClientHistorySubscription = {
	onUpdateClientHistory?: {
		__typename: 'ClientHistory';
		id: string;
		clientHospitalDoctorID: string;
		title?: string | null;
		treatment?: string | null;
		endModifyDate?: string | null;
		authorizationNumber?: string | null;
		description?: string | null;
		authorizationDetail?: string | null;
		personalPathologicalHistory?: string | null;
		familyPathologicalHistory?: string | null;
		physicalTest?: string | null;
		createdDate?: string | null;
		insuranceID?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnDeleteClientHistorySubscriptionVariables = {};

export type OnDeleteClientHistorySubscription = {
	onDeleteClientHistory?: {
		__typename: 'ClientHistory';
		id: string;
		clientHospitalDoctorID: string;
		title?: string | null;
		treatment?: string | null;
		endModifyDate?: string | null;
		authorizationNumber?: string | null;
		description?: string | null;
		authorizationDetail?: string | null;
		personalPathologicalHistory?: string | null;
		familyPathologicalHistory?: string | null;
		physicalTest?: string | null;
		createdDate?: string | null;
		insuranceID?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnCreateClientNotesSubscriptionVariables = {};

export type OnCreateClientNotesSubscription = {
	onCreateClientNotes?: {
		__typename: 'ClientNotes';
		id: string;
		clientHospitalDoctorID: string;
		title?: string | null;
		noteBody?: string | null;
		endModifyDate?: string | null;
		createdDate?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnUpdateClientNotesSubscriptionVariables = {};

export type OnUpdateClientNotesSubscription = {
	onUpdateClientNotes?: {
		__typename: 'ClientNotes';
		id: string;
		clientHospitalDoctorID: string;
		title?: string | null;
		noteBody?: string | null;
		endModifyDate?: string | null;
		createdDate?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnDeleteClientNotesSubscriptionVariables = {};

export type OnDeleteClientNotesSubscription = {
	onDeleteClientNotes?: {
		__typename: 'ClientNotes';
		id: string;
		clientHospitalDoctorID: string;
		title?: string | null;
		noteBody?: string | null;
		endModifyDate?: string | null;
		createdDate?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnCreateClientProcedureSurgerySubscriptionVariables = {};

export type OnCreateClientProcedureSurgerySubscription = {
	onCreateClientProcedureSurgery?: {
		__typename: 'ClientProcedureSurgery';
		id: string;
		clientHospitalDoctorID: string;
		title?: string | null;
		description?: string | null;
		endModifyDate?: string | null;
		createdDate?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnUpdateClientProcedureSurgerySubscriptionVariables = {};

export type OnUpdateClientProcedureSurgerySubscription = {
	onUpdateClientProcedureSurgery?: {
		__typename: 'ClientProcedureSurgery';
		id: string;
		clientHospitalDoctorID: string;
		title?: string | null;
		description?: string | null;
		endModifyDate?: string | null;
		createdDate?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnDeleteClientProcedureSurgerySubscriptionVariables = {};

export type OnDeleteClientProcedureSurgerySubscription = {
	onDeleteClientProcedureSurgery?: {
		__typename: 'ClientProcedureSurgery';
		id: string;
		clientHospitalDoctorID: string;
		title?: string | null;
		description?: string | null;
		endModifyDate?: string | null;
		createdDate?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnCreateDoctorSubscriptionVariables = {};

export type OnCreateDoctorSubscription = {
	onCreateDoctor?: {
		__typename: 'Doctor';
		id: string;
		name?: string | null;
		specialty?: string | null;
		exequator?: string | null;
		email: string;
		firstTime?: boolean | null;
		studyPlace?: string | null;
		isBeenReview?: boolean | null;
		isFirstAddSecretary?: boolean | null;
		hasTrialOnCreation?: boolean | null;
		title?: string | null;
		phone?: string | null;
		code?: string | null;
		password?: string | null;
		isAdmin?: boolean | null;
		daysOfTrial?: number | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnUpdateDoctorSubscriptionVariables = {};

export type OnUpdateDoctorSubscription = {
	onUpdateDoctor?: {
		__typename: 'Doctor';
		id: string;
		name?: string | null;
		specialty?: string | null;
		exequator?: string | null;
		email: string;
		firstTime?: boolean | null;
		studyPlace?: string | null;
		isBeenReview?: boolean | null;
		isFirstAddSecretary?: boolean | null;
		hasTrialOnCreation?: boolean | null;
		title?: string | null;
		phone?: string | null;
		code?: string | null;
		password?: string | null;
		isAdmin?: boolean | null;
		daysOfTrial?: number | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnDeleteDoctorSubscriptionVariables = {};

export type OnDeleteDoctorSubscription = {
	onDeleteDoctor?: {
		__typename: 'Doctor';
		id: string;
		name?: string | null;
		specialty?: string | null;
		exequator?: string | null;
		email: string;
		firstTime?: boolean | null;
		studyPlace?: string | null;
		isBeenReview?: boolean | null;
		isFirstAddSecretary?: boolean | null;
		hasTrialOnCreation?: boolean | null;
		title?: string | null;
		phone?: string | null;
		code?: string | null;
		password?: string | null;
		isAdmin?: boolean | null;
		daysOfTrial?: number | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnCreatePlanSubscriptionVariables = {};

export type OnCreatePlanSubscription = {
	onCreatePlan?: {
		__typename: 'Plan';
		id: string;
		refID?: number | null;
		title?: string | null;
		subTitle?: string | null;
		stripePlanKey?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnUpdatePlanSubscriptionVariables = {};

export type OnUpdatePlanSubscription = {
	onUpdatePlan?: {
		__typename: 'Plan';
		id: string;
		refID?: number | null;
		title?: string | null;
		subTitle?: string | null;
		stripePlanKey?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnDeletePlanSubscriptionVariables = {};

export type OnDeletePlanSubscription = {
	onDeletePlan?: {
		__typename: 'Plan';
		id: string;
		refID?: number | null;
		title?: string | null;
		subTitle?: string | null;
		stripePlanKey?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnCreateDoctorStripeCustomerSubscriptionSubscriptionVariables = {};

export type OnCreateDoctorStripeCustomerSubscriptionSubscription = {
	onCreateDoctorStripeCustomerSubscription?: {
		__typename: 'DoctorStripeCustomerSubscription';
		id: string;
		doctorID: string;
		currentPlanID?: number | null;
		stripeCustomerID?: string | null;
		stripeSubscriptionID?: string | null;
		defaultCard?: string | null;
		nextPaymentDate?: string | null;
		cancelAtPeriodEnd?: boolean | null;
		trialsEndsAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnUpdateDoctorStripeCustomerSubscriptionSubscriptionVariables = {};

export type OnUpdateDoctorStripeCustomerSubscriptionSubscription = {
	onUpdateDoctorStripeCustomerSubscription?: {
		__typename: 'DoctorStripeCustomerSubscription';
		id: string;
		doctorID: string;
		currentPlanID?: number | null;
		stripeCustomerID?: string | null;
		stripeSubscriptionID?: string | null;
		defaultCard?: string | null;
		nextPaymentDate?: string | null;
		cancelAtPeriodEnd?: boolean | null;
		trialsEndsAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnDeleteDoctorStripeCustomerSubscriptionSubscriptionVariables = {};

export type OnDeleteDoctorStripeCustomerSubscriptionSubscription = {
	onDeleteDoctorStripeCustomerSubscription?: {
		__typename: 'DoctorStripeCustomerSubscription';
		id: string;
		doctorID: string;
		currentPlanID?: number | null;
		stripeCustomerID?: string | null;
		stripeSubscriptionID?: string | null;
		defaultCard?: string | null;
		nextPaymentDate?: string | null;
		cancelAtPeriodEnd?: boolean | null;
		trialsEndsAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnCreateDoctorStripeCardsSubscriptionVariables = {};

export type OnCreateDoctorStripeCardsSubscription = {
	onCreateDoctorStripeCards?: {
		__typename: 'DoctorStripeCards';
		id: string;
		DoctorID: string;
		cardLastDigits?: string | null;
		stripePaymentMethodID?: string | null;
		defaultCard?: boolean | null;
		cardBrand?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnUpdateDoctorStripeCardsSubscriptionVariables = {};

export type OnUpdateDoctorStripeCardsSubscription = {
	onUpdateDoctorStripeCards?: {
		__typename: 'DoctorStripeCards';
		id: string;
		DoctorID: string;
		cardLastDigits?: string | null;
		stripePaymentMethodID?: string | null;
		defaultCard?: boolean | null;
		cardBrand?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnDeleteDoctorStripeCardsSubscriptionVariables = {};

export type OnDeleteDoctorStripeCardsSubscription = {
	onDeleteDoctorStripeCards?: {
		__typename: 'DoctorStripeCards';
		id: string;
		DoctorID: string;
		cardLastDigits?: string | null;
		stripePaymentMethodID?: string | null;
		defaultCard?: boolean | null;
		cardBrand?: string | null;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnCreateHospitalSubscriptionVariables = {};

export type OnCreateHospitalSubscription = {
	onCreateHospital?: {
		__typename: 'Hospital';
		id: string;
		name: string;
		type?: string | null;
		province?: string | null;
		town?: string | null;
		sector?: string | null;
		phone?: string | null;
		address?: string | null;
		pssCode?: string | null;
		rnc?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnUpdateHospitalSubscriptionVariables = {};

export type OnUpdateHospitalSubscription = {
	onUpdateHospital?: {
		__typename: 'Hospital';
		id: string;
		name: string;
		type?: string | null;
		province?: string | null;
		town?: string | null;
		sector?: string | null;
		phone?: string | null;
		address?: string | null;
		pssCode?: string | null;
		rnc?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnDeleteHospitalSubscriptionVariables = {};

export type OnDeleteHospitalSubscription = {
	onDeleteHospital?: {
		__typename: 'Hospital';
		id: string;
		name: string;
		type?: string | null;
		province?: string | null;
		town?: string | null;
		sector?: string | null;
		phone?: string | null;
		address?: string | null;
		pssCode?: string | null;
		rnc?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnCreateHospitalDoctorSubscriptionVariables = {};

export type OnCreateHospitalDoctorSubscription = {
	onCreateHospitalDoctor?: {
		__typename: 'HospitalDoctor';
		id: string;
		doctorID: string;
		hospitalID: string;
		lastWaitingListID: string;
		hospitalLogo?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		doctor?: {
			__typename: 'Doctor';
			id: string;
			name?: string | null;
			specialty?: string | null;
			exequator?: string | null;
			email: string;
			firstTime?: boolean | null;
			studyPlace?: string | null;
			isBeenReview?: boolean | null;
			isFirstAddSecretary?: boolean | null;
			hasTrialOnCreation?: boolean | null;
			title?: string | null;
			phone?: string | null;
			code?: string | null;
			password?: string | null;
			isAdmin?: boolean | null;
			daysOfTrial?: number | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
		hospital?: {
			__typename: 'Hospital';
			id: string;
			name: string;
			type?: string | null;
			province?: string | null;
			town?: string | null;
			sector?: string | null;
			phone?: string | null;
			address?: string | null;
			pssCode?: string | null;
			rnc?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type OnUpdateHospitalDoctorSubscriptionVariables = {};

export type OnUpdateHospitalDoctorSubscription = {
	onUpdateHospitalDoctor?: {
		__typename: 'HospitalDoctor';
		id: string;
		doctorID: string;
		hospitalID: string;
		lastWaitingListID: string;
		hospitalLogo?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		doctor?: {
			__typename: 'Doctor';
			id: string;
			name?: string | null;
			specialty?: string | null;
			exequator?: string | null;
			email: string;
			firstTime?: boolean | null;
			studyPlace?: string | null;
			isBeenReview?: boolean | null;
			isFirstAddSecretary?: boolean | null;
			hasTrialOnCreation?: boolean | null;
			title?: string | null;
			phone?: string | null;
			code?: string | null;
			password?: string | null;
			isAdmin?: boolean | null;
			daysOfTrial?: number | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
		hospital?: {
			__typename: 'Hospital';
			id: string;
			name: string;
			type?: string | null;
			province?: string | null;
			town?: string | null;
			sector?: string | null;
			phone?: string | null;
			address?: string | null;
			pssCode?: string | null;
			rnc?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type OnDeleteHospitalDoctorSubscriptionVariables = {};

export type OnDeleteHospitalDoctorSubscription = {
	onDeleteHospitalDoctor?: {
		__typename: 'HospitalDoctor';
		id: string;
		doctorID: string;
		hospitalID: string;
		lastWaitingListID: string;
		hospitalLogo?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		doctor?: {
			__typename: 'Doctor';
			id: string;
			name?: string | null;
			specialty?: string | null;
			exequator?: string | null;
			email: string;
			firstTime?: boolean | null;
			studyPlace?: string | null;
			isBeenReview?: boolean | null;
			isFirstAddSecretary?: boolean | null;
			hasTrialOnCreation?: boolean | null;
			title?: string | null;
			phone?: string | null;
			code?: string | null;
			password?: string | null;
			isAdmin?: boolean | null;
			daysOfTrial?: number | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
		hospital?: {
			__typename: 'Hospital';
			id: string;
			name: string;
			type?: string | null;
			province?: string | null;
			town?: string | null;
			sector?: string | null;
			phone?: string | null;
			address?: string | null;
			pssCode?: string | null;
			rnc?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type OnCreateTeamsSubscriptionVariables = {};

export type OnCreateTeamsSubscription = {
	onCreateTeams?: {
		__typename: 'Teams';
		id: string;
		OwnerID: string;
		MemberID: string;
		status?: TeamStatus | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		Owner?: {
			__typename: 'HospitalDoctor';
			id: string;
			doctorID: string;
			hospitalID: string;
			lastWaitingListID: string;
			hospitalLogo?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospital?: {
				__typename: 'Hospital';
				id: string;
				name: string;
				type?: string | null;
				province?: string | null;
				town?: string | null;
				sector?: string | null;
				phone?: string | null;
				address?: string | null;
				pssCode?: string | null;
				rnc?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
		Member?: {
			__typename: 'HospitalDoctor';
			id: string;
			doctorID: string;
			hospitalID: string;
			lastWaitingListID: string;
			hospitalLogo?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospital?: {
				__typename: 'Hospital';
				id: string;
				name: string;
				type?: string | null;
				province?: string | null;
				town?: string | null;
				sector?: string | null;
				phone?: string | null;
				address?: string | null;
				pssCode?: string | null;
				rnc?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
	} | null;
};

export type OnUpdateTeamsSubscriptionVariables = {};

export type OnUpdateTeamsSubscription = {
	onUpdateTeams?: {
		__typename: 'Teams';
		id: string;
		OwnerID: string;
		MemberID: string;
		status?: TeamStatus | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		Owner?: {
			__typename: 'HospitalDoctor';
			id: string;
			doctorID: string;
			hospitalID: string;
			lastWaitingListID: string;
			hospitalLogo?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospital?: {
				__typename: 'Hospital';
				id: string;
				name: string;
				type?: string | null;
				province?: string | null;
				town?: string | null;
				sector?: string | null;
				phone?: string | null;
				address?: string | null;
				pssCode?: string | null;
				rnc?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
		Member?: {
			__typename: 'HospitalDoctor';
			id: string;
			doctorID: string;
			hospitalID: string;
			lastWaitingListID: string;
			hospitalLogo?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospital?: {
				__typename: 'Hospital';
				id: string;
				name: string;
				type?: string | null;
				province?: string | null;
				town?: string | null;
				sector?: string | null;
				phone?: string | null;
				address?: string | null;
				pssCode?: string | null;
				rnc?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
	} | null;
};

export type OnDeleteTeamsSubscriptionVariables = {};

export type OnDeleteTeamsSubscription = {
	onDeleteTeams?: {
		__typename: 'Teams';
		id: string;
		OwnerID: string;
		MemberID: string;
		status?: TeamStatus | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		Owner?: {
			__typename: 'HospitalDoctor';
			id: string;
			doctorID: string;
			hospitalID: string;
			lastWaitingListID: string;
			hospitalLogo?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospital?: {
				__typename: 'Hospital';
				id: string;
				name: string;
				type?: string | null;
				province?: string | null;
				town?: string | null;
				sector?: string | null;
				phone?: string | null;
				address?: string | null;
				pssCode?: string | null;
				rnc?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
		Member?: {
			__typename: 'HospitalDoctor';
			id: string;
			doctorID: string;
			hospitalID: string;
			lastWaitingListID: string;
			hospitalLogo?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospital?: {
				__typename: 'Hospital';
				id: string;
				name: string;
				type?: string | null;
				province?: string | null;
				town?: string | null;
				sector?: string | null;
				phone?: string | null;
				address?: string | null;
				pssCode?: string | null;
				rnc?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
	} | null;
};

export type OnCreateHospitalDoctorClienteSubscriptionVariables = {};

export type OnCreateHospitalDoctorClienteSubscription = {
	onCreateHospitalDoctorCliente?: {
		__typename: 'HospitalDoctorCliente';
		id: string;
		clientID: string;
		hospitalDoctorID: string;
		lastHealthInsurranceID?: string | null;
		clientStatus?: string | null;
		note?: string | null;
		recordNo?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		client?: {
			__typename: 'Client';
			id: string;
			identificationName?: IdentificationTypes | null;
			identificationData?: string | null;
			actualInsurance?: string | null;
			name: string;
			lastName: string;
			cellphoneNumber?: string | null;
			email?: string | null;
			bornDate?: string | null;
			parentOrTutorCedula?: string | null;
			parentOrTutorID?: string | null;
			gender?: SexType | null;
			phoneNumber?: string | null;
			addressStreet?: string | null;
			city?: string | null;
			isAdult?: boolean | null;
			sector?: string | null;
			bloodType?: string | null;
			company?: string | null;
			vitalSignsHeight?: string | null;
			vitalSignsWeight?: string | null;
			bodyMass?: string | null;
			vitalSignsBloodPressure?: string | null;
			heartRate?: string | null;
			respiratoryRate?: string | null;
			bodyTemperature?: string | null;
			oxygenSaturation?: string | null;
			occupation?: string | null;
			profileImage?: string | null;
			heartBeat?: string | null;
			weight?: string | null;
			height?: string | null;
			bloodPressure?: string | null;
			updateDateTime?: string | null;
			createdAt: string;
			updatedAt: string;
			code?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
		} | null;
		hospitalDoctor?: {
			__typename: 'HospitalDoctor';
			id: string;
			doctorID: string;
			hospitalID: string;
			lastWaitingListID: string;
			hospitalLogo?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospital?: {
				__typename: 'Hospital';
				id: string;
				name: string;
				type?: string | null;
				province?: string | null;
				town?: string | null;
				sector?: string | null;
				phone?: string | null;
				address?: string | null;
				pssCode?: string | null;
				rnc?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
		clientInsurance?: {
			__typename: 'ClientInsurance';
			id: string;
			insuranceID?: string | null;
			clientID?: string | null;
			contractNumber?: string | null;
			affiliateNumber?: string | null;
			affiliateType?: AffiliateTypes | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			insurance?: {
				__typename: 'Insurance';
				id: string;
				name: string;
				allowClaim?: boolean | null;
				code?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
	} | null;
};

export type OnUpdateHospitalDoctorClienteSubscriptionVariables = {};

export type OnUpdateHospitalDoctorClienteSubscription = {
	onUpdateHospitalDoctorCliente?: {
		__typename: 'HospitalDoctorCliente';
		id: string;
		clientID: string;
		hospitalDoctorID: string;
		lastHealthInsurranceID?: string | null;
		clientStatus?: string | null;
		note?: string | null;
		recordNo?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		client?: {
			__typename: 'Client';
			id: string;
			identificationName?: IdentificationTypes | null;
			identificationData?: string | null;
			actualInsurance?: string | null;
			name: string;
			lastName: string;
			cellphoneNumber?: string | null;
			email?: string | null;
			bornDate?: string | null;
			parentOrTutorCedula?: string | null;
			parentOrTutorID?: string | null;
			gender?: SexType | null;
			phoneNumber?: string | null;
			addressStreet?: string | null;
			city?: string | null;
			isAdult?: boolean | null;
			sector?: string | null;
			bloodType?: string | null;
			company?: string | null;
			vitalSignsHeight?: string | null;
			vitalSignsWeight?: string | null;
			bodyMass?: string | null;
			vitalSignsBloodPressure?: string | null;
			heartRate?: string | null;
			respiratoryRate?: string | null;
			bodyTemperature?: string | null;
			oxygenSaturation?: string | null;
			occupation?: string | null;
			profileImage?: string | null;
			heartBeat?: string | null;
			weight?: string | null;
			height?: string | null;
			bloodPressure?: string | null;
			updateDateTime?: string | null;
			createdAt: string;
			updatedAt: string;
			code?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
		} | null;
		hospitalDoctor?: {
			__typename: 'HospitalDoctor';
			id: string;
			doctorID: string;
			hospitalID: string;
			lastWaitingListID: string;
			hospitalLogo?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospital?: {
				__typename: 'Hospital';
				id: string;
				name: string;
				type?: string | null;
				province?: string | null;
				town?: string | null;
				sector?: string | null;
				phone?: string | null;
				address?: string | null;
				pssCode?: string | null;
				rnc?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
		clientInsurance?: {
			__typename: 'ClientInsurance';
			id: string;
			insuranceID?: string | null;
			clientID?: string | null;
			contractNumber?: string | null;
			affiliateNumber?: string | null;
			affiliateType?: AffiliateTypes | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			insurance?: {
				__typename: 'Insurance';
				id: string;
				name: string;
				allowClaim?: boolean | null;
				code?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
	} | null;
};

export type OnDeleteHospitalDoctorClienteSubscriptionVariables = {};

export type OnDeleteHospitalDoctorClienteSubscription = {
	onDeleteHospitalDoctorCliente?: {
		__typename: 'HospitalDoctorCliente';
		id: string;
		clientID: string;
		hospitalDoctorID: string;
		lastHealthInsurranceID?: string | null;
		clientStatus?: string | null;
		note?: string | null;
		recordNo?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		client?: {
			__typename: 'Client';
			id: string;
			identificationName?: IdentificationTypes | null;
			identificationData?: string | null;
			actualInsurance?: string | null;
			name: string;
			lastName: string;
			cellphoneNumber?: string | null;
			email?: string | null;
			bornDate?: string | null;
			parentOrTutorCedula?: string | null;
			parentOrTutorID?: string | null;
			gender?: SexType | null;
			phoneNumber?: string | null;
			addressStreet?: string | null;
			city?: string | null;
			isAdult?: boolean | null;
			sector?: string | null;
			bloodType?: string | null;
			company?: string | null;
			vitalSignsHeight?: string | null;
			vitalSignsWeight?: string | null;
			bodyMass?: string | null;
			vitalSignsBloodPressure?: string | null;
			heartRate?: string | null;
			respiratoryRate?: string | null;
			bodyTemperature?: string | null;
			oxygenSaturation?: string | null;
			occupation?: string | null;
			profileImage?: string | null;
			heartBeat?: string | null;
			weight?: string | null;
			height?: string | null;
			bloodPressure?: string | null;
			updateDateTime?: string | null;
			createdAt: string;
			updatedAt: string;
			code?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
		} | null;
		hospitalDoctor?: {
			__typename: 'HospitalDoctor';
			id: string;
			doctorID: string;
			hospitalID: string;
			lastWaitingListID: string;
			hospitalLogo?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			doctor?: {
				__typename: 'Doctor';
				id: string;
				name?: string | null;
				specialty?: string | null;
				exequator?: string | null;
				email: string;
				firstTime?: boolean | null;
				studyPlace?: string | null;
				isBeenReview?: boolean | null;
				isFirstAddSecretary?: boolean | null;
				hasTrialOnCreation?: boolean | null;
				title?: string | null;
				phone?: string | null;
				code?: string | null;
				password?: string | null;
				isAdmin?: boolean | null;
				daysOfTrial?: number | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
			hospital?: {
				__typename: 'Hospital';
				id: string;
				name: string;
				type?: string | null;
				province?: string | null;
				town?: string | null;
				sector?: string | null;
				phone?: string | null;
				address?: string | null;
				pssCode?: string | null;
				rnc?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
		clientInsurance?: {
			__typename: 'ClientInsurance';
			id: string;
			insuranceID?: string | null;
			clientID?: string | null;
			contractNumber?: string | null;
			affiliateNumber?: string | null;
			affiliateType?: AffiliateTypes | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
			insurance?: {
				__typename: 'Insurance';
				id: string;
				name: string;
				allowClaim?: boolean | null;
				code?: string | null;
				_version: number;
				_deleted?: boolean | null;
				_lastChangedAt: number;
				createdAt: string;
				updatedAt: string;
			} | null;
		} | null;
	} | null;
};

export type OnCreateWaitListSubscriptionVariables = {};

export type OnCreateWaitListSubscription = {
	onCreateWaitList?: {
		__typename: 'WaitList';
		id: string;
		hospitalDoctorID: string;
		createdAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		updatedAt: string;
	} | null;
};

export type OnUpdateWaitListSubscriptionVariables = {};

export type OnUpdateWaitListSubscription = {
	onUpdateWaitList?: {
		__typename: 'WaitList';
		id: string;
		hospitalDoctorID: string;
		createdAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		updatedAt: string;
	} | null;
};

export type OnDeleteWaitListSubscriptionVariables = {};

export type OnDeleteWaitListSubscription = {
	onDeleteWaitList?: {
		__typename: 'WaitList';
		id: string;
		hospitalDoctorID: string;
		createdAt?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		updatedAt: string;
	} | null;
};

export type OnCreateWaitingListItemSubscriptionVariables = {};

export type OnCreateWaitingListItemSubscription = {
	onCreateWaitingListItem?: {
		__typename: 'WaitingListItem';
		id: string;
		waitingListID: string;
		clientID: string;
		status: WaitingListItemStatus;
		clientHealthInsurrance?: string | null;
		positionNumber: number;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnUpdateWaitingListItemSubscriptionVariables = {};

export type OnUpdateWaitingListItemSubscription = {
	onUpdateWaitingListItem?: {
		__typename: 'WaitingListItem';
		id: string;
		waitingListID: string;
		clientID: string;
		status: WaitingListItemStatus;
		clientHealthInsurrance?: string | null;
		positionNumber: number;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnDeleteWaitingListItemSubscriptionVariables = {};

export type OnDeleteWaitingListItemSubscription = {
	onDeleteWaitingListItem?: {
		__typename: 'WaitingListItem';
		id: string;
		waitingListID: string;
		clientID: string;
		status: WaitingListItemStatus;
		clientHealthInsurrance?: string | null;
		positionNumber: number;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnCreateClientInsuranceSubscriptionVariables = {};

export type OnCreateClientInsuranceSubscription = {
	onCreateClientInsurance?: {
		__typename: 'ClientInsurance';
		id: string;
		insuranceID?: string | null;
		clientID?: string | null;
		contractNumber?: string | null;
		affiliateNumber?: string | null;
		affiliateType?: AffiliateTypes | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		insurance?: {
			__typename: 'Insurance';
			id: string;
			name: string;
			allowClaim?: boolean | null;
			code?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type OnUpdateClientInsuranceSubscriptionVariables = {};

export type OnUpdateClientInsuranceSubscription = {
	onUpdateClientInsurance?: {
		__typename: 'ClientInsurance';
		id: string;
		insuranceID?: string | null;
		clientID?: string | null;
		contractNumber?: string | null;
		affiliateNumber?: string | null;
		affiliateType?: AffiliateTypes | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		insurance?: {
			__typename: 'Insurance';
			id: string;
			name: string;
			allowClaim?: boolean | null;
			code?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type OnDeleteClientInsuranceSubscriptionVariables = {};

export type OnDeleteClientInsuranceSubscription = {
	onDeleteClientInsurance?: {
		__typename: 'ClientInsurance';
		id: string;
		insuranceID?: string | null;
		clientID?: string | null;
		contractNumber?: string | null;
		affiliateNumber?: string | null;
		affiliateType?: AffiliateTypes | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		insurance?: {
			__typename: 'Insurance';
			id: string;
			name: string;
			allowClaim?: boolean | null;
			code?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type OnCreateInsuranceSubscriptionVariables = {};

export type OnCreateInsuranceSubscription = {
	onCreateInsurance?: {
		__typename: 'Insurance';
		id: string;
		name: string;
		allowClaim?: boolean | null;
		code?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnUpdateInsuranceSubscriptionVariables = {};

export type OnUpdateInsuranceSubscription = {
	onUpdateInsurance?: {
		__typename: 'Insurance';
		id: string;
		name: string;
		allowClaim?: boolean | null;
		code?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnDeleteInsuranceSubscriptionVariables = {};

export type OnDeleteInsuranceSubscription = {
	onDeleteInsurance?: {
		__typename: 'Insurance';
		id: string;
		name: string;
		allowClaim?: boolean | null;
		code?: string | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnCreateClientClaimSubscriptionVariables = {};

export type OnCreateClientClaimSubscription = {
	onCreateClientClaim?: {
		__typename: 'ClientClaim';
		id: string;
		clientHospitalDoctorID: string;
		healthInsuranceID: string;
		diagnostic: string;
		authorization: string;
		date: string;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		healthInsurance?: {
			__typename: 'Insurance';
			id: string;
			name: string;
			allowClaim?: boolean | null;
			code?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type OnUpdateClientClaimSubscriptionVariables = {};

export type OnUpdateClientClaimSubscription = {
	onUpdateClientClaim?: {
		__typename: 'ClientClaim';
		id: string;
		clientHospitalDoctorID: string;
		healthInsuranceID: string;
		diagnostic: string;
		authorization: string;
		date: string;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		healthInsurance?: {
			__typename: 'Insurance';
			id: string;
			name: string;
			allowClaim?: boolean | null;
			code?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type OnDeleteClientClaimSubscriptionVariables = {};

export type OnDeleteClientClaimSubscription = {
	onDeleteClientClaim?: {
		__typename: 'ClientClaim';
		id: string;
		clientHospitalDoctorID: string;
		healthInsuranceID: string;
		diagnostic: string;
		authorization: string;
		date: string;
		status?: Status | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
		healthInsurance?: {
			__typename: 'Insurance';
			id: string;
			name: string;
			allowClaim?: boolean | null;
			code?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type OnCreateClaimProcessSubscriptionVariables = {};

export type OnCreateClaimProcessSubscription = {
	onCreateClaimProcess?: {
		__typename: 'ClaimProcess';
		id: string;
		claimID: string;
		name: string;
		code: string;
		claimed: string;
		difference: string;
		authorized: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnUpdateClaimProcessSubscriptionVariables = {};

export type OnUpdateClaimProcessSubscription = {
	onUpdateClaimProcess?: {
		__typename: 'ClaimProcess';
		id: string;
		claimID: string;
		name: string;
		code: string;
		claimed: string;
		difference: string;
		authorized: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnDeleteClaimProcessSubscriptionVariables = {};

export type OnDeleteClaimProcessSubscription = {
	onDeleteClaimProcess?: {
		__typename: 'ClaimProcess';
		id: string;
		claimID: string;
		name: string;
		code: string;
		claimed: string;
		difference: string;
		authorized: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnCreateFormsSubscriptionVariables = {};

export type OnCreateFormsSubscription = {
	onCreateForms?: {
		__typename: 'Forms';
		id: string;
		DoctorID?: string | null;
		public?: boolean | null;
		name?: string | null;
		formImage?: string | null;
		description?: string | null;
		elements?: Array<string | null> | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnUpdateFormsSubscriptionVariables = {};

export type OnUpdateFormsSubscription = {
	onUpdateForms?: {
		__typename: 'Forms';
		id: string;
		DoctorID?: string | null;
		public?: boolean | null;
		name?: string | null;
		formImage?: string | null;
		description?: string | null;
		elements?: Array<string | null> | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnDeleteFormsSubscriptionVariables = {};

export type OnDeleteFormsSubscription = {
	onDeleteForms?: {
		__typename: 'Forms';
		id: string;
		DoctorID?: string | null;
		public?: boolean | null;
		name?: string | null;
		formImage?: string | null;
		description?: string | null;
		elements?: Array<string | null> | null;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		createdAt: string;
		updatedAt: string;
	} | null;
};

export type OnCreateAppointmentSubscriptionVariables = {};

export type OnCreateAppointmentSubscription = {
	onCreateAppointment?: {
		__typename: 'Appointment';
		id: string;
		clientID: string;
		doctorID: string;
		hospitalID: string;
		description: string;
		date: string;
		start: string;
		end: string;
		status?: Status | null;
		shouldSendReminder?: boolean | null;
		createdAt: string;
		updatedAt: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		client?: {
			__typename: 'Client';
			id: string;
			identificationName?: IdentificationTypes | null;
			identificationData?: string | null;
			actualInsurance?: string | null;
			name: string;
			lastName: string;
			cellphoneNumber?: string | null;
			email?: string | null;
			bornDate?: string | null;
			parentOrTutorCedula?: string | null;
			parentOrTutorID?: string | null;
			gender?: SexType | null;
			phoneNumber?: string | null;
			addressStreet?: string | null;
			city?: string | null;
			isAdult?: boolean | null;
			sector?: string | null;
			bloodType?: string | null;
			company?: string | null;
			vitalSignsHeight?: string | null;
			vitalSignsWeight?: string | null;
			bodyMass?: string | null;
			vitalSignsBloodPressure?: string | null;
			heartRate?: string | null;
			respiratoryRate?: string | null;
			bodyTemperature?: string | null;
			oxygenSaturation?: string | null;
			occupation?: string | null;
			profileImage?: string | null;
			heartBeat?: string | null;
			weight?: string | null;
			height?: string | null;
			bloodPressure?: string | null;
			updateDateTime?: string | null;
			createdAt: string;
			updatedAt: string;
			code?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
		} | null;
		doctor?: {
			__typename: 'Doctor';
			id: string;
			name?: string | null;
			specialty?: string | null;
			exequator?: string | null;
			email: string;
			firstTime?: boolean | null;
			studyPlace?: string | null;
			isBeenReview?: boolean | null;
			isFirstAddSecretary?: boolean | null;
			hasTrialOnCreation?: boolean | null;
			title?: string | null;
			phone?: string | null;
			code?: string | null;
			password?: string | null;
			isAdmin?: boolean | null;
			daysOfTrial?: number | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
		hospital?: {
			__typename: 'Hospital';
			id: string;
			name: string;
			type?: string | null;
			province?: string | null;
			town?: string | null;
			sector?: string | null;
			phone?: string | null;
			address?: string | null;
			pssCode?: string | null;
			rnc?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type OnUpdateAppointmentSubscriptionVariables = {};

export type OnUpdateAppointmentSubscription = {
	onUpdateAppointment?: {
		__typename: 'Appointment';
		id: string;
		clientID: string;
		doctorID: string;
		hospitalID: string;
		description: string;
		date: string;
		start: string;
		end: string;
		status?: Status | null;
		shouldSendReminder?: boolean | null;
		createdAt: string;
		updatedAt: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		client?: {
			__typename: 'Client';
			id: string;
			identificationName?: IdentificationTypes | null;
			identificationData?: string | null;
			actualInsurance?: string | null;
			name: string;
			lastName: string;
			cellphoneNumber?: string | null;
			email?: string | null;
			bornDate?: string | null;
			parentOrTutorCedula?: string | null;
			parentOrTutorID?: string | null;
			gender?: SexType | null;
			phoneNumber?: string | null;
			addressStreet?: string | null;
			city?: string | null;
			isAdult?: boolean | null;
			sector?: string | null;
			bloodType?: string | null;
			company?: string | null;
			vitalSignsHeight?: string | null;
			vitalSignsWeight?: string | null;
			bodyMass?: string | null;
			vitalSignsBloodPressure?: string | null;
			heartRate?: string | null;
			respiratoryRate?: string | null;
			bodyTemperature?: string | null;
			oxygenSaturation?: string | null;
			occupation?: string | null;
			profileImage?: string | null;
			heartBeat?: string | null;
			weight?: string | null;
			height?: string | null;
			bloodPressure?: string | null;
			updateDateTime?: string | null;
			createdAt: string;
			updatedAt: string;
			code?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
		} | null;
		doctor?: {
			__typename: 'Doctor';
			id: string;
			name?: string | null;
			specialty?: string | null;
			exequator?: string | null;
			email: string;
			firstTime?: boolean | null;
			studyPlace?: string | null;
			isBeenReview?: boolean | null;
			isFirstAddSecretary?: boolean | null;
			hasTrialOnCreation?: boolean | null;
			title?: string | null;
			phone?: string | null;
			code?: string | null;
			password?: string | null;
			isAdmin?: boolean | null;
			daysOfTrial?: number | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
		hospital?: {
			__typename: 'Hospital';
			id: string;
			name: string;
			type?: string | null;
			province?: string | null;
			town?: string | null;
			sector?: string | null;
			phone?: string | null;
			address?: string | null;
			pssCode?: string | null;
			rnc?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};

export type OnDeleteAppointmentSubscriptionVariables = {};

export type OnDeleteAppointmentSubscription = {
	onDeleteAppointment?: {
		__typename: 'Appointment';
		id: string;
		clientID: string;
		doctorID: string;
		hospitalID: string;
		description: string;
		date: string;
		start: string;
		end: string;
		status?: Status | null;
		shouldSendReminder?: boolean | null;
		createdAt: string;
		updatedAt: string;
		_version: number;
		_deleted?: boolean | null;
		_lastChangedAt: number;
		client?: {
			__typename: 'Client';
			id: string;
			identificationName?: IdentificationTypes | null;
			identificationData?: string | null;
			actualInsurance?: string | null;
			name: string;
			lastName: string;
			cellphoneNumber?: string | null;
			email?: string | null;
			bornDate?: string | null;
			parentOrTutorCedula?: string | null;
			parentOrTutorID?: string | null;
			gender?: SexType | null;
			phoneNumber?: string | null;
			addressStreet?: string | null;
			city?: string | null;
			isAdult?: boolean | null;
			sector?: string | null;
			bloodType?: string | null;
			company?: string | null;
			vitalSignsHeight?: string | null;
			vitalSignsWeight?: string | null;
			bodyMass?: string | null;
			vitalSignsBloodPressure?: string | null;
			heartRate?: string | null;
			respiratoryRate?: string | null;
			bodyTemperature?: string | null;
			oxygenSaturation?: string | null;
			occupation?: string | null;
			profileImage?: string | null;
			heartBeat?: string | null;
			weight?: string | null;
			height?: string | null;
			bloodPressure?: string | null;
			updateDateTime?: string | null;
			createdAt: string;
			updatedAt: string;
			code?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
		} | null;
		doctor?: {
			__typename: 'Doctor';
			id: string;
			name?: string | null;
			specialty?: string | null;
			exequator?: string | null;
			email: string;
			firstTime?: boolean | null;
			studyPlace?: string | null;
			isBeenReview?: boolean | null;
			isFirstAddSecretary?: boolean | null;
			hasTrialOnCreation?: boolean | null;
			title?: string | null;
			phone?: string | null;
			code?: string | null;
			password?: string | null;
			isAdmin?: boolean | null;
			daysOfTrial?: number | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
		hospital?: {
			__typename: 'Hospital';
			id: string;
			name: string;
			type?: string | null;
			province?: string | null;
			town?: string | null;
			sector?: string | null;
			phone?: string | null;
			address?: string | null;
			pssCode?: string | null;
			rnc?: string | null;
			_version: number;
			_deleted?: boolean | null;
			_lastChangedAt: number;
			createdAt: string;
			updatedAt: string;
		} | null;
	} | null;
};
