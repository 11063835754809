import NotFound from 'pages/NotFound';
import React, { ReactNode, ErrorInfo } from 'react';

interface ErrorBoundaryProps {
	children: ReactNode;
}

interface ErrorBoundaryState {
	hasError: boolean;
	error: Error | null;
	errorInfo: ErrorInfo | null;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
	constructor(props: ErrorBoundaryProps) {
		super(props);
		this.state = {
			hasError: false,
			error: null,
			errorInfo: null,
		};
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
		this.setState({
			hasError: true,
			error,
			errorInfo,
		});
	}

	render(): ReactNode {
		const { hasError } = this.state;
		const { children } = this.props;

		if (hasError) {
			return <NotFound />;
		}

		return children;
	}
}

export default ErrorBoundary;
