import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { StyledPictureDialog, StyledToolbar } from '../../../shared/style';
import { Slide as SlideContainer } from 'react-slideshow-image';
import { ClientImageType } from '../../../context/types';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

interface Props {
	previewState: boolean;
	setPreviewState: (newState: boolean) => void;
	images: ClientImageType[];
	imgIndex: number;
}
const useStyles = makeStyles(() =>
	createStyles({
		appBar: {
			position: 'relative',
		},
	})
);

const PreviewIMG: React.FC<Props> = ({ images, setPreviewState, previewState, imgIndex }) => {
	const classes = useStyles();
	const handleClose = () => {
		setPreviewState(false);
	};
	const buttonStyle = {
		width: '40.3px',
		height: '39.5px',
		background: 'none',
		border: '0px',
		backgroundColor: '#424242',
	};
	const properties = {
		prevArrow: (
			<button style={{ ...buttonStyle }}>
				<NavigateBeforeIcon fontSize="large" style={{ color: 'white' }} />
			</button>
		),
		nextArrow: (
			<button style={{ ...buttonStyle }}>
				<NavigateNextIcon fontSize="large" style={{ color: 'white' }} />
			</button>
		),
	};

	return (
		<>
			{previewState && (
				<StyledPictureDialog fullScreen open={previewState} onClose={handleClose}>
					<AppBar className={classes.appBar}>
						<StyledToolbar>
							<Button
								color="primary"
								startIcon={<ArrowBackIcon />}
								onClick={handleClose}
							>
								Atrás
							</Button>
						</StyledToolbar>
					</AppBar>
					<div className="slide-container my-5">
						<SlideContainer
							autoplay={false}
							transitionDuration={100}
							defaultIndex={imgIndex}
							{...properties}
						>
							{images.map((image, index) => (
								<div className="each-slide" key={index}>
									<div
										style={{
											backgroundImage: `url(${URL.createObjectURL(
												image.url
											)})`,
											backgroundRepeat: 'no-repeat',
											backgroundPosition: 'center',
											backgroundSize: 'contain',
											height: '87vh',
											width: '100vw',
										}}
									></div>
								</div>
							))}
						</SlideContainer>
					</div>
				</StyledPictureDialog>
			)}
		</>
	);
};

export default PreviewIMG;
