// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import CustomTabs from '../../components/customTabs/CustomTabs';
import CheckIcon from '@material-ui/icons/Check';
import ClientHeader from '../../components/clients/shared/ClientHeader';
import CreateUpdateConsultation from './CreateUpdate/CreateUpdateConsultation';
import PreviousConsultation from './Previous/PreviousConsultation';
import PatientHistory from '../../components/clients/history/PatientHistory';
import { Button } from '@material-ui/core';

import { useHistory } from 'react-router-dom';
import { CancelButton } from './styles';
import { DateErrorMessage, displayAge, getDateAndTime } from '../../shared/dateUtils';
import { ClientsContext } from '../../context/client-context';
import {
	ClientContextProps,
	ConsultationDiagnosticType,
	ConsultationType,
	MedicineType,
	RelationsContextProps,
	SuggestionType,
} from '../../context/types';
import { StyledHeaderChip } from '../../shared/style';
import Dialog from '../../components/dialog/CustomDialog';
import {
	LabImages,
	Medicine,
	MedicineOption,
	MedicineConsultation,
	SelectedDiagnosis,
	SidebarOptions,
	Templates,
	ConsultationTab,
	FreePrescription,
} from '../../shared/type';
import { sidebar } from '../../shared/constants';
import { DiagnosticTypes, LabImage, LabImage as LabImageType } from 'models';
import moment from 'moment';
import useQuery from '../../shared/hooks/useQuery';
import { RelationsContext } from '../../context/relations-context';
import { clearSuggestions, filterSuggestions } from 'shared/medicinesAndLabImagesUtils';
import { FieldValues } from 'react-hook-form/dist/types';
import { useForm } from 'react-hook-form';
import { VitalSigns } from './types';
import useMobile from 'hooks/useMobile';

const PatientConsultation = () => {
	const history = useHistory();

	const {
		medicines: medicineList,
		labImages: labImagesList,
		actualDoctor,
		createDoctorMedicine,
		createMedicine,
		createLabImage,
		createDoctorLabImage,
		fetchMedicines,
		fetchLabImages,
	} = useContext(RelationsContext) as RelationsContextProps;
	const {
		createClientConsultation,
		updateClientConsultation,
		selectedClient,
		getConsultationDataById,
		clientConsultations: prescriptions,
	} = useContext(ClientsContext) as ClientContextProps;
	const yearsOld =
		selectedClient?.bornDate &&
		selectedClient?.bornDate !== DateErrorMessage &&
		displayAge(moment(selectedClient.bornDate));
	const [isUpdate, setIsUpdate] = useState(false);
	const [actualTab, setActualtab] = useState<number>(ConsultationTab.createUpdate);
	const [openConfirmation, setOpenConfirmation] = useState(false);
	const [confirmedNavigation, setConfirmedNavigation] = useState(false);
	const [lastLocation, setLastLocation] = useState<string | null>(null);
	// Description
	const [reason, setReason] = useState('');
	const [isReasonEmpty, setIsReasonEmpty] = useState(false);
	const [description, setDescription] = useState('');
	// PhysicalTest
	const [physicalDescription, setPhysicalDescription] = useState('');
	// Diagnosis
	const [selectedDiagnosis, setSelectedDiagnosis] = useState<SelectedDiagnosis[]>(
		[] as SelectedDiagnosis[]
	);
	const [selectedDiffDiagnosis, setSelectedDiffDiagnosis] = useState<SelectedDiagnosis[]>(
		[] as SelectedDiagnosis[]
	);

	const { isMobile } = useMobile();
	const [diagnosisInputValue, setDiagnosisInputValue] = useState<string>('');
	const [diffDiagnosisInputValue, setDiffDiagnosisInputValue] = useState<string>('');

	const [selectedDiagnosisValue, setSelectedDiagnosisValue] = useState<string | null>('');
	const [selectedDiffDiagnosisValue, setSelectedDiffDiagnosisValue] = useState<string | null>('');
	// Treatment
	const [actualSidebarItem, setActualSidebarItem] = useState<string>(sidebar[0].id);
	const [treatmentDescription, setTreatmentDescription] = useState('');
	const [procedure, setProcedure] = useState('');
	const [procedureDescription, setProcedureDescription] = useState('');
	// Treatment prescription
	const [headerMenu, setHeaderMenu] = useState<null | HTMLElement>(null);
	const [medicines, setMedicines] = useState<Medicine[]>([] as Medicine[]);
	const [freePrescription, setFreePrescription] = useState<FreePrescription[]>(
		[] as FreePrescription[]
	);
	const [labImages, setLabImages] = useState<LabImages[]>([] as LabImages[]);
	const [isMedicine, setIsMedicine] = useState<boolean>(true);
	const [sidebarMedicines, setSideBarMedicines] = useState<SidebarOptions[]>(
		[] as SidebarOptions[]
	);
	const [searchValue, setSearchValue] = useState<SidebarOptions>({} as SidebarOptions);
	const [searchMedicines, setSearchMedicines] = useState<SidebarOptions[]>(
		[] as SidebarOptions[]
	);
	const [searchLabImages, setSearchLabImages] = useState<SidebarOptions[]>(
		[] as SidebarOptions[]
	);
	const [selectedMedicine, setSelectedMedicine] = useState<MedicineType>({} as MedicineType);
	const [medicine, setMedicine] = useState<MedicineOption>({} as MedicineOption);
	const [sidebarLabs, setSideLabs] = useState<SidebarOptions[]>([] as SidebarOptions[]);
	const [medicalTemplates, setMedicalTemplates] = useState<Templates[]>([] as Templates[]);
	const [openCreateTemplate, setOpenCreateTemplate] = useState<boolean>(false);
	const [templateName, setTemplateName] = useState<string>('');
	const [labImagesTemplates, setLabImagesTemplates] = useState<Templates[]>([] as Templates[]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	// Create medicine states
	const [openMedicineModal, setOpenMedicineModal] = useState(false);
	const [newMedicine, setNewMedicine] = useState<MedicineConsultation>(
		{} as MedicineConsultation
	);
	const [isDisabledCreateMed, setIsDisabledCreateMed] = useState<boolean>(true);
	// Create labImage states
	const [openLabImageModal, setOpenLabImageModal] = useState(false);
	const [isDisabledCreateLab, setIsDisabledCreateLab] = useState<boolean>(true);
	const [newLabImage, setNewLabImage] = useState<LabImageType>({} as LabImageType);
	const [medicinesSuggestions, setMedicinesSuggestions] = useState<MedicineType[]>(
		[] as MedicineType[]
	);
	const [labImagesSuggestions, setLabImagesSuggestions] = useState<LabImageType[]>(
		[] as LabImageType[]
	);
	const [freePrescriptionSuggestions, setFreePrescriptionSuggestions] = useState<
		FreePrescription[]
	>([] as FreePrescription[]);
	const [vitalsSigns, setVitalsSigns] = useState<VitalSigns | null>(null);

	const path = history.location.pathname.replace('/client-consultation', '');
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
		setValue,
		setError,
	} = useForm();
	const query = useQuery();

	useEffect(() => {
		setIsLoading(true);
		const loadConsultationData = async () => {
			if (query.get('consultationID') !== null) {
				setIsUpdate(true);
				const consultationID = query.get('consultationID');
				if (prescriptions && prescriptions.length) {
					const prevConsultation = await getConsultationDataById(
						consultationID as string
					);

					const actualVitalsSigns: VitalSigns = {
						bloodPressure: prevConsultation?.bloodPressure as string,
						heartRate: prevConsultation?.heartRate as string,
						respiratoryRate: prevConsultation?.respiratoryRate as string,
						bodyTemperature: prevConsultation?.bodyTemperature as string,
						weight: prevConsultation?.weight as string,
						height: prevConsultation?.height as string,
						oxygenSaturation: prevConsultation?.oxygenSaturation as string,
						bodyMass: prevConsultation?.bodyMass as string,
					};
					setVitalsSigns(actualVitalsSigns);
					setReason(
						prevConsultation?.visitReason
							? (prevConsultation?.visitReason as string)
							: ''
					);
					setDescription(
						prevConsultation?.visitDescription
							? (prevConsultation?.visitDescription as string)
							: ''
					);

					setPhysicalDescription(
						prevConsultation?.physicTestNote
							? (prevConsultation?.physicTestNote as string)
							: ''
					);

					setSelectedDiagnosis(
						prevConsultation?.diagnostics
							? (prevConsultation?.diagnostics
									.filter(
										(diagnosis: any) =>
											diagnosis.type === DiagnosticTypes.PRESUMPTIVE
									)
									.map((diagnosis: any) => ({
										id: diagnosis.id,
										label: diagnosis.name,
										code: diagnosis.code || '',
									})) as SelectedDiagnosis[])
							: []
					);

					let diffDiagnosis: SelectedDiagnosis[] = [];

					if (prevConsultation?.diffDiagnostics) {
						diffDiagnosis = prevConsultation?.diffDiagnostics.map((diagnosis: any) => ({
							id: diagnosis.id,
							label: diagnosis.name,
							code: diagnosis.code || '',
						})) as SelectedDiagnosis[];
					} else {
						diffDiagnosis = prevConsultation?.diagnostics
							? (prevConsultation?.diagnostics
									.filter(
										(diagnosis: any) =>
											diagnosis.type === DiagnosticTypes.DIFFERENTIAL
									)
									.map((diagnosis: any) => ({
										id: diagnosis.id,
										label: diagnosis.name,
										code: diagnosis.code || '',
									})) as SelectedDiagnosis[])
							: [];
					}

					setSelectedDiffDiagnosis(diffDiagnosis);

					setMedicines(
						prevConsultation?.prescription && prevConsultation?.prescription.medicines
							? (prevConsultation.prescription.medicines.map((medicine: any) => ({
									...medicine,
									description: medicine.description ? medicine.description : '',
									label: medicine.name as string,
									showDescription: Boolean(medicine.description),
							  })) as Medicine[])
							: ([] as Medicine[])
					);

					setFreePrescription(prevConsultation.prescription.freePrescription);

					setLabImages(
						prevConsultation?.prescription && prevConsultation?.prescription.analisis
							? prevConsultation.prescription.analisis.map((analisis: any) => ({
									...analisis,
									label: analisis.name as string,
									showDescription: Boolean(analisis.description),
							  }))
							: []
					);
					setTreatmentDescription(
						prevConsultation?.treatment ? prevConsultation?.treatment : ''
					);
					setProcedure(
						prevConsultation?.procedureName ? prevConsultation?.procedureName : ''
					);
					setProcedureDescription(
						prevConsultation?.procedureDescription
							? prevConsultation?.procedureDescription
							: ''
					);
				}
			}
		};

		loadConsultationData();
		setIsLoading(false);
	}, []);

	const handleReason = (value: string) => {
		setReason(value);
		if (value.trim() === '') {
			setIsReasonEmpty(true);
		} else {
			setIsReasonEmpty(false);
		}
	};

	const handleChangename = (value: string) => {
		if (value === undefined) return;

		setNewMedicine((prev) => ({ ...prev, medicineName: value }));

		if (!!value) {
			const isDisabledCreateMedicine =
				medicineList.filter(
					(m) =>
						m.name?.toLowerCase() === value.toLowerCase() && newMedicine.name !== m.name
				)?.length === 0;

			setIsDisabledCreateMed(!isDisabledCreateMedicine);
		}
	};

	useEffect(() => {
		const { medicineName } = newMedicine;

		const timerId = filterSuggestions(
			SuggestionType.MEDICINES,
			medicineList,
			medicineName,
			setMedicinesSuggestions
		);

		return () => {
			clearSuggestions(timerId, setMedicinesSuggestions);
		};
	}, [newMedicine.medicineName]);

	const handleAddNewMedicine = () => {
		setOpenMedicineModal(true);
		setNewMedicine({ medicineName: '' } as MedicineConsultation);
	};

	const handleClickMedicine = async (value: MedicineType) => {
		const isDoctorMedicineValid = !actualDoctor?.medicines
			.map((m) => m.name?.toLocaleLowerCase())
			.includes(value.name?.toLocaleLowerCase());
		const newValue = {
			...value,
			label: value.name as string,
			medicineID: value.id,
			openUnit: null,
		};

		if (isDoctorMedicineValid) {
			const medicine = await createDoctorMedicine(value.id, false);
			if (medicine) {
				setMedicines((prev) => [
					...prev,
					{
						...newValue,
						id: prev.length + 1,
					},
				]);
			}
			setOpenMedicineModal(false);
		} else {
			setMedicines((prev) => [
				...prev,
				{
					...newValue,
					id: prev.length + 1,
				},
			]);
			setOpenMedicineModal(false);
		}

		setMedicinesSuggestions([]);
	};

	const clearForm = () => {
		fetchMedicines();
		setOpenMedicineModal(false);
		setIsDisabledCreateMed(true);
	};

	const handleCreateMedicine = async (isFreePrescription: boolean) => {
		const value = await createMedicine(newMedicine.medicineName);
		await createDoctorMedicine(value.id, true);

		clearForm();
		const newMedicineValue = {
			medicineID: value.id,
			label: newMedicine.medicineName,
			openUnit: null,
			openSideUnit: null,
			take: '',
			indication: '',
			description: '',
			duration: '',
			showDescription: false,
		};
		if (!isFreePrescription) {
			setMedicines((prev) => [...prev, { ...newMedicineValue, id: prev.length + 1 }]);
		}
	};

	const deleteMedicineChip = (idx: number | string) => {
		setNewMedicine((prev) => {
			return { ...prev };
		});
	};

	const handleMedicineChip = (idx: number | string) => {
		setNewMedicine((m: MedicineConsultation) => {
			return {
				...m,
				description: '',
			};
		});
	};

	const handleMedicineBlur = (e: React.FocusEvent<HTMLInputElement>, idx: number | string) => {
		if (e.target.value !== '') {
			setNewMedicine((m: MedicineConsultation) => {
				return {
					...m,
					description: '',
				};
			});
		}
	};

	const handleMedicineChangeUnit = (
		e: React.ChangeEvent<HTMLInputElement>,
		idx: number | string
	) => {
		setNewMedicine((m: MedicineConsultation) => {
			return {
				...m,
				description: '',
			};
		});
	};

	const handleCloseNewMedicineModal = () => {
		setOpenMedicineModal(false);
		setNewMedicine({} as MedicineConsultation);
	};

	const handleCloseLabImageModal = () => {
		setOpenLabImageModal(false);
		setNewLabImage({} as LabImageType);
	};

	const handleClickLabImage = async (value: LabImageType) => {
		const isDoctorMedicineValid = !actualDoctor?.labImages
			.map((m) => m.name?.toLowerCase().trim())
			.includes(value.name?.toLowerCase().trim());

		if (isDoctorMedicineValid) {
			const labImageValue = await createLabImage(value.name as string);
			const newLabImageValue = {
				...labImageValue,
				labImageID: value.id,
				label: labImageValue?.name as string,
			} as SidebarOptions;
			setLabImages((prev) => prev.concat({ ...newLabImageValue, id: prev.length + 1 }));
			setOpenLabImageModal(false);
		} else {
			setOpenLabImageModal(false);
			setLabImages((prev) => [
				...prev,
				{
					id: prev.length + 1,
					name: value.name,
					label: value.name as string,
					labImageID: value.id,
					description: value.description,
				},
			]);
		}

		setLabImagesSuggestions([]);
	};

	const handleChangenameLabImage = (value: string) => {
		if (value === undefined) return;

		setNewLabImage((prev) => ({ ...prev, name: value }));

		if (!!value) {
			const isDisabledCreateMedicine =
				labImagesList.filter(
					(m) =>
						m.name?.toLowerCase() === value.toLowerCase() && newMedicine.name !== m.name
				).length === 0;

			setIsDisabledCreateLab(!isDisabledCreateMedicine);
		}
	};

	useEffect(() => {
		const { name } = newLabImage;

		const timerId = filterSuggestions(
			SuggestionType.LAB_IMAGES,
			labImagesList as LabImage[],
			name,
			setLabImagesSuggestions
		);

		return () => {
			clearSuggestions(timerId, setLabImagesSuggestions);
		};
	}, [newLabImage.name]);

	const clearFormLab = () => {
		fetchLabImages();
		setOpenLabImageModal(false);
		setIsDisabledCreateLab(true);
		setNewLabImage({} as LabImageType);
	};

	const handleCreateLabImage = async (isFreePrescription: boolean) => {
		const labImageValue = await createLabImage(newLabImage.name as string);
		await createDoctorLabImage(labImageValue.id);
		const newLabImageValue = {
			...labImageValue,
			label: labImageValue?.name as string,
			labImageID: labImageValue?.id,
		} as SidebarOptions;
		setSearchLabImages([newLabImageValue, ...searchLabImages]);
		if (!isFreePrescription) {
			setLabImages([...labImages, newLabImageValue]);
		}

		clearFormLab();
	};

	const handleAddNewLabImage = () => {
		setOpenLabImageModal(true);
		setNewLabImage({ name: '' } as LabImageType);
	};

	const tabList = [
		{
			id: ConsultationTab.preview,
			label: isMobile ? 'Anteriores' : 'Consultas Anteriores',
			body: <PreviousConsultation setActualtab={setActualtab} />,
		},
		{
			id: ConsultationTab.createUpdate,
			label: query.get('consultationID')
				? isMobile
					? 'Editar'
					: 'Editar Consulta'
				: isMobile
				? 'Nuevo'
				: 'Iniciar Consulta',
			body: (
				<CreateUpdateConsultation
					reason={reason}
					setReason={handleReason}
					isReasonEmpty={isReasonEmpty}
					description={description}
					setDescription={setDescription}
					physicalDescription={physicalDescription}
					setPhysicalDescription={setPhysicalDescription}
					vitalsSigns={vitalsSigns as VitalSigns}
					registerVitalSigns={register}
					watchVitalSigns={watch}
					vitalSignsErrors={errors}
					setVitalSignValues={setValue}
					setVitalSignErrors={setError}
					selectedDiagnosis={selectedDiagnosis}
					selectedDiffDiagnosis={selectedDiffDiagnosis}
					setSelectedDiagnosis={setSelectedDiagnosis}
					diagnosisInputValue={diagnosisInputValue}
					setDiagnosisInputValue={setDiagnosisInputValue}
					diffDiagnosisInputValue={diffDiagnosisInputValue}
					setDiffDiagnosisInputValue={setDiffDiagnosisInputValue}
					actualSidebarItem={actualSidebarItem}
					setActualSidebarItem={setActualSidebarItem}
					headerMenu={headerMenu}
					setHeaderMenu={setHeaderMenu}
					medicines={medicines}
					setMedicines={setMedicines}
					labImages={labImages}
					setLabImages={setLabImages}
					freePrescription={freePrescription}
					setFreePrescription={setFreePrescription}
					isMedicine={isMedicine}
					setIsMedicine={setIsMedicine}
					sidebarMedicines={sidebarMedicines}
					setSideBarMedicines={setSideBarMedicines}
					searchValue={searchValue}
					setSearchValue={setSearchValue}
					searchMedicines={searchMedicines}
					setSearchMedicines={setSearchMedicines}
					treatmentDescription={treatmentDescription}
					setTreatmentDescription={setTreatmentDescription}
					procedure={procedure}
					setProcedure={setProcedure}
					procedureDescription={procedureDescription}
					setProcedureDescription={setProcedureDescription}
					searchLabImages={searchLabImages}
					setSearchLabImages={setSearchLabImages}
					selectedMedicine={selectedMedicine}
					setSelectedMedicine={setSelectedMedicine}
					medicine={medicine}
					setMedicine={setMedicine}
					sidebarLabs={sidebarLabs}
					setSideLabs={setSideLabs}
					medicalTemplates={medicalTemplates}
					setMedicalTemplates={setMedicalTemplates}
					openCreateTemplate={openCreateTemplate}
					setOpenCreateTemplate={setOpenCreateTemplate}
					templateName={templateName}
					setTemplateName={setTemplateName}
					labImagesTemplates={labImagesTemplates}
					setLabImagesTemplates={setLabImagesTemplates}
					openMedicineModal={openMedicineModal}
					medicineList={medicinesSuggestions}
					handleAddNewMedicine={handleAddNewMedicine}
					handleChangename={handleChangename}
					newMedicine={newMedicine}
					isDisabledCreateMed={isDisabledCreateMed}
					handleClickMedicine={handleClickMedicine}
					handleCreateMedicine={handleCreateMedicine}
					deleteMedicineChip={deleteMedicineChip}
					handleMedicineChip={handleMedicineChip}
					handleMedicineBlur={handleMedicineBlur}
					handleMedicineChangeUnit={handleMedicineChangeUnit}
					handleCloseNewMedicineModal={handleCloseNewMedicineModal}
					openLabImageModal={openLabImageModal}
					isDisabledCreateLab={isDisabledCreateLab}
					labImagesList={labImagesSuggestions}
					freePrescriptionList={freePrescriptionSuggestions}
					handleCloseLabImageModal={handleCloseLabImageModal}
					newLabImage={newLabImage}
					handleClickLabImage={handleClickLabImage}
					handleChangenameLabImage={handleChangenameLabImage}
					handleCreateLabImage={handleCreateLabImage}
					handleAddNewLabImage={handleAddNewLabImage}
					selectedDiagnosisValue={selectedDiagnosisValue}
					setSelectedDiagnosisValue={setSelectedDiagnosisValue}
					selectedDiffDiagnosisValue={selectedDiffDiagnosisValue}
					setSelectedDiffDiagnosisValue={setSelectedDiffDiagnosisValue}
					setSelectedDiffDiagnosis={setSelectedDiffDiagnosis}
				/>
			),
		},
		{
			id: ConsultationTab.history,
			label: 'Historial',
			body: <PatientHistory />,
		},
	];

	useEffect(() => {
		if (!confirmedNavigation) {
			// Navigate to the previous blocked location with your navigate function
			const block = history.block((nextLocation) => {
				if (!nextLocation.pathname.includes('/client-consultation')) {
					setOpenConfirmation(true);
					setLastLocation(nextLocation.pathname);
				}
				return false;
			});

			return () => {
				block();
			};
		}
	}, [history, confirmedNavigation]);

	useEffect(() => {
		if (confirmedNavigation && lastLocation) {
			history.push(lastLocation);
		}
	}, [lastLocation, confirmedNavigation]);

	useEffect(() => {
		if (prescriptions && freePrescriptionSuggestions.length === 0) {
			const arr_result = prescriptions
				.filter(
					(p: any, i: any) =>
						p.prescription.freePrescription &&
						p.prescription.freePrescription[0] &&
						p.prescription.freePrescription[0].id
				)
				.map((p: any) => ({
					...p.prescription.freePrescription[0],
					label:
						p.prescription.freePrescription[0] &&
						p.prescription.freePrescription[0].description
							? p.prescription.freePrescription[0].description
							: '',
					description:
						p.prescription.freePrescription[0] &&
						p.prescription.freePrescription[0].description
							? p.prescription.freePrescription[0].description
							: '',
					freePrescriptionID:
						p.prescription.freePrescription[0] && p.prescription.freePrescription[0].id
							? p.prescription.freePrescription[0].id
							: '',
				}))
				.reduce((unique, prescription) => {
					// Check if the description already exists in the unique array
					const exists = unique.some(
						(p: any) => p.description === prescription.description
					);

					// If the description is unique, add it to the unique array
					if (!exists) {
						unique.push(prescription);
					}

					return unique;
				}, [])
				.slice(0, 8);

			setFreePrescriptionSuggestions(arr_result);
		}
	}, [prescriptions]);

	const handleCancel = () => {
		setOpenConfirmation(false);
		setConfirmedNavigation(false);
		setLastLocation(null);
	};

	const handleAgree = () => {
		setConfirmedNavigation(true);
	};

	const handleSaveConsultation = async (data: FieldValues) => {
		const consultationObj: ConsultationType = {
			visitReason: reason,
			visitDescription: description,
			physicTestNote: physicalDescription,
			bloodPressure: data.bloodPressure as string,
			heartRate: data.heartRate as string,
			respiratoryRate: data.respiratoryRate as string,
			weight: data.weight as string,
			height: data.height as string,
			bodyMass: data.bodyMass as string,
			bodyTemperature: data.bodyTemperature as string,
			oxygenSaturation: data.oxygenSaturation as string,
			prescription: {
				creationDate: getDateAndTime(),
				medicines:
					medicines?.length > 0
						? (medicines.map((m) => {
								return {
									id: m.medicineID ? m.medicineID : (m.id as string),
									medicineID: m.medicineID,
									name: m.label,
									take: m.take,
									indication: m.indication,
									duration: m.duration,
									description: m.description,
								};
						  }) as MedicineType[])
						: [],
				analisis:
					labImages?.length > 0
						? labImages.map((li) => {
								return {
									...li,
									name: li.label,
									labImageID: li.labImageID,
								};
						  })
						: [],
				customPrescription:
					freePrescription?.length > 0
						? freePrescription.map((li) => ({
								...li,
								description: li.description,
						  }))
						: [],
			},
			treatment: treatmentDescription,
			procedureName: procedure,
			procedureDescription: procedureDescription,
			diagnostics: selectedDiagnosis.map((d) => {
				return {
					id: d.id,
					name: d.label,
					code: d.code || '',
					type: DiagnosticTypes.PRESUMPTIVE,
				} as ConsultationDiagnosticType;
			}),
			diffDiagnostics: selectedDiffDiagnosis.map((d) => {
				return {
					id: d.id,
					name: d.label,
					code: d.code || '',
					type: DiagnosticTypes.DIFFERENTIAL,
				} as ConsultationDiagnosticType;
			}),
			createdAt: getDateAndTime(),
			updatedAt: getDateAndTime(),
		};
		if (isUpdate) {
			setConfirmedNavigation(true);
			await updateClientConsultation(query.get('consultationID') as string, consultationObj);
			history.push(path + '/consultation');
		} else {
			if (reason.trim() !== '') {
				setConfirmedNavigation(true);
				await createClientConsultation(consultationObj);
				history.push(path + '/consultation');
			} else {
				setIsReasonEmpty(true);
				setActualSidebarItem(sidebar[0].id);
				if (actualTab !== ConsultationTab.createUpdate) {
					setActualtab(ConsultationTab.createUpdate);
				}
			}
		}
	};

	const actionsButtons = (
		<>
			<CancelButton
				onClick={() => history.push(`/Clients/${selectedClient?.id}/summary`)}
				variant="outlined"
				size="small"
				color="primary"
			>
				Cancelar {!isMobile && 'consulta'}
			</CancelButton>
			<Button
				variant="contained"
				color="primary"
				size="small"
				onClick={handleSubmit(handleSaveConsultation)}
				startIcon={<CheckIcon />}
				data-fx-name="saveConsultation"
			>
				Guardar {!isMobile && 'y terminar'}
			</Button>
		</>
	);

	const infoSection = (
		<>
			{!isMobile && selectedClient?.gender && (
				<StyledHeaderChip
					style={{
						height: '24px',
					}}
					label={
						selectedClient?.gender.charAt(0).toUpperCase() +
						selectedClient?.gender.slice(1).toLowerCase()
					}
					variant="outlined"
				/>
			)}
			{!isMobile &&
				selectedClient?.bornDate &&
				selectedClient?.bornDate !== DateErrorMessage && (
					<StyledHeaderChip
						style={{
							height: '24px',
						}}
						label={yearsOld}
						variant="outlined"
					/>
				)}
		</>
	);

	const handleChange = (_event: React.ChangeEvent<Record<string, unknown>>, newValue: number) => {
		setActualtab(newValue);
	};

	return (
		<>
			<Dialog
				title="Si sales perderás los datos que agregaste en la consulta"
				open={openConfirmation}
				handleClose={handleCancel}
				disagreeText="Volver a la consulta"
				agreeText="Salir"
				maxWidth="xs"
				handleAgree={handleAgree}
			/>
			<CustomTabs
				isLink={false}
				isLoading={isLoading}
				customHeader={
					<ClientHeader
						actionsButtons={
							isMobile ? (
								<div className="fixed px-[20px] py-[12px] w-full bottom-0 left-0 mx-auto flex justify-end bg-[#fff] border-t border-solid border-t-[#0000001F] !bg-white shadow-md">
									{actionsButtons}
								</div>
							) : (
								actionsButtons
							)
						}
						infoSection={infoSection}
						style={
							isMobile
								? {}
								: {
										padding: '24px 24px 6px 24px',
								  }
						}
					/>
				}
				tabList={tabList}
				actualTab={actualTab}
				handleChange={handleChange}
			/>
		</>
	);
};

export default PatientConsultation;
