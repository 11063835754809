// @ts-nocheck
import {
	Button,
	Card,
	CardContent,
	CardHeader,
	IconButton,
	InputAdornment,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LoginPulseara } from '../components/svg';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { ForgotTextField, SendButton } from '../shared/style';
import useQuery from '../shared/hooks/useQuery';
import { LoaderContext } from '../context/loader-context';
import { LoaderContextProps, UserContextProps } from '../context/types';
import { UserContext } from '../context/user-context';
import { useForm } from 'react-hook-form';
import { FieldErrors, FieldValues } from 'react-hook-form/dist/types';
import { ERRORS } from 'shared/constants';

const ChangePassword = () => {
	const history = useHistory();
	const { showSpinner } = useContext(LoaderContext) as LoaderContextProps;
	const { forgotPassword, changePassword, logIn } = useContext(UserContext) as UserContextProps;
	const [isResendDisable, setIsResendDisable] = useState(false);
	const [userEmail, setUserEmail] = useState<string>('');
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [showConfPassword, setShowConfPassword] = useState<boolean>(false);
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
		setError,
		clearErrors,
	} = useForm();
	const query = useQuery();

	useEffect(() => {
		if (isResendDisable) {
			const resendTimeout = setTimeout(() => {
				setIsResendDisable(false);
			}, 30000);

			return () => {
				clearTimeout(resendTimeout);
			};
		}
	}, [isResendDisable]);

	useEffect(() => {
		if (query.get('email') !== null) {
			setUserEmail(query.get('email') as string);
			setIsResendDisable(true);
		}
	}, [query]);

	useEffect(() => {
		const errorHandler = (errorsData: FieldErrors<FieldValues>) => {
			if (errorsData.code && !errorsData.code.message) {
				if (errorsData.code.type === 'required') {
					setError('code', { type: 'required', message: 'El código es requerido.' });
					return;
				}

				setError('code', { type: 'required', message: 'El código no es válido.' });
				return;
			}

			if (errorsData.password && !errorsData.password.message) {
				if (errorsData.password.type === 'minLength') {
					setError('password', {
						type: 'minLength',
						message: 'Debes ingresar al menos 8 dígitos',
					});
					return;
				}

				if (errorsData.password.type === 'required') {
					setError('password', { type: 'required', message: ERRORS.PASSWORD.REQUIRED });
					return;
				}
			}

			if (errorsData.confPassword && !errorsData.confPassword.message) {
				if (errorsData.confPassword.type === 'required') {
					setError('confPassword', {
						type: 'required',
						message: ERRORS.PASSWORD.REQUIRED,
					});
					return;
				}
			}
		};

		errorHandler(errors);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [{ ...errors }]);

	useEffect(() => {
		if (watch('password')) {
			if (watch('password') !== watch('confPassword')) {
				setError('confPassword', {
					type: 'required',
					message: 'Las contraseñas no coinciden.',
				});
			} else {
				clearErrors('confPassword');
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watch('confPassword'), watch('password')]);

	const handleClickShowPassword = () => {
		setShowPassword((prevShowPassword) => !prevShowPassword);
	};

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	const handleClickShowConfPassword = () => {
		setShowConfPassword((prevShowPassword) => !prevShowPassword);
	};

	const handleMouseDownConfPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	const handleClickResend = async () => {
		if (userEmail !== '') {
			showSpinner(true);
			await forgotPassword(userEmail);
			setIsResendDisable(true);
			showSpinner(false);
			history.push(`/change-password?email=${userEmail}`);
		}
	};

	const handleMouseDownResend = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	const onSubmitChangePassword = async (data: FieldValues) => {
		showSpinner(true);

		const response = await changePassword(userEmail, data.code, data.password);

		if (response) {
			await logIn(userEmail, data.password);
		} else {
			setError('code', { type: 'required', message: 'El código no es válido.' });
		}

		showSpinner(false);
	};

	return (
		<div className="relative top-0 left-0 w-full h-screen min-wh-login flex flex-col items-center justify-center">
			<div className="mb-16">
				<div>
					<LoginPulseara />
				</div>
			</div>
			<Card className="flex flex-col bg-white-section p-8 rounded-md">
				<CardHeader
					title="Cambiar contraseña"
					subheader="Ingresa tu Email. Si estas registrado te enviaremos un código."
				/>
				<CardContent className="flex flex-col">
					<ForgotTextField
						value={watch('code')}
						variant="outlined"
						type="text"
						error={!!errors.code}
						helperText={errors.code && errors.code.message}
						{...register('code', {
							required: true,
							minLength: 6,
						})}
						id="code"
						name="code"
						label="Ingresa tu código"
						placeholder="Ingresa tu código"
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										size="small"
										color="primary"
										aria-label="resend code"
										onClick={handleClickResend}
										onMouseDown={handleMouseDownResend}
										disabled={isResendDisable}
										edge="end"
									>
										Reenviar
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					<ForgotTextField
						value={watch('password')}
						variant="outlined"
						type={showPassword ? 'text' : 'password'}
						{...register('password', {
							required: true,
							minLength: 8,
						})}
						id="password"
						name="password"
						label="Nueva contraseña"
						placeholder="Nueva contraseña"
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										size="small"
										aria-label="toggle new password visibility"
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
										edge="end"
									>
										{showPassword ? (
											<Visibility fontSize="small" />
										) : (
											<VisibilityOff fontSize="small" />
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
						error={!!errors.password}
						helperText={errors.password && errors.password.message}
					/>
					<ForgotTextField
						value={watch('confPassword')}
						variant="outlined"
						type={showConfPassword ? 'text' : 'password'}
						{...register('confPassword', {
							required: true,
						})}
						id="confPassword"
						name="confPassword"
						placeholder="Confirma tu nueva contraseña"
						label="Confirma tu nueva contraseña"
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										size="small"
										aria-label="toggle confirmation password visibility"
										onClick={handleClickShowConfPassword}
										onMouseDown={handleMouseDownConfPassword}
										edge="end"
									>
										{showConfPassword ? (
											<Visibility fontSize="small" />
										) : (
											<VisibilityOff fontSize="small" />
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
						error={!!errors.confPassword}
						helperText={!!errors.confPassword && errors.confPassword.message}
					/>
					<SendButton
						size="large"
						variant="contained"
						color="primary"
						fullWidth
						onClick={handleSubmit(onSubmitChangePassword)}
					>
						Cambiar contraseña
					</SendButton>
					<Button size="large" color="primary" fullWidth onClick={() => history.goBack()}>
						Cancelar
					</Button>
				</CardContent>
			</Card>
		</div>
	);
};

export default ChangePassword;
