import { TableRow } from '@material-ui/core';
import React from 'react';
import { IColumnType } from '../../shared/type';
import { StyledTableCell } from './style';

interface Props<T> {
	columns: IColumnType<T>[];
}

function CTableHeader<T>({ columns }: Props<T>): JSX.Element {
	return (
		<TableRow>
			{columns.map((column, columnIndex) => (
				<StyledTableCell
					key={`table-head-cell-${columnIndex}`}
					size={column.size || 'small'}
					align={column.align || 'left'}
					style={{ width: column.width }}
				>
					{column.title}
				</StyledTableCell>
			))}
		</TableRow>
	);
}

export default CTableHeader;
