import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	createTheme,
	withStyles,
} from '@material-ui/core';
import React, { FC } from 'react';

const theme = createTheme();

const LifeStyleHeader = withStyles({
	root: {
		padding: theme.spacing(1.5, 2.5),
		backgroundColor: '#E8EAF6',
		border: '1px solid rgba(0, 0, 0, 0.12)',
	},
	title: { color: 'rgba(0, 0, 0, 0.87)', fontWeight: 500 },
})(CardHeader) as typeof CardHeader;

const LifeStyleContent = withStyles({
	root: {
		padding: theme.spacing(2, 2.5, 3, 2.5),
	},
})(CardContent) as typeof CardContent;
const LifeStyleAction = withStyles({
	root: {
		padding: theme.spacing(0, 2.5, 2.5, 2.5),
		justifyContent: 'flex-end',
		backgroundColor: '#ffffff',
	},
})(CardActions) as typeof CardActions;

interface Props {
	children: React.ReactNode;
	avatar: React.ReactNode;
	title: React.ReactNode;
	isActionVisible: boolean;
	disabled: boolean;
	handleCancel: () => void;
	handleSave: () => void;
}

const LifeStyleCard: FC<Props> = ({
	children,
	avatar,
	title,
	isActionVisible,
	disabled,
	handleCancel,
	handleSave,
}) => {
	return (
		<Card>
			<LifeStyleHeader avatar={avatar} title={title} />
			<LifeStyleContent>
				<div style={{ flexGrow: 1 }}>{children}</div>
			</LifeStyleContent>
			{isActionVisible && (
				<LifeStyleAction>
					<Button disabled={disabled} onClick={handleCancel} size="small" color="primary">
						Cancelar
					</Button>
					<Button disabled={disabled} onClick={handleSave} size="small" color="primary">
						Guardar cambios
					</Button>
				</LifeStyleAction>
			)}
		</Card>
	);
};

export default LifeStyleCard;
