// @ts-nocheck
import { Container } from './styles';
import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect } from 'react';
import {
	LabImages,
	Medicine,
	MedicineOption,
	MedicineConsultation,
	SelectedDiagnosis,
	SidebarOptions,
	Templates,
	DiagnosisOptions,
	FreePrescription,
} from '../../../shared/type';
import { sidebar } from '../../../shared/constants';
import SidebarSection from './SidebarSection';
import CreateUpdateForm from '../../../components/forms/client/consultation/CreateUpdateForm';
import { AIContextProps, MedicineType } from '../../../context/types';
import { LabImage } from 'models';
import {
	FieldValues,
	UseFormRegister,
	UseFormSetError,
	UseFormSetValue,
	UseFormWatch,
} from 'react-hook-form/dist/types';
import { VitalSigns } from '../types';
import { AiContext } from 'context/ai-context';
import useMobile from 'hooks/useMobile';

interface Props {
	reason: string;
	setReason: (value: string) => void;
	isReasonEmpty: boolean;
	description: string;
	setDescription: (value: string) => void;
	physicalDescription: string;
	setPhysicalDescription: (value: string) => void;
	vitalsSigns: VitalSigns;
	registerVitalSigns: UseFormRegister<FieldValues>;
	watchVitalSigns: UseFormWatch<FieldValues>;
	vitalSignsErrors: FieldValues;
	setVitalSignValues: UseFormSetValue<FieldValues>;
	setVitalSignErrors: UseFormSetError<FieldValues>;
	selectedDiagnosis: SelectedDiagnosis[];
	selectedDiffDiagnosis: SelectedDiagnosis[];
	setSelectedDiagnosis: Dispatch<SetStateAction<SelectedDiagnosis[]>>;
	setSelectedDiffDiagnosis: Dispatch<SetStateAction<SelectedDiagnosis[]>>;
	diagnosisInputValue: string;
	setDiagnosisInputValue: (value: string) => void;
	diffDiagnosisInputValue: string;
	setDiffDiagnosisInputValue: (value: string) => void;
	actualSidebarItem: string;
	setActualSidebarItem: (value: string) => void;
	treatmentDescription: string;
	setTreatmentDescription: (value: string) => void;
	procedure: string;
	setProcedure: (value: string) => void;
	procedureDescription: string;
	setProcedureDescription: (value: string) => void;
	headerMenu: null | HTMLElement;
	setHeaderMenu: (value: null | HTMLElement) => void;
	medicines: Medicine[];
	setMedicines: Dispatch<SetStateAction<Medicine[]>>;
	labImages: LabImages[];
	setLabImages: Dispatch<SetStateAction<LabImages[]>>;
	freePrescription: FreePrescription[];
	setFreePrescription: Dispatch<SetStateAction<FreePrescription[]>>;
	isMedicine: boolean;
	setIsMedicine: (value: boolean) => void;
	sidebarMedicines: SidebarOptions[];
	setSideBarMedicines: (value: SidebarOptions[]) => void;
	searchValue: SidebarOptions;
	setSearchValue: (value: SidebarOptions) => void;
	searchMedicines: SidebarOptions[];
	setSearchMedicines: (value: SidebarOptions[]) => void;
	searchLabImages: SidebarOptions[];
	setSearchLabImages: (value: SidebarOptions[]) => void;
	selectedMedicine: MedicineType;
	setSelectedMedicine: (value: MedicineType) => void;
	medicine: MedicineOption;
	setMedicine: Dispatch<SetStateAction<MedicineOption>>;
	sidebarLabs: SidebarOptions[];
	setSideLabs: (value: SidebarOptions[]) => void;
	medicalTemplates: Templates[];
	setMedicalTemplates: (value: Templates[]) => void;
	openCreateTemplate: boolean;
	setOpenCreateTemplate: (value: boolean) => void;
	templateName: string;
	setTemplateName: (value: string) => void;
	labImagesTemplates: Templates[];
	setLabImagesTemplates: (value: Templates[]) => void;
	openMedicineModal?: boolean;
	medicineList: MedicineType[];
	freePrescriptionSuggestions: FreePrescription[];
	handleAddNewMedicine: () => void;
	handleChangename: (value: string) => void;
	newMedicine: MedicineConsultation;
	isDisabledCreateMed: boolean;
	handleClickMedicine: (value: MedicineType) => void;
	handleCreateMedicine: (isFreePrescription: boolean) => void;
	deleteMedicineChip: (value: number | string) => void;
	handleMedicineChip: (value: number | string) => void;
	handleMedicineBlur: (e: React.FocusEvent<HTMLInputElement>, value: number | string) => void;
	handleMedicineChangeUnit: (
		e: React.ChangeEvent<HTMLInputElement>,
		value: number | string
	) => void;
	handleCloseNewMedicineModal: () => void;
	openLabImageModal: boolean;
	isDisabledCreateLab: boolean;
	labImagesList: LabImage[];
	handleCloseLabImageModal: () => void;
	newLabImage: LabImage;
	handleClickLabImage: (value: LabImage) => void;
	handleChangenameLabImage: (value: string) => void;
	handleCreateLabImage: (isFreePrescription: boolean) => void;
	handleAddNewLabImage: () => void;
	selectedDiagnosisValue: string | null;
	setSelectedDiagnosisValue: (value: string | null) => void;
	selectedDiffDiagnosisValue: string | null;
	setSelectedDiffDiagnosisValue: (value: string | null) => void;
}

const CreateUpdateConsultation: React.FC<Props> = ({
	reason,
	setReason,
	isReasonEmpty,
	description,
	setDescription,
	physicalDescription,
	setPhysicalDescription,
	vitalsSigns,
	registerVitalSigns,
	watchVitalSigns,
	vitalSignsErrors,
	setVitalSignValues,
	setVitalSignErrors,
	selectedDiagnosisValue,
	setSelectedDiagnosisValue,
	diagnosisInputValue,
	setDiagnosisInputValue,
	diffDiagnosisInputValue,
	setDiffDiagnosisInputValue,
	selectedDiffDiagnosisValue,
	setSelectedDiffDiagnosisValue,
	actualSidebarItem,
	setActualSidebarItem,
	headerMenu,
	setHeaderMenu,
	medicines,
	setMedicines,
	labImages,
	setLabImages,
	isMedicine,
	setIsMedicine,
	sidebarMedicines,
	setSideBarMedicines,
	searchValue,
	selectedDiagnosis,
	selectedDiffDiagnosis,
	setSearchValue,
	searchMedicines,
	setSearchMedicines,
	searchLabImages,
	setSearchLabImages,
	selectedMedicine,
	setSelectedMedicine,
	medicine,
	setMedicine,
	sidebarLabs,
	setSideLabs,
	medicalTemplates,
	setMedicalTemplates,
	openCreateTemplate,
	setOpenCreateTemplate,
	templateName,
	setTemplateName,
	labImagesTemplates,
	setLabImagesTemplates,
	treatmentDescription,
	setTreatmentDescription,
	procedure,
	setProcedure,
	procedureDescription,
	setProcedureDescription,
	openMedicineModal,
	medicineList,
	handleAddNewMedicine,
	handleChangename,
	newMedicine,
	isDisabledCreateMed,
	handleClickMedicine,
	handleCreateMedicine,
	deleteMedicineChip,
	handleMedicineChip,
	handleMedicineBlur,
	handleMedicineChangeUnit,
	handleCloseNewMedicineModal,
	openLabImageModal,
	isDisabledCreateLab,
	labImagesList,
	handleCloseLabImageModal,
	newLabImage,
	handleClickLabImage,
	handleChangenameLabImage,
	handleCreateLabImage,
	handleAddNewLabImage,
	setSelectedDiagnosis,
	setSelectedDiffDiagnosis,
	freePrescription,
	setFreePrescription,
	freePrescriptionList,
}) => {
	const { clearIASuggestions, setAiSection } = useContext(AiContext) as AIContextProps;
	const { isMobile } = useMobile();
	const onScroll = (e: React.UIEvent<HTMLElement>): void => {
		const currentScrollY = e.currentTarget.scrollTop;
		if (currentScrollY === 0 || currentScrollY < 220) {
			setActualSidebarItem(sidebar[0].id);
		}
		if (currentScrollY >= 220 && currentScrollY < 550) {
			setActualSidebarItem(sidebar[1].id);
		}
		if (currentScrollY >= 800 && currentScrollY < 1100) {
			setActualSidebarItem(sidebar[2].id);
		}
		if (currentScrollY >= 1100) {
			setActualSidebarItem(sidebar[3].id);
		}
	};

	// Diagnosis Func
	const handleDeleteDiagnosis = useCallback((id: number | string) => {
		setSelectedDiagnosis((prev: SelectedDiagnosis[]) => {
			return prev
				.filter((diagnosis) => diagnosis.id !== id)
				.map((diagnosis, i) => {
					return {
						id: diagnosis.id ? diagnosis.id : i + 1,
						label: diagnosis.label,
						code: diagnosis.code || '',
					};
				});
		});
	}, []);

	const handleDeleteDiffDiagnosis = useCallback((id: number | string) => {
		setSelectedDiffDiagnosis((prev: SelectedDiagnosis[]) => {
			return prev
				.filter((diagnosis) => diagnosis.id !== id)
				.map((diagnosis, i) => {
					return {
						id: diagnosis.id ? diagnosis.id : i + 1,
						label: diagnosis.label,
						code: diagnosis.code || '',
					};
				});
		});
	}, []);

	const handleAddDiagnosist = useCallback(() => {
		if (diagnosisInputValue !== '') {
			setSelectedDiagnosis((prev: SelectedDiagnosis[]) => {
				return [...prev, { id: prev.length + 1, label: diagnosisInputValue!, code: '' }];
			});
			setDiagnosisInputValue('');
		}
	}, [diagnosisInputValue]);

	const handleAddDiffDiagnosist = useCallback(() => {
		if (diffDiagnosisInputValue !== '') {
			setSelectedDiffDiagnosis((prev: SelectedDiagnosis[]) => {
				return [
					...prev,
					{ id: prev.length + 1, label: diffDiagnosisInputValue!, code: '' },
				];
			});
		}
	}, [diffDiagnosisInputValue]);

	const handleClickAddDiagnosist = useCallback(
		(option: DiagnosisOptions | string | null) => {
			if (option && typeof option === 'string') {
				setSelectedDiagnosis((prev) => {
					return [...prev, { id: prev.length + 1, label: option as string, code: '' }];
				});
				setDiagnosisInputValue('');
			}

			if (option && typeof option !== 'string' && option?.label !== '') {
				setSelectedDiagnosis((prev) => {
					return [
						...prev,
						{
							id: prev.length + 1,
							label: option?.label as string,
							code: option?.code || '',
						},
					];
				});
				setSelectedDiagnosisValue(null);
				setDiagnosisInputValue('');
			}
		},
		[diagnosisInputValue]
	);

	const handleClickAddDiffDiagnosist = useCallback(
		(option: DiagnosisOptions | string | null) => {
			if (option && typeof option === 'string') {
				setSelectedDiffDiagnosis((prev) => {
					return [...prev, { id: prev.length + 1, label: option as string, code: '' }];
				});
				setDiffDiagnosisInputValue('');
			}
			if (option && typeof option !== 'string' && option?.label !== '') {
				setSelectedDiffDiagnosis((prev) => {
					return [
						...prev,
						{
							id: prev.length + 1,
							label: option?.label as string,
							code: option?.code || '',
						},
					];
				});
				setSelectedDiffDiagnosisValue(null);
				setDiffDiagnosisInputValue('');
			}
		},
		[diffDiagnosisInputValue]
	);

	useEffect(() => {
		setAiSection('consultation');

		return () => {
			clearIASuggestions();
		};
	}, []);

	return (
		<Container container onScroll={onScroll}>
			{!isMobile && (
				<SidebarSection
					actualSidebarItem={actualSidebarItem}
					setActualSidebarItem={setActualSidebarItem}
				/>
			)}
			<CreateUpdateForm
				sidebar={sidebar}
				freePrescriptionList={freePrescriptionList}
				reason={reason}
				setReason={setReason}
				isReasonEmpty={isReasonEmpty}
				selectedDiagnosis={selectedDiagnosis}
				selectedDiffDiagnosis={selectedDiffDiagnosis}
				description={description}
				setDescription={setDescription}
				physicalDescription={physicalDescription}
				setPhysicalDescription={setPhysicalDescription}
				vitalsSigns={vitalsSigns}
				registerVitalSigns={registerVitalSigns}
				watchVitalSigns={watchVitalSigns}
				vitalSignsErrors={vitalSignsErrors}
				setVitalSignValues={setVitalSignValues}
				setVitalSignErrors={setVitalSignErrors}
				selectedDiagnosisValue={selectedDiagnosisValue}
				handleDeleteDiagnosis={handleDeleteDiagnosis}
				handleDeleteDiffDiagnosis={handleDeleteDiffDiagnosis}
				diagnosisInputValue={diagnosisInputValue}
				setDiagnosisInputValue={setDiagnosisInputValue}
				diffDiagnosisInputValue={diffDiagnosisInputValue}
				setDiffDiagnosisInputValue={setDiffDiagnosisInputValue}
				selectedDiffDiagnosisValue={selectedDiffDiagnosisValue}
				setSelectedDiffDiagnosisValue={setSelectedDiffDiagnosisValue}
				handleAddDiagnosist={handleAddDiagnosist}
				handleClickAddDiagnosist={handleClickAddDiagnosist}
				handleClickAddDiffDiagnosist={handleClickAddDiffDiagnosist}
				handleAddDiffDiagnosist={handleAddDiffDiagnosist}
				setSelectedDiffDiagnosis={setSelectedDiffDiagnosis}
				treatmentDescription={treatmentDescription}
				setTreatmentDescription={setTreatmentDescription}
				procedure={procedure}
				setProcedure={setProcedure}
				procedureDescription={procedureDescription}
				setProcedureDescription={setProcedureDescription}
				headerMenu={headerMenu}
				setHeaderMenu={setHeaderMenu}
				medicines={medicines}
				setMedicines={setMedicines}
				freePrescription={freePrescription}
				setFreePrescription={setFreePrescription}
				labImages={labImages}
				setLabImages={setLabImages}
				isMedicine={isMedicine}
				setIsMedicine={setIsMedicine}
				sidebarMedicines={sidebarMedicines}
				setSideBarMedicines={setSideBarMedicines}
				searchValue={searchValue}
				setSearchValue={setSearchValue}
				searchMedicines={searchMedicines}
				setSearchMedicines={setSearchMedicines}
				searchLabImages={searchLabImages}
				setSearchLabImages={setSearchLabImages}
				selectedMedicine={selectedMedicine}
				setSelectedMedicine={setSelectedMedicine}
				medicine={medicine}
				setMedicine={setMedicine}
				sidebarLabs={sidebarLabs}
				setSideLabs={setSideLabs}
				medicalTemplates={medicalTemplates}
				setMedicalTemplates={setMedicalTemplates}
				openCreateTemplate={openCreateTemplate}
				setOpenCreateTemplate={setOpenCreateTemplate}
				templateName={templateName}
				setTemplateName={setTemplateName}
				labImagesTemplates={labImagesTemplates}
				setLabImagesTemplates={setLabImagesTemplates}
				openMedicineModal={openMedicineModal}
				medicineList={medicineList}
				handleAddNewMedicine={handleAddNewMedicine}
				handleChangename={handleChangename}
				newMedicine={newMedicine}
				isDisabledCreateMed={isDisabledCreateMed}
				handleClickMedicine={handleClickMedicine}
				handleCreateMedicine={handleCreateMedicine}
				deleteMedicineChip={deleteMedicineChip}
				handleMedicineChip={handleMedicineChip}
				handleMedicineBlur={handleMedicineBlur}
				handleMedicineChangeUnit={handleMedicineChangeUnit}
				handleCloseNewMedicineModal={handleCloseNewMedicineModal}
				openLabImageModal={openLabImageModal}
				isDisabledCreateLab={isDisabledCreateLab}
				labImagesList={labImagesList}
				handleCloseLabImageModal={handleCloseLabImageModal}
				newLabImage={newLabImage}
				handleClickLabImage={handleClickLabImage}
				handleChangenameLabImage={handleChangenameLabImage}
				handleCreateLabImage={handleCreateLabImage}
				handleAddNewLabImage={handleAddNewLabImage}
			/>
		</Container>
	);
};

export default CreateUpdateConsultation;
