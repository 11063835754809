import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

interface TabPanelProps {
	children?: React.ReactNode;
	index: any;
	value: any;
}

const useStyles = makeStyles(() => ({
	tabPanel: {
		backgroundColor: '#eceff1',
	},
	container: {
		padding: 0,
	},
}));

const TabPanel: FC<TabPanelProps> = (props) => {
	const { children, value, index, ...other } = props;
	const classes = useStyles();

	return (
		<div
			className={classes.tabPanel}
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box className={classes.container} p={3}>
					{children}
				</Box>
			)}
		</div>
	);
};

export default TabPanel;
