import React, { CSSProperties } from 'react';

interface LineSeparatorProps {
	styleProps?: CSSProperties;
}

const LineSeparator: React.FC<LineSeparatorProps> = ({ styleProps }) => (
	<div className="border-b-2" style={{ borderBottomColor: '#EDF3F1', ...styleProps }} />
);

export default LineSeparator;
