// @ts-nocheck
import { Button, Card, CardContent, CardHeader, TextField, makeStyles } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { RouteProps, useHistory } from 'react-router-dom';
import { LoginPulseara } from '../components/svg';
import { LoaderContext } from '../context/loader-context';
import { LoaderContextProps, RelationsContextProps, UserContextProps } from '../context/types';
import { LoginErrorText, RedErrorIcon, SendButton } from '../shared/style';
import { isDoctor, isDoctorMember, isSecretary } from 'shared/utilFunctions';
import { RelationsContext } from 'context/relations-context';
import { UserContext } from 'context/user-context';

const useStyles = makeStyles((theme) => ({
	button: {
		color: '#1976D2',
		textTransform: 'none',
		'&:disabled': {
			color: '#00000061',
		},
	},
}));

const ConfirmAccount: React.FC<RouteProps> = ({ location }) => {
	const history = useHistory();
	const { confirmSignUp, signIn, logOut, resendConfirmationCode } = useContext(
		UserContext
	) as UserContextProps;
	const { setAlertMessage } = useContext(RelationsContext) as RelationsContextProps;
	const { showSpinner } = useContext(LoaderContext) as LoaderContextProps;
	const [confirmationCode, setConfirmationCode] = useState('');
	const [confirmationErrorText, setConfirmationErrorText] = useState('');
	const [showResendIcon, setShowResendIcon] = useState(true);
	const classes = useStyles();

	const onSubmitHandler = async (event: any) => {
		event.preventDefault();
		try {
			showSpinner(true);
			try {
				await confirmSignUp(location?.state.username, confirmationCode.trim());
				const user = await signIn(location?.state.username, location?.state.password);
				if (isDoctorMember(user)) {
					history.push('/complete-registration-member');
				} else if (isDoctor(user)) {
					history.push('/complete-registration-doctor');
				} else if (isSecretary(user)) {
					history.push('/complete-registration-secretary');
				} else {
					history.push('/Not-Found');
				}
				setConfirmationErrorText('');
			} catch (e) {
				setConfirmationErrorText('El código no es válido, intenta de nuevo');
				showSpinner(false);
			}
			showSpinner(false);
		} catch (e) {
			history.push('/Not-Found');
		}
	};

	const onChangeHandler = ({ target }: any) => {
		setConfirmationCode(target.value);
	};

	const resendCode = async () => {
		try {
			setShowResendIcon(true);
			await resendConfirmationCode(location?.state.username);
			setAlertMessage({
				message: 'Se ha enviado un nuevo código a tu correo',
				severity: 'success',
			});

			localStorage.removeItem('resend-timer');
		} catch (e) {
			setAlertMessage({
				message: 'No se pudo enviar el código, intenta de nuevo',
				severity: 'error',
			});
		}
	};

	useEffect(() => {
		let timer = null;
		if (showResendIcon) {
			timer = setTimeout(() => {
				setShowResendIcon(false);
			}, 30000);
		}

		return () => {
			clearTimeout(timer);
		};
	}, [showResendIcon]);

	const ReSendCodeIcon = () => {
		return (
			<Button className={classes.button} onClick={resendCode} disabled={showResendIcon}>
				Reenviar
			</Button>
		);
	};

	return (
		<div className="relative top-0 left-0 w-full h-screen min-wh-login flex flex-col items-center justify-center">
			<div className="mb-16">
				<div>
					<LoginPulseara />
				</div>
			</div>
			<Card
				className="flex flex-col bg-white-section p-8 rounded-md"
				style={{ minWidth: 488 }}
			>
				<CardHeader
					title="Te hemos enviado un código"
					subheader="Ingresa el código que te hemos enviado a tu correo. "
				/>
				<CardContent className="flex flex-col">
					<TextField
						value={confirmationCode}
						onChange={onChangeHandler}
						label="Ingresa el código*"
						type="email"
						id="confirmationCode"
						name="confirmationCode"
						variant="outlined"
						placeholder="000000"
						InputProps={{
							endAdornment: ReSendCodeIcon(),
						}}
						style={{ marginBottom: 16 }}
					/>
					{confirmationErrorText !== '' && (
						<LoginErrorText variant="body2">
							<RedErrorIcon /> {confirmationErrorText}
						</LoginErrorText>
					)}
					<SendButton
						size="large"
						variant="contained"
						color="primary"
						disabled={confirmationCode.length < 6}
						fullWidth
						onClick={onSubmitHandler}
					>
						Continuar
					</SendButton>
					<Button size="large" color="primary" fullWidth onClick={logOut}>
						Salir
					</Button>
				</CardContent>
			</Card>
		</div>
	);
};

export default ConfirmAccount;
