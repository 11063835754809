import { Skeleton } from '@material-ui/lab';
import PrescriptionList from './PrescriptionList';
import PrescriptionSidebarSection from './PrescriptionSidebarSection';
import { PrescriptionContainer, SideBarSection } from './styles';

const PrescriptionsSkeleton: React.FC = () => {
	return (
		<PrescriptionContainer>
			<SideBarSection item xs={4} md={2}>
				<PrescriptionSidebarSection value={'all'} disabled />
			</SideBarSection>
			<PrescriptionList
				styles={{
					display: 'flex',
					flexDirection: 'column',
					gap: '28px',
				}}
			>
				<Skeleton variant="rect" width="83%" height={152} style={{ borderRadius: '4px' }} />
				<Skeleton variant="rect" width="83%" height={152} style={{ borderRadius: '4px' }} />
				<Skeleton variant="rect" width="83%" height={152} style={{ borderRadius: '4px' }} />
			</PrescriptionList>
		</PrescriptionContainer>
	);
};

export default PrescriptionsSkeleton;
