import PatientList from '../components/clients/PatientList';
// A cada cliente se le debe agregar u
const Patients = () => {
	return (
		<div className="bg-green-content px-8 py-8 1/2xl:px-10 1/2xl:gap-8 h-full pages-container w-full">
			<PatientList fullWidth />
		</div>
	);
};

export default Patients;
