import React from 'react';
import CircleCheckBox from './ExportExcelCircleCheckBox';

import { HumanoLogo, YunenLogo, SenasaLogo, MapfreLogo, DateIcon } from '../svg';
interface Props {
	setPropertiesToExport: Function;
	insuranceSelected: string;
	setInsuranceSelected: Function;
}

const ExportExcel: React.FC<Props> = ({
	setPropertiesToExport,
	insuranceSelected,
	setInsuranceSelected,
}) => {
	const onChange = (e: any) => {
		e.persist();
		setPropertiesToExport((properties: any) => {
			const newProperties = { ...properties };
			if (e.target.checked) newProperties[e.target.id] = true;
			else delete newProperties[e.target.id];
			return newProperties;
		});
	};

	return (
		<div className="mt-10">
			<div>
				<h2 className="mb-6 text-blueLucki-default font-bold raleway-font text-lg">
					Seleccionar fecha
				</h2>
				<div className="flex">
					<div className="flex flex-col mr-8">
						<span className="raleway-font text-base font-medium">Desde</span>
						<div className="flex noCalendarIcon items-center relative bg-white-lilac px-3 py-2 border-2 border-summerGreen-default rounded">
							<div className="mr-3">
								<DateIcon />
							</div>
							<input
								type="date"
								className="bg-white-lilac text-base font-normal"
								style={{ color: '#3D4043' }}
							/>
						</div>
					</div>
					<div className="flex flex-col mr-8">
						<span className="raleway-font text-base font-medium">Hasta</span>
						<div className="flex items-center noCalendarIcon relative bg-white-lilac px-3 py-2 border-2 border-summerGreen-default rounded">
							<div className="mr-3">
								<DateIcon />
							</div>
							<input
								type="date"
								className="bg-white-lilac text-base font-normal relative"
								style={{ color: '#3D4043' }}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className="mt-10">
				<h2 className="mb-6 text-blueLucki-default font-bold raleway-font text-lg">
					Seleccionar seguros
				</h2>
				<div className="flex">
					<div
						onClick={() => setInsuranceSelected('')}
						className={`h-12 w-48 px-2 flex items-center justify-center border-2 rounded-lg ${
							insuranceSelected === '' ? 'border-green-excel' : 'border-green-content'
						} mr-4 raleway-font font-normal`}
					>
						Sin Seguro
					</div>
					<div
						onClick={() => setInsuranceSelected('ARS Humano')}
						className={`h-12 w-48 px-2 py-1 flex items-center justify-center border-2 rounded-lg ${
							insuranceSelected === 'ARS Humano'
								? 'border-green-excel'
								: 'border-green-content'
						}  mr-4 `}
					>
						<div>
							<HumanoLogo />
						</div>
					</div>
					<div
						onClick={() => setInsuranceSelected('')}
						className={`h-12 w-48  px-2 py-1 flex hidden items-center justify-center border-2 rounded-lg ${
							insuranceSelected === 'Yunen'
								? 'border-green-excel'
								: 'border-green-content'
						} mr-4`}
					>
						<div>
							<YunenLogo />
						</div>
					</div>
					<div
						onClick={() => setInsuranceSelected('ARS SeNaSa')}
						className={`h-12 w-48 px-2 py-1 flex items-center justify-center border-2 rounded-lg ${
							insuranceSelected === 'ARS SeNaSa'
								? 'border-green-excel'
								: 'border-green-content'
						} mr-4`}
					>
						<div>
							<SenasaLogo />
						</div>
					</div>
					<div
						onClick={() => setInsuranceSelected('')}
						className="h-12 w-48 px-2 py-1 flex hidden items-center justify-center border-2 rounded-lg border-green-excel"
					>
						<div>
							<MapfreLogo />
						</div>
					</div>
				</div>
			</div>

			<div className="mt-10">
				<h1 className="mb-6 text-blueLucki-default font-bold raleway-font text-lg">
					Incluir estos datos
				</h1>
				<div className="grid grid-cols-4 gap-y-4 ">
					<CircleCheckBox onChangeHandler={onChange} id="name" label="Nombre" />
					<CircleCheckBox onChangeHandler={onChange} id="phoneNumber" label="Teléfono" />
					<CircleCheckBox
						onChangeHandler={onChange}
						id="cellphoneNumber"
						label="Celular"
					/>
					<CircleCheckBox
						onChangeHandler={onChange}
						id="identificationData"
						label="Cedula"
					/>
					<CircleCheckBox
						onChangeHandler={onChange}
						id="fechaIngreso"
						label="Fecha de Ingreso"
					/>
					<CircleCheckBox
						onChangeHandler={onChange}
						id="genderDate"
						label="Edad y Sexo"
					/>
					<CircleCheckBox onChangeHandler={onChange} id="email" label="Correo" />
					<CircleCheckBox
						onChangeHandler={onChange}
						id="noContrato"
						label="No. de Contrato"
					/>
					<CircleCheckBox
						onChangeHandler={onChange}
						id="affiliateNumber"
						label="No. de Afiliado"
					/>
					<CircleCheckBox
						onChangeHandler={onChange}
						id="addressStreet"
						label="Direccion"
					/>
					<CircleCheckBox onChangeHandler={onChange} id="sector" label="Sector" />
					<CircleCheckBox onChangeHandler={onChange} id="city" label="Ciudad" />
				</div>
			</div>
		</div>
	);
};

export default ExportExcel;
