export enum MemberActionsTypes {
	setSpecialty = 'setSpecialty',
	setSpecialtyError = 'setSpecialtyError',
	setDoctorName = 'setDoctorName',
	setDoctorNameError = 'setDoctorNameError',
	setDoctorPhone = 'setDoctorPhone',
	setDoctorPhoneError = 'setDoctorPhoneError',
	setLoading = 'setLoading',
	setNewPassword = 'setNewPassword',
	setNewPasswordError = 'setNewPasswordError',
	setConfirmPassword = 'setConfirmPassword',
	setConfirmPasswordError = 'setConfirmPasswordError',
}

type MemberActions = {
	type: MemberActionsTypes;
	payload: string | boolean;
};

export type MemberState = {
	specialty: string;
	specialtyError: string;
	doctorName: string;
	doctorNameError: string;
	doctorPhone: string;
	doctorPhoneError: string;
	password: string;
	passwordError: string;
	confirmPassword: string;
	confirmPasswordError: string;
	loading: boolean;
};

export const INITIAL_STATE: MemberState = {
	specialty: '',
	specialtyError: '',
	doctorName: '',
	doctorNameError: '',
	doctorPhone: '',
	doctorPhoneError: '',
	password: '',
	passwordError: '',
	confirmPassword: '',
	confirmPasswordError: '',
	loading: false,
};

export const reducer = (state: MemberState, action: MemberActions): MemberState => {
	const actions = {
		setSpecialty: (): MemberState => ({ ...state, specialty: action.payload as string }),
		setSpecialtyError: (): MemberState => ({
			...state,
			specialtyError: action.payload as string,
		}),
		setDoctorName: (): MemberState => ({ ...state, doctorName: action.payload as string }),
		setDoctorNameError: (): MemberState => ({
			...state,
			doctorNameError: action.payload as string,
		}),
		setDoctorPhone: (): MemberState => ({ ...state, doctorPhone: action.payload as string }),
		setDoctorPhoneError: (): MemberState => ({
			...state,
			doctorPhoneError: action.payload as string,
		}),
		setLoading: (): MemberState => ({ ...state, loading: action.payload as boolean }),
		setNewPassword: (): MemberState => ({ ...state, password: action.payload as string }),
		setNewPasswordError: (): MemberState => ({
			...state,
			passwordError: action.payload as string,
		}),
		setConfirmPassword: (): MemberState => ({
			...state,
			confirmPassword: action.payload as string,
		}),
		setConfirmPasswordError: (): MemberState => ({
			...state,
			confirmPasswordError: action.payload as string,
		}),
	};

	return actions[action.type] ? actions[action.type]() : state;
};
