import React, { useContext } from 'react';
import Clients from '../components/clients/PatientList';
import WaitingList from '../components/waitingList/WaitingList';
import clsx from 'clsx';
import useMobile from 'hooks/useMobile';
import { LoaderContext } from 'context/loader-context';
import { MobileNavigationOptions } from 'context/types';
import { AppointmentSideView } from 'components/appointment';
import { Box } from '@mui/material';
import { RelationsContext } from 'context/relations-context';
import SeederTrigger from '../components/seeder/SeederTrigger';

const Home: React.FC = () => {
	const { isMobile } = useMobile();
	const { currentViewMobile } = useContext(LoaderContext);
	const { isCalendarSideViewOpen } = useContext(RelationsContext);

	const mobileViewPrinter = () => {
		if (currentViewMobile === MobileNavigationOptions.PATIENTS) {
			return <Clients />;
		} else if (currentViewMobile === MobileNavigationOptions.WAITLIST) {
			return <WaitingList />;
		} else if (currentViewMobile === MobileNavigationOptions.CALENDAR) {
			return <AppointmentSideView />;
		}
	};

	const styles = !isMobile
		? {
				padding: '48px 20px 0px 20px',
				gap: '20px',
		  }
		: {};

	return (
		<Box display={'flex'} width={'100%'}>
			{/* <SeederTrigger />	Uncomment to seed data */}
			<div
				style={styles}
				className={clsx({
					'w-full': isMobile,
					'bg-green-content 1/2xl:px-10 grid grid-cols-2 1/2xl:gap-8 pages-container w-full flex-1':
						!isMobile,
				})}
			>
				{isMobile ? (
					mobileViewPrinter()
				) : (
					<>
						<Clients />
						<WaitingList />
					</>
				)}
			</div>
			{!isMobile && isCalendarSideViewOpen && <AppointmentSideView />}
		</Box>
	);
};

export default Home;
