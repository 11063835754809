import {
	WaitingListItemStatus,
	Insurance,
	Client,
	AffiliateTypes,
	Doctor,
	HospitalDoctor,
	Hospital,
	ClientAllergy,
	ClientHistory,
	DoctorStripeCards,
	ClientNotes,
	ClientProcedureSurgery,
	ClientClaim,
	ClaimProcess,
	DoctorMedicine,
	ClientPrescription,
	ClientPrescriptionAnalisis,
	Template,
	Medicine,
	ClientImageSection,
	ClientImage,
	Secretary,
	SecretaryHospitalDoctor,
	LabImage,
	ClientPrescriptionMedicine,
	PrescriptionTypes,
	RecentDoctorLabImagePrescription,
	TemplateMedicine,
	TemplateLabImage,
	ClientConsultation,
	ConsultationDiagnostic,
	ClientInsurance,
	ClientDisease,
	ClientProcedure,
	ClientHospitalization,
	ClientFamilyHistory,
	ClientLifeStyle,
	DoctorRecentDiagnosis,
	EagerClient,
	EagerInsurance,
	EagerHospitalDoctor,
	EagerHospital,
	ClientFreePrescription,
	Appointment,
	Teams,
	DoctorStripeCustomerSubscription,
} from 'models';
import { RecentDoctorMedicinePrescription, SexType } from 'API';
import { DiagnosticTypes } from 'models';
import {
	AISuggestion,
	PatientData,
	AIType,
	AISection,
	AIPatienDiagnostic,
	AIMedicalPlanSuggestion,
	AIDiagnosisSuggestion,
} from 'shared/ai/types';
import { Dispatch, SetStateAction } from 'react';
import { HospitalDoctorByIdIdentification } from 'customQueries/custom-query-types';
import { MemberState } from 'pages/reducer/DoctorMemberReducer';

export interface IdataForm {
	identificationData: Client['identificationData'];
	name: Client['name'];
	lastName: Client['lastName'];
	cellphoneNumber: Client['cellphoneNumber'];
	email: Client['email'];
	phoneNumber: Client['phoneNumber'];
	addressStreet: Client['addressStreet'];
	isAdult: Client['isAdult'];
	city: Client['city'];
	sector: Client['sector'];
	company: Client['company'];
	bloodType: Client['bloodType'];
	parentOrTutorCedula: Client['parentOrTutorCedula'];
	parentOrTutorID: Client['parentOrTutorID'];
	occupation: Client['occupation'];
	affiliateNumber?: ClientInsurance['affiliateNumber'];
	contractNumber?: ClientInsurance['contractNumber'];
	gender: Client['gender'];
	identificationName: Client['identificationName'];
	bornDate: Client['bornDate'];
	actualInsurance: Client['actualInsurance'];
	affiliateType?: ClientInsurance['affiliateType'];
}

export type TemplateLabImageType = TemplateLabImage;

export type WaitingListItemType = {
	id: string;
	status: WaitingListItemStatus | keyof typeof WaitingListItemStatus;
	positionNumber: number;
	clientID: string;
	clientName: string;
	clientLastName: string;
	clientHealthInsurrance: string;
};

export type SecretaryType = Secretary & SecretaryHospitalDoctor;

export interface ClaimType extends ClientClaim {
	healthInsuranceName: string;
	processes: ClaimProcess[];
}

export interface ConsultationDiagnosticType extends ConsultationDiagnostic {
	name: string;
}

export interface ClientType extends Omit<Client, 'createdAt'> {
	background?: any;
	id: string;
	gender: SexType;
	healthInsuranceId?: string;
	allowInsuranceClaim?: boolean | null | undefined;
	healthInsuranceName?: string;
	healthInsuranceAuthCode?: string;
	affiliateNumber?: ClientInsurance['affiliateNumber'];
	contractNumber?: ClientInsurance['contractNumber'];
	affiliateType?: ClientInsurance['affiliateType'];
	history?: ClientHistoryType[];
	allergies?: ClientAllergy[];
	diseases?: ClientDisease[];
	procedures?: ClientProcedure[];
	hospitalizations?: ClientHospitalization[];
	familyHistories?: ClientFamilyHistory[];
	lifeStyles?: ClientLifeStyle[];
	status?: string;
	claims?: ClaimType[];
	notes?: ClientNotes[];
	surgeries?: ClientProcedureSurgery[];
	prescriptions?: ClientPrescriptionType[];
	consultations?: ConsultationType[];
	files?: FilesType[];
	note?: string;
	profileImage?: string;
	profileImageKey?: string;
	appointments: Appointment[];
	recordNo?: string;
	_version: number;
	_lastChangedAt?: number;
}

export interface ClientHistoryType extends ClientHistory {
	healthInsuranceName: string;
	healthInsuranceAuthCode: string;
}

export interface RecentDoctorMedicinePrescriptionType extends RecentDoctorMedicinePrescription {
	name: string;
}

export interface TemplateMedicineType extends TemplateMedicine {
	name: string;
}

export interface CreateTemplateMedicine {
	medicineID: string;
	take: string;
	indication: string;
	duration: string;
	description: string;
}

export interface CreateTemplateLabImage {
	name: string;
	description: string;
}

export interface DoctorTemplateType extends Template {
	list: TemplateMedicineType[] | TemplateLabImage[];
}

export interface DoctorType extends Doctor {
	stripeCustomerID: string;
	stripeSubscriptionID: string;
	trialsEndsAt: string;
	nextPaymentDate: string;
	defaultCard: string;
	stripeCards: DoctorStripeCards[];
	confirmPassword: string;
	medicines: MedicineType[];
	labImages: LabImage[];
	templates: DoctorTemplateType[];
	recentLabImages: RecentDoctorLabImagePrescription[];
	recentMedicines: RecentDoctorMedicinePrescriptionType[];
	cancelAtPeriodEnd: boolean;
	currentPlanID: number;
	hospitalID?: string;
	profileImage?: string;
	hasTrial?: boolean;
	memberId?: string;
}

export interface FilesType extends ClientImageSection {
	images: ClientImageType[];
}

export interface ClientImageType extends ClientImage {
	url: Blob;
}

export interface PlanType {
	id: number;
	stripePlanKey: string;
	title: string;
	subtitle: string;
	price: string;
	refID: number;
}

export enum ResponseStatusType {
	SUCCESS = 'SUCCESS',
	ERROR = 'ERROR',
	WARNING = 'WARNING',
	INFO = 'INFO',
	INACTIVE = 'INACTIVE',
	NOT_AUTHORIZED = 'NOT_AUTHORIZED',
}

export enum SubscriptionTypes {
	ANNUAL = 'Anual',
	MONTHLY = 'Mes por mes',
}

export enum UserRoles {
	Owner = 'Owner',
	Member = 'Member',
	Secretary = 'Secretary',
}

export interface IMemberDropdown {
	doctorID: string;
	doctorName: string;
	lastWaitingListID: string;
}

export enum PaymentIntentStatus {
	RequiresPaymentMethod = 'requires_payment_method',
	RequiresConfirmation = 'requires_confirmation',
	RequiresAction = 'requires_action',
	Processing = 'processing',
	RequiresCapture = 'requires_capture',
	Succeeded = 'succeeded',
	Canceled = 'canceled',
}

export interface UserContextProps {
	handleDoctorLogin: (user: any, userAttributes: any, hideSetUser?: boolean) => Promise<any>;
	fetchPlans: () => Promise<void>;
	plans: PlanType[];
	userAuthenticated: any | null;
	logIn: (
		user: string,
		password: string,
		setErrorLoginText: Dispatch<React.SetStateAction<string>>
	) => Promise<ResponseStatusType | void>;
	signIn: (user: string, password: string) => any;
	logOut: () => Promise<void>;
	updateDateForPayment: () => Promise<void>;
	forgotPassword: (username: string) => Promise<any>;
	changePassword: (username: string, code: string, newPassword: string) => Promise<any>;
	confirmSignUp: (username: string, confirmationCode: string) => Promise<void>;
	resendConfirmationCode: (username: string) => Promise<void>;
	upsertSecretary: (secretary: SecretaryType) => Promise<SecretaryType | null>;
	completeSecretaryRegistration: (fullName: string, password: string) => Promise<void>;
	editSecretary: (secretary: SecretaryType) => Promise<SecretaryType | null>;
	completeDoctorRegistration: (doctor: DoctorType) => Promise<any[] | undefined>;
	checkIsLogged: (redirectTo?: string) => Promise<void>;
	getSecretaryByEmail: (name: string) => Promise<SecretaryType[]>;
	completeDoctorMemberRegistration: (doctor: MemberState) => Promise<{
		user: any;
		status: ResponseStatusType;
	}>;
	getDoctorByEmail: (name: string) => Promise<DoctorType[]>;
	userRole: UserRoles;
	doctorMembers: IMemberDropdown[];
	getDoctorMembersByDropDown: (doctorID: string) => Promise<IMemberDropdown[]>;
	doctorMemberSelectedHandler: (doctor: any) => void;
	doctorMemberSelected: any;
}

export type MOVE = 'next' | 'prev';
export interface ClientContextProps {
	clients: ClientType[] | null;
	saveClient: (
		client: ClientType,
		userImage?: string,
		createRelationships?: boolean
	) => Promise<Client>;
	createClientWithExampleData: (doctor: DoctorType) => Promise<Client>;
	updateClientInfo: (oldClient: Client, client: Client) => Promise<Client>;
	createClientAppointment: (
		appointment: Partial<Appointment> & { email: string; clientVersion: number },
		clientID: string
	) => Promise<void>;
	updateClientAppointment: (
		appointment: Partial<Appointment> & {
			email: string;
			_version: number;
			clientVersion: number;
		}
	) => Promise<void>;
	deleteClientAppointment: (
		appointment: Partial<Appointment> & { _version: number }
	) => Promise<void>;
	setClients: React.Dispatch<React.SetStateAction<ClientType[] | null>>;
	editingClient: boolean;
	setEditingClient: React.Dispatch<React.SetStateAction<boolean>>;
	modalClientState: boolean;
	setModalClientState: React.Dispatch<React.SetStateAction<boolean>>;
	prescriptionToAdd: PrescriptionType | PrescriptionType[] | null;
	setPrescriptionToAdd: (value: PrescriptionType | PrescriptionType[] | null) => void;
	createClientConsultation: (consultation: ConsultationType) => Promise<void>;
	updateClientConsultation: (
		consultationID: string,
		consultationUpdated: ConsultationType
	) => Promise<void>;
	deleteClientConsultation: (consultation: ConsultationType) => Promise<void>;
	createClientPrescription: (prescription: PrescriptionType) => Promise<void>;
	deleteClientPrescription: (prescription: ClientPrescriptionType) => Promise<void>;
	fetchClients: (filterText?: string, paginated?: boolean) => Promise<void>;
	fetchPaginatedClients: (page: number, limit: number, filterText?: string) => Promise<void>;
	fetchPaginatedClientsSync: (page: number, limit: number, filterText?: string) => Promise<void>;
	paginatedClients: IPaginatedClients;
	createInsurance: (dataForm: IdataForm, clientID: Insurance['id']) => Promise<void>;
	createClient: (clientData: IdataForm, userImage?: string) => Promise<void>;
	deleteClient: (id: string) => Promise<void>;
	updateClient: (dataForm: IdataForm, userImage?: string) => Promise<void>;
	fetchClient: (id: string) => Promise<void>;
	fetchPatientByIdentificationData: (identificationData: string) => Promise<Client>;
	fetchPatientById: (id: string) => Promise<Client | undefined>;
	selectedClient: ClientType | null;
	updateClientBloodPressure: (bloodPressure: string) => Promise<void>;
	updateClientHistory: (original: ClientHistoryType, updated: ClientHistory) => Promise<void>;
	deleteClientHistory: (history: ClientHistory) => Promise<void>;
	setSelectedClient: React.Dispatch<React.SetStateAction<ClientType | null>>;
	addClientHistory: (history: ClientHistory) => Promise<void>;
	updateClientHeartBeat: (heartBeat: string) => Promise<void>;
	updateClientWeight: (weight: string) => Promise<void>;
	updateClientHeight: (height: string) => Promise<void>;
	updateHospitalDoctorClientStatus: (status: string) => Promise<void>;
	deleteClaim: (id: string) => Promise<void>;
	createClaim: (claim: any, processes: ClaimProcess[]) => Promise<void>;
	updateClientDoctorNote: (clientID: string, note: string) => Promise<void>;
	updateClientNote: (original: ClientNotes, updated: ClientNotes) => Promise<void>;
	deleteClientNote: (note: ClientNotes) => Promise<void>;
	addClientNote: (newNote: ClientNotes) => Promise<void>;
	updateClientProcSurg: (
		original: ClientProcedureSurgery,
		updated: ClientProcedureSurgery
	) => Promise<void>;
	addClientProcSurg: (newProcSurg: ClientProcedureSurgery) => Promise<void>;
	deleteClientProcSurg: (surgerie: ClientProcedureSurgery) => Promise<void>;
	addImage: (
		date: string,
		filename: string,
		pathFile?: string
	) => Promise<{ image: ClientImage; imageSectionID: string }>;
	updateImageDateSection: (id: string, descripion: string) => Promise<void>;
	updateImage: (
		id: string,
		description: string,
		pathFile?: string,
		sectionId?: string
	) => Promise<void>;
	deleteImageDateSection: (id: string) => Promise<void>;
	deleteImage: (img: ClientImageType) => Promise<void>;
	getClientData: (clientId: string) => Promise<ClientType>;
	getClientDataToEdit: (clientId: string) => Promise<ClientType>;
	updateClientWithOutChanges: (clientId: string) => Promise<void>;
	fetchPaginatedClientsAPI: (
		page: number,
		limit: number,
		filterText?: string
	) => Promise<IPaginatedClients>;
	setPaginatedClients: Dispatch<SetStateAction<IPaginatedClients>>;
	imageLoading: boolean;
	setImageLoading: Dispatch<SetStateAction<boolean>>;
	fetchPaginatedClientsByName: (filterText: string) => Promise<IClientFilter[]>;
	getMainPageClientData: (clientId: string) => Promise<any>;
	getConsultationDataById: (consultationId: string) => Promise<any>;
	loadingConsultations: boolean;
	getConsultationsData: () => Promise<any>;
	loadingPrescriptions: boolean;
	getPrescriptionsData: () => Promise<any>;
	getClientHistoryData: () => Promise<any>;
	loadingClientHistory: boolean;
	getClientClaimsData: () => Promise<any>;
	clientClaims: any[];
	clientConsultations: any[];
	clearClientInformation: () => void;
	clientPrescriptions: any[];
	loadingClientClaims: boolean;
	clientHistory: any;
	setClientHistory: Dispatch<SetStateAction<any>>;
	clientFiles: any[];
	getClientFilesData: () => Promise<any>;
	page: number;
	setPage: Dispatch<SetStateAction<number>>;
	rowsPerPage: number;
	clientsLoading: boolean;
	setClientsLoading: React.Dispatch<React.SetStateAction<boolean>>;
	checkIfClientExistInHospitalDoctor: (
		identificationData: string
	) => Promise<HospitalDoctorByIdIdentification | undefined>;
	nextCursor: string;
	setLoadClientEditModal: React.Dispatch<React.SetStateAction<boolean>>;
	loadClientEditModal: boolean;
	onboardingData: any;
	setOnboardingData: React.Dispatch<React.SetStateAction<any>>;
	getTeamMembersIds: () => Promise<string[]>;
	setSelectedMember: React.Dispatch<React.SetStateAction<any>>;
	selectedMember: any;
	resetPatientData: () => void;
	patientSeederFunction: (
		hospitalDoctorId: string,
		patientData: any,
		doctorActualPatients: any
	) => Promise<Client>;
}

export interface AIContextProps {
	aiSuggestions: AISuggestion | AIMedicalPlanSuggestion | AIDiagnosisSuggestion | null;
	setIAPrompt: (
		prompt: PatientData | AIPatienDiagnostic | AIDiagnosisSuggestion | null,
		cb?: Function
	) => Promise<void>;
	clearIASuggestions: () => Promise<void>;
	suggestionClickHandler: (type: AIType, name: string, cb: Function) => Promise<void>;
	setAiSection: (section: AISection | null) => Promise<void>;
	aiSection: AISection;
}

export type MedicineType = DoctorMedicine &
	Medicine & {
		medicineID: string;
		take?: string | null;
		indication?: string | null;
		duration?: string | null;
		description?: string | null;
		doctorMedicineId?: string;
	};

export interface PrescriptionAnalisisType extends ClientPrescriptionAnalisis {
	description?: string;
	labImageID?: string;
}

export interface ConsultationType extends ClientConsultation {
	prescription: PrescriptionType;
	diagnostics: ConsultationDiagnostic[];
	diffDiagnostics: ConsultationDiagnostic[];
}

export interface ConsultationDiagnosticType extends ConsultationDiagnostic {
	type?: DiagnosticTypes;
}

export interface PrescriptionType extends ClientPrescription {
	freePrescription: any;
	creationDate: string;
	medicines?: MedicineType[] | ClientPrescriptionMedicine[];
	analisis?: PrescriptionAnalisisType[] | ClientPrescriptionAnalisis[];
	customPrescription?: {
		description?: string;
	}[];
}

export interface TemplateListyType {
	medicines?: MedicineType[] | ClientPrescriptionMedicine[];
	analisis?: PrescriptionAnalisisType[] | ClientPrescriptionAnalisis[];
}

export interface ClientPrescriptionType extends ClientPrescription {
	type: PrescriptionTypes;
	title?: string;
	date?: string;
	list?: ClientPrescriptionMedicine[] | ClientPrescriptionAnalisis[] | ClientFreePrescription[];
}

export interface IInsuranceData {
	clientID: string;
	name: string | undefined;
	contractNumber: string | undefined;
	affiliateNumber: string | undefined;
	affiliateType:
		| AffiliateTypes
		| 'PRINCIPAL'
		| 'TITULAR'
		| 'DEPENDIENTE'
		| 'PARENTESCO'
		| undefined;
}

export interface IDoctorContextProps {
	doctors: Doctor[];
	createDoctor: () => Promise<void>;
	fetchDoctors: () => Promise<void>;
	fetchDoctorDiagnosis: () => Promise<DoctorRecentDiagnosis[]>;
	deleteDoctor: (id: string) => Promise<void>;
	updateDoctor: (id: string) => Promise<void>;
	updateActualDoctor: (id: string) => Promise<void>;
	shouldUpdateDoctorPayments: (state: boolean) => void;
	updateDoctorPayments: boolean;
}

export interface AlertType {
	message: string | null;
	severity: 'error' | 'info' | 'success' | 'warning';
}

export type Query_Medicine = {
	id: string;
	name: string;
	_version: number;
	_deleted: boolean;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
	doctorMedicineId?: string;
	doctorID?: string;
};

export type Query_LabImage = {
	id: string;
	name: string;
	_version: number;
	_deleted: boolean;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
	doctorLabImageId?: string;
	doctorID?: string;
};

export enum TeamStatus {
	ACTIVE,
	INACTIVE,
	PENDING,
}

export interface IDoctorStripeCustomerSubscription {
	id: string;
	doctorID: string;
	currentPlanID: number;
	stripeCustomerID: string;
	stripeSubscriptionID: string;
	defaultCard: string;
	nextPaymentDate: string;
	cancelAtPeriodEnd: boolean;
	trialsEndsAt: string | null;
	_version: number;
	_deleted: boolean | null;
	_lastChangedAt: number;
	createdAt: string;
	updatedAt: string;
	__typename: string;
}

export type SubscriptionResponse = {
	id: string;
	object: string;
	application: string | null;
	application_fee_percent: number | null;
	automatic_tax: {
		enabled: boolean;
		liability: string | null;
	};
	billing_cycle_anchor: number;
	billing_cycle_anchor_config: any | null;
	billing_thresholds: any | null;
	cancel_at: number | null;
	cancel_at_period_end: boolean;
	canceled_at: number | null;
	cancellation_details: {
		comment: string | null;
		feedback: string | null;
		reason: string | null;
	};
	collection_method: string;
	created: number;
	currency: string;
	current_period_end: number;
	current_period_start: number;
	customer: string;
	days_until_due: number | null;
	default_payment_method: string | null;
	default_source: string | null;
	default_tax_rates: Array<any>;
	description: string | null;
	discount: any | null;
	discounts: Array<any>;
	ended_at: number | null;
	invoice_settings: {
		account_tax_ids: any | null;
		issuer: {
			type: string;
		};
	};
	items: {
		object: string;
		data: Array<{
			id: string;
			object: string;
			billing_thresholds: any | null;
			created: number;
			discounts: Array<any>;
			metadata: Record<string, any>;
			plan: {
				id: string;
				object: string;
				active: boolean;
				aggregate_usage: any | null;
				amount: number;
				amount_decimal: string;
				billing_scheme: string;
				created: number;
				currency: string;
				interval: string;
				interval_count: number;
				livemode: boolean;
				metadata: Record<string, any>;
				meter: any | null;
				nickname: string | null;
				product: string;
				tiers_mode: any | null;
				transform_usage: any | null;
				trial_period_days: number | null;
				usage_type: string;
			};
			price: {
				id: string;
				object: string;
				active: boolean;
				billing_scheme: string;
				created: number;
				currency: string;
				custom_unit_amount: any | null;
				livemode: boolean;
				lookup_key: string | null;
				metadata: Record<string, any>;
				nickname: string | null;
				product: string;
				recurring: {
					aggregate_usage: any | null;
					interval: string;
					interval_count: number;
					meter: any | null;
					trial_period_days: number | null;
					usage_type: string;
				};
				tax_behavior: string;
				tiers_mode: any | null;
				transform_quantity: any | null;
				type: string;
				unit_amount: number;
				unit_amount_decimal: string;
			};
			quantity: number;
			subscription: string;
			tax_rates: Array<any>;
		}>;
		has_more: boolean;
		total_count: number;
		url: string;
	};
	latest_invoice: string;
	livemode: boolean;
	metadata: Record<string, any>;
	next_pending_invoice_item_invoice: any | null;
	on_behalf_of: any | null;
	pause_collection: any | null;
	payment_settings: {
		payment_method_options: any | null;
		payment_method_types: any | null;
		save_default_payment_method: string;
	};
	pending_invoice_item_interval: any | null;
	pending_setup_intent: any | null;
	pending_update: any | null;
	plan: {
		id: string;
		object: string;
		active: boolean;
		aggregate_usage: any | null;
		amount: number;
		amount_decimal: string;
		billing_scheme: string;
		created: number;
		currency: string;
		interval: string;
		interval_count: number;
		livemode: boolean;
		metadata: Record<string, any>;
		meter: any | null;
		nickname: string | null;
		product: string;
		tiers_mode: any | null;
		transform_usage: any | null;
		trial_period_days: number | null;
		usage_type: string;
	};
	quantity: number;
	schedule: any | null;
	start_date: number;
	status: string;
	test_clock: any | null;
	transfer_data: any | null;
	trial_end: number;
	trial_settings: {
		end_behavior: {
			missing_payment_method: string;
		};
	};
	trial_start: number;
};

export interface RelationsContextProps {
	createHospitalDoctor: () => Promise<void>;
	updateSync: () => Promise<void>;
	setActualHospitalDoctor: React.Dispatch<React.SetStateAction<EagerHospitalDoctor | null>>;
	actualHospitalDoctor: HospitalDoctor | null;
	isCalendarSideViewOpen: boolean;
	createHospitalDoctorClients: (
		clientId: string,
		hospitalDoctorID: string,
		lastHealthInsurranceID: string,
		recordNo: string
	) => Promise<void>;
	updateHospitalDoctor: (hospitalDoctorID: string, hospitalID: string) => Promise<void>;
	updateDoctorLabImage: (labImageID: string) => Promise<void>;
	addLogoToHospitalDoctor: (logo: string) => Promise<void>;
	verifyClientExistInHospitalDoctor: (client: ClientType) => Promise<void>;
	setActualHospital: React.Dispatch<React.SetStateAction<EagerHospital | null>>;
	setActualDoctor: React.Dispatch<React.SetStateAction<DoctorType | null>>;
	actualSecretary: SecretaryType | null;
	setActualSecretary: React.Dispatch<React.SetStateAction<SecretaryType | null>>;
	actualDoctor: DoctorType | null;
	actualHospital: Hospital | null;
	medicines: MedicineType[];
	actualClient: Client | null;
	setActualClient: React.Dispatch<React.SetStateAction<EagerClient | null>>;
	actualInsurance: Insurance;
	updateDoctorMedicine: (id: string, isDeleted: boolean) => Promise<void>;
	updateActualClientInsurance: (clientID: string) => Promise<void>;
	labImages: LabImage[];
	fetchLabImages: (page?: number, limit?: number) => Promise<void>;
	fetchDoctorPrescriptionLabImages: (page?: number, limit?: number) => Promise<void>;
	fetchDoctorPrescriptionMedicines: (page?: number, limit?: number) => Promise<void>;
	fetchDoctorPrescriptionFreePrescriptions: (page?: number, limit?: number) => Promise<void>;
	fetchDoctorLabImages: (page?: number, limit?: number) => Promise<void>;
	createDoctorLabImage: (id: string) => Promise<void>;
	createLabImage: (labImageName: string, description?: string) => Promise<LabImage | undefined>;
	editLabImage: (labImage: LabImage) => Promise<void>;
	deleteLabImage: (labImage: LabImage) => Promise<void>;
	setActualInsurance: React.Dispatch<React.SetStateAction<EagerInsurance | undefined>>;
	setAlertMessage: React.Dispatch<React.SetStateAction<AlertType | null>>;
	alertMessage: AlertType | null;
	updateHospitalDoctorClientLastInsuranceId: (
		clientId: string,
		insuranceId: string
	) => Promise<void>;
	fetchDoctorTemplates: () => Promise<void>;
	createDoctorTemplate: (
		templateName: string,
		templateType: PrescriptionTypes,
		list: CreateTemplateMedicine[] | CreateTemplateLabImage[]
	) => Promise<void>;
	updateDoctorInfo: (doctorInfo: Doctor) => Promise<void>;
	createDoctorStripeCustomer: (
		customerID: string,
		subscriptionID: string,
		nextPaymentDate: number
	) => Promise<void>;
	addCardToCustomer: (paymentMethodID: string, last4: string, brand: string) => Promise<void>;
	changeDefaultCard: (paymentMethodID: string) => Promise<void>;
	deleteDoctorCard: (paymentMethodID: string) => Promise<void>;
	updatePaymentDate: () => Promise<void>;
	updateSubscription: (
		subscriptionID: string,
		currentPlanID: number,
		cancelAtPeriodEnd: boolean,
		nextPaymentDate?: number,
		trialsEndsAt?: string,
		doctorID?: string
	) => Promise<void>;
	updateSubscriptionVerifyContext: (
		subscriptionID: string,
		currentPlanID: number,
		cancelAtPeriodEnd: boolean,
		nextPaymentDate: string
	) => Promise<void>;
	createMedicine: (medicineName: string) => Promise<MedicineType>;
	fetchMedicines: (page?: number, limit?: number) => Promise<void>;
	fetchDoctorMedicines: (page?: number, limit?: number) => Promise<void>;
	createDoctorMedicine: (
		medicineId: string,
		isMedicineForm?: boolean
	) => Promise<Medicine | undefined>;
	deleteMedicine: (medicine: MedicineType) => Promise<void>;
	editMedicine: (medicineName: string, medicine: MedicineType) => Promise<void>;
	fetchMedicinesToSuggest: (name: string) => Promise<MedicineType[]>;
	fetchDoctorHospitals: () => Promise<void>;
	fetchDoctorHospitalSecretarys: () => Promise<SecretaryType[]>;
	deleteSecretaryFromDoctor: (secretary: SecretaryType) => Promise<void>;
	updateHospital: (hospital: Hospital) => Promise<void>;
	fetchDoctorAppointments: (
		filter: string | { start: string; end: string },
		doctorId: string | null
	) => Promise<Appointment[]>;
	setIsCalendarSideViewOpen: Dispatch<React.SetStateAction<boolean>>;
	teamMembers: Teams[];
	getTeamMembers: () => Promise<void>;
	addDoctorMember: (email: string) => Promise<void>;
	loadingMembers: boolean;
	disableTeamMember: (id: string) => Promise<void>;
	ownerHospitalDoctorId: string;
	setOwnerHospitalDoctorId: Dispatch<SetStateAction<string>>;
	getActualSubProfile: () => Promise<DoctorStripeCustomerSubscription>;
	handleError: (error: any | string, showScreen?: boolean) => Promise<void>;
	updateDoctorStripeSubscription: (subscriptionID: string) => Promise<void>;
	getCustomHospitalDoctorFilters: () => Promise<any>;
	getCustomHospitalDoctorFiltersWithOutCurrentUser: () => Promise<any>;
}

export enum MobileNavigationOptions {
	PATIENTS = 'patients',
	WAITLIST = 'wait-list',
	CALENDAR = 'calendar',
}
export interface LoaderContextProps {
	showSpinner: (loading: boolean) => Promise<void>;
	currentViewMobile: MobileNavigationOptions;
	setCurrentViewMobile: React.Dispatch<React.SetStateAction<MobileNavigationOptions | null>>;
}

// Enums
export enum HistorySidebarOptions {
	RESUMEN,
	ALERGIES,
	DISEASES,
	PROCEDURES,
	PREVIOUS_HOSPITALIZATION,
	FAMILY_HISTORY,
	LIFE_STYLE,
	SMOKING_DRUG,
	VACCINES_IMMUNIZATION,
	NUTRITION,
}

export enum SuggestionType {
	LAB_IMAGES = 'LAB_IMAGES',
	MEDICINES = 'MEDICINES',
}

export enum PAGE_ACTIONS {
	MOVE_TO_NEXT_PAGE,
	MOVE_TO_PREVIOUS_PAGE,
}

interface TokenPagination {
	token: string;
	page: number;
}

export interface IPaginatedClients {
	clients: ClientType[];
	pageCount: number;
	pageSize: number;
	pageIndex: number;
	token?: TokenPagination[];
	totalItemCount: number;
	nextToken?: string;
	prevToken?: string;
}

export interface IClientFilter {
	id: string;
	name: string;
	email: string;
	recordNo: string;
	_version: number;
}

export interface StripeContextProps {}
