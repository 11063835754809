import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import { NoItemsIcon } from '../../../svg';
import { NoItemButton, EmptyDescriptionTypography } from '../../../../shared/style';

interface NoItemProps {
	btnLabel?: string;
	onClickHandler: () => void;
	height?: string;
}

const ListEmptyState: React.FC<NoItemProps> = ({ onClickHandler, btnLabel, height }) => (
	<div
		className="flex flex-col justify-center items-center h-full"
		style={{ height: height || '100dvh' }}
	>
		<div>
			<NoItemsIcon />
		</div>
		<div>
			<EmptyDescriptionTypography>No hay datos que mostrar</EmptyDescriptionTypography>
			{btnLabel && (
				<NoItemButton
					variant="contained"
					color="primary"
					size="medium"
					onClick={() => onClickHandler()}
					startIcon={<AddIcon fontSize="large" />}
				>
					{btnLabel}
				</NoItemButton>
			)}
		</div>
	</div>
);

export default ListEmptyState;
