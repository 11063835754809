import { IconButton, Menu, MenuItem } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';

import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';

const HelpButton = () => {
	const history = useHistory<{ prevPath: '' }>();
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const goTo = (path: string) => {
		history.push(path);
		handleClose();
	};

	return (
		<>
			<IconButton
				aria-label="more"
				aria-controls="long-menu"
				aria-haspopup="true"
				onClick={handleClick}
			>
				<HelpOutlineOutlinedIcon
					style={{
						color: 'rgba(0, 0, 0, 0.54)',
					}}
				/>
			</IconButton>
			<Menu
				id="long-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem value="chat_en_linea" onClick={() => goTo('/Chat')}>
					<em>Chat en linea</em>
				</MenuItem>
			</Menu>
		</>
	);
};

export default HelpButton;
