import { useEffect, useState, createContext, FC, useContext } from 'react';
import {
	IDoctorContextProps,
	RelationsContextProps,
	StripeContextProps,
	SubscriptionTypes,
	UserContextProps,
	UserRoles,
} from './types';
import {
	DeleteSubscription,
	createPaymentIntent,
	createRefund,
	createSubscription,
	getSubscriptionStatus,
} from '../adapters/stripe';
import { RelationsContext } from './relations-context';
import { StripePaymentStatus } from 'shared/constants';
import { formatPrice, getTimeLeft } from 'shared/paymentUtils';
import ModalRenewSubscription from 'components/modalRenewSubscription/RenewPaymentSubscriptionModal';
import ModalPaymentSubscription from 'components/modalPaymentSubscription/PaymentSubscriptionModal';
import { UserContext } from './user-context';
import moment from 'moment';
import useLocalStorage from 'hooks/useLocalStorage';
import AlertPayment from 'components/modalRenewSubscription/Alert';
import useStripeConfiguration from 'hooks/useStripeConfiguration';
import { DoctorsContext } from './doctor-context';
import { useHistory } from 'react-router-dom';

export const StripeContext = createContext<Partial<StripeContextProps>>({});

type PaymentMethodType = 'M' | 'y';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const REPEAT_EACH_MINUTE = 60000;
const REPEAT_EACH_THREE_HOURS = 10800000;

const StripeProvider: FC = ({ children }) => {
	const history = useHistory();
	const [accountStatus, setAccountStatus] = useState<StripePaymentStatus | null | string>(null);
	const [pendingPayment, setPendingPayment] = useState<boolean>(false);
	const { shouldUpdateDoctorPayments, updateDoctorPayments } = useContext(
		DoctorsContext
	) as IDoctorContextProps;
	const [remainingDaysInSeconds, setRemainingDaysInSeconds] = useState<number>(0);
	const [openPayment, setOpenPayment] = useState<boolean>(false);
	const [selectedPlan, setSelectedPlan] = useState<number>(0); // [1, 2, 3]
	const [openRenew, setOpenRenew] = useState<boolean>(true);
	const [currentUser, setCurrentUser] = useState<string>('');
	const [updateSubscriptionStatus, setUpdateSubscriptionStatus] = useState<boolean>(false);
	const {
		actualDoctor,
		updateSubscriptionVerifyContext,
		setAlertMessage,
		updateDoctorStripeSubscription,
	} = useContext(RelationsContext) as RelationsContextProps;
	const { logOut, userRole } = useContext(UserContext) as UserContextProps;
	const {
		plans,
		paymentCards,
		actualSubs,
		getCustomerDefaultPayment,
		fetchCreditCards,
		fetchSubscription,
	} = useStripeConfiguration();
	const [storedValue, setValue] = useLocalStorage('accountPaymentStatus', {
		status: accountStatus,
		timeLeft: remainingDaysInSeconds,
		pendingPayment: pendingPayment,
		currentUser: currentUser,
	});

	const scheduleEvent = (eventName: string, callback: Function) => {
		const activeTimeoutKey = `active_event_${eventName}`;
		const activeTimeout = localStorage.getItem(activeTimeoutKey);
		if (activeTimeout) {
			clearTimeout(Number(activeTimeout));
		}

		const timeout = setTimeout(() => {
			callback();
			localStorage.removeItem(activeTimeoutKey);
		}, REPEAT_EACH_THREE_HOURS);
		localStorage.setItem(activeTimeoutKey, JSON.stringify(timeout));
	};

	const fetchSubscriptionStatus = async () => {
		let response = null;
		let timeLeft = 0;
		try {
			const getCurrentLoggedUser = localStorage.getItem('userName');

			if (
				remainingDaysInSeconds &&
				remainingDaysInSeconds > 0 &&
				!pendingPayment &&
				accountStatus &&
				getCurrentLoggedUser === currentUser
			) {
				response = {
					status: accountStatus,
					timeLeft: remainingDaysInSeconds,
					pendingPayment: pendingPayment,
				};
			} else {
				response = await getSubscriptionStatus(actualDoctor!.stripeSubscriptionID!);
				setAccountStatus(response.status);
			}
		} catch (error) {
			response = {
				status: accountStatus,
				timeLeft: remainingDaysInSeconds,
				pendingPayment: pendingPayment,
			};
		}

		if (response.status === StripePaymentStatus.Trialing) {
			timeLeft = getTimeLeft(actualDoctor?.trialsEndsAt!);
		} else {
			timeLeft = getTimeLeft(actualDoctor?.nextPaymentDate!);
		}
		const isPendingPayment = timeLeft <= 0 || response.status === StripePaymentStatus.PastDue;
		setPendingPayment(isPendingPayment);
		const planPriceAsNumber = Number(actualSubs.plan.amount / 100) * actualSubs.quantity;
		setValue({
			status: response.status,
			timeLeft,
			pendingPayment: isPendingPayment,
			currentUser: actualDoctor?.email,
			incomingInvoiceAmount: planPriceAsNumber,
			numberOfMembers: actualSubs.quantity,
			currentPlanID: actualDoctor?.currentPlanID,
		});

		scheduleEvent('account_payment', fetchSubscriptionStatus);
	};

	const handlerNextPaymentStep = () => {
		if (!isNaN(selectedPlan) && selectedPlan > 0) {
			setOpenPayment(true);
			setOpenRenew(false);
		}
	};

	const handleSelectPlan = (value: number) => {
		setSelectedPlan(value);
	};

	const handlerLogOut = async () => {
		await logOut();
		setOpenPayment(false);
		setOpenRenew(false);
	};

	const handlerUpdateNextPaymentCheck = async (subscription: any, updatedPlan: number) => {
		const currentPlan = plans.find((p) => p.id === selectedPlan);
		if (!currentPlan) {
			return;
		}
		const payment_type: PaymentMethodType =
			currentPlan.title === SubscriptionTypes.MONTHLY ? 'M' : 'y';
		const nextPaymentDate = moment().add(1, payment_type).format('L');
		const timeLeft = getTimeLeft(nextPaymentDate);

		await updateSubscriptionVerifyContext(subscription.id, updatedPlan, false, nextPaymentDate);

		setValue({
			status: subscription.status,
			timeLeft,
			pendingPayment: false,
			currentUser: actualDoctor?.email,
		});

		setRemainingDaysInSeconds(timeLeft);
		setPendingPayment(false);
		setAccountStatus(subscription.status);
	};

	const handlerCancelAndCreateNewSubscription = async () => {
		const currentPlan = plans.find((p) => p.id === selectedPlan);
		if (!currentPlan || actualDoctor === null) {
			return;
		}
		const keyPlan = currentPlan.stripePlanKey;
		const paymentMethodId = await getCustomerDefaultPayment();
		const currentSubscription = actualDoctor?.stripeSubscriptionID;

		const newSubscriptionData = {
			customer: actualDoctor?.stripeCustomerID,
			items: [{ price: keyPlan, quantity: actualSubs.quantity }],
			default_payment_method: paymentMethodId,
			payment_behavior: 'error_if_incomplete',
		};

		const newSubResponse = await createSubscription(newSubscriptionData);

		await DeleteSubscription(
			currentSubscription as string,
			'Cambio de plan por vencimiento de pago desde la plataforma'
		);

		if (newSubResponse.status !== StripePaymentStatus.Active) {
			setAlertMessage({
				severity: 'error',
				message: 'Ha ocurrido un error al actualizar tu suscripción',
			});
			return;
		}

		return newSubResponse;
	};

	const handlePayment = async () => {
		try {
			setUpdateSubscriptionStatus(true);
			const currentPlan = plans.find((p) => p.id === selectedPlan);
			if (!currentPlan) {
				return;
			}
			const newSubResponse = await handlerCancelAndCreateNewSubscription();
			if (!newSubResponse) {
				setUpdateSubscriptionStatus(false);
				return;
			}

			await updateDoctorStripeSubscription(newSubResponse.id);

			await handlerUpdateNextPaymentCheck(newSubResponse, selectedPlan);

			await fetchSubscription();

			setAlertMessage({
				severity: 'success',
				message: 'Se ha actualizado tu suscripción correctamente',
			});

			setOpenPayment(false);
			setUpdateSubscriptionStatus(false);
			return;
		} catch (e) {
			setAlertMessage({
				severity: 'error',
				message: 'Ha ocurrido un error al actualizar tu suscripción',
			});
			setUpdateSubscriptionStatus(false);
		}
	};

	const enablePaymentModal = async () => {
		if (
			(actualSubs && actualSubs?.status === StripePaymentStatus.Trialing) ||
			actualSubs?.status === StripePaymentStatus.Active ||
			actualSubs?.status === StripePaymentStatus.Succeeded
		) {
			await handlerUpdateNextPaymentCheck(actualSubs, actualDoctor?.currentPlanID as number);
		} else {
			if (userRole === UserRoles.Member) {
				logOut();
				return;
			}
			setOpenRenew(true);
		}
	};

	useEffect(() => {
		if (storedValue) {
			setAccountStatus(storedValue.status);
			setPendingPayment(storedValue.pendingPayment);
			setRemainingDaysInSeconds(storedValue.timeLeft);
			setCurrentUser(storedValue.currentUser);
		}
	}, []);

	useEffect(() => {
		const isAccountInformationStored = window.localStorage.getItem('accountPaymentStatus');
		if (actualDoctor && !isAccountInformationStored && actualSubs) {
			fetchSubscriptionStatus();
		}
	}, [actualDoctor?.stripeSubscriptionID, actualSubs]);

	useEffect(() => {
		if (!pendingPayment && remainingDaysInSeconds > 0 && accountStatus && actualDoctor) {
			scheduleEvent('account_payment', fetchSubscriptionStatus);
		}
	}, [remainingDaysInSeconds, pendingPayment, accountStatus, actualDoctor?.email]);

	useEffect(() => {
		if (pendingPayment && !openRenew && !openPayment && actualDoctor && actualSubs) {
			enablePaymentModal();
		}
		if ((!pendingPayment && openPayment) || !actualDoctor) {
			setOpenRenew(false);
		}
	}, [pendingPayment, actualDoctor, actualSubs]);

	useEffect(() => {
		if (!actualSubs) {
			fetchSubscription();
		}
	}, [actualSubs]);

	useEffect(() => {
		if (updateDoctorPayments) {
			fetchCreditCards();
			shouldUpdateDoctorPayments(false);
		}
	}, [updateDoctorPayments]);
	const stripeProviderValue = {};
	const disabledBtn = paymentCards.data && paymentCards.data.length === 0;

	if (userRole !== UserRoles.Owner && (openRenew || openPayment)) {
		history.push('/blocked-account');
	}
	const currentPlanAmount = (plans?.find((p) => p.id === selectedPlan)?.price ?? 0) as number;
	const numberOfMembers = actualSubs?.quantity ?? 1;
	const incomingInvoiceAmount = `${(currentPlanAmount / 100) * numberOfMembers}`;
	return (
		<StripeContext.Provider value={stripeProviderValue}>
			{openPayment && (
				<ModalPaymentSubscription
					open={openPayment}
					selectedPlan={selectedPlan}
					price={formatPrice(incomingInvoiceAmount)}
					handleClose={handlerLogOut}
					handlePay={handlePayment}
					members={numberOfMembers}
					disabled={disabledBtn}
					updateSubscriptionStatus={updateSubscriptionStatus}
				/>
			)}
			{openRenew && (
				<ModalRenewSubscription
					open={openRenew}
					disableBackdropClick={true}
					handleClose={handlerLogOut}
					handleSelectPlan={handleSelectPlan}
					selectedPlan={selectedPlan}
					handleNextStep={handlerNextPaymentStep}
				/>
			)}
			{children}
			{openRenew || openPayment ? <AlertPayment /> : null}
		</StripeContext.Provider>
	);
};

export default StripeProvider;
