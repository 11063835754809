/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from '../API';
type GeneratedMutation<InputType, OutputType> = string & {
	__generatedMutationInput: InputType;
	__generatedMutationOutput: OutputType;
};

export const createSecretary = /* GraphQL */ `mutation CreateSecretary(
  $input: CreateSecretaryInput!
  $condition: ModelSecretaryConditionInput
) {
  createSecretary(input: $input, condition: $condition) {
    id
    name
    email
    firstTime
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<APITypes.CreateSecretaryMutationVariables, APITypes.CreateSecretaryMutation>;
export const updateSecretary = /* GraphQL */ `mutation UpdateSecretary(
  $input: UpdateSecretaryInput!
  $condition: ModelSecretaryConditionInput
) {
  updateSecretary(input: $input, condition: $condition) {
    id
    name
    email
    firstTime
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<APITypes.UpdateSecretaryMutationVariables, APITypes.UpdateSecretaryMutation>;
export const deleteSecretary = /* GraphQL */ `mutation DeleteSecretary(
  $input: DeleteSecretaryInput!
  $condition: ModelSecretaryConditionInput
) {
  deleteSecretary(input: $input, condition: $condition) {
    id
    name
    email
    firstTime
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<APITypes.DeleteSecretaryMutationVariables, APITypes.DeleteSecretaryMutation>;
export const createEmailDistributionLists = /* GraphQL */ `mutation CreateEmailDistributionLists(
  $input: CreateEmailDistributionListsInput!
  $condition: ModelEmailDistributionListsConditionInput
) {
  createEmailDistributionLists(input: $input, condition: $condition) {
    id
    email
    specialty
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.CreateEmailDistributionListsMutationVariables,
	APITypes.CreateEmailDistributionListsMutation
>;
export const updateEmailDistributionLists = /* GraphQL */ `mutation UpdateEmailDistributionLists(
  $input: UpdateEmailDistributionListsInput!
  $condition: ModelEmailDistributionListsConditionInput
) {
  updateEmailDistributionLists(input: $input, condition: $condition) {
    id
    email
    specialty
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.UpdateEmailDistributionListsMutationVariables,
	APITypes.UpdateEmailDistributionListsMutation
>;
export const deleteEmailDistributionLists = /* GraphQL */ `mutation DeleteEmailDistributionLists(
  $input: DeleteEmailDistributionListsInput!
  $condition: ModelEmailDistributionListsConditionInput
) {
  deleteEmailDistributionLists(input: $input, condition: $condition) {
    id
    email
    specialty
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.DeleteEmailDistributionListsMutationVariables,
	APITypes.DeleteEmailDistributionListsMutation
>;
export const createSecretaryHospitalDoctor = /* GraphQL */ `mutation CreateSecretaryHospitalDoctor(
  $input: CreateSecretaryHospitalDoctorInput!
  $condition: ModelSecretaryHospitalDoctorConditionInput
) {
  createSecretaryHospitalDoctor(input: $input, condition: $condition) {
    id
    hospitalDoctorID
    secretaryID
    hasHistoryPermission
    hasSurgeryPermission
    hasPrescriptionsPermission
    hasFilesPermission
    hasNotesPermission
    hasClaimsPermission
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    secretary {
      id
      name
      email
      firstTime
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    hospitalDoctor {
      id
      doctorID
      hospitalID
      lastWaitingListID
      hospitalLogo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospital {
        id
        name
        type
        province
        town
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
	APITypes.CreateSecretaryHospitalDoctorMutationVariables,
	APITypes.CreateSecretaryHospitalDoctorMutation
>;
export const updateSecretaryHospitalDoctor = /* GraphQL */ `mutation UpdateSecretaryHospitalDoctor(
  $input: UpdateSecretaryHospitalDoctorInput!
  $condition: ModelSecretaryHospitalDoctorConditionInput
) {
  updateSecretaryHospitalDoctor(input: $input, condition: $condition) {
    id
    hospitalDoctorID
    secretaryID
    hasHistoryPermission
    hasSurgeryPermission
    hasPrescriptionsPermission
    hasFilesPermission
    hasNotesPermission
    hasClaimsPermission
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    secretary {
      id
      name
      email
      firstTime
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    hospitalDoctor {
      id
      doctorID
      hospitalID
      lastWaitingListID
      hospitalLogo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospital {
        id
        name
        type
        province
        town
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
	APITypes.UpdateSecretaryHospitalDoctorMutationVariables,
	APITypes.UpdateSecretaryHospitalDoctorMutation
>;
export const deleteSecretaryHospitalDoctor = /* GraphQL */ `mutation DeleteSecretaryHospitalDoctor(
  $input: DeleteSecretaryHospitalDoctorInput!
  $condition: ModelSecretaryHospitalDoctorConditionInput
) {
  deleteSecretaryHospitalDoctor(input: $input, condition: $condition) {
    id
    hospitalDoctorID
    secretaryID
    hasHistoryPermission
    hasSurgeryPermission
    hasPrescriptionsPermission
    hasFilesPermission
    hasNotesPermission
    hasClaimsPermission
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    secretary {
      id
      name
      email
      firstTime
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    hospitalDoctor {
      id
      doctorID
      hospitalID
      lastWaitingListID
      hospitalLogo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospital {
        id
        name
        type
        province
        town
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
	APITypes.DeleteSecretaryHospitalDoctorMutationVariables,
	APITypes.DeleteSecretaryHospitalDoctorMutation
>;
export const createSecretaryHospitalDoctorClient =
	/* GraphQL */ `mutation CreateSecretaryHospitalDoctorClient(
  $input: CreateSecretaryHospitalDoctorClientInput!
  $condition: ModelSecretaryHospitalDoctorClientConditionInput
) {
  createSecretaryHospitalDoctorClient(input: $input, condition: $condition) {
    id
    hospitalDoctorID
    secretaryID
    clientID
    note
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
		APITypes.CreateSecretaryHospitalDoctorClientMutationVariables,
		APITypes.CreateSecretaryHospitalDoctorClientMutation
	>;
export const updateSecretaryHospitalDoctorClient =
	/* GraphQL */ `mutation UpdateSecretaryHospitalDoctorClient(
  $input: UpdateSecretaryHospitalDoctorClientInput!
  $condition: ModelSecretaryHospitalDoctorClientConditionInput
) {
  updateSecretaryHospitalDoctorClient(input: $input, condition: $condition) {
    id
    hospitalDoctorID
    secretaryID
    clientID
    note
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
		APITypes.UpdateSecretaryHospitalDoctorClientMutationVariables,
		APITypes.UpdateSecretaryHospitalDoctorClientMutation
	>;
export const deleteSecretaryHospitalDoctorClient =
	/* GraphQL */ `mutation DeleteSecretaryHospitalDoctorClient(
  $input: DeleteSecretaryHospitalDoctorClientInput!
  $condition: ModelSecretaryHospitalDoctorClientConditionInput
) {
  deleteSecretaryHospitalDoctorClient(input: $input, condition: $condition) {
    id
    hospitalDoctorID
    secretaryID
    clientID
    note
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
		APITypes.DeleteSecretaryHospitalDoctorClientMutationVariables,
		APITypes.DeleteSecretaryHospitalDoctorClientMutation
	>;
export const createMemberHospitalDoctorClient =
	/* GraphQL */ `mutation CreateMemberHospitalDoctorClient(
  $input: CreateMemberHospitalDoctorClientInput!
  $condition: ModelMemberHospitalDoctorClientConditionInput
) {
  createMemberHospitalDoctorClient(input: $input, condition: $condition) {
    id
    hospitalDoctorID
    memberID
    ownerID
    clientID
    note
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    owner {
      id
      doctorID
      hospitalID
      lastWaitingListID
      hospitalLogo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospital {
        id
        name
        type
        province
        town
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
		APITypes.CreateMemberHospitalDoctorClientMutationVariables,
		APITypes.CreateMemberHospitalDoctorClientMutation
	>;
export const updateMemberHospitalDoctorClient =
	/* GraphQL */ `mutation UpdateMemberHospitalDoctorClient(
  $input: UpdateMemberHospitalDoctorClientInput!
  $condition: ModelMemberHospitalDoctorClientConditionInput
) {
  updateMemberHospitalDoctorClient(input: $input, condition: $condition) {
    id
    hospitalDoctorID
    memberID
    ownerID
    clientID
    note
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    owner {
      id
      doctorID
      hospitalID
      lastWaitingListID
      hospitalLogo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospital {
        id
        name
        type
        province
        town
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
		APITypes.UpdateMemberHospitalDoctorClientMutationVariables,
		APITypes.UpdateMemberHospitalDoctorClientMutation
	>;
export const deleteMemberHospitalDoctorClient =
	/* GraphQL */ `mutation DeleteMemberHospitalDoctorClient(
  $input: DeleteMemberHospitalDoctorClientInput!
  $condition: ModelMemberHospitalDoctorClientConditionInput
) {
  deleteMemberHospitalDoctorClient(input: $input, condition: $condition) {
    id
    hospitalDoctorID
    memberID
    ownerID
    clientID
    note
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    owner {
      id
      doctorID
      hospitalID
      lastWaitingListID
      hospitalLogo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospital {
        id
        name
        type
        province
        town
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
		APITypes.DeleteMemberHospitalDoctorClientMutationVariables,
		APITypes.DeleteMemberHospitalDoctorClientMutation
	>;
export const createClient = /* GraphQL */ `mutation CreateClient(
  $input: CreateClientInput!
  $condition: ModelClientConditionInput
) {
  createClient(input: $input, condition: $condition) {
    id
    identificationName
    identificationData
    actualInsurance
    name
    lastName
    cellphoneNumber
    email
    bornDate
    parentOrTutorCedula
    parentOrTutorID
    gender
    phoneNumber
    addressStreet
    city
    isAdult
    sector
    bloodType
    company
    vitalSignsHeight
    vitalSignsWeight
    bodyMass
    vitalSignsBloodPressure
    heartRate
    respiratoryRate
    bodyTemperature
    oxygenSaturation
    occupation
    profileImage
    heartBeat
    weight
    height
    bloodPressure
    updateDateTime
    createdAt
    updatedAt
    code
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<APITypes.CreateClientMutationVariables, APITypes.CreateClientMutation>;
export const updateClient = /* GraphQL */ `mutation UpdateClient(
  $input: UpdateClientInput!
  $condition: ModelClientConditionInput
) {
  updateClient(input: $input, condition: $condition) {
    id
    identificationName
    identificationData
    actualInsurance
    name
    lastName
    cellphoneNumber
    email
    bornDate
    parentOrTutorCedula
    parentOrTutorID
    gender
    phoneNumber
    addressStreet
    city
    isAdult
    sector
    bloodType
    company
    vitalSignsHeight
    vitalSignsWeight
    bodyMass
    vitalSignsBloodPressure
    heartRate
    respiratoryRate
    bodyTemperature
    oxygenSaturation
    occupation
    profileImage
    heartBeat
    weight
    height
    bloodPressure
    updateDateTime
    createdAt
    updatedAt
    code
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<APITypes.UpdateClientMutationVariables, APITypes.UpdateClientMutation>;
export const deleteClient = /* GraphQL */ `mutation DeleteClient(
  $input: DeleteClientInput!
  $condition: ModelClientConditionInput
) {
  deleteClient(input: $input, condition: $condition) {
    id
    identificationName
    identificationData
    actualInsurance
    name
    lastName
    cellphoneNumber
    email
    bornDate
    parentOrTutorCedula
    parentOrTutorID
    gender
    phoneNumber
    addressStreet
    city
    isAdult
    sector
    bloodType
    company
    vitalSignsHeight
    vitalSignsWeight
    bodyMass
    vitalSignsBloodPressure
    heartRate
    respiratoryRate
    bodyTemperature
    oxygenSaturation
    occupation
    profileImage
    heartBeat
    weight
    height
    bloodPressure
    updateDateTime
    createdAt
    updatedAt
    code
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<APITypes.DeleteClientMutationVariables, APITypes.DeleteClientMutation>;
export const createClientImageSection = /* GraphQL */ `mutation CreateClientImageSection(
  $input: CreateClientImageSectionInput!
  $condition: ModelClientImageSectionConditionInput
) {
  createClientImageSection(input: $input, condition: $condition) {
    id
    clientHospitalDoctorID
    date
    description
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.CreateClientImageSectionMutationVariables,
	APITypes.CreateClientImageSectionMutation
>;
export const updateClientImageSection = /* GraphQL */ `mutation UpdateClientImageSection(
  $input: UpdateClientImageSectionInput!
  $condition: ModelClientImageSectionConditionInput
) {
  updateClientImageSection(input: $input, condition: $condition) {
    id
    clientHospitalDoctorID
    date
    description
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.UpdateClientImageSectionMutationVariables,
	APITypes.UpdateClientImageSectionMutation
>;
export const deleteClientImageSection = /* GraphQL */ `mutation DeleteClientImageSection(
  $input: DeleteClientImageSectionInput!
  $condition: ModelClientImageSectionConditionInput
) {
  deleteClientImageSection(input: $input, condition: $condition) {
    id
    clientHospitalDoctorID
    date
    description
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.DeleteClientImageSectionMutationVariables,
	APITypes.DeleteClientImageSectionMutation
>;
export const createPosibleClient = /* GraphQL */ `mutation CreatePosibleClient(
  $input: CreatePosibleClientInput!
  $condition: ModelPosibleClientConditionInput
) {
  createPosibleClient(input: $input, condition: $condition) {
    id
    phoneNumber
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.CreatePosibleClientMutationVariables,
	APITypes.CreatePosibleClientMutation
>;
export const updatePosibleClient = /* GraphQL */ `mutation UpdatePosibleClient(
  $input: UpdatePosibleClientInput!
  $condition: ModelPosibleClientConditionInput
) {
  updatePosibleClient(input: $input, condition: $condition) {
    id
    phoneNumber
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.UpdatePosibleClientMutationVariables,
	APITypes.UpdatePosibleClientMutation
>;
export const deletePosibleClient = /* GraphQL */ `mutation DeletePosibleClient(
  $input: DeletePosibleClientInput!
  $condition: ModelPosibleClientConditionInput
) {
  deletePosibleClient(input: $input, condition: $condition) {
    id
    phoneNumber
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.DeletePosibleClientMutationVariables,
	APITypes.DeletePosibleClientMutation
>;
export const createClientImage = /* GraphQL */ `mutation CreateClientImage(
  $input: CreateClientImageInput!
  $condition: ModelClientImageConditionInput
) {
  createClientImage(input: $input, condition: $condition) {
    id
    pathFile
    description
    ClientImageDateID
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.CreateClientImageMutationVariables,
	APITypes.CreateClientImageMutation
>;
export const updateClientImage = /* GraphQL */ `mutation UpdateClientImage(
  $input: UpdateClientImageInput!
  $condition: ModelClientImageConditionInput
) {
  updateClientImage(input: $input, condition: $condition) {
    id
    pathFile
    description
    ClientImageDateID
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.UpdateClientImageMutationVariables,
	APITypes.UpdateClientImageMutation
>;
export const deleteClientImage = /* GraphQL */ `mutation DeleteClientImage(
  $input: DeleteClientImageInput!
  $condition: ModelClientImageConditionInput
) {
  deleteClientImage(input: $input, condition: $condition) {
    id
    pathFile
    description
    ClientImageDateID
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.DeleteClientImageMutationVariables,
	APITypes.DeleteClientImageMutation
>;
export const createConsultationDiagnostic = /* GraphQL */ `mutation CreateConsultationDiagnostic(
  $input: CreateConsultationDiagnosticInput!
  $condition: ModelConsultationDiagnosticConditionInput
) {
  createConsultationDiagnostic(input: $input, condition: $condition) {
    id
    clientConsultationID
    name
    code
    type
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.CreateConsultationDiagnosticMutationVariables,
	APITypes.CreateConsultationDiagnosticMutation
>;
export const updateConsultationDiagnostic = /* GraphQL */ `mutation UpdateConsultationDiagnostic(
  $input: UpdateConsultationDiagnosticInput!
  $condition: ModelConsultationDiagnosticConditionInput
) {
  updateConsultationDiagnostic(input: $input, condition: $condition) {
    id
    clientConsultationID
    name
    code
    type
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.UpdateConsultationDiagnosticMutationVariables,
	APITypes.UpdateConsultationDiagnosticMutation
>;
export const deleteConsultationDiagnostic = /* GraphQL */ `mutation DeleteConsultationDiagnostic(
  $input: DeleteConsultationDiagnosticInput!
  $condition: ModelConsultationDiagnosticConditionInput
) {
  deleteConsultationDiagnostic(input: $input, condition: $condition) {
    id
    clientConsultationID
    name
    code
    type
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.DeleteConsultationDiagnosticMutationVariables,
	APITypes.DeleteConsultationDiagnosticMutation
>;
export const createClientConsultation = /* GraphQL */ `mutation CreateClientConsultation(
  $input: CreateClientConsultationInput!
  $condition: ModelClientConsultationConditionInput
) {
  createClientConsultation(input: $input, condition: $condition) {
    id
    clientHospitalDoctorID
    doctorID
    visitReason
    visitDescription
    physicTestNote
    height
    weight
    bodyMass
    bloodPressure
    heartRate
    respiratoryRate
    bodyTemperature
    oxygenSaturation
    clientMedicinePrescriptionID
    clientAnalisisPrescriptionID
    clientFreePrescriptionID
    treatment
    procedureName
    procedureDescription
    createdAt
    updatedAt
    status
    _version
    _deleted
    _lastChangedAt
    doctor {
      id
      name
      specialty
      exequator
      email
      firstTime
      studyPlace
      isBeenReview
      isFirstAddSecretary
      hasTrialOnCreation
      title
      phone
      code
      password
      isAdmin
      daysOfTrial
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
	APITypes.CreateClientConsultationMutationVariables,
	APITypes.CreateClientConsultationMutation
>;
export const updateClientConsultation = /* GraphQL */ `mutation UpdateClientConsultation(
  $input: UpdateClientConsultationInput!
  $condition: ModelClientConsultationConditionInput
) {
  updateClientConsultation(input: $input, condition: $condition) {
    id
    clientHospitalDoctorID
    doctorID
    visitReason
    visitDescription
    physicTestNote
    height
    weight
    bodyMass
    bloodPressure
    heartRate
    respiratoryRate
    bodyTemperature
    oxygenSaturation
    clientMedicinePrescriptionID
    clientAnalisisPrescriptionID
    clientFreePrescriptionID
    treatment
    procedureName
    procedureDescription
    createdAt
    updatedAt
    status
    _version
    _deleted
    _lastChangedAt
    doctor {
      id
      name
      specialty
      exequator
      email
      firstTime
      studyPlace
      isBeenReview
      isFirstAddSecretary
      hasTrialOnCreation
      title
      phone
      code
      password
      isAdmin
      daysOfTrial
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
	APITypes.UpdateClientConsultationMutationVariables,
	APITypes.UpdateClientConsultationMutation
>;
export const deleteClientConsultation = /* GraphQL */ `mutation DeleteClientConsultation(
  $input: DeleteClientConsultationInput!
  $condition: ModelClientConsultationConditionInput
) {
  deleteClientConsultation(input: $input, condition: $condition) {
    id
    clientHospitalDoctorID
    doctorID
    visitReason
    visitDescription
    physicTestNote
    height
    weight
    bodyMass
    bloodPressure
    heartRate
    respiratoryRate
    bodyTemperature
    oxygenSaturation
    clientMedicinePrescriptionID
    clientAnalisisPrescriptionID
    clientFreePrescriptionID
    treatment
    procedureName
    procedureDescription
    createdAt
    updatedAt
    status
    _version
    _deleted
    _lastChangedAt
    doctor {
      id
      name
      specialty
      exequator
      email
      firstTime
      studyPlace
      isBeenReview
      isFirstAddSecretary
      hasTrialOnCreation
      title
      phone
      code
      password
      isAdmin
      daysOfTrial
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
	APITypes.DeleteClientConsultationMutationVariables,
	APITypes.DeleteClientConsultationMutation
>;
export const createMedicine = /* GraphQL */ `mutation CreateMedicine(
  $input: CreateMedicineInput!
  $condition: ModelMedicineConditionInput
) {
  createMedicine(input: $input, condition: $condition) {
    id
    name
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<APITypes.CreateMedicineMutationVariables, APITypes.CreateMedicineMutation>;
export const updateMedicine = /* GraphQL */ `mutation UpdateMedicine(
  $input: UpdateMedicineInput!
  $condition: ModelMedicineConditionInput
) {
  updateMedicine(input: $input, condition: $condition) {
    id
    name
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<APITypes.UpdateMedicineMutationVariables, APITypes.UpdateMedicineMutation>;
export const deleteMedicine = /* GraphQL */ `mutation DeleteMedicine(
  $input: DeleteMedicineInput!
  $condition: ModelMedicineConditionInput
) {
  deleteMedicine(input: $input, condition: $condition) {
    id
    name
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<APITypes.DeleteMedicineMutationVariables, APITypes.DeleteMedicineMutation>;
export const createClientAllergy = /* GraphQL */ `mutation CreateClientAllergy(
  $input: CreateClientAllergyInput!
  $condition: ModelClientAllergyConditionInput
) {
  createClientAllergy(input: $input, condition: $condition) {
    id
    clientID
    name
    status
    note
    diagnosedDate
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.CreateClientAllergyMutationVariables,
	APITypes.CreateClientAllergyMutation
>;
export const updateClientAllergy = /* GraphQL */ `mutation UpdateClientAllergy(
  $input: UpdateClientAllergyInput!
  $condition: ModelClientAllergyConditionInput
) {
  updateClientAllergy(input: $input, condition: $condition) {
    id
    clientID
    name
    status
    note
    diagnosedDate
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.UpdateClientAllergyMutationVariables,
	APITypes.UpdateClientAllergyMutation
>;
export const deleteClientAllergy = /* GraphQL */ `mutation DeleteClientAllergy(
  $input: DeleteClientAllergyInput!
  $condition: ModelClientAllergyConditionInput
) {
  deleteClientAllergy(input: $input, condition: $condition) {
    id
    clientID
    name
    status
    note
    diagnosedDate
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.DeleteClientAllergyMutationVariables,
	APITypes.DeleteClientAllergyMutation
>;
export const createClientDisease = /* GraphQL */ `mutation CreateClientDisease(
  $input: CreateClientDiseaseInput!
  $condition: ModelClientDiseaseConditionInput
) {
  createClientDisease(input: $input, condition: $condition) {
    id
    clientID
    name
    status
    note
    diagnosedDate
    updatedAt
    createdAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.CreateClientDiseaseMutationVariables,
	APITypes.CreateClientDiseaseMutation
>;
export const updateClientDisease = /* GraphQL */ `mutation UpdateClientDisease(
  $input: UpdateClientDiseaseInput!
  $condition: ModelClientDiseaseConditionInput
) {
  updateClientDisease(input: $input, condition: $condition) {
    id
    clientID
    name
    status
    note
    diagnosedDate
    updatedAt
    createdAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.UpdateClientDiseaseMutationVariables,
	APITypes.UpdateClientDiseaseMutation
>;
export const deleteClientDisease = /* GraphQL */ `mutation DeleteClientDisease(
  $input: DeleteClientDiseaseInput!
  $condition: ModelClientDiseaseConditionInput
) {
  deleteClientDisease(input: $input, condition: $condition) {
    id
    clientID
    name
    status
    note
    diagnosedDate
    updatedAt
    createdAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.DeleteClientDiseaseMutationVariables,
	APITypes.DeleteClientDiseaseMutation
>;
export const createClientProcedure = /* GraphQL */ `mutation CreateClientProcedure(
  $input: CreateClientProcedureInput!
  $condition: ModelClientProcedureConditionInput
) {
  createClientProcedure(input: $input, condition: $condition) {
    id
    clientID
    name
    note
    realizationDate
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.CreateClientProcedureMutationVariables,
	APITypes.CreateClientProcedureMutation
>;
export const updateClientProcedure = /* GraphQL */ `mutation UpdateClientProcedure(
  $input: UpdateClientProcedureInput!
  $condition: ModelClientProcedureConditionInput
) {
  updateClientProcedure(input: $input, condition: $condition) {
    id
    clientID
    name
    note
    realizationDate
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.UpdateClientProcedureMutationVariables,
	APITypes.UpdateClientProcedureMutation
>;
export const deleteClientProcedure = /* GraphQL */ `mutation DeleteClientProcedure(
  $input: DeleteClientProcedureInput!
  $condition: ModelClientProcedureConditionInput
) {
  deleteClientProcedure(input: $input, condition: $condition) {
    id
    clientID
    name
    note
    realizationDate
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.DeleteClientProcedureMutationVariables,
	APITypes.DeleteClientProcedureMutation
>;
export const createClientHospitalization = /* GraphQL */ `mutation CreateClientHospitalization(
  $input: CreateClientHospitalizationInput!
  $condition: ModelClientHospitalizationConditionInput
) {
  createClientHospitalization(input: $input, condition: $condition) {
    id
    clientID
    name
    note
    date
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.CreateClientHospitalizationMutationVariables,
	APITypes.CreateClientHospitalizationMutation
>;
export const updateClientHospitalization = /* GraphQL */ `mutation UpdateClientHospitalization(
  $input: UpdateClientHospitalizationInput!
  $condition: ModelClientHospitalizationConditionInput
) {
  updateClientHospitalization(input: $input, condition: $condition) {
    id
    clientID
    name
    note
    date
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.UpdateClientHospitalizationMutationVariables,
	APITypes.UpdateClientHospitalizationMutation
>;
export const deleteClientHospitalization = /* GraphQL */ `mutation DeleteClientHospitalization(
  $input: DeleteClientHospitalizationInput!
  $condition: ModelClientHospitalizationConditionInput
) {
  deleteClientHospitalization(input: $input, condition: $condition) {
    id
    clientID
    name
    note
    date
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.DeleteClientHospitalizationMutationVariables,
	APITypes.DeleteClientHospitalizationMutation
>;
export const createClientFamilyHistory = /* GraphQL */ `mutation CreateClientFamilyHistory(
  $input: CreateClientFamilyHistoryInput!
  $condition: ModelClientFamilyHistoryConditionInput
) {
  createClientFamilyHistory(input: $input, condition: $condition) {
    id
    clientID
    familyMember
    mellitusDiabetes
    mellitusDiabetesNote
    arterialHypertension
    arterialHypertensionNote
    heartDisease
    heartDiseaseNote
    nephropathy
    nephropathyNote
    neuropathies
    neuropathiesNote
    epilepsy
    epilepsyNote
    rheumatoidArthritis
    rheumatoidArthritisNote
    psychiatricDiseases
    psychiatricDiseasesNote
    cancer
    cancerNote
    other
    otherNote
    updatedAt
    createdAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.CreateClientFamilyHistoryMutationVariables,
	APITypes.CreateClientFamilyHistoryMutation
>;
export const updateClientFamilyHistory = /* GraphQL */ `mutation UpdateClientFamilyHistory(
  $input: UpdateClientFamilyHistoryInput!
  $condition: ModelClientFamilyHistoryConditionInput
) {
  updateClientFamilyHistory(input: $input, condition: $condition) {
    id
    clientID
    familyMember
    mellitusDiabetes
    mellitusDiabetesNote
    arterialHypertension
    arterialHypertensionNote
    heartDisease
    heartDiseaseNote
    nephropathy
    nephropathyNote
    neuropathies
    neuropathiesNote
    epilepsy
    epilepsyNote
    rheumatoidArthritis
    rheumatoidArthritisNote
    psychiatricDiseases
    psychiatricDiseasesNote
    cancer
    cancerNote
    other
    otherNote
    updatedAt
    createdAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.UpdateClientFamilyHistoryMutationVariables,
	APITypes.UpdateClientFamilyHistoryMutation
>;
export const deleteClientFamilyHistory = /* GraphQL */ `mutation DeleteClientFamilyHistory(
  $input: DeleteClientFamilyHistoryInput!
  $condition: ModelClientFamilyHistoryConditionInput
) {
  deleteClientFamilyHistory(input: $input, condition: $condition) {
    id
    clientID
    familyMember
    mellitusDiabetes
    mellitusDiabetesNote
    arterialHypertension
    arterialHypertensionNote
    heartDisease
    heartDiseaseNote
    nephropathy
    nephropathyNote
    neuropathies
    neuropathiesNote
    epilepsy
    epilepsyNote
    rheumatoidArthritis
    rheumatoidArthritisNote
    psychiatricDiseases
    psychiatricDiseasesNote
    cancer
    cancerNote
    other
    otherNote
    updatedAt
    createdAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.DeleteClientFamilyHistoryMutationVariables,
	APITypes.DeleteClientFamilyHistoryMutation
>;
export const createClientLifeStyle = /* GraphQL */ `mutation CreateClientLifeStyle(
  $input: CreateClientLifeStyleInput!
  $condition: ModelClientLifeStyleConditionInput
) {
  createClientLifeStyle(input: $input, condition: $condition) {
    id
    clientID
    lifeStyle
    name
    description
    updatedAt
    createdAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.CreateClientLifeStyleMutationVariables,
	APITypes.CreateClientLifeStyleMutation
>;
export const updateClientLifeStyle = /* GraphQL */ `mutation UpdateClientLifeStyle(
  $input: UpdateClientLifeStyleInput!
  $condition: ModelClientLifeStyleConditionInput
) {
  updateClientLifeStyle(input: $input, condition: $condition) {
    id
    clientID
    lifeStyle
    name
    description
    updatedAt
    createdAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.UpdateClientLifeStyleMutationVariables,
	APITypes.UpdateClientLifeStyleMutation
>;
export const deleteClientLifeStyle = /* GraphQL */ `mutation DeleteClientLifeStyle(
  $input: DeleteClientLifeStyleInput!
  $condition: ModelClientLifeStyleConditionInput
) {
  deleteClientLifeStyle(input: $input, condition: $condition) {
    id
    clientID
    lifeStyle
    name
    description
    updatedAt
    createdAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.DeleteClientLifeStyleMutationVariables,
	APITypes.DeleteClientLifeStyleMutation
>;
export const createClientPrescription = /* GraphQL */ `mutation CreateClientPrescription(
  $input: CreateClientPrescriptionInput!
  $condition: ModelClientPrescriptionConditionInput
) {
  createClientPrescription(input: $input, condition: $condition) {
    id
    clientHospitalDoctorID
    creationDate
    type
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.CreateClientPrescriptionMutationVariables,
	APITypes.CreateClientPrescriptionMutation
>;
export const updateClientPrescription = /* GraphQL */ `mutation UpdateClientPrescription(
  $input: UpdateClientPrescriptionInput!
  $condition: ModelClientPrescriptionConditionInput
) {
  updateClientPrescription(input: $input, condition: $condition) {
    id
    clientHospitalDoctorID
    creationDate
    type
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.UpdateClientPrescriptionMutationVariables,
	APITypes.UpdateClientPrescriptionMutation
>;
export const deleteClientPrescription = /* GraphQL */ `mutation DeleteClientPrescription(
  $input: DeleteClientPrescriptionInput!
  $condition: ModelClientPrescriptionConditionInput
) {
  deleteClientPrescription(input: $input, condition: $condition) {
    id
    clientHospitalDoctorID
    creationDate
    type
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.DeleteClientPrescriptionMutationVariables,
	APITypes.DeleteClientPrescriptionMutation
>;
export const createClientPrescriptionMedicine =
	/* GraphQL */ `mutation CreateClientPrescriptionMedicine(
  $input: CreateClientPrescriptionMedicineInput!
  $condition: ModelClientPrescriptionMedicineConditionInput
) {
  createClientPrescriptionMedicine(input: $input, condition: $condition) {
    id
    clientPrescriptionID
    medicineID
    name
    take
    indication
    duration
    description
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
		APITypes.CreateClientPrescriptionMedicineMutationVariables,
		APITypes.CreateClientPrescriptionMedicineMutation
	>;
export const updateClientPrescriptionMedicine =
	/* GraphQL */ `mutation UpdateClientPrescriptionMedicine(
  $input: UpdateClientPrescriptionMedicineInput!
  $condition: ModelClientPrescriptionMedicineConditionInput
) {
  updateClientPrescriptionMedicine(input: $input, condition: $condition) {
    id
    clientPrescriptionID
    medicineID
    name
    take
    indication
    duration
    description
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
		APITypes.UpdateClientPrescriptionMedicineMutationVariables,
		APITypes.UpdateClientPrescriptionMedicineMutation
	>;
export const deleteClientPrescriptionMedicine =
	/* GraphQL */ `mutation DeleteClientPrescriptionMedicine(
  $input: DeleteClientPrescriptionMedicineInput!
  $condition: ModelClientPrescriptionMedicineConditionInput
) {
  deleteClientPrescriptionMedicine(input: $input, condition: $condition) {
    id
    clientPrescriptionID
    medicineID
    name
    take
    indication
    duration
    description
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
		APITypes.DeleteClientPrescriptionMedicineMutationVariables,
		APITypes.DeleteClientPrescriptionMedicineMutation
	>;
export const createClientFreePrescription = /* GraphQL */ `mutation CreateClientFreePrescription(
  $input: CreateClientFreePrescriptionInput!
  $condition: ModelClientFreePrescriptionConditionInput
) {
  createClientFreePrescription(input: $input, condition: $condition) {
    id
    clientPrescriptionID
    description
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.CreateClientFreePrescriptionMutationVariables,
	APITypes.CreateClientFreePrescriptionMutation
>;
export const updateClientFreePrescription = /* GraphQL */ `mutation UpdateClientFreePrescription(
  $input: UpdateClientFreePrescriptionInput!
  $condition: ModelClientFreePrescriptionConditionInput
) {
  updateClientFreePrescription(input: $input, condition: $condition) {
    id
    clientPrescriptionID
    description
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.UpdateClientFreePrescriptionMutationVariables,
	APITypes.UpdateClientFreePrescriptionMutation
>;
export const deleteClientFreePrescription = /* GraphQL */ `mutation DeleteClientFreePrescription(
  $input: DeleteClientFreePrescriptionInput!
  $condition: ModelClientFreePrescriptionConditionInput
) {
  deleteClientFreePrescription(input: $input, condition: $condition) {
    id
    clientPrescriptionID
    description
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.DeleteClientFreePrescriptionMutationVariables,
	APITypes.DeleteClientFreePrescriptionMutation
>;
export const createClientPrescriptionAnalisis =
	/* GraphQL */ `mutation CreateClientPrescriptionAnalisis(
  $input: CreateClientPrescriptionAnalisisInput!
  $condition: ModelClientPrescriptionAnalisisConditionInput
) {
  createClientPrescriptionAnalisis(input: $input, condition: $condition) {
    id
    clientPrescriptionID
    name
    description
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
		APITypes.CreateClientPrescriptionAnalisisMutationVariables,
		APITypes.CreateClientPrescriptionAnalisisMutation
	>;
export const updateClientPrescriptionAnalisis =
	/* GraphQL */ `mutation UpdateClientPrescriptionAnalisis(
  $input: UpdateClientPrescriptionAnalisisInput!
  $condition: ModelClientPrescriptionAnalisisConditionInput
) {
  updateClientPrescriptionAnalisis(input: $input, condition: $condition) {
    id
    clientPrescriptionID
    name
    description
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
		APITypes.UpdateClientPrescriptionAnalisisMutationVariables,
		APITypes.UpdateClientPrescriptionAnalisisMutation
	>;
export const deleteClientPrescriptionAnalisis =
	/* GraphQL */ `mutation DeleteClientPrescriptionAnalisis(
  $input: DeleteClientPrescriptionAnalisisInput!
  $condition: ModelClientPrescriptionAnalisisConditionInput
) {
  deleteClientPrescriptionAnalisis(input: $input, condition: $condition) {
    id
    clientPrescriptionID
    name
    description
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
		APITypes.DeleteClientPrescriptionAnalisisMutationVariables,
		APITypes.DeleteClientPrescriptionAnalisisMutation
	>;
export const createDoctorLabImage = /* GraphQL */ `mutation CreateDoctorLabImage(
  $input: CreateDoctorLabImageInput!
  $condition: ModelDoctorLabImageConditionInput
) {
  createDoctorLabImage(input: $input, condition: $condition) {
    id
    doctorID
    labImageID
    updatedAt
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    labImage {
      id
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
	APITypes.CreateDoctorLabImageMutationVariables,
	APITypes.CreateDoctorLabImageMutation
>;
export const updateDoctorLabImage = /* GraphQL */ `mutation UpdateDoctorLabImage(
  $input: UpdateDoctorLabImageInput!
  $condition: ModelDoctorLabImageConditionInput
) {
  updateDoctorLabImage(input: $input, condition: $condition) {
    id
    doctorID
    labImageID
    updatedAt
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    labImage {
      id
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
	APITypes.UpdateDoctorLabImageMutationVariables,
	APITypes.UpdateDoctorLabImageMutation
>;
export const deleteDoctorLabImage = /* GraphQL */ `mutation DeleteDoctorLabImage(
  $input: DeleteDoctorLabImageInput!
  $condition: ModelDoctorLabImageConditionInput
) {
  deleteDoctorLabImage(input: $input, condition: $condition) {
    id
    doctorID
    labImageID
    updatedAt
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    labImage {
      id
      name
      description
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
	APITypes.DeleteDoctorLabImageMutationVariables,
	APITypes.DeleteDoctorLabImageMutation
>;
export const createLabImage = /* GraphQL */ `mutation CreateLabImage(
  $input: CreateLabImageInput!
  $condition: ModelLabImageConditionInput
) {
  createLabImage(input: $input, condition: $condition) {
    id
    name
    description
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<APITypes.CreateLabImageMutationVariables, APITypes.CreateLabImageMutation>;
export const updateLabImage = /* GraphQL */ `mutation UpdateLabImage(
  $input: UpdateLabImageInput!
  $condition: ModelLabImageConditionInput
) {
  updateLabImage(input: $input, condition: $condition) {
    id
    name
    description
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<APITypes.UpdateLabImageMutationVariables, APITypes.UpdateLabImageMutation>;
export const deleteLabImage = /* GraphQL */ `mutation DeleteLabImage(
  $input: DeleteLabImageInput!
  $condition: ModelLabImageConditionInput
) {
  deleteLabImage(input: $input, condition: $condition) {
    id
    name
    description
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<APITypes.DeleteLabImageMutationVariables, APITypes.DeleteLabImageMutation>;
export const createPharmacyMedicine = /* GraphQL */ `mutation CreatePharmacyMedicine(
  $input: CreatePharmacyMedicineInput!
  $condition: ModelPharmacyMedicineConditionInput
) {
  createPharmacyMedicine(input: $input, condition: $condition) {
    id
    name
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.CreatePharmacyMedicineMutationVariables,
	APITypes.CreatePharmacyMedicineMutation
>;
export const updatePharmacyMedicine = /* GraphQL */ `mutation UpdatePharmacyMedicine(
  $input: UpdatePharmacyMedicineInput!
  $condition: ModelPharmacyMedicineConditionInput
) {
  updatePharmacyMedicine(input: $input, condition: $condition) {
    id
    name
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.UpdatePharmacyMedicineMutationVariables,
	APITypes.UpdatePharmacyMedicineMutation
>;
export const deletePharmacyMedicine = /* GraphQL */ `mutation DeletePharmacyMedicine(
  $input: DeletePharmacyMedicineInput!
  $condition: ModelPharmacyMedicineConditionInput
) {
  deletePharmacyMedicine(input: $input, condition: $condition) {
    id
    name
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.DeletePharmacyMedicineMutationVariables,
	APITypes.DeletePharmacyMedicineMutation
>;
export const createPharmacyMedicineMeasure = /* GraphQL */ `mutation CreatePharmacyMedicineMeasure(
  $input: CreatePharmacyMedicineMeasureInput!
  $condition: ModelPharmacyMedicineMeasureConditionInput
) {
  createPharmacyMedicineMeasure(input: $input, condition: $condition) {
    id
    pharmacyMedicineID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.CreatePharmacyMedicineMeasureMutationVariables,
	APITypes.CreatePharmacyMedicineMeasureMutation
>;
export const updatePharmacyMedicineMeasure = /* GraphQL */ `mutation UpdatePharmacyMedicineMeasure(
  $input: UpdatePharmacyMedicineMeasureInput!
  $condition: ModelPharmacyMedicineMeasureConditionInput
) {
  updatePharmacyMedicineMeasure(input: $input, condition: $condition) {
    id
    pharmacyMedicineID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.UpdatePharmacyMedicineMeasureMutationVariables,
	APITypes.UpdatePharmacyMedicineMeasureMutation
>;
export const deletePharmacyMedicineMeasure = /* GraphQL */ `mutation DeletePharmacyMedicineMeasure(
  $input: DeletePharmacyMedicineMeasureInput!
  $condition: ModelPharmacyMedicineMeasureConditionInput
) {
  deletePharmacyMedicineMeasure(input: $input, condition: $condition) {
    id
    pharmacyMedicineID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.DeletePharmacyMedicineMeasureMutationVariables,
	APITypes.DeletePharmacyMedicineMeasureMutation
>;
export const createTemplate = /* GraphQL */ `mutation CreateTemplate(
  $input: CreateTemplateInput!
  $condition: ModelTemplateConditionInput
) {
  createTemplate(input: $input, condition: $condition) {
    id
    doctorID
    templateName
    templateType
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<APITypes.CreateTemplateMutationVariables, APITypes.CreateTemplateMutation>;
export const updateTemplate = /* GraphQL */ `mutation UpdateTemplate(
  $input: UpdateTemplateInput!
  $condition: ModelTemplateConditionInput
) {
  updateTemplate(input: $input, condition: $condition) {
    id
    doctorID
    templateName
    templateType
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<APITypes.UpdateTemplateMutationVariables, APITypes.UpdateTemplateMutation>;
export const deleteTemplate = /* GraphQL */ `mutation DeleteTemplate(
  $input: DeleteTemplateInput!
  $condition: ModelTemplateConditionInput
) {
  deleteTemplate(input: $input, condition: $condition) {
    id
    doctorID
    templateName
    templateType
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<APITypes.DeleteTemplateMutationVariables, APITypes.DeleteTemplateMutation>;
export const createTemplateMedicine = /* GraphQL */ `mutation CreateTemplateMedicine(
  $input: CreateTemplateMedicineInput!
  $condition: ModelTemplateMedicineConditionInput
) {
  createTemplateMedicine(input: $input, condition: $condition) {
    id
    templateID
    medicineID
    take
    indication
    duration
    description
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.CreateTemplateMedicineMutationVariables,
	APITypes.CreateTemplateMedicineMutation
>;
export const updateTemplateMedicine = /* GraphQL */ `mutation UpdateTemplateMedicine(
  $input: UpdateTemplateMedicineInput!
  $condition: ModelTemplateMedicineConditionInput
) {
  updateTemplateMedicine(input: $input, condition: $condition) {
    id
    templateID
    medicineID
    take
    indication
    duration
    description
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.UpdateTemplateMedicineMutationVariables,
	APITypes.UpdateTemplateMedicineMutation
>;
export const deleteTemplateMedicine = /* GraphQL */ `mutation DeleteTemplateMedicine(
  $input: DeleteTemplateMedicineInput!
  $condition: ModelTemplateMedicineConditionInput
) {
  deleteTemplateMedicine(input: $input, condition: $condition) {
    id
    templateID
    medicineID
    take
    indication
    duration
    description
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.DeleteTemplateMedicineMutationVariables,
	APITypes.DeleteTemplateMedicineMutation
>;
export const createDoctorRecentDiagnosis = /* GraphQL */ `mutation CreateDoctorRecentDiagnosis(
  $input: CreateDoctorRecentDiagnosisInput!
  $condition: ModelDoctorRecentDiagnosisConditionInput
) {
  createDoctorRecentDiagnosis(input: $input, condition: $condition) {
    id
    doctorID
    name
    code
    type
    updateDateTime
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.CreateDoctorRecentDiagnosisMutationVariables,
	APITypes.CreateDoctorRecentDiagnosisMutation
>;
export const updateDoctorRecentDiagnosis = /* GraphQL */ `mutation UpdateDoctorRecentDiagnosis(
  $input: UpdateDoctorRecentDiagnosisInput!
  $condition: ModelDoctorRecentDiagnosisConditionInput
) {
  updateDoctorRecentDiagnosis(input: $input, condition: $condition) {
    id
    doctorID
    name
    code
    type
    updateDateTime
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.UpdateDoctorRecentDiagnosisMutationVariables,
	APITypes.UpdateDoctorRecentDiagnosisMutation
>;
export const deleteDoctorRecentDiagnosis = /* GraphQL */ `mutation DeleteDoctorRecentDiagnosis(
  $input: DeleteDoctorRecentDiagnosisInput!
  $condition: ModelDoctorRecentDiagnosisConditionInput
) {
  deleteDoctorRecentDiagnosis(input: $input, condition: $condition) {
    id
    doctorID
    name
    code
    type
    updateDateTime
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.DeleteDoctorRecentDiagnosisMutationVariables,
	APITypes.DeleteDoctorRecentDiagnosisMutation
>;
export const createTemplateLabImage = /* GraphQL */ `mutation CreateTemplateLabImage(
  $input: CreateTemplateLabImageInput!
  $condition: ModelTemplateLabImageConditionInput
) {
  createTemplateLabImage(input: $input, condition: $condition) {
    id
    templateID
    name
    description
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.CreateTemplateLabImageMutationVariables,
	APITypes.CreateTemplateLabImageMutation
>;
export const updateTemplateLabImage = /* GraphQL */ `mutation UpdateTemplateLabImage(
  $input: UpdateTemplateLabImageInput!
  $condition: ModelTemplateLabImageConditionInput
) {
  updateTemplateLabImage(input: $input, condition: $condition) {
    id
    templateID
    name
    description
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.UpdateTemplateLabImageMutationVariables,
	APITypes.UpdateTemplateLabImageMutation
>;
export const deleteTemplateLabImage = /* GraphQL */ `mutation DeleteTemplateLabImage(
  $input: DeleteTemplateLabImageInput!
  $condition: ModelTemplateLabImageConditionInput
) {
  deleteTemplateLabImage(input: $input, condition: $condition) {
    id
    templateID
    name
    description
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.DeleteTemplateLabImageMutationVariables,
	APITypes.DeleteTemplateLabImageMutation
>;
export const createDoctorMedicine = /* GraphQL */ `mutation CreateDoctorMedicine(
  $input: CreateDoctorMedicineInput!
  $condition: ModelDoctorMedicineConditionInput
) {
  createDoctorMedicine(input: $input, condition: $condition) {
    id
    doctorID
    medicineID
    isDeleted
    updatedAt
    _version
    _deleted
    _lastChangedAt
    createdAt
    medicine {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
	APITypes.CreateDoctorMedicineMutationVariables,
	APITypes.CreateDoctorMedicineMutation
>;
export const updateDoctorMedicine = /* GraphQL */ `mutation UpdateDoctorMedicine(
  $input: UpdateDoctorMedicineInput!
  $condition: ModelDoctorMedicineConditionInput
) {
  updateDoctorMedicine(input: $input, condition: $condition) {
    id
    doctorID
    medicineID
    isDeleted
    updatedAt
    _version
    _deleted
    _lastChangedAt
    createdAt
    medicine {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
	APITypes.UpdateDoctorMedicineMutationVariables,
	APITypes.UpdateDoctorMedicineMutation
>;
export const deleteDoctorMedicine = /* GraphQL */ `mutation DeleteDoctorMedicine(
  $input: DeleteDoctorMedicineInput!
  $condition: ModelDoctorMedicineConditionInput
) {
  deleteDoctorMedicine(input: $input, condition: $condition) {
    id
    doctorID
    medicineID
    isDeleted
    updatedAt
    _version
    _deleted
    _lastChangedAt
    createdAt
    medicine {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
	APITypes.DeleteDoctorMedicineMutationVariables,
	APITypes.DeleteDoctorMedicineMutation
>;
export const createRecentDoctorMedicinePrescription =
	/* GraphQL */ `mutation CreateRecentDoctorMedicinePrescription(
  $input: CreateRecentDoctorMedicinePrescriptionInput!
  $condition: ModelRecentDoctorMedicinePrescriptionConditionInput
) {
  createRecentDoctorMedicinePrescription(input: $input, condition: $condition) {
    id
    doctorID
    medicineID
    take
    indication
    duration
    description
    updateDateTime
    createdAt
    updatedAt
    status
    _version
    _deleted
    _lastChangedAt
    medicine {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
		APITypes.CreateRecentDoctorMedicinePrescriptionMutationVariables,
		APITypes.CreateRecentDoctorMedicinePrescriptionMutation
	>;
export const updateRecentDoctorMedicinePrescription =
	/* GraphQL */ `mutation UpdateRecentDoctorMedicinePrescription(
  $input: UpdateRecentDoctorMedicinePrescriptionInput!
  $condition: ModelRecentDoctorMedicinePrescriptionConditionInput
) {
  updateRecentDoctorMedicinePrescription(input: $input, condition: $condition) {
    id
    doctorID
    medicineID
    take
    indication
    duration
    description
    updateDateTime
    createdAt
    updatedAt
    status
    _version
    _deleted
    _lastChangedAt
    medicine {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
		APITypes.UpdateRecentDoctorMedicinePrescriptionMutationVariables,
		APITypes.UpdateRecentDoctorMedicinePrescriptionMutation
	>;
export const deleteRecentDoctorMedicinePrescription =
	/* GraphQL */ `mutation DeleteRecentDoctorMedicinePrescription(
  $input: DeleteRecentDoctorMedicinePrescriptionInput!
  $condition: ModelRecentDoctorMedicinePrescriptionConditionInput
) {
  deleteRecentDoctorMedicinePrescription(input: $input, condition: $condition) {
    id
    doctorID
    medicineID
    take
    indication
    duration
    description
    updateDateTime
    createdAt
    updatedAt
    status
    _version
    _deleted
    _lastChangedAt
    medicine {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
		APITypes.DeleteRecentDoctorMedicinePrescriptionMutationVariables,
		APITypes.DeleteRecentDoctorMedicinePrescriptionMutation
	>;
export const createRecentDoctorLabImagePrescription =
	/* GraphQL */ `mutation CreateRecentDoctorLabImagePrescription(
  $input: CreateRecentDoctorLabImagePrescriptionInput!
  $condition: ModelRecentDoctorLabImagePrescriptionConditionInput
) {
  createRecentDoctorLabImagePrescription(input: $input, condition: $condition) {
    id
    doctorID
    labImageID
    name
    description
    updateDateTime
    createdAt
    updatedAt
    status
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
		APITypes.CreateRecentDoctorLabImagePrescriptionMutationVariables,
		APITypes.CreateRecentDoctorLabImagePrescriptionMutation
	>;
export const updateRecentDoctorLabImagePrescription =
	/* GraphQL */ `mutation UpdateRecentDoctorLabImagePrescription(
  $input: UpdateRecentDoctorLabImagePrescriptionInput!
  $condition: ModelRecentDoctorLabImagePrescriptionConditionInput
) {
  updateRecentDoctorLabImagePrescription(input: $input, condition: $condition) {
    id
    doctorID
    labImageID
    name
    description
    updateDateTime
    createdAt
    updatedAt
    status
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
		APITypes.UpdateRecentDoctorLabImagePrescriptionMutationVariables,
		APITypes.UpdateRecentDoctorLabImagePrescriptionMutation
	>;
export const deleteRecentDoctorLabImagePrescription =
	/* GraphQL */ `mutation DeleteRecentDoctorLabImagePrescription(
  $input: DeleteRecentDoctorLabImagePrescriptionInput!
  $condition: ModelRecentDoctorLabImagePrescriptionConditionInput
) {
  deleteRecentDoctorLabImagePrescription(input: $input, condition: $condition) {
    id
    doctorID
    labImageID
    name
    description
    updateDateTime
    createdAt
    updatedAt
    status
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
		APITypes.DeleteRecentDoctorLabImagePrescriptionMutationVariables,
		APITypes.DeleteRecentDoctorLabImagePrescriptionMutation
	>;
export const createTemplateAnalisis = /* GraphQL */ `mutation CreateTemplateAnalisis(
  $input: CreateTemplateAnalisisInput!
  $condition: ModelTemplateAnalisisConditionInput
) {
  createTemplateAnalisis(input: $input, condition: $condition) {
    id
    templateID
    analisisID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.CreateTemplateAnalisisMutationVariables,
	APITypes.CreateTemplateAnalisisMutation
>;
export const updateTemplateAnalisis = /* GraphQL */ `mutation UpdateTemplateAnalisis(
  $input: UpdateTemplateAnalisisInput!
  $condition: ModelTemplateAnalisisConditionInput
) {
  updateTemplateAnalisis(input: $input, condition: $condition) {
    id
    templateID
    analisisID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.UpdateTemplateAnalisisMutationVariables,
	APITypes.UpdateTemplateAnalisisMutation
>;
export const deleteTemplateAnalisis = /* GraphQL */ `mutation DeleteTemplateAnalisis(
  $input: DeleteTemplateAnalisisInput!
  $condition: ModelTemplateAnalisisConditionInput
) {
  deleteTemplateAnalisis(input: $input, condition: $condition) {
    id
    templateID
    analisisID
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.DeleteTemplateAnalisisMutationVariables,
	APITypes.DeleteTemplateAnalisisMutation
>;
export const createAnalisis = /* GraphQL */ `mutation CreateAnalisis(
  $input: CreateAnalisisInput!
  $condition: ModelAnalisisConditionInput
) {
  createAnalisis(input: $input, condition: $condition) {
    id
    analisisName
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<APITypes.CreateAnalisisMutationVariables, APITypes.CreateAnalisisMutation>;
export const updateAnalisis = /* GraphQL */ `mutation UpdateAnalisis(
  $input: UpdateAnalisisInput!
  $condition: ModelAnalisisConditionInput
) {
  updateAnalisis(input: $input, condition: $condition) {
    id
    analisisName
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<APITypes.UpdateAnalisisMutationVariables, APITypes.UpdateAnalisisMutation>;
export const deleteAnalisis = /* GraphQL */ `mutation DeleteAnalisis(
  $input: DeleteAnalisisInput!
  $condition: ModelAnalisisConditionInput
) {
  deleteAnalisis(input: $input, condition: $condition) {
    id
    analisisName
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<APITypes.DeleteAnalisisMutationVariables, APITypes.DeleteAnalisisMutation>;
export const createClientActiveDisease = /* GraphQL */ `mutation CreateClientActiveDisease(
  $input: CreateClientActiveDiseaseInput!
  $condition: ModelClientActiveDiseaseConditionInput
) {
  createClientActiveDisease(input: $input, condition: $condition) {
    id
    clientID
    name
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.CreateClientActiveDiseaseMutationVariables,
	APITypes.CreateClientActiveDiseaseMutation
>;
export const updateClientActiveDisease = /* GraphQL */ `mutation UpdateClientActiveDisease(
  $input: UpdateClientActiveDiseaseInput!
  $condition: ModelClientActiveDiseaseConditionInput
) {
  updateClientActiveDisease(input: $input, condition: $condition) {
    id
    clientID
    name
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.UpdateClientActiveDiseaseMutationVariables,
	APITypes.UpdateClientActiveDiseaseMutation
>;
export const deleteClientActiveDisease = /* GraphQL */ `mutation DeleteClientActiveDisease(
  $input: DeleteClientActiveDiseaseInput!
  $condition: ModelClientActiveDiseaseConditionInput
) {
  deleteClientActiveDisease(input: $input, condition: $condition) {
    id
    clientID
    name
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.DeleteClientActiveDiseaseMutationVariables,
	APITypes.DeleteClientActiveDiseaseMutation
>;
export const createClientHistory = /* GraphQL */ `mutation CreateClientHistory(
  $input: CreateClientHistoryInput!
  $condition: ModelClientHistoryConditionInput
) {
  createClientHistory(input: $input, condition: $condition) {
    id
    clientHospitalDoctorID
    title
    treatment
    endModifyDate
    authorizationNumber
    description
    authorizationDetail
    personalPathologicalHistory
    familyPathologicalHistory
    physicalTest
    createdDate
    insuranceID
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.CreateClientHistoryMutationVariables,
	APITypes.CreateClientHistoryMutation
>;
export const updateClientHistory = /* GraphQL */ `mutation UpdateClientHistory(
  $input: UpdateClientHistoryInput!
  $condition: ModelClientHistoryConditionInput
) {
  updateClientHistory(input: $input, condition: $condition) {
    id
    clientHospitalDoctorID
    title
    treatment
    endModifyDate
    authorizationNumber
    description
    authorizationDetail
    personalPathologicalHistory
    familyPathologicalHistory
    physicalTest
    createdDate
    insuranceID
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.UpdateClientHistoryMutationVariables,
	APITypes.UpdateClientHistoryMutation
>;
export const deleteClientHistory = /* GraphQL */ `mutation DeleteClientHistory(
  $input: DeleteClientHistoryInput!
  $condition: ModelClientHistoryConditionInput
) {
  deleteClientHistory(input: $input, condition: $condition) {
    id
    clientHospitalDoctorID
    title
    treatment
    endModifyDate
    authorizationNumber
    description
    authorizationDetail
    personalPathologicalHistory
    familyPathologicalHistory
    physicalTest
    createdDate
    insuranceID
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.DeleteClientHistoryMutationVariables,
	APITypes.DeleteClientHistoryMutation
>;
export const createClientNotes = /* GraphQL */ `mutation CreateClientNotes(
  $input: CreateClientNotesInput!
  $condition: ModelClientNotesConditionInput
) {
  createClientNotes(input: $input, condition: $condition) {
    id
    clientHospitalDoctorID
    title
    noteBody
    endModifyDate
    createdDate
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.CreateClientNotesMutationVariables,
	APITypes.CreateClientNotesMutation
>;
export const updateClientNotes = /* GraphQL */ `mutation UpdateClientNotes(
  $input: UpdateClientNotesInput!
  $condition: ModelClientNotesConditionInput
) {
  updateClientNotes(input: $input, condition: $condition) {
    id
    clientHospitalDoctorID
    title
    noteBody
    endModifyDate
    createdDate
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.UpdateClientNotesMutationVariables,
	APITypes.UpdateClientNotesMutation
>;
export const deleteClientNotes = /* GraphQL */ `mutation DeleteClientNotes(
  $input: DeleteClientNotesInput!
  $condition: ModelClientNotesConditionInput
) {
  deleteClientNotes(input: $input, condition: $condition) {
    id
    clientHospitalDoctorID
    title
    noteBody
    endModifyDate
    createdDate
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.DeleteClientNotesMutationVariables,
	APITypes.DeleteClientNotesMutation
>;
export const createClientProcedureSurgery = /* GraphQL */ `mutation CreateClientProcedureSurgery(
  $input: CreateClientProcedureSurgeryInput!
  $condition: ModelClientProcedureSurgeryConditionInput
) {
  createClientProcedureSurgery(input: $input, condition: $condition) {
    id
    clientHospitalDoctorID
    title
    description
    endModifyDate
    createdDate
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.CreateClientProcedureSurgeryMutationVariables,
	APITypes.CreateClientProcedureSurgeryMutation
>;
export const updateClientProcedureSurgery = /* GraphQL */ `mutation UpdateClientProcedureSurgery(
  $input: UpdateClientProcedureSurgeryInput!
  $condition: ModelClientProcedureSurgeryConditionInput
) {
  updateClientProcedureSurgery(input: $input, condition: $condition) {
    id
    clientHospitalDoctorID
    title
    description
    endModifyDate
    createdDate
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.UpdateClientProcedureSurgeryMutationVariables,
	APITypes.UpdateClientProcedureSurgeryMutation
>;
export const deleteClientProcedureSurgery = /* GraphQL */ `mutation DeleteClientProcedureSurgery(
  $input: DeleteClientProcedureSurgeryInput!
  $condition: ModelClientProcedureSurgeryConditionInput
) {
  deleteClientProcedureSurgery(input: $input, condition: $condition) {
    id
    clientHospitalDoctorID
    title
    description
    endModifyDate
    createdDate
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.DeleteClientProcedureSurgeryMutationVariables,
	APITypes.DeleteClientProcedureSurgeryMutation
>;
export const createDoctor = /* GraphQL */ `mutation CreateDoctor(
  $input: CreateDoctorInput!
  $condition: ModelDoctorConditionInput
) {
  createDoctor(input: $input, condition: $condition) {
    id
    name
    specialty
    exequator
    email
    firstTime
    studyPlace
    isBeenReview
    isFirstAddSecretary
    hasTrialOnCreation
    title
    phone
    code
    password
    isAdmin
    daysOfTrial
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<APITypes.CreateDoctorMutationVariables, APITypes.CreateDoctorMutation>;
export const updateDoctor = /* GraphQL */ `mutation UpdateDoctor(
  $input: UpdateDoctorInput!
  $condition: ModelDoctorConditionInput
) {
  updateDoctor(input: $input, condition: $condition) {
    id
    name
    specialty
    exequator
    email
    firstTime
    studyPlace
    isBeenReview
    isFirstAddSecretary
    hasTrialOnCreation
    title
    phone
    code
    password
    isAdmin
    daysOfTrial
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<APITypes.UpdateDoctorMutationVariables, APITypes.UpdateDoctorMutation>;
export const deleteDoctor = /* GraphQL */ `mutation DeleteDoctor(
  $input: DeleteDoctorInput!
  $condition: ModelDoctorConditionInput
) {
  deleteDoctor(input: $input, condition: $condition) {
    id
    name
    specialty
    exequator
    email
    firstTime
    studyPlace
    isBeenReview
    isFirstAddSecretary
    hasTrialOnCreation
    title
    phone
    code
    password
    isAdmin
    daysOfTrial
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<APITypes.DeleteDoctorMutationVariables, APITypes.DeleteDoctorMutation>;
export const createPlan = /* GraphQL */ `mutation CreatePlan(
  $input: CreatePlanInput!
  $condition: ModelPlanConditionInput
) {
  createPlan(input: $input, condition: $condition) {
    id
    refID
    title
    subTitle
    stripePlanKey
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<APITypes.CreatePlanMutationVariables, APITypes.CreatePlanMutation>;
export const updatePlan = /* GraphQL */ `mutation UpdatePlan(
  $input: UpdatePlanInput!
  $condition: ModelPlanConditionInput
) {
  updatePlan(input: $input, condition: $condition) {
    id
    refID
    title
    subTitle
    stripePlanKey
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<APITypes.UpdatePlanMutationVariables, APITypes.UpdatePlanMutation>;
export const deletePlan = /* GraphQL */ `mutation DeletePlan(
  $input: DeletePlanInput!
  $condition: ModelPlanConditionInput
) {
  deletePlan(input: $input, condition: $condition) {
    id
    refID
    title
    subTitle
    stripePlanKey
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<APITypes.DeletePlanMutationVariables, APITypes.DeletePlanMutation>;
export const createDoctorStripeCustomerSubscription =
	/* GraphQL */ `mutation CreateDoctorStripeCustomerSubscription(
  $input: CreateDoctorStripeCustomerSubscriptionInput!
  $condition: ModelDoctorStripeCustomerSubscriptionConditionInput
) {
  createDoctorStripeCustomerSubscription(input: $input, condition: $condition) {
    id
    doctorID
    currentPlanID
    stripeCustomerID
    stripeSubscriptionID
    defaultCard
    nextPaymentDate
    cancelAtPeriodEnd
    trialsEndsAt
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
		APITypes.CreateDoctorStripeCustomerSubscriptionMutationVariables,
		APITypes.CreateDoctorStripeCustomerSubscriptionMutation
	>;
export const updateDoctorStripeCustomerSubscription =
	/* GraphQL */ `mutation UpdateDoctorStripeCustomerSubscription(
  $input: UpdateDoctorStripeCustomerSubscriptionInput!
  $condition: ModelDoctorStripeCustomerSubscriptionConditionInput
) {
  updateDoctorStripeCustomerSubscription(input: $input, condition: $condition) {
    id
    doctorID
    currentPlanID
    stripeCustomerID
    stripeSubscriptionID
    defaultCard
    nextPaymentDate
    cancelAtPeriodEnd
    trialsEndsAt
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
		APITypes.UpdateDoctorStripeCustomerSubscriptionMutationVariables,
		APITypes.UpdateDoctorStripeCustomerSubscriptionMutation
	>;
export const deleteDoctorStripeCustomerSubscription =
	/* GraphQL */ `mutation DeleteDoctorStripeCustomerSubscription(
  $input: DeleteDoctorStripeCustomerSubscriptionInput!
  $condition: ModelDoctorStripeCustomerSubscriptionConditionInput
) {
  deleteDoctorStripeCustomerSubscription(input: $input, condition: $condition) {
    id
    doctorID
    currentPlanID
    stripeCustomerID
    stripeSubscriptionID
    defaultCard
    nextPaymentDate
    cancelAtPeriodEnd
    trialsEndsAt
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
		APITypes.DeleteDoctorStripeCustomerSubscriptionMutationVariables,
		APITypes.DeleteDoctorStripeCustomerSubscriptionMutation
	>;
export const createDoctorStripeCards = /* GraphQL */ `mutation CreateDoctorStripeCards(
  $input: CreateDoctorStripeCardsInput!
  $condition: ModelDoctorStripeCardsConditionInput
) {
  createDoctorStripeCards(input: $input, condition: $condition) {
    id
    DoctorID
    cardLastDigits
    stripePaymentMethodID
    defaultCard
    cardBrand
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.CreateDoctorStripeCardsMutationVariables,
	APITypes.CreateDoctorStripeCardsMutation
>;
export const updateDoctorStripeCards = /* GraphQL */ `mutation UpdateDoctorStripeCards(
  $input: UpdateDoctorStripeCardsInput!
  $condition: ModelDoctorStripeCardsConditionInput
) {
  updateDoctorStripeCards(input: $input, condition: $condition) {
    id
    DoctorID
    cardLastDigits
    stripePaymentMethodID
    defaultCard
    cardBrand
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.UpdateDoctorStripeCardsMutationVariables,
	APITypes.UpdateDoctorStripeCardsMutation
>;
export const deleteDoctorStripeCards = /* GraphQL */ `mutation DeleteDoctorStripeCards(
  $input: DeleteDoctorStripeCardsInput!
  $condition: ModelDoctorStripeCardsConditionInput
) {
  deleteDoctorStripeCards(input: $input, condition: $condition) {
    id
    DoctorID
    cardLastDigits
    stripePaymentMethodID
    defaultCard
    cardBrand
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.DeleteDoctorStripeCardsMutationVariables,
	APITypes.DeleteDoctorStripeCardsMutation
>;
export const createHospital = /* GraphQL */ `mutation CreateHospital(
  $input: CreateHospitalInput!
  $condition: ModelHospitalConditionInput
) {
  createHospital(input: $input, condition: $condition) {
    id
    name
    type
    province
    town
    sector
    phone
    address
    pssCode
    rnc
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<APITypes.CreateHospitalMutationVariables, APITypes.CreateHospitalMutation>;
export const updateHospital = /* GraphQL */ `mutation UpdateHospital(
  $input: UpdateHospitalInput!
  $condition: ModelHospitalConditionInput
) {
  updateHospital(input: $input, condition: $condition) {
    id
    name
    type
    province
    town
    sector
    phone
    address
    pssCode
    rnc
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<APITypes.UpdateHospitalMutationVariables, APITypes.UpdateHospitalMutation>;
export const deleteHospital = /* GraphQL */ `mutation DeleteHospital(
  $input: DeleteHospitalInput!
  $condition: ModelHospitalConditionInput
) {
  deleteHospital(input: $input, condition: $condition) {
    id
    name
    type
    province
    town
    sector
    phone
    address
    pssCode
    rnc
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<APITypes.DeleteHospitalMutationVariables, APITypes.DeleteHospitalMutation>;
export const createHospitalDoctor = /* GraphQL */ `mutation CreateHospitalDoctor(
  $input: CreateHospitalDoctorInput!
  $condition: ModelHospitalDoctorConditionInput
) {
  createHospitalDoctor(input: $input, condition: $condition) {
    id
    doctorID
    hospitalID
    lastWaitingListID
    hospitalLogo
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    doctor {
      id
      name
      specialty
      exequator
      email
      firstTime
      studyPlace
      isBeenReview
      isFirstAddSecretary
      hasTrialOnCreation
      title
      phone
      code
      password
      isAdmin
      daysOfTrial
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    hospital {
      id
      name
      type
      province
      town
      sector
      phone
      address
      pssCode
      rnc
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
	APITypes.CreateHospitalDoctorMutationVariables,
	APITypes.CreateHospitalDoctorMutation
>;
export const updateHospitalDoctor = /* GraphQL */ `mutation UpdateHospitalDoctor(
  $input: UpdateHospitalDoctorInput!
  $condition: ModelHospitalDoctorConditionInput
) {
  updateHospitalDoctor(input: $input, condition: $condition) {
    id
    doctorID
    hospitalID
    lastWaitingListID
    hospitalLogo
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    doctor {
      id
      name
      specialty
      exequator
      email
      firstTime
      studyPlace
      isBeenReview
      isFirstAddSecretary
      hasTrialOnCreation
      title
      phone
      code
      password
      isAdmin
      daysOfTrial
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    hospital {
      id
      name
      type
      province
      town
      sector
      phone
      address
      pssCode
      rnc
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
	APITypes.UpdateHospitalDoctorMutationVariables,
	APITypes.UpdateHospitalDoctorMutation
>;
export const deleteHospitalDoctor = /* GraphQL */ `mutation DeleteHospitalDoctor(
  $input: DeleteHospitalDoctorInput!
  $condition: ModelHospitalDoctorConditionInput
) {
  deleteHospitalDoctor(input: $input, condition: $condition) {
    id
    doctorID
    hospitalID
    lastWaitingListID
    hospitalLogo
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    doctor {
      id
      name
      specialty
      exequator
      email
      firstTime
      studyPlace
      isBeenReview
      isFirstAddSecretary
      hasTrialOnCreation
      title
      phone
      code
      password
      isAdmin
      daysOfTrial
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    hospital {
      id
      name
      type
      province
      town
      sector
      phone
      address
      pssCode
      rnc
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
	APITypes.DeleteHospitalDoctorMutationVariables,
	APITypes.DeleteHospitalDoctorMutation
>;
export const createTeams = /* GraphQL */ `mutation CreateTeams(
  $input: CreateTeamsInput!
  $condition: ModelTeamsConditionInput
) {
  createTeams(input: $input, condition: $condition) {
    id
    OwnerID
    MemberID
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    Owner {
      id
      doctorID
      hospitalID
      lastWaitingListID
      hospitalLogo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospital {
        id
        name
        type
        province
        town
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    Member {
      id
      doctorID
      hospitalID
      lastWaitingListID
      hospitalLogo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospital {
        id
        name
        type
        province
        town
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<APITypes.CreateTeamsMutationVariables, APITypes.CreateTeamsMutation>;
export const updateTeams = /* GraphQL */ `mutation UpdateTeams(
  $input: UpdateTeamsInput!
  $condition: ModelTeamsConditionInput
) {
  updateTeams(input: $input, condition: $condition) {
    id
    OwnerID
    MemberID
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    Owner {
      id
      doctorID
      hospitalID
      lastWaitingListID
      hospitalLogo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospital {
        id
        name
        type
        province
        town
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    Member {
      id
      doctorID
      hospitalID
      lastWaitingListID
      hospitalLogo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospital {
        id
        name
        type
        province
        town
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<APITypes.UpdateTeamsMutationVariables, APITypes.UpdateTeamsMutation>;
export const deleteTeams = /* GraphQL */ `mutation DeleteTeams(
  $input: DeleteTeamsInput!
  $condition: ModelTeamsConditionInput
) {
  deleteTeams(input: $input, condition: $condition) {
    id
    OwnerID
    MemberID
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    Owner {
      id
      doctorID
      hospitalID
      lastWaitingListID
      hospitalLogo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospital {
        id
        name
        type
        province
        town
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    Member {
      id
      doctorID
      hospitalID
      lastWaitingListID
      hospitalLogo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospital {
        id
        name
        type
        province
        town
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<APITypes.DeleteTeamsMutationVariables, APITypes.DeleteTeamsMutation>;
export const createHospitalDoctorCliente = /* GraphQL */ `mutation CreateHospitalDoctorCliente(
  $input: CreateHospitalDoctorClienteInput!
  $condition: ModelHospitalDoctorClienteConditionInput
) {
  createHospitalDoctorCliente(input: $input, condition: $condition) {
    id
    clientID
    hospitalDoctorID
    lastHealthInsurranceID
    clientStatus
    note
    recordNo
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    client {
      id
      identificationName
      identificationData
      actualInsurance
      name
      lastName
      cellphoneNumber
      email
      bornDate
      parentOrTutorCedula
      parentOrTutorID
      gender
      phoneNumber
      addressStreet
      city
      isAdult
      sector
      bloodType
      company
      vitalSignsHeight
      vitalSignsWeight
      bodyMass
      vitalSignsBloodPressure
      heartRate
      respiratoryRate
      bodyTemperature
      oxygenSaturation
      occupation
      profileImage
      heartBeat
      weight
      height
      bloodPressure
      updateDateTime
      createdAt
      updatedAt
      code
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    hospitalDoctor {
      id
      doctorID
      hospitalID
      lastWaitingListID
      hospitalLogo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospital {
        id
        name
        type
        province
        town
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    clientInsurance {
      id
      insuranceID
      clientID
      contractNumber
      affiliateNumber
      affiliateType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      insurance {
        id
        name
        allowClaim
        code
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
	APITypes.CreateHospitalDoctorClienteMutationVariables,
	APITypes.CreateHospitalDoctorClienteMutation
>;
export const updateHospitalDoctorCliente = /* GraphQL */ `mutation UpdateHospitalDoctorCliente(
  $input: UpdateHospitalDoctorClienteInput!
  $condition: ModelHospitalDoctorClienteConditionInput
) {
  updateHospitalDoctorCliente(input: $input, condition: $condition) {
    id
    clientID
    hospitalDoctorID
    lastHealthInsurranceID
    clientStatus
    note
    recordNo
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    client {
      id
      identificationName
      identificationData
      actualInsurance
      name
      lastName
      cellphoneNumber
      email
      bornDate
      parentOrTutorCedula
      parentOrTutorID
      gender
      phoneNumber
      addressStreet
      city
      isAdult
      sector
      bloodType
      company
      vitalSignsHeight
      vitalSignsWeight
      bodyMass
      vitalSignsBloodPressure
      heartRate
      respiratoryRate
      bodyTemperature
      oxygenSaturation
      occupation
      profileImage
      heartBeat
      weight
      height
      bloodPressure
      updateDateTime
      createdAt
      updatedAt
      code
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    hospitalDoctor {
      id
      doctorID
      hospitalID
      lastWaitingListID
      hospitalLogo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospital {
        id
        name
        type
        province
        town
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    clientInsurance {
      id
      insuranceID
      clientID
      contractNumber
      affiliateNumber
      affiliateType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      insurance {
        id
        name
        allowClaim
        code
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
	APITypes.UpdateHospitalDoctorClienteMutationVariables,
	APITypes.UpdateHospitalDoctorClienteMutation
>;
export const deleteHospitalDoctorCliente = /* GraphQL */ `mutation DeleteHospitalDoctorCliente(
  $input: DeleteHospitalDoctorClienteInput!
  $condition: ModelHospitalDoctorClienteConditionInput
) {
  deleteHospitalDoctorCliente(input: $input, condition: $condition) {
    id
    clientID
    hospitalDoctorID
    lastHealthInsurranceID
    clientStatus
    note
    recordNo
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    client {
      id
      identificationName
      identificationData
      actualInsurance
      name
      lastName
      cellphoneNumber
      email
      bornDate
      parentOrTutorCedula
      parentOrTutorID
      gender
      phoneNumber
      addressStreet
      city
      isAdult
      sector
      bloodType
      company
      vitalSignsHeight
      vitalSignsWeight
      bodyMass
      vitalSignsBloodPressure
      heartRate
      respiratoryRate
      bodyTemperature
      oxygenSaturation
      occupation
      profileImage
      heartBeat
      weight
      height
      bloodPressure
      updateDateTime
      createdAt
      updatedAt
      code
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    hospitalDoctor {
      id
      doctorID
      hospitalID
      lastWaitingListID
      hospitalLogo
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      doctor {
        id
        name
        specialty
        exequator
        email
        firstTime
        studyPlace
        isBeenReview
        isFirstAddSecretary
        hasTrialOnCreation
        title
        phone
        code
        password
        isAdmin
        daysOfTrial
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      hospital {
        id
        name
        type
        province
        town
        sector
        phone
        address
        pssCode
        rnc
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    clientInsurance {
      id
      insuranceID
      clientID
      contractNumber
      affiliateNumber
      affiliateType
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      insurance {
        id
        name
        allowClaim
        code
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
	APITypes.DeleteHospitalDoctorClienteMutationVariables,
	APITypes.DeleteHospitalDoctorClienteMutation
>;
export const createWaitList = /* GraphQL */ `mutation CreateWaitList(
  $input: CreateWaitListInput!
  $condition: ModelWaitListConditionInput
) {
  createWaitList(input: $input, condition: $condition) {
    id
    hospitalDoctorID
    createdAt
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<APITypes.CreateWaitListMutationVariables, APITypes.CreateWaitListMutation>;
export const updateWaitList = /* GraphQL */ `mutation UpdateWaitList(
  $input: UpdateWaitListInput!
  $condition: ModelWaitListConditionInput
) {
  updateWaitList(input: $input, condition: $condition) {
    id
    hospitalDoctorID
    createdAt
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<APITypes.UpdateWaitListMutationVariables, APITypes.UpdateWaitListMutation>;
export const deleteWaitList = /* GraphQL */ `mutation DeleteWaitList(
  $input: DeleteWaitListInput!
  $condition: ModelWaitListConditionInput
) {
  deleteWaitList(input: $input, condition: $condition) {
    id
    hospitalDoctorID
    createdAt
    _version
    _deleted
    _lastChangedAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<APITypes.DeleteWaitListMutationVariables, APITypes.DeleteWaitListMutation>;
export const createWaitingListItem = /* GraphQL */ `mutation CreateWaitingListItem(
  $input: CreateWaitingListItemInput!
  $condition: ModelWaitingListItemConditionInput
) {
  createWaitingListItem(input: $input, condition: $condition) {
    id
    waitingListID
    clientID
    status
    clientHealthInsurrance
    positionNumber
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.CreateWaitingListItemMutationVariables,
	APITypes.CreateWaitingListItemMutation
>;
export const updateWaitingListItem = /* GraphQL */ `mutation UpdateWaitingListItem(
  $input: UpdateWaitingListItemInput!
  $condition: ModelWaitingListItemConditionInput
) {
  updateWaitingListItem(input: $input, condition: $condition) {
    id
    waitingListID
    clientID
    status
    clientHealthInsurrance
    positionNumber
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.UpdateWaitingListItemMutationVariables,
	APITypes.UpdateWaitingListItemMutation
>;
export const deleteWaitingListItem = /* GraphQL */ `mutation DeleteWaitingListItem(
  $input: DeleteWaitingListItemInput!
  $condition: ModelWaitingListItemConditionInput
) {
  deleteWaitingListItem(input: $input, condition: $condition) {
    id
    waitingListID
    clientID
    status
    clientHealthInsurrance
    positionNumber
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.DeleteWaitingListItemMutationVariables,
	APITypes.DeleteWaitingListItemMutation
>;
export const createClientInsurance = /* GraphQL */ `mutation CreateClientInsurance(
  $input: CreateClientInsuranceInput!
  $condition: ModelClientInsuranceConditionInput
) {
  createClientInsurance(input: $input, condition: $condition) {
    id
    insuranceID
    clientID
    contractNumber
    affiliateNumber
    affiliateType
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    insurance {
      id
      name
      allowClaim
      code
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
	APITypes.CreateClientInsuranceMutationVariables,
	APITypes.CreateClientInsuranceMutation
>;
export const updateClientInsurance = /* GraphQL */ `mutation UpdateClientInsurance(
  $input: UpdateClientInsuranceInput!
  $condition: ModelClientInsuranceConditionInput
) {
  updateClientInsurance(input: $input, condition: $condition) {
    id
    insuranceID
    clientID
    contractNumber
    affiliateNumber
    affiliateType
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    insurance {
      id
      name
      allowClaim
      code
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
	APITypes.UpdateClientInsuranceMutationVariables,
	APITypes.UpdateClientInsuranceMutation
>;
export const deleteClientInsurance = /* GraphQL */ `mutation DeleteClientInsurance(
  $input: DeleteClientInsuranceInput!
  $condition: ModelClientInsuranceConditionInput
) {
  deleteClientInsurance(input: $input, condition: $condition) {
    id
    insuranceID
    clientID
    contractNumber
    affiliateNumber
    affiliateType
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    insurance {
      id
      name
      allowClaim
      code
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
	APITypes.DeleteClientInsuranceMutationVariables,
	APITypes.DeleteClientInsuranceMutation
>;
export const createInsurance = /* GraphQL */ `mutation CreateInsurance(
  $input: CreateInsuranceInput!
  $condition: ModelInsuranceConditionInput
) {
  createInsurance(input: $input, condition: $condition) {
    id
    name
    allowClaim
    code
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<APITypes.CreateInsuranceMutationVariables, APITypes.CreateInsuranceMutation>;
export const updateInsurance = /* GraphQL */ `mutation UpdateInsurance(
  $input: UpdateInsuranceInput!
  $condition: ModelInsuranceConditionInput
) {
  updateInsurance(input: $input, condition: $condition) {
    id
    name
    allowClaim
    code
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<APITypes.UpdateInsuranceMutationVariables, APITypes.UpdateInsuranceMutation>;
export const deleteInsurance = /* GraphQL */ `mutation DeleteInsurance(
  $input: DeleteInsuranceInput!
  $condition: ModelInsuranceConditionInput
) {
  deleteInsurance(input: $input, condition: $condition) {
    id
    name
    allowClaim
    code
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<APITypes.DeleteInsuranceMutationVariables, APITypes.DeleteInsuranceMutation>;
export const createClientClaim = /* GraphQL */ `mutation CreateClientClaim(
  $input: CreateClientClaimInput!
  $condition: ModelClientClaimConditionInput
) {
  createClientClaim(input: $input, condition: $condition) {
    id
    clientHospitalDoctorID
    healthInsuranceID
    diagnostic
    authorization
    date
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    healthInsurance {
      id
      name
      allowClaim
      code
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
	APITypes.CreateClientClaimMutationVariables,
	APITypes.CreateClientClaimMutation
>;
export const updateClientClaim = /* GraphQL */ `mutation UpdateClientClaim(
  $input: UpdateClientClaimInput!
  $condition: ModelClientClaimConditionInput
) {
  updateClientClaim(input: $input, condition: $condition) {
    id
    clientHospitalDoctorID
    healthInsuranceID
    diagnostic
    authorization
    date
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    healthInsurance {
      id
      name
      allowClaim
      code
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
	APITypes.UpdateClientClaimMutationVariables,
	APITypes.UpdateClientClaimMutation
>;
export const deleteClientClaim = /* GraphQL */ `mutation DeleteClientClaim(
  $input: DeleteClientClaimInput!
  $condition: ModelClientClaimConditionInput
) {
  deleteClientClaim(input: $input, condition: $condition) {
    id
    clientHospitalDoctorID
    healthInsuranceID
    diagnostic
    authorization
    date
    status
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    healthInsurance {
      id
      name
      allowClaim
      code
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
	APITypes.DeleteClientClaimMutationVariables,
	APITypes.DeleteClientClaimMutation
>;
export const createClaimProcess = /* GraphQL */ `mutation CreateClaimProcess(
  $input: CreateClaimProcessInput!
  $condition: ModelClaimProcessConditionInput
) {
  createClaimProcess(input: $input, condition: $condition) {
    id
    claimID
    name
    code
    claimed
    difference
    authorized
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.CreateClaimProcessMutationVariables,
	APITypes.CreateClaimProcessMutation
>;
export const updateClaimProcess = /* GraphQL */ `mutation UpdateClaimProcess(
  $input: UpdateClaimProcessInput!
  $condition: ModelClaimProcessConditionInput
) {
  updateClaimProcess(input: $input, condition: $condition) {
    id
    claimID
    name
    code
    claimed
    difference
    authorized
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.UpdateClaimProcessMutationVariables,
	APITypes.UpdateClaimProcessMutation
>;
export const deleteClaimProcess = /* GraphQL */ `mutation DeleteClaimProcess(
  $input: DeleteClaimProcessInput!
  $condition: ModelClaimProcessConditionInput
) {
  deleteClaimProcess(input: $input, condition: $condition) {
    id
    claimID
    name
    code
    claimed
    difference
    authorized
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
	APITypes.DeleteClaimProcessMutationVariables,
	APITypes.DeleteClaimProcessMutation
>;
export const createForms = /* GraphQL */ `mutation CreateForms(
  $input: CreateFormsInput!
  $condition: ModelFormsConditionInput
) {
  createForms(input: $input, condition: $condition) {
    id
    DoctorID
    public
    name
    formImage
    description
    elements
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<APITypes.CreateFormsMutationVariables, APITypes.CreateFormsMutation>;
export const updateForms = /* GraphQL */ `mutation UpdateForms(
  $input: UpdateFormsInput!
  $condition: ModelFormsConditionInput
) {
  updateForms(input: $input, condition: $condition) {
    id
    DoctorID
    public
    name
    formImage
    description
    elements
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<APITypes.UpdateFormsMutationVariables, APITypes.UpdateFormsMutation>;
export const deleteForms = /* GraphQL */ `mutation DeleteForms(
  $input: DeleteFormsInput!
  $condition: ModelFormsConditionInput
) {
  deleteForms(input: $input, condition: $condition) {
    id
    DoctorID
    public
    name
    formImage
    description
    elements
    _version
    _deleted
    _lastChangedAt
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<APITypes.DeleteFormsMutationVariables, APITypes.DeleteFormsMutation>;
export const createAppointment = /* GraphQL */ `mutation CreateAppointment(
  $input: CreateAppointmentInput!
  $condition: ModelAppointmentConditionInput
) {
  createAppointment(input: $input, condition: $condition) {
    id
    clientID
    doctorID
    hospitalID
    description
    date
    start
    end
    status
    shouldSendReminder
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    client {
      id
      identificationName
      identificationData
      actualInsurance
      name
      lastName
      cellphoneNumber
      email
      bornDate
      parentOrTutorCedula
      parentOrTutorID
      gender
      phoneNumber
      addressStreet
      city
      isAdult
      sector
      bloodType
      company
      vitalSignsHeight
      vitalSignsWeight
      bodyMass
      vitalSignsBloodPressure
      heartRate
      respiratoryRate
      bodyTemperature
      oxygenSaturation
      occupation
      profileImage
      heartBeat
      weight
      height
      bloodPressure
      updateDateTime
      createdAt
      updatedAt
      code
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    doctor {
      id
      name
      specialty
      exequator
      email
      firstTime
      studyPlace
      isBeenReview
      isFirstAddSecretary
      hasTrialOnCreation
      title
      phone
      code
      password
      isAdmin
      daysOfTrial
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    hospital {
      id
      name
      type
      province
      town
      sector
      phone
      address
      pssCode
      rnc
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
	APITypes.CreateAppointmentMutationVariables,
	APITypes.CreateAppointmentMutation
>;
export const updateAppointment = /* GraphQL */ `mutation UpdateAppointment(
  $input: UpdateAppointmentInput!
  $condition: ModelAppointmentConditionInput
) {
  updateAppointment(input: $input, condition: $condition) {
    id
    clientID
    doctorID
    hospitalID
    description
    date
    start
    end
    status
    shouldSendReminder
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    client {
      id
      identificationName
      identificationData
      actualInsurance
      name
      lastName
      cellphoneNumber
      email
      bornDate
      parentOrTutorCedula
      parentOrTutorID
      gender
      phoneNumber
      addressStreet
      city
      isAdult
      sector
      bloodType
      company
      vitalSignsHeight
      vitalSignsWeight
      bodyMass
      vitalSignsBloodPressure
      heartRate
      respiratoryRate
      bodyTemperature
      oxygenSaturation
      occupation
      profileImage
      heartBeat
      weight
      height
      bloodPressure
      updateDateTime
      createdAt
      updatedAt
      code
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    doctor {
      id
      name
      specialty
      exequator
      email
      firstTime
      studyPlace
      isBeenReview
      isFirstAddSecretary
      hasTrialOnCreation
      title
      phone
      code
      password
      isAdmin
      daysOfTrial
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    hospital {
      id
      name
      type
      province
      town
      sector
      phone
      address
      pssCode
      rnc
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
	APITypes.UpdateAppointmentMutationVariables,
	APITypes.UpdateAppointmentMutation
>;
export const deleteAppointment = /* GraphQL */ `mutation DeleteAppointment(
  $input: DeleteAppointmentInput!
  $condition: ModelAppointmentConditionInput
) {
  deleteAppointment(input: $input, condition: $condition) {
    id
    clientID
    doctorID
    hospitalID
    description
    date
    start
    end
    status
    shouldSendReminder
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    client {
      id
      identificationName
      identificationData
      actualInsurance
      name
      lastName
      cellphoneNumber
      email
      bornDate
      parentOrTutorCedula
      parentOrTutorID
      gender
      phoneNumber
      addressStreet
      city
      isAdult
      sector
      bloodType
      company
      vitalSignsHeight
      vitalSignsWeight
      bodyMass
      vitalSignsBloodPressure
      heartRate
      respiratoryRate
      bodyTemperature
      oxygenSaturation
      occupation
      profileImage
      heartBeat
      weight
      height
      bloodPressure
      updateDateTime
      createdAt
      updatedAt
      code
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    doctor {
      id
      name
      specialty
      exequator
      email
      firstTime
      studyPlace
      isBeenReview
      isFirstAddSecretary
      hasTrialOnCreation
      title
      phone
      code
      password
      isAdmin
      daysOfTrial
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    hospital {
      id
      name
      type
      province
      town
      sector
      phone
      address
      pssCode
      rnc
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
	APITypes.DeleteAppointmentMutationVariables,
	APITypes.DeleteAppointmentMutation
>;
