import { ClientPrescriptionAnalisis, ClientPrescriptionMedicine } from 'models';
import { ConsultationDiagnosticType, IClientFilter, MedicineType } from '../context/types';
import moment from 'moment';

export interface Option {
	id: number | string;
	label: string;
}

export interface ImageFile {
	name: string;
	tooltip?: string;
	extension?: string;
	label: string;
	file?: File;
}

export interface IColumnType<T> {
	key: string;
	title: string;
	width?: number;
	fontWeight?: number;
	render?: (column: IColumnType<T>, item: T) => void;
	align?: 'center' | 'inherit' | 'justify' | 'left' | 'right';
	size?: 'medium' | 'small';
	isDisabled?: boolean;
	padding?: string;
}

export interface SelectedDiagnosis {
	id: number | string;
	label: string;
	code: string;
}
export interface DiagnosisOptions {
	id: number | string;
	label: string;
	code: string;
}

export interface MedicineConsultation extends MedicineType {
	medicineName: string;
}

export enum SummaryTab {
	summary = 0,
	consultation = 1,
	history = 2,
	prescription = 3,
	claim = 4,
	procedure = 5,
	files = 6,
	ai_diagnostic = 7,
}

export enum StripeError {
	payment_method_unsupported_type = 'El método de pago no esta soportado por la aplicación.',
	processing_error = 'Ocurrió un error al procesar la tarjeta. Vuelve a intentarlo más tarde o con otro método de pago.',
	routing_number_invalid = 'El número de ruta bancaria proporcionado no es válido.',
	status_transition_invalid = 'La transición de estado solicitada no es válida.',
	transfers_not_allowed = 'No se puede crear la transferencia solicitada. Póngase en contacto con nosotros si recibe este error.',
	payment_method_provider_decline = 'El emisor o el cliente rechazó el pago o el intento de configuración.',
	payment_method_customer_decline = 'El cliente no aprobó el pago. Proporcione un nuevo método de pago para intentar cumplir con esta intención nuevamente.',
	payment_method_bank_account_blocked = 'This bank account has failed verification in the past and can not be used. Contact us if you wish to attempt to use these bank account credentials.',
	payment_intent_unexpected_state = 'El estado de pago era incompatible con la operación que intentabas realizar.',
	payment_intent_payment_attempt_failed = 'El último intento de pago ha fallado.',
	no_account = 'The bank account could not be located.',
	invalid_number = 'El número de tarjeta no es válido. Verifique los detalles de la tarjeta o use una tarjeta diferente.',
	invalid_expiry_year = 'El año de vencimiento de la tarjeta es incorrecto. Verifique la fecha de vencimiento o use una tarjeta diferente.',
	invalid_expiry_month = 'The card’s expiration month is incorrect. Check the expiration date or use a different card.',
	invalid_cvc = 'El código de seguridad de la tarjeta no es válido. Verifique el código de seguridad de la tarjeta o use una tarjeta diferente.',
	invalid_card_type = 'La tarjeta proporcionada como cuenta externa no es compatible con los pagos. Proporcione una tarjeta de débito no prepaga en su lugar.',
	insufficient_funds = 'La cuenta no tiene fondos suficientes para cubrir este pago.',
	incorrect_number = 'El número de tarjeta es incorrecto. Verifique el número de la tarjeta o use una tarjeta diferente.',
	incorrect_cvc = 'El código de seguridad de la tarjeta es incorrecto. Verifique el código de seguridad de la tarjeta o use una tarjeta diferente.',
	expired_card = 'La tarjeta ha caducado. Verifique la fecha de vencimiento o use una tarjeta diferente.',
	debit_not_authorized = 'Este pago no ha sido autorizado.',
	card_declined = 'La tarjeta ha sido rechazada.',
	bank_account_declined = 'La cuenta bancaria proporcionada no se puede usar para cobrar, ya sea porque aún no está verificada o porque no es compatible.',
	balance_insufficient = 'No se pudo completar la transferencia o el pago porque la cuenta asociada no tiene suficiente saldo disponible.',
	account_number_invalid = 'El número de cuenta bancaria proporcionado no es válido (por ejemplo, faltan dígitos).',
	account_information_mismatch = 'Parte de la información de la cuenta no coincide entre sí.',
}

export enum ConsultationTab {
	preview = 1,
	createUpdate = 2,
	history = 3,
}
export interface Medicine {
	id: number | string;
	medicineID?: number | string;
	label: string;
	selectedMeasure?: string | null;
	openUnit?: null | HTMLElement;
	openSideUnit?: null | HTMLElement;
	take?: string | null;
	indication?: string | null;
	duration?: string | null;
	description?: string | null;
	showDescription?: boolean;
}
export interface LabImages {
	id: number | string;
	labImageID?: number | string;
	label: string;
	description?: string | null;
}
export interface FreePrescription {
	id?: number | string | undefined;
	description: string;
	label?: string;
}

export interface Unit {
	id: number | string;
	label: string;
	measure?: string;
}
export interface SidebarOptions {
	id: number | string;
	medicineID?: string;
	labImageID?: string;
	freePrescriptionID?: string;
	label: string;
	openSideUnit?: null | HTMLElement;
	openUnit?: null | HTMLElement;
	measures?: Unit[];
	selectedMeasure?: string;
	take?: string;
	indication?: string;
	duration?: string;
	description?: string;
}
export interface Templates {
	id: number | string;
	name: string;
	templateList: Medicine[] | LabImages[];
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface UnitOption {
	id: string | number;
	medicineID?: string;
	measure: string;
	isChip: boolean;
}
export interface MedicineOption {
	id?: string | number;
	medicineName: string;
	medicineID?: string;
	description: string;
	doctorID?: string;
}
export interface Consultation {
	id: string;
	title: string;
	date: string;
	description: string;
	note_physic_test: string;
	medicines_prescripted: ClientPrescriptionMedicine[];
	labs_images: ClientPrescriptionAnalisis[];
	diagnostics: ConsultationDiagnosticType[];
	treatment: string;
	procedures_cirgury: string;
	proceures_cirugy_description: string;
}

export type AIDiagnosticExamplesTypes =
	| 'st_elevation_hypertensive'
	| 'inverted_t_waves'
	| 'chest_pain';

export enum AIDiagnosticExamples {
	st_elevation_hypertensive = 'Un hombre de 50 años con antecedentes de hipertensión se presenta en el hospital con dolor en el pecho y dificultad para respirar. El electrocardiograma muestra elevación del segmento ST en las derivaciones anteriores',
	inverted_t_waves = 'Una mujer joven de 30 años sin antecedentes médicos previos llega a urgencias con dolor en el pecho y mareo. El electrocardiograma muestra ondas T invertidas en las derivaciones inferiores.',
	chest_pain = 'Un hombre de 45 años con antecedentes de tabaquismo y obesidad se presenta en el hospital con dolor torácico opresivo. El electrocardiograma muestra una elevación del segmento ST en las derivaciones laterales',
}

export interface SubscriptionData {
	customer: string | null | undefined;
	items: { price: string }[];
	default_payment_method?: string | null | undefined;
	trial_end?: number;
	billing_cycle_anchor?: number;
}

export interface ISideBar {
	sidebarToggle: boolean;
	setsidebarToggle: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IAppointment {
	id?: string;
	_version?: number;
	client: IClientFilter | null;
	description: string;
	date: moment.Moment;
	start: string | null;
	end: string | null;
	shouldSendReminder?: boolean;
}
