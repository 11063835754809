import { Avatar, Button, Popover, Typography } from '@material-ui/core';
import Delete from '@material-ui/icons/DeleteForeverRounded';
import Notifications from '@material-ui/icons/Notifications';
import { Box } from '@mui/material';
import moment from 'moment';
import { FC } from 'react';

type AppointmentDetailPopOverProps = {
	id: string | undefined;
	open: boolean;
	anchorEl: HTMLElement | null;
	handleClose: () => void;
	appointment: any;
	setOpenConfirmationModal: (open: boolean) => void;
	handleClickEdit: () => void;
};

export const AppointmentDetailPopOver: FC<AppointmentDetailPopOverProps> = ({
	id,
	open,
	anchorEl,
	handleClose,
	appointment,
	setOpenConfirmationModal,
	handleClickEdit,
}) => {
	return (
		<Popover
			id={id}
			className="appointment-detail-pop-over"
			open={open}
			anchorEl={anchorEl}
			onClose={handleClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			transformOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
		>
			<Box padding={'16px 16px 8px'} display={'flex'} gap={'16px'} flexDirection={'column'}>
				<Box>
					<Typography>{appointment.description}</Typography>
					<Typography
						style={{
							fontSize: '14px',
							color: 'rgba(0, 0, 0, 0.60)',
							textTransform: 'capitalize',
						}}
						variant={'caption'}
					>
						{moment(appointment.date).format('dddd, D [de] MMMM')} ⋅ {appointment.start}{' '}
						- {appointment.end}
					</Typography>
				</Box>
				<Box padding={'16px 0 8px'}>
					<Box display={'flex'} gap={'16px'} alignItems={'center'} minWidth={'412px'}>
						<Avatar
							src={(appointment.client as any)?.profileImage}
							alt="profile-picture"
						/>
						<div>
							<Typography variant={'subtitle1'}>Paciente:</Typography>
							<Typography variant={'subtitle2'}>
								{appointment.client?.name}
							</Typography>
						</div>
					</Box>
					{appointment.shouldSendReminder && (
						<Box display={'flex'} marginTop={'32px'} gap={'16px'} alignItems={'center'}>
							<Box
								width={'40px'}
								height={'40px'}
								display={'flex'}
								justifyContent={'center'}
								alignItems={'center'}
								sx={{
									color: 'rgba(0,0,0,0.54)',
									fontSize: '24px',
								}}
							>
								<Notifications viewBox="0 0 22 22" />
							</Box>
							<div>
								<Typography variant={'subtitle1'}>Recordatorio:</Typography>
								<Typography variant={'subtitle2'}>
									Correo electrónico: {appointment.client?.email}
								</Typography>
							</div>
						</Box>
					)}
				</Box>
				<Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
					<Button
						color="secondary"
						style={{ color: '#ED6C02' }}
						onClick={() => setOpenConfirmationModal(true)}
					>
						<Delete />
						Eliminar
					</Button>
					<Button
						variant={'outlined'}
						color={'primary'}
						style={{ border: 'none' }}
						onClick={handleClickEdit}
					>
						Editar
					</Button>
				</Box>
			</Box>
		</Popover>
	);
};
