import React from 'react';

const HumanoLogo = () => (
	<svg width="95" height="17" viewBox="0 0 95 17" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_2652_21418)">
			<path
				d="M0 0.723639C1.07457 0.723639 2.13302 0.753374 3.19685 0.723639C3.67234 0.704717 3.75025 0.866906 3.73413 1.29401C3.70727 2.59693 3.73413 3.89985 3.71532 5.20277C3.71532 5.54067 3.80935 5.62447 4.13978 5.62176C6.00416 5.60915 7.86943 5.60915 9.7356 5.62176C10.0419 5.62176 10.1413 5.54067 10.1386 5.2244C10.1224 3.87282 10.1386 2.49961 10.1386 1.13722C10.1386 0.837172 10.2057 0.723639 10.5281 0.731748C11.5194 0.750671 12.5134 0.747967 13.5047 0.731748C13.7733 0.731748 13.8834 0.804734 13.8834 1.10208C13.8834 5.96777 13.8834 10.8253 13.8834 15.6748C13.8834 15.9613 13.8028 16.0614 13.5073 16.0587C12.5161 16.0424 11.5248 16.0397 10.5335 16.0587C10.2111 16.0587 10.1386 15.9451 10.1386 15.6478C10.1386 13.5582 10.1386 11.4714 10.1547 9.38186C10.1547 8.98179 10.0445 8.89259 9.66038 8.89529C7.82287 8.91692 5.98804 8.91421 4.15321 8.89529C3.82278 8.89529 3.72607 8.97638 3.72876 9.31428C3.74488 11.4011 3.72876 13.4907 3.74488 15.5775C3.74488 15.9316 3.67772 16.0695 3.28818 16.0587C2.19481 16.0235 1.09875 16.0316 0.00537284 16.0235L0 0.723639Z"
				fill="#50AFDD"
			/>
			<path
				d="M32.5326 9.08288C32.1215 12.9808 29.2148 15.9543 25.1933 16.6328C24.5216 16.7247 22.9501 16.7923 21.8701 16.4787C20.2365 16.0976 18.751 15.24 17.5993 14.0135C16.4477 12.7869 15.6813 11.2457 15.3959 9.58297V9.53972C15.2911 8.8396 15.2911 8.8396 16.0003 8.8396C16.7847 8.8396 17.5692 8.85582 18.3536 8.8396C18.6545 8.8396 18.7539 8.94232 18.8184 9.21534C19.447 11.8698 21.5505 13.4485 24.2799 13.3458C26.6063 13.2593 28.8468 11.2778 29.0617 9.0937C29.0974 9.02148 29.1547 8.96233 29.2255 8.92452C29.2963 8.88671 29.3771 8.87214 29.4566 8.88285C30.3727 8.88285 31.2887 8.88285 32.2048 8.88285C32.3526 8.88826 32.5138 8.86933 32.5326 9.08288Z"
				fill="#50AFDD"
			/>
			<path
				d="M33.9295 10.8588C33.9295 9.23688 33.9295 7.63121 33.9295 6.01742C33.9295 5.70656 34.0209 5.60924 34.3298 5.61735C35.0847 5.63898 35.8396 5.61735 36.5945 5.61735C36.7886 5.63163 36.9808 5.5707 37.1318 5.44705C38.432 4.36579 39.9418 4.24144 41.5294 4.49013C42.0356 4.56171 42.5225 4.73412 42.9616 4.99729C43.4007 5.26046 43.7833 5.6091 44.0869 6.02282C44.24 6.22827 44.3206 6.22016 44.4791 6.03904C44.943 5.51457 45.5126 5.09554 46.15 4.80982C46.7874 4.52411 47.4779 4.37827 48.1756 4.38201C50.6713 4.31983 53.0918 6.03364 53.073 8.92872C53.0587 11.1507 53.0587 13.3718 53.073 15.592C53.073 15.9488 52.9682 16.0434 52.6217 16.0353C51.7056 16.0137 50.7869 16.0137 49.8708 16.0353C49.5081 16.0353 49.3872 15.9515 49.3926 15.5704C49.4114 13.6322 49.406 11.6914 49.3926 9.75048C49.3926 8.44215 48.5517 7.58795 47.3455 7.65013C46.9579 7.65837 46.582 7.78546 46.268 8.01442C45.9541 8.24338 45.7171 8.5633 45.5886 8.93143C45.4029 9.39973 45.3116 9.90051 45.32 10.4046C45.32 12.1374 45.32 13.8701 45.32 15.6028C45.32 15.9218 45.2528 16.0434 44.9063 16.0353C43.9445 16.011 42.9828 16.0083 42.0211 16.0353C41.6476 16.0353 41.5832 15.9029 41.5859 15.5731C41.5984 13.6322 41.5984 11.6923 41.5859 9.75319C41.5859 8.67192 41.0701 7.94747 40.1647 7.70419C39.7247 7.58777 39.2582 7.62743 38.8438 7.81648C38.4294 8.00553 38.0923 8.33244 37.8893 8.7422C37.6172 9.36742 37.4913 10.0471 37.5213 10.729C37.5052 12.3266 37.4998 13.9269 37.5213 15.5271C37.5213 15.9542 37.3843 16.0461 36.984 16.0353C36.1109 16.0083 35.2378 16.0056 34.3647 16.0353C33.9806 16.0353 33.9 15.9137 33.9027 15.5542C33.9403 13.9999 33.9295 12.4401 33.9295 10.8588Z"
				fill="#50AFDD"
			/>
			<path
				d="M66.986 5.62003C66.2768 5.63624 65.5649 5.62003 64.8556 5.62003C64.6936 5.64417 64.5283 5.61071 64.3882 5.52541C63.4648 4.84282 62.3603 4.45229 61.2155 4.4036C59.4183 4.26034 57.7554 4.62797 56.4149 5.91467C54.6983 7.57441 54.3249 9.62881 54.7466 11.8805C55.3484 14.9757 58.1933 16.8706 61.2908 16.3056C62.1759 16.1465 63.0073 15.7663 63.7085 15.2C63.716 15.3215 63.716 15.4434 63.7085 15.5649C63.6494 15.9569 63.7811 16.1056 64.2055 16.0894C65.1243 16.0542 66.043 16.0704 66.9591 16.0894C67.2412 16.0894 67.3513 16.011 67.3486 15.7109C67.3486 12.4671 67.3486 9.22334 67.3486 5.97955C67.3433 5.69571 67.2573 5.61462 66.986 5.62003ZM60.9496 13.3213C59.2974 13.3213 58.1369 12.113 58.1342 10.383C58.1342 8.70703 59.2813 7.49061 60.8851 7.4825C61.258 7.47921 61.6278 7.55101 61.9728 7.69368C62.3177 7.83634 62.6308 8.04698 62.8937 8.31321C63.1565 8.57944 63.3637 8.89588 63.5032 9.24391C63.6427 9.59194 63.7116 9.96452 63.7059 10.3397C63.7273 12.0022 62.4997 13.3213 60.9496 13.3213Z"
				fill="#50AFDD"
			/>
			<path
				d="M94.6803 8.4773C93.8529 5.98769 91.5721 4.44689 88.6063 4.44689C88.0504 4.42739 87.4948 4.49487 86.9595 4.64692C84.7351 5.25783 83.1743 6.5986 82.6156 8.88007C82.0783 11.0669 82.535 13.07 84.2032 14.6594C85.7802 16.157 87.7144 16.5814 89.8313 16.2813C90.7009 16.1729 91.5356 15.8706 92.2744 15.3966C93.0132 14.9225 93.6377 14.2885 94.1022 13.5409C94.5667 12.7933 94.8597 11.9508 94.9597 11.0748C95.0597 10.1988 94.9642 9.31145 94.6803 8.4773V8.4773ZM88.7111 13.3214C87.1153 13.3051 85.9279 12.0455 85.9306 10.3722C85.9193 9.99308 85.9845 9.61557 86.1223 9.26251C86.2601 8.90945 86.4675 8.58817 86.7322 8.31807C86.9969 8.04797 87.3132 7.83468 87.6621 7.69107C88.011 7.54746 88.3852 7.47652 88.7621 7.48253C89.5079 7.50097 90.2162 7.81539 90.7325 8.35723C91.2489 8.89906 91.5314 9.62435 91.5184 10.3749C91.5287 10.7545 91.4641 11.1323 91.3282 11.4866C91.1922 11.8408 90.9878 12.1644 90.7266 12.4384C90.4655 12.7125 90.1528 12.9317 89.8068 13.0833C89.4608 13.2349 89.0884 13.3158 88.7111 13.3214V13.3214Z"
				fill="#50AFDD"
			/>
			<path
				d="M69.3285 10.8182C69.3285 9.2477 69.3285 7.67716 69.3285 6.10933C69.3285 5.70926 69.4467 5.58762 69.8336 5.61735C70.2204 5.64709 70.6637 5.61735 71.0774 5.61735C71.8645 5.65249 72.5603 5.58492 73.24 5.01725C74.011 4.36579 75.0533 4.36849 76.0365 4.3712C77.2748 4.37453 78.4751 4.80159 79.4402 5.58221C79.9391 5.98263 80.3396 6.49328 80.6108 7.07456C80.8819 7.65583 81.0164 8.29213 81.0037 8.93413C81.0413 11.1696 81.0037 13.4052 81.0199 15.6407C81.0199 15.9705 80.9231 16.0786 80.59 16.0732C79.6444 16.0543 78.6961 16.0515 77.7505 16.0732C77.4039 16.0732 77.2965 15.9921 77.2992 15.6326C77.318 13.662 77.3233 11.6941 77.2992 9.72345C77.2992 8.9125 76.9956 8.20698 76.2004 7.83124C75.8457 7.65504 75.4476 7.58676 75.0549 7.63477C74.6622 7.68279 74.2919 7.845 73.9895 8.10156C73.4173 8.54488 73.2239 9.20174 73.1245 9.89105C73.0632 10.3904 73.038 10.8937 73.0492 11.3967C73.0492 12.8051 73.0492 14.2107 73.0492 15.6163C73.0492 15.9786 72.9418 16.0678 72.5979 16.0597C71.6792 16.038 70.7604 16.0353 69.8443 16.0597C69.4467 16.0597 69.307 15.9678 69.307 15.5434C69.3446 13.9863 69.3285 12.4023 69.3285 10.8182Z"
				fill="#50AFDD"
			/>
		</g>
		<defs>
			<clipPath id="clip0_2652_21418">
				<rect width="95" height="16" fill="white" transform="translate(0 0.711182)" />
			</clipPath>
		</defs>
	</svg>
);

export default HumanoLogo;
