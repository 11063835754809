import React, { FC } from 'react';

interface Props {
	onClick: () => void;
}

const SmallLogo: FC<Props> = ({ onClick }) => {
	return (
		<svg
			onClick={onClick}
			width="48"
			height="20"
			viewBox="0 0 23 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15.727 10.596 12.73 13.86a.95.95 0 0 1-.668.33 1.044 1.044 0 0 1-.327-.057.849.849 0 0 1-.543-.853l.147-4.549-1.606 1.808a.915.915 0 0 1-.668.319H6.102c-.498 0-.758-.376-.724-.899.016-.318.081-.633.192-.932l3.178-.09 2.941-3.173a.951.951 0 0 1 .668-.33c.108-.017.219-.017.328 0a.85.85 0 0 1 .542.853l-.158 4.548 1.674-1.808a.97.97 0 0 1 .667-.307l1.844-.045c.77 0 1.131.307 1.063.83a4.366 4.366 0 0 1-.192 1.034l-2.398.057z"
				fill="#fff"
			/>
			<path
				d="M11.859 19.625h-.181a6.185 6.185 0 0 1-3.04-.908 6.228 6.228 0 0 1-2.197-2.299H6.17a6.177 6.177 0 0 1-3.409-1.151 6.227 6.227 0 0 1-2.198-2.86A6.263 6.263 0 0 1 .313 8.8a6.24 6.24 0 0 1 1.785-3.138A6.047 6.047 0 0 1 6.53 3.967h.419a6.199 6.199 0 0 1 2.223-2.581 6.152 6.152 0 0 1 6.53-.141 6.195 6.195 0 0 1 2.331 2.483 6.195 6.195 0 0 1 3.509 2.254 6.253 6.253 0 0 1 1.298 3.978 6.261 6.261 0 0 1-1.575 4.01 6.2 6.2 0 0 1-3.786 2.028 6.165 6.165 0 0 1-2.277 2.649 6.117 6.117 0 0 1-3.344.978zM6.95 14.53a.958.958 0 0 1 .882.557 4.385 4.385 0 0 0 1.552 1.922 4.35 4.35 0 0 0 2.339.773A4.294 4.294 0 0 0 14.341 17a4.33 4.33 0 0 0 1.623-2.208.921.921 0 0 1 .838-.625 4.355 4.355 0 0 0 2.935-1.287 4.4 4.4 0 0 0 1.272-2.954 4.392 4.392 0 0 0-1.034-2.944 4.348 4.348 0 0 0-2.721-1.502.902.902 0 0 1-.713-.546 4.361 4.361 0 0 0-1.68-2.022 4.326 4.326 0 0 0-4.99.222 4.367 4.367 0 0 0-1.496 2.164.912.912 0 0 1-.778.648.9.9 0 0 1-.353-.034 4.344 4.344 0 0 0-.848-.114 4.165 4.165 0 0 0-3.122 1.205 4.332 4.332 0 0 0-1.369 3.06 4.41 4.41 0 0 0 1.178 3.162 4.367 4.367 0 0 0 3.064 1.385c.203.012.407.012.61 0l.193-.08z"
				fill="#fff"
			/>
		</svg>
	);
};

export default SmallLogo;
