import {
	withStyles,
	createTheme,
	Grid,
	ListItem,
	List,
	TextField,
	Button,
	Typography,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import styled from 'styled-components';

const theme = createTheme();

export const SummaryContainer = withStyles({
	root: {
		flexGrow: 1,
		padding: '16px 16px 34px',
		height: 'calc(100dvh - 156px)',
		[theme.breakpoints.up('lg')]: {
			height: 'calc(100dvh - 167px)',
			padding: theme.spacing(2.5),
		},
		overflowY: 'auto',
	},
})(Grid) as typeof Grid;

export const TextFieldNote = withStyles({
	root: {
		backgroundColor: '#fffce4',
		height: '132px',
		overflowY: 'auto',
	},
})(TextField) as typeof TextField;

export const StyledButton = withStyles({
	root: {
		padding: 0,
		[theme.breakpoints.down('lg')]: {
			alignSelf: 'center',
		},
	},
})(Button) as typeof Button;

export const StyledInfoOutlinedIcon = withStyles({
	root: { marginLeft: '4px', fill: 'rgba(0, 0, 0, 0.54)' },
})(InfoOutlinedIcon) as typeof InfoOutlinedIcon;

export const VitalSignTitle = withStyles({
	root: {
		fontSize: '20px',
		fontWeight: 500,
	},
})(Typography) as typeof Typography;

export const TitleContainer = styled.div`
	display: flex;
	align-items: center;
`;

export const NoteContainer = withStyles({
	root: {
		flexGrow: 1,
		padding: theme.spacing(2, 0),
	},
})(Grid) as typeof Grid;

export const CardContainer = withStyles({
	root: {
		flexGrow: 1,
		[theme.breakpoints.up('lg')]: {
			paddingRight: `${theme.spacing(2.5)}px`,
		},
	},
})(Grid) as typeof Grid;

export const VisibilityIconStyled = withStyles({
	root: {
		color: 'rgba(0, 0, 0, 0.54)',
	},
})(VisibilityIcon) as typeof VisibilityIcon;

export const ConsultationTitle = styled.p`
	height: 24px;
	font-size: 15px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
`;
export const ConsultationDescription = styled.p`
	height: 20px;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
`;

export const EmptyStateContainer = styled.p`
	text-align: center;
	font-size: 20px;
	padding-top: 20px;
	padding-bottom: 20px;
`;

export const StyledListItem = withStyles({
	root: {
		display: 'flex',
		flexDirection: 'row',
		columnGap: '1rem',
		padding: theme.spacing(0.5, 0),
	},
})(ListItem) as typeof ListItem;

export const TitleTypography = withStyles({
	root: {
		fontSize: '14px',
		fontWeight: 500,
		color: 'rgba(0, 0, 0, 0.6)',
		marginTop: theme.spacing(2.5),
	},
})(Typography) as typeof Typography;

export const StyledList = withStyles({
	root: {
		padding: 0,
	},
})(List) as typeof List;
