import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';
import { BREAKPOINTS } from 'shared/style';

export const TypographyDetail = styled(Typography)`
	color: #1e2469;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
` as typeof Typography;
export const GridHeader = styled(Grid)`
	padding: 16px 16px 0;
	align-items: center;
	@media (min-width: ${BREAKPOINTS.md}px) {
		padding: 43px 24px 6px 24px;
	}
` as typeof Grid;
export const ActionButtonContainer = styled(Grid)`
	display: flex;
	justify-content: flex-end;
	align-self: end;
	flex-grow: 0;
	column-gap: 1rem;
` as typeof Grid;
export const CustomContainer = styled(Grid)`
	display: flex;
` as typeof Grid;
export const ProfileSection = styled(Grid)`
	flex-grow: 0;
	max-width: 4%;
	flex-basis: 4%;
` as typeof Grid;
export const InformationSection = styled(Grid)`
	flex-grow: 0;
	overflow: hidden;
	white-space: nowrap;
	row-gap: 0.5rem;
	@media (min-width: ${BREAKPOINTS.md}px) {
		max-width: 54%;
		flex-basis: 54%;
	}
` as typeof Grid;
export const ProfilePicture = styled.img`
	border-radius: 50%;
	width: 40px;
	height: 40px;
`;

export const GridTitleContainer = styled(Grid)`
	min-width: 400px;
`;
