import { FormControl, Grid, MenuItem } from '@material-ui/core';
import React, { FC, useContext } from 'react';
import { bloodTypeOptions, genderOptions } from '../../../../shared/constants';
import {
	InputContainer,
	LabelGrid,
	StyledTextField,
	StyledSelect,
	StyledKeyboardDatePicker,
	StyledInputLabel,
} from '../../../../shared/style';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'moment/locale/es-do';
import moment, { Moment } from 'moment';
import MomentUtils from '@date-io/moment';
import { Client } from 'models';
import { ClientType, LoaderContextProps } from '../../../../context/types';
import phoneMask from '../../../textMask/phoneMask';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { capitalizeFirstLetter } from '../../../../shared/utilFunctions';
import { HospitalDoctorByIdIdentification } from '../../../../customQueries/custom-query-types';
import { useHistory } from 'react-router-dom';
import { LoaderContext } from 'context/loader-context';
import SearchInputHelper from './SearchInputHelper';
import useMobile from 'hooks/useMobile';

interface Props {
	isDisabled: boolean;
	isInvalidAdultDate: boolean;
	insuranceValue: number;
	idType: string;
	handleIdType: (
		e: React.ChangeEvent<{
			name?: string | undefined;
			value: unknown | string;
		}>,
		child?: React.ReactNode
	) => void;
	idNumber: string;
	idNumberError: string;
	handleIdNumber: (e: React.ChangeEvent<HTMLInputElement>) => void;
	firstName: string;
	nameErrorText: string;
	lastNameErrorText: string;
	handleName: (e: React.ChangeEvent<HTMLInputElement>) => void;
	lastName: string;
	handleLastName: (e: React.ChangeEvent<HTMLInputElement>) => void;
	birthday: string | null | undefined | Moment;
	birthdayErrorText: string;
	handleBirthdayDate: (date: MaterialUiPickersDate) => void;
	gender: string;
	setGender: (gender: string) => void;
	phoneNumber: string;
	setPhoneNumber: (phone: string) => void;
	cellPhone: string;
	setCellPhone: (phone: string) => void;
	address: string;
	setAdress: (address: string) => void;
	sector: string;
	setSector: (sector: string) => void;
	city: string;
	setCity: (city: string) => void;
	contractNo: string;
	setContractNo: (contract: string) => void;
	bloodType: string;
	handleBloodType: (
		e: React.ChangeEvent<{
			name?: string | undefined;
			value: unknown | string;
		}>,
		child?: React.ReactNode
	) => void;
	affiliate: string;
	handleAffiliate: (e: React.ChangeEvent<HTMLInputElement>) => void;
	email: string;
	handleEmail: (e: React.ChangeEvent<HTMLInputElement>) => void;
	occupation: string;
	setOccupation: (occupation: string) => void;
	handleClickAddPatient: (data: Client) => void;
	isInvalidName: boolean;
	isInvalidLastName: boolean;
	isInvalidEmail: boolean;
	existedClient: HospitalDoctorByIdIdentification | null;
	getPatientData: (clientId: string) => Promise<ClientType>;
	setSelectedClient: React.Dispatch<React.SetStateAction<ClientType | null>>;
	closeModal: () => void;
}

const PatientAdultForm: FC<Props> = ({
	isInvalidAdultDate,
	insuranceValue,
	idType,
	handleIdType,
	idNumber,
	idNumberError,
	handleIdNumber,
	firstName,
	nameErrorText,
	lastNameErrorText,
	handleName,
	lastName,
	handleLastName,
	birthday,
	birthdayErrorText,
	handleBirthdayDate,
	gender,
	setGender,
	phoneNumber,
	setPhoneNumber,
	cellPhone,
	setCellPhone,
	address,
	setAdress,
	sector,
	setSector,
	city,
	setCity,
	contractNo,
	setContractNo,
	affiliate,
	handleAffiliate,
	email,
	handleEmail,
	occupation,
	setOccupation,
	bloodType,
	handleBloodType,
	handleClickAddPatient,
	isInvalidName,
	isInvalidLastName,
	isInvalidEmail,
	existedClient,
	getPatientData,
	setSelectedClient,
	closeModal,
}) => {
	const history = useHistory();
	const { showSpinner } = useContext(LoaderContext) as LoaderContextProps;
	const goToExistedClient = async () => {
		if (!existedClient) return '';
		closeModal();
		showSpinner(true);
		const client = await getPatientData(existedClient?.client.id);
		setSelectedClient(client);
		history.push(`/Clients/${existedClient?.client.id}`);
	};

	const { isMobile } = useMobile();

	return (
		<Grid item xs={12}>
			<LabelGrid>Datos Personales</LabelGrid>
			<InputContainer container spacing={2} style={{ marginBottom: '8px' }}>
				<Grid item xs={12} md={6} style={{ marginBottom: '8px' }}>
					<StyledTextField
						fullWidth
						value={firstName}
						error={isInvalidName}
						onChange={handleName}
						variant="outlined"
						label="Nombres (s)*"
						helperText={nameErrorText}
						data-fx-name="name"
					/>
				</Grid>
				<Grid item xs={12} md={6} style={{ marginBottom: '8px' }}>
					<StyledTextField
						fullWidth
						value={lastName}
						error={isInvalidLastName}
						onChange={handleLastName}
						variant="outlined"
						label="Apellidos (s)*"
						helperText={lastNameErrorText}
						data-fx-name="lastname"
					/>
				</Grid>
			</InputContainer>
			<InputContainer container spacing={2} style={{ marginBottom: '8px' }}>
				<Grid item xs={12} md={6}>
					<MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={'es'}>
						<StyledKeyboardDatePicker
							disableFuture
							invalidDateMessage="Formato fecha invalida"
							openTo="year"
							views={['year', 'month', 'date']}
							variant="inline"
							format="DD/MM/YYYY"
							inputVariant="outlined"
							placeholder="dd/mm/yyyy"
							id="date-picker-inline"
							label="Fecha de nacimiento"
							InputLabelProps={{
								shrink: true,
							}}
							value={birthday}
							error={isInvalidAdultDate}
							helperText={birthdayErrorText}
							onChange={handleBirthdayDate}
							KeyboardButtonProps={{
								'aria-label': 'change date',
							}}
							data-fx-name="dateBirth"
						/>
					</MuiPickersUtilsProvider>
				</Grid>
				<Grid item xs={12} md={6}>
					<FormControl variant="outlined" fullWidth>
						<StyledInputLabel htmlFor="outlined-gender-native-simple">
							Sexo biológico
						</StyledInputLabel>
						<StyledSelect
							label="Sexo biológico"
							value={gender}
							inputProps={{
								name: 'gender',
								id: 'outlined-gender-native-simple',
							}}
							onChange={(e) => setGender(e.target.value as string)}
							data-fx-name="gender"
						>
							{genderOptions.map((g) => {
								return (
									<MenuItem key={g.id} value={g.name}>
										{capitalizeFirstLetter(g.name.toLowerCase())}
									</MenuItem>
								);
							})}
						</StyledSelect>
					</FormControl>
				</Grid>
			</InputContainer>
			<InputContainer container spacing={isMobile ? 1 : 2}>
				<Grid
					item
					xs={12}
					md={6}
					style={!isMobile ? { marginBottom: '8px' } : {}}
					data-fx-name="idNumber"
				>
					<StyledTextField
						id="idNumber"
						value={idNumber}
						onChange={handleIdNumber}
						fullWidth
						label="Número de identificación"
						variant="outlined"
						error={!!idNumberError}
						helperText={
							<SearchInputHelper
								idNumberError={idNumberError}
								goToExistedClient={goToExistedClient}
							/>
						}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					md={6}
					style={isMobile ? { marginBottom: '12px' } : { paddingTop: '0' }}
				>
					<StyledTextField
						fullWidth
						value={occupation}
						onChange={(e) => setOccupation(e.target.value)}
						variant="outlined"
						label="Ocupación"
						data-fx-name="ocupation"
					/>
				</Grid>
			</InputContainer>

			<InputContainer container spacing={2}>
				<Grid
					item
					xs={12}
					md={6}
					style={isMobile ? { marginBottom: '0' } : { paddingTop: '0' }}
				>
					<StyledTextField
						fullWidth
						value={cellPhone}
						onChange={(e) => setCellPhone(e.target.value)}
						variant="outlined"
						label="Celular"
						InputProps={{ inputComponent: phoneMask as any }}
						data-fx-name="celNumber"
					/>
				</Grid>
				<Grid item xs={12} md={6} style={{ marginBottom: '8px' }}>
					<StyledTextField
						fullWidth
						value={phoneNumber}
						onChange={(e) => setPhoneNumber(e.target.value)}
						variant="outlined"
						label="Teléfono"
						InputProps={{ inputComponent: phoneMask as any }}
						data-fx-name="telNumber"
					/>
				</Grid>
			</InputContainer>

			<InputContainer container spacing={2} style={{ marginBottom: '8px' }}>
				<Grid item xs={12} md={6}>
					<FormControl variant="outlined" fullWidth>
						<StyledInputLabel htmlFor="outlined-gender-native-simple">
							Tipo de sangre
						</StyledInputLabel>
						<StyledSelect
							label="Tipo de sangre"
							value={bloodType}
							inputProps={{
								name: 'bloodType',
								id: 'outlined-gender-native-simple',
							}}
							onChange={handleBloodType}
							data-fx-name="bloodType"
						>
							{bloodTypeOptions.map((b) => {
								return (
									<MenuItem key={b.id} value={b.label}>
										{b.label}
									</MenuItem>
								);
							})}
						</StyledSelect>
					</FormControl>
				</Grid>
				<Grid item xs={12} md={6} style={!isMobile ? { marginBottom: '0' } : {}}>
					<StyledTextField
						fullWidth
						value={email}
						error={isInvalidEmail}
						onChange={handleEmail}
						helperText={isInvalidEmail ? 'Correo invalido' : ''}
						variant="outlined"
						type="email"
						label="Correo"
						data-fx-name="email"
					/>
				</Grid>
			</InputContainer>

			{insuranceValue !== 1 && (
				<>
					<LabelGrid>Detalles del seguro</LabelGrid>
					<InputContainer
						container
						spacing={2}
						style={isMobile ? { marginBottom: '12px' } : {}}
					>
						<Grid item xs={12} md={6}>
							<StyledTextField
								fullWidth
								value={affiliate}
								onChange={handleAffiliate}
								variant="outlined"
								label="No. de Afiliado"
								data-fx-name="affNumber"
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<StyledTextField
								fullWidth
								value={contractNo}
								onChange={(e) => setContractNo(e.target.value)}
								variant="outlined"
								label="No. de Contrato"
								data-fx-name="contractNumber"
							/>
						</Grid>
					</InputContainer>
				</>
			)}
			<LabelGrid>Dirección</LabelGrid>
			<InputContainer container spacing={2}>
				<Grid item xs={12} md={12} style={{ marginBottom: '8px' }}>
					<StyledTextField
						value={address}
						onChange={(e) => setAdress(e.target.value)}
						fullWidth
						variant="outlined"
						label="Calle y número"
						data-fx-name="address"
					/>
				</Grid>
			</InputContainer>
			<InputContainer container spacing={2} style={isMobile ? { marginBottom: '144px' } : {}}>
				<Grid item xs={12} md={6} style={isMobile ? { marginBottom: '0' } : {}}>
					<StyledTextField
						fullWidth
						value={sector}
						onChange={(e) => setSector(e.target.value)}
						variant="outlined"
						label="Sector"
						data-fx-name="sector"
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<StyledTextField
						fullWidth
						value={city}
						onChange={(e) => setCity(e.target.value)}
						variant="outlined"
						label="Ciudad"
						data-fx-name="city"
					/>
				</Grid>
			</InputContainer>
		</Grid>
	);
};

export default PatientAdultForm;
