// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import {
	RelationsContextProps,
	DoctorType,
	MedicineType,
	SecretaryType,
	ClientType,
	CreateTemplateMedicine,
	CreateTemplateLabImage,
	AlertType,
	Query_Medicine,
	Query_LabImage,
	UserRoles,
	IDoctorStripeCustomerSubscription,
} from './types';

import {
	HospitalDoctor,
	Hospital,
	Doctor,
	Client,
	Insurance,
	Template,
	TemplateMedicine,
	TemplateLabImage,
	AffiliateTypes,
	LabImage,
	PrescriptionTypes,
	Status,
	Appointment,
	Teams,
} from 'models';
import { getFormatNextPaymentDate } from '../shared/paymentUtils';

import { default as Amplify } from '@aws-amplify/core';
import { useHistory } from 'react-router-dom';
import useAPI from 'hooks/API';
import {
	createDoctorStripeCards,
	createDoctorStripeCustomerSubscription,
	createHospitalDoctor as createHospitalDoctorMutation,
	updateDoctor,
	updateDoctorStripeCards,
	updateDoctorStripeCustomerSubscription,
	updateHospitalDoctor as updateHospitalDoctorMutation,
	createDoctorLabImage as createDoctorLabImageMutation,
	updateDoctorLabImage as updateDoctorLabImageMutation,
	createLabImage as createLabImageMutation,
	updateLabImage,
	createTemplate,
	createTemplateMedicine,
	createTemplateLabImage,
	createMedicine as createMedicineMutation,
	createDoctorMedicine as createDoctorMedicineMutation,
	updateDoctorMedicine as updateDoctorMedicineMutation,
	updateMedicine,
	updateRecentDoctorMedicinePrescription,
	updateSecretaryHospitalDoctor,
	createHospitalDoctorCliente,
	updateClientInsurance,
	updateHospitalDoctorCliente,
	updateHospital as updateHospitalMutation,
	updateRecentDoctorLabImagePrescription,
	createTeams,
	createDoctor,
	updateTeams,
} from 'graphql/mutations';
import {
	syncClients,
	syncDoctorLabImages,
	syncDoctorMedicines,
	syncDoctorStripeCards,
	syncDoctorStripeCustomerSubscriptions,
	syncDoctors,
	syncHospitalDoctorClientes,
	syncHospitalDoctors,
	syncHospitals,
	syncLabImages,
	syncMedicines,
	syncRecentDoctorLabImagePrescriptions,
	syncRecentDoctorMedicinePrescriptions,
	syncSecretaryHospitalDoctors,
	syncTemplateLabImages,
	syncTemplateMedicines,
	syncTemplates,
	syncClientFreePrescriptions,
	syncAppointments,
	syncTeams,
} from 'graphql/queries';
import { TeamStatus } from 'models';
import { generatePassword } from 'shared/utilFunctions';
import Storage from '@aws-amplify/storage';
import { orderByLastChangedDate } from 'shared/utilFunctions';
import useStripeConfiguration from 'hooks/useStripeConfiguration';

export const RelationsContext = React.createContext<Partial<RelationsContextProps>>({});

const RelationsProvider: React.FC = (props) => {
	const history = useHistory();
	const [isError, setIsError] = useState(false);
	const [actualHospital, setActualHospital] = useState<Hospital | null>(null);
	const [actualDoctor, setActualDoctor] = useState<DoctorType | null>(null);
	const [actualSecretary, setActualSecretary] = useState<SecretaryType | null>(null);
	const [teamMembers, setTeamMembers] = useState<Teams[]>([] as Teams[]);
	const [isCalendarSideViewOpen, setIsCalendarSideViewOpen] = useState(true);
	const [actualClient, setActualClient] = useState<Client | null>({} as Client);
	const [actualInsurance, setActualInsurance] = useState<Insurance>();
	const [actualHospitalDoctor, setActualHospitalDoctor] = useState<HospitalDoctor | null>(null);
	const [ownerHospitalDoctorId, setOwnerHospitalDoctorId] = useState<string>('');
	const [alertMessage, setAlertMessage] = useState<AlertType | null>(null);
	const [medicines, setMedicines] = useState<MedicineType[]>([] as MedicineType[]);
	const [labImages, setLabImages] = useState<LabImage[]>([] as LabImage[]);
	const [loadingMembers, setLoadingMembers] = useState(false);
	const { updateSubsQuantity } = useStripeConfiguration();
	const { Auth } = Amplify;
	const { execute } = useAPI();

	useEffect(() => {
		if (isError) {
			return history.push('/Not-Found');
		}
	}, [isError]);

	const getTeamMembersIds = async () => {
		try {
			const ownerId = ownerHospitalDoctorId || actualHospitalDoctor?.id;
			if (!ownerId) return;
			const membersIDResponse = await execute(syncTeams, {
				filter: {
					OwnerID: {
						eq: ownerId,
					},
					status: {
						eq: TeamStatus.ACTIVE,
					},
				},
			});

			const membersIds = [
				...membersIDResponse.data.syncTeams.items.map((team) => team.Member.doctorID),
				ownerId,
			];
			return membersIds;
		} catch (error) {
			setAlertMessage({
				message: 'Error al obtener los miembros del equipo',
				severity: 'error',
			});
			return [];
		}
	};

	const getCustomHospitalDoctorFilters = async () => {
		const membersIds = await getTeamMembersIds();
		const customFilter = membersIds.map((memberId) => ({
			hospitalDoctorID: {
				eq: memberId,
			},
		}));

		return customFilter;
	};

	const getCustomHospitalDoctorFiltersWithOutCurrentUser = async () => {
		const membersIds = await getTeamMembersIds();
		const customFilter = membersIds
			.filter((memberId) => memberId !== actualHospitalDoctor?.id)
			.map((memberId) => ({
				hospitalDoctorID: {
					eq: memberId,
				},
			}));

		return customFilter;
	};

	const handleError = (error: Error, showScreen?: Boolean) => {
		// Make screen not-found appear
		if (showScreen) {
			setIsError(true);
		}

		if (process.env.NODE_ENV !== 'production') {
			console.log('error', error);
		} else {
			datadogRum.addError(error);
		}
	};

	const createHospitalDoctor = async () => {
		if (actualDoctor && actualHospital) {
			try {
				const createdHospitalResponse = await execute(createHospitalDoctorMutation, {
					input: {
						doctorID: actualDoctor.id,
						hospitalID: actualHospital.id,
						lastWaitingListID: '',
					},
				});
				const createdHospitalDoctor = createdHospitalResponse.data.createHospitalDoctor;
				return createdHospitalDoctor;
			} catch (error) {
				handleError(error, false);
				return null;
			}
		}
	};

	const updateHospitalDoctor = async (hospitalDoctorID: string, hospitalID: string) => {
		try {
			const hospitalDoctorResponse = await execute(syncHospitalDoctors, {
				filter: {
					id: {
						eq: hospitalDoctorID,
					},
				},
			});

			const hospitalDoctor = hospitalDoctorResponse.data.syncHospitalDoctors.items[0];

			const updatedHospitalDoctorResponse = await execute(updateHospitalDoctorMutation, {
				input: {
					id: hospitalDoctor.id,
					hospitalID: hospitalID,
					_version: hospitalDoctor._version,
				},
			});
			const updatedHospitalDoctor = updatedHospitalDoctorResponse.data.updateHospitalDoctor;

			setActualHospitalDoctor(updatedHospitalDoctor);
		} catch (error) {
			handleError(error, false);
		}
	};

	const fetchDoctorHospitals = async () => {
		const hospitalsResponse = await execute(syncHospitalDoctors, {
			filter: {
				doctorID: {
					eq: actualDoctor!.id,
				},
			},
		});

		const hospitals = hospitalsResponse.data.syncHospitalDoctors.items.map(
			(h: any) => h.hospital
		);

		return hospitals;
	};

	const addLogoToHospitalDoctor = async (logo: string) => {
		if (actualHospitalDoctor) {
			const currentHospitalDoctorResponse = await execute(syncHospitalDoctors, {
				filter: {
					id: {
						eq: actualHospitalDoctor.id,
					},
				},
			});

			const currentHospitalDoctor =
				currentHospitalDoctorResponse.data.syncHospitalDoctors.items[0];

			const updatedHospitalDoctorResponse = await execute(updateHospitalDoctorMutation, {
				input: {
					id: currentHospitalDoctor.id,
					hospitalLogo: logo,
					_version: currentHospitalDoctor._version,
				},
			});

			const updatedHospitalDoctor = updatedHospitalDoctorResponse.data.updateHospitalDoctor;

			setActualHospitalDoctor(updatedHospitalDoctor);
		}
	};

	const fetchDoctorHospitalSecretarys = async (): Promise<SecretaryType[]> => {
		const currentSecretaries = await execute(syncSecretaryHospitalDoctors, {
			filter: {
				hospitalDoctorID: {
					eq: actualHospitalDoctor!.id,
				},
				status: {
					ne: Status.INACTIVE,
				},
			},
		});

		const secretaries = currentSecretaries.data.syncSecretaryHospitalDoctors.items;

		const secretaryFormatted = secretaries.map((s: any) => {
			return {
				...s,
				...s?.secretary,
				hospitalDoctorID: s.hospitalDoctor?.hospitalID,
			};
		});

		return secretaryFormatted as SecretaryType[];
	};

	const updateHospital = async (hospital: Hospital) => {
		try {
			const currentHospitalResponse = await execute(syncHospitals, {
				filter: {
					id: {
						eq: hospital.id,
					},
				},
			});
			const currentHospital = currentHospitalResponse.data.syncHospitals.items[0];

			const updatedHospitalResponse = await execute(updateHospitalMutation, {
				input: {
					id: currentHospital.id,
					name: hospital.name,
					address: hospital.address,
					phone: hospital.phone,
					_version: currentHospital._version,
				},
			});

			const updatedHospital = updatedHospitalResponse.data.updateHospital;
			setActualHospital(
				(actualHospital) =>
					({
						...actualHospital,
						...updatedHospital,
					} as Hospital)
			);
		} catch (error) {
			handleError(error, false);
		}
	};

	const getAllDoctors = async () => {
		try {
			let tokenData = null;
			const allDoctors: Query_Doctor[] = [];

			do {
				const doctorPage = await execute(syncDoctors, {
					limit: 1000,
					nextToken: tokenData,
				});

				const doctors = doctorPage.data.syncDoctors.items;

				allDoctors.push(...doctors);

				tokenData = doctorPage.data.syncDoctors.nextToken;
			} while (tokenData);

			return allDoctors;
		} catch (e) {
			handleError(error, false);
		}
	};

	const getDocByEmail = async (email: string) => {
		try {
			const doctors = await execute(syncDoctors, {
				filter: {
					email: {
						eq: email,
					},
				},
			});
			return doctors.data.syncDoctors.items[0];
		} catch (e) {
			handleError(error, false);
		}
	};

	const checkIfDoctorExists = async (email: string) => {
		try {
			if (!email) {
				setAlertMessage({
					message: 'Debe ingresar un correo',
					severity: 'error',
				});
				return true;
			}

			const doctor = await getDocByEmail(email);
			if (doctor) {
				setAlertMessage({
					message: 'El doctor ya existe',
					severity: 'error',
				});
				return true;
			}
			return false;
		} catch (e) {
			setAlertMessage({
				message: 'Error al buscar el doctor',
				severity: 'error',
			});
			return false;
		}
	};

	const getTeamMembers = async () => {
		try {
			setLoadingMembers(true);
			const hospitalDoctorQuery = await execute(syncHospitalDoctors, {
				filter: {
					doctorID: {
						eq: actualDoctor!.id,
					},
				},
			});

			const hospitalDoctor = hospitalDoctorQuery.data.syncHospitalDoctors.items[0];
			const teamMembers = await execute(syncTeams, {
				filter: {
					OwnerID: {
						eq: hospitalDoctor!.id,
					},
					status: {
						ne: TeamStatus.INACTIVE,
					},
				},
			});

			const membersFormatted = teamMembers.data.syncTeams.items as Teams[];

			setTeamMembers(membersFormatted);
			setLoadingMembers(false);
		} catch (e) {
			setIsError(true);
		}
	};

	const disableTeamMember = async (teamMemberId: string) => {
		try {
			const teamMemberQuery = await execute(syncTeams, {
				filter: {
					MemberID: {
						eq: teamMemberId,
					},
				},
			});

			const teamMember = teamMemberQuery.data.syncTeams.items[0];

			const updatedTeamMember = await execute(updateTeams, {
				input: {
					id: teamMember.id,
					status: TeamStatus.INACTIVE,
					_version: teamMember._version,
				},
			});

			const updatedMember = updatedTeamMember.data.updateTeams;

			const updatedTeamMembers = teamMembers.filter((tm) => tm.id !== updatedMember.id);

			updateSubsQuantity(false, actualDoctor);

			setTeamMembers(updatedTeamMembers);
		} catch (e) {
			setIsError(true);
		}
	};

	const createDoctorMember = async (email: string) => {
		try {
			const newDoctorMutation = await execute(createDoctor, {
				input: {
					email: email,
				},
			});
			const newDoctor = newDoctorMutation.data.createDoctor;

			return newDoctor;
		} catch (e) {
			console.log(e);
			setIsError(true);
		}
	};

	const createDoctorTeamMember = async (ownerId: string, memberId: string) => {
		try {
			const newMemberMutation = await execute(createTeams, {
				input: {
					OwnerID: ownerId,
					MemberID: memberId,
					status: TeamStatus.PENDING,
				},
			});
			const newMember = newMemberMutation.data.createTeams;
			return newMember;
		} catch (e) {
			console.log(e);
			setIsError(true);
		}
	};

	const addDoctorMember = async (email: string) => {
		try {
			const doctorExists = await checkIfDoctorExists(email);
			if (doctorExists) {
				return;
			}

			const doctorMember = await createDoctorMember(email);
			const newHospitalDoctorMemberQuery = await execute(createHospitalDoctorMutation, {
				input: {
					doctorID: doctorMember.id,
					hospitalID: actualHospital!.id,
					lastWaitingListID: '',
				},
			});
			const newHospitalDoctorMember = newHospitalDoctorMemberQuery.data.createHospitalDoctor;

			const currentHospitalDoctorQuery = await execute(syncHospitalDoctors, {
				filter: {
					doctorID: {
						eq: actualDoctor!.id,
					},
				},
			});

			const currentHospitalDoctor =
				currentHospitalDoctorQuery.data.syncHospitalDoctors.items[0];
			const newTeamMember = await createDoctorTeamMember(
				currentHospitalDoctor!.id,
				newHospitalDoctorMember!.id
			);
			const tempPassword = generatePassword();
			await Auth.signUp({
				username: email,
				password: tempPassword,
				attributes: {
					email: email,
					'custom:doctorID': doctorMember.id,
					'custom:isTrial': 'false',
					'custom:name': email,
					'custom:role': UserRoles.Member,
					'custom:tempPassword': tempPassword,
				},
			});
			updateSubsQuantity(true, actualDoctor);
			setTeamMembers((teamMembers) => [...teamMembers, newTeamMember]);
		} catch (e) {
			console.log({
				e,
			});
			setIsError(true);
		}
	};

	const updateDoctorInfo = async (doctorInfo: Doctor) => {
		try {
			const currentDoctorResponse = await execute(syncDoctors, {
				filter: {
					id: {
						eq: doctorInfo.id,
					},
				},
			});

			const currentDoctor = currentDoctorResponse.data.syncDoctors.items[0];

			const updatedDoctorResponse = await execute(updateDoctor, {
				input: {
					id: currentDoctor.id,
					name: doctorInfo.name,
					email: doctorInfo.email,
					exequator: doctorInfo.exequator,
					specialty: doctorInfo.specialty,
					code: doctorInfo.code,
					title: doctorInfo.title,
					phone: doctorInfo.phone,
					_version: currentDoctor._version,
				},
			});

			const updatedDoctor = updatedDoctorResponse.data.updateDoctor;

			setActualDoctor(
				(actualDoctor) =>
					({
						...actualDoctor,
						...updatedDoctor,
					} as DoctorType)
			);
			setAlertMessage({
				message: 'Cambios guardados correctamente',
				severity: 'success',
			});
		} catch (error) {
			handleError(error, false);
		}
	};

	const updateActualClientInsurance = async (clientID: string) => {
		try {
			const currentClientResponse = await execute(syncClients, {
				filter: {
					id: {
						eq: clientID,
					},
				},
			});

			const currentClient = currentClientResponse.data.syncClients.items[0];

			setActualClient(currentClient);
			setActualInsurance(currentClient?.actualInsurance);
		} catch (error) {
			handleError(error, false);
		}
	};

	const fetchDoctorTemplates = async () => {
		const fetchedTemplatesResponse = await execute(syncTemplates, {
			filter: {
				doctorID: {
					eq: actualDoctor?.id,
				},
			},
		});
		let fetchedTemplates = fetchedTemplatesResponse.data.syncTemplates.items;

		fetchedTemplates = (await Promise.all(
			fetchedTemplates.map(async (t: any) => {
				if (t.templateType === PrescriptionTypes.MEDICINE) {
					const fetchedMedicinesResponse = await execute(syncTemplateMedicines, {
						filter: {
							templateID: {
								eq: t.id,
							},
						},
					});

					const fetchedMedicines =
						fetchedMedicinesResponse.data.syncTemplateMedicines.items;
					const medicines = await Promise.all(
						fetchedMedicines.map(async (m: any) => {
							const medicineResponse = await execute(syncMedicines, {
								filter: {
									id: {
										eq: m.medicineID,
									},
								},
							});

							const medicine = medicineResponse.data.syncMedicines.items[0];

							return {
								...medicine,
								...m,
								name: medicine ? medicine.name : '',
							};
						})
					);

					return {
						...t,
						list: medicines,
					};
				}

				if (t.templateType === PrescriptionTypes.LABIMAGE) {
					const analisisResponse = await execute(syncTemplateLabImages, {
						filter: {
							templateID: {
								eq: t.id,
							},
						},
					});

					const analisis = analisisResponse.data.syncTemplateLabImages.items;

					return {
						...t,
						list: analisis,
					};
				}
			})
		)) as Template[];

		setActualDoctor(
			(actualDoctor) => ({ ...actualDoctor, templates: fetchedTemplates } as DoctorType)
		);
	};

	const createDoctorStripeCustomer = async (
		customerID: string,
		subscriptionID: string,
		nextPaymentDate: number
	) => {
		try {
			const customerStripeResponse = await execute(createDoctorStripeCustomerSubscription, {
				input: {
					doctorID: actualDoctor!.id,
					stripeCustomerID: customerID,
					defaultCard: '',
					stripeSubscriptionID: subscriptionID,
					nextPaymentDate: getFormatNextPaymentDate(nextPaymentDate),
				},
			});

			const customerStripe =
				customerStripeResponse.data.createDoctorStripeCustomerSubscription;

			setActualDoctor((doctor) => ({
				...doctor!,
				stripeCustomerID: customerStripe.stripeCustomerID as string,
				defaultCard: '',
				stripeSubscriptionID: customerStripe.stripeSubscriptionID as string,
			}));
		} catch (error) {
			handleError(error, false);
		}
	};

	const updateDoctorStripeSubscription = async (subscriptionID: string) => {
		try {
			const subscriptionResponse = await execute(syncDoctorStripeCustomerSubscriptions, {
				filter: {
					doctorID: {
						eq: actualDoctor!.id,
					},
				},
			});

			const subscription =
				subscriptionResponse.data.syncDoctorStripeCustomerSubscriptions.items[0];

			const updatedSubscriptionResponse = await execute(
				updateDoctorStripeCustomerSubscription,
				{
					input: {
						id: subscription.id,
						stripeSubscriptionID: subscriptionID,
						_version: subscription._version,
					},
				}
			);

			const updatedSubscription =
				updatedSubscriptionResponse.data.updateDoctorStripeCustomerSubscription;

			setActualDoctor((doctor) => ({
				...doctor!,
				stripeSubscriptionID: updatedSubscription.stripeSubscriptionID,
			}));
		} catch (error) {
			handleError(error, false);
		}
	};

	const addCardToCustomer = async (paymentMethodID: string, last4: string, brand: string) => {
		try {
			const subscriptionResponse = await execute(syncDoctorStripeCustomerSubscriptions, {
				filter: {
					doctorID: {
						eq: actualDoctor!.id,
					},
				},
			});

			const subscription =
				subscriptionResponse.data.syncDoctorStripeCustomerSubscriptions.items[0];

			const newCardResponse = await execute(createDoctorStripeCards, {
				input: {
					DoctorID: actualDoctor!.id,
					cardLastDigits: `**** **** ${last4}`,
					stripePaymentMethodID: paymentMethodID,
					cardBrand: brand,
				},
			});

			const newCard = newCardResponse.data.createDoctorStripeCards;

			let newDefaultCard = '';

			if (subscription.defaultCard === '') {
				const defaultCardResponse = await execute(updateDoctorStripeCustomerSubscription, {
					input: {
						id: subscription.id,
						defaultCard: newCard.id,
						_version: subscription._version,
					},
				});

				const defaultCardData =
					defaultCardResponse.data.updateDoctorStripeCustomerSubscription;

				const { defaultCard } = defaultCardData;

				newDefaultCard = defaultCard;
			}

			setActualDoctor((doctor) => ({
				...doctor!,
				defaultCard: newDefaultCard || doctor!.defaultCard,
				stripeCards: doctor?.stripeCards ? [...doctor?.stripeCards, newCard] : [newCard],
			}));
		} catch (error) {
			handleError(error, false);
		}
	};

	const changeDefaultCard = async (cardID: string) => {
		const subscriptionResponse = await execute(syncDoctorStripeCustomerSubscriptions, {
			filter: {
				doctorID: {
					eq: actualDoctor!.id,
				},
			},
		});

		const subscription =
			subscriptionResponse.data.syncDoctorStripeCustomerSubscriptions.items[0];

		const subsUpdatedResponse = await execute(updateDoctorStripeCustomerSubscription, {
			input: {
				id: subscription.id,
				defaultCard: cardID,
				_version: subscription._version,
			},
		});

		const subsUpdated = subsUpdatedResponse.data.updateDoctorStripeCustomerSubscription;

		setActualDoctor(
			(doctor) =>
				({
					...doctor!,
					defaultCard: subsUpdated?.defaultCard,
				} as DoctorType)
		);
	};

	const updateSubscription = async (
		subscriptionID: string,
		currentPlanID: number,
		cancelAtPeriodEnd: boolean,
		nextPaymentDate?: number,
		trialsEndsAt?: string,
		doctorID?: string
	) => {
		const customerStripeResponse = await execute(syncDoctorStripeCustomerSubscriptions, {
			filter: {
				doctorID: {
					eq: actualDoctor ? actualDoctor.id : doctorID,
				},
			},
		});

		const customerStripe =
			customerStripeResponse.data.syncDoctorStripeCustomerSubscriptions.items[0];

		try {
			if (nextPaymentDate) {
				await execute(updateDoctorStripeCustomerSubscription, {
					input: {
						id: customerStripe.id,
						stripeSubscriptionID: subscriptionID,
						nextPaymentDate: getFormatNextPaymentDate(nextPaymentDate as number),
						currentPlanID: currentPlanID,
						cancelAtPeriodEnd: cancelAtPeriodEnd,
						trialsEndsAt: trialsEndsAt,
						_version: customerStripe._version,
					},
				});
			} else {
				await execute(updateDoctorStripeCustomerSubscription, {
					input: {
						id: customerStripe.id,
						stripeSubscriptionID: subscriptionID,
						currentPlanID: currentPlanID,
						cancelAtPeriodEnd: cancelAtPeriodEnd,
						_version: customerStripe._version,
					},
				});
			}

			setActualDoctor(
				(doctor) =>
					({
						...doctor!,
						stripeSubscriptionID: subscriptionID,
						currentPlanID: currentPlanID,
						cancelAtPeriodEnd: cancelAtPeriodEnd,
						trialsEndsAt: trialsEndsAt,
						nextPaymentDate: nextPaymentDate
							? getFormatNextPaymentDate(nextPaymentDate)
							: customerStripe.nextPaymentDate,
					} as DoctorType)
			);
		} catch (error) {
			handleError(error, false);
		}
	};

	const updateSubscriptionVerifyContext = async (
		subscriptionID: string,
		currentPlanID: number,
		cancelAtPeriodEnd: boolean,
		nextPaymentDate: string
	) => {
		const customerStripeResponse = await execute(syncDoctorStripeCustomerSubscriptions, {
			filter: {
				doctorID: {
					eq: actualDoctor!.id,
				},
			},
		});

		const customerStripe =
			customerStripeResponse.data.syncDoctorStripeCustomerSubscriptions.items[0];

		try {
			await execute(updateDoctorStripeCustomerSubscription, {
				input: {
					id: customerStripe.id,
					stripeSubscriptionID: subscriptionID,
					nextPaymentDate: nextPaymentDate,
					currentPlanID: currentPlanID,
					cancelAtPeriodEnd: cancelAtPeriodEnd,
					_version: customerStripe._version,
				},
			});

			setActualDoctor(
				(doctor) =>
					({
						...doctor!,
						stripeSubscriptionID: subscriptionID,
						currentPlanID: currentPlanID,
						cancelAtPeriodEnd: cancelAtPeriodEnd,
						nextPaymentDate: nextPaymentDate,
					} as DoctorType)
			);
		} catch (error) {
			handleError(error, false);
		}
	};

	const deleteDoctorCard = async (paymentMethodID: string) => {
		try {
			const doctorStripeCardResponse = await execute(syncDoctorStripeCards, {
				filter: {
					DoctorID: {
						eq: actualDoctor!.id,
					},
				},
			});

			const doctorStripeCard = doctorStripeCardResponse.data.syncDoctorStripeCards.items[0];

			await execute(updateDoctorStripeCards, {
				input: {
					id: doctorStripeCard.id,
					status: Status.INACTIVE,
					_version: doctorStripeCard._version,
				},
			});

			setActualDoctor((doctor) => ({
				...doctor!,
				stripeCards: doctor?.stripeCards.filter(
					(c) => c.stripePaymentMethodID !== paymentMethodID
				)!,
			}));
		} catch (error) {
			handleError(error, false);
		}
	};

	const updatePaymentDate = async () => {
		const customerStripeResponse = await execute(syncDoctorStripeCustomerSubscriptions, {
			filter: {
				doctorID: {
					eq: actualDoctor!.id,
				},
			},
		});

		const customerStripe =
			customerStripeResponse.data.syncDoctorStripeCustomerSubscriptions.items[0];

		setActualDoctor((ad) => ({
			...ad!,
			nextPaymentDate: customerStripe?.nextPaymentDate,
		}));
	};

	// Lab/Images
	const fetchDoctorPrescriptionFreePrescriptions = async (page?: number, limit?: number) => {
		try {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const fetchedRecentFreePrescriptions = await execute(syncClientFreePrescriptions, {
				filter: {
					doctorID: {
						eq: actualDoctor!.id,
					},
				},
			});
		} catch (error) {
			handleError(error, false);
		}
	};

	const fetchDoctorAppointments = async (
		filter: string | { start: string; end: string },
		doctorId: string | null = null
	) => {
		let filterData = {};

		if (typeof filter === 'string') {
			filterData.date = {
				eq: filter,
			};
		} else {
			filterData.and = [
				{
					date: {
						ge: filter.start,
					},
				},
				{
					date: {
						le: filter.end,
					},
				},
			];
		}
		const doctorID = doctorId ?? actualDoctor?.memberId ?? actualDoctor?.id;

		try {
			const appointmentsResponse = await execute(syncAppointments, {
				filter: {
					...filterData,
					status: {
						ne: Status.INACTIVE,
					},
					doctorID: {
						eq: doctorID,
					},
				},
			});

			const appointments = await Promise.all(
				appointmentsResponse.data.syncAppointments.items.map(async (a) => {
					let imageUrl = null;

					if (a.client.profileImage) {
						const url = await Storage.get(a.client.profileImage);
						imageUrl = url;
					}

					return {
						...a,
						client: {
							...a.client,
							name: `${a.client.name} ${a.client.lastName}`,
							profileImage: imageUrl,
						},
					};
				})
			);
			return appointments as Appointment[];
		} catch (error) {
			handleError(error, false);
			return [];
		}
	};

	const fetchDoctorPrescriptionLabImages = async (page?: number, limit?: number) => {
		try {
			const fetchedRecentLabImagesResponse = await execute(
				syncRecentDoctorLabImagePrescriptions,
				{
					filter: {
						doctorID: {
							eq: actualDoctor!.id,
						},
						status: {
							ne: Status.INACTIVE,
						},
					},
				}
			);

			const fetchedRecentLabImages =
				fetchedRecentLabImagesResponse.data.syncRecentDoctorLabImagePrescriptions.items;

			if (page && page > 0) {
				const fetchedRecentLabImagesFiltered = fetchedRecentLabImages.splice(page, limit);

				setActualDoctor(
					(d) =>
						({
							...d,
							recentLabImages: fetchedRecentLabImagesFiltered,
						} as DoctorType)
				);
			} else {
				setActualDoctor(
					(d) =>
						({
							...d,
							recentLabImages: fetchedRecentLabImages,
						} as DoctorType)
				);
			}
		} catch (error) {
			handleError(error, false);
		}
	};

	const getAllDoctorsLabImages = async () => {
		try {
			let tokenData = null;
			const allDoctorLabImages: Query_LabImage[] = [];

			do {
				const doctorLabImagePage = await execute(syncDoctorLabImages, {
					limit: 1000,
					nextToken: tokenData,
				});

				const doctorLabImages = doctorLabImagePage.data.syncDoctorLabImages.items.map(
					(m: any) => {
						return {
							...m.labImage,
							doctorLabImageId: m.id,
							doctorID: m.doctorID,
						};
					}
				);

				allDoctorLabImages.push(...doctorLabImages);

				tokenData = doctorLabImagePage.data.syncDoctorLabImages.nextToken;
			} while (tokenData);

			return allDoctorLabImages;
		} catch (error) {
			handleError(error, false);
			return [];
		}
	};

	const getAllLabImages = async () => {
		try {
			let tokenData = null;
			const allLabImages: Query_LabImage[] = [];
			const allDoctorLabImages = await getAllDoctorsLabImages();

			do {
				const labImagePage = await execute(syncLabImages, {
					limit: 1000,
					nextToken: tokenData,
				});

				const labImages = labImagePage.data.syncLabImages.items;

				allLabImages.push(...labImages);

				tokenData = labImagePage.data.syncLabImages.nextToken;
			} while (tokenData);

			const filteredLabImages = allLabImages.filter(
				(li) =>
					!allDoctorLabImages.some(
						(dli) => dli.id === li.id && dli.doctorID !== actualDoctor?.id
					) && li.name !== ''
			);

			return filteredLabImages;
		} catch (error) {
			handleError(error, false);
			return [];
		}
	};

	const fetchLabImages = async (page?: number, limit?: number) => {
		try {
			const fetchedLabImages = await getAllLabImages();

			if (page && page > 0) {
				const fetchedLabImagesFiltered = fetchedLabImages.splice(page, limit);

				setLabImages(fetchedLabImagesFiltered);
			} else {
				setLabImages(fetchedLabImages);
			}
		} catch (error) {
			handleError(error, false);
		}
	};

	const getAllDoctorLabImages = async () => {
		try {
			let tokenData = null;
			const allDoctorLabImages = [];

			do {
				const doctorLabImagePage = await execute(syncDoctorLabImages, {
					limit: 1000,
					filter: {
						doctorID: {
							eq: actualDoctor?.id,
						},
						status: {
							ne: Status.INACTIVE,
						},
					},
					nextToken: tokenData,
				});

				const doctorLabImages = doctorLabImagePage.data.syncDoctorLabImages.items;

				allDoctorLabImages.push(...doctorLabImages);

				tokenData = doctorLabImagePage.data.syncDoctorLabImages.nextToken;
			} while (tokenData);

			return allDoctorLabImages;
		} catch (error) {
			handleError(error, false);
			return [];
		}
	};

	const fetchDoctorLabImages = async (page?: number, limit?: number) => {
		try {
			const fetchedDoctorLabImages = await getAllDoctorLabImages();
			setActualDoctor(
				(doctor) =>
					({
						...doctor,
						labImages: fetchedDoctorLabImages
							.filter((li: any) => li.labImage)
							.map((li: any) => {
								return {
									...li.labImage,
									doctorLabImage: li.id,
								};
							})
							.reverse(),
					} as DoctorType)
			);
		} catch (error) {
			handleError(error, false);
		}
	};

	const createDoctorLabImage = async (labImageID: string) => {
		const newDoctorLabImage = await execute(createDoctorLabImageMutation, {
			input: {
				doctorID: actualDoctor?.id as string,
				labImageID: labImageID,
				status: Status.ACTIVE,
			},
		});

		const doctorLabImage = newDoctorLabImage.data.createDoctorLabImage;

		setActualDoctor(
			(doctor) =>
				({
					...doctor!,
					labImages: [doctorLabImage.labImage, ...actualDoctor!.labImages],
				} as DoctorType)
		);
	};

	const updateDoctorLabImage = async (labImageID: string) => {
		const currentDoctorLabImageResponse = await execute(syncDoctorLabImages, {
			filter: {
				id: {
					eq: labImageID,
				},
			},
		});

		const currentDoctorLabImage =
			currentDoctorLabImageResponse.data.syncDoctorLabImages.items[0];

		await execute(updateDoctorLabImageMutation, {
			input: {
				id: currentDoctorLabImage.id,
				status: Status.INACTIVE,
				_version: currentDoctorLabImage._version,
			},
		});
	};

	const createLabImage = async (labImageName: string, description?: string) => {
		try {
			const labImagesResponse = await getAllLabImages();

			const labImages = labImagesResponse.map((m: any) => {
				return { ...m, name: m.name?.toLocaleLowerCase() };
			});

			let newLabImage = labImages.find(
				(m: any) => m.name === labImageName.toLocaleLowerCase()
			);

			if (!newLabImage) {
				const newLabImageResponse = await execute(createLabImageMutation, {
					input: {
						name: labImageName,
						description: description ? description : '',
					},
				});

				newLabImage = newLabImageResponse.data.createLabImage;
				setLabImages((li) => [
					JSON.parse(JSON.stringify({ ...newLabImage, name: labImageName })),
					...li,
				]);
			}

			setAlertMessage({
				message: `${labImageName} agregado correctamente.`,
				severity: 'success',
			});

			return newLabImage;
		} catch (error) {
			handleError(error, false);
		}
	};

	const editLabImage = async (labImage: LabImage) => {
		try {
			const labImageResponse = await execute(syncLabImages, {
				filter: {
					id: {
						eq: labImage.id,
					},
				},
			});

			const labImageToEdit = labImageResponse.data.syncLabImages.items[0];

			await execute(updateLabImage, {
				input: {
					id: labImageToEdit.id,
					name: labImage.name,
					description: labImage.description,
					_version: labImageToEdit._version,
				},
			});

			const labIndex = actualDoctor!.labImages.findIndex((li) => li.id === labImage.id);

			setActualDoctor((d) => ({
				...d!,
				labImages: [
					labImage,
					...actualDoctor!.labImages.slice(0, labIndex),
					...actualDoctor!.labImages.slice(labIndex + 1),
				],
			}));

			setAlertMessage({
				message: `${labImage.name} modificado correctamente.`,
				severity: 'success',
			});
		} catch (error) {
			handleError(error, false);
		}
	};

	const removeLabFromDoctorRecent = async (labImage: any) => {
		try {
			const recentDoctorLabImageResponse = await execute(
				syncRecentDoctorLabImagePrescriptions,
				{
					filter: {
						doctorID: {
							eq: actualDoctor!.id,
						},
						labImageID: {
							eq: labImage.id,
						},
					},
				}
			);

			const recentDoctorLabImage =
				recentDoctorLabImageResponse.data.syncRecentDoctorLabImagePrescriptions.items[0];

			await execute(updateRecentDoctorLabImagePrescription, {
				input: {
					id: recentDoctorLabImage.id,
					status: Status.INACTIVE,
					_version: recentDoctorLabImage._version,
				},
			});

			setActualDoctor(
				(doctor) =>
					({
						...doctor,
						recentLabImages: actualDoctor!.recentLabImages.filter(
							(li) => li.id !== labImage.id
						),
					} as DoctorType)
			);
		} catch (error) {
			handleError(error, false);
		}
	};

	const deleteLabImage = async (labImage: any) => {
		try {
			const doctorLabImageResponse = await execute(syncDoctorLabImages, {
				filter: {
					id: {
						eq: labImage.doctorLabImage,
					},
				},
			});

			const doctorLabImage = doctorLabImageResponse.data.syncDoctorLabImages.items[0];

			// Check if lab exist on recentLabImages
			const recentLanCheck = actualDoctor?.recentLabImages?.find(
				(m) => m.labImageID === labImage.id
			);

			await execute(updateDoctorLabImageMutation, {
				input: {
					id: doctorLabImage.id,
					status: Status.INACTIVE,
					_version: doctorLabImage._version,
				},
			});

			if (recentLanCheck) {
				await removeLabFromDoctorRecent(labImage);
			}

			setActualDoctor(
				(doctor) =>
					({
						...doctor,
						labImages: actualDoctor!.labImages.filter((li) => li.id !== labImage.id),
					} as DoctorType)
			);

			setAlertMessage({
				message: `${labImage.name} eliminado correctamente.`,
				severity: 'success',
			});
		} catch (error) {
			handleError(error, false);
		}
	};

	const createDoctorTemplate = async (
		templateName: string,
		templateType: PrescriptionTypes,
		list: CreateTemplateMedicine[] | CreateTemplateLabImage[]
	) => {
		try {
			const templateResponse = await execute(createTemplate, {
				input: {
					doctorID: actualDoctor?.id as string,
					templateName,
					templateType,
				},
			});

			const template = templateResponse.data.createTemplate;

			if (templateType === PrescriptionTypes.MEDICINE) {
				const templateList = list as TemplateMedicine[];
				await Promise.all(
					templateList.map(async (t) => {
						await execute(createTemplateMedicine, {
							input: {
								templateID: template.id,
								medicineID: t.medicineID,
								take: t.take,
								indication: t.indication,
								duration: t.duration,
								description: t.description,
							},
						});
					})
				);
			}
			if (templateType === PrescriptionTypes.LABIMAGE) {
				const templateList = list as TemplateLabImage[];
				await Promise.all(
					templateList.map(async (t) => {
						await execute(createTemplateLabImage, {
							input: {
								templateID: template.id,
								name: t.name,
								description: t.description,
							},
						});
					})
				);
			}

			const newTemplate = {
				id: template.id,
				templateName,
				templateType,
				list: list.map((l) => {
					return { ...l, templateID: template.id };
				}),
			};

			setActualDoctor(
				(doctor) =>
					({
						...doctor,
						templates: doctor?.templates
							? [...doctor?.templates, newTemplate]
							: [newTemplate],
					} as DoctorType)
			);
		} catch (error) {
			handleError(error, false);
		}
	};

	// Medicines
	const fetchDoctorPrescriptionMedicines = async (page?: number, limit?: number) => {
		try {
			const fetchedRecentMedicinesResponse = await execute(
				syncRecentDoctorMedicinePrescriptions,
				{
					filter: {
						doctorID: {
							eq: actualDoctor!.id,
						},
						status: {
							ne: Status.INACTIVE,
						},
					},
				}
			);

			const fetchedRecentMedicines =
				fetchedRecentMedicinesResponse.data.syncRecentDoctorMedicinePrescriptions.items;

			const orderMedicine = fetchedRecentMedicines.sort((a: any, b: any) => {
				return a._lastChangedAt < b._lastChangedAt ? 1 : -1;
			});

			if (page && page > 0) {
				const fetchedRecentMedicinesFiltered = orderMedicine.splice(page, limit);

				setActualDoctor(
					(d) =>
						({
							...d,
							recentMedicines: fetchedRecentMedicinesFiltered.map((m: any) => {
								return {
									...m,
									name: m.medicine?.name,
								};
							}),
						} as DoctorType)
				);
			} else {
				setActualDoctor(
					(d) =>
						({
							...d,
							recentMedicines: orderMedicine.map((m: any) => {
								return {
									...m,
									name: m.medicine?.name,
								};
							}),
						} as DoctorType)
				);
			}
		} catch (error) {
			handleError(error, false);
		}
	};

	const getAllDoctorMedicines = async () => {
		try {
			let tokenData = null;
			const allDoctorMedicines: Query_Medicine[] = [];

			do {
				const doctorMedicinePage = await execute(syncDoctorMedicines, {
					filter: {
						doctorID: {
							eq: actualDoctor?.id,
						},
						isDeleted: {
							ne: true,
						},
					},
					limit: 1000,
					nextToken: tokenData,
				});

				const doctorMedicines = doctorMedicinePage.data.syncDoctorMedicines.items.map(
					(dm: any) => {
						return {
							...dm.medicine,
							doctorMedicineId: dm.id,
							doctorID: dm.doctorID,
						};
					}
				);

				allDoctorMedicines.push(...doctorMedicines);

				tokenData = doctorMedicinePage.data.syncDoctorMedicines.nextToken;
			} while (tokenData);

			return allDoctorMedicines;
		} catch (error) {
			handleError(error, false);
			return [];
		}
	};

	const getAllDoctorsMedicines = async () => {
		try {
			let tokenData = null;
			const allDoctorMedicines: Query_Medicine[] = [];

			do {
				const doctorMedicinePage = await execute(syncDoctorMedicines, {
					limit: 1000,
					nextToken: tokenData,
				});

				const doctorMedicines = doctorMedicinePage.data.syncDoctorMedicines.items.map(
					(dm: any) => {
						return {
							...dm.medicine,
							doctorMedicineId: dm.id,
							doctorID: dm.doctorID,
						};
					}
				);

				allDoctorMedicines.push(...doctorMedicines);

				tokenData = doctorMedicinePage.data.syncDoctorMedicines.nextToken;
			} while (tokenData);

			return allDoctorMedicines;
		} catch (error) {
			handleError(error, false);
			return [];
		}
	};

	const getAllMedicines = async () => {
		try {
			let tokenData = null;
			const allMedicines: Query_Medicine[] = [];

			const allDoctorMedicines = await getAllDoctorsMedicines();

			do {
				const medicinePage = await execute(syncMedicines, {
					limit: 1000,
					nextToken: tokenData,
				});

				const medicines = medicinePage.data.syncMedicines.items;

				allMedicines.push(...medicines);

				tokenData = medicinePage.data.syncMedicines.nextToken;
			} while (tokenData);

			const filteredMedicines = allMedicines.filter(
				(m: Query_Medicine) =>
					!allDoctorMedicines.some(
						(dm: Query_Medicine) => dm.id === m.id && dm.doctorID !== actualDoctor?.id
					) && m.name !== ''
			);

			return filteredMedicines;
		} catch (error) {
			handleError(error, false);
			return [];
		}
	};

	const fetchMedicines = async (page?: number, limit?: number) => {
		const fetchedMedicines = await getAllMedicines();

		if (page && page > 0) {
			const fetchedMedicinesFiltered = fetchedMedicines.splice(page, limit);

			setMedicines(fetchedMedicinesFiltered);
		} else {
			setMedicines(fetchedMedicines);
		}
	};

	const fetchDoctorMedicines = async (page?: number, limit?: number) => {
		const fetchDoctorMedicinesResponse = await getAllDoctorMedicines();

		const orderedMedicines = fetchDoctorMedicinesResponse.sort(orderByLastChangedDate);

		setActualDoctor(
			(d) =>
				({
					...d,
					medicines: orderedMedicines,
				} as DoctorType)
		);
	};

	const fetchMedicinesToSuggest = async (name: string): Promise<MedicineType[]> => {
		const suggestionsResponse = await getAllMedicines();

		const suggestions = suggestionsResponse
			.filter((m: any) => m.name?.toLowerCase().includes(name.toLowerCase()))
			.slice(0, 10);

		return suggestions as MedicineType[];
	};

	const createMedicine = async (medicineName: string) => {
		const newMedicineResponse = await execute(createMedicineMutation, {
			input: {
				name: medicineName,
			},
		});

		const newMedicineCreated = newMedicineResponse.data.createMedicine;

		setAlertMessage({
			message: `${medicineName} agregado correctamente.`,
			severity: 'success',
		});

		return { ...newMedicineCreated } as MedicineType;
	};

	const createDoctorMedicine = async (medicineId: string, isMedicineForm = true) => {
		const newDoctorMedicineResponse = await execute(createDoctorMedicineMutation, {
			input: {
				doctorID: actualDoctor?.id as string,
				medicineID: medicineId,
				isDeleted: false,
			},
		});

		const newDoctorMedicineCreated = newDoctorMedicineResponse.data.createDoctorMedicine;

		setActualDoctor(
			(d) =>
				({
					...d!,
					medicines: [
						{
							...newDoctorMedicineCreated.medicine,
							medicineID: newDoctorMedicineCreated.medicine.id,
						},
						...d!.medicines,
					],
				} as DoctorType)
		);

		if (isMedicineForm) {
			setAlertMessage({
				message: `${newDoctorMedicineCreated.medicine.name} agregado correctamente.`,
				severity: 'success',
			});
		}
		return { ...newDoctorMedicineCreated.medicine };
	};

	const updateDoctorMedicine = async (id: string, isDeleted: boolean) => {
		const doctorMedicineToUpdateResponse = await execute(syncDoctorMedicines, {
			filter: {
				id: {
					eq: id,
				},
			},
		});

		const doctorMedicineToUpdate =
			doctorMedicineToUpdateResponse.data.syncDoctorMedicines.items[0];

		await execute(updateDoctorMedicineMutation, {
			input: {
				id: doctorMedicineToUpdate.id,
				isDeleted: isDeleted,
				_version: doctorMedicineToUpdate._version,
			},
		});
	};

	const editMedicine = async (medicineName: string, selectedMedicine: MedicineType) => {
		try {
			/* getting medicine to edit from data store */
			const medicineToEditResponse = await execute(syncDoctorMedicines, {
				filter: {
					id: {
						eq: selectedMedicine.doctorMedicineId,
					},
				},
			});

			const medicineToEdit = medicineToEditResponse.data.syncDoctorMedicines.items[0];
			/* updating Medicine */
			if (medicineToEdit && medicineToEdit.name !== medicineName) {
				await execute(updateMedicine, {
					input: {
						id: medicineToEdit.medicine.id,
						name: medicineName,
						_version: medicineToEdit.medicine._version,
					},
				});
			}

			setActualDoctor((d) => {
				if (!d) return d;

				const updatedMedicine = d.medicines.find(
					(m) => m.doctorMedicineId === selectedMedicine.doctorMedicineId
				);

				const updatedMedicineWithNewName = {
					...updatedMedicine,
					name: medicineName,
				};

				const otherMedicines = d.medicines.filter(
					(m) => m.doctorMedicineId !== selectedMedicine.doctorMedicineId
				);

				return {
					...d,
					medicines: [updatedMedicineWithNewName, ...otherMedicines],
				} as DoctorType;
			});

			setAlertMessage({
				message: `${medicineName} modificado correctamente.`,
				severity: 'success',
			});
		} catch (error) {
			handleError(error, false);
		}
	};

	const deleteMedicine = async (medicine: MedicineType) => {
		// Get doctorMedicine
		const doctorMedicineToEditResponse = await execute(syncDoctorMedicines, {
			filter: {
				id: {
					eq: medicine.doctorMedicineId,
				},
			},
		});

		const doctorMedicineToEdit = doctorMedicineToEditResponse.data.syncDoctorMedicines.items[0];

		// If medicine is actualDoctorRecentMedicine (Delete)
		const medicinePrescr = actualDoctor?.recentMedicines?.find(
			(m) => m.medicineID === medicine.id
		);

		if (medicinePrescr) {
			const doctorMedicinePrescriptionResponse = await execute(
				syncRecentDoctorMedicinePrescriptions,
				{
					filter: {
						medicineID: {
							eq: medicine.id,
						},
					},
				}
			);

			const doctorMedicinePrescription =
				doctorMedicinePrescriptionResponse.data.syncRecentDoctorMedicinePrescriptions
					.items[0];

			await execute(updateRecentDoctorMedicinePrescription, {
				input: {
					id: doctorMedicinePrescription.id,
					status: Status.INACTIVE,
					_version: doctorMedicinePrescription._version,
				},
			});
		}

		if (doctorMedicineToEdit) {
			await execute(updateDoctorMedicineMutation, {
				input: {
					id: doctorMedicineToEdit.id,
					isDeleted: true,
					_version: doctorMedicineToEdit._version,
				},
			});

			setActualDoctor((d) => ({
				...d!,
				medicines: d!.medicines.filter((m) => m.id !== medicine.id),
			}));

			setAlertMessage({
				message: `${medicine.name} eliminado correctamente.`,
				severity: 'success',
			});
		} else {
			setAlertMessage({
				message: `Error al eliminar ${medicine.name}.`,
				severity: 'error',
			});
		}
	};

	// Secretary from doctor
	const deleteSecretaryFromDoctor = async (secretary: SecretaryType) => {
		const hospitalDoctorSecretaryResponse = await execute(syncSecretaryHospitalDoctors, {
			filter: {
				secretaryID: {
					eq: secretary.id,
				},
			},
		});

		const hospitalDoctorSecretaryToDelete =
			hospitalDoctorSecretaryResponse.data.syncSecretaryHospitalDoctors.items[0];

		await execute(updateSecretaryHospitalDoctor, {
			input: {
				id: hospitalDoctorSecretaryToDelete.id,
				status: Status.INACTIVE,
				_version: hospitalDoctorSecretaryToDelete._version,
			},
		});

		await fetchDoctorHospitalSecretarys();
	};

	const createHospitalDoctorClients = async (
		clientID: string,
		hospitalDoctorID: string,
		lastHealthInsurranceID: string | null,
		recordNo: string
	): Promise<void> => {
		try {
			const data = {
				clientID,
				hospitalDoctorID,
				lastHealthInsurranceID,
				clientStatus: Status.ACTIVE,
				recordNo,
			};
			await execute(createHospitalDoctorCliente, {
				input: data,
			});
		} catch (error) {
			handleError(error, false);
		}
	};

	const verifyClientExistInHospitalDoctor = async (client: ClientType) => {
		const customFilter = await getCustomHospitalDoctorFilters();
		const clientHospitalDoctorResponse = await execute(syncHospitalDoctorClientes, {
			filter: {
				clientID: {
					eq: client.id,
				},
				or: customFilter,
			},
		});

		const clientHospitalDoctor =
			clientHospitalDoctorResponse.data.syncHospitalDoctorClientes.items[0];

		if (!clientHospitalDoctor) {
			if (clientHospitalDoctor.clientInsurance) {
				await execute(updateClientInsurance, {
					input: {
						id: clientHospitalDoctor.clientInsurance.id,
						affiliateNumber: clientHospitalDoctor.clientInsurance.affiliateNumber,
						clientID: client.id,
						affiliateType:
							clientHospitalDoctor.clientInsurance.affiliateType ||
							AffiliateTypes.PRINCIPAL,
						contractNumber: clientHospitalDoctor.clientInsurance.contractNumber,
						insuranceID: clientHospitalDoctor.clientInsurance.insuranceID,
						_version: clientHospitalDoctor.clientInsurance._version,
					},
				});
			}

			await createHospitalDoctorClients(
				client.id,
				actualHospitalDoctor!.id,
				clientHospitalDoctor.clientInsurance.insurance.id
			);
		}
	};

	const updateHospitalDoctorClientLastInsuranceId = async (
		clientId: string,
		newInsuranceId: string
	) => {
		const customFilter = await getCustomHospitalDoctorFilters();
		const currentHospitalDoctorClientResponse = await execute(syncHospitalDoctorClientes, {
			filter: {
				clientID: {
					eq: clientId,
				},
				or: customFilter,
			},
		});

		const currentHospitalDoctorClient =
			currentHospitalDoctorClientResponse.data.syncHospitalDoctorClientes.items[0];

		if (currentHospitalDoctorClient) {
			await execute(updateHospitalDoctorCliente, {
				input: {
					id: currentHospitalDoctorClient.id,
					lastHealthInsurranceID: newInsuranceId,
					_version: currentHospitalDoctorClient._version,
				},
			});
		}
	};

	const getActualSubProfile = async () => {
		try {
			const subProfileResponse = await execute(syncDoctorStripeCustomerSubscriptions, {
				filter: {
					doctorID: {
						eq: actualDoctor!.id,
					},
				},
			});

			const subProfile =
				subProfileResponse.data.syncDoctorStripeCustomerSubscriptions.items[0];

			return subProfile;
		} catch (error) {
			handleError(error, false);
		}
	};

	return (
		<RelationsContext.Provider
			value={{
				actualClient,
				alertMessage,
				actualInsurance,
				actualHospitalDoctor,
				actualDoctor,
				actualHospital,
				actualSecretary,
				medicines,
				verifyClientExistInHospitalDoctor: verifyClientExistInHospitalDoctor,
				updateHospitalDoctorClientLastInsuranceId,
				setActualSecretary,
				setAlertMessage,
				setActualClient,
				setActualInsurance,
				createHospitalDoctor,
				setActualHospitalDoctor,
				setActualHospital,
				setActualDoctor,
				updateActualClientInsurance,
				labImages,
				fetchLabImages,
				fetchDoctorPrescriptionLabImages,
				fetchDoctorLabImages,
				createDoctorLabImage,
				createLabImage,
				updateDoctorLabImage,
				editLabImage,
				deleteLabImage,
				fetchDoctorPrescriptionMedicines,
				createMedicine,
				createHospitalDoctorClients,
				deleteMedicine,
				editMedicine,
				updateDoctorInfo,
				createDoctorStripeCustomer,
				addCardToCustomer,
				changeDefaultCard,
				deleteDoctorCard,
				updatePaymentDate,
				updateSubscription,
				fetchDoctorMedicines,
				createDoctorMedicine,
				updateDoctorMedicine,
				fetchMedicines,
				fetchDoctorTemplates,
				createDoctorTemplate,
				fetchMedicinesToSuggest,
				fetchDoctorHospitals,
				fetchDoctorHospitalSecretarys,
				deleteSecretaryFromDoctor,
				updateHospital,
				addLogoToHospitalDoctor,
				updateHospitalDoctor,
				updateSubscriptionVerifyContext,
				fetchDoctorPrescriptionFreePrescriptions,
				fetchDoctorAppointments,
				isCalendarSideViewOpen,
				setIsCalendarSideViewOpen,
				getActualSubProfile,
				handleError,
				teamMembers: teamMembers,
				getTeamMembers: getTeamMembers,
				addDoctorMember: addDoctorMember,
				loadingMembers: loadingMembers,
				disableTeamMember: disableTeamMember,
				setOwnerHospitalDoctorId: setOwnerHospitalDoctorId,
				ownerHospitalDoctorId: ownerHospitalDoctorId,
				updateDoctorStripeSubscription,
				getCustomHospitalDoctorFilters,
				getCustomHospitalDoctorFiltersWithOutCurrentUser,
			}}
		>
			{props.children}
		</RelationsContext.Provider>
	);
};

export default RelationsProvider;
