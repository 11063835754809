import React, { FC, useContext, useEffect, useState } from 'react';
import { PreviousContainer, Container, StyledPagination } from './style';
import { usePagination } from '../../../shared/paginationUtils';
import ConsultationCard from './ConsultationCard';
import { ClientsContext } from '../../../context/client-context';
import {
	ClientContextProps,
	ConsultationDiagnosticType,
	LoaderContextProps,
} from '../../../context/types';
import { ClientPrescriptionAnalisis, ClientPrescriptionMedicine } from 'API';
import ListEmptyState from '../../../components/clients/history/components/ListEmptyState';
import { DiagnosticTypes } from 'models';
import { LoaderContext } from 'context/loader-context';
import useMobile from 'hooks/useMobile';

interface Consultation {
	id: string;
	title: string;
	date: string;
	description: string;
	note_physic_test: string;
	medicines_prescripted: ClientPrescriptionMedicine[];
	labs_images: ClientPrescriptionAnalisis[];
	diagnostics: ConsultationDiagnosticType[];
	diffDiagnostics: ConsultationDiagnosticType[];
	treatment: string;
	procedures_cirgury: string;
	proceures_cirugy_description: string;
}

interface Props {
	height?: string;
	setActualtab?: (val: number) => void;
}

const PreviousConsultation: FC<Props> = ({ height, setActualtab }) => {
	const { clientConsultations, getConsultationsData } = useContext(
		ClientsContext
	) as ClientContextProps;
	const LIST_PER_PAGE = 6;
	const [consultations, setConsultations] = useState<Consultation[]>([] as Consultation[]);
	const { showSpinner } = useContext(LoaderContext) as LoaderContextProps;
	const [tryFetch, setTryFetch] = useState<boolean>(false);
	const { isMobile } = useMobile();
	const count = Math.ceil(consultations.length / LIST_PER_PAGE);
	const _DATA = usePagination(consultations, LIST_PER_PAGE);

	useEffect(() => {
		let data: any[] = [];
		if (clientConsultations && clientConsultations.length > 0) {
			data = clientConsultations.map((consultation) => {
				let diffDiagnostics: any[] = [];
				if (consultation.diffDiagnostics) {
					diffDiagnostics = consultation.diffDiagnostics;
				} else {
					diffDiagnostics = consultation.diagnostics.filter(
						(d: any) => d.type === DiagnosticTypes.DIFFERENTIAL
					);
				}
				const consultion = {
					id: consultation.id,
					title: consultation?.visitReason,
					date: consultation.updatedAt,
					description: consultation.visitDescription,
					note_physic_test: consultation.physicTestNote,
					medicines_prescripted: consultation.prescription.medicines,
					freePrescription: consultation.prescription.freePrescription,
					labs_images: consultation.prescription.analisis,
					diagnostics: consultation?.diagnostics.filter(
						(d: any) => d.type === DiagnosticTypes.PRESUMPTIVE
					),
					diffDiagnostics: diffDiagnostics,
					treatment: consultation.treatment,
					procedures_cirgury: consultation.procedureName,
					proceures_cirugy_description: consultation.procedureDescription,
				};
				return consultion;
			});

			setConsultations(data);
		}

		if (clientConsultations.length === 0 && !tryFetch) {
			showSpinner(true);
			getConsultationsData().finally(() => {
				showSpinner(false);
			});
			setTryFetch(true);
		}
	}, [clientConsultations]);

	const handleChangePage = (_e: any, p: number) => {
		_DATA.jump(p);
	};

	return (
		<Container
			item
			xs={12}
			style={
				isMobile
					? {
							padding: '16px',
							backgroundColor: '#eceff1',
							paddingBottom: '65px',
							height: `calc(100dvh - ${height ? height : '140px'})`,
					  }
					: {}
			}
		>
			{_DATA.currentData().length > 0 ? (
				<>
					{_DATA.currentData().map((consultation) => {
						return (
							<PreviousContainer
								key={consultation.id}
								item
								xs={12}
								style={isMobile ? { padding: '0', marginBottom: '16px' } : {}}
							>
								<ConsultationCard {...consultation} />
							</PreviousContainer>
						);
					})}

					{consultations.length > LIST_PER_PAGE && (
						<StyledPagination
							page={_DATA.currentPage}
							onChange={handleChangePage}
							count={count}
							variant="outlined"
							color="primary"
						/>
					)}
				</>
			) : (
				<ListEmptyState
					onClickHandler={() => {
						setActualtab?.(1);
					}}
				/>
			)}
		</Container>
	);
};

export default PreviousConsultation;
