import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListSubheader from '@material-ui/core/ListSubheader';
import { useTheme } from '@material-ui/core/styles';
import { VariableSizeList } from 'react-window';
import { AutocompleteRenderGroupParams } from '@material-ui/lab/Autocomplete';
import { useResetCache } from '../../shared/hooks/customHooks';
import { LISTBOX_PADDING } from '../../shared/constants';
import { virtualCloneElement } from '../../shared/utilFunctions';

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
	const outerProps = React.useContext(OuterElementContext);
	return <div ref={ref} {...props} {...outerProps} />;
});

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement>(function ListboxComponent(props, ref) {
	const { children, ...other } = props;
	const itemData = React.Children.toArray(children);
	const theme = useTheme();
	const isSMBreakPoint = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
	const itemCount = itemData.length;
	const itemSize = isSMBreakPoint ? 70 : 120;

	const getChildSize = (child: React.ReactNode) => {
		if (React.isValidElement(child) && child.type === ListSubheader) {
			return 48;
		}

		return itemSize;
	};

	const getHeight = () => {
		if (itemCount > 8) {
			return 8 * itemSize;
		}

		return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
	};

	const gridRef = useResetCache(itemCount);
	return (
		<div ref={ref}>
			<OuterElementContext.Provider value={other}>
				<VariableSizeList
					itemData={itemData}
					height={getHeight() + 2 * LISTBOX_PADDING}
					width="100%"
					style={{
						display: 'flex',
						gap: '16px',
						justifyContent: 'space-between',
					}}
					ref={gridRef}
					outerElementType={OuterElementType}
					innerElementType="ul"
					itemSize={(index) => getChildSize(itemData[index])}
					overscanCount={5}
					itemCount={itemCount}
				>
					{virtualCloneElement}
				</VariableSizeList>
			</OuterElementContext.Provider>
		</div>
	);
});

export const renderGroup = (params: AutocompleteRenderGroupParams) => [
	<ListSubheader key={params.key} component="div">
		{params.group}
	</ListSubheader>,
	params.children,
];

export default ListboxComponent;
