import React, { useContext, useEffect, useState, useCallback } from 'react';
import { ClientsContext } from '../../context/client-context';
import { WaitingListContextProps, WaitingListsContext } from '../../context/waiting-list-context';
import ClientC from './client/Patient';
import { ClientContextProps, ClientType, RelationsContextProps } from '../../context/types';
import ExcelButton from '../excelButton/ExcelButton';
import ModalClient from '../modalClient/ModalPatient';
import ModalContainer from '../modalContainer/ModalContainer';
import ExportExcelClients from '../exportExcel/ExportExcelClients';
import SaveButton from '../saveButton/SaveButton';
import ListContainer from '../list/ListContainer';
import { StyledPatientList } from '../../shared/style';
import LoadingSpinner from '../loading/LoadingSpinner';
import { API, graphqlOperation, GraphQLSubscription } from '@aws-amplify/api';
import NoItem from '../noItems';
import { OnCreateClientSubscription, OnUpdateClientSubscription } from 'API';
import { onCreateClient, onUpdateClient } from 'graphql/subscriptions';
import useMobile from 'hooks/useMobile';
import CustomPagination from './CustomPagination';
import { RelationsContext } from 'context/relations-context';

interface Props {
	fullWidth?: boolean;
}
const PatientList: React.FC<Props> = ({ fullWidth }) => {
	const {
		getClientDataToEdit,
		setSelectedClient,
		fetchPaginatedClients,
		paginatedClients: patients,
		page,
		setPage,
		rowsPerPage,
		clientsLoading: isLoading,
		setClientsLoading: setIsLoading,
	} = useContext(ClientsContext) as ClientContextProps;
	const { getCustomHospitalDoctorFilters, actualHospitalDoctor } = useContext(
		RelationsContext
	) as RelationsContextProps;
	const [filterText, setFilterText] = useState<string>('');
	const { isMobile } = useMobile();
	const waitingListsContext = useContext(WaitingListsContext) as WaitingListContextProps;
	const [propertiesToExport, setPropertiesToExport] = useState({});
	const [editing, setEditing] = useState(false);
	const [modalClientState, setModalClientState] = useState(false);
	const [modalExportExcel, setModalExportExcel] = useState(false);
	const [insuranceSelected, setInsuranceSelected] = useState('');
	const [isDisableSentBtn, setIsDisableSentBtn] = useState(false);

	const fetchClientList = useCallback(async () => {
		setIsLoading(true);
		await fetchPaginatedClients(page, rowsPerPage);
		setIsLoading(false);
	}, [fetchPaginatedClients, page, rowsPerPage, setIsLoading]);

	useEffect(() => {
		if (patients.clients.length > 0) return;
		fetchClientList();
	}, [patients.clients.length]);

	useEffect(() => {
		let syncTimeOut: NodeJS.Timeout;
		let syncUpdateTimeout: NodeJS.Timeout;
		let createSub: any;
		let updateSub: any;
		const subsToClient = async () => {
			const customFilter = await getCustomHospitalDoctorFilters();
			createSub = API.graphql<GraphQLSubscription<OnCreateClientSubscription>>(
				graphqlOperation(onCreateClient, {
					or: customFilter,
				})
			).subscribe({
				next: async (data: any) => {
					if (syncTimeOut) clearTimeout(syncTimeOut);
					syncTimeOut = setTimeout(async () => {
						const currentList = [...(patients?.clients || [])];
						const newClient = data.value.data.onCreateClient;

						const clientIndex = currentList.findIndex((x) => x.id === newClient.id);

						if (clientIndex !== -1) return;

						await fetchPaginatedClients(page, 30);
					}, 7000);
				},
			});

			updateSub = API.graphql<GraphQLSubscription<OnUpdateClientSubscription>>(
				graphqlOperation(onUpdateClient, {
					or: customFilter,
				})
			).subscribe({
				next: async (data: any) => {
					if (syncUpdateTimeout) clearTimeout(syncUpdateTimeout);
					syncUpdateTimeout = setTimeout(async () => {
						const currentList = [...(patients?.clients || [])];
						const updatedClient = data.value.data.onUpdateClient;
						const clientIndex = currentList.findIndex((x) => x.id === updatedClient.id);
						if (clientIndex === -1) return;
						const max = page;
						let iterations = 0;
						while (iterations <= max) {
							await fetchPaginatedClients(iterations, 30);
							iterations++;
						}
					}, 7000);
				},
			});
		};
		subsToClient();
		return () => {
			if (syncTimeOut) clearTimeout(syncTimeOut);
			if (syncUpdateTimeout) clearTimeout(syncUpdateTimeout);
			if (createSub) createSub?.unsubscribe();
			if (updateSub) updateSub?.unsubscribe();
		};
	}, [patients.clients, page, patients?.clients, actualHospitalDoctor?.id]);

	const handleEditClient = async (client: ClientType) => {
		const clientInfo = await getClientDataToEdit(client.id);
		setSelectedClient(clientInfo);
		handleModalClientState(true);
	};

	const handleSendClient = async (client: ClientType) => {
		setIsDisableSentBtn(true);
		await waitingListsContext.addClientToCurrentWaitingList(client);
		setIsDisableSentBtn(false);
	};

	const handleModalClientState = (editing: boolean) => {
		setEditing(editing);
		setModalClientState(true);
	};

	const columLabels: any = {
		name: 'Nombre',
		phoneNumber: 'Teléfono',
		cellphoneNumber: 'Celular',
		identificationData: 'Cédula o NUI',
		fechaIngreso: 'Fecha de Ingreso',
		genderDate: 'Edad y Sexo',
		email: 'Correo',
		noContrato: '',
		affiliateNumber: 'No. de Afiliado',
		addressStreet: 'Direccion',
		sector: 'Sector',
		city: 'Ciudad',
	};

	const cancelHandler = () => {
		setModalExportExcel(false);
		setPropertiesToExport({});
	};

	const handlerPrevious = () => {
		setIsLoading(true);
		const newPage = page - 1;
		if (newPage < 0) return;
		setPage(newPage);
		setIsLoading(false);
	};

	const handlerNext = async () => {
		setIsLoading(true);
		const newPage = page + 1;
		setPage(newPage);
		setIsLoading(false);
	};

	const usersToShow = patients.clients.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
	const enableNext = usersToShow.length === 30 && patients.clients.length > 30;

	const clients = () => {
		if (usersToShow.length > 0) {
			return usersToShow.map((client) => {
				if (!client) return null;
				return (
					<StyledPatientList key={client.id} dense>
						<ClientC
							image={''}
							client={client}
							isDisableSentBtn={isDisableSentBtn}
							onEdit={handleEditClient}
							onSend={handleSendClient}
							fullWidth={fullWidth}
						/>
					</StyledPatientList>
				);
			});
		}

		return (
			<NoItem
				title="No tienes Pacientes en tu lista"
				onClickHandler={() => handleModalClientState(false)}
				isRow={isMobile}
				btnLabel="Crear Paciente"
				containerStyle={{
					alignItems: 'center',
				}}
				height="100dvh"
				iconStyle={{
					width: '114px',
					height: '114px',
				}}
			/>
		);
	};

	return (
		<>
			<ModalClient
				modalState={modalClientState}
				setModalState={setModalClientState}
				isUpdate={editing}
				setIsUpdate={setEditing}
				updatePaginatedClients={fetchClientList}
			/>
			<ModalContainer
				cssClasses={'modalExcel'}
				title="Descargar Excel"
				cancelFunction={cancelHandler}
				setModalState={setModalExportExcel}
				modalState={modalExportExcel}
				customConfirmBtn={
					<ExcelButton
						dataToExport={patients.clients}
						filename={'Pacientes'}
						propertiesToExport={propertiesToExport}
						button={<SaveButton onClick={() => null} label="DESCARGAR" />}
						propertiesLabel={columLabels}
					/>
				}
			>
				<ExportExcelClients
					setPropertiesToExport={setPropertiesToExport}
					insuranceSelected={insuranceSelected}
					setInsuranceSelected={setInsuranceSelected}
				/>
			</ModalContainer>
			<ListContainer
				id="patients-list"
				title="Pacientes"
				filterText={filterText}
				setFilterText={setFilterText}
				isFooter={patients?.clients?.length > 0 && !isLoading}
				contentStyle={{
					justifyContent: `${patients.clients.length > 0 ? 'flex-start' : 'center'}`,
				}}
				className="list"
				footer={
					<CustomPagination
						next={enableNext}
						previous={!!page}
						onClickPrevious={handlerPrevious}
						onClickNext={handlerNext}
					/>
				}
				customStyles={{
					marginBottom: '20px',
					...(isMobile && { height: '100%' }),
				}}
				data-fx-name="patientList"
			>
				{isLoading ? <LoadingSpinner /> : clients()}
			</ListContainer>
		</>
	);
};

export default PatientList;
