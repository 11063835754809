import React, { useContext, useRef } from 'react';
import { Button, Grid } from '@material-ui/core';

import {
	HeaderListItem,
	LabHeaderGrid,
	GrayedBodyContainer,
	WhiteTextField,
	FooterListItem,
} from '../../../../../shared/style';
import PrintIcon from '@material-ui/icons/Print';
import ReactToPrint from 'react-to-print';
import PrescriptionPrintLayout from '../../../../../printLayout/Prescription';
import { ClientsContext } from '../../../../../context/client-context';
import { ClientContextProps } from '../../../../../context/types';
import { LabImages, Medicine } from '../../../../../shared/type';
import moment from 'moment';
interface Props {
	handleAddPrescription: (value: string) => void;
	height?: string;
	[key: string]: any;
	prescription: string;
}

const FreePrescriptionList: React.FC<Props> = ({ handleAddPrescription, prescription = '' }) => {
	const { selectedClient } = useContext(ClientsContext) as ClientContextProps;
	const printRef = useRef(null);
	return (
		<div
			style={{
				border: '1px solid rgba(0, 0, 0, 0.12)',
			}}
		>
			<HeaderListItem>
				<Grid container>
					<LabHeaderGrid item xs={11}>
						Prescripción libre
					</LabHeaderGrid>
				</Grid>
			</HeaderListItem>

			<GrayedBodyContainer container>
				<WhiteTextField
					placeholder="Detalles de prescripción libre"
					variant="outlined"
					minRows={5}
					fullWidth
					multiline
					value={prescription}
					onChange={(e) => handleAddPrescription(e.target.value)}
				/>
			</GrayedBodyContainer>

			<FooterListItem>
				<ReactToPrint
					removeAfterPrint
					trigger={() => (
						<Button color="primary" startIcon={<PrintIcon />}>
							Imprimir
						</Button>
					)}
					content={() => printRef.current}
				/>
				<div ref={printRef} className="printLayout">
					<PrescriptionPrintLayout
						client={selectedClient!}
						prescription={{
							creationDate: moment(new Date()).format('DD/MM/YYYY'),
							medicines: [] as Medicine[],
							analisis: [] as LabImages[],
							freePrescription: prescription,
						}}
					/>
				</div>
			</FooterListItem>
		</div>
	);
};

export default FreePrescriptionList;
