// @ts-nocheck
import React, { useCallback, useState } from 'react';
import { LoaderContextProps, MobileNavigationOptions } from './types';
import Loader from '../components/Loader/Loader';

export const LoaderContext = React.createContext<Partial<LoaderContextProps>>({});

const ContextProvider: React.FC = ({ children }) => {
	const [isLoading, setIsLoading] = useState(false);

	const [currentViewMobile, setCurrentViewMobile] = useState<MobileNavigationOptions | null>(
		'patients'
	);

	const showSpinner = useCallback(
		(loading: boolean) => {
			setIsLoading(loading);
		},
		[setIsLoading]
	);

	return (
		<LoaderContext.Provider
			value={{
				showSpinner,
				currentViewMobile,
				setCurrentViewMobile,
			}}
		>
			{isLoading && <Loader />}
			{children}
		</LoaderContext.Provider>
	);
};

export default ContextProvider;
