import React from 'react';
// import ReactExcel from 'react-export-excel';

// const { ExcelFile, ExcelSheet, ExcelColumn } = ReactExcel;

interface Props {
	dataToExport: any[];
	button: React.ReactNode;
	propertiesToExport: { [key: string]: any };
	propertiesLabel: { [key: string]: string };
	filename: string;
}

const ExcelButton: React.FC<Props> = ({
	dataToExport,
	button,
	propertiesToExport,
	propertiesLabel,
	filename,
}) => {
	return (
		// <ExcelFile element={button} filename={filename}>
		// 	<ExcelSheet data={dataToExport} name={filename}>
		// 		{Object.keys(propertiesToExport).map((colum) => (
		// 			<ExcelColumn key={colum} label={propertiesLabel[colum]} value={colum} />
		// 		))}
		// 	</ExcelSheet>
		// </ExcelFile>
		null
	);
};

export default ExcelButton;
