import React from 'react';

const AddIcon = () => (
	<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M0.674738 10.0257C0.674738 10.8871 1.37534 11.5762 2.22524 11.5762H8.40429V17.7553C8.40429 18.6052 9.09341 19.3058 9.94331 19.3058C10.8047 19.3058 11.4938 18.6052 11.4938 17.7553V11.5762H17.6729C18.5228 11.5762 19.2234 10.8871 19.2234 10.0257C19.2234 9.1758 18.5228 8.48669 17.6729 8.48669H11.4938V2.30764C11.4938 1.45773 10.8047 0.757133 9.94331 0.757133C9.09341 0.757133 8.40429 1.45773 8.40429 2.30764V8.48669H2.22524C1.37534 8.48669 0.674738 9.1758 0.674738 10.0257Z"
			fill="white"
		/>
	</svg>
);

export const AddIconGray = () => (
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M1.57483 10.1808H7.61194V16.218C7.61194 16.9162 8.18127 17.4963 8.89026 17.4963C9.5885 17.4963 10.1578 16.9162 10.1578 16.218V10.1808H16.1949C16.8824 10.1808 17.4625 9.61151 17.4625 8.91327C17.4625 8.21503 16.8824 7.64569 16.1949 7.64569H10.1578V1.60858C10.1578 0.921082 9.5885 0.341003 8.89026 0.341003C8.18127 0.341003 7.61194 0.921082 7.61194 1.60858V7.64569H1.57483C0.887329 7.64569 0.307251 8.21503 0.307251 8.91327C0.307251 9.61151 0.887329 10.1808 1.57483 10.1808Z"
			fill="#80868B"
		/>
	</svg>
);

export const AddIconGreen = () => (
	<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M1.7207 9.69727H6.89648V14.873C6.89648 15.625 7.51172 16.2402 8.26367 16.2402C9.01562 16.2402 9.63086 15.625 9.63086 14.873V9.69727H14.8066C15.5586 9.69727 16.1738 9.0918 16.1738 8.33008C16.1738 7.57812 15.5586 6.97266 14.8066 6.97266H9.63086V1.79688C9.63086 1.04492 9.01562 0.429688 8.26367 0.429688C7.51172 0.429688 6.89648 1.04492 6.89648 1.79688V6.97266H1.7207C0.96875 6.97266 0.353516 7.57812 0.353516 8.33008C0.353516 9.0918 0.96875 9.69727 1.7207 9.69727Z"
			fill="#07B284"
		/>
	</svg>
);

export const AddIconBlue = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
		<path
			d="M15.8332 10.8334H10.8332V15.8334H9.1665V10.8334H4.1665V9.16675H9.1665V4.16675H10.8332V9.16675H15.8332V10.8334Z"
			fill="#1976D2"
		/>
	</svg>
);

export default AddIcon;
