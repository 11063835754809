import React, { FC, useContext, useState } from 'react';
import { Button } from '@material-ui/core';
import PlanCard from '../components/modalRenewSubscription/PlanCard';
import { formatPrice } from '../shared/paymentUtils';
import { StyledCard, ActionContainer, BackButton, RenewHeader } from '../shared/style';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../context/user-context';
import { RelationsContextProps, UserContextProps } from '../context/types';
import { RelationsContext } from 'context/relations-context';
import FullPulsearaLogo from 'components/svg/FullPulsearaLogo';
import { HAS_TRIAL_USER_MESSAGE } from 'shared/constants';
import useTolt from 'hooks/useTolt';

const SelectPlan: FC = () => {
	const history = useHistory();
	const { logOut, plans } = useContext(UserContext) as UserContextProps;
	const { actualDoctor } = useContext(RelationsContext) as RelationsContextProps;
	const [selectedPlan, setSelectedPlan] = useState<number>(
		parseInt(window.localStorage.getItem('plan') as string)
	);
	const { getRefStored } = useTolt('ref');

	const handleSelectPlan = (value: number) => {
		window.localStorage.setItem('plan', value.toString());
		setSelectedPlan(value);
	};

	const handleNextStep = () => {
		const ref = getRefStored();
		let url = `/add-payment-method?plan_id=${selectedPlan}`;
		if (ref) {
			url += `&ref=${ref}`;
		}
		history.push(url);
	};

	return (
		<div className="relative top-0 left-0 w-full h-screen min-wh-login flex flex-col items-center justify-center px-[20px]">
			<FullPulsearaLogo className="w-[188px] md:w-[277px] h-[45px] mb-[40px] md:mb-[74px]" />
			<StyledCard className="bg-white-section rounded-md max-w-[488px] w-full px-[20px] md:px-[40px] pb-[20px] md:pb-[32px]">
				<RenewHeader>
					<h1
						style={{
							fontFamily: 'Roboto',
							fontSize: '24px',
							fontWeight: 'normal',
							fontStyle: 'normal',
							textDecoration: 'rgba(0, 0, 0, 0.87)',
							width: '100%',
						}}
					>
						Disfruta tus 14 días gratis
					</h1>
					<p
						style={{
							fontFamily: 'Roboto',
							fontSize: '17px',
							fontWeight: '500',
							fontStyle: 'normal',
							color: '#00000099',
							letterSpacing: '0.17px',
							marginBottom: '32px',
							width: '100%',
							textAlign: 'left',
						}}
					>
						{actualDoctor?.hasTrial && HAS_TRIAL_USER_MESSAGE}
					</p>
					{plans.map((plan) => {
						return (
							<PlanCard
								key={plan.id}
								selectedPlan={selectedPlan}
								handleSelectPlan={handleSelectPlan}
								value={plan.id}
								title={plan.title}
								subtitle={plan.subtitle}
								price={formatPrice(plan.price.slice(0, -2) as string)}
							/>
						);
					})}
				</RenewHeader>

				<ActionContainer>
					<Button onClick={handleNextStep} variant="contained" color="primary">
						Siguiente 2/3
					</Button>
					<BackButton color="primary" onClick={logOut}>
						agregar luego y desloguearme
					</BackButton>
				</ActionContainer>
			</StyledCard>
		</div>
	);
};

export default SelectPlan;
