import { FC, Fragment, SyntheticEvent, useContext, useEffect, useMemo, useState } from 'react';

import { Box, Checkbox, CircularProgress, FormControlLabel, TextField } from '@material-ui/core';
import { Autocomplete, AutocompleteInputChangeReason } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ClientsContext } from 'context/client-context';
import { ClientType, IClientFilter, UserContextProps } from 'context/types';
import { useClientFilter } from 'hooks/useClientFilter';
import moment from 'moment';
import CustomDialog from '../../dialog/CustomDialog';
import { isValidEmail } from 'shared/utilFunctions';
import MobileDialogHeader from '../../dialog/MobileDialogHeader';
import useMobile from 'hooks/useMobile';
import { UserContext } from 'context/user-context';

interface IAppointmentModal {
	initialValues: {
		id?: string;
		_version?: number;
		client: IClientFilter | null;
		description: string;
		date: moment.Moment | null;
		start: string | null;
		end: string | null;
	};

	modalState: boolean;
	closeModal: () => void;
	isUpdating: boolean;
	disabledUserInput?: boolean;

	selectedClient?: ClientType;
}

export const ModalAppointment: FC<IAppointmentModal> = ({
	modalState,
	closeModal,
	isUpdating,
	initialValues,
	disabledUserInput,
	selectedClient,
}) => {
	const { isMobile } = useMobile();
	const [appointment, setAppointment] = useState({
		...initialValues,
		shouldSendReminder: true,
		email: initialValues.client?.email || '',
	});
	const textFieldSize = 'small';

	useEffect(() => {
		setAppointment({
			...initialValues,
			shouldSendReminder: true,
			email: initialValues.client?.email || '',
		});
	}, [initialValues]);

	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
	const [confirmationModalTitle, setConfirmationModalTitle] = useState('');
	const [open, setOpen] = useState(false);
	const [errors, setErrors] = useState<Record<string, string>>({});
	const { createClientAppointment, updateClientAppointment, deleteClientAppointment } =
		useContext(ClientsContext);
	const { doctorMemberSelected } = useContext(UserContext) as UserContextProps;

	const { clientOptions, filterText, loadingClientOptions, setFilterText } = useClientFilter();

	const handleSearchPatient = (
		_e: SyntheticEvent<Element, Event>,
		value: string,
		_reason: AutocompleteInputChangeReason
	) => {
		setFilterText(value);
	};

	const validateForm = () => {
		const newErrors: Record<string, string> = {};

		const client = appointment.client || selectedClient;

		if (!client) {
			newErrors.client = 'El paciente es requerido';
		}

		if (!appointment.date) {
			newErrors.date = 'La fecha es requerida';
		}

		if (!appointment.start) {
			newErrors.start = 'La hora de inicio es requerida';
		}
		if (!appointment.end) {
			newErrors.end = 'La hora de fin es requerida';
		}

		const startTime = moment(appointment.start, 'hh:mm A');
		const endTime = moment(appointment.end, 'hh:mm A');

		if (appointment.start && appointment.end && !endTime.isAfter(startTime)) {
			newErrors.end = 'La hora de fin debe de ser después de la hora de inicio';
		}

		if (appointment.shouldSendReminder && !appointment.email.trim()) {
			newErrors.email = 'El correo es requerido';
		} else if (appointment.shouldSendReminder && !isValidEmail(appointment.email.trim())) {
			newErrors.email = 'El correo no es válido';
		}

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleClose = () => {
		setErrors({});
		closeModal();
	};

	const submit = async () => {
		if (!validateForm()) return;

		const client = appointment.client || selectedClient;

		await createClientAppointment!(
			{
				clientID: client!.id,
				description: appointment.description,
				date: appointment.date!.format('YYYY-MM-DD'),
				start: appointment.start!,
				end: appointment.end!,
				shouldSendReminder: appointment.shouldSendReminder,
				email: appointment.email,
				clientVersion: client?._version!,
			},
			doctorMemberSelected?.doctorID
		);
		handleClose();
	};

	const handleDelete = async () => {
		await deleteClientAppointment!({
			id: initialValues.id!,
			_version: initialValues._version!,
		});
		handleClose();
		setOpenConfirmationModal(false);
	};

	const handleUpdate = async () => {
		if (!validateForm()) return;

		const client = appointment.client || selectedClient;

		await updateClientAppointment!({
			id: initialValues.id!,
			clientID: client!.id,
			clientVersion: client!._version!,
			description: appointment.description,
			date: appointment.date!.format('YYYY-MM-DD'),
			start: appointment.start!,
			end: appointment.end!,
			shouldSendReminder: appointment.shouldSendReminder,
			email: appointment.email,
			_version: initialValues._version!,
		});

		handleClose();
		setOpenConfirmationModal(false);
	};

	const timeOptions = useMemo(() => {
		const options = [];
		const startTime = moment(appointment.date).startOf('day');
		const endTime = moment(appointment.date).endOf('day');

		while (startTime <= endTime) {
			const option = startTime.format('hh:mm A');
			options.push(option);
			startTime.add(30, 'minutes');
		}

		return options;
	}, [appointment.date]);

	const handleShowDeleteConfirmation = () => {
		setConfirmationModalTitle('¿Estás seguro que quieres eliminar la siguiente cita?');
		setOpenConfirmationModal(true);
	};

	const handleShowUpdateConfirmation = () => {
		setConfirmationModalTitle('¿Estás seguro que quieres actualizar la siguiente cita?');
		setOpenConfirmationModal(true);
	};

	const isConfirmationDeleting = confirmationModalTitle.includes('eliminar');

	const modalTitle = isUpdating ? 'Actualizar Cita' : 'Nueva Cita';

	return (
		<>
			<CustomDialog
				className={'appointment-modal'}
				disableBackdropClick
				open={modalState!}
				handleClose={handleClose}
				maxWidth={'sm'}
				title={
					isMobile ? (
						<MobileDialogHeader title={modalTitle} goBack={handleClose} />
					) : (
						modalTitle
					)
				}
				fullWidth
				fullScreen={isMobile}
				agreeText="Guardar"
				disagreeText="Cancelar"
				handleAgree={isUpdating ? handleShowUpdateConfirmation : submit}
				deleteText={isUpdating ? (isMobile ? 'Eliminar' : 'Eliminar Cita') : ''}
				handleDelete={handleShowDeleteConfirmation}
			>
				<Box
					display={'flex'}
					flexDirection={'column'}
					gridRowGap={'20px'}
					style={{
						...(isMobile ? { marginTop: '20px' } : {}),
					}}
				>
					{!selectedClient ? (
						<div>
							<Autocomplete
								fullWidth
								id="paciente"
								open={open}
								disabled={disabledUserInput}
								filterOptions={(x) => x}
								onOpen={() => {
									setOpen(true);
								}}
								onClose={() => {
									setOpen(false);
								}}
								inputValue={filterText}
								onInputChange={handleSearchPatient}
								isOptionEqualToValue={(option, value) => option.id === value?.id}
								getOptionLabel={(option) => option.name}
								options={clientOptions}
								value={appointment.client}
								onChange={(_e, value, _reason, _details) => {
									setAppointment((prev) => ({
										...prev,
										client: value,
										email: value?.email || '',
									}));
								}}
								loading={loadingClientOptions}
								renderInput={(params) => (
									<TextField
										{...params}
										fullWidth
										size={textFieldSize}
										label="Buscar Paciente"
										variant="outlined"
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<Fragment>
													{loadingClientOptions ? (
														<CircularProgress
															color="inherit"
															size={20}
														/>
													) : null}
													{params.InputProps.endAdornment}
												</Fragment>
											),
										}}
									/>
								)}
							/>
							{errors.client && <span style={{ color: 'red' }}>{errors.client}</span>}
						</div>
					) : (
						<TextField
							fullWidth
							label="Paciente"
							variant="outlined"
							size={textFieldSize}
							value={`${selectedClient?.name} ${selectedClient?.lastName}`}
							disabled
						/>
					)}
					<TextField
						label="Razón de la cita"
						fullWidth
						variant="outlined"
						size={textFieldSize}
						value={appointment.description}
						onChange={(e) => {
							setAppointment((prev) => ({
								...prev,
								description: e.target.value,
							}));
						}}
					/>
					<div>
						<DatePicker
							label="Dia de la cita"
							sx={{ width: '100%' }}
							value={appointment.date}
							views={['month', 'year', 'day']}
							onChange={(value) => {
								setAppointment((prev) => ({
									...prev,
									date: value,
								}));
							}}
							slotProps={{
								textField: {
									size: textFieldSize,
								},
							}}
						/>
						{errors.date && <span style={{ color: 'red' }}>{errors.date}</span>}
					</div>
					<Box
						display={'flex'}
						flexDirection={isMobile ? 'column' : 'row'}
						gridGap={isMobile ? '24px' : '15px'}
					>
						<div style={{ width: '100%' }}>
							<Autocomplete
								fullWidth
								id="from-time-select"
								options={timeOptions}
								value={appointment.start}
								onChange={(e, option) => {
									setAppointment((prev) => ({
										...prev,
										start: option,
									}));
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										size={textFieldSize}
										fullWidth
										label="Desde las"
										variant="outlined"
									/>
								)}
							/>
							{errors.start && (
								<span style={{ color: 'red', display: 'block' }}>
									{errors.start}
								</span>
							)}
						</div>
						<div style={{ width: '100%' }}>
							<Autocomplete
								fullWidth
								id="from-time-select"
								options={timeOptions}
								value={appointment.end}
								onChange={(e, option) => {
									setAppointment((prev) => ({
										...prev,
										end: option,
									}));
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										size={textFieldSize}
										fullWidth
										label="Hasta las"
										variant="outlined"
									/>
								)}
							/>
							{errors.end && (
								<span style={{ color: 'red', display: 'block' }}>{errors.end}</span>
							)}
						</div>
					</Box>

					<FormControlLabel
						checked={appointment.shouldSendReminder}
						control={<Checkbox color="primary" />}
						label="Enviar recordatorio por correo"
						style={{ maxWidth: 'max-content' }}
						onChange={(e) =>
							setAppointment((prev) => ({
								...prev,
								shouldSendReminder: (e.target as any).checked,
							}))
						}
					/>

					{appointment.shouldSendReminder && (
						<div>
							<TextField
								label="Correo del paciente"
								size={textFieldSize}
								fullWidth
								variant="outlined"
								value={appointment.email}
								onChange={(e) => {
									setAppointment((prev) => ({
										...prev,
										email: e.target.value,
									}));
								}}
							/>
							{errors.email && <span style={{ color: 'red' }}>{errors.email}</span>}
						</div>
					)}
				</Box>
			</CustomDialog>

			<CustomDialog
				maxWidth={'xs'}
				open={openConfirmationModal}
				handleClose={() => setOpenConfirmationModal(false)}
				title={confirmationModalTitle}
				agreeText="Confirmar"
				disagreeText="Cancelar"
				handleAgree={isConfirmationDeleting ? handleDelete : handleUpdate}
			>
				{initialValues.description}, {initialValues.date?.format('dddd, D [de] MMMM')} ⋅{' '}
				{initialValues.start} - {initialValues.end}
			</CustomDialog>
		</>
	);
};
