import { Avatar } from '@material-ui/core';
import React from 'react';
import { EditIcon, TranshIcon as DeleteIcon } from '../../svg';
import useMobile from 'hooks/useMobile';

interface Props {
	name: string;
	deleteFunction: () => void;
	editFunction: () => void;
}

const SecretaryBox: React.FC<Props> = ({ deleteFunction, editFunction, name }) => {
	const containerStyles = {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		gap: '8px',
		padding: '8px 0px',
		background: 'rgba(255, 255, 255, 0.00)',
		boxShadow: '0px -1px 0px 0px rgba(0, 0, 0, 0.12) inset',
	};

	const spanStyles = {
		fontStyle: 'normal',
		fontWeight: '500',
		fontSize: '16px',
		lineHeight: '24px',
		letterSpacing: '0.15px',
		fontFeatureSettings: 'clig off, liga off',
	};

	return (
		<div style={containerStyles}>
			<span style={spanStyles} className="capitalize">
				{name}
			</span>

			<div
				style={{
					marginLeft: 'auto',
					display: 'flex',
				}}
			>
				<button
					id="edit-card"
					onClick={editFunction}
					style={{
						marginRight: '1.5rem',
						cursor: 'pointer',
					}}
				>
					<EditIcon />
				</button>
				<button
					onClick={deleteFunction}
					id="delete-card"
					style={{
						cursor: 'pointer',
					}}
				>
					<DeleteIcon />
				</button>
			</div>
		</div>
	);
};

export default SecretaryBox;
