import React, { useContext } from 'react';
import { RouteProps, useHistory } from 'react-router-dom';
import NeedPayment from '../components/payment/NeedPayment';
import { UserContext } from '../context/user-context';
import { RelationsContext } from '../context/relations-context';
import { createSubscription } from '../adapters/stripe';
import { LoaderContext } from '../context/loader-context';
import { LoaderContextProps, RelationsContextProps, UserContextProps } from '../context/types';
import { Button } from '@material-ui/core';
import {
	ActionContainer,
	BackButton,
	ParagraphTypography,
	StyledAddPaymentCard,
	TitleTypography,
} from '../shared/style';
import useQuery from '../shared/hooks/useQuery';
import { formatPrice } from '../shared/paymentUtils';
import { getTrialEndDate } from 'shared/dateUtils';
import FullPulsearaLogo from 'components/svg/FullPulsearaLogo';
import { HAS_TRIAL_USER_MESSAGE, StripePaymentStatus } from 'shared/constants';
import { SubscriptionData } from 'shared/type';
import SecureModule from 'components/payment/SecureModule';
import useTolt from 'hooks/useTolt';
import useMobile from 'hooks/useMobile';

const AddPayment: React.FC<RouteProps> = () => {
	const { checkIsLogged, plans, logOut } = useContext(UserContext) as UserContextProps;
	const { actualDoctor, updateSubscription, setAlertMessage } = useContext(
		RelationsContext
	) as RelationsContextProps;
	const { showSpinner } = useContext(LoaderContext) as LoaderContextProps;
	const query = useQuery();
	const planId = parseInt(query.get('plan_id') as string);
	const { isMobile } = useMobile();
	const history = useHistory();
	const { refId, resetRef } = useTolt('ref', false);

	const keyPlan = plans.find((p) => p.id === planId)!.stripePlanKey;

	const saveHandler = async () => {
		showSpinner(true);
		try {
			const trialEndDate = getTrialEndDate();
			const subscriptionData: SubscriptionData = {
				customer: actualDoctor && actualDoctor.stripeCustomerID,
				items: [{ price: keyPlan }],
				default_payment_method:
					actualDoctor &&
					actualDoctor.stripeCards.find((s) => s.id === actualDoctor!.defaultCard)!
						.stripePaymentMethodID,
			};

			if (actualDoctor?.hasTrial) {
				subscriptionData.trial_end = trialEndDate.unix();
				subscriptionData.billing_cycle_anchor = trialEndDate.unix();
			}

			if (refId) {
				// @ts-ignore
				subscriptionData.metadata = {
					tolt_referral: refId,
				};
			}

			resetRef();

			const subscription = await createSubscription(subscriptionData);

			if (
				subscription.status !== StripePaymentStatus.Active &&
				subscription.status !== StripePaymentStatus.Trialing
			) {
				setAlertMessage({
					severity: 'error',
					message: 'Ocurrió un error al procesar el pago. Por favor, intenta de nuevo.',
				});

				showSpinner(false);
				return;
			}

			await updateSubscription(
				subscription.id,
				planId,
				false,
				subscription.current_period_end,
				trialEndDate.toString()
			);
			await checkIsLogged();
		} catch (e) {
			console.log(e);
		} finally {
			showSpinner(false);
		}
	};

	const saveWithOutPaymentHandler = async () => {
		showSpinner(true);
		try {
			const trialEndDate = getTrialEndDate();
			const subscriptionData: SubscriptionData = {
				customer: actualDoctor && actualDoctor.stripeCustomerID,
				items: [{ price: keyPlan }],
				trial_end: trialEndDate.unix(),
				billing_cycle_anchor: trialEndDate.unix(),
			};

			// @ts-ignore
			if (refId) {
				// @ts-ignore
				subscriptionData.metadata = {
					tolt_referral: refId,
				};
			}

			resetRef();

			const subscription = await createSubscription(subscriptionData);

			if (
				subscription.status !== StripePaymentStatus.Active &&
				subscription.status !== StripePaymentStatus.Trialing
			) {
				setAlertMessage({
					severity: 'error',
					message: 'Ocurrió un error al procesar el pago. Por favor, intenta de nuevo.',
				});

				showSpinner(false);
				return;
			}

			await updateSubscription(
				subscription.id,
				planId,
				false,
				subscription.current_period_end,
				trialEndDate.toString()
			);

			if (isMobile) {
				await logOut();
				history.push('/login');
				return;
			}

			await checkIsLogged();
		} catch (e) {
			return;
		} finally {
			showSpinner(false);
		}
	};

	const decoratorFormatted = () => {
		if (actualDoctor?.hasTrial) {
			return HAS_TRIAL_USER_MESSAGE;
		}

		return `Total a pagar: RD${' '}
    ${
		formatPrice(plans.find((p) => p.id === planId)?.price.slice(0, -2) as string) ||
		formatPrice('800')
	}`;
	};

	const completeBtnLabel = () => {
		if (actualDoctor?.hasTrial) {
			return `Empezar prueba gratis 3/3`;
		}

		return `Pagar ahora 3/3`;
	};

	const title = () => {
		return `Agrega método de pago (Opcional)`;
	};

	return (
		<div className="relative top-0 left-0 w-full h-screen min-wh-login flex flex-col items-center justify-center px-[20px]">
			<FullPulsearaLogo className="w-[188px] md:w-[277px] h-[45px] mb-[40px] md:mb-[74px]" />
			<StyledAddPaymentCard className="bg-white-section rounded-md max-w-[488px] w-full">
				<div className="flex justify-center items-center">
					<NeedPayment customCss="mt-5" planId={planId}>
						<TitleTypography
							variant="h5"
							className="text-[24px] font-normal decoration-[rgba(0, 0, 0, 0.87)] font-[Roboto]"
							style={{
								fontWeight: 'normal',
							}}
						>
							{title()}
						</TitleTypography>
						<ParagraphTypography
							style={{
								fontFamily: 'Roboto',
								fontSize: '17px',
								fontWeight: '500',
								fontStyle: 'normal',
								color: '#00000099',
								letterSpacing: '0.17px',
								width: '100%',
								textAlign: 'left',
							}}
						>
							{decoratorFormatted()}
						</ParagraphTypography>
					</NeedPayment>
				</div>

				<ActionContainer>
					<Button
						disabled={
							(actualDoctor &&
								actualDoctor.stripeCards.length === 0 &&
								actualDoctor.defaultCard === '') as boolean
						}
						onClick={saveHandler}
						variant="contained"
						color="primary"
					>
						{completeBtnLabel()}
					</Button>
					<BackButton
						color="primary"
						onClick={saveWithOutPaymentHandler}
						style={{
							color: '#1976D2',
						}}
					>
						saltar paso
					</BackButton>
				</ActionContainer>
			</StyledAddPaymentCard>
			<SecureModule />
		</div>
	);
};

export default AddPayment;
