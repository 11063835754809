import { useState } from 'react';

export const paginate = (array: any, page_size: number, page_number: number) => {
	return array && array.slice((page_number - 1) * page_size, page_number * page_size);
};

export const usePagination = (data: any[], itemsPerPage: number) => {
	const [currentPage, setCurrentPage] = useState(1);
	const maxPage = data && Math.ceil(data.length / itemsPerPage);

	function currentData() {
		const begin = (currentPage - 1) * itemsPerPage;
		const end = begin + itemsPerPage;
		return data && data.slice(begin, end);
	}

	function next() {
		setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage));
	}

	function prev() {
		setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
	}

	function jump(page: number) {
		const pageNumber = Math.max(1, page);
		setCurrentPage(() => Math.min(pageNumber, maxPage));
	}

	return { next, prev, jump, currentData, currentPage, maxPage };
};
