import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import React from 'react';
import { Fab } from '@material-ui/core';

type CustomPaginationProps = {
	next: boolean;
	previous: boolean;
	onClickNext: () => void;
	onClickPrevious: () => void;
};

const CustomPagination = (props: CustomPaginationProps) => {
	const { next, previous, onClickNext, onClickPrevious } = props;
	if (!next && !previous) return null;
	return (
		<div className="flex gap-5">
			<Fab
				onClick={onClickPrevious}
				disabled={!previous}
				className={`flex justify-center items-center border-[#0000003B] 
                    ${!next ? 'cursor-not-allowed' : 'cursor-pointer'}
                `}
				style={{
					width: '32px',
					height: '32px',
					boxShadow: 'none',
				}}
			>
				<ChevronLeftIcon />
			</Fab>
			<Fab
				onClick={onClickNext}
				disabled={!next}
				className={`flex justify-center items-center border-[#0000003B] 
                    ${!next ? 'cursor-not-allowed' : 'cursor-pointer'}
                `}
				style={{
					width: '32px',
					height: '32px',
					boxShadow: 'none',
				}}
			>
				<ChevronRightIcon
					style={{
						width: '32px',
					}}
				/>
			</Fab>
		</div>
	);
};

export default CustomPagination;
