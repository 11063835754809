import { Grid, SelectProps } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment, { Moment } from 'moment';
import React, { FormEvent, useContext, useEffect, useState } from 'react';
import {
	AllergyStatus,
	ClientAllergy,
	ClientDisease,
	ClientFamilyHistory,
	ClientHospitalization,
	ClientLifeStyle,
	ClientProcedure,
	HistoryFamilyMember,
	LifeStyle,
} from 'models';
import { ClientsContext } from '../../../context/client-context';
import { HistoryContext, HistoryContextProps } from '../../../context/history-context';
import { RelationsContext } from '../../../context/relations-context';
import {
	ClientContextProps,
	HistorySidebarOptions,
	RelationsContextProps,
} from '../../../context/types';
import {
	HistoryBodyContainer,
	HistoryListBodyContainer,
	HistoryContainer,
	HistoryListContainer,
	SummaryContainer,
} from '../../../shared/style';
import {
	removeBulletAddComma,
	replaceCommanForBullet,
	returBulletOnEnter,
} from '../../../shared/utilFunctions';
import AllergyCard from './components/AllergyCard';
import AllergyList from './components/AllergyList';
import AllergyFormDialog from './components/AllergyFormDialog';
import DiseaseFormDialog from './components/DiseaseFormDialog';
import DiseaseList from './components/DiseaseList';
import FamilyHistoryCard from './components/FamilyHistoryCard';
import FamilyHistoryFormDialog from './components/FamilyHistoryFormDialog';
import FamilyHistoryList from './components/FamilyHistoryList';
import HistorySidebar from './components/HistorySidebar';
import HospitalizationFormDialog from './components/HospitalizationFormDialog';
import HospitalizationList from './components/HospitalizationList';
import NonPathologicalHistoryCard from './components/NonPathologicalHistoryCard';
import NutritionList from './components/NutritionList';
import PathologicalHistoryCard from './components/PathologicalHistoryCard';
import LifeStyleList from './components/LifeStyleList';
import ProcedureFormDialog from './components/ProcedureFormDialog';
import ProcedureList from './components/ProcedureList';
import VaccineInmunizationList from './components/VaccineInmunizationList';
import { DROPDOWN_MOBILE_MENU, drugs } from '../../../shared/constants';
import { getFamilyMemberFromLabel } from './util';
import { getDateAndTime } from '../../../shared/dateUtils';
import useMobile from 'hooks/useMobile';
import HistoryDropdown from '../HistoryDropdown';
import CustomGroupTitle from '../HistoryDropdown/GroupTitle';
import CustomMenuItem from '../HistoryDropdown/MenuItem';

const AddButtonIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
		<path
			d="M14.25 9.75H9.75V14.25H8.25V9.75H3.75V8.25H8.25V3.75H9.75V8.25H14.25V9.75Z"
			fill="black"
			fill-opacity="0.87"
		/>
	</svg>
);

const PatientHistory = () => {
	const {
		selectedClient,
		updateClientWithOutChanges,
		getClientHistoryData,
		loadingClientHistory,
		clientHistory: clientHistoryData,
		setClientHistory,
	} = useContext(ClientsContext) as ClientContextProps;
	const { setAlertMessage } = useContext(RelationsContext) as RelationsContextProps;
	const {
		currentSidebarTab,
		createPatientDisease,
		updatePatientDisease,
		createPatientAllergy,
		updatePatientAllergy,
		createPatientProcedure,
		updatePatientProcedure,
		createPatientHospitalization,
		updatePatientHospitalization,
		createPatientFamilyHistory,
		updatePatientFamilyHistory,
		createPatientLifeStyle,
		updatePatientLifeStyle,
		deletePatientDisease,
		setCurrentSidebarTab,
	} = useContext(HistoryContext) as HistoryContextProps;
	// Disease states
	const [isDiseaseFormOpen, setIsDiseaseFormOpen] = useState<boolean>(false);
	const [isDiseaseDeleteOpen, setIsDiseaseDeleteOpen] = useState<boolean>(false);
	const [diseaseName, setDiseaseName] = useState<string>('');
	const [isEmptyDiseaseName, setIsEmptyDiseaseName] = useState<boolean>(false);
	const [diseaseStatus, setDiseaseStatus] = useState<AllergyStatus>(AllergyStatus.ACTIVE);
	const [diseaseDate, setDiseaseDate] = useState<string | null | undefined | Moment>(moment());
	const [diseaseNote, setDiseaseNote] = useState<string>('');
	const [diseaseId, setDiseaseId] = useState<string>('');
	// Allergy states
	const [isAllergyFormOpen, setIsAllergyFormOpen] = useState<boolean>(false);
	const [isAllergyDeleteOpen, setIsAllergyDeleteOpen] = useState<boolean>(false);
	const [allergyName, setAllergyName] = useState<string>('');
	const [isEmptyAllergyName, setIsEmptyAllergyName] = useState<boolean>(false);
	const [allergyStatus, setAllergyStatus] = useState<AllergyStatus>(AllergyStatus.ACTIVE);
	const [allergyDate, setAllergyDate] = useState<string | null | undefined | Moment>(moment());
	const [allergyNote, setAllergyNote] = useState<string>('');
	const [allergyId, setAllergyId] = useState<string>('');
	// Procedure states
	const [isProcedureFormOpen, setIsProcedureFormOpen] = useState<boolean>(false);
	const [isProcedureDeleteOpen, setIsProcedureDeleteOpen] = useState<boolean>(false);
	const [procedureName, setProcedureName] = useState<string>('');
	const [isEmptyProcedureName, setIsEmptyProcedureName] = useState<boolean>(false);
	const [procedureDate, setProcedureDate] = useState<string | null | undefined | Moment>(
		moment()
	);
	const [procedureNote, setProcedureNote] = useState<string>('');
	const [procedureId, setProcedureId] = useState<string>('');
	// Hospitalization states
	const [isHospitalizationFormOpen, setIsHospitalizationFormOpen] = useState<boolean>(false);
	const [isHospitalizationDeleteOpen, setIsHospitalizationDeleteOpen] = useState<boolean>(false);
	const [hospitalizationName, setHospitalizationName] = useState<string>('');
	const [isEmptyHospitalizationName, setIsEmptyHospitalizationName] = useState<boolean>(false);
	const [hospitalizationDate, setHospitalizationDate] = useState<
		string | null | undefined | Moment
	>(moment());
	const [hospitalizationNote, setHospitalizationNote] = useState<string>('');
	const [hospitalizationId, setHospitalizationId] = useState<string>('');
	// Allergy states
	const [isFamilyHistoryFormOpen, setIsFamilyHistoryFormOpen] = useState<boolean>(false);
	const [familyHistoryId, setFamilyHistoryId] = useState<string>('');
	const [familyMember, setFamilyMember] = useState<HistoryFamilyMember | null>(null);
	const [mellitusDiabetes, setMellitusDiabetes] = useState<boolean>(false);
	const [mellitusDiabetesNote, setMellitusDiabetesNote] = useState<string>('');
	const [arterialHypertension, setArterialHypertension] = useState<boolean>(false);
	const [arterialHypertensionNote, setArterialHypertensionNote] = useState<string>('');
	const [heartDisease, setHeartDisease] = useState<boolean>(false);
	const [heartDiseaseNote, setHeartDiseaseNote] = useState<string>('');
	const [nephropathy, setNephropathy] = useState<boolean>(false);
	const [nephropathyNote, setNephropathyNote] = useState<string>('');
	const [neuropathies, setNeuropathies] = useState<boolean>(false);
	const [neuropathiesNote, setNeuropathiesNote] = useState<string>('');
	const [epilepsy, setEpilepsy] = useState<boolean>(false);
	const [epilepsyNote, setEpilepsyNote] = useState<string>('');
	const [rheumatoidArthritis, setRheumatoidArthritis] = useState<boolean>(false);
	const [rheumatoidArthritisNote, setRheumatoidArthritisNote] = useState<string>('');
	const [psychiatricDiseases, setPsychiatricDiseases] = useState<boolean>(false);
	const [psychiatricDiseasesNote, setPsychiatricDiseasesNote] = useState<string>('');
	const [cancer, setCancer] = useState<boolean>(false);
	const [cancerNote, setCancerNote] = useState<string>('');
	const [other, setOther] = useState<boolean>(false);
	const [otherNote, setOtherNote] = useState<string>('');
	// LifeStyles states
	const [alcoholId, setAlcoholId] = useState<string>('');
	const [alcoholUpdatedAt, setAlcoholUpdatedAt] = useState<string>('');
	const [lifeStyleAlcohol, setLifeStyleAlcohol] = useState<string>('');
	const [lifeStyleAlcoholNote, setLifeStyleAlcoholNote] = useState<string>('');
	const [tabacoId, setTabacoId] = useState<string>('');
	const [tabacoUpdatedAt, setTabacoUpdatedAt] = useState<string>('');
	const [lifeStyleTabaco, setLifeStyleTabaco] = useState<string>('');
	const [lifeStyleTabacoNote, setLifeStyleTabacoNote] = useState<string>('');
	const [activityId, setActivityId] = useState<string>('');
	const [activityUpdatedAt, setActivityUpdatedAt] = useState<string>('');
	const [lifeStyleActivity, setLifeStyleActivity] = useState<string>('');
	const [lifeStyleActivityNote, setLifeStyleActivityNote] = useState<string>('');
	const [drugId, setDrugId] = useState<string>('');
	const [drugUpdatedAt, setDrugUpdatedAt] = useState<string>('');
	const [lifeStyleDrug, setLifeStyleDrug] = useState<string[]>([]);
	const [lifeStyleDrugNote, setLifeStyleDrugNote] = useState<string>('');
	const { isMobile } = useMobile();
	useEffect(() => {
		const getClientHistory = async () => {
			await getClientHistoryData();
		};

		if (!clientHistoryData) {
			getClientHistory();
		}
	}, []);

	const onCloseAll = () => {
		clearAllergyForm();
		clearDiseaseForm();
		clearProcedureForm();
		clearHospitalizationForm();
		clearFamilyHistoryForm();
		setIsAllergyFormOpen(false);
		setIsDiseaseFormOpen(false);
		setIsProcedureFormOpen(false);
		setIsHospitalizationFormOpen(false);
		setIsFamilyHistoryFormOpen(false);
	};

	// Disease Funtionalities
	const clearDiseaseForm = () => {
		setIsDiseaseFormOpen(false);
		setDiseaseName('');
		setDiseaseStatus(AllergyStatus.ACTIVE);
		setDiseaseDate(moment());
		setDiseaseNote('');
		setDiseaseId('');
		setIsDiseaseDeleteOpen(false);
		setIsEmptyDiseaseName(false);
	};
	const handleDeleteDisease = () => {
		setIsDiseaseFormOpen(false);
		setIsDiseaseDeleteOpen(true);
	};

	const deletePatientDiseaseHandler = async (id: string) => {
		await deletePatientDisease(id);
		setClientHistory((prev: any) => {
			return {
				...prev,
				diseases: prev?.diseases?.filter((d: any) => d.id !== id),
			};
		});
		clearDiseaseForm();
		setAlertMessage({
			message: 'Enfermedad eliminada',
			severity: 'success',
		});
	};
	const handleSubmitDisease = async () => {
		if (diseaseName === '') {
			setIsEmptyDiseaseName(true);
			return;
		}
		if (diseaseId !== '') {
			const updatedDisease = await updatePatientDisease({
				id: diseaseId,
				clientID: selectedClient?.id as string,
				name: diseaseName,
				status: diseaseStatus,
				note: diseaseNote,
				diagnosedDate: diseaseDate as string,
			});
			clearDiseaseForm();
			if (updatedDisease) {
				setClientHistory((prev: any) => {
					return {
						...prev,
						diseases: prev?.diseases?.map((d: any) =>
							d.id === diseaseId ? updatedDisease : d
						),
					};
				});
				setAlertMessage({
					message: 'Enfermedad editada',
					severity: 'success',
				});
			}
		} else {
			const createdDisease = await createPatientDisease({
				clientID: selectedClient?.id as string,
				name: diseaseName,
				status: diseaseStatus,
				note: diseaseNote,
				diagnosedDate: moment(diseaseDate).format('YYYY-MM-DD'),
			} as ClientDisease);
			clearDiseaseForm();
			if (createdDisease) {
				setClientHistory((prev: any) => {
					return {
						...prev,
						diseases: [createdDisease, ...(prev?.diseases as ClientDisease[])],
					};
				});
				setAlertMessage({
					message: 'Enfermedad agregada',
					severity: 'success',
				});
			}
		}

		await updateClientWithOutChanges(selectedClient?.id as string);
	};
	const handleEditDisease = (id: string) => {
		setDiseaseId(id);
		const diseaseSelected = clientHistoryData?.diseases?.find((x: any) => x.id === id);
		setDiseaseName(diseaseSelected?.name as string);
		setDiseaseDate(diseaseSelected?.diagnosedDate);
		setDiseaseStatus(diseaseSelected?.status as AllergyStatus);
		setDiseaseNote(diseaseSelected?.note as string);
		setIsDiseaseFormOpen(true);
	};
	const handleDiseaseNote = (e: React.ChangeEvent<HTMLInputElement>) => {
		setDiseaseNote(e.target.value);
	};
	const handleDiseaseDate = (date: MaterialUiPickersDate) => {
		const diseaseDateFormat = moment(date).format('YYYY-MM-DD');
		setDiseaseDate(diseaseDateFormat);
	};
	const handleChangeDiseaseName = (e: React.ChangeEvent<HTMLInputElement>) => {
		setDiseaseName(e.target.value);
		if (e.target.value.length > 0) {
			setIsEmptyDiseaseName(false);
		} else {
			setIsEmptyDiseaseName(true);
		}
	};
	const handleChangeDiseaseStatus = (
		e: React.ChangeEvent<{
			name?: string | undefined;
			value: unknown;
		}>
	) => {
		setDiseaseStatus(e.target.value as AllergyStatus);
	};
	const handleCloseDiseaseForm = () => {
		clearDiseaseForm();
	};
	const handleOpenDiseaseForm = () => {
		setIsDiseaseFormOpen(true);
	};

	// Disease Funtionalities
	const clearAllergyForm = () => {
		setIsAllergyFormOpen(false);
		setAllergyName('');
		setAllergyStatus(AllergyStatus.ACTIVE);
		setAllergyDate(moment());
		setAllergyNote('');
		setAllergyId('');
		setIsAllergyDeleteOpen(false);
		setIsEmptyAllergyName(false);
	};
	const handleDeleteAllergy = () => {
		setIsAllergyFormOpen(false);
		setIsAllergyDeleteOpen(true);
	};
	const handleSubmitAllergy = async () => {
		if (allergyName === '') {
			setIsEmptyAllergyName(true);
			return;
		}

		if (allergyId !== '') {
			const updatedAllergy = await updatePatientAllergy({
				id: allergyId,
				clientID: selectedClient?.id as string,
				name: allergyName,
				status: allergyStatus,
				note: allergyNote,
				diagnosedDate: allergyDate as string,
			});
			clearAllergyForm();
			if (updatedAllergy) {
				setClientHistory((prev: any) => {
					return {
						...prev,
						allergies: prev?.allergies?.map((d: any) =>
							d.id === allergyId ? updatedAllergy : d
						),
					};
				});
				setAlertMessage({
					message: 'Alergía editada',
					severity: 'success',
				});
			}
		} else {
			const createddAllergy = await createPatientAllergy({
				clientID: selectedClient?.id as string,
				name: allergyName,
				status: allergyStatus,
				note: allergyNote,
				diagnosedDate: moment(allergyDate).format('YYYY-MM-DD'),
			} as ClientAllergy);
			clearAllergyForm();
			if (createddAllergy) {
				setClientHistory((prev: any) => {
					return {
						...prev,
						allergies: [createddAllergy, ...(prev?.allergies as ClientAllergy[])],
					};
				});
				setAlertMessage({
					message: 'Alergía agregada',
					severity: 'success',
				});
			}
		}

		await updateClientWithOutChanges(selectedClient?.id as string);
	};
	const handleEditAllergy = (id: string) => {
		setAllergyId(id);
		const allergySelected = clientHistoryData?.allergies?.find((x: any) => x.id === id);
		setAllergyName(allergySelected?.name as string);
		setAllergyDate(allergySelected?.diagnosedDate);
		setAllergyStatus(allergySelected?.status as AllergyStatus);
		setAllergyNote(allergySelected?.note as string);
		setIsAllergyFormOpen(true);
	};
	const handleAllergyNote = (e: React.ChangeEvent<HTMLInputElement>) => {
		setAllergyNote(e.target.value);
	};
	const handleAllergyDate = (date: MaterialUiPickersDate) => {
		const allergyDateFormat = moment(date).format('YYYY-MM-DD');
		setAllergyDate(allergyDateFormat);
	};
	const handleChangeAllergyName = (e: React.ChangeEvent<HTMLInputElement>) => {
		setAllergyName(e.target.value);
		if (e.target.value.length > 0) {
			setIsEmptyAllergyName(false);
		} else {
			setIsEmptyAllergyName(true);
		}
	};
	const handleChangeAllergyStatus = (
		e: React.ChangeEvent<{
			name?: string | undefined;
			value: unknown;
		}>
	) => {
		setAllergyStatus(e.target.value as AllergyStatus);
	};
	const handleCloseAllergyForm = () => {
		clearAllergyForm();
	};
	const handleOpenAllergyForm = () => {
		setIsAllergyFormOpen(true);
	};

	// Procedure Funtionalities
	const clearProcedureForm = () => {
		setIsProcedureFormOpen(false);
		setProcedureName('');
		setProcedureDate(moment());
		setProcedureNote('');
		setProcedureId('');
		setIsProcedureDeleteOpen(false);
		setIsEmptyProcedureName(false);
	};
	const handleDeleteProcedure = () => {
		setIsProcedureFormOpen(false);
		setIsProcedureDeleteOpen(true);
	};
	const handleSubmitProcedure = async () => {
		if (procedureName === '') {
			setIsEmptyProcedureName(true);
			return;
		}
		if (procedureId !== '') {
			const updatedProcedure = await updatePatientProcedure({
				id: procedureId,
				clientID: selectedClient?.id as string,
				name: procedureName,
				note: procedureNote,
				realizationDate: procedureDate as string,
			});
			clearProcedureForm();
			if (updatedProcedure) {
				setClientHistory((prev: any) => {
					return {
						...prev,
						procedures: prev?.procedures?.map((d: any) =>
							d.id === procedureId ? updatedProcedure : d
						),
					};
				});
				setAlertMessage({
					message: 'Procedimiento editado',
					severity: 'success',
				});
			}
		} else {
			const createddProcedure = await createPatientProcedure({
				clientID: selectedClient?.id as string,
				name: procedureName,
				note: procedureNote,
				realizationDate: moment(procedureDate).format('YYYY-MM-DD'),
			} as ClientProcedure);
			clearProcedureForm();
			if (createddProcedure) {
				setClientHistory((prev: any) => {
					return {
						...prev,
						procedures: [createddProcedure, ...(prev?.procedures as ClientProcedure[])],
					};
				});
				setAlertMessage({
					message: 'Procedimiento agregado',
					severity: 'success',
				});
			}
		}
		await updateClientWithOutChanges(selectedClient?.id as string);
	};
	const handleEditProcedure = (id: string) => {
		setProcedureId(id);
		const procedureSelected = clientHistoryData?.procedures?.find((x: any) => x.id === id);
		setProcedureName(procedureSelected?.name as string);
		setProcedureDate(procedureSelected?.realizationDate);
		setProcedureNote(procedureSelected?.note as string);
		setIsProcedureFormOpen(true);
	};
	const handleProcedureNote = (e: React.ChangeEvent<HTMLInputElement>) => {
		setProcedureNote(e.target.value);
	};
	const handleProcedureDate = (date: MaterialUiPickersDate) => {
		const procedureDateFormat = moment(date).format('YYYY-MM-DD');
		setProcedureDate(procedureDateFormat);
	};
	const handleChangeProcedureName = (e: React.ChangeEvent<HTMLInputElement>) => {
		setProcedureName(e.target.value);
		if (e.target.value.length > 0) {
			setIsEmptyProcedureName(false);
		} else {
			setIsEmptyProcedureName(true);
		}
	};
	const handleCloseProcedureForm = () => {
		clearProcedureForm();
	};
	const handleOpenProcedureForm = () => {
		setIsProcedureFormOpen(true);
	};

	// Hospitalization Funtionalities
	const clearHospitalizationForm = () => {
		setIsHospitalizationFormOpen(false);
		setHospitalizationName('');
		setHospitalizationDate(moment());
		setHospitalizationNote('');
		setHospitalizationId('');
		setIsHospitalizationDeleteOpen(false);
		setIsEmptyHospitalizationName(false);
	};
	const handleDeleteHospitalization = () => {
		setIsHospitalizationFormOpen(false);
		setIsHospitalizationDeleteOpen(true);
	};
	const handleSubmitHospitalization = async () => {
		if (hospitalizationName === '') {
			setIsEmptyHospitalizationName(true);
			return;
		}

		if (hospitalizationId !== '') {
			const updatedHospitalization = await updatePatientHospitalization({
				id: hospitalizationId,
				clientID: selectedClient?.id as string,
				name: hospitalizationName,
				note: hospitalizationNote,
				date: hospitalizationDate as string,
			});
			clearHospitalizationForm();
			if (updatedHospitalization) {
				setClientHistory((prev: any) => {
					return {
						...prev,
						hospitalizations: prev?.hospitalizations?.map((d: any) =>
							d.id === hospitalizationId ? updatedHospitalization : d
						),
					};
				});
				setAlertMessage({
					message: 'Procedimiento editado',
					severity: 'success',
				});
			}
		} else {
			const createddHospitalization = await createPatientHospitalization({
				clientID: selectedClient?.id as string,
				name: hospitalizationName,
				note: hospitalizationNote,
				date: moment(hospitalizationDate).format('YYYY-MM-DD'),
			} as ClientHospitalization);
			clearHospitalizationForm();
			if (createddHospitalization) {
				setClientHistory((prev: any) => {
					return {
						...prev,
						hospitalizations: [
							createddHospitalization,
							...(prev?.hospitalizations as ClientHospitalization[]),
						],
					};
				});
				setAlertMessage({
					message: 'Hospitalización agregado',
					severity: 'success',
				});
			}
		}

		await updateClientWithOutChanges(selectedClient?.id as string);
	};
	const handleEditHospitalization = (id: string) => {
		setHospitalizationId(id);
		const hospitalizationSelected = clientHistoryData?.hospitalizations?.find(
			(x: any) => x.id === id
		);
		setHospitalizationName(hospitalizationSelected?.name as string);
		setHospitalizationDate(hospitalizationSelected?.date);
		setHospitalizationNote(hospitalizationSelected?.note as string);
		setIsHospitalizationFormOpen(true);
	};
	const handleHospitalizationNote = (e: React.ChangeEvent<HTMLInputElement>) => {
		setHospitalizationNote(e.target.value);
	};
	const handleHospitalizationDate = (date: MaterialUiPickersDate) => {
		const hospitalizationDateFormat = moment(date).format('YYYY-MM-DD');
		setHospitalizationDate(hospitalizationDateFormat);
	};
	const handleChangeHospitalizationName = (e: React.ChangeEvent<HTMLInputElement>) => {
		setHospitalizationName(e.target.value);
		if (e.target.value.length > 0) {
			setIsEmptyHospitalizationName(false);
		} else {
			setIsEmptyHospitalizationName(true);
		}
	};
	const handleCloseHospitalizationForm = () => {
		clearHospitalizationForm();
	};
	const handleOpenHospitalizationForm = () => {
		setIsHospitalizationFormOpen(true);
	};

	// Family History Funtionalities
	const clearFamilyHistoryForm = () => {
		setFamilyMember(null);
		setMellitusDiabetes(false);
		setMellitusDiabetesNote('');
		setArterialHypertension(false);
		setArterialHypertensionNote('');
		setHeartDisease(false);
		setHeartDiseaseNote('');
		setNephropathy(false);
		setNephropathyNote('');
		setNeuropathies(false);
		setNeuropathiesNote('');
		setEpilepsy(false);
		setEpilepsyNote('');
		setRheumatoidArthritis(false);
		setRheumatoidArthritisNote('');
		setPsychiatricDiseases(false);
		setPsychiatricDiseasesNote('');
		setCancer(false);
		setCancerNote('');
		setOther(false);
		setOtherNote('');
		setIsFamilyHistoryFormOpen(false);
	};
	const handleCloseFamilyHistoryForm = () => {
		clearFamilyHistoryForm();
	};
	const handleCreateFamilyHistory = (familyMember: string) => {
		setFamilyHistoryId('');
		setFamilyMember(getFamilyMemberFromLabel(familyMember));
		setIsFamilyHistoryFormOpen(true);
	};
	const handleEditFamilyHistory = (id: string, familyMember: string) => {
		setFamilyHistoryId(id);
		const selectedFamilyMember = clientHistoryData?.familyHistories?.find(
			(fh: any) => fh.id === id
		);
		setMellitusDiabetes(selectedFamilyMember?.mellitusDiabetes as boolean);
		setMellitusDiabetesNote(
			replaceCommanForBullet(selectedFamilyMember?.mellitusDiabetesNote as string)
		);
		setArterialHypertension(selectedFamilyMember?.arterialHypertension as boolean);
		setArterialHypertensionNote(
			replaceCommanForBullet(selectedFamilyMember?.arterialHypertensionNote as string)
		);
		setHeartDisease(selectedFamilyMember?.heartDisease as boolean);
		setHeartDiseaseNote(
			replaceCommanForBullet(selectedFamilyMember?.heartDiseaseNote as string)
		);
		setNephropathy(selectedFamilyMember?.nephropathy as boolean);
		setNephropathyNote(replaceCommanForBullet(selectedFamilyMember?.nephropathyNote as string));
		setNeuropathies(selectedFamilyMember?.neuropathies as boolean);
		setNeuropathiesNote(
			replaceCommanForBullet(selectedFamilyMember?.neuropathiesNote as string)
		);
		setEpilepsy(selectedFamilyMember?.epilepsy as boolean);
		setEpilepsyNote(replaceCommanForBullet(selectedFamilyMember?.epilepsyNote as string));
		setRheumatoidArthritis(selectedFamilyMember?.rheumatoidArthritis as boolean);
		setRheumatoidArthritisNote(
			replaceCommanForBullet(selectedFamilyMember?.rheumatoidArthritisNote as string)
		);
		setPsychiatricDiseases(selectedFamilyMember?.psychiatricDiseases as boolean);
		setPsychiatricDiseasesNote(
			replaceCommanForBullet(selectedFamilyMember?.psychiatricDiseasesNote as string)
		);
		setCancer(selectedFamilyMember?.cancer as boolean);
		setCancerNote(replaceCommanForBullet(selectedFamilyMember?.cancerNote as string));
		setFamilyMember(getFamilyMemberFromLabel(familyMember));
		setIsFamilyHistoryFormOpen(true);
	};
	const handleMellitusDiabetes = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event.target.checked) {
			setMellitusDiabetesNote('');
		}
		setMellitusDiabetes(event.target.checked);
	};
	const handleMellitusDiabetesNote = (event: React.ChangeEvent<HTMLInputElement>) => {
		setMellitusDiabetesNote(returBulletOnEnter(event));
	};
	const handleArterialHypertension = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event.target.checked) {
			setArterialHypertensionNote('');
		}
		setArterialHypertension(event.target.checked);
	};
	const handleArterialHypertensionNote = (event: React.ChangeEvent<HTMLInputElement>) => {
		setArterialHypertensionNote(returBulletOnEnter(event));
	};
	const handleHeartDisease = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event.target.checked) {
			setHeartDiseaseNote('');
		}
		setHeartDisease(event.target.checked);
	};
	const handleHeartDiseaseNote = (event: React.ChangeEvent<HTMLInputElement>) => {
		setHeartDiseaseNote(returBulletOnEnter(event));
	};
	const handleNephropathy = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event.target.checked) {
			setNephropathyNote('');
		}
		setNephropathy(event.target.checked);
	};
	const handleNephropathyNote = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNephropathyNote(returBulletOnEnter(event));
	};
	const handleNeuropathies = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event.target.checked) {
			setNeuropathiesNote('');
		}
		setNeuropathies(event.target.checked);
	};
	const handleNeuropathiesNote = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNeuropathiesNote(returBulletOnEnter(event));
	};
	const handleEpilepsy = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event.target.checked) {
			setEpilepsyNote('');
		}
		setEpilepsy(event.target.checked);
	};
	const handleEpilepsyNote = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEpilepsyNote(returBulletOnEnter(event));
	};
	const handleRheumatoidArthritis = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event.target.checked) {
			setRheumatoidArthritisNote('');
		}
		setRheumatoidArthritis(event.target.checked);
	};
	const handleRheumatoidArthritisNote = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRheumatoidArthritisNote(returBulletOnEnter(event));
	};
	const handlePsychiatricDiseases = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event.target.checked) {
			setPsychiatricDiseasesNote('');
		}
		setPsychiatricDiseases(event.target.checked);
	};
	const handlePsychiatricDiseasesNote = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPsychiatricDiseasesNote(returBulletOnEnter(event));
	};
	const handleCancer = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event.target.checked) {
			setCancerNote('');
		}
		setCancer(event.target.checked);
	};
	const handleCancerNote = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCancerNote(returBulletOnEnter(event));
	};
	const handleOther = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event.target.checked) {
			setOtherNote('');
		}
		setOther(event.target.checked);
	};
	const handleOtherNote = (event: React.ChangeEvent<HTMLInputElement>) => {
		setOtherNote(returBulletOnEnter(event));
	};

	const createFamilyHistory = async (commonInfo: any) => {
		const data = {
			clientID: selectedClient?.id as string,
			familyMember,
			...commonInfo,
		};

		const createdFamilyHistory = await createPatientFamilyHistory(data as ClientFamilyHistory);
		clearFamilyHistoryForm();
		if (createdFamilyHistory) {
			setClientHistory((prev: any) => {
				return {
					...prev,
					familyHistories: [
						createdFamilyHistory,
						...(prev?.familyHistories as ClientFamilyHistory[]),
					],
				};
			});
			setAlertMessage({
				message: 'La historia familiar ha sido guardada',
				severity: 'success',
			});
		}
		await updateClientWithOutChanges(selectedClient?.id as string);
	};

	const updateFamilyHistory = async (commonInfo: any) => {
		const data = {
			id: familyHistoryId,
			clientID: selectedClient?.id as string,
			familyMember: familyMember as HistoryFamilyMember,
			...commonInfo,
		};

		const updatedFamilyHistory = await updatePatientFamilyHistory(data);
		clearFamilyHistoryForm();
		if (updatedFamilyHistory) {
			setClientHistory((prev: any) => {
				return {
					...prev,
					familyHistories: prev?.familyHistories?.map((fh: any) =>
						fh.id === familyHistoryId ? updatedFamilyHistory : fh
					),
				};
			});
			setAlertMessage({
				message: 'La historia familiar ha sido actualizada',
				severity: 'success',
			});
		}

		await updateClientWithOutChanges(selectedClient?.id as string);
	};

	const onSubmit = async () => {
		const commonInfo = {
			mellitusDiabetes,
			mellitusDiabetesNote: removeBulletAddComma(mellitusDiabetesNote),
			arterialHypertension,
			arterialHypertensionNote: removeBulletAddComma(arterialHypertensionNote),
			heartDisease,
			heartDiseaseNote: removeBulletAddComma(heartDiseaseNote),
			nephropathy,
			nephropathyNote: removeBulletAddComma(nephropathyNote),
			neuropathies,
			neuropathiesNote: removeBulletAddComma(neuropathiesNote),
			epilepsy,
			epilepsyNote: removeBulletAddComma(epilepsyNote),
			rheumatoidArthritis,
			rheumatoidArthritisNote: removeBulletAddComma(rheumatoidArthritisNote),
			psychiatricDiseases,
			psychiatricDiseasesNote: removeBulletAddComma(psychiatricDiseasesNote),
			cancer,
			cancerNote: removeBulletAddComma(cancerNote),
			other,
			otherNote: removeBulletAddComma(otherNote),
		};
		if (familyHistoryId === '') {
			createFamilyHistory(commonInfo);
		} else {
			updateFamilyHistory(commonInfo);
		}
	};

	// Life Style Functionality
	const cancelAlcohol = () => {
		if (alcoholId === '') {
			setLifeStyleAlcohol('');
			setLifeStyleAlcoholNote('');
		} else {
			const clientAlcohol = getClientLifeStyle(LifeStyle.ALCOHOL);
			if (clientAlcohol) {
				setLifeStyleAlcohol(clientAlcohol.name);
				setLifeStyleAlcoholNote(clientAlcohol.description);
			} else {
				setLifeStyleAlcohol('');
				setLifeStyleAlcoholNote('');
			}
		}
	};

	const getLifeStyleTitle = (lifeStyle: LifeStyle) => {
		let title = 'Uso de alcohol';
		if (lifeStyle === LifeStyle.TABACO) {
			title = 'Uso de tabaco';
		}
		if (lifeStyle === LifeStyle.ACTIVITY) {
			title = 'Actividad física';
		}
		if (lifeStyle === LifeStyle.DRUG) {
			title = 'Otras drogas recreativas';
		}

		return { title };
	};

	const createLifeStyle = async (
		nameValue: string | string[],
		description: string,
		lifeStyle: LifeStyle
	) => {
		const createdPatienttLifeStyle = await createPatientLifeStyle({
			clientID: selectedClient?.id,
			name: nameValue,
			description: description,
			lifeStyle: lifeStyle,
		} as ClientLifeStyle);
		if (createdPatienttLifeStyle) {
			setClientHistory((prev: any) => {
				return {
					...prev,
					lifeStyles: [
						{
							...createdPatienttLifeStyle,
							updatedAt: getDateAndTime(),
						},
						...(prev?.lifeStyles as ClientLifeStyle[]),
					],
				};
			});
		}
		const { title } = getLifeStyleTitle(lifeStyle);
		setAlertMessage({
			message: `${title} ha sido guardado(a)`,
			severity: 'success',
		});
	};
	const updateLifeStyle = async (
		id: string,
		nameValue: string | string[],
		description: string,
		lifeStyle: LifeStyle
	) => {
		const updatedPatientLifeStyle = (await updatePatientLifeStyle({
			id: id,
			name: nameValue,
			description: description,
			lifeStyle: lifeStyle,
		} as ClientLifeStyle)) as ClientLifeStyle;
		if (updatedPatientLifeStyle) {
			setClientHistory((prev: any) => {
				return {
					...prev,
					lifeStyles: prev?.lifeStyles?.map((ls: any) =>
						ls.id === updatedPatientLifeStyle.id
							? {
									...updatedPatientLifeStyle,
									updatedAt: getDateAndTime(),
							  }
							: ls
					),
				};
			});
		}
		const { title } = getLifeStyleTitle(lifeStyle);
		setAlertMessage({
			message: `${title} ha sido actualizado(a)`,
			severity: 'success',
		});
	};
	const handleSave = async (
		id: string,
		name: string | string[],
		description: string,
		lifeStyle: LifeStyle
	) => {
		const nameValue = lifeStyle === LifeStyle.DRUG ? name.toString() : name;
		if (id === '') {
			await createLifeStyle(nameValue, description, lifeStyle);
		} else {
			await updateLifeStyle(id, nameValue, description, lifeStyle);
		}
	};
	const handleLifeStyleAlcohol = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLifeStyleAlcohol(event.target.value);
	};
	const handleLifeStyleAlcoholNote = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLifeStyleAlcoholNote(event.target.value);
	};
	const cancelTabaco = () => {
		if (tabacoId === '') {
			setLifeStyleTabaco('');
			setLifeStyleTabacoNote('');
		} else {
			const clientTabaco = getClientLifeStyle(LifeStyle.TABACO);
			if (clientTabaco) {
				setLifeStyleTabaco(clientTabaco.name);
				setLifeStyleTabacoNote(clientTabaco.description);
			} else {
				setLifeStyleTabaco('');
				setLifeStyleTabacoNote('');
			}
		}
	};
	const handleLifeStyleTabaco = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLifeStyleTabaco(event.target.value);
	};
	const handleLifeStyleTabacoNote = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLifeStyleTabacoNote(event.target.value);
	};
	const cancelActivity = () => {
		if (activityId === '') {
			setLifeStyleActivity('');
			setLifeStyleActivityNote('');
		} else {
			const clientActivity = getClientLifeStyle(LifeStyle.ACTIVITY);
			if (clientActivity) {
				setLifeStyleActivity(clientActivity.name);
				setLifeStyleActivityNote(clientActivity.description);
			} else {
				setLifeStyleActivity('');
				setLifeStyleActivityNote('');
			}
		}
	};
	const handleLifeStyleActivity = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLifeStyleActivity(event.target.value);
	};
	const handleLifeStyleActivityNote = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLifeStyleActivityNote(event.target.value);
	};
	const cancelDrug = () => {
		if (drugId === '') {
			setLifeStyleDrug([]);
			setLifeStyleDrugNote('');
		} else {
			const clientDrug = getClientLifeStyle(LifeStyle.DRUG);
			if (clientDrug) {
				setLifeStyleDrug(clientDrug.name ? clientDrug.name.split(',') : []);
				setLifeStyleDrugNote(clientDrug.description);
			} else {
				setLifeStyleDrug([]);
				setLifeStyleDrugNote('');
			}
		}
	};
	const handleLifeStyleDrug = (
		event: React.ChangeEvent<{
			name?: string | undefined;
			value: string[];
		}>
	) => {
		if (event.target.value.includes(drugs[0].value)) {
			setLifeStyleDrug([drugs[0].value]);
		} else {
			setLifeStyleDrug(event.target.value);
		}
	};
	const handleLifeStyleDrugNote = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLifeStyleDrugNote(event.target.value);
	};

	const getClientLifeStyle = (lifeStyle: LifeStyle) => {
		if (
			clientHistoryData?.lifeStyles &&
			clientHistoryData?.lifeStyles.some((x: any) => x.lifeStyle === lifeStyle)
		) {
			return clientHistoryData?.lifeStyles?.find((ls: any) => ls.lifeStyle === lifeStyle);
		} else {
			return null;
		}
	};

	const isAlcoholVisible =
		lifeStyleAlcohol !== '' &&
		(getClientLifeStyle(LifeStyle.ALCOHOL)?.name !== lifeStyleAlcohol ||
			getClientLifeStyle(LifeStyle.ALCOHOL)?.description !== lifeStyleAlcoholNote);

	const isTabacoVisible =
		lifeStyleTabaco !== '' &&
		(getClientLifeStyle(LifeStyle.TABACO)?.name !== lifeStyleTabaco ||
			getClientLifeStyle(LifeStyle.TABACO)?.description !== lifeStyleTabacoNote);

	const isActivityVisible =
		lifeStyleActivity !== '' &&
		(getClientLifeStyle(LifeStyle.ACTIVITY)?.name !== lifeStyleActivity ||
			getClientLifeStyle(LifeStyle.ACTIVITY)?.description !== lifeStyleActivityNote);

	const isDrugVisible =
		lifeStyleDrug.length > 0 &&
		((getClientLifeStyle(LifeStyle.DRUG)?.name &&
			getClientLifeStyle(LifeStyle.DRUG)?.name !== lifeStyleDrug.toString()) ||
			getClientLifeStyle(LifeStyle.DRUG)?.description !== lifeStyleDrugNote);

	useEffect(() => {
		if (clientHistoryData?.lifeStyles) {
			const clientAlcohol = getClientLifeStyle(LifeStyle.ALCOHOL);
			const clientTabaco = getClientLifeStyle(LifeStyle.TABACO);
			const clientActivity = getClientLifeStyle(LifeStyle.ACTIVITY);
			const clientDrug = getClientLifeStyle(LifeStyle.DRUG);
			if (clientAlcohol) {
				setAlcoholId(clientAlcohol.id);
				setLifeStyleAlcohol(clientAlcohol.name);
				setLifeStyleAlcoholNote(clientAlcohol.description);
				setAlcoholUpdatedAt(clientAlcohol.updatedAt);
			} else {
				setAlcoholId('');
				setLifeStyleAlcohol('');
				setLifeStyleAlcoholNote('');
				setAlcoholUpdatedAt('');
			}
			if (clientTabaco) {
				setTabacoId(clientTabaco.id);
				setLifeStyleTabaco(clientTabaco.name);
				setLifeStyleTabacoNote(clientTabaco.description);
				setTabacoUpdatedAt(clientTabaco.updatedAt);
			} else {
				setTabacoId('');
				setLifeStyleTabaco('');
				setLifeStyleTabacoNote('');
				setTabacoUpdatedAt('');
			}
			if (clientActivity) {
				setActivityId(clientActivity.id);
				setLifeStyleActivity(clientActivity.name);
				setLifeStyleActivityNote(clientActivity.description);
				setActivityUpdatedAt(clientActivity.updatedAt);
			} else {
				setActivityId('');
				setLifeStyleActivity('');
				setLifeStyleActivityNote('');
				setActivityUpdatedAt('');
			}
			if (clientDrug) {
				setDrugId(clientDrug.id);
				setLifeStyleDrug(clientDrug.name ? clientDrug.name?.split(',') : []);
				setLifeStyleDrugNote(clientDrug.description);
				setDrugUpdatedAt(clientDrug.updatedAt);
			} else {
				setDrugId('');
				setLifeStyleDrug([]);
				setLifeStyleDrugNote('');
				setDrugUpdatedAt('');
			}
		}
	}, [currentSidebarTab]);

	const getDropDownValueBaseOnTab = (tab: HistorySidebarOptions): string => {
		return DROPDOWN_MOBILE_MENU[tab];
	};

	const onChangeTab = (e: FormEvent<SelectProps>) => {
		const value = (e.target as HTMLInputElement)?.value;
		const tabs: {
			[key: string]: HistorySidebarOptions;
		} = {
			Enfermedades: HistorySidebarOptions.DISEASES,
			Alergias: HistorySidebarOptions.ALERGIES,
			Procedimientos: HistorySidebarOptions.PROCEDURES,
			'Estilo de vida': HistorySidebarOptions.LIFE_STYLE,
			'Historia familiar': HistorySidebarOptions.FAMILY_HISTORY,
			Nutrición: HistorySidebarOptions.NUTRITION,
			Hospitalizaciones: HistorySidebarOptions.PREVIOUS_HOSPITALIZATION,
			'Resumen del paciente': HistorySidebarOptions.RESUMEN,
			'Tabaco y drogas recreativas': HistorySidebarOptions.SMOKING_DRUG,
			'Vacunas e inmunización': HistorySidebarOptions.VACCINES_IMMUNIZATION,
		};
		setCurrentSidebarTab(tabs[value] ?? HistorySidebarOptions.RESUMEN);
		onCloseAll();
	};

	const onAddHistoryElement = () => {
		switch (currentSidebarTab) {
			case HistorySidebarOptions.DISEASES:
				handleOpenDiseaseForm();
				break;
			case HistorySidebarOptions.ALERGIES:
				handleOpenAllergyForm();
				break;
			case HistorySidebarOptions.PROCEDURES:
				handleOpenProcedureForm();
				break;
			case HistorySidebarOptions.PREVIOUS_HOSPITALIZATION:
				handleOpenHospitalizationForm();
				break;
			case HistorySidebarOptions.FAMILY_HISTORY:
				handleCreateFamilyHistory('Padre');
				break;
			default:
				break;
		}
	};

	const dropDownValues: {
		groupTitle: string;
		options: {
			label: string;
			value: number;
			highlight?: boolean;
		}[];
	}[] = [
		{
			groupTitle: '',
			options: [
				{
					label: 'Resumen del paciente',
					value: 0,
				},
			],
		},
		{
			groupTitle: '',
			options: [
				{
					label: 'Enfermedades',
					value: clientHistoryData?.diseases?.length ?? 0,
					highlight: true,
				},
				{
					label: 'Alergias',
					value: clientHistoryData?.allergies?.length ?? 0,
					highlight: true,
				},
				{
					label: 'Procedimientos',
					value: clientHistoryData?.procedures?.length ?? 0,
				},
				{
					label: 'Hospitalizaciones',
					value: clientHistoryData?.hospitalizations?.length ?? 0,
				},
			],
		},
		{
			groupTitle: '',
			options: [
				{
					label: 'Historia familiar',
					value: clientHistoryData?.familyHistories?.length ?? 0,
				},
			],
		},
		{
			groupTitle: '',
			options: [
				{
					label: 'Estilo de vida',
					value: clientHistoryData?.lifeStyles?.length ?? 0,
				},
			],
		},
	];

	const isAnyModalOpen =
		isDiseaseFormOpen ||
		isAllergyFormOpen ||
		isProcedureFormOpen ||
		isHospitalizationFormOpen ||
		isFamilyHistoryFormOpen;

	return (
		<>
			{isMobile && currentSidebarTab !== HistorySidebarOptions.RESUMEN && (
				<div className="flex flex-col w-full p-4 bg-[#ECEFF1]">
					<h1 className="mobile-patient-detail-title">Historial</h1>
					<div className="flex gap-2">
						<HistoryDropdown
							options={dropDownValues}
							value={getDropDownValueBaseOnTab(currentSidebarTab)}
							optionRender={(option) => {
								if (option.groupTitle === '') {
									return option.options.map((opt) => (
										<CustomMenuItem
											key={opt.label}
											value={opt.label}
											label={opt.label}
											count={opt.value}
											highlight={opt.highlight}
											highlightColor="#ED6C02"
										/>
									));
								}

								return [
									<CustomGroupTitle title={option.groupTitle} />,
									option.options.map((opt) => (
										<CustomMenuItem
											key={opt.label}
											value={opt.label}
											label={opt.label}
											count={opt.value}
											highlight={opt.highlight}
											highlightColor="#ED6C02"
										/>
									)),
								];
							}}
							defaultValue={'Resumen del paciente'}
							renderValue={(value) => {
								return value;
							}}
							onChange={onChangeTab}
						/>
						{currentSidebarTab !== HistorySidebarOptions.FAMILY_HISTORY &&
							currentSidebarTab !== HistorySidebarOptions.LIFE_STYLE && (
								<span
									className="w-[64px] h-[36px] bg-[#F5F5F5] flex items-center justify-center cursor-pointer"
									style={{
										borderRadius: '4px',
										boxShadow:
											'0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
									}}
									onClick={onAddHistoryElement}
								>
									<AddButtonIcon />
								</span>
							)}
					</div>
				</div>
			)}
			{isDiseaseFormOpen && (
				<DiseaseFormDialog
					isEdit={diseaseId !== ''}
					isOpen={isDiseaseFormOpen}
					fullWidth={isMobile}
					handleClose={handleCloseDiseaseForm}
					diseaseName={diseaseName}
					isEmptyDiseaseName={isEmptyDiseaseName}
					handleChangeDiseaseName={handleChangeDiseaseName}
					diseaseStatus={diseaseStatus}
					handleChangeDiseaseStatus={handleChangeDiseaseStatus}
					diseaseDate={diseaseDate}
					handleDiseaseDate={handleDiseaseDate}
					diseaseNote={diseaseNote}
					handleDiseaseNote={handleDiseaseNote}
					handleSubmitDisease={handleSubmitDisease}
					handleDeleteDisease={handleDeleteDisease}
					isMobile={isMobile}
				/>
			)}
			{isAllergyFormOpen && (
				<AllergyFormDialog
					isEdit={allergyId !== ''}
					isOpen={isAllergyFormOpen}
					handleClose={handleCloseAllergyForm}
					allergyName={allergyName}
					isEmptyAllergyName={isEmptyAllergyName}
					handleChangeAllergyName={handleChangeAllergyName}
					allergyStatus={allergyStatus}
					handleChangeAllergyStatus={handleChangeAllergyStatus}
					allergyDate={allergyDate}
					handleAllergyDate={handleAllergyDate}
					allergyNote={allergyNote}
					handleAllergyNote={handleAllergyNote}
					handleSubmitAllergy={handleSubmitAllergy}
					handleDeleteAllergy={handleDeleteAllergy}
					isMobile={isMobile}
				/>
			)}
			{isProcedureFormOpen && (
				<ProcedureFormDialog
					isEdit={procedureId !== ''}
					isOpen={isProcedureFormOpen}
					handleClose={handleCloseProcedureForm}
					procedureName={procedureName}
					isEmptyProcedureName={isEmptyProcedureName}
					handleChangeProcedureName={handleChangeProcedureName}
					procedureDate={procedureDate}
					handleProcedureDate={handleProcedureDate}
					procedureNote={procedureNote}
					handleProcedureNote={handleProcedureNote}
					handleSubmitProcedure={handleSubmitProcedure}
					handleDeleteProcedure={handleDeleteProcedure}
					isMobile={isMobile}
				/>
			)}
			{isHospitalizationFormOpen && (
				<HospitalizationFormDialog
					isEdit={hospitalizationId !== ''}
					isOpen={isHospitalizationFormOpen}
					handleClose={handleCloseHospitalizationForm}
					hospitalizationName={hospitalizationName}
					isEmptyHospitalizationName={isEmptyHospitalizationName}
					handleChangeHospitalizationName={handleChangeHospitalizationName}
					hospitalizationDate={hospitalizationDate}
					handleHospitalizationDate={handleHospitalizationDate}
					hospitalizationNote={hospitalizationNote}
					handleHospitalizationNote={handleHospitalizationNote}
					handleSubmitHospitalization={handleSubmitHospitalization}
					handleDeleteHospitalization={handleDeleteHospitalization}
					isMobile={isMobile}
				/>
			)}
			{isFamilyHistoryFormOpen && (
				<FamilyHistoryFormDialog
					familyMember={familyMember as HistoryFamilyMember}
					isEdit={familyHistoryId !== ''}
					isOpen={isFamilyHistoryFormOpen}
					handleClose={handleCloseFamilyHistoryForm}
					mellitusDiabetes={mellitusDiabetes}
					handleMellitusDiabetes={handleMellitusDiabetes}
					mellitusDiabetesNote={mellitusDiabetesNote}
					handleMellitusDiabetesNote={handleMellitusDiabetesNote}
					arterialHypertension={arterialHypertension}
					handleArterialHypertension={handleArterialHypertension}
					arterialHypertensionNote={arterialHypertensionNote}
					handleArterialHypertensionNote={handleArterialHypertensionNote}
					heartDisease={heartDisease}
					handleHeartDisease={handleHeartDisease}
					heartDiseaseNote={heartDiseaseNote}
					handleHeartDiseaseNote={handleHeartDiseaseNote}
					nephropathy={nephropathy}
					handleNephropathy={handleNephropathy}
					nephropathyNote={nephropathyNote}
					handleNephropathyNote={handleNephropathyNote}
					neuropathies={neuropathies}
					handleNeuropathies={handleNeuropathies}
					neuropathiesNote={neuropathiesNote}
					handleNeuropathiesNote={handleNeuropathiesNote}
					epilepsy={epilepsy}
					handleEpilepsy={handleEpilepsy}
					epilepsyNote={epilepsyNote}
					handleEpilepsyNote={handleEpilepsyNote}
					rheumatoidArthritis={rheumatoidArthritis}
					handleRheumatoidArthritis={handleRheumatoidArthritis}
					rheumatoidArthritisNote={rheumatoidArthritisNote}
					handleRheumatoidArthritisNote={handleRheumatoidArthritisNote}
					psychiatricDiseases={psychiatricDiseases}
					handlePsychiatricDiseases={handlePsychiatricDiseases}
					psychiatricDiseasesNote={psychiatricDiseasesNote}
					handlePsychiatricDiseasesNote={handlePsychiatricDiseasesNote}
					cancer={cancer}
					handleCancer={handleCancer}
					cancerNote={cancerNote}
					handleCancerNote={handleCancerNote}
					other={other}
					handleOther={handleOther}
					otherNote={otherNote}
					handleOtherNote={handleOtherNote}
					onSubmit={onSubmit}
					isMobile={isMobile}
				/>
			)}
			<Grid
				container
				style={
					isMobile
						? {
								backgroundColor: '#eceff1',
						  }
						: {}
				}
			>
				{!isMobile && (
					<HistoryListContainer>
						<HistorySidebar />
					</HistoryListContainer>
				)}
				<HistoryContainer>
					{currentSidebarTab === HistorySidebarOptions.RESUMEN && (
						<HistoryBodyContainer>
							{isMobile && (
								<div className="flex flex-col w-full mb-4">
									<h1 className="mobile-patient-detail-title">Historial</h1>
									<HistoryDropdown
										options={dropDownValues}
										value={getDropDownValueBaseOnTab(currentSidebarTab)}
										optionRender={(option) => {
											if (option.groupTitle === '') {
												return option.options.map((opt) => (
													<CustomMenuItem
														key={opt.label}
														value={opt.label}
														label={opt.label}
														count={opt.value}
														highlightColor="#ED6C02"
													/>
												));
											}
											return [
												<CustomGroupTitle title={option.groupTitle} />,
												option.options.map((opt) => (
													<CustomMenuItem
														key={opt.label}
														value={opt.label}
														label={opt.label}
														count={opt.value}
														highlight={opt.highlight}
														highlightColor="#ED6C02"
													/>
												)),
											];
										}}
										renderValue={(value) => {
											return value;
										}}
										defaultValue={HistorySidebarOptions.RESUMEN}
										onChange={onChangeTab}
									/>
								</div>
							)}
							<SummaryContainer>
								<AllergyCard
									handleOpenAllergyForm={handleOpenAllergyForm}
									allergies={clientHistoryData?.allergies}
									loading={loadingClientHistory}
								/>
								<PathologicalHistoryCard
									handleOpenDiseaseForm={handleOpenDiseaseForm}
									handleOpenProcedureForm={handleOpenProcedureForm}
									handleOpenHospitalizationForm={handleOpenHospitalizationForm}
									diseases={clientHistoryData?.diseases}
									procedures={clientHistoryData?.procedures}
									hospitalizations={clientHistoryData?.hospitalizations}
									loading={loadingClientHistory}
								/>
							</SummaryContainer>
							<SummaryContainer>
								<FamilyHistoryCard
									familyHistories={clientHistoryData?.familyHistories}
									loading={loadingClientHistory}
								/>
								<NonPathologicalHistoryCard
									lifeStyles={clientHistoryData?.lifeStyles}
									loading={loadingClientHistory}
								/>
							</SummaryContainer>
						</HistoryBodyContainer>
					)}
					{currentSidebarTab !== HistorySidebarOptions.RESUMEN && (
						<HistoryListBodyContainer isMobile={isMobile}>
							{currentSidebarTab === HistorySidebarOptions.ALERGIES && (
								<AllergyList
									allergyId={allergyId}
									clearAllergyForm={clearAllergyForm}
									handleEditAllergy={handleEditAllergy}
									handleOpenAllergyForm={handleOpenAllergyForm}
									isAllergyDeleteOpen={isAllergyDeleteOpen}
									allergyName={allergyName}
									isAnyModalOpen={isAnyModalOpen}
									allergies={clientHistoryData?.allergies}
									setClientHistory={setClientHistory}
								/>
							)}
							{currentSidebarTab === HistorySidebarOptions.DISEASES && (
								<DiseaseList
									diseaseId={diseaseId}
									clearDiseaseForm={clearDiseaseForm}
									handleEditDisease={handleEditDisease}
									handleOpenDiseaseForm={handleOpenDiseaseForm}
									isDiseaseDeleteOpen={isDiseaseDeleteOpen}
									diseaseName={diseaseName}
									isAnyModalOpen={isAnyModalOpen}
									diseases={clientHistoryData?.diseases}
									deletePatientDisease={deletePatientDiseaseHandler}
								/>
							)}
							{currentSidebarTab === HistorySidebarOptions.PROCEDURES && (
								<ProcedureList
									procedureId={procedureId}
									clearProcedureForm={clearProcedureForm}
									handleEditProcedure={handleEditProcedure}
									handleOpenProcedureForm={handleOpenProcedureForm}
									isProcedureDeleteOpen={isProcedureDeleteOpen}
									procedureName={procedureName}
									procedures={clientHistoryData?.procedures}
									setClientHistory={setClientHistory}
									isAnyModalOpen={isAnyModalOpen}
								/>
							)}
							{currentSidebarTab ===
								HistorySidebarOptions.PREVIOUS_HOSPITALIZATION && (
								<HospitalizationList
									hospitalizationId={hospitalizationId}
									clearHospitalizationForm={clearHospitalizationForm}
									handleEditHospitalization={handleEditHospitalization}
									handleOpenHospitalizationForm={handleOpenHospitalizationForm}
									isHospitalizationDeleteOpen={isHospitalizationDeleteOpen}
									hospitalizationName={hospitalizationName}
									isAnyModalOpen={isAnyModalOpen}
									hospitalizations={clientHistoryData?.hospitalizations}
									setClientHistory={setClientHistory}
								/>
							)}
							{currentSidebarTab === HistorySidebarOptions.FAMILY_HISTORY && (
								<FamilyHistoryList
									handleEditFamilyHistory={handleEditFamilyHistory}
									handleCreateFamilyHistory={handleCreateFamilyHistory}
									isAnyModalOpen={isAnyModalOpen}
									familyHistories={clientHistoryData?.familyHistories}
								/>
							)}
							{currentSidebarTab === HistorySidebarOptions.LIFE_STYLE && (
								<LifeStyleList
									getLifeStyleTitle={getLifeStyleTitle}
									cancelAlcohol={cancelAlcohol}
									handleSave={handleSave}
									alcoholId={alcoholId}
									alcoholUpdatedAt={alcoholUpdatedAt}
									isAlcoholVisible={isAlcoholVisible}
									lifeStyleAlcohol={lifeStyleAlcohol}
									handleLifeStyleAlcohol={handleLifeStyleAlcohol}
									lifeStyleAlcoholNote={lifeStyleAlcoholNote}
									handleLifeStyleAlcoholNote={handleLifeStyleAlcoholNote}
									cancelTabaco={cancelTabaco}
									tabacoId={tabacoId}
									tabacoUpdatedAt={tabacoUpdatedAt}
									isTabacoVisible={isTabacoVisible}
									isAnyModalOpen={isAnyModalOpen}
									lifeStyleTabaco={lifeStyleTabaco}
									handleLifeStyleTabaco={handleLifeStyleTabaco}
									lifeStyleTabacoNote={lifeStyleTabacoNote}
									handleLifeStyleTabacoNote={handleLifeStyleTabacoNote}
									cancelActivity={cancelActivity}
									activityId={activityId}
									activityUpdatedAt={activityUpdatedAt}
									isActivityVisible={isActivityVisible}
									lifeStyleActivity={lifeStyleActivity}
									handleLifeStyleActivity={handleLifeStyleActivity}
									lifeStyleActivityNote={lifeStyleActivityNote}
									handleLifeStyleActivityNote={handleLifeStyleActivityNote}
									cancelDrug={cancelDrug}
									drugId={drugId}
									drugUpdatedAt={drugUpdatedAt}
									isDrugVisible={isDrugVisible as boolean}
									lifeStyleDrug={lifeStyleDrug}
									handleLifeStyleDrug={handleLifeStyleDrug}
									lifeStyleDrugNote={lifeStyleDrugNote}
									handleLifeStyleDrugNote={handleLifeStyleDrugNote}
								/>
							)}
							{currentSidebarTab === HistorySidebarOptions.VACCINES_IMMUNIZATION && (
								<VaccineInmunizationList />
							)}
							{currentSidebarTab === HistorySidebarOptions.NUTRITION && (
								<NutritionList />
							)}
						</HistoryListBodyContainer>
					)}
				</HistoryContainer>
			</Grid>
		</>
	);
};

export default PatientHistory;
