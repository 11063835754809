import { useState, FC } from 'react';
import { SpeedDial, SpeedDialIcon } from '@material-ui/lab';
import { StyledTransparentBackdrop } from 'shared/style';
import { ButtonBase, withStyles } from '@material-ui/core';
import './MobileActionsButton.css';

type Actions = {
	name: string;
	onClick: () => void;
};

type MobileActionsButtonProps = {
	actions: Actions[];
};

const MobileActionsButton: FC<MobileActionsButtonProps> = ({ actions }) => {
	const [openAddBtn, setOpenAddBtn] = useState(false);

	const mobileDialToggle = () => {
		setOpenAddBtn(!openAddBtn);
	};

	const StyledButtonBase = withStyles({
		root: {
			padding: '16px 0',
			textAlign: 'left',
			width: '100%',
			justifyContent: 'flex-start',
		},
	})(ButtonBase);

	return (
		<>
			{openAddBtn && <StyledTransparentBackdrop open onClick={mobileDialToggle} />}
			<SpeedDial
				className="mobile-actions-button"
				ariaLabel="Creation Actions Button"
				FabProps={{ color: 'inherit', size: 'small' }}
				icon={<SpeedDialIcon />}
				style={{ marginLeft: 8 }}
				onClick={mobileDialToggle}
				open={openAddBtn}
				direction={'down'}
			>
				{actions.map((action) => (
					<StyledButtonBase onClick={action.onClick}>{action.name}</StyledButtonBase>
				))}
			</SpeedDial>
		</>
	);
};

export default MobileActionsButton;
