import React from 'react';

const OffLineSVG = () => {
	return (
		<svg
			width="195"
			height="195"
			style={{ margin: 'auto' }}
			viewBox="0 0 195 195"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_5801_136780)">
				<path
					opacity="0.1"
					d="M97.756 194.928C151.328 194.928 194.756 151.5 194.756 97.9291C194.756 44.3578 151.328 0.929688 97.756 0.929688C44.1843 0.929688 0.755859 44.3578 0.755859 97.9291C0.755859 151.5 44.1843 194.928 97.756 194.928Z"
					fill="#367CFF"
				/>
				<path
					d="M174.929 156.702C157.207 179.936 129.233 194.928 97.7584 194.928C66.2833 194.928 38.3094 179.936 20.5879 156.702C40.8179 149.091 67.9409 144.438 97.7584 144.438C127.576 144.438 154.697 149.091 174.929 156.702Z"
					fill="#367CFF"
				/>
				<path
					d="M183.069 51.7314L133.287 51.5187C133.287 43.2046 137.97 41.5677 142.949 40.2175C147.927 38.8673 150.167 29.7288 158.818 29.2088C163.926 28.9016 166.606 30.2163 168.825 31.9093C168.828 31.9123 168.828 31.9152 168.831 31.9152C174.39 37.8967 179.174 44.5545 183.069 51.7314Z"
					fill="white"
				/>
				<path
					d="M41.0656 113.508L2.02973 113.676C1.36296 109.6 0.958339 105.486 0.818359 101.359C9.3689 100.147 8.49434 93.3518 18.7053 93.9693C26.2809 94.4243 28.2486 102.428 32.6066 103.61C36.9646 104.792 41.0656 106.229 41.0656 113.508Z"
					fill="white"
				/>
				<path
					d="M92.7873 110.016C97.704 110.016 101.69 106.03 101.69 101.113C101.69 96.1967 97.704 92.2109 92.7873 92.2109C87.8706 92.2109 83.8848 96.1967 83.8848 101.113C83.8848 106.03 87.8706 110.016 92.7873 110.016Z"
					fill="#9EBCEA"
				/>
				<path
					d="M109.319 91.5901C108.847 91.5904 108.381 91.4729 107.965 91.2482C107.549 91.0235 107.196 90.6988 106.937 90.3032C106.401 89.4858 105.792 88.7181 105.119 88.0095L104.79 87.6862C103.214 86.1066 101.342 84.8534 99.2813 83.9984C97.2206 83.1433 95.0113 82.7032 92.7802 82.7032C90.5491 82.7032 88.3399 83.1433 86.2792 83.9984C84.2184 84.8534 82.3465 86.1066 80.7708 87.6862C79.9715 88.4827 79.2564 89.3594 78.6369 90.3026C78.2122 90.9059 77.5699 91.3207 76.8453 91.4594C76.1207 91.5982 75.3705 91.4501 74.753 91.0463C74.1356 90.6426 73.6991 90.0148 73.5356 89.2954C73.3721 88.576 73.4945 87.8212 73.8768 87.1902C74.7108 85.9196 75.6733 84.7382 76.7493 83.6647C80.8501 79.556 86.372 77.1787 92.1749 77.0237C97.9778 76.8687 103.619 78.9478 107.933 82.8318H107.977L108.824 83.6647C109.9 84.7376 110.862 85.9186 111.695 87.1889C111.976 87.6178 112.135 88.1144 112.156 88.6263C112.178 89.1382 112.061 89.6463 111.817 90.0972C111.574 90.548 111.213 90.9247 110.773 91.1876C110.334 91.4505 109.831 91.5898 109.319 91.5907L109.319 91.5901Z"
					fill="#9EBCEA"
				/>
				<path
					d="M64.3546 83.7166C63.8405 83.7158 63.3363 83.5758 62.8954 83.3114C62.4546 83.047 62.0937 82.6681 61.8509 82.2149C61.6082 81.7618 61.4927 81.2514 61.5168 80.7379C61.5409 80.2244 61.7037 79.727 61.9878 79.2986C63.3545 77.2395 64.9226 75.3213 66.669 73.5725C73.5974 66.6478 82.9921 62.7578 92.7877 62.7578C102.583 62.7578 111.978 66.6478 118.907 73.5725C120.652 75.3166 122.22 77.23 123.587 79.2844C123.808 79.5951 123.965 79.9471 124.047 80.3195C124.13 80.6919 124.137 81.0771 124.068 81.4522C123.998 81.8273 123.854 82.1846 123.644 82.503C123.434 82.8213 123.162 83.0941 122.844 83.3052C122.526 83.5162 122.169 83.6613 121.795 83.7316C121.42 83.802 121.034 83.7963 120.662 83.7148C120.289 83.6333 119.937 83.4777 119.625 83.2573C119.314 83.0369 119.05 82.7561 118.85 82.4317C117.692 80.6918 116.363 79.0713 114.885 77.5941C109.023 71.7359 101.075 68.4451 92.7874 68.4451C84.5 68.4451 76.5518 71.7359 70.6899 77.5941C69.2113 79.0755 67.8834 80.7001 66.7259 82.444C66.4663 82.8353 66.114 83.1563 65.7002 83.3784C65.2864 83.6004 64.8241 83.7166 64.3546 83.7166Z"
					fill="#9EBCEA"
				/>
				<path
					d="M132.866 75.9766C132.397 75.9769 131.935 75.8611 131.521 75.6397C131.108 75.4182 130.755 75.0979 130.495 74.7072C128.822 72.1898 126.903 69.8445 124.766 67.7059C116.284 59.2261 104.781 54.4623 92.7868 54.4623C80.7929 54.4623 69.29 59.2261 60.8077 67.7059C58.6688 69.8502 56.7437 72.1977 55.0596 74.715C54.6318 75.3234 53.9828 75.7401 53.2516 75.876C52.5204 76.0118 51.765 75.8559 51.1473 75.4417C50.5296 75.0274 50.0987 74.3878 49.9467 73.6597C49.7948 72.9317 49.934 72.1731 50.3345 71.5464C52.2249 68.722 54.3856 66.0883 56.7862 63.6824C66.3348 54.1362 79.2841 48.7734 92.7862 48.7734C106.288 48.7734 119.238 54.1362 128.786 63.6824C131.19 66.0878 133.349 68.7255 135.231 71.5567C135.516 71.985 135.679 72.4824 135.703 72.996C135.728 73.5096 135.612 74.0201 135.37 74.4734C135.127 74.9267 134.766 75.3058 134.325 75.5704C133.884 75.8349 133.38 75.9751 132.866 75.976V75.9766Z"
					fill="#9EBCEA"
				/>
				<path
					d="M124.096 125.255C135.985 125.255 145.622 115.617 145.622 103.729C145.622 91.8406 135.985 82.2031 124.096 82.2031C112.208 82.2031 102.57 91.8406 102.57 103.729C102.57 115.617 112.208 125.255 124.096 125.255Z"
					fill="#FD5D76"
				/>
				<path
					d="M132.587 114.834C132.28 114.835 131.975 114.773 131.692 114.653C131.409 114.532 131.154 114.356 130.941 114.134L113.959 96.4803C113.75 96.2643 113.587 96.0092 113.477 95.7298C113.367 95.4503 113.313 95.1519 113.319 94.8517C113.324 94.5515 113.389 94.2554 113.509 93.9802C113.629 93.705 113.802 93.4562 114.019 93.2479C114.235 93.0397 114.49 92.8762 114.77 92.7667C115.049 92.6572 115.348 92.6039 115.648 92.6098C115.948 92.6157 116.244 92.6808 116.519 92.8012C116.794 92.9217 117.043 93.0951 117.251 93.3117L134.234 110.965C134.544 111.288 134.753 111.695 134.834 112.136C134.915 112.577 134.865 113.031 134.689 113.444C134.514 113.856 134.221 114.207 133.847 114.455C133.473 114.702 133.035 114.833 132.587 114.833V114.834Z"
					fill="white"
				/>
				<path
					d="M115.605 114.831C115.157 114.832 114.719 114.7 114.345 114.453C113.971 114.206 113.678 113.854 113.503 113.442C113.327 113.03 113.277 112.575 113.358 112.134C113.439 111.693 113.648 111.286 113.959 110.964L130.941 93.3099C131.149 93.0937 131.398 92.9206 131.673 92.8005C131.948 92.6804 132.244 92.6156 132.544 92.6098C132.844 92.604 133.142 92.6574 133.421 92.7669C133.701 92.8764 133.956 93.0398 134.172 93.2479C134.388 93.4559 134.561 93.7045 134.681 93.9795C134.802 94.2544 134.866 94.5504 134.872 94.8504C134.878 95.1504 134.825 95.4485 134.715 95.7279C134.606 96.0073 134.442 96.2624 134.234 96.4786L117.252 114.132C117.039 114.354 116.783 114.53 116.5 114.65C116.217 114.77 115.913 114.832 115.605 114.831Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_5801_136780">
					<rect
						width="194"
						height="194"
						fill="white"
						transform="translate(0.755859 0.929688)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default OffLineSVG;
