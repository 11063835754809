import { Checkbox, FormControl, FormControlLabel, Grid } from '@material-ui/core';
import React, { FC, useContext, useEffect, useState } from 'react';
import { RelationsContext } from '../../context/relations-context';
import { RelationsContextProps, SecretaryType } from '../../context/types';
import {
	SecretaryButton,
	SecretaryDialog,
	SecretaryDialogContent,
	SecretaryDialogContentText,
	SecretaryDialogTitle,
	SecretaryFormGroup,
	SecretaryFormLabel,
	SecretaryTextField,
	SendButton,
} from '../../shared/style';
import { isValidEmail } from '../../shared/utilFunctions';
import useMobile from 'hooks/useMobile';
import MobileDialogHeader from '../dialog/MobileDialogHeader';
interface Props {
	open: boolean;
	handleClose: () => void;
	secretary: SecretaryType | null;
	onSubmit: (secretary: SecretaryType) => Promise<void>;
	onDelete: (secretary: SecretaryType) => Promise<void>;
}

const ModalSecretary: FC<Props> = ({ open, handleClose, secretary, onSubmit, onDelete }) => {
	const { actualHospital } = useContext(RelationsContext) as RelationsContextProps;
	const [email, setEmail] = useState<string>('');
	const [isInvalidEmail, setIsInvalidEmail] = useState<boolean>(false);
	const [hasPrescriptionsPermission, setHasPrescriptionsPermission] = useState<boolean>(false);
	const [hasFilesPermission, setHasFilesPermission] = useState<boolean>(false);
	const [hasClaimsPermission, setHasClaimsPermission] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);

	const handleSubmit = async () => {
		if (!email) {
			setIsInvalidEmail(true);
			return;
		}
		setLoading(true);
		await onSubmit({
			email,
			hospitalDoctorID: actualHospital?.id,
			hasPrescriptionsPermission,
			hasFilesPermission,
			hasClaimsPermission,
			hasHistoryPermission: false,
			hasNotesPermission: false,
			hasSurgeryPermission: false,
		} as SecretaryType);
		setLoading(false);
	};

	useEffect(() => {
		if (secretary) {
			if (secretary.email) setEmail(secretary.email);
			if (secretary.hasPrescriptionsPermission)
				setHasPrescriptionsPermission(secretary.hasPrescriptionsPermission);
			if (secretary.hasFilesPermission) setHasFilesPermission(secretary.hasFilesPermission);
			if (secretary.hasClaimsPermission)
				setHasClaimsPermission(secretary.hasClaimsPermission);
		}
	}, [secretary]);

	const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(e.target.value);
		if (!isValidEmail(e.target.value)) {
			setIsInvalidEmail(true);
		} else {
			setIsInvalidEmail(false);
		}
	};

	const { isMobile } = useMobile();

	const title = `${secretary ? 'Editar' : 'Agrega'} una secretaria`;

	const emailHelperText = (email: string, isInvalidEmail: boolean): string | undefined => {
		if (!email && isInvalidEmail) {
			return 'Debes agregar un correo.';
		}
		if (isInvalidEmail) {
			return 'El correo no es válido.';
		}
		return undefined;
	};
	return (
		<SecretaryDialog
			className="fullscreen-modal secretary-dialog"
			open={open}
			style={{ maxWidth: 488 }}
			handleClose={(_, reason): void => {
				if (reason !== 'backdropClick') {
					handleClose();
				}
			}}
			handleAgree={handleSubmit}
			aria-labelledby="secretary-dialog-title"
			aria-describedby="secretary-dialog-description"
			fullWidth
			fullScreen={isMobile}
			title={
				isMobile ? (
					<MobileDialogHeader goBack={handleClose} title={title} />
				) : (
					<SecretaryDialogTitle
						classes={{
							root: 'secretary-dialog-title',
						}}
					>
						{title}
					</SecretaryDialogTitle>
				)
			}
			agreeText={isMobile ? 'Guardar' : undefined}
			disagreeText={isMobile ? 'Cancelar' : undefined}
			deleteText={secretary && isMobile ? 'Eliminar' : undefined}
			handleDelete={secretary ? () => onDelete(secretary) : undefined}
		>
			<SecretaryDialogContent
				style={isMobile ? { paddingLeft: 0, paddingRight: 0 } : { paddingTop: '0' }}
			>
				{isMobile && (
					<SecretaryTextField
						type="text"
						value={email}
						onChange={handleEmail}
						id="secretaryEmail"
						variant="outlined"
						name="username"
						fullWidth
						label="Correo de la secretaria/o"
						placeholder="Correo de la secretaria/o"
						error={isInvalidEmail}
						disabled={secretary !== null}
						helperText={emailHelperText(email, isInvalidEmail)}
					/>
				)}

				<SecretaryDialogContentText>
					Puedes definir que pueden ver tus secretarias y así proteges la información más
					valiosa de tus pacientes.
				</SecretaryDialogContentText>

				{!isMobile && (
					<SecretaryTextField
						type="text"
						value={email}
						onChange={handleEmail}
						id="secretaryEmail"
						variant="outlined"
						name="username"
						fullWidth
						label="Correo de la secretaria/o"
						placeholder="Correo de la secretaria/o"
						error={isInvalidEmail}
						disabled={secretary !== null}
						classes={{
							root: 'secretary-dialog-email',
						}}
						helperText={emailHelperText(email, isInvalidEmail)}
					/>
				)}

				<Grid>
					<FormControl component="fieldset">
						<SecretaryFormLabel component="legend">
							Información de pacientes que puede ver
						</SecretaryFormLabel>
						<SecretaryFormGroup>
							<FormControlLabel
								control={
									<Checkbox
										checked={hasPrescriptionsPermission}
										color="primary"
										name="prescriptions"
										onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
											setHasPrescriptionsPermission(e.target.checked)
										}
									/>
								}
								label="Prescripciones"
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={hasFilesPermission}
										color="primary"
										name="files"
										onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
											setHasFilesPermission(e.target.checked)
										}
									/>
								}
								label="Archivos"
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={hasClaimsPermission}
										color="primary"
										name="claims"
										onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
											setHasClaimsPermission(e.target.checked)
										}
									/>
								}
								label="Reclamaciones"
							/>
						</SecretaryFormGroup>
					</FormControl>

					{!isMobile && (
						<>
							<SendButton
								onClick={handleSubmit}
								size="large"
								variant="contained"
								color="primary"
								fullWidth
								disabled={isInvalidEmail || loading}
							>
								{loading
									? 'Cargando...'
									: secretary
									? 'Editar Secretaria'
									: 'Agregar Secretaria'}
							</SendButton>
							<SecretaryButton
								size="large"
								color="primary"
								fullWidth
								onClick={handleClose}
							>
								Cancelar
							</SecretaryButton>
						</>
					)}
				</Grid>
			</SecretaryDialogContent>
		</SecretaryDialog>
	);
};

export default ModalSecretary;
