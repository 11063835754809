import { Button, Grid } from '@material-ui/core';
import React, { FC, ReactNode } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { EditIconButton, HeaderHistoryContainer, HistoryListTitle } from '../../../../shared/style';
import EditIcon from '@material-ui/icons/Edit';
import CTable from '../../../table/CTable';
import { IColumnType } from '../../../../shared/type';
import { ClientFamilyHistory, HistoryFamilyMember } from 'models';
import { getFamilyLabel } from '../util';
import useMobile from 'hooks/useMobile';

interface IData {
	familyMember: string | JSX.Element;
	action?: string;
	member?: string;
	inheritedFamilyDisease?: string | ReactNode;
	align?: 'center' | 'inherit' | 'justify' | 'left' | 'right';
	fontWeight?: number;
	isDisabled?: boolean;
}

interface Props {
	handleEditFamilyHistory: (id: string, familyMember: string) => void;
	handleCreateFamilyHistory: (familyMember: string) => void;
	familyHistories: any;
	isAnyModalOpen: boolean;
}

const FamilyHistoryList: FC<Props> = ({
	handleEditFamilyHistory,
	handleCreateFamilyHistory,
	familyHistories,
	isAnyModalOpen = false,
}) => {
	const { isMobile } = useMobile();

	const columns: IColumnType<IData>[] = [
		{
			key: 'familyMember',
			title: 'Nombre de relación',
			fontWeight: 410,
			width: isMobile ? 100 : 400,
		},
		{
			key: 'inheritedFamilyDisease',
			title: 'Nombre de enfermedades',
			width: isMobile ? 200 : 400,
		},
		{
			key: 'action',
			title: '',
			width: isMobile ? 40 : 400,
			render: (_, { familyMember, inheritedFamilyDisease, action }) => {
				if (isMobile) {
					return (
						<EditIconButton
							custommarginleft="0px"
							aria-label="edit"
							onClick={() =>
								action && inheritedFamilyDisease !== 'Sin datos'
									? handleEditFamilyHistory(
											action as string,
											familyMember as string
									  )
									: handleCreateFamilyHistory(familyMember as string)
							}
						>
							<EditIcon />
						</EditIconButton>
					);
				}
				return (
					<Button
						onClick={() =>
							action && inheritedFamilyDisease !== 'Sin datos'
								? handleEditFamilyHistory(action as string, familyMember as string)
								: handleCreateFamilyHistory(familyMember as string)
						}
						startIcon={
							action && inheritedFamilyDisease !== 'Sin datos' ? (
								<EditIcon />
							) : (
								<AddIcon />
							)
						}
						size="small"
						color="primary"
					>
						{action && inheritedFamilyDisease !== 'Sin datos' ? `Editar` : `Agregar`}{' '}
						datos
					</Button>
				);
			},
		},
	];

	const columnsForMobile: IColumnType<IData>[] = [
		{
			key: 'familyMember',
			title: 'Nombre de relación',
			fontWeight: 410,
			width: 400,
		},
		{
			key: 'action',
			title: '',
			width: 100,
			render: (_, { familyMember, member, inheritedFamilyDisease, action }) => {
				return (
					<EditIconButton
						custommarginleft="0px"
						aria-label="edit"
						onClick={() =>
							action && inheritedFamilyDisease !== 'Sin datos'
								? handleEditFamilyHistory(action as string, member as string)
								: handleCreateFamilyHistory(member as string)
						}
					>
						<EditIcon />
					</EditIconButton>
				);
			},
		},
	];

	const getSelectedClientByFamilyMember = (familyMember: HistoryFamilyMember) => {
		return familyHistories?.find((cfm: any) => cfm.familyMember === familyMember);
	};

	const clientFamilyFatherMember = getSelectedClientByFamilyMember(HistoryFamilyMember.FATHER);
	const clientFamilyMotherMember = getSelectedClientByFamilyMember(HistoryFamilyMember.MOTHER);
	const clientFamilyBrotherMember = getSelectedClientByFamilyMember(HistoryFamilyMember.BROTHERS);
	const clientFamilyGrandparentMember = getSelectedClientByFamilyMember(
		HistoryFamilyMember.GRANDPARENTS
	);
	const clientFamilyGreatGrandparentMember = getSelectedClientByFamilyMember(
		HistoryFamilyMember.GREATGRANDPARENTS
	);
	const clientFamilySonMember = getSelectedClientByFamilyMember(HistoryFamilyMember.SONS);

	const getRenderDiseaseName = (clientFamilyMember: ClientFamilyHistory) => {
		return clientFamilyMember.mellitusDiabetes ||
			clientFamilyMember.arterialHypertension ||
			clientFamilyMember.heartDisease ||
			clientFamilyMember.nephropathy ||
			clientFamilyMember.neuropathies ||
			clientFamilyMember.epilepsy ||
			clientFamilyMember.rheumatoidArthritis ||
			clientFamilyMember.psychiatricDiseases ||
			clientFamilyMember.cancer ||
			clientFamilyMember.other ? (
			<ul style={{ listStyleType: 'disc', marginLeft: '16px' }}>
				{clientFamilyMember.mellitusDiabetes && (
					<li>
						<strong>
							Diabetes mellitus
							{clientFamilyMember.mellitusDiabetesNote ? ':' : ''}{' '}
						</strong>
						{clientFamilyMember.mellitusDiabetesNote}
					</li>
				)}
				{clientFamilyMember.arterialHypertension && (
					<li>
						<strong>
							Hipertensión arterial
							{clientFamilyMember.arterialHypertensionNote ? ':' : ''}{' '}
						</strong>
						{clientFamilyMember.arterialHypertensionNote}
					</li>
				)}
				{clientFamilyMember.heartDisease && (
					<li>
						<strong>
							Cardiopatías{clientFamilyMember.heartDiseaseNote ? ':' : ''}{' '}
						</strong>
						{clientFamilyMember.heartDiseaseNote}
					</li>
				)}
				{clientFamilyMember.nephropathy && (
					<li>
						<strong>Nefropatías{clientFamilyMember.nephropathyNote ? ':' : ''} </strong>
						{clientFamilyMember.nephropathyNote}
					</li>
				)}
				{clientFamilyMember.neuropathies && (
					<li>
						<strong>
							Neuropatías{clientFamilyMember.neuropathiesNote ? ':' : ''}{' '}
						</strong>
						{clientFamilyMember.neuropathiesNote}
					</li>
				)}
				{clientFamilyMember.epilepsy && (
					<li>
						<strong>Epilepsia{clientFamilyMember.epilepsyNote ? ':' : ''} </strong>
						{clientFamilyMember.epilepsyNote}
					</li>
				)}
				{clientFamilyMember.rheumatoidArthritis && (
					<li>
						<strong>
							Artritis reumatoide
							{clientFamilyMember.rheumatoidArthritisNote ? ':' : ''}{' '}
						</strong>
						{clientFamilyMember.rheumatoidArthritisNote}
					</li>
				)}
				{clientFamilyMember.psychiatricDiseases && (
					<li>
						<strong>
							Enfermedades psiquiátricas{' '}
							{clientFamilyMember.psychiatricDiseasesNote ? ':' : ''}{' '}
						</strong>
						{clientFamilyMember.psychiatricDiseasesNote}
					</li>
				)}
				{clientFamilyMember.cancer && (
					<li>
						<strong>Cáncer {clientFamilyMember.cancerNote ? ':' : ''} </strong>
						{clientFamilyMember.cancerNote}
					</li>
				)}
				{clientFamilyMember.other && (
					<li>
						<strong>Otros {clientFamilyMember.otherNote ? ':' : ''} </strong>
						{clientFamilyMember.otherNote}
					</li>
				)}
			</ul>
		) : null;
	};

	const data: IData[] = [
		{
			familyMember: getFamilyLabel(HistoryFamilyMember.FATHER),
			inheritedFamilyDisease:
				clientFamilyFatherMember && getRenderDiseaseName(clientFamilyFatherMember)
					? getRenderDiseaseName(clientFamilyFatherMember)
					: 'Sin datos',
			action: clientFamilyFatherMember ? clientFamilyFatherMember.id : '',
		},
		{
			familyMember: getFamilyLabel(HistoryFamilyMember.MOTHER),
			inheritedFamilyDisease:
				clientFamilyMotherMember && getRenderDiseaseName(clientFamilyMotherMember)
					? getRenderDiseaseName(clientFamilyMotherMember)
					: 'Sin datos',
			action: clientFamilyMotherMember ? clientFamilyMotherMember.id : '',
		},
		{
			familyMember: getFamilyLabel(HistoryFamilyMember.BROTHERS),
			inheritedFamilyDisease:
				clientFamilyBrotherMember && getRenderDiseaseName(clientFamilyBrotherMember)
					? getRenderDiseaseName(clientFamilyBrotherMember)
					: 'Sin datos',
			action: clientFamilyBrotherMember ? clientFamilyBrotherMember.id : '',
		},
		{
			familyMember: getFamilyLabel(HistoryFamilyMember.GRANDPARENTS),
			inheritedFamilyDisease:
				clientFamilyGrandparentMember && getRenderDiseaseName(clientFamilyGrandparentMember)
					? getRenderDiseaseName(clientFamilyGrandparentMember)
					: 'Sin datos',
			action: clientFamilyGrandparentMember ? clientFamilyGrandparentMember.id : '',
		},
		{
			familyMember: getFamilyLabel(HistoryFamilyMember.GREATGRANDPARENTS),
			inheritedFamilyDisease:
				clientFamilyGreatGrandparentMember &&
				getRenderDiseaseName(clientFamilyGreatGrandparentMember)
					? getRenderDiseaseName(clientFamilyGreatGrandparentMember)
					: 'Sin datos',
			action: clientFamilyGreatGrandparentMember ? clientFamilyGreatGrandparentMember.id : '',
		},
		{
			familyMember: getFamilyLabel(HistoryFamilyMember.SONS),
			inheritedFamilyDisease:
				clientFamilySonMember && getRenderDiseaseName(clientFamilySonMember)
					? getRenderDiseaseName(clientFamilySonMember)
					: 'Sin datos',
			action: clientFamilySonMember ? clientFamilySonMember.id : '',
		},
	];

	const dataForMobile: IData[] = [
		{
			familyMember: (
				<div>
					<h3>{getFamilyLabel(HistoryFamilyMember.FATHER)}</h3>{' '}
					{clientFamilyFatherMember && getRenderDiseaseName(clientFamilyFatherMember)
						? getRenderDiseaseName(clientFamilyFatherMember)
						: 'Sin datos'}
				</div>
			),
			member: getFamilyLabel(HistoryFamilyMember.FATHER),
			action: clientFamilyFatherMember ? clientFamilyFatherMember.id : '',
		},
		{
			familyMember: (
				<div>
					<h3>{getFamilyLabel(HistoryFamilyMember.MOTHER)}</h3>{' '}
					{clientFamilyMotherMember && getRenderDiseaseName(clientFamilyMotherMember)
						? getRenderDiseaseName(clientFamilyMotherMember)
						: 'Sin datos'}
				</div>
			),
			member: getFamilyLabel(HistoryFamilyMember.MOTHER),
			action: clientFamilyMotherMember ? clientFamilyMotherMember.id : '',
		},
		{
			familyMember: (
				<div>
					<h3>{getFamilyLabel(HistoryFamilyMember.BROTHERS)}</h3>{' '}
					{clientFamilyBrotherMember && getRenderDiseaseName(clientFamilyBrotherMember)
						? getRenderDiseaseName(clientFamilyBrotherMember)
						: 'Sin datos'}
				</div>
			),
			member: getFamilyLabel(HistoryFamilyMember.BROTHERS),
			action: clientFamilyBrotherMember ? clientFamilyBrotherMember.id : '',
		},
		{
			familyMember: (
				<div>
					<h3>{getFamilyLabel(HistoryFamilyMember.GRANDPARENTS)}</h3>{' '}
					{clientFamilyGrandparentMember &&
					getRenderDiseaseName(clientFamilyGrandparentMember)
						? getRenderDiseaseName(clientFamilyGrandparentMember)
						: 'Sin datos'}
				</div>
			),
			member: getFamilyLabel(HistoryFamilyMember.GRANDPARENTS),
			action: clientFamilyGrandparentMember ? clientFamilyGrandparentMember.id : '',
		},
		{
			familyMember: (
				<div>
					<h3>{getFamilyLabel(HistoryFamilyMember.GREATGRANDPARENTS)}</h3>{' '}
					{clientFamilyGreatGrandparentMember &&
					getRenderDiseaseName(clientFamilyGreatGrandparentMember)
						? getRenderDiseaseName(clientFamilyGreatGrandparentMember)
						: 'Sin datos'}
				</div>
			),
			member: getFamilyLabel(HistoryFamilyMember.GREATGRANDPARENTS),
			action: clientFamilyGreatGrandparentMember ? clientFamilyGreatGrandparentMember.id : '',
		},
		{
			familyMember: (
				<div>
					<h3>{getFamilyLabel(HistoryFamilyMember.SONS)}</h3>{' '}
					{clientFamilySonMember && getRenderDiseaseName(clientFamilySonMember)
						? getRenderDiseaseName(clientFamilySonMember)
						: 'Sin datos'}
				</div>
			),
			member: getFamilyLabel(HistoryFamilyMember.SONS),
			action: clientFamilySonMember ? clientFamilySonMember.id : '',
		},
	];

	const renderData = isMobile ? dataForMobile : data;
	const renderColumns = isMobile ? columnsForMobile : columns;

	return (
		<Grid
			container
			style={{
				paddingBottom: isMobile && !isAnyModalOpen ? '70px' : '',
			}}
		>
			{!isMobile ? (
				<HeaderHistoryContainer item>
					<HistoryListTitle variant="h6">Historia familiar</HistoryListTitle>
				</HeaderHistoryContainer>
			) : null}
			<CTable data={renderData} columns={renderColumns} />
		</Grid>
	);
};

export default FamilyHistoryList;
