import { Divider, Grid, ListItemText } from '@material-ui/core';
import React, { useContext } from 'react';
import CustomCard from '../../card/CustomCard';
import { ClientsContext } from '../../../context/client-context';
import { ClientContextProps } from '../../../context/types';
import { DateErrorMessage, displayAge } from '../../../shared/dateUtils';
import { StyledListItem } from './PatientSummary.style';
import moment from 'moment';
import { insuranceType, NoSpecified } from '../../../shared/constants';
import EditIcon from '@material-ui/icons/Edit';
import { PatientIconButton } from '../../../shared/style';

interface Props {
	handleEditPatient: () => void;
}

const SummaryPatientInformation: React.FC<Props> = ({ handleEditPatient }) => {
	const { selectedClient } = useContext(ClientsContext) as ClientContextProps;
	const yearsOld =
		selectedClient?.bornDate && selectedClient?.bornDate !== DateErrorMessage
			? displayAge(moment(selectedClient.bornDate))
			: NoSpecified;

	return (
		<CustomCard
			title="Información Personal"
			divider={true}
			headerAction={
				<PatientIconButton aria-label="edit" size="medium" onClick={handleEditPatient}>
					<EditIcon />
				</PatientIconButton>
			}
			paddingTop={'0'}
			paddingBottom={'0'}
		>
			<Grid container>
				{selectedClient && (
					<>
						<Grid item xs={12} md={6}>
							<StyledListItem>
								<ListItemText
									primary={selectedClient?.identificationData || NoSpecified}
									secondary="Número de indentificación"
								/>
							</StyledListItem>
						</Grid>
						<Grid item xs={12} md={6}>
							<StyledListItem>
								<ListItemText primary={`${yearsOld}`} secondary="Edad" />
							</StyledListItem>
						</Grid>
					</>
				)}
			</Grid>
			<Divider />

			<Grid container>
				{selectedClient && (
					<>
						<Grid item xs={12} md={6}>
							<StyledListItem>
								<ListItemText
									primary={selectedClient?.gender || NoSpecified}
									secondary="Sexo biológico"
								/>
							</StyledListItem>
						</Grid>
						<Grid item xs={12} md={6}>
							<StyledListItem>
								<ListItemText
									primary={selectedClient?.occupation || NoSpecified}
									secondary="Ocupación"
								/>
							</StyledListItem>
						</Grid>
					</>
				)}
			</Grid>
			<Divider />

			<Grid container>
				{selectedClient && (
					<>
						<Grid item xs={12} md={6}>
							<StyledListItem>
								<ListItemText
									primary={selectedClient?.cellphoneNumber || NoSpecified}
									secondary="Celular"
								/>
							</StyledListItem>
						</Grid>
						<Grid item xs={12} md={6}>
							<StyledListItem>
								<ListItemText
									primary={selectedClient?.phoneNumber || NoSpecified}
									secondary="Teléfono"
								/>
							</StyledListItem>
						</Grid>
					</>
				)}
			</Grid>
			<Divider />

			<Grid container>
				{selectedClient && (
					<>
						<Grid item xs={12} md={6}>
							<StyledListItem>
								<ListItemText
									primary={selectedClient?.email || NoSpecified}
									secondary="Correo"
								/>
							</StyledListItem>
						</Grid>
						<Grid item xs={12} md={6}>
							<StyledListItem>
								<ListItemText
									primary={selectedClient?.bloodType || NoSpecified}
									secondary="Tipo de Sangre"
								/>
							</StyledListItem>
						</Grid>
					</>
				)}
			</Grid>
			<Divider />

			<Grid container>
				{selectedClient && (
					<>
						<Grid item xs={12} md={6}>
							<StyledListItem>
								<ListItemText
									primary={selectedClient?.addressStreet || NoSpecified}
									secondary="Calle y número"
								/>
							</StyledListItem>
						</Grid>
						<Grid item xs={12} md={6}>
							<StyledListItem>
								<ListItemText
									primary={selectedClient?.city || NoSpecified}
									secondary="Ciudad"
								/>
							</StyledListItem>
						</Grid>
					</>
				)}
			</Grid>

			{selectedClient && (
				<>
					<Divider />
					<Grid container>
						<Grid item xs={12} md={6}>
							<StyledListItem>
								<ListItemText
									primary={selectedClient?.sector || NoSpecified}
									secondary="Sector"
								/>
							</StyledListItem>
						</Grid>
						<Grid item xs={12} md={6}>
							<StyledListItem>
								<ListItemText
									primary={selectedClient?.actualInsurance || NoSpecified}
									secondary="Seguro"
								/>
							</StyledListItem>
						</Grid>
					</Grid>
					<Divider />

					{selectedClient?.identificationData &&
						selectedClient?.healthInsuranceName !== insuranceType.SINSEGURO && (
							<Grid container>
								<Grid item xs={12} md={6}>
									<StyledListItem>
										<ListItemText
											primary={selectedClient?.affiliateNumber || NoSpecified}
											secondary="No. de Afiliado"
										/>
									</StyledListItem>
								</Grid>
								<Grid item xs={12} md={6}>
									<StyledListItem>
										<ListItemText
											primary={selectedClient?.contractNumber || NoSpecified}
											secondary="No. de Contrato"
										/>
									</StyledListItem>
								</Grid>
							</Grid>
						)}
				</>
			)}
		</CustomCard>
	);
};

export default SummaryPatientInformation;
