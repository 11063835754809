import LoginPulseara from 'components/svg/LoginPulseara';
import useMobile from 'hooks/useMobile';
import { Redirect } from 'react-router-dom';

const ConfirmRegistration = () => {
	const { isMobile, hasResize } = useMobile();

	if (!isMobile && hasResize) {
		return <Redirect to="/login" />;
	}

	return (
		<div className="relative top-0 left-0 w-full h-screen min-wh-login flex flex-col items-center justify-center px-[20px]">
			<div className="w-[300px]">
				<div className="mb-16 flex justify-center items-center">
					<LoginPulseara />
				</div>
				<div className="flex flex-col justify-center items-center gap-[20px]">
					<h3 className="text-center font-['Noto Sans'] font-normal text-[32px] text-[#000]">
						¡Listo!
					</h3>
					<p className="text-center font-['Roboto'] font-normal text-[16px] color-[#424242] leading-6 w-[240px]">
						Recibiste un correo para iniciar la app en tu computadora. Revisa tu carpeta
						de spam si no lo ves. Ante problemas, contáctanos al{' '}
						<a
							href="tel:8296532245"
							className="font-roboto font-bold text-[16px] text-[#1976D2] leading-6"
						>
							829-653-2245
						</a>
					</p>
				</div>
			</div>
		</div>
	);
};

export default ConfirmRegistration;
