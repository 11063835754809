// @ts-nocheck
import React, { FC, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from './TabPanel';
import { withStyles, createTheme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import LoadingSpinner from '../loading/LoadingSpinner';
import useMobile from 'hooks/useMobile';
import { Button } from '@material-ui/core';
import { AddIcon } from 'components/svg';

const theme = createTheme();

const StyledTabs = withStyles({
	root: {
		padding: theme.spacing(0, 1),
	},
})(Tabs) as typeof Tabs;

const StyledAppBar = withStyles({
	root: {
		position: 'sticky',
		top: 0,
		backgroundColor: '#fff',
		[theme.breakpoints.up('md')]: {
			minHeight: '120px',
		},
	},
})(AppBar) as typeof AppBar;

const StyledTab = withStyles({
	root: {
		minWidth: '70px',
	},
})(Tab) as typeof Tab;

const useStyles = makeStyles(() => ({
	root: {
		flexGrow: 1,
		width: '100%',
		backgroundColor: '#eceff1',
	},
}));

interface TabItem {
	id: number;
	label: string;
	body: React.ReactNode;
	path?: string;
}

interface Props {
	customHeader?: React.ReactNode;
	tabList: TabItem[];
	actualTab: number;
	isLoading: boolean;
	isLink: boolean;
	handleChange: (e: React.ChangeEvent<Record<string, unknown>>, newValue: number) => void;
}

const CustomTabs: FC<Props> = (props) => {
	const classes = useStyles();
	const [isPlusOpen, setIsPlusOpen] = useState(false);
	const dropdownRef = useRef(null);
	const { isMobile, isMobileWidth } = useMobile();
	const { tabList } = props;

	const TABS = [
		'Resumen',
		'Historial',
		'Consultas',
		'Anteriores',
		'Consultas Anteriores',
		'Nuevo',
		'Iniciar Consulta',
		'Editar',
	];

	if (isMobileWidth >= 300 && tabList.filter((t) => TABS.includes(t.label))?.length === 1) {
		TABS.push('Prescripciones');
	}

	if (isMobileWidth >= 400 && tabList.filter((t) => TABS.includes(t.label))?.length === 2) {
		TABS.push('Archivos');
	}

	if (isMobileWidth >= 500 && tabList.filter((t) => TABS.includes(t.label))?.length === 2) {
		TABS.push('Reclamaciones');
	}

	if (isMobileWidth >= 550) {
		TABS.push('Prescripciones');
	}

	if (isMobileWidth >= 600) {
		TABS.push('Reclamaciones');
	}

	if (isMobileWidth >= 700) {
		TABS.push('Archivos');
	}

	const toggleDropdown = () => {
		setIsPlusOpen(!isPlusOpen);
	};

	const tabLinks = isMobile ? tabList.filter((t) => TABS.includes(t.label)) : tabList;
	const tabLinksplus = isMobile ? tabList.filter((t) => !TABS.includes(t.label)) : null;

	return (
		<div className={classes.root} style={isMobile ? { height: '100px' } : {}}>
			<div
				style={
					isMobile
						? {
								touchAction: 'none',
								position: 'fixed',
								width: '100%',
								zIndex: 999,
						  }
						: {}
				}
			>
				<StyledAppBar position="static" color="default">
					{props.customHeader}
					<StyledTabs
						value={props.actualTab}
						onChange={props.handleChange}
						indicatorColor="primary"
						textColor="primary"
						variant={
							isMobile && tabLinksplus?.length === 0 ? 'fullWidth' : 'scrollable'
						}
						scrollButtons="auto"
						aria-label="scrollable auto tabs example"
						className={isMobile ? 'tabs-mobile' : ''}
					>
						{tabLinks.map((t) => {
							if (props.isLink) {
								return (
									<StyledTab
										key={t.id}
										component={Link}
										to={t.path}
										label={t.label}
										id={`scrollable-auto-tab-${t.id}`}
										aria-controls={`scrollable-auto-tabpanel-${t.id}`}
										value={t.id}
										data-fx-name={t.label}
									/>
								);
							} else {
								return (
									<StyledTab
										key={t.id}
										label={t.label}
										id={`scrollable-auto-tab-${t.id}`}
										aria-controls={`scrollable-auto-tabpanel-${t.id}`}
										value={t.id}
										data-fx-name={t.label}
									/>
								);
							}
						})}

						{isMobile && tabLinksplus?.length > 0 && (
							<div className={`tab-simple-block ${isPlusOpen ? 'open' : ''}`}>
								<Button
									color="inherit"
									variant="text"
									className="tab-simple-button"
									onClick={toggleDropdown}
								>
									<AddIcon />
									más
								</Button>

								<div className="plus-dropdown-content" ref={dropdownRef}>
									{tabLinksplus.map((t) => (
										<Link
											onClick={toggleDropdown}
											key={t.id}
											to={t.path}
											id={`scrollable-auto-tab-${t.id}`}
											aria-controls={`scrollable-auto-tabpanel-${t.id}`}
										>
											{t.label}
										</Link>
									))}
								</div>

								{isPlusOpen && (
									<div
										className="fixed top-0 left-0 right-0 bottom-0 h-full w-full bg-transparent"
										onClick={toggleDropdown}
									></div>
								)}
							</div>
						)}
					</StyledTabs>
				</StyledAppBar>
				{props.tabList.map((t) => {
					return (
						<TabPanel key={t.id} value={props.actualTab} index={t.id}>
							{props.isLoading ? <LoadingSpinner /> : t.body}
						</TabPanel>
					);
				})}
			</div>
		</div>
	);
};
export default CustomTabs;
