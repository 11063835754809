import { Skeleton } from '@material-ui/lab';

const AllergyCardSkeleton = () => {
	return (
		<div
			style={{
				marginBottom: '16px',
			}}
		>
			<Skeleton variant="rect" width="100%" height={30} />
		</div>
	);
};

export default AllergyCardSkeleton;
