import React, { FC, Fragment } from 'react';
import { Zoom } from '@material-ui/core';
import SearchAutocomplete from '../../../../searchAutocomplete/SearchAutocomplete';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {
	DeleteIconButton,
	BodyListItem,
	BodyContainer,
	BodyGrid,
	BodyActionGrid,
	DescriptionListItem,
	StyledTextField,
	StyledTooltip,
	MedicineGrid,
} from '../../../../../shared/style';
import { Medicine } from '../../../../../shared/type';
import { pillOptions, indicationOptions, durationOptions } from '../../../../../shared/constants';
import useMobile from 'hooks/useMobile';

interface Props {
	medicine: Medicine;
	handleMouseOverMedicine: (id: number | string) => void;
	handleDeleteMedicine: (id: number | string) => void;
	handleFocusMedicine: (e: any, id: number | string) => void;
	handleUnFocuseMedicine: (e: any, id: number | string) => void;
	handleChangeMedicine: (id: number | string, label: string, value: any) => void;
}

const MedicineBody: FC<Props> = ({
	medicine,
	handleMouseOverMedicine,
	handleDeleteMedicine,
	handleFocusMedicine,
	handleUnFocuseMedicine,
	handleChangeMedicine,
}) => {
	const { isMobile } = useMobile();
	return isMobile ? (
		<Fragment>
			{/* <BodyListItem onMouseEnter={() => handleMouseOverMedicine(medicine.id)}>
        <BodyContainer container gap={16}>
          <MedicineGrid item>
            <StyledTooltip
              placement="top"
              TransitionComponent={Zoom}
              title={medicine.label}
              arrow
            >
              <p>{medicine.label}</p>
            </StyledTooltip>
          </MedicineGrid>
          <SearchAutocomplete
            id="take"
            placeholder="Una Pastilla"
            value={medicine.take}
            onChange={(_e, value: string | null) => {
              if (value) {
                handleChangeMedicine(medicine.id, "take", value);
              } else {
                handleChangeMedicine(medicine.id, "take", "");
              }
            }}
            inputValue={medicine.take ?? ""}
            onInputChange={(_e, value) => {
              handleChangeMedicine(medicine.id, "take", value);
            }}
            options={pillOptions ? pillOptions.map((p) => p.label) : []}
          />

          <SearchAutocomplete
            id="indication"
            placeholder="En ayuna"
            value={medicine.indication}
            onChange={(_e, value: string | null) => {
              if (value) {
                handleChangeMedicine(medicine.id, "indication", value);
              } else {
                handleChangeMedicine(medicine.id, "indication", "");
              }
            }}
            inputValue={medicine.indication ?? ""}
            onInputChange={(_e, value) => {
              handleChangeMedicine(medicine.id, "indication", value);
            }}
            options={
              indicationOptions ? indicationOptions.map((p) => p.label) : []
            }
          />
          <SearchAutocomplete
            id="duration"
            placeholder="20 días"
            value={medicine.duration as string}
            onChange={(_e, value: string | null) => {
              if (value !== null && value != undefined && value) {
                handleChangeMedicine(medicine.id, "duration", value);
              } else {
                handleChangeMedicine(medicine.id, "duration", "");
              }
            }}
            inputValue={medicine.duration ?? ""}
            onInputChange={(_e, value) => {
              handleChangeMedicine(medicine.id, "duration", value);
            }}
            options={durationOptions ? durationOptions.map((p) => p.label) : []}
          />
          <DeleteIconButton
            onClick={() => handleDeleteMedicine(medicine.id)}
            aria-label="add"
          >
            <DeleteForeverIcon fontSize="small" />
          </DeleteIconButton>

          <StyledTextField
            onFocus={(e: any) => handleFocusMedicine(e, medicine.id)}
            onBlur={(e: any) => handleUnFocuseMedicine(e, medicine.id)}
            value={medicine.description}
            onChange={(e: any) =>
              handleChangeMedicine(medicine.id, "description", e.target.value)
            }
            fullWidth
            id="description"
            label="Descripción"
            variant="outlined"
          />
        </BodyContainer>
      </BodyListItem> */}
		</Fragment>
	) : (
		<Fragment>
			<BodyListItem onMouseEnter={() => handleMouseOverMedicine(medicine.id)}>
				<BodyContainer container>
					<MedicineGrid item>
						<StyledTooltip
							placement="top"
							TransitionComponent={Zoom}
							title={medicine.label}
							arrow
						>
							<p>{medicine.label}</p>
						</StyledTooltip>
					</MedicineGrid>
					<BodyGrid item>
						<SearchAutocomplete
							id="take"
							placeholder="Una Pastilla"
							value={medicine.take}
							onChange={(_e, value: string | null) => {
								if (value) {
									handleChangeMedicine(medicine.id, 'take', value);
								} else {
									handleChangeMedicine(medicine.id, 'take', '');
								}
							}}
							inputValue={medicine.take ?? ''}
							onInputChange={(_e, value) => {
								handleChangeMedicine(medicine.id, 'take', value);
							}}
							options={pillOptions ? pillOptions.map((p) => p.label) : []}
						/>
					</BodyGrid>
					<BodyGrid item>
						<SearchAutocomplete
							id="indication"
							placeholder="En ayuna"
							value={medicine.indication}
							onChange={(_e, value: string | null) => {
								if (value) {
									handleChangeMedicine(medicine.id, 'indication', value);
								} else {
									handleChangeMedicine(medicine.id, 'indication', '');
								}
							}}
							inputValue={medicine.indication ?? ''}
							onInputChange={(_e, value) => {
								handleChangeMedicine(medicine.id, 'indication', value);
							}}
							options={indicationOptions ? indicationOptions.map((p) => p.label) : []}
						/>
					</BodyGrid>
					<BodyGrid item>
						<SearchAutocomplete
							id="duration"
							placeholder="20 días"
							value={medicine.duration as string}
							onChange={(_e, value: string | null) => {
								if (value !== null && value !== undefined && value) {
									handleChangeMedicine(medicine.id, 'duration', value);
								} else {
									handleChangeMedicine(medicine.id, 'duration', '');
								}
							}}
							inputValue={medicine.duration ?? ''}
							onInputChange={(_e, value) => {
								handleChangeMedicine(medicine.id, 'duration', value);
							}}
							options={durationOptions ? durationOptions.map((p) => p.label) : []}
						/>
					</BodyGrid>
					<BodyActionGrid item>
						<DeleteIconButton
							onClick={() => handleDeleteMedicine(medicine.id)}
							aria-label="add"
						>
							<DeleteForeverIcon fontSize="small" />
						</DeleteIconButton>
					</BodyActionGrid>
				</BodyContainer>
			</BodyListItem>
			{(medicine.showDescription || medicine.description) && (
				<DescriptionListItem>
					<StyledTextField
						onFocus={(e: any) => handleFocusMedicine(e, medicine.id)}
						onBlur={(e: any) => handleUnFocuseMedicine(e, medicine.id)}
						value={medicine.description}
						onChange={(e: any) =>
							handleChangeMedicine(medicine.id, 'description', e.target.value)
						}
						fullWidth
						id="description"
						label="Descripción"
						variant="outlined"
					/>
				</DescriptionListItem>
			)}
		</Fragment>
	);
};

export default MedicineBody;
