import { Grid } from '@material-ui/core';
import React, { FC } from 'react';
import CustomDialog from '../dialog/CustomDialog';
import { InputContainer } from './style';
import { LabImage } from 'models';
import SearchAutocomplete from '../searchAutocomplete/SearchAutocomplete';
import { SearchListItemText } from '../../shared/style';
import useMobile from 'hooks/useMobile';
import MobileDialogHeader from '../dialog/MobileDialogHeader';

interface Props {
	open: boolean;
	isUpdate: boolean;
	isDisabled?: boolean;
	handleClose: (event: any) => void;
	searchLabImages: LabImage[];
	handleAgree?: (labImage: LabImage) => void;
	labImage: LabImage;
	handleChangename: (value: string) => void;
	handleClickLabImage: (value: LabImage) => void;
}

const LabImageDialog: FC<Props> = ({
	isUpdate = false,
	isDisabled,
	open,
	handleClose,
	searchLabImages,
	handleAgree,
	labImage,
	handleChangename,
	handleClickLabImage,
}) => {
	const { isMobile } = useMobile();
	const dialogTitle = isUpdate ? 'Editar Lab/Imagen' : 'Nuevo Lab/Imagen';
	return (
		<CustomDialog
			title={
				isMobile ? (
					<MobileDialogHeader title={dialogTitle} goBack={handleClose} />
				) : (
					dialogTitle
				)
			}
			disableBackdropClick={true}
			open={open}
			maxWidth={'sm'}
			disagreeText={'Cancelar'}
			agreeText={'Guardar'}
			fullWidth
			disabled={isDisabled}
			handleClose={handleClose}
			fullScreen={isMobile}
			className="fullscreen-modal"
			handleAgree={() => handleAgree?.(labImage)}
		>
			<Grid container style={isMobile ? { marginTop: '32px' } : {}}>
				<InputContainer item xs={12}>
					<SearchAutocomplete
						id="autocomplete-search"
						inputValue={labImage.name ? labImage.name : ''}
						options={searchLabImages}
						label={'Nombre del Lab/Imagen'}
						placeholder={'Nombre del Lab/Imagen'}
						onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							e != null && handleChangename(e.target.value);
						}}
						getOptionLabel={(option) => option.name || ''}
						renderOption={(option) => (
							<SearchListItemText
								onClick={() => handleClickLabImage(option)}
								primary={option.name || ''}
							/>
						)}
					/>
				</InputContainer>
			</Grid>
		</CustomDialog>
	);
};

export default LabImageDialog;
