import React, { useContext, useEffect } from 'react';
import useOnlineHook from '../shared/hooks/useOnlineHook';
import NoInternet from '../components/noInternet/NoInternet';
import Iframe from 'react-iframe';
import { LoaderContext } from '../context/loader-context';
import { LoaderContextProps } from '../context/types';

const Chat: React.FC = () => {
	const { showSpinner } = useContext(LoaderContext) as LoaderContextProps;

	const onlineStatus = useOnlineHook();

	useEffect(() => {
		showSpinner(true);
	}, [showSpinner]);

	const handleLoad = () => {
		showSpinner(false);
	};

	return onlineStatus ? (
		<>
			<Iframe
				url={`${process.env.REACT_APP_CHAT_URL}`}
				width="640px"
				display="block"
				position="relative"
				styles={{
					height: '685px',
					width: '1445px',
					borderRadius: '14px',
					margin: '50px 20px',
				}}
				onLoad={handleLoad}
			/>
		</>
	) : (
		<NoInternet
			style={{ margin: '50px 20px 20px 20px' }}
			message="Para usar el chat debes de conectarte a internet."
		/>
	);
};

export default Chat;
